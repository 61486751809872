import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "react-step-progress-bar/styles.css";
import { Modal, Image } from 'react-bootstrap'
import { loadScript } from '../../helpers/Helpers';
import { toast } from 'react-toastify';
import { verifyBank, verifyUpi, walletOnboarding, verifyOwnershipThirdParty, updateOnboardingStatus, sendGstOrPan} from '../../redux/modules/newOnboarding';
import { verifyOwnershipThroughBankDetails, verifyOwnershipManually, getPhoneFromGst, verifyBankDetailsManually} from '../../redux/modules/merchantVerification/merchantVerification';
import {activateSaasUltron} from '../../redux/modules/merchantOnboarding';
import Loader from '../../images/loader.gif';
import blueTick from '../../images/blue-tick.svg';
import clockIcon from '../../images/in-process-clock.svg';
import closeIcon from '../../images/close-crm.svg';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'

import DiyTick from '../../images/DiyTick.png';
import UploadDiy from '../../images/UploadDiy.png';
import { getWeekYearWithOptions } from 'date-fns/fp';
// import './NewOnboarding.css';
const ga = window.ga;

export default connect(store => ({ 
    ultronOnboardingResponse: store.merchantOnboarding.ultronOnboardingResponse,
    saasLoading: store.merchantOnboarding.saas_loading,
    walletOnboardingData: store.merchantOnboarding.wallet_data,
    loading_gst_mobile: store.merchantActivation.loading_gst_mobile,
    loaded_gst_mobile: store.merchantActivation.loaded_gst_mobile,
    mobile: store.merchantActivation.mobile,
    mobileError: store.merchantActivation.mobileError,

    verifyingManually: store.merchantActivation.verifyingManually,
    manualResponse: store.merchantActivation.manualResponse,
    manualError: store.merchantActivation.manualError,

    uploadingCheque: store.merchantActivation.uploadingCheque
  }), dispatch => bindActionCreators({
    verifyOwnershipThroughBankDetails,
    verifyUpi,
    verifyBank,
    walletOnboarding,
    verifyOwnershipThirdParty,
    activateSaasUltron,
    updateOnboardingStatus,
    verifyOwnershipManually,
    getPhoneFromGst,
    verifyBankDetailsManually,
    sendGstOrPan
}, dispatch))
(class Step2Diy extends Component{
    constructor(props){
        super(props);
        this.state = {
            phoneNumber: "",
            upiId: "",
            showBankDetails: false,
            accountNo: "",
            accountNo1: "",
            ifscCode: "",
            accountName: "",
            fileSelected:"",
            gstNumber:"",
            gstNumber2:"",
            panNumber:"",
            panNumber2:"",
            uploadDocumentSuccess:false,
            gstNumberCheckSuccess:false,
            currPage:"step2Start",
            isUpiWrong:false,
            isAccountWrong:false,
            chequeSelected:"",
            benificiaryName:"",
            isFnBorPharma:false,
            showUpiInvalid:false,
            showAccountInvalid:false,
            numberType:""
        }
    }

    componentDidMount(){
        this.props.sendOnboardingEvent('step2_landing');
        const autoUPI = this.props.phoneNumber.slice(2,this.props.phoneNumber.length) +"@upi";
        const payload = {
            "accountName": this.state.accountName || "magicpin merchant",
            "upiId": autoUPI,
        }
        this.props.verifyUpi(payload)
        .then(res => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                this.setState({
                    upiId:autoUPI
                })
            }
        })

        if(window.localStorage.getItem("otpVerificationObject"))
        {
            let otpVerificationObject = JSON.parse(window.localStorage.getItem("otpVerificationObject")) 
            let currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
            if(otpVerificationObject.isOtpVerified && otpVerificationObject.time && otpVerificationObject.time!=="" && currentTime.toString()<otpVerificationObject.time.toString())
            {
                this.props.handleOtpVerifyCheck();
            }
            else
            {
                this.props.handleOtpSendStep2();
            }
        }

        else{
            this.props.handleOtpSendStep2(); 
        }

    }

    changeBankDetails(key,value){
        this.setState({
            [key]: value,
            showUpiInvalid:false,
            showAccountInvalid:false,
        })
        if(key==="upiId")
        {
            this.setState({
                accountNo: "",
                accountNo1: "",
                ifscCode: "",
                accountName: "", 
            })
        }
        else{
            this.setState({upiId:""});
        }
    }

    activateSaas = () => {
        this.props.sendOnboardingEvent('activate_saas_loading');
        const payload = {
            "merchantId": this.props.merchantId,
            "phoneNumber":  this.props.phoneNumber,
            "moveWithoutVerification": true,
            "source": "web",
            "offerings": [{"offeringName":"enable_saas","offeringValue":1}],
            "merchantName" : this.props.merchantInfo ? this.props.merchantInfo.merchant_name : "",
            "locality" : this.props.merchantInfo ? this.props.merchantInfo.locality : ""
        }
        this.props.activateSaasUltron(payload).then(() => {
            if(this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.status && this.props.ultronOnboardingResponse.status.toUpperCase() === 'SUCCESS') {
                this.props.sendOnboardingEvent('activate_saas_success');
                if(this.props.appInstall){
                    this.activateMarketing();
                }
                else{
                    //this.props.updateOnboardingStatus(this.props.merchantId,"COMLETE_BANK_DETAILS")
                    this.props.updateOnboardingStatus(this.props.merchantId,"COMPLETE_TRAINING")
                    this.props.handleNext();
                    this.props.changePage("step3Start",2);
                    this.setState({ showLoader: false})
                }
            } 
            else{
                this.setState({ showLoader: false})
                this.props.sendOnboardingEvent('activate_saas_failed_1', {"payload":payload, "response": this.props.ultronOnboardingResponse,});
                let message = (this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message) || "An error occurred creating your Online Store. Try again."
                toast.warn(message)
            }
        }).catch((err) => {
            this.props.sendOnboardingEvent('activate_saas_failed_2', {"payload":payload, "response": this.props.ultronOnboardingResponse});
            this.setState({ showLoader: false})
            let message = (this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message) || "An error occurred creating your Online Store. Try again."
            toast.warn(message)
        })
    }

    activateMarketing = () => {
        this.props.verifyOwnershipThirdParty(this.props.merchantId,this.props.phoneNumber)
        .then(res => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                this.setState({ showLoader: false})
                this.props.sendOnboardingEvent('activate_marketing_success');
                // this.props.updateOnboardingStatus(this.props.merchantId,"COMLETE_BANK_DETAILS")
                this.props.handleNext();
                this.props.changePage("step3Start",2);
            }
            else{
                this.setState({ showLoader: false})
                this.props.sendOnboardingEvent('activate_marketing_failed_1', {"response": res[0]});
                toast.warn("Error in creating subscription please contact support")
            }
        })
        .catch((err) => {
            this.props.sendOnboardingEvent('activate_marketing_failed_2', {"response": err});
            this.setState({ showLoader: false})
            toast.warn("Error in creating subscription, try again later")
        })
    }

    walletOnboarding = (payload) => {
        if(this.props.status === "PENDING"){
            this.saveBankDetailsInCBS(payload);
            return;
        }
        this.props.walletOnboarding(payload)
        .then(res => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                this.props.sendOnboardingEvent('wallet_onboarding_success');
                if(this.props.commercials.includes("SAAS")){
                    this.props.updateOnboardingStatus(this.props.merchantId,"COMPLETE_TRAINING")
                    if(this.props.appInstall)
                        this.activateMarketing(); 
                    else
                        this.setState({
                            showLoader: false
                        })
                }
                else
                    this.activateSaas();
            }
            else{
                this.props.sendOnboardingEvent('wallet_onboarding_failed_1');
                this.setState({ showLoader: false})
                const errorMessage = (res && res.message) || "Error in transfer the money"
                toast.error(errorMessage)                            
            }
        })
        .catch((err) => {
            this.props.sendOnboardingEvent('wallet_onboarding_failed_2');
            this.setState({ showLoader: false})
            const errorMessage = (err && err.message) || "Error in redeeming the voucher, try again later"
            toast.error(errorMessage)
        })
    }

    saveBankDetailsInCBS = ({merchantId, accountNo, ifscCode, phoneNumber, upiId, accountName}) => {
        this.props.verifyOwnershipThroughBankDetails(merchantId, accountNo, ifscCode, phoneNumber, this.props.email, upiId, accountName, true)
        .then(res => {
            this.props.sendOnboardingEvent('cbs_save_bank_success');
            this.setState({
                showLoader: false
            })
            //this.props.updateOnboardingStatus(this.props.merchantId,"COMLETE_BANK_DETAILS")
            this.props.updateOnboardingStatus(this.props.merchantId,"COMPLETE_TRAINING")
            this.props.handleNext();
            this.props.changePage("step3Start",2);
        })
        .catch((err) => {
            this.props.sendOnboardingEvent('cbs_save_bank_failed_2');
            this.setState({ showLoader: false})
            const errorMessage = (err && err.message) || "Error in saving the bank details, try again later"
            toast.error(errorMessage)
        })
    }

    verifyOwnershipThroughBankDetails = () => {
        this.props.sendOnboardingEvent('bank_details_next_button_clicked')
        this.setState({ showLoader: true})
        if(this.state.accountNo !== this.state.accountNo1){
            toast.warn("Account number is not matching")
            this.setState({ showLoader: false})
            return;
        }
        
        const payload = {
            "accountNo": this.state.accountNo,
            "ifscCode": this.state.ifscCode,
            "accountName": this.state.accountName || "magicpin merchant",
            "upiId": this.state.upiId,
            "merchantId": this.props.merchantId,
            "merchantUserId": this.props.merchantUserId,
            "phoneNumber": this.props.phoneNumber,
        }
        if(this.state.upiId===""){
            this.props.sendOnboardingEvent('validate_bank_loading');
            this.props.verifyBank(payload)
            .then(res => {
                if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                    this.props.sendOnboardingEvent('validate_bank_success');
                    this.walletOnboarding(payload);
                }
                else{
                    this.setState({ showLoader: false, isAccountWrong: true, showAccountInvalid: true})
                    if(!this.state.isUpiWrong)
                    {
                        toast.warn("We are not able to validate the bank details. Please enter the UPI ID instead");
                    }else{
                        const errorMessage = (res && res.message) || "We are not able to validate the bank details"
                        toast.error(errorMessage)
                    }
                    
                }
            })
            .catch(err => {
                this.props.sendOnboardingEvent('validate_bank_failed');
                this.setState({ showLoader: false, isAccountWrong: true, showAccountInvalid: true})
                if(!this.state.isUpiWrong)
                {
                    toast.warn("We are not able to validate the bank details. Please enter the UPI ID instead");
                }else{
                    const errorMessage = (err && err.message) || "We are not able to validate the bank details, try again after sometime"
                    toast.error(errorMessage)
                }
            })
        }
        else{
            this.props.sendOnboardingEvent('validate_upi_loading');
            this.props.verifyUpi(payload)
            .then(res => {
                if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                    this.props.sendOnboardingEvent('validate_upi_success');
                    this.walletOnboarding(payload);
                }
                else{
                    this.props.sendOnboardingEvent('validate_upi_failed_1');
                    this.setState({ showLoader: false, isUpiWrong: true, showUpiInvalid: true})
                    if(!this.state.isAccountWrong)
                    {
                        toast.warn("We are not able to validate the UPI details. Please enter the Bank details instead");
                    }else{
                        const errorMessage = (res && res.message) || "We are not able to validate the UPI details"
                        toast.error(errorMessage)
                    }
                }
            })
            .catch(err => {
                this.props.sendOnboardingEvent('validate_upi_failed_2');
                this.setState({ showLoader: false, isUpiWrong: true, showUpiInvalid: true})
                if(!this.state.isAccountWrong)
                {
                    toast.warn("We are not able to validate the UPI details. Please enter the Bank details instead");
                }else{
                    const errorMessage = (err && err.message) || "We are not able to validate the UPI details"
                    toast.error(errorMessage)
                }
            })
        }
    }


    verifyOwnership = () => {
        this.props.sendOnboardingEvent('document_upload_next_button_clicked');
        if(this.state.numberType==="gstNumber" && this.state.gstNumber !== this.state.gstNumber2)
        {
            toast.error("GST numbers do not match");
            return;
        }
        else if(this.state.numberType==="panNumber" && this.state.panNumber !== this.state.panNumber2)
        {
            toast.error("PAN numbers do not match"); 
            return;
        }
        if(this.state.gstNumber || this.state.panNumber){
            this.setState({showLoader:true});
            this.sendGstOrPan();
        }else{
            toast.error("Enter the number");
        }
        
    }

    sendGstOrPan = () => {
        const gstAvailable = this.state.numberType==="gstNumber" ? true:false;
        const gstNumber = this.state.numberType==="gstNumber" ? this.state.gstNumber : "";
        const panNumber = this.state.numberType==="panNumber" ? this.state.panNumber :""; 
        this.props.sendGstOrPan(this.props.merchantId, gstNumber, gstAvailable, "regular", panNumber)
        .then((res) => {
            if(res && res.length && res[0] && res[0].status==="SUCCESS" && res[0].message==="GST api failed 401")
            {
                toast.error("Please enter the correct GST or PAN number");
                this.setState({showLoader:false});
            }
            else if(this.state.fileSelected)
            {
                this.verifyOwnershipManually(); 
            }
            else{
                toast.error("Upload the required document");
                this.setState({showLoader:false});
            }
        })
        .catch((err)=>{
            const errorMessage = (err && err.message) || "We could not validate details, check the number again."
            toast.error(errorMessage)
            this.setState({showLoader:false});
        })
    }

    verifyOwnershipManually = () => {
        this.props.sendOnboardingEvent('uploading_document_loading');
    
        this.props.verifyOwnershipManually(this.props.merchantId, [this.state.fileSelected]).then((res) => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === 'SUCCESS'){
                this.props.sendOnboardingEvent('uploading_document_success');
                this.props.changePage("bankDetails",1.5);
                this.setState({showLoader:false});
                this.props.getMerchantData();
                if(this.props.isStatusRejected)
                {
                    this.props. handleDocumentUploadNext();
                }
            }
            else{
                this.props.sendOnboardingEvent('uploading_document_failed_1');
                const errorMessage = (res && res.message) || "Error in uploading the document"
                toast.error(errorMessage)
                this.setState({showLoader:false});
            }
        })
        .catch(err => {
            this.props.sendOnboardingEvent('uploading_document_failed_2');
            const errorMessage = (err && err.message) || "Error in uploading the document, try again later"
            this.setState({showLoader:false});
            toast.error(errorMessage)
        });
    }

    handleFileInput = () => {
        var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
        if(uploadedFile.length > 0){
          this.setState({
            fileSelected: uploadedFile[0],
          })
        }
    }

    deleteFile = () => {
        var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
        if(uploadedFile.length > 0){
            uploadedFile[0].value = "";
            this.setState({
                fileSelected: "",
            })
        }
    }

    verifyBankDetailsManually = () => {
        if(!this.state.benificiaryName || !this.state.chequeSelected)
        {
            toast.error("Please enter all the fields");
            return;
        }
        this.setState({showLoader:true});
        this.props.sendOnboardingEvent('uploading_cheque_loading');
        
        this.props.verifyBankDetailsManually(this.props.merchantId, [this.state.chequeSelected], this.state.chequeSelected.name, this.state.benificiaryName).then((res) => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === 'SUCCESS'){
                this.props.sendOnboardingEvent('uploading_cheque_success');
                //this.props.updateOnboardingStatus(this.props.merchantId,"COMLETE_BANK_DETAILS")
                this.props.updateOnboardingStatus(this.props.merchantId,"COMPLETE_TRAINING")
                this.props.handleNext();
                this.props.changePage("step3Start",2);
                this.setState({showLoader:false});
            }
            else{
                this.props.sendOnboardingEvent('uploading_cheque_failed_1');
                const errorMessage = (res && res.message) || "Error in uploading the document"
                toast.error(errorMessage)
                this.setState({showLoader:false});
            }
        })
        .catch(err => {
            this.props.sendOnboardingEvent('uploading_cheque_failed_2');
            const errorMessage = (err && err.message) || "Error in uploading the document, try again later"
            toast.error(errorMessage)
            this.setState({showLoader:false});
        });
    }

    handleChequeInput = () => {
        var uploadedCheque = document.getElementById('cheque-input') ? document.getElementById('cheque-input').files:[];
        if(uploadedCheque.length > 0){
          this.setState({
            chequeSelected: uploadedCheque[0],
          })
        }
    }

    deleteCheque = () => {
        this.props.sendOnboardingEvent('cheque_delete_button_clicked');
        var uploadedCheque = document.getElementById('cheque-input') ? document.getElementById('cheque-input').files:[];
        if(uploadedCheque.length > 0){
            uploadedCheque[0].value = "";
            this.setState({
                chequeSelected: "",
            })
        }
    }

    onClickButton = (buttonType) => {
        
        if(buttonType==="step2StartNext")
        {
            this.props.sendOnboardingEvent('step2_start_next_button_clicked')
            if((this.props.status==="" && !this.props.thirdPartyMerchant)|| this.props.status==="REJECTED_FOR_INVALID_DOC")
            {
                this.props.changePage("documentsDetails",1.5);
            }
            else{
                this.props.changePage("bankDetails",1.5);  
            }
        }
        else if(buttonType==="bankDetailsBack")
        {
            this.props.sendOnboardingEvent('bank_details_back_button_clicked')
            if((this.props.status==="" && !this.props.thirdPartyMerchant) || this.props.status==="REJECTED_FOR_INVALID_DOC")
            {
                this.props.changePage("documentsDetails",1.5);
            }
            else{
                this.props.changePage("step2Start",1); 
            }
        }
    }


      certificateType = () => {
        let certificateType = null;
        switch(true) {
            case this.props.commissions && this.props.commissions.merchantCategory  && (this.props.commissions.merchantCategory === "Pharmacy" || this.props.commissions.merchantCategory === "Pharmacy Store"):
                certificateType = "Drugs License"
                break;
            case this.props.commissions && this.props.commissions.merchantCategory  && this.props.commissions.merchantCategory === "Food and Beverages":
                certificateType =  "FSAAI"
                break; 
            default :
            certificateType =  "GST/PAN" 
        }
        return certificateType;
      }

      handleNumberTypeChange = (e, type) => {
        this.setState({
          [type]: e.target.value,
          gstNumber:"",
          gstNumber2:"",
          panNumber:"",
          panNumber2:""
        }) 
      }
    render(){
        const isDetailsFilled = (this.state.accountNo && this.state.ifscCode &&this.state.accountNo1 && this.state.accountName) || (this.state.upiId);
        return(
            <div>
                
                <div style={{width: window.innerWidth < 768 ? "95%":"360px", display: "inline-block", paddingBottom:'30px'}}>
                    
                    {this.props.currPage === "step2Start" && 
                    <div style={{marginTop:'70px'}}>
                        <img className="onboarding-verification-image" src={DiyTick} />
                        <div className="onboarding-verification-congrats">
                            Congratulations!
                        </div>
                        <div className="onboarding-verification-message" style={{width:'240px', marginLeft:'54px'}}>
                            You have succesfully completed your sign-up
                        </div>
                        <button  disabled={!this.props.isOtpVerifiedCheck}className="large-btn" style={{marginTop: "60px", width:"90%"}} onClick = {() => {this.onClickButton("step2StartNext")}}> 
                       Step 2 - Upload Legal/Bank Details</button>
                    </div> }


                    { this.props.currPage === "documentsDetails" && 
                    <div>
                        <div className="onboarding-steps-heading-container">
                            <div style={{ fontSize:"20px"}}>Upload Legal Documents and Bank Details</div>
                            <div className="onboarding-steps-heading-details">Please follow the instructions carefully.</div>
                        </div>
                        <div style={{marginTop:'36px', textAlign:'left'}}>

                            <div style={{fontSize:'15px'}}>
                                Add the document details <span style={{color:'red'}}>*</span>
                            </div>
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="position" name="gstNumber" value={this.state.numberType} onChange={(e) => this.handleNumberTypeChange(e,'numberType')}>
                                        <FormControlLabel
                                            value={'gstNumber'}
                                            control={<Radio size="small"/>}
                                            label={<Typography style={{fontSize:'14px',letterSpacing:"0.6px"}}>GST Number</Typography>}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl> 
                               {this.state.numberType==="gstNumber" && <input value={this.state.gstNumber} onChange={(e) => this.setState({gstNumber: e.target.value})} pattern="[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}" className="onboarding-steps-bank-details-input" style={{marginTop:'0px', marginBottom:"10px"}} placeholder="Enter GST Number"/>}
                               {this.state.numberType==="gstNumber" && <input value={this.state.gstNumber2} onChange={(e) => this.setState({gstNumber2: e.target.value})} pattern="[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}" onPaste={(e)=> {e.preventDefault(); return false;}} className="onboarding-steps-bank-details-input" style={{marginTop:'0px', marginBottom:"10px"}} placeholder="Re-enter GST Number" />}
                            </div>

                            <div >
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="position" name="panNumber" value={this.state.numberType} onChange={(e) => this.handleNumberTypeChange(e,'numberType')}>
                                        <FormControlLabel
                                            value={'panNumber'}
                                            control={<Radio size="small"/>}
                                            label={<Typography style={{fontSize:'14px', letterSpacing:"0.6px"}}>PAN Number</Typography>}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl> 
                               {this.state.numberType==="panNumber" && <input value={this.state.panNumber} onChange={(e) => this.setState({panNumber: e.target.value})} pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" className="onboarding-steps-bank-details-input" style={{marginTop:'0px', marginBottom:"10px"}} placeholder="Enter PAN Number" />}
                               {this.state.numberType==="panNumber" && <input value={this.state.panNumber2} onChange={(e) => this.setState({panNumber2: e.target.value})} pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" className="onboarding-steps-bank-details-input" onPaste={(e)=> {e.preventDefault(); return false;}} style={{marginTop:'0px', marginBottom:"10px"}} placeholder="Re-enter PAN Number" />}
                            </div>  
                        </div> 

                       <div style={{marginTop:'36px'}}>
                            <div style={{textAlign:'left'}}>
                                Upload {this.certificateType()} Certificate <span style={{color:'red'}}>*</span>
                            </div>
                            
                            <div className="onboarding-steps-file-upload-container">
                                
                                <div className="onboarding-steps-file-upload-inside">
                                    <div className = "onboarding-steps-file-upload-text-area">
                                        {!this.state.fileSelected ? <label htmlFor={'file-input'} style={{width:"100%", cursor:"pointer"}}>Add File here </label> :<label>{this.state.fileSelected.name.length<25?this.state.fileSelected.name:this.state.fileSelected.name.slice(0,25)}</label>  }
                                    </div>
                                    <div>
                                    {!this.state.fileSelected ?
                                        <label   htmlFor={'file-input'}> <img className="onboarding-steps-file-upload-logo" src={UploadDiy} htmlFor={"file-input"}/> </label>
                                    :
                                        <img className="onboarding-steps-file-delete" src={closeIcon} onClick={this.deleteFile}/>
                                    }
                                    </div>
                                    <input
                                        ref='fileInput' 
                                        id="file-input" 
                                        type="file" 
                                        style={{display:'none'}}
                                        onChange={this.handleFileInput }
                                    />
                                </div>
                                
                            </div>
                        </div>
                         

                        <div className="onboarding-button-container" >
                            {!this.state.showLoader ?
                            <div>
                                <button  className="large-btn" style={{ width:"46%", float:'left', backgroundColor:"#FDE8F1", color:"#EF1C74", borderRadius:'4px'}}  onClick = {() =>{this.props.changePage("step2Start",1);this.props.sendOnboardingEvent('document_upload_back_button_clicked')}}> 
                                    Back
                                </button>

                                <button disabled={!this.state.fileSelected || (!this.state.gstNumber && !this.state.panNumber)} className="onboarding-next-button large-btn" 
                                style={{backgroundColor:this.state.fileSelected && (this.state.gstNumber || this.state.panNumber)?"":"#f7f7fa",
                                    color: this.state.fileSelected && (this.state.gstNumber || this.state.panNumber)? "":"rgba(0, 0, 0, 0.2" }} 
                                onClick = {() => {
                                    // this.props.changePage("bankDetails",1.5);
                                    this.verifyOwnership();
                                    }}> 
                                    Next
                                </button>
                            </div>
                            :
                            <button disabled={true} className="onboarding-processing-button large-btn"> PROCESSING....</button>  
                            }
                       </div>
                    </div> }


                    {this.props.currPage === "bankDetails" && 
                    <div>
                        { !(this.state.isAccountWrong && this.state.isUpiWrong) ?
                        <div>
                            <div className="onboarding-steps-heading-container">
                                <div style={{ fontSize:"20px"}}>Upload Legal Documents and Bank Details</div>
                                <div className="onboarding-steps-heading-details">Please follow the instructions carefully.</div>
                            </div> 
                            
                            <div style={{marginTop:'36px'}}>
                                
                                <div style={{textAlign:'left'}}>
                                    UPI ID <span style={{color:'red'}}>*</span>
                                </div>
                                <input className={ this.state.showUpiInvalid?"onboarding-steps-invalid-input":"onboarding-steps-bank-details-input"} type="text" placeholder="Enter UPI Id" value={this.state.upiId} onChange={e => this.changeBankDetails("upiId", e.target.value)} />
                                {this.state.showUpiInvalid && <p className="error-style">Invalid UPI</p>}
                            </div>

                            <div className="onboarding-steps-or-container">
                                <hr/>
                                <span>OR</span>
                                <hr/>
                            </div>

                            <div>
                                {this.state.showAccountInvalid && <p className="error-style">* Please check your account details carefully.</p>} 
                                <div style={{textAlign:'left'}}>
                                    Account Number <span style={{color:'red'}}>*</span>
                                </div>
                                <input type="text"  placeholder="Account number" value={this.state.accountNo} onChange={e => this.changeBankDetails("accountNo", e.target.value)} className={ this.state.showAccountInvalid?"onboarding-steps-invalid-input":"onboarding-steps-bank-details-input"} />
                            </div>

                            <div style={{marginTop:'16px'}}>
                                
                                <div style={{textAlign:'left'}}>
                                    Confirm Account Number <span style={{color:'red'}}>*</span>
                                </div>
                                <input type="text" placeholder="Confirm account number" value={this.state.accountNo1} onChange={e => this.changeBankDetails("accountNo1", e.target.value)} onPaste={(e)=> {e.preventDefault(); return false;}} className={ this.state.showAccountInvalid?"onboarding-steps-invalid-input":"onboarding-steps-bank-details-input"} />
                            </div>

                            <div style={{marginTop:'16px'}}>
                                
                                <div style={{textAlign:'left'}}>
                                    IFSC Code <span style={{color:'red'}}>*</span>
                                </div>
                                <input type="text"  placeholder="Ifsc code" value={this.state.ifscCode} onChange={e => this.changeBankDetails("ifscCode", e.target.value.toUpperCase())} className={ this.state.showAccountInvalid?"onboarding-steps-invalid-input":"onboarding-steps-bank-details-input"} />
                            </div>

                            <div style={{marginTop:'16px'}}>
                                
                                <div style={{textAlign:'left'}}>
                                    Account Holder's Name <span style={{color:'red'}}>*</span>
                                </div>
                                <input type="text"  placeholder="Account Holder's Name" value={this.state.accountName} onChange={e => this.changeBankDetails("accountName", e.target.value)} className={ this.state.showAccountInvalid?"onboarding-steps-invalid-input":"onboarding-steps-bank-details-input"} />
                            </div>

                            <div className="onboarding-button-container">
                                {!this.state.showLoader ?
                                <div>
                                    <button  className="onboarding-back-button large-btn" onClick = {() =>{this.onClickButton("bankDetailsBack")}}> 
                                        Back
                                    </button>

                                    <button  disabled={!isDetailsFilled} className="onboarding-next-button large-btn" style={{ backgroundColor: isDetailsFilled ? "":"#f7f7fa",color: isDetailsFilled ? "":"rgba(0, 0, 0, 0.2"  }} onClick = {() => {
                                        // this.props.changePage("step3Start",2);
                                        this.verifyOwnershipThroughBankDetails();
                                        }}> 
                                        Next
                                    </button>
                                </div>
                                :
                                 <button disabled={true} className="onboarding-processing-button large-btn"> PROCESSING....</button> 
                                 }
                             </div>
                        </div>
                        :
                        <div>
                            <div className="onboarding-steps-heading-container">
                                    <div style={{ fontSize:"20px"}}> Please upload the Cancelled Cheque / Passbook Front Page</div>
                                    {/* <div style={{fontSize:'14px', color:'#A0A0A0', marginTop:'5px'}}>Please follow the instructions carefully.</div> */}
                            </div>
                            <div style={{marginTop:'36px'}}>
                            
                            <div style={{textAlign:'left'}}>
                                Enter the Beneficiary Name<span style={{color:'red'}}>*</span>
                            </div>
                            <input type="text" onChange={(e) => this.setState({benificiaryName: e.target.value})} value = {this.state.benificiaryName} className="onboarding-steps-bank-details-input" />
                        </div> 
                            <div style={{marginTop:'36px'}}>
                                
                                <div style={{textAlign:'left'}}>
                                    Cancelled Cheque / Passbook Front Page  <span style={{color:'red'}}>*</span>
                                </div>
                                    
                                
                                <div className="onboarding-steps-file-upload-container">
                                        
                                    <div className="onboarding-steps-file-upload-inside">
                                        <div className = "onboarding-steps-file-upload-text-area">
                                            {!this.state.chequeSelected ? <label htmlFor={'cheque-input'} style={{width:"100%", cursor:"pointer"}} >Add File here </label> :<label>{this.state.chequeSelected.name.length<25?this.state.chequeSelected.name:this.state.chequeSelected.name.slice(0,25)}</label>  }
                                        </div>
                                        <div>
                                        {!this.state.chequeSelected ?
                                            <label htmlFor={'cheque-input'}> <img src={UploadDiy} htmlFor={"cheque-input"} className="onboarding-steps-file-upload-logo"/> </label>
                                        :
                                            <img src={closeIcon} className="onboarding-steps-file-delete" onClick={this.deleteCheque}/>
                                        }
                                        </div>
                                        <input
                                            ref='chequeInput' 
                                            id="cheque-input" 
                                            type="file" 
                                            style={{display:'none'}}
                                            onChange={this.handleChequeInput }
                                        />
                                    </div>      
                                </div>
                                <div className="onboarding-button-container">
                                    {!this.state.showLoader?
                                    <div>
                                        <button  className="onboarding-back-button large-btn" onClick = {() =>{this.setState({isAccountWrong:false, isUpiWrong:false});this.props.sendOnboardingEvent('cheque_upload_back_button_clicked')}}> 
                                            Back
                                        </button>

                                        <button  disabled={!this.state.chequeSelected || !this.state.benificiaryName} className="onboarding-next-button large-btn" style={{ backgroundColor: this.state.chequeSelected && this.state.benificiaryName ? "":"#f7f7fa",color: this.state.chequeSelected && this.state.benificiaryName ? "":"rgba(0, 0, 0, 0.2"  }} onClick = {() => {
                                            // this.props.changePage("step3Start",2);
                                            this.verifyBankDetailsManually();
                                            this.props.sendOnboardingEvent('cheque_upload_next_button_clicked')}}> 
                                            Next
                                        </button>
                                    </div>
                                    :
                                    <button disabled={true} className="onboarding-processing-button large-btn"> PROCESSING....</button>  
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                    }

                </div>
                
            </div>                    
        )
    }
})