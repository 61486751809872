// import Snackbar from "react-native-snackbar";

const UPDATE_ORDER_STATUS_LOADING = 'magicOrder/UPDATE_ORDER_STATUS_LOADING';
const UPDATE_ORDER_STATUS_SUCCESS = 'magicOrder/UPDATE_ORDER_STATUS_SUCCESS';
const UPDATE_ORDER_STATUS_FAILURE = 'magicOrder/UPDATE_ORDER_STATUS_FAILURE';

const GET_MERCHANT_ALL_CONTACTS_LOADING = 'magicOrder/GET_MERCHANT_ALL_CONTACTS_LOADING';
const GET_MERCHANT_ALL_CONTACTS_SUCCESS = 'magicOrder/GET_MERCHANT_ALL_CONTACTS_SUCCESS';
const GET_MERCHANT_ALL_CONTACTS_FAILURE = 'magicOrder/GET_MERCHANT_ALL_CONTACTS_FAILURE';

const SET_DELIVERY_PERSON_DETAILS_LOADING = 'magicOrder/SET_DELIVERY_PERSON_DETAILS_LOADING';
const SET_DELIVERY_PERSON_DETAILS_SUCCESS = 'magicOrder/SET_DELIVERY_PERSON_DETAILS_SUCCESS';
const SET_DELIVERY_PERSON_DETAILS_FAILURE = 'magicOrder/SET_DELIVERY_PERSON_DETAILS_FAILURE';

const ASSIGN_RIDER_LOADING = 'magicOrder/ASSIGN_RIDER_LOADING';
const ASSIGN_RIDER_SUCCESS = 'magicOrder/ASSIGN_RIDER_SUCCESS';
const ASSIGN_RIDER_FAILURE = 'magicOrder/ASSIGN_RIDER_FAILURE';

const UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_LOADING = 'magicOrder/UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_LOADING';
const UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_SUCCESS = 'magicOrder/UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_SUCCESS';
const UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_FAILURE = 'magicOrder/UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_FAILURE';

const DELAY_ORDER_LOADING = 'magicOrder/DELAY_ORDER_LOADING';
const DELAY_ORDER_SUCCESS = 'magicOrder/DELAY_ORDER_SUCCESS';
const DELAY_ORDER_FAILURE = 'magicOrder/DELAY_ORDER_FAILURE';

const DELIVERY_AND_COMMERCIAL_DETAILS_LOADING = "magicOrder/delivery_and_commercial_details_loading";
const DELIVERY_AND_COMMERCIAL_DETAILS_SUCCESS = "magicOrder/delivery_and_commercial_details_success";
const DELIVERY_AND_COMMERCIAL_DETAILS_FAILURE = "magicOrder/delivery_and_commercial_details_failure";

const SEND_OTP_WITH_MSG_LOADING = "magicOrder/send_otp_with_msg_loading";
const SEND_OTP_WITH_MSG_SUCCESS = "magicOrder/send_otp_with_msg_success";
const SEND_OTP_WITH_MSG_FAILURE = "magicOrder/send_otp_with_msg_failure";

const GET_STORE_MEMBERS_LOADING = "magicOrder/get_store_members_loading";
const GET_STORE_MEMBERS_SUCCESS = "magicOrder/get_store_members_success";
const GET_STORE_MEMBERS_FAILURE = "magicOrder/get_store_members_failure";

const VERIFY_OTP_ON_CALL_LOADING = "magicOrder/verify_otp_on_call_loading";
const VERIFY_OTP_ON_CALL_SUCCESS = "magicOrder/verify_otp_on_call_success";
const VERIFY_OTP_ON_CALL_FAILURE = "magicOrder/verify_otp_on_call_failure";

const UPDATE_COMMERCIAL_DETAILS_LOADING = "magicOrder/update_commercial_details_loading";
const UPDATE_COMMERCIAL_DETAILS_SUCCESS = "magicOrder/update_commercial_details_success";
const UPDATE_COMMERCIAL_DETAILS_FAILURE = "magicOrder/update_commercial_details_failure";

const SET_MERCHANT_UPDATING_PRICE_LOADING = "magicOrder/set_merchant_updating_price_loading";
const SET_MERCHANT_UPDATING_PRICE_SUCCESS = "magicOrder/set_merchant_updating_price_success";
const SET_MERCHANT_UPDATING_PRICE_FAILURE = "magicOrder/set_merchant_updating_price_failure";

const GET_BEYOND_FOOD_CATALOG_ITEMS_LOADING = "magicOrder/GET_BEYOND_FOOD_CATALOG_ITEMS_LOADING";
const GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS = "magicOrder/GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS";
const GET_BEYOND_FOOD_CATALOG_ITEMS_FAILURE = "magicOrder/GET_BEYOND_FOOD_CATALOG_ITEMS_FAILURE";

const GET_ITEMS_FROM_CATALOG_LOADING = "magicOrder/GET_ITEMS_FROM_CATALOG_LOADING";
const GET_ITEMS_FROM_CATALOG_SUCCESS = "magicOrder/GET_ITEMS_FROM_CATALOG_SUCCESS";
const GET_ITEMS_FROM_CATALOG_FAILURE = "magicOrder/GET_ITEMS_FROM_CATALOG_FAILURE";

const GET_STOCK_ITEMS_FROM_CATALOG_LOADING = "magicOrder/GET_STOCK_ITEMS_FROM_CATALOG_LOADING";
const GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS = "magicOrder/GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS";
const GET_STOCK_ITEMS_FROM_CATALOG_FAILURE = "magicOrder/GET_STOCK_ITEMS_FROM_CATALOG_FAILURE";

const SEARCH_ITEMS_CATALOG_LOADING = "magicOrder/SEARCH_ITEMS_LOADING";
const SEARCH_ITEMS_CATALOG_SUCCESS = "magicOrder/SEARCH_ITEMS_CATALOG_SUCCESS";
const SEARCH_ITEMS_CATALOG_FAILURE = "magicOrder/SEARCH_ITEMS_CATALOG_FAILURE";

const UPDATE_ITEM_PRICES_BULK_LOADING = "magicOrder/UPDATE_ITEM_PRICES_BULK_LOADING";
const UPDATE_ITEM_PRICES_BULK_SUCCESS = "magicOrder/UPDATE_ITEM_PRICES_BULK_SUCCESS";
const UPDATE_ITEM_PRICES_BULK_FAILURE = "magicOrder/UPDATE_ITEM_PRICES_BULK_FAILURE";

const REQUEST_REFUND_LOADING = "magicOrder/REQUEST_REFUND_LOADING";
const REQUEST_REFUND_SUCCESS = "magicOrder/REQUEST_REFUND_SUCCESS";
const REQUEST_REFUND_FAILURE = "magicOrder/REQUEST_REFUND_FAILURE";

const VALIDATE_END_TIMINGS_LOADING = "magicOrder/VALIDATE_END_TIMINGS_LOADING";
const VALIDATE_END_TIMINGS_SUCCESS = "magicOrder/VALIDATE_END_TIMINGS_SUCCESS";
const VALIDATE_END_TIMINGS_FAILURE = "magicOrder/VALIDATE_END_TIMINGS_FAILURE";

const initialState = {
    loading: false,
    loaded: false,
    data : [],
    webSocket: null
}

export default function magicOrder(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_ORDER_STATUS_LOADING:
            return{
               ...state,
               updateOrdersStatusLoading: true
            }
          case UPDATE_ORDER_STATUS_SUCCESS:
            return{
              ...state,
              updateOrdersStatusLoading: false,
              updateOrdersStatusData: action.result,
              updateOrdersStatusError: null
            }
          case UPDATE_ORDER_STATUS_FAILURE:
            return{
              ...state,
              updateOrdersStatusLoading: false,
              updateOrdersStatusData: null,
              updateOrdersStatusError: action.error
            }

          case GET_MERCHANT_ALL_CONTACTS_LOADING:
            return{
               ...state,
               merchantAllContactsLoading: true
            }
          case GET_MERCHANT_ALL_CONTACTS_SUCCESS:
            return{
              ...state,
              merchantAllContactsLoading: false,
              merchantAllContactsData: action.result,
              merchantAllContactsError: null
            }
          case GET_MERCHANT_ALL_CONTACTS_FAILURE:
            return{
              ...state,
              merchantAllContactsLoading: false,
              merchantAllContactsData: null,
              merchantAllContactsError: action.error
            }

          case SET_DELIVERY_PERSON_DETAILS_LOADING:
            return{
               ...state,
               addMerchantContactLoading: true
            }
          case SET_DELIVERY_PERSON_DETAILS_SUCCESS:
            return{
              ...state,
              addMerchantContactLoading: false,
              addMerchantContactData: action.result,
              addMerchantContactError: null
            }
          case SET_DELIVERY_PERSON_DETAILS_FAILURE:
            return{
              ...state,
              addMerchantContactLoading: false,
              addMerchantContactData: null,
              addMerchantContactError: action.error
            }
            case ASSIGN_RIDER_LOADING:
            return{
               ...state,
               assignRiderLoading: true
            }
          case ASSIGN_RIDER_SUCCESS:
            return{
              ...state,
              assignRiderLoading: false,
              assignRiderData: action.result,
              assignRiderError: null
            }
          case ASSIGN_RIDER_FAILURE:
            return{
              ...state,
              assignRiderLoading: false,
              assignRiderData: null,
              assignRiderError: action.error
            }
          case UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_LOADING:
            return{
               ...state,
               updateMerchantDeliveryAndTimingsLoading: true
            }
          case UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_SUCCESS:
            return{
              ...state,
              updateMerchantDeliveryAndTimingsLoading: false,
              updateMerchantDeliveryAndTimingsData: action.result,
              updateMerchantDeliveryAndTimingsError: null
            }
          case UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_FAILURE:
            return{
              ...state,
              updateMerchantDeliveryAndTimingsLoading: false,
              updateMerchantDeliveryAndTimingsData: null,
              updateMerchantDeliveryAndTimingsError: action.error
            }
        case DELAY_ORDER_LOADING:
            return {
              ...state,
              delayOrderLoading: true,
            }
        case DELAY_ORDER_SUCCESS:
            let res = !!action.result && typeof action.result == "string" ?
              JSON.parse(action.result || "{}") :
              action.result || {};

            if (res.status != "SUCCESS") {
              return {
                ...state,
                delayOrderLoading: false,
                delayOrderData: null,
                delayOrderError: res
              }
            }

            return {
              ...state,
              delayOrderLoading: false,
              delayOrderData: res,
              delayOrderError: null
            }
        case DELAY_ORDER_FAILURE:
            return {
              ...state,
              delayOrderLoading: false,
              delayOrderData: null,
              delayOrderError: action.error
            }
      case DELIVERY_AND_COMMERCIAL_DETAILS_LOADING:
        return {
          ...state,
          deliveryAndCommercialsLoading: true,
        }
      case DELIVERY_AND_COMMERCIAL_DETAILS_SUCCESS:
         res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          deliveryAndCommercialsLoading: false,
          deliveryAndCommercialsData: res,
          deliveryAndCommercialsError: false
        }
      case DELIVERY_AND_COMMERCIAL_DETAILS_FAILURE:
        return {
          ...state,
          deliveryAndCommercialsLoading: false,
          deliveryAndCommercialsData: null,
          deliveryAndCommercialsError: action.error
        }
      case SEND_OTP_WITH_MSG_LOADING:
        return {
          ...state,
          sendOtpWithMsgLoading: true,
        }
      case SEND_OTP_WITH_MSG_SUCCESS:
        res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          sendOtpWithMsgLoading: false,
          sendOtpWithMsgData: res,
          sendOtpWithMsgFailure: false
        }
      case SEND_OTP_WITH_MSG_FAILURE:
        return {
          ...state,
          sendOtpWithMsgLoading: false,
          sendOtpWithMsgData: null,
          sendOtpWithMsgFailure: action.error
        }
      case GET_STORE_MEMBERS_LOADING:
        return {
          ...state,
          getStoreMembersLoading: true,
        }
      case GET_STORE_MEMBERS_SUCCESS:
        res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          getStoreMembersLoading: false,
          getStoreMembersData: res,
          getStoreMembersFailure: false
        }
      case GET_STORE_MEMBERS_FAILURE:
        return {
          ...state,
          getStoreMembersLoading: false,
          getStoreMembersData: null,
          getStoreMembersFailure: action.error
        }
      case VERIFY_OTP_ON_CALL_LOADING:
        return {
          ...state,
          verifyOtpOnCallLoading: true,
        }
      case VERIFY_OTP_ON_CALL_SUCCESS:
        res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          verifyOtpOnCallLoading: false,
          verifyOtpOnCallData: res,
          verifyOtpOnCallFailure: false
        }
      case VERIFY_OTP_ON_CALL_FAILURE:
        // Snackbar.show({
        //   title: "Incorrect OTP",
        //   duration: Snackbar.LENGTH_LONG,
        // })
        return {
          ...state,
          verifyOtpOnCallLoading: false,
          verifyOtpOnCallData: null,
          verifyOtpOnCallFailure: action.error
        }
      case UPDATE_COMMERCIAL_DETAILS_LOADING:
        return {
          ...state,
          updateCommercialDetailsLoading: true,
        }
      case UPDATE_COMMERCIAL_DETAILS_SUCCESS:
        res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        if (res.status === "FAILURE") {
          return {
            ...state,
            updateCommercialDetailsLoading: false,
            updateCommercialDetailsData: null,
            updateCommercialDetailsFailure: true
          }
        }
        return {
          ...state,
          updateCommercialDetailsLoading: false,
          updateCommercialDetailsData: res,
          updateCommercialDetailsFailure: false
        }
      case UPDATE_COMMERCIAL_DETAILS_FAILURE:
        return {
          ...state,
          updateCommercialDetailsLoading: false,
          updateCommercialDetailsData: null,
          updateCommercialDetailsFailure: action.error
        }
      case SET_MERCHANT_UPDATING_PRICE_LOADING:
        return {
          ...state,
          setMerchantUpdatingPriceLoading: true,
          setMerchantUpdatingPriceData: null,
          setMerchantUpdatingPriceFailure: false
        }
      case SET_MERCHANT_UPDATING_PRICE_SUCCESS:
        res = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          setMerchantUpdatingPriceLoading: false,
          setMerchantUpdatingPriceData: res,
          setMerchantUpdatingPriceFailure: false
        }
      case SET_MERCHANT_UPDATING_PRICE_FAILURE:
        return {
          ...state,
          setMerchantUpdatingPriceLoading: false,
          setMerchantUpdatingPriceData: null,
          setMerchantUpdatingPriceFailure: action.error
        }
        case GET_BEYOND_FOOD_CATALOG_ITEMS_LOADING:
        return {
          ...state,
          getBeyondFoodCatalogItemsLoading: true,
          getBeyondFoodCatalogItemsData: null,
          getBeyondFoodCatalogItemsFailure: false
        }
      case GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS:
        let GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ?
          JSON.parse(action.result || "{}") :
          action.result || {};
        return {
          ...state,
          getBeyondFoodCatalogItemsLoading: false,
          getBeyondFoodCatalogItemsData: GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS_RESULT,
          getBeyondFoodCatalogItemsFailure: false
        }
      case GET_BEYOND_FOOD_CATALOG_ITEMS_FAILURE:
        return {
          ...state,
          getBeyondFoodCatalogItemsLoading: false,
          getBeyondFoodCatalogItemsData: null,
          getBeyondFoodCatalogItemsFailure: action.error
        }
        case GET_ITEMS_FROM_CATALOG_LOADING:
          return {
            ...state,
            getItemsFromCatalogLoading: true,
            getItemsFromCatalogData: null,
            getItemsFromCatalogFailure: false
          }
        case GET_ITEMS_FROM_CATALOG_SUCCESS:
          let GET_ITEMS_FROM_CATALOG_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ?
            JSON.parse(action.result || "{}") :
            action.result || {};
          return {
            ...state,
            getItemsFromCatalogLoading: false,
            getItemsFromCatalogData: GET_ITEMS_FROM_CATALOG_SUCCESS_RESULT,
            getItemsFromCatalogFailure: false
          }
        case GET_ITEMS_FROM_CATALOG_FAILURE:
          return {
            ...state,
            getItemsFromCatalogLoading: false,
            getItemsFromCatalogData: null,
            getItemsFromCatalogFailure: action.error
          }
          case GET_STOCK_ITEMS_FROM_CATALOG_LOADING:
            return {
              ...state,
              getStockItemsFromCatalogLoading: true,
              getStockItemsFromCatalogData: null,
              getStockItemsFromCatalogFailure: false
            }
          case GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS:
            let GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ?
              JSON.parse(action.result || "{}") :
              action.result || {};
            return {
              ...state,
              getStockItemsFromCatalogLoading: false,
              getStockItemsFromCatalogData: GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS_RESULT,
              getStockItemsFromCatalogFailure: false
            }
          case GET_STOCK_ITEMS_FROM_CATALOG_FAILURE:
            return {
              ...state,
              getStockItemsFromCatalogLoading: false,
              getStockItemsFromCatalogData: null,
              getStockItemsFromCatalogFailure: action.error
            }
          case SEARCH_ITEMS_CATALOG_LOADING:
            return {
              ...state,
              searchItemsCatalogLoading: true,
              searchItemsCatalogData: null,
              searchItemsCatalogFailure: false
            }
          case SEARCH_ITEMS_CATALOG_SUCCESS:
            let SEARCH_ITEMS_CATALOG_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ?
              JSON.parse(action.result || "{}") :
              action.result || {};
            return {
              ...state,
              searchItemsCatalogLoading: false,
              searchItemsCatalogData: SEARCH_ITEMS_CATALOG_SUCCESS_RESULT,
              searchItemsCatalogFailure: false
            }
          case SEARCH_ITEMS_CATALOG_FAILURE:
            return {
              ...state,
              searchItemsCatalogLoading: false,
              searchItemsCatalogData: null,
              searchItemsCatalogFailure: action.error
            }
            case UPDATE_ITEM_PRICES_BULK_LOADING:
              return {
                ...state,
                updateItemPricesBulkLoading: true,
                updateItemPricesBulkData: null,
                updateItemPricesBulkFailure: false
              }
            case UPDATE_ITEM_PRICES_BULK_SUCCESS:
              let UPDATE_ITEM_PRICES_BULK_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ?
                JSON.parse(action.result || "{}") :
                action.result || {};
              return {
                ...state,
                updateItemPricesBulkLoading: false,
                updateItemPricesBulkData: UPDATE_ITEM_PRICES_BULK_SUCCESS_RESULT,
                updateItemPricesBulkFailure: false
              }
            case UPDATE_ITEM_PRICES_BULK_FAILURE:
              return {
                ...state,
                updateItemPricesBulkLoading: false,
                updateItemPricesBulkData: null,
                updateItemPricesBulkFailure: action.error
              }
              case REQUEST_REFUND_LOADING:
                return{
                  ...state,
                  requestRefundLoading: true,
                  requestRefundData: null,
                  requestRefundFailure: false
                }
              case REQUEST_REFUND_SUCCESS:
                let REQUEST_REFUND_SUCCESS_RESULT = !!action.result && typeof action.result == "string" ? JSON.parse(action.result || "{}") : action.result || {};
                //let REQUEST_REFUND_SUCCESS_RESULT = action.result;
                return{
                  ...state,
                  requestRefundLoading: false,
                  requestRefundData: REQUEST_REFUND_SUCCESS_RESULT,
                  requestRefundFailure: false                  
                }
                case REQUEST_REFUND_FAILURE:
                  return{
                    ...state,
                    requestRefundLoading: false,
                    requestRefundData: null,
                    requestRefundFailure: action.error                    
                }
                
        case VALIDATE_END_TIMINGS_LOADING:

          return {
            ...state,
            validateEndTimingsLoading: true,
          }

        case VALIDATE_END_TIMINGS_SUCCESS:

          let validateEndTimingsRes = !!action.result && typeof action.result == "string" ?
                                      JSON.parse(action.result || "{}") :
                                      action.result || {};

          if (validateEndTimingsRes && validateEndTimingsRes.status === "FAILURE") {
            return {
              ...state,
              validateEndTimingsLoading: false,
              validateEndTimingsData: null,
              validateEndTimingsFailure: true
            }
          }
          return {
            ...state,
            validateEndTimingsLoading: false,
            validateEndTimingsData: validateEndTimingsRes,
            validateEndTimingsFailure: false
          }

        case VALIDATE_END_TIMINGS_FAILURE:
          return {
            ...state,
            validateEndTimingsLoading: false,
            validateEndTimingsData: null,
            validateEndTimingsFailure: action.error
          }

        default:
            return state;
    }
  }

export function updateOrderStatusCommon(payload, user_id, access_token, headers={}){
  return {
    types: [
      UPDATE_ORDER_STATUS_LOADING,
      UPDATE_ORDER_STATUS_SUCCESS,
      UPDATE_ORDER_STATUS_FAILURE,
    ],
    promise: (client) =>
        client.webapi_https.post("loms/updateOrderStatusByMerchant", {
        // client.ultrontest.post("loms/test/updateOrderStatusByMerchant", {
        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function getMerchantAllContacts(payload){
  return {
    types: [
      GET_MERCHANT_ALL_CONTACTS_LOADING,
      GET_MERCHANT_ALL_CONTACTS_SUCCESS,
      GET_MERCHANT_ALL_CONTACTS_FAILURE,
    ],
    promise: (client) =>
        // client.sales.post("api/getAllMerchantContacts", {
        client.magicpin_in.post("api/getAllMerchantContacts", {
        data: payload,
      }),
  };
}

export function addOrUpdateDeliveyPerson(payload){
  return {
    types: [
      SET_DELIVERY_PERSON_DETAILS_LOADING,
      SET_DELIVERY_PERSON_DETAILS_SUCCESS,
      SET_DELIVERY_PERSON_DETAILS_FAILURE,
    ],
    promise: (client) =>
        // client.sales.post("api/addOrUpdateDeliveryPersonDetails", {
        client.magicpin_in.post("api/addOrUpdateDeliveryPersonDetails", {
        data: payload,
      }),
  };
}

export function assignRiderForSelfDelivery(orderId, user_id, access_token, headers={}){
  let payload = {"orderId": orderId}
  return {
    types: [
      ASSIGN_RIDER_LOADING,
      ASSIGN_RIDER_SUCCESS,
      ASSIGN_RIDER_FAILURE,
    ],
    promise: (client) =>
        client.webapi_https.post("loms/assignOrderRiderMerchant", {
        // client.ultrontest.post("loms/test/assignOrderRiderMerchant", {
        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function updateMerchantDeliveryAndTimings(user_id, access_token, payload, headers={}) {
  return {
    types: [
      UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_LOADING,
      UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_SUCCESS,
      UPDATE_MERCHANT_DELIVERY_AND_TIMINGS_FAILURE,
    ],
    promise: (client) =>
        client.ultron_mp.post("merchant/updateMerchantDeliveryAndTimings", {

        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function delayOrder(order_id, timeInSeconds, user_id, access_token, headers={}) {
  return {
    types: [
      DELAY_ORDER_LOADING, DELAY_ORDER_SUCCESS, DELAY_ORDER_FAILURE
    ],
    promise: (client) =>
      client.webapi_https.post("loms/delayOrder", {
        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: {
          orderId: order_id,
          orderDelayedBy: timeInSeconds
        }
      })
  }
}

export function getDeliveryAndCommercialDetails(merchantId, userId, access_token) {
  return {
    types: [
      DELIVERY_AND_COMMERCIAL_DETAILS_LOADING,
      DELIVERY_AND_COMMERCIAL_DETAILS_SUCCESS,
      DELIVERY_AND_COMMERCIAL_DETAILS_FAILURE
    ],
    promise: (client) =>
      client.webapi_https.get("/ultron-onboarding/merchant/getDeliveryAndCommercialDetails/"+ merchantId, {
        headers: {
          "auth-user-token": access_token,
          "auth-userid": userId,
          "Content-Type": "application/json",
        },
      })
  }
}

export function sendOtpWithMsg(phone,otpUseCaseIdentifier, bigToken) {
  return {
    types: [
      SEND_OTP_WITH_MSG_LOADING,
      SEND_OTP_WITH_MSG_SUCCESS,
      SEND_OTP_WITH_MSG_FAILURE
    ],
    promise: (client) =>
      client.magicpin_in.post("/api/sendOtpWithMsg", {
        data: {
          sendSixDigitOtp: true,
          accessToken: bigToken,
          phone,
          otpUseCaseIdentifier
        }
      })
  }
}

export function getStoreMembers(wantAllChildContact, bigToken) {
  return {
    types: [
      GET_STORE_MEMBERS_LOADING,
      GET_STORE_MEMBERS_SUCCESS,
      GET_STORE_MEMBERS_FAILURE
    ],
    promise: (client) =>
      client.magicpin_in.post("/api/getMembers", {
        data: {
          accessToken: bigToken,
          wantAllChildContact
        }
      })
  }
}

export function verifyOtpOnCall(phone,otp, bigToken) {
  return {
    types: [
      VERIFY_OTP_ON_CALL_LOADING,
      VERIFY_OTP_ON_CALL_SUCCESS,
      VERIFY_OTP_ON_CALL_FAILURE
    ],
    promise: (client) =>
      client.magicpin_in.post("/api/verifyOtpOnCall", {
        data: {
          accessToken: bigToken,
          otp,
          phone
        }
      })
  }
}

export function updateCommercialDetails(payload, user_id, access_token, source) {
  return {
    types: [
      UPDATE_COMMERCIAL_DETAILS_LOADING,
      UPDATE_COMMERCIAL_DETAILS_SUCCESS,
      UPDATE_COMMERCIAL_DETAILS_FAILURE
    ],
    promise: (client) =>
      client.webapi_https.post("/ultron-onboarding/merchant/relistOnToggle", {
        headers: {
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: {
          ...payload,
          source: source,
          reason : "toggle"
        }
      })
  }
}

export function setMerchantUpdatingPricing(order_id, isMerchantUpdatingPrice, user_id, access_token, headers={}) {
  return {
    types: [
      SET_MERCHANT_UPDATING_PRICE_LOADING, SET_MERCHANT_UPDATING_PRICE_SUCCESS, SET_MERCHANT_UPDATING_PRICE_FAILURE
    ],
    promise: (client) =>
      client.webapi_https.post("loms/setMerchantUpdatingPrice", {
        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: {
          orderId: order_id,
          isMerchantUpdatingPrice: isMerchantUpdatingPrice
        }
      })
  }
}

export function getBeyondFoodCatalogItems(access_token, user_id, headers={}) {
  const action = {
    method: "GET",
    apiName: "GET_CATEGORIES",
    // apiUrl: `http://search5.magicpin.com:8081/${user_id}/catalog/categories`
    apiUrl: `http://catalog-search.service/gateway/${user_id}/categories`
  }
  
  return {
    types: [
      GET_BEYOND_FOOD_CATALOG_ITEMS_LOADING, GET_BEYOND_FOOD_CATALOG_ITEMS_SUCCESS, GET_BEYOND_FOOD_CATALOG_ITEMS_FAILURE
    ],
    promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
      headers: {
        ...headers,
        "auth-user-token": access_token,
        "auth-userid": user_id,
        "Content-Type": "application/json"
      },
      data: action
    })
  }
}

export function getItemsFromCatalog(access_token, user_id, category_id, param, headers={}){
  let offset = param ? param : 0;
  // let api_url = `http://search5.magicpin.com:8081/${user_id}/catalog/items?categoryId=${category_id}`
  let api_url = `http://catalog-search.service/gateway/${user_id}/items?categoryId=${category_id}`
  const action = {
    method: 'GET',
    apiName: "GET_CATEGORY_ITEMS",
    apiUrl: `${api_url}&offset=${offset}&limit=20`
  };
  return {
    types: [GET_ITEMS_FROM_CATALOG_LOADING, GET_ITEMS_FROM_CATALOG_SUCCESS, GET_ITEMS_FROM_CATALOG_FAILURE],
    promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
      headers: {
        ...headers,
        "auth-user-token": access_token,
        "auth-userid": user_id,
        "Content-Type": "application/json"
      },
      data: action
    })
  }
}

export function getOutOfStockItemsFromCatalog(access_token, user_id, param, headers={}){
  let offset = param ? param : 0;
  const action = {
    method: 'GET',
    apiName: "GET_OUT_OF_STOCK_ITEMS",
    // apiUrl: `http://search5.magicpin.com:8081/${user_id}/catalog/items?in_stock=false&offset=${offset}&limit=50`
    apiUrl: `http://catalog-search.service/gateway/${user_id}/items?in_stock=false&offset=${offset}&limit=50`
  };
  return {
    types: [GET_STOCK_ITEMS_FROM_CATALOG_LOADING, GET_STOCK_ITEMS_FROM_CATALOG_SUCCESS, GET_STOCK_ITEMS_FROM_CATALOG_FAILURE],
    promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
      headers: {
        ...headers,
        "auth-user-token": access_token,
        "auth-userid": user_id,
        "Content-Type": "application/json"
      },
      data: action
    })
  }
}

export function searchCatalogItems(access_token, user_id, query, headers={}) {
  query = encodeURIComponent(query);
  const action = {
    method: 'GET',
    apiName: "GET_SEARCH_RESULT",
    // apiUrl: `http://search5.magicpin.com:8081/${user_id}/catalog/items/search?q=${query}`
    apiUrl: `http://catalog-search.service/gateway/${user_id}/items/search?q=${query}`
  };

  return {
    types: [
      SEARCH_ITEMS_CATALOG_LOADING, SEARCH_ITEMS_CATALOG_SUCCESS, SEARCH_ITEMS_CATALOG_FAILURE
    ],
    promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
      headers: {
        ...headers,
        "auth-user-token": access_token,
        "auth-userid": user_id,
        "Content-Type": "application/json"
      },
      data: action
    })
  }
}

export function updateItemPricesBulk(access_token, user_id, payload, headers={}, src="ORDERHERE") {
  if(payload && payload.length>0) {
    payload.map(el => {
      if(el.magicpinSku && el.storeCode) {
        el.magicpinSku = el.magicpinSku.toString();
        el.storeCode = el.storeCode.toString();
        el.mrp = el.sellingPrice
      }
    })
  }
  const action = {
    method: 'POST',
    apiName: "UPDATE_ITEMS_BULK",
    // apiUrl: `http://search5.magicpin.com:8081/catalog/bulkInventoryPrice`,
    apiUrl: `http://catalog-search.service/gateway/bulkInventoryPrice?src=${src}`,
    headers: {authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJsaWNpb3VzIiwiaWF0IjoxNjMxNjA5OTYwfQ.KI5HF3mUvYRY7c6Jd-r4UXWgSztLozoeAVaPsd3oTlc"},
    payload: [...payload]
  };
  return {
    types: [
      UPDATE_ITEM_PRICES_BULK_LOADING, UPDATE_ITEM_PRICES_BULK_SUCCESS, UPDATE_ITEM_PRICES_BULK_FAILURE
    ],
    promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
      headers: {
        ...headers,
        "auth-user-token": access_token,
        "auth-userid": user_id,
        "Content-Type": "application/json"
      },
      data: action
    })
  }
}

export function requestOrderRefund(payload, user_id, access_token, headers={}){
  return {
    types: [
      REQUEST_REFUND_LOADING,
      REQUEST_REFUND_SUCCESS,
      REQUEST_REFUND_FAILURE,
    ],
    promise: (client) =>
        client.webapi_https.post("loms/refundOrder", {
        headers: {
          ...headers,
          "auth-user-token": access_token,
          "auth-userid": user_id,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function validateEndTimings(payload = {}) {
  return {
    types: [
      VALIDATE_END_TIMINGS_LOADING,
      VALIDATE_END_TIMINGS_SUCCESS,
      VALIDATE_END_TIMINGS_FAILURE
    ],
    promise: (client) =>
      client.webapi_https.post("/ultron-onboarding/merchant/validateEndTimings", {
        headers: {
          "auth-user-token": payload.access_token,
          "auth-userid": payload.user_id,
          "Content-Type": "application/json",
        },
        data: {
          ...payload
        }
      })
  }
}