export function IsPharmaMerchant(merchantId) {
    // const listOfPharmaMerchantIds = [1301645, 52940]

    const listOfPharmaMerchantIds = [1301645,
        263552,
        266247,
        266322,
        267971,
        270580,
        270604,
        326976,
        355190,
        355248,
        355257,
        355263,
        355267,
        355284,
        355311,
        355338,
        355362,
        355468,
        355500,
        355565,
        355627,
        355638,
        355641,
        355664,
        355696,
        355704,
        355717,
        355740,
        355781,
        355799,
        355832,
        355859,
        355986,
        356060,
        356062,
        356063,
        356064,
        356065,
        356071,
        356080,
        356081,
        356122,
        356220,
        356234,
        356238,
        356282,
        356288,
        356292,
        356297,
        356325,
        356355,
        356391,
        356422,
        356477,
        356483,
        356525,
        356575,
        356591,
        356618,
        356626,
        356650,
        356700,
        356736,
        356783,
        356786,
        356787,
        356791,
        356824,
        356827,
        356966,
        356973,
        356996,
        357028,
        357117,
        357158,
        357179,
        357231,
        357234,
        357242,
        357259,
        357288,
        357384,
        357398,
        357400,
        357419,
        357461,
        357481,
        357500,
        357504,
        357513,
        357535,
        357552,
        357568,
        357572,
        357671,
        357708,
        357728,
        357738,
        357742,
        357744,
        357746,
        357754,
        357758,
        357787,
        357807,
        357842,
        357862,
        357907,
        357931,
        357933,
        357940,
        357955,
        358001,
        358020,
        358066,
        358074,
        358092,
        358135,
        358139,
        358161,
        358165,
        358193,
        358211,
        358228,
        358280,
        358361,
        358387,
        358389,
        358446,
        358553,
        358554,
        358567,
        358585,
        358605,
        358623,
        358630,
        358653,
        358663,
        358673,
        358748,
        358787,
        358813,
        358828,
        358871,
        358873,
        358892,
        358908,
        358938,
        358952,
        358954,
        358979,
        359024,
        359128,
        359150,
        359175,
        359190,
        359213,
        359239,
        359243,
        359364,
        359370,
        359418,
        359433,
        359435,
        359436,
        359438,
        359440,
        359465,
        359540,
        359550,
        359573,
        359576,
        359597,
        359610,
        359620,
        359621,
        359632,
        359719,
        359756,
        359762,
        359781,
        359803,
        359823,
        359894,
        359919,
        359922,
        359933,
        359936,
        359943,
        359957,
        359966,
        359977,
        359993,
        360015,
        360023,
        360044,
        360061,
        360062,
        360111,
        360119,
        360123,
        360133,
        360137,
        360164,
        360165,
        360185,
        360199,
        360215,
        360216,
        360220,
        360222,
        360234,
        360246,
        360283,
        360292,
        360293,
        360329,
        360430,
        360439,
        360455,
        360474,
        360521,
        360541,
        360542,
        360559,
        360632,
        360657,
        360702,
        360754,
        360759,
        360778,
        360782,
        360787,
        360794,
        360812,
        360844,
        360847,
        360911,
        360919,
        360927,
        360929,
        360938,
        360940,
        360944,
        360954,
        360984,
        361005,
        361036,
        361066,
        361073,
        361074,
        361133,
        361150,
        361179,
        361180,
        361186,
        361190,
        361197,
        361213,
        361217,
        361267,
        361272,
        361355,
        361363,
        361371,
        361374,
        361375,
        361380,
        361405,
        361435,
        361439,
        361485,
        361491,
        361510,
        361515,
        361519,
        361529,
        361564,
        361574,
        361583,
        361590,
        361596,
        361603,
        361629,
        361635,
        361646,
        361656,
        361691,
        361696,
        361700,
        361706,
        361726,
        361737,
        361747,
        361751,
        361872,
        361883,
        361907,
        361934,
        361940,
        361942,
        361956,
        361982,
        361989,
        362018,
        362044,
        362093,
        362140,
        362164,
        362170,
        362184,
        362195,
        362197,
        362218,
        362226,
        362234,
        362287,
        362290,
        362307,
        362310,
        362314,
        362334,
        362355,
        362363,
        362367,
        362391,
        362422,
        362426,
        362476,
        362511,
        362514,
        362547,
        362559,
        362616,
        362661,
        362682,
        362693,
        362698,
        362703,
        362715,
        362732,
        362795,
        362797,
        362805,
        362814,
        362815,
        362827,
        362844,
        362845,
        362850,
        362867,
        362881,
        362882,
        362913,
        362916,
        362942,
        362943,
        362963,
        362969,
        362972,
        362974,
        362996,
        363004,
        363014,
        363068,
        363090,
        363109,
        363110,
        363112,
        363152,
        363166,
        363221,
        363226,
        363238,
        363240,
        363242,
        363245,
        363256,
        363337,
        363342,
        363370,
        363391,
        363413,
        363420,
        363424,
        363441,
        363443,
        363501,
        363527,
        363538,
        363587,
        363589,
        363590,
        363597,
        363609,
        363685,
        363686,
        363688,
        363692,
        363717,
        363726,
        363762,
        363767,
        363769,
        363791,
        363800,
        363802,
        363826,
        363840,
        363849,
        363851,
        363857,
        363860,
        363872,
        363883,
        363891,
        363899,
        363911,
        363953,
        363955,
        363969,
        363975,
        364053,
        364099,
        364105,
        364111,
        364114,
        364151,
        364155,
        364166,
        364172,
        364182,
        364204,
        364228,
        364232,
        364235,
        364246,
        364259,
        364284,
        364292,
        364329,
        364346,
        364359,
        364417,
        364427,
        364469,
        364487,
        364490,
        364514,
        364535,
        364544,
        364554,
        364563,
        364576,
        364604,
        364638,
        364640,
        364641,
        364645,
        364670,
        364684,
        364713,
        364731,
        364735,
        364755,
        364774,
        364784,
        364795,
        364798,
        364809,
        364813,
        364854,
        364855,
        364868,
        364903,
        364931,
        364934,
        364952,
        364959,
        364962,
        364996,
        365033,
        365046,
        365057,
        365103,
        365110,
        365152,
        365155,
        365184,
        365192,
        365193,
        365262,
        365290,
        365320,
        365321,
        365342,
        365354,
        365362,
        365367,
        365379,
        365427,
        365429,
        365439,
        365444,
        365445,
        365475,
        365478,
        365490,
        365494,
        365495,
        365501,
        365526,
        365589,
        365632,
        365638,
        365646,
        365664,
        365669,
        365751,
        365773,
        365774,
        365779,
        365808,
        365827,
        365833,
        365835,
        365839,
        365841,
        365869,
        365874,
        365892,
        365921,
        365934,
        365944,
        365946,
        365947,
        365951,
        365953,
        365980,
        365988,
        365997,
        366006,
        366031,
        366043,
        366049,
        366051,
        366056,
        366071,
        366074,
        366116,
        366149,
        366155,
        366187,
        366195,
        366196,
        366224,
        366235,
        366258,
        366285,
        366295,
        366302,
        366331,
        366341,
        366379,
        366402,
        366408,
        366415,
        366425,
        366436,
        366447,
        366507,
        366509,
        366521,
        366525,
        366558,
        366596,
        366600,
        366601,
        366621,
        366623,
        366630,
        366639,
        366647,
        366655,
        366656,
        366665,
        366667,
        366685,
        366687,
        366696,
        366700,
        366724,
        366726,
        366734,
        366743,
        366751,
        366762,
        366763,
        366768,
        366804,
        366807,
        366808,
        366828,
        366833,
        366847,
        366880,
        366893,
        366898,
        366902,
        366905,
        366911,
        366923,
        366947,
        366985,
        367016,
        367024,
        367030,
        367036,
        367041,
        367045,
        367048,
        367087,
        367112,
        367117,
        367147,
        367158,
        367164,
        367165,
        367167,
        367172,
        367188,
        367193,
        367231,
        367232,
        367241,
        367265,
        367269,
        367282,
        367284,
        367308,
        367344,
        367358,
        367373,
        367405,
        367412,
        367447,
        367468,
        367551,
        367622,
        367641,
        367650,
        367656,
        367667,
        367707,
        367718,
        367726,
        367796,
        367812,
        367833,
        367837,
        367878,
        367893,
        367901,
        367911,
        367917,
        367978,
        368214,
        368240,
        383886,
        387129,
        392593,
        392686,
        392692,
        394957,
        395786,
        395825,
        395888,
        398546,
        398663,
        410628,
        412237,
        420836,
        420888,
        423568,
        423631,
        423661,
        423672,
        423692,
        423806,
        423808,
        423868,
        423928,
        424257,
        424284,
        424379,
        424414,
        424457,
        424508,
        424734,
        424744,
        424846,
        424948,
        424958,
        424971,
        425104,
        425137,
        425161,
        425164,
        425173,
        425201,
        425215,
        425220,
        425222,
        425342,
        425571,
        425711,
        425767,
        425795,
        425796,
        425832,
        425883,
        425938,
        425976,
        425978,
        426040,
        426045,
        426053,
        426090,
        426103,
        426104,
        426148,
        426187,
        426233,
        426359,
        427421,
        427428,
        427450,
        427463,
        427517,
        427548,
        427570,
        427602,
        427608,
        427688,
        427710,
        427739,
        427768,
        427787,
        427790,
        427792,
        427793,
        427794,
        427797,
        427854,
        427925,
        427961,
        427970,
        427972,
        427975,
        427996,
        428009,
        428039,
        428058,
        428059,
        428060,
        428065,
        428070,
        428072,
        428082,
        428089,
        428096,
        428099,
        428130,
        428173,
        428179,
        428197,
        428205,
        428219,
        428255,
        428276,
        428289,
        428372,
        428373,
        428378,
        428411,
        428453,
        428471,
        428477,
        428517,
        428518,
        428550,
        428554,
        428555,
        428584,
        428594,
        428621,
        428623,
        428634,
        428644,
        428678,
        428708,
        428843,
        428870,
        428892,
        428914,
        428932,
        428940,
        428970,
        429005,
        429046,
        429058,
        429059,
        429077,
        429081,
        431673,
        431739,
        431781,
        431808,
        431830,
        431841,
        431904,
        431908,
        431909,
        431953,
        431964,
        432013,
        432040,
        432047,
        432194,
        432212,
        432263,
        432265,
        432268,
        432302,
        432307,
        432337,
        432374,
        432415,
        432486,
        432550,
        432600,
        432605,
        432622,
        432639,
        432650,
        432651,
        432688,
        432692,
        432694,
        432695,
        432697,
        432733,
        432743,
        432769,
        432805,
        432817,
        432819,
        432827,
        432850,
        432859,
        432946,
        432950,
        432958,
        432969,
        432980,
        432985,
        432997,
        433044,
        433061,
        433083,
        433131,
        433159,
        433168,
        433169,
        433173,
        433178,
        433196,
        433217,
        433264,
        433274,
        433324,
        433355,
        433399,
        433430,
        433436,
        433450,
        433451,
        433456,
        433479,
        433485,
        433514,
        433527,
        433565,
        433568,
        433611,
        433620,
        433621,
        433624,
        433628,
        433679,
        433690,
        433701,
        433737,
        433754,
        433778,
        433823,
        433871,
        433881,
        433904,
        433911,
        433922,
        433965,
        433966,
        433972,
        434073,
        434116,
        434155,
        434164,
        434182,
        434189,
        434206,
        434336,
        434350,
        434368,
        434380,
        434436,
        434438,
        434459,
        434544,
        434549,
        434592,
        434600,
        434737,
        436661,
        437128,
        439633,
        439675,
        439680,
        439686,
        439707,
        439738,
        439761,
        439763,
        439769,
        439782,
        439787,
        439789,
        439793,
        439878,
        439909,
        439913,
        439935,
        439936,
        440359,
        443262,
        443299,
        444983,
        445070,
        446822,
        447941,
        447950,
        447954,
        447955,
        447956,
        447963,
        447972,
        447979,
        447999,
        448010,
        448017,
        448020,
        448025,
        448029,
        448040,
        448046,
        448051,
        448063,
        448071,
        448081,
        448085,
        449772,
        449898,
        449924,
        449936,
        456710,
        456938,
        458083,
        458349,
        458861,
        459296,
        460041,
        480045,
        480062,
        480180,
        480260,
        483505,
        484164,
        484463,
        484717,
        484944,
        485243,
        485347,
        487065,
        487593,
        516311,
        521726,
        540300,
        542235,
        546560,
        554356,
        554398,
        554442,
        554471,
        554837,
        554874,
        555330,
        555368,
        555434,
        555711,
        556211,
        556230,
        556871,
        557044,
        557331,
        557734,
        557784,
        557827,
        558096,
        558099,
        558186,
        558774,
        559136,
        559252,
        559925,
        560049,
        560983,
        561092,
        561894,
        562033,
        562049,
        562386,
        562628,
        562730,
        562841,
        569900,
        572024,
        573735,
        573935,
        573937,
        574195,
        574250,
        574303,
        574747,
        574814,
        575075,
        575267,
        575270,
        575982,
        576111,
        576131,
        576820,
        577265,
        577567,
        578058,
        578370,
        578660,
        579359,
        579367,
        579423,
        582663,
        583042,
        585159,
        586006,
        586154,
        586157,
        586571,
        586584,
        588506,
        601790,
        613076,
        615396,
        681203,
        686632,
        715953,
        716090,
        716229,
        716233,
        716243,
        716246,
        716769,
        717019,
        718088,
        718344,
        726379,
        733343,
        733562,
        733883,
        733894,
        734000,
        734040,
        734522,
        754074,
        754085,
        763816,
        766932,
        775101,
        775168,
        775784,
        776703,
        777199,
        777222,
        780532,
        780558,
        783562,
        795463,
        819065,
        819270,
        819417,
        820235,
        846395,
        846436,
        846965,
        847241,
        847524,
        848654,
        848922,
        849000,
        849218,
        850080,
        855062,
        855210,
        855371,
        856433,
        860948,
        861018,
        863967,
        864033,
        864240,
        864282,
        864679,
        865854,
        868904,
        868929,
        869002,
        869021,
        874355,
        879515,
        890491,
        891091,
        891228,
        891353,
        891390,
        891715,
        892027,
        892063,
        892120,
        892405,
        892541,
        892644,
        892762,
        892809,
        892819,
        892896,
        893032,
        893554,
        894080,
        894125,
        894139,
        894209,
        894216,
        894250,
        894316,
        894449,
        894713,
        894738,
        894761,
        894794,
        894823,
        894830,
        894843,
        894856,
        894973,
        895008,
        895023,
        895041,
        895052,
        895152,
        895157,
        895175,
        895195,
        897174,
        926106,
        928556,
        928652,
        928704,
        929071,
        941507,
        941949,
        942143,
        942376,
        942409,
        942726,
        942911,
        943058,
        946994,
        947032,
        947137,
        947224,
        950706,
        950710,
        952064,
        952182,
        955471,
        955756,
        957257,
        962176,
        962359,
        963118,
        963345,
        963565,
        963798,
        964250,
        964553,
        964727,
        964782,
        964793,
        964926,
        965307,
        965393,
        965411,
        965700,
        965741,
        966176,
        966508,
        966509,
        966583,
        966591,
        966674,
        966744,
        967292,
        972775,
        972915,
        972955,
        973095,
        973130,
        973146,
        973316,
        973571,
        974352,
        975959,
        976054,
        976106,
        976402,
        978027,
        978826,
        979177,
        979185,
        979214,
        979255,
        979360,
        979592,
        979616,
        979703,
        979785,
        979814,
        981007,
        981108,
        982376,
        982382,
        982860,
        982863,
        983026,
        983135,
        983174,
        983181,
        983227,
        983230,
        983249,
        983275,
        991641,
        996821,
        999289,
        999291,
        999568,
        999944,
        1003600,
        1003646,
        1003711,
        1003847,
        1003864,
        1003963,
        1004607,
        1005896,
        1005908,
        1006349,
        1006368,
        1006389,
        1006393,
        1006394,
        1006404,
        1006414,
        1006418,
        1006429,
        1006506,
        1006507,
        1006513,
        1006528,
        1006545,
        1006548,
        1006554,
        1006559,
        1006568,
        1006574,
        1006588,
        1006591,
        1006608,
        1006621,
        1006625,
        1006642,
        1006654,
        1006703,
        1006707,
        1006733,
        1006734,
        1006745,
        1006757,
        1006778,
        1006779,
        1006793,
        1006798,
        1006800,
        1006801,
        1006804,
        1006807,
        1006810,
        1006816,
        1006822,
        1006827,
        1006850,
        1006854,
        1006858,
        1006859,
        1006872,
        1006874,
        1006879,
        1006897,
        1006899,
        1006903,
        1006910,
        1006939,
        1006946,
        1006959,
        1006961,
        1006963,
        1007026,
        1007041,
        1007044,
        1007050,
        1007054,
        1007056,
        1007067,
        1007108,
        1007129,
        1007246,
        1007272,
        1007284,
        1007308,
        1007347,
        1007370,
        1007387,
        1007411,
        1007418,
        1007419,
        1007431,
        1007478,
        1007480,
        1007496,
        1007543,
        1007605,
        1007621,
        1007631,
        1007663,
        1007680,
        1007716,
        1007906,
        1007907,
        1008070,
        1008083,
        1008095,
        1008100,
        1008125,
        1008145,
        1008177,
        1008196,
        1008203,
        1008207,
        1008210,
        1008260,
        1008262,
        1008265,
        1008273,
        1008275,
        1008290,
        1008312,
        1008342,
        1008345,
        1008355,
        1008411,
        1008412,
        1008441,
        1008442,
        1008443,
        1008461,
        1008492,
        1008510,
        1008528,
        1008529,
        1008537,
        1008544,
        1008551,
        1008560,
        1008568,
        1008571,
        1008596,
        1008602,
        1008607,
        1008617,
        1008647,
        1008653,
        1008657,
        1008665,
        1008666,
        1008680,
        1008682,
        1008692,
        1008700,
        1008713,
        1008716,
        1008718,
        1008722,
        1008737,
        1008742,
        1008765,
        1008771,
        1008784,
        1008785,
        1008801,
        1008804,
        1008832,
        1008833,
        1008896,
        1009022,
        1009055,
        1009119,
        1009123,
        1009137,
        1009186,
        1009198,
        1009217,
        1009224,
        1009380,
        1009386,
        1009412,
        1009415,
        1009418,
        1009426,
        1009438,
        1009440,
        1009442,
        1009443,
        1009477,
        1009484,
        1009490,
        1009496,
        1009553,
        1009588,
        1009593,
        1009602,
        1009626,
        1009881,
        1011070,
        1014796,
        1016331,
        1017133,
        1017663,
        1017669,
        1018585,
        1018593,
        1018615,
        1020684,
        1021853,
        1021968,
        1021979,
        1022588,
        1022589,
        1023047,
        1023074,
        1044951,
        1045013,
        1045381,
        1045386,
        1045391,
        1045489,
        1045582,
        1046152,
        1048412,
        1048843,
        1054368,
        1054410,
        1059346,
        1059354,
        1059631,
        1059658,
        1059710,
        1067022,
        1067082,
        1067094,
        1067105,
        1069938,
        1070152,
        1070240,
        1070486,
        1070490,
        1070782,
        1070787,
        1070900,
        1071050,
        1071086,
        1071133,
        1071179,
        1071204,
        1071996,
        1072059,
        1072076,
        1072826,
        1074540,
        1075248,
        1075292,
        1075330,
        1075472,
        1075561,
        1075748,
        1075787,
        1076086,
        1076797,
        1077582,
        1077624,
        1077626,
        1077803,
        1077844,
        1078133,
        1078189,
        1078368,
        1078377,
        1078455,
        1078584,
        1078593,
        1078629,
        1078814,
        1078912,
        1079199,
        1079262,
        1079342,
        1079445,
        1079473,
        1079575,
        1079734,
        1089238,
        1089308,
        1089377,
        1089506,
        1089573,
        1089594,
        1089703,
        1089733,
        1089791,
        1089818,
        1089951,
        1089990,
        1090062,
        1090114,
        1090199,
        1090360,
        1090403,
        1090426,
        1091276,
        1092974,
        1092993,
        1092994,
        1093126,
        1093611,
        1094029,
        1094042,
        1094057,
        1094107,
        1094115,
        1094261,
        1096922,
        1097101,
        1097199,
        1097382,
        1097420,
        1098593,
        1098808,
        1099013,
        1099301,
        1099321,
        1100125,
        1100246,
        1100313,
        1100386,
        1100436,
        1100537,
        1100881,
        1100889,
        1104589,
        1104941,
        1105600,
        1106906,
        1106956,
        1107099,
        1107243,
        1107507,
        1109502,
        1109521,
        1109619,
        1109624,
        1109685,
        1110372,
        1110373,
        1110377,
        1110387,
        1110389,
        1110390,
        1110391,
        1110392,
        1110393,
        1110395,
        1110396,
        1110397,
        1110550,
        1110618,
        1110700,
        1110769,
        1110781,
        1110822,
        1110858,
        1110860,
        1110916,
        1111356,
        1111510,
        1111514,
        1111567,
        1112765,
        1113525,
        1114820,
        1115028,
        1115325,
        1115707,
        1115712,
        1115968,
        1116010,
        1116169,
        1116173,
        1116922,
        1116939,
        1116958,
        1117128,
        1117156,
        1117291,
        1117293,
        1117441,
        1117501,
        1117510,
        1117514,
        1118087,
        1118204,
        1118455,
        1119038,
        1119140,
        1119520,
        1119929,
        1119930,
        1119981,
        1120164,
        1121048,
        1121153,
        1121484,
        1123196,
        1123317,
        1123394,
        1123522,
        1123667,
        1123723,
        1123724,
        1123735,
        1124194,
        1124385,
        1124402,
        1124482,
        1124513,
        1124562,
        1125600,
        1126164,
        1126270,
        1126301,
        1126318,
        1126640,
        1127222,
        1127781,
        1128227,
        1128576,
        1128619,
        1129017,
        1130837,
        1134403,
        1134493,
        1134528,
        1134759,
        1134891,
        1135012,
        1135150,
        1135159,
        1135217,
        1135617,
        1135813,
        1135849,
        1135931,
        1136003,
        1136008,
        1136704,
        1137245,
        1137652,
        1137657,
        1137671,
        1137769,
        1137995,
        1138054,
        1138094,
        1138143,
        1138223,
        1139115,
        1139174,
        1139288,
        1139403,
        1139410,
        1139531,
        1139920,
        1140697,
        1140773,
        1142051,
        1142550,
        1142601,
        1142798,
        1143075,
        1143361,
        1143397,
        1143942,
        1144107,
        1144194,
        1144240,
        1144474,
        1144884,
        1145085,
        1146193,
        1146271,
        1146398,
        1146556,
        1147065,
        1147196,
        1147214,
        1147230,
        1147231,
        1147242,
        1147427,
        1148622,
        1148853,
        1149796,
        1150019,
        1150144,
        1150156,
        1150215,
        1150254,
        1150292,
        1150319,
        1150366,
        1150415,
        1150435,
        1150437,
        1151086,
        1151089,
        1151166,
        1151208,
        1151220,
        1151239,
        1151640,
        1151993,
        1152696,
        1153736,
        1153800,
        1153840,
        1153867,
        1154313,
        1154433,
        1154531,
        1154582,
        1154707,
        1154772,
        1154875,
        1154991,
        1155177,
        1155202,
        1155271,
        1155422,
        1155451,
        1156761,
        1156798,
        1156996,
        1157084,
        1157481,
        1157874,
        1163898,
        1164302,
        1164818,
        1165097,
        1165179,
        1165493,
        1165882,
        1165957,
        1166204,
        1166226,
        1166239,
        1166275,
        1166420,
        1166430,
        1166490,
        1166691,
        1167046,
        1167215,
        1167216,
        1167230,
        1167981,
        1168139,
        1168226,
        1168287,
        1168501,
        1168921,
        1169104,
        1169405,
        1169417,
        1169444,
        1169652,
        1169801,
        1170099,
        1170466,
        1170834,
        1171104,
        1171804,
        1171975,
        1172259,
        1172271,
        1173844,
        1176962,
        1177009,
        1177412,
        1177696,
        1178214,
        1178707,
        1178865,
        1178877,
        1179154,
        1179169,
        1179219,
        1180128,
        1180178,
        1180310,
        1180552,
        1180731,
        1180824,
        1180862,
        1181293,
        1182883,
        1182912,
        1183290,
        1183588,
        1183764,
        1184159,
        1188823,
        1188830,
        1189031,
        1189172,
        1189366,
        1191365,
        1191405,
        1193700,
        1193713,
        1193815,
        1194797,
        1195461,
        1195523,
        1195562,
        1195705,
        1195891,
        1197194,
        1197871,
        1199156,
        1199381,
        1199905,
        1202779,
        1203121,
        1203284,
        1203565,
        1203738,
        1203882,
        1203887,
        1203933,
        1204158,
        1204479,
        1206444,
        1207275,
        1207534,
        1207725,
        1207779,
        1208383,
        1209304,
        1213452,
        1213644,
        1215627,
        1215645,
        1215763,
        1215766,
        1215973,
        1216113,
        1216145,
        1216180,
        1216275,
        1221273,
        1225349,
        1237434,
        1237658,
        1239910,
        1240106,
        1241622,
        1241643,
        1241809,
        1242432,
        1245555,
        1246941,
        1247050,
        1247131,
        1247385,
        1248208,
        1248431,
        1255500,
        1257846,
        1257862,
        1257912,
        1257972,
        1267627,
        1267631,
        1267649,
        1267751,
        1270522,
        1271016,
        1271317,
        1271900,
        1271908,
        1271927,
        1272454,
        1272726,
        1273336,
        1273393,
        1273454,
        1273746,
        1274101,
        1274681,
        1275465,
        1275556,
        1275625,
        1275648,
        1278270,
        1278358,
        1278481,
        1278502,
        1278525,
        1278599,
        1279138,
        1279293,
        1279386,
        1279658,
        1279671,
        1279676,
        1279679,
        1279750,
        1279826,
        1279852,
        1279871,
        1280137,
        1280173,
        1280187,
        1280491,
        1280589,
        1280652,
        1280845,
        1280920,
        1281028,
        1281061,
        1281253,
        1281405,
        1281563,
        1281590,
        1281611,
        1281655,
        1283436,
        1283449,
        1283481,
        1283555,
        1283557,
        1283837,
        1283912,
        1283978,
        1284371,
        1284482,
        1284500,
        1284628,
        1288139,
        1288720,
        1288769,
        1289062,
        1289082,
        1289168,
        1289358,
        1291908,
        1292259,
        1292574,
        1292625,
        1292738,
        1292755,
        1295194,
        1295463,
        1295577,
        1295901,
        1296078,
        1297330,
        1297396,
        1298270,
        1298512,
        1298556,
        1298746,
        1298767,
        1300030,
        1300088,
        1300292,
        1300530,
        1300550,
        1300686,
        1301452,
        1301625,
        1301819,
        1301837,
        1301975,
        1302622,
        1302648,
        1305274,
        1305279,
        1305367,
        1306852,
        1307319,
        1307321,
        1307343,
        1307360,
        1307401,
        1307406,
        1307442,
        1307547,
        1307584,
        1307606,
        1307611,
        1307633,
        1307637,
        1307645,
        1307646,
        1307665,
        1307682,
        1307719,
        1307728,
        1307730,
        1307754,
        1307868,
        1307946,
        1308393,
        1308456,
        1308524,
        1308638,
        1308840,
        1308971,
        1309348,
        1309846,
        1309854,
        1309883,
        1309960,
        1309975,
        1310016,
        1310026,
        1310050,
        1310052,
        1310126,
        1310131,
        1310662,
        1310701,
        1310871,
        1311077,
        1311303,
        1311346,
        1311463,
        1311480,
        1311536,
        1311552,
        1311602,
        1311622,
        1311679,
        1311703,
        1311706,
        1311711,
        1311757,
        1311838,
        1311858,
        1311863,
        1311893,
        1311943,
        1311982,
        1311984,
        1312005,
        1312041,
        1312078,
        1312101,
        1312103,
        1312114,
        1312135,
        1312137,
        1312150,
        1312166,
        1312167,
        1312208,
        1312493,
        1312525,
        1312625,
        1312742,
        1312747,
        1312767,
        1312807,
        1312831,
        1312838,
        1312845,
        1312898,
        1312969,
        1313071,
        1313125,
        1313143,
        1313219,
        1313221,
        1313225,
        1313252,
        1313282,
        1313328,
        1313332,
        1313356,
        1313385,
        1313408,
        1313449,
        1313495,
        1314191,
        1314425,
        1314468,
        1314480,
        1314584,
        1314860,
        1315104,
        1315278,
        1315370,
        1316213,
        1316569,
        1316637,
        1316784,
        1316965,
        1317048,
        1317145,
        1317272,
        1317379,
        1317382,
        1317383,
        1317467,
        1317477,
        1317700,
        1317762,
        1317912,
        1318612,
        1318734,
        1318767,
        1318955,
        1318957,
        1319529,
        1319557,
        1319803,
        1319869,
        1320113,
        1320656,
        1320662,
        1320665,
        1321827,
        1322009,
        1322035,
        1322218,
        1322266,
        1322355,
        1322402,
        1322731,
        1322949,
        1323235,
        1323747,
        1323818,
        1324008,
        1324686,
        1325174,
        1325600,
        1325702,
        1325754,
        1326181,
        1326223,
        1326521,
        1326522,
        1326606,
        1326721,
        1326753,
        1326865,
        1326953,
        1326977,
        1326985,
        1327069,
        1327394,
        1327457,
        1327478,
        1327599,
        1327600,
        1327601,
        1327602,
        1327603,
        1327604,
        1327614,
        1327615,
        1327616,
        1327617,
        1327652,
        1327799,
        1327866,
        1328137,
        1328372,
        1328919,
        1329303,
        1329431,
        1329472,
        1330208,
        1330336,
        1330615,
        1330966,
        1331500,
        1331573,
        1331619,
        1331630,
        1331914,
        1332102,
        1332194,
        1332435,
        1332482,
        1332681,
        1332771,
        1332977,
        1332983,
        1333369,
        1333507,
        1333571,
        1334252,
        1334644,
        1334685,
        1335043,
        1335296,
        1335515,
        1335581,
        1335954,
        1335961,
        1336023,
        1336088,
        1336129,
        1336244,
        1336446,
        1336803,
        1336815,
        1336820,
        1336998,
        1337020,
        1338050,
        1338190,
        1338273,
        1338274,
        1338700,
        1338830,
        1339185,
        1339186,
        1339198,
        1339199,
        1339367,
        1339790,
        1340057,
        1340225,
        1340368,
        1340526,
        1340531,
        1340685,
        1340750,
        1340981,
        1341017,
        1341107,
        1341108,
        1341129,
        1341336,
        1341583,
        1341610,
        1342298,
        1342408,
        1342527,
        1342529,
        1342565,
        1342703,
        1342840,
        1343004,
        1343036,
        1343142,
        1343617,
        1343619,
        1343782,
        1343890,
        1343996,
        1344078,
        1344166,
        1344470,
        1344625,
        1344925,
        1345167,
        1345334,
        1345977,
        1346185,
        1346252,
        1346293,
        1346346,
        1346500,
        1347042,
        1347137,
        1347248,
        1347623,
        1348093,
        1348284,
        1348467,
        1348812,
        1349398,
        1349446,
        1349500,
        1349872,
        1350486,
        1350516,
        1350572,
        1350805,
        1350808,
        1352055,
        1352597,
        1352613,
        1352683,
        1352872,
        1352875,
        1352877,
        1353179,
        1353875,
        1353991,
        1354418,
        1354667,
        1354830,
        1354833,
        1354836,
        1354854,
        1355419,
        1355951,
        1356488,
        1356514,
        1357634,
        1358484,
        1360206,
        1360513,
        1360723,
        1361200,
        1361490,
        1362541,
        1363909]

    return listOfPharmaMerchantIds.includes(merchantId)
}