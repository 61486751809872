const DIWALI_PACKAGE_LOAD = 'diwaliExclusivePackage/DIWALI_PACKAGE_LOAD';
const DIWALI_PACKAGE_SUCCESS = 'diwaliExclusivePackage/DIWALI_PACKAGE_SUCCESS';
const DIWALI_PACKAGE_FAIL = 'diwaliExclusivePackage/DIWALI_PACKAGE_FAIL';

const LAUNCH_DIWALI_PACKAGE_LOAD = 'diwaliExclusivePackage/LAUNCH_DIWALI_PACKAGE_LOAD'
const LAUNCH_DIWALI_PACKAGE_SUCCESS= 'diwaliExclusivePackage/LAUNCH_DIWALI_PACKAGE_SUCCESS'
const LAUNCH_DIWALI_PACKAGE_FAIL= 'diwaliExclusivePackage/LAUNCH_DIWALI_PACKAGE_FAIL'
const ADD_ON_PACKAGE_GOAL_TRACKER_LOAD='diwaliExclusivePackage/ADD_ON_PACKAGE_GOAL_TRACKER_LOAD'
const ADD_ON_PACKAGE_GOAL_TRACKER_SUCCESS='diwaliExclusivePackage/ADD_ON_PACKAGE_GOAL_TRACKER_SUCCESS'
const ADD_ON_PACKAGE_GOAL_TRACKER_FAIL='diwaliExclusivePackage/ADD_ON_PACKAGE_GOAL_TRACKER_FAIL'

const initialState = {
    diwaliPackageLoading: false,
	diwaliPackageData: null,
	addOnPacakgeGoalTrackerData: null,
	addOnPacakgeGoalTrackerLoading: false
}

export default function diwaliExclusivePackage(state = initialState, action = {}){
    switch (action.type) {
    	case DIWALI_PACKAGE_LOAD:
    		return {
      		...state,
      		diwaliPackageLoading: true
    	};
    	case DIWALI_PACKAGE_SUCCESS:
    		return {
      		...state,
      		diwaliPackageLoading: false,
      		diwaliPackageData: action.result,
      		diwaliPackageError: null
    	};
    	case DIWALI_PACKAGE_FAIL:
			return {
				...state,
				diwaliPackageLoading: false,
				diwaliPackageData: null,
				diwaliPackageError: action.error
		};
		case LAUNCH_DIWALI_PACKAGE_LOAD:
			return {
				...state,
				launchDiwaliPackageLoading: true,
				launchDiwaliPackageData: null,
				launchDiwaliPackageError: action.error
		};
		case LAUNCH_DIWALI_PACKAGE_SUCCESS:
			return {
				...state,
				launchDiwaliPackageLoading: false,
				launchDiwaliPackageData: action.data,
				launchDiwaliPackageError: null
		};
		case LAUNCH_DIWALI_PACKAGE_FAIL:
			return {
				...state,
				launchDiwaliPackageLoading: false,
				launchDiwaliPackageLoaded: null,
				launchDiwaliPackageError: action.error
		};
		case ADD_ON_PACKAGE_GOAL_TRACKER_LOAD:
			return{
				...state,
				addOnPacakgeGoalTrackerLoading: true
			}
		case ADD_ON_PACKAGE_GOAL_TRACKER_SUCCESS:
			return{
				...state,
				addOnPacakgeGoalTrackerLoading: false,
				addOnPackageGoalTrackerData: action.result,
				addOnPackageGoalTrackerError: null,
			}
		case ADD_ON_PACKAGE_GOAL_TRACKER_FAIL:
			return{
				...state,
				addOnPacakgeGoalTrackerLoading: false,
				addOnPackageGoalTrackerData: null,
				addOnPacakgeGoalTrackerError: action.error,
			}
      default:
          return state;
    }
}

export function getDiwaliPackages(accessToken, packageId) {
    return {
        types: [DIWALI_PACKAGE_LOAD, DIWALI_PACKAGE_SUCCESS, DIWALI_PACKAGE_FAIL],
        promise: (client) => client.default.post('/getAddonPackages ',{
          data:{accessToken, packageId}
        })
    }
}

export function launchDiwaliPackage(accessToken, merchantPackageId, requestId, denomination ,country, loanAmount) {
    return {
        types: [LAUNCH_DIWALI_PACKAGE_LOAD, LAUNCH_DIWALI_PACKAGE_SUCCESS, LAUNCH_DIWALI_PACKAGE_FAIL],
        promise: (client) => client.default.post('/launchZeroCashinPackage ',{
          data:{accessToken, merchantPackageId, requestId, denomination, country, loanAmount}
        })
    }
}

export function addOnPackageGoalTracker(accessToken) {
    return {
        types: [ADD_ON_PACKAGE_GOAL_TRACKER_LOAD, ADD_ON_PACKAGE_GOAL_TRACKER_SUCCESS, ADD_ON_PACKAGE_GOAL_TRACKER_FAIL],
        promise: (client) => client.default.post('/addOnPackageGoalTracker ',{
          data:{accessToken}
        })
    }
}