import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import infoIcon from '../../images/info-icon-new.png';
import languageJson from './languageConst.js';

export default class CustomerPreference extends React.Component{
  render(){
      const languageConst = this.props.language ? languageJson[this.props.language] : languageJson["english"];
      var categoryArr = {"1": "foodConst" , "5": "groceryConst", "3": "fashionConst","2":"beautyConst"}
      const tooltip = (
       <Tooltip id="tooltip">{languageConst.topRetailersTooltip}</Tooltip>
     );
    // const widths = topMerchants && topMerchants.length && topMerchants.map(merchant => merchant.totalSales*100/topMerchants[0].totalSales + '%');

    return(
      <div style = {{backgroundColor:'white', paddingBottom: "60px"}}>
        <div style={{width: '100%', fontFamily:'robotoregular', fontSize: window.innerWidth > 768 ? '35px' : '20px', fontWeight:'bold', paddingLeft:'2%', textAlign:'center'}} >
          {languageConst.custPref}
          <OverlayTrigger placement="right" overlay={tooltip}>
            <img  style={{marginLeft: '3px'}} src={infoIcon} width="12" />
          </OverlayTrigger>
        </div>
        <div className="row" style = {{margin:'2% 3% 0 3%',display: window.innerWidth > 768 ? 'flex' : '',justifyContent:'center'}}>
          {this.props.customerPreference.map((data, index) => {
            if(data.merchants.length == 0 )
                return;
            var totalSpent  = 0
            data.merchants.sort(function(a, b){
              return b.bill_size-a.bill_size
            })
            data.merchants.map((merchant, index)=>{
              totalSpent = totalSpent + merchant.bill_size
            })
            return (
              <div className="col-md-3" style = {{ display:'inline-block', width: window.innerWidth > 768 ? '':'100%', marginBottom:'4%'}}>
              <div className="graph-box" style = {{backgroundColor:'#f7f7fa', boxShadow: "1px 2px 10px #00000044"}}>
                <p style={{margin:'0% 0% 0% 4%', paddingTop:'3px', fontWeight:'bold'}}>
                {languageConst[categoryArr[data.category_id]]}
                </p>
                <hr style={{margin:'3%', borderTop: "1px solid #ddd"}} />
                <div style={{width:'90%'}}>
                {data.merchants.map((merchant, index)=>{
                  if(index <= 5)
                  return (
                    <div style={{padding:'5%'}}>
                    <div style={{height:'40px', width:'40px', verticalAlign: 'top', backgroundImage:`url(${merchant.merchant_profile})`, backgroundSize:'cover', display:'inline-block'}}>
                    </div>
                    <div style={{padding: "0px 4%", width:'80%', display:'inline-block', textAlign:'left'}}>
                      <span style={{fontSize:'12px', fontWeight:'bold'}}>{merchant.merchant_name}</span>
                      <br />
                      <span style={{fontSize:'11px'}}>{languageConst.spent}: {Math.floor((merchant.bill_size/totalSpent)*100)}%</span>
                    </div>
                  </div>
                )
                })}
                </div>
                </div>
              </div>
            )

          })}
        </div>
      </div>
    );
  }
}