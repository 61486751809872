import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isIOS, isAndroid} from 'react-device-detect';

export default connect(store => ({

}), dispatch => bindActionCreators({

}, dispatch))
(class GetOrderhereApp extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        let appURL = ""
        if (isIOS) {
            appURL = "https://apps.apple.com/in/app/orderhere-easy-online-dukaan/id1528283353"
        } else {
            appURL = "https://play.google.com/store/apps/details?id=com.magicpin.partner"
        }
        window.location.href = appURL
    }

    render() {
        return ("")
    }
})
