import React,{Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import PromotionDeductModal from '../Feed/PromotionDeductModal'
import { getCurrencySymbol } from '../../helpers/Helpers';
export default class DeductMoney extends Component {
  constructor(props){
    super(props);
    this.state = {
      showSendOtpModal: false,
      showAddBalanceDialog:false,
    }
  }
    requestPromotion = () => {
      this.props.unmountDeductState();
      this.props.requestPromotion();
    }

    hideSendOtpModal = () => {
      this.props.unmountDeductState();
      this.setState({showSendOtpModal:false});
      
    }

    getPhoneNumbers=(members)=>{
      const phoneNumbers = members.filter(member => member.mobileNumber).map(member=>member.mobileNumber);
      return [...(new Set(phoneNumbers))]
    }

    handleRedirectDialogClose = () => {
      this.setState({redirectDialogOpen: false});
      this.props.unmountDeductState();
    };

    handleAddBalanceDialogClose = () => {
      this.setState({showAddBalanceDialog: false});
      this.props.unmountDeductState();
    };

    componentDidMount(){
      if((this.props.walletBalance && this.props.walletBalance < 300) || !this.props.isRunnable){
        this.setState({showAddBalanceDialog:true})
      }
      else this.setState({showSendOtpModal:true})
    }

    render() {
      let currency=getCurrencySymbol(this.props.user.country)
        return (
            <div>
              {this.state.showAddBalanceDialog &&
              <Dialog
                title="Your wallet balance is less than 300. Please recharge"
                actions={[
                  <FlatButton
                    label="Cancel"
                    onClick={() => this.handleAddBalanceDialogClose()}
                  />,
                  <RaisedButton 
                  label="Add Balance" 
                  onClick={()=>{this.props.push('/dashboard')}}
                  primary={true}/>
                ]}
                modal={false}
                open={true}
                onRequestClose={this.handleAddBalanceDialogClose}>
              </Dialog>
              }
            {this.state.showSendOtpModal &&
              <PromotionDeductModal 
                hideSendOtpModal={this.hideSendOtpModal} 
                members = {this.props.members}
                user = {this.props.user}
                onOtpVerified = {this.requestPromotion}
                message = 'One last step to boost your business'
                title = {`You have exhausted your monthly limit of free feed posts and we will charge ${currency}300 per feedpush `} />}
          </div>
        );
    }
}
