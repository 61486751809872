import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginViaApp } from '../../redux/modules/authReducers/auth';
import blueBackground from '../../images/blue-background.png';
import rightArrow from '../../images/right-arrow.png';
import {Image} from 'react-bootstrap';

export default connect(state => ({
  logginIn: state.auth.logginIn,
  loginError: state.auth.loginError,
  merchants: state.auth.merchants,
}), dispatch => bindActionCreators({
  loginViaApp,
}, dispatch))
(class LoginViaApp extends React.Component {

  extractUserIdAndToken (){
    let appUserId = null;
    let appUserToken = null;
    const params = new URLSearchParams(this.props.location.search);
    if(params.has('appUserId') && params.has('appUserToken')) {
      appUserId = params.get('appUserId');
      appUserToken = params.get('appUserToken');
    }
    if(!appUserId){
      appUserId = window.localStorage.getItem('appUserId');
      appUserToken = window.localStorage.getItem('appUserToken');
    }
    window.localStorage.setItem('isAppLogin', true)
    return {appUserId: appUserId, appUserToken: appUserToken};
  }

  componentWillMount(){
    const appCredentials= this.extractUserIdAndToken();
    if(!appCredentials.appUserId || !appCredentials.appUserToken) alert('No app auth found');
    else this.props.loginViaApp(appCredentials.appUserId, appCredentials.appUserToken);
  }

  render(){
    const appCredentials = this.extractUserIdAndToken();
    return(
     <div style={{height:"100%",backgroundColor:"white"}}> 

        {!Array.isArray(this.props.merchants) && <div>Opening Merchant Mode </div>}

        { this.props.merchants && this.props.merchants.length > 0 && 
         <div>
            <div style={{marginLeft:"0",height:"140px",width:"100%",position:"relative"}} className="row">
              <Image style={{width:"100%",height:"140px"}}  src={blueBackground} />
              <div style={{fontSize:"20px",fontFamily:"RobotoMedium",color:"white",position:"absolute",bottom:"30px",left:"15px"}}>Select a store </div>
              <div style={{fontSize:"14px",fontFamily:"RobotoRegular",color:"white",position:"absolute",bottom:"10px",left:"15px"}}>{this.props.merchants.length} stores </div>
            </div>

            <MerchantsList
              merchants = {this.props.merchants}
              select = {(merchantId) => this.props.loginViaApp(appCredentials.appUserId,appCredentials.appUserToken, merchantId)}
            />
         </div>
        }
    </div>  
    )
  }
})

export function MerchantsList(props){
  if (!props.merchants) return null;
  return (<>
    { props.merchants.map(merchant =>
      {
      const merchantId = merchant.merchantId || merchant.merchant_id
      const merchantName = merchant.merchantName || merchant.merchant_name
      const profilePic = merchant.profilePic || merchant.profile_pic 

      return (
      <div onClick={() => {props.select(merchantId, merchant.accessToken);}} style={{marginBottom:"20px",height:"70px",border:"solid 1px rgba(0, 0, 0, 0.12)",borderRadius:"8px",marginTop:"20px",marginLeft:"15px",marginRight:"15px"}} className="row">
        <div className ="col-xs-2"><Image style={{marginTop:"15px",width:"40px",height:"40px"}} src={profilePic ? profilePic : "https://s3-ap-southeast-1.amazonaws.com/magicpin-selfie/productimages/251485_profile_pic_2018-05-22-15-18-44-000348.png"} circle /></div>
        <div style={{fontSize:"16px",fontFamily:"RobotoBold",textAlign:"left",paddingRight:"0",paddingLeft:"5px",marginTop:"12px"}} className ="col-xs-7">{merchantName ? merchantName : "Magicpin merchant"} <br />
          <span style={{fontSize:"14px",fontFamily:"RobotoRegular"}}>{merchant.locality} </span>
        </div>
        <div className ="col-xs-1"></div>
        <div style={{paddingRight:"0px",textAlign:"right"}} className="col-xs-2"><Image style={{marginTop:"23px",height:"24px",width:"24px"}} src={rightArrow} /></div> 
      </div>)
      } 
    ) }
  </>);
}