import React from 'react';
import moment from 'moment';
import PostPage from '../PostPage/PostPage';
import FbShare from '../FbShare/FbShare';
//import Tooltip from 'material-ui/Tooltip';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import coins from '../../images/coins.png';
import defaultProfilePic from '../../images/profile-default.png';
import ReactHtmlParser from 'react-html-parser';
import { extractHashtag } from '../../helpers/Helpers';
import { getCurrencySymbol } from '../../helpers/Helpers';
import {toast} from 'react-toastify';
import { countriesInfo } from '../../constants';
import '../Feed/react-feed-stylesheet.css';
import NewUserOnMerchantCard from '../Feed/NewUserOnMerchantCard';

const ga = window.ga;

class CustomerCard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      styleSayThankYou : {
        visibility : "hidden",
        opacity : 0
      },
      shareModal : false,
    }
  }

  showPostModal(){
    this.setState({showPostModal: true});
  }
  hidePostModal(){
    this.setState({showPostModal: false});
  }

  onCickLikeButton(userId,postId,event){
    event.preventDefault();
    ga('send', 'event', 'Customer Page', 'like_customer', 'like_customer');
    var _this = this;
    this.setState({styleSayThankYou : {visibility : "visible",opacity : 1}});
    setTimeout(function(){
      _this.setState({styleSayThankYou : {visibility : "hidden",opacity : 0}});
    }, 10000)

    this.props.options.like(userId,postId,this.props.options.accessToken);
  }
  thankYouButton(userId,postId,event) {
    event.preventDefault();
    var defautMsg = "Hi, Thank you for visiting our outlet. We hope to see you back soon.";
    this.setState({styleSayThankYou : {visibility : "hidden",opacity : 0}});
    this.props.options.showModal(defautMsg);
    this.props.options.getComments(userId,postId,this.props.options.accessToken)
  }

  onCickGetComments(userId,postId,event){
    event.preventDefault();
    this.props.options.showCommentModal();
    this.props.options.getComments(userId,postId,this.props.options.accessToken);

  }
  hideShareModal = () => {
    this.setState({shareModal:false});
  }


  onClickGetPost(userId,postId,event){
    event.preventDefault();
    this.showPostModal();
  }

  onShare(){
    ga('send', 'event', 'Social Actions', 'Share', 'Customers page');
    this.setState({shareModal : true});
  }
  render() {
    var bill = this.props.data.transaction_details && this.props.data.transaction_details.transaction_amount;
    var visits = this.props.data.author_profile.post_count;
    var caption = this.props.data.question_response_content;
    var caption_hashTag = [];
    if(!caption) caption="";
    caption_hashTag = caption.match(/<a href.+?tag=(.+?)><b>(.*?)<\/b><\/a>.*?/g) || [];
    caption_hashTag.forEach(function(item,index){
      var hashTagItem = item.match(/<a href.+?tag=(.+?)><b>(.*?)<\/b>.*?/);
      caption = caption.replace(hashTagItem[0],hashTagItem[2]);
      caption = caption.replace("</a>","");
    });
    this.props.options.hidePostModal = this.hidePostModal.bind(this);
    var rating_color = '';
    var rating_value = this.props.data.rating;
    const country = this.props.user.country.toUpperCase()
    switch (true) {
      case (rating_value < 1.5):
      rating_color = 'merchant-rating rating_0';
      break;
      case (rating_value < 2.5):
      rating_color = 'merchant-rating rating_1';
      break;
      case (rating_value < 3):
      rating_color = 'merchant-rating rating_2';
      break;
      case (rating_value < 4):
      rating_color = 'merchant-rating rating_3';
      break;
      case (rating_value < 4.5):
      rating_color = 'merchant-rating rating_4';
      break;
      default:
      rating_color = 'merchant-rating rating_5';
      break;
    }
    const privatePost = this.props.data.privacy === 'SELF'
    const likeTooltip = (
      <Tooltip id={this.props.data.post_id+"likeTooltip"}>{privatePost ? 'User interaction is not allowed for private posts' : "Like all your customer's picture to improve your repeat rate by 30%"}</Tooltip>);
    const commentTooltip = (
      <Tooltip id={this.props.data.post_id+"commentTooltip"}>{privatePost ? 'User interaction is not allowed for private posts':"Say thanks to your customers and ask for their reviews."}</Tooltip>);

    return (
      <div className = 'card-feed-card-new'>
        <NewUserOnMerchantCard post={this.props.data} user={this.props.user} like={this.props.like} comment={this.props.comment} liked={this.props.liked} notShowNthVisit={true}  dontShrinkInMobile={true} /> 
      </div>
    );
  }
}

export default CustomerCard;
