import React, { Suspense, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Chart } from "react-google-charts";
import {
  getGroupBuyPlans,
  launchGroupBuyModal,
} from "../../redux/modules/groupBuyLaunch";
import {
  loadBusinessSummary,
  magicpinOverallDiscountCall,
} from "../../redux/modules/dashboardReducers/businessSummary";
import { countriesInfo } from "../../constants";
import { launchFixedCashinModal } from "../../redux/modules/fixedCashinLaunch";
import { getMerchantRatingsAndVisits } from "../../redux/modules/newMerchantLanding";
import { loadBusinessWithDates } from "../../redux/modules/dashboardReducers/businessWithDates";
import { loadOrdersWithDates } from "../../redux/modules/dashboardReducers/ordersWithDates";
import { loadBookingWithDates } from "../../redux/modules/dashboardReducers/bookingWithDates";
import { getTxnPattern } from "../../redux/modules/dashboardReducers/getTxnPattern";
import { loadAvgSpendWithDates } from "../../redux/modules/dashboardReducers/avgSpendWithDates";
import { loadRepeatWithDates } from "../../redux/modules/dashboardReducers/repeatWithDates";
import { loadCustomerLatLong } from "../../redux/modules/dashboardReducers/customerLatLong";
import {
  getGoalTrackerData,
  getGoalTrackerHistory,
} from "../../redux/modules/goalTracker";
import { getPosts, like } from "../../redux/modules/customerPage";
import "./CeetPage.css";
import { connect } from "react-redux";
import ReviewAndRatings from "../Customers/ReviewAndRatings";
import TimeOfDayChart from "../DashboardDetailed/TimeOfDayChart";
import VoucherSoldChart from "../Dashboard/VoucherSoldChart";
import CustomerAvgRatingChart from "../Dashboard/CustomerAvgRatingChart";
import GmvChart from "../DashboardDetailed/GmvChart";
import CeetLeaderBoard from "../Leaderboard/CeetLeaderBoard";
import { Grid } from "@material-ui/core";
import CustomerCardList from "../Customers/customerCardList";
import CustomersCssImport from "../Customers/CustomersCssImport";
import LocationIcon from "../../images/location-icon-ceet.png";

const getRatingColour = (rating) => {
  let result = "";

  const colourCompositions = [
    {
      colour: "#008A3B",
      condition: (x) => x >= 4.5,
    },
    {
      colour: "#4DA940",
      condition: (x) => x >= 4 && x < 4.5,
    },
    {
      colour: "#8BCC00",
      condition: (x) => x >= 3 && x < 4,
    },
    {
      colour: "#E0A44A",
      condition: (x) => x >= 2.5 && x < 3,
    },
    {
      colour: "#FF6C5B",
      condition: (x) => x >= 1.5 && x < 2.5,
    },
    {
      colour: "#EC3C3C",
      condition: (x) => x < 1.5,
    },
  ];

  colourCompositions.forEach(({ colour, condition }) => {
    if (condition(rating)) result = colour;
  });

  return result;
};

const CeetPage = connect(
  (store) => ({
    merchantRatingsVisitsData:
      store.newMerchantLanding.merchantRatingsVisitsData,
    user: store.auth.user,
    goalTrackerHistoryData: store.goalTracker.goalTrackerHistoryData,
    feedShortcutsStatusData: store.feedShortcutsStatus.feedShortcutsStatusData,
    groupBuyPlans: store.groupBuy.groupBuyPlans,
    summary: store.businessSummary.data,
    businessWithDates: store.businessWithDates.data,
    businessWithDatesLoading: store.businessWithDates.loading,
    ordersWithDates: store.ordersWithDates.data,
    ordersWithDatesLoading: store.ordersWithDates.loading,
    bookingWithDates: store.bookingWithDates.bookingsWithDatesData,
    loadingBookingWithDates: store.bookingWithDates.loading,
    BookingWithDatesLoaded: store.bookingWithDates.loaded,
    bookings: store.bookingWithDates.bookings,
    alias: store.newMerchantLanding.alias,
    magicpinDiscountData: store.businessSummary.magicpinDiscountData,
    goalTrackerHistoryData: store.goalTracker.goalTrackerHistoryData,
    suitablePackage: store.upgradePackage.suitablePackage,
    roiAmtOnAppliedPenalty: store.upgradePackage.roiAmtOnAppliedPenalty,
    repeatWithDates: store.repeatWithDates.data,

    avgSpendWithDates: store.avgSpendWithDates.data,
    avgSpendWithDatesLoading: store.avgSpendWithDates.loading,

    magicpinDiscountData: store.businessSummary.magicpinDiscountData,
    loadingMagicpinDiscount: store.businessSummary.loadingMagicpinDiscount,
    magicpinDiscountDataFailure:
      store.businessSummary.magicpinDiscountDataFailure,

    customerLatLong: store.customerLatLong.data,
    loadingLatLong: store.customerLatLong.loading,
    latLongLoaded: store.customerLatLong.loaded,

    dataPosts: store.customerPage.posts,
    offset: store.customerPage.offset,
    moreData: store.customerPage.moreData,
    loadingPosts: store.customerPage.loading,
    loadedPosts: store.customerPage.loaded,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        launchGroupBuyModal,
        launchFixedCashinModal,
        loadBusinessSummary,
        getGroupBuyPlans,
        loadBusinessWithDates,
        loadOrdersWithDates,
        loadBookingWithDates,
        getTxnPattern,
        magicpinOverallDiscountCall,
        loadAvgSpendWithDates,
        loadRepeatWithDates,
        loadCustomerLatLong,
        getPosts,
        like,
        getMerchantRatingsAndVisits,
        getGoalTrackerHistory,
        getGoalTrackerData,
      },
      dispatch
    )
)(
  ({
    user,
    merchantRatingsVisitsData,
    summary,
    location,
    push,
    businessWithDates,
    loadBusinessWithDates,
    businessWithDatesLoading,
    getTxnPattern,
    goalTrackerHistoryData,
    getMerchantRatingsAndVisits,
    getGoalTrackerHistory,
    getGoalTrackerData,
    loadBusinessSummary,
    dataPosts,
    loadedPosts,
    loadingPosts,
    offset,
    moreData,
    getPosts,
  }) => {
    const [packageStartDate, setPackageStartDate] = useState();
    const [sortby] = useState("recent");
    const { accessToken, userId, ultronToken, isCeetMerchant } = user;
    const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    const country = user.country.toUpperCase();
    const localeString = countriesInfo[country]["locale"];

    const isLocalMerchant = !(
      user.isRetailChain ||
      user.isParentMerchant ||
      user.parentMerchantId ||
      user.isOnlineMerchant
    );

    useEffect(() => {
      const isNotAdmin = (user?.userType || "").toLowerCase() !== "admin";
      if (isNotAdmin)
        localStorage.setItem(`isCeetMerchant_${accessToken}`, true);
      if (!summary) loadBusinessSummary(accessToken, "dashboard");
      getMerchantRatingsAndVisits(accessToken, userId);

      if (dataPosts && dataPosts.length === 0) {
        getPosts(
          user.accessToken,
          0,
          sortby,
          userId,
          ultronToken,
          isCeetMerchant
        );
      }

      if (!goalTrackerHistoryData) getGoalTrackerHistory(accessToken);

      getGoalTrackerData(accessToken)
        .then((goalTrackerData) => {
          let date = goalTrackerData?.[0]?.packageStartDate || startDate;
          date = moment(Date).format("YYYY-MM-DD");
          setPackageStartDate(date);
        })
        .catch({});
    }, []);

    const isMobile = window.innerWidth < 768;

    const ratings =
      merchantRatingsVisitsData && merchantRatingsVisitsData.ratings;

    const seeMore = () => {
      getPosts(
        user.accessToken,
        offset,
        sortby,
        userId,
        ultronToken,
        user && isCeetMerchant
      );
    };

    const options = {
      like: like,
      accessToken: accessToken,
    };

    const rating = ratings?.rating?.toFixed(1);
    const ratingColour = getRatingColour(rating);

    return (
      <Grid
        container
        direction="column"
        spacing={16}
        align={isMobile ? "center" : "start"}
        style={{
          paddingLeft: "5vw",
          paddingRight: isMobile ? "0vw" :"3vw",
          paddingTop: "3vw",
        }}
      >
        {/* <Grid item>
          <h1>Summary</h1>
        </Grid> */}
        <Grid
          item
          container
          xs={12}
          spacing={24}
          justify={isLocalMerchant ? "center" : ""}
        >
          {/* <Header user={user} isCeetPage /> */}
          {/* Left */}
          <Grid item xs={12} sm={9}>
            <Grid
              container
              spacing={24}
              direction="column"
              justify={isMobile ? "center" : "flex-start"}
            >
              {/* Banner */}
              <Grid item xs={12}>
                <Grid
                  container
                  className={`ceetPage-promo-banner${
                    isMobile ? "" : " ceetPage-promo-banner-background"
                  }`}
                  wrap="nowrap"
                >
                  <Grid item xs="auto" className="ceetPage-promo-banner-left">
                    <img
                      className={`ceetPage-promo-banner-img${
                        isMobile ? "-mobile" : ""
                      }`}
                      src={user?.profilePic}
                      alt="profile pic"
                    />
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    item
                    xs="auto"
                    className="ceetPage-promo-banner-info"
                  >
                    <Grid
                      container
                      item
                      justify="flex-end"
                      className="ceetPage-promo-banner-review"
                    >
                      {!isMobile ? (
                        <Grid
                          container
                          justify="center"
                          className="ceetPage-promo-banner-review-inner"
                        >
                          <Grid
                            item
                            className="ceetPage-promo-banner-review-inner-rating"
                            style={{ backgroundColor: ratingColour }}
                          >
                            <i
                              style={{ color: "#ffffff", fontSize: "12px" }}
                              className="star star2 fa fa-star"
                            ></i>
                            <p>{rating}</p>
                          </Grid>
                          <Grid
                            item
                            className="ceetPage-promo-banner-review-inner-reviews"
                          >
                            <span>
                              {ratings?.ratingCount &&
                                ratings?.ratingCount.toLocaleString(
                                  localeString
                                )}
                            </span>
                            <p>Reviews</p>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          className="ceetPage-promo-banner-review-rating-mobile"
                          style={{ backgroundColor: ratingColour }}
                        >
                          <Grid container>
                            <Grid item>
                              <i
                                style={{ color: "#ffffff", fontSize: "12px" }}
                                className="star star2 fa fa-star"
                              ></i>
                              &nbsp;
                            </Grid>
                            <Grid item>
                              <span>{rating}</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      item
                      className={`ceetPage-promo-banner-info-name${
                        isMobile ? "-mobile" : ""
                      }`}
                    >
                      <span>{user.merchantName}</span>
                    </Grid>

                    <Grid
                      item
                      container
                      spacing={8}
                      className={`ceetPage-promo-banner-info-address${
                        isMobile ? "-mobile" : ""
                      }`}
                    >
                      <Grid item>
                        <img src={LocationIcon} alt="location-icon" />
                      </Grid>
                      <Grid item>
                        <span>
                          {user.locality}
                          {user.city ? `, ${user.city}` : null}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {isMobile && !isLocalMerchant && (
                <Grid item xs={12} sm={3}>
                  <CeetLeaderBoard
                    user={user}
                    push={push}
                    location={location}
                    isCeetPage={true}
                  />
                </Grid>
              )}

              {/* 5 star rating */}
              {merchantRatingsVisitsData && (
                <Grid item xs={12}>
                  <ReviewAndRatings
                    user={user}
                    push={push}
                    merchantRatingsVisitsData={merchantRatingsVisitsData}
                    isCeetPage={true}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <CustomerAvgRatingChart
                  user={user}
                  push={push}
                  packageStartDate={packageStartDate}
                  isCeetPage={true}
                />
              </Grid>

              {!isLocalMerchant && (
                <Grid item xs={12}>
                  <VoucherSoldChart user={user} push={push} />
                </Grid>
              )}

              {/* popular timing */}
              {Array.isArray(summary?.TXN_PATTERN) &&
                summary.TXN_PATTERN.lengthoffset && (
                  <Grid item xs={12}>
                    <TimeOfDayChart
                      Chart={Chart}
                      getTxnPattern={getTxnPattern}
                      user={user}
                      daypattern={summary && summary.TXN_PATTERN}
                      isCeetPage={true}
                    />
                  </Grid>
                )}

              {/* Business -> 2 */}
              {summary && (
                <Grid item xs={12} align="start">
                  <Grid container direction="column" spacing={16}>
                    <Grid item>
                      <h1>Business</h1>
                    </Grid>
                    <Grid item>
                      <GmvChart
                        summary={summary}
                        businessWithDates={businessWithDates}
                        businessWithDatesLoading={businessWithDatesLoading}
                        loadBusinessWithDates={loadBusinessWithDates}
                        user={user}
                        Chart={Chart}
                        push={push}
                        isCeetPage={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                {loadedPosts &&
                dataPosts &&
                !dataPosts.length &&
                [0, 10].includes(offset) ? null : (
                  <div className="mrcht-cstmr-card-wrapper">
                    <div style={{ width: "100%", display: "inline-block" }}>
                      <div className="pull-left">
                        <h2>
                          <b>Customer Reviews</b>
                        </h2>
                      </div>
                    </div>
                    <Suspense fallback="loading">
                      <CustomersCssImport />
                    </Suspense>
                    <CustomerCardList
                      data={dataPosts}
                      options={options}
                      like={like}
                      user={user}
                    />

                    {moreData && (
                      <div
                        value="See More"
                        className={
                          loadingPosts ? "loadingDots large-btn" : "large-btn"
                        }
                        style={{
                          width: "200px",
                          display: "flex",
                          backgroundColor: "#EF1C74",
                        }}
                        onClick={() => seeMore()}
                      >
                        {" "}
                        {loadingPosts ? "Loading" : "See More"}
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Right */}
          {!isMobile && !isLocalMerchant && (
            <Grid item xs={12} sm={3}>
              <CeetLeaderBoard
                user={user}
                push={push}
                location={location}
                merchantRatingsVisitsData={merchantRatingsVisitsData}
                isCeetPage={true}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default CeetPage;
