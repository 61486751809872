export const TENURE_PACKAGES = [
    'TENURE_BRONZE_SIX_MONTHS',
    'TENURE_BRONZE_THREE_MONTHS',
    'TENURE_WELCOME_THREE_MONTHS',
    'TENURE_WELCOME_SIX_MONTHS',
    'TENURE_ENTRY_THREE_MONTHS',
    'TENURE_ENTRY_SIX_MONTHS',
    'TENURE_TELE-ENTRY_THREE_MONTHS',
    'TENURE_TELE-ENTRY_SIX_MONTHS',
    'TENURE_BEGINNER_SIX_MONTHS',
    'TENURE_BEGINNER_THREE_MONTHS'
]