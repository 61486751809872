import moment from 'moment';

const LOAD = 'bookingWithDates/LOAD';
const LOAD_SUCCESS = 'bookingWithDates/LOAD_SUCCESS';
const LOAD_FAIL = 'bookingWithDates/LOAD_FAIL';
const LOAD1 = 'bookingWithDates/LOAD1';
const LOAD_SUCCESS1 = 'bookingWithDates/LOAD_SUCCESS1';
const LOAD_FAIL1 = 'bookingWithDates/LOAD_FAIL1';

const initialState = {
  loaded: false,
  bookings:[],
  bookingWithDatesFirstCall:true
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        bookingsWithDatesData: action.result,
        bookingWithDatesFirstCall: false,
        bookings: state.bookingWithDatesFirstCall ? action.result.bookingCreated.map(booking=>{return {"blockedDate":booking.date,"dealsBlocked":booking.count}}) : state.bookings,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        bookingWithDatesData: null,
        error: action.error
      };
    case LOAD1:
      return {
        ...state,
        loading1: true
      };
    case LOAD_SUCCESS1:
      return {
        ...state,
        loading1: false,
        loaded1: true,
        bookingsWithDatesData1: action.result,
        bookingWithDatesFirstCall1: false,
        bookings1: state.bookingWithDatesFirstCall ? action.result.bookingCreated.map(booking=>{return {"blockedDate":booking.date,"dealsBlocked":booking.count}}) : state.bookings,
        error: null
      };
    case LOAD_FAIL1:
      return {
        ...state,
        loading1: false,
        loaded1: false,
        bookingWithDatesData1: null,
        error: action.error
      };
   default:
      return state;
  }
}

export function loadBookingWithDates(accessToken, startDate, endDate) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post(`/getBookingStats`, {
      data: {"accessToken":accessToken,
      "startDate":startDate,
      "endDate":endDate}
    })
  };
}

export function loadBookingWithDates1(accessToken, startDate, endDate) {
  return {
    types: [LOAD1, LOAD_SUCCESS1, LOAD_FAIL1],
    promise: (client) => client.default.post(`/getBookingStats`, {
      data: {"accessToken":accessToken,
      "startDate":moment(startDate).format('YYYY-MM-DD'),
      "endDate":moment(endDate).format('YYYY-MM-DD')}
    })
  };
}
