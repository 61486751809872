import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import {load as getMerchants} from '../../redux/modules/merchantList';

const styles = {
  titleStyle: {
    backgroundColor: '#338aed',
    fontSize: '16px',
    color: '#fff',
    padding: '16px',
    paddingTop: '8px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },

  contentStyle: {
    borderRadius : '8px',
    width: '320px',
  },

  closeStyle: {
    position: 'absolute',
    top: '4px',
    right: '8px',
    width: '16px',
    height: '16px'
  },

  bodyStyle: {
    padding: '16px',
  },

  paperStyle: {
    style: {
        borderRadius: '8px',
    }
  }
}

export default class ThanksPopup extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: !!this.props.open,
    }
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render(){

    return (
      <div>
        <Dialog
          title={this.props.title || "Thanks for signing up with us!"}
          titleStyle = {styles.titleStyle}
          paperProps = {styles.paperStyle}
          contentStyle = {styles.contentStyle}
          bodyStyle = {styles.bodyStyle}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose} >
          <img src="images/close.svg" onClick={this.handleClose} style={styles.closeStyle}/>
          {!!this.props.content ? this.props.content
          :
          <p>We’ll soon get in touch with you to help you onboard our business tools. Together we’ll add magic to your store.</p>
          }
        </Dialog>
    </div>
    )
  }
}
