const GET_CALL_CLICKS_LOADING = 'leads/GET_CALL_CLICKS_LOADING';
const GET_CALL_CLICKS_SUCCESS = 'leads/GET_CALL_CLICKS_SUCCESS';
const GET_CALL_CLICKS_FAILED = 'leads/GET_CALL_CLICKS_FAILED';

const initialState = {
    call_clicks_loading: false,
  }

export default function leads(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CALL_CLICKS_LOADING:
            return {
              ...state,
              call_clicks_loading: true,
            };
          case GET_CALL_CLICKS_SUCCESS:
            return {
              ...state,
              call_clicks_loading: false,
              call_clicks_loaded: true,
              call_clicks_data: action.result,
            };
          case GET_CALL_CLICKS_FAILED:
            return {
              ...state,
              call_clicks_loading: false,
              call_clicks_loaded: false,
              call_clicks_error: action.error,
              call_clicks_data: null
            };
     default:
        return state;
    }
  }

  export function getCallClicks(merchantUserId, limit, start, startDate, endDate){
    return {
      types:[GET_CALL_CLICKS_LOADING, GET_CALL_CLICKS_SUCCESS, GET_CALL_CLICKS_FAILED],
        promise:(client) => client.ultron_mp.get(`merchant/callClickUserData/${merchantUserId}?limit=${limit}&start=${start}&start_date=${startDate}&end_date=${endDate}`, {
     })
    }
  }