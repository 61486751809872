const LOAD = 'voucherDetails/LOAD';
const LOAD_SUCCESS = 'voucherDetails/LOAD_SUCCESS';
const LOAD_FAIL = 'voucherDetails/LOAD_FAIL';

const REDEEM = 'voucherRedeem/LOAD';
const REDEEM_SUCCESS = 'voucherRedeem/LOAD_SUCCESS';
const REDEEM_FAIL = 'voucherRedeem/LOAD_FAIL';

/*const REDEEM_TARGET = 'voucherRedeem/REDEEM_TARGET_LOAD';
const REDEEM_TARGET_SUCCESS = 'voucherRedeem/REDEEM_TARGET_LOAD_SUCCESS';
const REDEEM_TARGET_FAIL = 'voucherRedeem/REDEEM_TARGET_LOAD_FAIL';*/

const initialState = {
  loaded: false,
  redeemed: false,
};

export default function voucherRedemption(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        voucherInfo: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case REDEEM:
      return {
        ...state,
        redeeming: true
      };
    case REDEEM_SUCCESS:
      return {
        ...state,
        redeeming: false,
        redeemed: true,
        redeemInfo: action.result
      };
    case REDEEM_FAIL:
      return {
        ...state,
        redeeming: false,
        redeemed: false,
        redeemerror: action.error
      };
    /*case REDEEM_TARGET:
      return {
        ...state,
        targetLoading: true
      }
    case REDEEM_TARGET_SUCCESS:
      return {
        ...state,
        targetLoading: false,
        targetData: action.result,
        targetError: null
      }
    case REDEEM_TARGET_FAIL:
        return {
          ...state,
          targetLoading: false,
          targetData: null,
          targetError: action.error
        }*/
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.voucherRedemption && globalState.voucherRedemption.loaded;
}

export function load(accessToken, voucherCode, merchantId, merchantUserId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getVoucherInfo', {
      data:{accessToken, voucherCode, merchantId, merchantUserId}
    })
  };
}

export function redeem(accessToken, voucherCode, billAmount) {
  return {
    types: [REDEEM, REDEEM_SUCCESS, REDEEM_FAIL],
    promise: (client) => client.default.post('/redeemVoucher', {
      data:{accessToken, voucherCode, billAmount}
    })
  };
}

export function redeemMerchantVoucher(accessToken, voucherCode, billAmount, merchantId, merchantUserId) {
  return {
    types: [REDEEM, REDEEM_SUCCESS, REDEEM_FAIL],
    promise: (client) => client.default.post('/redeemVoucherForMechant', {
      data:{accessToken, voucherCode, billAmount, merchantId, merchantUserId}
    })
  };
}

/*export function getRedeemTarget(accessToken, user_id){
  let url='/getRedeemTarget'
  if(user_id) url+=`?user_id=${user_id}`
  return {
    types:[REDEEM_TARGET, REDEEM_TARGET_SUCCESS, REDEEM_TARGET_FAIL],
    promise: client=>client.default.post(url,{
      data:{accessToken}
    })
  }
}*/
