import React, { Component } from 'react'
import ChartComponent from 'react-chartjs-2';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import moment from 'moment';
import "./customerAvgRatingChart.css"
import { getRatingReviewAll } from '../../redux/modules/dashboardReducers/ratingReviewAll';
import { getGoalTrackerData } from '../../redux/modules/goalTracker';
import { generateArrayOfDates, groupDataTableByTime } from '../../helpers/Helpers';

export default connect(store => ({
    ratingReviewAllData: store.ratingReviewAll.ratingReviewAllData
}), dispatch => bindActionCreators({
    getRatingReviewAll,
    getGoalTrackerData
}, dispatch))
(class CustomerAvgRatingChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labels: [],
            avgRatingData: [],
            totalRatingsData: [],
            topRatingsData: [],
    
            intervalType:'month',
            startDate : moment().subtract(150, 'days').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            packageStartDate : moment().subtract(150, 'days').format("YYYY-MM-DD"),
            typeOfRange: ['date', 'week', 'month'],
            startDateSet: false,
            startDateForDaily: moment().subtract(45, 'days').format("YYYY-MM-DD"),
        }
    }

    componentDidMount = () => {
        this.props.getRatingReviewAll(this.props.user.accessToken, moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevState.intervalType !== this.state.intervalType) || (prevProps.ratingReviewAllData !== this.props.ratingReviewAllData)){
            this.prepareFinalData()
        }
    }

    prepareFinalData = () => {
        const dateWiseData = {}
        const ratingList = this.props.ratingReviewAllData && this.props.ratingReviewAllData.top_reviews && Array.isArray(this.props.ratingReviewAllData.top_reviews) &&
                           this.props.ratingReviewAllData.top_reviews || []

        const startDate = this.state.intervalType === "date" ? this.state.startDateForDaily : this.state.startDate

        let arrayOfDates = generateArrayOfDates(startDate, this.state.endDate, "YYYY-MM-DD") || []

        arrayOfDates.forEach((date) => {
                dateWiseData[date] = {
                    totalRatingCount: 0,
                    topRatingsCount: 0,
                    totalRatingSum: 0,
                    avgRating: 0
                }
        })

        ratingList.forEach((ratingObj) => {
            let createdAt = ratingObj && ratingObj.created_at && moment(ratingObj.created_at * 1000).format("YYYY-MM-DD")
            if(createdAt){
                if(dateWiseData[createdAt]){
                    dateWiseData[createdAt] = {
                        totalRatingCount: dateWiseData[createdAt].totalRatingCount + 1,
                        topRatingsCount: ratingObj.rating > 3 ?  dateWiseData[createdAt].topRatingsCount + 1 : dateWiseData[createdAt].topRatingsCount ,
                        totalRatingSum: dateWiseData[createdAt].totalRatingSum + ratingObj.rating,
                        avgRating: 0
                    }
                }
                else{
                    dateWiseData[createdAt] = {
                        totalRatingCount: 1,
                        topRatingsCount: ratingObj.rating > 3 ? 1 : 0,
                        totalRatingSum: ratingObj.rating,
                        avgRating: 0
                    }
                }
            }
        })


        let ungroupedData = arrayOfDates.map(date=>[date, dateWiseData[date].topRatingsCount, dateWiseData[date].avgRating, dateWiseData[date].totalRatingSum, dateWiseData[date].totalRatingCount])

        let {data, format} = groupDataTableByTime(ungroupedData, this.state.intervalType)
        let labels = []
        let avgRatingData = []
        let avgRatingDataToShow = []
        let totalRatingsData = []
        let topRatingsData = []

        data.forEach((item, index) => {
            let avgRating = item[4] ? item[3]/item[4] : 0

            if(index){
                let preRatingCountSum = 0
                let preRatingProduct = 0

                let curRatingCount = item[4] || 0
                let curRatingAvg = avgRating || 0

                data.forEach((itemPre, idxPre) => {
                    if(idxPre < index){
                        let preRatingCount = data[idxPre] && data[idxPre][4] || 0
                        let preRatingAvg = avgRatingData[idxPre] || 0

                        preRatingCountSum = preRatingCountSum + preRatingCount

                        preRatingProduct = preRatingProduct + (preRatingCount * preRatingAvg)
                    }
                })

                avgRating = curRatingCount + preRatingCountSum > 0 ? (((preRatingProduct) + (curRatingCount * curRatingAvg))/(curRatingCount + preRatingCountSum)) : 0
            }

            avgRating = avgRating > 0 ? avgRating.toFixed(2) : 0
            avgRatingData.push(avgRating)


            labels.push(moment(item[0]).format(format))
            topRatingsData.push(item[1])
            avgRatingDataToShow.push(avgRating)
            totalRatingsData.push(item[4] - item[1] >=0 ? item[4] - item[1] : item[4])
        })

        this.setState({
            labels,
            avgRatingData: avgRatingDataToShow,
            totalRatingsData,
            topRatingsData,
        })
    }

    render() {

    const data = {
        labels: this.state.labels,
        datasets: [
            // {
            //     label: 'Avg ratings',
            //     data: this.state.avgRatingData,
            //     borderColor: 'rgba(239, 162, 46, 0.8)',
            //     stack: 'combined',
            //     type: 'line',
            //     fill: false,
            //     borderWidth: 2,
            //     yAxisID: 'y2',
            //     pointRadius: this.state.intervalType === "month" ? 5 : 2,
            //     pointStyle: "star",
            //     tension: 0
            // },
            {
                label: 'No of 1/2/3 ratings',
                data:  this.state.totalRatingsData,
                borderColor: "white",
                backgroundColor: 'rgba(214, 214, 214, 0.8)',
                stack: 'combined',
                type: 'bar',
                maxBarThickness: 30,
                yAxisID: 'y',
            },
            {
                label: 'No of 4/5 ratings',
                data: this.state.topRatingsData,
                borderColor: "white",
                backgroundColor: "rgba(14, 78, 4, 0.8)",
                stack: 'combined',
                type: 'bar',
                barWidth: '10',
                maxBarThickness: 30,
                yAxisID: 'y',
            }
        ]
    };

    const options = {
        scales: {
            yAxes: [{
              stacked: true,
              id: 'y',
              position: 'left',
              ticks: {
                maxTicksLimit: 6,
                precision: 0,
                suggestedMax: 5
                },
              gridLines: {
                display: true,
                lineWidth: 1,
                zeroLineColor: 'rgba(214, 214, 214)',
             },
             scaleLabel: {
                display: true,
                labelString: "No. of ratings count",
                fontFamily: "robotomedium",
                fontSize: 14,
                padding: 8
              }
            }, 
            // {
            //   id: 'y2',
            //   type: 'linear',
            //   position: 'right',
            //   ticks: {
            //     max: 5,
            //     min: 0,
            //     fontColor: 'rgba(239, 162, 46)',
            //     maxTicksLimit: 5
            //   },
            //   gridLines: {
            //     display: false
            //  },
            //  scaleLabel: {
            //     display: true,
            //     labelString: "Avg. ratings out of 5",
            //     fontFamily: "robotomedium",
            //     fontSize: 14,
            //     padding: 8
            //   }
            // }
            ],
            xAxes: [{
                stacked: true,
                gridLines: {
                    display: false,
                }
              }],
          }
      }

    const addnStyle = this.props.isCeetPage ? {
        marginLeft: "0px",
    } : {};

     return (
       <div className='customer-rating-graph-comp-main card white-box white-box-wrapper mobile-full-card col-md-12' style={addnStyle} >
           <div className='customer-rating-graph-comp-main-div'>
                <div className='customer-rating-graph-comp-main-heading'>
                    <p>No. of 5 star ratings</p>
                    <div className='customer-rating-graph-comp-main-heading-ctas'>
                        <span className= {`${this.state.intervalType === "date" && 'customer-rating-graph-comp-main-heading-ctas-active'}`}
                              onClick={() => {this.setState({intervalType: "date"})}}> Daily</span>
                        <span className= {`${this.state.intervalType === "week" && 'customer-rating-graph-comp-main-heading-ctas-active'}`}
                              onClick={() => {this.setState({intervalType: "week"})}}> Weekly</span>
                        <span className= {`${this.state.intervalType === "month" && 'customer-rating-graph-comp-main-heading-ctas-active'}`}
                              onClick={() => {this.setState({intervalType: "month"})}}> Monthly</span>
                    </div>
                </div>
                <div className='customer-rating-graph-comp-main-body'>
                    <ChartComponent
                        type='bar'
                        data={data}
                        height={window.innerWidth < 768 ? 250 : 100}
                        options = {options}
                    />
                </div>
            </div>
       </div>
     )
   }
})