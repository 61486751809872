import { getUserDetail } from "../../helpers/Helpers";

const CREATE_MERCHANT = 'brandOnboarding/CREATE_MERCHANT';
const CREATE_MERCHANT_SUCCESS = 'brandOnboarding/CREATE_MERCHANT_SUCCESS';
const CREATE_MERCHANT_FAIL = 'brandOnboarding/CREATE_MERCHANT_FAIL';

const UPDATE_MERCHANT = 'brandOnboarding/UPDATE_MERCHANT';
const UPDATE_MERCHANT_SUCCESS = 'brandOnboarding/UPDATE_MERCHANT_SUCCESS';
const UPDATE_MERCHANT_FAIL = 'brandOnboarding/UPDATE_MERCHANT_FAIL';

const GET_BRAND_MERCHANT = 'brandOnboarding/GET_BRAND_MERCHANT';
const GET_BRAND_MERCHANT_SUCCESS = 'brandOnboarding/GET_BRAND_MERCHANT_SUCCESS';
const GET_BRAND_MERCHANT_FAIL = 'brandOnboarding/GET_BRAND_MERCHANT_FAIL';

const UPLOAD_BRAND_IMAGE = 'brandOnboarding/UPLOAD_BRAND_IMAGE';
const UPLOAD_BRAND_IMAGE_SUCCESS = 'brandOnboarding/UPLOAD_BRAND_IMAGE_SUCCESS';
const UPLOAD_BRAND_IMAGE_FAIL = 'brandOnboarding/UPLOAD_BRAND_IMAGE_FAIL';

const UPLOAD_BRAND_DOCUMENT = 'brandOnboarding/UPLOAD_BRAND_DOCUMENT';
const UPLOAD_BRAND_DOCUMENT_SUCCESS = 'brandOnboarding/UPLOAD_BRAND_DOCUMENT_SUCCESS';
const UPLOAD_BRAND_DOCUMENT_FAIL = 'brandOnboarding/UPLOAD_BRAND_DOCUMENT_FAIL';

const GET_UPLOADED_DOCUMENTS = 'brandOnboarding/GET_UPLOADED_DOCUMENTS';
const GET_UPLOADED_DOCUMENTS_SUCCESS = 'brandOnboarding/GET_UPLOADED_DOCUMENTS_SUCCESS';
const GET_UPLOADED_DOCUMENTS_FAIL = 'brandOnboarding/GET_UPLOADED_DOCUMENTS_FAIL';

const ONBOARD_BRAND = 'brandOnboarding/ONBOARD_BRAND';
const ONBOARD_BRAND_SUCCESS = 'brandOnboarding/ONBOARD_BRAND_SUCCESS';
const ONBOARD_BRAND_FAIL = 'brandOnboarding/ONBOARD_BRAND_FAIL';

const UPLOAD_BRAND_VIDEO = 'brandOnboarding/UPLOAD_BRAND_VIDEO';
const UPLOAD_BRAND_VIDEO_SUCCESS = 'brandOnboarding/UPLOAD_BRAND_VIDEO_SUCCESS';
const UPLOAD_BRAND_VIDEO_FAIL = 'brandOnboarding/UPLOAD_BRAND_VIDEO_FAIL';

const GET_BRAND_IMAGE = 'brandOnboarding/GET_BRAND_IMAGE';
const GET_BRAND_IMAGE_SUCCESS = 'brandOnboarding/GET_BRAND_IMAGE_SUCCESS';
const GET_BRAND_IMAGE_FAIL = 'brandOnboarding/GET_BRAND_IMAGE_FAIL';

const SET_RUNNING_STEP = 'brandOnboarding/SET_RUNNING_STEP';
const SET_RUNNING_STEP_SUCCESS = 'brandOnboarding/SET_RUNNING_STEP_SUCCESS';
const SET_RUNNING_STEP_FAIL = 'brandOnboarding/SET_RUNNING_STEP_FAIL';

const GET_RUNNING_STEP = 'brandOnboarding/GET_RUNNING_STEP';
const GET_RUNNING_STEP_SUCCESS = 'brandOnboarding/GET_RUNNING_STEP_SUCCESS';
const GET_RUNNING_STEP_FAIL = 'brandOnboarding/GET_RUNNING_STEP_FAIL';

const GET_CUURENT_STEP = 'brandOnboarding/GET_CUURENT_STEP';
const GET_COMPLETED_STEP = 'brandOnboarding/GET_COMPLETED_STEP';

const VERIFY_OTP = 'verifyOtpMagicStore/VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'verifyOtpMagicStore/VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAIL = 'verifyOtpMagicStore/VERIFY_OTP_FAIL';

const CONTACT_US = 'verifyOtpMagicStore/CONTACT_US';
const CONTACT_US_SUCCESS = 'verifyOtpMagicStore/CONTACT_US_SUCCESS';
const CONTACT_US_FAIL = 'verifyOtpMagicStore/CONTACT_US_FAIL';

const ADDITIONAL_INFO = 'verifyOtpMagicStore/ADDITIONAL_INFO';
const ADDITIONAL_INFO_SUCCESS = 'verifyOtpMagicStore/ADDITIONAL_INFO_SUCCESS';
const ADDITIONAL_INFO_FAIL = 'verifyOtpMagicStore/ADDITIONAL_INFO_FAIL';

const SET_ADDITIONAL_INFO = 'verifyOtpMagicStore/SET_ADDITIONAL_INFO';
const SET_ADDITIONAL_INFO_SUCCESS = 'verifyOtpMagicStore/SET_ADDITIONAL_INFO_SUCCESS';
const SET_ADDITIONAL_INFO_FAIL = 'verifyOtpMagicStore/SET_ADDITIONAL_INFO_FAIL';

const initialState = {
    completedStep: 0,
    currentStep: 0
};

function getCompletedSubSteps(response){
    if(response && response.length){
        return response.filter(item => [3.1, 3.2, 3.3].includes(Number(item))).map(nItem => Number(nItem));
    }
    return [];
}


function getCompletedStepsOnPriority(response){
    if(response && response.length){
        return Number(response.filter(item => [1, 2, 3, 4].includes(Number(item))).sort((a,b) => b - a)[0]);
    }
    return 0;
}

export default function brandOnboarding(state = initialState, action = {}) {

    switch (action.type) {

        case CREATE_MERCHANT:
            return {
                ...state,
                merchantCreateLoding: true,
            };

        case CREATE_MERCHANT_SUCCESS:
            return {
                ...state,
                merchantCreateLoding: false,
                merchantCreate: action.result
            };

        case CREATE_MERCHANT_FAIL:
            return {
                ...state,
                merchantCreateLoding: false,
                merchantCreate: null,
                merchantCreateError: action.error
            };
        case UPDATE_MERCHANT:
            return {
                ...state,
                merchantUpdateLoding: true,
            };

        case UPDATE_MERCHANT_SUCCESS:
            return {
                ...state,
                merchantUpdateLoding: false,
                merchantUpdate: action.result
            };

        case UPDATE_MERCHANT_FAIL:
            return {
                ...state,
                merchantUpdateLoding: false,
                merchantUpdate: null,
                merchantUpdateError: action.error
            };
    
        case GET_BRAND_MERCHANT:
            return {
                ...state,
                merchantInfoLoading: true,
            };

        case GET_BRAND_MERCHANT_SUCCESS:
            return {
                ...state,
                merchantInfoLoading: false,
                merchantInfo: action.result
            };

        case GET_BRAND_MERCHANT_FAIL:
            return {
                ...state,
                merchantInfoLoading: false,
                merchantInfo: null,
                merchantInfoError: action.error
            };

        case SET_ADDITIONAL_INFO:
            return {
                ...state,
                setAdditionalInfoLoading: true,
            };

        case SET_ADDITIONAL_INFO_SUCCESS:
            return {
                ...state,
                setAdditionalInfoLoading: false,
                setAdditionalInfoStatus: action.result
            };

        case SET_ADDITIONAL_INFO_FAIL:
            return {
                ...state,
                setAdditionalInfoLoading: false,
                setAdditionalInfoStatus: null,
                setAdditionalInfoError: action.error
            };

        case ADDITIONAL_INFO:
            return {
                ...state,
                additionalInfoLoading: true,
            };

        case ADDITIONAL_INFO_SUCCESS:
            return {
                ...state,
                additionalInfoLoading: false,
                additionalInfo: action.result
            };

        case ADDITIONAL_INFO_FAIL:
            return {
                ...state,
                additionalInfoLoading: false,
                additionalInfo: null,
                additionalInfoError: action.error
            };

        case UPLOAD_BRAND_IMAGE:
            return {
                ...state,
                uploadingBrandImage: true,
            };
    
        case UPLOAD_BRAND_IMAGE_SUCCESS:
            return {
                ...state,
                uploadingBrandImage: false,
                uploadResult: action.result
            };
    
        case UPLOAD_BRAND_IMAGE_FAIL:
            return {
                ...state,
                uploadingBrandImage: false,
                uploadResult: null,
                uploadError: action.error
            };

        case UPLOAD_BRAND_DOCUMENT:
            return {
                ...state,
                uploadingBrandDocument: true,
            };

        case UPLOAD_BRAND_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadingBrandDocument: false,
                uploadDocumentResult: action.result
            };

        case UPLOAD_BRAND_DOCUMENT_FAIL:
            return {
                ...state,
                uploadingBrandDocument: false,
                uploadDocumentResult: null,
                uploadDocumentError: action.error
            };

        case UPLOAD_BRAND_VIDEO:
            return {
                ...state,
                uploadingBrandVideo: true,
            };
    
        case UPLOAD_BRAND_VIDEO_SUCCESS:
            return {
                ...state,
                uploadingBrandVideo: false,
                uploadVideoResult: action.result
            };
    
        case UPLOAD_BRAND_VIDEO_FAIL:
            return {
                ...state,
                uploadingBrandVideo: false,
                uploadVideoResult: null,
                uploadVieoError: action.error
            };

        case GET_BRAND_IMAGE:
            return {
                ...state,
                loadingBrandImage: true,
            };
    
        case GET_BRAND_IMAGE_SUCCESS:
            return {
                ...state,
                loadingBrandImage: false,
                brandImages: action.result
            };
    
        case GET_BRAND_IMAGE_FAIL:
            return {
                ...state,
                loadingBrandImage: false,
                brandImages: null,
                brandImagesError: action.error
            };

        case ONBOARD_BRAND:
            return {
                ...state,
                loadingOnboardingBrand: true,
            };
    
        case ONBOARD_BRAND_SUCCESS:
            return {
                ...state,
                loadingOnboardingBrand: false,
                onboard: action.result
            };
    
        case ONBOARD_BRAND_FAIL:
            return {
                ...state,
                loadingOnboardingBrand: false,
                onboard: null,
                onboardingBrandError: action.error
            };
    
        case GET_COMPLETED_STEP:
            return {
                ...state,
                completedStep: action.completedStep
            }
        
        case GET_CUURENT_STEP:
            return {
                ...state,
                currentStep: action.currentStep
            }

        case GET_UPLOADED_DOCUMENTS:
            return {
                ...state,
                loadingUploadedDocs: true,
            };
    
        case GET_UPLOADED_DOCUMENTS_SUCCESS:
            return {
                ...state,
                loadingUploadedDocs: false,
                brandDocs: action.result
            };
    
        case GET_UPLOADED_DOCUMENTS_FAIL:
            return {
                ...state,
                loadingUploadedDocs: false,
                brandDocs: null,
                uploadedDocsError: action.error
            };

        case SET_RUNNING_STEP:
            return {
                ...state,
                loadingSetRunningStep: true,
            };
    
        case SET_RUNNING_STEP_SUCCESS:
            return {
                ...state,
                loadingSetRunningStep: false,
                currentStep: action.result
            };
    
        case SET_RUNNING_STEP_FAIL:
            return {
                ...state,
                loadingSetRunningStep: false,
                currentStep: null,
                runningStepError: action.error
            };
    
        case GET_RUNNING_STEP:
            return {
                ...state,
                loadingSteps: true,
            };
    
        case GET_RUNNING_STEP_SUCCESS:
            return {
                ...state,
                loadingSteps: false,
                completedStep: getCompletedStepsOnPriority(action.result),
                completedSubSteps: getCompletedSubSteps(action.result)
            };
    
        case GET_RUNNING_STEP_FAIL:
            return {
                ...state,
                loadingSteps: false,
                completedStep: null,
                stepsError: action.error
            };
    
        case VERIFY_OTP:
            return {
                ...state,
                verifyingOtp: true,
                otpVerified: false,
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                otpVerified: true,
                brands: action.result && action.result.brands ? action.result.brands : action.result,
                verifyingOtp: false,
                verifyOtpError:null
            };
        case VERIFY_OTP_FAIL:
            return {
                ...state,
                otpVerified: false,
                verifyingOtp: false,
                verifyOtpError: action.error
            };

        case CONTACT_US:
            return {
                ...state,
                contactUsLoading: true,
            };
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                contactUsResult: action.result,
                contactUsLoading: false,
                contactUsError: null
            };
        case CONTACT_US_FAIL:
            return {
                ...state,
                contactUsLoading: false,
                contactUsError: action.error
            };

        default:
            return state;
    }
}


export function createMerchant(brandDetails){

    return {
        types: [CREATE_MERCHANT, CREATE_MERCHANT_SUCCESS, CREATE_MERCHANT_FAIL],
        promise: (client) => client.default.post('/createMerchant', {
          data: brandDetails
        })
      };
}


export function updateMerchant(brandDetails){
    const userDetails = getUserDetail();
    const createdBy = `updateMerchant_${userDetails}`
    return {
        types: [UPDATE_MERCHANT, UPDATE_MERCHANT_SUCCESS, UPDATE_MERCHANT_FAIL],
        promise: (client) => client.default.post('/updateMerchant', {
          data: brandDetails,
          createdBy
        })
      };
}


export function getBrandMerchant(brandDetails){

    return {
        types: [GET_BRAND_MERCHANT, GET_BRAND_MERCHANT_SUCCESS, GET_BRAND_MERCHANT_FAIL],
        promise: (client) => client.default.post('/getBrandMerchant', {
          data: brandDetails
        })
      };
}


export function getUploadedDocument(merchant_id){

    return {
        types: [GET_UPLOADED_DOCUMENTS, GET_UPLOADED_DOCUMENTS_SUCCESS, GET_UPLOADED_DOCUMENTS_FAIL],
        promise: (client) => client.default.post('/getUploadedDocument', {
          data: {merchant_id: merchant_id}
        })
      };
}

export function onboardBrand(onboardingDetails){

    return {
        types: [ONBOARD_BRAND, ONBOARD_BRAND_SUCCESS, ONBOARD_BRAND_FAIL],
        promise: (client) => client.default.post('/onboardBrand', {
          data: onboardingDetails
        })
      };
}


export function submitAdditionalInfo(aov, optionText, merchant_id){

    return {
        types: [SET_ADDITIONAL_INFO, SET_ADDITIONAL_INFO_SUCCESS, SET_ADDITIONAL_INFO_FAIL],
        promise: (client) => client.default.post('/submitAdditionalDetails', {
          data: {aov, optionText, merchant_id}
        })
      };
}


export function getAdditionalInfo(merchant_id){

    return {
        types: [ADDITIONAL_INFO, ADDITIONAL_INFO_SUCCESS, ADDITIONAL_INFO_FAIL],
        promise: (client) => client.default.post('/getAdditionalDetails', {
          data: {merchant_id: merchant_id}
        })
      };
}

export function contactUs(brand_name, contact_no, email_id){

    return {
        types: [CONTACT_US, CONTACT_US_SUCCESS, CONTACT_US_FAIL],
        promise: (client) => client.default.post('/contactUs', {
          data: {
            "brand_name": brand_name, 
            "contact_no": Number(contact_no), 
            "email_id": email_id
            }
        })
      };
}

export function getBrandImages(user_id){

    return {
        types: [GET_BRAND_IMAGE, GET_BRAND_IMAGE_SUCCESS, GET_BRAND_IMAGE_FAIL],
        promise: (client) => client.default.post('/getBrandImage', {
          data: {user_id: Number(user_id)}
        })
      };
}


export function uploadBrandImages(file, imageGroup, filename, merchant_id, user_id){
    
    var data = new FormData();
    data.append('merchant_single_photo', file, file.name ? file.name : filename);
    data.append('imageGroup', imageGroup);
    data.append('user_id', user_id);
    data.append('merchant_id', merchant_id);

    return {
        types: [UPLOAD_BRAND_IMAGE, UPLOAD_BRAND_IMAGE_SUCCESS, UPLOAD_BRAND_IMAGE_FAIL],
        promise: (client) => client.default.post('/uploadBrandImage', {
          data: data
        })
      };
}


export function uploadBrandVerificationDocs(file, filename, merchant_id, legalName, registeredAddress){
    
    var data = new FormData();
    data.append('merchant_single_photo', file, file.name ? file.name : filename);
    data.append('legalName', legalName);
    data.append('registeredAddress', registeredAddress);
    data.append('merchant_id', merchant_id);

    return {
        types: [UPLOAD_BRAND_DOCUMENT, UPLOAD_BRAND_DOCUMENT_SUCCESS, UPLOAD_BRAND_DOCUMENT_FAIL],
        promise: (client) => client.default.post('/uploadBrandVerificationDocument', {
          data: data
        })
      };
}


export function uploadBrandVideo(file, imageGroup, filename, merchant_id, user_id){
    
    var data = new FormData();
    data.append('merchant_single_photo', file, file.name ? file.name : filename);
    data.append('imageGroup', imageGroup);
    data.append('user_id', user_id);
    data.append('merchant_id', merchant_id);

    return {
        types: [UPLOAD_BRAND_VIDEO, UPLOAD_BRAND_VIDEO_SUCCESS, UPLOAD_BRAND_VIDEO_FAIL],
        promise: (client) => client.default.post('/uploadBrandVideo', {
          data: data
        })
    };
}

export function getActiveStep(currentStep){
    return {
        type: GET_CUURENT_STEP,
        currentStep
    }
}


export function setRunningStep(merchant_id, currentStep){
    return {
        types: [SET_RUNNING_STEP, SET_RUNNING_STEP_SUCCESS, SET_RUNNING_STEP_FAIL],
        promise: (client) => client.default.post('/setProgress', {
          data: {progress: currentStep, merchant_id}
        })
    };
}

export function getRunningStep(merchant_id){
    return {
        types: [GET_RUNNING_STEP, GET_RUNNING_STEP_SUCCESS, GET_RUNNING_STEP_FAIL],
        promise: (client) => client.default.post('/getProgress', {
          data: {merchant_id}
        })
    };
}

export function getCompletedStep(completedStep){
    return {
        type: GET_COMPLETED_STEP,
        completedStep
    }
}

export function verifyOtp( otp, phone) {
    return {
        types: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL],
        promise: (client) => client.default.post('/validateBrandOTP',{
            data:{ otp, phone}
        })
    };
}

// export function uploadMultiple(files, imageGroup){
//     // var data = new FormData();
//     // for(let i = 0; i < files.length; ++i)
//     //   data.append('merchant_multiple_photos', files[i], files[i].name);
//     // data.append('imageGroup', imageGroup);
//     // data.append('accessToken', accessToken);
//     // return {
//     //   types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
//     //   promise: (client) => client.default.post('/uploadMultipleFiles',{
//     //     data: data,
//     //   })
//     // };
  
//     // ** using single file upload api multiple times
//     const count = files.length;
//     let promise = [];
//     for(let i = 0; i < count; ++i){
//       let data = new FormData();
//       data.append('merchant_single_photo', files[i], files[i].name);
//       data.append('imageGroup', imageGroup);
//       data.append('accessToken', accessToken);
//       promise.push((client) => client.default.post('/uploadFile', {data}));
//     }
  
//     return {
//       types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
//       repeat: count,
//       promise,
//       totalUploadCount: count,
//     };
//   }
