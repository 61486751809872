
const LAUNCH_REQUEST = 'groupBuy/LAUNCH_REQUEST';
const LAUNCH_REQUEST_SUCCESS = 'groupBuy/LAUNCH_REQUEST_SUCCESS';
const LAUNCH_REQUEST_FAIL = 'groupBuy/LAUNCH_REQUEST_FAIL';

const LOAD = 'groupBuy/LOAD';
const LOAD_SUCCESS = 'groupBuy/LOAD_SUCCESS';
const LOAD_FAIL = 'groupBuy/LOAD_FAIL';

const LOAD_PLANS = 'groupBuy/LOAD_PLANS';
const LOAD_PLANS_SUCCESS = 'groupBuy/LOAD_PLANS_SUCCESS';
const LOAD_PLANS_FAIL = 'groupBuy/LOAD_PLANS_FAIL';

const ADD_MONEY_REQUEST = 'groupBuy/ADD_MONEY_REQUEST';
const ADD_MONEY_REQUEST_SUCCESS = 'groupBuy/ADD_MONEY_REQUEST_SUCCESS';
const ADD_MONEY_REQUEST_FAIL = 'groupBuy/ADD_MONEY_REQUEST_FAIL';

const GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD = 'groupBuy/GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD';
const GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_SUCCESS = 'groupBuy/GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_SUCCESS';
const GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_FAIL = 'groupBuy/GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_FAIL';

const SHOW_LAUNCH_GROUP_BUY = 'groupBuy/SHOW_LAUNCH_GROUP_BUY';

const GET_MERCHANT_CREDIT_LOAD = 'groupBuy/GET_MERCHANT_CREDIT_LOAD';
const GET_MERCHANT_CREDIT_LOAD_SUCCESS = 'groupBuy/GET_MERCHANT_CREDIT_LOAD_SUCCESS';
const GET_MERCHANT_CREDIT_LOAD_FAIL = 'groupBuy/GET_MERCHANT_CREDIT_LOAD_FAIL';

const GET_GROUP_BUY_SOLD_DATA = 'groupBuy/GET_GROUP_BUY_SOLD_DATA';
const GET_GROUP_BUY_SOLD_DATA_SUCCESS = 'groupBuy/GET_GROUP_BUY_SOLD_DATA_SUCCESS';
const GET_GROUP_BUY_SOLD_DATA_FAIL = 'groupBuy/GET_GROUP_BUY_SOLD_DATA_FAIL';

const GROUPBUY_DETAILS = 'groupBuy/GROUPBUY_DETAILS';
const GROUPBUY_DETAILS_SUCCESS = 'groupBuy/GROUPBUY_DETAILS_SUCCESS';
const GROUPBUY_DETAILS_FAIL = 'groupBuy/GROUPBUY_DETAILS_FAIL';

const initialState = {
  launching: false,
  launched: false,
  showLaunchGroupBuy: false,
}

export default function groupBuy(state = initialState, action = {}) {
  switch (action.type) {

    case LOAD:
      return {
        ...state,
        loading: true
      }
    case LOAD_SUCCESS:
      if(Array.isArray(action.result) && action.result.length && (action.result[0].vouchersRedeemed || action.result[0].vouchersRedeemed == 0) 
          && action.result[0].groupBuyGoal && action.result[0].groupBuyStartDate)
        return {
          ...state,
          loading: false,
          loaded: true,
          groupBuyVoucherStats: action.result
        }
      else   
        return {
          ...state,
          loading: false,
          loaded: true,
          groupBuyVoucherStats : null,
        }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loadError: action.error,
        groupBuyVoucherStats: null,
      }  

    case LOAD_PLANS:
     return{
       ...state,
       loadingPlans:true
     }  

    case LOAD_PLANS_SUCCESS:

    if(Array.isArray(action.result) && action.result.length && action.result[0].packagePrice 
       && (action.result[0].discount || action.result[0].discount == 0 ) && action.result[0].packageId)
        return{
          ...state,
          loadingPlans: false,
          loadedPlans: true,
          groupBuyPlans: action.result,
        } 
    else
        return{
          ...state,
          loadedPlans: false,
          loadedPlans: true,
          groupBuyPlans: null
        }  

    case LOAD_PLANS_FAIL:
      return{
        ...state,
        loadedPlans:false,
        loadingPlans:false,
        loadPlansError: action.error
      } 

    case LAUNCH_REQUEST:
      return {
        ...state,
        launching: true
      }
    case LAUNCH_REQUEST_SUCCESS:
      return {
        ...state,
        launching: false,
        launched: true,
      }
    case LAUNCH_REQUEST_FAIL:
      return {
        ...state,
        launched: false,
        launching: false,
        error: action.error,
      }  
      case ADD_MONEY_REQUEST:
      return {
        ...state,
        sendingMail: true
      }
    case ADD_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        sendingMail: false,
        sentMail: true,
      }
    case ADD_MONEY_REQUEST_FAIL:
      return {
        ...state,
        sendingMail: false,
        sentMail: false,
        error: action.error,
      } 
      
    case GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD:
      return{
        ...state,
        activeGroupBuyInformationLoading: true
      }  
    
    case GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_SUCCESS:
      return{
        ...state,
        activeGroupBuyInformationLoading: false,
        activeGroupBuyInformationLoaded: true,
        activeGroupBuyInformation: action.result 
      }  

    case GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_FAIL:
      return{
        ...state,
        activeGroupBuyInformationLoading: false,
        activeGroupBuyInformationLoaded: false,
        activeGroupBuyInformationError: action.error
      }
      
    case SHOW_LAUNCH_GROUP_BUY :
      return{
        ...state,
        showLaunchGroupBuy : action.showLaunchGroupBuy
      }
    
    case GET_MERCHANT_CREDIT_LOAD:
      return{
        ...state,
        merchantCreditAmountDataLoading : true,
      }

    case GET_MERCHANT_CREDIT_LOAD_SUCCESS:
      return{
        ...state,
        merchantCreditAmountDataLoading : false,
        merchantCreditAmountData : action.result,
      }

    case GET_MERCHANT_CREDIT_LOAD_FAIL:
      return{
        ...state,
        merchantCreditAmountData : [],
        merchantCreditAmountDataError : action.error
      }

    case GET_GROUP_BUY_SOLD_DATA:
      return{
        ...state,
        groupBuyPlansSoldDataLoading: true,
      }

    case GET_GROUP_BUY_SOLD_DATA_SUCCESS:
      return{
        ...state,
        groupBuyPlansSoldDataLoading: false,
        groupBuyPlansSoldData: action.result,
      }
    
    case GET_GROUP_BUY_SOLD_DATA_FAIL:
      return{
        groupBuyPlansSoldDataLoading: false,
        groupBuyPlansSoldDataError: action.error,
        groupBuyPlansSoldData: {},
      }

    case GROUPBUY_DETAILS:
      return {
        ...state,
        groupBuyDetailsLoading: true
      }
    case GROUPBUY_DETAILS_SUCCESS:
      const groupBuyPlansByPackageId = state.groupBuyPlans.reduce((agg, curr, idx)=>{
        agg[curr.packageId]=curr
          return agg
      },{})
      let groupBuyDetails = action.result.reduce((agg, curr, idx)=>{
          agg[action.menu_amounts[curr.voucherId]]={...(agg[action.menu_amounts[curr.voucherId]] || {}),[curr.merchantPackageId]: {...groupBuyPlansByPackageId[curr.merchantPackageId], ...curr}}
          return agg
      },{})
      return {
        ...state,
        groupBuyDetailsLoading: false,
        groupBuyDetails,
        groupBuyDetailsError: null
      }
    
    case GROUPBUY_DETAILS_FAIL:
        return {
          ...state,
          groupBuyDetailsLoading: false,
          groupBuyDetails: action.error,
          groupBuyDetailsError: action.error
        }
    
    default:
      return state;
  }
}


export function launchGroupBuyRequest(accessToken,merchantDetails,packageChosenByMerchant,merchantEmails, requestId ,loanAmount, country){
  return {
    types: [LAUNCH_REQUEST, LAUNCH_REQUEST_SUCCESS, LAUNCH_REQUEST_FAIL],
    promise: (client) => client.default.post('/launchGroupBuyRequest',{
      data: {accessToken,merchantDetails,packageChosenByMerchant,merchantEmails, requestId, loanAmount , country}
    })
  }
}

export function getGroupBuyPlans(accessToken){
  return{
    types: [LOAD_PLANS, LOAD_PLANS_SUCCESS, LOAD_PLANS_FAIL],
    promise: (client) => client.default.post('/getGroupBuyPlans',{
      data: {accessToken}
    })
  }
}

export function launchGroupBuyModal(showLaunchGroupBuy){
    return{
      type: SHOW_LAUNCH_GROUP_BUY,
      showLaunchGroupBuy
    }
}

export const launchGroupBuyFromLeaderboard = (accessToken) => {
    getGroupBuyPlans(accessToken);
    launchGroupBuyModal(true);
}

export const launchGroupByWrapper = (launchGroupBuyModal, getGroupBuyPlans,
  groupBuyPlans, accessToken) => {
    if(!groupBuyPlans) {
      getGroupBuyPlans(accessToken);
    }
    launchGroupBuyModal(true);
}

export function getGroupBuyVoucherStats(accessToken){
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getGroupBuyStatus',{
      data: {accessToken}
    })
  }
}

export function addMoneySendMailGroupBuyRequest(accessToken,merchantDetails,packageChosenByMerchant,merchantEmails){
  return {
    types: [ADD_MONEY_REQUEST, ADD_MONEY_REQUEST_SUCCESS, ADD_MONEY_REQUEST_FAIL],
    promise: (client) => client.default.post('/addMoneySendMailGroupBuyRequest',{
      data: {accessToken,merchantDetails,packageChosenByMerchant,merchantEmails}
    })
  }
}

export function getActiveGroupBuyInformation(accessToken, localityId){
  return{
    types: [GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD, GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_SUCCESS, GET_ACTIVE_GROUP_BUY_INFORMATION_LOAD_FAIL],
    promise: (client) => client.default.post('/getActiveGroupBuyInformation',{
      data: {accessToken, localityId}
    })
  }
}

export function getMerchantCreditAmount(accessToken ,country){
  return{
    types: [GET_MERCHANT_CREDIT_LOAD, GET_MERCHANT_CREDIT_LOAD_SUCCESS, GET_MERCHANT_CREDIT_LOAD_FAIL],
    promise: (client) => client.default.post('/getMerchantCreditAmount',{
      data: { accessToken ,country}
    })
  }
}

export function getGroupBuyPlansSold(accessToken, city){
  return{
    types: [GET_GROUP_BUY_SOLD_DATA, GET_GROUP_BUY_SOLD_DATA_SUCCESS, GET_GROUP_BUY_SOLD_DATA_SUCCESS],
    promise: (client) => client.default.post('/getGroupBuyPlansSold',{
        data: {accessToken, city}
    })
  }
}

export function getGroupBuyRoi(accessToken, liveVouchers, menu_amounts){
  return {
    types:[GROUPBUY_DETAILS, GROUPBUY_DETAILS_SUCCESS, GROUPBUY_DETAILS_FAIL],
    promise: client=>client.default.post('/getGroupBuyRoi',{
      data: {accessToken, liveVouchers:liveVouchers.map(voucherId=>({"voucherId":voucherId}))}
    }),
    menu_amounts
  }
}


