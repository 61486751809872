import React, {Component} from "react";
import './OrderHereHeader.css'

export default class OrderhereHeader extends Component {

    render() {
        return (
            <div className= "orderhere-header-container" >

                <div className= "orderhere-header-dukaan">
                    <span className="orderhere-header-main-text">OrderHere</span>
                    India's most popular dukaan app.
                </div>

                {this.props.storeName &&
                    <div className="orderhere-hey-text">
                        Hey, <span style={{fontFamily: "robotomedium"}}> {this.props.storeName}</span>
                    </div>
                }
                <div className="orderhere-zero-commission-text">
                    Start selling online at ZERO commission
                </div>
                <div className="orderhere-free-website-text">
                    Create your <span style={{fontStyle: "italic", fontType:"robotobold", marginRight: "4px"}}>FREE </span> personal website and sell online in just 30 seconds!
                </div>

            </div>
        )
    }
}


