const LOAD_VOUCHER_DATA = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_DATA';
const LOAD_VOUCHER_DATA_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_DATA_SUCCESS';
const LOAD_VOUCHER_DATA_FAIL = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_DATA_FAIL';

const LOAD_REPEAT_FREQUENCY = 'businessSummaryOnlineMerchant/LOAD_REPEAT_FREQUENCY';
const LOAD_REPEAT_FREQUENCY_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_REPEAT_FREQUENCY_SUCCESS';
const LOAD_REPEAT_FREQUENCY_FAILED = 'businessSummaryOnlineMerchant/LOAD_REPEAT_FREQUENCY_FAILED';

const LOAD_CUSTOMER_PROFILE = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PROFILE';
const LOAD_CUSTOMER_PROFILE_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PROFILE_SUCCESS';
const LOAD_CUSTOMER_PROFILE_FAIL = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PROFILE_FAIL';

const LOAD_CUSTOMER_PREFERENCE = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PREFERENCE';
const LOAD_CUSTOMER_PREFERENCE_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PREFERENCE_SUCCESS';
const LOAD_CUSTOMER_PREFERENCE_FAIL = 'businessSummaryOnlineMerchant/LOAD_CUSTOMER_PREFERENCE_FAIL';

const LOAD_VOUCHERS_DATA = 'businessSummaryOnlineMerchant/LOAD_VOUCHERS_DATA';
const LOAD_VOUCHERS_DATA_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_VOUCHERS_DATA_SUCCESS';
const LOAD_VOUCHERS_DATA_FAIL = 'businessSummaryOnlineMerchant/LOAD_VOUCHERS_DATA_FAIL';

const LOAD_FUNNEL_DATA = 'businessSummaryOnlineMerchant/LOAD_FUNNEL_DATA';
const LOAD_FUNNEL_DATA_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_FUNNEL_DATA_SUCCESS';
const LOAD_FUNNEL_DATA_FAIL = 'businessSummaryOnlineMerchant/LOAD_FUNNEL_DATA_FAIL';

const LOAD_CITY_DISTRIBUTION_DATA = 'businessSummaryOnlineMerchant/LOAD_CITY_DISTRIBUTION_DATA';
const LOAD_CITY_DISTRIBUTION_DATA_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_CITY_DISTRIBUTION_DATA_SUCCESS';
const LOAD_CITY_DISTRIBUTION_DATA_FAIL = 'businessSummaryOnlineMerchant/LOAD_CITY_DISTRIBUTION_DATA_FAIL';

const LOAD_NEW_CUSTOMER_PREFERENCE = 'businessSummaryOnlineMerchant/LOAD_NEW_CUSTOMER_PREFERENCE';
const LOAD_NEW_CUSTOMER_PREFERENCE_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_NEW_CUSTOMER_PREFERENCE_SUCCESS';
const LOAD_NEW_CUSTOMER_PREFERENCE_FAIL = 'businessSummaryOnlineMerchant/LOAD_NEW_CUSTOMER_PREFERENCE_FAIL';

const UPLOAD_CSV = 'businessSummaryOnlineMerchant/UPLOAD_CSV';
const CSV_UPLOADED = 'businessSummaryOnlineMerchant/CSV_UPLOADED';
const CSV_FAILED = 'businessSummaryOnlineMerchant/CSV_FAILED';


const LOAD_ONLINE_FEED = 'businessSummaryOnlineMerchant/LOAD_ONLINE_FEED';
const LOAD_ONLINE_FEED_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_ONLINE_FEED_SUCCESS';
const LOAD_ONLINE_FEED_FAIL = 'businessSummaryOnlineMerchant/LOAD_ONLINE_FEED_FAIL';

const LOAD_ONLINE_CATEGORY_IMPRESSION = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION';
const LOAD_ONLINE_CATEGORY_IMPRESSION_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION_SUCCESS';
const LOAD_ONLINE_CATEGORY_IMPRESSION_FAIL = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION_FAIL';


const LOAD_VOUCHER_UPLOAD_STATUS = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_UPLOAD_STATUS';
const LOAD_VOUCHER_UPLOAD_STATUS_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_UPLOAD_STATUS_SUCCESS';
const LOAD_VOUCHER_UPLOAD_STATUS_FAIL = 'businessSummaryOnlineMerchant/LOAD_VOUCHER_UPLOAD_STATUS_FAIL';

const LOAD_ONLINE_CATEGORY_IMPRESSION_CPC = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION_CPC'
const LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_SUCCESS = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_SUCCESS'
const LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_FAIL = 'businessSummaryOnlineMerchant/LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_FAIL'

const initialState = {
};

export default function businessSummaryOnlineMerchant(state = initialState, action = {}){
    switch (action.type) {
      
        case LOAD_VOUCHER_DATA:
          return {
            ...state,
            loadingAOVData: true
          };

        case LOAD_VOUCHER_DATA_SUCCESS:
          return {
            ...state,
            loadingAOVData: false,
            loadedAOVData: true,
            aovData: action.result,
            error: null
          };
          
        case LOAD_VOUCHER_DATA_FAIL:
          return {
            ...state,
            loadingAOVData: false,
            loadedAOVData: false,
            aovData: null,
            error: action.error
          };

          case UPLOAD_CSV:
            return {
              ...state,
              uploading: true
            };
          case CSV_UPLOADED:
            return {
              ...state,
              uploading: false,
              uploadResult: action.result && action.result.batchId ? action.result.batchId : '',
              uploadError: null,
            };
          case CSV_FAILED:
            return {
              ...state,
              uploading: false,
              uploadResult: null,
              uploadError: action.error
           };

        case LOAD_CUSTOMER_PROFILE:
          return {
            ...state,
            loadingCustomerProfile: true
          };
        
        case LOAD_CUSTOMER_PROFILE_SUCCESS:
          return {
            ...state,
            loadingCustomerProfile:  false,
            loadedCustomerProfile: true,
            customerProfile: action.result,
            error: null
          };

        case LOAD_CUSTOMER_PROFILE_FAIL:
          return {
            ...state,
            loadingCustomerProfile: false,
            loadedCustomerProfile: false,
            customerProfile: null,
            error: action.error
          };

        case LOAD_CUSTOMER_PREFERENCE:
          return{
            ...state,
            loadingPreference: true
          };

        case LOAD_ONLINE_CATEGORY_IMPRESSION_CPC:
          return{
            ...state,
            loadingCategoryImpressionCpc: true,
          };
        case LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_SUCCESS:
          return{
            ...state,
            categoryImpressionCPC: action.result.retailCpc,
            loadingCategoryImpressionCpc: false,
          };
        case LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_FAIL:
          return{
            ...state,
            categoryImpressionCPC: null,
            loadingCategoryImpressionCpc:false,
          };
        case LOAD_CUSTOMER_PREFERENCE_SUCCESS:
          return{
            ...state,
            loadingPreference: false,
            loadedPreference: true,
            preferenceData: action.result,
            error: null
          };

        case LOAD_CUSTOMER_PREFERENCE_FAIL:
          return{
            ...state,
            loadingPreference: false,
            loadedPreference: false,
            preferenceData: null,
            error: action.error
          };

        case LOAD_VOUCHER_DATA:
          return{
            ...state,
            loadingVouchers: true
          };

        case LOAD_VOUCHERS_DATA_SUCCESS:
          return{
            ...state,
            loadingVouchers: false,
            loadedVouchers: true,
            vouchersData: action.result,
            error: null
          };

        case LOAD_VOUCHERS_DATA_FAIL:
          return {
            ...state,
            loadingVouchers:  false,
            loadedVouchers: false,
            vouchersData: null,
            error: action.error
          };

        case LOAD_FUNNEL_DATA:
          return{
            ...state,
            loadingFunnel: true
          };

        case LOAD_FUNNEL_DATA_SUCCESS:
          return{
            ...state,
            loadingFunnel: false,
            loadedFunnel: true,
            funnelData : action.result,
            error: null
          };

        case LOAD_VOUCHER_DATA_FAIL:
          return{
            ...state,
            loadingFunnel: false,
            loadedFunnel: false,
            funnelData: null,
            error: action.error
          };

        case LOAD_CITY_DISTRIBUTION_DATA:
          return{
            ...state,
            loadingCityData: true
          };

        case LOAD_CITY_DISTRIBUTION_DATA_SUCCESS:
          return{
            ...state,
            loadingCityData: false,
            loadedCity: true,
            cityData: action.result,
            error: null
          };

        case LOAD_CITY_DISTRIBUTION_DATA_FAIL:
          return{
            ...state,
            loadingCityData: false,
            loadedCity: false,
            cityData: null,
            error: action.error
          };

        case LOAD_NEW_CUSTOMER_PREFERENCE:
          return{
            ...state,
            cpLoading: true
          };

        case LOAD_NEW_CUSTOMER_PREFERENCE_SUCCESS:
          return{
            ...state,
            cpLoading: false,
            cpLoaded: true,
            visibleShowMore: (action.result.finalResponse || []).length > 2,
            subcategoryData: action.offsetPref !== 1 && !action.clearList ? [...state.subcategoryData, ...(action.result && action.result.finalResponse ? action.result.finalResponse : [])] : (action.result && action.result.finalResponse ? action.result.finalResponse : []),
            totalNumberOfPages: action.result && action.result.totalNumberOfPages ? action.result.totalNumberOfPages : 0,
            error: null
          };

        case LOAD_NEW_CUSTOMER_PREFERENCE_FAIL:
          return {
            ...state,
            cpLoading: false,
            cpLoaded: false,
            subcategoryData: null,
            error: action.error
          };
        case LOAD_ONLINE_FEED:
          return {
            ...state,
            loadingOnlineFeed : true
          };
    
        case LOAD_ONLINE_FEED_SUCCESS:
          return {
            ...state,
            loadingOnlineFeed: false,
            loadedOnlineFeed: true,
            onlineFeed: action.result,
            retailError: null
          };
    
        case LOAD_ONLINE_FEED_FAIL:
          return {
            ...state,
            loadingOnlineFeed: false,
            loadedOnlineFeed: false,
            onlineFeed: null,
            onlineError: action.error
          };
        case LOAD_ONLINE_CATEGORY_IMPRESSION:
          return{
            ...state,
            loadingCategoryImpression: true,
          };

        case LOAD_ONLINE_CATEGORY_IMPRESSION_SUCCESS:
          return{
            ...state,
            categoryImpression: action.result.viewsAndCtr,
            loadingCategoryImpression: false,
          };

        case LOAD_ONLINE_CATEGORY_IMPRESSION_FAIL:
          return{
            ...state,
            categoryImpression: null,
            loadingCategoryImpression:false,
          };
        case LOAD_VOUCHER_UPLOAD_STATUS:
          return{
            ...state,
            loadingVoucherStatus: true,
          };

        case LOAD_VOUCHER_UPLOAD_STATUS_SUCCESS:
          return{
            ...state,
            voucherUploadStatus: action.result,
            loadingVoucherStatus: false,
          };

        case LOAD_VOUCHER_UPLOAD_STATUS_FAIL:
          return{
            ...state,
            voucherUploadStatus: null,
            loadingVoucherStatus:false,
          };
        case LOAD_REPEAT_FREQUENCY:
          return {
            ...state,
            loadingRepeatFrequency: true
          };
        case LOAD_REPEAT_FREQUENCY_SUCCESS:
          return {
            ...state,
            loadingRepeatFrequency: false,
            loadedRepeatFrequency: true,
            repeatFrequencyData: action.result,
            error: null
          };
        case LOAD_REPEAT_FREQUENCY_FAILED:
          return{
            ...state,
            loadingRepeatFrequency: false,
            loadedRepeatFrequency: false,
            repeatFrequencyError: action.error
          };
        default:
            return state;
    }    
}

export function getVouchersData(accessToken, startDate, endDate, flag){
    return {
      types:[LOAD_VOUCHER_DATA, LOAD_VOUCHER_DATA_SUCCESS, LOAD_VOUCHER_DATA_FAIL],
      promise: client => client.default.post('/getOnlineAovAndTxn',{
        data:{accessToken, startDate, endDate, flag}
      })
    };
}

export function getCustomerProfile(accessToken, startDate, endDate, flag){
    return {
      types:[LOAD_CUSTOMER_PROFILE, LOAD_CUSTOMER_PROFILE_SUCCESS, LOAD_CUSTOMER_PROFILE_FAIL],
      promise: client => client.default.post('/getOnlineCustomerProfile',{
        data:{accessToken, startDate, endDate, flag}
      })
    }
}

export function getCustomerPreference(accessToken){
  return {
    types:[LOAD_CUSTOMER_PREFERENCE, LOAD_CUSTOMER_PREFERENCE_SUCCESS, LOAD_CUSTOMER_PREFERENCE_FAIL],
    promise: client => client.default.post('/getOnlineCustomerPreference',{
      data:{accessToken}
    })
  }
}

export function getOnlineVouchersDetails(accessToken, startDate, endDate, flag){
  return {
    types: [LOAD_VOUCHERS_DATA, LOAD_VOUCHERS_DATA_SUCCESS, LOAD_VOUCHERS_DATA_FAIL],
    promise: client => client.default.post('/getOnlineVoucherDetails',{
      data: {accessToken, startDate, endDate, flag}
    })
  }
}

export function getOnlineTransactionFunnel(accessToken, startDate, endDate, flag){
  return{
    types: [LOAD_FUNNEL_DATA,LOAD_FUNNEL_DATA_SUCCESS,LOAD_FUNNEL_DATA_FAIL],
    promise: client => client.default.post(`/getOnlineTransactionFunnel?start_date=${startDate}&end_date=${endDate}`,{
      data: {accessToken, flag}
    })
  }
}

export function getOnlineCityDistribution(accessToken, startDate, endDate, flag){
  return {
    types: [LOAD_CITY_DISTRIBUTION_DATA, LOAD_CITY_DISTRIBUTION_DATA_SUCCESS, LOAD_CITY_DISTRIBUTION_DATA_FAIL],
    promise: client => client.default.post('/getOnlineCityDistribution',{
      data: {accessToken, startDate, endDate, flag}
    })
  }
}

export function getOnlineNewCustomerPreference(accessToken, locality, offset, clearList){
  return {
    offsetPref : offset,
    clearList,
    types: [LOAD_NEW_CUSTOMER_PREFERENCE, LOAD_NEW_CUSTOMER_PREFERENCE_SUCCESS, LOAD_VOUCHERS_DATA_FAIL],
    promise: client => client.default.post('/getOnlineCustomerPreferenceV2',{
      data: {accessToken, locality, offset}
    })
  }
}

export function getOnlineCategoryImpressionCPC(accessToken, startDate, endDate){
  return {
    types : [LOAD_ONLINE_CATEGORY_IMPRESSION_CPC, LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_SUCCESS, LOAD_ONLINE_CATEGORY_IMPRESSION_CPC_FAIL],
    promise: (client) => client.default.post('/getOnlineImpressionCPC', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}

export function uploadOnlineVoucherCsv(accessToken, file, fileName){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : fileName);
  data.append('accessToken', accessToken);
  return {
    types:[UPLOAD_CSV, CSV_UPLOADED, CSV_FAILED],
    promise:client=>client.default.post('/uploadOnlineVoucherCsv',{data})
  }
}


export function getOnlineFeed(accessToken, startDate, endDate){
  return {
    types : [LOAD_ONLINE_FEED, LOAD_ONLINE_FEED_SUCCESS, LOAD_ONLINE_FEED_FAIL],
    promise: (client) => client.default.post('/getOnlineFeed', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}

export function getOnlineCategoryImpression(accessToken, startDate, endDate){
  return {
    types : [LOAD_ONLINE_CATEGORY_IMPRESSION, LOAD_ONLINE_CATEGORY_IMPRESSION_SUCCESS, LOAD_ONLINE_CATEGORY_IMPRESSION_FAIL],
    promise: (client) => client.default.post('/getOnlineCategoryImpression', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}


export function getOnlineVoucherStatus(accessToken){
  return {
    types : [LOAD_VOUCHER_UPLOAD_STATUS, LOAD_VOUCHER_UPLOAD_STATUS_SUCCESS, LOAD_VOUCHER_UPLOAD_STATUS_FAIL],
    promise: (client) => client.default.post('/getVoucherUploadStatus', {
      data: {accessToken}
    })
  
  }
}

export function getOnlineRepeatFrequencyData(accessToken, startDate, endDate){
  return {
    types: [LOAD_REPEAT_FREQUENCY, LOAD_REPEAT_FREQUENCY_SUCCESS, LOAD_REPEAT_FREQUENCY_FAILED],
    promise: (client) => client.default.post('/getOnlineRepeatFrequency',{
      data: {accessToken, startDate, endDate}
    })
  };
}