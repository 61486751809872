import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import CircularProgress from 'material-ui/CircularProgress';
import {push} from 'react-router-redux';
import {load as getMerchants} from '../../redux/modules/merchantList';
import LoginPopup from '../Claim/LoginPopup';
import BrandPopup from '../Claim/BrandPopup';
import {debounce, sendEvent, createMCPLog} from '../../helpers/Helpers';
import outlet_not_found from '../../images/add_outlet.svg';
import locationImg from '../../images/location.svg';
import downArrow from '../../images/left-arrow-copy-10.svg';
import language from './languageConst.js';


export default connect(store => ({
  storeslist: store.merchantList.data,
  storesloading: store.merchantList.loading,
  storesloaded: store.merchantList.loaded,
  storeserror: store.merchantList.error,
}), dispatch => bindActionCreators({
  getMerchants,
  push,
}, dispatch))
(class SearchBusinessLimited extends Component{
  constructor(props){
    super(props);
    this.state = {
      city: '*',
      locality: '*',
      query: '',
      merchantList: [],
      claim: false,
      showLoginPopup: false,
      showBrandPopup: false,
      blur: true,
      closeMerchantList: true,
    };
    
    // this.handleChange = debounce(this.handleChange, 500).bind(this);
    this.debouncedHandleChange = this.debouncedHandleChange.bind(this);
    this.handleSearchClick = debounce(this.handleSearchClick, 500).bind(this);
  }

  componentDidMount() {
      try {
          this.setState({ merchantList: JSON.parse(localStorage.getItem('merchantList')) || [] })
      } catch (err) {
          this.setState({ merchantList: [] })
      }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.storesloaded && nextProps.storeslist){
      if(nextProps.storeslist.length > 0){
        let pos = 30;
        let interval = 30;
    
        while (pos < nextProps.storeslist.length) {
            nextProps.storeslist.splice(pos, 0, {'merchant_name': null});
            pos += interval;
        }
        this.setState({
          merchantList: nextProps.storeslist,
          blur: false,
          closeMerchantList: false
        });
      }
      else{
        this.setState({
          merchantList: [],
        });
      }
    }
    else{
      this.setState({
        merchantList: [],
      });
    }
    if (nextProps.defaultMerchantName != '' && nextProps.defaultMerchantName != this.props.defaultMerchantName) {
      this.setState({
        merchantName: nextProps.defaultMerchantName 
      })
    }
  }

  handleChange(query) {
    this.setState({query: query});
      query = query && query.toLowerCase();
      let completeMerchantList = []
      try {
          const merchantListLS = JSON.parse(localStorage.getItem('merchantList'));
          if(merchantListLS) completeMerchantList = merchantListLS;
      } catch(err) {}

      const filterMerchantList = completeMerchantList.filter(merchant => {
        return merchant.merchant_name.toLowerCase().includes(query)
      });
      this.setState({
        merchantList: filterMerchantList,
        blur: false,
        closeMerchantList: false
      });

  }

  handleSearchClick(){
    this.handleChange(this.state.query);
    sendEvent({
      utm_campaign:localStorage.getItem('landingSource') || '',
      subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'search_store_magicstore':'search_store',
      page_type:'search_business_multiple'
    });
    createMCPLog({
      utm_campaign: localStorage.getItem('landingSource') || '',
      merchant_id: localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'search_store_magicstore':'search_store',
      phone: localStorage.getItem("subjectPhoneNumber") || '',
    });
  }

  debouncedHandleChange (e) {
    this.setState({
      merchantName: e.target.value
    })
    this.handleChange(e.target.value);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setCookie = (merchant) => {
    this.setState({closeMerchantList:true, storeNameVal: merchant.merchant_name});
    window.ga('send', 'event', 'Logout_page','Business_Name','Search');
    sendEvent({
      utm_campaign:localStorage.getItem('landingSource') || '',
      subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'select_merchant_magicstore':'select_merchant',
      page_type:'search_business_multiple'
    })
    createMCPLog({
      utm_campaign: localStorage.getItem('landingSource') || '',
      merchant_id: localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'select_merchant_magicstore':'select_merchant',
      phone: localStorage.getItem("subjectPhoneNumber") || '',
    });

    if(merchant.merchant_priority){
      this.setState({showBrandPopup: true})
    }
    else {
      if(typeof(Storage) !== "undefined") {
        localStorage.setItem('landingMerchant', merchant.merchant_name);
        localStorage.setItem('landingMerchantId', merchant.merchant_id);
        localStorage.setItem('landingMerchantRating', merchant.rating);
        localStorage.setItem('landingMerchantReviews', merchant.total_txn_count);
        localStorage.setItem('landingLocality', merchant.locality);
        localStorage.setItem('landingCity', merchant.city);
        localStorage.setItem('landingMerchantLogo', merchant.merchant_logo);
      }

      if(this.props.url)
        this.props.push(this.props.url);
      else if (this.props.magicstoreDIYS) {
        this.props.setMerchant(merchant)
        this.setState({
          merchantName: merchant.merchant_name,
          closeMerchantList: true
        })
      } else {
        this.props.changePage(this.props.onlineStore)
      }
    }
  }

  handleLandingClose = () => {
    this.setState({showLoginPopup:false, showBrandPopup:false});
  }

  toggleCloseMerchantList = (event) => {
    this.setState({closeMerchantList:!this.state.closeMerchantList});
  }

  render(){
    const languageConst = this.props.language ? language[this.props.language]: language["english"] ;

  const listStyle = {
      height: this.state.merchantList.length > 4 ? '250px': (this.state.merchantList.length * 50) + 100 + 'px',
      border: 'solid 1px #d8dae1',
      transitionDelay: '2s',
      boxShadow: '1px 1px 1px #e0e0e0',
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
  }

  const loaderStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
  

    return(
    <section className="search-holder" style={{display:this.props.magicstoreDIYS ? "inline" : "",width:this.props.magicstoreDIYS ? window.innerWidth < 768 ? "90%":"312px" : ''}}>
      <div className="search-query-holder" style={{marginLeft: window.innerWidth < 768 ? '' : ''}}>
        <input type="" name="" value={this.state.merchantName || ''} placeholder={languageConst.storeName} onChange = {this.debouncedHandleChange} onClick={this.toggleCloseMerchantList}/>
        { this.props.storesloading &&
            <MuiThemeProvider>
                <CircularProgress style={loaderStyle} size={20} className="search-loader" />
            </MuiThemeProvider>
        }
        { this.state.merchantList && this.state.merchantList.length > 0  && !this.state.closeMerchantList &&
          <section className="search-query-results" style={listStyle}>
            <div style={{textAlign:"left",margin:"5px 9px",fontFamily:"robotoregular",fontSize:"14px",color:"rgba(0, 0, 0, 0.54)"}}>Stores matching '<span style={{color:"rgba(0, 0, 0, 0.87)"}}>{this.state.query}</span>'</div>
          {
            this.state.merchantList.map((merchant, idx) => {
              if(!merchant.merchant_name){
                return(
                  <li className="dropdown-hover-class" key={idx} onClick={(event) => { 
                      if(this.props.showAddMerchantDialog)
                        if (this.props.magicstoreDIYS) {
                          this.props.showAddMerchantDialog(this.state.query)
                        } else {
                          this.props.showAddMerchantDialog()
                        }
                      if (this.props.magicstoreDIYS) {
                        this.setState({closeMerchantList:true})
                      }
                    }}>
                      <img src={outlet_not_found} style={{width: this.props.magicstoreDIYS ? "40px" : "62px",height: this.props.magicstoreDIYS ? "40px" : "36px"}}/>
                      <div className="merchant-details">
                        {!this.props.magicstoreDIYS && <h3>{languageConst.couldNotFind}</h3>}
                        {this.props.magicstoreDIYS && <h3>{this.state.query}</h3>}
                        {!this.props.magicstoreDIYS && <h4 style={{color:"#ef1c74"}}>{languageConst.clickHereConst}</h4>}
                        {this.props.magicstoreDIYS && <h4>Create a new listing with this name</h4>}
                      </div>
                  </li>
                )
              }
              return(
              <li className="dropdown-hover-class" key={idx} onClick={(event) => this.setCookie(merchant)} key={merchant.merchant_id}>
                <img src={merchant.profile_pic} />
                <div className="merchant-details">
                  <h3>{merchant.merchant_name}</h3>
                  <h4>{merchant.locality}, {merchant.city}</h4>
                </div>
              </li>
            )})
          }
          <li onClick={(event) => {
                if(this.props.showAddMerchantDialog)
                  if (this.props.magicstoreDIYS) {
                    this.props.showAddMerchantDialog(this.state.query)
                  } else {
                    this.props.showAddMerchantDialog()
                  }
                if (this.props.magicstoreDIYS) {
                  this.setState({closeMerchantList:true})
                }
              }} key={"not-found"}>
                  <img src={outlet_not_found} style={{width: this.props.magicstoreDIYS ? "40px" : "62px",height: this.props.magicstoreDIYS ? "40px" : "36px"}}/>
                  <div className="merchant-details">
                    {this.props.magicstoreDIYS && <h3>{this.state.query}</h3>}
                        {!this.props.magicstoreDIYS && <h4 style={{color:"#ef1c74"}}>{languageConst.clickHereConst}</h4>}
                        {this.props.magicstoreDIYS && <h4>Create a new listing with this name</h4>}
                  </div>
              </li>
          </section>
        }
        {
          Array.isArray(this.state.merchantList) && this.state.merchantList.length == 0 && !this.state.closeMerchantList &&
          <section className="search-query-results" style={listStyle}>
            <li onClick={(event) => {
                if(this.props.showAddMerchantDialog)
                  if (this.props.magicstoreDIYS) {
                    this.props.showAddMerchantDialog(this.state.query)
                  } else {
                    this.props.showAddMerchantDialog()
                  }
                if (this.props.magicstoreDIYS) {
                  this.setState({closeMerchantList:true})
                }
              }} key={"not-found"}>
                  <img src={outlet_not_found} style={{width: this.props.magicstoreDIYS ? "62px" : "",height: this.props.magicstoreDIYS ? "36px" : ""}} />
                  <div className="merchant-details">
                    {this.props.magicstoreDIYS && <h3>{this.state.query}</h3>}
                    {!this.props.magicstoreDIYS && <h4 style={{color:"#ef1c74"}}>{languageConst.clickHereConst}</h4>}
                    {this.props.magicstoreDIYS && <h4>Create a new listing with this name</h4>}
                  </div>
              </li>
          </section>
        }
          </div>
          {
            !this.props.magicstoreDIYS && <section className="claim-holder1" onClick = {this.handleSearchClick} style={{display:"inline"}}>
              <button style = {{fontFamily:"robotobold",fontWeight:"bold",height:"35px", width: window.innerWidth > 768 ? "220px" :"90%", padding:"8px 30px", marginTop: window.innerWidth > 768 ? "12px" :"3px"}} className="try-button1 button-header-hover">{languageConst.search}</button>
            </section>
          }
          
      { this.state.showLoginPopup &&
        <LoginPopup open={this.state.showLoginPopup} handleClose={this.handleLandingClose} push={this.props.push} />
      }
      { this.state.showBrandPopup &&
        <BrandPopup open={this.state.showBrandPopup} handleClose={this.handleLandingClose} push={this.props.push} />
      }
    </section>
    );
  }
})
