import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderLogin from '../Header/HeaderLogin';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import food from '../../images/food.svg';
import beauty from '../../images/beauty.svg';
import grocery from '../../images/grocery.svg';
import fashion from '../../images/fashion.svg';
import pharma from '../../images/pharma.svg';
import electronics from '../../images/electronic.svg';
import fitness from '../../images/sports.svg';
import other from '../../images/more.svg';
import checkmark from '../../images/checkmark.svg';
import crossmark from '../../images/cross_red_circle.svg';
import attachIcon from '../../images/attach_icon.svg';
import downArrow from '../../images/left-arrow-copy-10.svg';
import {states as stateSuggestions} from '../../constants';
import { toast } from 'react-toastify';
import { Dropdown } from 'semantic-ui-react'

import './Claim.css';
import { getCities } from '../../redux/modules/merchantList';
import {load as getLocalities} from '../../redux/modules/localityByCity';
import { addOutlet,getSubCategories } from '../../redux/modules/claimBusiness';
import { getBrowserInfo, sendEvent } from '../../helpers/Helpers';

export default connect(store => ({
  addedOutlet: store.claimBusiness.addedOutlet,
  addingOutlet: store.claimBusiness.addingOutlet,
  submitError: store.claimBusiness.error,
  cities: store.merchantList.cities ? store.merchantList.cities : [],
  localities: store.localityByCity.data,
  subcategories: store.claimBusiness.subCategories,
}), dispatch => bindActionCreators({
  addOutlet,
  getCities,
  getLocalities,
  getSubCategories,
}, dispatch))
  (class AddOutlet extends Component {
    constructor(props) {
      super(props)
      this.days = [
        {symbol: "MON",value: "MONDAY",taken: false,timing:""},
        {symbol: "TUE",value: "TUESDAY",taken: false,timing:""},
        {symbol: "WED",value: "WEDNESDAY",taken: false,timing:""},
        {symbol: "THU",value: "THURSDAY",taken: false,timing:""},
        {symbol: "FRI",value: "FRIDAY",taken: false,timing:""},
        {symbol: "SAT",value: "SATURDAY",taken: false,timing:""},
        {symbol: "SUN",value: "SUNDAY",taken: false,timing:""},
      ]

      this.hours = ["1:00","1:30","2:00","2:30","3:00","3:30","4:00","4:30","5:00","5:30 am","6:00 am","6:30 am","7:00 am","7:30 am","8:00 am","8:30 am","9:00 am","9:30 am","10:00 am","10:30 am","11:00 am","11:30 am","12:00 am","12:30 am",
        "1:00 pm","1:30 pm","2:00 pm","2:30 pm","3:00 pm","3:30 pm","4:00 pm","4:30 pm","5:00 pm","5:30 pm","6:00 pm","6:30 pm","7:00 pm","7:30 pm","8:00 pm","8:30 pm","9:00 pm","9:30 pm","10:00 pm","10:30 pm","11:00 pm","11:30 pm","12:00 pm","12:30 pm"]

      this.cityList = ["New Delhi", "Gurgaon", "Noida", "Mumbai", "Pune", "Bangalore", "Jaipur", "Hyderabad", "Lucknow", "Mysore", "Jakarta"]

      this.subcategories = {
        1: ["Bakery","Beverage Shop","Breakfast","Buffet Place","Cafe","Casual Dining","Dessert Parlor","Dhaba","Dinner","Fine Dining","Lounge","Lunch","Nightlife","Quick Bites","Sweets Shop","Kiosk","Meat Shop","Food Truck","Delivery Only"],
        2: ["Spa","Salon","Tattoo Studio","Piercing Parlour","Beauty Parlour","Nail Studio","Beauty Clinic","Beauty Cosmetics"],
        3: ["Western Wear","Casual Wear","Formal Wear","Ethnic Wear","Sports Wear","Footwear","Fashion Accessories","Jewellery Store","Garment Shop","Tailor Shop","Boutique","Multi-brand Store","Kids Wear"],
        4: ["Gym","Yoga Class","Zumba Class","Aerobics Class","Martial Arts Class","Nutritionist Center","Dance Class","Music Class","Sports Academy","Sporting Goods"],
        5: ["Departmental Store","Supermarket","Dairy Store","Fruit & Vegetable Store","Dry Fruit Store","Meat Products"],
        10: ["Liquor Store"],
        58: ["Major Appliances","Small Appliances","Laptops & Accessories","Mobile Phones","Electrical Accessories","Electronics Repair"],
        65: ["1 Star","2 Star","3 Star","4 Star","5 Star"],
        91: ['Pharmacy'],
        103: ["Stationery Store"],
        'others': ["Travel Agency","Hospitals & Clinics","Pet Care","Laundry","Autocare","Diagnostic Center","Gift Store","Movie Theater","Toy store","Photo Studio","Home Decor","Kitchen Supply Store","Religious Goods Store","Other"],
      }

      this.state = {
        selectedCategory: 0,
        selectedCategoryName:"",
        selectedSubCategory: "",
        createdBy: 'other',
        fileSelected: "",
        link:"",
        name: "",
        address: "",
        locality:"",
        pincode:"",
        contact: "",
        email:"",
        addedTimings: this.days,
        startTime: "",
        endTime: "",
        showCategoryList:false,
        showSubCategoryList:false,
        showCityList:false,
        showLocalityList:false,
        showStateList: false,
        showTimingList1:false,
        showTimingList2:false,
        showAddedTimings: false,
        city: "",
        state: "",
        landingSource: "direct",
        landingEmail:"",
        isName: true,
        isContact: true,
        isEmail: true,
        isAddress: true,
        isLink: true,
        isProof: true,
        isCity: true,
        isState: true,
        isTiming: true,
        isLocality: true,
        isPincode: true,
        isCategory: true,
        isSubCategory: true,
        isReferenceEmail: true,
        submiting: false,
        cities: this.cityList,
        states: [],
        localities: [],
        suggestions: [],
        subcategories:[],
        selectedDays: this.days
      }
    }

    

    changeCategory = (type,name) => {
      let subcategories = [] 
      if([1,2,3,4,5,58,65,10,91,103].indexOf(type) != -1){
        subcategories = this.subcategories[type]
      }
      else{
        subcategories = this.subcategories["66"].concat(['Other'])
      }
      this.setState({
        selectedCategory: type,
        selectedCategoryName: name,
        selectedSubCategory: "",
        showCategoryList: false,
        subcategories: subcategories,
      })
    }
    
    changeSubCategory = (value) => {
      this.setState({
        selectedSubCategory:value,
        showSubCategoryList:false
      })
    }

    changeState = (value) => {
      this.setState({
        state: value,
        states: []
      })
    }

    selectDay = (index) => {
      let allDays = this.state.selectedDays.map((curr,ind) =>{
        let newObject = {...curr}
        if(ind == index){
           newObject.taken = !newObject.taken
        }
        return newObject
      })
      this.setState({
        selectedDays: allDays
      })
    }

    markClosed = (index) => {
      let allDays = this.state.addedTimings.map((curr,ind) =>{
        let newObject = {...curr}
        if(ind == index){
           newObject.taken = false
           newObject.timing = ""
        }
        return newObject
      })
      this.setState({
        addedTimings: allDays
      })
    }

    selectTime = (value,type) => {
      if(type=="start"){
        this.setState({
          startTime: value,
          showTimingList1: false
        })
      }
      if(type=="end"){
        this.setState({
          endTime: value,
          showTimingList2: false
        })
      }
    }

    addTimings = (e) => {
      let showList = false
      let allDays = this.state.addedTimings.map((curr,index) =>{
          let newObject = {...this.state.selectedDays[index]}
          if(newObject.taken && this.state.startTime && this.state.endTime){
            let startTime = this.state.startTime 
            let endTime = this.state.endTime
            if(startTime === "00:00"){
              startTime = "00:01"
            }
            if(endTime === "00:00"){
              endTime = "23:59"
            }
            if(curr.timing){
              newObject.timing = curr.timing + '||' + startTime+":00" + "-" + endTime+":00"
            }
            else{
              newObject.timing = startTime+":00" + "-" + endTime+":00"
            }
            showList = true
            return newObject
          }
          return curr
      })
      if(showList){
        this.setState({
          addedTimings: allDays,
          selectedDays: this.days,
          startTime: "",
          endTime: "",
          showAddedTimings: showList,
          isTiming: true
        })
      }
      else{
        this.setState({
          isTiming: false
        })
      }
    }

    handleOwnerChange = (e) => {
      this.setState({
        createdBy: e.target.value
      })
    }

    handleRefererChange = (e) => {
      this.setState({
        landingSource: e.target.value
      })
    }

    getExtension = (filename) => {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    }

    isImage = (filename) => {
      var ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          return true;
      }
      return false;
    }

    onChange = () => {
      var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
      if(uploadedFile.length > 0 && this.isImage(uploadedFile[0].name)){
        this.setState({
          fileSelected: uploadedFile[0],
          isProof: true
        })
      }
      else{
        toast.error("please upload file in jpg/png/jpeg format only")
      }
    }
    
    toggleDropDownList = (type) => {
      if(type == "city"){
        this.setState((prevState) => ({showCityList: !prevState.showCityList}))
      }
      if(type == "locality"){
        this.setState((prevState) => ({showLocalityList: !prevState.showLocalityList}))
      }
      if(type == "category"){
        this.setState((prevState) => ({showCategoryList: !prevState.showCategoryList}))
      }
      if(type == "state"){
        this.setState((prevState) => ({showStateList: !prevState.showStateList}))
      }
      if(type == "subcategory"){
        this.setState((prevState) => ({showSubCategoryList: !prevState.showSubCategoryList}))
      }
      if(type == "timing1"){
        this.setState((prevState) => ({showTimingList1: !prevState.showTimingList1}))
      }
      if(type == "timing2"){
        this.setState((prevState) => ({showTimingList2: !prevState.showTimingList2}))
      }
    }

    handleChange = (e, type) => {
      this.setState({
        [type]: e.target.value
      })
      if(type == "name" && e.target.value != ""){
        this.setState({isName: true});
      }
      if(type == "category" && this.state.selectedCategoryName != ""){
        this.setState({isCategory: true});
      }
      if(type == "subcategory" && this.state.selectedSubCategory != ""){
        this.setState({isSubCategory: true});
      }
      if(type == "contact" && e.target.value != ""){
        this.setState({isContact: true});
      }
      if(type == "email" && e.target.value != ""){
        this.setState({isEmail: true});
      }
      if(type == "address" && e.target.value != ""){
        this.setState({isAddress: true});
      }
      if(type == "pincode" && e.target.value != ""){
        this.setState({isPincode: true});
      }
      if(type == "city" && e.target.value != ""){
        let newCites = this.state.cities.filter(curr => curr.toLowerCase().startsWith(e.target.value.toLowerCase()))
        this.setState({
          isCity: true,
          suggestions: newCites
        });
      }
      if(type == "locality" && e.target.value != ""){
        let newLocalities = this.props.localities ? this.props.localities.filter(curr => curr.locality.toLowerCase().startsWith(e.target.value.toLowerCase())) : [];
        newLocalities = newLocalities.map(curr => curr.locality);
        this.setState({
          isLocality: true,
          localities: newLocalities
        });
      }
      if(type == "state" && e.target.value != ""){
        let newStates = stateSuggestions.filter(curr => curr.toLowerCase().startsWith(e.target.value.toLowerCase()))
        this.setState({
          isState: true,
          states: newStates
        });
      }
      if(type == "timing" && e.target.value != ""){
        this.setState({isTiming: true});
      }
      if(type == "link" && e.target.value != ""){
        this.setState({isLink: true});
      }
      if(type == "landingEmail" && e.target.value != ""){
        this.setState({isReferenceEmail: true});
      }
    }

    scrollToInvalidFields = (invalidField) => {
      const items = document.getElementsByClassName(invalidField);
      if (items.length > 0) {
        window.scrollTo({
          top: items[0].offsetTop - 135,
          behavior: 'smooth'
        });
      }
    }

    validatePersonalDetails = () => {

      if(this.state.name == ""){
        this.setState({isName:  false});
        this.scrollToInvalidFields("outlet-name");
        return false
      }
      else
        this.setState({isName: true});
      
      let phoneRegex = /^[0-9]{10,12}$/;
      if(!phoneRegex.test(this.state.contact)) {
        this.setState({isContact : false});
        this.scrollToInvalidFields("outlet-phone");
        return false
      }
      else 
        this.setState({isContact: true});
      
      const emailRegex = /^[a-zA-Z._0-9]+@(\w+\.)+\w+$/;
      if(!emailRegex.test(this.state.email)){
        this.setState({isEmail: false});
        this.scrollToInvalidFields("outlet-email");
        return false
      }
      else 
        this.setState({isEmail: true});

      if(this.state.address == ""){
        this.setState({isAddress : false});
        this.scrollToInvalidFields("outlet-address");
        return false
      }
      else 
        this.setState({isAddress: true});
      
      if(this.state.city == ""){
        this.setState({isCity : false});
        this.scrollToInvalidFields("outlet-city");
        return false
      }
      else 
        this.setState({isCity: true});
      
      if(this.state.locality == ""){
        this.setState({isLocality : false});
        this.scrollToInvalidFields("outlet-locality");
        return false
      }
      else 
        this.setState({isLocality: true});
      
      if(this.state.pincode == ""){
        this.setState({isPincode:  false});
        this.scrollToInvalidFields("outlet-pincode");
        return false
      }
      else
        this.setState({isPincode: true});

      if(this.state.state == ""){
        this.setState({isState : false});
        this.scrollToInvalidFields("outlet-state");
        return false
      }
      else 
        this.setState({isState: true});
      
      let checkTiming = false;
      this.state.addedTimings.forEach((curr) => {
          checkTiming = checkTiming || curr.taken
      })
      if(!checkTiming){
        this.setState({isTiming : false});
        this.scrollToInvalidFields("outlet-timing");
        return false
      }
      else 
        this.setState({isTiming: true});
      
      let urlRegex =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if(!urlRegex.test(this.state.link)){
        this.setState({isLink: false});
        this.scrollToInvalidFields("outlet-link");
        return false
      }
      else 
        this.setState({isLink: true});

      if(this.state.fileSelected == ""){
        this.setState({isProof: false});
        this.scrollToInvalidFields("outlet-proof");
        return false
      }
      else 
        this.setState({isProof: true});

      if(this.state.selectedSubCategory == ""){
        this.setState({isSubCategory : false});
        this.scrollToInvalidFields("outlet-subcategory");
        return false
      }
      else 
        this.setState({isSubCategory: true});

      if(this.state.selectedCategoryName == ""){
        this.setState({isCategory : false});
        this.scrollToInvalidFields("outlet-category");
        return false
      }
      else 
        this.setState({isCategory: true});

      if(this.state.landingSource == 'sales_team' && !emailRegex.test(this.state.landingEmail)){
        this.setState({isReferenceEmail: false});
        this.scrollToInvalidFields("outlet-referer");
        return false
      }
      else 
        this.setState({isReferenceEmail: true});
    
      return true
    }

    sendUIGGenericEvents = (eventName, extraFeilds = {}) => {
      try{
       sendEvent({
         event: eventName,
         page_type: 'add_outlet',
         subject_type: 'merchant',
         device: window.innerWidth > 768 ? 'desktop' :'mobile',
         extraFeilds: extraFeilds ? JSON.stringify(extraFeilds) : ""
       });
       }catch(err){}
     }

    addOutlet = () => {
      const {name, contact, address, locality, email, pincode, link, createdBy, selectedCategory, selectedSubCategory, fileSelected, city, state, addedTimings} = this.state;
      const landingSource = this.state.landingEmail ? this.state.landingEmail : this.state.landingSource;
      const timingsInJson = JSON.stringify(addedTimings)
      let payload = {
        outletName: name,
        contact,
        email,
        address,
        locality,
        pincode,
        link,
        createdBy,
        categoryId: selectedCategory === 10 ? 5:selectedCategory,
        subCategory: selectedSubCategory,
        fileSelected,
        city,
        state,
        landingSource,
        timingsInJson
      }
              
      this.sendUIGGenericEvents('add_outlet_add_outlet_button_clicked', payload)
      if(this.validatePersonalDetails() && !this.state.submiting){
        this.props.addOutlet(payload);
        this.setState({submiting:true}); 
      }
    }

    componentDidMount() {
      let queryParams = new URLSearchParams(this.props.history.location.search);
      var utm_source = 'direct';
      for (const [key, value] of queryParams.entries()){
          if(key == 'utm_source'){
             utm_source = value;
          }
      }
      this.setState({
         landingSource: utm_source
      })
      this.props.getCities().then(() => {
        if(this.props.cities){
          this.setState({cities:this.props.cities})
        }
      })
      this.props.getSubCategories().then(() => {
        if(this.props.subcategories){
          this.subcategories = {...this.subcategories,...this.props.subcategories}
        }
      })
      this.setState({
        states: stateSuggestions
     })
    }

    handleSelectChange = (event,data,type) => {
      event.preventDefault();
      if(type === 'city'){
        this.props.getLocalities(data.value, null, "addOutlet");
        this.setState({
          showCityList: false,
          locality: "",
          city: data.value,
          isCity: true,
        })
      }
      if(type === 'locality'){ 
        this.setState({
          showLocalityList: false,
          locality: data.value,
          isLocality: true,
        })
      }
      if(type === 'state'){
        this.setState({
          showStateList: false,
          state: data.value,
          isState: true,
        })
      }
    }


    handleAppDownload = () => {
      let browserData = getBrowserInfo();
      if(/iPad|iPhone|iPod|Mac/i.test(browserData.user_agent))
          window.open('https://apps.apple.com/us/app/id1528283353', '_blank');
      else
          window.open('https://play.google.com/store/apps/details?id=com.magicpin.partner', '_blank');
    }
    


    render() {  
      const {selectedCategory} = this.state;
      const listStyle = {
        border: 'solid 1px #d8dae1',
        borderRadius:"4px",
        transitionDelay: '2s',
        marginTop:'5px',
        position: "absolute",
        left: "0px",
        top: "75px",
        background: "#f7f7fa",
        width: "100%",
        zIndex: "2000",
      }
      let error = '';
      if(this.props.submitError){
        for(let x in this.props.submitError){
            if(this.props.submitError[x]){
              if(Array.isArray(this.props.submitError[x])){
                  error += x.toUpperCase() + ": " + this.props.submitError[x][0] + " "
              }
              else{
                  error += x.toUpperCase() + ": " + this.props.submitError[x] + " "
              }
            } 
            else{
              error += x.toUpperCase() + " "
            }
        }
      }
      return (
        <div style={{display:"flex", justifyContent:"center"}}>
          <HeaderLogin user={this.props.user} login={this.props.login}
              loggingIn={this.props.loggingIn}
              loginError={this.props.loginError}
              verifyOtp={this.props.verifyOtp}
              push={this.props.push}
              selectLanguage={true} />
          {this.props.addedOutlet && !this.props.addingOutlet ?
            <div className="add-outlet-container" style={{textAlign: 'center'}}>
              <div style={{background:'white', width: "90%", display: "inline-flex", alignItems: "center", padding: "16px", borderRadius: "5px"}}>
                <img style={{height: '40px', marginRight:'10px'}} src={this.props.submitError ? crossmark:checkmark} />
                {!this.props.submitError && this.props.addedOutlet && <div style={{textAlign: 'left'}}> Thank you for registering with magicpin. Our team will verify your outlet information. We will keep you posted with further updates on your Registered Mobile Number</div>}
                {this.props.submitError && <div style={{textAlign: 'left'}}>{error} Please try again...</div>}
              </div>
              {!this.props.submitError && this.props.addedOutlet && <div className="large-btn" style={{marginTop: "40px",borderRadius:"4px"}} onClick={this.handleAppDownload} >Download App</div>}
            </div>
            : <div className="add-outlet-container">
              <h3>Add your Outlet</h3>
              <h4>If you are the owner of an outlet that is not listed on magicpin, let us know using this form </h4>
              <div className="basic-info-container">
                  <div className="outlet-name">
                    <h6>Outlet Name<span style={{color:"red"}}>*</span></h6>
                    <input type="text" placeholder="Enter outlet name…" value={this.state.name} onChange={(e) => this.handleChange(e,'name')}></input>
                    { !this.state.isName &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-phone">
                    <h6>Contact Number<span style={{color:"red"}}>*</span></h6>
                    <input type="number" placeholder="Enter outlet contact number…" value={this.state.contact} onChange={(e) => this.handleChange(e,'contact')}></input>
                    { !this.state.isContact &&
                      <p className="error-style">Incorrect Phone number</p>
                    }
                  </div>
                  <div className="outlet-email">
                    <h6>Email id<span style={{color:"red"}}>*</span></h6>
                    <input type="text" placeholder="Enter email id…" value={this.state.email} onChange={(e) => this.handleChange(e,'email')}></input>
                    { !this.state.isEmail &&
                      <p className="error-style">InValid Email id</p>
                    }
                  </div>
                  <div className="outlet-address">
                    <h6>Address<span style={{color:"red"}}>*</span></h6>
                    <input type="text" placeholder="Enter an address" value={this.state.address} onChange={(e) => this.handleChange(e,'address')}></input>
                    { !this.state.isAddress &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-city">
                    <h6>State<span style={{color:"red"}}>*</span></h6>
                      <Dropdown 
                        placeholder="Select State"
                        options={Array.isArray(this.state.states) && this.state.states.map(data=>({key:data, text:data,value:data}))} 
                        value={this.state.state} 
                        onChange = {(e,data) => this.handleSelectChange(e,data,'state')} 
                        search 
                        fluid 
                        selection 
                      />                    
                    {/* <div style={{position: "relative" }}>      
                      <select className="select-dropdown" name="" ref='state' onClick={(e) => this.toggleDropDownList("state")} value={this.state.state} onChange = {(e) => this.handleSelectChange(e,'state')}>
                          <input type="text" ref={ref} placeholder="Select State" onClick={(e) => this.toggleDropDownList("state")}>                          
                          {Array.isArray(this.state.states) && this.state.states.map((data, index)=><option key={index} value={data}>{data}</option>)}
                      </select>                      
                      <img src={downArrow} className={this.state.showStateList ? "reverse-icon": " "} style={{position: "absolute",right: "15px", top: "9px"}}/>
                    </div> */}
                    { !this.state.isState &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-state">
                    <h6>City<span style={{color:"red"}}>*</span></h6>
                    
                    <div style={{position: "relative" }}>
                    <Dropdown 
                        placeholder="Select City"
                        options={Array.isArray(this.props.cities) && this.props.cities.sort().map(data=>({key:data, text:data,value:data}))} 
                        value={this.state.city}   
                        onChange = {(e,data) => this.handleSelectChange(e,data,'city')} 
                        search 
                        fluid 
                        selection 
                      />   
                      {/* <select className="select-dropdown" name="" ref='city' value={this.state.city} onChange = {(e) => this.handleSelectChange(e,'city')}>
                              
                                      <option value="">Select City</option>
                                      {Array.isArray(this.props.cities) && [...this.cityList,...this.props.cities.sort()].map((city, index)=>{
                                          if(city)
                                           return <option key={index} value={city}>{city}</option>
                                          else
                                           return <option key={index} value={city} disabled>{city}</option>
                                          }
                                          )
                                      }
                      </select>                      
                      <img src={downArrow} onClick={(e) => this.toggleDropDownList("city")} className={this.state.showCityList ? "reverse-icon": " "} style={{position: "absolute",right: "15px", top: "9px",cursor: "pointer"}}/> */}
                    </div>
                    { !this.state.isCity &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-locality">
                    <h6>Locality<span style={{color:"red"}}>*</span></h6>
                    <div style={{position: "relative" }}>
                      <Dropdown  
                          placeholder={this.state.city!=="" ? "Select Locality":"Please select the city first"}
                          options={Array.isArray(this.props.localities) && this.props.localities.filter(data => data.city == this.state.city).map(data=>({key:data.locality, text:data.locality,value:data.locality}))} 
                          value={this.state.locality}   
                          onChange = {(e,data) => this.handleSelectChange(e,data,'locality')} 
                          search 
                          fluid 
                          selection   
                        />   
                      
                      {/* <select className="select-dropdown" name="" ref='locality' onClick={(e) => this.toggleDropDownList("locality")} value={this.state.locality} onChange = {(e) => this.handleSelectChangeCheck(e,'locality')}>
                          <option value="">{ this.state.city!=="" ? "Select locality":"Please select the city first"}</option>
                          {Array.isArray(this.props.localities) && this.props.localities.filter(data => data.city == this.state.city).map((data, index)=>{
                              if(data.locality)
                                return <option key={index} value={data.locality}>{data.locality}</option>
                              else
                                return <option key={index} value={data.locality} disabled>{data.locality}</option>
                              }
                              )
                          }
                      </select>                      
                      <img src={downArrow} className={this.state.showLocalityList ? "reverse-icon": " "} style={{position: "absolute",right: "15px", top: "9px"}}/> */}
                    </div>
                    { !this.state.isLocality &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-pincode">
                    <h6>Pincode<span style={{color:"red"}}>*</span></h6>
                    <input autocomplete="new-password" type="number" placeholder="Enter pincode" value={this.state.pincode} onChange={(e) => this.handleChange(e,'pincode')}></input>
                    { !this.state.isPincode &&
                      <p className="error-style">This field is required</p>
                    }
                  </div>
                  <div className="outlet-timing">
                    <h6>Outlet Timing<span style={{color:"red"}}>*</span></h6>
                    <div className="outlet-timing-bg">  
                      <div className="outlet-timing-days">
                        {
                          this.state.selectedDays.map((curr,index) => {
                            return(
                              <div className={`days ${curr.taken ? "active" : ""}`} onClick={(e) => this.selectDay(index)}>
                                {curr.symbol}
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className="outlet-timing-setting">
                        <div className="outlet-timing-dropdown">
                          <input type="time" placeholder="From" value={this.state.startTime} onChange={(e) => this.selectTime(e.target.value,"start")} ></input>
                        </div>
                        <hr className="hr-line"/>
                        <div className="outlet-timing-dropdown">
                          <input type="time" placeholder="To" value={this.state.endTime} onChange={(e) => this.selectTime(e.target.value,"end")} ></input>
                        </div>
                        <div className={`add-time-btn ${this.state.startTime && this.state.endTime ? "active" : ""}`} onClick={this.addTimings}>
                          Add time
                        </div>
                      </div>
                      { !this.state.isTiming &&
                        <p className="error-style">Incorrect Timings</p>
                      }                
                      {this.state.showAddedTimings &&  <hr className="hr-line" style={{margin: "10px auto",width: window.innerWidth < 800 ? "90%":"908px",height:"1px"}}/>}
                        { this.state.showAddedTimings && 
                          <div className="timing-list"  style={{margin: "10px 10px",width:"100%",padding:"10px 0px"}}>
                            {
                              this.state.addedTimings.map((curr,index) => {
                                return(
                                  <div style={{margin: "10px 2px", display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
                                    <div style={{width: window.innerWidth < 800 ? "90%":"30%", fontFamily:"robotomedium",fontSize:"16px"}}>
                                      {curr.value}
                                    </div>
                                    <div style={{width:window.innerWidth < 800 ? "60%":"30%"}}>
                                      { curr.taken && curr.timing ? curr.timing : "Closed"}
                                    </div>
                                    <div style={{color:"#ef1c74",width:"40%",cursor:"pointer"}} onClick={(e) => this.markClosed(index)} >
                                        {window.innerWidth < 800 ? curr.taken ? "Mark as closed": " ": curr.taken ? "Mark this day as closed": " "}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                  </div>
                  <div className="outlet-link">
                    <h6>Store Website/Google Location<span style={{color:"red"}}>*</span></h6>
                    <div style={{display:'flex'}}>
                      <input style={{width:"98%"}} type="text" placeholder="Outlet website link/ Any online listing link(google, zomato)…" value={this.state.link} onChange={(e) => this.handleChange(e,'link')}></input>
                    </div>
                    { !this.state.isLink &&
                      <p className="error-style">Invalid url</p>
                    }
                  </div>
                  <div className="outlet-proof">
                    <h6>Store verification details<span style={{color:"red"}}>*</span></h6>
                    <div style={{display:'flex'}}>
                      <input disabled={true} style={{width:"96%"}} type="text" value={this.state.fileSelected.name} placeholder="GST certificate, visiting card and electricity bill"></input>
                      <div style={{display:"inline-block", padding:'5px 15px 0 10px'}}>
                        <label htmlFor="file-input" className="pull-right attach-banner pointer">
                          <img src={attachIcon} />
                        </label>
                        <input
                          ref='fileInput' 
                          id="file-input" 
                          type="file" 
                          accept=".png, .jpg, .jpeg"
                          style={{display:'none'}}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    { !this.state.isProof &&
                      <p className="error-style">Invalid details/ file format</p>
                    }
                  </div>
                  <div className="outlet-category">
                    <h6>Select business category<span style={{color:"red"}}>*</span></h6>
                    <div style={{position: "relative" }}>
                      <input type="text" placeholder={ !this.state.selectedCategoryName ? "Select category" : this.state.selectedCategoryName} className={`category-input ${this.state.selectedCategoryName ? "strong" : ""}`} onClick={(e) => this.toggleDropDownList("category")} readOnly ></input>
                      <img src={downArrow} onClick={(e) => this.toggleDropDownList("category")}  className={this.state.showCategoryList ? "reverse-icon": " "} style={{position: "absolute",right: "15px", top: "9px",cursor: "pointer"}}/>
                    </div>
                    { !this.state.isCategory &&
                      <p className="error-style">This field is required</p>
                    }
                    {
                      this.state.showCategoryList &&
                      <section style={listStyle}>
                      {
                        <div style={{overflowY: "scroll",maxHeight:"200px",display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                          <div className={`category-container ${selectedCategory == 1 ? "active" : ""}`} onClick={()=>this.changeCategory(1,"Food & Beverages")}>
                            <img src={food} />
                            <span>Food &amp; Beverages</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 3 ? "active" : ""}`} onClick={()=>this.changeCategory(3,"Fashion")}>
                            <img src={fashion} />
                            <span>Fashion</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 5 ? "active" : ""}`} onClick={()=>this.changeCategory(5,"Grocery")}>
                            <img src={grocery} />
                            <span>Grocery</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 58 ? "active" : ""}`} onClick={()=>this.changeCategory(58,"Electronics")}>
                            <img src={electronics} />
                            <span>Electronics</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 2 ? "active" : ""}`} onClick={()=>this.changeCategory(2,"Beauty")}>
                            <img src={beauty} />
                            <span>Beauty</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 91 ? "active" : ""}`} onClick={()=>this.changeCategory(91,"Pharmacy")}>
                            <img src={pharma} />
                            <span>Pharmacy</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 4 ? "active" : ""}`} onClick={()=>this.changeCategory(4,"Fitness")}>
                            <img src={fitness} />
                            <span>Fitness</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 10 ? "active" : ""}`} onClick={()=>this.changeCategory(10,"Liquor Store")}>
                            <img src={other} />
                            <span>Liquor Store</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 103 ? "active" : ""}`} onClick={()=>this.changeCategory(103,"Stationary Store")}>
                            <img src={other} />
                            <span>Stationary Store</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 65 ? "active" : ""}`} onClick={()=>this.changeCategory(65,"Hotel")}>
                            <img src={other} />
                            <span>Hotel</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 99 ? "active" : ""}`} onClick={()=>this.changeCategory(99,"Entertainment")}>
                            <img src={other} />
                            <span>Entertainment</span>
                          </div>
                          <div className={`category-container ${selectedCategory == 66 ? "active" : ""}`} onClick={()=>this.changeCategory(66,"Other")}>
                            <img src={other} />
                            <span>Other</span>
                          </div>
                        </div>
                      }
                      </section>
                    }
                  </div>
                  <div className="outlet-subcategory">
                    <h6>Subcategory<span style={{color:"red"}}>*</span></h6>
                    <div style={{position: "relative" }}>
                      <input type="text" placeholder={ !this.state.selectedSubCategory ? "Select subcategory" : this.state.selectedSubCategory} className={`category-input ${this.state.selectedSubCategory ? "strong" : ""}`} onClick={(e) => this.toggleDropDownList("subcategory")} readOnly></input>
                      <img src={downArrow} onClick={(e) => this.toggleDropDownList("subcategory")} className={this.state.showSubCategoryList ? "reverse-icon": " "} style={{position: "absolute",right: "15px", top: "9px",cursor: "pointer"}}/>
                    </div>
                    { !this.state.isSubCategory &&
                      <p className="error-style">This field is required</p>
                    }
                    {
                      this.state.showSubCategoryList &&
                      <section style={listStyle}>
                        <div style={{overflowY: "scroll",maxHeight:"150px",display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                          {
                            this.state.subcategories.map((subcategory,index) => {
                              return(
                                  <div key={index} className="reason-container" onClick={(e) => this.changeSubCategory(subcategory)}>
                                    <span> {subcategory} </span>
                                  </div>
                                )
                              }
                            )
                          }
                        </div>
                      </section>
                    }
                  </div>
                  <div style={{width:"100%", marginTop: "20px"}}>
                    <h6>Are you the owner or manager of this outlet? <span style={{color:"red"}}>*</span></h6>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="position" name="createdBy" value={this.state.createdBy} onChange={this.handleOwnerChange} row>
                        <FormControlLabel
                          value={'other'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}>I'm not the owner/manager</Typography>}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={'self'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}>I'm the owner/manager</Typography>}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  { this.state.landingSource && this.state.landingSource != 'magicStore' &&
                    <div style={{width:"100%", marginTop: "5px"}}>
                    <h6>How did you get to know about magicpin?</h6>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="position" name="landingSource" value={this.state.landingSource} onChange={this.handleRefererChange} row>
                        <FormControlLabel
                          value={'direct'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}>Online search</Typography>}
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value={'support_team'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}>Friend/ relative, customers</Typography>}
                          labelPlacement="end"
                        />
                          <FormControlLabel
                          value={'sales_team'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}> magicpin Sales team</Typography>}
                          labelPlacement="end"
                        />
                          <FormControlLabel
                          value={'other'}
                          control={<Radio size="small"/>}
                          label={<Typography style={{fontSize:"15px"}}>Other</Typography>}
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  }
                  {
                    this.state.landingSource == 'sales_team' &&
                    <div className="outlet-referer">
                      <h6>Reference email id<span style={{color:"red"}}>*</span></h6>
                      <input type="text" placeholder="Enter reference email id.."  value={this.state.landingEmail} onChange={(e) => this.handleChange(e,'landingEmail')}></input>
                      { !this.state.isReferenceEmail &&
                        <p className="error-style">This field is required</p>
                      }
                    </div>  
                  }
              </div>
              <div className={`add-btn ${this.state.submiting == true ? "inactive" : ""}`}  onClick={this.addOutlet}>
                {this.state.submiting ? "Please wait while submiting your data...":"Add Outlet"}
              </div>
          </div>}
        </div>
      )
    }
  })