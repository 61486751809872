import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import * as authActions from '../../redux/modules/authReducers/auth';
import GalleryBlock from './GalleryBlock';
import {verifyMerchant}  from '../../redux/modules/authReducers/verifyMerchant';
import { reportErrorToMetricMonitorUnauthenticated } from '../../redux/modules/newMerchantLanding';
import {toast} from 'react-toastify';
import logoBusiness from '../../images/logo-business.svg'
import { loadScript, currencyString, sendEvent, constructEventNameForBq } from '../../helpers/Helpers';
import { MerchantsList } from '../LoginViaApp/LoginViaApp';
import { countryCodes, userTypes, otpCustomizedMsg } from '../../constants';
import SelectLanguage from '../Claim/SelectLanguage';
import moment from 'moment';

import './login-page-stylesheet.css'
import LoginViaOtp from './LoginViaOtp';
const ga = window.ga;

export default connect(
  state => ({
    user: state.auth.user,
    loggingIn: state.auth.loggingIn,
    loginError: state.auth.loginError,

    requestingOtp: state.auth.requestingOtp,
    otpRequested: state.auth.otpRequested,
    otpError: state.auth.otpError,

    merchants: state.auth.merchants,
  }),
  dispatch => bindActionCreators({
    ...authActions,
    verifyMerchant,
    reportErrorToMetricMonitorUnauthenticated
  }, dispatch))
  (class Login extends React.Component {

    constructor(props){
      super(props);
      this.params = new URLSearchParams(this.props.location.search);
      
      this.state = {
        otpLogin: this.props.location && this.props.location.state && this.props.location.state.otpLoginCheck!==null ? this.props.location.state.otpLoginCheck : true,
        errorMessage: false,
        showOtpByCallCta: false,
        disableOtpViaSmsButtonAfterUse: false,
        showTimer: true,
        resendOtpDiv: false,
        disableOtpViaCallButtonAfterUse: false,
        phone: this.props.location && this.props.location.state && this.props.location.state.phoneNo,
        countryCode: this.props.location && this.props.location.state && this.props.location.state.countryCode || countryCodes[0].prefix
      }
      this.showCountDown = false
      this.clock = moment("00:30","mm:ss").format("mm:ss");
      this.pageNameForBq = ''
      this.eventNameConstructorObject = {}
      this.eventName = '';
      //this.phone = this.props.location && this.props.location.state && this.props.location.state.phoneNo
    }

    getHiddenPasswordString = (password) => {
      let pass = "";
      if(password) {
        let arrayOfChars = password.split('');
        arrayOfChars.length > 2 && arrayOfChars.map((char,idx) => {
          if(idx === 0 || idx === arrayOfChars.length -1) {
            pass += char;
          } else {
            pass += "*";
          }
        })
      }
      return pass;
    }

    logToMetricMonitor = (userName, password, errObj) => {        
      let err = JSON.stringify(errObj)
      let pass = this.getHiddenPasswordString(password);
      let payload = {}
      payload['monitor_name'] = "MP.BACKEND.LOGIN.FAILURES";
      payload['status'] = "FAILURE";
      payload['url'] = window.location.href;
      payload['message'] = `Error generated by user: ${userName} while trying to login using credentials using pwd ${pass}.
      Full error message: ${err}`
      // payload['emailList'] = ["soumya.banerjee@magicpin.in", "gaurav.makhija@magicpin.in", "sumit.aswal@magicpin.in", "vikas.bhatia@magicpin.in", "rohan.arora@magicpin.com"];
      this.props.reportErrorToMetricMonitorUnauthenticated(payload)
    }

    sendGbAndGbqEventsLogin = (eventName, eventType, pageName, merchantId, mobileNo, password, extraField) =>{
      let userType=''
      if(this.props.user){
          userType= this.props.user.userType === userTypes.ADMIN ? "ADMIN" : "MERCHANT"
      }
      else{
          userType = "MERCHANT"
      }

      if(userType ==="ADMIN"){
          this.emailId = window.localStorage.getItem("magicpinId")
      }
      else{
          this.emailId= ""
      }
      let pass = this.getHiddenPasswordString(password);
      window.ga('send', 'event', eventName, eventType, pageName);
      sendEvent({
          event: eventName,
          actor_id: merchantId,
          actor_type: userType,
          subject_merchant_id: merchantId,
          user_type: userType,
          pass: pass,
          itemNameAndId: extraField ? extraField : '',
          item_id: mobileNo ? mobileNo : '',
          merchant_email: this.emailId,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
          timestamp: Date.now(),
          device: window.innerWidth > 768 ? 'desktop' :'mobile'
        });
    }

    loginWithCredentials = (event) => {
      event.preventDefault();
      this.eventNameConstructorObject.useCase = 'loginviapassword';
      if(this.props.loggingIn) return;

      let username = this.refs.username.value;
      let password = this.refs.password.value;
      
      this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'loginviapasswordrequested')
      // this.sendGbAndGbqEventsLogin('login_loginviapassword_loginviapasswordrequested', '', 'login', username, '', password, '')
      this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', username, '', password, '')
      this.password=password
      if(username && password){
        
        this.props.login({ username, password }).then(res=>{
            let merchantId=''
            if(res && res.length >0 && res[0].id){
              merchantId=res[0].id
            }
            this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'loginviapasswordsuccessful')
            this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', merchantId, '', this.password, '')
            window.localStorage.setItem('timeOfLastLogin', moment().format('YYYY-MM-DD hh:mm:ss a'))
            // this.sendGbAndGbqEventsLogin('login_loginviapassword_loginviapasswordsuccessful', '', 'login', merchantId, '', this.password, '')
            this.sendGbAndGbqEventsLogin('login_via_user_name_pwd','login_via_user_name_pwd','login_via_user_name_pwd_attempt', merchantId, '', this.password)
        })
        .catch(err => {
          // FIRE_METRIC_MONITOR_FOR_LOGIN_FAILURE
          this.logToMetricMonitor(this.refs.username.value, this.password, err);
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'loginviapasswordfailed')
          const isPhaseOutDefaultPasswordError = err.statusCode && err.statusCode== 4003;
          if(isPhaseOutDefaultPasswordError) {
            this.sendGbAndGbqEventsLogin("forgotpassword_loginviapasswordfailed", '', 'login',  this.refs.username.value, '', this.password, JSON.stringify(err))
            this.props.push('/forgotpassword', {error: 'For added security, please change your password NOW', mid: this.refs.username.value});
            return;
          }
          this.sendGbAndGbqEventsLogin(this.eventName, '', 'login',  this.refs.username.value, '', this.password, JSON.stringify(err))
          // this.sendGbAndGbqEventsLogin('login_loginviapassword_loginviapasswordfailed', '', 'login',  this.refs.username.value, '', this.password, JSON.stringify(err))
        })
      }
      username = '';
      password = '';
    }

    // requestOtp = (e) => {
    //   e.preventDefault();
    //   this.eventNameConstructorObject.useCase = 'loginviaotp';
    //   const sendSixDigitOtp = true
      
    //   if(this.props.requestingOtp) return;
    //   const { phone } = this.state;
    //   if(! /^[0-9]{10,14}$/.test(phone)){
    //     this.sendGbAndGbqEventsLogin('loginviaotp_invalidnumber', '', 'login', null, this.state.phone, '', '');
    //     toast('Please enter correct 10 digit mobile number');
    //     return;
    //   }
    //   this.props.requestOtp(this.state.countryCode+phone, otpCustomizedMsg.LOGIN_MSG, sendSixDigitOtp).then(res => {
    //     if(res && res[0] && res[0].message){
    //       toast.error(res[0].message)
    //       this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequestfailed')
    //       this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', res[0].message);
    //       // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpbysmsrequestfailed', '', 'login', null, this.state.phone, '', res[0].message);
    //       this.setState({
    //         errorMessage: true
    //       })
    //     }
    //     else{
    //       this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmssent')
    //       this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '');
    //       // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpbysmssent', '', 'login', null, this.state.phone, '', '');
    //       this.setState({
    //         errorMessage: false
    //       })
    //     }
    //   })
    //   .catch(err => {
    //     let errMsg;
    //     if(err && err.message) {
    //       errMsg = err.message
    //     }
    //     this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequestfailed')
    //     this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', errMsg)
    //   })
    // }

    // sendOtpByCall = (e) => {
    //   e.preventDefault();
    //   this.eventNameConstructorObject.useCase = 'loginviaotp';
    //   const { phone } = this.state
    //   this.props.sendOtpByCall(phone)
    //   .then(res =>  {
    //     toast.success("You will receive your otp via call shortly!");
    //     this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbycallsent')
    //     this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '')
    //     // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpbycallsent', '', 'login', null, this.state.phone, '', '')
    //     this.setState({disableOtpViaCallButtonAfterUse: true, disableOtpViaSmsButtonAfterUse: true, showTimer: true})
        
    
    //   })
    //   .catch(err => {
    //     this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbycallrequestfailed')
    //     this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', JSON.stringify(err))
    //     // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpbycallrequestfailed', '', 'login', null, this.state.phone, '', JSON.stringify(err))
    //     toast.error("Otp call request failed! Please try again in some time")
    //   })
    // }

    resendOtp = () =>{
      this.setState({resendOtpDiv : true});
    }

    disableOtpButton = () =>{
      this.setState({disableOtpViaCallButtonAfterUse: true, disableOtpViaSmsButtonAfterUse: true, showTimer: true});
    }

    // loginViaOtp = (e) => {
    //   e.preventDefault();
    //   this.eventNameConstructorObject.useCase = 'loginviaotp';
    //   if(this.props.loggingIn) return;
    //   const { phone, otp } = this.state;
    //   if(! /^[0-9]{10,14}$/.test(phone) || !/^[0-9]{4,6}$/.test(otp)){
    //     toast('Invalid mobile number or otp');
    //     return;
    //   }
    //   this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationrequested')
    //   this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, this.state.otp, '')
    //   // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpverificationrequested', '', 'login', null, this.state.phone, this.state.otp, '')
    //   this.mobileno=this.state.countryCode + phone
    //   this.props.verifyOtp(this.state.countryCode + phone, otp)
    //   .then(res=>{
    //     window.localStorage.setItem('phoneNumberDuringOtpLogin', this.state.phone)
    //     window.localStorage.setItem('timeOfLastLogin', moment().format('YYYY-MM-DD hh:mm:ss a'))
    //     let merchantId=''
    //     if(res && res.length >0 && res[0].id){
    //       merchantId=res[0].id
    //       this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationsuccessful')
    //       this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', merchantId, this.state.phone, this.state.otp, '')
    //       // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpverificationsuccessful', '', 'login', merchantId, this.state.phone, this.state.otp, '')
    //     } else if(res && res[0] && res[0].length > 0){
    //       let arrayOfmerchantIds = [];
    //       if(res && res[0] && res[0].length > 0) {
    //         res[0].map(result => {
    //           if(result.merchant_id) {
    //             arrayOfmerchantIds.push(result.merchant_id)
    //           }
    //         })
    //       }
    //       this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationsuccessful')
    //       this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', JSON.stringify(arrayOfmerchantIds), this.state.phone, this.state.otp, '')
    //       // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpverificationsuccessful', '', 'login', JSON.stringify(arrayOfmerchantIds), this.state.phone, this.state.otp, '')
    //     }
    //      this.sendGbAndGbqEventsLogin('login_via_mobile_no','login_via_mobile_no','login_via_mobile_no_attempt', '', this.mobileno)
    //   })
    //   .catch(err => {
    //     let errMsg;
    //     if(err && err.message) {
    //       errMsg = err.message
    //     }
    //     this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationfailed')
    //     this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, this.state.otp, errMsg)
    //     // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpverificationfailed', '', 'login', null, this.state.phone, this.state.otp, errMsg)
    //   })
    // }

    componentWillMount(){
      ga('send', 'pageview');      

      var identifier = this.params.get('identifier');
      if(this.params.has('identifier')){
        this.props.verifyMerchant(this.params.get('identifier'));
        var merchantId = identifier.split('_')[1];
        this.props.push('/?merchant=' + merchantId + '&utm_campaign=rtm');
        return;
      }

      if(this.params.has('value')){
        //console.log('query strings', this.props.location.query.value);
        this.props.loginShortUrl(this.params.get('value'), this.params.get('utm_source'));
      }

      // login via app using app's user id and token
      const appUserId = window.localStorage.getItem('app-userid');
      const appUserToken = window.localStorage.getItem('app-user-token');
      if(appUserId && appUserToken) this.props.loginViaApp(appUserId, appUserToken);
    }

    startTimer = ()=>{
      this.showCountDown = true
      let x = setInterval(()=>{
        this.clock = moment(this.clock,'mm:ss').subtract(1,'second').format("mm:ss")
        if(this.clock === "00:00") 
          {
            this.setState({showOtpByCallCta: true, showOtpBySmsCta: true, disableOtpViaSmsButtonAfterUse:false, disableOtpViaCallButtonAfterUse: false, showTimer: false})
            this.showCountDown = false
            clearInterval(x);
            //if(document.getElementById("resend-timer")) document.getElementById("resend-timer").innerHTML = "";
          }
        else{
          if(document.getElementById("resend-timer")) document.getElementById("resend-timer").innerHTML ="Resend in "+this.clock;
        }
      }, 1000)
    }

    componentWillReceiveProps(nextProps){
      if(this.props.loggingIn && nextProps.loginError){
        const isPhaseOutDefaultPasswordError = nextProps.loginError.statusCode && nextProps.loginError.statusCode== 4003
        if(isPhaseOutDefaultPasswordError || this.props.otpRequested) return;
        if(nextProps.loginError.message) toast.error(nextProps.loginError.message);
        else toast('Failed to log in. Please try again');
      }
      
      // if(this.props.requestingOtp && nextProps.otpError){
      //   if(nextProps.otpError.message) toast.error(nextProps.otpError.message);
      //   else toast('There was some error while sending OTP. Please check your number and try again');
      // }
    }

    componentDidMount(){
      loadScript("//translate.google.com/translate_a/element.js?cb=window.googleTranslateElementInit", null)
      this.pageNameForBq = this.props.location.pathname;
      this.eventNameConstructorObject['pageName'] = this.pageNameForBq;
      this.eventNameConstructorObject['useCase'] = '';
      if(window && window.location && window.location.href && window.location.href.includes('redirect=myteam')) {
        window.localStorage.setItem('redirectToMyTeam', "true");
      }
    }

    forgotPasswordHandler() {
      this.eventNameConstructorObject.useCase = 'forgotPassword';
      this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'forgotPasswordButtonClicked')
      this.sendGbAndGbqEventsLogin(this.eventName)
      this.props.push('/forgotpassword');
    }

    render() {
      const {user, logout} = this.props;
      return (
        <div>
          <header style={{position:"fixed", zIndex:"1000", width:"100vw", top:"0"}}>
            <div className="top-header-bar top-login-header" style={{height: "auto", padding: '5px', display: 'block'}}>
                <div className="row" style={{width:"100%" , padding: '0 15px', alignItems: 'center', display: 'flex' }}>
                  <div style={{float:'left'}} className="col-sm-5 col-xs-7">
                    <div className="logo login-logo logo-wrapper" style={{display:"block"}}>
                        <img src={logoBusiness} alt="magicpin"/>
                    </div>
                  </div>
                  <div style={{float:'right', padding:"0px"}}  className="col-sm-7 col-xs-6 row">
                    <div className="login-header-rgt header-login-mobile" style={{textAlign: 'right'}}>
      <a style={{whiteSpace:'nowrap', background: "#ef1c74", borderRadius: '4px', marginRight:'5px'}} className="btn-o button-header-hover" onClick={() => this.props.push('/claim')}> Claim your store</a>
                      <div style={{ width:'145px', float:'right'}} className="desktop-only" id="google_translate_element"></div>
                      {/* <SelectLanguage /> */}
                      </div>
                    </div>
                </div>
                </div>
            </header>
            <section className="login-content-wrapper">
              <div className="login-box-wrapper">
                {!this.state.otpLogin && <h1>Login to get started again!</h1>}
                {!this.state.otpLogin && <div className="sign-up-box-sub-ttl">Need help? Email us at partner@magicpin.in</div>}
                <div className="merchant-login-box" style={{borderRadius:'15px'}}>
                  <div className="login-form-wrapper">
                  {this.props.merchants ? 
                  <MerchantsList 
                    merchants = {this.props.merchants}
                    phone = {this.state.phone}
                    sendGbAndGbqEventsLogin = {this.sendGbAndGbqEventsLogin}
                    select = {(merchantId, salt) => this.props.verifyOtp( this.state.countryCode + this.state.phone, this.state.otp, merchantId, salt)}
                  /> : 
                  !this.state.otpLogin ?
                    <form role="form" onSubmit={this.loginWithCredentials}>
                      <div className="form-group">
                        <input type="text" ref="username" className="form-control" placeholder="Enter User Name" />
                      </div>
                      <div className="form-group">
                        <input type="password" ref="password" className="form-control" placeholder="Enter Password" />
                      </div>
                      <div className="row" style={{marginTop:'-16px'}}>
                        <div className="col-xs-12">
                          {this.props.loginError &&
                            <span className="ErrorAlert">Please check your credentials</span>}
                        </div>
                        <div className="col-xs-12">
                          <p className="login-forgot-password"><a className="mobile-login-frgt-passwd" onClick={()=> this.forgotPasswordHandler()}>Forgot password?</a></p>
                          <input type="submit" style={{ background: '#EF1C74' }} className={this.props.loggingIn ? "btn-submit loadingDots button-header-hover" : "btn-submit button-header-hover"} value={this.props.loggingIn ? "LOGGING IN" : "LOGIN"} onClick={this.loginWithCredentials} />
                          <p className="login-login-otp">Or <a className="mobile-login-frgt-passwd" onClick={()=> {
                            this.setState({otpLogin: true});
                            this.eventNameConstructorObject.useCase = 'loginviapassword';
                            this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'switchedtologinviaotp')
                            this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, null, '', null); 
                            // this.sendGbAndGbqEventsLogin('login_loginviapassword_switchedtologinviaotp', '', 'login', null, null, null, null);
                            }} >
                            Login with OTP</a></p>
                        </div>
                      </div>
                    </form> 
                    
                    :
                      <LoginViaOtp
                        changeLoginMethod={() => this.setState({ otpLogin: false })}
                        sendGbAndGbqEventsLogin={this.sendGbAndGbqEventsLogin} 
                        defaultPhone={this.props.location && this.props.location.state && this.props.location.state.phoneNo}/>

                    // <form role='form' onSubmit={this.props.otpRequested && !this.state.errorMessage ? this.loginViaOtp : this.requestOtp}>
                    //   <div className="form-group otp-login" style={{position:'relative', marginBottom: this.showCountDown === true ? '2px' : '22px'}}>
                    //     <select className="form-control country-code" style={{width: "25%", marginRight: "2%", padding: "1%", textAlign: "center"}} defaultValue={this.props.location && this.props.location.state && this.props.location.state.countryCode} onChange={e=>this.setState({countryCode:e.target.value})}>
                    //       {countryCodes.map((phonePrefix, idx)=><option key={idx} value={phonePrefix.prefix}>
                    //       {phonePrefix.abbr + ' +'+phonePrefix.prefix}
                    //         </option>)}
                    //     </select>
                    //     <input style={{width: "70%"}} type="tel" className="form-control phone-number" placeholder="Enter 10 digit mobile number" defaultValue={this.props.location && this.props.location.state && this.props.location.state.phoneNo}
                    //       onChange={ e => this.setState({ phone: e.target.value })} />
                        

                    // { this.state.resendOtpDiv && <div style={{ marginTop:'20px' , textAlign:'center'}}>

                    //     <div style={{float:'left',marginBottom:'20px'}} > 
                    //           {this.props.otpRequested && !this.state.errorMessage && this.state.showOtpBySmsCta &&  
                    //             <button className={"resendOtp" +" "+ (!this.state.disableOtpViaSmsButtonAfterUse ? "Enabled":"Disabled")}
                    //               onClick={
                    //               this.clock = moment("00:30","mm:ss").format("mm:ss"),
                    //               (e) => {
                    //               this.requestOtp(e);
                    //               this.startTimer();
                    //               this.disableOtpButton();
                    //               this.eventNameConstructorObject.useCase = 'loginviasms';
                    //               this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequestrepeat');
                    //               this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '');
                                 
                    //             }}   
                    //             disabled= {this.state.disableOtpViaSmsButtonAfterUse}     
                    //             >RESEND OTP BY SMS</button>
                    //           }

                    //     </div>

                    //         {this.props.otpRequested && !this.state.errorMessage && this.state.showTimer && <a style={{alignItems: 'center',   borderRadius:'4px', padding:'8px 12px',  cursor: 'default'}}><span id="resend-timer"></span></a>}
                              
                    //     <div style={{float:'right', marginBottom:'20px'}}  >
                          

                              
                    //           {this.props.otpRequested && !this.state.errorMessage && this.state.showOtpByCallCta  &&  
                    //             <button  className={"resendOtp" +" "+ (!this.state.disableOtpViaCallButtonAfterUse ? "Enabled":"Disabled")}
                    //             onClick={this.clock = moment("00:30","mm:ss").format("mm:ss"),
                    //             (e) => {this.sendOtpByCall(e);
                    //                     this.startTimer();
                    //                     this.eventNameConstructorObject.useCase = 'loginviaotp';
                    //                     this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbycallrequestrepeat');
                    //                     this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '');
                                       
                    //           }} 
                    //             disabled={this.state.disableOtpViaCallButtonAfterUse}
                    //             >RESEND OTP BY CALL</button>
                    //           }

                    //   </div>
                    //   </div> }

                    // </div>
                      
                      
                    //   {this.props.otpRequested && !this.state.errorMessage &&  <div className="form-group">
                    //     <input type="number" pattern="[0-9]*" inputmode="numeric" className="form-control" placeholder="Enter OTP"
                    //      onChange={ e => this.setState({ otp: e.target.value }) }
                    //      onKeyPress={e => { if(e.key === 'Enter') this.loginViaOtp(e) }} />
                    //   </div>}
                    //   <div className="row" style={{marginTop:'-16px'}}>
                    //     <div className="col-xs-12">
                    //       <div style={{marginTop: 16}}>
                    //       {(this.state.errorMessage || !this.props.otpRequested) ?
                    //         <button type="submit" style = {{ background: '#EF1C74' }} className={this.props.requestingOtp ? "btn-submit loadingDots button-header-hover" : "btn-submit button-header-hover"}
                    //           onClick={(e) => {
                    //             this.requestOtp(e);
                    //             this.startTimer();
                    //             this.resendOtp();
                    //             this.eventNameConstructorObject.useCase = 'loginviaotp';
                    //             this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequested');
                    //             this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '');
                    //             // this.sendGbAndGbqEventsLogin('login_loginviaotp_otpbysmsrequested', '', 'login', null, this.state.phone, '', '');
                    //           }} 
                    //           disabled={ this.props.requestingOtp}>{this.props.requestingOtp ? 'SENDING OTP' : 'SEND OTP'}</button> :
                    //           // sendGbAndGbqEventsLogin = (eventName, eventType, pageName, merchantId, mobileNo, password)
                    //         <input type="submit" style = {{ background: '#EF1C74' }} className={this.props.loggingIn ? "btn-submit loadingDots button-header-hover" : "btn-submit button-header-hover"}
                    //           value={this.props.loggingIn ? 'LOGGING IN' : 'LOGIN'}
                    //           onClick={this.loginViaOtp} />
                    //       }
                    //       </div>
                    //       <p className="login-login-otp">Or <a className="mobile-login-frgt-passwd" onClick={() => {
                    //         this.setState({otpLogin: false});
                    //         this.eventNameConstructorObject.useCase = 'loginviaotp';
                    //         this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'switchedtologinviapassword');
                    //         this.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, null, "", null); 
                    //         // this.sendGbAndGbqEventsLogin('login_loginviaotp_switchedtologinviapassword', '', 'login', null, null, null, null);
                    //         }}>
                    //         Login with credentials</a></p>
                    //     </div>
                    //   </div>
                    // </form>
                    }
                  </div>
                </div>
              </div>
            </section>
            <GalleryBlock />
          </div>
        );
      }
    })
