import React, { Component } from 'react'
import alertIcon from '../../images/alert.svg';
import { List } from 'semantic-ui-react'
import "./infoBar.css"
 
 export default class Alert extends Component {
    render() {

    if(!this.props.information && !this.props.bulletPoints)
    return 

    const ShowBulletPoints = () => {
        return(    
        <List bulleted>
           {this.props.bulletPoints.map((point) => {
            return <List.Item>
                     {point}
                   </List.Item>
            })
            }
        </List>
        )
    }

    //reusable infobar (pass custom classname, image, text if dont want to use default)
    return (
        <div className = {this.props.bodyStyleClassName || "info-bar-alert-body"}>
            <div className = {this.props.imageStyleClassName || "info-bar-alert-image"}>
              <img src = {this.props.iconImage || alertIcon}/>
            </div>
            <div className = {this.props.informationStyleClassName || "info-bar-alert-info"}>
                {this.props.bulletPoints && this.props.bulletPoints.length ? ShowBulletPoints() : this.props.information}
            </div>
        </div>
         )
     }
 }
 