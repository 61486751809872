import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { load as getMerchants, getReferrerDetails, setReferralFlag } from '../../redux/modules/merchantList';
import { verifyOtp, sendOtp } from '../../redux/modules/verifyOtpMagicStore';
import { claimBusiness, getImpressions, changeLanguage, getCustomerPreference, saveMerchantContacts, getBppBrands } from '../../redux/modules/claimBusiness';
import { getNewMerchantCustomerData,getMagicstoreText } from '../../redux/modules/newMerchantLanding';
import HeaderLogin from '../Header/HeaderLogin';
import SearchBusiness from './SearchBusiness';
import SearchBusinessDialog from './SearchBusinessDialog';
import {countries} from '../../constants';

import { setSource, set, get, MERCHANT_ID, CATEGORY_ID, REFERRAL, PHONE, COUNTRY, MERCHANT_LOGO } from '../../helpers/LocalStorageHelper'

import { sendEvent, currencyString, createMCPLog, debounce } from '../../helpers/Helpers';
import banner from '../../images/banner2.svg';
import bannerClaim from '../../images/banner-texture-mobile.jpg';
import bg1 from '../../images/dottedBG.png';
import mobileBanner from '../../images/banner-mob.svg';
import beautyScreenshot from '../../images/Beauty.jpg';
import fnbScreenshot from '../../images/F&B.jpg';
import groceryScreenshot from '../../images/grocery.jpg';
import fashionScreenshot from '../../images/Fashion.jpg';
import food from '../../images/food.svg';
import beauty from '../../images/beauty.svg';
import grocery from '../../images/grocery.svg';
import fashion from '../../images/fashion.svg';
import fnbLogos from '../../images/fnb_logos.png';
import beautyLogos from '../../images/beauty_logos.png';
import fashionLogos from '../../images/fashion_logos.png';
import groceryLogos from '../../images/grocery_logos.png';


import fnbLogosMob from '../../images/fnb_logos_mobile.png';
import beautyLogosMob from '../../images/beauty_logos_mobile.png';
import fashionLogosMob from '../../images/fashion_logos_mobile.png';
import groceryLogosMob from '../../images/grocery_logos_mobile.png';

import indBrandLogo from '../../images/indonesia_brand_logo.png';
import indBrandLogoMob from '../../images/indonesia_brand_logo_mobile.png';

import '../BrandPrivileges/BrandPrivileges.css';
import language from './languageConst.js';
import './OnlineStore.css'
import illustrationImg from '../../images/illustration.png'
import magicstoreIllustrationImg from '../../images/iphone-white-magicstore.png'
import offline from '../../images/offline.png';
import graph from '../../images/graph.png';
import ownFree from '../../images/own-free-online-store.png';
import screenshot1 from '../../images/1screenshot.png';
import screenshot2 from '../../images/2screenshot.png';
import screenshot3 from '../../images/3screenshot.png';
import screenshot4 from '../../images/4screenshot.png';
import screenshot5 from '../../images/5screenshot.png';
import screenshot6 from '../../images/6screenshot.png';
import screenshot7 from '../../images/7screenshot.png';
import group1 from '../../images/group-41.png';
import group2 from '../../images/group-34.png';
import group3 from '../../images/group-40.png';
import group4 from '../../images/group-35.png';
import group5 from '../../images/group-22.png';
import group6 from '../../images/group-25.png';
import illustration2 from '../../images/illustration2.png';
import Slider from 'react-slick/dist/react-slick';
import ReactPixel from 'react-facebook-pixel';
import illustrationBG from '../../images/illustration-magicstore-bg.png';

import chicagoPizzaImage from '../../images/partner-chicago.png';
import twentyFourSevenImage from '../../images/partner-24-seven.png';
import nirulaImage from '../../images/partner-nirula.png';
import wowMomoImage from '../../images/partner-wow.png';
import v3Image from '../../images/partner-v-3-q.png';
import barbequeImage from '../../images/partner-barbeque.png';

import rollsImage from '../../images/partner-rm-rolls.png'
import behrouzImage from '../../images/partner-behrouz.png'
import biryaniByKilo from '../../images/partner-biryani-by-kilo.png'
import fasoosImage from '../../images/partner-faasos.png'
import amulImage from '../../images/partner-amul.png'
import frozenImage from '../../images/partner-frozen-bottle.png'

import slayImage from '../../images/partner-slay.png'
import havmorImage from '../../images/partner-havmor.png'
import subwayImage from '../../images/partner-subway.png'
import bercosImage from '../../images/partner-bercos.png'
import instaPizzaImage from '../../images/partner-insta-pizza.png'


import ReactHtmlParser from 'react-html-parser';
import {isMobile} from 'react-device-detect';
import {toast} from "react-toastify";
import * as storage from "../../helpers/LocalStorageHelper";
import OtpVerificationMagicStore from "../Otp/OtpVerificationMagicStore";
import queryString from 'query-string';
import Footer from '../Claim/Footer';

const ga = window.ga;
let cssYPos = 0;

const categoryImg = {
  "1":{ "screenshot": fnbScreenshot},
  "2":{ "screenshot": beautyScreenshot},
  "5":{ "screenshot": groceryScreenshot},
  "3":{ "screenshot": fashionScreenshot},
}

export default connect(store => ({
  storeslist: store.merchantList.data,
  storesloading: store.merchantList.loading,
  storesloaded: store.merchantList.loaded,
  storeserror: store.merchantList.error,
  referrerData: store.merchantList.referrer_data,

  submitted: store.claimBusiness.submitted,
  submiterror: store.claimBusiness.error,
  impressionsCount: store.claimBusiness.impressionsCount,
  language: store.claimBusiness.language,
  customerPreference: store.claimBusiness.customerPreference,
  bppBrands: store.claimBusiness.bppBrands,

  summary: store.newMerchantLanding.summary,
  merchantInfo: store.newMerchantLanding.merchantInfo,
  postsLoading: store.newMerchantLanding.posts_loading,
  magicstoreCdnText: store.newMerchantLanding.magicstoreCdnText,
  otpSent: store.verifyOtpMagicStore.otpSent,
}), dispatch => bindActionCreators({
  getMerchants,
  claimBusiness,
  changeLanguage,
  getImpressions,
  getNewMerchantCustomerData,
  getReferrerDetails,
  setReferralFlag,
  getCustomerPreference,
  getBppBrands,
  saveMerchantContacts,
  getMagicstoreText,
  sendOtp,
  verifyOtp
}, dispatch))
  (class ClaimNew extends Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 'search',
        showDialog: false,
        openTopDiv: false,
        brandsTab: "retail",
        selectedCategory: 1,
        showLanguageDialog: false,
        shownLanguageDialog: false,
        showLanguageBar:false,
        otpDialog: false,
        otpRetry: true,
        otpVerified: false,
        otpCheck : 'check',
        sendSixDigitOtp: true
      };
      this.url = '';
      this.isDirectLink = false;
      this.languageCheck = false;
      this.categoryArray = [
        {
          categoryId: 1,
          categoryTitle: language[props.language].foodConst,
          categoryLogo: food,
        },
        {
          categoryId: 2,
          categoryTitle: language[props.language].beautyConst,
          categoryLogo: beauty,
        },
        {
          categoryId: 3,
          categoryTitle: language[props.language].fashionConst,
          categoryLogo: fashion,
        },
        {
          categoryId: 5,
          categoryTitle: language[props.language].groceryConst,
          categoryLogo: grocery,
        }
      ]
      this.partnersImage = [chicagoPizzaImage, twentyFourSevenImage, nirulaImage, wowMomoImage,
        v3Image, barbequeImage, rollsImage, behrouzImage, biryaniByKilo, fasoosImage, amulImage,
        frozenImage, slayImage, havmorImage, subwayImage, bercosImage, instaPizzaImage]

      this.partnersImageMobile = [chicagoPizzaImage, twentyFourSevenImage, wowMomoImage, barbequeImage, behrouzImage,
        fasoosImage, amulImage, subwayImage, instaPizzaImage]

      this.handlePhoneChange = debounce(this.handlePhoneChange, 500).bind(this);
      this.debouncedhandlePhoneChange = this.debouncedhandlePhoneChange.bind(this);
    }

    verifyOtp = (otp, phone) => {

      this.props.verifyOtp(otp, phone).then(res => {
        this.openOnboardingPage()
      })
    }

    handleListItemClick = (language) => {
      sendEvent({
        event: `language_select_${language}`,
        page_type: 'new_merchant_landing_magicstore',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
      });
    createMCPLog({
        merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        event: `language_select_${language}_magicstore`,
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        phone: this.state.phoneNumberInput ? this.state.phoneNumberInput : ''
    });
      this.setState({
        showLanguageDialog: false
      })
      this.props.changeLanguage(language);
    }

    getCategoryLogos = (categoryId, source="web", country = "India") => {
      if(country && country.toUpperCase() === countries.INDONESIA){
        if(source == "web")
          return indBrandLogo
        else
          return indBrandLogoMob
      }

      switch(categoryId){
        case 1:
          if(source == "web")
            return fnbLogos
          else
            return fnbLogosMob
        case 2:
          if(source == "web")
            return beautyLogos
          else
            return beautyLogosMob
        case 3:
          if(source == "web")
            return fashionLogos
          else
            return fashionLogosMob
        case 5:
          if(source == "web")
            return groceryLogos
          else
            return groceryLogosMob
        default:
          if(source == "web")
            return fnbLogos
          else
            return fnbLogosMob
      }
    }

    componentWillMount() {
      window['_fs_org'] = 'WY8XE';
      this.params = new URLSearchParams(this.props.location.search);
      var onboardingData = this.getOnboardingDataFromStorage()
      if (onboardingData.createdAt && Date.now() - onboardingData.createdAt >= 86400*1000*7) {
        this.clearOnboardingStorage(true)
        onboardingData = null
      }
      var merchantId
      if (this.params.has('merchant') && this.params.get('merchant')) {
        merchantId = this.params.get('merchant')
        merchantId = parseInt(this.params.get('merchant'), 13);
      } else if (onboardingData && onboardingData.merchantId) {
        merchantId = onboardingData.merchantId
      }
      var phone = ''
      if (this.params.has('phone') && this.params.get('phone')) {
        phone = parseInt(this.params.get('phone'), 13)
      } else if (onboardingData && onboardingData.phone) {
        phone = onboardingData.phone
      }
      if (phone && onboardingData) {
        this.setState({phoneNumberInput: phone, merchantId: merchantId},() => this.openOnboardingPage())
      }
      if (merchantId) {
        this.setState({phoneNumberInput: phone, merchantId: merchantId})
        if (phone) set(PHONE, phone)
        set(MERCHANT_ID, merchantId)
        this.isDirectLink = true;
        this.props.getMerchants('*', '*', merchantId);
        this.props.getNewMerchantCustomerData(merchantId).then(() => {
          if (this.props.merchantInfo){
            set(CATEGORY_ID, this.props.merchantInfo.categoryId)
            set(COUNTRY, this.props.merchantInfo.country)
            set(MERCHANT_LOGO, this.props.merchantInfo.profilePic)
            this.props.getCustomerPreference(merchantId,this.props.merchantInfo.localityId)
            if(!localStorage.getItem("landingLanguage")){
              if(this.props.merchantInfo.country && this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA)
                this.props.changeLanguage("bahasa" )
              else
                this.props.changeLanguage(this.props.merchantInfo.lang || "english" )
            }

          }
        }).catch(() => {
          this.setState({error:true})
        })
      }
       this.props.getMagicstoreText();
    }

    upsellFallback = () => {
      this.setState({showFosHeader:false})
    }

    componentDidMount() {
      this.props.changeLanguage('english');
      document.title = "Orderhere | Magicpin"

      let query=queryString.parse(this.props.location.search);

      if (query && query.otpCheck) {
        this.setState({
            otpCheck : query.otpCheck
        })
      }

      const token=query.otp;

      ga('set', 'page', 'NewMerchantLanding');
      ga('send', 'pageview');
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init('210666813526346', advancedMatching, options);

      ReactPixel.pageView();

      this.utm_source = this.params.has('utm_source') ? this.params.get('utm_source') : 'basic'
      sendEvent({
        event: 'page_view',
        page_type: 'new_merchant_landing_magicstore',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        isMobile: isMobile
      });
      createMCPLog({
        merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        event: 'new_merchant_landing_magicstore',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        phone: this.state.phoneNumberInput ? this.state.phoneNumberInput : ''
      });
      window.addEventListener('scroll', this.handleScroll);
            if (document.getElementById('top-header-bar'))
                document.getElementById('top-header-bar').classList.add('bpp')
      if(window.innerWidth > 768)

        this.closeLanguageBarInt = setInterval(this.closeLanguageBar, 15000);
      this.props.getBppBrands()

      if(this.props.merchantInfo){
        this.setState({
          selectedCategory: this.props.merchantInfo.categoryId
        })
      }
      window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          window['_fs_org'] = '2AAC5';
      });
    }

    componentWillUnmount() {
      window['_fs_org'] = '2AAC5';
      window.removeEventListener('scroll', this.handleScroll)
      if (document.getElementById('top-header-bar'))
          document.getElementById('top-header-bar').classList.remove('bpp')
      if(window.innerWidth > 768)
        clearInterval(this.interval);
        clearInterval(this.closeLanguageBarInt);
  }


    componentWillReceiveProps(nextProps) {
      if (nextProps.storesloaded && nextProps.storeslist && Array.isArray(nextProps.storeslist) && nextProps.storeslist.length && this.isDirectLink) {
        localStorage.setItem('landingMerchant', nextProps.storeslist[0].merchant_name);
        localStorage.setItem('landingMerchantId', nextProps.storeslist[0].merchant_id);
        localStorage.setItem('landingMerchantRating', nextProps.storeslist[0].rating);
        localStorage.setItem('landingMerchantReviews', nextProps.storeslist[0].total_txn_count);
        localStorage.setItem('landingLocality', nextProps.storeslist[0].locality);
        localStorage.setItem('landingCity', nextProps.storeslist[0].city);
        localStorage.setItem('landingMerchantLogo', nextProps.storeslist[0].merchant_logo);
        this.isDirectLink = false;
        this.changePage()
      }
      if(nextProps.language && nextProps.language != this.props.language){
        this.categoryArray = [
          {
            categoryId: 1,
            categoryTitle: language[nextProps.language].foodConst,
            categoryLogo: food,
          },
          {
            categoryId: 2,
            categoryTitle: language[nextProps.language].beautyConst,
            categoryLogo: beauty,
          },
          {
            categoryId: 3,
            categoryTitle: language[nextProps.language].fashionConst,
            categoryLogo: fashion,
          },
          {
            categoryId: 5,
            categoryTitle: language[nextProps.language].groceryConst,
            categoryLogo: grocery,
          }
        ]
      }
      if(nextProps.summary && nextProps.summary.ratingsData){
           this.setState({
             ratingValue:nextProps.summary.ratingsData.rating_value,
             reviewCount:nextProps.summary.ratingsData.total_rating_count
           })
         }
    }

    handleScroll = (event) => {
      event.preventDefault();
      let winScroll = window.scrollY;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrolled = (winScroll / height) * 100;
      let floaterLength = window.innerWidth > 768 ? 11 : 7;
      if (scrolled > floaterLength){
          this.setState({ 'openTopDiv': true , showLanguageBar: false})
          // if(!this.state.shownLanguageDialog){
          //    this.setState({showLanguageDialog: true, shownLanguageDialog : true})
          // }
        }
      else
          this.setState({ 'openTopDiv': false })

  }

    showAddMerchantDialog = () => {
      // this.setState({
      //   merchant_not_found: true,
      //   showDialog: false,
      // })
      sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'could_not_find',
        page_type: 'new_merchant_landing_magicstore'
      });
      createMCPLog({
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'could_not_find',
        utm_campaign: localStorage.getItem('landingSource') || ''
      });
      this.props.push('/addOutlet?utm_source=magicStore');
    }
    hideAddMerchantDialog = () => {
      this.setState({
        merchant_not_found: false,
        showDialog: false
      })
    }

    hideSurveyDialog = () => {
      this.setState({
        showSurveyDialog: false
      })
    }

    showOtpDialog = () => {
      if (this.state.otpCheck === 'skip') {
        this.openOnboardingPage()
        return
      }

      if (!this.state.phoneNumberInput) {
        return;
      }
      let subjectedPhoneNumber = this.state.phoneNumberInput.toString();
      if (subjectedPhoneNumber.length === 10) {
        subjectedPhoneNumber = "91" + subjectedPhoneNumber;
      }
      let isValidIndianMobile = /^(?:91)[6-9][0-9]{9}$/.test(subjectedPhoneNumber)
      if (isNaN(this.state.phoneNumberInput) || !isValidIndianMobile) {
        toast.warn("Please provide a valid phone number")
        return;
      }

      this.props.sendOtp(this.state.phoneNumberInput.toString(), this.state.sendSixDigitOtp)
      this.setState({
        otpDialog: true
      })
    }

    hideOtpDialog = () => {
      this.setState({
        otpDialog: false
      })
    }

    setOtpRetryState = () => {
      this.props.sendOtp(this.state.phoneNumberInput.toString(), this.state.sendSixDigitOtp)
      this.setState({
        otpRetry: true
      })
    }

    changePage = (isOnlineStore = false) => {
        if(isOnlineStore){
            // this.showClaimDialog();
            this.openOnboardingPage();
            return;
        }
      this.merchant = localStorage.getItem("landingMerchant");
      this.rating = localStorage.getItem("landingMerchantRating");
      this.merchant_id = localStorage.getItem("landingMerchantId");
      this.reviews = localStorage.getItem("landingMerchantReviews");
      window.gtag('event', 'page_view', { 'send_to': 'AW-940625898', 'dynx_itemid': this.merchant_id, 'dynx_pagetype': 'claim', 'local_id': this.merchant_id, 'local_pagetype': 'claim' });
      this.props.getImpressions(this.merchant_id).then((res) => {
        this.setState(
          { impressions: this.props.impressionsCount ? parseInt(this.props.impressionsCount) : 0 }
        )
      });
      this.props.getNewMerchantCustomerData(this.merchant_id).then(() => {
        if (this.props.merchantInfo){
          set(CATEGORY_ID, this.props.merchantInfo.categoryId)
          set(COUNTRY, this.props.merchantInfo.country)
          set(MERCHANT_LOGO, this.props.merchantInfo.profilePic)
          this.props.getCustomerPreference(this.merchant_id, this.props.merchantInfo.localityId)
          if(!localStorage.getItem("landingLanguage")){
            if(this.props.merchantInfo.country && this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA){
              this.props.changeLanguage('bahasa');
            }
            else
              this.props.changeLanguage("english");
          }
        }
      });
      if (this.rating) {
        this.rating = parseFloat(this.rating).toFixed(1);
      }
      this.setState({ page: 'claim' });
    };

    openOnboardingPage = () => {
      var subjectedPhoneNumber = this.state.phoneNumberInput.toString()
      if (subjectedPhoneNumber.length == 10) {
        subjectedPhoneNumber = "91" + subjectedPhoneNumber;
      }
      let isValidIndianMobile = /^(?:91)[6-9][0-9]{9}$/.test(subjectedPhoneNumber)
      if (isNaN(this.state.phoneNumberInput) || !isValidIndianMobile) {
        toast.warn("Please provide a valid phone number")
        return;
      }

      this.setState({
        otpVerified: true
      });

      let fbPixelData = {
          "utm_source": this.params.has('utm_source') ? this.params.get('utm_source') : "None",
          "utm_campaign": this.params.has('utm_campaign') ? this.params.get('utm_campaign') : "None"
      }
      ReactPixel.track("Lead", fbPixelData);
      let utmSource = this.params.has('utm_source') ? this.params.get('utm_source') : ''
      let utmCampaign = this.params.has('utm_campaign') ? this.params.get('utm_campaign') : ''
      let merchant = this.state.merchantId || ''
      sendEvent({
        event: 'submit_phone_number',
        page_type: 'new_merchant_landing_magicstore',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: merchant,
        phone_number_input: this.state.phoneNumberInput,
        isMobile: isMobile
      });
      createMCPLog({
        merchant_id: merchant,
        event: 'submit_phone_number',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        phone: this.state.phoneNumberInput ? this.state.phoneNumberInput : ''
      });

      this.setOnboardingDataInStorage("phone",subjectedPhoneNumber)

      this.props.push('/magicStoreOnboard?diys=1&ph=' + this.state.phoneNumberInput + '&merchant=' + merchant + '&utm_source=' + utmSource + '&utm_campaign=' + utmCampaign)
    }

    debouncedhandlePhoneChange (e) {
      this.setState({phoneNumberInput: e.target.value});
      this.handlePhoneChange(e.target.value);
    }

    handlePhoneChange = (phoneNumber) => {

      sendEvent({
        event: 'merchant_landing_magicstore_phone_input',
        page_type: 'new_merchant_landing_magicstore',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        phone_number_input: phoneNumber,
        isMobile: isMobile
      });
      createMCPLog({
        merchant_id: this.params.has('merchant') ? parseInt(this.params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        event: 'merchant_landing_magicstore_phone_input',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct'
      });
    };

    showClaimDialog = () => {
      if(this.params.has('utm_campaign') && this.params.get('utm_campaign') === 'field_demo'){
        alert('this is demo mode');
        return;
      }
      this.setState({ showDialog: true });
    }

    showSearch = () => {
      this.setState({ page: 'search' });
    }

    hideClaimDialog = () => {
      this.setState({ showDialog: false });
    }

    push = (url) => {
      if (localStorage.getItem("landingMerchantId"))
        this.props.push(url);
      else {
        this.url = url;
        this.showClaimDialog();
      }
    }

    changeBrandsTab = (tab) => {
      sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: `merchant_logos_${tab}`,
        page_type: 'new_merchant_landing'
      });
      createMCPLog({
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: `merchant_logos_${tab}`,
        utm_campaign: localStorage.getItem('landingSource') || ''
      });
      this.setState({brandsTab:tab})
    }

    changeCategory = (categoryId) => {
      sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'change_category_logos',
        page_type: 'new_merchant_landing'
      })
      createMCPLog({
        utm_campaign: localStorage.getItem('landingSource') || '',
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'change_category_logos'
      });
      this.setState({
        selectedCategory: categoryId
      })
    }

    closeLanguageBar = () => {
      this.setState({
        showLanguageBar: false
      })
    }

    phoneNumberKeyPress = (e) => {
      if (e.keyCode == 13) {
        this.changePage(true)
      }
    }

    getOnboardingDataFromStorage() {
      let onbDataObjName = "magicSaasOnboardingData"
      let onbDataObj = storage.get(onbDataObjName)
      if (onbDataObj) {
        try {
          onbDataObj = JSON.parse(onbDataObj)
          return onbDataObj
        } catch (e) {

        }
      }
      return false
    }

    setOnboardingDataInStorage(key,value) {
      if (key && value) {
        let onbDataObjName = "magicSaasOnboardingData"
        let onbDataObj = this.getOnboardingDataFromStorage()
        if (!onbDataObj) {
          onbDataObj = {}
        }
        if (key == "merchantId" && onbDataObj.subscriptionId && onbDataObj.merchantId != value) {
          this.setOnboardingDataInStorage("subscriptionId",false)
        }
        onbDataObj[key] = value
        storage.set(onbDataObjName,JSON.stringify(onbDataObj))
      }
    }

    clearOnboardingStorage(skipRedirect = false) {
      localStorage.removeItem("magicSaasOnboardingData");
      if (!skipRedirect) {
        window.location.reload()
      }
    }

    sendUigEvent = (eventName) => {
      const params = new URLSearchParams(this.props.location.search);
      const utm_source = params.has('utm_source') ? params.get('utm_source') : 'basic'
      sendEvent({
        event: eventName,
        page_type: 'orderhere_signup',
        subject_type: 'merchant',
        utm_source: utm_source,
        utm_medium: params.has('utm_medium') ? params.get('utm_medium') : '',
        utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: params.has('merchant') ? parseInt(params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
      });
      createMCPLog({
        utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        merchant_id: params.has('merchant') ? parseInt(params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        event: eventName
      });
    }

    render() {
      var bannerUrl = banner;
      bannerUrl = "url(" + bannerUrl + ")";
      var claimBannerUrl = bannerClaim;
      claimBannerUrl = "url(" + claimBannerUrl + ")";
      var mobileBannerUrl = mobileBanner;
      mobileBannerUrl = "url(" + mobileBannerUrl + ")";

      const settings = {
        dots: true,
        accessibility: true,
        arrows: false,
        draggable: true,
        swipeToSlide: true,
        variableWidth: true,
        centerMode: true,
        centerPadding: '0px',
        dotsClass: 'slick-dots slick-dots-new',
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 4000,
        infinite: false
      };

      return (
        <div>
          <div style={{ display: ((this.state.showDialog || this.state.showSurveyDialog) && window.innerWidth < 800) ? 'none' : 'block' }}>
            {/* {this.state.showLanguageBar && <LanguageBar categoryId={this.props.merchantInfo ? this.props.merchantInfo.categoryId: 1} language={this.props.language} closeLanguageBar={this.closeLanguageBar} />} */}
            {/* {this.state.showFosHeader && <HeaderLogin user={this.props.user} login={this.props.login}
              loggingIn={this.props.loggingIn}
              loginError={this.props.loginError}
              verifyOtp={this.props.verifyOtp}
              push={this.props.push}
              country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country : "india"} />}
               */}
            {!this.state.showFosHeader &&
              <div>
                <div style={{width: "100%", position: "absolute", zIndex: "10", paddingLeft: window.innerWidth > 768 ? "120px" : "4%", paddingRight: window.innerWidth > 768 ? "120px" : "4%", marginTop: "24px"}}>
                    <div className= "orderhere-header-dukaan" style={window.innerWidth<768 ? {marginLeft:"3%"}:{}}>
                      <span className="orderhere-header-main-text">OrderHere</span>
                      Free online dukaan
                    </div>
                  <div onClick={() => {window.location.href = "https://magicpin.in/partners/login"}} style={{position:"absolute", right:"7%", top: "0", float: "right" ,color: "white",width: "89px",height: "36px",borderRadius: "4px",border: "1px solid white",padding: "7px", cursor: "pointer", }}>
                    Login
                  </div>
                </div>
                {true ?
                <header style={{ backgroundColor: "#2e0090", textAlign:"left", position: "relative", paddingBottom: window.innerWidth > 768 ? "" : "24px" }} className="landing-header landing-header2">
                    <div style={{width: window.innerWidth > 768 ? "60%" : "100%", display:"inline-block", float:"left"}}>
                    <section className="header-details-holder header-details-holder2 pl-120" style={{width:window.innerWidth > 768 ? "90%" : "100%",marginTop:window.innerWidth > 768 ? "" : "16px"}}>
                      {this.props.merchantInfo && this.props.merchantInfo.profilePic && <div><img src={this.props.merchantInfo.profilePic} style={{width: "48px", height: "48px", borderRadius: "8px", marginTop: "24px", float: window.innerWidth < 768 ? "left" : ""}} /></div>}
                      {this.props.merchantInfo && this.props.merchantInfo.merchantName && <h3 style={{color: "white", fontSize: "20px"}}>Hey, <b>{this.props.merchantInfo.merchantName}</b></h3>}
                      {this.props.magicstoreCdnText && this.props.magicstoreCdnText.sas_onboarding_title ? ReactHtmlParser(this.props.magicstoreCdnText.sas_onboarding_title) : ''}
                      {this.props.magicstoreCdnText && this.props.magicstoreCdnText.sas_onboarding_sub_title ? ReactHtmlParser(this.props.magicstoreCdnText.sas_onboarding_sub_title) : ''}
                    </section>
                    <input type="number" placeholder="Phone Number" onChange={this.debouncedhandlePhoneChange} onKeyDown={(e) => this.phoneNumberKeyPress(e)} defaultValue={this.state.phoneNumberInput || ''} name="" id="" style={{marginLeft: window.innerWidth > 768 ? "120px" :"7%", width: window.innerWidth > 768 ? "345px" : "85%", height: "52px", fontSize: "20px", padding: "16px", borderRadius: "8px", position: window.innerWidth > 768 ? "absolute" : "", zIndex: !this.state.otpDialog && "1000000"}} />
                    <input type="button" value={this.props.magicstoreCdnText && this.props.magicstoreCdnText.sign_up_bt ? ReactHtmlParser(this.props.magicstoreCdnText.sign_up_bt) : 'SIGN UP FOR FREE'} onClick={this.showOtpDialog} style={{marginTop: window.innerWidth > 768 ? "" : "15px", marginLeft: window.innerWidth > 768 ? "24px" : "7%", width: window.innerWidth > 768 ? "220px" : "85%", height: "51px", backgroundColor: "rgb(239, 28, 116)", color: "white", fontSize: "20px", border: "none", borderRadius: "8px", position: window.innerWidth > 768 ? "absolute" : "", left: window.innerWidth > 768 ? "475px" : "", zIndex: !this.state.otpDialog && "1000000"}} />
                    </div>
                    {window.innerWidth > 768 && !this.props.merchantInfo &&
                      <div className="parent-scroller">
                          <img style={{marginTop:"2%"}} src={illustrationImg} />
                      </div>
                    }
                    {window.innerWidth > 768 && this.props.merchantInfo &&
                      <div className="parent-scroller" style={{right: "-4%"}}>
                          <img style={{marginTop:"2%"}} src={illustrationBG} />
                          <img style={{bottom: "0%",position: "absolute",right: "24%",width: "380px"}} src={magicstoreIllustrationImg} />
                          <div style={{position: "relative", "left": "32%", top: "-326px", width: "264px", overflow:"wrap",textAlign: "center", fontSize: "14.2px"}}>{this.props.merchantInfo.merchantName}</div>
                          <div style={{position: "relative", "left": "34%", top: "-293px", width: "123px", overflow:"wrap",textAlign: "right", fontSize: "8.5px"}}>{this.props.merchantInfo.locality}</div>
                      </div>
                    }

                    {/* <h6 style={{ fontFamily: "robotolight", marginTop:'16px', color:"#fff", fontSize: window.innerWidth > 768 ?  "16px" : "14px" }}><b>45</b> Merchants joined us in the last <b>7 days</b></h6> */}
                    {/* <div className="partner-logos">
                      <img src={lifestyle} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={lakme} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={mcdonalds} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={subway} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    </div> */}
                  </header>:
                    <header style={{ backgroundColor: "#2e0090", textAlign:"left", position: "relative"  }} className="landing-header landing-header2">
                        <HeaderLogin user={this.props.user} login={this.props.login}
                        loggingIn={this.props.loggingIn}
                        loginError={this.props.loginError}
                        verifyOtp={this.props.verifyOtp}
                        push={this.props.push}
                        selectLanguage={true}
                        loginViaOtp={true} />
                        <div style={{width: window.innerWidth > 768 ? "52%" : "100%", display:"inline-block", float:"left"}}>
                            <section className="header-details-holder header-details-holder2" style={{width:window.innerWidth > 768 ? "90%" : "100%"}}>

                            { window.innerWidth < 768 && <img src={illustration2} style={{ height: "200px"}} />}
                            <div style={window.innerWidth < 768 ? { paddingLeft: "5%", paddingRight: "5%", paddingTop: "10px"} : {}}>
                            <span style={{fontFamily:"robotoregular",fontSize: window.innerWidth > 768 ? "32px" : "20px", color:"#fff"}}>Hey, <span style={{fontFamily:"robotomedium"}}>{this.merchant}</span></span>
                            </div>
                            <h1>Start serving your customers online in less than 5 mins.</h1>
                            {window.innerWidth > 768 && <h3 style={{ fontFamily: "robotoregular", fontSize: "40px", marginTop:'24px', marginBottom:'8px', color: "#fff", fontStyle: 'italic' }}>No commission, forever!</h3>}
                            </section>
                            {/* <SearchBusiness showAddMerchantDialog={this.showAddMerchantDialog} changePage={this.changePage} push={this.props.push} language={this.props.language} /> */}
                            <section className="claim-holder1 claim-holder2">
                                <button className="try-button1 button-header-hover" onClick={this.showClaimDialog}>CLAIM YOUR ONLINE STORE</button>
                            </section>
                            <div className="not-business-text" onClick={this.showSearch}><h3 style={{marginBottom: "0px"}} className="not-business">Not your business? </h3> <span className="click-me">Click Here</span></div>
                        </div>
                        {window.innerWidth > 768 &&
                        <div className="parent-scroller">
                            <img style={{marginTop:"2%"}} src={illustrationImg} />
                        </div>
                        }

                    {/* <h6 style={{ fontFamily: "robotolight", marginTop:'16px', color:"#fff", fontSize: window.innerWidth > 768 ?  "16px" : "14px" }}><b>45</b> Merchants joined us in the last <b>7 days</b></h6> */}
                    {/* <div className="partner-logos">
                    <img src={lifestyle} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    <img src={lakme} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    <img src={mcdonalds} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    <img src={subway} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    </div> */}
                </header>

                }
              </div>
            }
            <section className="partners-orderhere">
              <div className="partners-heading">Our Partners</div>
              {window.innerWidth > 768 ?
                  this.partnersImage.map((partnerImg) => {
                    return <img src={partnerImg} style={{width: "100px", height: "100px", margin: "6px"}}/>
                  })
                  :
                  this.partnersImageMobile.map((partnerImg) => {
                    return <img src={partnerImg} style={{width: "100px", height: "100px", margin: "6px"}}/>
                  })
              }
            </section>
            <section className="row row1">
                <div className="col-md-5 section-info-left">
                Own your online store for FREE now!
                </div>
                <div className="col-md-7 section-info-right">
                    <img src={ownFree} />
                    <p style={window.innerWidth > 768 ? { position: "absolute", top: "45%", left: "37%", fontSize: "23px", fontFamily: "roboto" } : { position: "absolute", top: "35%", left: "34%", fontSize: "12px", fontFamily: "roboto" }}>https://<b>your-store</b>.orderhere.io/</p>
                </div>
            </section>
            {window.innerWidth > 768 && <section className="row row1 reverse">
                <div className="col-md-6 section-info-left" style={{textAlign: 'center'}}>
                    <img src={graph} />
                    <p>Ever increasing commissions</p>
                </div>
                <div className="col-md-6 section-info-right">
                  Avoid ever increasing high commission to third party aggregators.
                </div>
            </section>}
            {window.innerWidth < 768 && <section className="row row1 reverse">
                <div className="col-md-6 section-info-left" style={{paddingLeft: "50px !important", paddingRight: "50px !important"}}>
                  Avoid ever increasing high commission to third party aggregators.
                </div>
                <div className="col-md-6 section-info-right">
                    <img src={graph} />
                    <p>Ever increasing commissions</p>
                </div>
            </section>}
            <section className="partners1" style={window.innerWidth < 768 ? {paddingBottom: "20px"} : {}}>
                <h2>Give your customers a world class home ordering experience.</h2>
                { window.innerWidth < 768 &&
                    <Slider {...settings} >
                        <div className="screenshot-img-container">
                            <img src={screenshot1} />
                            <p>Setup your online store & share it with your customers.</p>
                        </div>
                        <div className="screenshot-img-container" style={{marginTop: window.innerWidth > 768 ? '40px' : 'unset'}}>
                            <img src={screenshot2} />
                            <p>Customers visit your store & pick items from the catalogue.</p>
                        </div>
                        <div className="screenshot-img-container">
                            <img src={screenshot3} />
                            <p>They pay online to place their orders.</p>
                        </div>
                        <div className="screenshot-img-container" style={{marginTop: window.innerWidth > 768 ? '40px' : 'unset'}}>
                            <img src={screenshot4} />
                            <p>and track the delivery status for 100% satisfaction.</p>
                        </div>
                    </Slider>
                }
                { window.innerWidth > 768 &&
                    <div className="screenshot-container">
                        <div>
                            <img src={screenshot1} />
                            <p>Setup your online store & share it with your customers.</p>
                        </div>
                        <div style={{marginTop: window.innerWidth > 768 ? '40px' : 'unset'}}>
                            <img src={screenshot2} />
                            <p>Customers visit your store & pick items from the catalogue.</p>
                        </div>
                        <div>
                            <img src={screenshot3} />
                            <p>They pay online to place their orders.</p>
                        </div>
                        <div style={{marginTop: window.innerWidth > 768 ? '40px' : 'unset'}}>
                            <img src={screenshot4} />
                            <p>and track the delivery status for 100% satisfaction.</p>
                        </div>
                    </div>
                }
            </section>
            <section className="partners1" style={window.innerWidth > 768 ? {} : {paddingBottom: "40px"}}>
                <h2>Completely own your online store.</h2>
                {window.innerWidth > 768 && <div className="screenshot-container">
                    <div>
                        <img className="screenshot-5" src={screenshot5} />
                        <p>Setup your online store & share it with your customers.</p>
                    </div>
                    <div style={{marginTop: '50px'}}>
                        <img className="screenshot-6" src={screenshot6} />
                        <p style={{width: "100%"}}>Customers visit your store & pick items from the catalogue.</p>
                    </div>
                    <div>
                        <img className="screenshot-5" src={screenshot7} />
                        <p>They pay online to place their orders.</p>
                    </div>

                </div>}
                {window.innerWidth < 768 && <Slider {...settings}>
                    <div className="screenshot-img-container">
                        <img className="screenshot-5" src={screenshot5} />
                        <p>Setup your online store & share it with your customers.</p>
                    </div>
                    <div className="screenshot-img-container" style={{marginTop: '50px'}}>
                        <img className="screenshot-6" src={screenshot6} />
                        <p style={{width: "100%"}}>Customers visit your store & pick items from the catalogue.</p>
                    </div>
                    <div className="screenshot-img-container">
                        <img className="screenshot-5" src={screenshot7} />
                        <p>They pay online to place their orders.</p>
                    </div>

                </Slider>}
            </section>
            <section className="partners1">
                <h2>Get access to powerful marketing tools for FREE!</h2>
                <div className="marketing-tools-container">
                    <div className="marketing-tools">
                        <div className="row row1">
                            <div className="group1">
                                <img src={group1} />
                                <p>Offers from our payment gateway partners </p>
                            </div>
                            <div className="group2">
                                <img src={group2} />
                            </div>
                        </div>
                        <div className="row row1">
                            <div className="group1">
                                <img src={group3} />
                                <p>Get access to easy loans to drive more demand </p>
                            </div>
                        </div>
                    </div>
                    <div className="marketing-tools">
                        <div className="row row1">
                            <div className="group1">
                                <img src={group6} />
                                <p>Engage with customers using SMS </p>
                            </div>
                        </div>
                        <div className="row row1">
                            <div className="group1">
                                <img src={group5} />
                                <p>Earn additional income by selling magicpin partner brands </p>
                            </div>
                            <div className="group2">
                                <img src={group4} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={window.innerWidth > 768 ?{backgroundColor:"#fff", margin:"0", padding:"120px 13%"} : {backgroundColor:"#fff", margin:"0", padding:"16px 3%"}}>
              <div className="download-section">
                <div className="download-store-heading" >Download the OrderHere app to easily manage your online store</div>
                <div>
                  <a onClick={() => this.sendUigEvent("footer_playstore")}
                     href="https://play.google.com/store/apps/details?id=com.magicpin.partner"  target="_blank"
                     style={window.innerWidth > 768 ?{margin:"48px 12px"}:{margin: "16px 12px"}}>
                    <img src="https://d1qp59yxlq7zhd.cloudfront.net/static/images/playstore.png" alt="playstore logo" />
                  </a>
                  <a onClick={() => this.sendUigEvent("footer_applestore")}
                     target="_blank" href="https://apps.apple.com/in/app/orderhere-easy-online-dukaan/id1528283353"
                     style={window.innerWidth > 768 ?{margin:"48px 12px"}:{margin: "16px 12px"}}>
                    <img src="https://d1qp59yxlq7zhd.cloudfront.net/static/images/appstore.png" alt="appstore logo" />
                  </a>
                </div>
              </div>
            </section>

          </div>
          {this.state.showDialog &&
            <SearchBusinessDialog
              merchant_not_found={this.state.merchant_not_found}
              open={true} close={this.hideClaimDialog}
              hideAddMerchantDialog={this.hideAddMerchantDialog}
              push={this.props.push}
              url={this.url}
              fos = {this.state.showFosHeader}
              source='claim'
              utm_source = {this.utm_source}
              language={this.props.language}
              country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country : "india"}
              city={this.props.merchantInfo && this.props.merchantInfo.city.toLowerCase()}
              opportunity_id={this.params.has("opportunity_id") ? this.params.get("opportunity_id") : null}
              onlineStore
              />
          }
                  {/* {window.innerWidth > 768 && this.state.page.localeCompare("claim") == 0 && (get(COUNTRY) && (get(COUNTRY).toUpperCase() != countries.INDONESIA) && <ExitPopup saveMerchantContacts={this.props.saveMerchantContacts} />} */}

          {(this.state.otpRetry || this.props.otpSent) && !this.state.otpVerified && this.state.otpDialog &&
            <OtpVerificationMagicStore
                retryCount = {3}
                closePopUp = {this.hideOtpDialog}
                number = {this.state.phoneNumberInput.toString()}
                tryAgain = {this.setOtpRetryState}
                verifyOtp={this.verifyOtp}
                openOnboardingPage = {this.openOnboardingPage}
            />
          }

          <Footer language={this.props.language} country={get(COUNTRY)} location={this.props.location} showCopyright={true} isOrderHere={true} hideDownloadSection={true}/>

        </div>

      );
    }
  })
