const TRANSFER_MONEY = 'transferMoney/TRANSFER_MONEY';
const TRANSFER_MONEY_SUCCESS = 'transferMoney/TRANSFER_MONEY_SUCCESS';
const TRANSFER_MONEY_FAIL = 'transferMoney/TRANSFER_MONEY_FAIL';

const REWARD_VOUCHER = 'transferMoney/REWARD_VOUCHER';
const REWARD_VOUCHER_SUCCESS = 'transferMoney/REWARD_VOUCHER_SUCCESS';
const REWARD_VOUCHER_FAIL = 'transferMoney/REWARD_VOUCHER_FAIL';

const initialState = {
    loaded: false,
};

export default function transferMoney(state = initialState, action = {}) {

    switch (action.type) {
        case TRANSFER_MONEY:
            return {
                ...state,
                loading: true
            };

        case TRANSFER_MONEY_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                transferStatus: action.result,

            }

        case TRANSFER_MONEY_FAIL:
            return {
                ...state,
                loading: false,
                loaded: true,

            }
        
        case REWARD_VOUCHER:
            return {
                ...state,
                voucherLoading: true
            };

        case REWARD_VOUCHER_SUCCESS:
            return {
                ...state,
                voucherLoading: false,
                voucherLoaded: true,
                voucherDetails: action.result.voucherDetails,

            }

        case REWARD_VOUCHER_FAIL:
            return {
                ...state,
                voucherLoading: false,
                voucherLoaded: true,

            }
        default:
            return state;

    }
}

export function sendMoneyToLoanAccount(accessToken, payload) {
    return {
        types: [TRANSFER_MONEY, TRANSFER_MONEY_SUCCESS, TRANSFER_MONEY_FAIL],
        promise: (client) => client.default.post('/transferMoneyToLoanAccount', {
            data: {
                accessToken,
                ...payload
            }
        })
    };
}

export function loadRewardVoucherDetails(accessToken) {
    return {
        types: [REWARD_VOUCHER, REWARD_VOUCHER_SUCCESS, REWARD_VOUCHER_FAIL],
        promise: (client) => client.default.post('/getLoanRepaymentRewards', {
            data: {
                accessToken            
            }
        })
    };
}