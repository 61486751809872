import React, {Component} from "react";
import tick from "./../../images/tick-copy.svg"
import infoIcon from "./../../images/info-icon.png"
import  './OrderHereEnterDetails.css'
import {toast} from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import * as storage from "../../helpers/LocalStorageHelper";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { getSubscriptionPackageData, getSubscriptionId, addOutlet, getGMV, activateDirectMagicstore, getMUIDByAlias, activateSaasUltron, getOrdersURL} from '../../redux/modules/merchantOnboarding';
import {createMCPLog, debounce, sendEvent} from "../../helpers/Helpers";
import {isMobile} from "react-device-detect";
import {getSuggestedAlias,createAlias} from "../../redux/modules/newMerchantLanding";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Link} from "react-router-dom";

export default connect(store => ({
    activateDirectResponse: store.merchantOnboarding.activateDirectResponse,
    suggestedAlias: store.newMerchantLanding.suggestedAlias,
    createAliasResult: store.newMerchantLanding.createAliasResult,
    muidByAliasResponse: store.merchantOnboarding.muidByAliasResponse,
    ultronOnboardingResponse: store.merchantOnboarding.ultronOnboardingResponse,
    orderShortUrlResponse: store.merchantOnboarding.orderShortUrlResponse
}), dispatch => bindActionCreators({
    getSubscriptionId,
    activateDirectMagicstore,
    getSuggestedAlias,
    createAlias,
    getMUIDByAlias,
    activateSaasUltron,
    getOrdersURL
}, dispatch))
(class OrderHereEnterDetails extends Component {

    state = {
        phoneNumberInput: "",
        aliasPicked: "",
        suggestedAlias: "",
        submitButtonPressed: false,
        storeLinkChanged: false,
        redirectLink : "/magicStoreOnboard"
    }

    phoneNumberKeyPress = (e) => {
        if (e.keyCode === 13) {
            // set focus to alias
        }
    }

    aliasNamePress = (e) => {
        if (e.keyCode === 13 ) {
            if (this.state.aliasUnavailable) {
                alert("Online store name unavailable")
                return
            }
            this.payOnline()
        }
    }

    debouncedhandlePhoneChange = (e) => {
        this.setState({phoneNumberInput: e.target.value});
    }

    debouncehandleAliasChange = (e) => {
        if (!this.state.storeLinkChanged) {
            this.createGBQEvent("store_alias_box_clicked")
            this.setState({
                storeLinkChanged: true
            })
        }

        var val = e.target.value
        this.setState({aliasPicked: val, aliasUnavailable: false})
        if (val.length < 5) {
            this.setState({aliasPicked: val,aliasLengthError: true, aliasUnavailable: true})
            return
        }
        let aliasRegex = new RegExp("[^a-zA-Z0-9-]+")
        let isInValidAlias = aliasRegex.test(val)
        if (isInValidAlias) {
            this.setState({aliasPicked: val, aliasUnavailable: true})
            return
        }
        this.setState({aliasLengthError: false,aliasPicked: val})
        this.getMUIDByAlias(val);
    }

    getMUIDByAlias(alias) {
        this.props.getMUIDByAlias(alias).then(() => {
            let unavailable = false;
            if (this.props.muidByAliasResponse && this.props.muidByAliasResponse.merchant_user_id && this.props.muidByAliasResponse.merchant_user_id !== 0) {
                unavailable = true;
            }
            this.setState({aliasPicked: alias, aliasUnavailable: unavailable})

        }).catch((err) => {
                this.setState({aliasPicked: alias, aliasUnavailable: true})
            })
    }

    generatePSR = () => {
        if (this.state.generatingPSR || this.state.verifyingPSR) {
            return;
        }

        const packageType = "magicSaas"
        this.setState({
            generatingPSR: true,
            packageType: packageType
        })
        const payload = {
            amount: 0,
            packageName: 'magicSaas',
            merchantPhone: parseInt(this.state.phoneNumberInput),
            merchantId: parseInt(this.props.merchantId),
            createdBy: "merchant_online_store",
            offerings:[{offeringName:"enable_saas",offeringValue:1}]
        }
        this.props.getSubscriptionId({ payload: payload, merchantId: this.props.merchantId }).then(res => {
            if(res.length){
                this.subscriptionId = res[0].subscriptionId
                localStorage.setItem("subscriptionId",this.subscriptionId)
                this.payOnline()
            } else {
                toast.warn("Something went wrong")
            }
            this.setState({
                generatingPSR: false
            })
        })
            .catch((err) => {
                toast.warn(err.message)
                this.setState({
                    generatingPSR: false
                })
            })
        const params = this.props.params
        let fbPixelData = {
            "phone"     : this.state.phoneNumberInput,
            "utm_source": params.has('utm_source') ? params.get('utm_source') : "None",
            "utm_campaign": params.has('utm_campaign') ? params.get('utm_campaign') : "None",
            "merchantId": this.props.merchantId,
        }
        ReactPixel.trackCustom("claim_complete", fbPixelData);
        this.createGBQEvent("claim_complete")
        this.createGBQEvent("magicstore_signup")
    }

    payOnline = () => {
        if (this.state.verifyingPSR) {
            return;
        }
        const params = this.props.params
        let fbPixelData = {
            "utm_source": params.has('utm_source') ? params.get('utm_source') : '',
            "utm_campaign": params.has('utm_campaign') ? params.get('utm_campaign') : '',
            "merchant_id": this.props.merchantId,
            "phone"     : this.state.phoneNumberInput
        }

        ReactPixel.trackCustom("create_your_store", fbPixelData)

        if (this.state.aliasUnavailable) {
            toast.warn("Please select a different storename")
            return;
        }

        this.setState({
            verifyingPSR: true
        })
        /*var data = {
            "merchantId": this.props.merchantId,
            "packageName": "magicSaas",
            "merchantPhone": localStorage.getItem(storage.PHONE),
            "subscriptionId": this.subscriptionId,
            "moveWithoutVerification": true,
            "offerings": [{"offeringName":"enable_saas","offeringValue":1}]
        }*/
        const payload = {
            "merchantId": this.props.merchantId,
            "phoneNumber": localStorage.getItem(storage.PHONE),
            "moveWithoutVerification": true,
            "source": "web",
            "offerings": [{"offeringName":"enable_saas","offeringValue":1}],
            "merchantName" : this.props.merchantName,
            "locality" : this.props.locality
        }
        this.props.activateSaasUltron(payload).then(() => {
            if (this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.status && this.props.ultronOnboardingResponse.status.toUpperCase() === 'SUCCESS') {
                this.setState({
                    loginShortUrl: this.loginShortUrl,
                    privilegeActivated: true,
                    openPlanDetails: true,
                    verifyingPSR: false
                })
                this.setOnboardingDataInStorage("merchantId",this.props.merchantId)
                this.setOnboardingDataInStorage("panelLink",this.loginShortUrl)
                this.createConversionEvents(payload)
                if (this.state.aliasPicked) {
                    this.props.createAlias(this.props.userId, this.state.aliasPicked).then(() => {
                        this.openOrdersPage()
                    }).catch((err) => {
                        this.openOrdersPage()
                    });
                }
            } else {
                let message = (this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message) || "An error occurred creating your Online Store. Try again."
                toast.warn(message)
                this.setState({error:true,verifyingPSR: false})
                if ((this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message)) {
                    const params = this.props.params
                    sendEvent({
                        event: "magicstore_signup_error",
                        entity_name: this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message,
                        page_type: 'magicstore_onboarding',
                        subject_type: 'merchant',
                        utm_source: params.has('utm_source') ? params.get('utm_source') : '',
                        utm_medium: params.has('utm_medium') ? params.get('utm_medium') : '',
                        utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : '',
                        subject_merchant_id: this.props.merchantId ? this.props.merchantId : localStorage.getItem('landingMerchantId') || '',
                        isMobile: isMobile
                    });
                }
            }
        }).catch((err) => {
            let message = (this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message) || "An error occurred creating your Online Store. Try again."
            toast.warn(message)
            this.setState({error:true,verifyingPSR: false})
            if ((this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message)) {
                const params = this.props.params
                sendEvent({
                    event: "magicstore_signup_error",
                    entity_name: this.props.ultronOnboardingResponse && this.props.ultronOnboardingResponse.message,
                    page_type: 'magicstore_onboarding',
                    subject_type: 'merchant',
                    utm_source: params.has('utm_source') ? params.get('utm_source') : '',
                    utm_medium: params.has('utm_medium') ? params.get('utm_medium') : '',
                    utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : '',
                    subject_merchant_id: this.props.merchantId ? this.props.merchantId : localStorage.getItem('landingMerchantId') || '',
                    isMobile: isMobile
                });
            }
        })
    }

    openOrdersPage = () => {
        this.props.getOrdersURL(this.props.merchantId).then(res => {
            this.loginShortUrl = this.props.orderShortUrlResponse.shortUrl ? JSON.parse(this.props.orderShortUrlResponse.shortUrl) : this.props.orderShortUrlResponse.defaultUrl
            window.location.assign(this.loginShortUrl)
        }).catch(() => {
            this.loginShortUrl = "https://magicpin.in/partners/login"
            window.location.assign(this.loginShortUrl)
        })
    }

    createGBQEvent = (eventName) => {
        const params = this.props.params
        sendEvent({
            event: eventName,
            page_type: 'magicstore_onboarding',
            subject_type: 'merchant',
            utm_source: params.has('utm_source') ? params.get('utm_source') : '',
            utm_medium: params.has('utm_medium') ? params.get('utm_medium') : '',
            utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : '',
            subject_merchant_id: this.props.merchantId ? this.props.merchantId : localStorage.getItem('landingMerchantId') || '',
            isMobile: isMobile
        });
        createMCPLog({
            merchant_id: this.props.merchantId ? this.props.merchantId : localStorage.getItem('landingMerchantId') || '',
            event: eventName,
            utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : '',
            utm_source: params.has('utm_source') ? params.get('utm_source') : '',
            phone: params.has('ph') && params.get('ph') ? params.get('ph') : ''
        });
    }

    getOnboardingDataFromStorage() {
        let onbDataObjName = "magicSaasOnboardingData"
        let onbDataObj = storage.get(onbDataObjName)
        if (onbDataObj) {
            try {
                onbDataObj = JSON.parse(onbDataObj)
                return onbDataObj
            } catch (e) {

            }
        }
        return false
    }

    setOnboardingDataInStorage(key,value) {
        if (key && value) {
            let onbDataObjName = "magicSaasOnboardingData"
            let onbDataObj = this.getOnboardingDataFromStorage()
            if (!onbDataObj) {
                onbDataObj = {}
            }
            if (key === "merchantId" && onbDataObj.subscriptionId && onbDataObj.merchantId !== value) {
                this.setOnboardingDataInStorage("subscriptionId",false)
            }
            onbDataObj[key] = value
            storage.set(onbDataObjName,JSON.stringify(onbDataObj))
        }
    }

    componentDidMount() {
        if (this.props.phoneNumber) {
            this.setState({phoneNumberInput: this.props.phoneNumber})
        }
        const params = this.props.params;
        let utmSource = params.has('utm_source') ? params.get('utm_source') : ''
        let utmCampaign = params.has('utm_campaign') ? params.get('utm_campaign') : ''
        this.setState({
            redirectLink : `/magicStoreOnboard?diys=1&ph=${this.props.phoneNumber}&merchant=${this.props.merchantId}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`
        })

        this.getMUIDByAlias = debounce(this.getMUIDByAlias, 500).bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.merchantName !== this.props.merchantName || prevProps.locality !== this.props.locality) {
            this.props.getSuggestedAlias(this.props.merchantName,this.props.locality).then(() => {
                if (this.props.suggestedAlias) {
                    this.setState({aliasPicked: this.props.suggestedAlias[0]})
                }
            });
        }
    }

    handleAliasChange = (e) => {
        var val = e.target.value
        this.setState({aliasPicked: val})
    }

    createConversionEvents = (payload) => {
        const params = this.props.params;
        let fbPixelData = {
            "phone"     : this.state.phoneNumberInput,
            "utm_source": params.has('utm_source') ? params.get('utm_source') : "None",
            "utm_campaign": params.has('utm_campaign') ? params.get('utm_campaign') : "None",
            "merchantId": this.props.merchantId,
        }
        ReactPixel.trackCustom("psr_verified", payload);
        this.createGBQEvent("psr_verified")
        if (this.state.isDIYS) {
            this.createGBQEvent("magicstore_signup")
        }
    }


    render() {
        return (
            <div className="orderhere-details-container">

                <div className="orderhere-details-join-text" >Create online store for <b style={{fontStyle: "italic"}}>{this.props.merchantName}</b></div>

                <div>
                    <div className="orderhere-details-mobile-text" >Mobile number</div>
                    <input  className="orderhere-details-mobile-input" type="number"
                            onKeyDown={(e) => this.phoneNumberKeyPress(e)}
                            onChange={this.debouncedhandlePhoneChange} placeholder= "Phone Number"
                            value={this.state.phoneNumberInput || ''}
                    />
                </div>

                <div style={{marginTop: "16px"}}>
                    <div className= "orderhere-details-domain-text">Select your domain name</div>
                    <div style={{ marginTop: "8px", fontFamily: "robotoregular", fontSize: "14px", lineHeight: "20px", fontColor: "rgba(0, 0, 0, 0.87)"}}>
                        https://
                        <input className="orderhere-details-domain-input" value={this.state.aliasPicked || ''}
                               onChange={(e) => this.debouncehandleAliasChange(e)}
                               onKeyDown={(e) => this.aliasNamePress(e)} placeholder="myownstore"
                        />
                        .orderhere.io
                    </div>
                    {this.state.aliasUnavailable &&
                        <div style={{textAlign: "left", color: "#ef1c74"}}>
                            {this.state.aliasLengthError ? "Storename should be minimum 5 characters long" : "This storename is not available"}
                        </div>
                    }
                </div>

                <div className="orderhere-details-feature-container">
                    <div  style={ { marginTop: "8px", display:"flex"}}>
                        <img src = {tick} className="orderhere-details-tick-image"/>
                        <div className="orderhere-details-feature-text" >Accept online orders</div>
                    </div>
                    <div  style={ { marginTop: "8px", display:"flex"}}>
                        <img src = {tick} className="orderhere-details-tick-image"/>
                        <div className="orderhere-details-feature-text" >Delivery through your own staff or through 3rd party logistics (e.g. Shadowfax)</div>
                    </div>
                    <div  style={ { marginTop: "8px", display:"flex"}}>
                        <img src = {tick} className="orderhere-details-tick-image"/>
                        <div className="orderhere-details-feature-text" >Accept online payments
                            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip"><div>PG charges : 0% for UPI and 2% for other payment modes</div></Tooltip>}>
                                <img className="info-icon" src={infoIcon} style={{ marginLeft: "4px", marginRight: "4px", width: "12px", height: "12px" }}/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div  style={ { marginTop: "8px", display:"flex"}}>
                        <img src = {tick} className="orderhere-details-tick-image"/>
                        <div className="orderhere-details-feature-text" >No marketing or demand generation Commission EVER</div>
                    </div>
                </div>

                <div className= "orderhere-create-btn" onClick={this.payOnline}
                     style={this.state.generatingPSR || this.state.verifyingPSR ? {alpha: '0.2', backgroundColor: "#bbb"}: {}}>

                    <div style={{width: "100%", textAlign: "center"}}>
                        {this.state.generatingPSR || this.state.verifyingPSR ? "Signing up..." : "SIGN UP"}
                    </div>

                    {this.state.generatingPSR || this.state.verifyingPSR ?
                        <div style={{width: "20%", position: "relative"}}>
                            <span className="loading"/>
                        </div>
                        : ""
                    }
                </div>

                <div style={{marginTop: "8px", fontSize: "10px", textAlign: "center"}}>
                    Not your store ? <Link style={{cursor: "pointer", color: "#ef1c74" }} to={this.state.redirectLink} onClick={() => this.createGBQEvent("not_your_store")}>Click Here</Link>
                </div>
            </div>
        )
    }
})