import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import checkedLogo from '../../images/checkbox-selected.svg';
import SendOtpNew from '../Otp/SendOtpNew';
import './PromotionDeductModal.css';
import { toast } from 'react-toastify';
import  cross from '../../images/cross2.png'
import  backBtn from '../../images/back-btn-popup.svg'
import { getCurrencySymbol } from '../../helpers/Helpers';
import {otpUseCaseIdentifierObj, userTypes} from '../../constants'
export default class PromotionDeductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payNow: false,
      showSendOtpModal: true,
    }
  }
  render() {
    let currency=getCurrencySymbol(this.props.user.country)
    return (
      <Modal show={this.state.showSendOtpModal} dialogClassName="promotion-deduct-modal" id="OTPpopup" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true" data-remote="" onHide={this.props.hideSendOtpModal}>
        <div style={window.innerWidth > 768 ? { marginLeft: '20px', marginRight: '20px' } : { marginLeft: '5px', marginRight: '20px' }}>
         {this.state.payNow && <img src={backBtn} style={{ float: 'left', marginTop: '15px' }} onClick={() => this.setState({payNow: false})} />}
          <img src={cross} style={{ float: 'right', marginTop: '15px' }} onClick={this.props.hideSendOtpModal} />
          {!this.state.payNow ?
            <Modal.Header style={window.innerWidth > 768 ? { margin: '0px 63px 9px 40px' } : { margin: '0px 0px 9px 7px ' }}>
              {this.props.title && <div className="modal-title" style={window.innerWidth > 768 ? { width: '434px', fontSize: '22px' } : { width: '100%', marginLeft: '5px', fontSize: '20px' }}>{this.props.title}</div>}
            </Modal.Header>
            :
            <Modal.Header style={window.innerWidth > 768 ? { margin: '0px 63px 9px 40px' } : { margin: '0px 0px 9px 7px ' }}>
            </Modal.Header>
          }
          <Modal.Body style={{ padding: '16px' }}>
            <div className="claim-listing">
              {!this.state.payNow ?
                <div>
                  {this.props.message && <span style={{ textAlign: 'center', fontSize: '17px', marginBottom: '16px', paddingTop: '0', marginTop: '10px', color: 'rgba(0,0,0,0.81)' }}>{this.props.message}</span>}
                  {this.props.isNewMerchant && <h3 style={{ textAlign: 'left', marginBottom: '16px', fontSize: '20px' }}>Is it <b>{this.props.merchantInfo.merchantName},</b> {this.props.merchantInfo.locality}?</h3>}
                  <div className="content-rectangle" style={window.innerWidth > 768 ? { width: '72%', marginLeft: '75px', marginRight: '60px' } : { width: '81%', marginLeft: '30px', marginRight: '0px' }}>
                    <div style={window.innerWidth > 768 ? { width: '320px' } : { width: '100%' }} className="feed-push-amount">
                      Feed push amount
                        <span className="black-text" style={window.innerWidth > 768 ? { marginRight: '24px' } : { marginRight: '40px', marginTop: '3px' }}>{currency}300</span>
                    </div>
                    <div className="box-separator"></div>
                    <div style={window.innerWidth > 768 ? { width: '320px' } : { width: '100%' }} className="feed-push-amount">
                      <img src={checkedLogo} style={{ height: '20px' }} /> Use wallet balance
                        <span className="blue-text" style={window.innerWidth > 768 ? { marginRight: '24px' } : { marginRight: '38px', marginTop: '3px' }}>-{currency}300</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12" style={{ marginTop: '24px', marginBottom: '40px' }}>
                      <input type="submit" onClick={() => { (this.setState({ payNow: true })) }} className="btn-submit" value={`Pay 300`} style={{ width: '50%', padding: '10px 8px', height: '40px', borderRadius: '4px', fontWeight: '500' }} />
                    </div>
                  </div>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5%', marginBottom: '10%' }}>
                  <SendOtpNew
                    user={this.props.user}
                    members={this.props.members}
                    allowedUserTypes={[userTypes.MERCHANT, userTypes.MERCHANT_MANAGER, userTypes.MERCHANT_EMPLOYEE]}
                    onVerify={() => { this.setState({ showSendOtpModal: false, payNow: false }, this.props.onOtpVerified) }}
                    onReject={() => {toast.error("Couldnot verify otp") }}
                    resendOtp={true}
                    otpUseCaseIdentifier = {otpUseCaseIdentifierObj.FEED_PUSH_PURCHASE}
                  />
                </div>
              }
            </div>
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
