import React, { Component } from 'react'
import language from './languageConst.js';
import {countries} from '../../constants';
import { sendEvent, createMCPLog } from '../../helpers/Helpers';

export default class Footer extends Component {

  sendUigEvent = (eventName) => {
    const params = new URLSearchParams(this.props.location.search);
    const utm_source = params.has('utm_source') ? params.get('utm_source') : 'basic'
    sendEvent({
      event: eventName,
      page_type: 'new_merchant_landing',
      subject_type: 'merchant',
      utm_source: utm_source,
      utm_medium: params.has('utm_medium') ? params.get('utm_medium') : '',
      utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
      utm_content: this.props.location.search,
      subject_merchant_id: params.has('merchant') ? parseInt(params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
    });
    createMCPLog({
        phone: localStorage.getItem("subjectPhoneNumber") || '',
        utm_campaign: params.has('utm_campaign') ? params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        merchant_id: params.has('merchant') ? parseInt(params.get('merchant'), 13) : localStorage.getItem('landingMerchantId') || '',
        event: eventName
    });
  }
  getDictionaryLinks = () => {
    const dictArray = [];
    for (let i = 65; i <= 90; i++){
      dictArray.push(
        <a data-type="footer-dir" href={"https://magicpin.in/dir/" + String.fromCharCode(i)}>{String.fromCharCode(i)}</a>
      )
    }
    return dictArray
  }

  render() {
    const lang = this.props.language ? language[this.props.language.toLowerCase()] : language["english"];
    const country = this.props.country || countries.INDIA;
    return (
      <div className="site-footer">
            <div className="site-footer-container">
                <header>
                    {this.props.showCopyright && <section className="keywords-playstore">
                            <p className="footer-playstore-link-label">People also searched for: </p>
                            <a className="footer-playstore-link" href="https://play.google.com/store/apps/details?id=com.magicpin.partner">Free online dukaan app</a>
                            <a className="footer-playstore-link" href="https://play.google.com/store/apps/details?id=com.magicpin.partner">Online catalog app for free</a>
                            <a className="footer-playstore-link" href="https://play.google.com/store/apps/details?id=com.magicpin.partner">Whatsapp catalog sharing app</a>
                            <a className="footer-playstore-link" href="https://play.google.com/store/apps/details?id=com.magicpin.partner">Online business app</a>
                            <a className="footer-playstore-link" href="https://play.google.com/store/apps/details?id=com.magicpin.partner">Sell online app</a>
                        </section>
                    }
                    <section onClick={() => this.sendUigEvent("footer_catgeory")} className="footer-categories">
                        <p className="label">{lang.categoryConst}:</p>
                        <a data-type="footer-category" href="https://magicpin.in/india/all/all/Restaurant/">{lang.restaurantConst}</a>
                        <a data-type="footer-category" href="https://magicpin.in/india/all/all/Beauty/">{lang.beautyConst}</a>
                        <a data-type="footer-category" href="https://magicpin.in/india/all/all/Fashion/">{lang.fashionConst}</a>
                        <a data-type="footer-category" href="https://magicpin.in/india/all/all/Grocery/">{lang.groceryConst}</a>
                        <a data-type="footer-category" href="https://magicpin.in/india/all/all/Fitness/">{lang.fitnessConst}</a>
                    </section>
                    <section className="footer-social">
                        <a onClick={() => this.sendUigEvent("social_facebook")} data-type="footer-social" target="_blank" href="https://www.facebook.com/mymagicpin/"><img alt="facebook icon" className="after-load" src="https://static.magicpin.com/samara/static/images/facebook-v1.svg" /></a>
                        <a onClick={() => this.sendUigEvent("social_instagram")} data-type="footer-social" target="_blank" href="https://www.instagram.com/mymagicpindelhi/"><img alt="instagram icon" className="after-load" src="https://static.magicpin.com/samara/static/images/instagram-v1.svg" /></a>
                        <a onClick={() => this.sendUigEvent("social_linkedln")} data-type="footer-social" target="_blank" href="https://www.linkedin.com/company/10072367?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10072367%2Cidx%3A1-1-1%2CtarId%3A1467354238488%2Ctas%3Amagicpin%20">
                            <img alt="linkedin icon" className="after-load" src="https://static.magicpin.com/samara/static/images/linkedin-v1.svg" />
                        </a>
                        <a data-type="footer-social" target="_blank" href="https://twitter.com/mymagicpin"><img alt="twitter icon" className="after-load" src="https://static.magicpin.com/samara/static/images/twitter-v1.svg" /></a>
                    </section>
                </header>
                {country.toUpperCase() === countries.INDIA ?
                <section className="footer-links">
                    <section onClick={() => this.sendUigEvent("footer_cities")} className="links-content">
                        <header className="links-header">{lang.popularCities}</header>
                        <ul className="links">
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/New-Delhi">New Delhi</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Gurgaon">Gurgaon</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Bangalore">Bangalore</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Chandigarh">Chandigarh</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Ghaziabad">Ghaziabad</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Faridabad">Faridabad</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Jaipur">Jaipur</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/NCR">NCR</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Mohali">Mohali</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Mumbai">Mumbai</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Navi-Mumbai">Navi - Mumbai</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Thane">Thane</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Rishikesh">Rishikesh</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Coorg">Coorg</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Kasol">Kasol</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.in/india/Haridwar">Haridwar</a>
                        </ul>
                    </section>
                    <section onClick={() => this.sendUigEvent("footer_localities")} className="links-content">
                        <header className="links-header">{lang.popularLocalities}</header>
                        <ul className="links">
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Bangalore/Koramangala/">Koramangala</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Bangalore/Brigade-Road/">Brigade Road</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Bangalore/HSR/">HSR</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Gurgaon/Sector-29/">Sector 29, Gurgaon</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Gurgaon/DLF-Cyber-City/">DLF Cyber City</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Gurgaon/Ambience-Mall/">Ambience Mall</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Chandigarh/Sector-8/">Sector 8, Chandigarh</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Chandigarh/Sector-17/">Sector 17, Chandigarh</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Chandigarh/Sector-11/">Sector 11, Chandigarh</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Jaipur/C-Scheme/">C Scheme, Jaipur</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Mumbai/Bandra-Kurla-Complex/">Bandra Kurla Complex</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Mumbai/Colaba/">Colaba</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/Mumbai/Malad-West/">Malad West</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/New-Delhi/Connaught-Place-(CP)/ ">Connaught Place (CP)</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/New-Delhi/Hauz-Khas-Village/">Hauz Khas Village</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.in/india/New-Delhi/Tagore-Garden/">Tagore Garden</a>
                        </ul>
                    </section>
                    <section onClick={() => this.sendUigEvent("footer_restaurants")} className="links-content">
                        <header className="links-header">{lang.popularRestaurants}</header>
                        <ul className="links">
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Bangalore/Koramangala/Restaurant/Truffles/store/6368/">Truffles</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Bangalore/Koramangala/Restaurant/Bistro-Claytopia/store/6399/">Bistro Claytopia</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Bangalore/Brigade-Road/Restaurant/Arbor-Brewing-Company/store/5646/"> Arbor Brewing Company</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Chandigarh/Sector-15/Restaurant/Burger-Point/store/3107b/">Burger Point</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Chandigarh/Sector-35/Restaurant/Nik-Baker's/store/27511/">Nik Baker's</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Chandigarh/Sector-32/Restaurant/Flames/store/27492/">Flames</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Gurgaon/Sector-29/Restaurant/Molecule-Air-Bar/store/1b4cb/">Molecule Air Bar</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Gurgaon/Sector-29/Restaurant/Warehouse-Cafe/store/7351/">Warehouse Cafe</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Gurgaon/Sector-29/Restaurant/Vapour-Bar-Exchange/store/7068/">Vapour Bar Exchange</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Jaipur/Tonk-Road/Restaurant/JECRC-Cafeteria/store/319b2/">JECRC Cafeteria</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Mumbai/Bandra-Kurla-Complex/Restaurant/Starbucks-Coffee/store/232b2/">Starbucks Coffee</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Mumbai/Colaba/Restaurant/Leopold-Cafe-&-Bar/store/29559/">Leopold Cafe & Bar</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/Mumbai/Malad-West/Restaurant/Joey's-Pizza/store/2535a/">Joey's Pizza</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/New-Delhi/GTB-Nagar/Restaurant/Rico's/store/291/">Rico's</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/New-Delhi/GTB-Nagar/Restaurant/QD's-Restaurant/store/292/">QD's Restaurant</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.in/New-Delhi/Hauz-Khas-Village/Restaurant/Hauz-Khas-Social/store/3c48/">Hauz Khas Social</a>
                        </ul>
                    </section>
                </section>
                :
                <section className="footer-links">
                    <section onClick={() => this.sendUigEvent("footer_cities")} className="links-content">
                        <header className="links-header">{lang.popularCities}</header>
                        <ul className="links">
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/South-Jakarta">South Jakarta</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/North-Jakarta">North Jakarta</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/Central-Jakarta">Central Jakarta</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/Tangerang">Tangerang</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/East-Jakarta">East Jakarta</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/West-Jakarta">West Jakarta</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/Depok">Depok</a>
                            <a data-type="footer-city" className="link" href="https://magicpin.com/indonesia/Bogor">Bogor</a>
                        </ul>
                    </section>
                    <section onClick={() => this.sendUigEvent("footer_localities")} className="links-content">
                        <header className="links-header">{lang.popularLocalities}</header>
                        <ul className="links">
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Jagakarsa/all/">Jagakarsa</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/North-Jakarta/Mangga-Dua/all/">Mangga Dua</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/North-Jakarta/HSR/all/">Kelapa Gading</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Sector-29/all/">Blok M</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/Central-Jakarta/Thamrin/all/">Thamrin</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Ambience-Mall/all/">Pondok Indah</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/Tangerang/Kec.-Tangerang/all/">Kec. Tengarang</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Gandaria/all/">Gandaria</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/Tangerang/Serpong-Utara/all/">Serpong Utara</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Kuningan/all/">Kuningan</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/Central-Jakarta/Gajah-Mada/all/">Gajah Mada</a>
                            <a data-type="footer-locality" className="link" href="https://magicpin.com/indonesia/South-Jakarta/Tebet/all/">Tebet</a>
                        </ul>
                    </section>
                    <section onClick={() => this.sendUigEvent("footer_restaurants")} className="links-content">
                        <header className="links-header">{lang.popularRestaurants}</header>
                        <ul className="links">
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/South-Jakarta/Blok-M/Restaurant/Join-Kopi-Bulungan/store/29C990/">Join-Kopi-Bulungan</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/KFC/store/29B40A/">KFC</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/HokBen/store/29B615/">HokBen</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/A&W/store/29B606/">A&W</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/Brew-&-Seeds/store/2A2740/">Brew & Seeds</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/Pizza-Hut/store/29B5CA/">Pizza Hut</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/Dewi-Luwak/store/29B600/">Dewi Luwak</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/Dum-Dum-Thai-Drinks/store/2A16A1/">Dum Dum Thai Drinks</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Kelapa-Gading/Restaurant/Chatime/store/29B704/">Chatime</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/Eaton/store/2A2A48/">Eaton</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/Central-Jakarta/Hayam-Wuruk/Restaurant/Burger-King/store/29B3CC/">Burger King</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/Central-Jakarta/Bendungan-Hilir/Restaurant/Starbucks/store/2A02A0/">Starbucks</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/Central-Jakarta/Gajah-Mada/Restaurant/Joni-Steak/store/29B45B/">Joni-Steak</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Ancol/Restaurant/RM-Padang-Raya-Baru/store/30250C/">RM Padang Raya Baru</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Kelapa-Gading/Restaurant/Yoshinoya/store/2A560C/">Yoshinoya</a>
                            <a data-type="footer-merchant" className="link" href="https://magicpin.com/North-Jakarta/Mangga-Dua/Restaurant/J.CO-Donuts-&-Coffee/store/2A2BAB/">J.CO Donuts & Coffee</a>

                        </ul>
                    </section>
                </section>
                }

                <section onClick={() => this.sendUigEvent("footer_aboutus")} className="footer-nav">
                    <a data-type="footer-nav" href="https://magicpin.in/about-us/">{lang.aboutUs}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/india/New-Delhi/vouchers/">{lang.vouchers}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/india/">{lang.aroundYou}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/blog/">{lang.blog}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/ar-quests/">{lang.augmentedReality}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/partner/store/terms/">{lang.termsCond}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/partner/store/communitystandards/">{lang.commStand}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/partner/store/privacy/">{lang.privacy}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/sitemap/">{lang.sitemap}</a>
                    <a data-type="footer-nav" href="https://magicpin.in/brands/">{lang.brands}</a> 
                </section>

                {this.props.showCopyright && <section className="copyright-sec">
                        <div>&copy; Samast Technologies Pvt. Ltd.</div>
                    </section>
                }

                <section onClick={() => this.sendUigEvent("footer_directory")} className="footer-directory">
                    <p>{lang.directory}</p>
                    <section className="directory-link">
                        {this.getDictionaryLinks()}
                        <a data-type="footer-dir" href="https://magicpin.in/dir/Others">Others</a>
                    </section>
                </section>

                {!this.props.hideDownloadSection &&
                <section className="app-links">
                    <a onClick={() => this.sendUigEvent("footer_playstore")} data-type="footer-app-link" target="_blank"
                       href={this.props.isOrderHere?"https://play.google.com/store/apps/details?id=com.magicpin.partner":"https://play.google.com/store/apps/details?id=com.magicpin.local"}>
                        <img className="after-load" src="https://lh3.googleusercontent.com/YyaeqBo4yVfRGzofbuv6pGFYz45PFC7YOkmsLc1pPkspT1o4sJwDXL18AhehxyAc5rPhow6U2qFa-KzsAB4Zo7aLJ_mZ9T1BIZqDiAk=w120-rw" alt="playstore logo" />
                    </a>
                    <a onClick={() => this.sendUigEvent("footer_applestore")} data-type="footer-app-link" target="_blank"
                       href={this.props.isOrderHere?"https://apps.apple.com/in/app/orderhere-easy-online-dukaan/id1528283353":"https://itunes.apple.com/in/app/magicpin-cashback-deals/id1171775851?mt=8"}>
                        <img className="after-load" src="https://lh3.googleusercontent.com/6r3772qJJ6zmuIaM6QvHCk3RbAh8mbmvjbjqSr0jf1MjeVKv4Xks8hJk9IiplfcUgdOZWVfUzW67w03ulTlXV7P0cS6zpRcQx2ODy98rKQ=w120-rw" alt="appstore logo" />
                    </a>
                    {!this.props.isOrderHere &&
                    <a onClick={() => this.sendUigEvent("footer_webapp")} data-type="footer-app-link" target="_blank"
                       href="https://magicpin.in/webapp">
                        <img className="after-load" src="https://lh3.googleusercontent.com/G5sgw1_EgAJjG3guS7_4LTjLKw3TquGtn9HVafvYBFJT7xgyG5CBWcpCHuCt6_lyXMWFsvBZoXw_9I3RkJT2MsgPJqqCLpTF-6p-lsg=w120-rw"
                             alt="webapp logo"/>
                    </a>
                    }
                </section>
                }

            </div>
        </div>
    )}
}