const LOAD = 'merchant-list/LOAD';
const LOAD_SUCCESS = 'merchant-list/LOAD_SUCCESS';
const LOAD_FAIL = 'merchant-list/LOAD_FAIL';
const REFERRER_LOADING = 'merchant-list/REFERRER_LOADING'
const REFERRER_SUCCESS = 'merchant-list/REFERRER_SUCCESS'
const REFERRER_FAILURE = 'merchant-list/REFERRER_FAILURE'
const REFERRAL = 'merchant-list/REFERRAL'
const CITIES_LOADING = 'merchant-list/CITIES_LOADING'
const CITIES_SUCCESS = 'merchant-list/CITIES_SUCCESS'
const CITIES_FAILURE = 'merchant-list/CITIES_FAILURE'
const CITY_IP_LOADING = 'merchant-list/CITY_IP_LOADING'
const CITY_IP_SUCCESS = 'merchant-list/CITY_IP_SUCCESS'
const CITY_IP_FAILURE = 'merchant-list/CITY_IP_FAILURE'
const SEARCH_BY_PHONE_LOADING = 'merchant-list/SEARCH_BY_PHONE_LOADING'
const SEARCH_BY_PHONE_SUCCESS = 'merchant-list/SEARCH_BY_PHONE_SUCCESS'
const SEARCH_BY_PHONE_FAILURE = 'merchant-list/SEARCH_BY_PHONE_FAILURE'
const GET_MERCHANT_LIST = 'merchant-list/GET_MERCHANT_LIST'
const GET_MERCHANT_LIST_SUCCESS = 'merchant-list/GET_MERCHANT_LIST_SUCCESS'
const GET_MERCHANT_LIST_FAIL = 'merchant-list/GET_MERCHANT_LIST_FAIL'

const initialState = {
  loaded: false,
  data: [],
};

const getMerchantsFiltered = (merchants = [], city = "*", locality = "*") => {
  if(!merchants || !Array.isArray(merchants) || merchants.length <=0 )
  {
    return []
  }

  if(city && city !== "*"){
    merchants = merchants.filter((merchant) => merchant.city === city)
  }

  if(locality && locality !== "*"){
    merchants = merchants.filter((merchant) => merchant.locality === locality)
  }

  return merchants
}

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: null
      };
    case REFERRER_SUCCESS:
      return{
        ...state,
        referrer_data: action.result
      }
    case REFERRAL:
      return{
        ...state,
        isReferral:true,
        referrerId:action.referrerId
      }
    case CITIES_SUCCESS:
      return{
        ...state,
        cities:action.result
      }
    case CITY_IP_SUCCESS:
      return{
        ...state,
        cityFromIp:action.result
      }

    case SEARCH_BY_PHONE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_BY_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: getMerchantsFiltered(action.result, action.city, action.locality),
        error: null
      };

    case SEARCH_BY_PHONE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: null
      };
    case GET_MERCHANT_LIST:
      localStorage.removeItem('merchantList');
      return {
        ...state
      };
    case GET_MERCHANT_LIST_SUCCESS:
      return {
        ...state,
        merchantList: action.result
      };
    case GET_MERCHANT_LIST_FAIL:
      return {
        ...state,
        verifyOtpError: action.error
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.merchantList && globalState.merchantList.loaded;
}

export function load(city, locality, query, searchType = null) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getMerchants', {
      data: {
        city,
        locality,
        query,
        searchType
      }
    })
  };
}

export function getReferrerDetails(city, locality, query){
  return{
    types: [REFERRER_LOADING, REFERRER_SUCCESS, REFERRER_FAILURE],
    promise: (client) => client.default.post('/getMerchants', {
      data: {
        city,
        locality,
        query,
      }
    })
  }
}

export function setReferralFlag(referrerId){
  return{
    type: REFERRAL,
    referrerId: referrerId
  }
}

export function getCities(){
  return{
    types: [CITIES_LOADING, CITIES_SUCCESS, CITIES_FAILURE],
    promise: (client) => client.default.get('/getCities', {})
  }
}

export function getCityFromIp(){
  return{
    types: [CITY_IP_LOADING, CITY_IP_SUCCESS, CITY_IP_FAILURE],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/get_city_from_ip/', {})
  }
}

export function getMerchantStoreList(city, locality, phone, accessToken){
  return{
    types: [SEARCH_BY_PHONE_LOADING, SEARCH_BY_PHONE_SUCCESS, SEARCH_BY_PHONE_FAILURE],
    promise: (client) => client.default.post('/getMerchantStoreList', {
      data: {
        city,
        locality,
        phone,
        accessToken
      }
    }),
    city,
    locality
  }
}

export function getMerchantListFromPhone(phoneNumber, otp) {
  return{
    types: [GET_MERCHANT_LIST, GET_MERCHANT_LIST_SUCCESS, GET_MERCHANT_LIST_FAIL],
    promise: (client) => client.default.post('/getMerchantListFromPhone', {
      data: {
        phoneNumber,
        otp
      }
    }),
  }
}