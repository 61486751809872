const SHOW_LAUNCH_FIXED_CASHIN = 'fixedCashin/SHOW_LAUNCH_FIXED_CASHIN';

const initialState = {
    launching: false,
    launched: false,
    showFixedCashinPopup: false,
}

export default function fixedCashin(state = initialState, action = {}) {
    switch (action.type) {
        case SHOW_LAUNCH_FIXED_CASHIN :
            return{
                ...state,
                showFixedCashinPopup : action.showFixedCashinPopup
            }
        default:
            return state;
    }
}


export function launchFixedCashinModal(showFixedCashinPopup){
    return{
        type: SHOW_LAUNCH_FIXED_CASHIN,
        showFixedCashinPopup
    }
}