import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'material-ui/Dialog';

import { toast } from 'react-toastify';
import { getOrderHereAlias} from '../../redux/modules/newMerchantLanding';

import '../Dashboard/dashboard.css';

import { getShortUrl } from '../../redux/modules/adminReducers/shortUrl';
import { getAllCrmData } from '../../redux/modules/merchantCrm';

import { sendEvent} from '../../helpers/Helpers';
import moment from 'moment';

import saasCopyBtn from '../../images/home-delivery-saas-merchants-copy-white.svg';
import qrCodeIcon from '../../images/home-delivery-qr-code-icon.svg';
import fb from '../../images/fb-share.png';
import FbShare from '../FbShare/FbShare';
import whatsapp from '../../images/whatsapp-share.png';
import QRCodeDialog from '../HomeDelivery/QRCodeDialog'; // import ReferCard from './ReferCard';
import domtoimage from 'dom-to-image';
import smsImage from '../../images/sms1.svg'

const saltFromAuth = window.localStorage.getItem('saltFromAuth');

const styles = {
    titleStyle: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        fontSize: '16px',
        backgroundColor: '#ef1c74',
        color: '#fff',
        padding: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
  
    contentStyle: {
        borderRadius : '8px',
        width: '300px',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px'
    },
  
    closeStyle: {
        position: 'absolute',
        top: '4px',
        right: '8px',
        width: '16px',
        height: '16px'
    },
  
    bodyStyle: {
        padding: '16px',
    },
  
    paperStyle: {
        style: {
            borderRadius: '8px',
        }
    }
  }

export default connect(state => ({
    tokenValsData: state.newMerchantLanding.tokenValsData,
    alias: state.newMerchantLanding.alias,
    shortUrlLoading: state.shortUrl.loading,
    allCrmData: state.merchantCrm.all_crm_data,

  }), dispatch => bindActionCreators({
    getOrderHereAlias,
    getShortUrl,
    getAllCrmData
  }, dispatch))
  (class ShareStoreDialog extends Component {
    constructor(props){
        super(props)
        this.state = {
            showQrModal: false,
            openShare: true,
            shortUrl: '',
            shortUrlWithAlias: '',
            shortUrlWithAliasForWhatsapp: '',
            shortUrlWithAliasForFb: ''
        }
        this.componentRef = React.createRef();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.openShare !== this.props.openShare){
            this.setState({openShare: nextProps.openShare})
        }
    }

    componentDidMount() {
        this.props.getOrderHereAlias(this.props.user.accessToken).then(() => {
            if (this.props.alias) {
                if(this.state.shortUrlWithAlias === "" && this.state.shortUrlWithAliasForWhatsapp === "" 
                && this.state.shortUrlWithAliasForFb === "" && !this.props.shortUrlLoading) {
                let longUrl = this.props.alias
                let longUrlForWhatsapp = this.props.alias+"?utm_source=merchant_portal&utm_medium=whatsapp&utm_campaign=orderhere"
                let longUrlForFb = this.props.alias+"?utm_source=merchant_portal&utm_medium=facebook&utm_campaign=orderhere"
                Promise.all([this.props.getShortUrl(this.props.user.accessToken, this.props.user.merchantId, null, longUrl),
                             this.props.getShortUrl(this.props.user.accessToken, this.props.user.merchantId, null, longUrlForWhatsapp),
                             this.props.getShortUrl(this.props.user.accessToken, this.props.user.merchantId, null, longUrlForFb)])
                             .then(result => {
                                this.setState({shortUrlWithAlias: result[0], shortUrlWithAliasForWhatsapp: result[1], shortUrlWithAliasForFb: result[2]})
                             })
              }
            }
          })
        if(!this.props.allCrmData) {
            // this.props.getAllCrmData(this.props.user.salt, this.props.user.userId);
            this.props.getAllCrmData((this.props.tokenValsData && this.props.tokenValsData.salt) ? this.props.tokenValsData.salt : this.props.user.salt, this.props.user.userId)
        }

    }


  copyLink = () => {
    this.shortURL.select();

    document.execCommand('copy');
    toast.info("Copied!")
    this.setState({
        openShare: false
    })
    this.props.hideShareStoreDialog();
    this.sendEventWithUtm("copylink")
    this.sendShareLinkEvent("link_share_copy");
  }

  sendEventWithUtm = (utmMedium) => {
    sendEvent({
        event: 'orderhereaction',
        actor_id: this.props.user.merchantId,
        page_type: 'merchant_portal',
        subject_type: 'merchant',
        utm_source: 'merchant_portal',
        utm_medium: utmMedium,
        utm_campaign: 'orderhere',
        utm_content: this.props.location.search
      });
  }

  sendShareLinkEvent = (entityName = "") => {
    sendEvent({
        event: "click",
        actor_id: this.props.user.merchantId,
        actor_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
        subject_merchant_id: this.props.user.merchantId,
        user_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
        merchant_email: this.emailId,
        datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        timestamp: Date.now(),
        device: window.innerWidth > 768 ? 'desktop' :'mobile',
        entity_name: entityName,
        page_type: "merchant_panel_order"
      });
}

handleCloseQrPopup = () => {
  this.setState({showQrModal: false});

}

handleOpenQrPopUp = () => {
    this.setState({showQrModal: true})
}

onClickDownload = () => {
    domtoimage.toJpeg(document.getElementById('qr-code-id'), { quality: 1 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'qr-orderhere.jpeg';
        link.href = dataUrl;
        link.click();
    });
  }

fireAndroidSharing=(e)=>{
    e.preventDefault()
    let text= this.props.alias
    this.props.whatsappProducer(this.props.user.accessToken, this.props.user.merchantName, text).then(res=>{
      this.props.getWhatsappImage(this.props.user.accessToken)
      .then(imageUrl=>{
        window.AndroidFunction.shareViaWhatsApp(text, imageUrl[0].url, "image/*")
      })
      .catch(err=>{
        toast.error("Something went wrong !!")
      })
    })
}


render () {
  const isAppLogin = window.localStorage.getItem('isAppLogin') ? true : false
  let allCrmData = this.props.allCrmData || {};

  return (
        <div>
            <Dialog
                title="Share your own store with customers"
                titleStyle = {styles.titleStyle}
                paperProps = {styles.paperStyle}
                contentStyle = {styles.contentStyle}
                bodyStyle = {styles.bodyStyle}
                modal={false}
                open={this.state.openShare}
                onRequestClose={() =>   {this.props.hideShareStoreDialog()}} >
                    <div>
                        {window.innerWidth <= 786 && isAppLogin 
                            ?
                            <a href='' onClick={(e)=>{this.fireAndroidSharing(e)}} data-action="share/whatsapp/share">
                                <div onClick={() => {this.sendShareLinkEvent("link_share_whatsapp"); this.sendEventWithUtm("whatsapp")}} style={{display: "flex", alignItems: "center", marginBottom: '16px', cursor: 'pointer'}}>
                                    <img style={{height: "32px", marginRight: "12px"}} src={whatsapp} />
                                    <p style={{margin: 0}}>Whatsapp</p>
                                </div>
                            </a>
                            :
                            <a href={"https://api.whatsapp.com/send?text=" + this.state.shortUrlWithAliasForWhatsapp} data-action="share/whatsapp/share">
                                <div onClick={() => this.sendShareLinkEvent("link_share_whatsapp")} style={{display: "flex", alignItems: "center", marginBottom: '16px', cursor: 'pointer'}}>
                                    <img style={{height: "32px", marginRight: "12px"}} src={whatsapp} />
                                    <p style={{margin: 0}}>Whatsapp</p>
                                </div>
                            </a>
                        }
                    {/*<div style={{display: "flex", alignItems: "center", marginBottom: '16px', cursor: 'pointer'}} onClick={() => {this.sendEventWithUtm("facebook"); this.sendShareLinkEvent("link_share_fb"); this.setState({shareModal: true})}} >
                        <img style={{height: "32px", marginRight: "12px"}} src={fb} />
                        <p style={{margin: 0}}>Facebook</p>
                    </div> */}
                    <div style={{display: "flex", alignItems: "center", position:"relative", marginBottom: '16px', cursor: 'pointer'}} onClick={(e)=> {this.props.push('/marketing?utm_source=snackbar'); this.props.hideShareStoreDialog();}}>
                        <img style={{height: "32px", marginRight: "12px"}} src={smsImage} />
                        <p style={{margin: 0}}>Send SMS's</p>
                        <div className='orders-tab-sticker' style={{position:"absolute",left:"7%",padding:"1px 2px",bottom: "80%"}}>New</div>
                        {
                            (allCrmData.totalFreeSmsQuota - allCrmData.totalFreeSmsQuotaUsed >= allCrmData?.pricing?.totalUsers) &&
                            <div style={{height: '28px', width: '112px', background: 'linear-gradient(to right, #2e0092 , #ef1c74)', borderRadius: '0px 14px 14px 14px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '8px'}}>
                                <h6 className="free-sms">{allCrmData.merchantSmsQuota} FREE SMS</h6>
                            </div>
                        } 
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginBottom: '16px', cursor: 'pointer',  overflowWrap: "anywhere"}} onClick={() => {this.sendShareLinkEvent("link_share_qrcode"); this.sendEventWithUtm("qrcode"); this.handleOpenQrPopUp()}} >
                        <img style={{height: "32px", marginRight: "12px"}} src={qrCodeIcon} />
                        <p style={{margin: 0}}>QR Code</p>
                    </div>
                    {this.state.shareModal
                        && <FbShare facebookId = {this.props.user.facebookId}
                        accessToken = {this.props.user.accessToken}
                        caption = {`${this.state.shortUrlWithAliasForFb}`}
                        hideShareModal = {() => this.setState({shareModal: false, openShare: false})}
                        />}
                    {document.queryCommandSupported('copy') && <div style={{display: "flex", alignItems: "center", marginBottom: '16px', cursor: 'pointer', overflowWrap: "anywhere"}} onClick={this.copyLink}>
                        <img style={{height: "32px", marginRight: "12px"}} src={saasCopyBtn} />
                        <p style={{margin: 0}}>{this.props.alias}</p>
                    </div>}
                    <div style={{opacity: 0, height: 0}}>
                        <textarea ref={(textarea) => this.shortURL = textarea}
                            value={this.props.alias}
                        >{this.props.alias}</textarea>
                    </div>
                    </div>
            </Dialog>
            {this.state.showQrModal &&
                <QRCodeDialog closePopUp={this.handleCloseQrPopup} sendEventWithUtm={this.sendEventWithUtm} url={this.props.alias} user={this.props.user} ref={this.componentRef} isSnackBar={true}/>
            }
        </div>
        );
    }
})