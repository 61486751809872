import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CoverImage from './CoverImage'
import TrendingUpBlack from '../../images/trendingUpBlack.svg'
import Eye from '../../images/eye.svg'
import CircleArrow from '../../images/circleArrow.svg'
import BannerIphone from '../../images/bannerIphone.svg'
import { bannerSamplesMapping, categoryName } from '../../constants'

const BannerSamples = (props) => {

    const {user, show, onHide} = props;
    const [scrollViewIndex, setScrollViewIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setScrollViewIndex(0);
            let element = document.getElementById(`desc0`)
            element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }, 100);
    }, [])

    const category = categoryName[user.categoryId];
    const BannerSample0 = require(`../../images/banner_sample_0_${category}.png`);
    const BannerSample1 = require(`../../images/banner_sample_1_${category}.png`);
    const BannerSample2 = require(`../../images/banner_sample_2_${category}.png`);
    const currBrandName = bannerSamplesMapping[category]["name"][scrollViewIndex];

    const bannerSamples = [{src: BannerSample0}, {src: BannerSample1}, {src: BannerSample2}];

    const leftScroll = (event) => {
        event.stopPropagation()
        if (scrollViewIndex > 0) {
            let decrement = scrollViewIndex - 1;
            setScrollViewIndex(decrement);
            const element = document.getElementById(`desc${decrement}`)
            element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }
    }

    const rightScroll = (event) => {
        event && event.stopPropagation()
        if (scrollViewIndex < bannerSamples.length - 1) {
            let increment = scrollViewIndex + 1;
            setScrollViewIndex(increment);
            let element = document.getElementById(`desc${increment}`)
            element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }
    }

    return (
        <Modal dialogClassName='modal-dialog new-modal-small small-rp-modal large-rp-modal-width' show={show} style={{ height: 'auto' }} onHide={onHide}>
            <div className="modal-content" style={{ height: 'auto' }}>
                <div className="modal-header" style={{ marginTop: '0' }}>
                    <h2 style={{ textTransform: 'none' }}>Banner Samples</h2>
                    <div onClick={onHide} className="dialog-cross fa fa-close" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    </div>
                </div>
                <div className="modal-body" >
                    <div style={{ backgroundColor: 'rgba(196, 196, 196, 0.15)', height: '50px', padding: '2vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <div style={{height:'36px', width:'36px', marginRight:'10px', overflow:'auto', borderRadius:'5px'}}>
                            <CoverImage coverImageComponent={FeedpushLogo}/>
                           </div> */}
                            <div>
                                <div style={{ fontSize: 'medium' }}>{currBrandName}</div>
                                <div style={{ fontSize: 'smaller', color: 'rgba(0,0,0,0.54)' }}>{user.locality}</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 'medium' }}>Sponsored</div>
                    </div>

                    <div className='bannerSampleContainer'>
                        <div className='fullImage' style={{ backgroundImage: `url(${BannerIphone})` }}>
                            <div className='forumCollectionContainer'>
                                <div className='forum-collection-heading' style={{ borderTop: 'none', width: '100%' }} >
                                    <div hidden id={"descbannersample"} className='forum-question' style={{ width: '50%', padding: '0', border: 'none', display: 'flex', alignItems: 'center' }}>
                                    </div>
                                    {bannerSamples.map((bannerSample, index) => (
                                         <div id={`desc${index}`} className='forum-question' style={{ width: scrollViewIndex == index ? '50%' : '45%', padding: '0', border: 'none', display: 'flex', alignItems: 'center' }}>
                                         <img className='mobileCurve' src={bannerSample.src} style={{ objectFit: 'cover' }} width="100%" />
                                     </div>
                                    ))}
                                    <div hidden id={"descbannersample3"} className='forum-question' style={{ width: '50%', padding: '0', border: 'none', display: 'flex', alignItems: 'center' }}>
                                    </div>

                                    {/* {window.innerWidth > 768 && */}
                                    <div>
                                        {
                                            scrollViewIndex > 0 &&
                                            <img className="pointer"
                                                src={CircleArrow}
                                                style={{ position: 'absolute', left: '10px', top: '60%', transform: 'rotate(180deg)' }}
                                                onClick={(event) => leftScroll(event)} />

                                        }
                                        {scrollViewIndex < bannerSamples.length - 1 &&
                                            <img className="pointer"
                                                src={CircleArrow}
                                                style={{ position: 'absolute', right: '10px', top: '60%' }}
                                                onClick={(event) => rightScroll(event)} />
                                        }
                                    </div>
                                    {/* } */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: '#EBEBFF', height: '50px', padding: '2vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                        <div style={{ width: '50%', borderRight: '1px solid rgba(101, 97, 232, 0.2)', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ height: '20px', width: '20px', marginRight: '10px' }}><CoverImage coverImageComponent={Eye} /></div>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>10k + Views</div>
                        </div>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ height: '20px', width: '20px', marginRight: '10px' }}><CoverImage coverImageComponent={TrendingUpBlack} /></div>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>5% Claims</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BannerSamples