// import Config from "react-native-config";

const GET_TREE = "helpDesk/GET_TREE";
const GET_TREE_SUCCESS = "helpDesk/GET_TREE_SUCCESS";
const GET_TREE_FAIL = "helpDesk/GET_TREE_FAIL";

const LOAD_MEMBERS = "helpDesk/LOAD_MEMBERS";
const LOAD_MEMBERS_SUCCESS = "helpDesk/LOAD_MEMBERS_SUCCESS";
const LOAD_MEMBERS_FAIL = "helpDesk/LOAD_MEMBERS_FAIL";

const initialState = {
  loading: false,
  loaded: false,
};

export default function helpDesk(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TREE:
      return {
        ...state,
        loading: true,
        membersLoaded: false,
      };
    case GET_TREE_SUCCESS:
      let response =
        !!action.result && typeof action.result == "string"
          ? JSON.parse(action.result || "{}")
          : action.result || {};

      return {
        ...state,
        data: response,
        loading: false,
      };
    case GET_TREE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case LOAD_MEMBERS:
      return {
        ...state,
        loadingMembers: true,
      };
    case LOAD_MEMBERS_SUCCESS:
      let res =
        !!action.result && typeof action.result == "string"
          ? JSON.parse(action.result || "{}")
          : action.result || {};
      return {
        ...state,
        loadingMembers: false,
        membersLoaded: true,
        members: res,
        membersError: null,
      };
    case LOAD_MEMBERS_FAIL:
      return {
        ...state,
        loadingMembers: false,
        membersLoaded: false,
        membersError: action.error,
        members: null,
      };
    default:
      return state;
  }
}

export function getHelpDeskTree(
  merchantId,
  merchantUserId,
  access_token,
  isRoot = true,
  fields = [],
  message = "",
  email = ""
) {
  return {
    types: [GET_TREE, GET_TREE_SUCCESS, GET_TREE_FAIL],
    promise: (client) =>
      client.webapi.post("helpdesk/partner/process", {
        urlencodeddata: {
          email: email || "",
          merchantId: merchantId,
          merchantUserId: merchantUserId,
          isRoot: isRoot,
          query: !!fields && Array.isArray(fields) ? fields : "",

          message: message || "",
        },

        headers: {
          "auth-userid": merchantUserId,
          "auth-user-token": access_token,
          "auth-merchant-id": merchantId,
          "Content-Type": "application/x-www-form-urlencoded",
          "helpdesk-version": 1,
        },
      }),
  };
}

export function getMembers(accessToken) {
  return {
    types: [LOAD_MEMBERS, LOAD_MEMBERS_SUCCESS, LOAD_MEMBERS_FAIL],
    promise: (client) =>
      client.magicpin_in.post("api/getMembers", {
        data: { accessToken },
      }),
  };
}
