import React, {Component} from 'react';
import { Dialog } from '@material-ui/core';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  {createEntryInSheetMagicOrder}  from '../../redux/modules/dealReducers/createVouchers';
import {sendEvent} from '../../helpers/Helpers';
import particles from '../../images/particles.svg'
import CircularProgress from '@material-ui/core/CircularProgress';
import './AddHomeDeliveryVouchers.css'

const ga=window.ga;
export default connect(store => ({
    creatingEntryInMagicOrder: store.createVoucher.creatingEntryInMagicOrder,
    createdEntryInMagicOrder: store.createVoucher.createdEntryInMagicOrder,
    errorInEntryInMagicOrder: store.createVoucher.errorInEntryInMagicOrder,

}), dispatch => bindActionCreators({
  createEntryInSheetMagicOrder,
}, dispatch))
(class IntrestedMagicOrderPopup extends Component{
  constructor(props){
    super(props);
    this.state = { 
      NYVoucherCost: null,
      NYVoucherText:null,
      discountPercentage: null,
      toMerchant:0,
      selectedFile:null,
      voucherFeedBack:"",
    };
  }

  componentDidMount(){
    ga('send', 'event', 'magic_order_intrested_popup', 'clicked', `merchant_id_${this.props.user.merchantId}`);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.creatingEntryInMagicOrder && this.props.createdEntryInMagicOrder){
      toast.success(" Congrats, you have successfully signed up for home delivery via magicOrder. Our representative will get in touch with you shortly to get you started!")
      this.props.onHide();
       setTimeout(() => {
        window.location.reload();
           }, 6000);
      
    }
    if(prevProps.creatingEntryInMagicOrder && this.props.errorInEntryInMagicOrder){
      toast.error("Error occurred while making your request, please try again later")
      this.props.onHide();
    }
  }

  onHide=()=>{
    document.forms.comboVoucherForm.reset();
    this.props.onHide();
  }

  handleSubmit = (e)=>{
    e.preventDefault();
    let comboForm = document.forms.comboVoucherForm;
    let comboFormData = new FormData(comboForm);
    const contactNumber = comboFormData.get('contact-number');
    
    this.props.createEntryInSheetMagicOrder(this.props.user.accessToken, this.props.user.merchantName, this.props.user.merchantId,contactNumber);

      ga('send', 'event', 'magic_order_intrested_popup_submit', 'requested', `merchant_id_${this.props.user.merchantId}`);
      sendEvent({
        event: "magic_order_intrested_popup-create",
        page_type: 'Feed',
        page_name: 'merchant_portal',
        actor_id: this.props.user.merchantId,
        subject_merchant_id: this.props.user.merchantId,
    });    
  }

  render(){
    return(
      <div>
        <Dialog fullScreen={window.innerWidth <= 486}  onClose={()=>{this.onHide()}} open={true} style={{backgroundColor:'rgba(0,0,0,0.67)'}}>
            <div>
                <div className="add-home-body-header" style={{backgroundImage:`url(${particles})`, backgroundSize:'100%', backgroundRepeat:'no-repeat'}}>
                    <div className="add-home-body-header-text">
                       <div style={{paddingLeft: '13px'}}>Start home delivery for your outlet and get orders from customers in your neighborhood</div>
                    </div>
                    <div>
                        <i class="fa fa-times-thin fa-2x" aria-hidden="true" onClick={()=>{this.onHide()}} style={{ fontSize:'30px',cursor: 'pointer'}}></i>
                    </div>
                </div>  
                <div className="add-home-form-div">
                    <form id="comboVoucherForm" action="" onSubmit={this.handleSubmit}>
                        {!this.props.creatingEntryInMagicOrder ? 
                        <div className="add-home-form-grid">
                            <div>
                              <div className="add-home-info-text" style={{fontSize:'17px',fontFamily:'robotomedium',color:'rgba(0,0,0,0.87'}}>Enter phone number <span style={{color:'rgba(0,0,0,0.57'}}>(Optional)</span></div>
                              <div style={{paddingTop: '1%', paddingBottom: '2%'}}>
                                  <input name='contact-number' type="text" minLength='10' placeholder={`E.g. 9887655432`} required={false} className="add-home-input-text-div">
                                  </input>
                              </div>
                            </div>
                        </div>
                        :
                        <CircularProgress style={{margin:'25px 45%'}}/>}
                        <input disabled={this.props.creatingEntryInMagicOrder} type="submit" value={!this.props.creatingEntryInMagicOrder ? "I am Interested" : "Registering your request"} className="pointer large-btn large-btn2 add-home-submit-btn" ></input>
                    </form>
              </div>
            </div>
        </Dialog>
      </div>
    );
  }
})
