import React, { Component } from 'react'
import Slider from 'react-slick/dist/react-slick';
import photosImg from '../../images/photos-screenshot.png';
import magicPayImg from '../../images/magicpay-screenshot.png';
import feedpushImg from '../../images/feedpush-screenshot.png';
import bookingImg from '../../images/Confirm-booking-screenshot.png';
import rewardsFB from '../../images/rewards_F&B.png';
import rewardsBeauty from '../../images/rewards_beauty.png';
import rewardsFashion from '../../images/rewards_fashion.png';
import rewardsGrocery from '../../images/rewards_grocery.png';
import voucherFB from '../../images/Voucher_f&B.png';
import voucherBeauty from '../../images/Voucher_beauty.png';
import voucherFashion from '../../images/Voucher_fashion.png';
import voucherGrocery from '../../images/Voucher_grocery.png';
import groupbuyFB from '../../images/Groupbuy_F&B.png';
import groupbuyImg from '../../images/Groupbuy_beauty.png';
import rewardJakarta from '../../images/rewards_F&B_jakarta.png';
import voucherJakarta from '../../images/Voucher_food_jakarta.png';
import groupbuyJakarta from '../../images/Groupbuy_F&B_jakarta.png';
import magicpayJakarta from '../../images/magicpay_jakarta.png';
import bookingJakarta from '../../images/confirm_booking_jakarta.png';
import cityBg from '../../images/city-bg.svg';
import groceryBuy from '../../images/group-64.jpg';
import groceryFeedpush from '../../images/feedpush.jpg';
import groceryInvite from '../../images/invite-customers.jpg';
import groceryAnalyse from '../../images/analyse-your-business.svg';
import groceryLeaderboard from '../../images/leaderboard.jpg';
import groceryBuyMobile from '../../images/group-76.jpg';
import { sendEvent, createMCPLog } from '../../helpers/Helpers';
import language from './languageConst.js';
import {countries} from '../../constants';


export default class BenefitsSection extends React.Component { 

  constructor(props) {
    super(props);
    this.state = {
        activeTab : {
            sales: 0,
            promotion: 0,
            customer: 0
        }
    }
    this.textCopies = {
        "sales": {
           "text" :[
               language[props.language].rewardsHeading, 
               language[props.language].vouchersHeading,
               language[props.language].groupBuyHeading,
               language[props.language].magicpayHeading,
               language[props.language].confirmBookingHeading,
            ],
           "right" : ["7%", "10%", "5%", "7%", "3%"],
           "bottom" : ["8%", "16%", "4%", "11%", "6%"],
           "headings" : [
            language[props.language].reward, 
            language[props.language].voucher,
            language[props.language].groupBuy,
            language[props.language].magicPay,
            language[props.language].confirmBooking,
           ]
       }
   }
    this.groceryTextCopies = {
        "sales": {
        "text" :[
            language[props.language].stunningAds, 
            language[props.language].inviteTop,
            language[props.language].trackSales,
            language[props.language].becomeTop,
            ],
        "right" : ["-8%", "-13%", "6%", "3%"],
        "bottom" : ["-5%", "-4%", "-9%", "-6%"],
        "headings" : [
            language[props.language].feedpush, 
            language[props.language].inviteCustomers,
            language[props.language].analyseBusiness,
            language[props.language].leaderboard,
        ]
        }
    }
    if(this.props.categoryId == 5 || this.props.categoryId == 91){
        this.images = {"sales": groceryFeedpush, "promotion":feedpushImg, "customer": photosImg}
        this.texts = {"sales": this.groceryTextCopies.sales.text[0]};
        this.right = {"sales": this.groceryTextCopies.sales.right[0]};
        this.bottom = {"sales": this.groceryTextCopies.sales.bottom[0]};
        this.heading = {"sales": this.groceryTextCopies.sales.headings[0]};
    }
    else{
        this.images = {"sales":rewardsFB, "promotion":feedpushImg, "customer":photosImg}
        this.texts = {"sales": this.textCopies.sales.text[0]};
        this.right = {"sales": this.textCopies.sales.right[0]};
        this.bottom = {"sales": this.textCopies.sales.bottom[0]};
        this.heading = {"sales": this.textCopies.sales.headings[0]};
    }
    this.tabCounter = 0;
    this.pause = false;
  }

componentWillReceiveProps(nextProps){
    if(nextProps.language && (nextProps.language != this.props.language) || (nextProps.categoryId != this.props.categoryId)){
        this.textCopies = {
            "sales": {
               "text" :[
                   language[nextProps.language].rewardsHeading, 
                   language[nextProps.language].vouchersHeading,
                   language[nextProps.language].groupBuyHeading,
                   language[nextProps.language].magicpayHeading,
                   language[nextProps.language].confirmBookingHeading,
                ],
               "right" : ["7%", "10%", "5%", "7%", "3%"],
               "bottom" : ["8%", "16%", "4%", "11%", "6%"],
               "headings" : [
                language[nextProps.language].reward, 
                language[nextProps.language].voucher,
                language[nextProps.language].groupBuy,
                language[nextProps.language].magicPay,
                language[nextProps.language].confirmBooking,
               ]
           }
       }
       this.groceryTextCopies = {
            "sales": {
            "text" :[
                language[nextProps.language].stunningAds, 
                language[nextProps.language].inviteTop,
                language[nextProps.language].trackSales,
                language[nextProps.language].becomeTop,
                ],
            "right" : ["-8%", "-13%", "6%", "3%"],
            "bottom" : ["-5%", "-4%", "-9%", "-6%"],
            "headings" : [
                language[nextProps.language].feedpush, 
                language[nextProps.language].inviteCustomers,
                language[nextProps.language].analyseBusiness,
                language[nextProps.language].leaderboard,
            ]
            }
        }
    }
}

  getScreenshots = (categoryId) => {
    if(this.props.country && this.props.country.toUpperCase() === countries.INDONESIA){
        return [rewardJakarta, voucherJakarta, groupbuyJakarta, magicpayJakarta, bookingJakarta]
    }
    switch(categoryId){
        case 2:
            return [rewardsBeauty, voucherBeauty, groupbuyImg, magicPayImg, bookingImg];
        case 3:
            return [rewardsFashion, voucherFashion, groupbuyImg, magicPayImg, bookingImg];
        case 5:
            return [groceryFeedpush, groceryInvite, groceryAnalyse, groceryLeaderboard];
        case 91:
            return [groceryFeedpush, groceryInvite, groceryAnalyse, groceryLeaderboard];
        default:
            return [rewardsFB, voucherFB, groupbuyFB, magicPayImg, bookingImg]
    }
  }
  changeTab = () => {
    const categoryId = this.props.categoryId
    if(!this.pause){
        this.tabCounter++;
        let newObj = {
            sales: this.tabCounter % ((categoryId == 5 || categoryId == 91) ? 4:5),
            promotion: this.tabCounter % 4,
            customer: this.tabCounter % ((categoryId == 5 || categoryId == 91) ? 4:5)
        }
        if(categoryId == 5 || categoryId == 91){
            this.images["sales"] = this.getScreenshots(categoryId)[this.tabCounter % 4];
            this.texts["sales"] = this.groceryTextCopies["sales"]["text"][this.tabCounter % 4];
            this.right["sales"] = this.groceryTextCopies["sales"]["right"][this.tabCounter % 4];
            this.bottom["sales"] = this.groceryTextCopies["sales"]["bottom"][this.tabCounter % 4];
            this.heading["sales"] = this.groceryTextCopies["sales"]["headings"][this.tabCounter % 4];
        }
        else{
            this.images["sales"] = this.getScreenshots(categoryId)[this.tabCounter % 5];
            this.texts["sales"] = this.textCopies["sales"]["text"][this.tabCounter % 5];
            this.right["sales"] = this.textCopies["sales"]["right"][this.tabCounter % 5];
            this.bottom["sales"] = this.textCopies["sales"]["bottom"][this.tabCounter % 5];
            this.heading["sales"] = this.textCopies["sales"]["headings"][this.tabCounter % 5];
        }
        this.setState({activeTab: newObj});
    }
  }
  componentDidMount(){
      if(window.innerWidth > 768)
        this.identity = setInterval(this.changeTab, 4000);
  }

  componentWillUnmount(){
      if(window.innerWidth > 768)
        clearInterval(this.identity);
  }
  handleTabChange = (type, index) => {
    if(this.props.categoryId == 5 || this.props.categoryId == 91){
        this.images[type] = this.getScreenshots(this.props.categoryId)[index];
        this.texts[type] = this.groceryTextCopies[type]["text"][index]
        this.right = {"sales": this.groceryTextCopies.sales.right[index]};
        this.bottom = {"sales": this.groceryTextCopies.sales.bottom[index]};
    }
    else{
        this.images[type] = this.getScreenshots(this.props.categoryId)[index];
        this.texts[type] = this.textCopies[type]["text"][index]
        this.right = {"sales": this.textCopies.sales.right[index]};
        this.bottom = {"sales": this.textCopies.sales.bottom[index]};
    }
    let newVal =  this.state.activeTab;
    newVal[type] = index
    this.setState({
        activeTab: newVal
    })
    sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: `increase_sales`,
        page_type: 'new_merchant_landing'
    });
    createMCPLog({
      phone: localStorage.getItem("subjectPhoneNumber") || '',
      merchant_id: localStorage.getItem('landingMerchantId') || '',
      event: "increase_sales",
      utm_campaign: localStorage.getItem('landingSource') || ''
    });

    this.pause = true;
    setTimeout(()=>{this.pause = false; this.tabCounter = index}, 8000)
  }

  render() {
    const languageConst = this.props.language ? language[this.props.language] : language["english"];
    if(this.props.merchantName){
        var merchantName = this.props.merchantName.length > 16 ? this.props.merchantName.slice(0, 16) + "...": this.props.merchantName;
    }
    const settings = {
        dots: true,  
        accessibility: true,
        infinite: true,
        arrows: false,
        draggable: true,
        swipeToSlide: true,
        variableWidth: true,
        centerMode: true,
        centerPadding: '0px',
        dotsClass: 'slick-dots slick-dots-new',
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 4000
    };
    return (
      <section className="partners" style={{backgroundColor:"#fff", textAlign:"center", paddingTop: window.innerWidth < 768 ? "0px": this.props.categoryId == 5 ? "20px":"70px",paddingBottom: window.innerWidth > 768 ? this.props.search ? "5%" :"2%" : "12%"}}>
        {this.props.categoryId != 5 && this.props.categoryId != 91 && <h2>{languageConst.salesHeading}</h2>}
        {(this.props.categoryId == 5 || this.props.categoryId == 91) && <h2 style={{marginBottom: window.innerWidth < 768 ? "0px":"10px",marginTop: window.innerWidth < 768 ? "-20px":"10px",fontSize: window.innerWidth < 768 ? "22px":"35px",fontFamily: "robotobold",paddingBottom:"0px",paddingTop: window.innerWidth < 768 ? "0px":"40px"}} >{languageConst.getPower}</h2>}
        {(this.props.categoryId == 5 || this.props.categoryId == 91) && <p style={{fontFamily:"robotoregular",margin: window.innerWidth < 768 ? "12px 5px 10px":"12px 5px 60px",fontSize: window.innerWidth < 768 ? "14px":"16px",lineHeight:"20px"}}> {languageConst.manageStore}</p>}
        {window.innerWidth > 768  ? 
        <div>
            <div className="benefits-tab">
                <div className="benefits-subtab">
                    {/* <h2 style={{color: "#4c5fcb"}}>Sales</h2> */}
                    {(this.props.categoryId == 5 || this.props.categoryId == 91) ?   
                        <ul>
                            {this.groceryTextCopies.sales.headings.map((d, i) => {
                                return (     
                                    <li key={i} className={this.state.activeTab["sales"] === i ? "grocery-active" : "grocery-inactive"} onClick={()=> this.handleTabChange("sales", i)}> {d} {this.state.activeTab["sales"] === i ? <div className="fa fa-angle-right"></div>: ""}</li>
                                )
                            })}
                        </ul> 
                        :
                        <ul>
                            {this.textCopies.sales.headings.map((d, i) => {
                                return (     
                                    <li key={i} className={this.state.activeTab["sales"] === i ? "active" : ""} onClick={()=> this.handleTabChange("sales", i)}>{d} {this.state.activeTab["sales"] === i ? <div className="fa fa-angle-right"></div>: ""}</li>
                                )
                            })}
                        </ul>}
                </div>
                {(this.props.categoryId == 5 || this.props.categoryId == 91) ? 
                <div style={{position:"relative",display:"inline", width: "60%", height: "408px", display: "inline-block", float: "right", backgroundPosition: "bottom", backgroundRepeat: "no-repeat"}}>
                    <img style={{boxShadow:"none",width:"74%"}} src={this.images.sales}/>
                    {this.texts.sales && <div className="detail-quote" style={{fontSize:"15px",backgroundColor:"#2e108e",right: this.right["sales"], bottom: this.bottom["sales"]}}>{this.texts.sales}</div>}
                    {this.images.sales == groceryLeaderboard && <div style={{display:"flex",alignItems:"center",fontSize:"14px",fontFamily:"robotomedium",position:"absolute",top: "48.8%", left:"26%"}}><img style={{borderRadius:"4px",border:"0.6px solid rgba(0, 0, 0, 0.12)",width:"28px",height:"28px",marginRight:"6px"}} src={this.props.merchantLogo}/> <span style={{marginTop:"2px"}}>{merchantName}</span></div>}
                    {this.images.sales == groceryFeedpush && <div style={{display:"flex",alignItems:"center",fontSize:"14px",fontFamily:"robotomedium",position:"absolute",top: "40%", left:"26%"}}><span style={{verticalAlign: "middle"}}>{merchantName}</span></div>}
                </div>
                :
                <div style={{display:"inline", backgroundImage:`url('${cityBg}')`, width: "60%", height: "408px", display: "inline-block", float: "right", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", borderBottom: "2px solid #00000034"}}>
                    <img src={this.images.sales} />
                    <div className="detail-quote" style={{backgroundColor:"#00bfa5", right: this.right["sales"], bottom: this.bottom["sales"]}}>{this.texts.sales}</div>
                </div>
                }
                
            </div>
        </div>
        :
        <div className="tab-block" style={{marginTop: (this.props.categoryId == 5 || this.props.categoryId == 91) ? "15px": "3%"}}>
            <div className="benefits-tab-mobile" style={{backgroundColor:"white"}}>
                <div className="benefits-subtab-mobile">
                    <ul style={{marginBottom:0}}>
                        { (this.props.categoryId == 5 || this.props.categoryId == 91) ? 
                            <Slider {...settings} >
                                {this.groceryTextCopies.sales.headings.map((d, i) => {
                                    return (<li key={i} style={{position:"relative"}} className = "slider-list">
                                        <div style={{color: "#ef1c74", fontFamily: "robotobold", marginBottom: "30px", fontSize: "20px"}}>{d}</div>
                                        <img style={{width:"330px",height:"331px"}} src={this.getScreenshots(this.props.categoryId)[i]}/> 
                                        {this.getScreenshots(this.props.categoryId)[i] == groceryLeaderboard && <div style={{display:"flex",alignItems:"center",fontSize:"12px",fontFamily:"robotobold",position:"absolute",bottom: "31.5%",left:"17%"}}><img style={{borderRadius:"4px",border:"0.6px solid rgba(0, 0, 0, 0.12)",width:"28px",height:"28px",marginRight:"6px"}} src={this.props.merchantLogo}/> <span style={{color:"black"}}>{merchantName}</span></div>}
                                        {this.getScreenshots(this.props.categoryId)[i] == groceryFeedpush && <div style={{display:"flex",alignItems:"center",fontSize:"12px",fontFamily:"robotoregular",position:"absolute",top: "48.5%",left:"17%"}}> <span style={{color:"black"}}>{merchantName}</span></div>}                                
                                        {this.groceryTextCopies.sales.text[i] && <div className="detail-quote-mob" style={{fontSize:"14px",backgroundColor:"#2e108e",bottom: "-6%",right:"2%"}}>{this.groceryTextCopies.sales.text[i]}</div>}
                                    </li>)
                                    })
                                }
                            </Slider>
                            :
                            <Slider {...settings} >
                                {this.textCopies.sales.headings.map((d, i) => {
                                    return (<li key={i} className = "slider-list">
                                        <div style={{marginBottom: "30px", fontSize: "18px", color:"black"}}>{d}</div>
                                        <img style={{width:"290px"}} src={this.getScreenshots(this.props.categoryId)[i]}/>                
                                        <div className="detail-quote-mob" style={{backgroundColor:"#00bfa5"}}>{this.textCopies.sales.text[i]}</div>
                                    </li>)
                                    })
                                }
                            </Slider>
                        }
                    </ul>
                </div>    
            </div>
        </div>
        }
        
      </section>
    )
  }
}