import crypto from 'crypto-js';
import moment from 'moment';

const MERCHANT_ACTIVATION_STATUS_LOAD = 'merchantActivation/MERCHANT_ACTIVATION_STATUS_LOAD';
const MERCHANT_ACTIVATION_STATUS_LOAD_SUCCESS = 'merchantActivation/MERCHANT_ACTIVATION_STATUS_LOAD_SUCCESS';
const MERCHANT_ACTIVATION_STATUS_LOAD_FAIL = 'merchantActivation/MERCHANT_ACTIVATION_STATUS_LOAD_FAIL';

const SAVE_OWNERSHIP_DETAILS = 'merchantActivation/SAVE_OWNERSHIP_DETAILS';
const SAVE_OWNERSHIP_DETAILS_SUCCESS = 'merchantActivation/SAVE_OWNERSHIP_DETAILS_SUCCESS';
const SAVE_OWNERSHIP_DETAILS_FAIL = 'merchantActivation/SAVE_OWNERSHIP_DETAILS_FAIL'

const SHOW_ACTIVATION_MODAL = 'merchantActivation/SHOW_ACTIVATION_MODAL';
const HIDE_ACTIVATION_MODAL = 'merchantAction/HIDE_ACTIVATION_MODAL';

const LOADING_GST_MOBILE = 'merchantActivation/LOADING_GST_MOBILE'
const LOADED_GST_MOBILE = 'merchantActivation/LOADED_GST_MOBILE'
const ERROR_GST_MOBILE = 'merchantActivation/ERROR_GST_MOBILE'

const LOADING_CA_MOBILE = 'merchantActivation/LOADING_CA_MOBILE'
const LOADED_CA_MOBILE = 'merchantActivation/LOADED_CA_MOBILE'
const ERROR_CA_MOBILE = 'merchantActivation/ERROR_CA_MOBILE'

const VERIFYING_BANK_DETAIL = 'merchantActivation/VERIFYING_BANK_DETAIL'
const VERIFIED_BANK_DETAIL = 'merchantActivation/VERIFIED_BANK_DETAIL'
const FAILED_BANK_DETAIL = 'merchantActivation/FAILED_BANK_DETAIL'

const VERIFYING_MANUALLY = 'merchantActivation/VERIFYING_MANUALLY'
const VERIFIED_MANUALLY = 'merchantActivation/VERIFIED_MANUALLY'
const FAILED_MANUALLY = 'merchantActivation/FAILED_MANUALLY'

const UPLOADING_CHEQUE ='merchantActivation/UPLOADING_CHEQUE'
const UPLOADED_CHEQUE ='merchantActivation/UPLOADED_CHEQUE'
const UPLOADING_CHEQUE_ERROR ='merchantActivation/UPLOADING_CHEQUE_ERROR'

const SAVING_BANK_DETAILS_INDONESIA = 'merchantActivation/SAVING_BANK_DETAILS_INDONESIA'
const SAVED_BANK_DETAILS_INDONESIA = 'merchantActivation/SAVED_BANK_DETAILS_INDONESIA'
const SAVING_BANK_DETAILS_INDONESIA_ERROR = 'merchantActivation/SAVING_BANK_DETAILS_INDONESIA_ERROR'

const VERIFYING_MANUALLY_INDONESIA = 'merchantActivation/VERIFYING_MANUALLY_INDONESIA'
const VERIFIED_MANUALLY_INDONESIA = 'merchantActivation/VERIFIED_MANUALLY_INDONESIA'
const VERIFYING_MANUALLY_INDONESIA_ERROR = 'merchantActivation/VERIFYING_MANUALLY_INDONESIA_ERROR'

const FEED_SHORTCUTS_STATUS_LOAD = 'merchantActivation/FEED_SHORTCUTS_STATUS_LOAD';
const FEED_SHORTCUTS_STATUS_LOAD_SUCCESS = 'merchantActivation/FEED_SHORTCUTS_STATUS_LOAD_SUCCESS';
const FEED_SHORTCUTS_STATUS_LOAD_FAIL = 'merchantActivation/FEED_SHORTCUTS_STATUS_LOAD_FAIL';

const customMessage = (err)=>{
  let resp;
  if(err && err.status && (err.status === 504)){
    resp= {status:400, message:"This is taking longer than usual. Please try another method."}
  }
  else resp=err;
  return resp;
}
const intialState = {
  merchantActivationStatusLoading: false,
  modalVisible: false,
};

export default function merchantActivation(state = intialState, action = {} ){
	switch(action.type){
	  case MERCHANT_ACTIVATION_STATUS_LOAD:
      return {
        ...state,
        merchantActivationStatusLoading: true,
      };
    case MERCHANT_ACTIVATION_STATUS_LOAD_SUCCESS:
      return {

        ...state,
        merchantActivationStatusLoading: false,
        merchantActivationStatusLoaded: true,
        merchantActivationStatusData: action.result,
        merchantActivationStatusError: null,
      };
    case MERCHANT_ACTIVATION_STATUS_LOAD_FAIL:
      return {
        ...state,
        merchantActivationStatusLoading: false,
        merchantActivationStatusLoaded: true,
        merchantActivationStatusError: customMessage(action.error),
      };

    case SAVE_OWNERSHIP_DETAILS:
      return{
        ...state,
        saveOwnershipDetailsLoading: true,
        saveOwnershipDetailsLoaded: false
      }

    case SAVE_OWNERSHIP_DETAILS_SUCCESS:
      return{
        ...state,
        saveOwnershipDetailsLoading: false,
        saveOwnershipDetailsLoaded: true,
        saveOwnershipDetailsData: action.result,
        saveOwnershipDetailsError: null
      }

    case SAVE_OWNERSHIP_DETAILS_FAIL:
      return{
        ...state,
        saveOwnershipDetailsLoading: false,
        saveOwnershipDetailsLoaded: true,
        saveOwnershipDetailsData: null,
        saveOwnershipDetailsError: customMessage(action.error)
      }
    case SHOW_ACTIVATION_MODAL:
      return{
        ...state,
        modalVisible: true,
      }
    case HIDE_ACTIVATION_MODAL:
      return{
        ...state,
        modalVisible: false,
      }

    case LOADING_GST_MOBILE:
      return{
        ...state,
        loading_gst_mobile: true,
        loaded_gst_mobile:false,
      }
    case LOADED_GST_MOBILE:
      return{
        ...state,
        loading_gst_mobile:false,
        loaded_gst_mobile:true,
        mobile: action.result.mobileNumber,
        mobileError:null,
      }
    case ERROR_GST_MOBILE:{
      return{
        ...state,
        loading_gst_mobile:false,
        loaded_gst_mobile:true,
        mobile:"",
        mobileError:customMessage(action.error)
      }
    }

    case LOADING_CA_MOBILE:
      return{
        ...state,
        loading_ca_mobile: true,
        loaded_ca_mobile:false,
      }
    case LOADED_CA_MOBILE:
      return{
        ...state,
        loading_ca_mobile:false,
        loaded_ca_mobile:true,
        mobile: action.result.mobileNumber,
        mobileError:null,
      }
    case ERROR_CA_MOBILE:
      return{
        ...state,
        loading_ca_mobile:false,
        loaded_ca_mobile:true,
        mobile:"",
        mobileError:customMessage(action.error)
      }

    case VERIFYING_BANK_DETAIL:
      return{
        ...state,
        verifyingBankDetail:true,
        verifiedBankDetail: false,
      }
    case VERIFIED_BANK_DETAIL:
      return{
        ...state,
        verifyingBankDetail:false,
        verifiedBankDetail: true,
        verifyBankResponse: action.result,
        bankError: null
      }
    case FAILED_BANK_DETAIL:
      return{
        ...state,
        verifyingBankDetail:false,
        verifiedBankDetail: true,
        bankError: customMessage(action.error)
      }
    case VERIFYING_MANUALLY:
      return {
        ...state,
        verifyingManually:true,
        varifiedManually:false,
        manualResponse: null,
        manualError: null
      }
    case VERIFIED_MANUALLY:
      return {
        ...state,
        verifyingManually:false,
        verifiedManually:true,
        manualResponse: action.result,
        manualError: null
      }
    case FAILED_MANUALLY:
      return {
        ...state,
        verifyingManually:false,
        verifiedManually:true,
        manualError:customMessage(action.error)
      }
    case UPLOADING_CHEQUE:
      return{
        ...state,
        uploadingCheque: true,
      }
    case UPLOADED_CHEQUE:
      return{
        ...state,
        uploadingCheque: false,
        uploadedCheque: action.result,
        uploadedChequeError: null,
      }
    case UPLOADING_CHEQUE_ERROR:
      return{
        ...state,
        uploadingCheque: false,
        uploadedCheque: null,
        uploadedChequeError: action.error,
      }
    case SAVING_BANK_DETAILS_INDONESIA:
      return{
        ...state,
        savingBankDetailsIndonesia: true,
      }
    case SAVED_BANK_DETAILS_INDONESIA:
      return{
        ...state,
        savingBankDetailsIndonesia: false,
        savedBankDetailsIndonesia: action.result,
        savingBankDetailsIndonesiaError: null,
      }
    case SAVING_BANK_DETAILS_INDONESIA_ERROR:
      return{
        ...state,
        savingBankDetailsIndonesia: false,
        savedBankDetailsIndonesia: null,
        savingBankDetailsIndonesiaError: action.error,
      }
    case VERIFYING_MANUALLY_INDONESIA:
      return{
        ...state,
        verifyingManuallyIndonesia: true,
        verifiedManuallyIndonesia: null,
        verifiedManuallyIndonesiaError: null,
      }
    case VERIFIED_MANUALLY_INDONESIA:
      return{
        ...state,
        verifyingManuallyIndonesia: false,
        verifiedManuallyIndonesia: action.result,
        verifiedManuallyIndonesiaError: null,
      }
    case VERIFYING_MANUALLY_INDONESIA_ERROR:
      return{
        verifyingManuallyIndonesia: false,
        verifiedManuallyIndonesia: null,
        verifiedManuallyIndonesiaError: action.error,
      }
    case FEED_SHORTCUTS_STATUS_LOAD:
      return {
        ...state,
        feedShortcutsStatusLoading: true,
      };
    case FEED_SHORTCUTS_STATUS_LOAD_SUCCESS:
      return {

        ...state,
        feedShortcutsStatusLoading: false,
        feedShortcutsStatusLoaded: true,
        feedShortcutsStatusData: action.result,
      };
    case FEED_SHORTCUTS_STATUS_LOAD_FAIL:
      return {
        ...state,
        feedShortcutsStatusLoading: false,
        feedShortcutsStatusLoaded: true,
        feedShortcutsStatusError: action.error,
      };
    default:
      return state;
	}
}


export function getMerchantActivationStatus(merchantId){
  return {
    types: [MERCHANT_ACTIVATION_STATUS_LOAD, MERCHANT_ACTIVATION_STATUS_LOAD_SUCCESS, MERCHANT_ACTIVATION_STATUS_LOAD_FAIL],
    promise: (client) => client.default.post('/getMagicstoreActivationStatus', {
      data: {merchantId}
    })
  };
}

export function saveOwnershipDetails(merchantId, payload){
  return {
    types: [SAVE_OWNERSHIP_DETAILS, SAVE_OWNERSHIP_DETAILS_SUCCESS, SAVE_OWNERSHIP_DETAILS_FAIL],
    promise: (client) => client.default.post('/saveMSOwnershipDetails',{
      data: {merchantId, ...payload},
    }),
  };
}

export function showActivationModal(){
  return{
    type:SHOW_ACTIVATION_MODAL
  }
}

export function hideActivationModal(){
  return{
    type:HIDE_ACTIVATION_MODAL
  }
}

export function getPhoneFromGst(merchantId, gstNumber){
  return{
    types:[LOADING_GST_MOBILE, LOADED_GST_MOBILE, ERROR_GST_MOBILE],
    promise:(client) => client.default.post('/getMSPhoneFromGst',{
      data:{merchantId, gstNumber}
    })
  }
}

export function getPhoneFromElectricityBill(merchantId,serviceProvider, caNumber){
  return{
    types:[LOADING_CA_MOBILE, LOADED_CA_MOBILE, ERROR_CA_MOBILE],
    promise:(client) => client.default.post('/getMSPhoneFromElectricityBill',{
      data:{merchantId, serviceProvider, caNumber}
    })
  }
}

export function verifyOwnershipThroughBankDetails(merchantId, accountNumber, ifsc, mobileNumber, emailId, upiId, accountHolderName, diyCommission=false){
  const secretKey = `ultron-onboarding-auth-${merchantId}`;
  var epoch = moment().utc().unix();
  const cipher = crypto.AES.encrypt(epoch.toString(),secretKey).toString();
  return{
    types:[VERIFYING_BANK_DETAIL, VERIFIED_BANK_DETAIL, FAILED_BANK_DETAIL],
    promise:(client) => client.default.post('/verifyMSOwnershipThroughBankDetails',{
      data:{merchantId, accountNumber, ifsc, mobileNumber, emailId, upiId, verificationStep:'GST_BANK_DIRECT', accountHolderName, diyCommission, cipher}
    })
  }
}

export function verifyOwnershipManually(merchantId, files, filename){
  let data = new FormData();
  if(files.length > 0)
  data.append('merchant_single_photo', files[0], files[0].name ? files[0].name : filename);
  data.append('merchantId', merchantId);
  return {
    types: [VERIFYING_MANUALLY, VERIFIED_MANUALLY, FAILED_MANUALLY],
    promise: (client) => client.default.post('/verifyMSOwnershipManually', { data })
  };
}

export function saveBankDetailsIndonesia(merchantId, swiftCode, bankAccountNumber, bankName, accountHolderName, phoneNumber, email, files, filename){
  let data = new FormData();
  if(files.length > 0)
    data.append('merchant_single_photo', files[0], files[0].name ? files[0].name : filename);
  data.append('merchantId',merchantId)
  data.append('bankAccountNumber',bankAccountNumber)
  data.append('bankName',bankName)
  data.append('accountHolderName',accountHolderName)
  data.append('phoneNumber',phoneNumber)
  data.append('email', email)
  data.append('swiftCode', swiftCode)
  return {
    types: [SAVING_BANK_DETAILS_INDONESIA, SAVED_BANK_DETAILS_INDONESIA, SAVING_BANK_DETAILS_INDONESIA_ERROR],
    promise: (client) => client.default.post('/saveMSBankDetailsIndonesia', { data })
  };
}

export function verifyOwnershipManuallyIndonesia(merchantId, files){
  let data = new FormData();
  data.append('merchant_multiple_photos', files[0].ktpAttachment, files[0].ktpAttachment.name || 'ktpAttachmentFile');
  if(files[0].selfDeclarationAttachment){
    data.append('merchant_multiple_photos', files[0].selfDeclarationAttachment, files[0].selfDeclarationAttachment.name || 'selfDeclarationAttachment');
  }
  data.append('merchantId', merchantId);
  return {
    types: [VERIFYING_MANUALLY_INDONESIA, VERIFIED_MANUALLY_INDONESIA, VERIFYING_MANUALLY_INDONESIA_ERROR],
    promise: (client) => client.default.post('/uploadMSBankFilesIndonesia', { data })
  };
}

export function verifyBankDetailsManually(merchantId, files, filename, beneficiaryName){
  let data = new FormData();
  if(files.length > 0)
  data.append('merchant_single_photo', files[0], files[0].name ? files[0].name : filename);
  data.append('merchantId', merchantId);
  data.append('beneficiaryName', beneficiaryName)
  return {
    types: [UPLOADING_CHEQUE, UPLOADED_CHEQUE, UPLOADING_CHEQUE_ERROR],
    promise: (client) => client.default.post('/verifyMSBankDetailsManually', { data })
  };
}

export function getFeedShortcutsStatus(merchantId,merchantUserId){
  return {
    types: [FEED_SHORTCUTS_STATUS_LOAD, FEED_SHORTCUTS_STATUS_LOAD_SUCCESS, FEED_SHORTCUTS_STATUS_LOAD_FAIL],
    promise: (client) => client.default.post('/getMagicstoreFeedShortcutStatus', {
      data: {merchantId,merchantUserId}
    })
  };
}
