import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Chart} from 'react-google-charts';
import DateSelector from '../DateSelector/DateSelector.jsx';
import moment from 'moment';
import { loadBusinessSummary } from '../../redux/modules/dashboardReducers/businessSummary';
import { loadBusinessWithDates } from '../../redux/modules/dashboardReducers/businessWithDates';
import { groupDataTableByTime, getCurrencySymbol, exportCsv, compactCurrencyString, generateArrayOfDates } from '../../helpers/Helpers'
import exportIcon from '../../images/export-icon.svg';
import './GmvChart.css'
import { getGoalTrackerData } from '../../redux/modules/goalTracker';
import { countries, countriesInfo } from '../../constants.js';

const ga = window.ga;

export default connect(store => ({
    businessWithDates: store.businessWithDates.gmvChartData,
    businessWithDatesLoading: store.businessWithDates.gmvChartDataLoading,
    goalTrackerData: store.goalTracker.goalTrackerData,
    feedShortcutsStatusData: store.feedShortcutsStatus.feedShortcutsStatusData
}), dispatch => bindActionCreators({
    loadBusinessSummary,
    loadBusinessWithDates,
    getGoalTrackerData
}, dispatch))
    (class GmvChart extends Component {
        constructor(props) {
            super(props);
            this.state = {
                selectedLegends: [true, true, true, true, true],
                sales: true,
                intervalType:'date',
                startDate : moment().subtract(30, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                dateChangeCount: 0,
                startDateSet: false,
                packageStartDate : moment().format("YYYY-MM-DD"),
                typeOfRange: ['day', 'date', 'week','month','year']
            }
        }

        componentDidMount(){
                if(!this.state.startDateSet) {
                    this.props.getGoalTrackerData(this.props.user.accessToken).then((goalTrackerData) => {
                        let startDate = goalTrackerData[0].packageStartDate;
                        startDate = moment(startDate).format("YYYY-MM-DD")
                        const diff = moment(this.state.endDate).diff(moment(startDate), 'days');
                        let gap = 'date';
                        switch(true) {
                            case diff<=20:
                            gap = 'date'
                            break;
                            case diff<=70:
                            gap = 'week'
                            break;
                            case diff<=912:
                            gap = 'month';
                            break;
                            default:
                            gap = 'year';
                        }
                        this.setState({
                            startDate,
                            startDateSet: true,
                            intervalType: gap,
                            packageStartDate: startDate
                        })
                        let isSaasOnly= false
                        let isMarketingOnly=false
                        let currentSubscription = true
                        if(this.props.feedShortcutsStatusData && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes)){
                            if(this.props.feedShortcutsStatusData.commercialTypes.length ==1){
                                isSaasOnly = this.props.feedShortcutsStatusData.commercialTypes[0]== 'SAAS'
                            }
                            if(this.props.feedShortcutsStatusData.commercialTypes.length ==1){
                                isMarketingOnly = this.props.feedShortcutsStatusData.commercialTypes[0]== 'MARKETING'
                            }
                        }

                        this.props.loadBusinessWithDates(this.props.user.accessToken,moment(startDate).format("YYYY-MM-DD"), this.state.endDate, 'gmvChartData', isSaasOnly, isMarketingOnly, currentSubscription)
                    })  
                }
                let cashback_gmv_change=0, voucher_gmv_change=0, magicpay_gmv_change=0, cashback_transactions_change=0, magicpay_transactions_change=0, vouchers_redeemed_change = 0;
                let {total_cashback_gmv,total_voucher_gmv, total_magicpay_gmv, total_cashback_transactions, total_magicpay_transactions, total_vouchers_redeemed} = this.props.summary.BUSINESS_SUMMARY || {}
                const {total_cashback_gmv: cashback_gmv_old,total_voucher_gmv: voucher_gmv_old, total_magicpay_gmv: magicpay_gmv_old, total_cashback_transactions: cashback_transactions_old, total_magicpay_transactions: magicpay_transactions_old, total_vouchers_redeemed: vouchers_redeemed_old} = this.props.summary.PREVIOUS_DATA || {}
                cashback_gmv_change = total_cashback_gmv &&(total_cashback_gmv - cashback_gmv_old)*100/total_cashback_gmv
                voucher_gmv_change = (total_voucher_gmv - voucher_gmv_old)*100/total_voucher_gmv
                magicpay_gmv_change = (total_magicpay_gmv - magicpay_gmv_old)*100/total_magicpay_gmv
                cashback_transactions_change = (total_cashback_transactions - cashback_transactions_old)*100/total_cashback_transactions
                magicpay_transactions_change = (total_magicpay_transactions - magicpay_transactions_old)*100/total_magicpay_transactions
                vouchers_redeemed_change = (total_vouchers_redeemed - vouchers_redeemed_old)*100/total_vouchers_redeemed
                this.setState({cashback_gmv_change, magicpay_gmv_change, voucher_gmv_change, cashback_transactions_change, magicpay_transactions_change, vouchers_redeemed_change})
        }

        componentWillUpdate = () => {
            if(!this.state.startDateSet && this.props.goalTrackerData) {
              const goalTrackerData = this.props.goalTrackerData;
              const startDate = goalTrackerData[0].packageStartDate;
              this.setState({
                startDate,
                startDateSet: true
              })
            }
          }

        componentDidUpdate(prevProps, prevState){
            if(prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate){
                let isSaasOnly= false
                let isMarketingOnly=false
                let currentSubscription = false
                let diffInDays = this.state.endDate && this.state.startDate && moment(this.state.endDate).diff(moment(this.state.startDate), 'days') || 0

                if(moment(this.state.startDate).isSame(this.state.packageStartDate, 'day') && moment().isSame(this.state.endDate, 'day'))
                    currentSubscription = true
                if(this.props.feedShortcutsStatusData && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes)){
                    if(this.props.feedShortcutsStatusData.commercialTypes.length ==1){
                        isSaasOnly = this.props.feedShortcutsStatusData.commercialTypes[0]== 'SAAS'
                    }
                    if(this.props.feedShortcutsStatusData.commercialTypes.length ==1){
                        isMarketingOnly = this.props.feedShortcutsStatusData.commercialTypes[0]== 'MARKETING'
                    }
                }

                if(diffInDays > 300){
                    this.setState({
                        intervalType: 'month',
                        typeOfRange: ['day','week','month','year']
                    })
                }

                if(diffInDays <= 300){
                    this.setState({
                        typeOfRange: ['day', 'date', 'week','month','year']
                    })
                }
                
                this.props.loadBusinessWithDates(this.props.user.accessToken, this.state.startDate, this.state.endDate, 'gmvChartData', isSaasOnly, isMarketingOnly, currentSubscription)
            }
        }

        getGmvData = ()=>{
            let business = this.props.businessWithDates ? this.props.businessWithDates : this.props.summary.BUSINESS_SUMMARY
            let {total_cashback_gmv: cashback_gmv,total_voucher_gmv: voucher_gmv, total_magicpay_gmv: magicpay_gmv, total_cashback_transactions: cashback_transactions, total_magicpay_transactions: magicpay_transactions, total_vouchers_redeemed: vouchers_redeemed, total_offline_gmv, total_online_gmv, total_online_transactions, total_offline_transactions} = business || {}     
            let accData = this.state.sales ? [
                {amount:cashback_gmv, type:'Cash transaction', change:this.state.cashback_gmv_change},
                {amount:voucher_gmv, type:'Vouchers', change:this.state.voucher_gmv_change},
                {amount:total_online_gmv, type:'Delivery business', change:this.state.magicpay_gmv_change},
                {amount:total_offline_gmv, type:'Magicpay business', change:this.state.magicpay_gmv_change},
            ] :
            [
                {amount:cashback_transactions, type:'Cash transaction', change:this.state.cashback_transactions_change},
                {amount:vouchers_redeemed, type:'Vouchers', change:this.state.vouchers_redeemed_change},
                {amount:total_online_transactions, type:'Delivery transaction', change:this.state.magicpay_transactions_change},
                {amount:total_offline_transactions, type:'Magicpay transaction', change:this.state.magicpay_transactions_change},
            ]

            return accData
        }

        clickCsvChild = e=>{
            exportCsv(this.prepareFinalColumns().map(column=>column.label), this.prepareFinalData().data.map(row=>[moment(row[0]).format("YYYY-MMM-DD"), ...row.slice(1,)]), 'salesData.csv')
        }

        toggleVisible  = idx=>{
            let {selectedLegends} = this.state
            selectedLegends[idx] = !selectedLegends[idx]
            this.setState({selectedLegends})
        }

        prepareFinalData = ()=>{
            let legendMap = this.state.sales ? {
                0:'cashback_gmv',
                1:'voucher_gmv',
                2:'online_gmv',
                3:'offline_gmv'
            }
            : {
                0:'cashback_transactions',
                1:'vouchers_redeemed',
                2:'online_transactions',
                3:'offline_transactions',
            }
            let datewise = this.props.businessWithDates ? this.props.businessWithDates.datewise : this.props.summary.BUSINESS_SUMMARY ? this.props.summary.BUSINESS_SUMMARY.datewise : {}
            let ungroupedData = generateArrayOfDates(this.state.startDate, this.state.endDate,"YYYY-MM-DD")
                                    .map(date=>[date, ...[1,2,3, 4].filter(idx=>this.state.selectedLegends[idx]).map(selected=> (datewise && datewise[date]) ? datewise[date][legendMap[selected-1]]: 0)])
            return groupDataTableByTime(ungroupedData, this.state.intervalType)
        }

        prepareFinalColumns = ()=>{
            let currency = getCurrencySymbol(this.props.user.country)
            let columns = this.state.sales ? [
                { type: 'string', label: 'date' },
                { type: 'number', label: 'Cash business '+currency },
                { type: 'number', label: 'voucher business '+currency },
                { type: 'number', label: 'Delivery business '+currency },
                { type: 'number', label: 'Magicpay business '+currency },
              ]
              :[
                { type: 'string', label: 'date' },
                { type: 'number', label: 'Cash transactions' },
                { type: 'number', label: 'voucher business' },
                { type: 'number', label: 'Delivery transactions' },
                { type: 'number', label: 'Magicpay transactions' },
              ]
            return columns.filter((column, idx)=>this.state.selectedLegends[idx])
        }
        render() {
            let colors=['#3366cc','#ff9900', '#dc3912', '#00acb7']
            let currency = getCurrencySymbol(this.props.user.country)
            const country= this.props.user.country ? this.props.user.country.toUpperCase() : countries.INDIA
            let options = {
                dataOpacity:  0.8 ,
                colors:colors.filter((color, idx)=>this.state.selectedLegends[idx+1]),
                chartArea: {
                  width: '100%',
                  height:'70%'
                },
                bar: {groupWidth: "70%"},
                legend: { position: "none"},
                isStacked: true,
                vAxis:{
                  gridThickness: 0,
                  labelAngle:180,
                  textStyle:{color: '#9cadb5'},
                  gridlines: {
                    color: '#e5e5e5',
                    baselineColor: '#e5e5e5',
                    opacity: '0.5'
                  },
                  format: 'short',
                  baselineColor:'transparent'
                },
                hAxis:{
                  textStyle:{color: '#a5a5a5'},
                //   textPosition:'none',
                  gridlines: {
                    color: 'transparent',
                    count:-1
                  },
                }
              }
            let {data, format} = this.prepareFinalData();

            const addnStyle = this.props.isCeetPage ? {
                margin: "0px",
            } : {}

            return (<div id="business-chart" className="brand-gmv-chart card white-box white-box-wrapper mobile-full-card col-md-12" style={addnStyle}>
                    <div className="gmv-chart-insights">
                        <div className="gmv-chart-filter-container">
                            <div className="date-filter">
                                <DateSelector
                                page="dashboard"
                                caller="gmv-chart"
                                onChange={(startDate, endDate)=>this.setState({startDate, endDate, dateChangeCount: this.state.dateChangeCount+=1})}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                showPackageStart={true}
                                packageStartDate={this.state.packageStartDate}
                                />
                            </div>
                            <div style={{fontSize:"18px", color:"#458eff"}} className="pointer" onClick={() => {
                                    ga('send', 'event', 'Analytics', 'export_data', 'sales');
                                    this.clickCsvChild();}}
                                >
                                <abbr title="export csv"><span><img src={exportIcon}/></span></abbr>
                            </div>
                            <div className="sales-vs-voucher">
                                <span onClick={e=>this.setState({sales: true})} className={`${this.state.sales ? 'active' : ''}`}>
                                    Sales
                                </span>
                                <span onClick={e=>this.setState({sales: false})} className={`${this.state.sales ? '' : 'active'}`}>
                                    Orders
                                </span>
                            </div>
                            <div className="interval-container">
                                {this.state.typeOfRange.map((filter,idx)=><span className={`${this.state.intervalType === filter ? 'active' : ''}`} key={idx} onClick={_=>this.setState({intervalType:filter})}>{filter}</span>)}
                            </div>
                        </div>
                        <div className="gmv-chart-legend-container">
                            {this.getGmvData().map((data, idx)=><div onClick={_=>this.toggleVisible(idx+1)} key={data.type} className={`brand-gmv-chart-legend ${this.state.selectedLegends[idx+1] ? ' active' : ''}`}>
                                <div style={{color: colors[idx]}} className={`legend-amount`}>{this.state.sales ? compactCurrencyString(data.amount, this.props.user.country): data.amount.toLocaleString(countriesInfo[country]['locale'])}
                                </div>
                                <div className={`legend-type`}>{data.type}
                                </div>
                                {/* {this.state.dateChangeCount === 0 && <div className={`legend-change`}><span className={`fa fa-caret-${data.change >= 0 ? 'up' : 'down'}`}></span><span>{data.change ? Math.abs(data.change).toFixed(2)+'% ' : 'N/A '}</span>
                                    in 30 days
                                </div>} */}
                                </div>)
                            }
                        </div>

                    </div>
                    <div style={{margin:'0 auto'}}>
                    <div className="chart-container" style={{height:'320px', overflow:'hidden', width:'calc( 100% - 32px)', margin:'0 auto'}}>
                        <Chart
                            rows={data.map(row=>[moment(row[0]).format(format), ...row.slice(1,)])}
                            columns={this.prepareFinalColumns()}
                            options={options}
                            width="100%"
                            chartType="ColumnChart"
                            height={"320px"}
                        />
                    </div>
                    </div>
                </div>
            )
        }
    })