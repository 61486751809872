import React, {Component} from 'react';
import {getCurrentEmailId, hardRefresh} from '../../helpers/Helpers'
import { reportErrorToMetricMonitor } from '../../redux/modules/newMerchantLanding';
import errorImage from '../../images/page_error.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

export default connect (store => ({
    errorReportData: store.newMerchantLanding.errorReportData
  }), dispatch => bindActionCreators({
    reportErrorToMetricMonitor
  }, dispatch)
)
(class ErrorPage extends Component{
  refreshPage = () => {
    window.parent.location = window.parent.location.href;
  }

  componentDidMount() {
    this._ismounted = true;
    // The 3 sec timeout ensures that there are no misfires due to react lifecycle latency
    setTimeout(() => {
      if(this._ismounted) {
        if(this.props.user && window.location.href.slice(0,29) === "https://magicpin.in/partners/") {
          let payload = {}
          payload['monitor_name'] = "MP.FrontEnd.ErrorPage"
          payload['status'] = "FAILURE"
          payload['url'] = window.location.href
          payload['message'] = `Error generated by MID:${this.props.user.merchantId} via ${this.props.user.userType} login, errorStack:- ${this.props.errorStack?.error || ""} ${this.props.errorStack?.error?.stack?.slice(0, 100) || ""}, user:- ${getCurrentEmailId() || ""}`
          this.props.reportErrorToMetricMonitor(this.props.user.accessToken, payload)
        }
      }
    }, 3000);
    
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    return (
      <section className="content-wrapper">
        <div className="container">
          {(this.props.upsellError && this.props.upsellError.message && this.props.upsellError.status === 400) || (this.props.errorMessage)?
          <div className="empty-state-wrapper">
          <div><img src={errorImage} alt="errorLogo" style={{width:'60%', maxWidth:'270px'}} /></div>
          <div style={{marginTop:'8px'}}>
            <p>Dear Merchant, you are currently not eligible for purchasing any package.<br/> Email us at <strong>partner@magicpin.in</strong> for any query.</p>
            <a className="large-btn large-btn3" style={{maxWidth:'150px', marginTop:'16px'}} onClick={()=>this.props.push('/dashboard')}>Go to Panel</a>
          </div>
        </div>
        :
          <div className="empty-state-wrapper">
            <div><img src={errorImage} alt="errorLogo" style={{width:'60%', maxWidth:'270px'}} /></div>
            <div style={{marginTop:'8px'}}>
              <p>Something seems to be wrong. We’ll be back soon.</p>
              
              { window.innerWidth > 1100 
                 ?<p>Please try refreshing with <kbd>Ctrl + Shift ⇧ + R</kbd> or <kbd>Cmd ⌘ + Shift ⇧ + R</kbd>.</p>
                 :<p>Please try refreshing.</p> }
              <a className="large-btn large-btn3" style={{maxWidth:'150px', marginTop:'16px'}} onClick={hardRefresh}>TRY AGAIN</a>
            </div>
          </div>
  }
        </div>
      </section>
    )
  }
})
