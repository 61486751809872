import React, {Component} from 'react';
import RatingsChart from './RatingsChart';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { countriesInfo } from '../../constants';
import infoIcon from '../../images/infoiconi.svg';

const ga = window.ga;

export default class ReviewAndRatings extends Component {
  constructor(props){
    super(props);
    this.state = {
      viewRatingsDetailed: false,
    }
  }
  render() {
    const tooltip = (
      <Tooltip id="check-store-rating-tooltip"> Check how your store rating is performing against your competitor</Tooltip>);

    const tooltipNArating = (
      <Tooltip id="check-store-rating-tooltip"> Ratings for your store are not calculated since you have received less than 5 reviews in last 1 year.</Tooltip>);

    // const ratings = this.props.user.ratings;
    const ratings = this.props.merchantRatingsVisitsData && this.props.merchantRatingsVisitsData.ratings;
    const rating = ratings && [ratings.fiveRating, ratings.fourRating, ratings.threeRating, ratings.twoRating, ratings.oneRating]
    const maxcount =  Math.max(...(rating || []))

    const ratingGraphWidth= ratings.ratingCount ? rating.map(rate => rate/maxcount*100):[0, 0, 0, 0, 0];
    const starClasses = ["five", "four", "three", "two", "one"].map(rate => `mrcht-cstmr-star-graph ${rate}-star`)
    const country = this.props.user.country.toUpperCase()
    const localeString = countriesInfo[country]['locale']
    const isMobile = window.innerWidth < 786
    // var perChange = this.props.ratingOld > 0 ?((this.props.user.ratings.rating - this.props.ratingOld)/this.props.ratingOld)*100:0;
      return (
        <div className="top-dashboard-wrapper">
          <div className="row">
            <div className="col-md-12 mobile-view-card">
              <div className="white-box" style={this.props.isCeetPage ? {padding:'3% 0 2%', display: "flex", justifyContent: "space-between", alignItems: "center", ...(this.props.isCeetPage ? {} : { margin: isMobile ? "3% 0" : "3% 2%" }), flexDirection: isMobile ? "column" : "row"} : {backgroundColor: '#f7f7fa', padding:'3% 0 2%'}}>
                <div className="overall-rating overall-rating2">
                  <div className="overall-rate-num" style={{display:'block'}}>
                   {ratings.rating === 0 ?
                          <span style={{fontSize:'50px'}}>-
                   <OverlayTrigger placement="top" overlay={tooltipNArating }>
                         <img style={{marginLeft:"4px",paddingLeft:"2px"}} className="info-icon" src={infoIcon} alt="i" width="16"  />
                    </OverlayTrigger> 
                    </span>
                      : 
                      <span className="large-number large-number2_1">{ratings.rating.toFixed(1)}</span>}
                  </div>
                  <div className="overall-rate-star" style={{marginLeft:0}}>
                    <div className="star-wraper review-tab-rate">
                      <span className="icon">
                        {Array(5).fill(1).map(function(item,index){
                          index++;
                          return ratings.rating >= index ? <i style={{color:'#fddc66', fontSize:'32px'}} key={index} className="star star2 fa fa-star"></i> :
                          ratings.rating > index -1 ? <i key={index} style={{color:'#fddc66', fontSize:'32px'}} className="star star2 fa fa-star-half"></i> :
                          <i style={{color:'#eceef4', fontSize:'32px'}} className="star star2 fa fa-star"></i>
                        })}
                      </span>
                    </div>
                    <p className="star-text star-text2">
                      {/*<span className="icon green"><i className="fa fa-caret-up"></i></span>
                      <span>0.2</span> since last month*/}
                    </p>
                  </div>
                  <p style={{paddingTop:'10px'}}>
                {/* {perChange > 0 ?

                  <span className="icon green">
                    <i className="fa fa-caret-up"></i>
                    <span>{perChange.toFixed(2)}% </span>
                  </span>
                  :
                  <span className="icon red">
                    <i className="fa fa-caret-down"></i>
                    <span>{perChange.toFixed(2)}% </span>
                  </span>

                }  Vs last 15 days */}
                  {this.props.isDashboardCalling ? 
                  <a 
                  className="link hover-border small-padding" 
                  target="_blank" 
                  onClick={()=> {
                      try{
                          document.getElementById('customer-review-business-page').scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
                      }
                      catch(err){}
                   }}>
                    See what people say about your store
                  </a> : 
                  <a style={{whiteSpace:'nowrap'}} 
                    onClick={()=>ga('send', 'event', 'Customer_Page', 'Navigation', 'Rating_Blog')} 
                    className="link hover-border small-padding" 
                    target="_blank" 
                    href="https://magicpin.in/blog/redefining-ratings-magicpin/">
                    How ratings are calculated ?
                  </a>}
                {/* {window.innerWidth < 768 && !this.state.viewRatingsDetailed && <div className="col-md-12" style={{paddingTop:'20px'}}>
                <OverlayTrigger placement="top" overlay={tooltip}>
                <a className="run-promotion-view-more pull-right" style={{fontSize:'12px', textAlign:'center', textDecoration:'none'}} onClick={()=>{ga('send', 'event', 'Customer_Page', 'View Ratings Detailed');this.setState({viewRatingsDetailed: true});}}>
                Compare your Ratings
                </a>
                </OverlayTrigger>
                </div>} */}
                </p>
                </div>
                <div className="mrcht-total-wrapper2">
                  <div className="mrcht-totals">
                    <div className="overall-rate-num" style={{display:'block'}}>
                      <span className="large-number large-number2_2">{ratings.ratingCount && ratings.ratingCount.toLocaleString(localeString)}</span>
                    </div>
                    <p className="star-text star-text2">
                      Total reviews
                    </p>
                  </div>
                  <div className="Separator2"></div>
                  {/* <div className="mrcht-totals">
                    <div className="overall-rate-num" style={{display:'block'}}>
                      {this.props.merchantRatingsVisitsData && this.props.merchantRatingsVisitsData.visits 
                        && <span className="large-number large-number2_2">{this.props.merchantRatingsVisitsData.visits.totalVisits && this.props.merchantRatingsVisitsData.visits.totalVisits.toLocaleString(localeString)}</span>}

                    </div>
                    <p className="star-text star-text2">
                      Total visits
                    </p>
                  </div>
                  <div className="Separator2"></div> */}
                  {/* {window.innerWidth >= 768 && !this.state.viewRatingsDetailed && <div className="col-md-12" >
                  <OverlayTrigger placement="top" overlay={tooltip}>
                  <a className="run-promotion-view-more pull-right" style={{fontSize:'12px', textAlign:'center', textDecoration:'none'}} onClick={()=>{ga('send', 'event', 'Customer_Page', 'View Ratings Detailed');this.setState({viewRatingsDetailed: true});}}>
                  Compare your Ratings
                  </a>
                  </OverlayTrigger>
                  </div>} */}
                </div>
                <div className="mercht-rating-detl mercht-rating-detl2">
                  {rating.map((rating, index)=>
                    <div key={index} className="mrcht-row mrcht-row2">
                    <div className="mrcht-cstmr-star-num">
                      {5-index}
                      <span className="icon"><i className="fa fa-star"></i></span>
                    </div>
                    <div className="mrcht-cstmr-star-graph-empty">
                      <div className={starClasses[index]} style={{"width":""+ratingGraphWidth[index]+"%"}}></div>
                    </div>
                    <div className="mrcht-cstmr-detail-wrapper">
                      <div className="mrcht-cstmr"><span>{rating}</span></div>
                    </div>
                  </div>)}

                </div>
                <div className="mrcht-total-wrapper">
                  <div className="mrcht-totals">
                    <div className="overall-rate-num" style={{display:'block'}}>
                      <span className="large-number large-number2_2">{ratings.ratingCount ? ratings.ratingCount.toLocaleString(localeString) : "NA" }</span>
                    </div>
                    <p className="star-text star-text2">
                      Total reviews
                    </p>
                  </div>
                  <div className="Separator2"></div>
                  {/* <div className="mrcht-totals">
                    <div className="overall-rate-num" style={{display:'block'}}>
                      {this.props.merchantRatingsVisitsData && this.props.merchantRatingsVisitsData.visits 
                      && <span className="large-number large-number2_2">{this.props.merchantRatingsVisitsData.visits.totalVisits && this.props.merchantRatingsVisitsData.visits.totalVisits.toLocaleString(localeString)}</span>}

                    </div>
                    <p className="star-text star-text2">
                      Total visits
                    </p>
                  </div> */}
                </div>
              </div>
              
            </div>
          </div>

          {this.state.viewRatingsDetailed &&
          <div className="row view-more-promotion-option-block" style={{marginTop:'3%', marginLeft:'-8px', marginRight:'-8px'}}>

            <RatingsChart getMerchants={this.props.getMerchants}
              user={this.props.user} combinedRatings={this.props.combinedRatings}
              getRatingsMerchants={this.props.getRatingsMerchants}
              merchantRatingsVisitsData={this.merchantRatingsVisitsData}
              push = {this.props.push}
              ratingsLoaded = {this.props.ratingsLoaded} />

            <div className="col-md-12" style={{marginTop:'-35px', marginBottom:'40px'}}>
              <a className="run-promotion-view-more pull-right" onClick={()=>this.setState({viewRatingsDetailed: false})}>
                Hide options
                <span className="icon"><i className="fa fa-angle-up"></i></span>
              </a>
            </div>
          </div>}
        </div>
      );
    }
  }
