import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router';
import './App.css';
import { Route, Link, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { push } from 'react-router-redux';
import { uneditableLocalStorageFields, userTypes } from '../../constants';
import { loadAuth, logout } from '../../redux/modules/authReducers/auth';
import { getVersionCode, invalidCredsAlertEmail } from '../../redux/modules/newMerchantLanding';
// import Headers
import Header from '../Header/Header';
import HeaderAdmin from '../Header/HeaderAdmin';
import HeaderParentMerchant from '../Header/HeaderParentMerchant';
// import HeaderLogin from '../Header/HeaderLogin';

// import routes
import Claim from '../Claim/Claim';
import Login from '../Login/Login';
import AddOutlet from '../Claim/AddOutlet'
import LoginViaApp from '../LoginViaApp/LoginViaApp';
// import NotFound from '../NotFound/NotFound';
import Help from '../Help/Help';
import OnlineStore from '../Claim/OnlineStore';
import GetOrderhereApp from '../GetApp/GetOrderhereApp';

import asyncComponent from '../HelperHOC/AsyncComponent';
import AuthenticatedRoute from '../HelperHOC/AuthenticatedRoute';
import CheckAuthRoute from '../HelperHOC/CheckAuthRoute';
import {sendEvent, changeTranslator, hardRefresh, constructEventNameForBq} from '../../helpers/Helpers'
import {set, get, ACTOR_ID, setActorId, setOriginSource} from '../../helpers/LocalStorageHelper'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';  // material ui components require a theme
import 'bootstrap/dist/css/bootstrap.min.css';
import AutoLogin from '../AutoLogin/AutoLogin';
import DeliveryItemList from '../MagicDeliveryVouchers/DeliveryItemList';
import {isMobile, browserName, osVersion, osName, mobileVendor, mobileModel, deviceType} from 'react-device-detect';
import OrderHereSinglePage from "../OrderHere/OrderHereSinglePage";
import NewPromotions from '../NewPromotions/NewPromotions';
import CreateFeedpush from '../NewPromotions/CreateFeedpush';
import NewOnboardingDiy from '../NewOnboardingDiy/NewOnboardingDiy';
//import Leads from '../Leads/Leads';
import CeetPage from '../CeetPage/CeetPage';

const OnboardingJourneyParent = asyncComponent(() => import('../BrandOnboarding/OnboardingJourneyParent') );
const Feed = asyncComponent(() => import('../Feed/Feed'));
const Dashboard = asyncComponent(() => import('../Dashboard/Dashboard'));
const Transactions = asyncComponent(() => import('../Transactions/Transactions'));
const Customers = asyncComponent(() => import('../Customers/Customers'));
const NotificationPostPage = asyncComponent(() => import('../PostPage/NotificationPostPage'));
const Vouchers = asyncComponent(() => import('../Vouchers/Vouchers'));
const Promotions = asyncComponent(() => import('../Promotions/Promotions'));
const Bookings = asyncComponent(() => import('../Bookings/Bookings'));
const Notifications = asyncComponent(() => import('../Notifications/Notifications'));
const Profile = asyncComponent(() => import('../ProfileNew/ProfileNew'));
const EditProfile = asyncComponent(() => import('../EditProfile/EditProfile'));
const Wallet = asyncComponent(() => import('../Wallet/Wallet'));
const Checkout = asyncComponent(() => import('../Checkout/Checkout'));
const SubscriptionPage = asyncComponent(() => import('../SubscriptionPage/SubscriptionPage'));
const MyTeam = asyncComponent(() => import('../MyTeam/MyTeam'));
const Insights = asyncComponent(() => import('../DashboardAll/Insights'));
const Discourse = asyncComponent(() => import('../Login/Discourse') )
const TermsAndConditions = asyncComponent(() => import('../TermsAndConditionsNew/TermsAndConditionsNew'));
const HomeDeliveryTnC = asyncComponent(() => import('../TermsAndConditions/HomeDeliveryTnC'));
const AdminDashboard = asyncComponent(() => import('../AdminDashboard/AdminDashboard'));
const BrandsHome = asyncComponent(() => import('../Brands/BrandsHome'));
const DashboardAll = asyncComponent(() => import('../DashboardAll/DashboardAll'));
//const BrandPrivileges = asyncComponent(() => import('../BrandPrivileges/BrandPrivileges'));
const BrandPrivileges = asyncComponent(() => import('../BrandPrivileges/BrandPrivilegesV1'));
const Leaderboard = asyncComponent(() => import('../Leaderboard/Leaderboard'));
const HomeDelivery = asyncComponent(() => import('../HomeDelivery/HomeDelivery'))
const QRCode = asyncComponent(() => import('../HomeDelivery/QRCode'));
const MerchantCrm = asyncComponent(() => import('../HomeDelivery/MerchantCrm'));
const NewMerchantPromotion = asyncComponent(() => import('../NewMerchantPromotion/NewMerchantPromotion'));
const NewMerchantCashback = asyncComponent(() => import('../NewMerchantCashback/NewMerchantCashback'));
const NewMerchantCustomers = asyncComponent(() => import('../NewMerchantCustomers/NewMerchantCustomers'));
const NewMerchantVouchers = asyncComponent(() => import('../NewMerchantVouchers/NewMerchantVouchers'));
const PaymentNew = asyncComponent(() => import('../Claim/Payment'));
// const PaymentOld = asyncComponent(() => import('../Claim/Payment99'))
const Payment299 = asyncComponent(() => import('../Claim/Payment299'))
const PaymentResult = asyncComponent(() => import('../Claim/PaymentResult'));
const UnSubscribe = asyncComponent(() => import('../Claim/UnSubscribe'))
const UpgradeSubcription=asyncComponent(()=>import('../Upgradations/UpgradeSubcription'));
const UpsellCheckout=asyncComponent(()=>import('../Upgradations/UpsellCheckout'));
const AssignedMerchants = asyncComponent(() => import('../OutboundSales/AssignedMerchants'))
const NewRemarketingContact = asyncComponent(() => import('../OutboundSales/AddNewRemarketingContact'))
const PaymentUpsell = asyncComponent(() => import('../Claim/PaymentUpsell'))
const MerchantContent = asyncComponent(() => import('../MerchantContent/Home'))
const UpsellPackageMerchant = asyncComponent(()=>import('../Upgradations/UpsellPackageMerchant'));
const DiwaliExclusivePackage = asyncComponent(()=>import('../Upgradations/DiwaliExclusivePackage'));
const ForgotPassword = asyncComponent(() => import('../ForgotPassword/ForgotPassword'));
const MerchantOnboarding = asyncComponent(() => import('../MerchantOnboarding/MerchantOnboarding'));
const OnlineStoreOnboarding = asyncComponent(() => import('../MerchantOnboarding/OnlineStoreOnboarding'));
const PaymentBpp = asyncComponent(() => import('../Claim/PaymentBpp'))
const ContentPortal = asyncComponent(() => import('../MerchantContent/ContentPortal'))
const FullScreenMobile  = asyncComponent(()=>import('../FullScreenMobile/FullScreenMobile'));
const ENachOnboarding = asyncComponent(()=>import('../ENach/ENachOnboarding'));
const Mou = asyncComponent(()=>import('../Mou/Mou'));
const Retail = React.lazy(()=>import('../DashboardAll/Retail'));
const Online = React.lazy(()=>import('../OnlineDashboard/Online'));
const PerformanceReport = React.lazy(()=>import('../PerformanceReport/PerformanceReport'))
const PerformanceReportPrintView = React.lazy(() => import('../PerformanceReport/PerformanceReportPrintView'))
const NewOnboarding = asyncComponent(()=>import('../NewOnboarding/NewOnboarding'));
const HelpDesk = asyncComponent(()=>import('../HelpDesk/HelpDesk'));
const OTOFWelcome = asyncComponent(()=>import('../WelcomeScreens/OTOF/OTOF'));
const Leads = asyncComponent(() => import('../Leads/Leads'));

const ga = window.ga;

export default withRouter(connect(
  state => ({
    user: state.auth.user,
    loadingAuth: state.auth.loading,
    loggingIn: state.auth.loggingIn,
    loginMedium: state.auth.loginMedium,
    versionCodeData: state.newMerchantLanding.versionCodeData,
    isLoggingOut: state.auth.loggingOut
  }),
  dispatch => bindActionCreators({
    push,
    loadAuth,
    logout,
    invalidCredsAlertEmail,
    getVersionCode
  },dispatch))
(class App extends Component {

  constructor(props){
    super(props);
    this.paths = {
      [userTypes.MERCHANT]: [
        '/feed',
        '/dashboard',
        '/transactions',
        '/vouchers',
        '/customers',
        '/notifications',
        '/promotions',
        '/profile',
        '/editprofile',
        '/postpage/:postId',
        '/wallet',
        '/subscription',
        '/myteam',
        '/help',
        '/bpp',
        '/mobileView',
        '/leaderboard',
        '/orders',
        '/qrCode',
        '/marketing',
        '/deliveryItems',
        '/beyondfoodcatalog',
        '/leads'
      ],

      [userTypes.ADMIN]: [
        '/adminDashboard',
      ],

      [userTypes.PARENT_MERCHANT]: [
        '/retail'
      ],

      [userTypes.BRAND]: [
        '/brandsHome',
      ],

      [userTypes.ONLINE]: [
        '/online',
      ]
    }
    this.eventObj = {pageName: 'app', useCase: 'localStorageEdit'}
  }

  checkVersionCode = () => {
    this.props.getVersionCode()
    .then(res => {
        if(this.props.versionCodeData && window.localStorage.getItem('version') != this.props.versionCodeData.versionCode){
         window.localStorage.setItem('version', this.props.versionCodeData.versionCode);
         hardRefresh();
       }
    })
  }

  checkCeetMerchant = () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const isCeetMerchantLanded = accessToken && JSON.parse(window.localStorage.getItem(`isCeetMerchant_${accessToken}`));
      if (isCeetMerchantLanded && this.props.location.pathname !== '/ceet') {
        this.props.push('/ceet');
      }
    } catch(err) {}
  }

  componentWillMount(){
    if (!!window && !!window.location && window.location.href === "https://orderhere.io/partners/login") {
      window.location.href = "https://magicpin.in/partners"
    }
    /**
     *  Sends an update request to the SW on every login / page refresh.
     *  SkipWait and auto-reload on controller-change is handled in registerServiceWorker.js
     *  Will not work on localhost as SW is configured only to register if ENV == 'production'
     */
    this.checkVersionCode();
    if(navigator && navigator.serviceWorker){
      navigator.serviceWorker.getRegistrations()
      .then(registrationsArray => {
        if(registrationsArray.length > 0) {
        registrationsArray[0].update();
        }
      })
    }

    ga('create', 'UA-66063161-1', { 'userId':-1000 });
    ga('require', 'displayfeatures');
    //if(!!window.Appcues) window.Appcues.anonymous();

    this.params = new URLSearchParams(this.props.location.search);
    if(this.params.has('utm_medium')){
      changeTranslator(this.params.get('utm_medium'))
    }
    if(this.params.has('utm_source')){
      setOriginSource(this.params.get('utm_source'))
    }
    if(!get(ACTOR_ID)){
      setActorId()
    }
    const extras = {isMobile, browserName, osVersion, osName, mobileVendor, mobileModel, deviceType};
    sendEvent({
      event: 'login_try',
      page_type: 'merchant_portal',
      subject_type: 'merchant',
      utm_source: this.params.has('utm_source') ? this.params.get('utm_source') :'direct',
      utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') :'',
      utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') :'',
      utm_content: this.props.location.search,
      loginMedium: this.props.loginMedium,
      ...extras,
    });

    this.checkCeetMerchant();
  }


  componentDidCatch(error, errorInfo) {
    // this.setState({ error });
    // window.Raven.captureException(error, { extra: { ...errorInfo,
    //   merchantId: this.props.user && this.props.user.merchantId,
    //   userType: this.props.user && this.props.user.userType,
    //  } });
  }


  componentDidUpdate (prevProps, prevState) {
    if(this.props.location && prevProps.location && prevProps.location.pathname != this.props.location.pathname) {
      this.checkVersionCode();
    };
  }

  sendBqEventApp(eventName, user){
    sendEvent({
      event: eventName,
      actor_id: user ? user.merchantId || user.parentMerchantId || 44444 : null,
      user_type: user && user.userType ? user.userType : null,
      merchant_email: window.localStorage.getItem("magicpinId") || "",
    })
  }

  componentWillReceiveProps(nextProps) {
    // handling logout flow
    if(this.props.user && !nextProps.user){
      this.props.push('/login');
      if(!!window.fcWidget) window.fcWidget.show(); // show the freshwork chat
    }

    // handling login flow
    if(!this.props.user && nextProps.user){
      window.localStorage.setItem('accessToken', nextProps.user.accessToken);
      
      // sets the salt received from the auth api to localStorage 
      if(nextProps.user.saltFromAuth && (nextProps.user.userType && nextProps.user.userType === "MERCHANT")) {
        // console.log("USER.saltFromAuth: ", nextProps.user.saltFromAuth)
        window.localStorage.setItem('saltFromAuth', nextProps.user.saltFromAuth);
      }

      window.addEventListener("storage", (event) => {
        if(process.env.NODE_ENV !== 'development' && uneditableLocalStorageFields.includes(event.key)) {
          this.props.logout(this.props.user.accessToken);
          // const eventName = constructEventNameForBq(this.eventObj, event.key);
          // this.sendBqEventApp(eventName, nextProps.user)
        }
      })

      // if(nextProps.user.version && window.localStorage.getItem('version') != nextProps.user.version){
      //   window.localStorage.setItem('version', nextProps.user.version);
      //   hardRefresh();
      // }
      // console.log(nextProps.user);
      this.setUserIdentity({gaUserId:this.getGaUserId(nextProps.user), user: nextProps.user});

      // handling the loginViaapp redirect to page
      if(['/loginViaApp', '/autologin'].includes(this.props.location.pathname)){
        let newLocation = '/';
        if(this.params.has('value')) this.params.delete('value');
        if(this.params.has('page')) newLocation += this.params.get('page');
        // if(nextProps.user.isSaasOnly) newLocation += 'orders'
        newLocation += '?' + this.params.toString();
        this.props.push(newLocation);
      }
    }

    if(this.props.location.pathname !== nextProps.location.pathname){
      const extras = {isMobile, browserName, osVersion, osName, mobileVendor, mobileModel, deviceType};
      // capturing route change
      sendEvent({
        event: 'path_change',
        page_type: 'merchant_portal',
        actor_id: nextProps.user ? nextProps.user.merchantId || nextProps.user.parentMerchantId || 'admin' : null,
        actor_type: nextProps.user && nextProps.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
        user_type: nextProps.user && nextProps.user.userType ? nextProps.user.userType : null,
        subject_id: nextProps.user ? this.getGaUserId(nextProps.user) : null,
        subject_type: nextProps.user ? nextProps.user.userType : null,
        utm_source: this.params.has('utm_source') ? this.params.get('utm_source') :'direct',
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') :'',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') :'',
        page_source: this.props.location.pathname, //previous page
        active_page: nextProps.location.pathname, //current page
        page_source_query_params:this.props.location.search,
        active_page_query_params:nextProps.location.search,
        loginMedium: this.props.loginMedium,
        ...extras
      });

    }
  }

  getGaUserId = (user) => {
    switch(user.userType){

      case userTypes.ADMIN:
        return 'admin_merchant'
      case userTypes.PARENT_MERCHANT:
        return user.parentMerchantId + '_parent_merchant';
      case userTypes.BRAND:
      case userTypes.MERCHANT:
        return user.merchantId + '_merchant';
    }
  }
  /** check if current path is valid/allowed path for user */
  isPathValid = () => {
    const pathname = this.props.location.pathname;
    const user = this.props.user;

    if(user && (
      this.paths[userTypes.MERCHANT].includes(pathname) ||
      this.paths[userTypes.ADMIN].includes(pathname) ||
      this.paths[userTypes.PARENT_MERCHANT].includes(pathname) ||
      this.paths[userTypes.BRAND].includes(pathname) ||
      this.paths[userTypes.ONLINE].includes(pathname) 
    )){

      // logs out fraud user
      if(get(ACTOR_ID) == 17321516) {
        this.props.logout(this.props.user.accessToken);
      }

      if(this.paths[user.userType].includes(pathname)) return;

      // The commented out code allows PIDs and ADMINs with PID credentials to go to MID endpoints,
      // which results in continuous api failures
      
      // if((user.userType === userTypes.ADMIN || user.userType === userTypes.PARENT_MERCHANT)
      // && user.merchantId && this.paths[userTypes.MERCHANT].includes(pathname)) return;
      if(user.userType === userTypes.ADMIN && !user.isParentMerchant && this.paths[userTypes.MERCHANT].includes(pathname)) return;

      if(((user.userType === userTypes.ADMIN && user.isParentMerchant) || user.userType === userTypes.PARENT_MERCHANT)
       && this.paths[userTypes.MERCHANT].includes(pathname)) {
        // send alert email
        let emailId;
        if(user.userType === userTypes.ADMIN){
          emailId = window.localStorage.getItem("magicpinId")
        }
        else{
          emailId= ""
        }
        this.props.invalidCredsAlertEmail(user.accessToken, user.userType, emailId, user.userId, user.merchantId, user.parentMerchantId, this.props.location.pathname)
      }

      if((user.userType === userTypes.ADMIN || user.userType === userTypes.ONLINE)
      && user.onlineMerchantId && this.paths[userTypes.ONLINE].includes(pathname)) return;
      
      this.props.push('/');
    }
  }

  setUserIdentity = ({gaUserId, user}) => {
    if(!!window.DD_LOGS) window.DD_LOGS.addLoggerGlobalContext('user', gaUserId);

    // if(!!window.FS)
    // window.FS.identify(gaUserId, {
    //   displayName: user.userType === userTypes.ADMIN ? 'ADMIN' : user.merchantName,
    //   locality: user.locality,
    // });

    window.ga('set', 'userId', gaUserId);
    window.ga('set', 'dimension1', gaUserId);

    //if(!!window.Appcues) window.Appcues.identify(gaUserId);
    if(!!window.fcWidget){
      window.fcWidget.setExternalId(gaUserId);
      window.fcWidget.hide(); // hide the freshwork chat
      document.getElementById('custom_chat_button').style.visibility = "hidden"
    }

    if(user.id) {
      const extras = {isMobile, browserName, osVersion, osName, mobileVendor, mobileModel, deviceType};
      sendEvent({
        event: 'logged_in',
        page_type: 'merchant_portal',
        actor_id: user.id,
        subject_id: gaUserId,
        subject_type: user.userType,
        utm_source: this.params.has('utm_source') ? this.params.get('utm_source') :'direct',
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') :'',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') :'',
        utm_content: this.props.location.search,
        loginMedium: this.props.loginMedium,
        ...extras,
      });
    }
  }

  render() {
    const pathname = this.props.location.pathname;
    const user = this.props.user;
    this.isPathValid();
    return (
      <MuiThemeProvider>
        <div className="App">
            {user && (
              (pathname === '/adminDashboard' && <HeaderAdmin { ...this.props } />) ||
              (pathname === '/brandsHome' && <HeaderAdmin { ...this.props } />) ||
              (pathname === '/dashboardall' && <HeaderParentMerchant { ...this.props } />) ||
              (this.paths[userTypes.MERCHANT].includes(pathname) && <Header { ...this.props } />)
            )}

          <main>
            <Switch>
              {/* logged out (check for login) routes */}
              <Route exact path="/" render={(props) => <CheckAuthRoute component={OnlineStore} {...this.props} {...props} />} />
              <Route exact path="/claim" render={(props) => <CheckAuthRoute component={OnlineStore} {...this.props} {...props} />} />
              <Route path="/login" render={(props) => <CheckAuthRoute component={Login} {...this.props} {...props} />} />
              <Route exact path="/magicStore" render={(props) => <CheckAuthRoute component={OnlineStore} {...this.props} {...props} />} />
              <Route exact path="/magicStore/onboard" render={(props) => <CheckAuthRoute component={OrderHereSinglePage} {...this.props} {...props} />} />

              {/* logged out (no login check) routes */}
              <Route exact path="/loginViaApp" render={(props) => <LoginViaApp {...this.props} {...props} />} />
              <Route exact path="/reports" render={(props) => <Suspense fallback="loading"><PerformanceReport {...this.props} {...props}/></Suspense>}/>
              <Route exact path="/printreport" render={(props) => <Suspense fallback="loading"><PerformanceReportPrintView {...this.props} {...props}/></Suspense>}/>
              <Route exact path="/help" component={Help}/>
              <Route exact path="/guidelines" component={TermsAndConditions}/>
              <Route exact path="/deliveryGuidelines" component={HomeDeliveryTnC}/>
              <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...this.props} {...props} />} />
              <Route exact path="/startmembership" render={(props) => <MerchantOnboarding {...this.props} {...props} />} />
              <Route exact path="/magicStoreOnboard" render={(props) => <OnlineStoreOnboarding {...this.props} {...props} />} />
              <Route exact path="/onboarding" render={(props) => <NewOnboardingDiy {...this.props} {...props} />} />
              <Route exact path="/getApp" render={(props) => <GetOrderhereApp {...this.props} {...props} />} />
              <Route exact path="/newmerchantpromotion" render={(props) => <NewMerchantPromotion {...this.props} {...props} />} />
              <Route exact path="/newmerchantvouchers" render={(props) => <NewMerchantVouchers {...this.props} {...props} />} />
              <Route exact path="/newmerchantcashback" render={(props) => <NewMerchantCashback {...this.props} {...props} />} />
              <Route exact path="/newmerchantcustomers" render={(props) => <NewMerchantCustomers {...this.props} {...props} />} />
              <Route exact path="/newMerchantPayment" render={(props) => <PaymentNew {...this.props} {...props}/>}/>
              <Route exact path="/paymentResult" render={(props) => <PaymentResult {...this.props} {...props} />} />
              <Route exact path="/merchantPackage" render={(props) => <Payment299 {...this.props} {...props} />} />
              <Route exact path="/unsubscribe" render={(props) => <UnSubscribe {...this.props} {...props} />} />
              <Route exact path="/upgrade/:merchantId" render={(props) => <UpgradeSubcription {...this.props} {...props} />} />
              <Route exact={false} path="/brandsignup" render={(props) => <OnboardingJourneyParent {...this.props} {...props} />} />
              <Route exact path="/upgrade" render={(props) => <UpgradeSubcription {...this.props} {...props} />} />
              <Route exact path="/outbound" render={(props) => <AssignedMerchants {...this.props} {...props}/>} />
              <Route exact path="/newRemarketingContact" render={(props) => <NewRemarketingContact {...this.props} {...props}/>} />
              <Route exact path="/paymentUpsell" render={(props) => <PaymentUpsell {...this.props} {...props}/>} />
              <Route exact path="/brandPrivilege" render={(props) => <PaymentBpp {...this.props} {...props}/>} />
              <Route exact path="/renew/:merchantId" render={(props) => <UpsellPackageMerchant {...this.props} {...props} />} />
              <Route exact path="/uploadContent" render={(props) => <MerchantContent {...this.props} {...props}/>} />
              <Route exact path="/contentPortal" render={(props) => <ContentPortal {...this.props} {...props}/>} />
              <Route exact path="/autologin" render={props=><AutoLogin {...this.props} {...props}/>} />
              <Route exact path="/addOutlet" render={(props) => <AddOutlet {...this.props} {...props}/>} />
              <Route exact path="/eNach/:merchantId/:merchantType" render={(props) => <ENachOnboarding {...this.props} {...props} />} />
              <Route exact path="/mou/:merchantId" render={(props) => <Mou {...this.props} {...props} />} />
              <Route exact path="/OTOF" render={(props) => <OTOFWelcome {...this.props} {...props} />} />

              {/* logged in routes */}
              <Route exact path="/diwaliExclusive" render={(props) => <AuthenticatedRoute
                    {...this.props} {...props} render={_props=><DiwaliExclusivePackage {..._props}/>}
                    />} />
              <Route exact path="/upsellCheckout" render={(props) => <AuthenticatedRoute
                    {...this.props} {...props} render={_props=><UpsellCheckout {..._props}/>}
                    />} />
               <Route exact path="/qrCode" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><QRCode {..._props}/>}
                   />} />
              <Route exact path="/adminDashboard" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><AdminDashboard {..._props}/>}
                   />} />
              <Route exact path="/dashboardall" render={(props) => <Redirect to={{
                pathname: '/',
                search: this.props.location.search,
              }} />} />
              <Route exact path="/brandsHome" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><BrandsHome {..._props}/>} 
                   />} />
              <Route exact path="/feed" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Feed {..._props}/>}
                   />} />
              <Route exact path="/dashboard" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Dashboard {..._props}/>} 
                   />} />
               <Route exact path="/ceet" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><CeetPage {..._props}/>} 
                   />} />
              <Route exact path="/transactions" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Transactions {..._props}/>} 
                   />} />
              <Route exact path="/vouchers" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Vouchers {..._props}/>} 
                   />} />
              <Route exact path="/deliveryItems" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><DeliveryItemList {..._props}/>} 
                   />} />
              <Route exact path="/deals" render={(props) => <Redirect to={{
                pathname: '/vouchers',
                search: this.props.location.search,
              }} />} />
              <Route exact path="/customers" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Customers {..._props}/>} 
                   />} />
              <Route exact path="/postpage/:postId" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><NotificationPostPage {..._props}/>} 
                   />} />
              <Route exact path="/promotions" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><NewPromotions {..._props}/>} 
                   />} />
              <Route exact path="/promotions/feedpush" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><CreateFeedpush {..._props}/>} 
                   />} />
              <Route exact path="/notifications" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Notifications {..._props}/>} 
                   />} />
              <Route exact path="/profile" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Profile {..._props}/>}
                   />} />
              <Route exact path="/marketing" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><MerchantCrm {..._props}/>}
                   />} />
              <Route exact path="/editprofile" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><EditProfile {..._props}/>} 
                   />} />
              <Route exact path="/wallet" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Wallet {..._props}/>}
                   />} />
              <Route exact path="/checkout" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Checkout {..._props}/>} 
                   />} />
              <Route exact path="/bpp" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><BrandPrivileges {..._props}/>} 
                   />} />
              <Route exact path="/leaderboard" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Leaderboard {..._props}/>}
                   />} />
              <Route exact path="/orders" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><HomeDelivery {..._props}/>}
                   />} />
              <Route exact path="/beyondfoodcatalog" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><HomeDelivery {..._props}/>}
                   />} />
                   {/* beyondfoodcatalog */}
              <Route exact path="/subscription" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><SubscriptionPage {..._props}/>} 
                   />} />
              <Route exact path="/myteam" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><MyTeam  {..._props}/>}
                   />} />
              <Route exact path="/insights" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Insights {..._props}/>} 
                   />} />
              <Route exact={false} path="/retail" render={(props)=><AuthenticatedRoute
                {...this.props} {...props}
                render={_props=><Suspense fallback="loading"><Retail {..._props}/></Suspense>}
              />}/>

              <Route exact={false} path="/online" render={(props)=><AuthenticatedRoute
                {...this.props} {...props}
                render={_props=><Suspense fallback="loading"><Online {..._props}/></Suspense>}
              />}/>

              <Route exact path="/mobileView" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><FullScreenMobile {..._props}/>} 
                   />} />
              <Route exact path="/leads" render={(props) => <AuthenticatedRoute
                  {...this.props} {...props}
                  render={_props=><Leads  {..._props}/>}
              />} />
              <Route render={(props) => <Redirect to={{
                pathname: '/login',
                search: this.props.location.search,
              }} />} />
              
              
            </Switch>

            <ToastContainer position="top-center" hideProgressBar={true}
              style={{ marginTop: '120px', lineHeight: '20px', paddingTop: 16,
              textAlign: 'center', fontSize: 14}}
              />
          </main>
        </div>
      </MuiThemeProvider>
    );
  }
}))
