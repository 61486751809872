import { getCurrentEmailId } from "../../../helpers/Helpers";

const INVITE = 'feedRight/INVITE';
const INVITE_SUCCESS = 'feedRight/INVITE_SUCCESS';
const INVITE_FAIL = 'feedRight/INVITE_FAIL';

const LOAD_POPULAR = 'feedRight/LOAD_POPULAR';
const LOAD_POPULAR_SUCCESS = 'feedRight/LOAD_POPULAR_SUCCESS';
const LOAD_POPULAR_FAIL = 'feedRight/LOAD_POPULAR_FAIL';

const getCurrentEmail = getCurrentEmailId() || ""

const initialState = {

  popularLoaded: false,
  inviting: false,
};

export default function feedRight(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_POPULAR:
      return {
        ...state,
        popularLoading: true,
      };
    case LOAD_POPULAR_SUCCESS:
      return {
        ...state,
        popularLoading: false,
        popularLoaded: true,
        popularCustomers: action.result,
      };
    case LOAD_POPULAR_FAIL:
      return {
        ...state,
        popularLoading: false,
        popularLoaded: false,
        popularError: action.error,
        popularCustomers: [],
      };

    case INVITE:
      return {
        ...state,
        inviting: true
      };

    case INVITE_SUCCESS:
      const invitationResult = action.result.reduce((agg, curr, idx)=>{
        agg[curr.id]={status: curr.status, message: curr.message}
        return agg
      },{})

      return {
        ...state,
        inviting: false,
        inviteResult: action.result,
        inviteError: null,
        popularCustomers: state.popularCustomers.map(customer=>{
          if(action.customerUserIds.includes(customer.user_id)){
            if(invitationResult[customer.user_id] && invitationResult[customer.user_id].status === 'SUCCESS')
              return {...customer, invite_status:'INVITED'}
            else
              return {...customer, invite_status: invitationResult[customer.user_id] ? invitationResult[customer.user_id].message || 'some error occured' : 'some error occured'}
          }
          return customer
        })
      };
    case INVITE_FAIL:
      return {
        ...state,
        inviting: false,
        inviteResult: action.result,
        inviteError: action.error
      };

     default:
      return state;
  }
}

export function loadPopularCustomers(accessToken, merchantId, merchantUserId, salt) {
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': salt,
    'merchantId': merchantId
  }
  let action = {
      url: `http://m-partners-2:8080/ultron-mp/merchant/${merchantId}/getPopularCustomers`,
      method: 'get',
      data:{
        headers: headers
      }
      
    };
  
  return {
    types: [LOAD_POPULAR, LOAD_POPULAR_SUCCESS, LOAD_POPULAR_FAIL],
    promise: (client) => client.default.post('/takeActionForUltronMp/getPopularCustomers', {
      data: {accessToken, action}
    })
  }
}

export function sendMerchantInviteToCustomer({accessToken, type, customerUserIds, voucherText, roaId, gmv, menuPrice, phone, otp}){
  if(type === 'BOOKING'){
    roaId=null;
    voucherText=""
  }
  else if(roaId) voucherText = "";
  return {
    types:[INVITE, INVITE_SUCCESS, INVITE_FAIL],
    promise: client => client.default.post('sendMerchantInviteToCustomer',{
      data:{accessToken , type, customerUserIds, voucherText, roaId, gmv, menuPrice, phone, otp, updatedBy: getCurrentEmail}
    }),
    customerUserIds
  }
}