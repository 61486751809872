import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import ErrorPage from '../Error/ErrorPage';
import Loader from '../Loader/Loader'
import { AUTH_FAIL_CODES } from '../../constants';
import { logErrorToServer } from '../../helpers/Helpers';

export default class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: null,
      hasError: false,
    };
  }

  componentDidCatch(error, info){
    logErrorToServer(this.props.user ? this.props.user.merchantId : 'user', error, info, true)
  }

  static getDerivedStateFromError(error){
    return {hasError:true, errorStack: {error}}
  }


  componentDidMount() {
    if(!this.props.user){
      const accessToken = window.localStorage.getItem('accessToken');
      if(accessToken){
        this.props.loadAuth(accessToken)
          .then(res => this.setState({
            auth: 'passed'}))
          .catch(err => {
            if(err && AUTH_FAIL_CODES.includes(err.status)) {
              this.setState({auth: 'failed'})
              window.localStorage.removeItem('accessToken');
            }
          });
      } else {
        this.setState({auth: 'failed'})
      }
    }
    else this.setState({auth: 'passed'});
  }

  componentDidUpdate(prevProps, prevState) {
    if((prevProps.render !== this.props.render) || (prevProps.user !== this.props.user)) this.setState({hasError: false})
  }
  

  render() {
    if(this.state.hasError) return <ErrorPage user={this.props.user} page={"AuthenticatedRoute"} errorStack = {this.state.errorStack}/>
    if(this.props.loadingAuth) return <Loader />
    if(this.state.auth){
      if(this.state.auth === 'passed' && this.props.user) return this.props.render(this.props)
      else return <Redirect to={{
        pathname: '/login',
        search: this.props.location.search
      }} />
    }
    return null
  }
}
