import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logoBusiness from '../../images/logo-business.svg';
import defaultLogo from '../../images/circus.png';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from 'react-step-progress-bar';
import blueTick from '../../images/blue-tick.svg';
import download from '../../images/download-blue.svg';
import closeIcon from '../../images/close-crm.svg';
import Loader from '../../images/loader.gif';
import clockIcon from '../../images/in-process-clock.svg';
import errorIcon from '../../images/error-icon.svg';
import { sendEvent } from '../../helpers/Helpers';
import { getBrowserInfo } from '../../helpers/Helpers';
import Step1Diy from './Step1Diy';
import Step2Diy from './Step2Diy';
import Step3Diy from './Step3Diy';
import { getSubscriptionId, getOrdersURL} from '../../redux/modules/merchantOnboarding';
import {verifyOwnershipManually, getPhoneFromGst} from '../../redux/modules/merchantVerification/merchantVerification';
import {isAppInstalledUnauth} from "../../redux/modules/appInstall";
import { load as getMerchants } from '../../redux/modules/merchantList';
import { activatePrivilegeAccount, sendDIYComissionSignupEmail} from '../../redux/modules/newMerchantLanding';
import { toast } from 'react-toastify';
import { getOnboardinStatus, updateOnboardingStatus, hasAcceptedTestOrder, createTestOrder} from '../../redux/modules/newOnboarding';
import NewOnboardingOtpDialog from '../NewOnboarding/NewOnboardingOtpDialog';
import {onboardingStatus as STATUS } from '../../constants';
import { set, get } from '../../helpers/LocalStorageHelper';
import * as FullStory from '@fullstory/browser';
import whiteTick from '../../images/white_tick_diy.png';
import '../NewOnboarding/NewOnboarding.css';
import { white } from 'material-ui/styles/colors';
import {diyFlowTimeConstants as THRESHOLD } from '../../constants';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
const ga = window.ga;


var gbq_identity = "onboarding";

export default connect(store => ({ 
    activationLoading: store.newMerchantLanding.activationLoading,
    activationData: store.newMerchantLanding.activationData,
    merchantInfo: store.merchantList.data,
    onboardinStatus: store.newOnboarding.status_data,
    createTestOrderResult: store.newOnboarding.create_test_order_response,
    appInstall: store.appInstall.appUnAuthInstalled,
    testOrder: store.newOnboarding.test_order_data,
    loading_gst_mobile: store.merchantActivation.loading_gst_mobile,
    loaded_gst_mobile: store.merchantActivation.loaded_gst_mobile,
    verifyingManually: store.merchantActivation.verifyingManually,
    manualResponse: store.merchantActivation.manualResponse,
    manualError: store.merchantActivation.manualError,
    orderShortUrlResponse: store.merchantOnboarding.orderShortUrlResponse
  }), dispatch => bindActionCreators({
    getOnboardinStatus,
    getSubscriptionId,
    activatePrivilegeAccount,
    sendDIYComissionSignupEmail,
    getMerchants,
    isAppInstalledUnauth,
    updateOnboardingStatus,
    hasAcceptedTestOrder,
    createTestOrder,
    getOrdersURL,
    verifyOwnershipManually,
  }, dispatch))
(class NewOnboarding extends Component{
    constructor(props){
        super(props);
        this.state = {
            activeState: 0,
            phoneNumber: "",
            phoneNumbers: [],
            merchantId: "",
            email: "",
            showVideoModal: false,
            upiId: "",
            showVerificationModal: false,
            showVerificationModalCheck: false,
            showTnCModal: false,
            isEmail: false,
            onboardingStatus: [],
            verificationStatus: "",
            commercials: [],
            activationMode: [],
            commissions: {},
            subscriptionId: "",
            bypassCompleteCall: false,
            onboardingLink:"",
            currPage:"storeDetails",
            progressStatus:0,
            thirdPartyMerchant:false,
            tryAgainClicked:false,
            createdBy:"",
            email2:"",
            isStatusRejected:false,
            isDocumentUploadNextClicked:false,
            isOtpVerifiedCheck: false,
            refreshWarning: false,
            clockTimer:moment(THRESHOLD.timerThreshold, "mm:ss").format("mm:ss"),
            establishmentType: ""
        }
        this.activeState = null;
        this.acceptedDocuments = ["Screenshot of Online listing","FSSAI/Drug license","Electricity bill","Other Documents"]
    }

    componentWillMount(){
        // document.body.style.background = 'white';
        FullStory.init({ orgId: 'o-1A16GJ-na1' });
        this.params = new URLSearchParams(this.props.location.search);
        if(this.params.has('merchant') && this.params.has('mobile')){
            this.activeState = parseInt(get(`activeState${this.params.has('id') ? this.params.get('id'):"direct"}`)) || null;
            set('onboardingMerchantId',this.params.get('merchant'))
            this.setState({
                phoneNumber: this.params.get('mobile'),
                merchantId: this.params.get('merchant'),
            })
            this.props.isAppInstalledUnauth(this.params.get('merchant'),this.params.get('mobile'));
            this.getMerchantData(this.params.get('merchant'),this.params.get('mobile'),"diy_commission");
        }
        if(this.params.has('id')) {
            // this.fetchPackageSubscriptionDetails(this.params.get('id'))
        }
        else{
            this.props.push('/');
        }

    }

    sendOnboardingEvent = (eventName,extraParams={}) => {
        gbq_identity = "onboarding";
        this.params = new URLSearchParams(this.props.location.search);
        sendEvent({
            event: `${gbq_identity}_${eventName}`,
            page_type: 'onboarding',
            page_name: 'onboarding',
            subject_merchant_id: this.params.has('merchant') ? this.params.get('merchant') : get('onboardingMerchantId') || 'NOT FOUND',
            device: window.innerWidth > 768 ? 'desktop' :'mobile',
            ...extraParams
        });
    }

    getDateDelta = (delta=0) => {
        let today = new Date()
        let deltaTimestamp
        if (delta) {
            deltaTimestamp = new Date().setDate(today.getDate()-delta)
        } else {
            deltaTimestamp = today
        }
        let deltaDate = new Date(deltaTimestamp)
        let deltaYear = deltaDate.getFullYear()
        let deltaMonth = (deltaDate.getMonth() + 1).toString()
        let deltaDay = deltaDate.getDate().toString()
        deltaMonth = deltaMonth.length > 1 ? deltaMonth : "0" + deltaMonth
        deltaDay = deltaDay.length > 1 ? deltaDay : "0" + deltaDay

        return deltaYear + '-' + deltaMonth + '-' + deltaDay
    }

    getMerchantData = (merchantId,phoneNumber="",packageName="diy_commission") => {
        this.params = new URLSearchParams(this.props.location.search);
        if(merchantId){
            if(this.params)
                this.activeState = parseInt(get(`activeState${this.params.has('id') ? this.params.get('id'):"direct"}`)) || null;
            this.props.getMerchants('*', '*', merchantId)
            .then(res => {
                if(this.props.merchantInfo.length > 0 && this.props.merchantInfo[0] && this.props.merchantInfo[0].user_id){
                    this.props.hasAcceptedTestOrder(this.props.merchantInfo[0].user_id,this.getDateDelta(30),this.getDateDelta(0),"ALL");
                }
            });
            this.sendOnboardingEvent('get_onboarding_data_loading');
            this.props.getOnboardinStatus(merchantId,phoneNumber,packageName).then(res => {
                if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() == 'SUCCESS'){
                   this.sendOnboardingEvent('get_onboarding_data_success');
                    let data = res[0];
                    let phoneNumbers = data.verifiedContacts || [];
                    let activationStatus = data.activationStatus || "";
                    let currentCommissions = {
                        currentDeliveryCommission: Number.isFinite(data.deliveryCommission) ? data.deliveryCommission : (data.commissions && data.commissions.deliveryCommission), 
                        currentVoucherCommission: Number.isFinite(data.voucherCommission) ? data.voucherCommission : (data.commissions && data.commissions.voucherCommission), 
                        currentCashbackCommission: Number.isFinite(data.cashbackCommission) ? data.cashbackCommission : (data.commissions && data.commissions.cashbackCommission),
                        currentMagicpayCommission: Number.isFinite(data.magicPayCommission) ? data.magicPayCommission : (data.commissions && data.commissions.magicPayCommission)
                    }
                    let newCommissions = data.commissions ? {...data.commissions, customerSavings: data.fixedCashin, ...currentCommissions}: {};
                    if(Number.isInteger(STATUS[data.completedStatus]) && STATUS[data.completedStatus] === 0 && data.isOnboardingAllowed === false)
                        this.props.push('/login');
                    this.setState({
                        activationMode: data.activationMode || [],
                        verificationStatus: activationStatus.toUpperCase(),
                        commercials: data.commercials || [],
                        onboardingStatus: data.completedStatus || "",
                        commissions: newCommissions,
                        phoneNumbers: phoneNumbers,
                        subscriptionId: data.packageSubscriptionRequestId || "",
                        phoneNumber: data.merchantPhone,
                        email: data.merchantEmail?data.merchantEmail:this.state.email2,
                        merchantId: data.merchantId,
                        onboardingLink: data.link,
                        thirdPartyMerchant: data.thirdPartyMerchant,
                        createdBy: data.createdBy || "",
                        establishmentType: data.establishmentType || ""
                    })
                    
                    if(data.activationStatus && data.activationStatus.toUpperCase()=== "REJECTED_FOR_FRAUD" && !this.state.tryAgainClicked) {
                        this.setState({
                            showVerificationModal: true,   
                        })
                        setTimeout(() => {this.props.push('/login')},2500);
                    } 
                    
                    else if(data.activationStatus && data.activationStatus === "REJECTED_FOR_INVALID_DOC" && !this.state.tryAgainClicked) {
                        this.setState({
                            activeState: 0,
                            bypassCompleteCall: true,
                            isEmail: false,
                            showVerificationModal: true,
                            progressStatus: 0,
                            currPage:"storeDetails",
                            isStatusRejected:true
                        })
                    } 
                    else if(data.completedStatus && Number.isInteger(STATUS[data.completedStatus]) && !(data.activationStatus && data.activationStatus === "REJECTED_FOR_INVALID_DOC")){
                        const pageCheck = this.activeState !== null && this.activeState >= STATUS[data.completedStatus] ? this.activeState : STATUS[data.completedStatus]

                        if(pageCheck!==this.state.activeState)
                        {
                            this.setState({
                                activeState: this.activeState !== null && this.activeState >= STATUS[data.completedStatus] ? this.activeState : STATUS[data.completedStatus],
                                progressStatus: this.activeState !== null && this.activeState >= STATUS[data.completedStatus] ? this.activeState : STATUS[data.completedStatus],
                                currPage:pageCheck===0?"storeDetails":pageCheck===1?"step2Start":"step3Start",
                            })
                        }
                    }
                }
                else{
                    this.sendOnboardingEvent('get_onboarding_data_failed');
                }
            });
        }
    }

    handleNext = () => {
        this.setState(prevState => {
            if(prevState.verificationStatus === "PENDING")
                set(`activeState${this.params.has('id') ? this.params.get('id'):"direct"}`, prevState.activeState+1)
            if (prevState.activeState+1 == 2) {
                this.triggerTestOrder()
            }
            return {activeState: prevState.activeState+1}
        })

    }

    triggerTestOrder = () => {
        if (this.state.verificationStatus !== "REJECTED_FOR_INVALID_DOC" && this.props.appInstall && !this.props.testOrder && this.props.merchantInfo.length > 0 && this.props.merchantInfo[0]) {
            this.props.createTestOrder(this.props.merchantInfo[0].user_id,this.props.merchantInfo[0].category_id,"MERCHANT_TRAINING")
        }
    }

    changeEmail = (email) => {
        this.setState({
            email: email,
            isEmail: false,
            email2: email
        })
    }

    activatePrivilege = () => {
        if(!this.state.subscriptionId){
            toast.info('Please wait, we are loading details..')
            return;
        }
        if((this.state.bypassCompleteCall && !this.state.isDocumentUploadNextClicked) || ["COMPLETE_REQUEST","DOCUMENT_SUBMITTED","VERIFY_MERCHANT"].includes(this.state.onboardingStatus.toUpperCase())){
            this.setState({
                isEmail: false,
               showVerificationModalCheck: true,
            });
            if(!this.state.isDocumentUploadNextClicked){
                if((this.state.createdBy==="diy_commission" || this.state.createdBy==="") && (this.state.commissions && Number.isFinite(this.state.commissions.currentDeliveryCommission) && this.state.commissions.currentDeliveryCommission>0))
                {
                    this.changePage("establishmentStatus",0.5);
                }
                else

                {
                    this.changePage("establishmentDetails",0.5); 
                }
            }
            else{
                this.props.updateOnboardingStatus(this.state.merchantId,"DOCUMENT_SUBMITTED")
            }
            this.sendOnboardingEvent('next_old_success');
            return;
        }
        let data = {};
        data = {
            "merchantId": this.state.merchantId,
            "merchantEmail": this.state.email,
            "merchantPhone": this.state.phoneNumber,
            "subscriptionId": this.state.subscriptionId,
            "paymentMode": "online"
        }
        this.props.activatePrivilegeAccount(data).then((res) => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                this.setState({
                    isEmail: false,
                    showVerificationModalCheck: true,
                    mandatory: true,
                });
                set('diyOnboardingShortUrl',res[0].shortUrl);
                if(!this.state.isDocumentUploadNextClicked){
                    if((this.state.createdBy==="diy_commission" || this.state.createdBy==="") && (this.state.commissions && Number.isFinite(this.state.commissions.currentDeliveryCommission) && this.state.commissions.currentDeliveryCommission>0))
                    {
                        this.changePage("establishmentStatus",0.5);
                    }
                    else
                    {

                        this.changePage("establishmentDetails",0.5); 
                    }
                    this.getMerchantData(this.state.merchantId,this.state.phoneNumber,"diy_commission");
                }
                else{
                    this.props.updateOnboardingStatus(this.state.merchantId,"DOCUMENT_SUBMITTED")
                }
                this.sendOnboardingEvent('next_new_success');                        
            }
            else{
                this.sendOnboardingEvent('next_new_failed_1', {"payload":data, "response":res[0], "state": this.state});
                const errorMessage = (res && res.message) || "Error in creating the account"
                toast.error(errorMessage)
            }
        })
        .catch((err) => {
           this.sendOnboardingEvent('next_new_failed_2', {"payload":data, "response": err.message, "state": this.state});
            const errorMessage = (err && err.message) || "Error in creating the account, please try again"
            toast.error(errorMessage)
        });
    }

    validateDetails = () => {
       this.sendOnboardingEvent('next_clicked');
        if(this.props.merchantInfo[0] && this.props.merchantInfo[0].is_parent_merchant){
            this.sendOnboardingEvent('next_failed_pid');
            toast.info("This package doesn't support your onboarding, contact support")
        }
        else{
            const emailRegex = /^[a-zA-Z._0-9]+@(\w+\.)+\w+$/;
            if(this.state.email && !emailRegex.test(this.state.email))
            {
                this.setState({isEmail: true});
                toast.error("Email is not valid");
            }
            else{
                this.activatePrivilege();
            }
        }
    }

    sendSignupEmail = (phoneNumber) => {
        if (!phoneNumber) {
            return;
        }
        const merchantData = this.props.merchantInfo[0] || {}
        let data = {};
        data = {
            "merchantId": this.state.merchantId,
            "emailId": this.state.email,
            "phoneNumber": phoneNumber,
            "name": merchantData.merchant_name,
            "address": merchantData.address_line1 + merchantData.locality,
            "voucherSavings": this.state.commissions && this.state.commissions.voucherCommission,
            "deliveryCommission": this.state.commissions && this.state.commissions.currentDeliveryCommission,
            "customerSavings": this.state.commissions && this.state.commissions.customerSavings,
            "cashBusinessComission":  this.state.commissions && (this.state.commissions.cashbackCommission ? this.state.commissions.cashbackCommission : (merchantData.category_id === 5 || merchantData.category_id === 91) ? 5:10 ),
            "discountedBillCashBusiness": this.state.commissions && (this.state.commissions.cashbackCommission ? this.state.commissions.cashbackCommission/2 : (merchantData.category_id === 5 || merchantData.category_id === 91) ? 2.5:5),  /////
            "onboardingLink": this.state.onboardingLink,
        }
        this.props.sendDIYComissionSignupEmail(data).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        });
    }

    hideOtpDialog = (hide=true) => {
        // this.getMerchantData(this.state.merchantId,this.state.phoneNumber,"diy_commission");
        if(hide && !(this.state.activeState === 1 && !this.state.isOtpVerifiedCheck))
        {
            this.setState({showVerificationModal: false, tryAgainClicked:true});
        }
    };

    handleAppDownload = () => {
        this.sendOnboardingEvent('download_app_clicked');
        let browserData = getBrowserInfo();
        if(/iPad|iPhone|iPod|Mac/i.test(browserData.user_agent))
            window.open('https://apps.apple.com/us/app/id1528283353', '_blank');
        else
            window.open('https://play.google.com/store/apps/details?id=com.magicpin.partner', '_blank');
    }

    handleOpenApp = () => {
        if(window.innerWidth > 768){
            this.handleAppDownload();
            return;
        }
        this.sendOnboardingEvent('open_app_clicked');
        let browserData = getBrowserInfo();
        if(/iPad|iPhone|iPod|Mac/i.test(browserData.user_agent)){
            setTimeout(function () { window.location.href = "https://apps.apple.com/us/app/id1528283353"; }, 100);
            window.location.href = "orderhere://";
        }
        else
            window.location.href = "intent://android#Intent;scheme=orderhere;package=com.magicpin.partner;S.browser_fallback_url=play.google.com/store/apps/details?id=com.magicpin.partner;end";
    }

    handleMpLogin = () => {
        this.sendOnboardingEvent('go_panel_clicked');
        let loginShortUrl = get('diyOnboardingShortUrl');
        if(!loginShortUrl){
            this.props.getOrdersURL(this.state.merchantId)
            .then(res => {
                loginShortUrl = this.props.orderShortUrlResponse.shortUrl ? this.props.orderShortUrlResponse.shortUrl : "https://magicpin.in/partners/login"
                window.open(JSON.parse(loginShortUrl),'_blank');
            })
            .catch(() => {
                loginShortUrl = "https://magicpin.in/partners/login"
                window.open(JSON.parse(loginShortUrl),'_blank');
            })
        }
        if(loginShortUrl)
            window.open(JSON.parse(loginShortUrl),'_blank');
    }

    changeStatus = () => {
        this.setState({
            verificationStatus: "",
            showVerificationModal: false,
            tryAgainClicked: true
        })
    }

    handleSubmitStep1 = () =>{
        this.sendOnboardingEvent('establishment_commission__details_submit_button_clicked_old_success');
        if(this.state.showVerificationModalCheck)
        {
            this.setState({showVerificationModal:true});
        }
    }

    changePage = (type, status) => {
        
        this.setState({currPage:type, progressStatus:status})  
    }

    handleDocumentUploadNext = () => {
        this.setState({isDocumentUploadNextClicked:true});
        this.activatePrivilege();
    }

    handleOtpVerification = () => {
        let otpVerificationObject = {
            isOtpVerified: true,
            time:moment().add(THRESHOLD.localStorageTimeThreshold, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        }
        window.localStorage.setItem("otpVerificationObject", JSON.stringify(otpVerificationObject));
        this.handleOtpVerifyCheck();   
    }

    handleOtpSendStep2 = () => {
        let otpVerificationObject = {
            isOtpVerified: false,
            time:"",
        }
        window.localStorage.setItem("otpVerificationObject", JSON.stringify(otpVerificationObject));
        this.setState({showVerificationModal:true, isOtpVerifiedCheck: false}) ;

    }

    handleOtpVerifyCheck = () => {
        this.setState({isOtpVerifiedCheck: true});
        this.startTimer();
    }

    startTimer = () => {
        let timer = moment(THRESHOLD.timerThreshold, "mm:ss").format("mm:ss");
        let timerHandler = setInterval(() => {
         timer = moment(timer, 'mm:ss').subtract(1, 'second').format("mm:ss") ;
          this.setState({clockTimer: timer});
          if (timer === "00:00") {
            clearInterval(timerHandler);
            window.location.reload(false);
          }
          else if(timer === "02:00"){
            this.setState({refreshWarning: true});
          }

          else if(this.state.activeState!==1)
          {
            clearInterval(timerHandler); 
          }
        }, 1000)
      }

      componentDidUpdate = (prevState) => {
        if(!prevState.isOtpVerified && this.state.isOtpVerifiedCheck)
        {
            if(this.state.currPage==="step2Start")
            {
                if((this.state.verificationStatus==="" && !this.state.thirdPartyMerchant)|| (this.state.verificationStatus && this.state.verificationStatus.toUpperCase()==="REJECTED_FOR_INVALID_DOC"))
                {  
                    setTimeout(() => {  this.changePage("documentsDetails",1.5) }, 3000);
                }
                else{
                    setTimeout(() => {  this.changePage("bankDetails",1.5) }, 3000);
                }
            }
        }
      }

    render(){
        const merchantData = this.props.merchantInfo[0] || {};
        return(
            <div className="new-onboarding-diy-container" >
                <div className="header">
                    <img src={logoBusiness} />
                    {
                        window.innerWidth > 768 &&                     
                            <div className="merchant-detail">
                            <img className="new-onboarding-diy-merchant-logo" src={merchantData.merchant_logo || defaultLogo} />
                            <p style={{marginLeft: "16px"}}>{merchantData.merchant_name || "Merchant"}</p>
                        </div>
                    }
                </div>
                <div className="new-onboarding-diy-merchant-steps-container" >
                    {this.state.currPage!== "step2Start" && this.state.currPage!== "step3Start" &&
                    <div className="new-onboarding-diy-merchant-progress-bar-container" style={{width: window.innerWidth < 768 ? "70%":"309px",display: 'inline-block'}}>
                        <ProgressBar
                            filledBackground="#6561E8"
                            percent={this.state.progressStatus*(1/2)*100}
                            height="4px"
                        >
                        {['Sign up','Upload Documents',`Partner-app Training`].map((data, index) => (
                            <Step transition="scale" key={index}>
                                {({ accomplished }) => (
                                    <div>
                                        <div className="new-onboarding-diy-merchant-progress-circle-container" style={{background:Math.floor(this.state.progressStatus) > index  ? '#6561E8' :'white', border: accomplished ? '2.33px solid #6561E8' : '2px solid #A0A0A0', color: accomplished ? "#6561E8" : "rgba(0, 0, 0, 0.38)", fontFamily:accomplished?"robotomedium":"robotoregular" }}>
                                            
                                            {Math.floor(this.state.progressStatus) > index ? <div className="new-onboarding-diy-merchant-progress-bar-img-box" ><img width="14px" height="12.5px" src={whiteTick} /> </div>: index+1}
                                        </div>
                                        <span className="new-onboarding-diy-merchant-progress-bar-step-type" style={{left: data === "Upload Documents" ? "-22px":'-10px', color: accomplished ? "#6561E8" : "rgba(0, 0, 0, 0.54)", fontFamily:Math.floor(this.state.progressStatus) === index ?"robotomedium":"robotoregular"}}>{data}</span>
                                    </div>
                                )}
                            </Step>
                            )
                        )}
                        </ProgressBar>
                    </div>}
                    <div className="tab-container" style={{marginTop:"80px", background:'white'}}>
                        

                                 {this.state.activeState===0 ?
                                 <Step1Diy
                                    merchantInfo={merchantData} 
                                    commissions={this.state.commissions || {}} 
                                    phoneNumber={this.state.phoneNumber || ""} 
                                    phoneNumbers={this.state.phoneNumbers || []}
                                    email={this.state.email || ""} 
                                    isEmail={this.state.isEmail}
                                    changeEmail={this.changeEmail}
                                    handleNext={this.handleNext}
                                    validateDetails={this.validateDetails}
                                    loading={this.props.activationLoading}
                                    status={this.state.verificationStatus}
                                    sendOnboardingEvent={(eventName,extraParams={}) => this.sendOnboardingEvent(eventName,extraParams)}
                                    changePage={this.changePage}
                                    currPage={this.state.currPage}
                                    handleSubmitStep1={this.handleSubmitStep1}
                                    push={this.props.push}
                                    subscriptionId={this.state.subscriptionId}
                                    merchantId={parseInt(this.state.merchantId) || 0}
                                    createdBy={this.state.createdBy}
                                    getMerchantData={()=>{this.getMerchantData(this.state.merchantId, this.state.phoneNumber, "diy_commission")}}
                                    establishmentType={this.state.establishmentType || ""}
                                     >
                                </Step1Diy>
                            :
                            this.state.activeState===1 ?   
                                 <Step2Diy
                                    merchantId={parseInt(this.state.merchantId) || 0}
                                    commissions={this.state.commissions || {}} 
                                    merchantUserId={merchantData.user_id}
                                    phoneNumbers={this.state.phoneNumbers || []}
                                    phoneNumber={this.state.phoneNumber || ""} 
                                    email={this.state.email || ""} 
                                    handleNext={this.handleNext}
                                    loading={this.props.activationLoading}
                                    merchantInfo={merchantData}
                                    commercials={this.state.commercials}
                                    voucherCommission={this.state.commissions ? this.state.commissions.voucherCommission : 10}
                                    sendOnboardingEvent={(eventName,extraParams={}) => this.sendOnboardingEvent(eventName,extraParams)}
                                    appInstall={this.props.appInstall || false}
                                    status={this.state.verificationStatus}
                                    changePage={this.changePage}
                                    currPage={this.state.currPage}
                                    thirdPartyMerchant={this.state.thirdPartyMerchant}
                                    getMerchantData={()=>{this.getMerchantData(this.state.merchantId, this.state.phoneNumber, "diy_commission")}}
                                    isStatusRejected={this.state.isStatusRejected}
                                    handleDocumentUploadNext={this.handleDocumentUploadNext}
                                    handleOtpSendStep2={this.handleOtpSendStep2} 
                                    isOtpVerifiedCheck={this.state.isOtpVerifiedCheck}
                                    handleOtpVerifyCheck={this.handleOtpVerifyCheck}
                                ></Step2Diy>
                                :
                                <Step3Diy
                                    merchantId={parseInt(this.state.merchantId) || 0} 
                                    merchantUserId={merchantData.user_id}
                                    phoneNumber={this.state.phoneNumber || ""} 
                                    email={this.state.email || ""} 
                                    handleNext={this.handleNext}
                                    loading={this.props.activationLoading}
                                    merchantInfo={merchantData}
                                    commercials={this.state.commercials}
                                    voucherCommission={this.state.commissions ? this.state.commissions.voucherCommission : 10}
                                    sendOnboardingEvent={(eventName,extraParams={}) => this.sendOnboardingEvent(eventName,extraParams)}
                                    appInstall={this.props.appInstall || false}
                                    testOrder={this.props.testOrder}
                                    verificationStatus={this.state.verificationStatus}
                                    handleOpenApp={this.handleOpenApp}
                                    handleAppDownload={this.handleAppDownload}
                                    handleMpLogin={this.handleMpLogin}
                                    changePage={this.changePage}
                                    currPage={this.state.currPage}
                                    
                                ></Step3Diy>
                                 }
                            
                        
                    </div>
                </div>
                {
                    this.state.showVerificationModal && 
                    <NewOnboardingOtpDialog phoneNumbers={this.state.phoneNumbers || []} hideOtpDialog={this.hideOtpDialog}
                        handleNext={this.handleNext}
                        merchantId={parseInt(this.state.merchantId || 0)}
                        phoneNumber={this.state.phoneNumber}
                        status={this.state.verificationStatus}
                        push={this.props.push}
                        commercials={this.state.commercials}
                        changeStatus={this.changeStatus}
                        sendSignupEmail={this.sendSignupEmail}
                        activationMode={this.state.activationMode || []}
                        sendOnboardingEvent={(eventName,extraParams={}) => this.sendOnboardingEvent(eventName,extraParams)}
                        changePage={this.changePage} 
                        tryAgainClicked={this.state.tryAgainClicked}
                        getMerchantData={()=>{this.getMerchantData(this.state.merchantId, this.state.phoneNumber, "diy_commission")}}
                        activeState={this.state.activeState}
                        handleOtpVerification={this.handleOtpVerification}
                        thirdPartyMerchant={this.state.thirdPartyMerchant}
                    >
                    </NewOnboardingOtpDialog>
                }

                {this.state.refreshWarning && 
                    <Dialog onClose={() => this.setState({refreshWarning: false})} aria-labelledby="customized-dialog-title" open={this.state.refreshWarning}>
                        <div className="onboarding-refresh-warning-container">
                            <img className="onboarding-refresh-warning-cross-img" src={closeIcon} onClick={() => this.setState({refreshWarning: false})}/>
                            <div className="verification-container" style={{textAlign:"center"}}>
                                Please fill the details. The page will refresh in {this.state.clockTimer} mins.     
                            </div>
                        </div>
                        
                    </Dialog>
                }
            </div>
        )
    }
})