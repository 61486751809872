import React, {Component} from 'react';
import './InstantChat.css';
import chatIndicatorImg from '../../images/chat-indicator-img.svg';
import chatSendIconImg from '../../images/chat-send-icon.svg';
import { toast } from 'react-toastify';
import moment from 'moment';
import chatIcon from '../../images/chat-icon-img.svg';
export default class InstantChat extends Component{

   constructor(props){
      super(props)
      this.state={
         message: '',
         unreadCount: 0
      }
   }

   sendMessage=(inputBoxId)=>{
      // e.preventDefault()
      if(this.state.message && this.state.message != ""){
         this.props.sendMessageToChat(this.props.orderData.orderId, this.state.message)
         document.getElementById(inputBoxId).value = ""
         this.setState({'message': ''})
         // toast.success("Message sent")
      }
   }
   sendMessageOnEnter=(inputBoxId, e)=>{
      // e.preventDefault()
      if(this.state.message && this.state.message != "" && e.key == "Enter"){
         this.props.sendMessageToChat(this.props.orderData.orderId, this.state.message)
         document.getElementById(inputBoxId).value = ""
         this.setState({'message': ''})
         // toast.success("Message sent")
      }
   }

   componentDidMount(){
      this.findLastMessageInfo()
   }

   componentDidUpdate(prevProps){
      if(prevProps.orderData !== this.props.orderData) {
         this.findLastMessageInfo()
      }
      if(prevProps.updatedChatsData !== this.props.updatedChatsData){
         this.findLastMessageInfo()
      }
   }

    findLastMessageInfo = () =>{   
      if(this.props.updatedChatsData && this.props.updatedChatsData.threads){
         let tempChat = this.props.updatedChatsData.threads.filter(thread => thread['thread_id'] == this.props.orderData.orderId)
         if(tempChat && tempChat.length >0){
            let n = tempChat[0].messages.length
            if(n > 0) {
            let unreadMessagesCount = tempChat[0].unread_messages
            let lastMsg= tempChat[0].messages.length > 0 && tempChat[0].messages[n-1].text && tempChat[0].messages[n-1].text.length > 65 ? tempChat[0].messages[n-1].text.slice(0,62) + "..." : tempChat[0].messages[n-1].text
            let isAttachment = tempChat[0].messages[n-1].attachment ? true : false
            let sentByMerchant = (tempChat[0].messages[n-1].user.user_id == this.props.user.userId ? true : false)
            let lastMsgTime= moment(tempChat[0].messages[n-1].sent).format("hh:mm A")
            this.setState({lastMsgTime, lastMsg, sentByMerchant, isAttachment, unreadCount: unreadMessagesCount})
            } else {
               this.setState({lastMsgTime: null, lastMsg: null, sentByMerchant: null, isAttachment: null, unreadCount: 0})
            }
         }
      }
    }

    render(){
        let inputBoxId = this.props.orderData ? 'chat-inputbox' + this.props.orderData.orderId : 'chat-inputbox'
        if(this.props.fireMessageShownSignal === true && (this.state.lastMsg || this.state.isAttachment)) {
           this.props.messageShown()
        }
        return(
        (this.state.lastMsg || this.state.isAttachment)
        ?
        <div className="chat-outer-div">
           <div className="chat-last-text-line" onClick={() => this.props.handleInstantChatClick(this.props.orderData, this.props.callAcceptOrder)}>
              <div style={{textAlign: 'left'}}>
                 <div className="chat-txt-bold">
                    <span style={{color: 'rgba(0,0,0,0.54)'}}>{this.state.sentByMerchant === true ? "You: " : "Customer: "}</span>
                    {this.state.lastMsg? this.state.lastMsg: "Sent an attachment"}
                 </div>
                 <div className="chat-txt-light">{this.state.lastMsgTime}</div>
              </div>
              <div>
                 <div className="chat-unread-cnt">
                 {this.state.unreadCount > 0
                   && <div className="chat-cnt-num-div">
                         {this.state.unreadCount}
                    </div>}
                 </div>
              </div>
           </div>
           <div className="msg-send-input-div">
                <img src={chatIndicatorImg}/>
                <input id={inputBoxId} autoComplete='off' className="instant-chat-input-box" placeholder='Start typing...' onChange={(e)=> this.setState({message: e.target.value})} onKeyPress={(e) => this.sendMessageOnEnter(inputBoxId,e)}/>
                <img src={chatSendIconImg} onClick={() => this.sendMessage(inputBoxId)}/>
           </div>
        </div>
        :
        this.props.caller && this.props.caller === "ORDER_CARD" 
        ?
        null
        :
        <div className="chat-outer-div">
           <div className="chat-last-text-line-default" onClick={() => this.props.handleInstantChatClick(this.props.orderData, this.props.callAcceptOrder)}>
              <div style={{textAlign: 'left'}}>
                 Chat with customer for any concerns
              </div>
              <div>
                 <img src={chatIcon}/>
              </div>
           </div>
        </div>
        )
    }
}