const GET_MERCHANT_COMMISSION = 'commission/GET_MERCHANT_COMMISSION';
const GET_MERCHANT_COMMISSION_SUCCESS = 'commission/GET_MERCHANT_COMMISSION_SUCCESS';
const GET_MERCHANT_COMMISSION_FAIL = 'commission/GET_MERCHANT_COMMISSION_FAIL';

export default function commission(state={} , action = {}) {
  switch (action.type) {
    case GET_MERCHANT_COMMISSION:
      return {
        ...state,
        loading: true
      };
    case GET_MERCHANT_COMMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        commission: action.result,
        error: null
      };
    case GET_MERCHANT_COMMISSION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getMerchantCommission(accessToken, isSaas) {
  return {
    types: [GET_MERCHANT_COMMISSION, GET_MERCHANT_COMMISSION_SUCCESS, GET_MERCHANT_COMMISSION_FAIL],
    promise: (client) => client.default.post('/getMerchantCommission', {
      data: {accessToken, isSaas}
    })
  };
}
