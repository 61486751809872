import React, { useEffect, useState } from 'react'
import './NewPromotions.css'
import PromotionLogo from '../../images/promotionLogo.svg'
import PromotionHeading from '../../images/promotionHeading.svg'
import PrimaryButton from './PrimaryButton'
import CoverImage from './CoverImage'
import PromotionCard from './PromotionCard'
import '../Dashboard/FastWithdrawal.css'
import PromotionHistoryCard from './PromotionHistoryCard'
import '../Feed/partnersForumCollection.css';
import '../Promotions/promotions.css'
import CircleArrow from '../../images/circleArrow.svg'
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bannerInterested, loadAdSalesInteractions, loadPromotions } from '../../redux/modules/promotions'
import moment from 'moment'
import FeedPushSamples from './FeedPushSamples'
import BannerSamples from './BannerSamples'
import BannerInterested from './BannerInterested'
import { toast } from 'react-toastify'
import CategoryBannerInsights from './CategoryBannerInsights'
import { getImpressionsMerchants } from '../../redux/modules/dashboardReducers/impressionDistribution'
import Insights from '../Promotions/Insights'
import { userTypes } from '../../constants'
import { sendEvent } from '../../helpers/Helpers'


export default connect(store => ({
    promotionsLoading: store.promotions.promotionsLoading,
    promotionsLoaded: store.promotions.promotionsLoaded,
    promotions: store.promotions.promotions,
    offset: store.promotions.offset,
    promotionsFilter: store.promotions.filter,

    bannerInterestedError: store.promotions.bannerInterestedError,
    bannerInterestedLoading: store.promotions.bannerInterestedLoading,

    adSalesInteractionsData: store.promotions.adSalesInteractionsData,

    promotionImpressionsLoading: store.impressionDistribution.promotionImpressionsLoading,
    promotionImpressions: store.impressionDistribution.promotionImpressions
}),
    {
        loadPromotions,
        bannerInterested,
        loadAdSalesInteractions,
        getImpressionsMerchants
    })
    (function NewPromotions(props) {

        const [scrollViewIndex, setScrollViewIndex] = useState(0);
        const [showInfoModal, setShowInfoModal] = useState(false);
        const [showFeedpushSamples, setShowFeedpushSamples] = useState(false);
        const [showBannerSamples, setShowBannerSamples] = useState(false);
        const [showBannerInsights, setShowBannerInsights] = useState(false);
        const [insights, setInsights] = useState(false);
        const [selectedPromotion, setSelectedPromotion] = useState({});
        const [promotionFilter, setPromotionFilter] = useState('all');

        useEffect(() => {
            props.loadPromotions(props.user.accessToken, 0, "all", props.user.userId);
            let parentOrMerchantId = props.user && props.user.isParentMerchant && props.user.parentMerchantId ? props.user.parentMerchantId : props.user.merchantId;
            if (!props.adSalesInteractionsData) props.loadAdSalesInteractions(props.user.accessToken, parentOrMerchantId, moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
        }, [])

        const sendBqEvent = (eventName, extra) => {
            const merchantId = props.user && props.user.merchantId
            let userType = "MERCHANT";
            let emailId = ""
            if(props.user){
                userType= props.user.userType === userTypes.ADMIN ? "ADMIN" : "MERCHANT"
            }

            if(userType ==="ADMIN"){
                emailId = window.localStorage.getItem("magicpinId")
            }

            sendEvent({
                event: eventName,
                actor_id: merchantId,
                actor_type: userType,
                subject_merchant_id: merchantId,
                user_type: userType,
                merchant_email: emailId,
                datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
                timestamp: Date.now(),
                device: window.innerWidth > 768 ? 'desktop' :'mobile',
                extra: extra ? JSON.stringify(extra) : null
            })
        }

        const scrollToId = (idName) => {
            sendBqEvent('promotion_know_more_button_clicked');
            const item = document.getElementById(idName);
            if (item) {
                window.scrollTo({
                    top: item.offsetTop,
                    behavior: 'smooth'
                });
            }
        }

        const leftScroll = (event, callerName) => {
            event.stopPropagation()
            if (scrollViewIndex > 0) {
                let decrement = scrollViewIndex - 1;
                setScrollViewIndex(decrement);
                const element = document.getElementById(`desc${callerName}${decrement}`)
                element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }

        const rightScroll = (event, callerName, maxLength = 1) => {
            event && event.stopPropagation()
            if(scrollViewIndex === maxLength-3) {
                props.loadPromotions(props.user.accessToken, props.offset, promotionFilter, props.user.userId);
            }
            if (scrollViewIndex < maxLength - 1) {
                let increment = scrollViewIndex + 1;
                setScrollViewIndex(increment);
                let element = document.getElementById(`desc${callerName}${increment}`)
                element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }

        const showInfoHandler = () => {
            if(!showInfoModal) sendBqEvent("promotion_banner_interested_button_clicked");
            setShowInfoModal(!showInfoModal);
        }

        const showFeedPushHandler = () => {
            if(!showFeedpushSamples) sendBqEvent("promotion_feed_push_sample_button_clicked");
            setShowFeedpushSamples(!showFeedpushSamples)
        }

        const showBannerHandler = () => {
            if(!showBannerSamples) sendBqEvent("promotion_banner_sample_button_clicked");
            setShowBannerSamples(!showBannerSamples)
        }

        const bannerInterestedHandler = async (data) => {
            try {
                await props.bannerInterested(data);
                sendBqEvent("promotion_banner_interested_response_success", data);
                toast.success('Your response submitted successfully');
                showInfoHandler();
            } catch (err) {
                sendBqEvent("promotion_banner_interested_response_failure", data);
                toast.error(props.bannerInterestedError || err && err.message || "Please try again later");
            }
        }

        const categoryBannerDateRangeHandler = (startDate, endDate) => {
            let parentOrMerchantId = props.user && props.user.isParentMerchant && props.user.parentMerchantId ? props.user.parentMerchantId : props.user.merchantId;
            props.loadAdSalesInteractions(props.user.accessToken, parentOrMerchantId, startDate, endDate);
        }

        const hideInsights = () => {
            setShowBannerInsights(false);
            setInsights(false)
        }

        const viewInsights = (promotion) => {
            const startDate = moment(promotion.start_date.substr(0, 10)).format('YYYY-MM-DD');
            const endDate = moment(promotion.start_date).add(7, 'days').isBefore(moment())
                ? moment(promotion.start_date.substr(0, 10))
                    .add(7, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            props.getImpressionsMerchants(props.user.accessToken, [props.user.merchantId], startDate, endDate, 'promotionImpressions');
            setInsights(true);
            setSelectedPromotion(promotion);
        }

        const viewBannerInsights = (promotion) => {
            let parentOrMerchantId = props.user && props.user.isParentMerchant && props.user.parentMerchantId ? props.user.parentMerchantId : props.user.merchantId;
            props.loadAdSalesInteractions(props.user.accessToken, parentOrMerchantId, moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
            setShowBannerInsights(true);
            setSelectedPromotion(promotion);
        }

        const changeFilterHandler = (filter) => {
            props.loadPromotions(props.user.accessToken, 0, filter, props.user.userId);
            setPromotionFilter(filter);
            setScrollViewIndex(0);
            let element = document.getElementById(`descfeedhistory0`)
            element && element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }

        const showPromotionSection = promotionFilter === 'all' ? (props.promotions && props.promotions.length) || (props.adSalesInteractionsData && props.adSalesInteractionsData.interactions_with_widget_details) : true;
        const showBannerSection = promotionFilter === 'all' || (props.adSalesInteractionsData && props.adSalesInteractionsData.status && props.adSalesInteractionsData.status.toLowerCase() === promotionFilter)

        return (
            <div>
                {/* top section */}
                <div className='pageInfoParent'>
                    <div className='pageInfoLeft'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h1 className='bigHeading'> Grow your business with us</h1>
                            <div className='seperator'></div>
                            <div className='paragraph'>Promote your outlet to 10000 + customers of your locality.</div>
                            <div className='button' >
                                <PrimaryButton buttonName={"Know More"} onClick={() => scrollToId("feedCardHeader")} />
                            </div>
                        </div>
                    </div>
                    <div className='pageInfoRight'>
                        <div className='coverImage'>
                            <CoverImage coverImageComponent={PromotionLogo} animation={true} />
                        </div>
                    </div>
                </div>

                {/* seperator */}
                <div className='pageInfoParent section' id="feedCardHeader">
                    <div className='pageInfoLeft'>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 className='smallHeading'>We help you engage your Customers!</h2>
                            <div className='paragraph' style={{ marginTop: 'auto' }}>To stand out you need the right tools. With us you’ve got everything you need to build your presence.</div>
                        </div>
                    </div>
                </div>

                {/* feed cards */}
                <div className='pageInfoParent paddingMobile'>
                    <div className='pageInfoLeft marginRight2'>
                        <PromotionCard sendBqEvent={sendBqEvent} showFeedpushSamples={showFeedPushHandler} {...props} promotionType="feedpush" description="Create stunning ads and promote to all the customers in your locality for free." />
                    </div>

                    <div className='pageInfoLeft marginLeft2 marginBottom10'>
                        <PromotionCard showBannerSamples={showBannerHandler} showInfoHandler={showInfoHandler} {...props} promotionType="banner" description="Get ahead from your competitors and get up to 8X more visibility on the Shop Page." />
                    </div>
                </div>

                {/* seperator */}
                {showPromotionSection && <div className='pageInfoParent' style={{ backgroundColor: 'rgba(196, 196, 196, 0.15)', paddingTop: '0', paddingBottom: '0' }}>
                    <div className='pageInfoLeft' style={{ paddingTop: '1vw', display: 'flex', flexDirection: 'row' }}>
                        <h2 className='smallHeading'>My Promotions</h2>
                        <div style={{ width: '55%', marginTop: "-9%", marginLeft: '-30%' }}>
                            <CoverImage coverImageComponent={PromotionHeading} />
                        </div>
                    </div>
                </div>}

                {/* my promotions */}
                {showPromotionSection && <div className='pageInfoParent' style={{ flexDirection: 'column', paddingBottom: '6vw' }}>
                    {props.promotions && <div className='pageInfoLeft' style={{ order: 'unset', justifyContent: 'space-between', width:'100%' }}>
                        <div style={{ flexDirection: 'column', alignItems: 'flex-start', width: '30%' }}>
                            <h2 className='smallHeading'>FeedPush</h2>
                            <div className='seperator alignLeftSeperator'></div>
                        </div>
                        <div className='dropdownfilter' style={{display:'contents'}}>
                            <Dropdown
                                text={window.innerWidth > 768 ? `Filter by:  ${promotionFilter}` : 'Filter by'}
                                icon='filter'
                                floating
                                labeled
                                button
                                className='icon'
                            >
                                <Dropdown.Menu>
                                <Dropdown.Item onClick={() => changeFilterHandler('all')}>All</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeFilterHandler('completed')}>Completed</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeFilterHandler('live')}>Live</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeFilterHandler('pending')}>Pending</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeFilterHandler('declined')}>Declined</Dropdown.Item>
                                <Dropdown.Item onClick={() => changeFilterHandler('paused')}>Paused</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>}
                    {props.promotions && <div className='forum-collection-container' style={{ marginTop: '2vw', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <div className='forum-collection-heading' style={{ borderTop: 'none', overflowX: 'hidden' }} >
                            {props.promotions && props.promotions.map((promotion, index) => <PromotionHistoryCard key={promotion.promotion_id} id={`descfeedhistory${index}`} {...props} image={promotion.imageUrlPushed || promotion.imageUrl} status={promotion.status} message={promotion.messageTextPushed || promotion.messageText} startDate={promotion.startDate && moment(promotion.startDate).format('YYYY-MM-DD')} promotion={promotion} viewInsights={viewInsights} />
                            )}

                            <div>
                                {
                                    scrollViewIndex > 0 &&
                                    <img className="pointer"
                                        src={CircleArrow}
                                        style={{ position: 'absolute', left: '10px', top: '45%', transform: 'rotate(180deg)' }}
                                        onClick={(event) => leftScroll(event, 'feedhistory')} />

                                }
                                {scrollViewIndex < props.promotions.length - 1 &&
                                    <img className="pointer"
                                        src={CircleArrow}
                                        style={{ position: 'absolute', right: '10px', top: '45%' }}
                                        onClick={(event) => rightScroll(event, 'feedhistory', props.promotions.length)} />
                                }
                            </div>
                        </div>
                    </div>}

                    {props.adSalesInteractionsData && props.adSalesInteractionsData.interactions_with_widget_details && showBannerSection && <div className='pageInfoLeft' style={{ order: 'unset', justifyContent: 'start', marginTop: '6vw' }}>
                        <div style={{ flexDirection: 'column', alignItems: 'flex-start', width: '30%' }}>
                            <h2 className='smallHeading'>Banners</h2> 
                            <div className='seperator alignLeftSeperator'></div>
                        </div>
                    </div>}
                    {props.adSalesInteractionsData && props.adSalesInteractionsData.interactions_with_widget_details && showBannerSection && <div className='forum-collection-container' style={{ marginTop: '2vw', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <div className='forum-collection-heading' style={{ borderTop: 'none', overflowX: 'hidden' }} >
                            <PromotionHistoryCard {...props} image={props.adSalesInteractionsData && props.adSalesInteractionsData.image} status={props.adSalesInteractionsData && props.adSalesInteractionsData.status} startDate={props.adSalesInteractionsData && props.adSalesInteractionsData.start_date && moment(props.adSalesInteractionsData.start_date).format('YYYY-MM-DD')} promotion={props.adSalesInteractionsData} viewInsights={viewBannerInsights} />
                        </div>
                    </div>}
                </div>}


                {/* footer */}
                {/* <div className='pageInfoParent' style={{ backgroundColor: '#2E108E', paddingBottom: '6rem' }}>
                    <div className='footerContainer' style={{ width: '100%' }}>
                        <div className='footerAlignment'>
                            <img src={MagicpinForBusiness} width="70%" />
                            <div style={{ color: 'white', marginTop: '8px' }}>India's #1 marketing app for local businesses.</div>
                        </div>
                        <div className='footerAlignment' >
                            <div style={{ color: 'white' }}><span style={{ color: '#EF1C74' }}>Email ID:</span> magicpinportal@magicpin.com</div>
                            <div style={{ color: 'white', marginTop: '8px' }}><span style={{ color: '#EF1C74' }}>Contact Us:</span> 8865782334</div>
                        </div>
                    </div>
                </div> */}


                {showInfoModal && <BannerInterested
                    {...props}
                    show={showInfoModal}
                    onHide={showInfoHandler}
                    onSubmit={(data) => bannerInterestedHandler(data)} />}

                {showFeedpushSamples && <FeedPushSamples
                    {...props}
                    show={showFeedpushSamples}
                    onHide={showFeedPushHandler} />}


                {showBannerSamples && <BannerSamples
                    {...props}
                    show={showBannerSamples}
                    onHide={showBannerHandler} />}

                {showBannerInsights && <CategoryBannerInsights hideInsights={hideInsights} user={props.user}
                    promotion={selectedPromotion}
                    categoryBannerInsightsData={props.adSalesInteractionsData}
                    categoryBannerDateRangeHandler={(start, end) => categoryBannerDateRangeHandler(start, end)} />}

                {insights && <Insights hideInsights={hideInsights}
                    user={props.user}
                    promotion={selectedPromotion}
                    impressionLoading={props.promotionImpressionsLoading}
                    impressions={props.promotionImpressions ? props.promotionImpressions : []} />}
            </div>
        )
    })