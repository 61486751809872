const LOAD = 'outbound/LOAD';
const LOAD_SUCCESS = 'outbound/LOAD_SUCCESS';
const LOAD_FAIL = 'outbound/LOAD_FAIL';
const VERIFY_SUCCESS = 'outbound/VERIFY_SUCCESS'
const VERIFY_FAILURE = 'outbound/VERIFY_FAILURE'
const VERIFY_LOADING= 'outbound/VERIFY_LOADING'
const CRM_CONATACT_LOADING = 'outbound/CRM_CONATACT_LOADING'
const CRM_CONATACT_SUCCESS = 'outbound/CRM_CONATACT_SUCCESS'
const CRM_CONATACT_FAILURE = 'outbound/CRM_CONATACT_FAILURE'
const CALLING = 'outbound/CALLING'
const CALL_CONNECTED = 'outbound/CALL_CONNECTED'
const CALL_FAILED = 'outbound/CALL_FAILED'
const HANGUP_LOADING = 'outbound/HANGUP_LOADING'
const HANGUP_SUCCESS = 'outbound/HANGUP_SUCCESS'
const HANGUP_FAILURE = 'outbound/HANGUP_FAILURE'
const AGENT_ACTIVITY_LOADING = 'outbound/AGENT_ACTIVITY_LOADING'
const AGENT_ACTIVITY_FAILURE = 'outbound/AGENT_ACTIVITY_FAILURE'
const AGENT_ACTIVITY_SUCCESS = 'outbound/AGENT_ACTIVITY_SUCCESS'
const CHANGE_REMARK_LOADING = 'outbound/CHANGE_REMARK_LOADING'
const CHANGE_REMARK_SUCCESS = 'outbound/CHANGE_REMARK_SUCCESS'
const CHANGE_REMARK_FAILURE = 'outbound/CHANGE_REMARK_FAILURE'

const initialState = {
  loading: true
}

export default function outbound(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        assignedMerchants: action.result
      }
    case LOAD_FAIL:
      return {
        ...state,
        loading:false,
        err:action.error
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        verify_data: action.result
      }
    case CALLING:
      return{
        ...state,
        calling:true,
        connected:false
      }
    case CALL_CONNECTED:
      return {
        ...state,
        call_response: action.result,
        calling:false,
        connected:true
      }
    case CALL_FAILED:
      return{
        ...state,
        calling:false,
        connected:false
      }
    case HANGUP_SUCCESS:
      return{
        ...state,
        hangup_data:action.result
      }
    case HANGUP_FAILURE:
      return{
        ...state,
        err:action.error
      }
    case AGENT_ACTIVITY_SUCCESS:
      return{
        ...state,
        agent_activity: action.result
      }
    default:
      return state;
  }
}

export function getAssignedMerchants(agent_id, remarkType){
  return{
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/get_assigned_merchants/',{
      params:{
        agent_id:agent_id,
        remarketing_type:remarkType
      }
    })
  }
}

export function getMerchantRemarketingDetails(merchant_id){
  return{
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/search/',{
      params:{
        merchant_id:merchant_id
      }
    })
  }
}

export function verifyMerchant(merchantId, phone, markedBy){
  return{
    types:[VERIFY_LOADING, VERIFY_SUCCESS, VERIFY_FAILURE],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/verify_contact/',{
      params:{
        merchant_id:merchantId,
        phone:phone,
        marked_by:markedBy
      }
    })
  }
}

export function createCRMcontact(merchantId, phone, agentId, statusValue, statusDate){
  return{
    types:[CRM_CONATACT_LOADING, CRM_CONATACT_SUCCESS, CRM_CONATACT_FAILURE],
    promise: (client) => client.default.get('https://crm.magicpin.in/mpcode/AutomaticEntry.php',{
      params:{
        merchantId:merchantId,
        method:'createMerchantContact',
        merchantMobile:phone,
        agentId:agentId,
        qualificationStatus:statusValue,
        qualificationDate: statusDate
      }
    })
  }
}

export function outboundCall(agent_id, phone){
  let name = agent_id.split('.')[0].toLowerCase()
  name = name + '@magicpin'
  return{
    types:[CALLING, CALL_CONNECTED, CALL_FAILED],
    promise: (client) => client.default.get('/outboundCallConnect',{
      params:{
        userName:name,
        phone:phone
      }
    })
  }
}

export function callHangup(agent_id, dispositionType){
  let name = agent_id.split('.')[0].toLowerCase()
  name = name + '@magicpin'
  return{
    types:[HANGUP_LOADING, HANGUP_SUCCESS, HANGUP_FAILURE],
    promise: (client) => client.default.get('/outboundCallHangup',{
      params:{
        userName:name,
        callType:'DEFAULT:'+dispositionType
      }
    })
  }
}

export function getAgentActivity(agent_id){
  return{
    types:[AGENT_ACTIVITY_LOADING, AGENT_ACTIVITY_SUCCESS, AGENT_ACTIVITY_FAILURE],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/agentactivity/',{
      params:{
        agent_id:agent_id
      }
    })
  }
}

export function changeRemarketingType(merchant_id, remarkType){
  return{
    types:[CHANGE_REMARK_LOADING, CHANGE_REMARK_SUCCESS, CHANGE_REMARK_FAILURE],
    promise: (client) => client.default.post('https://mcp.magicpin.in/mcp/merchant/contact/change_remarketing/',{
      data:{
        merchant_id:merchant_id,
        new_remarketing_type:remarkType
      }
    })
  }
}