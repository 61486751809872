import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OtpInput from "react-otp-input";
import blueTick from '../../images/tick-blue-bubble.svg';
import clockIcon from '../../images/in-process-clock.svg';

import Dialog from 'material-ui/Dialog';
import { toast } from 'react-toastify';

import {verifyOtp,sendOtp,sendOtpByCall} from '../../redux/modules/verifyOtpMagicStore';
import {verifyOwnershipManually,getPhoneFromGst} from '../../redux/modules/merchantVerification/merchantVerification';
import { verifyOwnershipThirdParty,} from '../../redux/modules/newOnboarding';
import {  activateDirectMagicstore, getMUIDByAlias, activateSaasUltron, getOrdersURL} from '../../redux/modules/merchantOnboarding';
import closeIcon from '../../images/close-crm.svg';
import downArrow from '../../images/left-arrow-copy-10.svg';
import Loader from '../../images/loader.gif';
import errorIcon from '../../images/error-icon.svg';
import { updateOnboardingStatus} from '../../redux/modules/newOnboarding';


import './NewOnboarding.css';

const styles = {
    titleStyle: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        fontSize: '20px',
        lineHeight: '24px',
        fontFamily: 'robotobold',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '24px',
        borderBottom: 'none',
    },
    contentStyle: {
        borderRadius: '8px',
        width:  window.innerWidth < 768 ? '96%':'500px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px'
    },
    closeStyle: {
        position: 'absolute',
        top: '24px',
        right: '18px',
        width: '16px',
        height: '16px',
        cursor: 'pointer',
    },
    bodyStyle: {
        padding: '0px auto',
        paddingBottom: '32px',
    },
    paperStyle: {
        style: {
            borderRadius: '16px',
        }
    },
  }

  
export default connect(state => ({
    otpSent: state.verifyOtpMagicStore.otpSent,

    loading_gst_mobile: state.merchantActivation.loading_gst_mobile,
    loaded_gst_mobile: state.merchantActivation.loaded_gst_mobile,
    mobile: state.merchantActivation.mobile,
    mobileError: state.merchantActivation.mobileError,

    verifyingManually: state.merchantActivation.verifyingManually,
    manualResponse: state.merchantActivation.manualResponse,
    manualError: state.merchantActivation.manualError,
  }), dispatch => bindActionCreators({
    sendOtp,
    sendOtpByCall,
    verifyOtp,
    verifyOwnershipManually,
    getPhoneFromGst,
    verifyOwnershipThirdParty,
    activateDirectMagicstore,
    updateOnboardingStatus,
  }, dispatch))
  (class NewOnboardingOtpDialog extends Component {
    constructor(props){
        super(props)
        this.state = {
            showDialog: true,
            openDropDown: false,
            otpSent: false,
            phoneNumber: "",
            otp: "",
            fileSelected: "",
            counter: 30,
            showVerifyOtp: false,
            otpVerified: false,
            hasErrored: false,
            document: "",
            sendSixDigitOtp: true
        }
        this.acceptedDocuments = ["Screenshot of Online listing","FSSAI/Drug license","Electricity bill","Other Documents"]
    }

componentDidMount() {

    if(this.props.phoneNumbers.includes(this.props.phoneNumber) || this.props.status === "APPROVED"){
        this.setState({
            phoneNumber: this.props.phoneNumber
        })
        this.props.sendOnboardingEvent('popup_with_otp_dropdown_only');
    }
    else{
        this.setState({
            phoneNumber: ""
        })
        if(this.props.phoneNumbers.length > 0)
            this.props.sendOnboardingEvent('popup_with_both_dropdowns');        
        else
            this.props.sendOnboardingEvent('popup_with_document_dropdown_only');
    }

}

handleOtp = (e) => {
    this.setState({ otp: e})
}

verifyOwnership = () => {
    if(this.state.document === "GST number" && this.state.gstNumber){
        this.getPhoneFromGst();
    }
    else if(this.state.fileSelected){
        this.verifyOwnershipManually();
    }
}

verifyOwnershipManually = () => {
    this.props.sendOnboardingEvent('uploading_document_loading');

    this.props.verifyOwnershipManually(this.props.merchantId, [this.state.fileSelected]).then((res) => {
        if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === 'SUCCESS'){
            this.props.sendOnboardingEvent('uploading_document_success');
            this.props.hideOtpDialog(false);
        }
        else {
            this.props.sendOnboardingEvent('uploading_document_failed_1');
            const errorMessage = (res && res.message) || "Error in uploading the document"
            toast.error(errorMessage)
        }
    })
    .catch(err => {
        this.props.sendOnboardingEvent('uploading_document_failed_2');
        const errorMessage = (err && err.message) || "Error in uploading the document, try again later"
        toast.error(errorMessage)
    });
}

activateCommercials = () => {

    if(this.props.status === 'APPROVED' || this.props.status === '')
        this.props.updateOnboardingStatus(this.props.merchantId,"OTP_VERIFIED").then((res) => {
            if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === "SUCCESS"){
                this.props.sendOnboardingEvent('onboarding_status_success');
                this.props.handleNext();
                this.props.changePage("step2Start",1);
                this.props.getMerchantData(); 
                this.props.hideOtpDialog();
                
            }
            else{
                this.props.sendOnboardingEvent('onboarding_status_failed_1');
                const errorMessage = (res && res.message) || "Error in updating the status"
                toast.error(errorMessage)
            }
        })
        .catch((err) => {
            this.props.sendOnboardingEvent('onboarding_status_failed_2');
            const errorMessage = (err && err.message) || "Error in updating the status, try after sometime"
            toast.error(errorMessage)
        })
    else{
        this.props.updateOnboardingStatus(this.props.merchantId,"DOCUMENT_OTP_VERIFIED")
        this.props.handleNext();
        this.props.changePage("step2Start",1); 
        this.props.hideOtpDialog();
        
    }
}

getPhoneFromGst = () => {
    this.props.getPhoneFromGst(this.props.merchantId, this.state.gstNumber).then((res) => {
        if(this.props.mobile && this.props.mobile.substr(this.props.mobile.length - 10) === this.props.phoneNumber.substr(this.props.phoneNumber.length - 10)){
            this.activateCommercials();
        }
        else{
            const errorMessage = (res && res.message) || "We could validate gst details, try other documents"
            
            toast.error(errorMessage)
        }
    })
    .catch((err) => {
        const errorMessage = (err && err.message) || "We could validate gst details, try other documents"
        toast.error(errorMessage)
        
    })
}

sendOtp = (resend=false) => {
    let phoneNumber = this.refs.claimNumbers ? this.refs.claimNumbers.value : this.refs.phoneNumbers.value;
    this.props.sendOnboardingEvent('send_otp_clicked');
    if(resend)
        this.setState({
            counter: 30,
            otpSent: true,
        })
    else     
        this.setState({
            counter: 30,
            otpSent: true,
            phoneNumber: this.refs.claimNumbers ? this.refs.claimNumbers.value : this.refs.phoneNumbers.value,
        })
    if(this.props.activeState===0)
    {
        this.props.sendSignupEmail(resend ? this.state.phoneNumber: phoneNumber.toString())
    }
    
    this.props.sendOtp(resend ? this.state.phoneNumber: phoneNumber.toString(), this.state.sendSixDigitOtp).catch(err => {
        toast.error(err.message || "Please try again later");
    })
    this.timer = setInterval(this.countDown,1000);
}

sendOtpByCall = () => {
    this.props.sendOnboardingEvent('send_otp_by_call_clicked');
        this.setState({
            counter: 30,
            otpSent: true,
        })
    this.props.sendOtpByCall(this.state.phoneNumber)
    this.timer = setInterval(this.countDown,1000);
}


verifyOtp = (phone,otp) => {
    this.props.sendOnboardingEvent('verify_otp_clicked');
    this.props.verifyOtp(otp, phone).then(res => {
        this.props.sendOnboardingEvent('verify_otp_success');
        this.setState({
            hasErrored: false,
            otpVerified: true
        });
        this.props.handleOtpVerification();
        if(this.props.activeState === 0)
        {
            this.activateCommercials();
        }  
        else
        {
            this.props.hideOtpDialog();
        }  
    })
    .catch(err => {
        this.props.sendOnboardingEvent('verify_otp_failed');
        this.setState({
            hasErrored: true,
        })
    })
}

countDown = () => {
    let seconds = this.state.counter - 1;
    this.setState({
      counter: seconds
    });
    
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
}

handleKeyPress = (e) => {
    if(e.key === 'Enter' && this.state.otp.length === 6) this.verifyOtp(this.state.phoneNumber,this.state.otp);
}

onChange = () => {
    var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
    if(uploadedFile.length > 0){
      this.setState({
        fileSelected: uploadedFile[0],
      })
    }
}

deleteFile = () => {
    var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
    if(uploadedFile.length > 0){
        uploadedFile[0].value = "";
        this.setState({
            fileSelected: "",
        })
    }
}

triggerUpload = () => {
    let fileInput = document.getElementById('file-input')
    if(fileInput && this.acceptedDocuments.includes(this.state.document)){
        fileInput.click()
    }
}


render() {
    let uniqueNumbers = [...new Set(this.props.phoneNumbers)];
    let claimNumbers = [...new Set([this.props.phoneNumber,...this.props.phoneNumbers])]
    if(this.props.status === "APPROVED")
        uniqueNumbers = claimNumbers

    return (
    <div>
        <Dialog
            title={""}
            titleStyle = {{...styles.titleStyle}}
            paperProps = {styles.paperStyle}
            contentStyle = {styles.contentStyle}
            bodyStyle = {{...styles.bodyStyle}}
            modal={false}
            open={this.state.showDialog}
            onRequestClose={() => {this.props.hideOtpDialog()}}>
            {this.props.status !== "REJECTED_FOR_FRAUD" && this.props.activeState !== 1 && <img src={closeIcon} onClick={() => {this.props.hideOtpDialog()}} style={styles.closeStyle}/>}
            {
                !this.state.otpSent && (this.props.status === "REJECTED_FOR_INVALID_DOC" || this.props.status === "REJECTED_FOR_FRAUD") && !this.props.tryAgainClicked ? 
                <div className="verify-otp-container">
                    <img style={{width:"48px",marginBottom:"16px"}} src={errorIcon}/>
                    <h5 style={{fontSize:"16px"}}>Your verification is rejected</h5>
                    {this.props.status !== "REJECTED_FOR_FRAUD" &&
                    <div style={{marginTop:"60px"}}>
                        <button style={{backgroundColor: "#ef1c74",padding:"8px 50px", borderRadius:"4px",color: "white", border:"none",fontSize:"14px",height:"40px"}} 
                        onClick={() => {this.props.changeStatus();this.props.sendOnboardingEvent('document_rejected');}} >
                            Try again
                        </button>
                    </div>}
                </div>
                :
                !this.state.otpSent && this.props.thirdPartyMerchant===false && this.props.phoneNumber ?
                    <div className="verification-container">
                        <div className="otp-container" style={{textAlign:"center"}}>
                            <h5 style={{fontSize:"16px"}}>A verification OTP will be send to this number</h5>
                            <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"24px auto 10px",width: window.innerWidth < 768 ? "95%":"70%"}}>
                                <div style={{position:"relative"}}>
                                    <select className="select-dropdown" name="" ref='claimNumbers' value={this.state.phoneNumber || this.props.phoneNumber} onChange = {(e) => this.setState({phoneNumber: e.target.value})}>
                                        <option value={this.props.phoneNumber}>{this.props.phoneNumber}</option>
                                        {/* {Array.isArray(claimNumbers) && claimNumbers.map((data, index)=><option key={index} value={data}>{data}</option>)} */}
                                    </select>                      
                                </div>
                                <div>
                                    <button disabled={!(this.state.phoneNumber || this.props.phoneNumber)} onClick={() => this.sendOtp()} style={{backgroundColor:"#ef1c74",padding:"8px 16px", borderRadius:"4px", border:"none",color:"white",fontSize:"14px",height:"32px"}}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                !this.state.otpSent && this.props.status === "PENDING" ? 
                    <div className="verification-container">
                        <div className="otp-container" style={{textAlign:"center"}}>
                            <h5 style={{fontSize:"16px"}}>A verification OTP will be send to this number</h5>
                            <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"24px auto 10px",width: window.innerWidth < 768 ? "95%":"70%"}}>
                                <div style={{position:"relative"}}>
                                    <select className="select-dropdown" name="" ref='claimNumbers' value={this.state.phoneNumber || this.props.phoneNumber} onChange = {(e) => this.setState({phoneNumber: e.target.value})}>
                                        {/* <option value={this.props.phoneNumber}>{this.props.phoneNumber}</option> */}
                                        {Array.isArray(claimNumbers) && claimNumbers.map((data, index)=><option key={index} value={data}>{data}</option>)}
                                    </select>                      
                                </div>
                                <div>
                                    <button disabled={!(this.state.phoneNumber || this.props.phoneNumber)} onClick={() => this.sendOtp()} style={{backgroundColor:"#ef1c74",padding:"8px 16px", borderRadius:"4px", border:"none",color:"white",fontSize:"14px",height:"32px"}}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                !this.state.otpSent && this.props.status === "APPROVED" ? 
                    <div className="verification-container">
                        <div className="otp-container" style={{textAlign:"center"}}>
                            <h5 style={{fontSize:"16px"}}>A verification OTP will be send to this number</h5>
                            <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"24px auto 10px",width: window.innerWidth < 768 ? "95%":"70%"}}>
                                <div style={{position:"relative"}}>
                                    <select className="select-dropdown" name="" ref='claimNumbers' value={this.state.phoneNumber} onChange = {(e) => this.setState({phoneNumber: e.target.value})}>
                                        {/* <option value="">Phone Number</option> */}
                                        {Array.isArray(this.props.phoneNumbers) && uniqueNumbers.map((data, index)=><option key={index} value={data}>{data}</option>)}
                                    </select>                      
                                </div>
                                <div>
                                    <button disabled={!this.state.phoneNumber} onClick={() => this.sendOtp()} style={{backgroundColor:"#ef1c74",padding:"8px 16px", borderRadius:"4px", border:"none",color:"white",fontSize:"14px",height:"32px"}}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                this.state.otpSent ?
                <div className="verify-otp-container" onKeyPress={this.handleKeyPress}>
                    <h5 style={{fontSize:"16px"}}>OTP sent to {this.state.phoneNumber}</h5>
                    <p style={{fontSize:"14px",fontFamily:"robotoregular",color:"red"}}> <span style={{color:"red"}}>*</span>Don't share this OTP with anyone</p>
                    {
                        this.state.counter === 0 ?
                            <div>
                                <p style={{fontSize:"14px",fontFamily:"robotoregular",color:"rgba(0, 0, 0, 0.54)"}}>Didn’t receive your OTP? <span style={{color:"#ef1c74",cursor:"pointer"}} onClick={e => this.sendOtp(true)}>Resend OTP</span></p>
                                <p style={{fontSize:"14px",fontFamily:"robotoregular",color:"rgba(0, 0, 0, 0.54)"}}><span style={{color:"#ef1c74",cursor:"pointer",textDecoration: "underline"}} onClick={e => this.sendOtpByCall()}>Send OTP via Call</span></p>
                            </div>
                        :
                        <p style={{fontSize:"14px",fontFamily:"robotoregular",color:"rgba(0, 0, 0, 0.54)"}}>Resend in {this.state.counter} seconds</p>
                    }
                    <div style={{margin: window.innerWidth < 768 ? "16px auto":"24px 25px",width: "95%"}}>
                        <div style={{position:"relative"}}>
                            <OtpInput
                                value={this.state.otp}
                                inputStyle="otp-box"
                                onChange={this.handleOtp}
                                numInputs={6}
                                shouldAutoFocus
                                isInputNum={true}
                                containerStyle={{"justifyContent":"center"}}
                                errorStyle="error"
                                hasErrored={this.state.hasErrored}
                                separator={<span>&nbsp;&nbsp;</span>}
                            />
                            {this.state.hasErrored && <p style={{color:"red",textAlign:"center",marginLeft: "10px",fontSize: "14px",marginTop:"4px"}}>Incorrect OTP</p>}
                        </div>

                    </div>
                    <div>
                        <button style={{backgroundColor: this.state.otp.length === 6 ? "#ef1c74":"#f7f7fa",padding:"8px 16px", borderRadius:"4px",color: this.state.otp.length === 6 ? "white":"rgba(0, 0, 0, 0.2)", border:"none",fontSize:"14px",height:"40px"}} 
                        onClick={e => this.verifyOtp(this.state.phoneNumber,this.state.otp)} >
                            Verify OTP
                        </button>
                    </div>
                </div>
                :
                <div className="verification-container">
                    {/* {
                        uniqueNumbers.length > 0 && 
                        <div className="otp-container">
                            <h5 style={{fontSize:"16px"}}>A verification OTP will be send to this number</h5>
                            <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"24px auto 10px",width: window.innerWidth < 768 ? "95%":"70%"}}>
                                <div style={{position:"relative"}}>
                                    <select className="select-dropdown" name="" ref='phoneNumbers' value={this.state.phoneNumber} onChange = {(e) => this.setState({phoneNumber: e.target.value})}>
                                        <option value="">Phone Number</option>
                                        {Array.isArray(this.props.phoneNumbers) && uniqueNumbers.map((data, index)=><option key={index} value={data}>{data}</option>)}
                                    </select>                      
                                </div>
                                <div>
                                    <button disabled={!this.state.phoneNumber} onClick={() => this.sendOtp()} style={{backgroundColor:"#ef1c74",padding:"8px 16px", borderRadius:"4px", border:"none",color:"white",fontSize:"14px",height:"32px"}}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    } */}
                    {/* {
                        uniqueNumbers.length > 0 && !this.props.phoneNumbers.includes(this.props.phoneNumber) &&
                        <div style={{color:"rgba(0, 0, 0, 0.54)",padding:"10px 0px",fontFamily:"robotomedium",fontSize:"14px"}}>
                            AND
                        </div>
                    } */}
                    {/* {
                        !this.props.phoneNumbers.includes(this.props.phoneNumber) && 
                        <div className="document-container">
                            <h5 style={{fontSize:"16px"}}>Please upload the document for your verification</h5>
                            <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"12px auto 10px",width: window.innerWidth < 768 ? "95%":"80%"}}>
                                <div style={{position:"relative"}}>
                                    <select className="select-dropdown" name="" ref='documents' value={this.state.document} onChange = {(e) => this.setState({document: e.target.value})}>
                                        <option value="">Select Document</option>
                                        {this.acceptedDocuments.map((data, index)=><option key={index} value={data}>{data}</option>)}
                                    </select>                      
                                    {
                                        this.state.fileSelected && <p style={{fontFamily:"robotoregular",fontSize:"12px",lineHeight:"14px",color:"rgba(0, 0, 0, 0.54)",position: "absolute",left: "0%", padding:"2px 8px",border:"1px solid rgba(0, 0, 0, 0.12)",borderRadius:"8px",bottom: "-100%"}}>{this.state.fileSelected.name.slice(0,15)}<img width="12px" style={{cursor:"pointer"}} src={closeIcon} onClick={this.deleteFile}/></p>
                                    }
                                </div>
                                {
                                    this.state.document === "GST number" ? 
                                    <input className="gst-input" placeholder={"GST number"} onChange={(e) => this.setState({gstNumber: e.target.value})} pattern="[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}" ></input>
                                    :
                                    <div>
                                        <button disabled={!this.state.document} style={{backgroundColor:"#fde8f1",padding: window.innerWidth < 768 ? "8px 10px":"8px 16px", borderRadius:"4px", border:"none",color:"#ef1c74",fontSize:"14px",height:"32px"}} onClick={this.triggerUpload}>Upload</button>
                                        <input
                                            ref='fileInput' 
                                            id="file-input" 
                                            type="file" 
                                            style={{display:'none'}}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                }
                                <div>
                                    {
                                        (this.props.verifyingManually || this.props.loading_gst_mobile) ?
                                        <img src={Loader} style={{width:"20px", height:"20px"}}/>
                                        :
                                        <button disabled={!(this.state.fileSelected || this.state.gstNumber)} style={{backgroundColor: (this.state.fileSelected || this.state.gstNumber) ? "#ef1c74":"#f7f7fa",padding: window.innerWidth < 768 ? "8px 10px":"8px 16px", borderRadius:"4px",color: (this.state.fileSelected || this.state.gstNumber) ? "white":"rgba(0, 0, 0, 0.2)", border:"none",fontSize:"14px",height:"32px"}} onClick={this.verifyOwnership}>Submit</button>
                                    }
                                </div>
                            </div>
                        </div>
                    } */}
                    
                    <div className="otp-container" style={{textAlign:"center"}}>
                        <h5 style={{fontSize:"16px"}}>A verification OTP will be send to this number</h5>
                        <div style={{display: "flex", flexDirection:"row",justifyContent:"space-around", margin:"24px auto 10px",width: window.innerWidth < 768 ? "95%":"70%"}}>
                            <div style={{position:"relative"}}>
                                <select className="select-dropdown" name="" ref='claimNumbers' value={this.state.phoneNumber || this.props.phoneNumber} onChange = {(e) => this.setState({phoneNumber: e.target.value})}>
                                    {/* <option value={this.props.phoneNumber}>{this.props.phoneNumber}</option> */}
                                    {Array.isArray(claimNumbers) && claimNumbers.map((data, index)=><option key={index} value={data}>{data}</option>)}
                                </select>                      
                            </div>
                            <div>
                                <button disabled={!(this.state.phoneNumber || this.props.phoneNumber)} onClick={() => this.sendOtp()} style={{backgroundColor:"#ef1c74",padding:"8px 16px", borderRadius:"4px", border:"none",color:"white",fontSize:"14px",height:"32px"}}>Send OTP</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            }
            
        </Dialog>
    </div>
    );
    }
})