const ACTION = 'generic/ACTION';
const ACTION_SUCCESS = 'generic/ACTION_SUCCESS';
const ACTION_FAIL = 'generic/ACTION_FAIL';

const LOADING = 'generic/LOADING';
const LOADED = 'generic/LOADED';
const FAILED = 'generic/FAILED';

const intialState = {
  actionDone: false,
};

export default function notifications(state = intialState, action = {} ){
  switch(action.type){
    case ACTION:
      return {
        ...state,
        actionDoing: true,
      };
    case ACTION_SUCCESS:
      return {
        ...state,
        actionDoing: false,
        actionDone: true,
      };
    case ACTION_FAIL:
      return {
        ...state,
        actionDoing: false,
        actionDone: false,
        actionError: action.error,
      };

    case LOADING:
      return {
        ...state,
        [action.caller+'Loading']:true
      }
    case LOADED:
      return {
        ...state,
        [action.caller+'Loading']: false,
        [action.caller]: action.result,
        [action.caller+'Error']: null
      }
    case FAILED:
        return {
          ...state,
          [action.caller+'Loading']: false,
          [action.caller]: null,
          [action.caller+'Error']: action.error
        }
    default:
      return state;
  }
}

export function takeAction(accessToken, action){
  return {
    types: [ACTION, ACTION_SUCCESS, ACTION_FAIL],
    promise: (client) => client.default.post('/takeAction', {
      data: {accessToken, action}
    }),
  };
}

export function genericAction({endpoint, data, caller}){
  if(!endpoint.startsWith('/'))
    endpoint="/"+endpoint
  return {
    types:[LOADING, LOADED, FAILED],
    promise:client=>client.default.post(endpoint,{data}),
    caller
  }
}


