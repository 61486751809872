const LOAD = 'get-localities/LOAD';
const LOAD_SUCCESS = 'get-localities/LOAD_SUCCESS';
const LOAD_FAIL = 'get-localities/LOAD_FAIL';

const LOAD_LOCALITY = 'get-localities/LOAD_LOCALITY';
const LOAD_LOCALITY_SUCCESS = 'get-localities/LOAD_LOCALITY_SUCCESS';
const LOAD_LOCALITY_FAIL = 'get-localities/LOAD_LOCALITY_FAIL';

const initialState = {
  loaded: false
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_LOCALITY:
      return {
        ...state,
        loadingLocality: true,
        localityData: []
      };
    case LOAD_LOCALITY_SUCCESS:
      return {
        ...state,
        loadingLocality: false,
        loadedLocality: true,
        localityData: action.result
      };
    case LOAD_LOCALITY_FAIL:
      return {
        ...state,
        loadingLocality: false,
        loadedLocality: false,
        localityData: [],
        localityError: action.error
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.localityByCity && globalState.localityByCity.loaded;
}

export function load(city, limit, pageType = "") {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getLocalities', {
      data: {city, limit: limit ? limit * 1000 : null, pageType}
    })
  };
}

export function localityDetailed(city) {
  return {
    types: [LOAD_LOCALITY, LOAD_LOCALITY_SUCCESS, LOAD_LOCALITY_FAIL],
    promise: (client) => client.default.post('/getLocalitiesNew', {
      data: {city}
    })
  };
}
