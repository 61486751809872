import React from 'react';

export default function Rating(props){
  const rating = props.rating;
  let ratingStars = [];
  let i = 1;

  //inser full stars as per rating
  for(;i <= rating; ++i) ratingStars.push(
    <i key={i} style={{width: '14px', color:'orange'}} className="fa fa-star">
    </i>
  );

  //insert half stars if required
  if(rating != Math.round(rating)) ratingStars.push(
    <i key={i+1} style={{width: '14px', color:'orange'}} className="fa fa-star-half">
    </i>
  );

  //insert remaing empty stars
  while(ratingStars.length < 5){
    ratingStars.push(
      <i key={ratingStars.length+2} style={{width: '14px', color:'#eceef4'}} className="fa fa-star">
      </i>
    );
  }
  return (<span className="icon orange-star"> {ratingStars} </span>);
}
