import React, { Component } from 'react';
import Loader from '../Loader/Loader';
import {Line} from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './../Dashboard/ImpressionCharts.css'

export default connect(
  state => ({}), dispatch => bindActionCreators({}, dispatch))
(class ImpressionCharts extends Component{
  constructor(props){
    super(props);
  }

  getChartData = (yAxisValues=[], xAxisValues=[]) => {
    let data = {}
    let datasets = []
    let labels = xAxisValues

    datasets = [{
        label: this.props.yAxisLabel,
        fill: false,
        lineTension: 0.5,
        backgroundColor: "black",
        borderColor: "#3b74fc",
        borderWidth: 2,
        data: yAxisValues
      }]

    data = {
      ...data,
      labels,
      datasets
    }
    return data
  }

render(){
    const yAxisValues = this.props.yAxisValues;
    const xAxisValues = this.props.xAxisValues;
    let chartData = this.getChartData(yAxisValues, xAxisValues)
  return(
    <div className='comparison-wrapper' id="detailed-impressions">
      <div className="white-box" style={{ borderRadius: '8px', borderColor: '#d8dae1' }}>
          <div className="form-horizontal">
          <div style={{ display: 'inline-block', width: '100%' }}>
              

              <div className="chartWrapper impression-dis"  id="LineChart" style={{padding:'8px', marginTop: '3%'}}>
                <div style={{ position: 'relative', width: '100%',}}>
                  { this.props.loading ? 
                    <Loader/>
                    :
                    this.props.error ?
                    <div/>
                    :
                    <Line data={chartData} legend={{display: false}} options={{ maintainAspectRatio: true,
                      scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                                drawBorder: true,
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: true,
                                drawBorder: true,
                            }   
                        }]
                    }
                     }} height={window.innerWidth < 768 ? 220 : 100}/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
})