import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { otpCustomizedMsg } from '../../constants';
import { constructEventNameForBq } from '../../helpers/Helpers';
import IndiaFlag from '../../images/indiaFlag.png'
import { requestOtp, sendOtpByCall, verifyOtp } from '../../redux/modules/authReducers/auth';
import CoverImage from '../NewPromotions/CoverImage'
import './LoginViaOtp.css'

export default connect(
  state => ({
    loggingIn: state.auth.loggingIn,
    loginError: state.auth.loginError,

    requestingOtp: state.auth.requestingOtp,
    otpRequested: state.auth.otpRequested,
    otpError: state.auth.otpError
  }),
  dispatch => bindActionCreators({
    requestOtp,
    verifyOtp,
    sendOtpByCall
  }, dispatch))
  (class LoginViaOtp extends Component {

    constructor(props) {
      super(props);
      this.state = {
        showMobileError: false,
        showOtpError: false,
        sendOtpDisabled: true,
        phone: this.props.defaultPhone || '',
        errorMessage: 'Invalid Otp',
        resendOtp: false,
        showOtpDialog: false,
        countryCode: "91"
      }
      this.otpDigits = 6
      this.clock = moment("00:30", "mm:ss").format("mm:ss")
      this.eventNameConstructorObject = { pageName: 'login', useCase: 'loginviaotp' }
    }

    componentDidMount() {
      const phoneLength = this.state.phone && this.state.phone.length
      this.setState({ sendOtpDisabled: !(phoneLength === 10) })
      if(phoneLength > 0 && phoneLength != 10) {
        this.setState({
          errorMessage: "Invalid Number",
          showMobileError: true
        })
      }
    }

    componentWillUnmount() {
      if(this.timerHandler) {
        clearInterval(this.timerHandler);
      }
    }

    sendOtpByCall = () => {
      const { phone } = this.state
      this.props.sendOtpByCall(phone)
        .then(res => {
          toast.success("You will receive your otp via call shortly!");
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbycallsent')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, phone, '', '')
          this.startTimer();
        })
        .catch(err => {
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbycallrequestfailed')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', JSON.stringify(err))
          let errMsg;
          if (err && err.message) {
            errMsg = err.message
          }

          this.setState({
            errorMessage: errMsg || "Otp call request failed! Please try again in some time",
            showOtpError: true
          })
        })
    }

    loginViaOtp = () => {
      let otp = Array.from(document.querySelectorAll("#otp-input input")).map(ele => ele.value).slice(0, this.otpDigits).join("")
      if (otp.length !== this.otpDigits) {
        this.setState({
          showOtpError: true,
          errorMessage: "Invalid OTP"
        })
        return;
      }
      const { phone } = this.state;

      this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationrequested')
      this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, phone, otp, '')
      this.mobileno = this.state.countryCode + phone
      this.props.verifyOtp(this.state.countryCode + phone, otp)
        .then(res => {
          window.localStorage.setItem('phoneNumberDuringOtpLogin', phone)
          window.localStorage.setItem('timeOfLastLogin', moment().format('YYYY-MM-DD hh:mm:ss a'))
          let merchantId = ''
          if (res && res.length > 0 && res[0].id) {
            merchantId = res[0].id
            this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationsuccessful')
            this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', merchantId, phone, otp, '')
          } else if (res && res[0] && res[0].length > 0) {
            let arrayOfmerchantIds = [];
            if (res && res[0] && res[0].length > 0) {
              res[0].map(result => {
                if (result.merchant_id) {
                  arrayOfmerchantIds.push(result.merchant_id)
                }
              })
            }
            this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationsuccessful')
            this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', JSON.stringify(arrayOfmerchantIds), phone, otp, '')
          }
          this.props.sendGbAndGbqEventsLogin('login_via_mobile_no', 'login_via_mobile_no', 'login_via_mobile_no_attempt', '', this.mobileno)
        })
        .catch(err => {
          let errMsg;
          if (err && err.message) {
            errMsg = err.message
          }

          this.setState({
            errorMessage: errMsg || "Something went wrong. Please try again later",
            showOtpError: true
          })
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpverificationfailed')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, phone, otp, errMsg)
        })
    }

    requestOtp = () => {
      const { phone } = this.state;
      this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequested');
      this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, phone, '', '');

      this.props.requestOtp(this.state.countryCode + phone, otpCustomizedMsg.LOGIN_MSG, true).then(res => {
        if (res && res[0] && res[0].message) {
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequestfailed')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', res[0].message);
          this.setState({
            errorMessage: res[0].message,
            showMobileError: true
          })
        }
        else {
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmssent')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', '');
          this.setState({
            showMobileError: false,
            showOtpDialog: true
          })
          this.startTimer();
        }
      })
        .catch(err => {
          let errMsg;
          if (err && err.message) {
            errMsg = err.message
          }

          this.setState({
            errorMessage: errMsg || "Something went wrong. Please try again later",
            showMobileError: true
          })
          this.eventName = constructEventNameForBq(this.eventNameConstructorObject, 'otpbysmsrequestfailed')
          this.props.sendGbAndGbqEventsLogin(this.eventName, '', 'login', null, this.state.phone, '', errMsg)
        })
    }

    mobileNumberHandler = (event) => {
      this.setState({ phone: event.target.value }, function () {
        const isValidNumber = this.state.phone && this.state.phone.length === 10
        this.setState({ sendOtpDisabled: !isValidNumber, showMobileError: false })
      });
    }

    captureOtp = (e, idx) => {
      this.setState({ showOtpError: false })
      if(e.key === 'ArrowLeft') {
        if (idx >= 1) document.querySelectorAll("#otp-input input")[idx - 1].focus()
        return;
      }

      if(e.key === 'ArrowRight') {
        if (idx + 1 < this.otpDigits) document.querySelectorAll("#otp-input input")[idx + 1].focus()
        return;
      }

      if(!(/[0-9]/.test(e.key) || e.key === 'Backspace')) {
        e.preventDefault();
        return;
      }

      if(e.key === 'Backspace') {
        document.querySelectorAll("#otp-input input")[idx].value = "";
        if (idx >= 1) {
          document.querySelectorAll("#otp-input input")[idx - 1].focus()
        }
      }else {
        if(e.target.value) {
          if(idx + 1 < this.otpDigits) {
            idx++;
            document.querySelectorAll("#otp-input input")[idx].value = e.key;
            document.querySelectorAll("#otp-input input")[idx].focus()
          }
        } else {
          document.querySelectorAll("#otp-input input")[idx].value = e.key;
        }
        if (idx + 1 < this.otpDigits) document.querySelectorAll("#otp-input input")[idx + 1].focus()
      }
      e.preventDefault();
    }

    startTimer = () => {
      if (this.state.resendOtp) this.setState({ resendOtp: false });
      this.timerHandler = setInterval(() => {
        this.clock = moment(this.clock, 'mm:ss').subtract(1, 'second').format("mm:ss")
        if (this.clock === "00:00") {
          this.setState({ resendOtp: true })
          this.clock = moment("00:30", "mm:ss").format("mm:ss")
          clearInterval(this.timerHandler);
        }
        else {
          if (document.getElementById("resend-timer-sms")) document.getElementById("resend-timer-sms").innerHTML = `(${this.clock})`;
          if (document.getElementById("resend-timer-call")) document.getElementById("resend-timer-call").innerHTML = `(${this.clock})`;
        }
      }, 1000)
    }

    render() {
      return (
        <div style={{ textAlign: 'center' }}>
          <h2>Login to your account</h2>
          <p style={{ fontSize: 'small' }}>Need Help? Email us at  partner@magicpin.in</p>
          <div className='login-via-otp-forum-heading'>Enter registered Mobile Number</div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className='login-via-otp-country-flag'>
              <div style={{ width: '55%', paddingTop: '5px' }}><CoverImage coverImageComponent={IndiaFlag} /></div>
              <div style={{ fontSize: '16px', paddingRight: '10px' }}>+91</div>
            </div>
            <input disabled={this.state.showOtpDialog} type="number" className={this.state.showMobileError ? 'login-via-otp-mobile-input-error' : 'login-via-otp-mobile-input'} onChange={this.mobileNumberHandler} placeholder="Enter Mobile Number" defaultValue={this.props.defaultPhone} />
          </div>
          {this.state.showMobileError && <div className='login-via-otp-error-cta'>{this.state.errorMessage}</div>}
          {!this.state.showOtpDialog && <div disabled={this.state.sendOtpDisabled || this.props.requestingOtp} className={this.props.requestingOtp ? 'login-via-otp-primary-button loadingDots' : 'login-via-otp-primary-button'} onClick={this.requestOtp} >{this.props.requestingOtp ? "Sending OTP" : "Send OTP"}</div>}
          {!this.state.showOtpDialog && <div className='login-via-otp-redirect-cta' onClick={this.props.changeLoginMethod}>Try other Login Method</div>}
          
          {/* Enter OTP Section */}
          {this.state.showOtpDialog && <div>
            <div className='login-via-otp-forum-heading'>Enter OTP</div>
            <div id='otp-input' className='login-via-otp-flex-row'>
              {Array.from({ length: this.otpDigits }).map((element, idx) =>
                <input key={idx} className='login-via-otp-otp-input' onKeyDown={(e) => this.captureOtp(e, idx)} type="text" pattern="\d*" maxlength="1" />
              )}
            </div>
            {this.state.showOtpError && <div className='login-via-otp-error-cta'>{this.state.errorMessage}</div>}
            <div className='login-via-otp-flex-row' style={{ marginTop: '15px' }}>
              <div disabled={!this.state.resendOtp} onClick={this.requestOtp} className='login-via-otp-resend-timer' style={{ color: this.state.resendOtp ? '#E92675' : '#A0A0A0'}}>Resend OTP by SMS {!this.state.resendOtp && <span id="resend-timer-sms" style={{ color: '#E92675', fontSize:'20px' }}>{`(${this.clock})`}</span>}</div>
              <div disabled={!this.state.resendOtp} onClick={this.sendOtpByCall} className='login-via-otp-resend-timer' style={{ color: this.state.resendOtp ? '#E92675' : '#A0A0A0'}}>Resend OTP by Call {!this.state.resendOtp && <span id="resend-timer-call" style={{ color: '#E92675', fontSize:'20px' }}>{`(${this.clock})`}</span>}</div>
            </div>
            <div disabled={this.props.loggingIn} className={this.props.loggingIn ? 'login-via-otp-primary-button loadingDots' : 'login-via-otp-primary-button'} onClick={this.loginViaOtp} style={{ marginTop: '30px' }} >Login Now</div>
            <div className='login-via-otp-redirect-cta' onClick={this.props.changeLoginMethod}>Try other Login Method</div>
            {/* <div style={{ marginTop: '30px', fontSize: 'small' }}>Note : OTP can end up in junk folder or inside apps like Truecaller.</div> */}
          </div>}
        </div>
      )
    }
  })