const SEND_OTP = 'sendOtp/SEND_OTP';
const SEND_OTP_SUCCESS = 'sendOtp/SEND_OTP_SUCCESS';
const SEND_OTP_FAIL = 'sendOtp/SEND_OTP_FAIL';

const SEND_OTP_SMS = 'sendOtp/SEND_OTP_SMS';
const SEND_OTP_SMS_SUCCESS = 'sendOtp/SEND_OTP_SMS_SUCCESS';
const SEND_OTP_SMS_FAIL = 'sendOtp/SEND_OTP_SMS_FAIL';

const SEND_OTP_CALL = 'sendOtp/SEND_OTP_CALL';
const SEND_OTP_CALL_SUCCESS = 'sendOtp/SEND_OTP_CALL_SUCCESS';
const SEND_OTP_CALL_FAIL = 'sendOtp/SEND_OTP_CALL_FAIL';

const VERIFY_OTP = 'sendOtp/VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'sendOtp/VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAIL = 'sendOtp/VERIFY_OTP_FAIL';


const initialState = {
  error: null,
};

export default (state = initialState, action = {})=>{
  switch (action.type) {
    case SEND_OTP:
    case SEND_OTP_SMS:
    case SEND_OTP_CALL:
      return {
        ...state,
        otpSending: true,
        otpSent: false,
      };
    case SEND_OTP_SUCCESS:
    case SEND_OTP_CALL_SUCCESS:
    case SEND_OTP_SMS_SUCCESS:
      return {
        ...state,
        otpSent: true,
        otpSending: false,
        error:null
      };
    case SEND_OTP_FAIL:
    case SEND_OTP_CALL_FAIL:
    case SEND_OTP_SMS_FAIL:
      return {
        ...state,
        otpSent: false,
        otpSending: false,
        error: action.error
      };
    case VERIFY_OTP:
      return {
        ...state,
        verifyingOtp: true,
        otpVerified: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerified: true,
        verifyingOtp: false,
        verifyOtpError:null
      };
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        otpVerified: false,
        verifyingOtp: false,
        verifyOtpError: action.error
      };
   default:
      return state;
  }
}

export function sendOtp(accessToken, phone, sendSixDigitOtp = false) {
  return {
    types: [SEND_OTP, SEND_OTP_SUCCESS, SEND_OTP_FAIL],
    promise: (client) => client.default.post('/sendOtp',{
      data:{accessToken, phone, sendSixDigitOtp}
    })
  };
}

export function verifyOtp(accessToken, otp, phone) {
  return {
    types: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL],
    promise: (client) => client.default.post('/verifyOtpOnCall',{
      data:{accessToken, otp, phone}
    })
  };
}

export function sendOtpBySms(accessToken, phone, otpUseCaseIdentifier = null, additionalPayload = null, sendSixDigitOtp = false) {

  let url = '/sendOtpWithMsg'

  return {
    types: [SEND_OTP_SMS, SEND_OTP_SMS_SUCCESS, SEND_OTP_SMS_FAIL],
    promise: (client) => client.default.post(url,{
      data:{accessToken, phone, otpUseCaseIdentifier, additionalPayload, sendSixDigitOtp}
    })
  };
}

// export function sendOtpWithMsg(accessToken, phone, otpUseCaseIdentifier) {
//   return {
//     types: [SEND_OTP_SMS, SEND_OTP_SMS_SUCCESS, SEND_OTP_SMS_FAIL],
//     promise: (client) => client.default.post('/sendOtpWithMsg',{
//       data:{accessToken, phone, otpUseCaseIdentifier}
//     })
//   };
// }

export function sendOtpByCall(accessToken, phone) {
  return {
    types: [SEND_OTP_CALL, SEND_OTP_CALL_SUCCESS, SEND_OTP_CALL_FAIL],
    promise: (client) => client.default.post('/sendOtpByCall',{
      data:{accessToken, phone}
    })
  };
}
