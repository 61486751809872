const GET_GOAL_DATA = 'goalTracker/GET_GOAL_DATA';
const GET_GOAL_DATA_SUCCESS = 'goalTracker/GET_GOAL_DATA_SUCCESS';
const GET_GOAL_DATA_FAIL = 'goalTracker/GET_GOAL_DATA_FAIL';

const GET_GOAL_TRACKER_HISTORY= 'goalTracker/GET_GOAL_TRACKER_HISTORY';
const GET_GOAL_TRACKER_HISTORY_SUCCESS= 'goalTracker/GET_GOAL_TRACKER_HISTORY_SUCCESS';
const GET_GOAL_TRACKER_HISTORY_FAIL= 'goalTracker/GET_GOAL_TRACKER_HISTORY_FAIL';

const GET_EASY_REFUND_PACKAGE_LOAD= 'goalTracker/GET_EASY_REFUND_PACKAGE_LOAD';
const GET_EASY_REFUND_PACKAGE_SUCCESS= 'goalTracker/GET_EASY_REFUND_PACKAGE_SUCCESS';
const GET_EASY_REFUND_PACKAGE_FAIL= 'goalTracker/GET_EASY_REFUND_PACKAGE_FAIL';

const REQUEST_REFUND_MONEY_LOAD= 'goalTracker/REQUEST_REFUND_MONEY_LOAD';
const REQUEST_REFUND_MONEY_SUCCESS= 'goalTracker/REQUEST_REFUND_MONEY_SUCCESS';
const REQUEST_REFUND_MONEY_FAIL= 'goalTracker/REQUEST_REFUND_MONEY_FAIL';

const REDEMPTION_LOAD = 'goalTracker/REDEMPTION_LOAD';
const REDEMPTION_SUCCESS = 'goalTracker/REDEMPTION_SUCCESS';
const REDEMPTION_FAIL = 'goalTracker/REDEMPTION_FAIL';

const SET_UPI_ID_LOAD = 'goalTracker/SET_UPI_ID_LOAD'
const SET_UPI_ID_SUCCESS = 'goalTracker/SET_UPI_ID_SUCCESS'
const SET_UPI_ID_FAIL = 'goalTracker/SET_UPI_ID_FAIL'

const initialState = {};

export default function goalTracker(state = initialState, action = {}){
  switch(action.type){
    case GET_GOAL_DATA:
      return {
        ...state,
        loadingGoalData: true,
      };

    case GET_GOAL_DATA_SUCCESS:
      return {
        ...state,
        loadingGoalData: false,
        goalDataLoaded: true,
        goalData: action.result,
      };

    case GET_GOAL_DATA_FAIL:
      return {
        ...state,
        loadingGoalData: false,
        goalDataLoaded: false,
        goalDataError: action.error,
      };
    
    case GET_GOAL_TRACKER_HISTORY:
    return {
        ...state,
        loadingGoalTrackerHistory: true,
        goalAchieved: false,
    };

    case GET_GOAL_TRACKER_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGoalTrackerHistory: false,
        goalTrackerHistoryData : action.result.goalHistoryDtoList,
        goalAchieved: action.result.goalAchieved,
        isInvalid: action.result.isInvalid,
        childMerchantsCount: action.result.childMerchantsCount,
        refund: action.result.refund,
        loanRecoveryAmount: action.result.loanRecoveryAmount,
        loanRecoveryHoldAmount: action.result.loanRecoveryHoldAmount,
        isWalletDown: action.result.isWalletDown,
        goalTrackerHistoryError: false
      };

    case GET_GOAL_TRACKER_HISTORY_FAIL: 
      return{
        ...state,
        loadingGoalTrackerHistory: false,
        goalTrackerHistoryError : action.error,
        goalTrackerHistoryData :{},
        goalAchieved: false
      };
    
    case GET_EASY_REFUND_PACKAGE_LOAD:
      return{
        ...state,
        loadingEasyRefundPackage: true,
        easyRefundPackageData: {},
        easyRefundPackageError: null
      }

    case GET_EASY_REFUND_PACKAGE_SUCCESS:
      return{
        ...state,
        loadingEasyRefundPackage: false,
        easyRefundPackageData: action.result,
        easyRefundPackageError: null,
      }

    case GET_EASY_REFUND_PACKAGE_FAIL:
      return{
         ...state,
         loadingEasyRefundPackage: false,
         easyRefundPackageData: {},
         easyRefundPackageError: action.error
      }

    case REQUEST_REFUND_MONEY_LOAD:
      return{
         ...state,
         requestingRefundMoney: true,
         requestedRefundMoney: false,
         requestedRefundMoneyError: null,
      }
    
    case REQUEST_REFUND_MONEY_SUCCESS:
      return{
         requestedRefundMoney: true,
         requestingRefundMoney: false,
         requestedRefundMoneyError: null,
      }

    case REQUEST_REFUND_MONEY_FAIL:
      return{
         requestedRefundMoney: true,
         requestedRefundMoneyError: action.error,
         requestingRefundMoney: false
      }

    case REDEMPTION_LOAD:
      return{
          ...state,
          requestingMerchantRedemption: true,
          merchantRedemption: null,
          merchantRedemptionError: null,
      }
    
    case REDEMPTION_SUCCESS:
      return{
        ...state,
          requestedMerchantRedemption: true,
          requestingMerchantRedemption: false,
          merchantRedemptionError: null,
          merchantRedemption: action.result
      }

    case REDEMPTION_FAIL:
      return{
        ...state,
          requestedMerchantRedemption: false,
          requestingMerchantRedemption: false,
          merchantRedemptionError: true,
          merchantRedemption: null
      }

    case SET_UPI_ID_LOAD:
      return{
          ...state,
          registeringUpi: true,
          upiRegistred: null,
          registeringUpiError: null,
      }
    
    case SET_UPI_ID_SUCCESS:
      return{
        ...state,
        registeringUpi: false,
        upiRegistred: true,
        registeringUpiError: false,
      }

    case SET_UPI_ID_FAIL:
      return{
        ...state,
        registeringUpi: false,
        upiRegistred: false,
        registeringUpiError: true,
      }

    default:
      return state;
  }
}

export function getGoalTrackerData(accessToken){
  return {
    types: [GET_GOAL_DATA, GET_GOAL_DATA_SUCCESS, GET_GOAL_DATA_FAIL],
    promise: (client) => client.default.post('/getGoalTrackerData', {
      data: { accessToken },
    })
  };
}

export function getGoalTrackerHistory(accessToken, isOnline){
  return {
    types: [GET_GOAL_TRACKER_HISTORY, GET_GOAL_TRACKER_HISTORY_SUCCESS, GET_GOAL_TRACKER_HISTORY_FAIL],
    promise: (client) => client.default.post('/getGoalTrackerHistory', {
      data: { accessToken, isOnline },
    })
  };
}

export function getEasyRefundPackageInfo(accessToken){
  return {
    types: [GET_EASY_REFUND_PACKAGE_LOAD, GET_EASY_REFUND_PACKAGE_SUCCESS, GET_EASY_REFUND_PACKAGE_FAIL],
    promise: (client) => client.default.post('/getEasyRefundPackageInfo', {
      data: { accessToken },
    })
  };
}

export function requestRefundMoney(accessToken, moveTo){
  return {
    types: [REQUEST_REFUND_MONEY_LOAD, REQUEST_REFUND_MONEY_SUCCESS, REQUEST_REFUND_MONEY_FAIL],
    promise: (client) => client.default.post('/requestRefundMoney', {
      data: { accessToken, moveTo },
    })
  };
}

export function getMerchantRedemption(accessToken, startDate, endDate){
  return {
    types: [REDEMPTION_LOAD, REDEMPTION_SUCCESS, REDEMPTION_FAIL],
    promise: (client) => client.default.post('/getMerchantRedemption', {
      data: { accessToken, startDate, endDate },
    })
  };
}

export function setUpiId(accessToken, upiID){
  return {
    types: [SET_UPI_ID_LOAD, SET_UPI_ID_SUCCESS, SET_UPI_ID_FAIL],
    promise: (client) => client.default.post('/setUpiId', {
      data: {accessToken, upiID},
    })
  };
}

