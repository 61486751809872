import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux'
import config from '../../config'
import {sendEvent, currencyString, createMCPLog} from '../../helpers/Helpers'
import { getMerchantPosts } from '../../redux/modules/newMerchantLanding';
import ReactHtmlParser from 'react-html-parser';
import { extractHashtag } from '../../helpers/Helpers';
import Slider from 'react-slick/dist/react-slick';
import defaultProfile from '../../images/profile-default.png'
import star from "../../images/star-white.png";
import language from './languageConst.js';

const ga = window.ga;
const BANNED_NAMES = ['na']

class CustomerPosts extends Component {

  constructor(props){
    super(props);
    this.state = {
    };
    this.postsShown = false;
    this.fallbackPostsIds = {
      1:{
        "new delhi":["440678865","440793811","440805857","440853198","440902393","440903639"],
        "gurgaon":["441593427","441636892","441730209","441815455","441823266","441910469"],
        "mumbai":["441600606","443116109","443269792","444393898","445315501","445617129"],
        "hyderabad":["443191746","443929164","444091163","446314257","446946025","449960710"],
        "bangalore":["440876226","441293217","441368841","441700623","442648055","443256373"],
        "pune":["443303335","445560772","445850906","445860245","446411616","446779206"],
        "lucknow":["500321391","500327712","508781710","510082651","514109081","514112837"]
      },
      2:{
        "new delhi":["449820098","458846269","473178957","473195787","473247140","473467170"],
        "gurgaon":["460336329","463973238","466099568","468534065","479159009","491975364"],
        "mumbai":["441897464","473532762","479127637", "403215130","436443773","403556189","484933894","458133428","468207669"],
        "hyderabad":["475468211", "474756494","477685258","477841142","477847069","477849943","477892414"],
        "bangalore":["474756494","477685258","477841142","477847069","477849943","477892414"],
        "pune":["456399121","457444128","492551327", "456399121","457444128","393607500","394234023","462067081","399133129"],
        "lucknow":["449820098","458846269","473178957","473195787","473247140","473467170"],
      },
      3:{
        "new delhi":["442531928","442586007","442643003","442826969","442939314","443271434"],
        "gurgaon":["442514430","443122009","444404328","447150024","449004670","449146511"],
        "mumbai":["444365297","445977866","448079888","451415328","451546233","452580719"],
        "hyderabad":["445005790","447311389","457940774","466808168","475856196","477930364"],
        "bangalore":["445550888","449297090","450227300","450547483","451021246","451157403"],
        "pune":["447480140","447725139","448796581","449658600","449802621","450732786"],
        "lucknow":["396787110","473269274","494789323","508326047","435312781","452200119"],
      },
      5:{
        "new delhi":["446641176","447136258","448093199","448104126","449283312","450336600"],
        "gurgaon":["446637219","447498960","451997655","452060354","452950663","458146098"],
        "mumbai":["457488775","457593159","458205637","459828411","464283321","466832221"],
        "hyderabad":["444011930","446519130","446610062","454587726","454654007","462848366"],
        "bangalore":["452505030","452948747","454274657","455773855","457251950","458112703"],
        "pune":["454697054","456397250","461362908","464612180","467305465","472960250"],
        "lucknow":["423438675","463704471","431517147","433633674","454483734","513965411"],
      }
    }
    this.indonesiaPosts={
      1: ["437962954","470834284","490190813","470868223","500571993","437962954"],
      3: ["399085194","462136853","485044830","512405714"],
      5: ["408170220","408922702","422027465","407294031","415282214","421007982","431712056","463644364"],
      2: ["399085194","462136853","485044830","512405714"]
    }
    this.posts = [];
    this.randomizePosts = false;
  }


  getRandomAmount = (amount) => {
    if(amount > 100)
      return amount
    return 200 + Math.random() * (300);
  }

  getDefaultPostIds = () => {
    let postIds = []
    let categoryId = this.props.categoryId && [1,2,3,5].includes(this.props.categoryId) ? this.props.categoryId : 1;

    if (this.props.country && this.props.country.toLowerCase() == 'indonesia'){
      postIds = this.indonesiaPosts[categoryId]
    }
    else{
      let city = this.props.city && ["new delhi", "gurgaon","hyderabad","mumbai", "bangalore", "pune", "lucknow"].includes(this.props.city.toLowerCase()) ? this.props.city.toLowerCase() : "gurgaon"
      postIds = this.fallbackPostsIds[categoryId][city]
    }
    return postIds
  }

  getRemainingPosts = (currentPosts) => {
    let posts = currentPosts ? currentPosts : []
    let defaultPostIds = this.getDefaultPostIds()
    this.props.getMerchantPosts(null, defaultPostIds).then(() => {
      if(this.props.fallbackPosts){
        Object.keys(this.props.fallbackPosts).map(postId => {
          posts.push(this.props.fallbackPosts[postId])
        })
        this.setState({
          posts: posts,
          postsLength:3,
          button_text:language[this.props.language].buttonCta4
        })
      }
    })
  }

  afterPosts = () => {
    let finalPosts = []
    if(this.props.merchantPosts && Array.isArray(this.props.merchantPosts)){
      finalPosts = this.props.merchantPosts.filter((curr_post)=>((curr_post.visibility === 'PUBLIC' || curr_post.visibility === 'DEFAULT') && !!curr_post.selfie_url))
      if (finalPosts.length < 6)
        this.getRemainingPosts(finalPosts)
      else{
        this.setState({
          posts: finalPosts,
          postsLength:3,
          button_text: language[this.props.language].buttonCta4
        })
      }
    }
    else{
      this.getRemainingPosts([])
    }
  }

  getPosts(merchantId){
    this.props.getMerchantPosts(merchantId).then(() => {
      this.afterPosts()
      
    }
    ).catch(() => {
      this.getRemainingPosts([])
    })
  }

  componentDidMount(){
    this.getPosts(this.props.merchantId)
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.merchantId != nextProps.merchantId){
      this.getPosts(nextProps.merchantId)
      this.postsShown = false
      this.randomizePosts = true;
    }
    if(this.props.categoryId != nextProps.categoryId){
      this.getPosts(nextProps.merchantId)
      this.postsShown = false
      this.randomizePosts = true;
    }
    if(this.props.city != nextProps.city){
      this.getPosts(nextProps.merchantId)
      this.postsShown = false
      this.randomizePosts = true;
    }
    if(this.props.country != nextProps.country){
      this.getPosts(nextProps.merchantId)
      this.postsShown = false
      this.randomizePosts = true;
    }
    if(nextProps.language && nextProps.language != this.props.language){
      this.setState({
        button_text: language[nextProps.language].buttonCta4
      })
    }
  }

  handleViewMore() {
    this.randomizePosts = false;
    if(this.postsShown) {
      this.props.showDialog();
    }
    else {
      this.setState({
        postsLength: 6,
        button_text:language[this.props.language].buttonCta1
      },() => {
        this.postsShown = true
      });
    }
  }

  render() {
    const languageConst = this.props.language ? language[this.props.language] : language["english"];
    let country = this.props.country? this.props.country : "india"
    const settings = this.props.search ? {
      dots: true,  
      accessibility: true,
      infinite: false,
      arrows: false,
      draggable: true,
      swipeToSlide: true,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0px',
      dotsClass: 'slick-dots'
    }
    :
    {
      dots: true,  
      accessibility: true,
      infinite: false,
      arrows: false,
      draggable: true,
      swipeToSlide: true,
      variableWidth: false,
      centerMode: false,
      centerPadding: '0px',
      dotsClass: 'slick-dots'
    };

    let postClass = `post ${this.props.search ? "" : "post-new"}`;
    this.posts = this.state.posts || [];
    return(
      <div className="posts-holder" style={{}}>
        { (this.state.posts && this.state.posts.length >= 3) &&
          (
            <section>
              { this.posts.map((post, index) => {
                  if ( post && index < this.state.postsLength)
                  return(
                    <div className="post" key={index}>
                    <div className="user-details">
                      <div className="user-image-container">
                        <img src={post.author_profile.profile_pic_url.length ? post.author_profile.profile_pic_url : defaultProfile } className="user-image"/>
                      </div>
                      <div className="user-details-text">
                        <div className="user">
                          <span className="user-name">{BANNED_NAMES.includes(post.author_profile.first_name.toLowerCase()) ? 'User' : post.author_profile.first_name.length ? post.author_profile.first_name : "Magicpin User"}</span>
                          <h6 style={{display:"inline", marginLeft:"5px", color:"#9cadb5"}}>{post.author_profile.num_followers} {languageConst.followers}</h6>
                        </div>
                        {!!post.author_profile.num_likes && !!post.author_profile.num_followers &&
                          <h6>{post.author_profile.num_posts} {languageConst.posts}, {post.author_profile.num_likes} {languageConst.likes}</h6>
                        }
                      </div>
                    </div>
                    
                    
                    <div className="post-image-container">
                    { post.selfie_url &&
                    <div>
                      <img className="post-image" src={post.selfie_url} style={{objectFit:'cover'}} />
                    </div>
                    }
                    </div>
                    <div className="post-text" style={{marginTop:"4px", height:post.caption.length ? "20px":"0px"}}>
                    {ReactHtmlParser(post.caption,{transform:extractHashtag})}
                    </div>
                    
                    
                    { post.merchant_profile &&
                    <div className="merchant">
                      <div className="merchant-logo-container">
                        <img src={post.merchant_profile.logo_url} className="merchant-logo" />
                      </div>
                      <div className="merchant-details" style={{height:"30px"}}>
                        <h4 className="merchant-name">{post.merchant_profile.name}</h4>
                        <h5>{post.merchant_profile.locality}, {post.merchant_profile.city}</h5>
                      </div>
                      <div style={{display:"block", fontSize:"12px", marginTop:"2%"}}>
                      <span style={{marginTop:"4px",marginRight:"6px"}}>{languageConst.spent}: {currencyString(this.getRandomAmount(post.bill_amount), country.toLowerCase(), 0)}.</span>
                      <span>{languageConst.ratedBy} {BANNED_NAMES.includes(post.author_profile.first_name.toLowerCase()) ? 'User' : post.author_profile.first_name.length ? post.author_profile.first_name.split(" ")[0].slice(0,8) : "User"} : </span>
                      <div className="merchant-rating">
                          <span className="star-icon">
                            <img src={star} />
                          </span>{parseInt(post.rating || post.selfie_rating) <= 0 ? 4 :parseInt(post.rating || post.selfie_rating) }
                        
                      </div>
                      </div>                                        
                    </div>
                    }
                    {/* <div className="post-icons">
                      <span className="post-like fa fa-heart">
                      </span>
                      <span className="icon-values">{post.likes}</span>
                      <span className="post-comment">
                        <img className="comment-icon" src={config.resourcepath + "/images/comment.png"} alt=""/>
                      </span>
                      <span className="icon-values">{post.comments}</span>
                      <span className="post-share fa fa-share">
                      </span>
                    </div> */}
                  </div>
                  )
                })
              }
            </section>)
          
          }
          { this.props.showMore &&
          <a className="start-button button-header-hover" style={{ marginTop: "25px"}}
            onClick={() => {
              this.handleViewMore(); 
              ga('send', 'event', 'Logout_page','View_More_Posts','Start_Customers');
              sendEvent({
                utm_campaign:localStorage.getItem('landingSource') || '',
                subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
                event: 'view_more_posts',
                page_type:'new_merchant_landing',
                utm_source: this.props.utm_source
              })
              createMCPLog({
                phone: localStorage.getItem("subjectPhoneNumber") || '',
                utm_campaign: localStorage.getItem('landingSource') || '',
                merchant_id: localStorage.getItem('landingMerchantId') || '',
                event: 'view_more_posts'
              });
            }}>{window.innerWidth < 768 ? languageConst.buttonCta1 :this.state.button_text}</a>
          }
      </div>
    )
  }
}

export default connect((store) => ({
  posts: store.newMerchantLanding.posts,
  merchantPosts: store.newMerchantLanding.merchantPosts,
  fallbackPosts: store.newMerchantLanding.fallbackPosts
}), {
  getMerchantPosts:getMerchantPosts
})(CustomerPosts)
