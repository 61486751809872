const PG_REDIRECTION = 'paymentGatway/PG_REDIRECTION';
const PG_REDIRECTION_SUCCESS = 'paymentGatway/PG_REDIRECTION_SUCCESS';
const PG_REDIRECTION_FAIL = 'paymentGatway/PG_REDIRECTION_FAIL';

const intialState = {};

export default function paymentGatway(state = intialState, action = {} ){
	switch(action.type){
	  case PG_REDIRECTION:
        return {
            ...state,
            pgRedirection: true
        }
      case PG_REDIRECTION_SUCCESS:
          return {
            ...state,
            pgRedirection: false
          }
      case PG_REDIRECTION_FAIL:
          return {
            ...state,
            pgRedirection: false
          }
      default:
          return state;
	}
}

export function getPGredirectionUrl(pgPayload){
    return{
      types:[PG_REDIRECTION, PG_REDIRECTION_SUCCESS, PG_REDIRECTION_FAIL],
      promise:(client) => client.default.post('/getPGredirectionUrl',{
        data:{pgPayload}
      })
    }
  }