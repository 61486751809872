import { states } from "../../../constants";

const LOAD = 'runningOffers/LOAD';
const LOAD_SUCCESS = 'runningOffers/LOAD_SUCCESS';
const LOAD_FAIL = 'runningOffers/LOAD_FAIL';

const STOCK_OUT_VOUCHERS = 'runningOffers/STOCK_OUT_VOUCHERS';
const STOCK_OUT_VOUCHERS_SUCCESS = 'runningOffers/STOCK_OUT_VOUCHERS_SUCCESS';
const STOCK_OUT_VOUCHERS_FAIL = 'runningOffers/STOCK_OUT_VOUCHERS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
  offers: [],
  vouchers: [],
  stockOutVouchers: []
};

export default function runningOffers(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        offers: action.result.other_deals_vouchers_list ? action.result.other_deals_vouchers_list.other_deals_vouchers_list : state.offers,
        vouchers: action.result.vouchers ? action.result.vouchers : state.vouchers,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case STOCK_OUT_VOUCHERS:
      return {
        ...state
      }
    case STOCK_OUT_VOUCHERS_SUCCESS:
      return {
        ...state,
        stockOutVouchers: action.result
      }
    case STOCK_OUT_VOUCHERS_FAIL:
      return {
        ...state,
        stockOutVoucherError: action.error
      }
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.runningOffers && globalState.runningOffers.loaded;
}

export function load(accessToken) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getRunningOffers', {
      data: {accessToken}
    })
  };
}

export function getStockOutVouchers(accessToken) {
  return {
    types: [STOCK_OUT_VOUCHERS, STOCK_OUT_VOUCHERS_SUCCESS, STOCK_OUT_VOUCHERS_FAIL],
    promise: (client) => client.default.post('/getStockOutVouchers', {
      data: {accessToken}
    })
  }
}
