import { toast } from "react-toastify";
import { getCurrentEmailId, isValidPhoneNumber } from "../../helpers/Helpers";

import { purifyTimings, sortTimings } from "../../merchant-app-portal/Helpers/EditStoreTimings";

const LOAD_PROFILE = 'profile/LOAD_PROFILE';
const LOAD_PROFILE_SUCCESS = 'profile/LOAD_PROFILE_SUCCESS';
const LOAD_PROFILE_FAIL = 'profile/LOAD_PROFILE_FAIL';

const UPLOAD = 'profile/UPLOAD';
const UPLOAD_SUCCESS = 'profile/UPLOAD_SUCCESS';
const UPLOAD_FAIL = 'profile/UPLOAD_FAIL';

const UPLOAD_VIDEO = 'profile/UPLOAD_VIDEO';
const UPLOAD_VIDEO_SUCCESS = 'profile/UPLOAD_VIDEO_SUCCESS';
const UPLOAD_VIDEO_FAIL = 'profile/UPLOAD_VIDEO_FAIL';

const SAVE = 'profile/SAVE';
const SAVE_SUCCESS = 'profile/SAVE_SUCCESS';
const SAVE_FAIL = 'profile/SAVE_FAIL';

const REQUEST_EDIT = 'profile/REQUEST_EDIT';
const REQUEST_EDIT_SUCCESS = 'profile/REQUEST_EDIT_SUCCESS';
const REQUEST_EDIT_FAIL = 'profile/REQUEST_EDIT_FAIL';

const DELETE_PHOTO = 'profile/DELETE_PHOTO';
const DELETE_PHOTO_SUCCESS = 'profile/DELETE_PHOTO_SUCCESS';
const DELETE_PHOTO_FAIL = 'profile/DELETE_PHOTO_FAIL';

const LOAD_COMPETITORS = 'profile/LOAD_COMPETITORS';
const LOAD_COMPETITORS_SUCCESS = 'profile/LOAD_COMPETITORS_SUCCESS';
const LOAD_COMPETITORS_FAIL = 'profile/LOAD_COMPETITORS_FAIL';

const UPDATE_COMPETITORS = 'profile/UPDATE_COMPETITORS';
const UPDATE_COMPETITORS_SUCCESS = 'profile/UPDATE_COMPETITORS_SUCCESS';
const UPDATE_COMPETITORS_FAIL = 'profile/UPDATE_COMPETITORS_FAIL';

const LOAD_COMPANY_GROUPS = 'profile/LOAD_COMPANY_GROUPS';
const LOAD_COMPANY_GROUPS_SUCCESS = 'profile/LOAD_COMPANY_GROUPS_SUCCESS';
const LOAD_COMPANY_GROUPS_FAIL = 'profile/LOAD_COMPANY_GROUPS_FAIL';

const CREATE_EVENT_LOADING = 'profile/CREATE_EVENT_LOADING';
const CREATE_EVENT_SUCCESS = 'profile/CREATE_EVENT_SUCCESS';
const CREATE_EVENT_FAIL = 'profile/CREATE_EVENT_FAIL';

const UPDATE_EVENT_LOADING = 'profile/UPDATE_EVENT_LOADING';
const UPDATE_EVENT_SUCCESS = 'profile/UPDATE_EVENT_SUCCESS';
const UPDATE_EVENT_FAIL = 'profile/UPDATE_EVENT_FAIL';

const ADD_EVENT_MEDIA = 'profile/ADD_EVENT_MEDIA';
const ADD_EVENT_MEDIA_SUCCESS = 'profile/ADD_EVENT_MEDIA_SUCCESS';
const ADD_EVENT_MEDIA_FAIL = 'profile/ADD_EVENT_MEDIA_FAIL';

const getCurrentEmail = getCurrentEmailId() || ""

const initialState = {
  loaded: false,
  uploaded: false,
  uploadCount: 0,
  totalUploadCount: 0,
  saved: false,
  requested: false,
  profileChanged: false,
  timingChanged: false,
  valueChanged: [],
};

const emptyTiming = [
  { "open_time": "00:00:00",
    "close_time": "00:00:00",
    "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
    "day": "MONDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "TUESDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "WEDNESDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "THURSDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "FRIDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "SATURDAY" },
    { "open_time": "00:00:00",
      "close_time": "00:00:00",
      "time_slots": [{ "closetime": "00:00:00", "opentime": "00:00:00" }],
      "day": "SUNDAY" }
  ];

export default function profile(state = initialState, action = {}){
  switch (action.type) {
    case LOAD_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PROFILE_SUCCESS:
      action.result.purifiedTimings = purifyTimings(action.result.timings)
      action.result.timings = sortTimings(action.result.timings)
      return {
        ...state,
        loading: false,
        loaded: true,
        profile: action.result,
        initialProfile: action.result,
        profileChanged: false,
        timingChanged: false,
        orignalLocality: action.result && action.result.locality || ""
      };
    case LOAD_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case UPLOAD:
      return {
        ...state,
        uploading: true,
        uploadCount: 0,
        totalUploadCount: action.totalUploadCount,
        uploadError: null,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploaded: true,
        uploadCount: state.uploadCount + 1,
        uploadError: null,
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        uploading: false,
        uploaded: false,
        uploadCount: 0,
        uploadError: action.error,
      };

    case UPLOAD_VIDEO:
      return {
        ...state,
        uploadingVideo: true,
      };
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadingVideo: false,
        uploadedVideo: true
      };
    case UPLOAD_VIDEO_FAIL:
      return {
        ...state,
        uploadingVideo: false,
        uploadedVideo: false,
        uploadVideoError: action.error,
      };
    case DELETE_PHOTO:
      return {
        ...state,
        deletingFile: true,
      };
    case DELETE_PHOTO_SUCCESS:
      return {
        ...state,
        deletingFile: false,
        deletedFile: true,
      };
    case DELETE_PHOTO_FAIL:
      return {
        ...state,
        deletingFile: false,
        deletedFile: false,
        deletedError: action.error,
      };
    case SAVE:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        timingChanged: false,
        profileChanged: false,
      };
    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        saveError: action.error,
      };
    case REQUEST_EDIT:
      return {
        ...state,
        requesting: true,
      };
    case REQUEST_EDIT_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
      };
    case REQUEST_EDIT_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
      };
    case 'ADD_KNOWNFOR':
      var subType = state.profile.must_haves.filter(x => x.type == action.subtype).length ? state.profile.must_haves.filter(x => x.type == action.subtype)[0].description : ""
      var newSubtype = subType == "" ? action.value : subType + ',' +action.value;
      var must_haves = state.profile.must_haves;
      if(must_haves.filter(x => x.type == action.subtype).length) 
        must_haves.filter(x => x.type == action.subtype)[0].description = newSubtype
      else 
        must_haves.push({
          title: action.subtype,
          description: newSubtype
        })
      return {
        ...state,
        profileChanged: true,
        profile: {...state.profile, must_haves}
      };
    case 'REMOVE_KNOWNFOR':
      var subType = state.profile.must_haves.filter(x => x.type == action.subtype).length ? state.profile.must_haves.filter(x => x.type == action.subtype)[0].description : ""
      var subTypeArr = subType.split(',')
      var newSubArr = subTypeArr.filter(x => x != action.value)
      var must_haves = state.profile.must_haves;
      var newSubtype = newSubArr.join(',')
      if(must_haves.filter(x => x.type == action.subtype).length) 
        must_haves.filter(x => x.type == action.subtype)[0].description = newSubtype
      return {
        ...state,
        profileChanged: true,
        profile: {...state.profile, must_haves}
      };
    case 'ADD_SUBCATEGORY':
      var newCategories = {...state.profile.sub_categories.categories, [action.subCategory]: true};
      return {
        ...state,
        profileChanged: true,
        profile: {...state.profile, sub_categories: {...state.profile.sub_categories, categories: newCategories} }
      }
    case 'REMOVE_SUBCATEGORY':
      var newCategories = {...state.profile.sub_categories.categories, [action.subCategory]: false};
      return {
        ...state,
        profileChanged: true,
        profile: {...state.profile, sub_categories: {...state.profile.sub_categories, categories: newCategories} }
      }
    case 'TOGGLE_HIGHLIGHT':
      var newHightlights = {...state.profile.highlights,
        [action.highlight]: !state.profile.highlights[action.highlight]};
      return {
        ...state,
        profileChanged: true,
        profile: {...state.profile, highlights: newHightlights }
      }
    case 'CHANGE_PHONE':
    let newPhones;
    if(action.isDelete){
      if(state.profile.phone_numbers && state.profile.phone_numbers.length == 1){
        toast.error("You cannot delete all numbers")
        return state;
      }
      let deletedNumber = state.profile.phone_numbers.splice(action.index, 1)

      if(deletedNumber && deletedNumber.length && deletedNumber[0] && state.profile.store_numbers && state.profile.store_numbers[action.index] && action.country === "INDIA" && !isValidPhoneNumber(deletedNumber[0], true, "INDIA").isValid) 
      {
        let deleteNotValidNumber = state.profile.store_numbers.splice(action.index, 1)
      }
      else if(state.profile.store_numbers && state.profile.store_numbers[action.index]) 
        state.profile.store_numbers[action.index].shadowed = true

      newPhones = state.profile.phone_numbers;
    }
    else if(!state.profile.phone_numbers || (!state.profile.phone_numbers[action.index] && !["", " "].includes(state.profile.phone_numbers[action.index])) ){
      newPhones = state.profile.phone_numbers;
      if(!newPhones)
        newPhones = [];
      newPhones.push(action.phone)
      state.profile.store_numbers.push({mobileNumber: action.phone, newAddition: true})

    }
    else if(state.profile.store_numbers[action.index]){
      state.profile.store_numbers[action.index].mobileNumber = action.phone
      state.profile.store_numbers[action.index].modified = true
    }
      return {
        ...state,
        profileChanged: true,
        profile: {
          ...state.profile,
          phone_numbers: newPhones ? newPhones : (!state.profile.phone_numbers || state.profile.phone_numbers.length == 0) ? [action.phone] :
            state.profile.phone_numbers.map(
            (phone, index) => index == action.index ? action.phone : phone)
        }
      }
    case 'CHANGE_ADDRESS':

      // if((!action.address || action.address == '') && !state.allowEmpty){
      //   toast.info("Address cannot be empty")
      //   return state
      // }
      if(action.address.length > 160){
        toast.info("Max. length for address - 160 characters ")
        return state
      }
      
      let newAddress = action.address.replace(/\b(sexy)\b|\b(hot)\b|\b(naughty)\b|\b(beautiful)\b|\b(darling)\b|\b(Shit)\b|\b(Chuck)\b|\b(Fucking)\b|\b(paytm)\b|\b(crownit)\b|\b(fuckers)\b|\b(pretty)\b|\b(babe)\b|\b(love)\b|\b(moti)\b|\b(Kutiya)\b|\b(bhosdi)\b|\b(Tharak)\b|\b(gorgeous)\b|\b(Bra)\b|\b(eyes)\b|\b(Asshole)\b|\b(bosdk)\b|\b(baby)\b|\b(patakha)\b|\b(lick)\b|\b(lover)\b|\b(jaanu)\b|\b(Aunty)\b|\b(contact)\b|\b(sucker)\b|\b(call)\b|\b(Madar)\b|\b(Bhopdi)\b|\b(boob)\b|\b(boobs)\b|\b(fuck)\b|\b(suck)\b|\b(penis)\b|\b(vagina)\b|\b(pussy)\b|\b(cock)\b|\b(sex)\b|\b(sleep)\b/gi , "" );
      if(action.latlng){
        return {
          ...state,
          profileChanged: true,
          profile: {
            ...state.profile,
            merchant: {...state.profile.merchant, address: newAddress},
            lat: action.latlng.lat,
            lon: action.latlng.lng,
            address: {...state.profile.address, lat: action.latlng.lat, lon: action.latlng.lng}
          }
        }
      }
      return {
        ...state,
        profileChanged: true,
        profile: {
          ...state.profile,
          merchant: {...state.profile.merchant, address: newAddress}
        }
      }
    case 'SET_TIMINGS':
      const timingToMap = state.profile.timings && state.profile.timings.length != 0 ? state.profile.timings : emptyTiming;
      return {
        ...state,
        timingChanged: true,
        profile: {
          ...state.profile,
          timings: timingToMap.map((timing, index) => {
            // check if timing to be set for this day
            if(timing.day && action.days[timing.day]){
              const newtiming = {...timing, time_slots: action.slots};
              return newtiming;
            }
            else return timing;
          })
        }
      }
    case 'VALUE_CHANGED':
      if(state.valueChanged.indexOf(action.key) == -1){
        let newState = {...state};
        newState.valueChanged.push(action.key);
        return newState;
      }
      else return state;
    case 'VALUE_DISCARDED':
      let newState = {...state};
      newState.valueChanged = [];
      return newState; 
    case 'CHANGE_NAME':
      if(!action.name || action.name == ''){
        toast.info("Outlet name cannot be empty")
        return state
      }
      return {
        ...state,
        profileChanged: true,
        profile: {
          ...state.profile,
          merchant: {
            ...state.profile.merchant,
            name: action.name
          }
        }
      }

    case 'CHANGE_WEBSITE':
        if(!action.website || action.website == ''){
          toast.info("Website name cannot be empty")
          return state
        }
        return {
          ...state,
          profileChanged: true,
          profile: {
            ...state.profile,
            merchant: {
              ...state.profile.merchant
            },
            webSite: action.website
          }
        }

    case 'CHANGE_OUTLET_INFO':

      // if(!action.info || action.info == ''){
      //   toast.info("About cannot be empty")
      //   return state
      // }
      let newInfo = action.info.replace(/\b(sexy)\b|\b(hot)\b|\b(naughty)\b|\b(beautiful)\b|\b(darling)\b|\b(Shit)\b|\b(Chuck)\b|\b(Fucking)\b|\b(paytm)\b|\b(crownit)\b|\b(fuckers)\b|\b(pretty)\b|\b(babe)\b|\b(love)\b|\b(moti)\b|\b(Kutiya)\b|\b(bhosdi)\b|\b(Tharak)\b|\b(gorgeous)\b|\b(Bra)\b|\b(eyes)\b|\b(Asshole)\b|\b(bosdk)\b|\b(baby)\b|\b(patakha)\b|\b(lick)\b|\b(lover)\b|\b(jaanu)\b|\b(Aunty)\b|\b(contact)\b|\b(sucker)\b|\b(call)\b|\b(Madar)\b|\b(Bhopdi)\b|\b(boob)\b|\b(boobs)\b|\b(fuck)\b|\b(suck)\b|\b(penis)\b|\b(vagina)\b|\b(pussy)\b|\b(cock)\b|\b(sex)\b|\b(sleep)\b/gi , "" );
      return {
        ...state,
        profileChanged: true,
        profile: {
          ...state.profile,
          merchant_description: newInfo,
          merchant: {
            ...state.profile.merchant,
            description: newInfo
          }
        }
      }

    case LOAD_COMPETITORS:
      return {
        ...state,
        loadingCompetitors: true,
        loadedCompetitors: false,
      };
    case LOAD_COMPETITORS_SUCCESS:
      return {
        ...state,
        loadingCompetitors: false,
        loadedCompetitors: true,
        competitors: action.result,
      };
    case LOAD_COMPETITORS_FAIL:
      return {
        ...state,
        loadingCompetitors: false,
        loadedCompetitors: false,
        loadCompetitorsError: action.error,
      };

    case UPDATE_COMPETITORS:
      return {
        ...state,
        updatingCompetitors: true,
        updatedCompetitors: false,
      };
    case UPDATE_COMPETITORS_SUCCESS:
      return {
        ...state,
        updatingCompetitors: false,
        updatedCompetitors: true,
        updateCompetitorsResponse: action.result,
      };
    case UPDATE_COMPETITORS_FAIL:
      return {
        ...state,
        updatingCompetitors: false,
        updatedCompetitors: false,
        updateCompetitorsError: action.error,
      };
      case CREATE_EVENT_LOADING:
        return {
          ...state,
          createEventLoading: true,
          createdEvent:false
        };
      case CREATE_EVENT_SUCCESS:
        return {
          ...state,
          createEventLoading: false,
          createdEvent:true,
          createEventData: action.result,
          createEventError: null
        };
      case CREATE_EVENT_FAIL:
        return {
          ...state,
          createEventLoading: false,
          createdEvent: false,
          createEventData: null,
          createEventError: action.error
        };
        case UPDATE_EVENT_LOADING:
          return {
            ...state,
            updateEventLoading: true
          };
        case UPDATE_EVENT_SUCCESS:
          return {
            ...state,
            updateEventLoading: false,
            updateEventData: action.result,
            updateEventError: null
          };
        case UPDATE_EVENT_FAIL:
          return {
            ...state,
            updateEventLoading: false,
            updateEventData: null,
            updateEventError: action.error
          };
          case ADD_EVENT_MEDIA:
            return {
              ...state,
              addingEventMedia: true,
            };
          case ADD_EVENT_MEDIA_SUCCESS:
            return {
              ...state,
              addingEventMedia: false,
              addedEventMedia: true
            };
          case ADD_EVENT_MEDIA_FAIL:
            return {
              ...state,
              addingEventMedia: false,
              addedEventMedia: false,
              addEventMediaError: action.error,
            };

    case LOAD_COMPANY_GROUPS:
      return {
        ...state,
        loadingCompanyGroups: true,
        loadedCompanyGroups: false,
      };
    case LOAD_COMPANY_GROUPS_SUCCESS:
      return {
        ...state,
        loadingCompanyGroups: false,
        loadedCompanyGroups: true,
        companyGroupsResponse: action.result,
      };
    case LOAD_COMPANY_GROUPS_FAIL:
      return {
        ...state,
        loadingCompanyGroups: false,
        loadedCompanyGroups: false,
        companyGroupsError: action.error,
      };

    case 'CHANGE_LOCALITY':
      if(action.latlng){
        const merchantRequestedChange =  state.orignalLocality ? state.orignalLocality === action.locality ? false : !action.isCommissionMerchant : !action.isCommissionMerchant
        return {
          ...state,
          profileChanged: true,
          allowEmpty: true,
          profile: {
            ...state.profile,
            merchant: {...state.profile.merchant, locality: action.locality, merchantRequestedChange: merchantRequestedChange, locality_id: action.localityId},
            address: {...state.profile.address, lat: action.latlng.lat, lon: action.latlng.lng, locality: action.locality},
            locality: action.locality,
            lat: action.latlng.lat,
            lon: action.latlng.lng,
            merchantRequestedChange: merchantRequestedChange
          }
        }
      }

      

      return state

    default:
      return state;
  }
}

export function loadProfile(accessToken, merchantUserId = 0, ultronToken, salt, merchantId = 0){
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': ultronToken
  }
  return {
    types: [LOAD_PROFILE, LOAD_PROFILE_SUCCESS, LOAD_PROFILE_FAIL],
    promise: (client) => client.default.get(`https://webapi.magicpin.in/ultron-mp/merchant/${merchantUserId}/profile?token=${salt}&merchantId=${merchantId}`, {
      data: {accessToken},
      headers: headers
    })
  };
}

export function upload(accessToken, file, imageGroup, filename,idToken){
  var data = new FormData();

  data.append('merchant_single_photo', file, file.name ? file.name : filename);
  data.append('imageGroup', imageGroup);
  data.append('accessToken', accessToken);
  data.append('idToken', idToken);
  data.append('updatedBy', getCurrentEmail);

  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    promise: (client) => client.default.post('/uploadFile',{
      data
    }),
    totalUploadCount: 1,
  };
}


export function uploadVideo(accessToken, file, imageGroup, filename){
  var data = new FormData();
  data.append('merchant_single_photo', file);
  data.append('imageGroup', imageGroup);
  data.append('accessToken', accessToken);
  return {
    types: [UPLOAD_VIDEO, UPLOAD_VIDEO_SUCCESS, UPLOAD_VIDEO_FAIL],
    promise: (client) => client.default.post('/uploadVideoRetail',{
      data
    }),
    totalUploadCount: 1,
  };
}

export function uploadMultiple(accessToken, files, imageGroup, progressTracker){
  // var data = new FormData();
  // for(let i = 0; i < files.length; ++i)
  //   data.append('merchant_multiple_photos', files[i], files[i].name);
  // data.append('imageGroup', imageGroup);
  // data.append('accessToken', accessToken);
  // return {
  //   types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
  //   promise: (client) => client.default.post('/uploadMultipleFiles',{
  //     data: data,
  //   })
  // };

  // ** using single file upload api multiple times
  const count = files.length;
  let promise = [];
  for(let i = 0; i < count; ++i){
    let data = new FormData();
    data.append('merchant_single_photo', files[i], files[i].name);
    data.append('imageGroup', imageGroup);
    data.append('accessToken', accessToken);
    data.append('updatedBy', getCurrentEmail);

    promise.push((client) => client.default.post('/uploadFile', {data}));
  }

  return {
    types: [UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAIL],
    repeat: count,
    promise,
    totalUploadCount: count,
  };
}

export function deleteFile(accessToken, imageId, idToken){
  return {
    types: [DELETE_PHOTO, DELETE_PHOTO_SUCCESS, DELETE_PHOTO_FAIL],
    promise: (client) => client.default.post('/deleteFile', {
      data: {accessToken, imageId, idToken}
    }),
    imageId,
  };
}

export function saveProfile({accessToken, profile, profileChanged, timingChanged, valuesChanged, idToken, isBrand}){
  
  let profileObj = profile;
  if(profileObj && profileObj.webSite && profileObj.merchant && isBrand){
    profileObj.merchant.webSite = profileObj.webSite;
  }
  delete profileObj.webSite;

  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) => client.default.post('/saveMerchantProfile', {
      data: {accessToken, profile: profileObj, profileChanged, timingChanged, valuesChanged, idToken, updatedBy: getCurrentEmail}
    })
  };
}
export function requestEdit(accessToken, name, description){
  return {
    types: [REQUEST_EDIT, REQUEST_EDIT_SUCCESS, REQUEST_EDIT_FAIL],
    promise: (client) => client.default.post('/requestEdit', {
      data: {accessToken, name, description}
    }),
    name,
    description,
  };
}

export function addKnownFor(value, subtype){
  return {
    type: 'ADD_KNOWNFOR',
    value,
    subtype,
  };
}
export function removeKnownFor(value, subtype){
  return {
    type: 'REMOVE_KNOWNFOR',
    value,
    subtype
  };
}
export function addSubCategory(subCategory){
  return {
    type: 'ADD_SUBCATEGORY',
    subCategory,
  }
}
export function removeSubCategory(subCategory){
  return {
    type: 'REMOVE_SUBCATEGORY',
    subCategory,
  }
}
export function toggleHighlight(highlight){
  return {
    type: 'TOGGLE_HIGHLIGHT',
    highlight,
  }
}
export function changePhone(index, phone, isDelete, country = "INDIA"){
  return {
    type: 'CHANGE_PHONE',
    index, phone, isDelete, country
  }
}
export function changeAddress(address, latlng){
  return {
    type: 'CHANGE_ADDRESS',
    address,
    latlng
  }
}
export function setTimings(days, slots){
  return {
    type: 'SET_TIMINGS',
    days,
    slots,
  }
}
export function onValueChanged(key){
  return {
    type: 'VALUE_CHANGED',
    key,
  }
}
export function onValueDiscard(){
  return {
    type: 'VALUE_DISCARDED',
  }
}
export function changeName(name){
  return {
    type: 'CHANGE_NAME',
    name,
  }
}

export function changeWebSite(website){
  return {
    type: 'CHANGE_WEBSITE',
    website,
  }
}

export function changeOutletInfo(info){
  return {
    type: 'CHANGE_OUTLET_INFO',
    info,
  }
}

export function changeLocalityFn(locality, latlng, localityId, isCommissionMerchant){
  return {
    type: 'CHANGE_LOCALITY',
    locality,
    latlng,
    localityId,
    isCommissionMerchant
  }
}

export function getCompetitors(salt, userId, merchantId){
  const headers = {
    'auth-userid': userId,
    'auth-user-token': salt
  }
  return {
    types: [LOAD_COMPETITORS, LOAD_COMPETITORS_SUCCESS, LOAD_COMPETITORS_FAIL],
    promise: (client) => client.default.get(`https://webapi.magicpin.in/ultron-mp/retail/getCompetitors/${merchantId}`, {
      headers: headers
    })
  };
}

export function getCompanyGroups(salt, userId, categoryId, query){
  const headers = {
    'auth-userid': userId,
    'auth-user-token': salt
  }
  return {
    types: [LOAD_COMPANY_GROUPS, LOAD_COMPANY_GROUPS_SUCCESS, LOAD_COMPANY_GROUPS_FAIL],
    promise: (client) => client.default.get(`https://webapi.magicpin.in/ultron-mp/retail/getCompanyGroups?q=${query}&categoryId=${categoryId}`, {
      headers: headers
    })
  };
}

export function updateCompetitors(salt, userId, parentId, shadow, companyGroupId){
  const headers = {
    'auth-userid': userId,
    'auth-user-token': salt
  }
  return {
    types: [UPDATE_COMPETITORS, UPDATE_COMPETITORS_SUCCESS, UPDATE_COMPETITORS_FAIL],
    promise: (client) => client.default.post(`https://webapi.magicpin.in/ultron-mp/retail/updateCompetitors`, {
      data: { parentId: parseInt(parentId), companyGroupId: parseInt(companyGroupId), shadow },
      headers: headers
    })
  };
}

export function createEvent( access_token, payload, headers={}) {
  return {
    types: [
      CREATE_EVENT_LOADING,
      CREATE_EVENT_SUCCESS,
      CREATE_EVENT_FAIL,
    ],
    promise: (client) =>
        client.ultron_mp.post("merchant/createEvent", {

        headers: {
          ...headers,
          "auth-user-token": access_token,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function updateEvent(access_token, payload, headers={}) {
  return {
    types: [
      UPDATE_EVENT_LOADING,
      UPDATE_EVENT_SUCCESS,
      UPDATE_EVENT_FAIL,
    ],
    promise: (client) =>
        client.ultron_mp.post("merchant/updateEvent", {

        headers: {
          ...headers,
          "auth-user-token": access_token,
          "Content-Type": "application/json",
        },
        data: payload,
      }),
  };
}

export function addEventMedia(accessToken, file, eventId, imageGroup, filename, updatedBy){
  var data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : filename);
  data.append('imageGroup', imageGroup);
  data.append('eventId', eventId); 
  data.append('accessToken', accessToken);
  data.append('updatedBy',updatedBy)

  return {
    types: [ADD_EVENT_MEDIA, ADD_EVENT_MEDIA_SUCCESS, ADD_EVENT_MEDIA_FAIL],
    promise: (client) => client.default.post('/addEventMedia',{
      data
    }),
  };
}