const LOAD = 'ratingReviewAll/LOAD';
const LOAD_SUCCESS = 'ratingReviewAll/LOAD_SUCCESS';
const LOAD_FAIL = 'ratingReviewAll/LOAD_FAIL';

const initialState = {
    ratingReviewAllLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {...state, ratingReviewAllLoading: true}

    case LOAD_SUCCESS:
      return {
        ...state,
        ratingReviewAllLoading: false,
        ratingReviewAllLoaded: true,
        ratingReviewAllError: null,
        ratingReviewAllData: action.result}

    case LOAD_FAIL:
      return {
        ...state,
        ratingReviewAllLoading: false,
        ratingReviewAllLoaded: false,
        ratingReviewAllData: null,
        ratingReviewAllError: action.error} 

   default:
      return state;
  }
}

export function getRatingReviewAll(accessToken, startDate, endDate) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post(`/getRatingReviewAll?start_date=${startDate}&end_date=${endDate}`, {
      data: {accessToken}
    })
  };
}
