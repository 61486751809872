import React from 'react'
import moment from 'moment';
import DatePicker from 'material-ui/DatePicker';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import '../DateSelector/DateSelector.css';

const ga = window.ga;
export default class DateSelector extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selected:  'last 30 days',
            tempSelected:'last 30 days',
            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: moment().format("YYYY-MM-DD"),
            tempStartDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            tempEndDate: moment().format("YYYY-MM-DD"),
            modalOpen: false,
            anchorEl: null,
        }
    }

    componentDidUpdate(prevProps, prevState){
      if(prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || prevProps.packageStartDate !==this.props.packageStartDate){
        this.setState({startDate: this.props.startDate, endDate: this.props.endDate, tempStartDate: this.props.startDate, tempEndDate: this.props.endDate, packageStartDate: this.props.packageStartDate})
      }
    }

    componentDidMount() {
      if(this.props.showPackageStart) {
        this.setState({
          selected: 'current subscription',
          tempSelected: 'current subscription',
          packageStartDate: this.props.packageStartDate
        })
      }
    }

    onDropDownChange = value=>{
      switch(value){
        case 'last 30 days':
          ga('event', this.props.page, 'change date', this.props.caller, 'last 30 days')
          this.setState({tempStartDate:moment().subtract(30, 'days').format("YYYY-MM-DD"), tempEndDate:  moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
        case 'last 60 days':
          ga('event', this.props.page, 'change date', this.props.caller, 'last 60 days')
          this.setState({tempStartDate:moment().subtract(60, 'days').format("YYYY-MM-DD"), tempEndDate: moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
        case 'last 90 days':
          ga('event', this.props.page, 'change date', this.props.caller, 'last 90 days')
          this.setState({tempStartDate:moment().subtract(90, 'days').format("YYYY-MM-DD"), tempEndDate: moment().format("YYYY-MM-DD"), tempSelected: value})
          break
        case 'custom':
          ga('event', this.props.page, 'change date', this.props.caller, 'custom')
          this.setState({tempSelected:'custom'})
        case 'current subscription':
          ga('event', this.props.page, 'change date', this.props.caller, 'current subscription')
          this.setState({tempStartDate:moment(this.state.packageStartDate).format("YYYY-MM-DD"), tempEndDate: moment().format("YYYY-MM-DD"), tempSelected: value})
      }
    }

    handleClick=event=>{
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
      this.setState({ tempStartDate: this.state.startDate, tempEndDate: this.state.endDate, tempSelected: this.state.selected, anchorEl: null });
    };

    apply = e=>{
      this.setState({startDate: this.state.tempStartDate, endDate: this.state.tempEndDate, selected: this.state.tempSelected})
      this.props.onChange(this.state.tempStartDate, this.state.tempEndDate)
      this.setState({anchorEl: null})
    }

    displayDates=(startDate, endDate)=>{
      startDate = moment(startDate, "YYYY-MM-DD")
      endDate = moment(endDate, "YYYY-MM-DD")
      let finalFormat = ""
      if(startDate.format("YYYY") !== endDate.format("YYYY")) finalFormat=" YY"
      if(finalFormat){
        finalFormat="D MMM"+finalFormat
        return startDate.format(finalFormat)+' - '+endDate.format(finalFormat)
      }
      return startDate.format("D MMM")+' - '+endDate.format("D MMM")
    }

    render(){
      let datepickerStyle={
        width: '80px'        
      }

      let isMagicOrderGraph=false;
      let isOrderIoGraph =false;
      if(this.props.isMagicOrderGraph){
        isMagicOrderGraph=true
      }
      if(this.props.isOrderIoGraph){
        isOrderIoGraph=true
      }
      const { anchorEl } = this.state;
      let isCategoryBannerInsightGraph = this.props.isCategoryBannerInsightGraph;
        return(
                <Paper>
                  <Button
                            aria-owns={anchorEl ? this.elementId : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            style={{fontSize:'12px'}}
                          >
                            {this.state.selected !== 'custom' ? this.state.selected : this.displayDates(this.state.startDate, this.state.endDate)}
                            <span className="fa fa-chevron-down" style={{margin:'-4px 0 0 5px'}}></span>
                  </Button>
                  <Menu
                    id={this.elementId}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    disableEnforceFocus
                  >
                    { (!isMagicOrderGraph && !isOrderIoGraph && !isCategoryBannerInsightGraph) &&
                      <MenuItem onClick={()=>this.onDropDownChange('current subscription')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px'}}
                      selected={this.state.tempSelected === 'current subscription'}

                    >
                      Current Subscription
                    </MenuItem>
                    }


                    <MenuItem onClick={()=>this.onDropDownChange('last 30 days')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px'}}
                      selected={this.state.tempSelected === 'last 30 days'}

                    >
                      Last 30 days
                    </MenuItem>

                    <MenuItem onClick={()=>this.onDropDownChange('last 60 days')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px'}}
                      selected={this.state.tempSelected === 'last 60 days'}

                    >
                      Last 60 days
                    </MenuItem>

                    <MenuItem onClick={() => this.onDropDownChange('last 90 days')}
                     style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px'}}
                      selected={this.state.tempSelected === 'last 90 days'}
                    >
                    Last 90 days
                    </MenuItem>
                    { !isMagicOrderGraph && !isCategoryBannerInsightGraph &&
                    <MenuItem component="ul" selected={this.state.tempSelected === 'custom'}>
                      <MenuItem>
                        <DatePicker 
                          value={moment(this.state.tempStartDate, "YYYY-MM-DD").toDate()}
                          autoOk={true}
                          style={datepickerStyle}
                          onChange={(e, date)=>this.setState({tempSelected:'custom',tempStartDate: moment(date).format("YYYY-MM-DD")})}
                          name='startDate'
                          minDate={this.props.minDate ? moment(this.props.minDate,'YYYY-MM-DD').toDate() : null}
                          maxDate={moment(this.state.tempEndDate, "YYYY-MM-DD").toDate()}
                          formatDate={(date) => moment(date).format('DD MMM, YY')}/>
                      </MenuItem>
                      <MenuItem>
                        <DatePicker
                          autoOk={true} 
                          style={{...datepickerStyle, margin:'0 0 0 5px'}}
                          value={moment(this.state.tempEndDate, "YYYY-MM-DD").toDate()}
                          className='datepicker'
                          name='endDate'
                          maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : moment().toDate()}
                          minDate={moment(this.state.tempStartDate, "YYYY-MM-DD").toDate()}
                          onChange={(e, date)=>this.setState({tempSelected:'custom',tempEndDate: moment(date).format("YYYY-MM-DD")})}
                          formatDate={(date) => moment(date).format('DD MMM, YY')}/>
                      </MenuItem>
                    </MenuItem>
                     }
                    <MenuItem
                    onClick = {this.apply}
                    style={{fontSize:'16px', backgroundColor:'#458eff', color:'white', justifyContent:'center', width:'calc( 100% - 50px)', borderRadius:'8px', margin: isCategoryBannerInsightGraph ? '2% auto' : '0 auto'}}
                    >
                      APPLY
                    </MenuItem>
                  </Menu>
                </Paper>
            )
    }

}