import { getCurrentEmailId } from "../../../helpers/Helpers";

const GET_CASH_VOUCHERS = 'addPauseVoucher/GET_CASH_VOUCHERS';
const GET_CASH_VOUCHERS_SUCCESS = 'addPauseVoucher/GET_CASH_VOUCHERS_SUCCESS';
const GET_CASH_VOUCHERS_FAIL = 'addPauseVoucher/GET_CASH_VOUCHERS_FAIL';
const REQUEST_CASH_VOUCHER = 'addPauseVoucher/REQUEST_CASH_VOUCHER';
const REQUEST_CASH_VOUCHER_SUCCESS = 'addPauseVoucher/REQUEST_CASH_VOUCHER_SUCCESS';
const REQUEST_CASH_VOUCHER_FAIL = 'addPauseVoucher/REQUEST_CASH_VOUCHER_FAIL';
const PAUSE_CASH_VOUCHER = 'addPauseVoucher/PAUSE_CASH_VOUCHER';
const PAUSE_CASH_VOUCHER_SUCCESS = 'addPauseVoucher/PAUSE_CASH_VOUCHER_SUCCESS';
const PAUSE_CASH_VOUCHER_FAIL = 'addPauseVoucher/PAUSE_CASH_VOUCHER_FAIL';
const GET_MARKETING_REDEMPTION_STATUS = 'addPauseVoucher/GET_MARKETING_REDEMPTION_STATUS';
const GET_MARKETING_REDEMPTION_STATUS_SUCCESS = 'addPauseVoucher/GET_MARKETING_REDEMPTION_STATUS_SUCCESS';
const GET_MARKETING_REDEMPTION_STATUS_FAIL = 'addPauseVoucher/GET_MARKETING_REDEMPTION_STATUS_FAIL';
const UPDATE_MERCHANT_SUBSCRIPTION = 'addPauseVoucher/UPDATE_MERCHANT_SUBSCRIPTION';
const UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS = 'addPauseVoucher/UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS';
const UPDATE_MERCHANT_SUBSCRIPTION_FAIL = 'addPauseVoucher/UPDATE_MERCHANT_SUBSCRIPTION_FAIL';

const getCurrentEmail = getCurrentEmailId() || ""

const initialState = {
  loaded: false,
  addRequested: false,
  pauseRequested: false,
};

export default function cashVouchers(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CASH_VOUCHERS:
      return {
        ...state,
        loading: true
      };
    case GET_CASH_VOUCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cashVouchers: action.result
      };
    case GET_CASH_VOUCHERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case REQUEST_CASH_VOUCHER:
      return {
        ...state,
        addRequesting: true
      };
    case REQUEST_CASH_VOUCHER_SUCCESS:
      return {
        ...state,
        addRequesting: false,
        addRequested: true
      };
    case REQUEST_CASH_VOUCHER_FAIL:
      return {
        ...state,
        addRequesting: false,
        addRequested: false,
        addError: action.error
      };
    case PAUSE_CASH_VOUCHER:
      return {
        ...state,
        pauseRequesting: true
      };
    case PAUSE_CASH_VOUCHER_SUCCESS:
      return {
        ...state,
        pauseRequesting: false,
        pauseRequested: true,
      };
    case PAUSE_CASH_VOUCHER_FAIL:
      return {
        ...state,
        pauseRequesting: false,
        pauseRequested: false,
        pauseError: action.error
      };
    case GET_MARKETING_REDEMPTION_STATUS:
      return {
        ...state,
        gettingStatus: true,
        marketingRedemptionStatusData: null
      };
    case GET_MARKETING_REDEMPTION_STATUS_SUCCESS:
      return {
        ...state,
        gettingStatus: false,
        marketingRedemptionStatusData: action.result,
        remarketingStatus: action.result.marketingRedemptionOptionStatus,
      };
    case GET_MARKETING_REDEMPTION_STATUS_FAIL:
      return {
        ...state,
        gettingStatus: false,
        marketingRedemptionStatusData: null,
        gettingStatus: action.error
      };
    case UPDATE_MERCHANT_SUBSCRIPTION:
      return {
        ...state,
        updatingStatus: true
      };
    case UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updatingStatus: false,
        updatedStatus: true
      };
    case UPDATE_MERCHANT_SUBSCRIPTION_FAIL:
      return {
        ...state,
        updatingStatus: false,
        updatedStatus: false,
      };
    default:
      return state;
  }
}

export function getCashVouchers(accessToken) {
  const action = {
    url: 'https://webapi.magicpin.in/merchants/merchantUserId/vouchers/cash/',
    method: 'get',
    headerAuth: true,
  };
  return {
    types: [GET_CASH_VOUCHERS, GET_CASH_VOUCHERS_SUCCESS, GET_CASH_VOUCHERS_FAIL],
    promise: (client) => client.default.post('/takeAction/getCashVouchers', {
      data: {accessToken, action}
    })
  };
}

export function requestVoucher(accessToken, merchantName, voucherAmount, category, commission){
  return {
    types: [REQUEST_CASH_VOUCHER, REQUEST_CASH_VOUCHER_SUCCESS, REQUEST_CASH_VOUCHER_FAIL],
    promise: (client) => client.default.post('/requestVoucher',{
      data:{accessToken, merchantName, voucherAmount, category, commission}
    })
  };
}

export function pauseVoucher(accessToken,merchantName,voucherRoaId, reason, detail='', message=''){
  return {
    types: [PAUSE_CASH_VOUCHER, PAUSE_CASH_VOUCHER_SUCCESS, PAUSE_CASH_VOUCHER_FAIL],
    promise: (client) => client.default.post('/pauseVoucher',{
      data:{accessToken,merchantName,voucherRoaId, reason, detail, message}
    })
  }
}

export function getMarketingRedemptionStatus(accessToken){
  return {
    types: [GET_MARKETING_REDEMPTION_STATUS, GET_MARKETING_REDEMPTION_STATUS_SUCCESS, GET_MARKETING_REDEMPTION_STATUS_FAIL],
    promise: (client) => client.default.post('/getMarketingRedemptionStatus',{
      data:{accessToken}
    })
  }
}

export function updateMerchantSubscription(accessToken, unshadow, reason = null, updatedBy = null, refundPurchasedVouchers = false ){
  return {
    types: [UPDATE_MERCHANT_SUBSCRIPTION, UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS, UPDATE_MERCHANT_SUBSCRIPTION_FAIL],
    promise: (client) => client.default.post('/updateMerchantSubscription',{
      data:{accessToken, unshadow, reason, updatedBy: (updatedBy || getCurrentEmail), refundPurchasedVouchers}
    })
  }
}
