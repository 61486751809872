import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import maxTriesIcon from '../../images/home-delivery-otp-max-tries-exceeded.svg';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { verifyOrder } from '../../redux/modules/newMerchantLanding';
import IconButton from '@material-ui/core/IconButton';
import './DeliveryItemList.css';

import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: "10px",
    width: window.innerWidth > 768 ? "324px" : "250px"
  },
  closeButton: {
    position: 'absolute',
    right: "5px",
    top: "5px",
    color: "gray",
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "16px",
    textAlign: "center"
  },
}))(MuiDialogContent);


export default connect(
    store => ({
        verifyOtpLoading: store.newMerchantLanding.verifyOtpLoading,
        verifyOtpResponseData: store.newMerchantLanding.verifyOtpResponseData,
        verifyOtpError: store.newMerchantLanding.verifyOtpError
    }),dispatch=>bindActionCreators({
        verifyOrder
    }, dispatch))
    (class OtpVerificationScreen extends Component {
    
    state = {
        backSpace: false,
        otpEntered: false,
        otp: "",
        otpVerified: false,
        otpVerificationFailed: false,
        otpVerificationFailureMessage: "",
        otpRetry: 3,
        showCustomerCarePhoneNo: false
    }

    cancelOrderAndClosePopup = (order) => {
        this.props.cancelOrder(order)
        this.props.closePopUp()
    }

    verifyOtp = () => {
        this.props.verifyOrder(this.props.user.accessToken, this.props.orderData.orderId, this.state.otp)
    }

    componentDidUpdate(prevProps) {
        if((prevProps.verifyOtpResponseData !== this.props.verifyOtpResponseData )) {
          if(this.props.verifyOtpResponseData) {
            if(this.props.verifyOtpResponseData.status == "SUCCESS") {
                this.setState({ otpVerified: true, otpVerificationFailed: false, otpVerificationFailureMessage: "", otpRetry: this.state.otpRetry - 1 })
            } else {
                this.setState({ otpVerified: false, otpVerificationFailed: true, otpVerificationFailureMessage: this.props.verifyOtpResponseData.message, otpRetry: this.state.otpRetry - 1 })
            }
          }
      }
    }

    captureOtp = (e, idx) => {
            if(e.target.value){
                idx <= 2 && document.querySelectorAll("#otp-1")[idx+1].focus()
                console.log(document.querySelectorAll("#otp-1")[2].value == "")
                // if(idx+1 === 4) {
                let field1 = document.querySelectorAll("#otp-1")[0].value
                let field2 = document.querySelectorAll("#otp-1")[1].value
                let field3 = document.querySelectorAll("#otp-1")[2].value
                let field4 = document.querySelectorAll("#otp-1")[3].value
                if(field1 != "" && field2 != "" && field3 != "" && field4 != "" && field4.length == 1) {
                    let otpByMerchant;
                    // otpByMerchant = document.querySelectorAll("#otp-1")[0].value + document.querySelectorAll("#otp-1")[1].value + document.querySelectorAll("#otp-1")[2].value + document.querySelectorAll("#otp-1")[3].value
                    otpByMerchant = field1 + field2 + field3 + field4
                    if(otpByMerchant.length == 4) {
                        this.setState({otpEntered:true, otp: otpByMerchant})
                    }
                }
              }
              else{
                  if(idx >= 1 ){
                    if(this.state.backSpace){
                      this.setState({backSpace:false});
                      document.querySelectorAll("#otp-1")[idx-1].focus()
                    }
                    else{
                      this.setState({backSpace:true})
                    }
                  }
                  this.setState({otpEntered: false})
              }
            e.preventDefault();
    }

render () {
    if(this.state.otpVerified && !this.state.otpVerificationFailed) {
        toast.success("Otp verified successfully!");
        this.props.closePopUp();
    }
    let retryCountCheck = (this.props.orderData.retryCount == 0 || this.state.otpRetry == 0)
    console.log(retryCountCheck)
    // console.log(this.props.orderData)
    // console.log(this.props.verifyOtpResponseData)
  return (
      <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
            {/* Improve rank
            <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
        </DialogTitle>
        
        <DialogContent dividers>
            <div style={{ fontSize: '16px', fontFamily: 'robotoregular', paddingBottom: '8%'}}>
                <div>Verify delivery agent's OTP</div>
            </div>
            {!this.state.otpVerified && !this.state.otpVerificationFailed && this.props.orderData.retryCount > 0 && this.state.otpRetry > 0
                 && <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%', marginBottom: '10%'}}>
                <div className="inputs">
                  {Array.from({length: 4}).map((ele, idx)=>
                    <input className="otp-input-box" id="otp-1" key={idx} required pattern="[0-9]" onKeyUp={(e)=>this.captureOtp(e, idx)} type="number" maxLength="1" size="1"/>
                  )}
                </div>
            </div>}

            {!this.state.otpVerified && this.state.otpVerificationFailed && this.props.orderData.retryCount > 0 && this.state.otpRetry > 0
                &&
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%', marginBottom: '10%'}}>
                        <div className="inputs">
                        {Array.from({length: 4}).map((ele, idx)=>
                            <input style={{border: '1px solid #ff455f'}} className="otp-input-box" id="otp-1" key={idx} required pattern="[0-9]" onKeyUp={(e)=>this.captureOtp(e, idx)} type="number" maxLength="1" size="1"/>
                        )}
                        </div>
                    </div>
                    <div>{this.state.otpVerificationFailureMessage}</div>
                </div>
            }
            {retryCountCheck && <img style={{paddingBottom: '16px'}} src={maxTriesIcon}></img>}
            {retryCountCheck &&
                <div style={{fontFamily: 'robotoregular', fontSize: '16px'}}>
                    <div style={{marginBottom: '2%'}}>Maximum retries exceeded!</div>
                    {window.innerWidth > 768 && <div>Please call customer care to<br/>verify delivery agent</div>}
                    {window.innerWidth <= 768 && <div>Please call customer care to verify delivery agent</div>}
                </div>
            }

            {!this.state.otpEntered && this.props.orderData.retryCount > 0 && this.state.otpRetry > 0 && <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <a style={{color: 'rgba(0, 0, 0, 0.2)', backgroundColor: '#f7f7fa'}} className="otp-verify-btn">
                  Verify
                </a>
              </div>
            }
            {this.state.otpEntered && this.props.orderData.retryCount > 0 && this.state.otpRetry > 0 && <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <a style={{color: 'white'}} className="otp-verify-btn" onClick={() => this.verifyOtp()}>
                  Verify
                </a>
              </div>
            }
            {/* {retryCountCheck
              && window.innerWidth <= 768 &&
              <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <a href={`tel:+91-7510175101`} style={{color: 'white'}} className="otp-verify-btn">
                  Call customer care
                </a>
              </div>
            } */}
            {retryCountCheck
              && window.innerWidth > 768 && !this.state.showCustomerCarePhoneNo &&
              <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <a style={{color: 'white'}} className="otp-verify-btn" onClick={() => this.setState({ showCustomerCarePhoneNo: !this.state.showCustomerCarePhoneNo})}>
                  Call customer care
                </a>
              </div>
            }
            {/* {retryCountCheck
              && window.innerWidth > 768 && this.state.showCustomerCarePhoneNo &&
              <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <a style={{color: 'rgba(0,0,0,0.54)', fontSize: '14px', fontFamily: 'robotomedium', background: '#fff'}} className="otp-verify-btn" onClick={() => this.setState({ showCustomerCarePhoneNo: !this.state.showCustomerCarePhoneNo})}>
                  Customer care contact: +91-7510175101
                </a>
              </div>
            } */}
        </DialogContent>
      </Dialog>
  );
          }
})
