import moment from "moment";
import _ from "lodash";

const NEXT_DAY_CONFLICT = 'next-day-conflict';
const SAME_DAY_CONFLICT = 'same-day-conflict';

export const TIME_PREFS_CUSTOM = "custom";
export const TIME_PREFS_DEFAULT = "default";
export const TIME_LIST_VERSION_TODAY = "today";
export const TIME_LIST_VERSION_TOMORROW = "tomorrow";

export function sortTimings(timings) {
  if (!Array.isArray(timings)) {
    return [];
  }

  let weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
  let sortedTimings = []

  weekDays.forEach(day => {
    let found = timings.find(timing => {
      return timing.day && typeof timing.day === "string" && timing.day.toUpperCase() === day
    });
    if(!!found) {
      sortedTimings.push(found)
    }
  });

  return sortedTimings
}

export function purifyTimings(timings) {
  // this function considers a day as closed if a time_slots key is invalid or no info is available for that day
  if (!Array.isArray(timings)) {
    timings = [];
  }
  
  let weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
  let closedDay = [{ "opentime": "00:00:00", "closetime": "00:00:00" }]

  let purifiedTimings = weekDays.map(day => {
    let found = timings.find(timing => {
      return timing.day && typeof timing.day === "string" && timing.day.toUpperCase() === day
    });
    if (!!found) {
      let timeSlots = found.time_slots
      if(!timeSlots || !Array.isArray(timeSlots) || (Array.isArray(timeSlots) && timeSlots.length === 0)) {
        found.time_slots = closedDay;
      }
      return found;
    } else {
      return { "day": day, "time_slots": closedDay }
    }
  });

  return purifiedTimings;
}

export function setTimingsData(timingsArr) {
  return JSON.parse(JSON.stringify(timingsArr.map((timing) => {
    let tempTimingData = {...timing}
    if (timing.time_slots.length > 1) {
      tempTimingData = {
        ...tempTimingData,
        isOpenFullDay: false,
        isClosedFullDay: false,
      }
      return tempTimingData;
    } else if (timing.time_slots.length === 1) {
      let storeTimings = timing.time_slots[0]
      if (storeTimings.closetime === '00:00:00' && storeTimings.opentime === '00:00:00') {
        tempTimingData = {
          ...tempTimingData,
          isOpenFullDay: false,
          isClosedFullDay: true
        }
        return tempTimingData;
      }
      if (storeTimings.closetime === "23:59:59" && storeTimings.opentime === "00:00:00") {
        tempTimingData = {
          ...tempTimingData,
          isOpenFullDay: true,
          isClosedFullDay: false
        }
        return tempTimingData;
      }
      tempTimingData = {
        ...tempTimingData,
        isOpenFullDay: false,
        isClosedFullDay: false
      }
      return tempTimingData;
    }
  })));
}

export function setStoreOpenAllDay(day, value, timingsListCopy) {
  return timingsListCopy.map((dayData) => {
    let tempData = {...dayData};
    if (dayData.day === day) {
      if (value) {
        tempData = {
          ...tempData,
          time_slots: [{opentime: '00:00:00', closetime: '23:59:59'}],
          isOpenFullDay: value,
          isClosedFullDay: !value
        }
      } else {
        tempData = {
          ...tempData,
          isOpenFullDay: value,
        }
      }
      return tempData;
    }
    return tempData;
  })
}

export function setStoreClosedAllDay(day, value, timingsListCopy) {
  return timingsListCopy.map((dayData) => {
    let tempData = {...dayData};
    if (dayData.day === day) {
      if (value) {
        tempData = {
          ...tempData,
          time_slots: [{opentime: '00:00:00', closetime: '00:00:00'}],
          isOpenFullDay: !value,
          isClosedFullDay: value
        }
      } else {
        tempData = {
          ...tempData,
          isClosedFullDay: value
        }
      }
      return tempData;
    }
    return tempData;
  })
}

export function addNewTimingsRowToDay(day, timingsListCopy) {
  return timingsListCopy.map((dayData) => {
    let tempData = {...dayData};
    let timingsObj = {
      opentime: '',
      closetime: ''
    }
    if (dayData.day === day) {
      tempData.time_slots.push(timingsObj)
      tempData = {
        ...tempData,
        isOpenFullDay: false,
        isClosedFullDay: false
      }
    }
    return tempData;
  })
}

export function popTheLastTimingsRowFromDay(day, index, timingsListCopy) {
  return timingsListCopy.map((dayData) => {
    let tempData = {...dayData};
    if (dayData.day === day) {
      if (index === 0) {
        tempData.time_slots.shift();
      } else {
        tempData.time_slots.pop();
      }
    }
    return tempData;
  })
}

export function setNewTimeForCell(time, day, indexOfRow, isOpeningTime, timingsListCopy) {
  return timingsListCopy.map((dayData) => {
    let tempData = {...dayData}
    if (dayData.day === day) {
      if (isOpeningTime) {
        tempData.time_slots[indexOfRow].opentime = time;
      } else {
        tempData.time_slots[indexOfRow].closetime = time;
      }
      if (tempData.time_slots.length > 1) {
        if (!(tempData.time_slots[0].opentime === '' || tempData.time_slots[1].opentime === '')) {
          tempData.time_slots.sort((timeSegment1, timeSegment2) =>
            timeSegment1["opentime"].localeCompare(timeSegment2["opentime"])
          );
        }
      }
      tempData = {
        ...tempData,
        isOpenFullDay: false,
        isClosedFullDay: false
      }
    }
    return tempData;
  })
}

export function checkOverlapOfTimings(currentTimeSegments, nextTimeSegments) {
  currentTimeSegments = _.cloneDeep(currentTimeSegments);
  nextTimeSegments = _.cloneDeep(nextTimeSegments);

  let start1 = moment(currentTimeSegments[0].opentime, 'HH:mm:ss');
  let end1 = moment(currentTimeSegments[0].closetime, 'HH:mm:ss');
  let nextStart = moment(nextTimeSegments[0].opentime, 'HH:mm:ss');
  let isNextDayClosed = false;
  if (
    (nextTimeSegments[0].opentime === '00:00:00' && nextTimeSegments[0].closetime === '00:00:00') ||
    (nextTimeSegments[0].opentime === '' || nextTimeSegments[0].closetime === '')
  ) {
    isNextDayClosed = true;
  }

  if (currentTimeSegments.length === 1) {
    if (end1.isSame(start1)) {
      return {
        isOverlapping: true,
        reason: SAME_DAY_CONFLICT
      }
    }
    if (end1.isBefore(start1)) {
      if (end1.isBefore(nextStart) || isNextDayClosed) {
        return {
          isOverlapping: false,
        };
      } else {
        return {
          isOverlapping: true,
          reason: NEXT_DAY_CONFLICT
        };
      }
    } else {
      return {
        isOverlapping: false,
      };
    }
  }

  if (_.isEqual(currentTimeSegments[0], currentTimeSegments[1])) {
    return {
      isOverlapping: true,
      reason: SAME_DAY_CONFLICT
    }
  }

  if (!(currentTimeSegments[0].opentime === '' || currentTimeSegments[1].opentime === '')) {
    currentTimeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1["opentime"].localeCompare(timeSegment2["opentime"])
    );
  }

  start1 = moment(currentTimeSegments[0].opentime, 'HH:mm:ss')
  end1 = moment(currentTimeSegments[0].closetime, 'HH:mm:ss');

  let start2 = moment(currentTimeSegments[1].opentime, 'HH:mm:ss');
  let end2 = moment(currentTimeSegments[1].closetime, 'HH:mm:ss');

  if (end1.isSame(start1) || end2.isSame(start2)) {
    return {
      isOverlapping: true,
      reason: SAME_DAY_CONFLICT
    }
  }

  if (end2.isBefore(start2)) {
    if (start2.isBetween(start1, end1)) {
      return {
        isOverlapping: true,
        reason: SAME_DAY_CONFLICT
      };
    }
    if (!(end2.isBefore(nextStart) || isNextDayClosed)) {
      return {
        isOverlapping: true,
        reason: NEXT_DAY_CONFLICT
      };
    }
  } else {
    if (start2.isBetween(start1, end1)) {
      return {
        isOverlapping: true,
        reason: SAME_DAY_CONFLICT
      };
    }
    if (end2.isBetween(start1, end1)) {
      return {
        isOverlapping: true,
        reason: SAME_DAY_CONFLICT
      };
    }
  }
  if (end1.isBefore(start1)) {
    if (start2.isBetween(start1, end1.add(1, 'day')) || end2.isBetween(start1, end1.add(1, 'day'))) {
      return {
        isOverlapping: true,
        reason: SAME_DAY_CONFLICT
      };
    }
  }
  return {
    isOverlapping: false
  };
}

export function getMinifiedTimingsArray(timingsListCopy) {
  if (timingsListCopy.length === 0) {
    return [];
  }
  let minifiedArr = new Array({
    ...timingsListCopy[0],
    startDay: 'MONDAY',
    endDay: null
  });
  for (let index = 1; index < timingsListCopy.length; index++) {
    let lastTimingsArr = JSON.parse(JSON.stringify(minifiedArr[minifiedArr.length - 1]));
    delete lastTimingsArr['startDay'];
    delete lastTimingsArr['endDay'];
    delete lastTimingsArr['day'];

    let currentTimingsArr = JSON.parse(JSON.stringify(timingsListCopy[index]));
    delete currentTimingsArr['day'];
    currentTimingsArr['time_slots'] = currentTimingsArr['time_slots'].filter((slot) => {
      if (slot.opentime === '' || slot.closetime === '') {
        return false;
      } else {
        return true;
      }
    })
    if (
      (lastTimingsArr.isOpenFullDay && currentTimingsArr.isOpenFullDay) ||
      (lastTimingsArr.isClosedFullDay && currentTimingsArr.isClosedFullDay)
    ) {
      minifiedArr[minifiedArr.length - 1].endDay = timingsListCopy[index].day
    } else {
      if (_.isEqual(lastTimingsArr, currentTimingsArr)) {
        minifiedArr[minifiedArr.length - 1].endDay = timingsListCopy[index].day
      } else {
        minifiedArr.push({
          ...timingsListCopy[index],
          time_slots: timingsListCopy[index]['time_slots'].filter((slot) => {
            if (slot.opentime === '' || slot.closetime === '') {
              return false;
            } else {
              return true;
            }
          }),
          startDay: timingsListCopy[index].day,
          endDay: null
        })
      }
    }
  }
  return minifiedArr;
}

export function getValidationCaseForASlot(timingsSlot, nextTimeSlot) {

  let checkOverlap = checkOverlapOfTimings(timingsSlot, nextTimeSlot);

  if (checkOverlap.isOverlapping) {
    switch (checkOverlap.reason) {
      case SAME_DAY_CONFLICT:
        return 'timings-overlap'
      case NEXT_DAY_CONFLICT:
        return 'next-day-timings-overlap'
    }
  }

  if (timingsSlot.length === 1) {
    return null;
  }

  let endTimeOfFirstSlot = timingsSlot[0].closetime;
  let startTimeOfSecondSlot = timingsSlot[1].opentime;

  if (endTimeOfFirstSlot === startTimeOfSecondSlot) {
    return 'start-equals-end'
  }
  return null
}

export function getChangesInInitialAndFinalState(timingsList, timingsListCopy) {
  let changesArr = [];
  for (let index = 0; index < timingsListCopy.length; index++) {
    let dayOriginal = timingsList[index];
    let dayModified = timingsListCopy[index];
    if (!_.isEqual(dayOriginal, dayModified)) {
      changesArr.push(timingsListCopy[index]);
    }
  }
  changesArr = changesArr.map((dayData) => {
    let tempDay = {
      day: dayData.day
    }
    if (dayData.isOpenFullDay) {
      return {
        ...tempDay,
        widgetType: 'open-full-day'
      }
    }
    if (dayData.isClosedFullDay) {
      return {
        ...tempDay,
        widgetType: 'closed-full-day'
      }
    }
    let totalHours = 0;
    let totalMinutes = 0;
    dayData.time_slots.forEach((dayData) => {
      if (!(dayData.opentime === '' || dayData.closetime === '')) {
        let startTime = moment(dayData.opentime, 'HH:mm:ss');
        let endTime = moment(dayData.closetime, 'HH:mm:ss');
        if (startTime.isAfter(endTime)) {
          let temp1 = moment("24:00:00", 'HH:mm:ss');
          let temp2 = moment("00:00:00", 'HH:mm:ss');
          let tempSumMinute = temp1.diff(startTime, 'minute') + endTime.diff(temp2, 'minute');
          totalMinutes += tempSumMinute;
        } else {
          totalMinutes += endTime.diff(startTime, 'minute');
        }
      }
    })
    totalHours = Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    return {
      ...tempDay,
      widgetType: 'timings',
      hours: totalHours,
      minutes: totalMinutes
    }
  })

  changesArr = changesArr.filter((day) => {
    if (day.hours > 8) {
      return false;
    } else {
      if (day.hours === 8) {
        if (day.minutes === 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  });

  return changesArr;
}

export function getApiPayloadFromData(timingsArr, merchantUserId) {
  let timingsArrCopy = _.cloneDeep(timingsArr);
  let tempLastDay = timingsArrCopy.pop();
  timingsArrCopy.unshift(tempLastDay);
  let payload = {
    timingUpdateRequest: {
      merchantUserId: `${merchantUserId}`,
      timings: timingsArrCopy.map((day, index) => {
        if (day.time_slots.length === 1) {
          return {
            timings: `${day.time_slots[0].opentime}-${day.time_slots[0].closetime}`,
            day: day.day,
            dayIndex: index
          }
        } else {
          if (day.time_slots[1].opentime === '' || day.time_slots[1].closetime === '') {
            return {
              timings: `${day.time_slots[0].opentime}-${day.time_slots[0].closetime}`,
              day: day.day,
              dayIndex: index
            }
          }
          return {
            timings: `${day.time_slots[0].opentime}-${day.time_slots[0].closetime}||${day.time_slots[1].opentime}-${day.time_slots[1].closetime}`,
            day: day.day,
            dayIndex: index
          }
        }
      })
    }
  }
  return payload;
}

export function getTimingsList(listVersion = TIME_LIST_VERSION_TODAY, timeOptionsArray, listHeaderIndexes) {
  if (listVersion === TIME_LIST_VERSION_TODAY) {
    let today = moment();
    today.minute() >= 30 ? today.minute("30").seconds("0") : today.minute("0").seconds("0");
    let tonight = moment(today).hour(23).minute(30);
    let listOfTimes = [];
    listOfTimes.push(moment().format("DD MMMM") + " (Today)");
    while (today.isBefore(tonight)) {
      let tempElement = moment(today.add(30, "minute"));
      if (tempElement.diff(moment(), "minutes") > 15) { // as the store closes 15 minutes before the selected time
        listOfTimes.push(tempElement);
      }
    }
    return {timeOptionsArray: listOfTimes, listHeaderIndexes: [0]}
  } else {
    let tomorrow12am = moment().add(1, "day").hour(0).minute(0).second(0);
    let tomorrow4am = moment().add(1, "day").hour(4).minute(0).second(0);
    let listOfTimes = [];
    let nextHeaderIndex = timeOptionsArray.length;
    listOfTimes.push(tomorrow4am.format("DD MMMM") + " (Tomorrow)");
    while (tomorrow12am.isSameOrBefore(tomorrow4am)) {
      listOfTimes.push(moment(tomorrow12am));
      tomorrow12am.add(30, "minute")
    }
    return {timeOptionsArray: [...timeOptionsArray, ...listOfTimes], listHeaderIndexes: [0, nextHeaderIndex]};
  }
}
