import React, { Component } from 'react';
import config from '../../config';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import moment from 'moment';
const ga = window.ga;

export default class TimeOfDayChart extends Component {
  constructor(props){
    super(props);
    this.state = {
      chartType: 'day_of_week',
    }
  }

  componentDidMount(){
    this.props.getTxnPattern(this.props.user.accessToken, moment().subtract(50,'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), 'time_of_day')
    .then(data=>this.setState({hourlyTxnPattern:data[0]}))
  }

  onClickTime=()=>{
    this.setState({chartType: 'time_of_day'});
    ga('send', 'event', 'Analytics', 'Change_Chart_Type', 'Time_Of_The_Day_Chart', 'sales order');
  }

  onClickDay=()=>{
    this.setState({chartType: 'day_of_week'});
    ga('send', 'event', 'Analytics', 'Change_Chart_Type', 'Time_Of_The_Day_Chart', 'sales order');
  }


  render(){
    const chartOptions = {
      dataOpacity: 0.8 ,
      colors: ['#fab155'],
      chartArea: {
        width: '90%',
        height:'80%'
      },
      // bar: {groupWidth: "70%"},
      displayAnnotations: true,
      annotations: {
        textStyle: {
          fontSize: 14,
        },
      },
      legend: { position: "none" },
      vAxis:{
        gridThickness: 0,
        labelAngle:180,
        textStyle:{color: '#9cadb5'},
        gridlines: {
          color: '#e5e5e5',
          baselineColor: '#e5e5e5',
          opacity: '0.5'
        },
        format: 'short',
        baselineColor: 'transparent'
      },
      hAxis:{
        textStyle:{color: '#9cadb5'},
        gridlines: {
          color: 'transparent',
          count:2
        },
      }
    };

    const buttonStyles = {
      backgroundColor: '#f7f7fa',
      color: 'rgba(0,0,0,0.38)',
      padding: '5px 8px',
      fontSize: '12px',
      lineHeight: '14px',
      display: 'block',
      textDecoration: 'none',
    }

    const chartFields = [
          {'type': 'string', 'label' : 'days'},
          {'type' : 'number', 'label' : 'Transactions'},
        ];

    let weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    const tooltip = (
      <Tooltip id="tooltip">Based on visits to this place</Tooltip>);
    let chartData = this.state.chartType === 'day_of_week' ? this.props.daypattern : this.state.hourlyTxnPattern
    const addnStyle = this.props.isCeetPage ? { paddingLeft: '0px', paddingRight: '0px' } : {};
    return(
      <div>
        <div className="col-md-12 white-box-wrapper mobile-full-card" style={addnStyle}>
          <div className="white-box">
            <div
              className="white-box-header"
              style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
             <span className="chart-title"> Popular Timings                
                  <OverlayTrigger placement="right" overlay={tooltip}>
                    <img className="chart-info-icon" src={config.basepath + "images/info-icon.png"} width="16" />
                  </OverlayTrigger>
              </span> 
              <div
              className="graph-wrapper"
              style={{overflow: 'hidden'}}>
              <div
                className="gragh-status"
                style={{marginTop: 0}}>
                <div className="feed-graph-text-wrapper">
                  <div className="feed-graph-txt">
                    <ButtonGroup>
                      <Button style = {buttonStyles} className={this.state.chartType === 'time_of_day' ? 'active' : ''} onClick={this.onClickTime}>Time</Button>
                      <Button style = {buttonStyles} className={this.state.chartType === 'day_of_week' ? 'active' : ''} onClick={this.onClickDay}>Day</Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div style={{position:"relative", width:'calc( 100% - 32px )', height:'300px', margin:'0 auto'}}>
              {chartData && <figure><this.props.Chart
                loader={<Loader/>}
                chartType="ColumnChart"
                rows={this.state.chartType === 'day_of_week' ? chartData.sort((a,b)=>weekDays.indexOf(a.key.toLowerCase())-weekDays.indexOf(b.key.toLowerCase())).map(dayOrTime=>[dayOrTime.key, dayOrTime.doc_count]): chartData.sort((a,b)=>{return a.key-b.key}).map(dayOrTime=>[moment(dayOrTime.key,'HH').format('h a'), dayOrTime.doc_count])}
                columns={chartFields}
                options={chartOptions}
                graph_id="DayPatternChart"
                width={"100%"}
                height={"280px"}
                legend_toggle={true} /></figure>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
