import React, {Component} from 'react';
import logoBusiness from '../../images/logo-business.svg';

import './header.css';

const ga = window.ga;

export default class HeaderAdmin extends Component {
  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout(this.props.user.accessToken);
    ga('send', 'event', 'Dashboard', 'navigation', 'logout');
  }

  componentDidMount(){
    document.body.classList.add('top-header-bar-visible')
  }

  componentWillUnmount(){
    document.body.classList.remove('top-header-bar-visible')
  }

  render() {
    return (
      !this.props.user ? <div /> :
      <header style={{position:"fixed", zIndex:"1000", width:"100vw", top:"0" }}>
        <div className="top-header-bar top-login-header" style={{display:'block',  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'}}>
          <div className="container-fluid">
            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="col-sm-4 col-xs-6">
                <div className="logo login-logo logo-wrapper">
                <a><img src={logoBusiness} alt="magicpin" /></a>
                </div>
              </div>
              <div className="col-sm-8 col-xs-6">
                <div className="login-header-rgt header-login-mobile" style={{textAlign: 'right', display:'flex', justifyContent:'flex-end'}}>
                  <button style={{whiteSpace:'nowrap', marginRight:'5px', background: "#ef1c74", outline: 'none', borderRadius: '4px'}} 
                  className= {this.props.isLoggingOut ? "btn-o loadingDots button-header-hover" : "btn-o button-header-hover"} 
                  onClick = {this.handleLogout}
                  disabled = {this.props.isLoggingOut}>{this.props.isLoggingOut ? "LOGGING OUT" : "LOG OUT"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
