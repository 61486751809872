const GET_INVENTORY_REQUEST_ID = 'inventoryRequestId/GET_INVENTORY_REQUEST_ID';
const GET_INVENTORY_REQUEST_ID_SUCCESS = 'inventoryRequestId/GET_INVENTORY_REQUEST_ID_SUCCESS';
const GET_INVENTORY_REQUEST_ID_FAIL = 'inventoryRequestId/GET_INVENTORY_REQUEST_ID_FAIL';
const GET_VOUCHER_REDEMPTION_ON_CTC_LOADING = 'inventoryRequestId/GET_VOUCHER_REDEMPTION_ON_CTC_LOADING';
const GET_VOUCHER_REDEMPTION_ON_CTC_SUCCESS = 'inventoryRequestId/GET_VOUCHER_REDEMPTION_ON_CTC_SUCCESS';
const GET_VOUCHER_REDEMPTION_ON_CTC_FAILED = 'inventoryRequestId/GET_VOUCHER_REDEMPTION_ON_CTC_FAILED';

const intialState = {
  inventoryRequestIdLoading: false,
  voucherRedemptionOnCtcLoading: false
};

export default function inventoryRequestId(state = intialState, action = {} ){
	switch(action.type){
	case GET_INVENTORY_REQUEST_ID:
      return {
        ...state,
    inventoryRequestIdLoading: true,
      };
    case GET_INVENTORY_REQUEST_ID_SUCCESS:
      return {
        ...state,
        inventoryRequestIdLoading: false,
        inventoryRequestIdLoaded: true,
        inventoryRequestId: action.result.requestId,
        inventoryRequestIdError: null,
      };
    case GET_INVENTORY_REQUEST_ID_FAIL:
      return {
        ...state,
        inventoryRequestIdLoading: false,
        inventoryRequestIdLoaded: true,
        inventoryRequestId: null,
        inventoryRequestIdError: action.error,
      };
    case GET_VOUCHER_REDEMPTION_ON_CTC_LOADING:
      return {
        ...state,
        voucherRedemptionOnCtcLoading: true,
      };
    case GET_VOUCHER_REDEMPTION_ON_CTC_SUCCESS:
      return {
        ...state,
        voucherRedemptionOnCtcLoading: false,
        voucherRedemptionOnCtcLoaded: true,
        voucherRedemptionOnCtcData: action.result,
        voucherRedemptionOnCtcError: null
      };
    case GET_VOUCHER_REDEMPTION_ON_CTC_FAILED:
      return {
        ...state,
        voucherRedemptionOnCtcLoading: false,
        voucherRedemptionOnCtcLoaded: true,
        voucherRedemptionOnCtcData: null,
        voucherRedemptionOnCtcError: action.error
      };
    default:
      return state;
	}
}


export function getInventoryRequestId({accessToken, payload, serviceName}){
  return {
    types: [GET_INVENTORY_REQUEST_ID, GET_INVENTORY_REQUEST_ID_SUCCESS, GET_INVENTORY_REQUEST_ID_FAIL],
    promise: (client) => client.default.post('/getInventoryRequestId', {
      data: {accessToken, payload, serviceName}
    })
  };
}

export function getVoucherRedemptionLoanOnCtc(accessToken){
  return {
    types: [GET_VOUCHER_REDEMPTION_ON_CTC_LOADING, GET_VOUCHER_REDEMPTION_ON_CTC_SUCCESS, GET_VOUCHER_REDEMPTION_ON_CTC_FAILED],
    promise: (client) => client.default.post('/getVoucherRedemptionLoanOnCtc', {
      data: {accessToken}
    })
  };
}