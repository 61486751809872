import { loginMediums } from "../../constants";
import { getEmailId } from "../../helpers/Helpers";

const CHECK_SESSION = "loginSession/CHECK_SESSION";
const CHECK_SESSION_FAIL = "loginSession/CHECK_SESSION_FAIL";
const CHECK_SESSION_SUCCESS = "loginSession/CHECK_SESSION_SUCCESS";

const CHECK_MERCHANT_SESSION = "loginSession/CHECK_MERCHANT_SESSION";
const CHECK_MERCHANT_SESSION_FAIL = "loginSession/CHECK_MERCHANT_SESSION_FAIL";
const CHECK_MERCHANT_SESSION_SUCCESS = "loginSession/CHECK_MERCHANT_SESSION_SUCCESS";

const initialState = {};

export default function loginSession(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function checkAdminSession(emailId, deviceDetails) {
    return {
        types: [CHECK_SESSION, CHECK_SESSION_SUCCESS, CHECK_SESSION_FAIL],
        promise: (client) => client.default.post('/checkMySession', {
          data: {emailId, deviceDetails}
        })
      };
}

export function checkMerchantSession(accessToken, deviceDetails) {
  let loginMedium = "";
  try {
    loginMedium = localStorage.getItem('loginMedium');
    loginMedium = loginMedium && loginMedium.includes('short_link') ? loginMediums['short_url'] : loginMediums[loginMedium];
  } catch (err) {}
  return {
    types: [CHECK_MERCHANT_SESSION, CHECK_MERCHANT_SESSION_SUCCESS, CHECK_MERCHANT_SESSION_FAIL],
    promise: (client) => client.default.post('/checkMerchantSession', {
      data: {accessToken, deviceDetails, loginMedium}
    })
  };
}
