const LOAD = 'transactions/LOAD';
const LOAD_SUCCESS = 'transactions/LOAD_SUCCESS';
const LOAD_FAIL = 'transactions/LOAD_FAIL';

const REPORT = 'transactions/REPORT';
const REPORT_SUCCESS = 'transactions/REPORT_SUCCESS';
const REPORT_FAIL = 'transactions/REPORT_FAIL';

const REQUEST_CSV = 'transactions/REQUEST_CSV';
const REQUEST_CSV_SUCCESS = 'transactions/REQUEST_CSV_SUCCESS';
const REQUEST_CSV_FAIL = 'transactions/REQUEST_CSV_FAIL';

const DOWNLOAD_CSV = 'transactions/DOWNLOAD_CSV';
const DOWNLOAD_CSV_SUCCESS = 'transactions/DOWNLOAD_CSV_SUCCESS';
const DOWNLOAD_CSV_FAIL = 'transactions/DOWNLOAD_CSV_FAIL';

const REPORT_EASY_REWARD = 'transactions/REPORT_EASY_REWARD'
const REPORT_EASY_REWARD_SUCCESS = 'transactions/REPORT_EASY_REWARD_SUCCESS'
const REPORT_EASY_REWARD_FAIL = 'transactions/REPORT_EASY_REWARD_FAIL'

const initialState = {
  next: 0,
  transactions: [],
};

export default function allTransactions(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        transactions: action.next == 0 ? [] : state.transactions,
      };
    case LOAD_SUCCESS:

    let voucherCount = (action.result && action.result.next && action.result.next.vouchers_next)
    let payByMagicpinCount = (action.result && action.result.next && action.result.next.pay_by_magicpin_next)
    let dealCount = (action.result && action.result.next && action.result.next.transaction_next)
    let smsTxnCount = (action.result && action.result.next && action.result.next.sms_txn_next)

    voucherCount = voucherCount && voucherCount > 0 ? voucherCount : action.voucherStart || 0;
    payByMagicpinCount = payByMagicpinCount && payByMagicpinCount > 0 ? payByMagicpinCount : action.payByMagicpinStart || 0;
    dealCount = dealCount && dealCount > 0 ? dealCount : action.dealStart || 0;
    smsTxnCount = smsTxnCount && smsTxnCount > 0 ? smsTxnCount : action.smsTxnStart || 0;

    let transactionsData = action.result.data || []
    
    const transactionDataExtraType = ["DELIVERY_OTHER", "DELIVERY_CHARGE"]
    let transactionDataExtra = transactionsData && Array.isArray(transactionsData) && transactionsData.filter((txn) => transactionDataExtraType.includes(txn.product_type && txn.product_type.toUpperCase() )) || []

    transactionsData = transactionsData
                        .filter((txn) => !transactionDataExtraType.includes(txn.product_type && txn.product_type.toUpperCase() ))
                        .map((txn) => {
                          if(!txn || !txn.product_id || !Array.isArray(transactionDataExtra) || !transactionDataExtra.length || !txn.product_type || !(txn.product_type.toLowerCase() == "delivery"))
                          {
                            return txn
                          }
                          
                          // let extraDataObj = transactionDataExtra.find(extraTxn => ((extraTxn && extraTxn.product_id) == txn.product_id))

                          let extraDataObj = transactionDataExtra.reduce((extraDataObj, item) => {

                            if((item && item.product_id) == txn.product_id){
                              extraDataObj.bill_amount = extraDataObj.bill_amount + item.bill_amount || 0
                              extraDataObj.commission = extraDataObj.commission + item.commission || 0
                              extraDataObj.cost_to_magicpin = extraDataObj.cost_to_magicpin + item.cost_to_magicpin || 0
                              extraDataObj.loan_deduct = extraDataObj.loan_deduct + item.loan_deduct || 0
                              extraDataObj.netMerchantCreditAmount = extraDataObj.netMerchantCreditAmount + item.netMerchantCreditAmount || 0
                              extraDataObj.pgAmount = extraDataObj.pgAmount + item.pgAmount || 0
                              extraDataObj.totalDeduct = extraDataObj.totalDeduct + item.totalDeduct || 0
                            }
                      
                            return extraDataObj
                          }, {bill_amount: 0, commission: 0, cost_to_magicpin: 0, loan_deduct: 0, netMerchantCreditAmount: 0, pgAmount: 0, totalDeduct: 0})

                          if(!extraDataObj){
                            return txn
                          }

                          let newDataObj = {
                            ...txn,
                            bill_amount: txn.bill_amount + extraDataObj.bill_amount,
                            commission: txn.commission + extraDataObj.commission,
                            cost_to_magicpin: txn.cost_to_magicpin + extraDataObj.cost_to_magicpin,
                            loan_deduct: txn.loan_deduct + extraDataObj.loan_deduct,
                            netMerchantCreditAmount: txn.netMerchantCreditAmount + extraDataObj.netMerchantCreditAmount,
                            pgAmount: txn.pgAmount + extraDataObj.pgAmount,
                            totalDeduct: txn.totalDeduct + extraDataObj.totalDeduct
                            // walletRedemptionCommercialDetail: {},
                            // walletRedemptionRefundDtoList: [{…}],
                          }

                          return newDataObj
                        } )

      return {
        ...state,
        loading: false,
        loaded: true,
        transactions: action.voucherStart  == 0  && action.payByMagicpinStart==0 && action.dealStart==0 && action.smsTxnStart==0 ? transactionsData : [...state.transactions, ...transactionsData],
        next : transactionsData.length > 0 ? 1 : -1,
        voucherCount,
        dealCount,
        payByMagicpinCount,
        smsTxnCount,
        nextSmsTxnStartDate: action.result && action.result.next && action.result.next.nextSmsTxnStartDate || ""
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case REPORT:
      return {
        ...state,
        reporting: true,
      };
    case REPORT_SUCCESS:
      return {
        ...state,
        reporting: false,
        reported: true,
        transactions: state.transactions.map(transaction => {
          if(transaction.type == 'deal' && action.txn_id == transaction.txn_id){
            return {...transaction, status: 'DISPUTED'};
          }
          else return transaction;
        })
      };
    case REPORT_FAIL:
      return {
        ...state,
        reporting: false,
        reported: false,
        reportError: action.error,
      };
    case REQUEST_CSV:
      return {
        ...state,
        requesting: true,
      };
    case REQUEST_CSV_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
        requestError: null,
      };
    case REQUEST_CSV_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
      };

      case DOWNLOAD_CSV:
        return {
          ...state,
          isDownloading: true,
          downloadError: null,
        };
      case DOWNLOAD_CSV_SUCCESS:
        return {
          ...state,
          isDownloading: false,
          downloadError: null,
          csvString: action.result.data || ""
        };
      case DOWNLOAD_CSV_FAIL:
        return {
          ...state,
          isDownloading: false,
          downloadError: action.error,
        };
    case REPORT_EASY_REWARD:
      return {
        ...state,
        reportingSmsTxn: true,
      };
    case REPORT_EASY_REWARD_SUCCESS:
      return {
        ...state,
        reportingSmsTxn: false,
        reportedSmsTxn: true,
        transactions: state.transactions.map(transaction => {
          if(transaction.type == 'easyRewards' && action.txn_id == transaction.txn_id){
            return {...transaction, status: 'DISPUTED'};
          }
          else return transaction;
        })
      };
    case REPORT_EASY_REWARD_FAIL:
      return {
        ...state,
        reportingSmsTxn: false,
        reportedSmsTxn: false,
        reportSmsTxnError: action.error,
      };

   default:
      return state;
  }
}

//passing salt for admin case only
export function getTransactions(accessToken, deal, voucher, payViaMagicpin ,voucherStart,dealStart,payByMagicpinStart,startDate, endDate, next, smsTxnStart = 0, salt = null, nextSmsTxnStartDate = "", orders, easyReward, cardSwipes) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getTransactions',{
      data:{accessToken, deal, voucher, payViaMagicpin,voucherStart,dealStart,payByMagicpinStart,startDate, endDate, next, smsTxnStart, salt, nextSmsTxnStartDate, orders, easyReward, cardSwipes}
    }),
    next,voucherStart,payByMagicpinStart,dealStart, smsTxnStart, nextSmsTxnStartDate
  };
}

export function reportTransaction(accessToken, reasonId, reasonText, transactionId, txnDate, merchantName, billAmount) {
  return {
    types: [REPORT, REPORT_SUCCESS, REPORT_FAIL],
    promise: (client) => client.default.post('/reportTransaction',{
      data:{accessToken, reasonId, reasonText, transactionId, txnDate, merchantName, billAmount}
    }),
    txn_id: transactionId,
  };
}

export function requestCsv(accessToken, startDate, endDate, email, isParent, type) {
  let url = `/requestCsv?start_date=${startDate}&end_date=${endDate}`
  if (type)
    url += `&type=${type}`
  //console.log(url);  
  return {
    types: [REQUEST_CSV, REQUEST_CSV_SUCCESS, REQUEST_CSV_FAIL],
    promise: (client) => client.default.post(url, {
      data: {accessToken, email, isParent, downloadCsv:false}
    })
  }
}

export function requestDownloadCsv(accessToken, startDate, endDate, isParent, type) {
  let url = `/requestCsv?start_date=${startDate}&end_date=${endDate}`
  if (type)
    url += `&type=${type}`
  
  return {
    types: [DOWNLOAD_CSV, DOWNLOAD_CSV_SUCCESS, DOWNLOAD_CSV_FAIL],
    promise: (client) => client.default.post(url, {
      data: {accessToken, isParent, downloadCsv:true}
    })
  }
 }
 
export function requestCustomerCsv(accessToken, startDate, endDate, email, isParent) {
  let url = `/requestCustomerCsv?start_date=${startDate}&end_date=${endDate}`
  return {
    types: [REQUEST_CSV, REQUEST_CSV_SUCCESS, REQUEST_CSV_FAIL],
    promise: (client) => client.default.post(url, {
      data: {accessToken, email, isParent}
    })
  }
}

export function disputeSmsTransaction(accessToken, txnId, salt = null) {
  return {
    types: [REPORT_EASY_REWARD, REPORT_EASY_REWARD_SUCCESS, REPORT_EASY_REWARD_FAIL],
    promise: (client) => client.default.post('/disputeSmsTransaction',{
      data:{accessToken, txnId, salt}
    }),
    txn_id: txnId,
  };
}