import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Alert from '../InfoBar/Alert';
import './GstVerificationModal.css'
export default class GstVerificationModal extends Component {

    state = {
        isConsentAvailable: false,
        verificationType: 'gst'
    }
    
    submit = (event)=>{
        event.preventDefault();
        const gstForm = document.forms.gstForm;
        const gstFormData = new FormData(gstForm)
        const uniqueIdentificationNumber = gstFormData.get('unique-identification-number');
        const confirmUniqueIdentificationNumber = gstFormData.get('confirm-unique-identification-number');


        if(uniqueIdentificationNumber !== confirmUniqueIdentificationNumber)
        {
            toast.error(`${this.state.verificationType.toUpperCase()} number does not match`)
        }
        else if(!this.state.isConsentAvailable)
        {
            toast.error("Pls agree before submitting")
        }
        else if (!(uniqueIdentificationNumber && confirmUniqueIdentificationNumber)) {
            toast.error("Please submit a valid response.")
        }
        else if(this.state.isConsentAvailable && (uniqueIdentificationNumber === confirmUniqueIdentificationNumber))
        {
            const gstNumber = this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.gstVerified && this.props.feedShortcutsStatusData.gstNumber;
            const panNumber = this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.panNumber
            const isGstAvailable = !!gstNumber  // converting into boolean
            this.state.verificationType === 'gst' ? this.handleSubmit(uniqueIdentificationNumber, true, "regular", panNumber) : this.handleSubmit(gstNumber, isGstAvailable, "regular", uniqueIdentificationNumber);
        }
        else
        {
            toast.error("Please refresh and try again")
        }
    }

    handleCheckbox = () => {
        this.setState({isConsentAvailable: !this.state.isConsentAvailable})
    }

    handleRadioButton = (e) => {
        this.setState({verificationType: e.target.value || 'gst', isConsentAvailable: false})
    }

    handleSubmit = (gstNumber, gstAvailable, gstScheme, panNumber) => {
        this.props.postGst(this.props.accessToken, gstNumber, gstAvailable, gstScheme, panNumber)
        .then(() => {
            toast.success(`${this.state.verificationType.toUpperCase()} Number submitted successfully!`)
            this.props.hideModal();

            setTimeout(() => {
                window.location.reload(); 
              }, 3000);
        })
        .catch((err)=>{
            toast.error(err && err.message ?  err.message: "Please try again later")
            this.props.hideModal();
        })
    }

    render() {

        const isMobile = window.innerWidth < 768
        return(
            <div>
            <Modal
                show={()=>this.props.showModal()}
              onHide={()=>this.props.hideModal()} 
            >
              <Modal.Header className='gst-modal-header' closeButton>
                <h2 className='header-text'>Add Details</h2>
              </Modal.Header>
              <Modal.Body style={{padding: isMobile ? "15px" : '25px'}}>
                  <div className='gst-form-container'>
                    <div style = {{margin: "0 0 5% 0"}}>
                        <Alert
                        information = {"As per Government of India guidelines, every merchant is required to hold GSTIN or PAN Number under Regular Scheme for being able to sell through e-commerce platforms."}
                        />
                    </div>

                    <form id="gstForm" action="" autoComplete="off" onSubmit={(event)=>this.submit(event)}>
                     <fieldset>
                        <legend className='title-text'><span style = {{fontFamily: "robotobold"}}>Benefits:</span> This GSTIN will be used by company for Invoicing purpose and you can claim GST input on services provided by magicpin.</legend>
                        <div style={{flexDirection:'row', display:'flex'}}>
                            <input style={{marginRight:'3px'}} type="radio" id="pan" name="uan" value="gst" onClick={this.handleRadioButton} checked={this.state.verificationType === 'gst'}/>
                            <label style={{marginRight:'20px'}} for="gst">GST Number</label>
                            <input style={{marginRight:'3px'}} type="radio" id="pan" name="uan" value="pan" onClick={this.handleRadioButton} />
                            <label for="pan">Pan Card</label>
                        </div>

                        <input id='gst-textfield' name="unique-identification-number" className="form-control" pattern={this.state.verificationType === 'gst' ? "[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}" : "[A-Z]{5}[0-9]{4}[A-Z]{1}"} placeholder={`Enter ${this.state.verificationType.toUpperCase()} number`}
                        type="text" style={{marginTop: '10px', marginBottom: '20px'}} required/>
                        <input id='confirm-gst-textfield' name="confirm-unique-identification-number" className="form-control" pattern={this.state.verificationType === 'gst' ? "[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}" : "[A-Z]{5}[0-9]{4}[A-Z]{1}"} placeholder={`Re-enter ${this.state.verificationType.toUpperCase()} number`}
                        type="text"style={{marginTop: '20px', marginBottom: '20px'}} onPaste={(e)=> {e.preventDefault(); return false;}} required/>

                        <div style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}>
                            <input name='gst-available' type="checkbox" checked={this.state.isConsentAvailable} onClick={()=>this.handleCheckbox()}/>
                            <p style={{paddingLeft: '10px', fontSize: isMobile ? '12px' :'14px'}}>{this.state.verificationType === 'gst' ? "I agree that GSTIN shared above is a valid GST registered under" : "I agree that PAN shared above is a valid PAN that belongs to store/owner"} 
                            {this.state.verificationType === 'gst' && <span style = {{fontFamily: "robotobold", fontStyle: "italic"}}>*Regular scheme <br/><br/>
                               *The Type of Registration is mentioned at Serial Number 7 in GST Registration Certificate</span>}
                            </p>
                        </div>
                        <div>
                            <input type="submit" value="submit" className="cta pointer large-btn large-btn2"/>
                        </div>
                     </fieldset>
                    </form> 
                  </div>
              </Modal.Body>
            </Modal>
          </div>
        )
     }
 }