const SNACKBAR_EMAIL='snackbar/SNACKBAR_EMAIL'
const SNACKBAR_EMAIL_SUCCESS='snackbar/SNACKBAR_EMAIL_SUCCESS'
const SNACKBAR_EMAIL_FAIL='snackbar/SNACKBAR_EMAIL_FAIL'

const SHOW_SNACKBAR_SUCCESS = 'snackbar/SHOW_SNACKBAR_SUCCESS';
const HIDE_SNACKBAR_SUCCESS = 'snackbar/HIDE_SNACKBAR_SUCCESS';
const SHOW_BOTTOM_SNACKBAR_SUCCESS = 'snackbar/SHOW_BOTTOM_SNACKBAR_SUCCESS';
const HIDE_BOTTOM_SNACKBAR_SUCCESS = 'snackbar/HIDE_BOTTOM_SNACKBAR_SUCCESS';

const initialState = {
    requested: false,
    priority: 100,
  }  

export default function snackbar(state = initialState, action = {}) {
    switch (action.type) {
  
      case SNACKBAR_EMAIL:
        return {
          ...state,
          requesting: true,
        };
      case SNACKBAR_EMAIL_SUCCESS:
        return {
          ...state,
          requesting: false,
          requested: true,
        };
      case SNACKBAR_EMAIL_FAIL:
        return {
          ...state,
          requesting: false,
          requested: false,
          requestError: action.error,
        };
      case SHOW_SNACKBAR_SUCCESS:
          if(isNaN(action.payload.priority) || action.payload.priority >= state.priority) return state
          return {
            ...state,
            snackBarVisible: true,
            ...action.payload,
          }
      case HIDE_SNACKBAR_SUCCESS:
        return {
          ...state,
          priority: 100,
          snackBarVisible: false,
        }
      case SHOW_BOTTOM_SNACKBAR_SUCCESS:
        return{
          ...state,
          bottomSnackbarVisible: true,
        }
      case HIDE_BOTTOM_SNACKBAR_SUCCESS:
        return {
          ...state,
          bottomSnackbarVisible: false,
        } 
  
     default:
        return state;
    }
  }


  export function sendSnackbarEmail(accessToken,outletName,locality,city,latitude,longitude,magicpinURL,costForTwo,merchantId,categoryId,profilePic){
    return {
        types: [SNACKBAR_EMAIL, SNACKBAR_EMAIL_SUCCESS, SNACKBAR_EMAIL_FAIL],
        promise: (client) => client.default.post('/snackbarEmail', {
          data: {accessToken,outletName,locality,city,latitude,longitude,magicpinURL,costForTwo,merchantId,categoryId,profilePic}
        })
      }
  }


export function showSnackBar(payload){
  const {priority} = payload
  if(isNaN(priority)){
    console.error('priorities must be defined to change snackbar', payload, priority);
    payload['priority']=99
  }
  return{
    type: SHOW_SNACKBAR_SUCCESS,
    payload
  };
}

export function hideSnackBar(){
  return{
    type: HIDE_SNACKBAR_SUCCESS,
  };
}

export function showBottomSnackbar(){
  return{
    type:SHOW_BOTTOM_SNACKBAR_SUCCESS,
  };
}

export function hideBottomSnackbar(){
  return{
    type:HIDE_BOTTOM_SNACKBAR_SUCCESS,
  }
}