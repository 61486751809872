import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import cross from '../../images/cross.png';
// import './CustomerComplaints.css';
import './imageViewerFeedCards.css';
import zoomIcon from '../../images/zoom-icon-image.svg'
export class ImageViewerFeedCards extends Component {

    constructor(props){
        super(props);
        this.state={
            showSrc:null,
            isShow:false,
        }
    }
    render() {
        return (
            <React.Fragment>
                <Modal className = 'image-viewer-feeds-card-pro-pic' style = {{background:'rgba(0,0,0,0.65)'}}show={this.state.showSrc && this.state.isShow} onHide={() => { this.setState({ isShow: false }) }}>
                    <Modal.Body className="complaint-style" style={{ padding: '10%', left: '0', right: '0', top: '0', bottom: '0',textAlign:'center' }}>
                        <div className="dialog-cross" onClick={() => { this.setState({ isShow: false }) }} style={{ textAlign: 'right',marginRight:'30px' }}>
                            <img src={cross} style={{ height: "26px", width: "26px" ,position:'absolute'}} />
                        </div>
                        <img src={this.props.src} onError={this.props.onError}
                            style={{ height: 'auto',maxHeight:'400px',maxWidth:'100%',borderRadius:'4px',border:'1px solid rgba(0,0,0,0.87)' }} />
                    </Modal.Body>
                </Modal>
                {
                 this.props.isZoomIcon
                 ?
                 <div style={{position: 'relative', height: '36px', width:'36px'}}>
                    <img src={this.props.thumb} onError={this.props.onError} 
                            onClick={(event)=>this.setState({showSrc:event.target.src, isShow: true })} 
                            className={this.props.className || ""} style={this.props.style || {}}/>
                    <img src={zoomIcon} style={{position: 'absolute', bottom: '0', right:'0'}}/>
                 </div>
                 :
                <img src={this.props.thumb} onError={this.props.onError} 
                        onClick={(event)=>this.setState({showSrc:event.target.src, isShow: true })} 
                        className={this.props.className || ""} style={this.props.style || {}}/>
                }
            </React.Fragment>
        )
    }
}
