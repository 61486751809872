const LOAD_STATS = 'removedTxnStats/LOAD_STATS';
const LOAD_STATS_SUCCESS = 'removedTxnStats/LOAD_STATS_SUCCESS';
const LOAD_STATS_FAIL = 'removedTxnStats/LOAD_STATS_FAIL';

const initialState = {
    stats: null,
}

export default function removedTxnStats(state = initialState, action = {}) {
    switch (action.type) {

        case LOAD_STATS:
            return {
                ...state,
                loadingStats: true,
            };
        case LOAD_STATS_SUCCESS:
            return {
                ...state,
                loadingStats: false,
                statsLoaded: true,
                stats: action.result,
                statsError: null,
            };
        case LOAD_STATS_FAIL:
            return {
                ...state,
                loadingStats: false,
                statsLoaded: false,
                statsError: action.error,
                stats: null,
            };
        default:
            return state;
    }
}


export function getRemovedTxnStats(accessToken) {
    return {
        types: [LOAD_STATS, LOAD_STATS_SUCCESS, LOAD_STATS_FAIL],
        promise: (client) => client.default.post('/getRemovedTxnStats', {
            data: { accessToken }
        })
    };
}