import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import {load as getMerchants} from '../../redux/modules/merchantList';
import closeImg from '../../images/close.svg';

const styles = {
  titleStyle: {
    backgroundColor: '#338aed',
    fontSize: '16px',
    color: '#fff',
    padding: '16px',
    paddingTop: '8px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },

  contentStyle: {
    borderRadius : '8px',
    width: '85%',
  },

  closeStyle: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    width: '20px',
    height: '20px'
  },

  bodyStyle: {
    padding: '16px',
  },

  paperStyle: {
    style: {
        borderRadius: '8px',
    }
  }
}

export default class LoginPopup extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: !!this.props.open,
    }
  }

  handleOpen = () => {
    this.setState({open: true});
  };

  render(){

    return (
      <div>
        <Dialog
          title="Store already claimed"
          titleStyle = {styles.titleStyle}
          paperProps = {styles.paperStyle}
          contentStyle = {styles.contentStyle}
          bodyStyle = {styles.bodyStyle}
          modal={false}
          open={this.props.open}
          onRequestClose={this.handleClose} >
          <img src={closeImg} onClick={this.props.handleClose} style={styles.closeStyle}/>
          <p>Oops! seems this outlet has already been claimed. Please login to continue</p>
          <a onClick={(event)=> {this.props.push('/login')}}>Login</a>
          {this.props.showFalseClaimText && <p style={{marginTop: '10px'}}>
            If you think this store is falsely claimed, please reach out to us at partner@magicpin.in with subject 'Falsely claim {this.props.merchantName}'. Also, mention your phone number, store address in the email with one of the verification documents:
            <br/><br/>
            1. FSSAI certificate (For FnB outlets)<br/>
            2. GST certificate.<br/>
            3. Cancelled cheque with store name.<br/>
          </p>}
        </Dialog>
    </div>
    )
  }
}
