import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import customerPage from './customerPage';
import postPage from './postPage';
import localityByCity from './localityByCity';
import merchantList from './merchantList';
import claimBusiness from './claimBusiness';
import merchantCrm from './merchantCrm';
import * as dealReducers from './dealReducers';
import * as authReducers from './authReducers';
import {LOGOUT_SUCCESS, SELECT_MERCHANT_SUCCESS} from './authReducers/auth.js'
import * as parentMerchantReducers from './parentMerchantReducers';
import * as adminReducers from './adminReducers';
import * as dashboardReducers from './dashboardReducers';
import * as feedReducers from './feedReducers';
import * as outbound from './outboundReducers';
import {businessSummaryOnlineMerchant} from './onlinePanelReducers';
import transactions from './transactions';
import snackbar from './snackbarEmail';
import magicpaySubscription from './magicpaySubscription';
import groupBuy from './groupBuyLaunch';
import verifyPayment from './verifyInventoryPayment';
import inventoryRequestId from './inventoryRequestId';
import notifications from './notifications';
import bookDeal from './bookDeal';
import profile from './profile';
import genericAction from './genericAction';
import fbShare from './fbShare';
import diwaliExclusivePackage from './diwaliExclusivePackage';
import promotions from './promotions';
import walletSummary from './walletSummary';
import forgotPassword from './forgotPassword';
import newLandingPage from './newLandingPage';
import newMerchantLanding from './newMerchantLanding';
import merchantContent from './merchantContent'
import nyePopup from './nyePopup';
import * as brandReducers from './brandReducers';
import sendOtp from './sendOtp';
import merchantOnboarding from './merchantOnboarding';
import merchantSubscription from './merchantSubscription';
import myteam from './myteam';
import goalTracker from './goalTracker';
import upgradePackage from './upgradePackage'
import brandOnboarding from './brandOnboarding';
import upsellPayment from './verifyUpsellPayment'
import commission from './getMerchantCommission';
import brandPrivilegeProgram from './brandPrivilegeProgram';
import transferMoney from './transferMoney'
import updateCreditLimit from './updateCreditLimit'
import voucherRedemptionLeaderBoard from './voucherRedemptionLeaderBoard'
import smsCampaign from './smsCampaign'
import removedTxnStats from './removedTxnStats'
import conversionFunnelStats from './conversionFunnelStats'
import enachLanding from './enachLanding'
import verifyOtpMagicStore from "./verifyOtpMagicStore";
import appInstall from "./appInstall";
import newOnboarding from "./newOnboarding";
import helpDeskMP from "./helpDesk";
import verifyGst from "./verifyGst";
import magicOrder from '../../merchant-app-portal/Redux/MagicOrder';
import helpDesk from '../../merchant-app-portal/Redux/HelpDesk';
import fixedCashin from './fixedCashinLaunch';
import confirm from './utilityReducers/confirm';
import safeMerchant from './safeMerchant';
import loginSession from './loginSession';
import paymentGatway from './paymentGatway';
import leads from './leads'

const appReducer = combineReducers({
  routing: routerReducer,
  customerPage,
  postPage,
  localityByCity,
  merchantList,
  claimBusiness,
  merchantCrm,
  ...dealReducers,
  ...authReducers,
  ...parentMerchantReducers,
  ...adminReducers,
  ...dashboardReducers,
  ...feedReducers,
  businessSummaryOnlineMerchant,
  transactions,
  snackbar,
  magicpaySubscription,
  groupBuy,
  verifyPayment,
  inventoryRequestId,
  notifications,
  bookDeal,
  profile,
  genericAction,
  fbShare,
  promotions,
  walletSummary,
  forgotPassword,
  newLandingPage,
  merchantOnboarding,
  newOnboarding,
  helpDesk,
  newMerchantLanding,
  merchantContent,
  merchantSubscription,
  ...brandReducers,
  sendOtp,
  myteam,
  goalTracker,
  upgradePackage,
  brandOnboarding,
  upsellPayment,
  ...outbound,
  commission,
  brandPrivilegeProgram,
  transferMoney,
  updateCreditLimit,
  voucherRedemptionLeaderBoard,
  smsCampaign,
  diwaliExclusivePackage,
  removedTxnStats,
  conversionFunnelStats,
  verifyOtpMagicStore,
  appInstall,
  enachLanding,
  verifyGst,
  magicOrder,
  helpDeskMP,
  fixedCashin,
  confirm,
  safeMerchant,
  loginSession,
  paymentGatway,
  leads
});

export default function rootReducer(state, action){
  if(action.type == LOGOUT_SUCCESS){
    const { routing } = state
    state = { routing }
  }
  else if(action.type == SELECT_MERCHANT_SUCCESS){
    const { routing, auth } = state
    state = { routing, auth }
  }

  return appReducer(state, action);
}
