const VERIFY = 'verifyMerchant/VERIFY';
const VERIFY_SUCCESS = 'verifyMerchant/VERIFY_SUCCESS';
const VERIFY_FAIL = 'verifyMerchant/VERIFY_FAIL';

const DISCOURSE_LOADING = 'verifyMerchant/DISCOURSE_LOADING'
const DISCOURSE_SUCCESS = 'verifyMerchant/DISCOURSE_SUCCESS'
const DISCOURSE_FAILURE = 'verifyMerchant/DISCOURSE_FAILURE'

const REDEEM_ONLINE_TRAINING = 'verifyMerchant/REDEEM_ONLINE_TRAINING'
const REDEEM_ONLINE_TRAINING_SUCCESS= 'verifyMerchant/REDEEM_ONLINE_TRAINING_SUCCESS'
const REDEEM_ONLINE_TRAINING_FAILURE = 'verifyMerchant/REDEEM_ONLINE_TRAINING_FAILURE'

const initialState = {
  verified: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VERIFY:
      return {
        ...state,
        verifying: true
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verifying: false,
        verified: true,
      };
    case VERIFY_FAIL:
      return {
        ...state,
        verifying: false,
        verified: false,
        error: action.error
      };
    case DISCOURSE_LOADING:
      return {
        ...state,
        validating: true,
        validated: false
      }
    case DISCOURSE_SUCCESS:
      return {
        ...state,
        validated:true,
        validating:false,
        queryString:action.result
      }
    case DISCOURSE_FAILURE:
      return {
        ...state,
        validating:false,
        validated:false
      }
    case REDEEM_ONLINE_TRAINING:
      return{
        ...state,
        magic30VoucherRedeeming: true,
      }
    case REDEEM_ONLINE_TRAINING_SUCCESS:
      return{
        ...state,
        magic30VoucherRedeeming: false,
        magic30VoucherRedeemed: action.result,
        magic30VoucherRedeemingError: null
      }
    case REDEEM_ONLINE_TRAINING_FAILURE:
      return{
        ...state,
        magic30VoucherRedeeming: false,
        magic30VoucherRedeemed: null,
        magic30VoucherRedeemingError: action.error
      }
   default:
      return state;
  }
}

export function verifyMerchant(identifier) {
  window.localStorage.setItem('unconverted_identifier', identifier);
  return {
    types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
    promise: (client) => client.default.post(`/verifyMerchant`, {
      data: {identifier}
    })
  };
}

export function validateDiscoursePayload(accessToken, sso, sig, userName, avatar, groups) {
  return {
    types: [DISCOURSE_LOADING, DISCOURSE_SUCCESS, DISCOURSE_FAILURE],
    promise: (client) => client.default.post('/discourseAuth', {
      data: {accessToken, sso, sig, userName, avatar, groups}
    })
  }
}

export function redeemOnlineTraining(accessToken, merchantId){
  return {
    types: [REDEEM_ONLINE_TRAINING, REDEEM_ONLINE_TRAINING_SUCCESS, REDEEM_ONLINE_TRAINING_FAILURE],
    promise: (client) => client.default.post('/redeemOnlineTraining', {
      data: {accessToken, merchantId}
    })
  }
}


