import React from 'react'
import CustomDialog from '../CustomComponents/CustomDialog'
import "./securityAlert.css"

const SecurityAlert = (props) => {

    const {user, setShowSecurityAlert, showSecurityAlert, handleLogout} = props

    const customHeading = () =>{
    
        let headingDiv = 
              <div className = "custom-dialog-heading">
                  <p className = "custom-dialog-heading-name">Security update</p>
              </div>
      
        return headingDiv
    }

    const customContent = () =>{

        return(
        <div className = "content-body">
            <div>
                <p>
                Dear merchant, <br/><br/>
                To perform this action, you may need to prove it is you. <br/>
                <p style={{color: "red"}}>
                As an added security, please log out and log in again to verify your account.
                </p>
                This extra step helps protect your account.
                <br/>
                Thanks.
                </p>
            </div>
        </div>
        )
    }

    const actions = () => {
        return(
            <div className = "security-alert-actions">
                <button className = "custom-dialog-btn" onClick = {() => setShowSecurityAlert()}> Cancel </button>
                <button className = "custom-dialog-btn" onClick = {() => handleLogout()}> Log me out </button>
            </div>
        )
    }
    
    return (
        <div>
            <CustomDialog
                open = {showSecurityAlert}
                close = {setShowSecurityAlert}
                heading = {() => customHeading()}
                content = {() => customContent()}
                actions = {() => actions()}
            />
        </div>
    )
}

export default SecurityAlert
  