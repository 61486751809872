import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ios from '../../images/app-store.svg'
import android from '../../images/google-play-store-badge.svg'
import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';
import './AppDownloadPopup'
import { sendEvent } from '../../helpers/Helpers';
import moment from 'moment';

const styles = theme => ({
  root: {
    margin: 0,
    width: window.innerWidth > 768 ? "324px" : "250px"
  },
  closeButton: {
    position: 'absolute',
    right: "5px",
    top: "5px",
    color: "gray",
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "16px",
    textAlign: "center",
    backgroundImage: 'linear-gradient(135deg, #ef1c74, #2e0092)'
  },
}))(MuiDialogContent);

export default class AppDownloadPopup extends Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        sendEvent({
            event: "fire_app_download_popup",
            actor_id: this.props.user && this.props.user.merchantUserId,
            actor_type: this.props.user && this.props.user.userType,
            subject_merchant_id: this.props.user && this.props.user.merchantId,
            user_type: this.props.user && this.props.user.userType,
            datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            timestamp: Date.now()
        });
    }

    openPlayStore=(text)=>{
        if(text == 'android'){
            window.open("https://orderhere.io/getApp")
            sendEvent({
                event: "clicked_playstore_btn",
                actor_id: this.props.user && this.props.user.merchantUserId,
                actor_type: this.props.user && this.props.user.userType,
                subject_merchant_id: this.props.user && this.props.user.merchantId,
                user_type: this.props.user && this.props.user.userType,
                datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
                timestamp: Date.now(),
                device: window.innerWidth > 768 ? 'desktop' :'mobile',
              });
        }
        else{
            window.open("https://orderhere.io/getApp")
            sendEvent({
                event: "clicked_playstore_btn",
                actor_id: this.props.user && this.props.user.merchantUserId,
                actor_type: this.props.user && this.props.user.userType,
                subject_merchant_id: this.props.user && this.props.user.merchantId,
                user_type: this.props.user && this.props.user.userType,
                datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
                timestamp: Date.now()
            });
        }
        this.props.closePopUp()
    }

    clickedOutside=()=>{
        sendEvent({
            event: "clicked_outside_app_download_popup",
            actor_id: this.props.user && this.props.user.merchantUserId,
            actor_type: this.props.user && this.props.user.userType,
            subject_merchant_id: this.props.user && this.props.user.merchantId,
            user_type: this.props.user && this.props.user.userType,
            datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            timestamp: Date.now()
        });
        this.props.closePopUp()
    }

    render(){
        return(
            <Dialog onClose={() => this.clickedOutside()} aria-labelledby="customized-dialog-title" open={true} >
                <DialogContent dividers>
                    <div style={{marginTop: '-15px', textAlign: 'right', fontSize: '16px', color: '#ffffff'}}>
                       <i class="fa fa-close" onClick={()=> this.props.closePopUp()}></i>
                    </div>
                    <div>
                        <div style={{ fontSize: '18px', fontFamily: 'robotoregular', textAlign:'left',paddingLeft: '14px', color: '#fff'}}>
                        Accept orders easily with the 
                        <br/>magicpin - OrderHere app. 
                        <br/>Click to download now!
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', paddingLeft: '14px', paddingRight: '14px', paddingTop: '20px'}}>
                            <img src={android} style={{width: '115px', paddingRight: '8px'}} onClick={()=>{this.openPlayStore('android')}}/>
                            <img src={ios} style={{width: '112px'}} onClick={()=>{this.openPlayStore('ios')}}/>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }


}
