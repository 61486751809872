import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {changeLanguage} from '../../redux/modules/claimBusiness';
import { sendEvent, createMCPLog } from '../../helpers/Helpers';
import cross from "../../images/cross2.png";

export default connect(store => ({
  language: store.claimBusiness.language
}), dispatch => bindActionCreators({
    changeLanguage
}, dispatch))
(class LanguageBar extends Component{
  constructor(props){
    super(props);
}

  handleChange = (value) => {
    if(value != ""){
        this.props.changeLanguage(value);
        sendEvent({
          utm_campaign:localStorage.getItem('landingSource') || '',
          subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
          event: 'select_language',
          page_type:'select_language'
        })
        createMCPLog({
          phone: localStorage.getItem("subjectPhoneNumber") || '',
          utm_campaign: localStorage.getItem('landingSource') || '',
          merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'select_language'
        });
      }
    
  }

  render(){
    const country = this.props.country ? this.props.country.toLowerCase() : "india"
    const language = this.props.language || 'english';
    const categoryId = this.props.categoryId;
    return(
        
            <div className="select-lang">
                {window.innerWidth > 768 && <p>Choose your language</p>}
                <div className={"language-tab " + (language=="english" ? "active" : "")} value="english" onClick={() => this.handleChange("english")}>English</div>
                {country == "india" && <div className={"language-tab " + (language=="hindi" ? "active" : "")} value="hindi" onClick={() => this.handleChange("hindi")}>Hindi</div>}
                {country == "indonesia" && categoryId != 5 && <div className={"language-tab " + (language=="bahasa" ? "active" : "")} value="bahasa" onClick={() => this.handleChange("bahasa")}>Bahasa</div>}
                {country == "india" && <div className={"language-tab " + (language=="marathi" ? "active" : "")} value="marathi" onClick={() => this.handleChange("marathi")}>Marathi</div>}
                {country == "india" && <div className={"language-tab " + (language=="telugu" ? "active" : "")} value="telugu" onClick={() => this.handleChange("telugu")}>Telugu</div>}
                {country == "india" && <div className={"language-tab " + (language=="kannada" ? "active" : "")} value="kannada" onClick={() => this.handleChange("kannada")}>Kannada</div>}
                {window.innerWidth > 768 && <img onClick={this.props.closeLanguageBar} style={{float:"right", cursor:"pointer"}} src={cross}/>}
            </div>

    );
  }
})
