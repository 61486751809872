const LOAD = 'businessWithDates/LOAD';
const LOAD_SUCCESS = 'businessWithDates/LOAD_SUCCESS';
const LOAD_FAIL = 'businessWithDates/LOAD_FAIL';

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return action.caller ? {...state, [action.caller+'Loading'] : true} : {...state,loading: true}
    case LOAD_SUCCESS:
      return action.caller ? 
          {...state, [action.caller] : action.result, 
          [action.caller+'Loading']: false, 
          [action.caller+'Loaded']: true, 
          [action.caller+'Error']: null}
      :{...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.result}
    case LOAD_FAIL:
      return !action.caller ? 
        {...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error} 
        :{...state, [action.caller] : null,
          [action.caller+'Loading']: false,
          [action.caller+'Loaded']: false,
          [action.caller+'Error']: action.error}
   default:
      return state;
  }
}

export function loadBusinessWithDates(accessToken, startDate, endDate, caller, saasOnly, marketingOnly, currentSubscription) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post(`/loadBusinessWithDates?start_date=${startDate}&end_date=${endDate}`, {
      data: {accessToken, saasOnly, marketingOnly, currentSubscription}
    }),
    caller
  };
}
