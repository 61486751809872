export const hosts = {
  aryan: "https://aryan.magicpin.in",
  hop: "https://hop.magicpin.in",
  ultrontest: "https://ultrontest.magicpin.com",
  ultron: "https://ultron.magicpin.com",
  webapi: "https://webapi.magicpin.in",
  uig: "http://uig.api.magicpin.in",
  uigWithPort: "http://uig.api.magicpin.in:8081",
  locality: "https://locality.magicpin.in",
  delivery: "https://delivery.magicpin.com",
  magicpin_in: "https://magicpin.in",
  webapi_lom: "https://webapi.magicpin.in",
  ultron_magicpin: "http://ultron.magicpin.com",
  webapi_https: "https://webapi.magicpin.in",
  sales_magicpin: "http://sales.magicpin.in/onboarding",
  sales_ultron: "http://sales.magicpin.in/ultron-onboarding",
  sales: "http://sales.magicpin.in",
  ultron_mp: "https://webapi.magicpin.in/ultron-mp",
  orderhere: "https://orderhere.io",
  default: "https://magicpin.in/api",
};

export const CHROME_EXTENSION_ID = "cgaeclpcjkpehjliekjhheboboeofpnc"

export default {
  path: {
    server: 'https://magicpin.in/partners/',
    apiserver: 'https://magicpin.in/api',
    payment: 'https://magicpin.in/',
  },
  hosts: hosts,
  basename: 'partners', // this is used in routing so don't modify
  host: 'https://magicpin.in/partners',
  resourcepath: 'https://magicpin.in/partners',
  basepath: 'https://magicpin.in/partners/',
};