import moment from "moment";

const LOAD = 'customerPage/LOAD';
const LOAD_SUCCESS = 'customerPage/LOAD_SUCCESS';
const LOAD_FAIL = 'customerPage/LOAD_FAIL';
const LIKE = 'customerPage/LIKE';
const LIKE_SUCCESS = 'customerPage/LIKE_SUCCESS';
const LIKE_FAIL = 'customerPage/LIKE_FAIL';
const COMMENT = 'customerPage/COMMENT';
const COMMENT_SUCCESS = 'customerPage/COMMENT_SUCCESS';
const COMMENT_FAIL = 'customerPage/COMMENT_FAIL';
const GET_COMMENT = 'customerPage/GET_COMMENT';
const GET_COMMENT_SUCCESS = 'customerPage/GET_COMMENT_SUCCESS';
const GET_COMMENT_FAIL = 'customerPage/GET_COMMENT_FAIL';
const REVIEW = 'customerPage/REVIEW';
const REVIEW_SUCCESS = 'customerPage/REVIEW_SUCCESS';
const REVIEW_FAIL = 'customerPage/REVIEW_FAIL';
const HIDE_MODAL = 'customerPage/HIDE_MODAL';
const HIDE_LIKE_MODAL = 'customerPage/HIDE_LIKE_MODAL';
const RECENT_PLACES = 'customerPage/RECENT_PLACES';
const RECENT_PLACES_SUCCESS = 'customerPage/RECENT_PLACES_SUCCESS';
const RECENT_PLACES_FAIL = 'customerPage/RECENT_PLACES_FAIL';
const HIDE_RECENT_PLACES_MODAL = 'customerPage/HIDE_RECENT_PLACES_MODAL';
const DUMP_RECENT_PLACES = 'customerPage/DUMP_RECENT_PLACES';
const GET_POST = 'customerPage/GET_POST';
const GET_POST_SUCCESS = 'customerPage/GET_POST_SUCCESS';
const GET_POST_FAIL = 'customerPage/GET_POST_FAIL';


const initialState = {
  sharing: false,
  shared: false,
  fbDetails : [],
  fbMessage : null,
  loadedReview : false,
  loading: false,
  loaded: false,
  offset: 0,
  posts : [],
  moreData : true,
  start_time: null,
  end_time: null,
  postData : {},
  authorProfile : {},
  deal : {},
  commentModalData : {
   comments : null,
   dataLoaded : false
  },
  likeModalDatax : {
    userId:null,
  },
  recentPlacesData : {
    moreData : true,
    recentVisits : [],
    loaded : false ,
    loading : true,
    userId : null,
    txn_time : null,
    limit : 10
  }
}


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    return {
      ...state,
      loading: true,
      loaded: false,
      posts: action.offset == 0 ? [] : state.posts,
    };
    case LOAD_SUCCESS:
    
    const isCeetMerchant = action.isCeetMerchant

    const reviews = action.result || []

    let filtredReviews = []
    let similarPostStartDateObj = {}

    filtredReviews = !isCeetMerchant ? reviews : reviews.map((postOuter, indexOuter) => {
      let similarPostCount =  0
      let filterPostDateDiff = similarPostStartDateObj[postOuter.author_id] && postOuter.created_at && moment(similarPostStartDateObj[postOuter.author_id]).diff(moment(postOuter.created_at * 1000), 'days') || 0
      
      reviews.forEach((postInternal, indexInternal) => {
        similarPostCount = postOuter.author_id && postInternal.author_id && (postOuter.author_id === postInternal.author_id) && postInternal.similarPostCount || similarPostCount
        // let filterPostDateDiff = (!similarPostStartDateObj[postInternal.author_id] ||  moment(similarPostStartDateObj[postInternal.author_id]).diff(moment(postInternal.created_at), 'days') <= 15)
       
        let filterPost = postOuter && postInternal && postOuter.author_id && postInternal.author_id && (postOuter.author_id === postInternal.author_id) &&
                         (postOuter.rating === postInternal.rating )&& (indexOuter > indexInternal)

        if(filterPost && filterPostDateDiff <= 15){
          similarPostCount += 1
        }

        if(filterPostDateDiff > 15){
          similarPostStartDateObj[postOuter.author_id] = postOuter.created_at * 1000
          similarPostCount = 1
        }

      })

      if(similarPostCount === 1){
        similarPostStartDateObj[postOuter.author_id] = postOuter.created_at * 1000
      }

      postOuter.similarPostCount = similarPostCount
      return postOuter
    });

    filtredReviews =  !isCeetMerchant ? reviews : filtredReviews.filter(post => (!post.similarPostCount || post.similarPostCount <= 1) )

    const firstCall = action.lastPostId == '0' && action.lastPostTime == '0';
      /** we are pushing review widget after the posts in first call */
    // let calculateLastPostId = reviews && reviews.length > 1 ? reviews[reviews.length -1].post_id : null
    let calculateNewStart = reviews && Array.isArray(reviews) && reviews.length ? reviews.length : 0
    //** offset to be incremented by 10 regardless of number of posts returned as it is being used in solr
    return {
      ...state,
      loading: false,
      loaded: true,
      posts:  action.offset == 0 ? filtredReviews  : [...state.posts, ...filtredReviews],
      offset : action.offset + calculateNewStart,
      moreData : reviews && reviews.length != 0,
    };
    case LOAD_FAIL:
    return {
      ...state,
      loading: false,
      loaded: false,
      data: null,
      error: action.error
    };
    case REVIEW_SUCCESS :
    return{
      ...state,
      loadedReview : true,
      reviews: action.result
    };
    case REVIEW_FAIL :
    return{
      ...state,
      loadedReview : false,
      reviews: action.error
    };
    case REVIEW :
    return{
      ...state,
      loadedReview : false,
    }
    case GET_COMMENT :
    return {
      ...state,
      commentModalData : {
        data:action.data,
        comments : null
      }
    }
    case GET_COMMENT_SUCCESS :
    return {
      ...state,
      commentModalData : {
        data:action.data,
        comments : action.result,
        dataLoaded : true
      }
    }
    case LIKE :
    return {
      ...state,
      likeModalData : {
        userId : action.userId,
        postId : action.postId
      },
    }

    case LIKE_SUCCESS :
    return {
      ...state,
      likeModalData : {
        userId : action.userId,
        postId : action.postId
      },
      posts : state.posts.map(d =>
        (d.post_id == action.postId && !(d.like_id > 0 )) ? {...d, like_id: 1, likes: d.likes+1} : d),
    }

    case LIKE_FAIL :
    return {
      ...state,
      posts : state.posts.map(d =>
        (d.post_id == action.postId && d.like_id == 1 ) ? {...d, like_id: 1, likes: d.likes+1} : d)
    }
    case COMMENT_SUCCESS:
    return {
      ...state,
      posts : state.posts.map(d =>
        (d.post_id == action.postId ) ? {...d, comments: d.comments+1} : d)
    }
    case RECENT_PLACES :
    return action.userId != state.recentPlacesData.userId ? {
      ...state,
      recentPlacesData :  { ...state.recentPlacesData,
        recentVisits : [],
        userId : action.userId,
        txn_time : "",
        limit : 10,
        moreData : true,
        loading : true
      }
    } : {...state,
      recentPlacesData :  {
        ...state.recentPlacesData,
        loading : true
      }
    }
    case RECENT_PLACES_SUCCESS :
    var temp=[];
    temp.push.apply(temp,state.recentPlacesData.recentVisits);
    temp.push.apply(temp,action.result.data);
    return {
      ...state,
      recentPlacesData : {
        recentVisits : temp,
        userId : action.userId,
        loaded : true,
        loading : false,
        txn_time : action.result.next == -1 ? "":action.result.next,
        limit : 10,
        moreData : action.result.next == -1 ? false : true
      }
    }
    case DUMP_RECENT_PLACES :
    return {
      ...state,
      recentPlacesData : {
        ...state.recentPlacesData,
        recentVisits : []
      }
    }


    case GET_POST :
    return {
      ...state,
      data:action.data,
    }

    case GET_POST_SUCCESS :
    return {
      ...state,
      postData:action.result[0],
      authorProfile:action.result[0].author_profile,
      deal: action.result[0].deal,
    }
    default:
    return state;
  }
}

export function getPosts(accessToken, offset, sortby, merchantUserId, ultronToken, isCeetMerchant) {
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': ultronToken
  }
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.get(`https://webapi.magicpin.in/ultron-mp/merchant/${merchantUserId}/feed?offset=${offset}&limit=${isCeetMerchant ? 50 : 10}`,{
      data : {accessToken, offset, sortby, isCeetMerchant},
      headers: headers
    }),
    offset, isCeetMerchant
  };
}

export function like(accessToken, postId, userId){
  return {
    types: [LIKE,LIKE_SUCCESS,LIKE_FAIL],
    promise: (client) => client.default.post('/likePost',{
      data : { postId : postId,
       userId : userId,
       accessToken
     }
   }),
    postId,
    userId
  }
}

export function comment(userId,postId,content,accessToken){
  return {
    types: [COMMENT,COMMENT_SUCCESS,COMMENT_FAIL],
    promise: (client) => client.default.post('/postComment',{
      data : { p_id : postId,
       c_uid : userId,
       content : content,
       accessToken
     }
   }),
    postId
  }
}

export function getComments(userId,postId,accessToken){
  return {
    types: [GET_COMMENT,GET_COMMENT_SUCCESS,GET_COMMENT_FAIL],
    promise: (client) => client.default.post('/getComments',{
      data: {
        postId : postId,
        userId : userId,
        accessToken
      }
    }),
    data: {
      postId : postId,
      userId : userId,
    }
  }
}


export function getPost(postId,accessToken){
  return {
    types: [GET_POST,GET_POST_SUCCESS,GET_POST_FAIL],
    promise: (client) => client.default.post('/getPostData',{
      data: {postId,accessToken}
    })
  }
}

export function getRecentPlaces(userId,accessToken,txn_time,limit){
  return {
     types : [RECENT_PLACES, RECENT_PLACES_SUCCESS, RECENT_PLACES_FAIL],
     promise: (client) => client.default.post('/getRecentPlaces',{
      data: {
        userId,
        txn_time,
        limit,
        accessToken
      }
    }),
    userId
  }
}
export function dumpRecentPlaces(){
  return {
     type : DUMP_RECENT_PLACES
  }
}
