const LOAD_STATS = 'verifyGst/LOAD_STATS';
const LOAD_STATS_SUCCESS = 'verifyGst/LOAD_STATS_SUCCESS';
const LOAD_STATS_FAIL = 'verifyGst/LOAD_STATS_FAIL';

const intialState = {
    gstVerified: false,
  };

export default function verifyGst(state = intialState, action = {} ){
	switch(action.type){
	  case LOAD_STATS:
        return {
          verifyingGst: true,
          gstVerified: false
        }
      case LOAD_STATS_SUCCESS:
          return {
            verifyingGst: false,
            gstVerified: true
          }
      case LOAD_STATS_FAIL:
          return {
              verifyGst: false,
              gstVerified: false
          }
    default:
      return state;
	}
}

export function verifyGstAfterActivation(accessToken, gstNumber="", gstAvailable, gstScheme = "", panNumber = ""){
    return{
      types:[LOAD_STATS, LOAD_STATS_SUCCESS, LOAD_STATS_FAIL],
      promise:(client) => client.default.post('/verifyGst',{
        data:{accessToken, gstNumber, gstAvailable, gstScheme, panNumber}
      })
    }
  }