import React, { Suspense } from 'react';
import DatePicker from 'material-ui/DatePicker';
import Popover from 'material-ui/Popover';
import moment from 'moment';
import {toast} from 'react-toastify';
import { compressToBlob, getPlaceHolder, scrollBy } from '../../helpers/Helpers';
import DeductMoney from './DeductMoney';
import leftArrow from '../../images/promo-left-arrow.svg';
import rightArrow from '../../images/promo-right-arrow.svg';
import infoIcon from '../../images/info-icon.png';
import constants from '../../constants';
import { easings } from '../../helpers/Utilities'
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import { sendEvent } from '../../helpers/Helpers';
import { loadAvailablePromotions, requestPromotion, feedPushActiveCounter } from '../../redux/modules/promotions';
import { countriesInfo } from '../../constants';
import { getWalletBalance } from '../../redux/modules/walletSummary';
import {getMembers} from '../../redux/modules/myteam.js';
import { loadPromotions } from '../../redux/modules/promotions';
import './createPromotion.css';
import { Button } from 'react-bootstrap';
import { getFeedShortcutsStatus } from '../../redux/modules/feedReducers/feedShortcutsStatus';
import {getWithdrawalStatus } from '../../redux/modules/walletSummary';
import { updateSubscription} from '../../redux/modules/merchantSubscription';
import circleArrow from '../../images/left-arrow-pink.svg';
import circleDown from '../../images/circle-arrow-down.png'
import mask from '../../images/mask.png';
import feedpushTop from '../../images/feedpushTop.svg';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

const VoucherRelisting = React.lazy(() => import('../Vouchers/VoucherRelisting'))
const EditImage  = React.lazy(()=>import('../EditImage/EditImage'))

const ga = window.ga;
export default connect(store => ({
  requestingPromotion: store.promotions.requesting,
  requestedPromotion: store.promotions.requested,
  requestPromotionError: store.promotions.requestError,

  availableLoading: store.promotions.availableLoading,
  availableLoaded: store.promotions.availableLoaded,
  availablePromotions: store.promotions.availablePromotions,
  availableError: store.promotions.availableError,

  promotions: store.promotions.promotions,
  members: store.myteam.members,
  membersLoaded: store.myteam.membersLoaded,

  walletBalance: store.walletSummary.walletBalance,
  isMerchantOnWallet: store.walletSummary.isMerchantOnWallet,
  feedShortcutsStatusData: store.feedShortcutsStatus.feedShortcutsStatusData,

  registeredBankDetails: store.walletSummary.registeredBankDetails,
  loadingWithdrawalStatus: store.walletSummary.loadingWithdrawalStatus,
  withdrawalStatusError: store.walletSummary.withdrawalStatusError,

  updating: store.merchantSubscription.updating,
  updateResult: store.merchantSubscription.updateResult,
  updateError: store.merchantSubscription.updateError,

  feedpushedCounter: store.promotions.feedpushedCounter,
  
  remarketingStatus : store.cashVouchers.remarketingStatus,

}), dispatch => bindActionCreators({
  loadAvailablePromotions,
  requestPromotion,
  getWalletBalance,
  getMembers,
  loadPromotions,
  getFeedShortcutsStatus,
  getWithdrawalStatus,
  updateSubscription,
  feedPushActiveCounter
}, dispatch))
(class CreatePromotion extends React.Component{
  constructor(props){
    super(props);
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 2);
    this.state = {
      banner: null,
      bannerText: "",
      templateSelected: null,
      selectedDate: this.minDate,
      showMoreTemplates: false,
      deductMoney: false,
      showBannerEditModal: false,
      showTemplateEditModal: false,
      promotionBannerFile:null,
      popoverImageStart:0,
      popoverImageEnd:16,
      src:null,
      templateFile: null,
      embedTextOnImage: true,
      bankAccountExists: true,
      showVoucherRelisting: false,
      promotionAfterRelist: false,
      concaveSelected: false,
      curvedDesignSelected: true,
      disablePromotionBtn: false
    };

  }

  checkPromotionRequestValidity=()=>{

    ga("send", "event", "Promote_Launch", "home_page_promote_click", "home_page_promote_click");
    sendEvent({
        event: 'home_page_promote_click',
        page_type: 'merchant_home',
        page_name: 'home',
        actor_id: this.props.user.merchantId,
        subject_merchant_id: this.props.user.merchantId,
      });    

    if(!this.props.remarketingStatus){
      this.showVoucherDelisted()
      return
    }

    if(this.props.promotions){
      const publishDate = moment(this.state.selectedDate).format('YYYY-MM-DD');
      const promotions = this.props.promotions;
      let promotion = promotions.find(promotion => promotion.status.toLowerCase() === 'pending' && publishDate === moment(promotion.dispatch_date).format('YYYY-MM-DD'));
      if(promotion){
         toast.warn(<div style={{textAlign:'center'}}>
              Your promotion request is pending for this date. Please choose some other date or remove the previous request
              <Button onClick={()=>this.props.push('/promotions')} style={{marginTop:'8px',color:"white",backgroundColor:"#00bfa5"}}>View Promotions</Button>
              </div>,{autoClose: false})
         return
      }
    }
    if(this.props.availablePromotions[0] && this.props.availablePromotions[0].is_runnable && this.props.availablePromotions[0].runnable_count > 0){
      //runnable
      this.requestPromotion();
    }
    else{
      //deduce money
      this.setState({deductMoney:true});
    }
  }

  unmountDeductState = () => {
    this.setState({deductMoney:false});
  }
  requestPromotion = () => {

    // const key = `feedPushPromotion_${moment(this.state.selectedDate).format('YYYY-MM-DD')}_${this.props.user.merchantId}`
    // const now = new Date()

    // if(localStorage.getItem(key) && now.getTime() < localStorage.getItem(key))
    // {
    //   toast.info('Your promotion request is pending for this date. Please choose some other date')
    //   return
    // }

    if(!this.props.remarketingStatus){
      this.showVoucherDelisted()
      return
    }

    if(this.state.bannerText.trim() === ''){
      toast.warn('Please write something about your store',)
      return
    }

    if(!(this.state.templateSelected || this.state.templateFile || this.state.banner)){
      toast.warn('Please attach an image',)
      return
    }
    const promotion = {
      type: 'Feed push',
      typeId: 1,
      dispatchDate: moment(this.state.selectedDate).format('YYYY-MM-DD'),
      message: this.state.bannerText,
      merchantName: this.props.user.merchantName && this.props.user.merchantName.length >=26 ? this.props.user.merchantName.substring(0, 26) + "..." : this.props.user.merchantName ,
      merchantId: this.props.user.merchantId,
      merchantLogo: this.props.user.profilePic,
      locality: this.props.user.locality,
      templateUrl: (this.state.templateFile || this.state.banner) ? null : this.state.templateSelected,
      embedTextOnImage: this.state.embedTextOnImage,
      ctaText: this.state.bannerText && (this.state.bannerText.includes('order') || this.state.bannerText.includes('Order') || this.state.bannerText.includes('ORDER')) ? 'Order Now': 'Visit Us Now',
      config: this.state.curvedDesignSelected ? "banner_feed_push" : "banner_feed_push_centered",
      templateType: this.state.curvedDesignSelected ? "convex" : "centered"
    };

    let files = this.state.banner ? [this.state.promotionBannerFile]: [];
    files = this.state.templateFile ? [...files, this.state.templateFile] : files;

    // if(!this.state.bankAccountExists && this.props.feedShortcutsStatusData && !this.props.feedShortcutsStatusData.isRedemptionOptionLive)
    // {
    //   this.showVoucherDelisted()
    //   return;
    // }
    // else if(this.state.bankAccountExists && this.props.feedShortcutsStatusData && !this.props.feedShortcutsStatusData.isRedemptionOptionLive)
    // {
    //   toast.warn('Relisting your vouchers')
    //   this.props.updateSubscription(this.props.user.accessToken,true, this.props.user.merchantName, 'reason not required for new flow', null)
    //   .then(()=>
    //   this.setState({promotionAfterRelist: true})
    //   )
    //   .then(()=>
    //   this.props.promotionAfterRelist && this.props.promotionAfterRelisting()
    //   )
    //   .then(()=>
    //   {
    //     if(!this.props.updating){
    //       if(this.props.updateError)
    //           toast.error(this.props.updateError.message)
    //       else{
    //           if(files.length){     
    //             compressToBlob(files[0])
    //             .then(blob => this.props.requestPromotion(this.props.user.accessToken, promotion, [blob], files[0].name))
    //             .catch(err => this.props.requestPromotion(this.props.user.accessToken, promotion, files, files[0].name));
    //           }
    //           else{
    //             this.props.requestPromotion(this.props.user.accessToken, promotion, files, 'profile');
    //           }
    //         }
    //   }
    //   })
    //   .catch(err =>
    //     {
    //     if(this.props.updateError)
    //           toast.error(this.props.updateError.message)
    //     }
    //   )     
      
    //   return;
    // }

    if(files.length){     
      compressToBlob(files[0])
      .then(blob => this.props.requestPromotion(this.props.user.accessToken, promotion, [blob], files[0].name))
      .catch(err => this.props.requestPromotion(this.props.user.accessToken, promotion, files, files[0].name));
    }
    else{
      this.props.requestPromotion(this.props.user.accessToken, promotion, files, 'profile');
    }
    ga('send', 'event', 'Feed', 'Run Promotion', 'Feed push from feed');
  }

  // storeInLocal = (promotion) => {
  //   const now = new Date()
  //   const expiry = now.getTime() + 43200000
  //   const key = `feedPushPromotion_${promotion.dispatchDate}_${promotion.merchantId}`
  //   localStorage.setItem(key, expiry)
  // }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.requestingPromotion && this.props.requestedPromotion && this.state.promotionAfterRelist){
      toast.success('We have relisted your vouchers and submitted your promotion request. We will review the promotional text and publish it.');
      this.props.getWalletBalance(this.props.user.accessToken)
      this.props.loadPromotions(this.props.user.accessToken, 0, 'all', this.props.user.userId);
      // this.props.feedPushActiveCounter(this.props.user.accessToken);
      setTimeout(() => {
        window.location.reload(); 
      }, 6000);
      
    }

    else if(prevProps.requestingPromotion && this.props.requestedPromotion){
      toast.success('Your request is submitted. We will review the promotional text and publish it.');
      this.props.getWalletBalance(this.props.user.accessToken)
      this.props.loadPromotions(this.props.user.accessToken, 0, 'all', this.props.user.userId);
      // this.props.feedPushActiveCounter(this.props.user.accessToken);
      this.setState({disablePromotionBtn: true})

      setTimeout(() => {
        // window.location.reload(); 
        this.props.getWalletBalance(this.props.user.accessToken)
        this.props.loadPromotions(this.props.user.accessToken, 0, 'all', this.props.user.userId);
        // this.props.feedPushActiveCounter(this.props.user.accessToken);
        this.setState({disablePromotionBtn: false})
      }, 2000);
    }

    if(prevProps.requestingPromotion && this.props.requestPromotionError){
      toast.error(this.props.requestPromotionError.message);
    }

    if(prevProps.availableLoading && !this.props.availableLoading && Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length){
      this.setInitialImage();
    }

    if(prevProps.loadingWithdrawalStatus && !this.props.loadingWithdrawalStatus){
      if(this.props.withdrawalStatusError){
          console.log("error in createPromotion didupdate")
      }
      else{
          this.setBankStatus();
      }
  }
   }

   showVoucherDelisted = () => {
    this.setState({showVoucherRelisting: !this.state.showVoucherRelisting})
  }
  
   setInitialImage = ()=>{
    this.promotionTextArray=[`Visit ${this.props.user.merchantName} and get upto 20% savings through magicpin.`];
    const intialTemplateIndex = Math.floor(Math.random()*5);//any one of first five to make sure only template images are selected
    let voucherTemp="";
    if( Array.isArray(this.props.availablePromotions[0].available_vouchers)){
      for(let voucher of this.props.availablePromotions[0].available_vouchers.filter(voucher=>voucher.voucher_type === 'cash')){
        voucherTemp = `Buy voucher to ${voucher.coupon_info}`;
        this.promotionTextArray.push(...[voucherTemp, ""]);
      }
    }
    const initialTextLength = Math.floor(Math.random()*this.promotionTextArray.length);
    const templateSelected = this.props.availablePromotions[0].templates[intialTemplateIndex] || this.props.availablePromotions[0].templates[0];
    let templateImage = new Image();
    templateImage.addEventListener('load',event=>{
      let aspectRatio = event && event.path && Array.isArray(event.path) && event.path.length ? event.path[0].naturalWidth / event.path[0].naturalHeight : 0
      if((aspectRatio > 1.65 && aspectRatio < 1.67)) this.setState({templateSelected})
    })
    templateImage.src = templateSelected;
    this.setState({bannerText:this.promotionTextArray[initialTextLength]})
   }

  componentDidMount(){ 
    if(!this.props.user) return
    if(!this.props.availablePromotions) {
      let countryAbbr = 'IN';
      if(this.props.user.country) {
        countryAbbr = countriesInfo[this.props.user.country.toUpperCase()]['abbr'];
      }
      this.props.loadAvailablePromotions(this.props.user.accessToken, countryAbbr);
    }
    this.props.getWalletBalance(this.props.user.accessToken);
    if(!this.props.members) this.props.getMembers(this.props.user.accessToken);
    if(Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length)
      this.setInitialImage();

    if(!this.props.feedShortcutsStatusData)
    {
      this.props.getFeedShortcutsStatus(this.props.user.accessToken);
    }

    if(!this.props.registeredBankDetails && !this.props.loadingWithdrawalStatus)
       this.props.getWithdrawalStatus(this.props.user.accessToken).then(()=>this.setBankStatus())
    if(this.props.registeredBankDetails) this.setBankStatus();

    // if(!this.props.feedpushedCounter)
    // {
    //   this.props.feedPushActiveCounter(this.props.user.accessToken);
    // }
  }

  setBankStatus = () => {
    this.setState({
        bankAccountExists: !!(this.props.registeredBankDetails &&
        this.props.registeredBankDetails.length > 0 &&
        this.props.registeredBankDetails[0].bankAccountNumber)
    })
   }

  onEditSuccess = (file, src) => {
    if(src){
      if(this.state.showTemplateEditModal){
        this.setState({templateFile: file, promotionBannerFile: null, banner: null, templateSelected: src, embedTextOnImage: true});
      }
      else{
        this.setState({promotionBannerFile: file, banner: src, templateSelected: null, embedTextOnImage: false});
      }
    }
    this.setState({showBannerEditModal:false, showTemplateEditModal:false})
  }
  handlePromotionTextChange = (e) => {
    if(e.target.value.length >  125) return
    this.setState({bannerText: e.target.value})
    let fakeDiv = document.getElementsByClassName("fakeDiv")[0];
    fakeDiv.innerHTML = e.target.value;
    e.target.style.height = `${fakeDiv.clientHeight}px`;
  }

  templateClicked = (src, index, type)=>{
    let imageSelector=""
    if(type === 'direct')
    {
      imageSelector = "#promotion-templates-wrapper li a img";
    }
    else if(type === 'popover')
    {
      imageSelector = ".promotion-popover-image img";
    }
    else{
      console.log("type not provided. unable to determine node");
      return null;
    }
    const Width = document.querySelectorAll(imageSelector)[index].naturalWidth;
    const Height = document.querySelectorAll(imageSelector)[index].naturalHeight;
    if(typeof Width !== "undefined" && Width === 0){
      toast.warn("You have selected a broken image, Try to clear cache and reload again");
    }
    else if(Math.abs((Width / Height) - constants.feedPushTemplateAspectRatio) >= 0.1 ){
      toast.warn("Images without 10/6 ratio don't look good on mobile. Please crop. Ratio is given for you.")
      this.setState({showTemplateEditModal: true, src:src, showMoreTemplates: false, embedTextOnImage:true})
    }
    else{
      this.setState({templateSelected:src, banner:null, showMoreTemplates: false, embedTextOnImage: true})
    }

  }


  nextImageGroup=(direction, number)=>{
    if(this.state.popoverImageStart === 0 && direction === -1  || this.state.popoverImageEnd >= this.props.availablePromotions[0].templates.length && direction === 1) return;
    this.setState({popoverImageStart:this.state.popoverImageStart + direction*number,popoverImageEnd: this.state.popoverImageEnd + direction*number});
  }

  onChange = (event)=>{
    if(document.getElementById('file-input').files.length){
      this.setState({promotionBannerFile:document.getElementById('file-input').files[0]},()=>this.setState({showBannerEditModal: true}))
    }
    event.target.value=null;
  }

  ConvexConcave = () => {
    return (
      <div className={"feed-rp-banner-txt " + (this.state.templateSelected ? 'pattern-style' : '')}
      style={{ border: "1px solid #ddd", margin : '5px 15px 0px 15px', minHeight:this.state.templateSelected ? "180px":"50px", background:this.state.templateSelected ? "linear-gradient(cyan,transparent),linear-gradient(-45deg,magenta,transparent),linear-gradient(45deg,yellow,transparent)" : null, backgroundBlendMode:"repeat"}}>
      {this.state.templateSelected && <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerWidth > 768 ? "280px" : "230px",
        overflow: "hidden",
        borderRadius: "8px"
      }}><div style={{background: 'white', height: window.innerWidth > 768 ? "280px" : "230px", width: "75%", display: "flex",flexDirection: 'column', borderRadius: this.state.concaveSelected ? "0% 50% 50% 0%" : "", overflow: this.state.concaveSelected ? "overlay" : ""}}>
       <div style={{padding: window.innerWidth > 768 ? '16px 0 0 24px' : '8px 0 0 8px', marginBottom: "5%"}}>
          <div className={`custpmer-img-thumb ${this.props.user ? '' : 'loadingShimmer'}`} style={{borderRadius:'4px', width:'36px', padding: "1%", backgroundColor: "#fff", border: "0.9px solid rgba(0,0,0,0.12)"}}>
            {this.props.user && <img src={this.props.user.profilePic} alt="profile pic" />}
          </div>
          <div className={`customer-hd-txt ${this.props.user ? '' : 'loadingShimmer'}`} style={{marginTop: "4px"}}>
            {this.props.user && 
            <><span className="notranslate">{this.props.user.merchantName && this.props.user.merchantName.length >=26 ? this.props.user.merchantName.substring(0, 26) + "..." : this.props.user.merchantName }</span>
            <span className="note-txt">{this.props.user.locality}</span></> }
          </div>
        </div>
      {this.state.templateSelected && 
      <div style = {{height: "70%", textAlign: "left", paddingLeft: window.innerWidth > 768 ? '24px' : "8px", display: "flex", alignItems: "center", marginBottom: "2%"}}>
        <textarea
          style={{
            width: '70%',
            minHeight: window.innerWidth > 768 ? '130px' : '90px',
            border: '0 none',
            outline: 'none',
            fontSize: window.innerWidth > 768 ? this.state.bannerText && this.state.bannerText.length > 90 ? '14px' : '20px' : "14px",
            resize: 'none', 
            color: 'rgba(0,0,0,0.87)', 
            textAlign:'left',
            lineHeight: window.innerWidth > 768 ? '28px' : '20px',
            fontFamily: 'robotomedium',
            textShadow: this.state.bannerText ? 'none':'0 0 black',
            // paddingLeft: window.innerWidth > 768 ? '24px' : "8px",
            // height: this.state.bannerText ? `${(Math.ceil(this.state.bannerText.length / 18) * (window.innerWidth > 768 ? 26 : 13 ))}px` :'unset',
            // textDecoration: this.state.bannerText ? "underline" : "",
            // textUnderlinePosition: this.state.bannerText ? "under" : ""
          }} 
          className="template"
          value={this.state.bannerText}
          placeholder={`${getPlaceHolder(this.props.user.state || this.props.user.country, 'placeholder')}...\nWrite about your store...`}
          onChange={this.handlePromotionTextChange}
          autoFocus={window.innerWidth > 768}>
        </textarea>
      </div>
      }
      {/* <span>{`${this.state.bannerText || 0} / 125`}</span> */}
      {this.state.templateSelected && 
      <div style={{padding: window.innerWidth > 768 ? '0 0 24px 24px' : ' 0 0 8px 8px'}}>
         <div className={`col-md-5 col-sm-6 col-xs-6 large-btn promote-btn`} style={{backgroundColor: 'rgb(239, 28, 116)', border: 'none', padding: '10px', width: window.innerWidth > 768 ? '140px' : '100px', cursor: 'none', height: window.innerWidth > 768 ? '40px' : '35px', fontSize: window.innerWidth > 768 ? '14px' : '12px' }}>
          {this.state.bannerText && (this.state.bannerText.includes('order') || this.state.bannerText.includes('Order') || this.state.bannerText.includes('ORDER')) ? 'Order Now': 'Visit Us Now'}
        </div>
      </div>
      }

      </div>
        <div style={{background: 'transaparent', height: window.innerWidth > 768 ? "355px" : "302px", width: "45%", borderRadius: this.state.concaveSelected ? "" : "50% 0 0 50%", display: "inline-block", marginLeft: "-20%", overflow: this.state.concaveSelected ? "" : "hidden", padding: "36px 0px"}}>
         <img alt="" style={{height:"100%", borderRadius: '8px', float:"left", marginLeft: '-170px'}} src={this.state.templateSelected} />
        </div>
      </div>}

      
        <div className="fakeDiv" style={{fontSize:this.state.templateSelected ? '24px' : '16px', visibility:'hidden', width:'100%', position:'absolute', left:'0', top:'0', overflowWrap:'break-word'}}>

      </div>
      {(this.state.banner || !this.state.templateSelected) && 
        <div style={{marginBottom:"5px", padding:"10px", verticalAlign:"center", minHeight:"35px", borderRadius:"4px"}}>
          <textarea
            style={{
              width: '100%', 
              fontSize:'16px', 
              color:"#3e5561", 
              border: '0 none', 
              background: 'none', 
              overflow: 'hidden',
              outline: 'none',
              resize:"none", 
              textAlign:'left',
              minHeight:'40px',
            }}
            className="lp-text-area-cls banner"
            value={this.state.bannerText}
            autoFocus={window.innerWidth > 768}
            placeholder={`${getPlaceHolder(this.props.user ? this.props.user.state : 'hello', 'placeholder')}...\nWrite about your store...`}
            onChange={this.handlePromotionTextChange}>
          </textarea>
        </div>}
        {!(this.state.banner || this.state.templateSelected) && this.props.availableLoading && <div className="loadingShimmer" style={{width:'auto', paddingBottom:'56.25%', borderRadius:'8px'}}></div>}
    </div>
    )
  }

  TextInBW = () => {
    return (
      <div className={"feed-rp-banner-txt " + (this.state.templateSelected ? 'pattern-style' : '')}
      style={{position: 'relative', margin : '5px 15px 0px 15px', minHeight:this.state.templateSelected ? "180px":"50px", background:this.state.templateSelected ? "linear-gradient(cyan,transparent),linear-gradient(-45deg,magenta,transparent),linear-gradient(45deg,yellow,transparent)" : null, backgroundBlendMode:"repeat"}}>
      {this.state.templateSelected && <img alt="" style={{width: '100%', height: window.innerWidth > 768 ? "280px" : "230px", borderRadius: '8px', objectFit:"cover"}} src={this.state.templateSelected} />}
      {this.state.templateSelected && 
        <textarea
          style={{
            width: '98%',
            minHeight:'60px',
            height:'90px',
            border: '0 none',
            background: 'none',
            overflow: 'hidden',
            outline: 'none',
            fontSize:'24px',
            resize: 'none', 
            color: 'inherit', 
            textAlign:'center', 
            textShadow:"#00000080 0px 2px 4px"
          }} 
            className="lp-text-area-cls template"
          value={this.state.bannerText}
          placeholder={`${getPlaceHolder(this.props.user.state || this.props.user.country, 'placeholder')}...\nWrite about your store...`}
          onChange={this.handlePromotionTextChange}
          autoFocus={window.innerWidth > 768}>
        </textarea>
      }
        <div className="fakeDiv" style={{fontSize:this.state.templateSelected ? '24px' : '16px', visibility:'hidden', width:'100%', position:'absolute', left:'0', top:'0', overflowWrap:'break-word'}}>

      </div>
      {(this.state.banner || !this.state.templateSelected) && 
        <div style={{marginBottom:"5px", padding:"10px", verticalAlign:"center", minHeight:"35px", border:"1px solid rgba(0,0,0,0.12)", borderRadius:"4px"}}>
          <textarea
            style={{
              width: '100%', 
              fontSize:'16px', 
              color:"#3e5561", 
              border: '0 none', 
              background: 'none', 
              overflow: 'hidden',
              outline: 'none',
              resize:"none", 
              textAlign:'left',
              minHeight:'40px',
            }}
            className="lp-text-area-cls banner"
            value={this.state.bannerText}
            autoFocus={window.innerWidth > 768}
            placeholder={`${getPlaceHolder(this.props.user ? this.props.user.state : 'hello', 'placeholder')}...\nWrite about your store...`}
            onChange={this.handlePromotionTextChange}>
          </textarea>
        </div>}
        {!(this.state.banner || this.state.templateSelected) && this.props.availableLoading && <div className="loadingShimmer" style={{width:'auto', paddingBottom:'56.25%', borderRadius:'8px'}}></div>}
    </div>
    )
  }

  render(){

    const runnableCount = (Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length && this.props.availablePromotions[0].runnable_count) || 0
  
    return(
      <div id="promotionCard" className="white-box">
        <div style={{backgroundColor: '#f7f7fa', marginBottom: '17px', padding: '16px', backgroundImage: ` url(${feedpushTop})`, backgroundRepeat: "no-repeat", backgroundPositionX: "right", backgroundPositionY: "bottom"}}>
          <h4 style={{fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', margin: 0,marginBottom: '4px'}}>Promote your outlet</h4>
          <p style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)', margin: 0}}>Create an offer, event, or outlet update that will excite users to visit</p>
          {this.state.banner ? null :
          <>
            <p style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)', margin: 0}}>Select from the templates below</p>
            <div style = {{marginTop: "2%"}}>
              <img className={`feed-push-template-select ${this.state.curvedDesignSelected ? 'template-selected' : ''}`} alt="templates" src={mask} style={{background: ` url(${this.state.templateSelected})`}} onClick = {() => this.setState({curvedDesignSelected: true})}/>
              <img className={`feed-push-template-select ${this.state.curvedDesignSelected ? '' : 'template-selected'}`} alt="templates" src={this.state.templateSelected || mask} style={{marginLeft: "2%"}} onClick = {() => this.setState({curvedDesignSelected: false})}/>
            </div>
          </>}
        </div>
        <div>
          {this.state.curvedDesignSelected ?
          <>
            {this.ConvexConcave()}
          </>:
          <>
            {this.TextInBW()}
          </>
          }
          {this.state.banner && <div style={{margin:'0 16px', width:'auto', borderRadius: '8px',  backgroundColor: '#f7f7fa',
            border: 'solid 1px #d8dae1'}}>
            <img alt="banner image" src={this.state.banner} style={{borderRadius: '8px'}} width='100%'/>
          </div>}
          <div style={{width:'100%', display:'inline-block', padding: '0 16px'}}>
            {(this.state.showBannerEditModal || this.state.showTemplateEditModal) &&
              <Suspense fallback="loading">
                <EditImage
                onHide={()=>this.setState({showTemplateEditModal:false, showBannerEditModal: false, src: ""})}
                show={this.state.showTemplateEditModal || this.state.showBannerEditModal} 
                aspectRatio={100/60}
                uploading = {this.props.requestingPromotion}
                src={this.state.src ? this.state.src : ""}
                file={this.state.promotionBannerFile ? this.state.promotionBannerFile : null}
                mimeType={this.state.src ? "image/"+this.state.src.split(".").pop() : this.state.promotionBannerFile.type}
                confirm={this.onEditSuccess}
                rotatable={true}
                uploadingText="CROPPING"
                uploadText="CROP TO CONTINUE"
              />
            </Suspense>
            }
          </div>
          <div className="feed-rp-upload-btn-wrapper">
              {window.innerWidth <= 768 && 
                  <div className="leftArrow pointer" onClick={()=>scrollBy(this.refs.horizontalImageContainer, 'left', 150, 200, easings.easeInExpo)}>
                    <img src={circleArrow} width="20px" height="20px"/>
                  </div>
              }
            <div style={{margin:"0px 15px 0px 15px"}}>
            <div ref='horizontalImageContainer' id="horizontalImageContainer" style={{width:"100%",display:"flex", height:"75px", overflowY:"hidden"}}>
                <ul id="promotion-templates-wrapper" style={{marginTop: '4px', fontSize:0}}>
                  {Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length && this.props.availablePromotions[0].templates.length && this.props.availablePromotions[0].templates.map((template, index) =>
                    <li key={index}>
                      <a style={{color: '#fff'}} className={this.state.templateSelected == template ? 'selected' : ""} onClick={()=> this.templateClicked(template,index, 'direct')}>
                        <img className="loadingShimmer" alt="templates" src={template} style={{width:'100%', height:'100%',objectFit:"cover"}} />
                      </a>
                    </li>
                  )}
                  {this.props.availableLoading && Array(10).fill(0).map((num,idx)=>
                    <li key={idx}>
                    <a className="loadingShimmer">
                    </a>
                  </li>)}
                </ul>
              </div>
            </div>
            {window.innerWidth <= 768 ?
                  <div className="rightArrow pointer" onClick={()=>scrollBy(this.refs.horizontalImageContainer,'right', 150, 200, easings.easeInExpo)}>
                    <img src={circleArrow} width="20px" height="20px"/>
                  </div>
            :
            <div className="downArrow pointer" onClick={() => this.setState({showMoreTemplates: !this.state.showMoreTemplates})}
                    ref = 'moreTemplates'>
                    <img src={circleArrow} width="20px" height="20px"/>
                    
            </div>
            }
            </div>

        </div>
          <Popover
            style={{width: '344px'}}
            open={this.state.showMoreTemplates}
            anchorEl={this.refs.moreTemplates}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            onRequestClose={() => this.setState({showMoreTemplates: false})}
          >
            <div style={{width: '100%'}} className="pop-over">
              <p style = {{padding: "5%", fontFamily: "robotomedium", borderBottom: "1px solid #d8d8d8", display: "flex", justifyContent: "space-between"}}>
                Choose background
                <span style = {{cursor: "pointer"}} onClick = {()=>this.setState({showMoreTemplates: !this.state.showMoreTemplates})}>&#x2715;</span>
              </p>
              {this.state.showMoreTemplates && 
              <div className= "promotion-popover-wrapper" style={{height: "300px"}}>
                {this.props.availablePromotions && this.props.availablePromotions[0].templates.map((template, index) =>
                  <span key={index} className="promotion-popover-image pointer" style={{color: '#fff'}} onClick={()=> this.templateClicked(template, index, 'popover')}>
                    <img src={template}/>
                  </span>
                )}
                {/* <span className="arrow-container">
                  <span className="left pointer" onClick={()=>this.nextImageGroup(-1, 16)}>
                    <img src={leftArrow}/>
                  </span>
                  <span className="right pointer" onClick={()=>this.nextImageGroup(1, 16)}>
                    <img src={rightArrow}/>
                  </span>
                </span> */}
            </div>}
          </div>
        </Popover>
        <div style={{display:"inline-block", width:"100%", padding:'5px 15px 0 10px'}}>
          <label htmlFor="file-input" className="pull-right attach-banner pointer">
                  <span className="icon"><i className="fa fa-plus">&nbsp;</i></span>
                  Attach banner
          </label>
          <input 
            ref='fileInput' 
            id="file-input" 
            type="file" 
            accept=".png, .jpg, .jpeg, .webp"
            style={{display:'none'}}
            onChange={this.onChange}
          /> 
          <div  className="pull-left promotion-guidelines" style={{cursor:"pointer"}}>
                  <a style={{color:"rgba(0,0,0,0.87)", fontFamily: 'robotoregular'}} className="icon" href="https://s3-ap-southeast-1.amazonaws.com/magicpin-image-assets/Remarketing-Pics/promotiontemplates/PromotionGuidelines2_130818.pdf" target="_blank">
                    Promotion guidelines
                    <i style={{marginLeft:"6px",objectFit:"contain"}}><img style={{ width:"12px", height:"12px"}} src={infoIcon}></img></i>
                  </a>
          </div>
        </div>
        {/* <div className="Separator2" style={{borderColor:'#e2e2e2'}}></div> */}
        <div className="say-thank-you-box create-promotion" style={{background:"#f7f7fa", boxShadow:'none', textAlign:'right', padding:'16px', flexDirection: window.innerWidth > 768 ? "row" : "column"}}>
          <div className="say-thanks col-md-7 col-sm-12 col-xs-12" style={{textAlign:'left', padding:'0', whiteSpace:'nowrap'}}>
            <label className='customer-talk' style={{color:'rgba(0,0,0,0.84)', fontWeight:'unset', fontFamily:'robotobold'}}>Publish on:</label>
            <span style={{background: 'white', border:'1px solid rgba(0,0,0,0.12)', borderRadius:'4px',display:'inline-block', margin:'0 5px', textAlign: 'center'}}>
              <DatePicker autoOk={true} value={this.state.selectedDate}
              minDate={this.minDate}
              className='feed-create-promotion'
              onChange={(e, date)=> this.setState({selectedDate: date})}
              formatDate={(date) => moment(date).format('DD MMM, YYYY')}
              />
              <i className="fa fa-angle-right fa-rotate-90" style={{marginRight:'10px', color: 'rgba(0, 0, 0, 0.54)'}}></i>
            </span>
          </div>
         {(this.state.disablePromotionBtn || this.props.availableLoading || this.props.requestingPromotion || this.props.updating)? 
          <OverlayTrigger placement="top" overlay={<Tooltip id="withdrawal-deny-tooltip">{"Please wait for some time."}</Tooltip>}>
            <button className={`col-md-5 col-sm-12 col-xs-12 large-btn promote-btn ${(this.props.requestingPromotion || this.props.updating) ? 'loading' : ''}`}
            style={{backgroundColor: 'rgba(0,0,0,0.26)', margin:'0', borderRadius:'4px', float:'none', display:'flex', justifyContent: 'space-around', alignItems: 'center', border: 'none', color: 'white', height: "40px", fontSize: "12px", marginTop: window.innerWidth > 768 ? "" : "5%", cursor: "not-allowed" }}>
                {Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length && this.props.availablePromotions[0].runnable_count > 0 ? 'Promote For Free' : 'Promote'}
               {<div className={`pull-right ${this.props.availableLoading ? 'loadingShimmer' : ''}`} style={{height:'23px', minWidth:'40px', paddingTop: "1%"}}>
                    {this.props.availablePromotions && this.props.availablePromotions[0] && <span style={{fontSize:"10px", backgroundColor: 'rgba(253, 232, 241, 0.8)', color: 'rgb(239 29 116)', padding:'2px 4px', borderRadius:'4px'}}>
                      {`${this.props.availablePromotions[0].runnable_count} LEFT`}
                    </span>}
                  </div>}
            </button>
          </OverlayTrigger>
           :
          <button className={`col-md-5 col-sm-12 col-xs-12 large-btn promote-btn ${(this.props.requestingPromotion || this.props.updating) ? 'loading' : ''}`}
            style={{backgroundColor: '#ef1c74', margin:'0', borderRadius:'4px', float:'none', display:'flex', justifyContent: 'space-around', alignItems: 'center', border: 'none', color: 'white', height: "40px", fontSize: "12px", marginTop: window.innerWidth > 768 ? "" : "5%", width: window.innerWidth > 768 ? "" : "100%" }} onClick={this.checkPromotionRequestValidity}>
                {Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length && this.props.availablePromotions[0].runnable_count > 0 ? 'Promote For Free' : 'Promote'}
                <div className={`pull-right ${this.props.availableLoading ? 'loadingShimmer' : ''}`} style={{height:'23px', minWidth:'40px', paddingTop: "1%"}}>
                    {this.props.availablePromotions && this.props.availablePromotions[0] && <span style={{fontSize:"10px", backgroundColor: 'rgba(253, 232, 241, 0.8)', color: 'rgb(239 29 116)', padding:'2px 4px', borderRadius:'4px'}}>
                      {this.props.availablePromotions[0].runnable_count} LEFT
                    </span>}
                  </div>
            </button>
          }
        </div>
        {this.state.deductMoney && <DeductMoney
                user={this.props.user}
                requestPromotion={this.requestPromotion}
                walletBalance={this.props.walletBalance}
                push={this.props.push}
                isMerchantOnWallet={this.props.isMerchantOnWallet}
                isRunnable={this.props.availablePromotions[0].is_runnable}
                members={this.props.members}
                unmountDeductState={this.unmountDeductState}/>}
        {this.state.showVoucherRelisting &&
                <Suspense fallback="loading">
                   <VoucherRelisting
                      //push={this.props.push}
                        user={this.props.user}
                        location={this.props.location}
                        showVoucherDelisted={this.showVoucherDelisted}
                        customMessage = {'Making a feed push live is permitted only if you are accepting vouchers and magic pay.'}
                   />
                </Suspense>}
        </div>
      
    );
  }
})