import React, { useState } from 'react'
import TrendingUp from '../../images/trendingUp.svg'
import ConvexPattern from '../Promotions/ConvexPattern'

const PromotionHistoryCard = (props) => {

    const [isHovering, setIsHovering] = useState(false);

    const onHover = () => {
        setIsHovering(true);
    }

    const onHoverOver = () => {
        setIsHovering(false);
    }

    const showMessageText = props.message && isHovering
    return (
        <div id={props.id} className='forum-question promotionCardContainer feedCardWidth' style={{ padding: '15px', margin:'12px 15px' }}>
            <div style={{ flexDirection: 'column', width: '100%' }}>
                <div className={`promotionTag tag-${props.status}`}>{props.status}</div>
                <div style={{ marginTop: '10px', border: props.status === "pending" ? "1px solid #E8E8E8" : "none", overflow:'hidden', borderRadius:'20px', textAlign:'left' }}>
                    {props.status === "PENDING" ? <ConvexPattern
                    profilePic={props.user && props.user.profilePic}
                    merchantName={props.user && props.user.merchantName}
                    merchantLocality={props.user && props.user.locality}
                    message= {props.promotion?.message || null}
                    backgroundImage= {props.promotion?.banner_pushed || props.promotion?.image || null}
                    /> :
                    <div className='feedpushImage' style={{position:'relative', display:'flex', textAlign:'center', alignItems:'center', justifyContent:'center', overflow:'hidden'}}><img className='mobileCurve' src={props.image} style={{ objectFit: 'cover', opacity: showMessageText ? '0.2': '1' }} width="100%" />
                    </div>}
                </div>
                <div style={{textAlign:'left', marginTop:'10px'}}>{props.message}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <div className='smallFontSizeMobile' style={{ color: 'rgb(160, 160, 160)' }}>Started On : {props.startDate}</div>
                    {['completed', 'live'].includes(props.status && props.status.toLowerCase()) && <div style={{ color: 'rgb(233, 38, 117)', display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'flex-end', alignItems: 'center', cursor:'pointer' }} onClick={() => props.viewInsights(props.promotion)}>
                        <img className='mobileCurve' src={TrendingUp} width="10%" />
                        <div style={{ marginLeft: '4px' }}>View Insights</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default PromotionHistoryCard