const LOAD = 'notifications/LOAD';
const LOAD_SUCCESS = 'notifications/LOAD_SUCCESS';
const LOAD_FAIL = 'notifications/LOAD_FAIL';

const ACTION = 'notification/ACTION';
const ACTION_SUCCESS = 'notification/ACTION_SUCCESS';
const ACTION_FAIL = 'notification/ACTION_FAIL';

const COUNT = 'notification/COUNT';
const COUNT_SUCCESS = 'notification/COUNT_SUCCESS';
const COUNT_FAIL = 'notification/COUNT_FAIL';

const RESET_SUCCESS = 'notification/RESET_SUCCESS';

const intialState = {
  loaded: false,
  actionDone: false,
  notifications: [],
  newCount: 0,
  callFurther: true,
  snackBarVisible: false,
  bottomSnackbarVisible: false,
};

export default function notifications(state = intialState, action = {} ){
  switch(action.type){
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {

        ...state,
        loading: false,
        loaded: true,
        notifications: [ ...state.notifications, ...action.result ],
        callFurther: action.result.length >= action.limit,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case ACTION:
      return {
        ...state,
        loadingId: action.notificationId,
      };
    case ACTION_SUCCESS:
      // update the state of that notification so it reflects when action is successfully processed
      return {
        ...state,
        loadingId: null,
        actionDone: true,
        notifications: state.notifications.map(notification => {
          if(notification.id == action.notificationId){
            const newNotificationContent = {...notification.notificationContent, actionProcessed: true};
            return({...notification, notificationContent: newNotificationContent});
          }
          else return notification;
        }),
      };
    case ACTION_FAIL:
      return {
        ...state,
        loadingId: null,
        actionDone: false,
        actionError: action.error,
      };
    case COUNT:
      return {
        ...state,
        countLoading: true,
      };
    case COUNT_SUCCESS:
      return {
        ...state,
        countLoading: false,
        countLoaded: true,
        newCount: action.result,
      };
    case COUNT_FAIL:
      return {
        ...state,
        countLoading: false,
        countLoaded: false,
        countError: action.error,
      };
    case RESET_SUCCESS:
      return{
        ...state,
        newCount:0,
      } 
    default:
      return state;
  }
}

export function load(accessToken, beforeId, limit){
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getNotifications', {
      data: {accessToken, beforeId, limit}
    }),
    limit
  };
}

export function takeAction(accessToken, action){
  return {
    types: [ACTION, ACTION_SUCCESS, ACTION_FAIL],
    promise: (client) => client.default.post(`/takeNotificationAction/${action.actionText}`, {
      data: {accessToken, action}
    }),
    notificationId: action.notificationId,
  };
}

export function getNotificationCount(accessToken){
  return {
    types: [COUNT, COUNT_SUCCESS, COUNT_FAIL],
    promise: (client) => client.default.post('/getNotificationCount', {
      data: {accessToken}
    })
  };
}

export function resetCounter(){

   return{
    type: RESET_SUCCESS,
   };
}
