import React, { Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import CommissionDelivery from '../../images/Commission_delivery.png';
import CommissionVoucher from '../../images/Commission_voucher.png';
import { merchantCategories,onboardingStatus } from '../../constants';
import PrimaryButton from '../NewPromotions/PrimaryButton';
import { DropdownDivider } from 'semantic-ui-react';
import { updateEstablishmentType} from '../../redux/modules/newOnboarding';
import { toast } from 'react-toastify';

const ga = window.ga;


export default connect(store => ({ 
    
  }), dispatch => bindActionCreators({
    updateEstablishmentType
    }, dispatch))
(class Step1Diy extends Component{
    constructor(props){
        super(props);
        this.state = {
            tnc: true,
            phoneNumber: "",
            email: "",
            showVideoModal: false,
            upiId: "",
            showVerificationModal: false,
            showTnCModal: false,
            establishmentStatus: "",
            currPage:"storeDetails",
            showLoader:false
        }
    }

    componentDidMount(){
        this.props.sendOnboardingEvent('store_details_page_landing'); 
    }

    handleChange = (e, type) => {
        this.setState({
          [type]: e.target.value
        }) 
      }
    
    handleEstablishmentTypeChanges = (establishmentType = "INSTORE") => {
      this.setState({showLoader:true});
      this.props.sendOnboardingEvent('uploading_establishment_type_loading');
      
      this.props.updateEstablishmentType(this.props.merchantId, this.props.subscriptionId, establishmentType).then((res) => {
          if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase() === 'SUCCESS'){
              this.props.sendOnboardingEvent('uploading_establishment_type_success');
              this.props.changePage("establishmentDetails",0.5);
              this.props.getMerchantData();
              this.setState({showLoader:false});
          }
          else{
              this.props.sendOnboardingEvent('uploading_establishment_type_failed_1');
              const errorMessage = (res && res.message) || "Error in choosing the establishment type"
              toast.error(errorMessage)
              this.setState({showLoader:false});
          }
      })
      .catch(err => {
          this.props.sendOnboardingEvent('uploading_establishment_type_failed_2');
          const errorMessage = (err && err.message) || "Error in choosing the establishment type, try again later"
          toast.error(errorMessage)
          this.setState({showLoader:false});
      });
    }

    handleEstablishmentDetailsBack = () =>{
      this.props.sendOnboardingEvent('establishment_commission__details_back_button_clicked');
      
      if((this.props.createdBy==="diy_commission" || this.props.createdBy==="") && (this.props.commissions && Number.isFinite(this.props.commissions.currentDeliveryCommission) && this.props.commissions.currentDeliveryCommission>0) )
      {
        this.props.changePage("establishmentStatus",0.5) ;
      }
      else
      {
        this.props.changePage("storeDetails",0) ; 
      }
    }


    handleClickSubmit = () => {
      if((this.props.createdBy==="diy_commission") && !(this.props.commissions && Number.isFinite(this.props.commissions.currentDeliveryCommission) && this.props.commissions.currentDeliveryCommission>0) )
      {
        this.handleEstablishmentTypeChanges();
      } 
      this.props.handleSubmitStep1()
    }
   
    handleClickNext = () => {
      this.setState({establishmentStatus: (this.props.createdBy==="diy_commission") && !(this.props.commissions && Number.isFinite(this.props.commissions.currentDeliveryCommission) && this.props.commissions.currentDeliveryCommission>0) ? "INSTORE" : this.props.establishmentType});
      this.props.validateDetails(); 
    }

    render(){
        const merchantData = this.props.merchantInfo || {};
        const commissions = this.props.commissions;
        return(
            <div>
                <div className="onboarding-steps-outer-container">
                    { this.props.currPage === "storeDetails" &&
                     <div className="onboarding-merchant-container">
                        <div className="onboarding-steps-heading-container">
                            <div style={{color:"#2E108E"}}>Hi, <span className="onboarding-steps-heading-merchant-name">{merchantData.merchant_name || "Merchant"}</span></div>
                            <div style={{marginTop:'5px'}}>Become a magicpin partner today!</div>
                            <div className="onboarding-steps-heading-details">Please verify your store details</div>
                        </div>
                        <label className="onboarding-step1-labels" style={{marginTop:"24px", }}>
                            Address
                            <p className="onboarding-step1-details-box" >{merchantData.address_line1}, {merchantData.locality}</p>
                        </label>
                        <label className="onboarding-step1-labels">
                            Mobile Number
                            <p className="onboarding-step1-details-box">{"+91 " + this.props.phoneNumber.slice(2)}</p>
                        </label>
                        <label className="onboarding-step1-labels">
                            Email Id
                            <input className="onboarding-email-input-box" placeholder="Enter your email id" value={this.props.email} onChange={e => this.props.changeEmail(e.target.value)} style={{ border:this.props.email && this.props.isEmail ? "1px solid rgb(255 0 0)" : "1px solid rgb(0 0 0)"}} /> 
                        </label>
                        <label className="onboarding-step1-labels" style={{marginTop:"18px"}}>
                            Category
                            <p className="onboarding-step1-details-box">{commissions.merchantCategory ? commissions.merchantCategory : merchantCategories[merchantData.category_id] || "Food & Bevarages"}</p> 
                        </label>

                        {this.props.loading?
                        <button disabled={true} className="onboarding-processing-button large-btn"> PROCESSING....</button> 
                        :
                        <button className="onboarding-processing-button" disabled={this.props.email===""} className="large-btn" style={{marginTop: "40px", width:"90%", backgroundColor: this.props.email!=="" ? "":"#f7f7fa", color: this.props.email!=="" ? "":"rgba(0, 0, 0, 0.2"  }} 
                          onClick = {() => {
                          //this.props.changePage("establishmentStatus",0.5);
                          // this.props.validateDetails();
                          this.handleClickNext();
                          }}       
                        > NEXT</button>}
                       <div className="onboarding-not-your-business-container">
                           <span>Not your business? </span>
                           <span onClick={() => {this.props.push('/addOutlet')}} style={{color:"#2E108E", textDecoration:"underline", cursor:'pointer'}}>Click here</span>
                       </div>
                        
                       
                    </div> }
                    
                    { this.props.currPage === "establishmentStatus" &&
                    <div className="onboarding-merchant-container">
                        <div className="onboarding-steps-heading-container">
                            <div style={{ fontSize:"16px"}}>Establishment Type</div>
                            <div className="onboarding-steps-heading-details">Please verify your store details</div>
                        </div> 
                        
                      <div style={{marginTop:'24px', marginLeft:'2px'}}>
                        <div>
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="DELIVERYANDINSTORE" value={this.state.establishmentStatus} onChange={(e) => this.handleChange(e,'establishmentStatus')}>
                              <FormControlLabel
                                value={'DELIVERYANDINSTORE'}
                                control={<Radio size="small"/>}
                                label={<Typography style={{fontSize:"medium", letterSpacing:"0.6px"}}>Both, accepting, vouchers and online orders</Typography>}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>  
                          <div className="onboarding-steps-establishment-type-details">
                            Select this option when you want to increase footfall at your store as well as activate online ordering
                          </div>

                        </div>
                        <div style={{marginTop:'16px'}}>
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="INSTORE" value={this.state.establishmentStatus} onChange={(e) => this.handleChange(e,'establishmentStatus')}>
                              <FormControlLabel
                                value={'INSTORE'}
                                control={<Radio size="small"/>}
                                label={<Typography style={{fontSize:"medium", letterSpacing:"0.6px"}}>Accepting vouchers only</Typography>}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                          <div className="onboarding-steps-establishment-type-details" >
                            {`Select when you don’t want to register for online ordering`}
                          </div> 

                        </div>

                        {/* <div style={{marginTop:"16px"}}>

                        
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="DELIVERY" value={this.state.establishmentStatus} onChange={(e) => this.handleChange(e,'establishmentStatus')}>
                              <FormControlLabel
                                value={'DELIVERY'}
                                control={<Radio size="small"/>}
                                label={<Typography style={{fontSize:"medium", letterSpacing:"0.6px"}}>Accepting online orders only</Typography>}
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl> 
                          <div className="onboarding-steps-establishment-type-details">
                             {`Select when you don’t have a facility for customers to dine-in (like delivery kitchens)`}
                          </div>

                        </div> */}

                      </div>              

                       <div className="onboarding-button-container">
                          {!this.state.showLoader?
                            <div>
                              <button  className="onboarding-back-button large-btn" onClick = {() =>{this.props.changePage("storeDetails",0);this.props.sendOnboardingEvent('establishment_type_back_button_clicked')}}> 
                                Back
                              </button>

                              <button  disabled={!this.state.establishmentStatus} className="onboarding-next-button large-btn" style={{backgroundColor: this.state.establishmentStatus!=="" ? "":"#f7f7fa", color: this.state.establishmentStatus!=="" ? "":"rgba(0, 0, 0, 0.2"   }} onClick = {() => {this.handleEstablishmentTypeChanges(this.state.establishmentStatus)}}> 
                                    Next
                              </button>
                            </div>
                            :
                            <button disabled={true} className="onboarding-processing-button large-btn"> PROCESSING....</button>  
                          }
                        </div>
                        
                      </div> }
                      
                  {this.props.currPage==="establishmentDetails" &&
                    <div className="onboarding-merchant-container">
                        <div className="onboarding-steps-heading-container">
                            <div style={{ fontSize:"16px"}}>Establishment commision details</div>
                            {this.state.establishmentStatus === "DELIVERYANDINSTORE" ? 
                            <div className="onboarding-steps-heading-details">For Dine-in and Delivery available</div> 
                            :
                            <div className="onboarding-steps-heading-details">For Dine-in available</div> 
                            }
                        </div> 
                        
                        <div style={{marginTop:'24px', marginLeft:'2px'}}>

                        
                            <div className="onboarding-establishment-details-tile-container" >
                                <div className="onboarding-establishment-details-tile-image-container" style={{ width:"45px"}}>
                                    <img src={CommissionVoucher} style={{height:"20px", width:"20px", marginTop:'9px'}}/>
                                </div>
                                <div style={{marginLeft:'16px'}}>
                                    Commission on Cash business: <span style={{fontFamily:"robotobold"}}>{Number.isFinite(commissions.currentCashbackCommission) ? commissions.currentCashbackCommission : (merchantData.category_id === 5 || merchantData.category_id === 91) ? 5:10}% + GST </span>   
                                </div> 
                            </div>


                            {this.state.establishmentStatus!=="DELIVERY" &&
                            <div className="onboarding-establishment-details-tile-container" >
                                <div className="onboarding-establishment-details-tile-image-container" style={{ width:"70px"}}>
                                    <img src={CommissionVoucher} style={{height:"20px", width:"20px", marginTop:'9px'}}/>
                                </div>
                                <div style={{marginLeft:'16px'}}>
                                    Commission on vouchers and magicpay business: <span style={{fontFamily:"robotobold"}}>{commissions.currentVoucherCommission}%</span>   
                                </div> 
                            </div>}

                            {(this.state.establishmentStatus!=="INSTORE") && Number.isFinite(commissions.currentDeliveryCommission) && commissions.currentDeliveryCommission>0 ?
                            <div className="onboarding-establishment-details-tile-container"  style={{padding:"12px 14px"}}>
                                <div className="onboarding-establishment-details-tile-image-container" style={{width:"44px"}}>
                                    <img src={CommissionDelivery} style={{height:"100%", width:"100%"}}/>
                                </div>
                                <div style={{marginLeft:'16px'}}>
                                   Commission on home delivery: <span style={{fontFamily:"robotobold"}}>{commissions.currentDeliveryCommission}% + GST </span>
                                </div>
                            </div>
                            :""}

                            {Number.isFinite(commissions.customerSavings) ?
                            <div className="onboarding-establishment-details-tile-container" >
                                <div className="onboarding-establishment-details-tile-image-container" style={{ width:"42px"}}>
                                    <img src={CommissionVoucher} style={{height:"20px", width:"20px", marginTop:'9px'}}/>
                                </div>
                                <div style={{marginLeft:'16px'}}>
                                  Extra Promotion sponsored by you: <span style={{fontFamily:"robotobold"}}>{commissions.customerSavings}% </span>   
                                </div> 
                            </div>
                            :""}

                        

                        </div>

                        <div style={{marginTop:'40px', width:'100%', paddingLeft:'10px', paddingRight:'10px'}}>
                                <button  className="onboarding-back-button large-btn" onClick = {() =>{this.handleEstablishmentDetailsBack()}} > 
                            Back</button>

                                <button  className="onboarding-next-button large-btn" onClick = {()=> {
                                //this.props.changePage("step2Start",1);
                                this.handleClickSubmit()}}> 
                            Submit</button>
                       </div>
                        
                  </div>}
                      
            </div>  
                
          </div>
                            
        )
    }
})