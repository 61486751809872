import React from 'react';
import CustomerCard from './customerCard';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { extractHashtag } from '../../helpers/Helpers';
var Masonry = require('react-masonry-component');

const screen = window.screen;

class CustomerCardList extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        commentModal:{show : false,defaultComment : ""},
        showLikeModal: false,
        showRecentPlacesModal: false,
        showPostModal : false,
      }
  }

  hideModal(){
    this.setState({commentModal:{show: false,defaultComment : ""}});
  }
  showModal(dummyComment) {
    if(!dummyComment) dummyComment ="We appreciate your feedback and look forward to seeing you again soon!";
    this.setState({commentModal:{show: true,defaultComment : dummyComment}});
  }


  hideLikeModal() {
   this.setState({showLikeModal: false});
 }
  showLikeModal() {
    this.setState({showLikeModal: true});
  }

 hideRecentVisitModal(){
   this.props.options.dumpRecentPlaces();
   this.setState({showRecentPlacesModal: false});
 }
 showRecentPlacesModal() {
    this.setState({showRecentPlacesModal: true});
 }
 thankYouButton(userId,postId) {
    this.hideLikeModal();
    this.showModal();
    this.props.options.getComments(userId,postId,this.props.options.accessToken)
 }
 onCickCommentButton(userId,postId,event){
   event.preventDefault();
   var content=this.refs.commentText.value;
   this.hideModal();
   this.props.options.comment(userId,postId,content,this.props.options.accessToken);
 }

 onCickVisits(userId,event){
       var txn_time = this.props.recentPlacesData.txn_time;
       var limit = this.props.recentPlacesData.limit;
       event.preventDefault();
       this.showRecentPlacesModal();
       this.props.options.getRecentPlaces(userId,this.props.options.accessToken,txn_time,limit);
   }

 render() {
  var screenS = {width:screen.width, height : screen.height};
  var options = this.props.options;
  options.showCommentModal = this.showModal.bind(this);
  options.showModal = this.showModal.bind(this);
  options.showLikeModal = this.showLikeModal.bind(this);

  options.showRecentPlacesModal = this.showRecentPlacesModal.bind(this);
  options.onCickVisits = this.onCickVisits.bind(this);
  var deal = this.props.deal;
   var opt= {
    containerStyle : {margin:"0px",position:"relative"}
  }
  var _this=this;

  return(
        <div>
          <div className="row">
            <Masonry options={opt}>
              {this.props.data && this.props.data.filter((post, index) => post.visibility != "SELF" && post.post_type != "Moment" 
               && this.props.data.findIndex(obj => (obj.first_name === post.first_name) && (obj.created_at === post.created_at)) === index
             ).map((item,index) =>
              <div key={item.post_id} className="col-md-4 grid-item" >
                <CustomerCard options={options}
                data={item}
                facebookId = {this.props.facebookId}
                setfbToken={this.props.setfbToken}
                getFbToken = {this.props.getFbToken}
                fbShare={this.props.fbShare}
                user={this.props.user}
                liked={this.props.liked}
                like={this.props.like}
                comment={this.props.comment}
                deal = {deal}/>
                </div>
                )}

              </Masonry>
            </div>
            {this.state.commentModal.show && <Modal show={this.state.commentModal.show} dialogClassName="small-modal-box customer-comment" onHide={this.hideModal.bind(this)}>
            <div>
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.hideModal.bind(this)}>
                  ×
                </button>
              </div>

              <Modal.Body>

              <div className="ReviewComment">
                <h2 id="getCommentCount" style={{color:'rgba(0,0,00.54)'}}><span className="fa fa-commenting" style={{marginRight:'4px'}}></span>Comments</h2>
              </div>
              <div id="CustomersComment_bx">

                <div className="customer-review-box" id="getCustomerComments">

                  { this.props.commentModalData.comments && this.props.commentModalData.comments.comment_data.map(function(item,index){
                  return( <div key={index} className="row">
                  <div className="col-xs-2 review-reply-img">
                    <div className="review-reply-img-block">
                      <img src={_this.props.commentModalData.comments.profile_pics[index]} />
                    </div>
                  </div>
                  <div className="col-xs-9 review-ques review-reply-text">
                    <p className="review-reply-user">{ReactHtmlParser( _this.props.commentModalData.comments.comment_list_names[index],{transform:extractHashtag})}</p>
                    <p>{ReactHtmlParser( item.content.content, {transform:extractHashtag})}</p>
                  </div>
                  {ReactHtmlParser( _this.props.commentModalData.comments.comment_list_names[index], {transform:extractHashtag})}

                </div>
                )
              })}


              <div className="row">
                <div className="col-xs-12">
                  <div id="ReviewReplyBox" className="review-reply-comment-tex">
                    <textarea style={{"border-color":"#888080", resize:'vertical', height:'80px'}} placeholder="Leave your Comment here ... " ref="commentText" className="review-comment-box form-control" rows="4" cols="4" id="replyContent">
                      {this.state.commentModal.defaultComment}
                    </textarea>
                    <p><a style={{"cursor":"pointer" }} id="ReviewReplySaveBtn" className="review-comment-save pull-right" onClick={this.onCickCommentButton.bind(this,this.props.commentModalData.data.userId,this.props.commentModalData.data.postId)}>Send</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
      </Modal> }
      {  this.state.showLikeModal  && <Modal show={this.state.showLikeModal} dialogClassName="small-modal-box customer-comment" onHide={this.hideLikeModal.bind(this)}>
      <div>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.hideLikeModal.bind(this)}>
            ×
          </button>
        </div>
        <Modal.Body>

        <div id="CustomersComment_bx" style={{"padding":"16px 0"}}>
          <div className="customer-review-box" id="getCustomerComments">
            <div className="row">
              <div className="col-xs-12 review-ques review-reply-text" style={{"border-bottom":"0"}}>
                <p className="review-reply-user">Congrats! The following message has been sent to the customer<br/></p>
                <p>Hi, Thank you for visiting our outlet. We hope to see you back soon.</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button style={{"width":"40%"}} type="button" className="btn-submt" data-dismiss="modal" onClick={this.thankYouButton.bind(this,this.props.likeModalData.userId,this.props.likeModalData.postId)}>
          Say Thank You
        </button>
      </Modal.Footer>
      </div>
      </Modal>
      }
      {this.state.showRecentPlacesModal  && <Modal show={this.state.showRecentPlacesModal} dialogClassName="small-modal-box customer-comment" onHide={this.hideRecentVisitModal.bind(this)}>
            <div>
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.hideRecentVisitModal.bind(this)}>
                  ×
                </button>
              </div>

              <Modal.Body >
              <div className="ReviewComment">
                <h2 id="getCommentCount"><span className="review-cooment-icon"></span>Recent Visits</h2>
              </div>
              <div id="CustomersComment_bx" style={{maxHeight:screenS.height-180, overflowY:"scroll"}}>
                <div className="customer-review-box" id="getCustomerComments">
                  { this.props.recentPlacesData.loaded  && this.props.recentPlacesData.recentVisits.map(function(item,index){
                  return( <div key={index} className="row">
                  <div className="col-xs-2 review-reply-img">
                    <div className="review-reply-img-block">
                      <img src={item.merchant_logo} />
                    </div>
                  </div>
                  <div className="col-xs-9 review-ques review-reply-text">
                    <p className="review-reply-user">{item.merchant_name}</p>
                    <p>{item.org_time && moment(item.org_time).format('DD MMM YYYY, h:mm a')}</p>
                  </div>

                </div>
                )
              }) }
               {this.props.recentPlacesData.moreData && <div style={{textAlign:"center",padding:"8px 0 16px"}}>
               <a href="#" className="review-comment-save"
                 onClick={this.onCickVisits.bind(this,this.props.recentPlacesData.userId)}>
                 {this.props.recentPlacesData.loading ? "LOADING" : "See More"}
               </a>
             </div>}
            </div>
          </div>
        </Modal.Body>
      </div>
      </Modal> }
      </div>
  );
 }
}

 export default CustomerCardList;
