import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import './HomeDelivery.css';
import buzzerInfoIconRed from '../../images/home-delivery-order-buzzer-download-extension.png'
import buzzerSpeakerInfoIconRed from '../../images/home-delivery-order-buzzer-icon.png'
import chromeIcon from '../../images/home-delivery-chrome-extension-chrome-icon.png';
import {toast} from 'react-toastify';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: "10px",
    width: window.innerWidth > 768 ? "324px" : "250px"
  },
  closeButton: {
    position: 'absolute',
    right: "5px",
    top: "5px",
    color: "gray",
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "16px",
    textAlign: "center"
  },
}))(MuiDialogContent);

export default class AskAutoplayPermission extends Component {
    
  constructor(props) {
    super(props)
    this.state = {
      closingTime: null
    }
  }

render () {
  return (
    this.props.showDownloadExtensionPopup === true
    ?
    <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
        </DialogTitle>
        
        <DialogContent dividers>
            {/* <div style={{ fontSize: '16px', fontFamily: 'robotoregular', paddingBottom: '8%'}}>
                <div>Please select your store's closing time</div>
            </div> */}
            <img src={buzzerInfoIconRed} style={{width: '50px'}}></img>
            <div style={{ fontSize: '20px', fontFamily: 'robotobold', padding: '5%', textAlign: 'start'}}>
                <div style={{fontFamily: 'robotoregular'}}>
                  Your order buzzer has been blocked by the browser.
                </div>
                <div style={{fontFamily: 'robotobold'}}>
                  Never miss an order! Download our Chrome<img src={chromeIcon} style={{width: '20px', marginLeft: '1%'}}></img> Plug-in to get the buzzer<img src={buzzerSpeakerInfoIconRed} style={{width: '24px', marginLeft: '1%'}}></img> <span style={{fontFamily: 'robotoregular'}}>for all orders</span>
                </div>
            </div>
            <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {/* <div style={{fontSize: '14px', padding: '10px 24px'}} className="merchant-primary-action-btn" onClick={() => this.props.closePopUp()}>
                    Go to Chrome Web Store
                </div> */}
                <a className="merchant-primary-action-btn" style={{fontSize: '16px', width: '40%', color: '#fff', padding: '10px 24px'}} target="_blank" href={`https://chrome.google.com/webstore/detail/cgaeclpcjkpehjliekjhheboboeofpnc`} onClick={()=>{
                  // fire BQ
                  this.props.sendBqEvent("order_download_chrome_extension_clicked_from_popup")
                  this.props.closePopUp();
                }}>
                  Download now
                </a>
            </div>

            </div>
        </DialogContent>
      </Dialog>
    :
      <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
        </DialogTitle>
        
        <DialogContent dividers>
            {/* <div style={{ fontSize: '16px', fontFamily: 'robotoregular', paddingBottom: '8%'}}>
                <div>Please select your store's closing time</div>
            </div> */}
            <div style={{ fontSize: '20px', fontFamily: 'robotobold', paddingTop: '4%', paddingBottom: '4%', textAlign: 'start'}}>
                <div>{this.props.permissionsMessage}</div>
            </div>
            <div style={{ }}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{fontSize: '14px', padding: '10px 24px'}} className="merchant-primary-action-btn" onClick={() => this.props.closePopUp()}>
                    OK
                </div>
            </div>

            </div>
        </DialogContent>
      </Dialog>
  );
          }
}