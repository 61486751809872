import React from 'react';
import {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import particles from '../../images/particles.svg'
import constants, {snackbarPriorities, countries, MAGIC_ORDER_CAT, MAGIC_ORDER_CITIES, MAGIC_ORDER_CITIES_FNB, faultyMerchantIds} from '../../constants';
import { sendEvent, getCurrencySymbol, currencyString } from '../../helpers/Helpers'
import { showSnackBar, hideSnackBar} from '../../redux/modules/snackbarEmail';
import { getMerchantActivationStatus } from '../../redux/modules/feedReducers/merchantActivation';
import { showActivationModal } from '../../redux/modules/feedReducers/merchantActivation';
import { getMarketingRedemptionStatus, updateMerchantSubscription} from '../../redux/modules/dealReducers/cashVouchers';
import './snackbar.css';
import { getSubscriptionPackageData } from '../../redux/modules/merchantOnboarding';
import freeSms from '../../images/freesms.svg';
import free500 from '../../images/500-free-sms.svg';
import arrowVert from '../../images/speedArrowsVertical.svg'
import arrowHor from '../../images/speedArrows.svg'
import googlePlay from '../../images/google-play-store-badge.svg'
import android from '../../images/android.svg'
import appStore from '../../images/app-store.svg'
import ios from '../../images/ios.svg'

var delistedSnackBarActive;
var showSaasSnackBarActive;

class SnackBar extends Component{


  state = {
    showGroceryModalToMerchant : false,
    BPP_0 : false,
    deeplinkPopupExecuted: false
  }

  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  mapCategory(val){
    switch(val){
      case 2:
      return 'Beauty';
      case 3:
      return 'Fashion';
      case 4:
      return 'Fitness';
      case 5:
      return 'Grocery';
      default:
      return 'Restaurant';
    }
  }

  clean_for_url(text){
    if (!text)
        return
    text=this.titleCase(text)

    text=text.replace(/\s|\?|#|\//g,"-")

    text=text.replace(/'|%|"/g,"")
    return text;
  }


  getMerchantUrl(merchant_id, merchant_name, locality, city, category_id = 1){
  if ( !merchant_name || !locality || !city ||  !merchant_id)
      return ""

  merchant_name =this.clean_for_url(merchant_name)


  locality=this.clean_for_url(locality)


  city =this.clean_for_url(city)


  merchant_id =merchant_id.toString(13)


  const category=this.mapCategory(category_id);

  const url=`https://magicpin.in/${city}/${locality}/${category}/${merchant_name}/store/${merchant_id}`
  return url
  }

  merchantActivationStatusText = (merchantActivationStatusData) =>{
    switch(merchantActivationStatusData.activationStatus){
      case constants.merchantActivationStatus.INCOMPLETE :
        if(merchantActivationStatusData.activationStep === constants.merchantActivationStep.OWNERSHIP_VERIFICATION){
          return "Unlock extra benefits by getting your ownership verified";
        }
        else {
          return "Now learn how to redeem vouchers on magicpin. Use code: magic20";
        }
      case constants.merchantActivationStatus.PENDING :
        return "Thanks! We are reviewing your details and will activate your account shortly.";
      case constants.merchantActivationStatus.REJECTED :
        return "Your account verification request was rejected. " + merchantActivationStatusData.rejectionReason;
      case constants.merchantActivationStatus.APPROVED:
        return "Your activation request is approved."
      default:
        return "Please submit your ownership details to activate account now";
    }
  }

  showBppSnackBar = ()=>{
    this.props.showSnackBar({
      text:"Earn more by selling partner brands! Enroll into Brands Privilege Program", 
      ctaText: "KNOW MORE", 
      closable: true, 
      action: ()=>this.props.push('/bpp'),
      showEvent: "shown_bpp_snackbar", 
      clickEvent: "clicked_bpp_snackbar", 
      hideEvent: "closed_bpp_snackbar", 
      shimmer: false,
      priority: snackbarPriorities['bpp'],
      color:"#121728"})
  }

  // showUpsellSnackBar = ()=>{
    
  //   this.props.showSnackBar({
  //     text: ` Get ${currencyString(3000, countries.INDIA, 0)} of FREE marketing credits with code LOGINTOMAGICPIN ( Valid only for f&b outlets )`, 
  //     ctaText: "KNOW MORE", 
  //     closable: true, 
  //     action: () => this.props.push(`${'/upgrade/' + this.props.user.merchantId }`),
  //     showEvent: "shown_upsell_snackbar", 
  //     clickEvent: "clicked_upsell_snackbar", 
  //     hideEvent: "closed_upsell_snackbar", 
  //     shimmer: true,
  //     priority: snackbarPriorities['upsell'],
  //     color:"rgb(239, 28, 116)"})
  // }

  showGroupBuySnackbar = ()=>{
    this.props.showSnackBar({
      text:`Get assured 145 vouchers redeemed at ${this.props.user.merchantName} in 7 days. Launch GroupBuy now!`, 
      ctaText: "BOOST SALES", 
      closable: true, 
      action: ()=>this.props.launchGroupBuyModal(true),
      showEvent: "shown_groupbuy_snackbar", 
      clickEvent: "clicked_groupbuy_snackbar", 
      hideEvent: "closed_groupbuy_snackbar", 
      shimmer: true,
      priority:snackbarPriorities['groupbuy'],
      color:"#006230"})
  }


  showNewYearVoucherSnackbar = () => {
    this.props.showSnackBar({
      text:`Hey ${this.props.user.merchantName}, you can now create special Christmas & New Year vouchers `, 
      ctaText: "KNOW MORE", 
      closable: false, 
      action: ()=>this.props.showNYVoucherModal(),
      showEvent: "shown_groupbuy_snackbar", 
      clickEvent: "clicked_groupbuy_snackbar", 
      hideEvent: "closed_groupbuy_snackbar", 
      shimmer: true,
      priority:snackbarPriorities['newyear'],
      color:"#006230"})
  }

  showGrocerySnackbar = () => {
    // this.setState({ delistedSnackBarActive: false })
    // delistedSnackBarActive = false;
    // this.props.showSnackBar({
    //   text:`Hey ${this.props.user.merchantName}, you can now create special home delivery vouchers `, 
    //   ctaText: "INTERESTED", 
    //   closable: true, 
    //   action: ()=>this.props.showGroceryModal(),
    //   showEvent: "shown_grocery_snackbar", 
    //   clickEvent: "clicked_grocery_snackbar", 
    //   hideEvent: "closed_grocery_snackbar", 
    //   shimmer: true,
    //   priority:snackbarPriorities['groupbuy'],
    //   color:"#1c003d"})
    console.log("commented")
  }

  showMagicOrderSnackbar = () => {
    this.setState({ delistedSnackBarActive: false })
    delistedSnackBarActive = false;
    this.props.showSnackBar({
      text:` Hey ${this.props.user.merchantName}, you can now start home delivery with magicOrder !`,
      ctaText: "Start Home Delivery", 
      closable: false, 
      action: ()=>this.props.showMagicOrderModal(),
      showEvent: "shown_magic_order_snackbar", 
      clickEvent: "clicked_magic_order_snackbar", 
      hideEvent: "closed_magic_order_snackbar", 
      shimmer: true,
      priority:snackbarPriorities['magic_order'],
      color:"#1c003d"})
  }

  showDownloadApp = () => {
    this.setState({ delistedSnackBarActive: false })
    delistedSnackBarActive = false;
    this.props.showSnackBar({
      text:` Accept orders easily with the magicpin - OrderHere app. Click to download now!`,
      ctaText: null, 
      closable: false, 
      action: ()=>{ window.open('https://play.google.com/store/apps/details?id=com.magicpin.partner', '_blank');},
      action2: ()=>{ window.open('https://apps.apple.com/us/app/id1528283353', '_blank');},
      showEvent: "shown_download_app_snackbar", 
      clickEvent: "clicked_download_app_snackbar", 
      hideEvent: "closed_download_app_snackbar", 
      shimmer: false,
      priority:snackbarPriorities['downloadMerchantApp'],
      color:"linear-gradient('#ef1c74', '#2e0092')"})
  }

  showMerchantVerification = () => {
    this.setState({ delistedSnackBarActive: false })
    delistedSnackBarActive = false;
    this.props.showSnackBar({
      text:`Complete your verification to start receiving payments to your bank account`,
      ctaText: "Verify Now",
      closable: false,
      action: () => this.props.showActivationModal(),
      showEvent: "shown_verify_merchant_snackbar",
      clickEvent: "clicked_verify_merchant_snackbar",
      hideEvent: "closed_verify_merchant_snackbar",
      shimmer: false,
      priority:snackbarPriorities['downloadMerchantApp'],
      color:"linear-gradient('#ef1c74', '#2e0092')"})
  }

  showMerchantVerificationStarted = (verified) => {
    this.setState({ delistedSnackBarActive: false, hideDownloadIcons: true })
    delistedSnackBarActive = false;
    this.props.showSnackBar({
      text:verified ? "Your verification is successful. You can now start receiving payments to your bank account.":"Your verification is in progress. Our representative will be in touch with you shortly.",
      ctaText: null,
      closable: false,
      showEvent: "shown_verify_merchant_started_snackbar",
      clickEvent: "clicked_verify_merchant_started_snackbar",
      hideEvent: "closed_verify_merchant_started_snackbar",
      shimmer: false,
      priority:snackbarPriorities['downloadMerchantApp'],
      color:"linear-gradient('#ef1c74', '#2e0092')"})
  }

  showVouchersDelisted = () => {
    // this.setState({ delistedSnackBarActive: true })
    delistedSnackBarActive = true;
    this.props.showSnackBar({
      text: window.innerWidth > 768 ? `Hey ${this.props.user.merchantName}, your sales are dropping as your vouchers are delisted.` 
                                    : `Your sales are dropping as your vouchers are delisted.`, 
      ctaText: "RE-LIST VOUCHERS", 
      closable: window.innerWidth > 768 ? true : false, 
      action: ()=>{this.sendUIGEvent('relist_vouchers'); this.props.showVoucherDelisted()},
      showEvent: "shown_groupbuy_snackbar", 
      clickEvent: "clicked_groupbuy_snackbar", 
      hideEvent: "closed_groupbuy_snackbar", 
      shimmer: true,
      priority:snackbarPriorities['voucherRelisting'],
      color:"#006230"})
  }

  showSaasSnackBar =()=>{
    delistedSnackBarActive=false
    showSaasSnackBarActive=true
    this.props.showSnackBar({
      text: (this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.packageType && this.props.feedShortcutsStatusData.packageType !== "subscription")
       ? `Get orders for ZERO marketing fees (not counted in package ROI)` : `Your online store is live! Get orders directly from your customers`,
      ctaText: "Share your store", 
      closable: false, 
      action: ()=>{this.props.showShareStoreDialog()},
      showEvent: "shown_magic_order_snackbar", 
      clickEvent: "clicked_magic_order_snackbar", 
      hideEvent: "closed_magic_order_snackbar", 
      shimmer: true,
      priority:snackbarPriorities['magic_order'],
      color:"#2e108e"})
  }

  showOnsurityInsuranceSnackbar =()=>{
    delistedSnackBarActive=false
    showSaasSnackBarActive=false
    this.props.showSnackBar({
      text: "Secure yourself and your employees with 3 months of FREE healthcare membership",
      ctaText: "Get Insured", 
      closable: false, 
      action: () => { 
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSexpKmbw3WyEu5oj-MAlbcL1-zntxCJn7KOUymqLTUpNjK8XQ/viewform', '_blank')
      },
      showEvent: "shown_onsurity_snackbar", 
      clickEvent: "clicked_onsurity_snackbar", 
      hideEvent: "closed_onsurity_snackbar", 
      shimmer: true,
      priority: 1,
      color:"#006230"})
  }

  showSetUpiSnackbar = () => {
    delistedSnackBarActive=false
    showSaasSnackBarActive=false
    this.props.showSnackBar({
      text: "Complete your profile & increase sales by sharing store UPI!",
      ctaText: "Share now", 
      closable: false, 
      action: () => { 
       this.props.setUpiFN()
      },
      showEvent: "shown_set_upi_snackbar", 
      clickEvent: "clicked_set_upi_snackbar", 
      hideEvent: "closed_set_upi_snackbar", 
      shimmer: true,
      priority: snackbarPriorities.setUpi,
      color:"#006230"})
  }

  componentDidMount(){
// console.log(snackbarPriorities)
    this.params = new URLSearchParams(this.props.location.search);

    this.country = this.props.user.country.toUpperCase();

    //let showShareOut= this.props.gstModalCount %2 ==0 ? true : false;
    let showShareOut= Math.floor(Math.random() * 100) % 2 === 0;
    this.props.getMarketingRedemptionStatus(this.props.user.accessToken);

    if(this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes 
      && this.props.feedShortcutsStatusData.commercialTypes.includes('MARKETING') && this.props.showOnsurityData 
      && this.props.showOnsurityData.showOnsuritySnackbar === true) {
        this.showOnsurityInsuranceSnackbar()
      }

    if(this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.user && this.props.user.isFunded
      && this.props.feedShortcutsStatusData.commercialTypes.includes('MARKETING') && !this.props.feedShortcutsStatusData.haveUpiId){
        this.showSetUpiSnackbar()
      }
      
    if(this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.feedShortcutsStatusData.commercialTypes.includes('SAAS')){
      if(!this.props.remarketingStatus && !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
        && this.props.accountStatus !== 'Expired'){
        !this.props.hide && this.showVouchersDelisted()
      }
      else if(showShareOut == false && !this.props.appInstalled){
        this.showDownloadApp();
      }
      else{

        let firstPaymentDone = false
        let previousWithdrawals = this.props.previousWithdrawals && this.props.previousWithdrawals.length > 0 ? this.props.previousWithdrawals : []

        if(Array.isArray(previousWithdrawals) && previousWithdrawals.length > 0){
          previousWithdrawals.map(withdrawal =>{
            if(withdrawal.status === "CLOSED"){
              firstPaymentDone = true 
            }
          })
        }
        
        if(this.props.feedShortcutsStatusData.ownershipVerified && !firstPaymentDone) {
          this.showMerchantVerificationStarted(true)
        } else if(this.props.feedShortcutsStatusData.documentUploaded && !firstPaymentDone) {
          this.showMerchantVerificationStarted(false)
        } else if(!this.props.feedShortcutsStatusData.ownershipVerified){
          this.showMerchantVerification()
        }
      }
    }
    
    if(this.props.merchantActivationStatusData)
      this.decideActivationFlow(this.props.merchantActivationStatusData)
    // else if(Array.isArray(this.props.groupBuyPlans) && this.props.groupBuyPlans.length){
    else if(this.props.showNewYearVoucherSnackbar && this.props.remarketingStatus)
    {
      this.showNewYearVoucherSnackbar()
    }
    else if((this.props.feedShortcutsStatusData && this.props.remarketingStatus && 
             (this.props.isIntrestedInMagicOrder !== 1)
             && ( (MAGIC_ORDER_CAT.includes(this.props.user.categoryId) && MAGIC_ORDER_CITIES.includes(this.props.user.city.toLowerCase())) || 
                  (this.props.user.categoryId === 1 && MAGIC_ORDER_CITIES_FNB.includes(this.props.user.city.toLowerCase())) ) 
             && this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant && !this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking
             )
           ) {
      this.setState({showGroceryModalToMerchant: true})
      this.showMagicOrderSnackbar()
    }
    else{
      // if(this.country !== countries.INDONESIA){
        // this.showGroupBuySnackbar()
        // this.showNewYearVoucherSnackbar()
        if(this.props.feedShortcutsStatusData && !this.props.remarketingStatus && !this.props.user.isSaasOnly &&
          !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
          && this.props.accountStatus !== 'Expired') {
          !this.props.hide && this.showVouchersDelisted()
        }
        else if(this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.feedShortcutsStatusData.commercialTypes.includes('SAAS')){
          this.showSaasSnackBar();
        }

        
      // }
    }
    this.adjustPadding();


    if (this.params.has('id') && this.params.get('id'))
    this.props.getSubscriptionPackageData(this.params.get('id')).then(() => {
      if (this.props.subscriptionData && this.props.subscriptionData.status != 'FAILURE'){
        
        if(this.props.subscriptionData.packageDtoList.name == "BPP_0"){
          this.setState({BPP_0: true})
        }
      }
    })

    this.landOnNewYearPopup();
  }

  decideActivationFlow = (activationData)=>{
    if(activationData.activationStatus !== constants.merchantActivationStatus.APPROVED){
      let payload  = {
        text: this.merchantActivationStatusText(activationData), 
        closable : false,
        showEvent : "shown_activation_snackbar", 
        hideEvent: "clicked_activation_snackbar", 
        closeEvent: "closed_activation_snackbar",
        priority: snackbarPriorities['merchantActivation'],
        color: "#ff455f"}
      let ctaText = this.merchantActivationCTA(activationData)
      payload["ctaText"] = ctaText
      switch(ctaText){
        case `EARN ${getCurrencySymbol(this.props.user.country)} 20`:
          payload["action"]=()=>this.props.push('/vouchers')
          break
        case "TRACK STATUS":
          payload["action"]=()=>this.props.push('/subscription')
          break
        case "ACTIVATE AGAIN":
        case "ACTIVATE NOW":
          payload["action"]=()=>this.props.showActivationModal()
        break
      }
       this.props.showSnackBar(payload);
      
    }
    // else if(Array.isArray(this.props.groupBuyPlans) && this.props.groupBuyPlans.length){
      // if(this.country !== countries.INDONESIA)
        // this.showGroupBuySnackbar() // not this
        // this.showNewYearVoucherSnackbar()
        // this.showVouchersDelisted()
    // }
    else if(this.props.showNewYearVoucherSnackbar && this.props.remarketingStatus)
    {
       this.showNewYearVoucherSnackbar()
    }
    else if((this.props.feedShortcutsStatusData && this.props.remarketingStatus && 
             this.props.isIntrestedInMagicOrder !== 1
              && ( (MAGIC_ORDER_CAT.includes(this.props.user.categoryId) && MAGIC_ORDER_CITIES.includes(this.props.user.city.toLowerCase())) || 
                   (this.props.user.categoryId === 1 && MAGIC_ORDER_CITIES_FNB.includes(this.props.user.city.toLowerCase())) ) 
              && this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant && !this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking
            )
              || (this.props.isIntrestedInMagicOrder !== 1 && this.props.user.merchantId===1301645)
           ) {
              //  this.setState({showGroceryModalToMerchant: true})
               this.showMagicOrderSnackbar()
   }
    else{
      if(this.props.feedShortcutsStatusData && !this.props.remarketingStatus && !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
        && this.props.accountStatus !== 'Expired'){
        !this.props.hide && this.showVouchersDelisted()
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    
    let showShareOut= this.props.gstModalCount %2 ==0 ? true : false;

    if(this.state.deeplinkPopupExecuted === false && prevProps.showNewYearVoucherSnackbar != this.props.showNewYearVoucherSnackbar) {
      this.landOnNewYearPopup();
    }

    if(prevProps.feedShortcutsStatusLoading && !this.props.feedShortcutsStatusLoading && this.props.feedShortcutsStatusData){
      if(!this.props.feedShortcutsStatusData.merchantActive && this.props.feedShortcutsStatusData.paymentVerified){
        this.props.getMerchantActivationStatus(this.props.user.accessToken);
      }
      if(this.props.feedShortcutsStatusData && !this.props.remarketingStatus && !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
        && this.props.accountStatus !== 'Expired') {
        !this.props.hide && this.showVouchersDelisted()
      }
      // else if(Array.isArray(this.props.groupBuyPlans) && this.props.groupBuyPlans.length){
        // if(this.country !== countries.INDONESIA)
          // this.showGroupBuySnackbar() //not this
          // this.showNewYearVoucherSnackbar()
          // this.showVouchersDelisted()
      // }
      else if(this.props.feedShortcutsStatusData && this.props.remarketingStatus && this.country !== countries.INDONESIA && (this.props.user.categoryId == 5 || this.props.IsPharmaMerchant == true)) {
        // this.setState({showGroceryModalToMerchant: true})
        // this.showGrocerySnackbar()
      }
      else {
          // if(this.props.feedShortcutsStatusData && !this.props.remarketingStatus && !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
          //   && this.props.accountStatus !== 'Expired') {
          //   !this.props.hide && this.showVouchersDelisted()
          // }
      }

    }

    if(this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.feedShortcutsStatusData.commercialTypes.includes('SAAS')){
      if(showShareOut == true){
        this.showSaasSnackBar();
      }
    }
    else if(prevProps.merchantActivationStatusLoading && !this.props.merchantActivationStatusLoading && this.props.merchantActivationStatusData){
      this.decideActivationFlow(this.props.merchantActivationStatusData)
    }

    if(!prevProps.snackBarVisible && this.props.snackBarVisible){
      this.sendUIGEvent(this.props.showEvent)
    }

    if(prevProps.snackBarVisible && !this.props.snackBarVisible)
      this.sendUIGEvent(this.props.hideEvent);
    if(prevProps.snackBarVisible !== this.props.snackBarVisible) this.adjustPadding();
    
    // if(prevProps.groupBuyPlansLoading && this.props.groupBuyPlansLoaded && this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.merchantActive){
    //   if(Array.isArray(this.props.groupBuyPlans) && this.props.groupBuyPlans.length){
    //     if(this.country !== countries.INDONESIA)
    //       // this.showGroupBuySnackbar() // THIS
    //       // this.showNewYearVoucherSnackbar()
    //       this.showVouchersDelisted()
    //   }
    // }
    if(this.props.feedShortcutsStatusData && this.props.remarketingStatus && this.country !== countries.INDONESIA && (this.props.user.categoryId == 5 || this.props.IsPharmaMerchant == true)) {
      // this.setState({showGroceryModalToMerchant: true})
      // this.showGrocerySnackbar()
    }
    else if(this.props.showNewYearVoucherSnackbar && this.props.remarketingStatus)
    {
       this.showNewYearVoucherSnackbar()
    }
    else{
      if(this.props.feedShortcutsStatusData && !this.props.remarketingStatus && !this.state.showGroceryModalToMerchant && !this.state.BPP_0 && !this.props.hideSnackBarRelistOnVoucher
        && this.props.accountStatus !== 'Expired') {
        !this.props.hide && this.showVouchersDelisted()
      }
    }
  }

  merchantActivationCTA = (merchantActivationStatusData) =>{
    switch(merchantActivationStatusData.activationStatus){
      case constants.merchantActivationStatus.INCOMPLETE :
        if(merchantActivationStatusData.activationStep === constants.merchantActivationStep.OWNERSHIP_VERIFICATION){
          return "ACTIVATE NOW";
        }
        else {
          return `EARN ${getCurrencySymbol(this.props.user.country)} 20`;
        }
      case constants.merchantActivationStatus.REJECTED :
        return "ACTIVATE AGAIN";
      case constants.merchantActivationStatus.APPROVED:
      case constants.merchantActivationStatus.PENDING :
        return "TRACK STATUS";
      default:
        return "ACTIVATE NOW";
    }
  }

  sendUIGEvent = event =>{
    const params = new URLSearchParams(this.props.location.search);
    sendEvent({
      event: event,
      page_type: 'merchant_portal',
      subject_type: 'merchant',
      utm_source: params.get('utm_source'),
      actor_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
      utm_campaign: window.localStorage.getItem('landingSource'),
      actor_id: this.props.user.merchantId,
      merchant_phone: window.localStorage.getItem('landingPhone') || '',
      subject_merchant_id: this.props.user.merchantId,
  });
  return true;
  }

  actionClick = (route) =>{
    if(route){
      if(route == 'android'){
        this.props.action();
        return
      }
      else if(route == 'ios') {
        window.open('https://apps.apple.com/us/app/id1528283353', '_blank')
        return
      }
    }
    this.sendUIGEvent(this.props.clickEvent);
    this.props.action();
  }

  adjustPadding=()=>{
    if(this.props.snackBarVisible){
      document.getElementById('top-header-bar').classList.add('snackbar-visible')
      document.body.classList.add('snackbar-visible')
    }
    else{
      document.getElementById('top-header-bar').classList.remove('snackbar-visible')
      document.body.classList.remove('snackbar-visible')
    }
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.remarketingStatus && (this.props.remarketingStatus !== nextProps.remarketingStatus)){
      this.showVouchersDelisted()
    }
  }

  // this.showNewYearVoucherSnackbar()
  landOnNewYearPopup = () => {
        if(this.props.showNewYearVoucherSnackbar) {
            const params = new URLSearchParams(this.props.location.search);
            // if(this.props.merchantOrdersData && this.props.user.categoryId === 1) {
                if(params.has('openNYVoucher')) {
                    this.setState({ deeplinkPopupExecuted: true })
                    this.props.showNYVoucherModal()
                }
            // }
        }
  }

  render(){
  if(!this.props.snackBarVisible) return null;
  let allCrmData = this.props.allCrmData || {};

  // const magicpinURL=this.getMerchantUrl(props.user.merchantId,props.user.merchantName,props.user.locality,props.user.city,props.user.categoryId);
  // const indifiURL = props.user.categoryId == 1 ? 'magicpin' : 'magicpinretail' ;
  return(
  !!this.props.user.merchantId &&
  // <div className="snackbar snackbar-mobile" ref="snackBar" style={{width:'100%', height:'48px', backgroundColor:this.props.color, zIndex: 999, top:0}}>
  // <div className="snackbar snackbar-mobile" ref="snackBar" style={{width:'100%', height:'48px', backgroundColor:"#FF4560", zIndex: 999, top:0}}>
   <div className="snackbar snackbar-mobile" ref="snackBar" style={{ width:'100%', height:'48px', backgroundColor: (this.props.showOnsurityData && this.props.showOnsurityData.showOnsuritySnackbar === true) ? "#1c003d" : delistedSnackBarActive ? "#FF4560" : showSaasSnackBarActive ? "#2e108e" : "#1c003d", backgroundSize: delistedSnackBarActive ? "" : "100% 100%", backgroundImage: !this.props.ctaText ? 'linear-gradient(90deg,#ef1c74, #2e0092)'  :  (delistedSnackBarActive || showSaasSnackBarActive) ? (this.props.showOnsurityData && this.props.showOnsurityData.showOnsuritySnackbar === true ) ? `url(${particles})` : "" : `url(${particles})`, zIndex: 999, top:0}}>
   <div className="container-fluid" style={{height: "100%"}}>
      <div style={{margin: 'auto',display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around', height: "100%"}}>
        <div onClick={!this.props.ctaText ? null : this.actionClick} style={{float:"left",fontSize: window.innerWidth < 768 ? "14px" : '16px', color:'#fff', display: 'flex', padding: window.innerWidth < 768 ? '8px' : 0, alignItems: 'center'}}>
          <a  style={{color:'#fff',textDecorationLine:"none"}}>
            <span>{this.props.text}</span> 
          </a>

          {!this.state.hideDownloadIcons && !this.props.ctaText && <img onClick={() => this.actionClick('android')} style={{marginLeft: window.innerWidth > 768 ? '40px' : '10px', cursor: 'pointer'}} src={window.innerWidth > 768 ? googlePlay : android} />}
          {!this.state.hideDownloadIcons && !this.props.ctaText && <img onClick={() => this.actionClick('ios')} style={{marginLeft: window.innerWidth > 768 ? '16px' : '10px', cursor: 'pointer'}} src={window.innerWidth > 768 ? appStore : ios} />}
        </div>
        
        { (this.props.ctaText || this.props.closable) && <div style={{float:"right",display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around'}}>
        {/* <Button style={{color:'white', backgroundColor: "#FF4560", border:`1px solid white`, borderRadius:'4px'}} onClick={this.actionClick}> */}
          {/* <Button className={this.props.shimmer ? 'shimmer' : ''} style={this.props.shimmer ? {color:'white', backgroundColor: "#1c003d", border:`1px solid white`, borderRadius:'4px'}:{color:this.props.color, borderColor:'unset'}} onClick={this.actionClick}> */}
          {this.props.ctaText && <div style={{display: window.innerWidth > 768 ? 'flex' : 'unset', alignItems: 'center'}}><Button className={this.props.shimmer ? 'shimmer' : ''} style={ delistedSnackBarActive && !(this.props.showOnsurityData && this.props.showOnsurityData.showOnsuritySnackbar === true) ? {color:'white', backgroundColor:  "#FF4560", border:`1px solid white`, borderRadius:'4px'} : showSaasSnackBarActive || (this.props.showOnsurityData && this.props.showOnsurityData.showOnsuritySnackbar === true)? {color: '#2e108e', backgroundColor: '#ffffff',borderRadius: '4px', fontFamily:'robotomedium', paddingLeft: '24px',paddingRight: '24px', borderColor: 'transparent'}:{color:'white', backgroundColor: "#1c003d", border:`1px solid white`, borderRadius:'4px'}} onClick={this.actionClick}> 
            <span style={{ margin: 'auto', fontFamily:'robotomedium' }}>{this.props.ctaText}</span>
          </Button>
          {this.props.ctaText == 'Share your store' && allCrmData.totalFreeSmsQuota !== allCrmData.totalFreeSmsQuotaUsed && <div>
            <img style={window.innerWitdth < 768 ? {height: '11px', marginRight: '6px'} : {marginLeft: '16px', marginRight: '8px'}} src={window.innerWidth > 768 ? arrowHor : arrowVert} />
            {
              allCrmData.merchantSmsQuota === 1000 ?
                <img style={window.innerWidth < 768 ? {height: '11px'} : {}} src={freeSms} />
                :
                <img style={window.innerWidth < 768 ? {height: '11px'} : {}} src={free500} />
            }            
            </div>}
          </div>
        }
          {this.props.closable && 
          <span style={{position:'fixed',cursor:'pointer', right:'5px', top:window.innerWidth > 768 ? '12px' : '0px'}} onClick={()=>{this.setState({showGroceryModalToMerchant: false},()=>this.props.hideSnackBar)}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#FFF" fillRule="nonzero" d="M18.386 6.469a.29.29 0 0 0 .004-.412l-.973-.974a.293.293 0 0 0-.412.005l-5.268 5.268-5.268-5.268a.29.29 0 0 0-.412-.005l-.974.974a.293.293 0 0 0 .005.412l5.268 5.268-5.268 5.268a.29.29 0 0 0-.005.412l.974.973a.293.293 0 0 0 .412-.004l5.268-5.268 5.268 5.268a.29.29 0 0 0 .412.004l.973-.973a.293.293 0 0 0-.004-.412l-5.268-5.268 5.268-5.268z"/>
          </svg></span>}
        </div>}
      </div>
    </div>
  </div>
  )
}
}

export default connect(store=>({
  merchantActivationStatusData: store.merchantActivation.merchantActivationStatusData,
  merchantActivationStatusLoading: store.merchantActivation.merchantActivationStatusLoading,
  merchantActivationStatusLoaded: store.merchantActivation.merchantActivationStatusLoaded,
  
  feedShortcutsStatusLoading: store.feedShortcutsStatus.feedShortcutsStatusLoading,
  feedShortcutsStatusLoaded: store.feedShortcutsStatus.feedShortcutsStatusLoaded,
  feedShortcutsStatusData: store.feedShortcutsStatus.feedShortcutsStatusData,

  snackBarVisible: store.snackbar.snackBarVisible,

  text: store.snackbar.text,
  ctaText: store.snackbar.ctaText,
  closable: store.snackbar.closable,
  action: store.snackbar.action,
  showEvent: store.snackbar.showEvent,
  clickEvent: store.snackbar.clickEvent,
  hideEvent: store.snackbar.hideEvent,
  color: store.snackbar.color,
  shimmer: store.snackbar.shimmer,
  priority: store.snackbar.priority,
  subscriptionData: store.merchantOnboarding.data,
  remarketingStatus : store.cashVouchers.remarketingStatus,

  previousWithdrawals: store.walletSummary.previousWithdrawals,
  appInstalled: store.appInstall.appInstalled,
}), dispatch=>bindActionCreators({
  showSnackBar,
  hideSnackBar,
  getMerchantActivationStatus,
  showActivationModal,
  getSubscriptionPackageData,
  getMarketingRedemptionStatus,
  updateMerchantSubscription
}, dispatch))(SnackBar)
