const LOAD = 'magicpaySubscription/LOAD';
const LOAD_SUCCESS = 'magicpaySubscription/LOAD_SUCCESS';
const LOAD_FAIL = 'magicpaySubscription/LOAD_FAIL';

const LAUNCH_REQUEST = 'magicpaySubscription/LAUNCH_REQUEST';
const LAUNCH_REQUEST_SUCCESS = 'magicpaySubscription/LAUNCH_REQUEST_SUCCESS';
const LAUNCH_REQUEST_FAIL = 'magicpaySubscription/LAUNCH_REQUEST_FAIL';

const SHOW_MAGICPAY_MODAL_DIRECT = 'magicpaySubscription/SHOW_MAGICPAY_MODAL_DIRECT';

const TOGGLE_MAGICPAY ='magicpaySubscription/TOGGLE_MAGICPAY';
const TOGGLE_MAGICPAY_SUCCESS ='magicpaySubscription/TOGGLE_MAGICPAY_SUCCESS';
const TOGGLE_MAGICPAY_FAIL='magicpaySubscription/TOGGLE_MAGICPAY_FAIL';
 
const initialState = {
  loading: false,
  loaded: false,
  launching: false,
  launched: false,
  showMagicpayModalDirect: false,
}

export default function magicpaySubscription(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        magicpayStatus: action.result,
      }
    case LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false
      }  

    case SHOW_MAGICPAY_MODAL_DIRECT:
      return {
        ...state,
        showMagicpayModalDirect: !state.showMagicpayModalDirect,
     }  

    case TOGGLE_MAGICPAY:
      return {
        ...state,
        togglingMagicPay: true,
    }
    
    case TOGGLE_MAGICPAY_SUCCESS:
      return {
        ...state,
        togglingMagicPay: false,
        toggledMagicPay: true,
        error:null,
      }
      
    case TOGGLE_MAGICPAY_FAIL:
      return{
        ...state,
        toggledMagicPay: false,
        togglingMagicPay: false,
        error: action.error,
      }

    default:
       return state;
  }
}

export function getMagicpaySubscription(accessToken){
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getMagicpaySubscription',{
      data: {accessToken}
    })
  }
}

export function showMagicpayModalFeedShortcutClick(){
  return{
    type: SHOW_MAGICPAY_MODAL_DIRECT,
  }
}

export function toggleMagicPay(accessToken, enable){
  return{
     types: [TOGGLE_MAGICPAY, TOGGLE_MAGICPAY_SUCCESS, TOGGLE_MAGICPAY_FAIL],
     promise : (client) => client.default.post('/enableDisableMagicPayRequest',{
       data: {accessToken, enable}
     })
  }
}
