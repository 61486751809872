import React from 'react';
import Dialog from 'material-ui/Dialog';
import popupBg from '../../images/exitPopup.svg';
import logoBusiness from '../../images/logo-business.svg';
import cross from '../../images/cross.svg';
import { sendEvent, createMCPLog } from '../../helpers/Helpers';


export default class ExitPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            number:'',
            openedPopup:false
        }
    }
    componentDidMount() {
        var self =  this;
        window.addEventListener('mouseout', function(event) {
            if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
                    self.setState({ open: true });
                }
            })
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false,
            openedPopup: true
        })
    };
    handleNumberChange = (event) => {
        this.setState({number: event.target.value});
    };

    onSubmit = () => {
        sendEvent({
            utm_campaign:localStorage.getItem('landingSource') || '',
            subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
            event: 'exit_popup_signup',
            page_type:'exit_popup',
            subject_id:localStorage.getItem('landingMerchantId') || '',
            entity_id:localStorage.getItem('landingMerchantId') || '',
            extra:'exit-popup',
            utm_source:this.props.utm_source
        })
        createMCPLog({
          phone: localStorage.getItem("subjectPhoneNumber") || '',
          utm_campaign: localStorage.getItem('landingSource') || '',
          merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'exit_popup_signup'
        });
        this.props.saveMerchantContacts('', this.state.number, '', localStorage.getItem('landingMerchantId') || "", localStorage.getItem('landingMerchant') || "")
        this.handleClose();
    }

    render() {
        const {handleClose, handleClickOpen} = this;
        const styles = {
            contentStyle: {
              borderRadius : '8px',
              width: '940px',
              maxWidth: '1000px',
              height:'516px'
            },
          
            bodyStyle: {
              padding: '0',
              minHeight: '320px',
              border: "none"
            },
          
            inputStyle: {
              width: '40%',
              height: '40px',
              padding: '8px',
              border: 'solid 1px #d8dae1',
              marginBottom: "10px",
              borderRadius: "5px",
              marginLeft: "60px",
              marginTop: "16px",
              marginRight: "12px",
              fontWeight: "lighter",
              fontSize: "14px"
            },
          
            buttonStyle: {
              width: '25%',
              borderRadius: '4px',
              backgroundColor: '#ef1c74',
              outline: 'none',
              padding: '8px',
              border: 'none',
              color: '#fff',
              marginTop:'16px'
            },
          }
          
        return (
            <div>
            <Dialog
                open={this.state.open && !this.state.openedPopup}
                onClose={handleClose}
                onRequestClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                bodyStyle={styles.bodyStyle}
                contentStyle={styles.contentStyle}
            >
                <div style={{width:"63%",display:"inline-block"}}>
                    <div style={{paddingLeft:"60px", paddingTop:"30px"}}>
                        <h3 style={{color:"rgba(0, 0, 0, 0.87)", fontSize:"48px"}}>Don't miss out on sales!</h3>
                        <div style={{width:"80%"}}><h4 style={{lineHeight: "32px", color:"rgba(0, 0, 0, 0.87)", fontSize:"24px", paddingTop:'13px', fontWeight:200}}>Get <b>₹200</b> in your business wallet on successful partnership</h4></div>
                    </div>
                    <div style={{paddingLeft:"60px", paddingTop:"53px", width:"86%"}}>
                        <h4 style={{color:"rgba(0, 0, 0, 0.87)", fontSize:"16px", paddingTop:'13px', fontWeight:200, lineHeight:"20px"}}>Want to grow your business? Reach out to our community of 10 lac+ customers spending ₹300 crores+ monthly</h4>
                    </div>
                    <input style={styles.inputStyle} onChange={this.handleNumberChange} placeholder={"Enter phone number"} value={this.state.number}/>
                    <button style={styles.buttonStyle} onClick={this.onSubmit}>I'm Interested</button>
                    <div style={{paddingLeft:"60px", paddingTop:"52px"}}>
                        <img src={logoBusiness} />
                    </div>
                </div>
                <div style={{width:"37%", backgroundImage:`url('${popupBg}')`, backgroundSize:"cover", height:"516px", display:"inline-block", float:"right"}}>
                    <img onClick={this.handleClose} style={{ float: "right", padding: "20px", cursor:"pointer"}} src={cross} />
                </div>
            </Dialog>
            </div>
        );
    }
    }