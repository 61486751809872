import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import ErrorPage from '../Error/ErrorPage';
import { userTypes } from '../../constants';

export default class CheckAuthRoute extends Component {
  constructor(props) { 
    super(props);
    this.state = {
      component: this.redirectToPath(this.props.user),
    };
  }

  redirectToPath = (user) => {
    const params = new URLSearchParams(this.props.location.search);
    if(user) {
     //console.log('redirecting to logged in page')
      switch (user.userType) {
        case userTypes.MERCHANT:
          if(params.has('sso') && params.has('sig')){
            this.props.push(`/discourse${this.props.location.search}`)
          }
          else if(params.has('utm_source') && params.get('utm_source') === 'bpp'){
            if(user.isSaasOnly){
              this.props.push('/orders?utm_source=bpp' + (params.has('action') ? `&action=${params.get('action')}` : ''));
            }
            else
              this.props.push('/feed?utm_source=bpp' + (params.has('action') ? `&action=${params.get('action')}` : ''));
          }
          else if(params.has('page')){
            this.props.push(`/${params.get('page')}` + (params.has('action') ? `?action=${params.get('action')}` : ''));
          }
          else this.props.push((user.isCeetMerchantAndMagicpinMerchant ? '/vouchers' : user.isCeetMerchant ? '/ceet' : user.isSaasOnly ? '/orders?' :'/feed?') + params.toString());
          break;
        case userTypes.PARENT_MERCHANT: 
          // this.props.push('/dashboardall' + (params.has('action') ? `&action=${params.get('action')}` : ''));
          this.props.push('/retail' + (params.has('action') ? `?action=${params.get('action')}` : ''));
          break;

        case userTypes.ADMIN: 
          this.props.push('/adminDashboard');
          break;

        case userTypes.ONLINE: 
          this.props.push('/online' + (params.has('action') ? `?action=${params.get('action')}` : ''));
          break;

        case userTypes.BRAND: this.props.push('/brandsHome');
          break;
      }
    }
    return null;
  }

  componentWillMount() {
    if(!this.props.user){
      const accessToken = window.localStorage.getItem('accessToken');
      if(accessToken){
        this.props.loadAuth(accessToken)
        .catch(err => this.setState({component: this.props.component}));
      } else {
        this.setState({component: this.props.component});
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(!this.props.user && nextProps.user){
      this.redirectToPath(nextProps.user);
    }

    if(this.props.component !== nextProps.component) this.setState({component: nextProps.component});
  }

  render() {
    const C = this.state.component;
    return C ? <C {...this.props} /> : null;
  }
}
