import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLocalityBanners } from '../../redux/modules/newMerchantLanding'
import { sendEvent, createMCPLog } from '../../helpers/Helpers'
import views from "../../images/views2.svg";
import language from './languageConst.js';


class LocalityBanners extends Component {

  constructor(props) {
    super(props);
    this.state = {
      competitors: []
    }
    }

  componentDidMount() {
    if (!this.props.merchant_id) return
    this.props.getLocalityBanners(this.props.merchant_id);

  }
  componentWillReceiveProps(nextProps) {
    if (this.props.merchant_id == nextProps.merchant_id){
      return
    }
    this.props.getLocalityBanners(nextProps.merchant_id);
  }

  render() {
    const languageConst = this.props.language ? language[this.props.language] : language["english"];
    if (!(this.props.localityBanners && Array.isArray(this.props.localityBanners) && this.props.localityBanners.length > 0)) return null
    
    let bannerIds = []
    let count = 0;
    return (
      <section className="partners" style={{backgroundColor:"#fff"}}>
        <h2 style={{lineHeight:"1.30",fontWeight:"bold",fontFamily:"robotobold"}}>{languageConst.promotionsHeading}</h2>
        <div>
        {
          this.props.localityBanners.map((bannerData, index) => {
              
          if(count < 3 && !bannerIds.includes(bannerData.user_id)){
            count++;
            let impressions = bannerData.total_impressions;
            bannerIds.push(bannerData.user_id)
            return (
              <article key={'competitors' + index} className="competitor-testimonial">
                <div className="merchant testimonial-merchant">
                  <div className="merchant-logo-container">
                    <img src={bannerData.merchant_logo} className="merchant-logo" />
                  </div>
                  <div className="merchant-details" style={{width:"70%"}}>
                    <h4 className="merchant-name" style={{lineHeight:"2.1"}}>{bannerData.merchant_name}</h4>
                  </div>
                </div>
                <div style={{ height: 0, width: 0}}>
                </div>
                <div style={{ boxShadow: "0px 6px 10px 2px #00000019", borderRadius:"10px"}}>
                <div style={{height:"216px"}}>
                  <div style={{ display: "block", textAlign: "left" }}>
                    <img style={{width:"100%"}} src={bannerData.merchant_cover_pic}></img>
                  </div>
                </div>
                {impressions &&
                  <div className="competitor-data" style={{ paddingRight:"14px", padding:"10px", textAlign:"left"}} >
                    <div className="competitor-transactions-driven" >
                      <img src={views} style={{ width: "40px", height: "40px", verticalAlign: 'baseline'}} />
                      <div>
                        <p className="count">{impressions.toLocaleString('en-IN')}</p>
                        <p className="text" >{languageConst.views}</p>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </article>
            )}
          })
        }
        </div>
        {this.props.showClaimDialog &&
            <a className="start-button button-header-hover" style={{marginTop: window.innerWidth > 768 ? "40px" : "0px"}} onClick={() => {
              this.props.showClaimDialog();
              window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
              sendEvent({
                utm_campaign: localStorage.getItem('landingSource') || '',
                subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                event: 'become_a_partner',
                page_type: 'new_merchant_landing'
              })
              createMCPLog({
                phone: localStorage.getItem("subjectPhoneNumber") || '',
                utm_campaign: localStorage.getItem('landingSource') || '',
                merchant_id: localStorage.getItem('landingMerchantId') || '',
                event: 'become_a_partner'
              });
            }}>{languageConst.buttonCta3}</a>
          }
      </section>
    )
  }
}

export default connect(state => ({
  localityBanners: state.newMerchantLanding.localityBanners,
  language: state.claimBusiness.language
}), {
    getLocalityBanners: getLocalityBanners
  })(LocalityBanners)