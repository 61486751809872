const GET_POST = 'postPage/GET_POST';
const GET_POST_SUCCESS = 'postPage/GET_POST_SUCCESS';
const GET_POST_FAIL = 'postPage/GET_POST_FAIL';

const LIKE = 'postPage/LIKE';
const LIKE_SUCCESS = 'postPage/LIKE_SUCCESS';
const LIKE_FAIL = 'postPage/LIKE_FAIL';

const COMMENT = 'postPage/COMMENT';
const COMMENT_SUCCESS = 'postPage/COMMENT_SUCCESS';
const COMMENT_FAIL = 'postPage/COMMENT_FAIL';

const LIKE_COMMENT = 'postPage/LIKE_COMMENT';
const LIKE_COMMENT_SUCCESS = 'postPage/LIKE_COMMENT_SUCCESS';
const LIKE_COMMENT_FAIL = 'postPage/LIKE_COMMENT_FAIL';

const GET_COMMENT = 'postPage/GET_COMMENT';
const GET_COMMENT_SUCCESS = 'postPage/GET_COMMENT_SUCCESS';
const GET_COMMENT_FAIL = 'postPage/GET_COMMENT_FAIL';

const GET_LIKES = 'postPage/GET_LIKES';
const GET_LIKES_SUCCESS = 'postPage/GET_LIKES_SUCCESS';
const GET_LIKES_FAIL = 'postPage/GET_LIKES_FAIL';

const GET_COMMENT_LIKES = 'postPage/GET_COMMENT_LIKES';
const GET_COMMENT_LIKES_SUCCESS = 'postPage/GET_COMMENT_LIKES_SUCCESS';
const GET_COMMENT_LIKES_FAIL = 'postPage/GET_COMMENT_LIKES_FAIL';


const initialState = {
  post: null,
  loaded: false,
  comments: [],
  likelist: [],
  commentLikes: [],
  nextLikes: -1
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case GET_POST :
    return {
      ...state,
      post: null,
      loading: true,
      loaded: false,
      comments: [],
      likelist: [],
    }

    case GET_POST_SUCCESS :
    return {
      ...state,
      loading: false,
      loaded: true,
      post:action.result[0],
      error: null
    }

    case GET_POST_FAIL:
    return {
      ...state,
      loading: false,
      loaded: false,
      post: null,
      error: action.error,
    }

    case GET_COMMENT :
    return {
      ...state,
      loadingComments: true,
    }

    case GET_COMMENT_SUCCESS :
    return {
      ...state,
      loadingComments: false,
      loadedComments: true,
      comments: action.result.data,
    }

    case GET_COMMENT_FAIL:
    return {
      ...state,
      loadedComments: false,
      loadingComments: false,
      commentsError: action.error,
    }

    case GET_LIKES :
    return {
      ...state,
      loadingLikes: true,
    }

    case GET_LIKES_SUCCESS :
    return {
      ...state,
      loadingLikes: false,
      loadedLikes: true,
      likelist: action.next == -1 ? action.result.data : [...state.likelist, ...action.result.data],
      nextLikes: action.result.next
    }

    case GET_LIKES_FAIL:
    return {
      ...state,
      loadingLikes: false,
      loadedLikes: false,
      likelistError: action.error,
    }

    case LIKE:
    return {
      ...state,
      liking: true,
    };

    case LIKE_SUCCESS:
    return {
      ...state,
      liking: false,
      liked: true,
      post: {...state.post, like_id: action.result.id, likes : state.post.likes + 1}
    };

    case LIKE_FAIL:
    return {
      ...state,
      liked: false,
      liking: false,
      likeError: action.error,
    };

    case COMMENT:
    return {
      ...state,
      commenting: true,
    };

    case COMMENT_SUCCESS:
    return {
      ...state,
      commenting: false,
      commented: true,
      post: {...state.post, comments : state.post.comments + 1},
      comments: [...state.comments, action.newComment],
    }

    case COMMENT_FAIL:
    return {
      ...state,
      commented: false,
      commenting: false,
      commentError: action.error,
    }

    case LIKE_COMMENT:
    return {
      ...state,
      likingComment: true,
    }

    case LIKE_COMMENT_SUCCESS:
    return {
      ...state,
      likingComment: false,
      likedComment: true,
      //  here we have to update like status of the comment
      comments: state.comments.map(comment => {
        if(comment.comment_id == action.commentId){
          const newComment = {...comment, likes_count: comment.likes_count + 1, like_id: action.result};
          return newComment;
        }
        else return comment;
      }),
    }


    case GET_COMMENT_LIKES :
    return {
      ...state,
      commentLikes: [],
      loadingCommentLikes: true,
    }

    case GET_COMMENT_LIKES_SUCCESS :
    return {
      ...state,
      loadingCommentLikes: false,
      loadedCommentLikes: true,
      commentLikes: action.result.data,
    }

    case GET_COMMENT_LIKES_FAIL:
    return {
      ...state,
      loadedCommentLikes: false,
      loadingCommentLikes: false,
      commentLikesError: action.error,
    }

    default:
    return state;
  }
}

export function like(accessToken, postId, postUserId){
  return {
    types: [LIKE, LIKE_SUCCESS, LIKE_FAIL],
    promise: (client) => client.default.post('/likePost',{
      data : { accessToken, postId, userId: postUserId }
    })
  };
}

export function comment(userId,postId,content,accessToken,logo,name){
  const newComment = {
    profile_pic : logo,
    name : name,
    comment : content,
    like_id : null,
    likes_count : 0,
    action_time : Date(),
  }
  return {
    types: [COMMENT,COMMENT_SUCCESS,COMMENT_FAIL],
    promise: (client) => client.default.post('/postComment',{
      data : { p_id : postId,
        c_uid : userId,
        content : content,
        accessToken
      }
    }),newComment
  }
}

export function getComments(userId,postId,accessToken){
  const action = {
    url: 'https://webapi.magicpin.in/posts/'+postId+'/comment/',
    method: 'get',
    headerAuth: true,
  };
  return {
    types: [GET_COMMENT,GET_COMMENT_SUCCESS,GET_COMMENT_FAIL],
    promise: (client) => client.default.post('/takeAction/getComments', {
      data: {accessToken, action}
    })
  }
}

export function getLikes(userId,postId,accessToken,next){
  var url = '';
  if(next != -1){
    url = 'https://webapi.magicpin.in/posts/'+postId+'/like/?&start='+next+'&limit=10';
  }
  else
    url = 'https://webapi.magicpin.in/posts/'+postId+'/like/';
  const action = {
    url: url,
    method: 'get',
    headerAuth: true,
  };
  return {
    types: [GET_LIKES,GET_LIKES_SUCCESS,GET_LIKES_FAIL],
    promise: (client) => client.default.post('/takeAction/getLikes', {
      data: {accessToken, action}
    }),
    next
  }
}


export function getPost(postId,accessToken){
  return {
    types: [GET_POST,GET_POST_SUCCESS,GET_POST_FAIL],
    promise: (client) => client.default.post('/getPostData',{
      data: {postId,accessToken}
    })
  }
}

export function getCommentLikes(accessToken, commentId){
  const action = {
    url: 'https://webapi.magicpin.in/comments/'+commentId+'/like/',
    method: 'get',
    headerAuth: true,
  };
  return {
    types: [GET_COMMENT_LIKES, GET_COMMENT_LIKES_SUCCESS, GET_COMMENT_LIKES_FAIL],
    promise: (client) => client.default.post('/takeAction/getCommentLikes', {
    data: {accessToken, action}
    })
  }
}

export function likeComment(accessToken, commentId){
  return {
    types: [LIKE_COMMENT, LIKE_COMMENT_SUCCESS, LIKE_COMMENT_FAIL],
    promise: (client) => client.default.post('/likeAComment', {
    data: {accessToken, commentId}
    }),
    commentId
  }
}
