import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as shareActions from '../../redux/modules/fbShare';
import {setFbDetails} from '../../redux/modules/authReducers/auth';
import { toast } from 'react-toastify';

const FB = window.FB;

export default connect(
	store => ({
		sharing : store.fbShare.sharing,
		shared : store.fbShare.shared,
		fbDetails : store.fbShare.fbDetails,
		fbMessage : store.fbShare.fbMessage,
		deletedFbToken : store.fbShare.deletedFbToken,
		deletingFbToken: store.fbShare.deletingFbToken,
	}),
	dispatch => bindActionCreators({...shareActions, setFbDetails}, dispatch)
)(class FbShare extends Component{
	constructor(props){
		super(props);
		this.state = {
			showData: 'comments',
			showPagesModal : false
		}

	if(this.props.fbDetails != null){
			this.setState({showPagesModal : true});
		}
	}
	// componentWillReceiveProps(nextProps){	
	//  if (this.props.deletingFbToken && nextProps.deletedFbToken){
	// 	toast("Your Account has been successfully removed");	
	//     }
	// }
	componentDidUpdate (prevProps){	
		if (prevProps.deletingFbToken && this.props.deletedFbToken){
		   toast("Your Account has been successfully removed");	
		   }
	   }


	onSelectPage(fbToken,pageId){
	const url="https://magicpin.in/partner/store/feed_pic/"+this.props.postId+"/";
	var sharePostObject={
		link: url,
		userName: this.props.userName,
		merchantName: this.props.merchantName,
		rating: this.props.rating,
		review: this.props.review, 
	}
	this.props.fbSharePost(this.props.accessToken,fbToken,pageId,sharePostObject);
	this.setState({showPagesModal: false}) ;
	toast('Posted Successfully');

	}

	addAccount(){
		window.FB.login((fbResponse) => {
		if (fbResponse.authResponse) {

			this.props.setFbDetails(this.props.accessToken, fbResponse.authResponse.accessToken, fbResponse.authResponse.userID);
			window.FB.api('/me/accounts', (response) => {
				this.props.setPageData(response.data);
				this.setState({showPagesModal: true});
			});

		} else {
			this.props.hideShareModal();
		}
		},{scope: 'pages_show_list, pages_manage_posts, pages_read_engagement, publish_pages'});

	}

	deleteAccount(){
		this.props.deleteFbToken(this.props.accessToken)
		this.props.hideShareModal();
	}


	componentDidMount(){
		if(this.props.facebookId){
		 this.props.getFbToken(this.props.facebookId,this.props.accessToken).then(res => {
		if(res.length && res[0].message){
			if(window.FB && window.FB.login && window.FB.api) {
				window.FB.login((fbResponse) => {
					if (fbResponse.authResponse) {
					this.props.setFbDetails(this.props.accessToken, fbResponse.authResponse.accessToken, fbResponse.authResponse.userID);
					window.FB.api('/me/accounts', (response) => {
						this.props.setPageData(response.data);
						this.setState({showPagesModal: true});
						});
					}else {
					 this.props.hideShareModal();
					}
					},{scope: 'pages_show_list,publish_pages, pages_manage_posts, pages_read_engagement'});
			}
			}
			else{
				this.setState({showPagesModal: true});
			}
		});
		}

		else {
		if(window.FB && window.FB.login && window.FB.api) {
			window.FB.login((fbResponse) => {
			if (fbResponse.authResponse) {
			  this.props.setFbDetails(this.props.accessToken, fbResponse.authResponse.accessToken, fbResponse.authResponse.userID);
			  window.FB.api('/me/accounts', (response) => {
				 this.props.setPageData(response.data);
				 this.setState({showPagesModal: true});
				});
	
			} 
			else {
			this.props.hideShareModal();
			}
			},{scope: 'pages_show_list, publish_pages, pages_manage_posts, pages_read_engagement'});
		}
	  }
	}

	render(){
	  return (
		this.props.fbDetails != null &&
		<Modal onHide={this.props.hideShareModal} show={this.state.showPagesModal} dialogClassName="small-modal-box customer-comment" >
		  <div>
			<Modal.Header closeButton></Modal.Header>
		  <Modal.Body>
			<div className="ReviewComment">
			  <h2 style={{textAlign:"center",paddingLeft:"0px" }} id="getCommentCount"><span className="review-cooment-icon"></span>Select a Facebook Page</h2>
			  <h6 style={{textAlign:"center"}} id="getCommentCount"><span className="review-cooment-icon"></span>Please Add Account which has official page linked with it.</h6>
			</div>
			<div id="CustomersComment_bx" style={{"padding":"16px 0"}}>
			  <div className="customer-review-box" id="getCustomerComments">
				 {this.props.fbDetails.map((data,index) =>{		
			return(
			<div key={index}>
			  <div className="row">
				<div className="col-xs-12 review-ques review-reply-text" style={{"borderBottom":"5"}}>
				 <div style={{paddingLeft:"20px"}}>{index+1}. 
				   <a style={{paddingLeft:"5px"}} href='#' onClick={this.onSelectPage.bind(this,data.access_token,data.id)}> {data.name}</a>
				   <a href="#" onClick={this.deleteAccount.bind(this)} style={{paddingRight:"20px",float:"right"}} >Remove </a>
				 </div>
				</div>
			  </div>
				<div className="Separator2"></div>
			</div>
			);
					})}
			 <div className="row">
			  <div className="col-xs-12 review-ques review-reply-text" style={{"borderBottom":"5"}}>
				 <div style={{textAlign:"center"}}>
				   <a href='#' onClick={this.addAccount.bind(this)}>Add Account</a></div>
				 </div>
			   </div> 
			 </div>
			</div>
		  </Modal.Body>
		</div>
		</Modal>
		);
	}
})
