const UPSELL_PAYMENT = 'upsellPayment/UPSELL_PAYMENT';
const UPSELL_PAYMENT_SUCCESS = 'upsellPayment/UPSELL_PAYMENT_SUCCESS';
const UPSELL_PAYMENT_FAIL = 'upsellPayment/UPSELL_PAYMENT_FAIL';

const UPSELL_SUBSCRIPTION_ID = 'upsellPayment/UPSELL_SUBSCRIPTION_ID';
const UPSELL_SUBSCRIPTION_ID_SUCCESS = 'upsellPayment/UPSELL_SUBSCRIPTION_ID_SUCCESS';
const UPSELL_SUBSCRIPTION_ID_FAIL = 'upsellPayment/UPSELL_SUBSCRIPTION_ID_FAIL';


const intialState = {
  
};

export default function upsellPayment(state = intialState, action = {} ){

	switch(action.type){

		case UPSELL_PAYMENT:
      return {
        ...state,
        upsellPaymentLoading: true
      };
      
    case UPSELL_PAYMENT_SUCCESS:
      return {
        ...state,
        upsellPaymentLoading: false,
        upsellPaymentLoaded: true,
        upsellPaymentData: action.result,
      };

    case UPSELL_PAYMENT_FAIL:
      return {
        ...state,
        upsellPaymentLoading: false,
        upsellPaymentLoaded: true,
        upsellPaymentError: action.error,
      };
    
    case UPSELL_SUBSCRIPTION_ID:
      return {
        ...state,
        upsellSubscriptionIdLoading: true,
      };
    case UPSELL_SUBSCRIPTION_ID_SUCCESS:
      return {
        ...state,
        upsellSubscriptionIdLoading: false,
        upsellSubscriptionIdLoaded: true,
        upsellSubscriptionId: action.result.subscriptionId,
      };
    case UPSELL_SUBSCRIPTION_ID_FAIL:
      return {
        ...state,
        upsellSubscriptionIdLoading: false,
        upsellSubscriptionIdLoaded: true,
        upsellSubscriptionIdError: action.error,
      };

      default:
      return state;
	}
}


export function verifyUpsellPayment(args){
  return {
    types: [UPSELL_PAYMENT, UPSELL_PAYMENT_SUCCESS, UPSELL_PAYMENT_FAIL],
    promise: (client) => client.default.post('/verifyUpsellPayment', {
      data: args
    })
  };
}



export function getUpsellSubscriptionId(args){
  return {
    types: [UPSELL_SUBSCRIPTION_ID, UPSELL_SUBSCRIPTION_ID_SUCCESS, UPSELL_SUBSCRIPTION_ID_FAIL],
    promise: (client) => client.default.post('/getUpsellSubscriptionId', {
      data: args
    })
  };
}
