/*global chrome*/
import React from 'react'
import {CHROME_EXTENSION_ID} from '../config';
// import {CHROME_EXTENSION_ID} from '../constants';
const request = require('superagent');

export function getExtensionId(){
    return CHROME_EXTENSION_ID
}

export function isChrome() {
    let isChrome = navigator.userAgent.includes("Chrome") && navigator.vendor.includes("Google Inc");
    if (!isChrome) {
    //    toDo:// log
        console.log("Not Chrome")
    }
    return isChrome
}

export function sendMessage(text='',type='MAGICORDER'){
    return new Promise((resolve, reject) => {
        if (!isChrome()) {
            console.log("extensionlog not chrome")
            //toDo:// log failure ie browser type is not chrome
            return reject("NOT_CHROME")
        }
        /*if (!chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
            console.log("extensionlog no chrome method")
            //toDo:// log failure ie chrome functions undefined
            return reject("CHROME_RUNTIME_MISSING")
        }*/
        var data = { type, text};
        chrome.runtime.sendMessage(getExtensionId(),data,function (reply) {
            console.log("extensionlog reply callback",reply)
            return resolve(reply)
        });
    })
}

export function getVersion() {
    return new Promise((resolve, reject) => {
        // if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && chrome && chrome.runtime) {
        //     reject(0)
        // }
        sendMessage("VERSION","VERSION").then((response) => {
            console.log("extensionlog in then",response)
            if (response && response.version) {
                return resolve(response.version)
            }
            reject(`Extension not installed: ${JSON.stringify(response)}`)
        }).catch(err => {
            return reject(`sendMessage to extension failed: ${JSON.stringify(err)}`)
        })
    })
}