import React from 'react'
import CoverImage from './CoverImage'
import Iphone from './Iphone'
import BannerTag from '../../images/bannerTag.svg'
import PrimaryButton from './PrimaryButton'

const PromotionCard = (props) => {
    let seperatorClass = "seperator";
    if (props && props.promotionType === 'banner') seperatorClass += " bannerSepereratorMargin";
    return (
        <div className='promotionCardContainer'>
            <div className='iphoneDimensions'>
                <div className='overtop' style={{ width: '100%', height: '100%' }}>
                    <Iphone {...props}/>
                </div>
            </div>
            <div className='iphoneDimensions' style={{ height: 'auto' }}>
                <div className='overtop promotionCardDescription'>
                    <div style={{ marginTop: '8px' }}>
                        {props && props.promotionType === 'feedpush' ? <h2 className='smallHeading' style={{textTransform:'capitalize'}}>{props && props.promotionType}</h2> :
                            <div className='spacebetweenCenter'>
                                <h2 className='smallHeading'>Banners</h2>
                                <div style={{ width: '60%' }}>
                                    <CoverImage coverImageComponent={BannerTag} />
                                </div>
                            </div>}
                        <div className={seperatorClass}></div>
                        <p className='paragraph'>{props && props.description}</p>
                        {props && props.promotionType === 'feedpush' ? <h4 className='highlightHyperlink' style={{ marginTop: '15px' }} onClick={props.showFeedpushSamples}>View Samples</h4> :
                            <div className='spacebetweenCenter' style={{ margin: '4px auto' }}>
                                <h4 className='highlightHyperlink' style={{ marginTop: '15px' }} onClick={props.showBannerSamples}>View Samples</h4>
                                <div style={{ width: '50%' }}>
                                    <PrimaryButton buttonName={"I am Interested"} onClick={props.showInfoHandler} />
                                </div>
                            </div>
                        }
                        {props && props.promotionType === 'feedpush' && <p className='smallText'>Make your own feedpush all by yourself. <span className='highlightHyperlink' onClick={()=> { props.sendBqEvent("promotion_feed_push_create_button_clicked"); props.push('/promotions/feedpush')}}>Explore</span></p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionCard