import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'material-ui/Dialog';
import SearchBusiness from './SearchBusiness';
import ThanksPopup from './ThanksPopup';
import { claimBusiness, saveMerchantContacts } from '../../redux/modules/claimBusiness';
import { paymentMailer, activatePrivilegeAccount, createComissionPSR } from '../../redux/modules/newMerchantLanding';
import config from '../../config';
import { sendEvent, guid, currencyString, changeFromINR, createMCPLog } from '../../helpers/Helpers'
import * as ls from '../../helpers/LocalStorageHelper'
import Loader from '../../images/loader.gif'
import language from './languageConst.js';
import {countries} from '../../constants';

export default connect(store => ({
    submitted: store.claimBusiness.submitted,
    submiterror: store.claimBusiness.error,
    submitting: store.claimBusiness.submitting,
    isReferral: store.merchantList.isReferral,
    referrerId: store.merchantList.referrerId,
    activationData: store.newMerchantLanding.activationData,
    createPSRData: store.newMerchantLanding.createPSRData
  }), dispatch => bindActionCreators({
  claimBusiness,
  saveMerchantContacts,
  paymentMailer,
  activatePrivilegeAccount,
  createComissionPSR
}, dispatch))
(class SearchBusinessDialog extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: !!this.props.open,
      showSearch: false,
      name: ls.get(ls.NAME),
      email: ls.get(ls.EMAIL),
      phone: ls.get(ls.PHONE),
      isName:  true,
      isPhone : true,
      isEmail: true,
      outlet: '',
      city: '',
      locality: '',
      isOutlet: true,
      isCity: true,
      isLocality: true,
      showThanksDialog: false,
      title: '',
      showLoader: false,
    }
  }

  componentWillMount() {
    if (!localStorage.getItem("landingMerchantId"))
      this.setState({
        showSearch: true,
        title: ''
    });
    if (this.props.hidePhoneInput) {
      this.setState({phone: this.props.phoneNumber})
    }

    sendEvent({
      utm_campaign:localStorage.getItem('landingSource') || '',
      subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'magicstore_signup_started':'signup_started',
      page_type:this.props.source ? this.props.source+'_signup_dialog' :'signup_dialog',
      utm_source:this.props.utm_source
    })
    createMCPLog({
      phone: localStorage.getItem("subjectPhoneNumber") || '',
      utm_campaign: localStorage.getItem('landingSource') || '',
      merchant_id: localStorage.getItem('landingMerchantId') || '',
      event: this.props.onlineStore ? 'magicstore_signup_started':'signup_started',
    });
  }


  changePage = () => {
    this.setState({
      showSearch: false,
    });
    this.merchant = localStorage.getItem("landingMerchant");
    this.rating = localStorage.getItem("landingMerchantRating");
    this.merchant_id = localStorage.getItem("landingMerchantId");
    this.reviews = localStorage.getItem("landingMerchantReviews");
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    if(this.props.merchant_not_found)
      this.props.hideAddMerchantDialog && this.props.hideAddMerchantDialog()
    else
      this.props.close();
    this.setState({open: false});
  };

  handleNameChange = (event) => {
    this.setState({name: event.target.value});
  };

  handleEmailChange = (event) => {
    let cleanedEmail = event.target.value.replace(/\s/g,"");
    this.setState({email: cleanedEmail});
  };

  handlePhoneChange = (event) => {
    this.setState({phone: event.target.value});
  };


  handleMerchantDetails = (event, val) => {
    this.setState({[val] : event.target.value});
  }

  validatePersonalDetails = () => {
    const nameRegex = /^[a-zA-Z' -]+$/;
    if(!nameRegex.test(this.state.name)){
      this.setState({isName:  false});
      return false
    }
    else
      this.setState({isName: true});
    

    const emailRegex = /^[a-zA-Z._0-9]+@(\w+\.)+\w+$/;
    if(!emailRegex.test(this.state.email)){
      this.setState({isEmail: false});
      return false
    }
    else 
      this.setState({isEmail: true});

    let phoneRegex = /^[0-9]{10,12}$/;

    if(!phoneRegex.test(this.state.phone)) {
      this.setState({isPhone : false});
      return false
    }
    else 
      this.setState({isPhone: true});

    return true
  }

  validateOutletDetails = () => {
    if(this.props.merchant_not_found && !this.state.outlet){
      this.setState({isOutlet: false});
      return false
    }
    else
      this.setState({isOutlet: true})

    if(this.props.merchant_not_found && !this.state.city){
      this.setState({isCity: false});
      return false
    }
    else
      this.setState({isCity: true})

    if(this.props.merchant_not_found && !this.state.locality){
      this.setState({isLocality: false});
      return false
    }
    else
      this.setState({isLocality: true})

      return true
  }

  sendAddOutletMail = () => {
    var mail_data = {
      businessName: this.state.outlet,
      city: this.state.city,
      email: this.state.email,
      locality: this.state.locality,
      personName: this.state.name,
      phone: this.state.phone,
      message: '[MerchantAquisition] merchant addition request from '+this.state.outlet,
      request_type: 'merchant_addition',
    }
    this.props.paymentMailer(mail_data)
    if(this.props.hideAddMerchantDialog)
      this.props.hideAddMerchantDialog()
    
    sendEvent({
      utm_campaign:localStorage.getItem('landingSource') || '',
      subject_merchant_id:localStorage.getItem('landingMerchantId'),
      event: 'add_merchant',
      page_type:this.props.source ? this.props.source+'_signup_dialog' :'signup_dialog',
      subject_id:this.state.phone,
      entity_id:this.state.email,
      extra:this.state.name,
      utm_source:this.props.utm_source
    })
    createMCPLog({
      phone: localStorage.getItem("subjectPhoneNumber") || '',
      utm_campaign: localStorage.getItem('landingSource') || '',
      merchant_id: localStorage.getItem('landingMerchantId'),
      event: 'add_merchant'
    });
  }

  sendFosSignup = (data) => {
    return this.props.paymentMailer(data)
  }

  submitDetails = () => {
    this.setState({showLoader: true});
    let isValid = false
    window.ga('send', 'event', 'Logout_page','Claim_Business','SIGNUP');
    isValid = this.validatePersonalDetails()
    if (!isValid) {
      this.setState({showLoader: false});
      return
    }
    localStorage.setItem('landingName', this.state.name);
    localStorage.setItem('landingEmail', this.state.email);
    localStorage.setItem('landingPhone', this.state.phone);

    var data = {
      businessName: localStorage.getItem("landingMerchant"),
      city: localStorage.getItem("landingCity"),
      email: this.state.email,
      locality: localStorage.getItem("landingLocality"),
      merchantId: localStorage.getItem("landingMerchantId"),
      personName: this.state.name,
      phone: this.state.phone,
      source: localStorage.getItem('landingSource') || 'direct',
      sendMailToInsideSales: true
    }

    if(this.props.opportunity_id){
      data.sendMailToInsideSales = false
    }
    
    if (this.props.merchant_not_found){
      let isOutletValid = this.validateOutletDetails()
      if(isOutletValid) this.sendAddOutletMail()
    }
    else{
      if(this.props.fos){
        this.props.saveMerchantContacts(this.state.name, this.state.phone, this.state.email, localStorage.getItem('landingMerchantId'), localStorage.getItem('landingMerchant'),'fos' )
        this.sendFosSignup(data).then(() => this.props.push('/paymentUpsell'))
      }
      else if(this.props.onlineStore){
        this.props.saveMerchantContacts(this.state.name, this.state.phone, this.state.email, localStorage.getItem('landingMerchantId'), localStorage.getItem('landingMerchant'),'fos' )
        this.props.push('/magicStoreOnboard')
      }
      else{
        
        this.props.saveMerchantContacts(this.state.name, this.state.phone, this.state.email, localStorage.getItem('landingMerchantId'), localStorage.getItem('landingMerchant'))
          this.props.claimBusiness(data).then(() => {
            let accountData = {
            "merchantId": localStorage.getItem('landingMerchantId'),
            "packageName": "bpp",
            "merchantPhone": this.state.phone,
            "merchantEmail": this.state.email,
            "amount": 200,
            "createdBy": "merchant_portal_bpp",
            }
            if(this.props.opportunity_id){
              accountData.opportunityId = this.props.opportunity_id;
              localStorage.setItem('opportunity_id', this.props.opportunity_id);
              localStorage.setItem('utm_campaign', 'field_onboarding');
            }
            this.props.activatePrivilegeAccount(accountData).then(() => {
              this.loginShortUrl = this.props.activationData && this.props.activationData.shortUrl ? JSON.parse(this.props.activationData.shortUrl) : this.props.activationData.defaultUrl
              this.setState({showLoader : false});
              window.location.href = this.loginShortUrl
              ls.set(ls.PRIVILEGE_ACTIVATED, true)
              return
            }).catch(() => {
              this.setState({showLoader: false})
            })
        }) 
    }
      
      sendEvent({
        utm_campaign:localStorage.getItem('landingSource') || '',
        subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
        event: this.props.onlineStore ? 'magicstore_signup':'signup',
        page_type:this.props.source ? this.props.source+'_signup_dialog' :'signup_dialog',
        subject_id:this.state.phone,
        entity_id:this.state.email,
        extra:this.state.name,
        utm_source:this.props.utm_source
      })
      createMCPLog({
        phone: localStorage.getItem("subjectPhoneNumber") || '',
        utm_campaign: localStorage.getItem('landingSource') || '',
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: this.props.onlineStore ? 'magicstore_signup':'signup',
      });
    }

    if(this.props.onSubmit){
      this.props.onSubmit()
      this.props.close && this.props.close()
    }
  }

  render(){
    const languageConst = this.props.language ? language[this.props.language] : language["english"];
    const country = this.props.country ? this.props.country.toUpperCase() : countries.INDIA;
    const styles = {
      titleStyle: {
        backgroundColor: 'transparent',
        fontSize: '16px',
        color: '#000',
        padding: '16px',
        paddingTop: '8px',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
      },

      contentStyle: {
        borderRadius : '8px',
        width: '400px',
      },

      closeStyle: {
        position: 'absolute',
        top: '9px',
        right: '9px',
        width: '20px',
        height: '20px',
        cursor:'pointer',
        padding:"2px",
        borderRadius:"50%",
        backgroundColor:"white",
      },

      bodyStyle: {
        padding: '0',
        minHeight: '320px',
        border: "none"
      },

      imgStyle: {
        width: '58%',
        marginLeft:"17%"
      },

      textContainerStyle: {
        padding: '16px',
        paddingTop:"0px"
      },

      headingStyle: {
        fontSize: '14px',
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.87)'
      },

      inputStyle: {
        display: 'block',
        width: '100%',
        height: '40px',
        padding: '8px',
        border: 'solid 1px #d8dae1',
        marginBottom: "10px",
        borderRadius: "5px"
      },

      buttonStyle: {
        width: '100%',
        borderRadius: '4px',
        backgroundColor: '#ef1c74',
        outline: 'none',
        padding: '8px',
        border: 'none',
        color: '#fff',
        marginTop:'16px'
      },

      errorStyle: {
        color: 'red',
        fontSize: '12px',
        display: 'block',
        textAlign: 'left',
      },

      paperStyle: {
        style: {
            borderRadius: '8px',
        }
      }
    }

    const landingCountry =  localStorage.getItem('landingCountry') || countries.INDIA
    return (
      <div>
          <Dialog
            title= {this.state.title}
            titleStyle = {styles.titleStyle}
            paperProps = {styles.paperStyle}
            bodyClassName = "dialog-body"
            contentClassName= "dialog-content"
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose}
            autoDetectWindowHeight={true} 
            autoScrollBodyContent={true}
            modal={true}>
            <img src={config.host + "/images/cross2.png"} onClick={this.handleClose} style={styles.closeStyle}/>
            { ((!this.state.showSearch && !this.props.url) || this.props.merchant_not_found)? (
                <div style={{border:"none", marginTop:"23px"}}>
                  <section style={styles.textContainerStyle}>
                    <h2 style={{color: "rgba(0, 0, 0, 0.87)", fontSize: window.innerWidth > 768 ? "20px" : "18px", lineHeight:"1.4"}}>{country == 'INDIA' ? this.props.onlineStore ? 'Claim your online store' : languageConst.popupHeading2 : languageConst.popupHeading}</h2>
                    {/* {country == 'INDIA' && <p style={{...styles.headingStyle,color: "rgb(239, 28, 116"}}> {languageConst.oneTimeFee} <span style={{textDecoration: "line-through",marginLeft: "-1px"}}>999</span>/- ₹99/- (90% off)</p>} */}
                    <p style={styles.headingStyle}>{languageConst.popupSubHeading}</p>
                    <input style={styles.inputStyle} onChange={this.handleNameChange} placeholder={this.props.onlineStore ? 'Owner/ Manager Name' :languageConst.nameConst} value={this.state.name}/>
                    { !this.state.isName &&
                      <p style={styles.errorStyle}>{languageConst.iName}</p>
                    }
                    <input style={styles.inputStyle} onChange={this.handleEmailChange} placeholder={languageConst.emailConst} value={this.state.email}/>
                    { !this.state.isEmail &&
                      <p style={styles.errorStyle}>{languageConst.iEmail}</p>
                    }
                    {!this.props.hidePhoneInput &&
                    <input type="number" style={styles.inputStyle} onChange={this.handlePhoneChange} placeholder={languageConst.phoneConst} value={this.state.phone}/>}
                    { !this.props.hidePhoneInput && !this.state.isPhone &&
                      <p style={styles.errorStyle}>{languageConst.iPhone}</p>
                    }
  
                    {this.props.merchant_not_found && 
                      <div>
                        <input style={styles.inputStyle} onChange={(event)=>this.handleMerchantDetails(event,'outlet')} placeholder="Outlet name"/>
                        { !this.state.isOutlet &&
                          <p style={styles.errorStyle}>{languageConst.iOutlet}</p>
                        }
                        <input style={styles.inputStyle} onChange={(event)=>this.handleMerchantDetails(event,'city')} placeholder="City" />
                        { !this.state.isCity &&
                          <p style={styles.errorStyle}>{languageConst.iCity}</p>
                        }
                        <input style={styles.inputStyle} onChange={(event)=>this.handleMerchantDetails(event,'locality')} placeholder="Locality" />
                        { !this.state.isLocality &&
                          <p style={styles.errorStyle}>{languageConst.iLocality}</p>
                        }
                      </div>
                    }
                    
                    <button disabled={this.props.submitting} style={styles.buttonStyle} onClick={this.submitDetails}>
                    {!this.state.showLoader &&
                      languageConst.signUpConst
                    }
                    {this.state.showLoader &&
                      <img src={Loader} style={{width:"20px", height:"20px"}}/>
                    }
                    </button>
                      {!this.props.onlineStore && <div style={{fontFamily:"robotomedium", fontSize:"15px", textAlign:"center", marginTop:"22px", padding: "0 10px", color:"#00bfa5", marginBottom:"10px"}}>
                        {languageConst.getWalletHeading} <span style={{fontSize:"20px", fontFamily:"robotobold" }}>{currencyString(changeFromINR(200, country), country, 0)}</span> {languageConst.getWalletHeading2}
                      </div>}
                    
                    
                    
                    {!this.props.onlineStore && this.props.isReferral && <div style={{fontFamily:"robotoregular", fontSize:"12px", color:"#00BFA5", textAlign:"center", marginTop:"8px"}}>
                    {languageConst.eligibleConst} {languageConst.referralConst}.
                      </div>}
                    
                  </section>
                </div>
              ) : (
                <div>
                  <div style = {{backgroundColor: "#ef1c74", height: "42px", textAlign: "left"}}>
                    <div style={{padding: "10px", color: "#fff"}}>{languageConst.findStore}</div>
                  </div>
                  <div className="dialog-search-container">
                    <SearchBusiness changePage={this.changePage} url={this.props.url} language={this.props.language} onlineStore={this.props.onlineStore} />
                  </div>
                  {!this.props.onlineStore && <div style={{fontFamily:"robotomedium", fontSize:"15px", color:"#00BFA5", textAlign:"center", marginTop:"3px", padding:"0 25px", marginBottom:"15px"}}>
                    {languageConst.getWalletHeading} <span style={{fontSize:"20px", fontFamily:"robotobold" }}>{languageConst.rupees}</span> {languageConst.getWalletHeading2}
                  </div>  }               
                </div>
              )
            }
          </Dialog>
          {
            this.state.showThanksDialog &&
            <ThanksPopup open={true} />
          }
    </div>
    )
  }
})
