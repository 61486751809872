import React, { Component } from 'react'
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import './CeetLeaderBoard.css'
import { getCeetLeaderboard } from '../../redux/modules/dashboardReducers/ceetLeaderboard';
import LinearProgress from '@material-ui/core/LinearProgress';
import goldenCrown from '../../../src/images/crown.png';
import silverCrown from '../../../src/images/crownSilver.png';
import bronzeCrown from '../../../src/images/crownBronze.png';

const LOCAL_CONSTANTS = {
  internal: 'internal',
  external: 'external',
  starArray: [1,2,3,4,5]
};

export default connect(
  (store) => ({
    ceetLeaderboardBrandData: store.ceetLeaderboard.ceetLeaderboardBrandData,
    ceetLeaderboardBrandError: store.ceetLeaderboard.ceetLeaderboardBrandError,
    ceetLeaderboardBrandLoading:
      store.ceetLeaderboard.ceetLeaderboardBrandLoading,

    ceetLeaderboardCompetitorData:
      store.ceetLeaderboard.ceetLeaderboardCompetitorData,
    ceetLeaderboardCompetitorError:
      store.ceetLeaderboard.ceetLeaderboardCompetitorError,
    ceetLeaderboardCompetitorLoading:
      store.ceetLeaderboard.ceetLeaderboardCompetitorLoading,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCeetLeaderboard,
      },
      dispatch
    )
)(
  class CeetLeaderBoard extends Component {
    constructor(props) {
      super(props);
      this.state = {
        leaderboardType: 'internal',
        sortBrandType: 'top',
        sortCompetitorType: 'top',
        startDate: moment().subtract(45, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
    }

    componentDidMount = () => {
      this.props.getCeetLeaderboard(
        this.props.user.merchantId,
        this.props.user.accessToken,
        this.state.startDate,
        this.state.endDate,
        this.props.user.parentMerchantId,
        this.props.user.city,
        this.props.user.mega_city,
        this.props.user.categoryId,
        this.props.user.localityId,
        LOCAL_CONSTANTS.internal,
        this.props.user.lattitude,
        this.props.user.longitude
      );
      this.props.getCeetLeaderboard(
        this.props.user.merchantId,
        this.props.user.accessToken,
        this.state.startDate,
        this.state.endDate,
        this.props.user.parentMerchantId,
        this.props.user.city,
        this.props.user.mega_city,
        this.props.user.categoryId,
        this.props.user.localityId,
        LOCAL_CONSTANTS.external,
        this.props.user.lattitude,
        this.props.user.longitude
      );
    };


    render() {
      const threshold = 10;
      const {
        ceetLeaderboardBrandData,
        ceetLeaderboardBrandError,
        ceetLeaderboardBrandLoading,
        ceetLeaderboardCompetitorData,
        ceetLeaderboardCompetitorError,
        ceetLeaderboardCompetitorLoading,
      } = this.props;
      const { sortBrandType, sortCompetitorType } = this.state;
      const update = (type) => {
        const isBrand = type === LOCAL_CONSTANTS.internal;
        const data = isBrand
          ? ceetLeaderboardBrandData
          : ceetLeaderboardCompetitorData;
        const error = isBrand
          ? ceetLeaderboardBrandError
          : ceetLeaderboardCompetitorError;
        const sortType = isBrand ? sortBrandType : sortCompetitorType;
        if (error || (data && Array.isArray(data) && data.length <= 0)) {
          return [];
        }

        let ceetLeaderboardData = (data && [...data]) || [];

        if (sortType === 'bottom') {
          ceetLeaderboardData = ceetLeaderboardData && [
            ...ceetLeaderboardData.reverse(),
          ];
        } else {
          ceetLeaderboardData = (data && [...data]) || [];
        }

        let swapPrevIdx = -1;
        let swapCurrIdx = -1;

        ceetLeaderboardData =
          ceetLeaderboardData &&
          [...ceetLeaderboardData].map((curMerchant, curIndex) => {
            let rank =
            [...new Set([...ceetLeaderboardData].map(item => (item.topRatingCount || 0)))]
            .findIndex((preMerchant, preIndex) => {
                const pTopRatingCount = preMerchant || 0;
                const cTopRatingCount = curMerchant.topRatingCount || 0;
                return pTopRatingCount === cTopRatingCount;
              }) + 1;

            if (curMerchant.merchant_id === this.props.user.merchantId) {
              swapCurrIdx = curIndex;
              swapPrevIdx = [...ceetLeaderboardData].findIndex((preMerchant, preIndex) => {
                const pTopRatingCount = preMerchant.topRatingCount || 0;
                const cTopRatingCount = curMerchant.topRatingCount || 0;
                return pTopRatingCount === cTopRatingCount;
              }) ;
            }

            return { ...curMerchant, myRank: rank };
          });

        if (
          swapPrevIdx >= 0 &&
          swapCurrIdx >= 0 &&
          swapPrevIdx !== swapCurrIdx
        ) {
          let swapObj = ceetLeaderboardData[swapPrevIdx];
          ceetLeaderboardData[swapPrevIdx] = ceetLeaderboardData[swapCurrIdx];
          ceetLeaderboardData[swapCurrIdx] = swapObj;
        }

        let myRankingObj =
          ceetLeaderboardData &&
          ceetLeaderboardData.filter((merchant, index) => {
            if (
              merchant.merchant_id === this.props.user.merchantId &&
              index > threshold - 1
            ) {
              return true;
            }

            return false;
          })[0];

        if (ceetLeaderboardData && myRankingObj) {
          ceetLeaderboardData.splice(threshold - 1, 0, myRankingObj);
        } else {
          myRankingObj = undefined;
        }

        return ceetLeaderboardData;
      };

      const ceetbrandData = update(LOCAL_CONSTANTS.internal);
      const ceetCompetitorData = update(LOCAL_CONSTANTS.external);

      const addnStyle = this.props.isCeetPage
        ? {}
        : {
            margin: '2% 0 5% 0',
          };

          const isMobile = window.innerWidth < 768;

      return (
        <div className='ceet-leader-board-card-parent'>
          <div className='ceet-leader-board-card-box'>
            <div className='ceet-leader-board-block' style={addnStyle}>
              <div className='ceet-leader-board-header-block'>
                <div className='ceet-leader-board-heading'>
                  5 star rating leaderboard
                </div>
                <div 
                className='ceet-leader-board-card-chip'
                >
                  <span
                    className='ceet-leader-board-card-chip-content'
                  >
                    Vs Brand
                  </span>
                </div>

              </div>

              <div className='ceet-leader-board-toggle-block'>
                {ceetbrandData && ceetbrandData.length >= threshold && (
                  <div className='ceet-leader-board-toggle-sort' style={{borderRadius: isMobile ? '12px' : '20px'}}>
                    <span
                      className={`${
                        sortBrandType === 'top' &&
                        'ceet-leader-board-toggle-selected'
                      }`}
                      style={{ borderRadius: isMobile ? '10px' : '16px'}}
                      onClick={() => {
                        this.setState({ sortBrandType: 'top' });
                      }}
                    >
                      Top Rating
                    </span>
                    <span
                      className={`${
                        sortBrandType === 'bottom' &&
                        'ceet-leader-board-toggle-selected'
                      }`}
                      style={{ borderRadius: isMobile ? '10px' : '16px'}}
                      onClick={() => {
                        this.setState({ sortBrandType: 'bottom' });
                      }}
                    >
                      Bottom Rating
                    </span>
                  </div>
                )}
              </div>

              <div className='ceet-leader-board-body-block'>
                {ceetLeaderboardBrandLoading && (
                  <div>
                    <LinearProgress color='secondary' />
                  </div>
                )}

                {!ceetLeaderboardBrandLoading &&
                  ceetbrandData &&
                  ceetbrandData.slice(0, threshold).map((merchant, index) => {
                    const isMyMid =
                      merchant.merchant_id === this.props.user.merchantId;

                    return (
                      <div
                        className='ceet-leader-board-card'
                        style={{
                          backgroundColor: isMyMid && 'rgb(253, 240, 246)',
                          margin: '10px',
                          border: !isMyMid && '1px solid #E8E8E8',
                        }}
                      >
                        <div className='ceet-leader-board-card-rank' style={{backgroundColor: merchant.myRank === 1 || merchant.myRank === 2 || merchant.myRank === 3 ? 'transparent' : '#F8F8F8' }}>            
                          {merchant.myRank === 1 ? <img src={goldenCrown} alt="goldenCrown" /> : merchant.myRank === 2 ? <img src={silverCrown} alt="silver linen" /> : merchant.myRank === 3 ? <img src={bronzeCrown} alt="bronze crown" /> : merchant.myRank}
                        </div>
                        <div className='ceet-leader-board-card-details'>
                          <div className='ceet-leader-board-card-details-logo'>
                            <img
                              className='ceet-leader-board-card-details-logo-img'
                              src={
                                merchant.merchant_logo ||
                                this.props.user.profilePic
                              }
                            />
                          </div>
                          <div className='ceet-leader-board-card-details-info'>
                            <span className='ceet-leader-board-card-details-info-main'>
                              {merchant.merchant_name ||
                                this.props.user.merchantName ||
                                ''}
                            </span>
                            <span className='ceet-leader-board-card-details-info-sub'>
                              {merchant.locality || ''}
                            </span>
                          </div>
                        </div>
                        <div className='ceet-leader-board-card-count'>
                          {
                            LOCAL_CONSTANTS.starArray.map((item) => (
                              <i key={item} style={{color:'#4DA940', fontSize:'12px'}} className="star star2 fa fa-star"></i>
                            ))
                          }
                          <span>&nbsp;&nbsp;{merchant.topRatingCount || 0}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className='ceet-leader-board-card-box'>
            <div className='ceet-leader-board-block' style={addnStyle}>
              <div className='ceet-leader-board-header-block'>
                <div className='ceet-leader-board-heading'>
                  5 star rating leaderboard
                </div>
                <div className='ceet-leader-board-card-chip'>
                  <span
                    className='ceet-leader-board-card-chip-content'
                  >
                    Vs Competitor
                  </span>
                </div>

              </div>

              <div className='ceet-leader-board-toggle-block'>
                {ceetCompetitorData && ceetCompetitorData.length >= threshold && (
                  <div className='ceet-leader-board-toggle-sort' style={{borderRadius: isMobile ? '12px' : '20px'}}>
                    <span
                      className={`${
                        sortCompetitorType === 'top' &&
                        'ceet-leader-board-toggle-selected'
                      }`}
                      style={{ borderRadius: isMobile ? '10px' : '16px'}}
                      onClick={() => {
                        this.setState({ sortCompetitorType: 'top' });
                      }}
                    >
                      Top Rating
                    </span>
                    <span
                      className={`${
                        sortCompetitorType === 'bottom' &&
                        'ceet-leader-board-toggle-selected'
                      }`}
                      style={{ borderRadius: isMobile ? '10px' : '16px'}}
                      onClick={() => {
                        this.setState({ sortCompetitorType: 'bottom' });
                      }}
                    >
                      Bottom Rating
                    </span>
                  </div>
                )}
              </div>

              <div className='ceet-leader-board-body-block'>
                {ceetLeaderboardCompetitorLoading && (
                  <div>
                    <LinearProgress color='secondary' />
                  </div>
                )}

                {!ceetLeaderboardCompetitorLoading &&
                  ceetCompetitorData &&
                  ceetCompetitorData
                    .slice(0, threshold)
                    .map((merchant, index) => {
                      const isMyMid =
                        merchant.merchant_id === this.props.user.merchantId;

                      return (
                        <div
                          className='ceet-leader-board-card'
                          style={{
                            backgroundColor: isMyMid && 'rgb(253, 240, 246)',
                            margin: '10px',
                            border: !isMyMid && '1px solid #E8E8E8',
                          }}
                        >
                          <div className='ceet-leader-board-card-rank' style={{backgroundColor: merchant.myRank === 1 || merchant.myRank === 2 || merchant.myRank === 3 ? 'transparent' : '#F8F8F8' }}>
                            {merchant.myRank === 1 ? <img src={goldenCrown} alt="goldenCrown" /> : merchant.myRank === 2 ? <img src={silverCrown} alt="silver linen" /> : merchant.myRank === 3 ? <img src={bronzeCrown} alt="bronze crown" /> : merchant.myRank}
                          </div>
                          <div className='ceet-leader-board-card-details'>
                            <div className='ceet-leader-board-card-details-logo'>
                              <img
                                className='ceet-leader-board-card-details-logo-img'
                                src={
                                  merchant.merchant_logo ||
                                  this.props.user.profilePic
                                }
                              />
                            </div>
                            <div className='ceet-leader-board-card-details-info'>
                              <span className='ceet-leader-board-card-details-info-main'>
                                {merchant.merchant_name ||
                                  this.props.user.merchantName ||
                                  ''}
                              </span>
                              <span className='ceet-leader-board-card-details-info-sub'>
                                {merchant.locality || ''}
                              </span>
                            </div>
                          </div>
                          <div className='ceet-leader-board-card-count'>
                          {
                            LOCAL_CONSTANTS.starArray.map((item) => (
                              <i key={item} style={{color:'#4DA940', fontSize:'12px'}} className="star star2 fa fa-star"></i>
                            ))
                          }
                            <span>&nbsp;&nbsp;{merchant.topRatingCount || 0}</span>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
