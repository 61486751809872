import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getLocalityDetails} from '../../redux/modules/newMerchantLanding';
import background from '../../images/BG2.jpg';
import influenceGroup from '../../images/influence-grfx.svg'

import { sendEvent, compactCurrencyString, createMCPLog} from '../../helpers/Helpers'
import language from './languageConst.js';
import { countries, countriesInfo } from '../../constants';

class MagicpinInfluence extends Component {

  constructor() {
    super()
    this.state = {
      scriptLoaded: false,
      show: false
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }


  componentDidMount() {
    if (this.props.localityId)
      this.props.getLocalityDetails(this.props.localityId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.localityId && this.props.localityId != prevProps.localityId) {
      this.props.getLocalityDetails(this.props.localityId);
    }
  }

  render() {
     const languageConst = this.props.language ? language[this.props.language] : language["english"];
     const country = this.props.country || countries.INDIA;

    let customers = 0;
    let transactions = 0;
    let sales = 0;
    if(this.props.localityData){
      const fundedMerchants = this.props.localityData['fundedMerchants'] || 0;
      customers = 5 * this.props.localityData['fundedMerchants'];
      customers = this.props.localityData['customers'] ? Math.max(this.props.localityData['customers'] , customers): customers;
      transactions = fundedMerchants < 50000 ? Math.max(5 * fundedMerchants, this.props.localityData['txns']) : Math.max(3 * fundedMerchants, this.props.localityData['txns']);
      sales = Math.min(parseInt(this.props.localityData['sale']), 991*transactions);

      if(country.toUpperCase() == countries.INDONESIA){
        transactions = this.props.localityData['txns'] <=10 ? this.props.merchantId.substring(this.props.merchantId.toString().length - 2, this.props.merchantId.toString().length):this.props.localityData['txns'] ;
        sales = Math.min(parseInt(this.props.localityData['sale']), 551*transactions*200);
      }
    }
    
    return (
      <div>
        {(this.props.locality_details_loaded && window.innerWidth > 768) &&
          this.props.categoryId != 5 && this.props.categoryId != 91 ? 
            <section className="partners" style={{ backgroundColor: "white", backgroundSize:"contain", height:"390px"}}>
              <div style={{width: "50%", float:"left", display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "9%"}}>
                <h2 style={{paddingBottom:0}}>{languageConst.magicpin}</h2> 
                <h2 style={{paddingBottom:0}}>{languageConst.influence} {this.props.localityData.city || this.props.localityName || languageConst.yourLocality}</h2>
                {this.props.showClaimDialog &&
                  <a className="start-button button-header-hover" style={{marginTop:window.innerWidth > 768 ? "40px" : "35px"}} onClick={() => {
                  this.props.showClaimDialog();
                  window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
                  sendEvent({
                    utm_campaign: localStorage.getItem('landingSource') || '',
                    subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                    event: 'become_a_partner',
                    page_type: 'new_merchant_landing'
                  })
                  createMCPLog({
                    phone: localStorage.getItem("subjectPhoneNumber") || '',
                    utm_campaign: localStorage.getItem('landingSource') || '',
                    merchant_id: localStorage.getItem('landingMerchantId') || '',
                    event: 'become_a_partner'
                  });
                  }}>{languageConst.buttonCta2}</a>
                }
              </div>
              <div className="localityParent" style={{width:"50%", display:"inline", float:"left"}}>
                {
                  <div className="localityInfoCard" style={{right:"57%", top:"130px"}}>
                    <div className="infoGroup">
                      <div className="number-locality">{customers.toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                      <div className="title">{languageConst.customers}</div>
                    </div>
                  </div>
                }

                {this.props.localityData && this.props.localityData['fundedMerchants'] &&
                  <div className="localityInfoCard" style={{right:"18%"}}>
                    <div className="infoGroup">
                      <div className="number-locality">{this.props.localityData['fundedMerchants'].toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                      <div className="title">{languageConst.merchants}</div>
                    </div>
                  </div>
                }

                {this.props.localityData && this.props.localityData['sale'] &&
                  <div className="localityInfoCard" style={{right:"18%", top:"130px"}}>
                    <div className="infoGroup">
                      <div className="number-locality">{compactCurrencyString(sales, this.props.country.toLowerCase(), 0) }</div>
                      <div className="title">{languageConst.monthlySale}</div>
                    </div>
                  </div>
                }

                {this.props.localityData && this.props.localityData['txns'] &&
                  <div className="localityInfoCard" style={{right:"57%"}}>
                    <div className="infoGroup">
                      <div className="number-locality">{transactions.toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                      <div className="title">{languageConst.monthlyTxns}</div>
                    </div>
                  </div>
                }
              </div>
            </section> 
            :
            <section className="partners" style={{padding:"60px 30px",backgroundColor:"white",display: window.innerWidth < 768 ? "none":"flex",justifyContent:"center",alignItems:"center"}}>
              <div style={{width: "40%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <div style={{marginLeft:"2%",lineHeight:"48px", textAlign:"center",fontSize:"35px",fontFamily:"robotobold"}}>
                  <h2 style={{paddingBottom:0}}>{languageConst.magicpin + " " + languageConst.influence}</h2> 
                  <h2 style={{paddingBottom:0}}>{(this.props.localityData && this.props.localityData.city) || (this.props.localityData && this.props.localityName) || languageConst.yourLocality}</h2>
                </div>
                <div style={{marginTop:"10px"}}>
                  <button className="start-button button-header-hover" style={{border:"0px",marginTop: "20px"}} onClick={() => {
                         window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
                         sendEvent({
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner',
                           page_type: 'new_merchant_landing'
                         })
                         createMCPLog({
                           phone: localStorage.getItem("subjectPhoneNumber") || '',
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner'
                         });
                        this.props.showClaimDialog();
                      }}> {languageConst.groceryClaim}
                  </button>
                </div>
              </div>
              <div style={{width: "50%", position:"relative"}}>
                  <img style={{width: "85%"}} src={influenceGroup}></img>
                  <div style={{position:"absolute", bottom:"13.5%", right:"18.5%"}}>
                      <div style={{fontFamily:"robotobold",color:"#2e108e",fontSize:"20px"}}>
                        {this.props.localityData && this.props.localityData['txns'] ? transactions.toLocaleString(countriesInfo[country.toUpperCase()].locale) : ""}
                      </div>
                      <div style={{fontSize:"14px"}}>
                        {languageConst.monthlyTxns}
                      </div>
                  </div>
                  <div style={{position:"absolute", bottom:"20%", left:"17%"}}>
                      <div style={{fontFamily:"robotobold",color:"#2e108e",fontSize:"20px"}}>
                        {this.props.localityData && this.props.localityData['sale'] ? compactCurrencyString(sales, this.props.country.toLowerCase(), 0): ""}
                      </div>
                      <div style={{fontSize:"14px"}}>
                        {languageConst.monthlySale}
                      </div>
                  </div>
                  <div style={{position:"absolute", top:"36%", right:"16%"}}>
                      <div style={{fontFamily:"robotobold",color:"#2e108e",fontSize:"20px"}}>
                        {this.props.localityData && this.props.localityData['fundedMerchants'] ? this.props.localityData['fundedMerchants'].toLocaleString(countriesInfo[country.toUpperCase()].locale) : ""}
                      </div>
                      <div style={{fontSize:"14px"}}>
                        {languageConst.merchants}
                      </div>
                  </div>
                  <div style={{position:"absolute", top:"13.5%", left:"26%"}}>
                      <div style={{fontFamily:"robotobold",color:"#2e108e",fontSize:"20px"}}>
                        {customers.toLocaleString(countriesInfo[country.toUpperCase()].locale)}
                      </div>
                      <div style={{fontSize:"14px"}}>
                        {languageConst.customers}
                      </div>
                  </div>
              </div>
            </section>
        }
        {(this.props.locality_details_loaded && window.innerWidth < 768) &&
          <section className="partners" style={{ backgroundColor: "white"}}>
            <div style={{width: "90%", float:"left", display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "9%",fontWeight:"bold",fontFamily:"robotobold"}}>
            <h2 style={{lineHeight:"1.30",fontWeight:"bold",fontFamily:"robotobold"}}>{languageConst.magicpin} {languageConst.influence} {this.props.localityData.city || this.props.localityName || languageConst.yourLocality}</h2>
            </div>
            <div className="facts">
            {
                <div className="localityInfoCard" style={{position:"inherit", height:'68px', width:'160px', justifyContent:'center', border:"1px solid #0000000f", borderRadius:"5px 0 0 0", boxShadow:'0 2px 10px 0 #d4cbcbaa'}}>
                  <div className="infoGroup" style={{padding: "0px 10px", margin:"14px 0"}}>
                    <div className="number-locality" style={{fontSize:"24px"}}>{customers.toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                    <div className="title">{languageConst.customers}</div>
                  </div>
                </div>
              }

              {this.props.localityData && this.props.localityData['fundedMerchants'] &&
                <div className="localityInfoCard" style={{position:"inherit", height:'68px', width:'160px', justifyContent:'center', border:"1px solid #0000000f", borderRadius:"0 5px 0 0", boxShadow:'0 2px 10px 0 #d4cbcbaa'}}>
                  <div className="infoGroup" style={{padding: "0px 10px", margin:"14px 0"}}>
                    <div className="number-locality" style={{fontSize:"24px"}}>{this.props.localityData['fundedMerchants'].toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                    <div className="title">{languageConst.merchants}</div>
                  </div>
                </div>
              }

              {this.props.localityData && this.props.localityData['sale'] &&
                <div className="localityInfoCard" style={{position:"inherit", height:'68px', width:'160px', justifyContent:'center', border:"1px solid #0000000f", borderRadius:"0 0 0 5px", boxShadow:'0 2px 10px 0 #d4cbcbaa'}}>
                  <div className="infoGroup" style={{padding: "0px 10px", margin:"14px 0"}}>
                    <div className="number-locality" style={{fontSize:"24px"}}>{compactCurrencyString(sales, this.props.country.toLowerCase(), 0)}</div>
                    <div className="title">{languageConst.monthlySale}</div>
                  </div>
                </div>
              }

              {this.props.localityData && this.props.localityData['txns'] &&
                <div className="localityInfoCard" style={{position:"inherit", height:'68px', width:'160px', justifyContent:'center', border:"1px solid #0000000f", borderRadius:"0 0 5px 0", boxShadow:'0 2px 10px 0 #d4cbcbaa'}}>
                  <div className="infoGroup" style={{padding: "0px 10px", margin:"14px 0"}}>
                    <div className="number-locality" style={{fontSize:"24px"}}>{transactions.toLocaleString(countriesInfo[country.toUpperCase()].locale)}</div>
                    <div className="title">{languageConst.monthlyTxns}</div>
                  </div>
                </div>
              }
            </div>
            {this.props.showClaimDialog &&
          <a className="start-button button-header-hover" style={{marginTop:window.innerWidth > 768 ? "40px" : "35px"}} onClick={() => {
            this.props.showClaimDialog();
            window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
            sendEvent({
              utm_campaign: localStorage.getItem('landingSource') || '',
              subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
              event: 'become_a_partner',
              page_type: 'new_merchant_landing'
            })
            createMCPLog({
              phone: localStorage.getItem("subjectPhoneNumber") || '',
              utm_campaign: localStorage.getItem('landingSource') || '',
              merchant_id: localStorage.getItem('landingMerchantId') || '',
              event: 'become_a_partner'
            });
          }}>{languageConst.buttonCta2}</a>
        }
          </section>
        }
      </div>
    )
  }
}

export default connect(state => ({
  localityData: state.newMerchantLanding.locality_details,
  leaderboard: state.newMerchantLanding.leaderboard,
  locality_details_loading: state.newMerchantLanding.locality_details_loading,
  locality_details_loaded: state.newMerchantLanding.locality_details_loaded,
}), {
    getLocalityDetails: getLocalityDetails
  })(MagicpinInfluence)