import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchBusiness from '../Claim/SearchBusiness';
import './upgradations.css'
import '../Claim/Claim.css'
import { compactCurrencyString } from '../../helpers/Helpers'
import { get, MERCHANT_ID } from '../../helpers/LocalStorageHelper'
import { getUpgradePackage } from '../../redux/modules/upgradePackage'
import upgradeBannerPic from '../../images/banner-texture.svg';
import defaultProfilePic from '../../images/profile-default.png';
import magicAssuredLogo from '../../images/magic-assured.svg'

class upsellHeader extends Component {

  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    this.props.getUpgradePackage({ merchantId: get(MERCHANT_ID) }).catch((err) => {
      this.props.upsellFallback()
    })
  }

  changePage = () => {
    this.props.getUpgradePackage({ merchantId: get(MERCHANT_ID) }).catch((err) => {
      this.props.upsellFallback()
    })
    this.props.changePage()
  }

  render() {
    if (!this.props.upgradePackageData)
      return null

    let merchantInfo = this.props.upgradePackageData.merchantInfo
    let profilePic = merchantInfo.logo
    let merchantName = merchantInfo.name
    let packageName = this.props.upgradePackageData.suitablePackage.name;
    packageName = packageName.charAt(0).toUpperCase() + packageName.slice(1);
    let upgradePackageAmount = this.props.upgradePackageData.suitablePackage.roi_amount;
    let returnOnInvestment = compactCurrencyString(upgradePackageAmount);
    let amount = this.props.upgradePackageData.suitablePackage.amount
    let amountInLakh = compactCurrencyString(amount)
    return (
      <div>
        <div className="banner-texture" style={{ backgroundImage: `url(${upgradeBannerPic})` }}>

          {/* <div className="upgrade-logo"><img src={magicpinBusinessPic} /></div> */}

          <div className="upsell-merchant-logo">
            <img className="merchant-top-logo" src={profilePic || defaultProfilePic} onError={e => e.target.src = defaultProfilePic} />
          </div>

          <div className="header-upsell">
            <h1 className="welcome"><span className="welcome-light">Welcome,</span><span>{merchantName}</span></h1>
            <p className="-lakh-worth-of-gura"><span className="text-style-1">{returnOnInvestment}
              </span> worth of <span className=""> guaranteed</span> sale for just <span className="text-style-2">{amountInLakh}</span>
            </p>
          </div>

          <div className="Rectangle-48"></div>
          {this.props.page.localeCompare("search") == 0 &&
            <SearchBusiness
              showAddMerchantDialog={this.props.showAddMerchantDialog}
              changePage={this.changePage}
              push={this.props.push}
            />
          }

          {this.props.page.localeCompare("claim") == 0 &&
            <div>
              <section className="claim-holder">
                <button className="try-button button-header-hover" onClick={this.props.showClaimDialog}>CLAIM YOUR BUSINESS </button>
              </section>
              <div style={{ cursor: "pointer" }} onClick={this.props.showSearch}>
                <h3 className="not-business">Not your business?</h3>
                <span className="click-me">Click here</span>
              </div>
            </div>
          }

          <div className="upgrade-magic-assured">
            <img className="upgrade-magic-assured-img" src={magicAssuredLogo} />
            <p className="One-of-its-kind-assu">One of its kind assurance in India for guaranteed business.</p>
          </div>
          <div className="mouse-scroll-img">
            <div className="scroll-downs">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  upgradePackageData: store.upgradePackage.upgradePackageData,
}), {
    getUpgradePackage: getUpgradePackage,
  })(upsellHeader)