const LOAD = 'ratingsDistribution/LOAD';
const LOAD_SUCCESS = 'ratingsDistribution/LOAD_SUCCESS';
const LOAD_FAIL = 'ratingsDistribution/LOAD_FAIL';

const initialState = {
  loaded: false,
  combinedRatings: [],
  ratingOld: 0,
};

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case LOAD_SUCCESS:
    if(action.firstCall)
      return {
        ...state,
        loading: false,
        loaded: true,
        combinedRatings: action.result,
        ratingOld: action.result.length > 0 ? action.result[0][action.result[0].length -1].rating : 0,
      };
    else {
      return {
        ...state,
        loading: false,
        loaded: true,
        combinedRatings: action.result,
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        combinedRatings: [],
        error: action.error
      };
   default:
      return state;
  }
}

export function getRatingsMerchants(accessToken, merchantIds, startDate, endDate, firstCall = false) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getRatingsMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    firstCall,
  };
}
