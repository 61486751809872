import React, {Component, PropTypes} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../../redux/modules/authReducers/auth';
import { toast } from 'react-toastify';
import logoBusiness from '../../images/logo-business.svg';
import { loadScript, currencyString } from '../../helpers/Helpers';
import './header.css';
import SelectLanguage from '../Claim/SelectLanguage';
import { countryCodes, userTypes, otpCustomizedMsg } from '../../constants';

export default connect(store => ({
  loggingIn: store.auth.loggingIn,
  loginError: store.auth.loginError,
}), dispatch => bindActionCreators({
  login,
}, dispatch))

(class HeaderLogin extends Component{

  constructor(props){
    super(props);
    this.state = {
      countryCode:countryCodes[0].prefix
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let username = this.refs.username.value;
    let password = this.refs.password.value;
    if(username && password){
      this.props.login({ username, password });
    }
    username = '';
    password = '';
  }

  componentWillReceiveProps(nextProps){
    if(this.props.loggingIn && nextProps.loginError){
      if(nextProps.loginError.message) toast(nextProps.loginError.message);
      else toast('Failed to log in. Please try again');
    }
  }

  componentDidMount(){
    loadScript('//translate.google.com/translate_a/element.js?cb=window.googleTranslateElementInit',  null)
  }

  render(){
    return(
      <div>
      <div ref="topHeaderBar" className="top-header-bar top-login-header" style={{display:'block', padding: '0px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)', position:"fixed", zIndex:"1000", width:"100vw", top:"0", height:'unset'}}>
        <div className="container-fluid" style={{ padding: '0px' }}>
          <div className="row" style={{ display: 'flex', padding: '0 15px' }}>
            <div className="col-sm-4 col-xs-8" style={{ margin: 'auto' }}>
              <div className="logo login-logo logo-wrapper">
                  <img src={logoBusiness} alt="magicpin" />
              </div>
            </div>
            <div className="col-sm-8 col-xs-4" style={{padding:"0px"}}>
              <div className="login-header-rgt" style={{ display: 'flex' }}>
                <div style={{padding:'0'}} className="header-login-frm-wrapper col-sm-10 col-md-10ncm">
                  <form role="form" onSubmit={this.handleSubmit}>
                    <div className="row" style={{marginLeft:'-4px', marginRight:'-4px'}}>
                      <div className="col-sm-4" style={{paddingLeft:'4px', paddingRight:'4px', width: 'auto'}}>
                      <select className="form-control country-code" style={{ backgroundColor:'#eceef4'}} onChange={e=>this.setState({countryCode:e.target.value})}>
                          {countryCodes.map((phonePrefix, idx)=><option key={idx} value={phonePrefix.prefix}>
                          {phonePrefix.abbr + ' +'+phonePrefix.prefix}
                            </option>)}
                        </select>
                
                      </div>
                      

                      <div className="col-sm-4" style={{paddingLeft:'4px', paddingRight:'4px'}}>
                        <input type="tel" className="form-control phone-number" placeholder="Enter 10 digit mobile number" style={{ backgroundColor:'#eceef4'}}
                          onChange={ e => this.setState({ phone: e.target.value })} />
                        {/* <a className="hdr-forgot-password" style= {{ color: 'rgba(0, 0, 0, 0.54)'}}onClick={() => this.props.push('/forgotpassword')}>Forgot password?</a> */}
                      </div>
                      <div className="col-sm-4" style={{paddingLeft:'4px', paddingRight:'4px', width:'50%', marginTop:'-1px'}}>
                        <input type="submit" style={{background: "#ef1c74", borderRadius: '4px',height:'35px'}} className="btn-o button-header-hover" value="Login via OTP" onClick={() => this.props.push('/login?otp=true', {otpLoginCheck:true , phoneNo:this.state.phone, countryCode: this.state.countryCode})}/>
                        {!this.props.loginViaOtp && <div  style={{color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'robotomedium'}} className="signup-login-otp">or <a style={{ color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'robotomedium', fontSize:'15px' }} onClick={() => this.props.push( '/login?otp=true', {otpLoginCheck:false})}>Login with credentials</a></div>}
                        <a className="hdr-forgot-password" style= {{ color: 'rgba(0, 0, 0, 0.54)'}}onClick={() => this.props.push('/forgotpassword')}>Forgot password?</a>

                      </div>
                    </div>
                  </form>
                </div>
                  {/* <div style={{float:'right'}} id="google_translate_element"></div> */}
                  <div style={{float:'right', marginTop:"4px"}}>
                    
                    {!this.props.selectLanguage && <SelectLanguage country={this.props.country}/>}
                  </div>
                <p style={{textAlign:"right", display: window.innerWidth < 768 ? "inline-block":"none"}} id="MobileLgnHdrBtn"><a className="btn-o" onClick={() => this.props.push('/login',{otpLoginCheck:true})}>Login</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
})
