import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import maxTriesIcon from '../../images/home-delivery-otp-max-tries-exceeded.svg';
import IconButton from '@material-ui/core/IconButton';
import msgIcon from './../../images/otpMessageIcon.svg';

import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';

const styles = theme => ({
    root: {
        margin: 0,
        padding: "10px",
        width: window.innerWidth > 768 ? "400px" : "3000px"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "15px",
        color: "gray",
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <img src={closeBtn}/>
            </IconButton>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: "16px",
        textAlign: "center"
    },
}))(MuiDialogContent);


export default connect(
    store => ({
        verifyOtpResponseData: store.verifyOtpMagicStore.verifyOtpResponseData,
        verifyOtpError: store.merchantList.verifyOtpError,
        verifyingOtp: store.verifyOtpMagicStore.verifyingOtp
    }),dispatch=>bindActionCreators({
    }, dispatch))
(class OtpVerificationMagicStore extends Component {

    state = {
        backSpace: false,
        otpEntered: false,
        otp: "",
        otpVerified: false,
        otpVerificationFailed: false,
        otpVerificationFailureMessage: "",
        otpRetry: 3,
        tryAgain: false
    }

    retry = () => {
        this.hideTryAgain()
        this.setState({
            otpRetry: this.state.otpRetry - 1
        })
        this.props.tryAgain()
    }

    showTryAgain = () => {
        this.setState({
            tryAgain: true
        })
    }

    hideTryAgain = () => {
        this.setState({
            tryAgain: false
        })
        setTimeout(this.showTryAgain, 30000)
    }

    verify = () => {
        this.props.verifyOtp(this.state.otp, this.props.number)
    }

    componentDidMount() {
        this.hideTryAgain()
    }

    componentDidUpdate(prevProps) {
        if((prevProps.otpVerified !== this.props.otpVerified )) {
            if(this.props.otpVerified) {
                this.setState({ otpVerified: true, otpVerificationFailed: false, otpVerificationFailureMessage: "", otpRetry: this.state.otpRetry - 1 })
            } else {
                this.setState({ otpVerified: false, otpVerificationFailed: true, otpVerificationFailureMessage: this.props.verifyOtpError, otpRetry: this.state.otpRetry - 1 })
            }

            if (this.state.otpVerified) {
                this.props.openOnboardingPage()
            }
        }

        if(prevProps.verifyOtpError !== this.props.verifyOtpError && this.props.verifyOtpError) toast.error(this.props.verifyOtpError.message || "Please try again later");
    }

    captureOtp = (e, idx) => {
        if(e.target.value){
            idx <= 4 && document.querySelectorAll("#otp-1")[idx+1].focus()

            // if(idx+1 === 6) {
            let field1 = document.querySelectorAll("#otp-1")[0].value
            let field2 = document.querySelectorAll("#otp-1")[1].value
            let field3 = document.querySelectorAll("#otp-1")[2].value
            let field4 = document.querySelectorAll("#otp-1")[3].value
            let field5 = document.querySelectorAll("#otp-1")[4].value
            let field6 = document.querySelectorAll("#otp-1")[5].value
            if(field1 != "" && field2 != "" && field3 != "" && field4 != "" && field5 != "" && field6 != "" && field6.length == 1) {
                let otpByMerchant;
                // otpByMerchant = document.querySelectorAll("#otp-1")[0].value + document.querySelectorAll("#otp-1")[1].value + document.querySelectorAll("#otp-1")[2].value + document.querySelectorAll("#otp-1")[3].value
                otpByMerchant = field1 + field2 + field3 + field4 + field5 + field6
                if(otpByMerchant.length == 6) {
                    this.setState({otpEntered:true, otp: otpByMerchant})
                }
            }
        }
        else{
            if(idx >= 1 ){
                if(this.state.backSpace){
                    this.setState({backSpace:false});
                    document.querySelectorAll("#otp-1")[idx-1].focus()
                }
                else{
                    this.setState({backSpace:true})
                }
            }
            this.setState({otpEntered: false})
        }
        e.preventDefault();
    }

    render () {
        if(this.state.otpVerified && !this.state.otpVerificationFailed) {
            toast.success("Otp verified successfully!");
            this.props.closePopUp();
        }
        let retryCountCheck = (this.props.retryCount == 0 || this.state.otpRetry == 0)

        return (
            <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
                <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
                    {/* Improve rank
            <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
                </DialogTitle>

                <DialogContent dividers>
                    <div style={{ display: 'flex', fontSize: '20px', marginTop: '-16px', fontFamily: 'robotobold', justifyContent: 'flex-start'}}>
                        <div>OTP Verification</div>
                    </div>

                    <div style={{marginTop: '5%'}}>
                        <img src = {msgIcon} style={{width: '68px', height: '68px'}} />
                    </div>

                    <div style={{ fontSize: '16px', marginTop: '5%', fontFamily: 'robotobold', marginLeft: '16px'}}>
                        <div>We sent you a 6-digit code</div>
                    </div>

                    <div style={{marginTop: '8px',color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'Roboto-Regular', fontSize: '12px'}}>
                        Please enter the OTP sent to {this.props.number}
                    </div>
                    {this.state.tryAgain &&
                        <div style={{marginTop: '8px',color: 'rgba(0, 0, 0, 0.87)', fontFamily: 'Roboto-Regular', fontSize: '12px'}}>
                            Didn’t received it yet?
                            <span onClick= {() => this.retry()} style={{ display: 'inline', paddingLeft: '4px', marginTop: '8px', color: '#ef1c74', fontFamily: 'Roboto-Regular', fontSize: '12px', cursor: 'pointer'}}>
                                Try Again
                            </span>
                        </div>
                    }
                    {!this.state.otpVerified && !this.state.otpVerificationFailed && this.props.retryCount > 0 && this.state.otpRetry > 0
                    && <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%', marginBottom: '5%'}}>
                        <div className="inputs">
                            {Array.from({length: 6}).map((ele, idx)=>
                                <input className="otp-input-box" id="otp-1" key={idx} required pattern="[0-9]" onKeyUp={(e)=>this.captureOtp(e, idx)} type="number" maxLength="1" size="1" style={{caretColor: '#ef1c74'}}/>
                            )}
                        </div>
                    </div>}

                    {!this.state.otpVerified && this.state.otpVerificationFailed && this.props.retryCount > 0 && this.state.otpRetry > 0
                    &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%'}}>
                            <div className="inputs">
                                {Array.from({length: 6}).map((ele, idx)=>
                                    <input style={{border: '1px solid #ff455f'}} className="otp-input-box" id="otp-1" key={idx} required pattern="[0-9]" onKeyUp={(e)=>this.captureOtp(e, idx)} type="number" maxLength="1" size="1"/>
                                )}
                            </div>
                        </div>
                        <div>{this.state.otpVerificationFailureMessage}</div>
                    </div>
                    }
                    {retryCountCheck && <img style={{paddingBottom: '8px'}} src={maxTriesIcon}></img>}

                    {!this.state.otpEntered && this.props.retryCount > 0 && this.state.otpRetry > 0 && <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                        <a style={{color: 'rgba(0, 0, 0, 0.2)', backgroundColor: '#f7f7fa'}} className="otp-verify-btn">
                            Verify
                        </a>
                    </div>
                    }
                    {this.state.otpEntered && this.props.retryCount > 0 && this.state.otpRetry > 0 &&
                    <div className="otp-verify-btn" onClick={() => this.verify()}
                         style={this.props.verifyingOtp ? {alpha: '0.2', backgroundColor: "#bbb", display:"flex", width:"80%", marginLeft:"10%", marginRight:"10%"}: {width:"80%", marginLeft:"10%", marginRight:"10%"}} >
                        {/*<a style={{color: 'white'}} className="otp-verify-btn" onClick={() => this.verify()}>*/}
                        {/*    Verify*/}
                        {/*</a>*/}
                        <div style={{width: "100%", textAlign: "center"}}>
                            {this.props.verifyingOtp ? "Verifying..." : "Verify"}
                        </div>

                        {this.props.verifyingOtp ?
                            <div style={{width: "20%", position: "relative"}}>
                                <span className="loading"/>
                            </div>
                            : ""
                        }
                    </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
})
