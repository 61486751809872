import React from 'react'
import CustomDialog from '../CustomComponents/CustomDialog'
import info from '../../images/info-icon.svg';
import thumbsUp from '../../images/on-fast-track-.svg';
import { toast } from 'react-toastify';
import "./SetUpi.css"

export const SetUpi = (props) => {

    const {setUpi, setUpiFN, feedShortcutsStatusData, getFeedShortcutsStatus, user, setUpiId, registeringUpi} = props
    const haveUpiId = feedShortcutsStatusData && feedShortcutsStatusData.haveUpiId || false

    React.useEffect(() => {
        if(!feedShortcutsStatusData && user && user.accessToken)
            getFeedShortcutsStatus(user.accessToken)
      }, [])

    const submit = (event)=>{
        event.preventDefault();
        const upiForm = document.forms.upiForm;
        const upiFormData = new FormData(upiForm)
        const upiId = upiFormData.get('upiId');
        const confirmUpiId = upiFormData.get('confirm-upiId');
    
    
        if(upiId !== confirmUpiId)
        {
            toast.error("UPI ID does not match, please re-enter")
        }
        else if (!(upiId && confirmUpiId)) {
            toast.error("Please submit a valid response.")
        }
        else if(upiId === confirmUpiId && user && user.accessToken)
        {
            setUpiId(user.accessToken, upiId)
            .then((res) => {
                getFeedShortcutsStatus(user.accessToken)
                toast.success("UPI ID successfully registred")
                setUpiFN()
            })
            .catch((err) => {
                toast.error("Something went wrong, please try again after some time")
                setUpiFN()
            })
        }
        else
        {
            toast.error("Please refresh and try again")
        }
    }
    
    const customHeading = () =>{
    
        let headingDiv = 
              <div className = "custom-dialog-heading">
                  <p className = "custom-dialog-heading-name">Please share your UPI ID</p>
              </div>
      
        return headingDiv
      }
      
    const haveUpiIdContent = () =>{
        return(
        <div className = "already-have-content-body">
            <img src = {thumbsUp} alt = "100%" style = {{marginRight: "2%", alignSelf: "center", height: "60%"}}/>
            <p>UPI ID already registred with us</p>
        </div>)
    }
    
    const customContent = () =>{

        const cta = registeringUpi ? "SUBMITING ..." : "SUBMIT"
    
        if(haveUpiId){
            return haveUpiIdContent()
        }
        else
        return(
        <div className = "content-body">
            <div className = "content-body-info-bar">
                <img src = {info} alt = "100%" style = {{marginRight: "2%", alignSelf: "center"}}/>
                <p>21,444 merchants have already shared their store's UPI with us!</p>
            </div>
    
            <form id="upiForm" action="" autoComplete="off" onSubmit={(event)=>submit(event)}>
                <fieldset>
                <input id='upiId-textfield' name="upiId" className="form-control" pattern="^[\w.-]+@[\w.-]+$" placeholder="Enter UPI ID" 
                type="text" style={{marginTop: '10px', marginBottom: '20px'}} required/>
                <input id='confirm-upiId-textfield' name="confirm-upiId" className="form-control" pattern="^[\w.-]+@[\w.-]+$" placeholder="Re-enter UPI ID" 
                type="text" style={{marginTop: '20px', marginBottom: '20px'}} onPaste={(e)=> {e.preventDefault(); return false;}} required/>
    
                <div className = "action-body">
                    <input type="submit" value={cta} className="custom-submit-btn" disabled = {registeringUpi || false}/>
                </div>
                </fieldset>
            </form> 
    
        </div>
        )
    }
      
    // const customActions = () => {
    //     return(
    //     <div className = "action-body">
    //         <button>Submit</button>
    //     </div>
    //     )
    // }

    return (
        <div>
            <CustomDialog
                open = {setUpi || false}
                close = {setUpiFN}
                heading = {haveUpiId ? null : () => customHeading()}
                content = {() => customContent()}
            />
        </div>
    )
}

export default SetUpi;

