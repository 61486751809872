import React, {Component} from 'react';
import { Dialog } from '@material-ui/core';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createHomeDeliveryVouchers } from '../../redux/modules/dealReducers/createVouchers';
import {sendEvent} from '../../helpers/Helpers';
import './AddHomeDeliveryVouchers.css'

const ga=window.ga;
export default connect(store => ({
    createHomeDeliveryLoading : store.createVoucher.createHomeDeliveryLoading,
    createHomeDeliveryLoaded : store.createVoucher.createHomeDeliveryLoaded,
    createHomeDeliveryData: store.createVoucher.createHomeDeliveryData,
    createHomeDeliveryError: store.createVoucher.createHomeDeliveryError

}), dispatch => bindActionCreators({
  createHomeDeliveryVouchers,
}, dispatch))
(class AddHomeDeliveryVouchers extends Component{
  constructor(props){
    super(props);
    this.state = { 
      NYVoucherCost: null,
      NYVoucherText:null,
      discountPercentage: null,
      toMerchant:0,
      selectedFile:null,
      voucherFeedBack:"",
    };
  }

  componentDidMount(){
    ga('send', 'event', 'grocery_and_pharma_voucher', 'clicked', `merchant_id_${this.props.user.merchantId}`);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.createHomeDeliveryLoading && this.props.createHomeDeliveryData){
      toast.success("Your request is submitted successfully")
      this.props.onHide();
    }
    if(prevProps.createHomeDeliveryLoading && this.props.createHomeDeliveryError){
      toast.error("Error occurred while creating home delivery vouchers")
      this.props.onHide();
    }
  }

  onHide=()=>{
    document.forms.comboVoucherForm.reset();
    this.props.onHide();
  }

  convertToTwelveHourFormat=(time)=>{
      let arr=time.split(":")
      let hours=arr[0];
      let minutes=arr[1];
      let AmOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = (hours % 12) || 12;
      return hours+":"+minutes+" "+AmOrPm
  }

  handleSubmit = (e)=>{
    e.preventDefault();
    let comboForm = document.forms.comboVoucherForm;
    let comboFormData = new FormData(comboForm);
    let minBillValue = comboFormData.get('min-bill-value');
    let deliveryTimingsStart = comboFormData.get('delivery-timings-start');
    let deliveryTimingsEnd = comboFormData.get('delivery-timings-end');
    let deliveryRadius = comboFormData.get('delivery-radius');
    const contactNumber = comboFormData.get('contact-number');

    let requestBody = {}
    
      requestBody['minBillValue'] = parseFloat(minBillValue);
      requestBody['deliveryTimingsStart'] = this.convertToTwelveHourFormat(deliveryTimingsStart);
      requestBody['deliveryTimingsEnd'] = this.convertToTwelveHourFormat(deliveryTimingsEnd)
      requestBody['deliveryRadius'] = parseFloat(deliveryRadius);
      requestBody['contactNumber'] = contactNumber;
      this.props.createHomeDeliveryVouchers(this.props.user.accessToken, this.props.user.merchantName, requestBody);

      ga('send', 'event', 'grocery-pharma-voucher', 'requested', `merchant_id_${this.props.user.merchantId}`);
      sendEvent({
        event: "grocery-pharma-voucher-create",
        page_type: 'Feed',
        page_name: 'merchant_portal',
        actor_id: this.props.user.merchantId,
        subject_merchant_id: this.props.user.merchantId,
        payload:JSON.stringify(requestBody)
    });    
  }

  render(){
    return(
      <div>
        <Dialog fullScreen={window.innerWidth <= 486}  onClose={()=>{this.onHide()}} open={true}>
            <div>
                <div className="add-home-body-header">
                    <div className="add-home-body-header-text">
                       <div style={{paddingLeft: '13px'}}>Launch Home Delivery vouchers</div>
                    </div>
                    <div>
                        <i class="fa fa-times-thin fa-2x" aria-hidden="true" onClick={()=>{this.onHide()}} style={{ fontSize:'30px',cursor: 'pointer' }}></i>
                    </div>
                </div>  
                <div className="add-home-form-div">
                    <form id="comboVoucherForm" action="" onSubmit={this.handleSubmit}>
                        <div className="add-home-form-grid">
                            <div>
                              <div className="add-home-info-text">Minimum order value</div>
                              <div style={{paddingTop: '1%', paddingBottom: '2%'}}>
                                  <input name='min-bill-value' type="number" placeholder={`E.g. ₹300`} required={true} min="1" className="add-home-input-text-div">
                                  </input>
                              </div>
                            </div>
                            <div>
                              <div className="add-home-info-text">Delivery timings</div>
                              <div style={{paddingTop: '1%', paddingBottom: '2%', display:'flex', alignItems:'center'}}>
                                  <input name='delivery-timings-start' type="time" defaultValue={`E.g. 1:40 PM`} required={true} className="add-home-input-text-div input-width">
                                  </input>
                                  <div style={{fontSize:'14px', color:'rgba(0,0,0,0.87)', padding:'0px 8px'}}>to</div>
                                  <input name='delivery-timings-end' type="time" defaultValue={`E.g. 12:40 AM`} required={true} className="add-home-input-text-div input-width">
                                  </input>
                              </div>
                            </div>
                            <div>
                              <div className="add-home-info-text">Delivery radius</div>
                              <div style={{paddingTop: '1%', paddingBottom: '2%'}}>
                                  <input name='delivery-radius' type="number" placeholder={`E.g. 5 Kms`} required={true} min="1" className="add-home-input-text-div">
                                  </input>
                              </div>
                            </div>
                            <div>
                              <div className="add-home-info-text">Contact number</div>
                              <div style={{paddingTop: '1%', paddingBottom: '2%'}}>
                                  <input name='contact-number' type="text" minLength='10' placeholder={`E.g. 9887655432`} required={true} className="add-home-input-text-div">
                                  </input>
                              </div>
                            </div>
                        </div>
                        <input disabled={this.props.createHomeDeliveryLoading} type="submit" value={!this.props.createHomeDeliveryLoading ? "submit" : "submitting"} className="pointer large-btn large-btn2 add-home-submit-btn"></input>
                    </form>
              </div>
            </div>
        </Dialog>
      </div>
    );
  }
})
