const LOAD_BRAND = "ceetLeaderboard/LOAD_BRAND";
const LOAD_BRAND_SUCCESS = "ceetLeaderboard/LOAD_BRAND_SUCCESS";
const LOAD_BRAND_FAIL = "ceetLeaderboard/LOAD_BRAND_FAIL";
const LOAD_COMPETITOR = "ceetLeaderboard/LOAD_COMPETITOR";
const LOAD_COMPETITOR_SUCCESS = "ceetLeaderboard/LOAD_COMPETITOR_SUCCESS";
const LOAD_COMPETITOR_FAIL = "ceetLeaderboard/LOAD_COMPETITOR_FAIL";

const initialState = {
  ceetLeaderboardBrandLoaded: false,
  ceetLeaderboardCompetitorLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_BRAND:
      return { ...state, ceetLeaderboardBrandLoading: true };

    case LOAD_BRAND_SUCCESS:
      return {
        ...state,
        ceetLeaderboardBrandLoading: false,
        ceetLeaderboardBrandLoaded: true,
        ceetLeaderboardBrandError: null,
        ceetLeaderboardBrandData: action.result,
      };

    case LOAD_BRAND_FAIL:
      return {
        ...state,
        ceetLeaderboardBrandLoading: false,
        ceetLeaderboardBrandLoaded: false,
        ceetLeaderboardBrandData: null,
        ceetLeaderboardBrandError: action.error,
      };

    case LOAD_COMPETITOR:
      return { ...state, ceetLeaderboardCompetitorLoading: true };

    case LOAD_COMPETITOR_SUCCESS:
      return {
        ...state,
        ceetLeaderboardCompetitorLoading: false,
        ceetLeaderboardCompetitorLoaded: true,
        ceetLeaderboardCompetitorError: null,
        ceetLeaderboardCompetitorData: action.result,
      };

    case LOAD_COMPETITOR_FAIL:
      return {
        ...state,
        ceetLeaderboardCompetitorLoading: false,
        ceetLeaderboardCompetitorLoaded: false,
        ceetLeaderboardCompetitorData: null,
        ceetLeaderboardCompetitorError: action.error,
      };

    default:
      return state;
  }
}

export function getCeetLeaderboard(
  merchantId,
  accessToken,
  startDate,
  endDate,
  pid,
  city,
  mega_city,
  categoryId,
  localityId,
  leaderboardType,
  lattitude,
  longitude
) {
  const brandTypes = [LOAD_BRAND, LOAD_BRAND_SUCCESS, LOAD_BRAND_FAIL];
  const competitorTypes = [LOAD_COMPETITOR, LOAD_COMPETITOR_SUCCESS, LOAD_COMPETITOR_FAIL];
  const types = leaderboardType === 'internal' ? brandTypes : competitorTypes;
  return {
    types,
    promise: (client) =>
      client.default.post(`/getCeetLeaderboard`, {
        data: {
          merchantId,
          accessToken,
          startDate,
          endDate,
          pid,
          city,
          mega_city,
          categoryId,
          localityId,
          leaderboardType,
          lattitude,
          longitude,
        },
      }),
  };
}
