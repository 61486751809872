export default {
    english:{
        groceryTopHeading:"Online store to reach 50 Lac+ users - Reach your customers where they live",
        grocerySubHeading:"The entire world is buying online on Big Basket, Grofers, Amazon, etc. Why not build your online store as well.",
        groceryGetSales:"We will give you sales as well - not just an online store!",
        groceryPartnership:"After partnership with magicpin, your store is marketed aggressively to drive customers. The sales become steady to ensure higher returns from before.",
        increaseBusiness:"Increase my business",
        groceryRewards:"Reward your customers through magicpin offers",
        getMore:"Get more visits & orders",
        getMoreSubHeading:"magicpin rewards customers with upto 20% cashback each time they visit you",
        increaseRepeat:"Increase repeat customer",
        exclusiveVouchers:"With exclusive vouchers for your store, the customers keep coming back.",
        increaseOrder:"Increase order value",
        magicPayComboVouchers:"Get customers to spend more with magicpay & combo vouchers",
        spendOnly:"Spend only when you get sales",
        chargeOnly:"Say no to wasted marketing spend - We charge 5% only if a customer visits",
        getPower:"Get the power to do much more",
        manageStore:"Manage your online page, engage customers and get rich business insights to grow your business",
        feedpush:"Feedpush",
        inviteCustomers:"Invite customers",
        analyseBusiness:"Analyze your business",
        leaderboard:"Leaderboard",
        stunningAds:"Create stunning ads and promote to all customers in your locality",
        inviteTop:"Invite top customers to visit your store with a personalized offer",
        trackSales:"Track sales, ROI on marketing spend and preferences of customers in your locality",
        becomeTop:"Get more business and marketing to become the top outlet in your locality",
        additionalIncome:"and a chance for you to earn additional income of ₹5000 per month",
        sellBrands:"Sell magicpin partner brands to earn more money each month. Print bills and get rewards straight to your account",
        topOutlets:"Top outlets in",
        outletName:"your Outlet",
        cities:"Cities",
        whyMagicpin:"Why join magicpin?",
        groceryClaim:"Claim your online store",
        alreadyPartner:"Already a partner?",
        topHeading:"India’s #1 marketing app for local businesses",
        searchBusiness:"Search your business on magicpin"  ,
        selectCity:"Select City"  ,
        search:"SEARCH"  ,
        storeName:"Search your store name"  ,
        incFootfall:"Increase footfall at your store "  ,
        buttonCta1:"GET MORE CUSTOMERS"  ,
        salesHeading:"How to get assured sales with magicpin?"  ,
        reward:"Rewards"  ,
        voucher:"Vouchers"  ,
        groupBuy:"GroupBuy"  ,
        confirmBooking:"Confirm Bookings"  ,
        magicPay:"magicPay"  ,
        joinHeading:"Join the 1,00,000+ businesses using magicpin"  ,
        productBrands:"Product Brands"  ,
        retailBrands:"Retailer Brands"  ,
        partnersHeading:"What our partners are saying?"  ,
        buttonCta2:"Grow your Business"  ,
        business1:"5 million",
        business12:"users across 40 cities",
        business2:"18-35"  ,
        business22:"core user age group",
        business3:"5000rs +" ,
        business32:"average user spent/month",
        business4:"30 minutes+", 
        business42:"average user actice /day",
        rewardsHeading:"Best in class loyalty points for your customers on every purchase"   ,
        vouchersHeading:" 30 lakhs + worth of vouchers are sold on magicpin daily . create your own vouchers now!"  ,
        groupBuyHeading:"What is better than 1 ? 5, 10 ,15 ? get a group of friends to visit you today"   ,
        magicpayHeading:"Go cashless with a single click . one stop app for online payment"   ,
        confirmBookingHeading:"Never miss out on any bookings from your local customers"  ,
        testimonial1:"Success story of Molecule and magicpin goes hand in hand. With new customer acquisition resulting in the overall sales to go up, magicpin has contributed a lot to our growth."  ,
        testimonial2:"Our journey with magicpin started a year back. magicpin has helped us a lot with additional footfall and made sure that the old ones come back. Customers look forward to earn cashback which they can further spend to shop"  ,
        testimonial3:"Not only helping us to acquire new customers, but magicpin makes sure that we engage with customers on a repetitive basis. With great cashbacks and vouchers, magicpin is a great social media platform"  ,
        competitorsHeading:"know what your competition is doing"  ,
        competitors1:"While acquiring new customers we have also been able to maintain existing customer base with magicpin."  ,
        competitors2:"The reviews we are getting on magicpin are very different, I can even go back and invite a reviewer to my store."  ,
        competitors3:"We knew what customers we were looking for, magicpin helped us find them."  ,
        visitedCustomers:"Visited customers"  ,
        txnsDriven:"Transaction driven"  ,
        buttonCta3:"see your competitors"  ,
        heading2:"Take control of your Online & Offline visibility at magicpin"  ,
        yourRank:"Your rank among"  ,
        in:"in"  ,
        merchants:"Merchants"  ,
        magicpin:"magicpin's",
        influence:"influence in",
        yourLocality:"your locality",
        monthlyTxns:"Monthly Transactions"  ,
        customers:"Customers"  ,
        monthlySale:"Monthly sale"  ,
        wrongDetails:"wrong details"  ,
        username:"Username"  ,
        password:"Password"  ,
        forgotPassword:"Forgot password"  ,
        login:"Login"  ,
        loginOtp:"login via otp"  ,
        popupHeading:"Partner with magicpin to grow your business. Its free now!"  ,
        popupHeading2:"Partner with magicpin to grow your business.",
        popupSubHeading:"Please enter your details to continue"  ,
        rupees:"200",
        getWalletHeading:"Now get ",
        getWalletHeading2:"instantly in your wallet on successful verification."  ,
        oneTimeFee:"One time listing fees ₹",
        nameConst:"Name"  ,
        emailConst:"Email id"  ,
        phoneConst:"Phone Number"  ,
        outletNameConst:"Outlet Name"  ,
        cityConst:"City"  ,
        localityConst:"Locality"  ,
        signUpConst:"SIGN UP"  ,
        outletsConst:"Outlets"  ,
        txnsConst:"Transactions"  ,
        salesConst:"Sales"  ,
        customerReviewConst:"Customer Reviews"  ,
        viewsMagicpinConst:"Views on magicpin"  ,
        yourRatingConst:"Your Rating"  ,
        notBusiness:"Not your business?"   ,
        clickHere:"Click here"  ,
        testimonial4:"We have been partnered with magicpin since day one and it has been the perfect app for us. It has helped us giving us all the features which we were looking to build for ourself."  ,
        testimonial5:"A happy place catering to people who love beer, magicpin has helped us getting new customers on board and also retain old customers. As a consultant, he recommends the app to everyone."  ,
        testimonial6:"It has been 3-4 months with magicpin and it has been a fruitful journey. We get to analyze the data where we get a close insight about the customers liking. I recommend this app to everyone."  ,
        buttonCta4:"VIEW MORE CUSTOMERS"  ,
        restaurantConst:"Restaurant"  ,
        foodConst:"Food",
        beautyConst:"Beauty"  ,
        fashionConst:"Fashion"  ,
        fitnessConst:"Fitness"  ,
        groceryConst:"Grocery"  ,
        promotionsHeading:"Live promotions running in your locality"  ,
        whatIsMagicpin:"What is magicpin"  ,
        heading3:"How can we grow your business" ,
        couldNotFind:"Can't find your outlet?"  ,
        clickHereConst:"Click here to add it."  ,
        partnerFree:"PARTNER for FREE"  ,
        ratedBy:"Rated by"  ,
        spent:"Spent"  ,
        seeTop:"See top 10"  ,
        iName:"Invalid Name"  ,
        iEmail:"Invalid Email"  ,
        iPhone:"Invalid Phone"  ,
        iOutlet:"Invalid Outlet Name"  ,
        iCity:"Invalid City" ,
        iLocality:"Invalid Locality"  ,
        eligibleConst:"You are eligible for a "  ,
        referralConst:"₹100 referral reward.",
        findStore:"FIND YOUR STORE",
        welcomeConst:"Welcome to" ,
        hey:"Hey" ,
        partner:"Partner"  ,
        owner:"Owner" ,
        founder:"Founder",
        likes:"Likes",
        posts:"Posts",
        followers:"Followers",
        views:"Views",
        findStore:"Find Your Store"  ,
        loginText:"Login to get started again!"  ,
        needHelp:"Need help? "  ,
        callUs:"Call us at 75101 75101 (10 AM to 8 PM)"  ,
        forgotPassword:"Forgot password?"  ,
        loginText2:"Or Login with OTP"  ,
        aboutUS:"About us"  ,
        text1:"magicpin is a community that binds customers, activities, and merchants in a locality. It allows brands to connect and engage with their potential and existing customers. Voted as the best local discovery app last year, it allows you to boost your business and increase your visibility in the market."  ,
        text2:"Acquire new customers for businesses"  ,
        text3:"Spread the word"  ,
        text4:"Incentivize new customers"  ,
        text5:"Drive utilization for existing businesses"  ,
        text6:"Get rid of unsold inventory"  ,
        text7:"Improve usage in lean periods"  ,
        text8:"Engage & Increase repeat for existing businesses"  ,
        text9:"Reward loyal users"  ,
        text10:"Incentivize users to repeat"  ,
        text11:"Promote your business with a platform designed to attract the right audience based on location, spending pattern, gender, and more."  ,
        acquire:"Acquire"  ,
        text12:"Promote your business with a platform designed to attract the right audience based on location, spending pattern, gender, and more."  ,
        retain:"Retain"  ,
        text13:"magicpin makes it possible for businesses to bring back that nostalgic, remember-your-name kind of loyalty that consumers look for."  ,
        analyse:"Analyse"  ,
        text14:"magicpin makes it possible for businesses to remember seemingly small details about customers from their names to their favorite drinks. Feel the pulse of your business: page views, generated revenue, customers data, and more."  ,
        resetPassword:"Reset password"  ,
        mobileNo:"mobile number"  ,
        sendOtp:"send OTP"  ,
        validMob:"Please enter a valid mobile number"  ,
        notRegistered:"Not registered? Find and Claim your listing."  ,
        enterNo:"Enter 10 digit mobile number"  ,
        loginText3:"Or Login with credentials"  ,
        getStarted:"Get started"  ,
        IN:"IN"  ,
        viewsConst:"views" ,
        custPref:"Customers' preference in your locality" ,
        topRetailersTooltip:"The top retailers preferred by customers in your locality across various categories",
        restaurantConst:"Restaurants"  ,
        beautyConst:"Beauty"  ,
        fashionConst:"Fashion"  ,
        groceryConst:"Grocery"  ,
        fitnessConst:"Fitness"  ,
        popularCities:"Popular Cities"  ,
        popularLocalities:"Popular Localities"  ,
        popularRestaurants:"Popular Restaurants"  ,
        aboutUs:"About us"  ,
        voucher:"Vouchers"  ,
        aroundYou:"Around You "  ,
        blog:"Blog "  ,
        augmentedReality:"Augmented Reality "  ,
        termsCond:"Terms & Conditions "  ,
        commStand:"Community Standards "  ,
        privacy:"Privacy "  ,
        sitemap:"Sitemap "  ,
        brands:"Brands"  ,
        directory:"Directory",
        categoryConst:"Categories"
    },
    hindi:{
        groceryTopHeading:"50 लाख + ग्राहकों तक पहुंचने के लिए ऑनलाइन स्टोर - अपने ग्राहकों तक पहुंचें जहां वे रहते हैं",
        grocerySubHeading:"पूरी दुनिया बिग बास्केट, ग्रोफर्स, अमेज़ॅन आदि पर ऑनलाइन ख़रीदारी कर रही है, क्यों न आप भी अपना ऑनलाइन स्टोर तैयार करें।",
        groceryGetSales:"न केवल एक ऑनलाइन स्टोर बल्कि बिक्री में भी बढ़ोतरी करें",
        groceryPartnership:"मैजिकपिन के साथ साझेदारी के बाद, ग्राहकों को आपके स्टोर तक लाने के लिए नियमित रूप से मार्केटिंग की जाती है। जिसके कारण पहले से कहीं ज्यादा रिटर्न, बिक्री के अंतर्गत प्राप्त होना शुरू हो जाता है।",
        increaseBusiness:"मेरा व्यापार बढ़ाएं",
        groceryRewards:"मैजिकपिन ऑफ़र के माध्यम से अपने ग्राहकों को रिवॉर्ड के रूप में लाभ पहुंचाएं",
        getMore:"अधिक से अधिक विज़िट एवं ऑर्डर प्राप्त करने का मौक़ा पाएं",
        getMoreSubHeading:"मैजिकपिन हर बार ग्राहकों को आपके स्टोर को विज़िट करने पर रिवॉर्ड के रूप में 20% तक का कैशबैक देता है",
        increaseRepeat:"रिपीट ग्राहक में बढ़ोतरी करें",
        exclusiveVouchers:"आपके स्टोर के विशेष वाउचर होने की वज़ह से ग्राहक बार बार आते हैं।",
        increaseOrder:"आर्डर वैल्यू बढ़ाएं",
        magicPayComboVouchers:"मैजिकपे और कॉम्बो वाउचर के माध्यम से अधिक खर्च करने वाले ग्राहकों को अपने यहां पाएं",
        spendOnly:"खर्च बिक्री प्राप्त होने पर ही करें",
        chargeOnly:"व्यर्थ मार्केटिंग खर्च से बचें - किसी ग्राहक के विज़िट करने पर हम केवल 5% चार्ज करते हैं",
        getPower:"और अधिक सेवाएं प्राप्त करें",
        manageStore:"अपने ऑनलाइन पेज को मैनेज करें, ग्राहकों को एंगेज करें और अपने व्यापार को बढ़ाने के लिए समृद्ध विश्लेषण प्राप्त करें",
        feedpush:"फ़ीड-पुश",
        inviteCustomers:"ग्राहकों को आमंत्रित करें",
        analyseBusiness:"अपने व्यापार का विश्लेषण करें",
        leaderboard:"लीडरबोर्ड",
        stunningAds:"शानदार विज्ञापन तैयार करें और अपने इलाके के सभी ग्राहकों तक पहुंच बनाएं",
        inviteTop:"व्यक्तिगत ऑफर के साथ आपके स्टोर को विज़िट करने के लिए टॉप के ग्राहकों को आमंत्रित करें",
        trackSales:"अपने इलाके में मार्केटिंग खर्च से संबंधित बिक्री एवं आरओआई को ट्रैक करें और साथ ही ग्राहकों की पसंद का ख्याल रखें",
        becomeTop:"अपने इलाके में टॉप के विक्री केन्द्र बनने के लिए अधिक व्यापार और मार्केटिंग प्राप्त करें",
        additionalIncome:"साथ ही, आप प्रति माह ₹5000 तक का अतिरिक्त आय अर्जित करने का मौका पाएं",
        sellBrands:"हर महीने अधिक पैसे कमाने के लिए मैजिकपिन पार्टनर ब्रांड बेचें। बिल प्रिंट करें और अपने खाते में सीधे रिवॉर्ड प्राप्त करें",
        topOutlets:"टॉप के विक्री केन्द्र",
        outletName:"आपका आउटलेट",
        cities:"शहर",
        whyMagicpin:"क्यों शामिल हो मैजिकपिन में?",
        groceryClaim:"अपना ऑनलाइन स्टोर शुरू करें",
        alreadyPartner:"पहले से ही एक साथी?",
        topHeading:"स्‍थानीय व्यवसाय की बिक्री के लिए भारत का #1 ऍप्लिकेशन्",
        searchBusiness:"मैजिकपिन पर अपने व्यवसाय की खोज करें"  ,
        selectCity:"शहर चुनें"  ,
        search:"खोज करें"  ,
        storeName:"दुकान का नाम"  ,
        incFootfall:"अपने स्टोर के ग्राहको की संख्या बढ़ाएं"  ,
        buttonCta1:"अन्य ग्रहक देखें"  ,
        salesHeading:"मैजिकपिन के साथ से एक निश्चित बिक्री पाएँ"  ,
        reward:"इनाम / पुरस्कार"  ,
        voucher:"वाउचर"  ,
        groupBuy:"सामूहिक लाभ / बिक्री"  ,
        magicPay:"मैजिक पे"  ,
        confirmBooking:"बुकिंग सुनिश्चित करें"  ,
        joinHeading:"1,00,000 से भी अधिक व्यवसायों की तरह मैजिकपिन से जुड़ें"  ,
        productBrands:"उत्पादक ब्रांड"  ,
        retailBrands:"फुटकर ब्रांड"  ,
        partnersHeading:"हमारे पटनर्स का मैजिकपिन के साथ अनुभव"  ,
        buttonCta2:" अपने व्यापार को बढ़ाएँ"  ,
        business1:" 12 लाख "  ,
        business12:"से भी अधिक उपभोक्ता 10 शहरो मे"  ,
        business2:"18- 35"  ,
        business22:"मुख्य उपभोक्ताओं की आयु सीमा"  ,
        business3:" 5000 से भी अधिक"  ,
        business32:"औसत उपभोक्ताओं का मासिक खर्च"  ,
        business4:" 30 मिनिट्स से भी अधिक "  ,
        business42:"औसत उपभोक्ताओं की  दैनिक सक्रियता"  ,
        rewardsHeading:"आपके ग्राहकों के लिए प्रत्येक खरीद पर एक निश्चित पुरस्कार"  ,
        vouchersHeading:" मैजिकपिन पर रोजाना ३० लाख से भी अधिक वाउचर्स की बिक्री होती है,बनाएँ अपने खुद के वाउचर्स "  ,
        groupBuyHeading:"एक से उत्तम क्या ? 5 ,10, 15 ? अब समूह मे पायें ग्राहक"  ,
        magicpayHeading:"सिर्फ़ एक क्लिक मे अपने भुक्तान को बनायें कैशलेस| ऑनलाइन पेमेंट का आसान स्थान मैजिकपिन"  ,
        confirmBookingHeading:"स्थानीय ग्राहकों की एक भी बुकिंग्स को ना खोएँ"  ,
        testimonial1:"मौलीक्युल और मैजिकपिन की सफलता साथ साथ चल रही है । मैजिकपिन के द्वारा नए ग्राहकों के अर्जन का पूर्ण परिणाम समग्र बिक्री की बढ़ोत्तरी में देखने को मिल रहा है। हमारी सफलता में मैजिकपिन का बहुत अधिक योगदान रहा है।"  ,
        testimonial2:"एक साल पहले हमारा  सफर मैजिकपिन के साथ शुरू हुआ । मैजिकपिन की सहायता से ग्राहकों की बढ़ोत्तरी के साथ साथ पुराने ग्राहकों के दोहराव में भी तीव्रता आयी है । ग्राहकों में भी कैशबैक पाने की लालसा देखने को मिलता है ।"  ,
        testimonial3:"मैजिकपिन ना केवल हमें नये ग्राहकों के अर्जन में सहायता कर रहा बल्कि ग्राहकों के  दोहराव को भी बना के रखने में सहायता करता है। मैजिकपिन उच्चतम कैशबैक और  वाउचर पाने का सर्वश्रेष्ठ सार्वजनिक माध्यम है।"  ,
        competitorsHeading:"अपनी व्यापारिक प्रतियोगिता को जानें"  ,
        competitors1:"मैजिकपिन की सहायता से हम नये ग्राहकों की प्राप्ति के साथ साथ अपने मौजूदा ग्राहकों को बनाए रखने में सफल हुए हैं।"  ,
        competitors2:"ग्राहकों की जो समीक्षाएं  हमें मैजिकपिन के द्वारा के प्राप्त हो रही हैं , वह अलग हैं । मैजिकपिन की सहायता से ,मैं अपने समीक्षकों को दुबारा अपनी दुकान पर आमंत्रित कर बुला सकता हूं।"  ,
        competitors3:"मैजिकपिन हमें अपने अनुरूप ग्राहकों को पाने में सहायक रहा है ।"  ,
        visitedCustomers:"आये हुए ग्राहक"  ,
        txnsDriven:"चालित लेन देन"  ,
        buttonCta3:"अपने प्रतिद्वद्वियों को देखें"  ,
        heading2:"मैजिकपिन पर अपनी ऑनलाइन तथा ऑफलाइन दृश्यता को नियंत्रित करें।"  ,
        yourRank:"आपका स्थान"  ,
        in:"में"  ,
        merchants:"व्यवसायियों"  ,
        magicpin:"मैजिकपिन"  ,
        influence:"का प्रभाव आपके इलाके में",
        monthlyTxns:"मासिक लेन देन"  ,
        customers:"ग्राहक"  ,
        monthlySale:"मासिक बिक्री"  ,
        wrongDetails:"गलत डिटेल्स"  ,
        username:"यूजर नेम"  ,
        password:"पासवर्ड"  ,
        forgotPassword:"खाता भूल गए?"  ,
        login:"लॉग इन करें"  ,
        loginOtp:"लॉग इन करें फ़ोन नंबर से "  ,
        popupHeading:"निःशुल्क मैजिकपिन के साथ जुड़ें और अपने व्यापार को बढ़ाएं!"  ,
        popupHeading2:"मैजिकपिन के साथ जुड़ें और अपने व्यापार को बढ़ाएं!",
        popupSubHeading:"शुरू करने के लिए अपनी डिटेल्स डालें"  ,
        rupees:"२०० की राशि"  ,
        getWalletHeading:" पाएं "  ,
        getWalletHeading2:"सफल साइन अप करने पर"  ,
        oneTimeFee:"एक बार लिस्टिंग शुल्क ₹",
        nameConst:"नाम"  ,
        emailConst:"ईमेल पता"  ,
        phoneConst:"फोन नंबर"  ,
        outletNameConst:"दुकान का नाम"  ,
        cityConst:"शहर"  ,
        localityConst:"इलाका"  ,
        signUpConst:"साइन अप करें"  ,
        outletsConst:"दुकानें"  ,
        txnsConst:"लेन देन"  ,
        salesConst:"बिक्री"  ,
        customerReviewConst:"ग्राहकों का अनुभव"  ,
        viewsMagicpinConst:"खोज मैजिकपिन पर"  ,
        yourRatingConst:"आपकी रेटिंग"  ,
        notBusiness:"क्या यह आपका व्यापार नहीं है?"  ,
        clickHere:"यहां क्लिक करें"  ,
        testimonial4:"हमारा सफर मैजिकपिन के साथ पहले दिन से ही है , ये ऐप हमारे लिए सर्वोत्तम साबित हुआ है । वह सभी विशेषताएं जिनकी खोज हम अपने व्यापार को बढ़ाने में कर रहे थे , मैजिकपिन की सहायता से वह सभी विशेषताओं की प्राप्ति हुई है।"  ,
        testimonial5:"बियर प्रेमियों के भोजन के लिए एक सुखद स्थान , मैजिक पिन ना केवल हमें नए ग्राहक दे रहा बल्कि हमारे नियमित ग्राहकों को आकर्षित करके दुबारा जल्दी जल्दी हमरे तक लाने में भी सहायता दे रहा है। एक सलाहकार के रूप में सभी को यह ऐप प्रयोग करने की सलाह दे रहा है।"  ,
        testimonial6:"मैजिकपिन के साथ ३-५ माह हुए काम करते हुए , अभी तक ये एक सुखद सफर रहा है । मैजिकपिन के डाटा की सहायता से हमें अपने ग्राहकों की पसंद को और अधिक करीब से जानने का मौका मिला है। मैंने अपने से जुड़े सभी लोगो को इस ऐप की सलाह दी है।"  ,
        buttonCta4:"और अन्य ग्राहकों को देखें"  ,
        restaurantConst:"रेसटोरेंट् "  ,
        beautyConst:"सौंदर्य"  ,
        fashionConst:"वेश भूषा"  ,
        fitnessConst:"फिटनेस"  ,
        groceryConst:"किराना"  ,
        promotionsHeading:"आपके इलाके में चल रहा विज्ञापन"  ,
        whatIsmagicpin:"मैजिकपिन क्या है?"  ,
        heading3:"हमारे द्वारा आपके व्यापार में वृद्धि" ,
        welcomeConst:"स्वागत हे"  ,
        couldNotFind:"आपका आउटलेट नहीं मिल सका?"  ,
        clickHereConst:"यहां क्लिक करें, हम आपसे ईमेल के माध्यम से संपर्क करेंगे।"  ,
        partnerFree:"मुफ्त में पार्टनर बनिये"  ,
        ratedBy:"द्वारा रेट किया गया"  ,
        spent:"खर्च किया"  ,
        seeTop:"शीर्ष 10 देखें"  ,
        iName:"अवैध नाम"  ,
        iEmail:"अवैध ईमेल"  ,
        iPhone:"अवैध फ़ोन"  ,
        iOutlet:"अवैध आउटलेट नाम"  ,
        iCity:"अवैध शहर"  ,
        iLocality:"अवैध इलाका"  ,
        eligibleConst:"आप पात्र हैं"  ,
        referralConst:"100 रेफरल इनाम के। "  ,
        foodConst:" भोजन"  ,
        followers:"समर्थक"  ,
        likes:"लाइक्स"  ,
        posts:"पोस्ट"  ,
        partner:"साथी"  ,
        owner:"मालिक" ,
        hey:"नमस्ते"  ,
        findStore:"अपने स्टोर का पता लगाएं",
        views:"लोगों ने देखा",
        custPref:"आपके इलाके में ग्राहकों की पसंद",
        topRetailersTooltip:"आपके क्षेत्र में ग्राहकों द्वारा पसंद किए जाने वाले शीर्ष विक्रेता" ,
        restaurantConst:"रेसटोरेंट् "  ,
        beautyConst:"सौंदर्य"  ,
        fashionConst:"वेश भूषा"  ,
        groceryConst:"किराना"  ,
        fitnessConst:"फिटनेस"  ,
        popularCities:"लोकप्रिय शहर"  ,
        popularLocalities:"लोकप्रिय इलाके"  ,
        popularRestaurants:"लोकप्रिय रेसटोरेंट् "  ,
        aboutUs:"हमारे बारे में जानें"  ,
        voucher:"वाउचर"  ,
        aroundYou:"तुम्हारे आस पास"  ,
        blog:"ब्लॉग"  ,
        augmentedReality:"संवर्धित वास्तविकता"  ,
        termsCond:"नियम एवं शर्तें"  ,
        commStand:"सामुदायिक मानक"  ,
        privacy:"गोपनीयता"  ,
        sitemap:"साइटमैप"  ,
        brands:"ब्रांड"  ,
        directory:"निर्देशिका" ,
        categoryConst:"श्रेणियाँ"
      },
      "bahasa" : {
        topHeading:"Aplikasi nomor 1 pemasaran di Indonesia untuk bisnis lokal"  ,
        searchBusiness:"Cari bisnis anda di magicpin"  ,
        selectCity:"Pilih Kota"  ,
        search:"Cari"  ,
        storeName:"Nama Outlet"  ,
        incFootfall:"Tingkatkan pengunjung di outlet anda"  ,
        buttonCta1:"Dapatkan lebih banyak pelanggan"  ,
        salesHeading:"Dapatkan penjualan terjamin dengan magicpin"  ,
        reward:"Reward"  ,
        voucher:"Voucher"  ,
        groupBuy:"Group Buy"  ,
        confirmBooking:"Konfirmasi Pemesanan"  ,
        magicPay:"Magic Pay"  ,
        joinHeading:"Bergabunglah dengan 100.000+ bisnis menggunakan magicpin"  ,
        productBrands:"Merek Produk"  ,
        retailBrands:"Merek Retail"  ,
        partnersHeading:"Pendapat partner kami"  ,
        buttonCta2:"Mengembangkan bisnis anda"  ,
        business1:"5 Juta"  ,
        business12:"pengguna di 40 kota"  ,
        business2:"18-35"  ,
        business22:"Kelompok usia pengguna"  ,
        business3:"Rp 10.000.000"  ,
        business32:"rata-rata pengeluaran pengguna perbulan"  ,
        business4:"30 menit +"  ,
        business42:"rata-rata pengguna aktif per hari"  ,
        rewardsHeading:"memberikan poin kesetiaan pelanggan terbaik di kelasnya untuk pelanggan anda di setiap pembelian"  ,
        vouchersHeading:"3 juta + voucher terjual di magicpin setiap hari. Buat voucher anda sendiri sekarang!"  ,
        groupBuyHeading:"apa yang lebih baik dari 1? 5, 10, 15? dapatkan sekelompok teman untuk mengunjungi anda hari ini"  ,
        magicpayHeading:"tanpa uang tunai hanya dengan satu kali klik untuk proses pembayaran online"  ,
        confirmBookingHeading:"Jangan lewatkan pemesanan dari pelanggan anda"  ,
        testimonial1:"Kisah sukses dari Molecule dan magicpin yang berjalan seiringan. Dengan akuisisi pelanggan baru yang membuahkan naiknya penjualan. magicpin telah berkontribusi banyak pada pertumbuhan Molecule."  ,
        testimonial2:"Perjalan kami dengan magicpin dimulai setahun yang lalu. magicpin sudah banyak membantu kami dengan mendatangkan pengunjung dan memastikan bahwa pelanggan yang lama tetap kembali. Pelanggan berharap untuk mendapatkan cashback yang selanjutnya dapat mereka gunakan untuk berbelanja."  ,
        testimonial3:"Tidak hanya membantu kami untuk mendapatkan pelanggan baru, magicpin juga memastikan bahwa keterikatan dengan pelanggan secara berkala. Dengan cashback dan voucher yang menarik, magicpin adalah plaform media sosial yang hebat."  ,
        competitorsHeading:"Mengetahui apa yang dilakukan oleh pesaing anda"  ,
        competitors1:"Ketika mendapatkan pelanggan baru, kami mampu mempertahankan pelanggan yang sudah ada di magicpin"  ,
        competitors2:"Ulasan yang kami dapat di magicpin sangat berbeda saya bahkan bisa kembali dan mengundang pemberi ulasan datang ke outlet saya"  ,
        competitors3:"Kami mengetahui pelanggan yang kami cari, magicpin membantu kami menemukan pelanggan"  ,
        visitedCustomers:"Pelanggan yang datang"  ,
        txnsDriven:"Peningkatan transaksi"  ,
        buttonCta3:"Lihat pesaing anda"  ,
        heading2:"Kendalikan keberadaan online & offline outlet anda di magicpin"  ,
        yourRank:"Peringkat anda diantara"  ,
        inMerchants:"di"  ,
        merchants:"Outlet"  ,
        magicpin:"magicpin"  ,
        influence:"Pengaruh di",
        monthlyTxns:"Transaksi per bulan"  ,
        customers:"Pelanggan"  ,
        monthlySale:"Penjualan per bulan"  ,
        wrongDetails:"Detail yang salah"  ,
        username:"Usernama"  ,
        password:"Kata Sandi"  ,
        forgotPassword:"Lupa Kata Sandi"  ,
        login:"Masuk"  ,
        loginOtp:"Masuk dengan OTP"  ,
        popupHeading:"Bekrjasama dengan magicpin untuk membangun bisnis anda. Gratis sekarang!"  ,
        popupSubHeading:"Lengkapi data diri anda untuk melanjutkan"  ,
        rupees:"40.000"  ,
        getWalletHeading:"Dapatkan sekarang"  ,
        getWalletHeading2:"langsung ditambahkan ke magicpin wallet anda setelah verivikasi berhasil"  ,
        nameConst:"Nama"  ,
        emailConst:"Email"  ,
        phoneConst:"No Telepon"  ,
        outletNameConst:"Nama Outlet"  ,
        cityConst:"Kota"  ,
        localityConst:"Daerah"  ,
        signUpConst:"Daftar"  ,
        outletsConst:"Outlet"  ,
        txnsConst:"Transaksi"  ,
        salesConst:"Penjualan"  ,
        customerReviewConst:"Ulasan Pengunjung"  ,
        viewsMagicpinConst:"Dilihat dalam magicpin"  ,
        yourRatingConst:"Penilaian Anda"  ,
        notBusiness:"Bukan outlet anda?"  ,
        clickHere:"Klik disini"  ,
        testimonial4:"Kami telah bekerjasama dengan magicpin sejak awal dan magicpin telah menjadi aplikasi yang sangat membantu bagi kami. magicpin membantu kami memberikan semua fitur yang ingin kami buat sendiri."  ,
        testimonial5:"Tempat yang menyenangkan untuk melayani orang-orang yang menyukai Beer, magicpin telah membantu kami mendapatkan pelanggan baru dan juga mempertahankan pelanggan lama. Sebagai konsultan, dia merekomendasikan aplikasi ini kepada semua orang."  ,
        testimonial6:"Sudah 3-4 bulan bekerjasama dengan magicpin merupakan pengalaman yang bermanfaat. kami dapat menganalisis data dimana kami dapat mengetahui mengenai keinginan pelanggan. Saya merekomendasikan aplikasi ini kepada semua orang."  ,
        buttonCta4:"lihat lebih banyak pelanggan"  ,
        restaurantConst:"Restoran"  ,
        beautyConst:"Kecantikan"  ,
        fashionConst:"Fashion"  ,
        fitnessConst:"Fitness"  ,
        groceryConst:"Grocery"  ,
        promotionsHeading:"Promosi berlangsung di daerah anda"  ,
        whatIsmagicpin:"Apa itu magicpin?"  ,
        heading3:"Bagaimana kami dapat mengembangkan bisnis anda"  ,
        welcomeConst:"Selamat datang ke"  ,
        couldNotFind:"Tidak dapat menemukan outlet anda?"  ,
        clickHereConst:"Klik disini, kami akan menghubungi anda melalui email"  ,
        partnerFree:"Bekerjasama secara gratis"  ,
        ratedBy:"dinilai oleh"  ,
        spent:"dihabiskan"  ,
        seeTop:"Lihat 10 teratas"  ,
        iName:"Nama Salah"  ,
        iEmail:"Email Salah"  ,
        iPhone:"Telepon Salah"  ,
        iOutlet:"Nama Outlet Salah"  ,
        iCity:"Kota Salah"  ,
        iLocality:"Daerah Salah"  ,
        eligibleConst:"Anda berhak mendapatkan"  ,
        referralConst:"hadiah referral sebesar Rp 20.000"  ,
        foodConst:"Makanan"  ,
        followers:"Pengikut"  ,
        likes:"Disukai"  ,
        posts:"Post"  ,
        partner:"Rekanan"  ,
        owner:"Pemilik"  ,
        founder:"Pendiri"  ,
        operations:"Operasional"  ,
        hey:"Hai"  ,
        findStore:"Temukan Toko Anda",
        views:"Tampilan",
        custPref:"Preferensi pelanggan di wilayah Anda", 
        topRetailersTooltip:"Pengecer teratas disukai oleh pelanggan di wilayah Anda" ,
        restaurantConst:"Restoran"  ,
        beautyConst:"Kecantikan"  ,
        fashionConst:"Fashion"  ,
        groceryConst:"Grocery"  ,
        fitnessConst:"Fitness"  ,
        popularCities:"Kota-kota Populer"  ,
        popularLocalities:"Lokalitas Populer"  ,
        popularRestaurants:"Restoran Populer"  ,
        aboutUs:"Tentang kami"  ,
        voucher:"Voucher"  ,
        aroundYou:"Sekitarmu"  ,
        blog:"Blog"  ,
        augmentedReality:"Augmented Reality"  ,
        termsCond:"Syarat dan ketentuan"  ,
        commStand:"Standar Komunitas"  ,
        privacy:"Pribadi"  ,
        sitemap:"Peta situs"  ,
        brands:"merek"  ,
        directory:"Direktori" ,
        categoryConst:"Kategori"
    },
    "marathi": {
        groceryTopHeading:"50 लाख + ग्राहकांपर्यंत पोहोचण्यासाठी ऑनलाइन स्टोअर - आपल्या ग्राहकांना ते जिथे राहतात तेथे पोहोचा",
        grocerySubHeading:"संपूर्ण जग बिग बास्केट, ग्रीफर्स, ऍमेझॉन इ. वर ऑनलाइन खरेदी करीत आहे. तसेच आपले ऑनलाइन स्टोअर का तयार करू नये.",
        groceryGetSales:"केवळ ऑनलाइन स्टोअरच नव्हे तर विक्री देखील मिळवा",
        groceryPartnership:"मॅजिकपिनने भागीदारीनंतर, ग्राहकांना चालना देण्यासाठी आपल्या स्टोअरची आक्रमकपणे विक्री केली जाते. पूर्वीपेक्षा जास्त परतावा मिळण्यासाठी विक्री स्थिर होते.",
        increaseBusiness:"माझा व्यवसाय वाढवा",
        groceryRewards:"मॅजिकपिन ऑफरद्वारे आपल्या ग्राहकांना बक्षीस द्या",
        getMore:"अधिक भेटी आणि ऑर्डर मिळवा",
        getMoreSubHeading:"मॅजिकपिन प्रत्येक वेळी आपल्यास भेट देताना 20% पर्यंत कॅशबॅक ग्राहकांना पुरस्कृत करते",
        increaseRepeat:"पुन्हा पुन्हा येणारे ग्राहक वाढवा",
        exclusiveVouchers:"आपल्या स्टोअरसाठी अनन्य व्हाउचरसह, ग्राहक परत येत राहतात.",
        increaseOrder:"ऑर्डर मूल्य वाढवा",
        magicPayComboVouchers:"मॅजिकपे आणि कॉम्बो व्हाउचरसह अधिक खर्च करण्यासाठी ग्राहक मिळवा",
        spendOnly:"जेव्हा आपण विक्री करू शकाल तेव्हाच खर्च करा",
        chargeOnly:"व्यर्थ विपणन खर्चाला नाही म्हणा- ग्राहकाने भेट दिली तरच आम्ही 5% शुल्क आकारतो",
        getPower:"बरेच काही करण्याची शक्ती मिळवा",
        manageStore:"आपले ऑनलाइन पृष्ठ व्यवस्थापित करा, ग्राहक जोडा आणि आपला व्यवसाय वाढविण्यासाठी समृद्ध व्यवसाय अंतर्दृष्टी मिळवा",
        feedpush:"फीडपश",
        inviteCustomers:"ग्राहकांना आमंत्रित करा",
        analyseBusiness:"आपल्या व्यवसायाचे विश्लेषण करा",
        leaderboard:"लीडरबोर्ड",
        stunningAds:"जबरदस्त आकर्षक जाहिराती तयार करा आणि आपल्या परिसरातील सर्व ग्राहकांपर्यंत त्यांचा प्रसार करा.",
        inviteTop:"आपल्या ग्राहकांना वैयक्तिकृत ऑफरसह भेट देण्यासाठी महत्त्वाच्या ग्राहकांना आमंत्रित करा",
        trackSales:"आपल्या परिसरातील ग्राहकांच्या पसंतीच्या विपणनावरील खर्च आणि खरेदीची मागोवा घ्या",
        becomeTop:"आपल्या परिसरातील सर्वोच्च आउटलेट होण्यासाठी अधिक व्यवसाय आणि विपणन मिळवा",
        additionalIncome:"आणि आपल्यासाठी दरमहा ₹5000 चे अतिरिक्त उत्पन्न मिळवण्याची संधी",
        sellBrands:"प्रत्येक महिन्यात अधिक पैसे मिळविण्यासाठी मॅजिकपिन पार्टनर ब्रँडची विक्री करा. बिले मुद्रित करा आणि थेट आपल्या खात्यावर बक्षीस मिळवा",
        topOutlets:"सर्वोत्तम विक्रीकेंद्रांमधिल",
        outletName:"आपले दुकान",
        cities:"शहरे",
        whyMagicpin:"आपण मॅजिकपिन मध्ये का सामील व्हावे?",
        groceryClaim:"आपले ऑनलाइन स्टोअर सुरू करा",
        alreadyPartner:"आधीच भागीदार आहे?",
        topHeading:"स्थानिक व्यवसायांसाठी भारताचा # 1 विपणन अ‍ॅप"  ,
        searchBusiness:"मॅजिकपिन वर आपला व्यवसाय शोधा"  ,
        selectCity:"शहर निवडा"  ,
        search:"शोधा"  ,
        storeName:"स्टोअरचे नाव"  ,
        incFootfall:"आपल्या स्टोअरला भेट देणाऱ्या ग्राहकांची संख्या वाढवा"  ,
        buttonCta1:"जास्त ग्राहक मिळवा"  ,
        salesHeading:"मॅजिकपिन सोबत खात्रीशीर विक्री मिळवा"  ,
        reward:"बक्षिसे / पुरस्कार"  ,
        voucher:"व्हाउचर्स"  ,
        groupBuy:"गट-खरेदी"  ,
        confirmBooking:"बुकिंग्स कन्फर्म करा"  ,
        magicPay:"मॅजिक-पे"  ,
        joinHeading:"मॅजिकपिन वापरून 1,00,000+ व्यवसायांशी संलग्न व्हा."  ,
        productBrands:"उत्पादनांचे ब्रॅण्ड्स"  ,
        retailBrands:"किरकोळ विक्रेत्यांचे ब्रॅण्ड्स"  ,
        partnersHeading:"आपले भागीदार काय म्हणत आहेत"  ,
        buttonCta2:"आपला व्यवसाय वाढवा"  ,
        business1:"5 दशलक्ष"  ,
        business12:"40 शहरांमधील वापरकर्ते"  ,
        business2:"18-35"  ,
        business22:"मूळ वापरकर्त्याचा वयोगट"  ,
        business3:"₹ 50,000+"  ,
        business32:"वापरकर्त्याचा सरासरी प्रतिमाह खर्च"  ,
        business4:"30 मिनिटे+"  ,
        business42:"वापरकर्त्याचा सरासरी प्रतिदिन सक्रिय ("  ,
        rewardsHeading:"प्रत्येक खरेदीवर तुमच्या ग्राहकांना श्रेणीतील सर्वोत्तम लॉयल्टी पॉईंट्स"  ,
        vouchersHeading:"मॅजिकपिनवर दररोज 30 लाखांपेक्षा जास्त किमतीची व्हाउचर्स विकली जातात. आताच तुमची स्वतःची व्हाउचर्स बनवा!"  ,
        groupBuyHeading:"1 पेक्षा जास्त चांगले काय आहे? 5, 10, 15? तुम्हाला भेट देण्याकरता मित्रांचा गट मिळवा."  ,
        magicpayHeading:"केवळ एका क्लिक वर कॅशलेस व्हा. ऑनलाईन पेमेंटच्या विविध पर्यायांसाठी एकच ऍप्प"  ,
        confirmBookingHeading:"आपल्या स्थानिक ग्राहकांचे कोणतेही बुकिंग कधीही चुकवू नका"  ,
        testimonial1:"मॉलेक्यूल आणि मॅजिकपिन ची यशोगाथा एकत्र हातात हात घालून पुढे जात आहे. आमच्या व्यवसायवृद्धीत, नवीन ग्राहक संपादनाद्वारे एकूण विक्रीचा आलेख चढता ठेवण्यात मॅजिकपिन चे मोठे योगदान आहे."  ,
        testimonial2:"आमचा मॅजिकपिन सोबतचा प्रवास गेल्या वर्षी सुरु झाला. जास्तीत जास्त ग्राहक भेट देण्याच्या संख्येत वाढ करण्यात आणि जुने ग्राहक हमखास परत येण्यात आम्हाला मॅजिकपिनची खूप मदत झाली. ग्राहक कॅशबॅक मिळवण्यासाठी उत्सुक असतात, जो कॅशबॅक ते पुढील खरेदीसाठी खर्च करतात."  ,
        testimonial3:"मॅजिकपिन आम्हांस केवळ नवीन ग्राहक संपादित करण्यात मदतच करत नाही, तर आम्ही ग्राहकांसोबत पुनःपुन्हा जोडले जाऊ ह्याची खात्रीसुद्धा करते. उत्तम कॅशबॅक आणि व्हाउचर्स असणारे मॅजिकपिन हे सोशल मीडियामधील एक   उत्कृष्ट व्यासपीठ आहे."  ,
        competitorsHeading:"आपले स्पर्धक काय करत आहेत ते जाणून घ्या"  ,
        competitors1:"मॅजिकपिन सोबत नवीन ग्राहक संपादित करण्याबरोबरच, आमचा सध्याचा ग्राहकवर्ग राखणे आम्हाला शक्य झाले"  ,
        competitors2:"मॅजिकपिनवर आम्हाला मिळणारे रिव्ह्यू अतिशय वेगळे असतात. मी पुन्हा मागे जाऊन रिव्ह्यू देणाऱ्या व्यक्तीस माझ्या स्टोअर मध्ये आमंत्रित करू शकतो / शकते."  ,
        competitors3:"आम्ही कोणत्या प्रकारच्या ग्राहकांच्या शोधात आहोत, हे आम्हाला माहित होते. मॅजिकपिन ने आम्हाला ते (ग्राहक) शोधण्यास मदत केली."  ,
        visitedCustomers:"भेट दिलेले ग्राहक"  ,
        txnsDriven:"व्यवहार केलेले"  ,
        buttonCta3:"आपले स्पर्धक पहा"  ,
        heading2:"मॅजिकपिनवरील आपल्या ऑनलाईन / ऑफलाईन दृश्यमानतेचे नियंत्रण करा"  ,
        yourRank:"यांमधील आपला क्रमांक"  ,
        inMerchants:"IN"  ,
        merchants:"व्यापारी"  ,
        magicpinInfluence:"आपल्या परिसरात असलेला मॅजिकपिन चा प्रभाव"  ,
        influence:"आपल्या परिसरात प्रभाव",
        monthlyTxns:"मासिक व्यवहार"  ,
        customers:"ग्राहक"  ,
        monthlySale:"मासिक विक्री"  ,
        wrongDetails:"चुकीची माहिती"  ,
        username:"युजरनेम"  ,
        password:"पासवर्ड"  ,
        forgotPassword:"पासवर्ड विसरला आहे"  ,
        login:"लॉगिन"  ,
        loginOtp:"ओ.टी.पी. द्वारा लॉगिन"  ,
        popupHeading:"आपल्या व्यवसायवृद्धीसाठी मॅजिकपिन सोबत भागीदारी करा.  हे आता निःशुल्क आहे!"  ,
        popupHeading2:"आपल्या व्यवसायवृद्धीसाठी मॅजिकपिन सोबत भागीदारी करा.",
        popupSubHeading:"कृपया पुढे जाण्यासाठी आपली माहिती भरा"  ,
        rupees:"200"  ,
        getWalletHeading:"आता मिळवा"  ,
        getWalletHeading2:"यशस्वी पडताळणीनंतर त्वरित आपल्या वॉलेट मध्ये"  ,
        oneTimeFee:"एक वेळ यादी फी ₹",
        nameConst:"नाव"  ,
        emailConst:"ई-मेल आयडी"  ,
        phoneConst:"फोन नंबर"  ,
        outletNameConst:"आउटलेट चे नाव"  ,
        cityConst:"शहर"  ,
        localityConst:"परिसर"  ,
        signUpConst:"साईन अप करा"  ,
        outletsConst:"आउटलेट्स"  ,
        txnsConst:"व्यवहार"  ,
        salesConst:"विक्री"  ,
        customerReviewConst:"ग्राहकांचे रिव्ह्यू"  ,
        viewsMagicpinConst:"मॅजिकपिन वरील पाहणी संख्या"  ,
        yourRatingConst:"तुमचे रेटिंग"  ,
        notBusiness:"तुमचा व्यवसाय नाही?"  ,
        clickHere:"येथे क्लिक करा"  ,
        testimonial4:"आम्ही पहिल्या दिवसापासूनच मॅजिकपिन सोबत भागीदारी केली, आणि ते आमच्याकरिता अतिशय सुयोग्य ऍप आहे. मॅजिकपिनमुळे आम्हाला  ती सारी वैशिष्ट्ये प्राप्त झाली, जी आम्ही स्वतःकरता बनविणार होतो."  ,
        testimonial5:"ज्यांना बिअर प्रिय आहे, अश्या व्यक्तींसाठी खानपान पुरवणाऱ्या अश्या आमच्या आनंददायी जागेसाठी नवीन ग्राहक मिळवण्यात आणि जुने ग्राहक राखण्यात आम्हाला मॅजिकपिनची मदत झाली. एक सल्लागार म्हणून, तो ह्या ऍपची प्रत्येकाला शिफारस करतो."  ,
        testimonial6:"मॅजिकपिन सोबत 3-4 महिने झाले असून हा प्रवास नक्कीच फलदायी आहे. ग्राहकांच्या आवडीनिवडीची अचूक नस पकडण्यासाठी आवश्यक असणाऱ्या माहितीचे विश्लेषण आम्हाला येथे करता येते. मी प्रत्येकाला ह्या ऍप ची शिफारस करेन."  ,
        buttonCta4:"अधिक ग्राहक बघा"  ,
        restaurantConst:"रेस्टॉरंट"  ,
        beautyConst:"ब्युटी"  ,
        fashionConst:"फॅशन"  ,
        fitnessConst:"फिटनेस"  ,
        groceryConst:"किराणा"  ,
        promotionsHeading:"आपल्या परिसरात सुरु असणारे प्रोमोशन्स"  ,
        whatIsmagicpin:"मॅजिकपिन काय आहे?"  ,
        magicpin:"मॅजिकपिन चा",
        heading3:"तुमचा व्यवसाय आम्ही कसा वाढवू शकतो?"  ,
        welcomeConst:"स्वागत आहे"  ,
        couldNotFind:"आपले आउटलेट मिळाले नाही?"  ,
        clickHereConst:"येथे क्लिक करा. आम्ही आपल्याशी ई-मेल द्वारा संपर्क साधू."  ,
        partnerFree:"निःशुल्क भागीदार"  ,
        ratedBy:"कोणी रेट केले"  ,
        spent:"खर्च केला"  ,
        seeTop:"टॉप 10 पहा"  ,
        iName:"अवैध नाव"  ,
        iEmail:"अवैध ई-मेल"  ,
        iPhone:"अवैध फोन"  ,
        iOutlet:"अवैध आउटलेट नाव"  ,
        iCity:"अवैध शहर"  ,
        iLocality:"अवैध परिसर"  ,
        eligibleConst:"आपण यासाठी पात्र झालेले आहात"  ,
        referralConst:"₹100 शिफारस पुरस्कार"  ,
        foodConst:"अन्न"  ,
        followers:"फॉलोअर्स"  ,
        likes:"लाईक्स"  ,
        posts:"पोस्ट्स"  ,
        partner:"भागीदार"  ,
        owner:"मालक"  ,
        founder:"संस्थापक"  ,
        operations:"ऑपरेशन्स"  ,
        hey:"अहो"  ,
        findStore:"आपले स्टोअर शोधा"  ,
        loginText:"पुन्हा सुरुवात करण्यासाठी लॉगिन करा!"  ,
        needHelp:"मदत हवी आहे?"  ,
        callUs:"आम्हाला ह्या क्रमांकावर कॉल करा 75101 75101 (सकाळी 10 ते रात्री 8 पर्यंत)"  ,
        forgotPassword:"पासवर्ड विसरला आहे?"  ,
        loginText2:"किंवा ओ.टी.पी. द्वारा लॉगिन करा"  ,
        aboutUS:"आमच्याबद्दल"  ,
        text1:"मॅजिकपिन हा एकाच परिसरातील ग्राहक, उपक्रम व व्यापाऱ्यांना एकत्र आणणारा समुदाय आहे. मॅजिकपिन विविध ब्रॅण्ड्स ना त्यांच्या संभाव्य आणि विद्यमान ग्राहकांशी जोडण्यास साहाय्यभूत ठरते. 'बेस्ट लोकल डिस्कवरी ऍप' म्हणून गेल्यावर्षी निवडले गेलेले मॅजिकपिन, तुम्हाला तुमच्या व्यवसायवृद्धीसाठी आणि बाजारपेठेतील तुमची दृश्यमानता वाढवण्यासाठी मदत करते."  ,
        text2:"व्यवसायासाठी नवीन ग्राहक संपादित करा"  ,
        text3:"तोंडी प्रचार करा"  ,
        text4:"नवीन ग्राहकांना प्रोत्साहन द्या"  ,
        text5:"विद्यमान व्यवसायाची उपयुक्तता वाढविण्यास चालना द्या"  ,
        text6:"विकल्या न गेलेल्या मालाची विल्हेवाट लावा"  ,
        text7:"मंदीच्या काळात वापर वाढवा"  ,
        text8:"विद्यमान व्यवसायांसाठी (ग्राहक) जोडा व वाढवा."  ,
        text9:"एकनिष्ठ ग्राहकांना पुरस्कृत करा"  ,
        text10:"वापरकर्त्यांना पुन्हा (व्यवहार करण्यासाठी) प्रोत्साहन द्या"  ,
        text11:"स्थळ, खर्चाची पद्धत, लिंग आणि इतर अश्या अनेक घटकांचा विचार करून सुयोग्य ग्राहकवर्ग निवडून आकर्षित करण्यासाठी बनवलेल्या ह्या व्यासपीठावरून आपल्या व्यवसायाची वृद्धी करा."  ,
        acquire:"ताब्यात घ्या / मिळवा"  ,
        text12:"स्थळ, खर्चाची पद्धत, लिंग आणि इतर अश्या अनेक घटकांचा विचार करून सुयोग्य ग्राहकवर्ग निवडून आकर्षित करण्यासाठी बनवलेल्या ह्या व्यासपीठावरून आपल्या व्यवसायाची वृद्धी करा."  ,
        retain:"राखा / राखून ठेवा"  ,
        text13:"ग्राहक ज्याप्रकारे 'आहे-का-लक्षात-ते-दुकान...' असे भावूकपणे म्हणत व्यवसायांच्या आठवणींना उजाळा देतात, तशा प्रकारची ग्राहकनिष्ठेची भावना पुनरुज्जीवित करणे हे व्यवसायांना मॅजिकपिनच्या माध्यमातून शक्य आहे."  ,
        analyse:"विश्लेषण करा"  ,
        text14:"मॅजिकपिनमुळे व्यवसायिकांना त्यांच्या ग्राहकांविषयीची सकृतदर्शनी किरकोळ वाटणारी माहिती, जसे की ग्राहकाचे नाव, त्यांचे आवडते पेय, इ. लक्षात ठेवणे शक्य होते. आपल्या व्यवसायाची नस पकडा: आपल्या पेजला भेट देणाऱ्यांची संख्या, आपल्या व्यवसायातून मिळालेला महसूल, ग्राहकांची माहिती, आणि अजून बरेच काही."  ,
        resetPassword:"पासवर्डची पुन्हा नोंद करा"  ,
        mobileNo:"मोबाईल क्रमांक"  ,
        sendOtp:"ओ.टी.पी. पाठवा"  ,
        validMob:"कृपया वैध मोबाईल क्रमांक भरा"  ,
        notRegistered:"नोंदणी केलेली नाही? शोधा व तुमच्या व्यवसायाची नोंद होण्यासाठी अर्ज करा"  ,
        enterNo:"10 अंकी मोबाईल क्रमांक भरा"  ,
        loginText3:"किंवा तुमच्या प्रमाणित माहितीने लॉगिन करा"  ,
        getStarted:"सुरुवात करा"  ,
        IN:""  ,
        views:"पाहणी संख्या",
        custPref:"आपल्या परिसरातील ग्राहकांचे प्राधान्य",
        topRetailersTooltip:"आपल्या परिसरातील ग्राहकांनी पसंत केलेले शीर्ष विक्रेते",
        restaurantConst:"रेस्टॉरंट"  ,
        beautyConst:"ब्युटी"  ,
        fashionConst:"फॅशन"  ,
        groceryConst:"किराणा"  ,
        fitnessConst:"फिटनेस"  ,
        popularCities:"लोकप्रिय शहरे"  ,
        popularLocalities:"लोकप्रिय परिसर"  ,
        popularRestaurants:"लोकप्रिय रेस्टॉरंट"  ,
        aboutUs:"आमच्याबद्दल"  ,
        voucher:"व्हाउचर्स"  ,
        aroundYou:"आपल्या सभोवताल"  ,
        blog:"ब्लॉग"  ,
        augmentedReality:"संवर्धित वास्तव"  ,
        termsCond:"अटी व शर्ती"  ,
        commStand:"समुदाय मानक"  ,
        privacy:"गोपनीयता"  ,
        sitemap:"साइट मॅप"  ,
        brands:"ब्रँड"  ,
        directory:"निर्देशिका" ,
        categoryConst:"कॅटेगरीज"      
      },
      "telugu" :{
        groceryTopHeading:"50 లక్షల + వినియోగదారులను చేరుకోవడానికి ఆన్‌లైన్ స్టోర్ - మీ కస్టమర్‌లు వారు నివసించే ప్రదేశానికి చేరుకోండి",
        grocerySubHeading:"ప్రపంచం మొత్తం బిగ్ బాస్కెట్, గ్రోఫర్స్, అమెజాన్ మొదలైన వాటిలో ఆన్‌లైన్‌లో కొనుగోలు చేస్తోంది. మీరు ఆన్‌లైన్ స్టోర్‌ను ఎందుకు నిర్మించకూడదు.",
        groceryGetSales:"ఆన్‌లైన్ స్టోర్ మాత్రమే కాకుండా అమ్మకాలను కూడా పొందండి",
        groceryPartnership:"మ్యాజిక్‌పిన్‌తో భాగస్వామ్యం తరువాత, మీ స్టోర్‌కు కస్టమర్లను నడపడానికి మీ స్టోర్ బాగా మార్కెట్ చేయబడుతుంది. అమ్మకాలు స్థిరంగా మారతాయి, ఇది మునుపటి కంటే ఎక్కువ రాబడిని ఇస్తుంది.",
        increaseBusiness:"నా వ్యాపారాన్ని పెంచండి",
        groceryRewards:"మ్యాజిక్‌పిన్ ఆఫర్‌ల ద్వారా మీ కస్టమర్లకు రివార్డ్ ఇవ్వండి",
        getMore:"మరిన్ని సందర్శనలు మరియు ఆర్డర్‌లను పొందండి",
        getMoreSubHeading:"మీ స్టోర్‌లో కొనుగోలు చేసిన ప్రతిసారీ మ్యాజిక్‌పిన్ 20% క్యాష్‌బ్యాక్‌తో వినియోగదారులకు రివార్డ్ చేస్తుంది",
        increaseRepeat:"రిపీట్ కస్టమర్ ను పెంచండి",
        exclusiveVouchers:"మీ స్టోర్‌లోని ప్రత్యేకమైన వోచర్‌ల కారణంగా, కస్టమర్‌లు తిరిగి వస్తూ ఉంటారు.",
        increaseOrder:"ఆర్డర్ విలువను పెంచండి",
        magicPayComboVouchers:"వినియోగదారులు మ్యాజిక్‌పే & కాంబో వోచర్‌లతో ఎక్కువ ఖర్చు చేస్తారు",
        spendOnly:"మీకు అమ్మకాలు వచ్చినప్పుడు మాత్రమే ఖర్చు చేయండి",
        chargeOnly:"వృధా మార్కెటింగ్ ఖర్చులకు నో చెప్పండి - కస్టమర్ కొనుగోలు చేస్తేనే మేము 5% వసూలు చేస్తాము",
        getPower:"ఎక్కువ వ్యాపారం చేసే శక్తిని పొందండి",
        manageStore:"మీ ఆన్‌లైన్ పేజీని నిర్వహించండి, కస్టమర్‌లను నిమగ్నం చేయండి మరియు మీ వ్యాపారాన్ని పెంచుకోవడానికి గొప్ప వ్యాపార ఇన్ సైట్స్ లను పొందండి",
        feedpush:"ఫీడ్ పుష్",
        inviteCustomers:"కస్టమర్లను ఆహ్వానించండి",
        analyseBusiness:"మీ వ్యాపారాన్ని విశ్లేషించండి",
        leaderboard:"లీడర్ బోర్డు",
        stunningAds:"అద్భుతమైన ప్రకటనలను సృష్టించండి మరియు మీ ప్రాంతంలోని కస్టమర్లకు ప్రచారం చేయండి",
        inviteTop:"వ్యక్తిగతీకరించిన ఆఫర్‌తో మీ స్టోర్ ను సందర్శించడానికి అగ్ర స్థానంలో ఉన్న కస్టమర్లను ఆహ్వానించండి",
        trackSales:"ట్రాక్ అమ్మకాలు, మార్కెటింగ్ ఖర్చుపై ఆర్ఓఐ మరియు మీ ప్రాంతంలోని కస్టమర్  ప్రాధాన్యతలను",
        becomeTop:"మీ ప్రాంతంలోని అగ్రశ్రేణి అవుట్‌లెట్‌గా మారడానికి మరింత వ్యాపారం మరియు మార్కెటింగ్ ను పొందండి",
        additionalIncome:"మరియు మీకు నెలకు ₹5000 అదనపు ఆదాయం సంపాదించే అవకాశం",
        sellBrands:"ప్రతి నెలా ఎక్కువ డబ్బు సంపాదించడానికి మ్యాజిక్‌పిన్ భాగస్వామి బ్రాండ్‌లను అమ్మండి. బిల్లులను ప్రింట్ చేయండి మరియు మీ ఖాతాకు నేరుగా బహుమతులు పొందండి",
        topOutlets:"మీ ప్రాంతంలోని అగ్రశ్రేణి దుకాణాలు",
        outletName:"మీ స్టోర్",
        cities:"నగరాలు",
        whyMagicpin:"మీరు మ్యాజిక్‌పిన్‌లో ఎందుకు చేరాలి?",
        groceryClaim:"మీ ఆన్‌లైన్ స్టోర్‌ను తెరవండి",
        alreadyPartner:"ఇప్పటికే భాగస్వామి?",
        topHeading:"స్థానిక వ్యాపారం కొరకు  భారతదేశపు #1 మార్కెటింగ్ యాప్"  ,
        searchBusiness:"మ్యాజిక్‌పిన్‌లో మీ వ్యాపారాన్ని శోధించండి"  ,
        selectCity:"నగరాన్ని ఎంచుకోండి"  ,
        search:"శోధన"  ,
        storeName:"స్టోర్ పేరు"  ,
        incFootfall:"మీ స్టోర్ వద్ద ఫుట్‌ఫాల్ పెంచండి"  ,
        buttonCta1:"ఎక్కువ మంది కస్టమర్లను పొందండి"  ,
        salesHeading:"మ్యాజిక్‌పిన్‌తో హామీ అమ్మకాలను పొందండి"  ,
        reward:"బహుమతులు"  ,
        voucher:"వోచర్లు"  ,
        groupBuy:"గ్రూప్బ్య"  ,
        confirmBooking:"బుకింగ్స్ నిర్ధారించండి"  ,
        magicPay:"మేజిక్ పే"  ,
        joinHeading:"మ్యాజిక్‌పిన్ ఉపయోగించి 1,00,000+ వ్యాపారాలలో చేరండి"  ,
        productBrands:"ఉత్పత్తి బ్రాండ్లు"  ,
        retailBrands:"రిటైలర్ బ్రాండ్స్"  ,
        partnersHeading:"మా భాగస్వాములు ఏమి చెబుతున్నారు"  ,
        buttonCta2:"మీ వ్యాపారాన్ని పెంచుకోండి"  ,
        business1:"5 మిలియన్లు"  ,
        business12:"40 నగరాల్లోని వినియోగదారులు"  ,
        business2:"18-35"  ,
        business22:"ప్రధాన వినియోగదారు వయస్సు"  ,
        business3:"రూ 5000 +"  ,
        business32:"సగటు వినియోగదారుడు ఖర్చు/నెల"  ,
        business4:"30 నిమిషాలు +"  ,
        business42:"సగటు వినియోగదారు చర్య / రోజు"  ,
        rewardsHeading:"ప్రతి కొనుగోలులో మీ కస్టమర్ల కోసం క్లాస్ లాయల్టీ పాయింట్లలో ఉత్తమమైనది"  ,
        vouchersHeading:"మ్యాజిక్‌పిన్‌లో రోజూ 30 లక్షలు + విలువైన వోచర్‌లు అమ్ముతారు. ఇప్పుడు మీ స్వంత వోచర్‌లను సృష్టించండి!"  ,
        groupBuyHeading:"1 కన్నా మంచిది ఏమిటి? 5, 10, 15? ఈ రోజు మిమ్మల్ని సందర్శించడానికి స్నేహితుల బృందాన్ని పొందండి"  ,
        magicpayHeading:"ఒకే క్లిక్‌తో నగదు రహితంగా వెళ్లండి. ఆన్‌లైన్ చెల్లింపు కోసం ఒక స్టాప్ అనువర్తనం"  ,
        confirmBookingHeading:"మీ స్థానిక కస్టమర్ల నుండి ఎటువంటి బుకింగ్‌లను కోల్పోకండి"  ,
        testimonial1:"మాలిక్యుల్ మరియు మ్యాజిక్పిన్ యొక్క విజయవంతమైన కథ చేతికి వెళుతుంది. కొత్త కస్టమర్ల సముపార్జన ఫలితంగా మొత్తం అమ్మకాలు పెరిగాయి, మా పెరుగుదలకు మ్యాజిక్‌పిన్ చాలా దోహదపడింది."  ,
        testimonial2:"మ్యాజిక్‌పిన్‌తో మా ప్రయాణం ఒక సంవత్సరం క్రితం ప్రారంభమైంది. మ్యాజిక్‌పిన్ అదనపు ఫుట్‌ఫాల్‌తో మాకు చాలా సహాయపడింది మరియు పాతవి తిరిగి వచ్చేలా చూసుకున్నారు. కస్టమర్లు క్యాష్‌బ్యాక్ సంపాదించడానికి ఎదురుచూస్తున్నారు, వారు షాపింగ్ చేయడానికి మరింత ఖర్చు చేయవచ్చు"  ,
        testimonial3:"క్రొత్త కస్టమర్లను సంపాదించడానికి మాకు సహాయం చేయడమే కాకుండా, మ్యాజిక్‌పిన్ పునరావృత ప్రాతిపదికన కస్టమర్‌లతో సన్నిహితంగా ఉండేలా చేస్తుంది. గొప్ప క్యాష్‌బ్యాక్‌లు మరియు వోచర్‌లతో, మ్యాజిక్‌పిన్ గొప్ప సోషల్ మీడియా వేదిక"  ,
        competitorsHeading:"మీ పోటీ ఏమి చేస్తుందో తెలుసుకోండి"  ,
        competitors1:"క్రొత్త కస్టమర్లను సంపాదించేటప్పుడు మేము ఇప్పటికే ఉన్న కస్టమర్ బేస్ను మ్యాజిక్పిన్తో నిర్వహించగలిగాము."  ,
        competitors2:"మేజిక్‌పిన్‌పై మేము పొందుతున్న సమీక్షలు చాలా భిన్నంగా ఉంటాయి, నేను కూడా తిరిగి వెళ్లి నా దుకాణానికి సమీక్షకుడిని ఆహ్వానించగలను."  ,
        competitors3:"మేము వెతుకుతున్న కస్టమర్‌లు మాకు తెలుసు, మ్యాజిక్‌పిన్ వారిని కనుగొనడంలో మాకు సహాయపడింది."  ,
        visitedCustomers:"సందర్శించిన వినియోగదారులు"  ,
        txnsDriven:"లావాదేవీ నడిచేది"  ,
        buttonCta3:"మీ పోటీదారులను చూడండి"  ,
        heading2:"మ్యాజిక్‌పిన్‌లో మీ ఆన్‌లైన్ & ఆఫ్‌లైన్ దృశ్యమానతను నియంత్రించండి"  ,
        yourRank:"మీ ర్యాంక్ మధ్య"  ,
        inMerchants:"లో"  ,
        merchants:"వ్యాపారులు"  ,
        magicpin: "మ్యాజిక్‌పిన్‌ల",
        influence: "మీ ప్రాంతంలో ప్రభావం",
        magicpinInfluence:"మీ ప్రాంతంలో మ్యాజిక్‌పిన్‌ల ప్రభావం"  ,
        monthlyTxns:"నెలవారీ లావాదేవీలు"  ,
        customers:"వినియోగదారులు"  ,
        monthlySale:"నెలవారీ అమ్మకం"  ,
        wrongDetails:"తప్పు వివరాలు"  ,
        username:"యూజర్ పేరు"  ,
        password:"పాస్వర్డ్"  ,
        forgotPassword:"పాస్వర్డ్ మర్చిపోయారా"  ,
        login:"లాగిన్"  ,
        loginOtp:"ఓటీపీ ద్వారా లాగిన్"  ,
        popupHeading:"మీ వ్యాపారాన్ని పెంచుకోవడానికి మ్యాజిక్‌పిన్‌తో భాగస్వామి. ఇప్పుడు ఇది ఉచితం!"  ,
        popupHeading2:"మీ వ్యాపారాన్ని పెంచుకోవడానికి మ్యాజిక్‌పిన్‌తో భాగస్వామి.",
        popupSubHeading:"కొనసాగించడానికి దయచేసి మీ వివరాలను నమోదు చేయండి"  ,
        ruppes:"200"  ,
        getWalletHeading:"ఇప్పుడు పొందండి"  ,
        getWalletHeading2:"విజయవంతంగా వెరిఫికేషన్ మీద మీ వాలెట్ లో తక్షణం."  ,
        oneTimeFee:"వన్ టైమ్ లిస్టింగ్ ఫీజు ₹",
        nameConst:"పేరు"  ,
        emailConst:"ఇమెయిల్ ఐడి"  ,
        phoneConst:"ఫోన్ నంబర్"  ,
        outletNameConst:"అవుట్లెట్ పేరు"  ,
        cityConst:"నగరం"  ,
        localityConst:"ప్రాంతం"  ,
        signUpConst:"సైన్ అప్"  ,
        outletsConst:"సంస్థలు"  ,
        txnsConst:"లావాదేవీలు"  ,
        salesConst:"అమ్మకాలు"  ,
        customerReviewConst:"కస్టమర్ సమీక్షలు"  ,
        viewsMagicpinConst:"మ్యాజిక్‌పిన్‌పై అభిప్రాయాలు"  ,
        yourRatingConst:"మీ రేటింగ్"  ,
        notBusiness:"మీ వ్యాపారం కాదా?"  ,
        clickHere:"ఇక్కడ క్లిక్ చేయండి"  ,
        testimonial4:"మేము మొదటి రోజు నుండి మ్యాజిక్‌పిన్‌తో భాగస్వామ్యం కలిగి ఉన్నాము మరియు ఇది మాకు సరైన అనువర్తనం. ఇది మన కోసం మనం నిర్మించాలనుకుంటున్న అన్ని లక్షణాలను ఇవ్వడానికి ఇది మాకు సహాయపడింది."  ,
        testimonial5:"బీర్‌ను ఇష్టపడే వ్యక్తులకు క్యాటరింగ్ చేసే సంతోషకరమైన ప్రదేశం, మ్యాజిక్‌పిన్ కొత్త కస్టమర్లను బోర్డులోకి తీసుకురావడానికి మరియు పాత కస్టమర్లను నిలుపుకోవడంలో మాకు సహాయపడింది. కన్సల్టెంట్‌గా, అతను ప్రతి ఒక్కరికీ అనువర్తనాన్ని సిఫారసు చేస్తాడు."  ,
        testimonial6:"ఇది మ్యాజిక్‌పిన్‌తో 3-4 నెలలు అయ్యింది మరియు ఇది ఫలవంతమైన ప్రయాణం. కస్టమర్‌లను ఇష్టపడటం గురించి మాకు దగ్గరి అవగాహన ఉన్న డేటాను విశ్లేషించగలము. నేను ఈ అనువర్తనాన్ని అందరికీ సిఫార్సు చేస్తున్నాను."  ,
        buttonCta4:"ఎక్కువ మంది వినియోగదారులను వీక్షించండి"  ,
        restaurantConst:"రెస్టారెంట్"  ,
        beautyConst:"అందం"  ,
        fashionConst:"ఫ్యాషన్"  ,
        fitnessConst:"ఫిట్నెస్"  ,
        groceryConst:"కిరాణా"  ,
        promotionsHeading:"మీ ప్రాంతంలో ప్రత్యక్ష ప్రమోషన్లు నడుస్తున్నాయి"  ,
        whatIsmagicpin:"మ్యాజిక్పిన్ అంటే ఏమిటి"  ,
        heading3:"మేము మీ వ్యాపారాన్ని ఎలా పెంచుకోవచ్చు"  ,
        welcomeConst:"స్వాగతం"  ,
        couldNotFind:"మీ అవుట్‌లెట్‌ను కనుగొనలేకపోయారా?"  ,
        clickHereConst:"ఇక్కడ క్లిక్ చేయండి, మేము మిమ్మల్ని ఇమెయిల్ ద్వారా సంప్రదిస్తాము."  ,
        partnerFree:"భాగస్వామి ఉచితంగా"  ,
        ratedBy:"ద్వారా రేట్ చేయబడింది"  ,
        spent:"ఖర్చు"  ,
        seeTop:"టాప్ 10 చూడండి"  ,
        iName:"చెల్లని పేరు"  ,
        iEmail:"చెల్లని ఇమెయిల్"  ,
        iPhone:"చెల్లని ఫోన్"  ,
        iOutlet:"చెల్లని అవుట్‌లెట్ పేరు"  ,
        iCity:"చెల్లని నగరం"  ,
        iLocality:"చెల్లని ప్రాంతం"  ,
        eligibleConst:"మీరు దీనికి అర్హులు"  ,
        referralConst:"రూ. 100 రెఫరల్ బహుమతి."  ,
        foodConst:"ఆహారం"  ,
        followers:"అనుచరులు"  ,
        likes:"ఇష్టపడ్డారు"  ,
        posts:"పోస్టులు"  ,
        partner:"భాగస్వామి"  ,
        owner:"యజమాని"  ,
        founder:"వ్యవస్థాపకుడు"  ,
        operations:"కార్యకలాపాలు"  ,
        hey:"హే"  ,
        findStore:"మీ దుకాణాన్ని కనుగొనండి"  ,
        loginText:"మళ్ళీ ప్రారంభించడానికి లాగిన్ అవ్వండి!"  ,
        needHelp:"సహాయం కావాలా?"  ,
        callUs:"75101 75101 వద్ద మాకు కాల్ చేయండి (ఉదయం 10 నుంచి 8 గంటల వరకు)"  ,
        forgotPassword:"పాస్‌వర్డ్ మర్చిపోయారా?"  ,
        loginText2:"లేదా ఓటీపీ తో లాగిన్ అవ్వండి"  ,
        aboutus:"మా గురించి"  ,
        text1:"మ్యాజిక్‌పిన్ అనేది ఒక ప్రాంతంలోని కస్టమర్‌లు, కార్యకలాపాలు మరియు వ్యాపారులను బంధించే సంఘం. ఇది బ్రాండ్‌లు వారి సంభావ్య మరియు ఇప్పటికే ఉన్న కస్టమర్‌లతో కనెక్ట్ అవ్వడానికి మరియు పరస్పర చర్య చేయడానికి అనుమతిస్తుంది. గత సంవత్సరం ఉత్తమ స్థానిక ఆవిష్కరణ అనువర్తనంగా ఓటు వేయబడింది, ఇది మీ వ్యాపారాన్ని పెంచడానికి మరియు మార్కెట్లో మీ దృశ్యమానతను పెంచడానికి మిమ్మల్ని అనుమతిస్తుంది."  ,
        text2:"వ్యాపారాల కోసం కొత్త కస్టమర్లను పొందండి"  ,
        text3:"ఈ మాటను విస్తరింపచేయు"  ,
        text4:"క్రొత్త కస్టమర్లను ప్రోత్సహించండి"  ,
        text5:"ఇప్పటికే ఉన్న వ్యాపారాల కోసం డ్రైవ్ వినియోగం"  ,
        text6:"అమ్ముడుపోని జాబితాను వదిలించుకోండి"  ,
        text7:"లీన్ పీరియడ్స్‌లో వాడకాన్ని మెరుగుపరచండి"  ,
        text8:"ఇప్పటికే ఉన్న వ్యాపారాల కోసం పునరావృతం చేయండి"  ,
        text9:"నమ్మకమైన వినియోగదారులకు బహుమతి ఇవండీ"  ,
        text10:"పునరావృతం చేయడానికి వినియోగదారులను ప్రోత్సహించండి"  ,
        text11:"స్థానం, ఖర్చు విధానం, లింగం మరియు మరిన్ని ఆధారంగా సరైన ప్రేక్షకులను ఆకర్షించడానికి రూపొందించిన ప్లాట్‌ఫారమ్‌తో మీ వ్యాపారాన్ని ప్రోత్సహించండి."  ,
        acquire:"కొనుగోలు"  ,
        text12:"స్థానం, ఖర్చు విధానం, లింగం మరియు మరిన్ని ఆధారంగా సరైన ప్రేక్షకులను ఆకర్షించడానికి రూపొందించిన ప్లాట్‌ఫారమ్‌తో మీ వ్యాపారాన్ని ప్రోత్సహించండి."  ,
        retain:"అలాగే ఉంచుకోండి"  ,
        text13:"వినియోగదారులు వెతుకుతున్న ఆ వ్యామోహ, గుర్తుంచుకో-మీ-పేరు విధేయతను వ్యాపారాలు తిరిగి తీసుకురావడానికి మ్యాజిక్‌పిన్ సాధ్యపడుతుంది."  ,
        analyse:"విశ్లేషణ"  ,
        text14:"మ్యాజిక్‌పిన్ వ్యాపారాలు వారి పేర్ల నుండి తమ అభిమాన పానీయాల వరకు కస్టమర్ల గురించి చిన్న వివరాలను గుర్తుంచుకోవడం సాధ్యం చేస్తుంది. మీ వ్యాపారం యొక్క నాడిని అనుభవించండి: పేజీ వీక్షణలు, సంపాదించిన ఆదాయం, కస్టమర్ల డేటా మరియు మరిన్ని."  ,
        resetPassword:"పాస్వర్డ్ మార్చుకోండి"  ,
        mobileNo:"మొబైల్ నంబర్"  ,
        sendOtp:"ఓటీపీ పంపండి"  ,
        validMob:"దయచేసి చెల్లుబాటు అయ్యే మొబైల్ నంబర్‌ను నమోదు చేయండి"  ,
        notRegistered:"నమోదు కాలేదు? మీ జాబితాను కనుగొని దావా వేయండి."  ,
        enterNo:"10 అంకెల మొబైల్ నెంబరును నమోదు చేయండి"  ,
        loginText3:"లేదా ఆధారాలతో లాగిన్ అవ్వండి"  ,
        getStarted:"మొదలు పెట్టు"  ,
        IN:""  ,
        views:"వీక్షణలు"  ,
        custPref:"మీ ప్రాంతంలో వినియోగదారుల ప్రాధాన్యత"  ,
        topRetailersTooltip:"మీ ప్రాంతంలోని కస్టమర్‌లు ఇష్టపడే అగ్ర చిల్లర వ్యాపారులు"  ,
        restaurantConst:"రెస్టారెంట్"  ,
        beautyConst:"అందం"  ,
        fashionConst:"ఫ్యాషన్"  ,
        groceryConst:"కిరాణా"  ,
        fitnessConst:"ఫిట్నెస్"  ,
        popularCities:"ప్రసిద్ధ నగరాలు"  ,
        popularLocalities:"జనాదరణ పొందిన ప్రాంతాలు"  ,
        popularRestaurants:"ప్రసిద్ధ రెస్టారెంట్లు"  ,
        aboutUs:"మా గురించి"  ,
        voucher:"వోచర్లు"  ,
        aroundYou:"మీ చుట్టూ"  ,
        blog:"బ్లాగ్"  ,
        augmentedReality:"అనుబంధ వాస్తవికత"  ,
        termsCond:"నిబంధనలు మరియు షరతులు"  ,
        commStand:"కమ్యూనిటీ ప్రమాణం"  ,
        privacy:"గోప్యతా"  ,
        sitemap:"సైట్ మ్యాప్"  ,
        brands:"బ్రాండ్"  ,
        directory:"డైరెక్టరీ" ,
        categoryConst:"కేటగిరీలు"
      },
      "kannada" :{
        groceryTopHeading:"50 ಲ್ಯಾಕ್ + ಗ್ರಾಹಕರನ್ನು ತಲುಪಲು ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ - ನಿಮ್ಮ ಗ್ರಾಹಕರು ವಾಸಿಸುವ ಸ್ಥಳವನ್ನು ತಲುಪಿ",
        grocerySubHeading:"ಇಡೀ ಜಗತ್ತು ಬಿಗ್ ಬಾಸ್ಕೆಟ್, ಗ್ರೋಫರ್ಸ್, ಅಮೆಜಾನ್ ಇತ್ಯಾದಿಗಳಲ್ಲಿ ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಖರೀದಿಸುತ್ತಿದೆ. ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯನ್ನು ನೀವೇ ಏಕೆ ನಿರ್ಮಿಸಬಾರದು.",
        groceryGetSales:"ಓಂದು ಆನ್‌ಲೈನ್ ಅಂಗಡಿಯೇ  ಅಲ್ಲದೆ ಮಾರಾಟವನ್ನೂ ಮಾಡಿರಿ",
        groceryPartnership:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ಜೊತೆಗಿನ ಪಾಲುದಾರಿಕೆಯ ನಂತರ, ಗ್ರಾಹಕರನ್ನು ಆಕರ್ಶಿಸಲು  ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಪರಿವರ್ತನಾರೀತಿಯಲ್ಲಿ  ಮಾರಾಟ ಸ್ಥಳವನ್ನಾಗಿ ಮಾಡಲಾಗುತ್ತದೆ. ಮೊದಲಿಗಿಂತ ಹೆಚ್ಚಿನ ಆದಾಯವನ್ನು ಖಚಿತಪಡಿಸಿ ಮಾರಾಟವು ಸ್ಥಿರವಾಗಿರಿಸುತ್ತದೆ.",
        increaseBusiness:"ನನ್ನ ವ್ಯವಹಾರವನ್ನು ಹೆಚ್ಚಿಸಿ",
        groceryRewards:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ಕೊಡುಗೆಗಳ ಮೂಲಕ ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ಬಹುಮಾನ ನೀಡಿ",
        getMore:"ಹೆಚ್ಚಿನ ಭೇಟಿಗಳನ್ನು ಮತ್ತು ಆದೇಶಗಳನ್ನು ಪಡೆಯಿರಿ",
        getMoreSubHeading:"ಗ್ರಾಹಕರು ಪ್ರತಿ ಬಾರಿ ನಿಮ್ಮನ್ನು ಭೇಟಿ ಮಾಡಿದಾಗ ಮ್ಯಾಜಿಕ್ಪಿನ್  20% ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್ ನೀಡುತ್ತದೆ",
        increaseRepeat:"ಪುನರಾವರ್ತಿತ ಗ್ರಾಹಕರನ್ನು ಹೆಚ್ಚಿಸಿ",
        exclusiveVouchers:"ನಿಮ್ಮ ಅಂಗಡಿಗಾಗಿ ವಿಶೇಷ ಸಲ್ಲಿಕೆ ಚೀಟಿಗಳೊಂದಿಗೆ, ಗ್ರಾಹಕರು ಪುನಹ ಬರುತ್ತಾರೆ.",
        increaseOrder:"ಆದೇಶ ಮೌಲ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿ",
        magicPayComboVouchers:"ಮ್ಯಾಜಿಕ್ಪೇ ಮತ್ತು ಕಾಂಬೊ ವಿಶೇಷ ಸಲ್ಲಿಕೆ ಚೀಟಿಗಳೊಂದಿಗೆ ಹೆಚ್ಚು ಖರ್ಚು ಮಾಡಲು ಗ್ರಾಹಕರನ್ನು ಪಡೆಯುವಿರಿ",
        spendOnly:"ನೀವು ಮಾರಾಟವನ್ನು ಮಾಡಿದಾಗ ಮಾತ್ರ ಖರ್ಚು ಮಾಡಿ",
        chargeOnly:"ವ್ಯರ್ಥವಾದ ಮಾರ್ಕೆಟಿಂಗ್ ಖರ್ಚಿಗೆ ಬೇಡ ಎಂದು ಹೇಳಿ - ಗ್ರಾಹಕರು ಭೇಟಿ ನೀಡಿದರೆ ಮಾತ್ರ ನಾವು 5% ಶುಲ್ಕ ವಿಧಿಸುತ್ತೇವೆ",
        getPower:"ಹೆಚ್ಚಿನದನ್ನು ಮಾಡಲು ಶಕ್ತಿಯನ್ನು ಪಡೆಯಿರಿ",
        manageStore:"ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಪುಟವನ್ನು ನಿರ್ವಹಿಸಿ, ಗ್ರಾಹಕರನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಲು ಶ್ರೀಮಂತ ವ್ಯವಹಾರ ಒಳನೋಟಗಳನ್ನು ಪಡೆಯಿರಿ",
        feedpush:"ದಿನಸಿವ್ಯಾಪಾರಿ",
        inviteCustomers:"ಗ್ರಾಹಕರನ್ನು ಆಹ್ವಾನಿಸಿ",
        analyseBusiness:"ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ವಿಶ್ಲೇಷಿಸಿ",
        leaderboard:"ಲೀಡರ್ಬೋರ್ಡ್",
        stunningAds:"ಬೆರಗುಗೊಳಿಸುವ ಜಾಹೀರಾತುಗಳನ್ನು ರಚಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಪ್ರದೇಶದ ಎಲ್ಲ ಗ್ರಾಹಕರಿಗೆ ಪ್ರಚಾರ ಮಾಡಿ",
        inviteTop:"ವೈಯಕ್ತಿಕಗೊಳಿಸಿದ ಕೊಡುಗೆಯೊಂದಿಗೆ ನಿಮ್ಮ ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡಲು ಉನ್ನತ ಗ್ರಾಹಕರನ್ನು ಆಹ್ವಾನಿಸಿ",
        trackSales:"ಟ್ರ್ಯಾಕ್ ಮಾರಾಟ, ಮಾರ್ಕೆಟಿಂಗ್ ಖರ್ಚಿನ ಆರ್‌ಒಐ ಮತ್ತು ನಿಮ್ಮ ಪ್ರದೇಶದ ಗ್ರಾಹಕರ ಆದ್ಯತೆಗಳು",
        becomeTop:"ನಿಮ್ಮ ಪ್ರದೇಶದ ಉನ್ನತ ಮಳಿಗೇ ಆಗಲು ಹೆಚ್ಚಿನ ವ್ಯಾಪಾರ ಮತ್ತು ಮಾರ್ಕೆಟಿಂಗ್ ಪಡೆಯಿರಿ",
        additionalIncome:"ಮತ್ತು ತಿಂಗಳಿಗೆ ₹5000 ಹೆಚ್ಚುವರಿ ಆದಾಯವನ್ನು ಗಳಿಸುವ ಅವಕಾಶ",
        sellBrands:"ಪ್ರತಿ ತಿಂಗಳು ಹೆಚ್ಚಿನ ಹಣವನ್ನು ಗಳಿಸಲು ಮ್ಯಾಜಿಕ್ಪಿನ್ ಪಾಲುದಾರ ಬ್ರ್ಯಾಂಡ್‌ಗಳನ್ನು ಮಾರಾಟ ಮಾಡಿ. ಬಿಲ್‌ಗಳನ್ನು ಮುದ್ರಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಗೆ ನೇರವಾಗಿ ಪ್ರತಿಫಲಗಳನ್ನು ಪಡೆಯಿರಿ",
        topOutlets:"ಉನ್ನತ ಮಳಿಗೆಗಳು",
        outletName:"ನಿಮ್ಮ ಅಂಗಡಿ",
        cities:"ನಗರಗಳು",
        whyMagicpin:"ನೀವು ಮ್ಯಾಜಿಕ್ಪಿನ್ಗೆ ಏಕೆ ಸೇರಬೇಕು?",
        groceryClaim:"ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಸ್ಟೋರ್ ತೆರೆಯಿರಿ",
        alreadyPartner:"ಈಗಾಗಲೇ ಪಾಲುದಾರ?",
        topHeading:"ಸ್ಥಳೀಯ ವ್ಯವಹಾರಗಳಿಗಾಗಿ ಭಾರತದ # 1 ಮಾರ್ಕೆಟಿಂಗ್ ಅಪ್ಲಿಕೇಶನ್"  ,
        searchBusiness:"ಮ್ಯಾಜಿಕ್ ಪಿನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಹುಡುಕಿ"  ,
        selectCity:"ನಗರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ"  ,
        search:"ಹುಡುಕಿ"  ,
        storeName:"ಮಳಿಗೆಯ ಹೆಸರು"  ,
        incFootfall:"ನಿಮ್ಮ ಅಂಗಡಿಯಲ್ಲಿ ಫುಟ್ಫಾಲ್  ಹೆಚ್ಚಿಸಿ"  ,
        buttonCta1:"ಹೆಚ್ಚಿನ ಗ್ರಾಹಕರನ್ನು ಪಡೆಯಿರಿ"  ,
        salesHeading:"ಮ್ಯಾಜಿಕ್ಪಿನ್‌ನೊಂದಿಗೆ ಖಚಿತವಾದ ಮಾರಾಟವನ್ನು ಪಡೆಯಿರಿ"  ,
        reward:"ರಿವಾರ್ಡ್‍ಗಳು"  ,
        voucher:"ವೋಚರಗಳು"  ,
        groupBuy:"ಸಮೂಹ ಖರೀದಿ"  ,
        confirmBooking:"ಬುಕಿಂಗ್  ಧೃಢಪಡಿಸಿ"  ,
        magicPay:"ಮ್ಯಾಜಿಕ್ ಪೇ"  ,
        joinHeading:"ಮ್ಯಾಜಿಕ್ ಪಿನ್ ಬಳಸಿ 1,00,000+ ವ್ಯವಹಾರಗಳಿಗೆ ಸೇರಿ"  ,
        productBrands:"ಪ್ರೊಡಕ್ಟ್ ಬ್ರಾಂಡ್‍ಗಳು"  ,
        retailBrands:"ರಿಟೇಲರ್ ಬ್ರಾಂಡ್‍ಗಳು"  ,
        partnersHeading:"ನಮ್ಮ ಪಾಲುದಾರರು ಏನು ಹೇಳುತ್ತಿದ್ದಾರೆ"  ,
        buttonCta2:"ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಿಕೊಳ್ಳಿ"  ,
        business1:"5 ಮಿಲಿಯನ್"  ,
        business12:"40 ನಗರಗಳಲ್ಲಿ ಬಳಕೆದಾರರು"  ,
        business2:"18-35"  ,
        business22:"ಪ್ರಮುಖ ಬಳಕೆದಾರರ ವಯಸ್ಸಿನ ಗುಂಪು"  ,
        business3:"ರೂ.  5000+"  ,
        business32:"ಸರಾಸರಿ ಬಳಕೆದಾರರ ವೆಚ್ಚ / ತಿಂಗಳು"  ,
        business4:"30 ನಿಮಿಷಗಳು+"  ,
        business42:"ಸರಾಸರಿ ಬಳಕೆದಾರ ಸಕ್ರಿಯ / ದಿನ"  ,
        rewardsHeading:"ಪ್ರತಿ ಖರೀದಿಯಲ್ಲಿ ನಿಮ್ಮ ಗ್ರಾಹಕರಿಗೆ ವರ್ಗ ಲಾಯಲ್ಟಿ ಪಾಯಿಂಟ್‌ಗಳಲ್ಲಿ ಉತ್ತಮವಾಗಿದೆ"  ,
        vouchersHeading:"ಮ್ಯಾಜಿಕ್‌ಪಿನ್‌ನಲ್ಲಿ ಪ್ರತಿದಿನ 30 ಲಕ್ಷ + ಮೌಲ್ಯದ ಚೀಟಿಗಳನ್ನು ಮಾರಾಟ ಮಾಡಲಾಗುತ್ತದೆ. ಈಗ ನಿಮ್ಮ ಸ್ವಂತ ಚೀಟಿಗಳನ್ನು ರಚಿಸಿ!"  ,
        groupBuyHeading:"1 ಗಿಂತ ಯಾವುದು ಉತ್ತಮ? 5, 10, 15? ಇಂದು ನಿಮ್ಮನ್ನು ಭೇಟಿ ಮಾಡಲು ಸ್ನೇಹಿತರ ಗುಂಪನ್ನು ಪಡೆಯಿರಿ"  ,
        magicpayHeading:"ಒಂದೇ ಕ್ಲಿಕ್‌ನಲ್ಲಿ  ಕ್ಯಾಶಲೆಸ್ ಹೋಗಿ. ಆನ್‌ಲೈನ್ ಪಾವತಿಗಾಗಿ ಒಂದು ಸ್ಟಾಪ್ ಅಪ್ಲಿಕೇಶನ್"  ,
        confirmBookingHeading:"ನಿಮ್ಮ ಸ್ಥಳೀಯ ಗ್ರಾಹಕರಿಂದ ಯಾವುದೇ ಬುಕಿಂಗ್ ಅನ್ನು ಎಂದಿಗೂ ಕಳೆದುಕೊಳ್ಳಬೇಡಿ"  ,
        testimonial1:"ಮಾಲಿಕ್ಯೂಲ್  ಮತ್ತು ಮ್ಯಾಜಿಕ್‌ಪಿನ್‌ನ ಯಶಸ್ಸಿನ ಕಥೆ ಒಂದರ ಜೊತೆ ಒಂದು ಸಾಗುತ್ತದೆ. ಹೊಸ ಗ್ರಾಹಕರ ಸ್ವಾಧೀನದ ಪರಿಣಾಮವಾಗಿ ಒಟ್ಟಾರೆ ಮಾರಾಟವು ಹೆಚ್ಚಾಗುತ್ತದೆ, ಮ್ಯಾಜಿಕ್ಪಿನ್ ನಮ್ಮ ಬೆಳವಣಿಗೆಗೆ ಸಾಕಷ್ಟು ಕೊಡುಗೆ ನೀಡಿದೆ."  ,
        testimonial2:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ಜೊತೆಗಿನ ನಮ್ಮ ಪ್ರಯಾಣವು ಒಂದು ವರ್ಷದ ಹಿಂದೆಯೇ ಪ್ರಾರಂಭವಾಯಿತು. ಮ್ಯಾಜಿಕ್ಪಿನ್ ಹೆಚ್ಚುವರಿ ಪಾದಚಾರಿಗಳೊಂದಿಗೆ ನಮಗೆ ಸಾಕಷ್ಟು ಸಹಾಯ ಮಾಡಿದೆ ಮತ್ತು ಹಳೆಯವುಗಳು ಹಿಂತಿರುಗುವಂತೆ ನೋಡಿಕೊಂಡಿದೆ. ಗ್ರಾಹಕರು ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್ ಗಳಿಸಲು ಎದುರು ನೋಡುತ್ತಾರೆ, ಅದನ್ನು ಅವರು ಶಾಪಿಂಗ್ ಮಾಡಲು ಮತ್ತಷ್ಟು ಖರ್ಚು ಮಾಡಬಹುದು"  ,
        testimonial3:"ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುವುದು ಮಾತ್ರವಲ್ಲ, ಆದರೆ ಪುನರಾವರ್ತಿತ ಆಧಾರದ ಮೇಲೆ ಗ್ರಾಹಕರೊಂದಿಗೆ ತೊಡಗಿಸಿಕೊಳ್ಳುವುದನ್ನು ಮ್ಯಾಜಿಕ್ಪಿನ್ ಖಚಿತಪಡಿಸುತ್ತದೆ. ಉತ್ತಮ ಕ್ಯಾಶ್‌ಬ್ಯಾಕ್ ಮತ್ತು ಚೀಟಿಗಳೊಂದಿಗೆ, ಮ್ಯಾಜಿಕ್ಪಿನ್ ಉತ್ತಮ ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ವೇದಿಕೆಯಾಗಿದೆ"  ,
        competitorsHeading:"ನಿಮ್ಮ ಸ್ಪರ್ಧಿಗಳು  ಏನು ಮಾಡುತ್ತಿದ್ದಾರೆ  ಎಂದು ತಿಳಿಯಿರಿ"  ,
        competitors1:"ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಪಡೆದುಕೊಳ್ಳುವಾಗ ನಾವು ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರ ನೆಲೆಯನ್ನು ಮ್ಯಾಜಿಕ್ಪಿನ್‌ನೊಂದಿಗೆ ಕಾಪಾಡಿಕೊಳ್ಳಲು ಸಮರ್ಥರಾಗಿದ್ದೇವೆ."  ,
        competitors2:"ಮ್ಯಾಜಿಕ್‌ಪಿನ್‌ನಲ್ಲಿ ನಾವು ಪಡೆಯುತ್ತಿರುವ ವಿಮರ್ಶೆಗಳು ತುಂಬಾ ವಿಭಿನ್ನವಾಗಿವೆ, ನಾನು ಹಿಂತಿರುಗಿ ವಿಮರ್ಶಕನನ್ನು ನನ್ನ ಅಂಗಡಿಗೆ ಆಹ್ವಾನಿಸಬಹುದು."  ,
        competitors3:"ನಾವು ಯಾವ ಗ್ರಾಹಕರನ್ನು ಹುಡುಕುತ್ತಿದ್ದೇವೆ ಎಂದು ನಮಗೆ ತಿಳಿದಿತ್ತು, ಮ್ಯಾಜಿಕ್ಪಿನ್ ಅವರನ್ನು ಹುಡುಕಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಿತು."  ,
        visitedCustomers:"ಭೇಟಿ ನೀಡಿದ ಗ್ರಾಹಕರು"  ,
        txnsDriven:"ವಹಿವಾಟು ನಡೆಸಲಾಗುತ್ತದೆ"  ,
        buttonCta3:"ನಿಮ್ಮ ಸ್ಪರ್ಧಿಗಳನ್ನು ನೋಡಿ"  ,
        heading2:"ಮ್ಯಾಜಿಕ್‌ಪಿನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಮತ್ತು ಆಫ್‌ಲೈನ್ ಗೋಚರತೆಯನ್ನು ನಿಯಂತ್ರಿಸಿ"  ,
        yourRank:"ಇದರ ನಡುವೆ  ನಿಮ್ಮ ಶ್ರೇಣಿ"  ,
        inMerchants:"ಒಳಗೆ"  ,
        merchants:"ವ್ಯಾಪಾರಿಗಳು"  ,
        magicpin:"ಮ್ಯಾಜಿಕ್ಪಿನ್",
        influence:"ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಪ್ರಭಾವ",
        magicpinInfluence:"ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಮ್ಯಾಜಿಕ್ಪಿನ್ ಪ್ರಭಾವ"  ,
        monthlyTxns:"ಮಾಸಿಕ ವ್ಯವಹಾರಗಳು"  ,
        customers:"ಗ್ರಾಹಕರು"  ,
        monthlySale:"ಮಾಸಿಕ ಮಾರಾಟ"  ,
        wrongDetails:"ತಪ್ಪು ವಿವರಗಳು"  ,
        username:"ಯೂಸರ್ ನೇಮ್"  ,
        password:"ಪಾಸವರ್ಡ್"  ,
        forgotPassword:"ಪಾಸವರ್ಡ್ ಮರೆತಿರಾ?"  ,
        login:"ಲಾಗಿನ್"  ,
        loginOtp:"ಓಟಿಪಿ  ಮೂಲಕ ಲಾಗಿನ್ ಮಾಡಿ"  ,
        popupHeading:"ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಲು ಮ್ಯಾಜಿಕ್ಪಿನ್‌ನೊಂದಿಗೆ ಪಾಲುದಾರ ಆಗಿ. ಇದೀಗ ಇದು ಉಚಿತವಾಗಿದೆ!"  ,
        popupHeading2:"ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಬೆಳೆಸಲು ಮ್ಯಾಜಿಕ್ಪಿನ್‌ನೊಂದಿಗೆ ಪಾಲುದಾರ ಆಗಿ.",
        popupSubHeading:"ಮುಂದುವರಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ"  ,
        ruppes:"200"  ,
        getWalletHeading:"ಈಗ ಪಡೆಯಿರಿ"  ,
        getWalletHeading2:"ಯಶಸ್ವಿ ಪರಿಶೀಲನೆಯ ನಂತರ ತಕ್ಷಣ ನಿಮ್ಮ ವ್ಯಾಲೆಟ್‍ನಲ್ಲಿ"  ,
        oneTimeFee:"ಒಂದು ಬಾರಿ ಪಟ್ಟಿ ಶುಲ್ಕಗಳು ₹",
        nameConst:"ಹೆಸರು"  ,
        emailConst:"ಇ ಮೇಲ್ ಐಡಿ"  ,
        phoneConst:"ಫೋನ್ ನಂಬರ್"  ,
        outletNameConst:"ಔಟಲೆಟ್ ಹೆಸರು"  ,
        cityConst:"ನಗರ"  ,
        localityConst:"ಸ್ಥಳ"  ,
        signUpConst:"ಸೈನ್ ಅಪ್"  ,
        outletsConst:"ಔಟಲೆಟ್‍ಗಳು"  ,
        txnsConst:"ವ್ಯವಹಾರಗಳು"  ,
        salesConst:"ಮಾರಾಟ"  ,
        customerReviewConst:"ಗ್ರಾಹಕರ ವಿಮರ್ಶೆಗಳು"  ,
        viewsMagicpinConst:"ಮ್ಯಾಜಿಕ್ ಪಿನ್ ಅಭಿಪ್ರಾಯಗಳು"  ,
        yourRatingConst:"ನಿಮ್ಮ ರೇಟಿಂಗ್"  ,
        notBusiness:"ನಿಮ್ಮ ವ್ಯವಹಾರ ಅಲ್ಲವೇ?"  ,
        clickHere:"ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ"  ,
        testimonial4:"ನಾವು ಮೊದಲ ದಿನದಿಂದಲೇ  ಮ್ಯಾಜಿಕ್‌ಪಿನ್‌ನೊಂದಿಗೆ ಪಾಲುದಾರಿಕೆ ಹೊಂದಿದ್ದೇವೆ ಮತ್ತು ಇದು ನಮಗೆ ಸೂಕ್ತವಾದ ಅಪ್ಲಿಕೇಶನ್‌ ಆಗಿದೆ. ನಾವು ನಮಗಾಗಿ ನಿರ್ಮಿಸಲು ಬಯಸುವ ಎಲ್ಲಾ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ನೀಡಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡಿದೆ."  ,
        testimonial5:"ಬಿಯರ್ ಅನ್ನು ಇಷ್ಟಪಡುವ ಜನರಿಗೆ ಸಂತೋಷದಾಯಕ ಸ್ಥಳ, ಮ್ಯಾಜಿಕ್ಪಿನ್ ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಮಂಡಳಿಯಲ್ಲಿ ಪಡೆಯಲು ಮತ್ತು ಹಳೆಯ ಗ್ರಾಹಕರನ್ನು ಉಳಿಸಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಿದೆ. ಸಲಹೆಗಾರರಾಗಿ, ಅವರು ಎಲ್ಲರಿಗೂ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುತ್ತಾರೆ."  ,
        testimonial6:"ಇದು ಮ್ಯಾಜಿಕ್ಪಿನ್ನೊಂದಿಗೆ 3-4 ತಿಂಗಳುಗಳು ಮತ್ತು ಇದು ಫಲಪ್ರದ ಪ್ರಯಾಣವಾಗಿದೆ. ನಾವು ಗ್ರಾಹಕರು ಡೇಟಾವನ್ನು ಇಷ್ಟಪಡುವ ಬಗ್ಗೆ ನಿಕಟ ಒಳನೋಟವನ್ನು ಪಡೆಯುವ ಡೇಟಾವನ್ನು ವಿಶ್ಲೇಷಿಸುತ್ತೇವೆ. ನಾನು ಎಲ್ಲರಿಗೂ ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ."  ,
        buttonCta4:"ಹೆಚ್ಚಿನ ಗ್ರಾಹಕರನ್ನು ವೀಕ್ಷಿಸಿ"  ,
        restaurantConst:"ಉಪಹಾರ ಗೃಹ"  ,
        beautyConst:"ಸೌಂದರ್ಯ"  ,
        fashionConst:"ಫ್ಯಾಷನ್"  ,
        fitnessConst:"ಫಿಟನೆಸ್"  ,
        groceryConst:"ಕಿರಾಣಿ"  ,
        promotionsHeading:"ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಲೈವ್ ಪ್ರಚಾರಗಳು ಚಾಲನೆಯಲ್ಲಿವೆ"  ,
        whatIsmagicpin:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ಎಂದರೇನು"  ,
        heading3:"ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ನಾವು ಹೇಗೆ ಬೆಳೆಸಬಹುದು"  ,
        welcomeConst:"ನಿಮಗೆ ಸ್ವಾಗತ"  ,
        couldNotFind:"ನಿಮ್ಮ ಔಟಲೆಟ್ ನಿಮಗೆ ಸಿಗಲಿಲ್ಲವೇ?"  ,
        clickHereConst:"ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ, ನಾವು ನಿಮ್ಮನ್ನು ಇಮೇಲ್ ಮೂಲಕ ಸಂಪರ್ಕಿಸುತ್ತೇವೆ."  ,
        partnerFree:"ಉಚಿತ ಪಾಲುದಾರ"  ,
        ratedBy:"ಇವರಿಂದ ರೇಟ್ ಮಾಡಲಾಗಿದೆ"  ,
        spent:"ವೆಚ್ಚ ಮಾಡಲಾದ"  ,
        seeTop:"ಟಾಪ್ 10 ನೋಡಿ"  ,
        iName:"ಅಮಾನ್ಯ ಹೆಸರು"  ,
        iEmail:"ಅಮಾನ್ಯ ಇಮೇಲ್"  ,
        iPhone:"ಅಮಾನ್ಯ ಫೋನ್"  ,
        iOutlet:"ಅಮಾನ್ಯ  ಔಟ್ಲೆಟ್ ಹೆಸರು"  ,
        iCity:"ಅಮಾನ್ಯ ನಗರ"  ,
        iLocality:"ಅಮಾನ್ಯ ಸ್ಥಳ"  ,
        eligibleConst:"ನೀವು ಇದಕ್ಕೆ ಅರ್ಹರಾಗಿದ್ದೀರಿ"  ,
        referralConst:"₹100 ಉಲ್ಲೇಖಿತ ಬಹುಮಾನ."  ,
        foodConst:"ಆಹಾರ"  ,
        followers:"ಫಾಲೋವರ್‌ಗಳು"  ,
        likes:"ಲೈಕ್‍ಗಳು"  ,
        posts:"ಪೋಸ್ಟ್‌ಗಳು"  ,
        partner:"ಪಾಲುದಾರ"  ,
        owner:"ಮಾಲೀಕ"  ,
        founder:"ಸ್ಥಾಪಕ"  ,
        operations:"ಕಾರ್ಯಾಚರಣೆ"  ,
        hey:"ಹೇ"  ,
        findStore:"ನಿಮ್ಮ ಅಂಗಡಿಯನ್ನು ಹುಡುಕಿ"  ,
        loginText:"ಮತ್ತೆ ಪ್ರಾರಂಭಿಸಲು ಲಾಗಿನ್ ಮಾಡಿ!"  ,
        needHelp:"ಸಹಾಯ ಬೇಕೇ?"  ,
        callUs:"75101 75101 (10 AM ರಿಂದ 8 PM) ಗೆ ಕರೆ ಮಾಡಿ"  ,
        forgotPassword:"ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?"  ,
        loginText2:"ಅಥವಾ ಒಟಿಪಿ ಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ"  ,
        aboutus:"ನಮ್ಮ ಕುರಿತು"  ,
        text1:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ಒಂದು ಸಮುದಾಯವಾಗಿದ್ದು, ಗ್ರಾಹಕರು, ಚಟುವಟಿಕೆಗಳು ಮತ್ತು ವ್ಯಾಪಾರಿಗಳನ್ನು ಸ್ಥಳೀಯವಾಗಿ ಬಂಧಿಸುತ್ತದೆ. ಬ್ರ್ಯಾಂಡ್‌ಗಳು ತಮ್ಮ ಸಂಭಾವ್ಯ ಮತ್ತು ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಲು ಮತ್ತು ತೊಡಗಿಸಿಕೊಳ್ಳಲು ಇದು ಅನುಮತಿಸುತ್ತದೆ. ಕಳೆದ ವರ್ಷ ಅತ್ಯುತ್ತಮ ಸ್ಥಳೀಯ ಅನ್ವೇಷಣೆ ಅಪ್ಲಿಕೇಶನ್‌ ಎಂದು ಮತಗಳನ್ನು ಪಡೆದಿದೆ, ಇದು ನಿಮ್ಮ ವ್ಯವಹಾರವನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ನಿಮ್ಮ ಗೋಚರತೆಯನ್ನು ಹೆಚ್ಚಿಸಲು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ."  ,
        text2:"ವ್ಯವಹಾರಗಳಿಗಾಗಿ ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಪಡೆದುಕೊಳ್ಳಿ"  ,
        text3:"ವಿಷಯವನ್ನು ಎಲ್ಲರಿಗೂ ತಿಳಿಸಿ"  ,
        text4:"ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ"  ,
        text5:"ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ವ್ಯವಹಾರಗಳಿಗೆ ಡ್ರೈವ್ ಬಳಕೆ"  ,
        text6:"ಮಾರಾಟವಾಗದ ದಾಸ್ತಾನು ತೆಗೆದು ಹಾಕಲು"  ,
        text7:"ನೇರ ಅವಧಿಗಳಲ್ಲಿ ಬಳಕೆಯನ್ನು ಸುಧಾರಿಸಿ"  ,
        text8:"ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ವ್ಯವಹಾರಗಳಿಗೆ ತೊಡಗಿಸಿಕೊಳ್ಳಿ ಮತ್ತು ಪುನರಾವರ್ತಿಸಿ"  ,
        text9:"ನಿಷ್ಠಾವಂತ ಬಳಕೆದಾರರಿಗೆ ಬಹುಮಾನ ನೀಡಿ"  ,
        text10:"ಪುನರಾವರ್ತಿಸಲು ಬಳಕೆದಾರರನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ"  ,
        text11:"ಸ್ಥಳ, ಖರ್ಚು ಮಾದರಿ, ಲಿಂಗ ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಆಧರಿಸಿ ಸರಿಯಾದ ಪ್ರೇಕ್ಷಕರನ್ನು ಆಕರ್ಷಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾದ ವೇದಿಕೆಯೊಂದಿಗೆ ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಪ್ರಚಾರ ಮಾಡಿ."  ,
        acquire:"ಪಡೆದುಕೊಳ್ಳಿ"  ,
        text12:"ಸ್ಥಳ, ಖರ್ಚು ಮಾದರಿ, ಲಿಂಗ ಮತ್ತು ಹೆಚ್ಚಿನದನ್ನು ಆಧರಿಸಿ ಸರಿಯಾದ ಪ್ರೇಕ್ಷಕರನ್ನು ಆಕರ್ಷಿಸಲು ವಿನ್ಯಾಸಗೊಳಿಸಲಾದ ವೇದಿಕೆಯೊಂದಿಗೆ ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಪ್ರಚಾರ ಮಾಡಿ."  ,
        retain:"ಉಳಿಸಿಕೊಳ್ಳಿ"  ,
        text13:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ವ್ಯವಹಾರಗಳಿಗೆ ಆ ನಾಸ್ಟಾಲ್ಜಿಕ್ ಅನ್ನು ಮರಳಿ ತರಲು ಸಾಧ್ಯವಾಗಿಸುತ್ತದೆ. ನೆನಪಿಡಿ-ನಿಮ್ಮ ಹೆಸರಿನ ಲಾಯಲ್ಟಿ. ಇದಕ್ಕಾಗಿ ನಿಮ್ಮ ಗ್ರಾಹಕರು ಹುಡುಕುತ್ತಾರೆ."  ,
        analyse:"ವಿಶ್ಲೇಷಿಸಿ"  ,
        text14:"ಮ್ಯಾಜಿಕ್ಪಿನ್ ವ್ಯವಹಾರಗಳಿಗೆ ಗ್ರಾಹಕರ ಕುರಿತು  ತಮ್ಮ ಹೆಸರಿನಿಂದ ಹಿಡಿದು ತಮ್ಮ ನೆಚ್ಚಿನ ಪಾನೀಯಗಳವರೆಗೆ ಸಣ್ಣ ವಿವರಗಳನ್ನು ನೆನಪಿಟ್ಟುಕೊಳ್ಳಲು ಸಾಧ್ಯವಾಗಿಸುತ್ತದೆ. ನಿಮ್ಮ ವ್ಯವಹಾರದ ನಾಡಿಮಿಡಿತವನ್ನು ಅನುಭವಿಸಿ: ಪುಟ ವೀಕ್ಷಣೆಗಳು, ಗಳಿಸಿದ ಆದಾಯ, ಗ್ರಾಹಕರ ಡೇಟಾ ಮತ್ತು ಇನ್ನಷ್ಟು."  ,
        resetPassword:"ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ"  ,
        mobileNo:"ಮೊಬೈಲ್ ನಂಬರ್"  ,
        sendOtp:"OTP ಕಳುಹಿಸಿ"  ,
        validMob:"ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"  ,
        notRegistered:"ನೋಂದಣಿಯಾಗಿಲ್ಲವೇ? ನಿಮ್ಮ ಪಟ್ಟಿಯನ್ನು ಹುಡುಕಿ ಮತ್ತು ಕ್ಲೈಮ್ ಮಾಡಿ."  ,
        enterNo:"10 ಅಂಕಿಯ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"  ,
        loginText3:"ಅಥವಾ ರುಜುವಾತುಗಳೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ"  ,
        getStarted:"ಪ್ರಾರಂಭಿಸಿ"  ,
        IN:""  ,
        views:"ಅಭಿಪ್ರಾಯಗಳು" ,
        custPref:"ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಗ್ರಾಹಕರ ಆದ್ಯತೆ",
        topRetailersTooltip:"ನಿಮ್ಮ ಪ್ರದೇಶದ ಗ್ರಾಹಕರು ಆದ್ಯತೆ ನೀಡುವ ಉನ್ನತ ಚಿಲ್ಲರೆ ವ್ಯಾಪಾರಿಗಳು",
        restaurantConst:"ಉಪಹಾರ ಗೃಹ"  ,
        beautyConst:"ಸೌಂದರ್ಯ"  ,
        fashionConst:"ಫ್ಯಾಷನ್"  ,
        groceryConst:"ಕಿರಾಣಿ"  ,
        fitnessConst:"ಫಿಟನೆಸ್"  ,
        popularCities:"ಜನಪ್ರಿಯ ನಗರಗಳು"  ,
        popularLocalities:"ಜನಪ್ರಿಯ ಪ್ರದೇಶಗಳು"  ,
        popularRestaurants:"ಜನಪ್ರಿಯ ರೆಸ್ಟೋರೆಂಟ್‌ಗಳು"  ,
        aboutUs:"ನಮ್ಮ ಕುರಿತು"  ,
        voucher:"ವೋಚರಗಳು"  ,
        aroundYou:"ನಿಮ್ಮ ಸುತ್ತ"  ,
        blog:"ಬ್ಲಾಗ್"  ,
        augmentedReality:"ವರ್ಧಿತ ರಿಯಾಲಿಟಿ"  ,
        termsCond:"ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು"  ,
        commStand:"ಸಮುದಾಯ ಗುಣಮಟ್ಟ"  ,
        privacy:"ಗೌಪ್ಯತೆ"  ,
        sitemap:"ಸೈಟ್ಮ್ಯಾಪ್"  ,
        brands:"ಬ್ರಾಂಡ್"  ,
        directory:"ಡೈರೆಕ್ಟರಿ" ,
        categoryConst:"ವರ್ಗಗಳು" 
      }
}