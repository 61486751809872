import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { sendEvent, createMCPLog } from '../../helpers/Helpers'
import {setSurveyFlag, markInactive} from '../../redux/modules/newMerchantLanding'

class SurveyFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      remark: props.remark || '',
      reasonIndex:props.response || null,
      error: false
    }
    this.reason = [
      "I don't know much about magicpin",
      "Pricing seems high",
      "I do not understand the profit from doing this",
      "I am still thinking about it",
      "I am not the authority for this decision",
      "This is not relevant to me"
    ]
  }

  handleClose = () => {
    this.setState({ show: false });
    this.props.hide && this.props.hide()
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  optionChecked = (event) => {
    this.setState({ reasonIndex: parseInt(event.target.value), error: false })
  }

  onSubmit = () => {
    if(this.state.reasonIndex === null){
      this.setState({
        error: true
      })
    }
    else{
      this.params = new URLSearchParams(this.props.location.search);
      this.handleClose()
      this.props.setSurveyFlag()
      if (this.params.has('phone')) {
        this.props.unsubscribeRemarketing(this.params.get('phone'))
        sendEvent({
          utm_campaign:localStorage.getItem('landingSource') || '',
          subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
          event: 'unsubscribe',
          page_type: "survey_feedback",
          answer: this.reason[this.state.reasonIndex],
          utm_source: this.props.source||'payment',
          remark: this.state.remark,
          reason: 'reason_' + this.state.reasonIndex,
        })
        createMCPLog({
          utm_campaign: localStorage.getItem('landingSource') || '',
          merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'unsubscribe'
        });
      }
    }
  }

  handleRemark = (event) => {
    this.setState({ remark: event.target.value })
  }

  componentDidMount() {
    if (this.props.response) {
      this.onSubmit()
    }
  }
  render() {
    if(this.props.surveyFlag) return null
    return (
      <div className="surveyFeedback">
        {!this.props.simple &&
          <Modal show={this.state.show} onHide={this.handleClose} style={{ zIndex: "111111" }}>
            <Modal.Body style={{}} bsClass="surveyModalHeader">
              <div>
                <div style={{ textAlign: 'center' }}>
                  <div className="surveyFeedbackText1">We welcome your feedback</div>
                  <div className="surveyFeedbackText2">Help us improve your experience.</div>
                </div>
                <div className="surveyQuestion">
                  <div>
                    <div style={{ fontFamily: "robotomedium", fontSize: "20px", color: "#3e5561" }}>If you do not like this package, please help us understand why : </div>
                    <div>
                      {
                        this.reason.map((reason, index) => {
                          return (
                            <label key={'feedback' + index} className="radio-label" style={{ display: "block", marginBottom: "8px", fontFamily: "robotolight", color: "#3e5561" }}><input style={{ marginRight: "10px" }} type="radio" value={index} checked={this.state.reasonIndex == index} name="groupOptions" onChange={this.optionChecked} />{reason}</label>
                          )
                        })
                      }
                      <div style={{ marginTop: "14px", fontFamily: "robotomedium", fontSize: "18px", color: "#3e5561" }}>Anything else on your mind :</div>
                      <textarea name="remark" style={{ width: "100%", border: "solid 2px rgb(230, 230, 238)" }}
                        onChange={this.handleRemark}
                        placeholder='Write something'
                        value={this.state.remark} />
                    </div>
                  </div>
                  <div style={{ textAlign: "center", marginTop:"20px" }}>
                    <div className="surveyFeedbackCancel" onClick={this.handleClose}>
                      <span className="surveyFeedbackCancelText">CANCEL</span>
                    </div>
                    <div className="surveyFeedbackSubmit" onClick={this.onSubmit}>
                      <span className="surveyFeedbackSubmitText">UNSUBSCRIBE</span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        }
        {this.props.simple &&
          <div className="surveyModalHeaderPayment">
            <div style={{ textAlign: 'center' }}>
              <div className="surveyFeedbackText1">We welcome your feedback</div>
              <div className="surveyFeedbackText2">Help us improve your experience.</div>
            </div>
            <div className="surveyQuestion" style={{ backgroundColor: "#f7f7fa" }}>
              <div>
                <div style={{ fontFamily: "robotomedium", fontSize: "20px", color: "#3e5561" }}>Before unsubscribing to our messages, please help us understand why *</div>
                <div style={{marginTop:"20px"}}>
                  {
                    this.reason.map((reason, index) => {
                      return (
                        <label key={'feedback' + index} className="radio-label" style={{ display: "block", marginBottom: "8px", fontFamily: "robotolight", color: "#3e5561" }}><input style={{ marginRight: "10px" }} type="radio" value={index} checked={this.state.reasonIndex == index} name="groupOptions" onChange={this.optionChecked} />{reason}</label>
                      )
                    })
                  }
                  <div style={{ marginTop: "14px", fontFamily: "robotomedium", fontSize: "18px", color: "#3e5561" }}>Anything else on your mind :</div>
                  <textarea name="remark" style={{ width: "100%", border: "solid 2px rgb(230, 230, 238)", marginTop:"8px" }}
                    onChange={this.handleRemark}
                    placeholder='Please give suggestions'
                    value={this.state.remark} />
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop:"20px" }}>
                <div className="surveyFeedbackSubmit" onClick={this.onSubmit}>
                  <span className="surveyFeedbackSubmitText">UNSUBSCRIBE</span>
                </div>
              </div>
              {this.state.error && <div style={{ textAlign: "center", marginTop:"10px", color:'red', marginBottom:'-20px' }}>
                * Please select a reason
              </div>}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(state => ({
  surveyFlag: state.newMerchantLanding.survey_flag
}), {
  setSurveyFlag:setSurveyFlag,
  unsubscribeRemarketing: markInactive
  })(SurveyFeedback)