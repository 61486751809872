import moment from 'moment';
import { getCurrentEmailId } from '../../helpers/Helpers';

const LOAD_PROMOTIONS = 'promotions/LOAD_PROMOTIONS';
const LOAD_PROMOTIONS_SUCCESS = 'promotions/LOAD_PROMOTIONS_SUCCESS';
const LOAD_PROMOTIONS_FAIL = 'promotionsLOAD_PROMOTIONS_FAIL';

const LOAD_AVAILABLE_PROMOTIONS = 'promotions/LOAD_AVAILABLE_PROMOTIONS';
const LOAD_AVAILABLE_PROMOTIONS_SUCCESS = 'promotions/LOAD_AVAILABLE_PROMOTIONS_SUCCESS';
const LOAD_AVAILABLE_PROMOTIONS_FAIL = 'promotions/LOAD_AVAILABLE_PROMOTIONS_FAIL';

const REQUEST_PROMOTION = 'promotions/REQUEST_PROMOTION';
const REQUEST_PROMOTION_SUCCESS = 'promotions/REQUEST_PROMOTION_SUCCESS';
const REQUEST_PROMOTION_FAIL = 'promotions/REQUEST_PROMOTION_FAIL';

const CANCEL_PROMOTION = 'promotions/CANCEL_PROMOTION';
const CANCEL_PROMOTION_SUCCESS = 'promotions/CANCEL_PROMOTION_SUCCESS';
const CANCEL_PROMOTION_FAIL = 'promotions/CANCEL_PROMOTION_FAIL';

const LOAD_PROMOTION_DETAILS = 'promotions/LOAD_PROMOTION_DETAILS';
const LOAD_PROMOTION_DETAILS_SUCCESS = 'promotions/LOAD_PROMOTION_DETAILS_SUCCESS';
const LOAD_PROMOTION_DETAILS_FAIL = 'promotions/LOAD_PROMOTION_DETAILS_FAIL';

const FEED_POST_CHANGED = 'promotions/FEED_POST_CHANGED';

const LOAD_FEED_PUSHED_COUNT = 'promotions/LOAD_FEED_PUSHED_COUNT';
const LOAD_FEED_PUSHED_COUNT_SUCCESS = 'promotions/LOAD_FEED_PUSHED_COUNT_SUCCESS';
const LOAD_FEED_PUSHED_COUNT_FAIL = 'promotions/LOAD_FEED_PUSHED_COUNT_FAIL';

const BANNER_INTERESTED = 'promotions/BANNER_INTERESTED';
const BANNER_INTERESTED_SUCCESS = 'promotions/BANNER_INTERESTED_SUCCESS';
const BANNER_INTERESTED_FAIL = 'promotions/BANNER_INTERESTED_FAIL';

const LOAD_AD_SALES_INTERACTIONS = 'promotions/LOAD_AD_SALES_INTERACTIONS';
const LOAD_AD_SALES_INTERACTIONS_SUCCESS = 'promotions/LOAD_AD_SALES_INTERACTIONS_SUCCESS';
const LOAD_AD_SALES_INTERACTIONS_FAIL = 'promotions/LOAD_AD_SALES_INTERACTIONS_FAIL';

const getCurrentEmail = getCurrentEmailId() || ""

const intialState = {
  promotionsLoaded: false,
  promotions: [],
  availableLoaded: false,
  availablePromotions: null,
  requested: false,
  offset: 0,
  promotionDetails: null,
  feedPostInitiated: false,
  filter: 'all'
};

function correctStatus(promotions){
  let promotionsNew = []

  promotions.forEach(promotion => {
    const endDate = promotion.start_date ? moment(promotion.start_date.substr(0, 10), 'YYYY-MM-DD')
    .add(promotion.duration || 0, 'days') : moment();
    if(promotion.status === 'LIVE' && moment().isAfter(endDate)) promotion.status = 'COMPLETED';

    promotionsNew.push({...promotion,
      "promotion_id": promotion.promotionId || promotion.promotion_id,
      "type": promotion.promotionType || promotion.type,
      "status": promotion.status,
      "message": promotion.messageText || promotion.message,
      "image": promotion.imageUrl || promotion.image,
      "rejection_reason": promotion.reasonForRejection || promotion.rejection_reason,
      "banner_pushed": promotion.imageUrlPushed || promotion.banner_pushed,
      "message_pushed": promotion.messageTextPushed || promotion.message_pushed,
      "locality":promotion.locality,
      "city": promotion.city,
      "request_date": promotion.requestDate || promotion.request_date,
      "dispatch_date": promotion.dispatchDate || promotion.dispatch_date,
      "impression": promotion.impression,
      "identifier": promotion.identifier,
      "duration": promotion.duration,
      "start_date": promotion.startDate || promotion.start_date,
      "templateType": promotion.templateType
  })
  });

  return promotionsNew;
}

export default function promotions(state = intialState, action = {} ){
  switch(action.type){
    case LOAD_PROMOTIONS:
      return {
        ...state,
        promotionsLoading: true,
      };
    case LOAD_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotionsLoading: false,
        promotionsLoaded: true,
        promotions: correctStatus(action.offset ? [ ...state.promotions, ...(action.result.data ? action.result.data : []) ] : action.result.data ? action.result.data : []) ,
        offset: action.result.next,
        feedPostInitiated: false,
        filter: action.filter,
      };
    case LOAD_PROMOTIONS_FAIL:
      return {
        ...state,
        promotionsLoading: false,
        promotionsLoaded: false,
        promotionsError: action.error,
      };
    case LOAD_AVAILABLE_PROMOTIONS:
      return {
        ...state,
        availableLoading: true,
      };
    case LOAD_AVAILABLE_PROMOTIONS_SUCCESS:
      return {
        ...state,
        availableLoading: false,
        availableLoaded: true,
        availablePromotions: action.result,
      };
    case LOAD_AVAILABLE_PROMOTIONS_FAIL:
      return {
        ...state,
        availableLoading: false,
        availableLoaded: false,
        availableError: action.error,
      };
    case CANCEL_PROMOTION:
      
       return {
        ...state,
        cancelRequesting: true,
       };
    case CANCEL_PROMOTION_SUCCESS:
        return{
          ...state,
          cancelRequesting: false,
          cancelRequested: true,
          promotions: state.promotions.map(promotion => promotion.promotion_id === action.promotionId
            ? { ...promotion, status: 'DECLINED'} : promotion),
          availablePromotions: state.availablePromotions.map((promo, idx)=>{
            if(idx==0)
              promo.runnable_count = promo.runnable_count+=1;
            return promo;
          }),
        };
        
    case CANCEL_PROMOTION_FAIL:  
        return{
          ...state,
          cancelRequesting: false,
          cancelRequested: false,
          cancelError: action.error
        }       
    case REQUEST_PROMOTION:
      return {
        ...state,
        requesting: true,
        feedPostInitiated: false,
      };
    case REQUEST_PROMOTION_SUCCESS:
      let availablePromotions = state.availablePromotions;
      availablePromotions[0].runnable_count -= (availablePromotions[0].runnable_count > 0 ? 1 : 0);
      return {
        ...state,
        requesting: false,
        requested: true,
        feedPostInitiated: false,
        availablePromotions:availablePromotions,
      };
    case REQUEST_PROMOTION_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
        feedPostInitiated: false,
      };
    case LOAD_PROMOTION_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        promotionDetails: null,
      };
    case LOAD_PROMOTION_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        detailsLoaded: true,
        promotionDetails: Object.assign({},action.result, ...state.promotions.filter(promotion=>promotion.promotion_id === action.promotionId)),
      };
    case LOAD_PROMOTION_DETAILS_FAIL:
      return {
        ...state,
        detailsLoading: false,
        detailsLoaded: false,
        detailsError: action.error,
      };
    case FEED_POST_CHANGED:
      return {
        ...state,
        feedPostInitiated: true,
      }
    case LOAD_FEED_PUSHED_COUNT:
      return {
        ...state,
        feedPushActiveCounterLoading: true
      }
    case LOAD_FEED_PUSHED_COUNT_SUCCESS:
      return {
        ...state,
        feedpushedCounter: action.result && action.result.message ? 0 : action.result ||  0,
        feedPushActiveCounterLoading: false
      }
    case LOAD_FEED_PUSHED_COUNT_FAIL:
      return {
        ...state,
        feedpushedCounter: 0,
        feedPushActiveCounterLoading: false
      }
    
    case BANNER_INTERESTED:
      return {
        ...state,
        bannerInterestedError: false,
        bannerInterestedLoading: true
      }
    case BANNER_INTERESTED_SUCCESS:
      return {
        ...state,
        bannerInterestedLoading: false
      }
    case BANNER_INTERESTED_FAIL:
      return {
        ...state,
        bannerInterestedError: action.error,
        bannerInterestedLoading: false
      }
    
    case LOAD_AD_SALES_INTERACTIONS:
      return {
        ...state
      }
    case LOAD_AD_SALES_INTERACTIONS_SUCCESS:
      return {
        ...state,
        adSalesInteractionsData: action.result
      }
    case LOAD_AD_SALES_INTERACTIONS_FAIL:
      return {
        ...state
      }
    default:
      return state;
  }
}

export function loadPromotions(accessToken, offset, filter, merchantUserId){
  // let url = `https://webapi.magicpin.in/merchants/merchantUserId/promotions/history/?status=${filter}`;
  let url = `https://webapi.magicpin.in/ultron-mp/merchant/${merchantUserId}/getPromotionHistory?status=${filter}`
  // let url = `http://sales.magicpin.in/ultron-mp/merchant/${merchantUserId}/getPromotionHistory?status=${filter}`
  if(offset) url += `&offset=${offset}`;

  const action = {
    url,
    method: 'get',
    headerAuth: true,
  };
  return {
    types: [LOAD_PROMOTIONS, LOAD_PROMOTIONS_SUCCESS, LOAD_PROMOTIONS_FAIL],
    promise: (client) => client.default.post('/takeAction/loadPromotions', {
      data: {accessToken, action}
    }),
    offset, filter
  };
}

export function cancelPendingPromotion(accessToken,promotionId){
  return {
    types: [CANCEL_PROMOTION, CANCEL_PROMOTION_SUCCESS, CANCEL_PROMOTION_FAIL],
    promise: (client) => client.default.post('/cancelPendingPromotion', {
      data: {accessToken, promotionId}
    }),
    promotionId
  };
}



export function loadAvailablePromotions(accessToken, countryAbbr){
  const action = {
    url: `https://webapi.magicpin.in/merchants/merchantUserId/promotions/available/`,
    method: 'get',
    headerAuth: true,
    countryAbbr
  };

  return {
    types: [LOAD_AVAILABLE_PROMOTIONS, LOAD_AVAILABLE_PROMOTIONS_SUCCESS, LOAD_AVAILABLE_PROMOTIONS_FAIL],
    promise: (client) => client.default.post('/takeAction/loadAvailablePromotions', {
      data: {accessToken, action}
    })
  };
}

export function requestPromotion(accessToken, promotion, files, filename){
  let data
  if(files.length > 0){
  data = new FormData();
  data.append('merchant_single_photo', files[0], files[0].name ? files[0].name : filename);
  data.append('promotion', JSON.stringify(promotion));
  data.append('accessToken', accessToken);
  data.append('updatedBy', getCurrentEmail);
  }
  else {
    data = {
      promotion: JSON.stringify(promotion),
      accessToken,
      updatedBy: getCurrentEmail
    }
  }
  // const now = new Date()
  // const expiry = now.getTime() + 43200000
  // const key = `feedPushPromotion_${promotion.dispatchDate}_${promotion.merchantId}`
  // localStorage.setItem(key, expiry)

  return {
    types: [REQUEST_PROMOTION, REQUEST_PROMOTION_SUCCESS, REQUEST_PROMOTION_FAIL],
    promise: (client) => client.default.post(files.length > 0 ? '/requestPromotion' : '/submitPromotionRequest', { data })
  };
}

export function requestPromotion2(accessToken, promotion, merchant, isUpgradeRequest){
  return {
    types: [REQUEST_PROMOTION, REQUEST_PROMOTION_SUCCESS, REQUEST_PROMOTION_FAIL],
    promise: (client) => client.default.post('/requestPromotion2', {
      data: {accessToken, promotion, merchant, isUpgradeRequest: !!isUpgradeRequest}
    })
  };
}

export function getPromotionDetails(accessToken, promotionId) {
     return {
       types: [LOAD_PROMOTION_DETAILS, LOAD_PROMOTION_DETAILS_SUCCESS, LOAD_PROMOTION_DETAILS_FAIL],
      promise: (client) => client.default.post('/getImpressionsByPromotionId/', {
        data: {accessToken, promotionId}
      })
     };
   }

export function onFeedPostChanged(){
  return {
    type: FEED_POST_CHANGED,
  };
}

export function feedPushActiveCounter(accessToken){
  return {
    types: [LOAD_FEED_PUSHED_COUNT, LOAD_FEED_PUSHED_COUNT_SUCCESS, LOAD_FEED_PUSHED_COUNT_FAIL],
    promise: (client) => client.default.post('/feedPushActiveCounter/', {
     data: {accessToken}
   })
  };
}

export function bannerInterested(data) {
  return {
    types: [BANNER_INTERESTED, BANNER_INTERESTED_SUCCESS, BANNER_INTERESTED_FAIL],
    promise: (client) => client.default.post('/merchantShowInterest', {
      data
    })
  }
}

export function loadAdSalesInteractions(accessToken, parentOrMerchantId, startDate, endDate) {
  return {
    types: [LOAD_AD_SALES_INTERACTIONS, LOAD_AD_SALES_INTERACTIONS_SUCCESS, LOAD_AD_SALES_INTERACTIONS_FAIL],
    promise: (client) => client.default.post('/loadAdSalesInteractions', {
      data: {accessToken, parentOrMerchantId, startDate, endDate}
    })
  }
}
