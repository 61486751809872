import React, { Component } from 'react'
import { Accordion, Icon, List } from 'semantic-ui-react'
import { getBrowserInfo } from '../../helpers/Helpers'
import "./locSettingGuideline.css"

export default class LocSettingGuidAccordion extends Component {
  state = { activeIndex: 0 }

  componentDidMount = () => {
    let browserName = ""

    try {
        let browserInfo = getBrowserInfo()
        browserName = browserInfo && browserInfo.browser_name ? browserInfo.browser_name.toLowerCase() : ""
    } catch (error) {}
    
    switch (browserName) {
        case "chrome":
            this.setState({activeIndex: 0})
            break;

        case "firefox":
            this.setState({activeIndex: 1})
            break;

        case "safari":
            this.setState({activeIndex: 2})
            break;
    
        case "microsoft internet explorer":
            this.setState({activeIndex: 4})
            break;

        default:
            break;
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' link/>
          <i className = "fa fa-brands fa-chrome location-guide-icon-margin"></i>
          Chrome
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
        <List ordered className = "location-guide-list-margin">
            <List.Item>
                To the left of the web address, click Lock <i class="fa fa-solid fa-lock" style={{color: "grey"}}></i>
            </List.Item>
            <List.Item> 
                Click <span className = "location-guide-bold-font">Site settings</span>.
            </List.Item>
            <List.Item>
                To the right of "Location," choose <span className = "location-guide-bold-font">Allow</span>.
            </List.Item>
        </List>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' link/>
          <i className = "fa fa-brands fa-firefox location-guide-icon-margin"></i>
          Firefox
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
        <List ordered className = "location-guide-list-margin">
            <List.Item>
                To the left of the web address, click Lock <i class="fa fa-solid fa-lock" style={{color: "grey"}}></i>
            </List.Item>
            <List.Item> 
                Click <span className = "location-guide-bold-font"> Connection secure and then More information</span>.
            </List.Item>
            <List.Item>
                Select <span className = "location-guide-bold-font"> Permissions</span>.
            </List.Item>
            <List.Item>
                To the right of "Access your location," uncheck <span className = "location-guide-bold-font">Use Default</span>. Then, choose <span className = "location-guide-bold-font">Allow</span>.
            </List.Item>
        </List>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' link/>
          <i className = "fa fa-brands fa-safari location-guide-icon-margin"></i>
          Safari
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
        <List ordered className = "location-guide-list-margin">
            <List.Item>
                Click the Apple icon and then <span className = "location-guide-bold-font"> System Preferences </span>.
            </List.Item>
            <List.Item> 
                Click the "Security & Privacy" icon. Then, select <span className = "location-guide-bold-font"> Privacy </span>.
            </List.Item>
            <List.Item>
                In the bottom left, click the Lock icon so that it's unlocked.
            </List.Item>
            <List.Item>
                Check <span className = "location-guide-bold-font"> Enable Location Services </span>.
            </List.Item>
            <List.Item> 
                In the list, check <span className = "location-guide-bold-font"> Safari </span>.
            </List.Item>
            <List.Item>
                Click the Lock icon so that it's locked.
            </List.Item>
        </List>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' link/>
          <i className = "fa fa-brands fa-safari location-guide-icon-margin"></i>
          Safari (iOS)
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3}>
        <List ordered className = "location-guide-list-margin">
            <List.Item>
              Go to your iPhone’s <span className = "location-guide-bold-font"> Settings </span>.
            </List.Item>
            <List.Item> 
              Then go to <span className = "location-guide-bold-font"> Privacy </span>.
            </List.Item>
            <List.Item>
              Click <span className = "location-guide-bold-font"> Location Services </span>.
            </List.Item>
            <List.Item>
              Select <span className = "location-guide-bold-font"> Safari </span>.
            </List.Item>
            <List.Item> 
              Make sure you’ve allowed it to access your location <span className = "location-guide-bold-font"> “While Using the App”. </span>
            </List.Item>
        </List>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={this.handleClick}
        >
          <Icon name='dropdown' link/>
          <i className="fa fa-brands fa-edge location-guide-icon-margin" aria-hidden="true"></i>
          Microsoft Edge
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
        <List ordered className = "location-guide-list-margin">
            <List.Item>
                To the left of the web address, click Lock <i class="fa fa-solid fa-lock" style={{color: "grey"}}></i>.
            </List.Item>
            <List.Item> 
                Click <span className = "location-guide-bold-font"> Permissions for this site </span>.
            </List.Item>
            <List.Item>
                To the right of "Location," choose <span className = "location-guide-bold-font"> Allow </span>.
            </List.Item>
        </List>
        </Accordion.Content>

      </Accordion>
    )
  }
}
