const LOAD = 'shortUrl/LOAD';
const LOAD_SUCCESS = 'shortUrl/LOAD_SUCCESS';
const LOAD_FAIL = 'shortUrl/LOAD_FAIL';

const RESET_PASSWORD = 'admin/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'admin/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'admin/RESET_PASSWORD_FAIL';

const GENERATE_PAYMENT_LINK = 'admin/GENERATE_PAYMENT_LINK';
const GENERATE_PAYMENT_LINK_SUCCESS = 'admin/GENERATE_PAYMENT_LINK_SUCCESS';
const GENERATE_PAYMENT_LINK_FAIL = 'admin/GENERATE_PAYMENT_LINK_FAIL';

const initialState = {
  loaded: false,
  reset: false,
};

export default function info(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        data: null
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: null
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetting: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetting: false,
        reset: true,
        newPassword: action.result
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetting: false,
        reset: false,
        resetError: action.error
      };
    case GENERATE_PAYMENT_LINK:
      return {
        ...state,
        generating: true
      };
    case GENERATE_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        generating: false,
        generated: true,
        paymentLink: action.result
      };
    case GENERATE_PAYMENT_LINK_FAIL:
      return {
        ...state,
        generating: false,
        generated: false,
        generateError: action.error
      };
    default:
      return state;
  }
}

export function getShortUrl(accessToken, merchantId, page, longURL) {
  if(longURL){
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/getShortUrlUnclaimedMerchant', {
        data: {accessToken, merchantId, longURL}
      })
    };
  }
  let querystring = `utm_source=admin_dashboard`;
  if(page && page !== '') querystring += `&page=${page}`;
  if(page == 'landing')
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/getShortUrlUnclaimedMerchant', {
        data: {accessToken, merchantId, querystring}
      })
    };
  else 
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/getShortUrl', {
        data: {accessToken, merchantId, querystring}
      })
    };
}

export function resetPassword(accessToken, merchantId){
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    promise: (client) => client.default.post('/resetPassword', {
      data: {accessToken, merchantId}
    })
  };
}

export function generatePaymentLink(accessToken, merchantId, email) {
  return {
    types: [GENERATE_PAYMENT_LINK, GENERATE_PAYMENT_LINK_SUCCESS, GENERATE_PAYMENT_LINK_FAIL],
    promise: (client) => client.default.post('/getPaymentLink', {
      data: {accessToken, merchantId, email}
    })
  };
}
