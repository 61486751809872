import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { countriesInfo } from '../../constants';
import cityBackground from '../../images/city-background.png';
import { loadAvailablePromotions } from '../../redux/modules/promotions';
import CreatePromotion from '../Feed/CreatePromotion';
import '../Promotions/promotions.css'
import '../Feed/react-feed-stylesheet.css';

export default connect(store => ({
    availableLoaded: store.promotions.availableLoaded,
    availablePromotions: store.promotions.availablePromotions,

  }), dispatch => bindActionCreators({
      loadAvailablePromotions
  }, dispatch))
  (class CreateFeedpush extends Component {
    constructor(props){
        super(props);
        this.state = {
            promotionAfterRelisting : false,
          };
    }

    componentWillMount(){
        if(!this.props.availablePromotions) {
          let countryAbbr = 'IN';
          if(this.props.user.country) {
            countryAbbr = countriesInfo[this.props.user.country.toUpperCase()]['abbr'];
          }
          this.props.loadAvailablePromotions(this.props.user.accessToken, countryAbbr);
        }
    }

    promotionAfterRelisting = () => {
        this.setState({
        promotionAfterRelisting : true
        })

        if(this.props.location && this.props.location.pathname === '/promotions')
        setTimeout(() => {
        window.location.reload()
        }, 10000);
    }

  render() {

    let no_of_users = Array.isArray(this.props.availablePromotions) && this.props.availablePromotions.length > 0 && this.props.availablePromotions[0] && this.props.availablePromotions[0].impressions ? parseInt(this.props.availablePromotions[0].impressions) > 9000 ? parseInt(this.props.availablePromotions[0].impressions) : parseInt(this.props.availablePromotions[0].impressions) + 9000 : 0;
    const country = (this.props.user && this.props.user.country && this.props.user.country.toUpperCase()) || "INDIA"

    return (
        <div>
          <div>
              <h1 className="desktop-only" style={{padding:'2% 0 0.5% 0', color:'rgba(0,0,0,0.54)', fontFamily:'unset'}}>Promote to {no_of_users.toLocaleString(countriesInfo[country]['locale'])} customers of {this.props.user.locality}</h1>
            </div>
              {this.props.availableLoaded &&
              <div className="col-md-12" style={{display:'flex', textAlign:'center', backgroundImage:`url(${cityBackground})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'bottom'}}>
                <div className="col-md-5 col-sm-12 col-xs-12" style={{margin:'0 auto'}}>
                  <div className="merchant-feed-main-wrappper promotion">
                    <CreatePromotion
                      user={this.props.user}
                      push={this.props.push}
                      promotionAfterRelisting = {this.promotionAfterRelisting}
                      promotionAfterRelist = {true}
                    />
                  </div>
                </div>
              </div>
              }
      </div>
    )
  }
})