import React from 'react'
import BtwLogo from '../../images/btwLogo.svg'
import CoverImage from './CoverImage'

const FeedCard = (props) => {

  const {bgImageComponent, logo, description, cardImage} = props
  return (
    <div className='fullImage mobileCurve' style={{ backgroundImage: `url(${bgImageComponent})`}}>
        <div className='feedcardMobile'>
                <div style={{width:'50%', color:'white'}}>
                    <div className='feedcardLogo' style={{textAlign:'start'}}>
                        <CoverImage coverImageComponent={logo}/>
                    </div>
                    <div className='feedcardText'>{description}</div>
                </div>
                <div className='feedcardImage'>
                    <CoverImage coverImageComponent={cardImage}/>
                </div>
        </div>
    </div>
  )
}

export default FeedCard