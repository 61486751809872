import { getUserDetail } from "../../../helpers/Helpers";

const CREATE_GROCERY_PHARMA_VOUCHER = 'createVoucher/CREATE_GROCERY_PHARMA_VOUCHER';
const CREATE_GROCERY_PHARMA_VOUCHER_SUCCESS = 'createVoucher/CREATE_GROCERY_PHARMA_VOUCHER_SUCCESS';
const CREATE_GROCERY_PHARMA_VOUCHER_FAIL = 'createVoucher/CREATE_GROCERY_PHARMA_VOUCHER_FAIL';

const CREATE_NY_VOUCHER = 'createVoucher/CREATE_NY_VOUCHER';
const CREATE_NY_VOUCHER_SUCCESS = 'createVoucher/CREATE_NY_VOUCHER_SUCCESS';
const CREATE_NY_VOUCHER_FAIL = 'createVoucher/CREATE_NY_VOUCHER_FAIL';

const CREATE_COMBO_VOUCHER = 'createVoucher/CREATE_COMBO_VOUCHER';
const CREATE_COMBO_VOUCHER_SUCCESS = 'createVoucher/CREATE_COMBO_VOUCHER_SUCCESS';
const CREATE_COMBO_VOUCHER_FAIL = 'createVoucher/CREATE_COMBO_VOUCHER_FAIL';

const NY_VOUCHER_MODAL_HIDE = 'createVoucher/NY_VOUCHER_MODAL_HIDE';
const NY_VOUCHER_MODAL_SHOW = 'createVoucher/NY_VOUCHER_MODAL_SHOW';

const GROCERY_PHARMA_VOUCHER_MODAL_HIDE = 'createVoucher/GROCERY_PHARMA_VOUCHER_MODAL_HIDE';
const GROCERY_PHARMA_VOUCHER_MODAL_SHOW = 'createVoucher/GROCERY_PHARMA_VOUCHER_MODAL_SHOW';

const RELISTING_MODAL_SHOW = 'createVoucher/RELISTING_MODAL_SHOW';
const RELISTING_MODAL_HIDE =  'createVoucher/RELISTING_MODAL_HIDE';
const RELISTING_MOBILE_SHOW = 'createVoucher/RELISTING_MOBILE_SHOW';
const RELISTING_MOBILE_HIDE =  'createVoucher/RELISTING_MOBILE_HIDE';

const SENDING_SMS = 'createVoucher/SENDING_SMS';
const SENT_SMS = 'createVoucher/SENT_SMS';
const FAILED_SMS = 'createVoucher/FAILED_SMS';

const REMOVING = 'createVoucher/REMOVING';
const REMOVED = 'createVoucher/REMOVED';
const REMOVE_ERROR = 'createVoucher/REMOVE_ERROR';

const LOAD_QR_VOUCHERS = 'feedRight/LOAD_QR_VOUCHERS';
const LOAD_QR_VOUCHERS_SUCCESS = 'feedRight/LOAD_QR_VOUCHERS_SUCCESS';
const LOAD_QR_VOUCHERS_FAIL = 'feedRight/LOAD_QR_VOUCHERS_FAIL';

const UPDATE_QR_VOUCHERS = 'createVoucher/UPDATE_QR_VOUCHERS';
const UPDATE_QR_VOUCHERS_SUCCESS = 'createVoucher/UPDATE_QR_VOUCHERS_SUCCESS';
const UPDATE_QR_VOUCHERS_FAIL = 'createVoucher/UPDATE_QR_VOUCHERS_FAIL';

const CREATE_HOME_DELIVERY_VOUCHER = 'createVoucher/CREATE_HOME_DELIVERY_VOUCHER';
const CREATE_HOME_DELIVERY_VOUCHER_SUCCESS = 'createVoucher/CREATE_HOME_DELIVERY_VOUCHER_SUCCESS';
const CREATE_HOME_DELIVERY_VOUCHER_FAIL = 'createVoucher/CREATE_HOME_DELIVERY_VOUCHER_FAIL';

const RECOMMENDED = 'createVoucher/RECOMMENDED';
const RECOMMENDED_SUCCESS = 'createVoucher/RECOMMENDED_SUCCESS';
const RECOMMENDED_FAIL = 'createVoucher/RECOMMENDED_FAIL';

const MAGIC_ORDER_SHOW_MODEL = 'createVoucher/showMagicOrderModal';
const MAGIC_ORDER_HIDE_MODEL = 'createVoucher/hideMagicOrderModal';

const CREATE_ENTRY_MAGICORDER = 'createVoucher/CREATE_ENTRY_MAGICORDER';
const CREATE_ENTRY_MAGICORDER_SUCCESS = 'createVoucher/CREATE_ENTRY_MAGICORDER_SUCCESS';
const CREATE_ENTRY_MAGICORDER_FAIL = 'createVoucher/CREATE_ENTRY_MAGICORDER_FAIL';

let initialState={
  relistingMobile: false,
  relistingMobile: false,
  qrVouchersLoading :false,
  qrVouchersError : null,
  qrVouchersData : [],
  qrVouchersUpdating : false,
  qrVouchersUpdated : false,
  qrVouchersUpdateError : null,
  createHomeDeliveryLoading : false,
  createHomeDeliveryLoaded : false,
  createHomeDeliveryData: null,
  createHomeDeliveryError: null,
  loadingRecommendVoucher: false,
  recommendVoucherError: false,
  recommendedList: null
}
export default function createVoucher(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_NY_VOUCHER:
      return {
        ...state,
        loading: true
      };
    case CREATE_NY_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        commission: action.result
      };
    case CREATE_NY_VOUCHER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    
      case CREATE_GROCERY_PHARMA_VOUCHER:
        return {
          ...state,
          loading: true
        };
      case CREATE_GROCERY_PHARMA_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          commission: action.result
        };
      case CREATE_GROCERY_PHARMA_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.error
        };

    case NY_VOUCHER_MODAL_SHOW:
      return{
        ...state,
        nyVoucherModalVisible: true
      }
    case NY_VOUCHER_MODAL_HIDE:
      return{
        ...state,
        nyVoucherModalVisible: false
      }

      case GROCERY_PHARMA_VOUCHER_MODAL_SHOW:
        return{
          ...state,
          groceryPharmaModalVisible: true
        }
      case GROCERY_PHARMA_VOUCHER_MODAL_HIDE:
        return{
          ...state,
          groceryPharmaModalVisible: false
        }
      
      case MAGIC_ORDER_SHOW_MODEL:
         return{
           ...state,
           visibleMagicOrderModal: true
         }
      case MAGIC_ORDER_HIDE_MODEL:
         return{
           ...state,
           visibleMagicOrderModal: false
         }
      
    
    case CREATE_COMBO_VOUCHER:
      return{
        ...state,
        creatingComboVoucher: true
      }
    case CREATE_COMBO_VOUCHER_SUCCESS:
      return{
        ...state,
        creatingComboVoucher: false,
        createdComboVoucher: true,
        comboVoucherResponse: action.result,
        comboVoucherError: null
      }
    case CREATE_COMBO_VOUCHER_FAIL:
      return{
        ...state,
        creatingComboVoucher: false,
        createdComboVoucher: true,
        comboVoucherError: action.error
      }
    case RELISTING_MODAL_SHOW:
      return{
        ...state,
        relistingVisible:true,
      }
    case RELISTING_MODAL_HIDE:
      return{
        ...state,
        relistingVisible:false,
      }
    case RELISTING_MOBILE_SHOW:
      return{
        ...state,
        relistingMobile:true,
      }
    case RELISTING_MOBILE_HIDE:
      return{
        ...state,
        relistingMobile:false,
      }
    case SENDING_SMS:
      return{
        ...state,
        sending:true,
        sent:false
      }
    case SENT_SMS:
      return{
        ...state,
        sending: false,
        sent:true,
        smsError: null
      }
    case FAILED_SMS:
      return {
        ...state,
        sending: false,
        sent:true,
        smsError: action.error
      }
    case REMOVING:
      return {
        ...state,
        removing: true,
      }
    case REMOVED:
      return{
        removing: false,
        removeResult: action.result,
        removeError: null,
      }
    case REMOVE_ERROR:
      return{
        removing:false,
        removeError: action.error,
        removeResult: null,
      }
    case LOAD_QR_VOUCHERS:
      return{
        ...state,
        qrVouchersLoading : true,
        qrVouchersLoaded: false,
        qrVouchersData : [],
      }
    case LOAD_QR_VOUCHERS_SUCCESS:
      return{
        ...state,
        qrVouchersLoading : false,
        qrVouchersLoaded: true,
        qrVouchersError : null,
        qrVouchersData : action.result,
      }
    case LOAD_QR_VOUCHERS_FAIL:
      return{
          ...state,
        qrVouchersLoading : false,
        qrVouchersLoaded : true,
        qrVouchersError : action.error,
        qrVouchersData : [],
      }
    case UPDATE_QR_VOUCHERS:
      return{
        ...state,
        qrVouchersUpdating :true,
      }
    case UPDATE_QR_VOUCHERS_SUCCESS:
      return{
        ...state,
        qrVouchersUpdating : false,
        qrVouchersUpdated : true,
        qrVouchersUpdateError : null,
        qrVouchersData: state.qrVouchersData.map( voucher => {
          if(voucher.roaid === action.roaId){
            return {...voucher, is_active: !voucher.is_active }
          }
          return voucher
        })
      }
    case UPDATE_QR_VOUCHERS_FAIL:
      return{
        ...state,
        qrVouchersUpdating : false,
        qrVouchersUpdated : false,
        qrVouchersUpdateError : action.error,
      };
    
    case CREATE_HOME_DELIVERY_VOUCHER:
      return{
        ...state,
        createHomeDeliveryLoading: true,
      };
    case CREATE_HOME_DELIVERY_VOUCHER_SUCCESS:
      return{
         ...state,
         createHomeDeliveryLoading: false,
         createHomeDeliveryLoaded: true,
         createHomeDeliveryData: action.result,
         createHomeDeliveryError: null
      };
    case CREATE_HOME_DELIVERY_VOUCHER_FAIL:
      return{
        ...state,
        createHomeDeliveryLoading: false,
        createHomeDeliveryLoaded: true,
        createHomeDeliveryData: null,
        createHomeDeliveryError: action.error
      };

      case CREATE_ENTRY_MAGICORDER:
        return{
          ...state,
          creatingEntryInMagicOrder: true,
          createdEntryInMagicOrder: false,
          errorInEntryInMagicOrder: null
        };
      case CREATE_ENTRY_MAGICORDER_SUCCESS:
        return{
          ...state,
          creatingEntryInMagicOrder: false,
          createdEntryInMagicOrder: true,
          errorInEntryInMagicOrder: null
        };
      case CREATE_ENTRY_MAGICORDER_FAIL:
        return{
          ...state,
          creatingEntryInMagicOrder: false,
          createdEntryInMagicOrder: false,
          errorInEntryInMagicOrder: false
        }

      case RECOMMENDED:
      return{
        ...state,
        loadingRecommendVoucher: true,
        recommendVoucherError: false,
        recommendedList: null
      }

    case RECOMMENDED_SUCCESS:
      return{
        ...state,
        loadingRecommendVoucher: false,
        recommendVoucherError: false,
        recommendedList: action.result.recommendList
      }

    case RECOMMENDED_FAIL:
      return{
        ...state,
        loadingRecommendVoucher: false,
        recommendVoucherError: true,
        recommendedList: null

        
      }

    default:
      return state;

    
  }
}

export function createNYVoucher(accessToken, merchantName, voucherDetails, file) {
  let data = new FormData();
  if(file && file.name)
    data.append('merchant_single_photo', file, file.name);
  data.append('voucherDetails', JSON.stringify(voucherDetails));
  data.append('accessToken', accessToken);
  data.append('merchantName', merchantName)
  return {
    types: [CREATE_NY_VOUCHER, CREATE_NY_VOUCHER_SUCCESS, CREATE_NY_VOUCHER_FAIL],
    promise: (client) => client.default.post('/createNYVoucher', { data })
  };
}

export function createGroceryPharmaVoucher(accessToken, merchantName, voucherDetails) {
  let payload = {};
  payload['voucherDetails'] = JSON.stringify(voucherDetails);
  payload['merchantName'] = merchantName
  return {
    types: [CREATE_GROCERY_PHARMA_VOUCHER, CREATE_GROCERY_PHARMA_VOUCHER_SUCCESS, CREATE_GROCERY_PHARMA_VOUCHER_FAIL],
    promise: (client) => client.default.post('/createGroceryAndPharmaVoucher', { data:{accessToken, payload} })
  };
}

export function createHomeDeliveryVouchers(accessToken, merchantName, voucherDetails) {
  const userDetails = getUserDetail();
  const createdBy = `createHomeDeliveryVouchers_${userDetails}`
  let payload = {};
  payload['voucherDetails'] = voucherDetails;
  payload['merchantName'] = merchantName;
  payload['createdBy'] = createdBy;
  return {
    types: [CREATE_HOME_DELIVERY_VOUCHER, CREATE_HOME_DELIVERY_VOUCHER_SUCCESS, CREATE_HOME_DELIVERY_VOUCHER_FAIL],
    promise: (client) => client.default.post('/createHomeDeliveryVouchers', { data:{accessToken, payload} })
  };
}

export  function createEntryInSheetMagicOrder(accessToken, merchantName,merchantId,contactNumber){
   return {
      types: [CREATE_ENTRY_MAGICORDER, CREATE_ENTRY_MAGICORDER_SUCCESS, CREATE_ENTRY_MAGICORDER_FAIL],
      promise: (client) => client.default.post('./magicSpeedSheet',{data:{
        accessToken,
        "merchantId":merchantId,
        "Merchant":merchantName,
        "phoneNumber":contactNumber
      }})
   };
}

export function createComboVoucher(accessToken, country, voucherDetails, phone, otp){
  return {
    types:[CREATE_COMBO_VOUCHER, CREATE_COMBO_VOUCHER_SUCCESS, CREATE_COMBO_VOUCHER_FAIL],
    promise: (client) => client.default.post('/createComboVoucher',{
        data:{accessToken, country, voucherDetails, phone, otp}
    })
  }
}

export function showNYVoucherModal(){
  return {
    type: NY_VOUCHER_MODAL_SHOW
  }
}

export function hideNYVoucherModal(){
  return {
    type: NY_VOUCHER_MODAL_HIDE
  }
}

export function showGroceryPharmaModal(){
  return {
    type: GROCERY_PHARMA_VOUCHER_MODAL_SHOW
  }
}

export function hideGroceryPharmaModal(){
  return {
    type: GROCERY_PHARMA_VOUCHER_MODAL_HIDE
  }
}

export function showMagicOrderModal(){
  return {
    type: MAGIC_ORDER_SHOW_MODEL
  }
}

export function hideMagicOrderModal(){
   return {
      type: MAGIC_ORDER_HIDE_MODEL
   }
}


export function showVoucherRelistingModal(){
  return{
    type:RELISTING_MODAL_SHOW
  }
}

export function hideVoucherRelistingModal(){
  return{
    type:RELISTING_MODAL_HIDE
  }
}

export function showVoucherRelistingMobile(){
  return{
    type:RELISTING_MOBILE_SHOW
  }
}

export function hideVoucherRelistingMobile(){
  return{
    type:RELISTING_MOBILE_HIDE
  }
}

export function sendSms(accessToken, merchantName, phone, bankDetail){
  let data = {accessToken, merchantName, phone, active: true, reason: 'staff_denied'};
  if(bankDetail)
    data['bankDetail']=bankDetail

  return {
    types:[SENDING_SMS, SENT_SMS, FAILED_SMS],
    promise: (client) => client.default.post('/sendVoucherRedemptionTutorialSms',{data})
  } 
}

export function removeVouchers(accessToken, payload){
  return {
    types:[REMOVING, REMOVED, REMOVE_ERROR],
    promise:client=>client.default.post('/pauseMultipleVoucher',{
      data:{accessToken, payload, reason:'remove_voucher', active:true}
    })
  }
}

 export function loadQrVouchers(accessToken) {
  
   const action = {
     url: "https://webapi.magicpin.in/qrvouchers/merchantUserId",
     method: 'get',
     headerAuth: true,
   };
   return {
     types: [LOAD_QR_VOUCHERS, LOAD_QR_VOUCHERS_SUCCESS, LOAD_QR_VOUCHERS_FAIL],
     promise: (client) => client.default.post('/takeAction/getQrVouchers', {
       data: {accessToken, action}
     })
   };
 }

export function updateQrVouchers(accessToken, arObject ,roaId ,maxCount, active) {

  return {
    types: [UPDATE_QR_VOUCHERS, UPDATE_QR_VOUCHERS_SUCCESS, UPDATE_QR_VOUCHERS_FAIL],
    roaId,
    promise: (client) => client.default.post('/updateQrVouchers', {
      data: {accessToken, arObject ,roaId ,maxCount ,active}
    })
  };
}

export function recommendedComboVoucher(accessToken,categoryId,locality) {

  return {
    types: [RECOMMENDED, RECOMMENDED_SUCCESS, RECOMMENDED_FAIL],
    promise: (client) => client.default.post('/recommendedComboVoucher', {
      data: {accessToken,categoryId,locality}
    })
  };
}