import React, { Component } from 'react'
import ChartComponent from 'react-chartjs-2';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import moment from 'moment';
import "./voucherSoldChart.css"
import { getVoucherSoldData } from '../../redux/modules/dashboardReducers/voucherSoldData';
import { currencyString, generateArrayOfDates, groupDataTableByTime, numericString } from '../../helpers/Helpers';

export default connect(store => ({
    voucherSoldDataData: store.voucherSoldData.voucherSoldDataData
}), dispatch => bindActionCreators({
    getVoucherSoldData
}, dispatch))
(class VoucherSoldChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labels: [],
            salesData: [],
            ordersData: [],
            totalValue: 0,
            totalOrder: 0,
            graphType: "order",
            intervalType:'date',
            startDate : moment().subtract(150, 'days').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            typeOfRange: ['date', 'week', 'month'],    
            startDateForDaily: moment().subtract(45, 'days').format("YYYY-MM-DD"),
        }
    }

    componentDidMount = () => {
        this.props.getVoucherSoldData(this.props.user.accessToken, moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"))
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevState.intervalType !== this.state.intervalType) || (prevProps.voucherSoldDataData !== this.props.voucherSoldDataData) || (prevState.graphType !== this.state.graphType)){
            this.prepareFinalData()
        }
    }

    prepareFinalData = () => {        
        const datewiseVouchersData = this.props.voucherSoldDataData && this.props.voucherSoldDataData.datewise && this.props.voucherSoldDataData.datewise || {}

        const total_voucher_sale = this.props.voucherSoldDataData && this.props.voucherSoldDataData.total_voucher_sale || 0
        const total_vouchers_count = this.props.voucherSoldDataData && this.props.voucherSoldDataData.total_vouchers_count || 0

        const startDate = this.state.intervalType === "date" ? this.state.startDateForDaily : this.state.startDate

        let arrayOfDates = generateArrayOfDates(startDate, this.state.endDate, "YYYY-MM-DD") || []

        let ungroupedData = arrayOfDates.map(date=>[date, ((datewiseVouchersData[date] && datewiseVouchersData[date].voucher_sale) || 0), ((datewiseVouchersData[date] && datewiseVouchersData[date].vouchers_count) || 0)])

        let {data, format} = groupDataTableByTime(ungroupedData, this.state.intervalType)

        let labels = []
        let salesData = []
        let ordersData = []

        data.forEach((item, index) => {

            labels.push(moment(item[0]).format(format))
            salesData.push(item[1])
            ordersData.push(item[2])

        })

        this.setState({
            labels,
            salesData,
            ordersData,
            totalValue: total_voucher_sale,
            totalOrder :total_vouchers_count
        })
    }

    render() {

    const country = this.props.user && this.props.user.country || "INDIA"

    const data = {
        labels: this.state.labels,
        datasets: [
            {
                label: 'Vouchers sold',
                data: this.state.graphType === "value" ? this.state.salesData : this.state.ordersData,
                borderColor: "white",
                backgroundColor: "rgba(59, 116, 252, 1)",
                stack: 'combined',
                type: 'bar',
                barWidth: '10',
                maxBarThickness: 30,
                yAxisID: 'y',
            }
        ]
    };

    const options = {
        scales: {
            yAxes: [{
              id: 'y',
              position: 'left',
              ticks: {
                maxTicksLimit: 6,
                precision: 0,
                suggestedMax: 5
                },
              gridLines: {
                display: true,
                lineWidth: 1,
                zeroLineColor: 'rgba(214, 214, 214)',
             },
             scaleLabel: {
                display: false,
                labelString: "No. of ratings count",
                fontFamily: "robotomedium",
                fontSize: 14,
                padding: 8
              }
            }
            ],
            xAxes: [{
                stacked: true,
                gridLines: {
                    display: false,
                }
              }],
          }
      }

    const { isCeetMerchant } = this.props?.user || {};

    const addnStyle = isCeetMerchant ? {} : {
        marginLeft: "16px",
        width: "calc( 100% - 16px )"
    }

     return (
       <div className='voucher-sold-graph-comp-main card white-box white-box-wrapper mobile-full-card col-md-12' style={addnStyle}>
           <div className='voucher-sold-graph-comp-main-div'>
                <div className='voucher-sold-graph-comp-main-heading'>
                    <div className='voucher-sold-graph-comp-main-heading-text'>
                        <p>{this.state.graphType === "value" ? "VOUCHER SALES" : "VOUCHER SOLD"}</p>
                        <span>{this.state.graphType === "value" ? currencyString(this.state.totalValue, country, 0): numericString(this.state.totalOrder, country, 0)}</span>
                    </div>

                    <div className='voucher-sold-graph-comp-main-heading-ctas-div'>
                        <div className='voucher-sold-graph-comp-type-ctas'>
                            <span className= {`${this.state.graphType === "value" && 'voucher-sold-graph-comp-main-heading-ctas-active'}`}
                                onClick={() => {this.setState({graphType: "value"})}}> Value</span>
                            <span className= {`${this.state.graphType === "order" && 'voucher-sold-graph-comp-main-heading-ctas-active'}`}
                                onClick={() => {this.setState({graphType: "order"})}}> Order</span>
                        </div>

                        <div className='voucher-sold-graph-comp-main-heading-ctas'>
                            <span className= {`${this.state.intervalType === "date" && 'voucher-sold-graph-comp-main-heading-ctas-active'}`}
                                onClick={() => {this.setState({intervalType: "date"})}}> Daily</span>
                            <span className= {`${this.state.intervalType === "week" && 'voucher-sold-graph-comp-main-heading-ctas-active'}`}
                                onClick={() => {this.setState({intervalType: "week"})}}> Weekly</span>
                            <span className= {`${this.state.intervalType === "month" && 'voucher-sold-graph-comp-main-heading-ctas-active'}`}
                                onClick={() => {this.setState({intervalType: "month"})}}> Monthly</span>
                        </div>
                    </div>
                </div>
                <div className='voucher-sold-graph-comp-main-body'>
                    <ChartComponent
                        legend={{display: false}} 
                        type='bar'
                        data={data}
                        height={window.innerWidth < 768 ? 250 : 100}
                        options = {options}
                    />
                </div>
            </div>
       </div>
     )
   }
})