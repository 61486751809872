import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { countriesInfo } from '../../constants';
import LineChart from '../Utilities/LineChart';
import DateSelector from '../BrandPrivileges/DateSelector';
export default class CategoryBannerInsights extends Component{

    constructor(props){
        super(props);
        this.state = {
          activeImpressionType: 'views',
          xAxisValues: [],
          yAxisValues: [],
          totalClicks: 0,
          totalViews: 0
        };
    }

    updateGraphDataHandler = () => {
        let xAxisValues = []
        let yAxisValues = []
        let totalViews = 0;
        let totalClicks = 0;
        if(this.props.categoryBannerInsightsData && this.props.categoryBannerInsightsData.interactions_with_widget_details) {
            this.props.categoryBannerInsightsData.interactions_with_widget_details.map( dateWiseData => {
                xAxisValues.push(dateWiseData.date);
                yAxisValues.push(this.state.activeImpressionType === 'views' ? dateWiseData.view_count : dateWiseData.click_count);
                totalClicks += dateWiseData.click_count;
                totalViews += dateWiseData.view_count;
            })
        }
        this.setState({xAxisValues, yAxisValues, totalClicks, totalViews})
    }

    componentDidMount () {
        this.updateGraphDataHandler();
    }

    componentDidUpdate (prevProps) {
        if(prevProps && this.props && prevProps.categoryBannerInsightsData !== this.props.categoryBannerInsightsData) this.updateGraphDataHandler();
    }

    changeImpressionType = (impressionType) => {
        let xAxisValues = []
        let yAxisValues = []
        if(this.props.categoryBannerInsightsData && this.props.categoryBannerInsightsData.interactions_with_widget_details) {
            this.props.categoryBannerInsightsData.interactions_with_widget_details.map( dateWiseData => {
                xAxisValues.push(dateWiseData.date);
                yAxisValues.push(impressionType === 'views' ? dateWiseData.view_count : dateWiseData.click_count);
            })
        }
        this.setState({xAxisValues, yAxisValues, activeImpressionType: impressionType})
    }

  render(){
    const country= (this.props && this.props.user && this.props.user.country && this.props.user.country.toUpperCase()) || "INDIA"
    const isActiveImpressionViews = this.state.activeImpressionType === 'views'
    return(
       <Modal dialogClassName='modal-dialog new-modal-small small-rp-modal'
        show={true} onHide={this.props.hideInsights}>
        { this.state.xAxisValues && this.state.yAxisValues && <div className= "modal-content">
          <div className= "modal-header" style={{marginTop:'0'}}>
            <h2>BANNER INSIGHTS</h2>
            <div className= "dialog-cross fa fa-close" onClick={this.props.hideInsights} style={{ display: 'flex',flexDirection: 'row-reverse' }}>
            </div>
          </div>
          <div className="modal-body">
            <div className="rp-modal-row">
              <div className="modal-lft-text-block" style={{paddingTop:'15px'}}>
                <div className="rp-modal-top-imp-block" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', flexBasis:'50%'}}>
                        <div onClick={() => this.changeImpressionType('views')} style={{ cursor:'pointer', width:'100%', backgroundColor: '#ffffff', padding:'3%' }}>
                            <p><span style={{color: 'rgba(0,0,0,0.87)'}}>{this.state.totalViews.toLocaleString(countriesInfo[country]['locale'])}</span></p>
                            <p style={{color: 'rgba(0,0,0,0.87)',marginTop:'10px'}}>Views on your store</p>
                        </div>
                        {/* <div onClick={() => this.changeImpressionType('clicks')} style={{ cursor:'pointer', width:'100%', backgroundColor: !isActiveImpressionViews ? '#458eff' : '#ffffff', padding:'3%'}}>
                            <p><span style={{color: !isActiveImpressionViews ? '#ffffff' : 'rgba(0,0,0,0.87)'}}>{this.state.totalClicks.toLocaleString(countriesInfo[country]['locale'])}</span></p>
                            <p style={{color: !isActiveImpressionViews ? '#ffffff' : 'rgba(0,0,0,0.87)',marginTop:'10px'}}>Clicks on your store</p>
                        </div> */}
                    </div>
                    <div>
                        < DateSelector isCategoryBannerInsightGraph = {true}
                        onChange={(startDate, endDate) => this.props.categoryBannerDateRangeHandler(startDate, endDate)}/>
                    </div>
                </div>
                {/* <div id="InsiteGraph" className="rp-modal-graph-block"></div> */}
                {<div style={{width:'100%'}} className='chart-loading bottom-round-box'>
                <LineChart
                  xAxisValues={this.state.xAxisValues}
                  yAxisValues={this.state.yAxisValues}
                  yAxisLabel={this.state.activeImpressionType}/>
                </div>}
                <div className="rp-modal-row">
                  {this.props.promotion.start_date && <div className="rp-insite-txt" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Published: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{moment(this.props.promotion.start_date).format('D MMM, YYYY')}</span></p>
                        <p style={{color: 'rgba(0, 0, 0, 0.54)',marginLeft:'12px'}}>Locality: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{this.props.user.locality}</span></p>
                    </div>
                    <div>
                    <p style={{color: 'rgba(0, 0, 0, 0.54)',marginLeft:'12px'}}>Expired On: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{moment(this.props.promotion.end_date).format('D MMM, YYYY')}</span></p>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      </Modal>
    );
  }
}
