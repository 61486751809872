const LOAD = 'forumData/LOAD';
const LOAD_SUCCESS = 'forumData/LOAD_SUCCESS';
const LOAD_FAIL = 'forumData/LOAD_FAIL';

const intialState = {
    forumDataLoaded: false,
  };
export default function forumData(state = intialState, action = {} ){
    switch (action.type) {
        case LOAD:
        return {
          ...state,
          forumDataLoading: true,
          forumDataLoaded: false
        };
      case LOAD_SUCCESS:
        return {
          ...state,
          forumDataLoading: false,
          forumDataLoaded: true,
          questionList: action.result,
          forumDataError: null
        };
      case LOAD_FAIL:
        return {
          ...state,
          forumDataLoading: false,
          forumDataLoaded: true,
          forumDataError: action.error
        };
    
    default:
        return state;  
    
    }

}


export function getForumData(accessToken) {
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/getForumData',{
        data: { accessToken }
      })
    };
  }
  