const VERIFY_OTP = 'verifyOtpMagicStore/VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'verifyOtpMagicStore/VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAIL = 'verifyOtpMagicStore/VERIFY_OTP_FAIL';

const SEND_OTP = 'verifyOtpMagicStore/SEND_OTP';
const SEND_OTP_SUCCESS = 'verifyOtpMagicStore/SEND_OTP_SUCCESS';
const SEND_OTP_FAIL = 'verifyOtpMagicStore/SEND_OTP_FAIL';

const SEND_OTP_CALL = 'verifyOtpMagicStore/SEND_CALL_OTP';
const SEND_OTP_CALL_SUCCESS = 'verifyOtpMagicStore/SEND_OTP_CALL_SUCCESS';
const SEND_OTP_CALL_FAIL = 'verifyOtpMagicStore/SEND_OTP_CALL_FAIL';

const initialState = {
    error: null,
};

export default (state = initialState, action = {})=>{
    switch (action.type) {
        case SEND_OTP:
            return {
                ...state,
                otpSending: true,
                otpSent: false,
            };
        case SEND_OTP_SUCCESS:
            return {
                ...state,
                otpSent: true,
                otpSending: false,
                error:null
            };
        case SEND_OTP_FAIL:
            return {
                ...state,
                otpSent: false,
                otpSending: false,
                error: action.error
            };
        case SEND_OTP_CALL:
            return {
                ...state,
                otpSending: true,
                otpSent: false,
            };
        case SEND_OTP_CALL_SUCCESS:
            return {
                ...state,
                otpSent: true,
                otpSending: false,
                error:null
            };
        case SEND_OTP_CALL_FAIL:
            return {
                ...state,
                otpSent: false,
                otpSending: false,
                error: action.error
            };
        case VERIFY_OTP:
            return {
                ...state,
                verifyingOtp: true,
                otpVerified: false,
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                otpVerified: true,
                verifyingOtp: false,
                verifyOtpError:null
            };
        case VERIFY_OTP_FAIL:
            return {
                ...state,
                otpVerified: false,
                verifyingOtp: false,
                verifyOtpError: action.error
            };
        default:
            return state;
    }
}

export function verifyOtp( otp, phone) {
    return {
        types: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL],
        promise: (client) => client.default.post('/magicValidateOtp',{
            data:{ otp, phone}
        })
    };
}

export function sendOtp( phone, sendSixDigitOtp = false) {
    return {
        types: [SEND_OTP, SEND_OTP_SUCCESS, SEND_OTP_FAIL],
        promise: (client) => client.default.post('/magicSendOtp',{
            data:{ phone, sendSixDigitOtp}
        })
    };
}

export function sendOtpByCall( phone) {
    return {
        types: [SEND_OTP_CALL, SEND_OTP_CALL_SUCCESS, SEND_OTP_CALL_FAIL],
        promise: (client) => client.default.post('/sendOtpByCallUnauth',{
            data:{ phone}
        })
    };
}

