const LOAD = 'newLandingPage/LOAD';
const LOAD_SUCCESS = 'newLandingPage/LOAD_SUCCESS';
const LOAD_FAIL = 'newLandingPage/LOAD_FAIL';

const SUBMIT_REQUEST = 'newLandingPage/SUBMIT_REQUEST';
const SUBMIT_REQUEST_SUCCESS = 'newLandingPage/SUBMIT_REQUEST_SUCCESS';
const SUBMIT_REQUEST_FAIL = 'newLandingPage/SUBMIT_REQUEST_FAIL';

const SEND_OTP = 'newLandingPage/SEND_OTP';
const SEND_OTP_SUCCESS = 'newLandingPage/SEND_OTP_SUCCESS';
const SEND_OTP_FAIL = 'newLandingPage/SEND_OTP_FAIL';

const VERIFY_OTP = 'newLandingPage/VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'newLandingPage/VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAIL = 'newLandingPage/VERIFY_OTP_FAIL';

const SUBMIT_NUMBER = 'newLandingPage/SUBMIT_NUMBER';
const SUBMIT_NUMBER_SUCCESS = 'newLandingPage/SUBMIT_NUMBER_SUCCESS';
const SUBMIT_NUMBER_FAIL = 'newLandingPage/SUBMIT_NUMBER_FAIL';


const initialState = {
  loading: false,
  loaded: false,
  requested: false,
}

export default function newLandingPage(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        merchantInfo: action.result.merchantInfo,
        summary: action.result.summary,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    case SUBMIT_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
      };
    case SUBMIT_REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
      };
    case SEND_OTP:
      return {
        ...state,
        otpSending: true,
        otpSent: false,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        phone: action.number,
      };

    case SEND_OTP_FAIL:
      return {
        ...state,
        otpSent: false,
      };

    case VERIFY_OTP:
      return {
        ...state,
        otpVerifying: true,
        otpVerified: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerified: true,
      };
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        otpVerified: false,
      };
    case SUBMIT_NUMBER:
      return {
        ...state,
        numberSubmitting: true,
        numberSubmited: false,
      };
    case SUBMIT_NUMBER_SUCCESS:
      return {
        ...state,
        numberSubmited: true,
      };
    case SUBMIT_NUMBER_FAIL:
      return {
        ...state,
        numberSubmited: false,
      };

    default:
      return state;
  }
}

export function getLandingPageData() {
  const identifier = window.localStorage.getItem('unconverted_identifier')
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getLandingPageData',{
      data:{identifier}
    })
  };
}

export function submitRequest(requestBody){
  const identifier = window.localStorage.getItem('unconverted_identifier')
  return {
    types: [SUBMIT_REQUEST, SUBMIT_REQUEST_SUCCESS, SUBMIT_REQUEST_FAIL],
    promise: (client) => client.default.post('/submitRequest', {
      data: {identifier, requestBody}
    })
  }
}

export function sendOtpOnCall(number){
  const identifier = window.localStorage.getItem('unconverted_identifier')
  return {
    types: [SEND_OTP, SEND_OTP_SUCCESS, SEND_OTP_FAIL],
    promise: (client) => client.default.post('/sendOtpOnCall', {
      data: {identifier, number}
    }), number
  }
}

export function verifyOtp(otp, phone){
  const identifier = window.localStorage.getItem('unconverted_identifier')
  return {
    types: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL],
    promise: (client) => client.default.post('/verifyOnCallOtp', {
      data: {otp, phone, identifier}
    })
  }
}

export function submitNewNumber(phone){
  const identifier = window.localStorage.getItem('unconverted_identifier')
  return {
    types: [SUBMIT_NUMBER, SUBMIT_NUMBER_SUCCESS, SUBMIT_NUMBER_FAIL],
    promise: (client) => client.default.post('/submitNewNumber', {
      data: {identifier, phone}
    })
  }
}
