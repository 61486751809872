import React from 'react'
import moment from 'moment';
import DatePicker from 'material-ui/DatePicker';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './DateSelector.css';

const ga = window.ga;
export default class DateSelector extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selected:  this.props.caller === "impression-chart" ? 'last 45 days': this.props.isMagicDiscountGraph ? 'last 3 months' : 'last 30 days',
            tempSelected: this.props.caller === "impression-chart" ? 'last 45 days': this.props.isMagicDiscountGraph ? 'last 3 months' : 'last 30 days',
            startDate: this.props.startDate || moment().subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: this.props.endDate || moment().format("YYYY-MM-DD"),
            tempStartDate: this.props.startDate || moment().subtract(30, 'days').format('YYYY-MM-DD'),
            tempEndDate: this.props.endDate || moment().format("YYYY-MM-DD"),
            modalOpen: false,
            anchorEl: null,
            packageStartDate: this.props.packageStartDate
        }
        this.elementId = "date-selector-button"+Math.random().toString(36).substring(0,15)
        this.elementId2 = "date-range-selector-button"+Math.random().toString(36).substring(0,15)

    }

    componentDidUpdate(prevProps, prevState){
      if(prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || prevProps.packageStartDate !==this.props.packageStartDate){
        this.setState({startDate: this.props.startDate, endDate: this.props.endDate, tempStartDate: this.props.startDate, tempEndDate: this.props.endDate, packageStartDate: this.props.packageStartDate})
      }
    }

    componentDidMount() {
      if(this.props.showPackageStart) {
        this.setState({
          selected: 'current subscription',
          tempSelected: 'current subscription',
          packageStartDate: this.props.packageStartDate
        })
      }
    }

    onDropDownChange = value=>{
      const {
        caller,
        page,
        isDownloadLedger,
        currentSubscriptionRange: { startDate, endDate } = {}
      } = this.props;
      const { packageStartDate } = this.state;

      switch(value){
        case 'last 30 days':
          ga('event', page, 'change date', caller, 'last 30 days')
          this.setState({tempStartDate:moment().subtract(30, 'days').format("YYYY-MM-DD"), tempEndDate: caller === "impression-chart" ? moment().subtract(1,'days').format("YYYY-MM-DD"): moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
        case 'last 45 days':
          ga('event', page, 'change date', caller, 'last 45 days')
          this.setState({tempStartDate:moment().subtract(45, 'days').format("YYYY-MM-DD"), tempEndDate: caller === "impression-chart" ? moment().subtract(1,'days').format("YYYY-MM-DD"):moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
        case 'last 7 days':
          ga('event', page, 'change date', caller, 'last 7 days')
          this.setState({tempStartDate:moment().subtract(7, 'days').format("YYYY-MM-DD"), tempEndDate: caller === "impression-chart" ? moment().subtract(1,'days').format("YYYY-MM-DD"):moment().format("YYYY-MM-DD"), tempSelected: value})
          break
        case 'custom':
          ga('event', page, 'change date', caller, 'custom')
          this.setState({tempSelected:'custom'})
          break
        case 'current subscription':
          ga("event", page, "change date", caller, "current subscription");
          const sD = moment(packageStartDate).format("YYYY-MM-DD");
          const eD =
            caller === "impression-chart"
              ? moment().subtract(1, "days").format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD");
          const tempStartDate = isDownloadLedger ? startDate : sD;
          const tempEndDate = isDownloadLedger ? endDate : eD;
          this.setState({ tempStartDate, tempEndDate, tempSelected: value });
          break;
        case 'last 3 months':
          ga('event', page, 'change date', caller, 'last 3 months')
          this.setState({tempStartDate:moment().subtract(90, 'days').format("YYYY-MM-DD"), tempEndDate: moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
        case 'last 6 months':
          ga('event', page, 'change date', caller, 'last 6 months')
          this.setState({tempStartDate:moment().subtract(6, 'M').format("YYYY-MM-DD"), tempEndDate: moment().format("YYYY-MM-DD"), tempSelected: value})
          break;
      }
    }

    handleClick=event=>{
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
      this.setState({ tempStartDate: this.state.startDate, tempEndDate: this.state.endDate, tempSelected: this.state.selected, anchorEl: null });
    };

    apply = e=>{
      this.setState({startDate: this.state.tempStartDate, endDate: this.state.tempEndDate, selected: this.state.tempSelected})
      this.props.onChange(this.state.tempStartDate, this.state.tempEndDate)
      this.setState({anchorEl: null})
    }

    displayDates=(startDate, endDate)=>{
      startDate = moment(startDate, "YYYY-MM-DD")
      endDate = moment(endDate, "YYYY-MM-DD")
      let finalFormat = ""
      if(startDate.format("YYYY") !== endDate.format("YYYY")) finalFormat=" YY"
      if(finalFormat){
        finalFormat="D MMM"+finalFormat
        return startDate.format(finalFormat)+' - '+endDate.format(finalFormat)
      }
      return startDate.format("D MMM")+' - '+endDate.format("D MMM")
    }

    isTempSelected = (selectedTemp) => {
      return this.state.tempSelected === selectedTemp
    }

    render(){
      let datepickerStyle={
        width: '80px'        
      }

      let isMagicOrderGraph=false;
      let isOrderIoGraph =false;
      const isMagicDiscountGraph = this.props.isMagicDiscountGraph || false

      if(this.props.isMagicOrderGraph){
        isMagicOrderGraph=true
      }
      if(this.props.isOrderIoGraph){
        isOrderIoGraph=true
      }
      const { anchorEl } = this.state;

      const {isNewDesign, isDownloadLedger, showSubscription = true } = this.props

        return(
                <Paper elevation={isDownloadLedger && 0} style = {isNewDesign ? {borderRadius: "50px", textAlign: "center"} : {}}>
                  <Button
                            aria-owns={anchorEl ? this.elementId : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            style={isNewDesign ? {width: "100%", fontSize: "12px", display: "flex", justifyContent: "space-between", fontFamily: 'robotomedium', borderRadius: "50px", border: "1px solid #DDDDDD", paddingLeft: "25px", textTransform: "capitalize"} : {fontSize:'12px'}}
                          >
                            <span>{this.state.selected !== 'custom' ? this.state.selected : this.displayDates(this.state.startDate, this.state.endDate)}</span>
                            <span className="fa fa-chevron-down" style={isNewDesign ? {color: "#EA4C89", backgroundColor: "rgba(234, 76, 137, 0.1)", borderRadius: "100%", marginLeft: "5%", fontSize: "8px", padding: "3%"} : {margin:'-4px 0 0 5px'}}></span>
                  </Button>
                  <Menu
                    id={this.elementId}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    disableEnforceFocus
                  >
                    { (!isMagicOrderGraph && showSubscription && !isOrderIoGraph ) &&
                      <MenuItem onClick={()=>this.onDropDownChange('current subscription')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("current subscription") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'current subscription'}

                    >
                      Current Subscription
                    </MenuItem>
                    }


                    <MenuItem onClick={()=>this.onDropDownChange('last 30 days')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("last 30 days") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'last 30 days'}

                    >
                      Last 30 days
                    </MenuItem>

                    <MenuItem onClick={()=>this.onDropDownChange('last 45 days')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("last 45 days") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'last 45 days'}

                    >
                      Last 45 days
                    </MenuItem>

                    {isMagicDiscountGraph &&                
                    <MenuItem onClick={()=>this.onDropDownChange('last 3 months')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("last 3 months") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'last 3 months'}

                    >
                      Last 3 months
                    </MenuItem>}

                    {isMagicDiscountGraph &&                
                    <MenuItem onClick={()=>this.onDropDownChange('last 6 months')}
                      style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("last 6 months") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'last 6 months'}

                    >
                      Last 6 months
                    </MenuItem>}

                    <MenuItem onClick={() => this.onDropDownChange('last 7 days')}
                     style={{width: 'calc( 100% - 32px )', justifyContent:'center', fontSize:'16px', backgroundColor: this.isTempSelected("last 7 days") && "#FFF2F7"}}
                      selected={this.state.tempSelected === 'last 7 days'}
                    >
                    Last 7 days
                    </MenuItem>
                    { !isMagicOrderGraph && !isMagicDiscountGraph &&
                    <MenuItem component="ul" selected={this.state.tempSelected === 'custom'} style = {{backgroundColor: this.isTempSelected("custom") && "#FFF2F7"}}>
                      <i class="fa fa-calendar-check-o" style={{color: "#E92675", fontSize: "14px"}}></i>
                      <MenuItem>
                        <DatePicker 
                          value={moment(this.state.tempStartDate, "YYYY-MM-DD").toDate()}
                          autoOk={true}
                          style={datepickerStyle}
                          onChange={(e, date)=>this.setState({tempSelected:'custom',tempStartDate: moment(date).format("YYYY-MM-DD")})}
                          name='startDate'
                          minDate={this.props.minDate ? moment(this.props.minDate,'YYYY-MM-DD').toDate() : null}
                          maxDate={moment(this.state.tempEndDate, "YYYY-MM-DD").toDate()}
                          formatDate={(date) => moment(date).format('DD MMM, YY')}/>
                      </MenuItem>
                      <MenuItem>
                        <DatePicker
                          autoOk={true} 
                          style={{...datepickerStyle, margin:'0 0 0 5px'}}
                          value={moment(this.state.tempEndDate, "YYYY-MM-DD").toDate()}
                          className='datepicker'
                          name='endDate'
                          maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : moment().toDate()}
                          minDate={moment(this.state.tempStartDate, "YYYY-MM-DD").toDate()}
                          onChange={(e, date)=>this.setState({tempSelected:'custom',tempEndDate: moment(date).format("YYYY-MM-DD")})}
                          formatDate={(date) => moment(date).format('DD MMM, YY')}/>
                      </MenuItem>
                    </MenuItem>
                     }
                    <MenuItem
                    onClick = {this.apply}
                    style={{fontSize:'16px', backgroundColor:'#EF1C74', color:'white', justifyContent:'center', width:'calc( 100% - 50px)', borderRadius:'8px', margin:'0 auto'}}
                    >
                      APPLY
                    </MenuItem>
                  </Menu>
                </Paper>
            )
    }

}