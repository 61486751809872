import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadChildMerchants, selectChildId } from '../../redux/modules/parentMerchantReducers/childMerchants';
import { selectMerchant } from '../../redux/modules/authReducers/auth';
import Select from 'react-select';
import { toast } from 'react-toastify';
import logoBusiness from '../../images/logo-business.svg';
import { loadScript } from '../../helpers/Helpers';
// import 'react-select/dist/react-select.css';

import './header.css';
import moment from 'moment';

const ga = window.ga;

export default connect(store => ({
  selecting: store.auth.selecting,
  selected: store.auth.selected,
  selectError: store.auth.selectError,

  storeslist: store.childMerchants.data,
  error: store.childMerchants.error,
  loaded: store.childMerchants.loaded,
  loading: store.childMerchants.loading,
  selectedChildId: store.childMerchants.selectedChildId,
}), dispatch => bindActionCreators({
  loadChildMerchants,
  selectChildId,
  selectMerchant,
}, dispatch))
(class HeaderParentMerchant extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedStore : {label: 'All Stores', value: 0}
    }
  }

  componentWillMount(){
    if(!this.props.storelist && !this.props.loading) this.props.loadChildMerchants(this.props.user.accessToken);
  }

  componentDidMount(){
    loadScript("//translate.google.com/translate_a/element.js?cb=window.googleTranslateElementInit", null)
  }

  componentWillReceiveProps(nextprops){
    if(this.props.selecting && nextprops.selected) this.props.push('/dashboard');
    if(this.props.selecting && nextprops.selectError) toast.error(nextprops.selectError && nextprops.selectError.message || 'something failed');
  }

  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout(this.props.user.accessToken);
    ga('send', 'event', 'Dashboard', 'navigation', 'logout');
  }

  selectChild = (result) => {
    const merchantId = result.value;
    this.props.selectChildId(merchantId)
    this.setState({selectedStore: result});
  }

  gotoStore = (event) => {
    event.preventDefault();
    if(this.props.selectedChildId){
      this.props.selectMerchant(this.props.user.accessToken, this.props.selectedChildId);
    }
  }

  render() {
    let searchOptions = [{value: 0, label: 'All Stores'}];
    document.getElementsByTagName('body')[0].style.paddingTop = '46px';
    if(this.props.loaded){
      var stores = this.props.storeslist.map((store) =>
        <option key={store.id} value={store.id}>
          {store.merchant_name + ', ' + store.city + ', ' + store.locality}
        </option>
      );

      this.props.storeslist.forEach(store => {
        searchOptions.push({value: store.id,
          label: store.merchant_name + ', ' + store.city + ', ' + store.locality});
      });
    }

    return (
      !this.props.user ? <div /> :
      <header style={{position:"fixed", zIndex:"1000", width:"100vw", top:"0"}}>
        <div className="top-header-bar top-login-header">
          <div className="container-fluid" style={{width:"100%"}}>
            <div style={{display:'flex', justifyContent:"space-between"}}>
                <div style={{marginLeft:'0'}} className="logo login-logo logo-wrapper parent-merchant-logo-wrapper">
                    <img onClick={()=>this.props.push("/")} src={logoBusiness} alt="magicpin"/>
                </div>
              <div className="parent-merchant-right-wrapper" style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    <div className="parent-merchant-hdr-wrapper parent-hdr-btn-wrapper">
                      {this.state.selectedStore.value != 0 &&
                      <button style={{whiteSpace:'nowrap'}} className="btn-o parent-hdr" onClick={this.gotoStore}>GO TO STORE</button>}
                    </div>
                  {!this.props.hideSearch &&
                    <div className="parent-merchant-hdr-wrapper" style={{textAlign: 'left'}}>
                      <Select
                        value={this.state.selectedStore}
                        options={searchOptions}
                        onChange={this.selectChild}
                      />
                    </div>
                  }
                    <div className='parent-merchant-hdr-wrapper parent-hdr-btn-wrapper' style={{textAlign: 'right'}}>
                      <button style={{whiteSpace:"nowrap"}} className="btn-o parent-hdr" onClick = {this.handleLogout}>LOG OUT</button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
})
