import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "react-step-progress-bar/styles.css";

import { Modal, Image } from 'react-bootstrap'
import videoPlayIcon from '../../images/video-play-icon.svg';
import { loadScript } from '../../helpers/Helpers';
import { toast } from 'react-toastify';


import DiyTick from '../../images/DiyTick.png';
// import './NewOnboarding.css';
const ga = window.ga;

export default connect(store => ({ 

  }), dispatch => bindActionCreators({

}, dispatch))
(class Step3Diy extends Component{
    constructor(props){
        super(props);
        loadScript('https://www.youtube.com/iframe_api', window['YT'])
        this.state = {
            showVideoModal: false,
            watchedVideo: false,
            currPage:"step3Start"
        }
        window['onYouTubeIframeAPIReady'] = (e) => {
            this.YT = window['YT'];
        };
        this.players = {}
        this.video = {
            "videoId":"LYGdK3dXiv0",
            "description":"",
        }
    }

    componentDidMount(){
        this.props.sendOnboardingEvent('step3_start_landing');                 
        setTimeout(() => {  this.props.changePage("trainingVideoPage",2) }, 3000);
        if(window.localStorage.getItem("otpVerificationObject"))
        {
            window.localStorage.removeItem("otpVerificationObject");
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.currPage==="step3Start" && this.props.currPage==="trainingVideoPage")
        {
            this.props.sendOnboardingEvent('training_landing'); 
        }
    }

    thumbnailClick = (video) =>{
        ga('send','event','video_tutorials','video_click',video.description);
        this.setState({
            watchedVideo: true,
        })
        this.videoId = video.videoId;
        if(window.innerWidth <= 768){
          loadScript('https://www.youtube.com/iframe_api', window['YT'])
          .then(_=>this.createNewPlayer(video))
          .catch(err=>toast.error('some error occured in player. Please try again'))
          return
        }
        this.setState({showVideoModal:true});
        this.props.sendOnboardingEvent('training_video_started');
    }

    

    createNewPlayer = video =>{

        this.players[video.videoId] = new window['YT'].Player(`player-${video.videoId}`, {
          videoId: video.videoId,
          width:document.getElementById(`player-${video.videoId}`).offsetWidth,
          height:document.getElementById(`player-${video.videoId}`).offsetHeight,
          events: {
            'onReady': (e) => e.target.playVideo(),
          }
        });    
    }

    getMessageAndButton = () => {
        
        let node = null;
        switch(true) {
            case this.props.appInstall && this.props.verificationStatus !== "PENDING" && this.props.testOrder && this.props.commercials.includes("MARKETING"):
                node = <div>
                            <div className="onboarding-diy-pink-container-message" >
                                Congratulations! Your store is now live on magicpin
                            </div>
                            <button disabled={!this.state.watchedVideo} className="onboarding-diy-pink-container-button large-btn" style={{  backgroundColor:!this.state.watchedVideo?"#f7f7fa":"#EF1C74", color:!this.state.watchedVideo?"rgba(0, 0, 0, 0.2)":"#ffffff"  }}  onClick={this.props.handleMpLogin}>Go to Merchant Panel</button>
                        </div>
                break;
            case this.props.appInstall && this.props.verificationStatus !== "PENDING" && this.props.testOrder && !this.props.commercials.includes("MARKETING"):
                node =  <div>
                            <div className="onboarding-diy-pink-container-message" >Open the <b>OrderHere app</b> to take your <b>store live</b> on magicpin</div>
                            <button disabled={!this.state.watchedVideo} className="onboarding-diy-pink-container-button large-btn" style={{  backgroundColor:!this.state.watchedVideo?"#f7f7fa":"#EF1C74", color:!this.state.watchedVideo?"rgba(0, 0, 0, 0.2)":"#ffffff" }}  onClick={this.props.handleOpenApp}>Open App</button>
                        </div> 
                break;
            case this.props.appInstall && !this.props.testOrder:
                node =  <div>
                            <div className="onboarding-diy-pink-container-message" >Open <b>OrderHere app</b> to accept <b>your first order </b>and <b>enable delivery</b> at your store</div>
                            <button disabled={!this.state.watchedVideo} className="onboarding-diy-pink-container-button large-btn" style={{  backgroundColor:!this.state.watchedVideo?"#f7f7fa":"#EF1C74", color:!this.state.watchedVideo?"rgba(0, 0, 0, 0.2)":"#ffffff"}}   onClick={this.props.handleOpenApp}>Open App</button>
                        </div> 
                break;
            case this.props.appInstall && this.props.verificationStatus === "PENDING" && this.props.testOrder && !this.props.commercials.includes("MARKETING"):
                node =  <div>
                            {/* <h4><span><img src={clockIcon}></img></span>Verification pending</h4> */}
                            <div className="onboarding-diy-pink-container-message" >Your verification is in progress, our representative will be in touch with you shortly.</div>
                            <button disabled={true} className="onboarding-diy-pink-container-button large-btn" style={{ backgroundColor:"#f7f7fa",color:"rgba(0, 0, 0, 0.2)"  }}>Go to Merchant Panel</button>
                        </div> 
                break;
            case !this.props.appInstall && this.props.testOrder && !this.props.commercials.includes("MARKETING"):
                node =  <div>
                            {/* <h4><span><img src={download}></img></span>Download App</h4> */}
                            <div className="onboarding-diy-pink-container-message" >Download the app to take your store live on magicpin</div>
                            <button disabled={!this.state.watchedVideo} className="onboarding-diy-pink-container-button large-btn" style={{  backgroundColor:!this.state.watchedVideo?"#f7f7fa":"#EF1C74", color:!this.state.watchedVideo?"rgba(0, 0, 0, 0.2)":"#ffffff"}}   onClick={this.props.handleAppDownload}>Download App</button>
                        </div> 
                break;
            case !this.props.appInstall && !this.props.testOrder && !this.props.commercials.includes("MARKETING"):
                node =  <div>
                            {/* <h4><span><img src={download}></img></span>Download App</h4> */}
                            <div className="onboarding-diy-pink-container-message">Download the app and accept a test order to take your store live on magicpin</div>
                            <button disabled={!this.state.watchedVideo} className="onboarding-diy-pink-container-button large-btn" style={{ backgroundColor:!this.state.watchedVideo?"#f7f7fa":"#EF1C74", color:!this.state.watchedVideo?"rgba(0, 0, 0, 0.2)":"#ffffff" } } onClick={this.props.handleAppDownload}>Download App</button>
                        </div> 
                break;   
        }
        return node;
    }


    render(){
        const video = this.video;
        return(
            <div>
                
                <div style={{width: window.innerWidth < 768 ? "95%":"360px", display: "inline-block", paddingBottom:'30px'}}>
                    
                    {this.props.currPage === "step3Start" && 
                    <div style={{marginTop:'70px'}}>
                        <img className="onboarding-verification-image" src={DiyTick}/>
                        <div className="onboarding-verification-congrats">
                            Congratulations!
                        </div>
                        <div className="onboarding-verification-message">
                            You have succesfully uploaded your legal documents and bank details
                        </div>
                        <button  className="large-btn" style={{marginTop: "60px", width:"90%"}} onClick = {() => {this.props.changePage("trainingVideoPage",2);this.props.sendOnboardingEvent('step3Start_next_botton_clicked');}}> 
                       Step 3 - Partner app Training</button>
                    </div> }


                    { this.props.currPage === "trainingVideoPage" && 
                    <div style={{ marginTop:'55px'}}>
                        <div style={{textAlign:'center', marginTop:'20px'}}>
                            <div style={{ fontSize:"20px"}}>Step 3 - Partner app  Training</div>
                            <div style={{fontSize:'14px', marginTop:'5px', width:"100%", padding:'14px'}}>Watch short video and get the training on how to use the partners app</div>
                        </div> 
                        <div className="onboarding-diy-video-container">
                            <div className="onboarding-diy-video-thumbnailImage" id={`player-${video.videoId}`} style={{background:`url(https://i.ytimg.com/vi/${video.videoId}/mqdefault.jpg)`, backgroundRepeat:'no-repeat', backgroundPosition:'center', backgroundSize:'cover'}}>    
                                <Image className="video-play-icon play-icon pointer" src={videoPlayIcon} responsive  onClick={()=>this.thumbnailClick(video)}/>  
                            </div>  
                        </div>
                        <div className='onboarding-redeem-details-container'>
                            <div className='onboarding-redeem-details-container-inside'>
                                <p className='onboarding-steps-heading-redeem-details' >Steps to redeem voucher via App</p>
                                <ul style={{paddingLeft:'4%'}}>
                                <li className='onboarding-circle-list'>Login in OrderHere App</li>
                                <li className='onboarding-circle-list'>Select your store</li>
                                <li className='onboarding-circle-list'>Select "Redeem Voucher" in More Tab </li>
                                <li className='onboarding-circle-list'>Add Voucher Code magic20</li>
                                <li className='onboarding-circle-list'>Click on Submit</li>
                                </ul>
                            </div>
                        </div>
                        <div className="onboarding-diy-pink-container">
                            <div className="onboarding-diy-pink-background">
                                <div style={{  fontFamily:'robotobold'}}>
                                    Watched training video?
                                </div>
                                {/* <div style={{  color:'#A0A0A0', marginTop:'5px'}}>
                                    Download the magicpin partner app to complete your training.
                                </div>

                                <button  className="large-btn" style={{ width:"258px", borderRadius:'4px', marginTop:'24px', height:'40px'  }} onClick = {() => {this.props.changePage("step3Start",2)}}> 
                                    Download App
                                </button> */}
                                {
                                    this.getMessageAndButton()
                                }
                            
                            </div> 
                        </div>                     
                    </div> }
                    
                </div>
                {
                    this.state.showVideoModal && 
                    <Modal show={this.state.showVideoModal} onHide={()=>this.setState({showVideoModal:false})}>
                        <Modal.Header className="video-tut-close" closeButton></Modal.Header>
                        <Modal.Body>
                        <iframe src={`https://www.youtube.com/embed/${this.videoId}?autoplay=1`} width="100%" height="400px" frameBorder="0"
                                webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen="true">
                        </iframe>
                        </Modal.Body>
                    </Modal>
                    
                }
                
            </div>                    
        )
    }
})