import React from 'react';
import { connect } from 'react-redux';
import {loginShortUrl} from '../../redux/modules/authReducers/auth';
import Loader from '../Loader/Loader'

export default connect(store=>({
}),{loginShortUrl})
(
    class ShortUrl extends React.Component{
        constructor(props){
            super(props);
            this.params = new URLSearchParams(this.props.location.search);
            localStorage.removeItem('accessToken')
        }

        componentDidMount(){
            if(this.params.has('value')){
                this.props.loginShortUrl(this.params.get('value'), this.params.get('utm_source'))
                .catch(err=>this.props.push('/login'))
            }
            else this.props.push('/login')
        }
        render(){
            return(<Loader/>)
        }

    }
)