const SHOW_CONFIRM_POPUP = 'confirm/SHOW_CONFIRM_POPUP';

const initialState = {
    launching: false,
    launched: false,
    showConfirmPopup: false,
}

let callbackMethod, callbackArgs, callbackScope;

let emptyCallback = () => {

}

export default function confirm(state = initialState, action = {}) {
    switch (action.type) {
        case SHOW_CONFIRM_POPUP :
            return{
                ...state,
                showConfirm : action.showConfirm,
                message : action.message,
                yesText : action.yesText,
                noText : action.noText,
                title: action.title,
            }
        default:
            return state;
    }
}


export function showConfirmPopup(showConfirm, scope, callback, args=[], message='', title='', yesText='', noText='') {
    callbackMethod = callback
    callbackArgs = args
    callbackScope = scope
    return {
        type: SHOW_CONFIRM_POPUP,
        showConfirm,
        message,
        yesText,
        noText,
        title
    }
}

export function isConfirmed() {
    // showConfirmPopup(false, emptyCallback, [], "","","")
    // callbackMethod()
    callbackMethod.apply(callbackScope,callbackArgs)
    return {
        type: SHOW_CONFIRM_POPUP,
        showConfirm: false,
        message: "",
        title: "",
        yesText: "",
        noText: "",
    }
}

export function closeConfirm() {
    callbackMethod = emptyCallback
    callbackArgs = []
    callbackScope = null
    return {
        type: SHOW_CONFIRM_POPUP,
        showConfirm: false,
        message: "",
        title: "",
        yesText: "",
        noText: "",
    }
    // return showConfirmPopup(false, emptyCallback, [], "","","")
}