import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Dropdown } from 'semantic-ui-react'
import { isValidPhone } from '../../helpers/Helpers'
import Human from '../../images/human.svg'
import CoverImage from './CoverImage'
import PrimaryButton from './PrimaryButton'

const BannerInterested = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');


    const firstNameHandler = (event) => {
        setFirstName(event.target.value);
    }

    const lastNameHandler = (event) => {
        setLastName(event.target.value);
    }

    const mobileHandler = (event) => {
        setMobile(event.target.value);
    }

    const submitHandler = () => {
        let data = {
            "merchantId": props.user.merchantId,
            "name": `${firstName} ${lastName}`,
            "phoneNumber": mobile,
        }
        if(!firstName || firstName === '') {
            toast.warn('First Name cannot be empty');
            return;
        } 

        if(!lastName || lastName === '') {
            toast.warn('Last Name cannot be empty');
            return;
        } 

        if(!mobile || !isValidPhone(mobile)) {
            toast.warn('Invalid Mobile Number');
            return;
        }
        props.onSubmit(data);
    }

    return (
        <Modal dialogClassName='modal-dialog new-modal-small small-rp-modal' show={props.show} style={{ height: 'auto' }} onHide={props.onHide}>
            <div className="modal-content" style={{ height: 'auto' }}>
                <div className="modal-header" style={{ marginTop: '0' }}>
                    <h2 style={{ textTransform: 'none' }}>Fill your information</h2>
                    <div onClick={props.onHide} className="dialog-cross fa fa-close" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    </div>
                </div>
                <div className="modal-body">
                    <div style={{ padding: '2vw 4vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {window.innerWidth > 768 && <div style={{ marginRight: '60px' }}>
                            <CoverImage coverImageComponent={Human} />
                        </div>}
                        <div className='modalContent'>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <div style={{ color: '#A0A0A0' }}>First Name <span style={{color:'red'}}>*</span> </div>
                                    <input onChange={firstNameHandler} className='customPlaceholder' placeholder='First Name' />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <div style={{ color: '#A0A0A0' }}>Last Name <span style={{color:'red'}}>*</span> </div>
                                    <input onChange={lastNameHandler} className='customPlaceholder' placeholder='Last Name' />
                                </div>
                            </div>
                            {/* <div>
                                <div style={{ color: '#A0A0A0', marginTop: '20px' }}>Email Address (optional)</div>
                                <input onChange={emailHandler} className='customPlaceholder' placeholder='Email Address' />
                            </div> */}
                            <div>
                                <div style={{ color: '#A0A0A0', marginTop: '20px' }}>Mobile Number <span style={{color:'red'}}>*</span> </div>
                                <div className='customPlaceholder' style={{ display: 'flex', justifyContent: 'space-between', padding: '3px' }}>
                                    <div className='dropdownBorder' style={{ width: '155px', border: 'none', paddingRight: '15px' }}>
                                        <Dropdown
                                            defaultValue={"india"}
                                            fluid
                                            search
                                            selection
                                            options={[{ key: 'in', value: 'india', flag: 'in', text: '+91' }]} />
                                    </div>
                                    <input onChange={mobileHandler} style={{ width: '100%', border: 'none' }} placeholder='Enter Mobile Number' />
                                </div>
                            </div>
                            {/* <div>
                                <div style={{ color: '#A0A0A0', marginTop: '20px' }}>Message (optional)</div>
                                <input onChange={messageHandler} style={{ height: '100px' }} className='customPlaceholder' placeholder='Message' />
                            </div> */}
                        </div>
                    </div>
                    <div style={{ justifyContent: 'end', display: 'flex', alignItems: 'center', padding: '2vw 4vw', paddingTop: '0' }}>
                        <div onClick={props.onHide} className='paragraph' style={{ margin: 'auto 20px', color: '#EF1C74', cursor: 'pointer' }}>Cancel</div>
                        <div disabled={props.bannerInterestedLoading} ><PrimaryButton buttonName={props.bannerInterestedLoading ? "Submitting..." : "Submit Request"} onClick={submitHandler} /></div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BannerInterested