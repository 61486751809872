const LOAD = 'voucherSoldData/LOAD';
const LOAD_SUCCESS = 'voucherSoldData/LOAD_SUCCESS';
const LOAD_FAIL = 'voucherSoldData/LOAD_FAIL';

const initialState = {
    voucherSoldDataLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {...state, voucherSoldDataLoading: true}

    case LOAD_SUCCESS:
      return {
        ...state,
        voucherSoldDataLoading: false,
        voucherSoldDataLoaded: true,
        voucherSoldDataError: null,
        voucherSoldDataData: action.result}

    case LOAD_FAIL:
      return {
        ...state,
        voucherSoldDataLoading: false,
        voucherSoldDataLoaded: false,
        voucherSoldDataData: null,
        voucherSoldDataError: action.error} 

   default:
      return state;
  }
}

export function getVoucherSoldData(accessToken, startDate, endDate) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post(`/getVoucherSoldData`, {
      data: {accessToken, startDate, endDate}
    })
  };
}
