import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {toast} from 'react-toastify';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { currencyString } from "../../helpers/Helpers";
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import infoIconWhite from '../../images/home-delivery-info-icon-small-white.svg';
import infoIconGrey from '../../images/home-delivery-info-icon-grey.png';
import './HomeDelivery.css';

import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: "10px",
    width: window.innerWidth > 768 ? "324px" : "250px"
  },
  closeButton: {
    position: 'absolute',
    right: "5px",
    top: "5px",
    color: "gray",
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "16px",
    textAlign: "center"
  },
}))(MuiDialogContent);

export default class UpdatePopupDialog extends Component {

  // state = {
  //   enteredTextForName: "",
  //   enteredTextForPhoneNo: ""
  // }

performAction = () => {
    if(this.props.currStep === "PENDING"){
      this.props.sendGbAndGbqEventsOrder('order_page_accept_popup_clicked','order_page_accept_popup_clicked','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }
    else if(this.props.currStep === "ACCEPTED"){
      this.props.sendGbAndGbqEventsOrder('order_page_bill_submit_popup_clicked','order_page_bill_submit_popup_clicked','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }
    else{
      this.props.sendGbAndGbqEventsOrder('order_page_dispatch_popup_clicked','order_page_dispatch_popup_clicked','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }

    // console.log("DELIVERY PX NAME: ", this.props.enteredTextForName)
    // console.log("DELIVERY PX PHONE NUMBER: ", this.props.enteredTextForPhoneNo)
    
    if(!!this.props.enteredTextForName && !!this.props.enteredTextForPhoneNo) {
      let validPhone = this.props.enteredTextForPhoneNo.match(/^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/g);
      let validName = this.props.enteredTextForName.match(/^[a-zA-Z ]*$/g);
      
      if(validName && validPhone) {
        document.getElementById("deliveryPxName").value = "";
        document.getElementById("deliveryPxPhone").value = "";
        // console.log("validName: ", validName, " Number: ", validPhone);
        this.props.performAction()
        this.props.createOrAddDeliveryPxDetails()
      } else if(validName && !validPhone) {
        toast.error(`Please enter a valid 10-digit number`);
      } else if(!validName && validPhone) {
        toast.error(`Delivery person's name should only contain alphabets.`);
      } else {
        toast.error(`Invalid name and phone number. Name should only contain alphabets and phone number should be 10-digit`);
      }

    } else {
      this.props.performAction()
    }
}

closePopUp = () =>{
    if(this.props.currStep === "PENDING"){
      this.props.sendGbAndGbqEventsOrder('order_page_accept_popup_closed','order_page_accept_popup_closed','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }
    else if(this.props.currStep === "ACCEPTED"){
      this.props.sendGbAndGbqEventsOrder('order_page_bill_submit_popup_closed','order_page_bill_submit_popup_closed','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }
    else{
      this.props.sendGbAndGbqEventsOrder('order_page_dispatch_popup_closed','order_page_dispatch_popup_closed','singleOrderItem', this.props.orderData ? this.props.orderData.orderId : '')
    }
    this.props.closePopUp()
}

// handleOnChange = (event, field) => {
//   let enteredText = event.target.value
//   if(field === "NAME") {
//     this.setState({enteredTextForName: enteredText})
//     console.log("", this.state.enteredTextForName)
//   } else if(field === "PHONE_NUMBER") {
//     this.setState({enteredTextForPhoneNo: enteredText})
//     console.log("", this.state.enteredTextForPhoneNo)
//   }
// }

render () {
  let suggestedItemsPrice = 0
  if(this.props.suggestedItems && this.props.suggestedItems.length > 0) {
    this.props.suggestedItems.map(item => {
      let itemPrice = item[this.props.orderData.orderId].amount * item[this.props.orderData.orderId].qtyUpperBound
      suggestedItemsPrice += itemPrice
    })
  }

  if(this.props.orderData.deliveryCost && this.props.orderData.deliveryCost.length > 0) {
    this.props.orderData.deliveryCost.map(rule => {
        if(rule.deliveryInitiator == "MERCHANT" && (rule.merchantCommission || rule.merchantCommission === 0)) {
          this.merchantCommissionForMerchantDelivery = rule.merchantCommission                        
        } else if(rule.deliveryInitiator == "MAGICPIN" && (rule.merchantCommission || rule.merchantCommission === 0)) {
            this.merchantCommissionForMagicpinDelivery = rule.merchantCommission
            this.costToMerchantForMagicpinDelivery = rule.costToMerchant
        }
    })
}


  if(this.props.showPriceBreakup) {
    return (
      <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
            {/* Improve rank
            <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
        </DialogTitle>
        
        <DialogContent dividers>
{
  this.props.orderData &&
  <div className="item-price-breakup" style={{padding: '0px'}}>
    <div style={{padding: '0px 42px 30px 42px', fontSize: '16px', fontFamily: 'robotomedium'}}>Please verify the bill details</div>
     {this.props.totalItemCount > 0 && 
          <div className="item-price-show" style={{fontSize: '14px'}}>
             <div>Available items ({this.props.totalItemCount})</div>
             <div>{currencyString(this.props.totalItemAmount , this.props.user.country,1)}</div>
          </div>
      } 
     {this.props.totalVoucherCount > 0 && 
          <div className="item-price-show" style={{fontSize: '14px'}}>
             <div>Voucher items ({this.props.totalVoucherCount})</div>
             <div>{currencyString(this.props.totalVoucherAmount, this.props.user.country,1)}</div>
          </div>
      }
     {this.props.totalSuggestedCount > 0 && 
          <div className="item-price-show" style={{fontSize: '14px'}}>
              <div>Suggested items ({this.props.totalSuggestedCount})</div>
              <div>{currencyString(this.props.totalSuggestedAmount, this.props.user.country,1)}</div>
          </div>
      }
     <div className="item-price-total-show" style={{fontSize: '16px', padding: '10px 0px', marginTop: '10px', borderTop: '2px dashed rgba(0,0,0,0.12)', borderBottom: '2px dashed rgba(0,0,0,0.12)'}}>
          <div>Total amount</div>
          <div className="item-price-tot-bar" style={{padding: '0px'}}>{currencyString(this.props.totalBillValue, this.props.user.country,1)}</div>
     </div>
     {/* {this.props.deliveryType == "MERCHANT" && <span style={{fontFamily: 'robotolight', fontSize: '13px'}}><div style={{paddingTop: '3%'}}>You will earn an extra {currencyString(this.props.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)} delivery fee</div></span>} */}
     {this.props.deliveryType == "MERCHANT" && this.merchantCommissionForMerchantDelivery && <span style={{fontFamily: 'robotolight', fontSize: '13px'}}><div style={{paddingTop: '3%'}}>You will earn an extra {currencyString(this.merchantCommissionForMerchantDelivery, this.props.user.country, 0)} delivery fee</div></span>}
     <div style={{fontSize: '12px', textAlign: 'start', borderBottom: '1px solid rgba(0,0,0,0.12)', fontFamily: 'robotoregular', padding: '5% 5% 0 5%', marginBottom: '5%'}}>
        Payment by customer can vary based on suggested item’s selection
      </div>
      {this.props.deliveryType === "MERCHANT" && <div style={{fontSize: '14px', fontFamily: 'robotomedium', backgroundColor: '#d9f3f4', borderRadius: '4px', textAlign: 'left', padding: '5%'}}>
        Are you sure you want to deliver this order on your own?<br/>
        Please make sure you deliver within 2 hours.
        </div>}
     {this.props.orderData && (this.props.orderData.orderType === "PICK_UP" || (this.props.deliveryType && this.props.deliveryType === "MAGICPIN")) && <div>
        <div style={{fontSize: '14px', padding: '10px 24px', width: '100%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
            Yes, send it
        </div>
      </div>}
      {this.props.deliveryType && this.props.deliveryType === "MERCHANT" && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
        <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%', backgroundColor: '#fde8f1', color: '#ef1c74'}} className="merchant-primary-action-btn" onClick={() => this.closePopUp()}>
            No
        </div>
        <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
            Yes, I will deliver
        </div>
      </div>}
      
  </div>
}        </DialogContent>
      </Dialog>
  );
  } else {
      if(this.props.orderData && this.props.deliveryType == "MERCHANT" && this.props.user && !this.props.user.isParentMerchant && this.props.orderData.source != "orderhere" && this.props.orderData.versionCode && this.props.orderData.versionCode >= 806 && this.props.orderData.versionCode < 2000) {  
        return (
            <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
              <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
                  {/* Improve rank
                  <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
              </DialogTitle>
              
              <DialogContent dividers>
              <div style={{fontFamily: 'robotomedium', color: 'rgba(0,0,0,0.54)', textAlign: 'left', padding: '0 0 1% 0'}}>YOU HAVE SELECTED</div>
              <div style={{display: 'flex', margin: '0 0 5% 0', borderRadius: '4px', color: '#fff', maxWidth: '100px', padding: '4px 4px 4px 6px', backgroundColor: '#8501ba', fontSize: '12px', fontFamily: 'robotomedium'}}>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{`You have to deliver this order on your own.`} </Tooltip>}>
                    <img src={infoIconWhite} style={{width: '14px', marginRight: '4px'}}></img>
                </OverlayTrigger>
                <div style={{fontStyle: 'italic'}}>Self delivery</div>
              </div>
              {this.props.orderData &&
                <div className="item-price-breakup" style={{padding: '0px'}}>       
                    
                    {this.props.orderData.onTimeOrFree && this.props.orderData.onTimeOrFree.enabled && this.props.orderData.OTOFMerchantData && this.props.orderData.OTOFMerchantData.merchantTime && this.props.orderData.OTOFMerchantData.merchantTimeMerchantDelivery
                      ?
                        <div style={{fontSize: '14px', border: '1px solid #00acb7', fontFamily: 'robotomedium', backgroundColor: '#d9f3f4', borderRadius: '4px', textAlign: 'left', padding: '5%'}}>
                            <div style={{color: '#00acb7', fontSize: '18px'}}>Make sure the order is delivered within <span style={{color: '#ff7530'}}>{Math.trunc((this.props.orderData.OTOFMerchantData.merchantTimeMerchantDelivery / 60), 0)} MINS</span></div>
                            <div>
                              Please ensure that the order is marked delivered as soon as it reaches the customer to avoid penalty.
                              {this.props.user && !this.props.user.isParentMerchant && this.props.orderData && this.props.orderData.source != "orderhere" && this.props.orderData.versionCode && this.props.orderData.versionCode >= 806 && this.props.orderData.versionCode < 2000
                                && this.props.orderData.onTimeOrFree && this.props.orderData.onTimeOrFree.enabled
                                && <OverlayTrigger placement="top" overlay={<Tooltip style={{zIndex: '9999'}} id="tooltip">{`${this.props.orderData.OTOFMerchantData.merchantDelayCostPercent}% amount will be deducted over and above commission deduction and loan recovery if applicable.`}</Tooltip>}>
                                    <img src={infoIconGrey} style={{opacity: 'unset', marginLeft: '3px', width: '14px'}}/> 
                                </OverlayTrigger>}
                            </div>
                        </div>
                      :
                        <div style={{fontSize: '14px', border: '1px solid #00acb7', fontFamily: 'robotomedium', backgroundColor: '#d9f3f4', borderRadius: '4px', textAlign: 'left', padding: '5%'}}>
                            <div style={{color: '#00acb7', fontSize: '18px'}}>Make sure the order is delivered within <span style={{color: '#ff7530'}}>{Math.trunc((this.props.orderData.OTOFMerchantData.merchantTimeMerchantDelivery / 60), 0)} MINS</span></div>
                            <div>Please ensure that the order is marked delivered as soon as it reaches the customer.</div>
                        </div>
                    }
                  {/* Delivery Px details */}
                  {<div style={{padding: '2% 0'}}>
                    
                    <div style={{padding: '4% 0 2%'}}>
                    {!(this.props.enteredTextForPhoneNo.length > 0 && this.props.enteredTextForName === "")
                        && <div style={{textAlign: 'left', fontSize: '16px', paddingBottom: '1%'}}>Delivery person's name</div>}

                      {this.props.enteredTextForPhoneNo.length > 0 && this.props.enteredTextForName === ""
                       && <div style={{textAlign: 'left', fontSize: '16px', paddingBottom: '1%'}}>Delivery person's name <span style={{color: '#ef1c74'}}>(* required)</span></div>}

                      <div style={{maxWidth: window.innerWidth > 768 ? '50%' : '100%', margin: '0 0 0 2%', border: '1px solid rgba(0,0,0,0.54)', padding: '2% 3%', borderRadius: '4px'}}>
                        <input style={{width: '100%', border: 'none'}} id="deliveryPxName" autocomplete="off" placeholder="Eg. Rajesh Singh" onChange={(e) => this.props.handleOnChange(e, "NAME")} />
                      </div>
                    </div>

                    {this.props.listOfDeliveryPx && this.props.listOfDeliveryPx.length && this.props.listOfDeliveryPx.length > 0
                      ? <div style={{display: 'flex', flexWrap: 'wrap'}}> {this.props.listOfDeliveryPx.map((contact, idx) => idx < 5 && !!contact.name && !!contact.mobileNumber && (
                            <div style={{cursor: 'pointer', color: 'rgba(0,0,0,0.54)', background: '#f7f7fa', border: '1px solid rgba(0,0,0,0.12)', borderRadius: '25px', padding: '1% 2%', margin: window.innerWidth > 786 ? '0 2% 1% 0' : '0 3% 2% 0'}} onClick={() => this.props.tapToFillDeliveyPxDetails(contact)}>
                              {contact.name}
                            </div>))}
                         </div>
                      :
                        null
                    }

                    <div style={{padding: '4% 0 2%'}}>                      
                      {!(this.props.enteredTextForName.length > 0 && this.props.enteredTextForPhoneNo === "")
                        && <div style={{textAlign: 'left', fontSize: '16px', paddingBottom: '1%'}}>Delivery person's contact number</div>}

                      {this.props.enteredTextForName.length > 0 && this.props.enteredTextForPhoneNo === ""
                       && <div style={{textAlign: 'left', fontSize: '16px', paddingBottom: '1%'}}>Delivery person's contact number <span style={{color: '#ef1c74'}}>(* required)</span></div>}

                      <div style={{maxWidth: window.innerWidth > 768 ? '50%' : '100%', margin: '0 0 0 2%', border: '1px solid rgba(0,0,0,0.54)', padding: '2% 3%', borderRadius: '4px'}}>
                        <input style={{width: '100%', border: 'none'}} id="deliveryPxPhone" autocomplete="off" placeholder="Eg. 9876543210" onChange={(e) => this.props.handleOnChange(e, "PHONE_NUMBER")} />
                      </div>
                    </div>
                  </div>}
                  
                  {/* {this.props.orderData && (this.props.orderData.orderType === "PICK_UP" || (this.props.deliveryType && this.props.deliveryType === "MAGICPIN")) && <div>
                      <div style={{fontSize: '14px', padding: '10px 24px', width: '100%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
                          Yes, send it
                      </div>
                    </div>} */}
                    {this.props.deliveryType && this.props.deliveryType === "MERCHANT" && <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%', backgroundColor: '#fde8f1', color: '#ef1c74'}} className="merchant-primary-action-btn" onClick={() => this.closePopUp()}>
                          No
                      </div>
                      {((this.props.enteredTextForName === "" && this.props.enteredTextForPhoneNo === "")
                       || (this.props.enteredTextForName.length > 0 && this.props.enteredTextForPhoneNo.length > 0))
                        ?
                         <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
                          Yes, I will deliver
                         </div>
                        :
                         <div style={{fontSize: '14px', backgroundColor: 'rgba(0,0,0,0.12)', color: 'rgba(0,0,0,0.54)', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn">
                          Yes, I will deliver
                         </div>
                      }
                    </div>}
                    
                </div>
              }        
            </DialogContent>
          </Dialog>
        );
    } else if (this.props.orderData && this.props.deliveryType === "MAGICPIN" && this.props.user && !this.props.user.isParentMerchant && this.props.orderData.source != "orderhere" && this.props.orderData.versionCode && this.props.orderData.versionCode >= 806 && this.props.orderData.versionCode < 2000){
      return (
        <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
          </DialogTitle>
          
          <DialogContent dividers>
          
          <div style={{fontFamily: 'robotomedium', color: 'rgba(0,0,0,0.54)', textAlign: 'left', padding: '0 0 1% 0'}}>YOU HAVE SELECTED</div>
          <div style={{display: 'flex', borderRadius: '4px', color: '#fff', maxWidth: '120px', padding: '4px 4px 4px 6px', backgroundColor: '#2e108e', fontSize: '12px', fontFamily: 'robotomedium'}}>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{`magicpin will assign an agent to deliver this order.`} </Tooltip>}>
                <img src={infoIconWhite} style={{width: '14px', marginRight: '4px'}}></img>
            </OverlayTrigger>
            <div style={{fontStyle: 'italic'}}>{`Partner delivery`}</div>
          </div>

          {this.props.orderData &&
            <div className="item-price-breakup" style={{padding: '0px'}}>       
                <div style={{fontSize: '18px', fontFamily: 'robotomedium', borderRadius: '4px', textAlign: 'left', padding: '5% 5% 2%'}}>
                   Are you sure you want to choose partner assisted delivery?<br/>
                </div>
                {this.props.orderData.OTOFMerchantData && this.props.orderData.OTOFMerchantData.merchantTime && this.props.orderData.OTOFMerchantData.merchantTimeMagicpinDelivery
                  ?
                    <div style={{margin: '1% 5%', fontSize: '14px', border: '1px solid #00acb7', fontFamily: 'robotomedium', backgroundColor: '#d9f3f4', borderRadius: '4px', textAlign: 'left', padding: '4%'}}>
                        <div style={{color: '#00acb7'}}>Make sure you prepare the order within <span style={{color: '#ff7530'}}>{this.props.merchantKptData && this.props.merchantKpt ? this.props.merchantKpt : Math.trunc((this.props.orderData.OTOFMerchantData.merchantTimeMagicpinDelivery / 60), 0)} mins</span>. Mark the order packed once ready.</div>
                    </div>
                  :
                    <div style={{margin: '1% 5%', fontSize: '14px', border: '1px solid #00acb7', fontFamily: 'robotomedium', backgroundColor: '#d9f3f4', borderRadius: '4px', textAlign: 'left', padding: '4%'}}>
                        <div style={{color: '#00acb7', fontSize: '18px'}}>Make sure you prepare the order within time. Mark the order packed once ready.</div>
                    </div>
                }

                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                  <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%', backgroundColor: '#fde8f1', color: '#ef1c74'}} className="merchant-primary-action-btn" onClick={() => this.closePopUp()}>
                      No
                  </div>
                  <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
                      Yes
                  </div>
                </div>
                
            </div>
          }        
        </DialogContent>
      </Dialog>
    );
    } else if (this.props.user && this.props.orderData && (this.props.user.isParentMerchant || this.props.orderData.source == "orderhere" || !(this.props.orderData.versionCode && this.props.orderData.versionCode >= 806 && this.props.orderData.versionCode < 2000))) {
      return (
        <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
              {/* Improve rank
              <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
          </DialogTitle>
          
          <DialogContent dividers>
            {this.props.deliveryType && this.props.deliveryType === "MERCHANT" 
              && <div className="item-price-breakup" style={{padding: '0px'}}>
                  <div style={{fontSize: '1.8rem', fontFamily: 'robotomedium', borderRadius: '4px', textAlign: 'left', padding: '5%'}}>
                    Are you sure you want to deliver this order on your own?<br/>
                    Please make sure you deliver within 45 mins.
                  </div>
                    {<div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%', backgroundColor: '#fde8f1', color: '#ef1c74'}} className="merchant-primary-action-btn" onClick={() => this.closePopUp()}>
                          No
                      </div>
                      <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
                          Yes, I will deliver
                      </div>
                    </div>}
                </div>
              }
          </DialogContent>
        </Dialog>
      );
    } else {
      return (
        <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
          <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
              {/* Improve rank
              <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
          </DialogTitle>
          
          <DialogContent dividers>
            {this.props.deliveryType && this.props.deliveryType === "MERCHANT" 
              && <div className="item-price-breakup" style={{padding: '0px'}}>
                  <div style={{fontSize: '1.8rem', fontFamily: 'robotomedium', borderRadius: '4px', textAlign: 'left', padding: '5%'}}>
                    Are you sure you want to deliver this order on your own?<br/>
                    Please make sure you deliver within 45 mins.
                  </div>
                    {<div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%', backgroundColor: '#fde8f1', color: '#ef1c74'}} className="merchant-primary-action-btn" onClick={() => this.closePopUp()}>
                          No
                      </div>
                      <div style={{fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: window.innerWidth > 768 ? '10px 20px' : '5px 10px', width: '40%'}} className="merchant-primary-action-btn" onClick={() => this.performAction()}>
                          Yes, I will deliver
                      </div>
                    </div>}
                </div>
              }
          </DialogContent>
        </Dialog>
      );
    }
  }
  }
}

/**
 * 
 * 
 */