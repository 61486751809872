const LOADING_ONBOARDING_ACTIONS = 'newMerchantAction/LOADING_ONBOARDING_ACTIONS'
const LOADED_ONBOARDING_ACTIONS = 'newMerchantAction/LOADED_ONBOARDING_ACTIONS'
const FAILED_ONBOARDING_ACTIONS = 'newMerchantAction/FAILED_ONBOARDING_ACTIONS'

const UPDATING_MOU_SHOWN = 'newMerchantAction/UPDATING_MOU_SHOWN'
const UPDATED_MOU_SHOWN = 'newMerchantAction/UPDATED_MOU_SHOWN'
const ERROR_MOU_SHOWN = 'newMerchantAction/ERROR_MOU_SHOWN'

const initialstate = {
    
};
export default function newMerchantAction(state=initialstate, action){
    switch(action.type){
        case LOADING_ONBOARDING_ACTIONS:
            return {
                ...state,
                loadingAction: true
            }
        case LOADED_ONBOARDING_ACTIONS:
            let {commentCustomerPost, confirmBooking, inviteCustomer, promote, showMou} = action.result
            return {
                ...state,
                loadingAction: false,
                availableAction: {
                    promoteAction: promote || false,
                    // commentCustomerPostAction: commentCustomerPost,
                    confirmBookingAction: confirmBooking || false,
                    inviteCustomerAction: inviteCustomer || false,
                    showMouAction: showMou || false,
                },
                actionError: null
            }
        case FAILED_ONBOARDING_ACTIONS:
            return {
                ...state,
                loadingAction: false,
                actionError: action.error
            }

        case UPDATING_MOU_SHOWN:
            return {
                ...state,
                updating: true
            }
        case UPDATED_MOU_SHOWN:
            return {
                ...state,
                updating: false,
                updated: true
            }
        case ERROR_MOU_SHOWN:
            return {
                ...state,
                updating: false,
                updated: false,
            }
        default:
            return state
    }
}

export function availableActions(accessToken){
    return{
    types: [LOADING_ONBOARDING_ACTIONS, LOADED_ONBOARDING_ACTIONS, FAILED_ONBOARDING_ACTIONS],
    promise: client => client.default.post('/featureEducation', {
        data:{ accessToken }
    })
    }
}

export function updateMouSeen(accessToken){
    return{
        types:[UPDATING_MOU_SHOWN, UPDATED_MOU_SHOWN, ERROR_MOU_SHOWN],
        promise: client => client.default.post('/updateMouSeen', {
            data:{accessToken}
        })
    }
}