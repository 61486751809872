import crypto from 'crypto-js';
import moment from 'moment';

const LOAD_MERCHANTS = 'forgotpassword/LOAD_MERCHANTS';
const LOAD_MERCHANTS_SUCCESS = 'forgotpassword/LOAD_MERCHANTS_SUCCESS';
const LOAD_MERCHANTS_FAIL = 'forgotpassword/LOAD_MERCHANTS_FAIL';

const GET_OTP = 'forgotpassword/GET_OTP';
const GET_OTP_SUCCESS = 'forgotpassword/GET_OTP_SUCCESS';
const GET_OTP_FAIL = 'forgotpassword/GET_OTP_FAIL';

const CHANGE_PASSWORD = 'forgotpassword/CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'forgotpassword/CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'forgotpassword/CHANGE_PASSWORD_FAIL';

const LOAD_MOBILES = 'forgotpassword/LOAD_MOBILES';
const LOAD_MOBILES_SUCCESS = 'forgotpassword/LOAD_MOBILES_SUCCESS';
const LOAD_MOBILES_FAIL = 'forgotpassword/LOAD_MOBILES_FAIL';

const GET_OWNER_NUMBER = 'forgotpassword/GET_OWNER_NUMBER';
const GET_OWNER_NUMBER_SUCCESS = 'forgotpassword/GET_OWNER_NUMBER_SUCCESS';
const GET_OWNER_NUMBER_FAIL = 'forgotpassword/GET_OWNER_NUMBER_FAIL';

const initialState = {
  merchantsLoaded: false,
  mobilesLoaded: false,
  token: '',
  mobiles: [],
  merchants: [],
  ownerPhone:[],
  otpSent: false,
  passwordChanged: false,
};

export default function forgotPassword(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_MOBILES:
      return {
        ...state,
        mobilesLoading: true,
      };
    case LOAD_MOBILES_SUCCESS:
      return {
        ...state,
        mobilesLoading: false,
        mobilesLoaded: true,
        token: action.result.token,
        mobiles: action.result.mobiles,
      };
    case LOAD_MOBILES_FAIL:
      return {
        ...state,
        mobilesLoading: false,
        mobilesLoaded: true,
        mobilesError: action.error
      };
    case LOAD_MERCHANTS:
      return {
        ...state,
        merchantsLoading: true,
      };
    case LOAD_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchantsLoading: false,
        merchantsLoaded: true,
        token: action.result.token,
        merchants: action.result.merchants,
        merchantsError: null,
      };
    case LOAD_MERCHANTS_FAIL:
      return {
        ...state,
        merchantsLoading: false,
        merchantsLoaded: true,
        merchantsError: action.error,
        token: null,
        merchants: null,
      };
    case GET_OTP:
      return {
        ...state,
        otpSending: true
      };
    case GET_OTP_SUCCESS:
      return {
        ...state,
        otpSending: false,
        otpSent: true,
      };
    case GET_OTP_FAIL:
      return {
        ...state,
        otpSending: false,
        otpSent: false,
        otpError: action.error
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        passwordChanging: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanging: false,
        passwordChanged: true,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        passwordChanging: false,
        passwordChanged: false,
        changeError: action.error
      };
    case GET_OWNER_NUMBER:
    return {
      ...state,
      ownerPhoneLoading: true,
      ownerPhone:[],
    };
    case GET_OWNER_NUMBER_SUCCESS:
      return {
        ...state,
        ownerPhoneLoading: false,
        ownerPhone: action.result,
      };
    case GET_OWNER_NUMBER_FAIL:
      return {
        ...state,
        ownerPhoneError: action.error
      };
    default:
      return state;
  }
}

export function getMerchantsfromMobile(mobile) {
  return {
    types: [LOAD_MERCHANTS, LOAD_MERCHANTS_SUCCESS, LOAD_MERCHANTS_FAIL],
    promise: (client) => client.default.post('/getMerchantsFromMobile', {
      data: {
        mobile
      }
    })
  };
}

export function forgotRequestOtp(token, sendSixDigitOtp = false) {
  return {
    types: [GET_OTP, GET_OTP_SUCCESS, GET_OTP_FAIL],
    promise: (client) => client.default.post('/forgotRequestOtp', {
      data: {
        token, sendSixDigitOtp
      }
    })
  };
}

export function requestOtp(token, phoneIndex) {
  return {
    types: [GET_OTP, GET_OTP_SUCCESS, GET_OTP_FAIL],
    promise: (client) => client.default.post('/requestOtp', {
      data: {
        token,
        phoneIndex
      }
    })
  };
}


export function updatePassword(token, merchantIndex, otp, newpassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: (client) => client.default.post('/updatePassword', {
      data: {
        token,
        merchantIndex,
        otp,
        newpassword
      }
    })
  };
}

export function loadMobiles(username) {
  return {
    types: [LOAD_MOBILES, LOAD_MOBILES_SUCCESS, LOAD_MOBILES_FAIL],
    promise: (client) => client.default.post('/getMobiles', {
      data: {
        username
      }
    })
  };
}

export const getOwnerMobileFromMerchantId = (merchantId) => {
  const secretKey = `forgot-password-auth-${merchantId}`;
  var epoch = moment().utc().unix();
  const cipher = crypto.AES.encrypt(epoch.toString(),secretKey).toString();
  return{
    types:[GET_OWNER_NUMBER, GET_OWNER_NUMBER_SUCCESS, GET_OWNER_NUMBER_FAIL],
    promise:(client) => client.default.post('/getOwnerPhoneFromMid',{
      data:{merchantId, cipher}
    })
  }
}