import { encryptObj, getCurrentEmailId } from "../../helpers/Helpers";
const LOAD_MEMBERS = 'myteam/LOAD_MEMBERS';
const LOAD_MEMBERS_SUCCESS = 'myteam/LOAD_MEMBERS_SUCCESS';
const LOAD_MEMBERS_FAIL = 'myteam/LOAD_MEMBERS_FAIL';

const UPDATE_DETAILS = 'myteam/UPDATE_DETAILS';
const UPDATE_DETAILS_SUCCESS = 'myteam/UPDATE_DETAILS_SUCCESS';
const UPDATE_DETAILS_FAIL = 'myteam/UPDATE_DETAILS_FAIL';

const CONTACT_REQUEST_STATUS = 'myteam/CONTACT_REQUEST_STATUS';
const CONTACT_REQUEST_STATUS_SUCCESS = 'myteam/CONTACT_REQUEST_STATUS_SUCCESS';
const CONTACT_REQUEST_STATUS_FAIL = 'myteam/CONTACT_REQUEST_STATUS_FAIL';

const REQUEST_OTP = 'myteam/REQUEST_OTP';
const REQUEST_OTP_SUCCESS = 'myteam/REQUEST_OTP_SUCCESS';
const REQUEST_OTP_FAIL = 'myteam/REQUEST_OTP_FAIL';

const VERIFY_MEMBER = 'myteam/VERIFY_MEMBER';
const VERIFY_MEMBER_SUCCESS = 'myteam/VERIFY_MEMBER_SUCCESS';
const VERIFY_MEMBER_FAIL = 'myteam/VERIFY_MEMBER_FAIL';

const getCurrentEmail = getCurrentEmailId() || ""

const initialState = {
  membersLoaded: false,
};

export default function myteam(state = initialState, action = {} ){
  switch(action.type){
    case LOAD_MEMBERS:
      return {
        ...state,
        loadingMembers: true,
      };
    case LOAD_MEMBERS_SUCCESS:
      return {
        ...state,
        loadingMembers: false,
        membersLoaded: true,
        members: action.result,
        membersError: null,
      };
    case LOAD_MEMBERS_FAIL:
      return {
        ...state,
        loadingMembers: false,
        membersLoaded: false,
        membersError: action.error,
        members: null,
      };
    case UPDATE_DETAILS:
      return {
        ...state,
        updatingDetails: true,
      };
    case UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        updatingDetails: false,
        detailsUpdated: true,
        members: !action.otp && !action.notUpdate ? state.members.map(member => 
          member.merchantContactId === action.merchantContactId ? {...member, whatsappAllowed: true} : member ): state.members
      };
    case UPDATE_DETAILS_FAIL:
      return {
        ...state,
        updatingDetails: false,
        detailsUpdated: false,
        updateError: action.error,
      };
    case REQUEST_OTP:
      return {
        ...state,
        requestingOtp: true,
      };
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        requestingOtp: false,
        otpRequested: true,
      };
    case REQUEST_OTP_FAIL:
      return {
        ...state,
        requestingOtp: false,
        otpRequested: true,
        otpError: action.error,
      };
    case VERIFY_MEMBER:
      return {
        ...state,
        verifyingDetail: true,
      };
    case VERIFY_MEMBER_SUCCESS:
      return {
        ...state,
        verifyingDetail: false,
        detailVerified: true,
        members: state.members.map(member => 
          member.merchantContactId === action.verifyInfo.merchantContactId && 
          member.mobileNumber === action.verifyInfo.mobileNumber ? {...member, mobileVerified: true} : member),
      };
    case VERIFY_MEMBER_FAIL:
      return {
        ...state,
        verifyingDetail: false,
        detailVerified: false,
        verifyError: action.error,
      };
      case CONTACT_REQUEST_STATUS:
        return {
          ...state,
          loadingContactStatus: true,
        }
      case CONTACT_REQUEST_STATUS_SUCCESS:
        return {
          ...state,
          loadedContactStatus: true,
          loadingContactStatus: false,
          contactRequest: action.result,
          contactStatusError: null,
        }
      case CONTACT_REQUEST_STATUS_FAIL:
        return {
          ...state,
          loadedContactStatus: false,
          loadingContactStatus: false,
          contactStatusError: action.error
        }
    
    default:
      return state;
  }
}

export function getMembers(accessToken, wantAllChildContact = false){
  return {
    types: [LOAD_MEMBERS, LOAD_MEMBERS_SUCCESS, LOAD_MEMBERS_FAIL],
    promise: (client) => client.default.post('/getMembers', {
      data: { accessToken, wantAllChildContact }
    })
  };
}

export function getContactRequestStatus(accessToken){
  return {
    types: [CONTACT_REQUEST_STATUS, CONTACT_REQUEST_STATUS_SUCCESS, CONTACT_REQUEST_STATUS_FAIL],
    promise: (client) => client.default.post('/getContactRequestStatus', {
      data: {accessToken}
    })
  }
}

export function updateMemberDetails(accessToken, newDetails, phone, otp, latLonObj = {}){

  const xyObj = encryptObj(latLonObj, accessToken)

  const phoneValue  = newDetails.mobileNumber ? newDetails.mobileNumber.replace(/\D/g,'') : newDetails.mobileNumber
  newDetails.mobileNumber = phoneValue
  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAIL],
    promise: (client) => client.default.post('/updateMemberDetails', {
      data: { accessToken, newDetails, phone, otp, updatedBy: getCurrentEmail, xyObj},
    }),
    merchantContactId: newDetails.merchantContactId,
    otp : otp,
    notUpdate: 'shadowed' in newDetails
  };
}

export function requestOtp(accessToken, phone, msg = null, sendSixDigitOtp = false){
  return {
    types: [ REQUEST_OTP, REQUEST_OTP_SUCCESS, REQUEST_OTP_FAIL ],
    promise: (client) => client.default.post('sendOtp/', { 
      data: {accessToken, phone, msg, sendSixDigitOtp}
    })
  };
}

export function sendOtpWithMsg(accessToken, phone, otpUseCaseIdentifier, sendSixDigitOtp = false) {
  return {
    types: [ REQUEST_OTP, REQUEST_OTP_SUCCESS, REQUEST_OTP_FAIL ],
    promise: (client) => client.default.post('/sendOtpWithMsg',{
      data:{accessToken, phone, otpUseCaseIdentifier, sendSixDigitOtp}
    })
  };
}

export function verifyMemberDetails(accessToken, verifyInfo){
  return {
    types: [ VERIFY_MEMBER, VERIFY_MEMBER_SUCCESS, VERIFY_MEMBER_FAIL ],
    promise: (client) => client.default.post('/verifyMemberDetails', {
      data: { accessToken, verifyInfo }
    }),
    verifyInfo
  };
}
