import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "../../images/cross2.png";
import "./CustomDialog.css"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '5%',
    backgroundColor: '#fff',
    borderRadius: '0px',
    borderBottom: '2px ​dotted grey'
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} className = "customized-dialog-heading-outer">
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <img src={CloseIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
    root: {
      padding: "0%",
    },
  }))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
root: {
    margin: 0,
    padding: '2% 5%',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    justifyContent: "center",
    flexDirection: "column"
},
}))(MuiDialogActions);


const CustomDialog = (inputProps) => {

  const handleClose = () => {
    inputProps.close()
  };

  const fullScreen = window.innerWidth < 768

  return (
    <div>
      <Dialog disableEnforceFocus onClose={handleClose} aria-labelledby="customized-dialog-title" open={inputProps.open}  maxWidth={inputProps.maxWidth || "xl"} fullScreen={fullScreen} className = "customized-dialog-outer-box">
        
        {inputProps.heading ? 
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {inputProps.heading()}
        </DialogTitle> : null}

        {inputProps.content ?
        <DialogContent dividers = "true" className={!!inputProps.contentStyleClassName? inputProps.contentStyleClassName: ""}>
            {inputProps.content()}
        </DialogContent> : null}

        {inputProps.actions ?
        <DialogActions>
            {inputProps.actions()}
        </DialogActions> : null}
        
      </Dialog>
    </div>
  );
}
export default CustomDialog
