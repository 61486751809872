const VERIFY_PAYMENT = 'verifyPayment/VERIFY_PAYMENT';
const VERIFY_PAYMENT_SUCCESS = 'verifyPayment/VERIFY_PAYMENT_SUCCESS';
const VERIFY_PAYMENT_FAIL = 'verifyPayment/VERIFY_PAYMENT_FAIL';

const GET_PAYMENT_GATEWAY_AMOUNT = 'verifyPayment/GET_PAYMENT_GATEWAY_AMOUNT';
const GET_PAYMENT_GATEWAY_AMOUNT_SUCCESS = 'verifyPayment/GET_PAYMENT_GATEWAY_AMOUNT_SUCCESS';
const GET_PAYMENT_GATEWAY_AMOUNT_FAIL = 'verifyPayment/GET_PAYMENT_GATEWAY_AMOUNT_FAIL';

const intialState = {
  inventoryPaymentLoading: false,
};

export default function verifyPayment(state = intialState, action = {} ){
	switch(action.type){
		case VERIFY_PAYMENT:
      return {
        ...state,
        inventoryPaymentLoading: true,
      };
    case VERIFY_PAYMENT_SUCCESS:
      return {

        ...state,
        inventoryPaymentLoading: false,
        inventoryPaymentLoaded: true,
        inventoryPaymentData: action.result,
      };
    case VERIFY_PAYMENT_FAIL:
      return {
        ...state,
        inventoryPaymentLoading: false,
        inventoryPaymentLoaded: true,
        inventoryPaymentError: action.error,
      };
    case GET_PAYMENT_GATEWAY_AMOUNT:
      return{
        ...state,
        getPaymentGatewayAmountLoading:true
      }
    case GET_PAYMENT_GATEWAY_AMOUNT_SUCCESS:
      return{
        ...state,
        getPaymentGatewayAmountLoading:false,
        getPaymentGatewayAmountLoaded: true,
        getPaymentGatewayAmount: action.result
      }
    case GET_PAYMENT_GATEWAY_AMOUNT_FAIL:
      return{
        ...state,
        getPaymentGatewayAmountLoading:false,
        getPaymentGatewayAmountLoaded: true,
        getPaymentGatewayAmountError: action.error,
      }  
      
      default:
      return state;
	}
}


export function verifyInventoryPayment(args){
  return {
    types: [VERIFY_PAYMENT, VERIFY_PAYMENT_SUCCESS, VERIFY_PAYMENT_FAIL],
    promise: (client) => client.default.post('/verifyInventoryPayment', {
      data: args
    })
  };
}


export function getPaymentGatewayAmountToAdd(accessToken,amount){
  return {
    types: [GET_PAYMENT_GATEWAY_AMOUNT, GET_PAYMENT_GATEWAY_AMOUNT_SUCCESS, GET_PAYMENT_GATEWAY_AMOUNT_FAIL],
    promise: (client) => client.default.post('/getPaymentGatewayAmountToAdd', {
      data: {accessToken,amount}
    })
  };
}

