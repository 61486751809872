const ACTION = 'enachLanding/ACTION';
const ACTION_SUCCESS = 'enachLanding/ACTION_SUCCESS';
const ACTION_FAIL = 'enachLanding/ACTION_FAIL';

const AUTHORIZE_MANDATE_VIA = 'enachLanding/AUTHORIZE_MANDATE_VIA';
const AUTHORIZE_MANDATE_VIA_SUCCESS = 'enachLanding/AUTHORIZE_MANDATE_VIA_SUCCESS';
const AUTHORIZE_MANDATE_VIA_FAIL = 'enachLanding/AUTHORIZE_MANDATE_VIA_FAIL';

const ACCOUNT_TYPE_SHOW_MODEL = 'enachLanding/showAccountTypeModal';
const ACCOUNT_TYPE_HIDE_MODEL = 'enachLanding/hideAccountTypeModal';


const intialState = {
  information: null,
  informationLoading: false,
  informationLoaded: false,
  error:false,

  authorizeMandateViaLoading : false,
  authorizeMandateViaLoaded : false,
  authorizeMandateViaError : null,
  shortURL : null,
  authorizeMandateViaResponse: null,

  visibleAccountTypeModal: false
};

export default function enachLanding(state = intialState, action = {} ){
  switch(action.type){
    case ACTION:
      return {
        ...state,
        information: null,
        informationLoading: true,
        informationLoaded: false,
        error:false, 
      };
    case ACTION_SUCCESS:
      return {
        ...state,
        information: action.result.information ,
        informationLoading: false,
        informationLoaded: true,
        error:false, 
      };
    case ACTION_FAIL:
      return {
        ...state,
        information: null,
        informationLoading: false,
        informationLoaded: false,
        error:true, 
      };
    
    case AUTHORIZE_MANDATE_VIA:
        return {
            ...state,
            authorizeMandateViaLoading : true,
            authorizeMandateViaLoaded : false,
            authorizeMandateViaError : null,
            shortURL : null,
            authorizeMandateViaResponse: null

        };
    case AUTHORIZE_MANDATE_VIA_SUCCESS:
        return {
            ...state,
            authorizeMandateViaLoading : false,
            authorizeMandateViaLoaded : true,
            authorizeMandateViaError : null,
            shortURL : action.result.shortURL,
            authorizeMandateViaResponse: action.result
        }
    case AUTHORIZE_MANDATE_VIA_FAIL:
        return {
            ...state,
            authorizeMandateViaLoading : false,
            authorizeMandateViaLoaded : false,
            authorizeMandateViaError : true,
            shortURL : null,
            authorizeMandateViaResponse: null
        }

    case ACCOUNT_TYPE_SHOW_MODEL:
        return{
           ...state,
           visibleAccountTypeModal: true
          }
    case ACCOUNT_TYPE_HIDE_MODEL:
        return{
           ...state,
           visibleAccountTypeModal: false
          }

    default:
        return state;
}
}

export function getenachInfo(merchantId, merchantType){
  return {
    types: [ACTION, ACTION_SUCCESS, ACTION_FAIL],
    promise: (client) => client.default.post('/getenachInfo', {
      data: { merchantId, merchantType}
    }),
  };
}

export function authorizeMandateVia(merchantId,mandateVia, accountType, merchantType)
{
  return {
    types: [AUTHORIZE_MANDATE_VIA, AUTHORIZE_MANDATE_VIA_SUCCESS, AUTHORIZE_MANDATE_VIA_FAIL],
    promise: (client) => client.default.post('/authorizeMandateVia', {
      data: { merchantId, mandateVia, accountType, merchantType}
    }),
  };
}

export function showAccountTypeModal(){
  console.log("hit")
  return {
    type: ACCOUNT_TYPE_SHOW_MODEL
  }
}

export function hideAccountTypeModal(){
   return {
      type: ACCOUNT_TYPE_HIDE_MODEL
   }
}