// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
import config from './config';
const publicVapidKey = "BD_U7MPpRPdBP1E6YTQH-oLkWUBLZt-URsLLCGYWCw5Om4PULeY2-tkoOibZztKWvkxMFiBawlJ8VHp4qQMyjr4"
const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
const isLocalhost = Boolean( window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

export default function register() {
    if(!('serviceWorker' in navigator)) return
    if(process.env.NODE_ENV !== 'production') return
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      if (!isLocalhost) {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      } else {
        // This is running on localhost. Lets check if a service worker still exists or not
        checkValidServiceWorker(swUrl);
      }
    });
}

function registerValidSW(swUrl) {
  // reload once when the new Service Worker starts activating
  var refreshing;
  navigator.serviceWorker.addEventListener('controllerchange',
    function() {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    }
  );

  navigator.serviceWorker.register(swUrl)
    .then(registration => listenForWaitingServiceWorker(registration, promptUserToRefresh))
    .catch(error => console.error('Error during service worker registration:', error));
}

function listenForWaitingServiceWorker(reg, callback) {
  function awaitStateChange() {
    reg.installing.addEventListener('statechange', function() {
      if (this.state === 'installed') callback(reg);
    });
  }
  if (!reg) return;
  if (reg.waiting) return callback(reg);
  if (reg.installing) awaitStateChange();
  reg.addEventListener('updatefound', awaitStateChange);
}

function promptUserToRefresh(reg) {
  //if (window.confirm("New content is available! Refresh page?")) {
    reg.waiting.postMessage('skipWaiting');
  //}
}

export function subscribeToPush(accessToken){
  return new Promise((resolve, reject)=>{
    if(!('serviceWorker' in navigator)) return reject('service worker not found')
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription()
      .then(function(pushSubscription){
        if(pushSubscription){
          updateSubscriptionOnServer(pushSubscription, accessToken)
          .then(result=>resolve(result))
          .catch(err=>reject(err))
        }
        else{
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
          })
          .then(function(pushSubscription){
            updateSubscriptionOnServer(pushSubscription, accessToken)
            .then(result=>resolve(result))
            .catch(err=>reject(err))
          })
          .catch(function(err){
            reject('Notification permission denied')
          })
        }
      })
    })
    .catch(err=>reject(err))
  })
}

export function updateSubscriptionOnServer(subscription, accessToken){
  return new Promise((resolve, reject)=>{
    fetch(`${config.path.apiserver}/updatePushSubscription`,{
      method: 'post',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({'subscription': subscription,
          'accessToken':accessToken})
    })
    .then(result=>{
      if(result.status === 200) resolve('successfully updated')
      else reject('some error occured')
    })
    .catch(err=>reject(err))
  })
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
