import React, { Component } from 'react'
import { connect } from 'react-redux'
import HeatMap from '../NewMerchantCustomers/HeatMap'
import { getLocalityDetails, getLocalityLeaderBoard } from '../../redux/modules/newMerchantLanding';
import { loadScript } from '../../helpers/Helpers';
import Prize from '../../images/reward-icon.svg';
import First from '../../images/1.svg';
import Second from '../../images/2.svg';
import Third from '../../images/group-9.svg';
import LeftArrow from '../../images/left-arrow.svg';
import patternDesign from '../../images/web-combined-shape-mask.svg';
import { Modal } from 'react-bootstrap';
import { sendEvent, compactCurrencyString, createMCPLog} from '../../helpers/Helpers'
import language from './languageConst.js';

class LocalityWidget extends Component {

  constructor() {
    super()
    this.state = {
      scriptLoaded: false,
      show: false
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }


  componentDidMount() {
    if (this.props.localityId)
      this.props.getLocalityDetails(this.props.localityId)
    if (this.props.merchantId)
      this.props.getLocalityLeaderBoard(this.props.merchantId)

    loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDyHksPCg1-wdLRNmqdae5OY--oUiFxOI4&libraries=visualization',
      'google')
      .then(() => {
        this.setState({ scriptLoaded: true })
        if (window.google && this.props.locality_details_loaded)
          this.initialize(this.props.lat, this.props.lon)
      })
      .catch(err => console.log(err));
  }

  componentDidUpdate(prevProps) {
    if (this.props.localityId && this.props.localityId != prevProps.localityId) {
      this.props.getLocalityDetails(this.props.localityId).then((res) => {
        if (window.google && this.props.locality_details_loaded)
          this.initialize(this.props.lat, this.props.lon)
      })
    }
    if (this.props.merchantId && this.props.merchantId != prevProps.merchantId)
      this.props.getLocalityLeaderBoard(this.props.merchantId)
  }

  initialize = (lat, lon) => {
    if(!window.google || (window.google && !window.google.maps)) return
    if (!lat || !lon) {
      lat = 28.772966
      lon = 77.499016
    }
    let merchant_loc = new window.google.maps.LatLng(lat, lon)
    var mapProp = {
      center: merchant_loc,
      zoom: 12,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
    };
    if(document.getElementById("googleMap")){
      var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
      var marker = new window.google.maps.Marker({
        position: merchant_loc,
        map: map,
        icon: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png'
      });
      marker.setMap(map)
      }
    }
 

  render() {
    let leaderBoardButtonMarginLeft = "80px";
    const languageConst = this.props.language ? language[this.props.language] : language["english"];
    console.log(this.props.leaderboard ? this.props.leaderboard['leaderboard'].rank:0);
    let customers = 0;
    let transactions = 0;
    let sales = 0;
    if(this.props.localityData){
    const fundedMerchants = this.props.localityData['fundedMerchants'];
    customers = 5 * this.props.localityData['fundedMerchants'];
    customers = this.props.localityData['customers'] ? Math.max(this.props.localityData['customers'] , customers): customers;
    transactions = fundedMerchants < 50000 ? Math.max(5 * fundedMerchants, this.props.localityData['txns']) : Math.max(3 * fundedMerchants, this.props.localityData['txns']);
    sales = Math.min(parseInt(this.props.localityData['sale']), 991*transactions);
    }
    return (
      <div style={{backgroundColor:"#fff", paddingBottom:"60px",paddingTop: (this.props.categoryId == 5 || this.props.categoryId == 91) ? "60px":"60px"}}>
        {!!this.props.leaderboard &&
          <div style={{display:"contents", width: "100%", zIndex: "1000", marginTop: window.innerWidth > 768 ? "10%" : "-20%"}}>
            <div className="leadershipBackGround">
                <div className="leadership-2">
                    <div style={{display:"flex" , flexDirection:"row", marginLeft: "8%"}}>
                        <img src={Prize} style={{ width: "130px", height: "130px", display: window.innerWidth > 768 ? "inline-block": "none"}} />
                      <div className="leaderboardRankText">
                            <div style={{fontWeight: "bold",display: window.innerWidth > 768 ? "block": "inline"}}> {languageConst.topOutlets} </div>
                            <div style={{fontWeight: "bold",display: window.innerWidth > 768 ? "block": "inline"}}> {localStorage.getItem('landingLocality')}, {localStorage.getItem('landingCity')} </div>
                      </div>
                    </div>
                    <div style={{display:"flex" , flexDirection:window.innerWidth > 768 ? "row": "column",justifyContent:"center",alignItems:"center", marginLeft: window.innerWidth > 768 ? leaderBoardButtonMarginLeft: "15px", position: "relative"}}>
                        {
                          this.props.leaderboard['leaderboard']['merchantData'].map((merchant,index) => {
                              merchant.merchant_name = merchant.merchant_name.length > 16 ? merchant.merchant_name.slice(0, 16) + "...": merchant.merchant_name;
                              if(merchant.merchant_id == this.props.merchantId){
                                return(
                                  <div className="leaderboardCard" style={{border:"solid white 0.6px"}}>
                                    <div style={{position:"relative",textAlign:"center",color:"black", fontSize: "14px",fontFamily: "robotomedium"}}>
                                      <div style={{position:"absolute",top: window.innerWidth > 768 ? "5%":"60%",left:"50%",transform:"translate(-50%, -50%)"}}> {this.props.leaderboard['leaderboard'].rank + 1} </div>
                                      <img src={Third} className="leaderboardCardImage"/>
                                    </div>
                                    <div className="leaderboardCardDescribe">
                                    <div className="leaderboardCardTitle">{languageConst.outletName}</div>
                                      <div style={{fontSize: "10px",fontFamily: "robotoregular"}}> 
                                        {languageConst.salesConst}: {compactCurrencyString(parseInt(merchant.sale || 0), this.props.country.toLowerCase(), 0)}. 
                                        {languageConst.txnsConst}: {merchant.yearly_txn_count}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            if (index == 2 && this.props.leaderboard['leaderboard'].rank < 2){
                              return(
                                <div className="leaderboardCard">
                                  <div style={{position:"relative",textAlign:"center",color:"black", fontSize: "14px",fontFamily: "robotomedium"}}>
                                    <div style={{position:"absolute",top: window.innerWidth > 768 ? "5%":"60%", left:"50%",transform:"translate(-50%, -50%)"}}> {index+1} </div>
                                    <img src={index == 0 ? First : Second} className="leaderboardCardImage"/>
                                  </div>
                                  <div className="leaderboardCardDescribe">
                                    <div className="leaderboardCardTitle">{merchant.merchant_name}</div>
                                    <div style={{fontSize: "10px",fontFamily: "robotoregular"}}> 
                                    {languageConst.salesConst}: {compactCurrencyString(parseInt(merchant.sale || 0), this.props.country.toLowerCase(), 0)}. 
                                      {languageConst.txnsConst}: {merchant.yearly_txn_count}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            if (index < 2){
                              return(
                                <div className="leaderboardCard">
                                  <div style={{position:"relative",textAlign:"center",color:"black", fontSize: "14px",fontFamily: "robotomedium"}}>
                                    <div style={{position:"absolute",top: window.innerWidth > 768 ? "5%":"60%", left:"50%",transform:"translate(-50%, -50%)"}}> {index+1} </div>
                                    <img src={index == 0 ? First : Second} className="leaderboardCardImage"/>
                                  </div>
                                  <div className="leaderboardCardDescribe">
                                    <div className="leaderboardCardTitle">{merchant.merchant_name}</div>
                                    <div style={{fontSize: "10px",fontFamily: "robotoregular"}}> 
                                     {languageConst.salesConst}: {compactCurrencyString(parseInt(merchant.sale || 0), this.props.country.toLowerCase(), 0)}. 
                                      {languageConst.txnsConst}: {merchant.yearly_txn_count}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })
                        }
                        <div className="leaderboardButton" onClick={() => {
                            this.setState({ show: true })
                            sendEvent({
                              event: 'leaderboard_popup',
                              page_type: 'new_merchant_landing',
                              subject_type: 'merchant',
                              utm_campaign: localStorage.getItem('landingSource') || 'direct',
                              subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                              utm_source: this.props.utm_source
                            })
                            createMCPLog({
                              phone: localStorage.getItem("subjectPhoneNumber") || '',
                              utm_campaign: localStorage.getItem('landingSource') || 'direct',
                              merchant_id: localStorage.getItem('landingMerchantId') || '',
                              event: 'leaderboard_popup'
                            });
                          }}>
                          <div className="leaderboardButtonText">{languageConst.seeTop}
                            <div className="leaderboardButtonLeftArrow">
                              <img src={LeftArrow} style={{width: "12px", height: "12px",marginBottom:"3px"}}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>

              </div>
            <div className="leaderboardModal">
              <Modal show={this.state.show} onHide={this.handleClose} style={{ zIndex: "111111" }} >
                <Modal.Body bsClass="leaderboardModalHeader">
                  <table style={{ borderRadius: "16px", width: "100%" }}>
                    <tbody>
                      <tr className="leaderboardModalHeaderRow">
                        <td className="leaderboardHeaderText" style={{ width: "40%", textAlign: 'center', borderRight: "solid 1px #0000001e" }}>{languageConst.outletsConst}</td>
                        <td className="leaderboardHeaderText" style={{ width: "30%", textAlign: 'center', borderRight: "solid 1px #0000001e" }}>{languageConst.txnsConst}</td>
                        <td className="leaderboardHeaderText" style={{ width: "30%", textAlign: 'center', position: "relative" }}>
                          {languageConst.salesConst}
                        <div className="leaderboardClose" onClick={() => {
                            this.setState({ show: false })
                          }}>X
                        </div>
                        </td>
                      </tr>
                      {this.props.leaderboard && this.props.leaderboard['leaderboard']['merchantData'].map((merchant, index) => {
                        if (merchant['yearly_txn_count'])
                          return (
                            <tr key={'leader_' + index} style={{ height: '56px' }}>
                              <td style={{ width: "40%", textAlign: 'left', paddingLeft: '20px', borderRight: "solid 1px #0000001e" }}>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                  <img src={merchant.merchant_logo} style={{ width: "40px", height: "40px" }} />
                                  <div style={{ position: 'absolute', bottom: "-4px", right: "-6px" }}>
                                    <div style={{ width: "20px", height: "20px", background:index == 0 ? "#fba730" : "#f25c7e", borderRadius:"50%", textAlign: "center", verticalAlign: "middle", position: "relative" }}>
                                    {
                                      <span className="leaderboardRankIndex">{(index == 10) ? (this.props.leaderboard['leaderboard'].rank + 1).toString().length > 2 ? '' : this.props.leaderboard['leaderboard'].rank + 1 : index + 1}</span>
                                    }
                                    </div>
                                  </div>
                                </div>
                                <p className="leaderboardtableTextMerchantName" style={{ marginLeft: "6px" }}>{merchant['merchant_name']}</p>
                              </td>
                              <td className="leaderboardtableText" style={{ width: "30%", textAlign: 'center', borderRight: "solid 1px #0000001e" }}>
                                {merchant['yearly_txn_count']}
                              </td>
                              <td className="leaderboardtableText" style={{ width: "30%", textAlign: 'center' }}>
                                {compactCurrencyString(parseInt(merchant['sale'] || 0), this.props.country.toLowerCase(), 0)}
                              </td>
                            </tr>
                          )
                      })
                      }
                    </tbody>
                  </table>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default connect(state => ({
  localityData: state.newMerchantLanding.locality_details,
  leaderboard: state.newMerchantLanding.leaderboard,
  locality_details_loading: state.newMerchantLanding.locality_details_loading,
  locality_details_loaded: state.newMerchantLanding.locality_details_loaded,
}), {
    getLocalityDetails: getLocalityDetails,
    getLocalityLeaderBoard: getLocalityLeaderBoard
  })(LocalityWidget)