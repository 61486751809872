const GET_KYC_LOADING = 'newOnboarding/GET_KYC_LOADING';
const GET_KYC_SUCCESS = 'newOnboarding/GET_KYC_SUCCESS';
const GET_KYC_FAILED = 'newOnboarding/GET_KYC_FAILED';

const GET_STATUS_LOADING = 'newOnboarding/GET_STATUS_LOADING';
const GET_STATUS_SUCCESS = 'newOnboarding/GET_STATUS_SUCCESS';
const GET_STATUS_FAILED = 'newOnboarding/GET_STATUS_FAILED';

const VERIFY_LOADING = 'newOnboarding/VERIFY_LOADING';
const VERIFY_SUCCESS = 'newOnboarding/VERIFY_SUCCESS';
const VERIFY_FAILED = 'newOnboarding/VERIFY_FAILED';

const VERIFY_UPI_LOADING = 'newOnboarding/VERIFY_UPI_LOADING';
const VERIFY_UPI_SUCCESS = 'newOnboarding/VERIFY_UPI_SUCCESS';
const VERIFY_UPI_FAILED = 'newOnboarding/VERIFY_UPI_FAILED';

const VERIFY_BANK_LOADING = 'newOnboarding/VERIFY_BANK_LOADING';
const VERIFY_BANK_SUCCESS = 'newOnboarding/VERIFY_BANK_SUCCESS';
const VERIFY_BANK_FAILED = 'newOnboarding/VERIFY_BANK_FAILED';

const UPDATE_STATUS_LOADING = 'newOnboarding/UPDATE_STATUS_LOADING';
const UPDATE_STATUS_SUCCESS = 'newOnboarding/UPDATE_STATUS_SUCCESS';
const UPDATE_STATUS_FAILED = 'newOnboarding/UPDATE_STATUS_FAILED';

const WALLET_LOADING = 'newOnboarding/WALLET_LOADING';
const WALLET_SUCCESS = 'newOnboarding/WALLET_SUCCESS';
const WALLET_FAILED = 'newOnboarding/WALLET_FAILED';

const TEST_ORDER_LOADING = 'newOnboarding/TEST_ORDER_LOADING';
const TEST_ORDER_SUCCESS = 'newOnboarding/TEST_ORDER_SUCCESS';
const TEST_ORDER_FAILED = 'newOnboarding/TEST_ORDER_FAILED';

const CREATE_TESTORDER_UNAUTH_LOADING = 'newOnboarding/CREATE_TESTORDER_UNAUTH_LOADING';
const CREATE_TESTORDER_UNAUTH_SUCCESS = 'newOnboarding/CREATE_TESTORDER_UNAUTH_SUCCESS';
const CREATE_TESTORDER_UNAUTH_FAILED = 'newOnboarding/CREATE_TESTORDER_UNAUTH_FAILED';

const LOAD_STATS = 'newOnboarding/LOAD_STATS';
const LOAD_STATS_SUCCESS = 'newOnboarding/LOAD_STATS_SUCCESS';
const LOAD_STATS_FAIL = 'newOnboarding/LOAD_STATS_FAIL';

const UPDATE_ESTABLISHMENT_TYPE_LOADING = 'newOnboarding/UPDATE_ESTABLISHMENT_TYPE_LOADING';
const UPDATE_ESTABLISHMENT_TYPE_SUCCESS = 'newOnboarding/UPDATE_ESTABLISHMENT_TYPE_SUCCESS';
const UPDATE_ESTABLISHMENT_TYPE_FAILED = 'newOnboarding/UPDATE_ESTABLISHMENT_TYPE_FAILED';

const initialState = {
  loading: false,
  loaded: false,
  gstVerified: false,
}

export default function newOnboarding(state = initialState, action = {}) {
  switch (action.type) {
    case GET_KYC_LOADING:
      return {
        ...state,
        kyc_loading: true,
      };
    case GET_KYC_SUCCESS:
      return {
        ...state,
        kyc_loading: false,
        kyc_loaded: true,
        kyc_data: action.result,
      };
    case GET_KYC_FAILED:
      return {
        ...state,
        kyc_loading: false,
        kyc_loaded: false,
        kyc_error: action.error
      };
    case GET_STATUS_LOADING:
      return {
        ...state,
        status_loading: true,
      };
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        status_loading: false,
        status_loaded: true,
        status_data: action.result,
      };
    case GET_STATUS_FAILED:
      return {
        ...state,
        status_loading: false,
        status_loaded: false,
        status_error: action.error
      };
    case VERIFY_LOADING:
      return {
        ...state,
        verify_loading: true,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verify_loading: false,
        verify_data: action.result,
      };
    case VERIFY_FAILED:
      return {
        ...state,
        verify_loading: false,
        verify_error: action.error
      };
    case VERIFY_UPI_LOADING:
      return {
        ...state,
        verify_upi_loading: true,
      };
    case VERIFY_UPI_SUCCESS:
      return {
        ...state,
        verify_upi_loading: false,
        verify_upi_data: action.result,
      };
    case VERIFY_UPI_FAILED:
      return {
        ...state,
        verify_upi_loading: false,
        verify_upi_error: action.error
      };
    case VERIFY_BANK_LOADING:
      return {
        ...state,
        verify_bank_loading: true,
      };
    case VERIFY_BANK_SUCCESS:
      return {
        ...state,
        verify_bank_loading: false,
        verify_bank_data: action.result,
      };
    case VERIFY_BANK_FAILED:
      return {
        ...state,
        verify_bank_loading: false,
        verify_bank_error: action.error
      };
    case WALLET_LOADING:
      return {
        ...state,
        wallet_loading: true,
      };
    case WALLET_SUCCESS:
      return {
        ...state,
        wallet_loading: false,
        wallet_data: action.result,
      };
    case WALLET_FAILED:
      return {
        ...state,
        wallet_loading: false,
        wallet_error: action.error
      };
    case UPDATE_STATUS_LOADING:
      return {
        ...state,
        update_status_loading: true,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        update_loading: false,
        update_data: action.result,
      };
    case UPDATE_STATUS_FAILED:
      return {
        ...state,
        update_loading: false,
        update_error: action.error
      };
    case TEST_ORDER_LOADING:
      return { 
        ...state,
        test_order_loading: true,
      }
    case TEST_ORDER_SUCCESS:
      return { 
        ...state,
        test_order_loading: false,
        test_order_data: action.result && action.result.message.toUpperCase() === "YES" ? true : false
      }
    case TEST_ORDER_FAILED:
      return{
        ...state,
        test_order_loading: false,
        test_order_error: action.error
      }
    case CREATE_TESTORDER_UNAUTH_LOADING:
      return {
        ...state,
        creating_test_order: true,
      }
    case CREATE_TESTORDER_UNAUTH_SUCCESS:
      return {
        ...state,
        creating_test_order: false,
        create_test_order_response: action.result
      }
    case CREATE_TESTORDER_UNAUTH_FAILED:
      return{
        ...state,
        creating_test_order: false,
        create_test_order_response: action.error
      }
    case LOAD_STATS:
      return{
        verifyingGst: true,
        gstVerified: false
      }
    case LOAD_STATS_SUCCESS:
        return {
          verifyingGst: false,
          gstVerified: true
        }
    case LOAD_STATS_FAIL:
        return {
          verifyGst: false,
          gstVerified: false
        }
    case UPDATE_ESTABLISHMENT_TYPE_LOADING:
      return {
        ...state,
        update_establishment_type_loading: true,
      };
    case UPDATE_ESTABLISHMENT_TYPE_SUCCESS:
      return {
        ...state,
        update_establishment_type_loading: false,
        update_establishment_type_data: action.result,
      };
    case UPDATE_ESTABLISHMENT_TYPE_FAILED:
      return {
        ...state,
        update_establishment_type_loading: false,
        update_establishment_type_error: action.error
      };
   default:
      return state;
  }
}


export function getKycNumbers(merchantId){
  return {
    types:[GET_KYC_LOADING, GET_KYC_SUCCESS, GET_KYC_FAILED],
    promise:(client) => client.default.get(`https://webapi.magicpin.in/ultron-onboarding/cbd/getThirdPartyContacts/${merchantId}`, {
    })
  }
}

export function getOnboardinStatus(merchantId,phoneNumber,packageName){
  return {
    types:[GET_STATUS_LOADING, GET_STATUS_SUCCESS, GET_STATUS_FAILED],
      promise:(client) => client.default.get(`https://webapi.magicpin.in/ultron-onboarding/cbd/getOnboardingStatus/${merchantId}?mobileNumber=${phoneNumber}&packageName=${packageName}`, {
   })
  }
}

export function verifyOwnershipThirdParty(merchantId, phoneNumber){
  return {
    types: [VERIFY_LOADING, VERIFY_SUCCESS, VERIFY_FAILED],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/merchant/verifyOwnership/thirdParty',{
      data: {merchantId, phoneNumber},
    }),
  };
}

export function verifyUpi({upiId: account, accountName: name}){
  return {
    types: [VERIFY_UPI_LOADING, VERIFY_UPI_SUCCESS, VERIFY_UPI_FAILED],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/merchant/cashfree/verifyUpi',{
      data: {account, name},
    }),
  };
}

export function verifyBank({accountNo: account,ifscCode: ifsc, phoneNumber: contact, accountName: name}){
  return {
    types: [VERIFY_BANK_LOADING, VERIFY_BANK_SUCCESS, VERIFY_BANK_FAILED],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/merchant/cashfree/verifyBank',{
      data: {account,ifsc,contact, name},
    }),
  };
}


export function walletOnboarding({accountNo,ifscCode,accountName,upiId,merchantId,merchantUserId}){
  merchantId = merchantId.toString();
  merchantUserId = merchantUserId.toString();
  return {
    types: [WALLET_LOADING, WALLET_SUCCESS, WALLET_FAILED],
    promise: (client) => client.default.post('/walletOnboarding',{
      data: {
        bankDetails: {
          accountNo,ifscCode,upiId,accountName
        },
        merchantId,
        merchantUserId,
      },
    }),
  };
}


export function updateOnboardingStatus(merchantId,step){
  return {
    types: [UPDATE_STATUS_LOADING, UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAILED],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/merchant/onboardingStatus',{
      data: {merchantId,step},
    }),
  };
}

export function hasAcceptedTestOrder(merchantUserId,startDate='',endDate='',queryType=''){
  return {
    types: [TEST_ORDER_LOADING, TEST_ORDER_SUCCESS, TEST_ORDER_FAILED],
    promise: (client) => client.default.post(`/hasAcceptedTestOrders`,{
      data: {merchantUserId,startDate,endDate,queryType}
    })
  }
}

export function createTestOrder(merchantUserId,categoryId,testOrderType){
  return{
    types:[CREATE_TESTORDER_UNAUTH_LOADING, CREATE_TESTORDER_UNAUTH_SUCCESS, CREATE_TESTORDER_UNAUTH_FAILED],
    promise:(client) => client.default.post('/createTestOrderUnauth', {
      data:{merchantUserId,categoryId, testOrderType}
    })
  }
}

export function sendGstOrPan(merchantId, gstNumber="", gstAvailable, gstScheme = "", panNumber = ""){
  return{
    types:[LOAD_STATS, LOAD_STATS_SUCCESS, LOAD_STATS_FAIL],
    promise:(client) => client.default.post('/sendGstOrPan',{
      data:{merchantId, gstNumber, gstAvailable, gstScheme, panNumber}
    })
  }
}

export function updateEstablishmentType(merchantId, subscriptionId, establishmentType){
  return {
    types: [UPDATE_ESTABLISHMENT_TYPE_LOADING, UPDATE_ESTABLISHMENT_TYPE_SUCCESS, UPDATE_ESTABLISHMENT_TYPE_FAILED],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/cbd/updateEstablishmentType',{
      data: {merchantId, subscriptionId, establishmentType},
    }),
  };
}

