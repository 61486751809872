const SHARE = 'fbShare/SHARE';
const SHARE_SUCCESS = 'fbShare/SHARE_SUCCESS';
const SHARE_FAIL = 'fbShare/SHARE_FAIL'; 
const GET_TOKEN = 'fbShare/GET_TOKEN';
const GET_TOKEN_SUCCESS = 'fbShare/GET_TOKEN_SUCCESS';
const GET_TOKEN_FAIL = 'fbShare/GET_TOKEN_FAIL';
const DELETE_TOKEN = 'fbShare/DELETE_TOKEN';
const DELETE_TOKEN_SUCCESS = 'fbShare/DELETE_TOKEN_SUCCESS';
const DELETE_TOKEN_FAIL = 'fbShare/DELETE_TOKEN_FAIL';
const SET_DETAILS = 'fbShare/SET_DETAILS'

const initialState = {
  sharing: false,
  shared: false,
  fbDetails: null,
}

export default function fbShare(state = initialState, action = {}){
    switch (action.type) {
    	case SHARE:
    		return {
      		...state,
      		sharing: true
    	};
    	case SHARE_SUCCESS:
    		return {
      		...state,
      		sharing: false,
      		shared: true,
      		error: null
    	};
    	case SHARE_FAIL:
    	return {
      		...state,
      		sharing: false,
      		shared: false,
      		error: action.error
    	};

    case GET_TOKEN:
      return {...state, fbDetails: []};
      
    case GET_TOKEN_SUCCESS: 
      return {
       ...state,
       fbMessage: action.result.message,
        fbDetails: action.result.data

      };
    case GET_TOKEN_FAIL:
      return state;


    case DELETE_TOKEN:
      return {
        ...state,
         deletingFbToken: true,
         deletedFbToken: false,
        };
    
    case DELETE_TOKEN_SUCCESS:
      return {
      ...state,
      deletedFbToken: true,
      deletingFbToken:false,
       };

     case DELETE_TOKEN_FAIL:
       return { 
        ...state,
        deletedFbToken:false,
        deletingFbToken: false,
       };


    case SET_DETAILS:
      return{
        ...state,
        fbDetails: action.pagesData, 
      }


      default:
          return state;
    }
  }

  export function setPageData(pagesData) {
   return {
     type: SET_DETAILS,
     pagesData
   };
 } 


 export function getFbToken(fbId,accessToken) {
     return {
     types: [GET_TOKEN, GET_TOKEN_SUCCESS, GET_TOKEN_FAIL],
     promise: (client) => client.default.post('/getFbDetails',{
       data:{accessToken, fbId}
     })
   }
 }

export function deleteFbToken(accessToken){
  return {
    types: [DELETE_TOKEN, DELETE_TOKEN_SUCCESS, DELETE_TOKEN_FAIL],
    promise: (client) => client.default.post('/deleteFbDetails',{
      data:{accessToken}
    })
  }
}


export function fbSharePost(accessToken,fbToken,pageId,sharePostObject){
    return {
    types: [SHARE, SHARE_SUCCESS, SHARE_FAIL],
    promise: (client) =>client.default.post('/shareFb',{
      data:{accessToken,fbToken,pageId,sharePostObject}
    })
  }
}
