import React from 'react'
import CustomDialog from '../CustomComponents/CustomDialog'
import LocSettingGuidAccordion from './LocSettingGuidAccordion'
import "./locSettingGuideline.css"


const LocSettingGuideline = (props) => {

    const {user, setShowLocationSettingGuide, showLocationSettingGuide} = props

    const customHeading = () =>{
    
        let headingDiv = 
              <div className = "location-guide-custom-dialog-heading">
                <p className = "location-guide-custom-dialog-heading-name">
                  <i className="fa fa-solid fa-crosshairs" style ={{marginRight: "1%"}}></i>
                    Locate location permission in your browser
                </p>
                <p style={{color: "red", fontSize: "16px"}}>
                    *Please allow location permission to move forward with this action.<br/>
                    magicpin does not have permission to use your location.
                </p>
              </div>
      
        return headingDiv
    }

    const customContent = () =>{

        return(
        <div className = "location-guide-content-body">
                <LocSettingGuidAccordion/>
        </div>
        )
    }

    const customActions = () => {
        return(
        <div className = "location-guide-custom-action-body">
            <button className = "location-guide-custom-dialog-btn" onClick = {() => setShowLocationSettingGuide()}> I Understand </button>
        </div>
        )
    }
    
    return (
        <div>
            <CustomDialog
                open = {showLocationSettingGuide}
                close = {setShowLocationSettingGuide}
                heading = {() => customHeading()}
                content = {() => customContent()}
                actions = {() => customActions()}
            />
        </div>
    )
}

export default LocSettingGuideline
  