import { getCurrentEmailId } from "../../helpers/Helpers";

const MARK_MERCHANT_SAFE = 'safeMerchant/MARK_MERCHANT_SAFE';
const MARK_MERCHANT_SAFE_SUCCESS = 'safeMerchant/MARK_MERCHANT_SAFE_SUCCESS';
const MARK_MERCHANT_SAFE_FAIL = 'safeMerchant/MARK_MERCHANT_SAFE_FAIL';

export default function safeMerchant(state = {}, action = {}) {

    switch (action.type) {
        case MARK_MERCHANT_SAFE:
            return {
                ...state,
                markMerchantSafeError: false,
                markMerchantSafeLoading: true,

            };

        case MARK_MERCHANT_SAFE_SUCCESS:
            return {
                ...state,
                markMerchantSafeError: false,
                markMerchantSafeLoading: false,
                markMerchantSafeMessage: 'Merchant has been marked as safe'
            }

        case MARK_MERCHANT_SAFE_FAIL:
            return {
                ...state,
                markMerchantSafeLoading: false,
                markMerchantSafeMessage: action.error.message,
                markMerchantSafeError: action.error,
            }

        default:
            return state;

    }
}

export function markMerchantSafe(merchantId, accessToken, lastConfirmationDate){
    return {
        types: [MARK_MERCHANT_SAFE, MARK_MERCHANT_SAFE_SUCCESS, MARK_MERCHANT_SAFE_FAIL],
        promise:(client)=>client.default.post('/paymentCommercialsUpsert', {
            data:{accessToken, updatedBy: (getCurrentEmailId() || ""), lastConfirmationDate, merchantId}
          })
    }
}