import React, { Component } from 'react';
import { sendEvent, createMCPLog } from '../../helpers/Helpers'
import Vimeo from '@vimeo/player';
import Quote from '../../images/Quote-closed.svg'
import upgradeBannerPic from '../../images/banner-texture.svg';
import Slider from 'react-slick/dist/react-slick';
import language from './languageConst.js';

const ga = window.ga;

export default class Testimonials extends Component {

  constructor(props) {
    super(props);
    this.videoRef = []
    this.videoArrayLanding = [
      {
        url: 'https://player.vimeo.com/video/258751464?autoplay=0&autopause=0',
        id: '258751464',
        merchantLogo: 'https://d2jz4nqvi4omcr.cloudfront.net/productimages/widget/194227_profile_pic.jpg',
        merchantName: 'Molecule',
        characters: `Sahil Sambhi, ${language[props.language].partner}`,
        address: "Sector 29, Gurgaon",
        message: language[props.language].testimonial1
      },
      {
        url: 'https://player.vimeo.com/video/258751003',
        id: '258751003',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_9457_2018-02-10-21-45-02-000225.jpg',
        merchantName: 'Cheenos',
        address: "Sector 18, Noida",
        characters: `Ajit Singh, ${language[props.language].owner}`,
        message: language[props.language].testimonial2
      },
      {
        url: 'https://player.vimeo.com/video/259298960',
        id: '259298960',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1480854_2017-12-09-13-35-09-000016.jpg',
        merchantName: 'Time Machine',
        address: "Sector 38, Noida",
        characters: `Richa Vashisth, ${language[props.language].partner}`,
        message: language[props.language].testimonial3,
      }
    ]

    this.videoArrayPayment = [
      {
        url: 'https://player.vimeo.com/video/266554781?autoplay=0&autopause=0',
        id: '266554781',
        merchantLogo: 'https://d2jz4nqvi4omcr.cloudfront.net/productimages/widget/9460_profile_pic.jpg',
        merchantName: 'The Classroom',
        address:'Sector 29, Gurgaon',
        characters: 'Vishesh Nanda, Head of Operations',
        message: 'magicpin is a very instrumental app and very different from other apps which helps people create memories with its selfie feature',
      },
      {
        url: 'https://player.vimeo.com/video/266554925',
        id: '266554925',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/thumb/mp_245544_2018-01-31-20-42-10-000680.jpg',
        merchantName: 'Mithaas',
        address:'Sector 25, Noida',
        characters: 'Sumit Choudhary, Director',
        message: 'A unique platform that provides social networking, he recommends magicpin as it makes sure that the customers repeatedly visit the outlet.'
      },
      {
        url: 'https://player.vimeo.com/video/266554878',
        id: '266554878',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1189283_2018-01-08-18-35-05-000219.jpg',
        merchantName: 'Suroor',
        address:'Rajouri Garden, New Delhi',
        characters: 'Udheshya Babbar, Consultant',
        message: 'magicpin has helped us increase the business through banners and deals, it is a much better portal as compared',
      }
    ]

    this.videoArrayBangalorePayment = [
      {
        url: 'https://player.vimeo.com/video/286837810?autoplay=0&autopause=0',
        id: '286837810',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_13652_2017-10-03-19-30-01-000896.jpg',
        merchantName: 'Big pitcher',
        address:'Old Airport Road, Bangalore',
        characters: `Abhay, ${language[props.language].partner}`,
        message: 'A platform that provides social networking, he recommends magicpin as it helps them understand the interests of the customers and provides a good insight about them.',
      },
      {
        url: 'https://player.vimeo.com/video/286837767',
        id: '286837767',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_670499_2017-04-07-10-13-44-000426.jpg',
        merchantName: 'Cafe azure',
        address:'Kalyan Nagar, Bangalore',
        characters: `Aslam, ${language[props.language].partner}`,
        message: 'We have been associated with magicpin for more than a year and I was initially not in a favor of getting on board. But it is a great pool for restaurants as well as consumers. magicpin has helped us getting many conversions and it has been a great journey',
      },
      {
        url: 'https://player.vimeo.com/video/286837723',
        id: '286837723',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1688968_2018-05-24-22-20-11-000213.jpg',
        merchantName: 'Chill-um',
        address:'Koramangala, Bangalore',
        characters: 'Shashank, Founder',
        message: 'magicpin is one of the best application of social media. It helps keep the customers happy as well as the restaurant. We have seen a significgant increase in sales from when we got on board with magicpin. ',
      }
    ]

    this.videoArrayBangaloreLanding = [
      {
        url: 'https://player.vimeo.com/video/286837649',
        id: '286837649',
        merchantLogo: 'https://d2jz4nqvi4omcr.cloudfront.net/productimages/widget/333974_profile_pic.jpg',
        merchantName: 'Eat street',
        address:'Koramangala, Bangalore',
        characters: 'Amar, Operations',
        message: language[props.language].testimonial6,
      },
      {
        url: 'https://player.vimeo.com/video/286837573',
        id: '286837573',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1536311_2017-12-14-19-45-04-000297.jpg',
        merchantName: 'Boho',
        address:'Koramangala, Bangalore',
        characters: `Vartika, ${language[props.language].partner}`,
        message: language[props.language].testimonial4,
      },
      {
        url: 'https://player.vimeo.com/video/286837529',
        id: '286837529',
        merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_12563_2017-11-21-18-20-19-000978.jpg',
        merchantName: 'happy brew',
        address:'Koramangala, Bangalore',
        characters: `Himesh, ${language[props.language].partner}`,
        message: language[props.language].testimonial5,
      }
    ]
    this.state = {
      videoArray : props.city == 'Bangalore' ? props.paymentPage ?  this.videoArrayBangalorePayment : this.videoArrayBangaloreLanding : this.props.paymentPage ? this.videoArrayPayment : this.videoArrayLanding
    }

    this.testimonialStyle = { 
      backgroundColor:this.props.paymentPage ? "#f7f7fa" :"#fff",
      backgroundPosition: this.props.upsellPage && 'center', 
      backgroundSize: this.props.upsellPage && 'cover', 
      backgroundImage: this.props.upsellPage &&  `url(${upgradeBannerPic})`
    
    }

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.city != nextProps.city){
      this.setState({
        videoArray : nextProps.city == 'Bangalore' ? this.props.paymentPage ?  this.videoArrayBangalorePayment : this.videoArrayBangaloreLanding : this.props.paymentPage ? this.videoArrayPayment : this.videoArrayLanding
      })
    }
    if(nextProps.language && nextProps.language != this.props.language){
      const languageConst = language[nextProps.language]
      this.videoArrayLanding = [
        {
          url: 'https://player.vimeo.com/video/258751464?autoplay=0&autopause=0',
          id: '258751464',
          merchantLogo: 'https://d2jz4nqvi4omcr.cloudfront.net/productimages/widget/194227_profile_pic.jpg',
          merchantName: 'Molecule',
          characters: `Sahil Sambhi, ${languageConst.partner}`,
          address: "Sector 29, Gurgaon",
          message: languageConst.testimonial1
        },
        {
          url: 'https://player.vimeo.com/video/258751003',
          id: '258751003',
          merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_9457_2018-02-10-21-45-02-000225.jpg',
          merchantName: 'Cheenos',
          address: "Sector 18, Noida",
          characters: `Ajit Singh, ${language[nextProps.language].owner}`,
          message: language[nextProps.language].testimonial2
        },
        {
          url: 'https://player.vimeo.com/video/259298960',
          id: '259298960',
          merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1480854_2017-12-09-13-35-09-000016.jpg',
          merchantName: 'Time Machine',
          address: "Sector 38, Noida",
          characters: `Richa Vashisth, ${language[nextProps.language].partner}`,
          message: language[nextProps.language].testimonial3,
        }
      ]
      this.videoArrayBangaloreLanding = [
        {
          url: 'https://player.vimeo.com/video/286837649',
          id: '286837649',
          merchantLogo: 'https://d2jz4nqvi4omcr.cloudfront.net/productimages/widget/333974_profile_pic.jpg',
          merchantName: 'Eat street',
          address:'Koramangala, Bangalore',
          characters: 'Amar, Operations',
          message: language[nextProps.language].testimonial6,
        },
        {
          url: 'https://player.vimeo.com/video/286837573',
          id: '286837573',
          merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_1536311_2017-12-14-19-45-04-000297.jpg',
          merchantName: 'Boho',
          address:'Koramangala, Bangalore',
          characters: `Vartika, ${language[nextProps.language].partner}`,
          message: language[nextProps.language].testimonial4,
        },
        {
          url: 'https://player.vimeo.com/video/286837529',
          id: '286837529',
          merchantLogo: 'https://d13genyhhfmqry.cloudfront.net/widget/mp_12563_2017-11-21-18-20-19-000978.jpg',
          merchantName: 'happy brew',
          address:'Koramangala, Bangalore',
          characters: `Himesh, ${language[nextProps.language].partner}`,
          message: language[nextProps.language].testimonial5,
        }
      ]
      this.setState({
        videoArray : nextProps.city == 'Bangalore' ? nextProps.paymentPage ?  this.videoArrayBangalorePayment : this.videoArrayBangaloreLanding : nextProps.paymentPage ? this.videoArrayPayment : this.videoArrayLanding
      })
      
    }
  }

  componentDidMount() {
    this.player = []
    for (let i = 0; i < this.state.videoArray.length; i++) {
      this.player[i] = new Vimeo(this.videoRef[i])
      this.player[i].on('play', () => {
        sendEvent({
          utm_campaign: localStorage.getItem('landingSource') || '',
          subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
          upsell_merchant_id: localStorage.getItem('upsellLandingMerchantId') || '',
          event: 'video_play',
          page_type: this.props.paymentPage ? 'payment':'landing',
          play_upsell: this.props.upsellPage || false,
          video_id: this.state.videoArray[i].id,
          utm_source: this.props.utm_source
        })
        createMCPLog({
          phone: localStorage.getItem("subjectPhoneNumber") || '',
          utm_campaign: localStorage.getItem('landingSource') || '',
          merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'video_play'
        });
      })
      this.player[i].on('pause', () => {
        sendEvent({
          utm_campaign: localStorage.getItem('landingSource') || '',
          subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'video_pause',
          pause_upsell: this.props.upsellPage || false,
          upsell_merchant_id: localStorage.getItem('upsellLandingMerchantId') || '',
          page_type: this.props.paymentPage ? 'payment':'landing',
          video_id: this.state.videoArray[i].id,
          utm_source: this.props.utm_source
        })
        createMCPLog({
          phone: localStorage.getItem("subjectPhoneNumber") || '',
          utm_campaign: localStorage.getItem('landingSource') || '',
          merchant_id: localStorage.getItem('landingMerchantId') || '',
          event: 'video_pause'
        });
      })
    }
}

  render() {
     const languageConst = this.props.language ? language[this.props.language] : language["english"];

    const settings = {
      dots: true,  
      accessibility: true,
      infinite: false,
      arrows: false,
      draggable: true,
      swipeToSlide: true,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0px',
      dotsClass: 'slick-dots'
  };
    return (
      <section className="partners" style={this.testimonialStyle }>
        {this.props.paymentPage &&
          <h2>Listen to our happy <span>Partners</span></h2>
        }
        {!this.props.paymentPage &&
          <h2 style={{marginTop: 0 , marginBottom:window.innerWidth > 768 ? 0 :"3%"}}>{languageConst.partnersHeading}</h2>
        }

        <div>
          {window.innerWidth >= 768 && this.state.videoArray.map((video, index) => {
            return (
              <article key={'video' + index} className="testimonial testimonial-video">
                <iframe
                  ref={(vid) => this.videoRef[index] = vid}
                  src={video.url}
                  width="100%"
                  height={window.innerWidth > 800 ? "180" : "160"}
                  onClick={() => { ga('send', 'event', 'Logout_page', 'testimonial-video', 'claim_page'); }}
                  frameBorder="0"
                  style={{borderRadius:"8px"}}
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}/>
                <div className="merchant-data">
                  <div className="merchant-details">
                    <img src={video.merchantLogo} />
                    <div className="merchant-name-holder">
                      <h5>{video.merchantName}</h5>
                      <h6>{video.address}</h6>
                    </div>
                  </div>
                  <p>{video.message}</p>
                  <div style={{textAlign:'left'}}>
                    <img src={Quote} className="quotes" />
                    <span className="merchant-person"><b>{video.characters.split(',')[0]+','}</b>{video.characters.split(',')[1]}</span>
                  </div>
                </div>
              </article>
            )
          })
          }
          {window.innerWidth < 768 && 
          <Slider {...settings} >
          {
          this.state.videoArray.map((video, index) => {
            return (
              <article key={'video' + index} className="testimonial testimonial-video">
                <iframe
                  ref={(vid) => this.videoRef[index] = vid}
                  src={video.url}
                  width="100%"
                  height={window.innerWidth > 800 ? "180" : "160"}
                  onClick={() => { ga('send', 'event', 'Logout_page', 'testimonial-video', 'claim_page'); }}
                  frameBorder="0"
                  style={{borderRadius:"8px"}}
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}/>
                <div className="merchant-data">
                  <div className="merchant-details">
                    <img src={video.merchantLogo} />
                    <div className="merchant-name-holder">
                      <h5>{video.merchantName}</h5>
                      <h6>{video.address}</h6>
                    </div>
                  </div>
                  <p>{video.message}</p>
                  <div style={{textAlign:'left'}}>
                    <img src={Quote} className="quotes" />
                    <span className="merchant-person"><b>{video.characters.split(',')[0]+','}</b>{video.characters.split(',')[1]}</span>
                  </div>
                </div>
              </article>
            )
          })
        }
        </Slider>
        }
        </div>

        {this.props.showClaimDialog &&
          <a className="start-button button-header-hover" style={{marginTop:window.innerWidth > 768 ? "0px" : "25px"}} onClick={() => {
            this.props.showClaimDialog();
            window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
            sendEvent({
              utm_campaign: localStorage.getItem('landingSource') || '',
              subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
              event: 'become_a_partner',
              page_type: 'new_merchant_landing'
            })
            createMCPLog({
              phone: localStorage.getItem("subjectPhoneNumber") || '',
              utm_campaign: localStorage.getItem('landingSource') || '',
              merchant_id: localStorage.getItem('landingMerchantId') || '',
              event: 'become_a_partner'
            });
          }}>{languageConst.buttonCta2}</a>
        }
      </section>
    )
  }
}