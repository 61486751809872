import moment from 'moment';

const MARKET_SHARE_LOADING = 'marketInsights/MARKET_SHARE_LOADING'
const MARKET_SHARE_SUCCESS = 'marketInsights/MARKET_SHARE_SUCCESS'
const MARKET_SHARE_FAILURE = 'marketInsights/MARKET_SHARE_FAILURE'
const LOCALITY_SHARE_LOADING = 'marketInsights/LOCALITY_SHARE_LOADING'
const LOCALITY_SHARE_SUCCESS = 'marketInsights/LOCALITY_SHARE_SUCCESS'
const LOCALITY_SHARE_FAILURE = 'marketInsights/LOCALITY_SHARE_FAILURE'
const REPEAT_RATE_LOADING = 'marketInsights/REPEAT_RATE_LOADING'
const REPEAT_RATE_SUCCESS = 'marketInsights/REPEAT_RATE_SUCCESS'
const REPEAT_RATE_FAILURE = 'marketInsights/REPEAT_RATE_FAILURE'
const REPEAT_CUSTOMER_LOADING = 'marketInsights/REPEAT_CUSTOMER_LOADING'
const REPEAT_CUSTOMER_SUCCESS = 'marketInsights/REPEAT_CUSTOMER_SUCCESS'
const REPEAT_CUSTOMER_FAILURE = 'marketInsights/REPEAT_CUSTOMER_FAILURE'
const COMPETITORS_LOADING = 'marketInsights/COMPETITORS_LOADING'
const COMPETITORS_LOADED = 'marketInsights/COMPETITORS_LOADED'
const COMPETITORS_FAILED = 'marketInsights/COMPETITORS_FAILED'

const INTERVAL_REPEAT_LOADING = 'marketInsights/INTERVAL_REPEAT_LOADING'
const INTERVAL_REPEAT_LOADED = 'marketInsights/INTERVAL_REPEAT_LOADED'
const INTERVAL_REPEAT_FAILED = 'marketInsights/INTERVAL_REPEAT_FAILED'
 
const initialState = {
  loaded: false,
};

function getTotalValueAndVolume(marketData) {
  let quarterData = {}
  let sumObj = {} 
  for (let i = 0; i < marketData.length; i++) {
    quarterData = marketData[i]['marketShare']
    for(let brand in quarterData){
      if(!sumObj[i]) sumObj[i] = {totalValue:0, totalVolume:0}
      sumObj[i].totalValue += quarterData[brand]['billSize']
      sumObj[i].totalVolume += quarterData[brand]['transactions']
    }
  }
  return sumObj
}

function getOwnData (marketData, retail_id) {
  let sumObj = getTotalValueAndVolume(marketData)
  let quarterData = {}
  let retArr = []
  for (let j = 0; j < marketData.length; j++) {
    quarterData = marketData[j]['marketShare']
    for(let brand in quarterData){
      if(retail_id == brand){
        retArr.push({value:parseFloat((quarterData[brand]['billSize']/sumObj[j].totalValue * 100).toFixed(2)),
        volume:parseFloat((quarterData[brand]['transactions']/sumObj[j].totalVolume * 100).toFixed(2))})
      }
    }
  }
  return retArr
}

function getCompetitorData (marketData, names) {
  let retArr = []
  let sumObj = getTotalValueAndVolume(marketData)
  let quarterData = {}
  for (let i = 0; i < marketData.length; i++) {
    let obj = {}
    quarterData = marketData[i]['marketShare']
    for(let brand in quarterData){
      obj[names[brand]] = {
        value:parseFloat((quarterData[brand].billSize/sumObj[i].totalValue * 100).toFixed(2)),
        volume:parseFloat((quarterData[brand].transactions/sumObj[i].totalVolume *100).toFixed(2))
      }
    }
    retArr.push(obj)
  }
  return retArr
}

function getAovData(marketData, retail_id, names){
  let sum={}
  let retArr = []
  for(let i=0 ;i< marketData.length; i++){
    for(let brand in marketData[i].marketShare){
      if(!sum[brand]) 
        sum[brand] = 0
      sum[brand] += marketData[i].marketShare[brand].avgBillSize
    }
  }
  let avg = {}
  let avgSum = 0
  for(let retailId in sum){
    avg[retailId] = sum[retailId]/marketData.length
    retArr.push([names[retailId], parseFloat(avg[retailId].toFixed(2))])
    avgSum += avg[retailId]
  }

  return {aov:retArr, segmentAvg: Math.round(avgSum/(Object.keys(sum).length)), ownAvg:Math.round(avg[retail_id])}
}

function getAovChangeData (marketData, retail_id){
  let quarterData=[]
  let quarter = 0
  for (let i of marketData){
    for(let j in i['marketShare']){
      if(j == retail_id)
        quarterData.push(['Q'+moment(i['startDate']).quarters().toString()+moment(i['startDate']).format('-YY'), parseFloat(i['marketShare'][j]['avgBillSize'].toFixed(2))])
    }
    quarter++
  }
  return quarterData
}


export default function reducer(state = initialState, action = {}) {
  var aovData;
  switch (action.type) {
    case MARKET_SHARE_LOADING :
      return {
        ...state,
        marketShareLoading: true,
        marketShareLoaded: false
      }
    case MARKET_SHARE_SUCCESS :
      switch(action.source){
        case 'marketShare':
          return{
            ...state,
            marketShareLoading: false,
            marketShareLoaded: true,
            ownData: getOwnData(action.result, state.retail_id),
            competitorData: getCompetitorData(action.result, state.retail_merchant_name) 
          }
        case 'aov':
          aovData = getAovData(action.result, action.parent_id, state.retail_merchant_name)
          return {
            ...state,
            marketShareLoading: false,
            marketShareLoaded: true,
            merchantAvgs: aovData.aov,
            segmentAvg: aovData.segmentAvg,
            ownAvg: aovData.ownAvg
          }
        case 'aovChange':
          return {
            ...state,
            marketShareLoading: false,
            marketShareLoaded: true,
            merchantAvgsChange:getAovChangeData(action.result, state.retail_id)
          }

        case undefined:
          aovData = getAovData(action.result, action.parent_id, state.retail_merchant_name)
          return{
            ...state,
            marketShareLoading: false,
            marketShareLoaded: true,
            ownData: getOwnData(action.result, state.retail_id),
            competitorData: getCompetitorData(action.result, state.retail_merchant_name),
            merchantAvgs: aovData.aov,
            segmentAvg: aovData.segmentAvg,
            ownAvg: aovData.ownAvg,
            merchantAvgsChange:getAovChangeData(action.result, state.retail_id)
          }
        default:
          return state
      }

    case MARKET_SHARE_FAILURE :
      return {
        ...state,
        marketShareLoading: false,
        marketShareLoaded: false,
        marketShareError: action.error
      }

      case LOCALITY_SHARE_LOADING :
      return {
        ...state,
        localityShareLoading: true,
        localityShareLoaded: false
      }
    case LOCALITY_SHARE_SUCCESS :
      return {
        ...state,
        localityShareLoading: false,
        localityShareLoaded: true,
        localityShareData: action.result
      }
    case LOCALITY_SHARE_FAILURE :
      return {
        ...state,
        localityShareLoading: false,
        localityShareLoaded: false,
        localityShareError: action.error
      }
    case REPEAT_RATE_LOADING :
      return {
        ...state,
        repeatRateLoading: true,
        repeatRateLoaded: false
      }
    case REPEAT_RATE_SUCCESS :
      return {
        ...state,
        repeatRateLoading: false,
        repeatRateLoaded: true,
        repeatRateData: action.result
      }
    case REPEAT_RATE_FAILURE :
      return {
        ...state,
        repeatRateLoading: false,
        repeatRateLoaded: false,
        repeatRateError: action.error
      }

    case REPEAT_CUSTOMER_LOADING:
      return {
        ...state,
        repeatCustomerLoading: true,
        repeatCustomerLoaded: false,
      }
    case REPEAT_CUSTOMER_SUCCESS :
      return {
        ...state,
        repeatCustomerLoading: false,
        repeatCustomerLoaded: true,
        repeat_customer_data:action.result
      }
    case REPEAT_CUSTOMER_FAILURE:
      return{
        ...state,
        repeatCustomerLoading: false,
        repeatCustomerLoaded: false,
      }
    case COMPETITORS_LOADING:
      return {
        ...state,
        competitorsLoading: true,
        competitorsLoaded: false
      }

    case COMPETITORS_LOADED:
      return {
        ...state,
        competitorsLoading: false,
        competitorsLoaded: true,
        retail_id: action.result.retail_id,
        retail_name: action.result.retail_merchant_name[action.result.retail_id] || "",
        competitors: action.result.competitors,
        retail_merchant_name:action.result.retail_merchant_name || {}
      }
    case COMPETITORS_FAILED:
      return {
        ...state,
        competitorsLoading: false,
        competitorsLoaded: true,
        error: action.error
      }
    case INTERVAL_REPEAT_LOADING:
      return{
        ...state,
        intervalRepeatLoading: true,
      }
    case INTERVAL_REPEAT_LOADED:
      return{
        ...state,
        intervalRepeatLoading: false,
        intervalRepeatLoaded: true,
        intervalRepeatData: action.result
      }
    case INTERVAL_REPEAT_FAILED:
      return {
        ...state,
        intervalRepeatLoading: false,
        intervalRepeatLoaded: false,
        intervalRepeatError: action.error
      }

    default :
      return state
    }
}

export function getMarketShare(accessToken, startDate, endDate, city, locality, source) {
  return {
    types: [MARKET_SHARE_LOADING, MARKET_SHARE_SUCCESS, MARKET_SHARE_FAILURE],
    promise: (client) => client.default.post('/getBrandMarketShare', {
      data: { accessToken, startDate, endDate, city, locality }
    }),
    source: source,
  };
}

export function getLocalityShare(accessToken, startDate, endDate, city) {
  return {
    types: [LOCALITY_SHARE_LOADING, LOCALITY_SHARE_SUCCESS, LOCALITY_SHARE_FAILURE],
    promise: (client) => client.default.post('/getBrandGeoSplit', {
      data: { accessToken, startDate, endDate, city }
    })
  };
}

export function getRepeatRate(accessToken, startDate, endDate, city) {
  return {
    types: [REPEAT_RATE_LOADING, REPEAT_RATE_SUCCESS, REPEAT_RATE_FAILURE],
    promise: (client) => client.default.post('/getBrandRepeatRate', {
      data: { accessToken, startDate, endDate, city }
    })
  };
}

export function getBrandNewRepeatCustomers( accessToken ){
  return {
    types: [REPEAT_CUSTOMER_LOADING, REPEAT_CUSTOMER_SUCCESS, REPEAT_CUSTOMER_FAILURE],
    promise: (client) => client.default.post('/getBrandNewRepeatCustomers', {
      data: {accessToken}
    })
  }
}

export function getRetailCompetitorDetails( accessToken ){
  return{
    types: [COMPETITORS_LOADING, COMPETITORS_LOADED, COMPETITORS_FAILED],
    promise: (client) => client.default.post('/getRetailCompetitorDetails',{
      data:{accessToken}
    })
  }
}

export function getIntervalRepeatRate( accessToken, startDate, endDate, city){
  return{
    types:[INTERVAL_REPEAT_LOADING, INTERVAL_REPEAT_LOADED, INTERVAL_REPEAT_FAILED],
      promise: (client) => client.default.post('/getBrandRepeatInterval',{
        data:{accessToken, startDate, endDate}
      })
  }
}