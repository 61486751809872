import React, { Component } from 'react';

import thumbsDown from "../../images/thumbs-down.svg";
import thumbsDownActive from "../../images/thumbs-down-active.svg";
import thumbsUpInactive from "../../images/thumbs-up-inactive.svg";
import thumbsUp from "../../images/thumbs-up.svg";
import thumbsUpActive from "../../images/thumbsup-active.svg";

import './HelpDesk.css';

export default class HelpDeskFeedBack extends Component {
    constructor(props){
        super(props)
        this.state = {
            notSolved: false,
            comment: "",
            fileSelected: "",
        }
    }

    triggerUpload = () => {
        let fileInput = document.getElementById('file-input2')
        if(fileInput){
            fileInput.click()
        }
    }

    onChange = () => {
        var uploadedFile = document.getElementById('file-input2') ? document.getElementById('file-input2').files:[];
        if(uploadedFile.length > 0){
          this.setState({
            fileSelected: uploadedFile[0],
            isProof: true
          })
        }
    }

    render() {
        return (
            <div className="helpdesk-feedback">
                <input
                    ref='fileInput' 
                    id="file-input2" 
                    accept=".png, .jpg, .jpeg, .csv, .pdf, .xlsx"
                    type="file" 
                    style={{display:'none'}}
                    onChange={this.onChange}
                />
                <h5 style={{textAlign:"center"}}>{this.state.notSolved ? "Please elaborate your concern":"Was your query resolved?"}</h5>
                {
                    this.state.notSolved ?
                    <div className={`helpdesk-thumbs-down-comment`}>
                        <textarea placeholder="Add a comment" onChange={e => this.setState({comment: e.target.value})}>
                        </textarea>
                        <div style={{marginLeft:"4px",color:"#ff455f",fontFamily:"robotomedium",cursor:"pointer"}} onClick={this.triggerUpload}> Attach files</div> 
                        {this.state.fileSelected && <div style={{marginLeft:"4px", padding:"2px 4px",backgroundColor:"white",borderRadius:"16px",width:'50%'}}>{this.state.fileSelected?.name.slice(0,20)}</div>}
                        <button disabled={this.props.loading} onClick={() => this.props.handleSubmit(this.state.comment,this.state.fileSelected,true)}>{this.props.loading ? "Submitting..":"Submit"}</button>
                    </div>
                    :
                    <div className="helpdesk-thumbs">
                        <img onMouseOver={() => this.setState({hover1:true})}
                            onMouseOut={() => this.setState({hover1:false})} 
                            src={this.state.notSolved ? thumbsUpInactive: !this.state.hover1 ? thumbsUp: thumbsUpActive} onClick={() => {this.props.sendHelpDeskEvent('clicked','thumbs_up');this.props.closeDialog()}}/>
                        <img onMouseOver={() => this.setState({hover2:true})}
                            onMouseOut={() => this.setState({hover2:false})}
                            src={this.state.notSolved ? thumbsDownActive: !this.state.hover2 ? thumbsDown: thumbsDownActive} onClick={() => {this.props.sendHelpDeskEvent('clicked','thumbs_down'); this.setState({notSolved: true})}}/>
                    </div>
                }
            </div>
        );
    }
}