import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from 'material-ui/Dialog';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendEvent } from '../../helpers/Helpers';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import QRCode from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';
import domtoimage from 'dom-to-image';
import qr from '../../images/qr.png';
import './QrCode.css'

const ga = window.ga;

const styles = {
  titleStyle: {
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  fontSize: '16px',
  backgroundColor: '#ef1c74',
  color: '#fff',
  padding: '16px',
  paddingTop: '8px',
  paddingBottom: '8px',
  overflow: "scroll"
  },

  contentStyle: {
  borderRadius : '8px',
  width: window.innerWidth > 768 ? '540px' : '330px',
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  overflow: "scroll"
  },

  closeStyle: {
  position: 'absolute',
  top: '4px',
  right: '8px',
  width: '16px',
  height: '16px'
  },

  bodyStyle: {
  padding: '0px',
  overflow: "scroll"
  },

  paperStyle: {
  style: {
      borderRadius: '8px',
      overflow: "scroll"
  }
  }
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    paddingTop: "16px",
    paddingLeft: "24px",
    width: window.innerWidth <= 768 ? "auto" : "500px"
  },
}))(MuiDialogContent);

export default connect(
  state => ({

}), dispatch => bindActionCreators({
// launchGroupBuyModal,
// getGroupBuyPlans,
}, dispatch))
(class QRCodeDialog extends Component {

onClickDownload = () => {
  domtoimage.toJpeg(document.getElementById('qr-code-id'), { quality: 1 })
  .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'qr-orderhere.jpeg';
      link.href = dataUrl;
      link.click();
  });
}

  componentDidMount() {
      this.props.sendEventWithUtm("qrcodescan")
  }

  render() {
  return (
      <Dialog
      paperProps = {styles.paperStyle}
      contentStyle = {styles.contentStyle}
      bodyStyle = {styles.bodyStyle}
      modal={false}
      open={true}
      onRequestClose={this.props.closePopUp} >
        <div style={{position: 'relative'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'white', overflow: 'scroll', height: '100%'}} id="qr-code-id">
                <div style={window.innerWidth > 768 ? {fontSize: "30px", color: '#293895', fontFamily: 'robotobold', marginTop: '20px'} : {fontSize: "22px", color: '#293895', fontFamily: 'robotobold', marginTop: '25px'}}>{this.props.user.merchantName}</div>
                <div style={{fontSize: window.innerWidth > 768 ? "24px" : '19px', color: '#293895', marginTop: '5px', paddingBottom: '24px', overflowWrap: "anywhere", textAlign: "center", margin: "0 16px"}}>{this.props.url}</div>
                <div style={{position: 'relative'}}>
                  <img style={{height: window.innerWidth > 768 ? '350px': '270px'}} src={qr} />
                {window.innerWidth > 768
                ? 
                  <div style={{position: "absolute", top: "58px", left: "32px"}}>
                  <QRCode renderAs='svg' value={`${this.props.url}?utm_source=merchant_portal&utm_medium=qrcodescan&utm_campaign=orderhere`} size='230' fgColor='#293895'></QRCode>
                  </div>
                  :
                  <div style={{position: "absolute", top: "45px", left: "24px"}}>
                  <QRCode renderAs='svg' value={`${this.props.url}?utm_source=merchant_portal&utm_medium=qrcodescan&utm_campaign=orderhere`} size='180' fgColor='#293895'></QRCode>
                  </div>
                }
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#293895', width: '100%', color: '#fff', paddingTop: "76px", paddingBottom: this.props.isSnackBar ? "20px" : "", marginTop: "-82px"}}>
                    <div style={{fontSize: window.innerWidth > 768 ? '34px' : "24px", fontFamily: 'robotobold'}}>Order here</div>
                    {/* <a target="_blank" href={this.props.url} style={{color: '#293895'}}>{this.props.url}</a> */}
                    <div style={{fontSize: window.innerWidth > 768 ? '22px' : '14px', fontFamily: 'robotoregular', padding: "8px 40px 44px", textAlign: "center"}}>Scan using phone's camera to view menu
                        <span style={{fontFamily: 'robotobold'}}> Pickup</span> or get it <span style={{fontFamily: 'robotobold'}}>Home delivered</span></div>
                </div>
            </div>
            { this.props.isSnackBar && <div style={{ position: 'absolute', zIndex: '200000',textAlign:"center", bottom: window.innerWidth > 768 ? '1%' : '2%', left: window.innerWidth > 768 ? '40%' : '33%',cursor:"pointer", borderRadius: '8px', minWidth: '120px', padding: '10px 5px', backgroundColor: 'rgb(239, 29, 115)', color: '#fff'}} onClick={this.onClickDownload}>Download</div>}
        </div>
    </Dialog>
  );
}
})