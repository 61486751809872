import React, {Component} from 'react';
import informationIcon from '../../images/information-icon.svg';
import { sendEvent } from '../../helpers/Helpers';
import { Button,Image} from 'react-bootstrap';
import  './MagicPay.css';
const ga=window.ga;

export default class GroupBuyToast extends Component{
  handleToastButtonClick = (redirectPage) =>{
    sendEvent({
      event: this.props.toastContent.eventName,
      page_type: 'toast_click',
      page_name: 'toast_click',
      actor_id: this.props.user.merchantId,
      subject_merchant_id: this.props.user.merchantId
    });
    ga('send','event','sponsor_groupbuy','toast_click','toast_click');
    this.props.push(redirectPage)
  }

  render(){
    return(
     <div onClick={() => this.handleToastButtonClick(this.props.toastContent.redirectPage)} className="row">
      <div style={{ backgroundColor: "rgba(255, 255, 255, 0.3)",height:"100px",width:"60px"}} className="col-lg-1 col-xs-1 toastInformationIcon">
        <Image src={informationIcon}  style={{marginLeft:"15px",marginTop:"38px",width:"10px",height:"24px"}}/>
      </div>
      <div className="col-xs-1 toastRemoveIcon"></div>
      <div style={{marginTop:"20px",marginLeft:"15px" }} className="col-lg-8 col-xs-6 toastText">
        { this.props.toastContent && this.props.toastContent.toastText ? this.props.toastContent.toastText : '' }
      </div>
      <div className="col-lg-2 col-xs-2"></div>
      <div className='col-lg-1 col-xs-1'>
      <Button className="toastButton" style={{marginLeft:"-24px",marginTop:"45px",borderColor:'white'}}><span style={{color: this.props.toastContent.toastTextColor , fontFamily:"RobotoRegular"}}>KNOW MORE</span></Button>
      </div>
    </div>
    )};
}
