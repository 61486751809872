const LOAD_SUMMARY = 'brand/LOAD_SUMMARY';
const LOAD_SUMMARY_SUCCESS = 'brand/LOAD_SUMMARY_SUCCESS';
const LOAD_SUMMARY_FAIL = 'brand/LOAD_SUMMARY_FAIL';

const LOAD_TRANSACTIONS = 'brand/LOAD_TRANSACTIONS';
const LOAD_TRANSACTIONS_SUCCESS = 'brand/LOAD_TRANSACTIONS_SUCCESS';
const LOAD_TRANSACTIONS_FAIL = 'brand/LOAD_TRANSACTIONS_FAIL';

const LOAD_TOP_MERCHANTS = 'brand/LOAD_TOP_MERCHANTS';
const LOAD_TOP_MERCHANTS_SUCCESS = 'brand/LOAD_TOP_MERCHANTS_SUCCESS';
const LOAD_TOP_MERCHANTS_FAIL = 'brand/LOAD_TOP_MERCHANTS_FAIL';

const LOAD_TOP_CITIES = 'brand/LOAD_TOP_CITIES';
const LOAD_TOP_CITIES_SUCCESS = 'brand/LOAD_TOP_CITIES_SUCCESS';
const LOAD_TOP_CITIES_FAIL = 'brand/LOAD_TOP_CITIES_FAIL';



const initialState = {
  loaded: false,
  transactionsLoaded: false,
  transactions: [],
  callFurther: true,
  start: 0,
  end: 10,
};

export default function brand(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUMMARY:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        summary: action.result,
      };
    case LOAD_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_TRANSACTIONS:
      return {
        ...state,
        loadingTransactions: true
      };
    case LOAD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTransactions: false,
        transactionsLoaded: true,
        transactions: action.start ? [...state.transactions, ...action.result] : action.result,
        start: action.start + 10,
        callFurther: action.result.length != 0,
      };
    case LOAD_TRANSACTIONS_FAIL:
      return {
        ...state,
        loadingTransactions: false,
        transactionsLoaded: false,
        transactionsError: action.error
      };
    case LOAD_TOP_MERCHANTS:
      return {
        ...state,
        loadingTopMerchants: true
      };
    case LOAD_TOP_MERCHANTS_SUCCESS:
      return {
        ...state,
        loadingTopMerchants: false,
        topMerchantLoaded: true,
        top10Merchants: action.result,
      };
    case LOAD_TOP_MERCHANTS_FAIL:
      return {
        ...state,
        loadingTopMerchants: false,
        topMerchantLoaded: false,
        topMerchantsError: action.error
      };
    case LOAD_TOP_CITIES:
      return {
        ...state,
        loadingTopCities: true
      };
    case LOAD_TOP_CITIES_SUCCESS:
      return {
        ...state,
        loadingTopCities: false,
        topCitiesLoaded: true,
        top5Cities: action.result,
      };
    case LOAD_TOP_CITIES_FAIL:
      return {
        ...state,
        loadingTopCities: false,
        topCitiesLoaded: false,
        topCitiesError: action.error
      };
  default:
      return state;
  }
}

export function loadBrandSummary(accessToken, start, end) {
  return {
    types: [LOAD_SUMMARY, LOAD_SUMMARY_SUCCESS, LOAD_SUMMARY_FAIL],
    promise: (client) => client.default.post('/getBrandSummary', {
      data: { accessToken, start, end }
    })
  };
}

export function loadTopMerchants(accessToken) {
  return {
    types: [LOAD_TOP_MERCHANTS, LOAD_TOP_MERCHANTS_SUCCESS, LOAD_TOP_MERCHANTS_FAIL],
    promise: (client) => client.default.post('/getBrandTop10Merchants', {
      data: { accessToken }
    })
  };
}

export function loadTopCities(accessToken) {
  return {
    types: [LOAD_TOP_CITIES, LOAD_TOP_CITIES_SUCCESS, LOAD_TOP_CITIES_FAIL],
    promise: (client) => client.default.post('/getBrandTop5Cities', {
      data: { accessToken }
    })
  };
}

export function loadBrandTransactions(accessToken, start){
  return {
    types: [LOAD_TRANSACTIONS, LOAD_TRANSACTIONS_SUCCESS, LOAD_TRANSACTIONS_FAIL],
    promise: (client) => client.default.post('/getBrandTransactions', {
      data: { accessToken, start}
    }),
    start,
  };
}