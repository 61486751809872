import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {changeLanguage} from '../../redux/modules/claimBusiness';
import { sendEvent, createMCPLog } from '../../helpers/Helpers';
import star from "../../images/language_icon.svg";

export default connect(store => ({
  language: store.claimBusiness.language
}), dispatch => bindActionCreators({
    changeLanguage
}, dispatch))
(class SelectLanguage extends Component{
  constructor(props){
    super(props);
}

  handleChange = (event) => {
    if(event.target.value != "")
        this.props.changeLanguage(event.target.value);
  }

  render(){
    const country = this.props.country ? this.props.country.toLowerCase() : "india"
    const categoryId = this.props.categoryId ? this.props.categoryId : 1
    return(
        <select style={{color: window.innerWidth > 768 ? "rgba(0, 0, 0, 0.54)": "transparent", height: "28px", outline: 'none', border: window.innerWidth > 768 ? "1px solid rgb(239, 28, 116)" : "none", width:window.innerWidth > 768 ? "auto" : "28px", backgroundImage: window.innerWidth > 768 ? "none" :`url(${star})`, margin:"0 7px", backgroundSize:"contain"}} name="" ref='language' onChange = {this.handleChange} onClick={() => {
            window.ga('send', 'event', 'Logout_page','select_language','Search');
            sendEvent({
              utm_campaign:localStorage.getItem('landingSource') || '',
              subject_merchant_id:localStorage.getItem('landingMerchantId') || '',
              event: 'select_language',
              page_type:'select_language'
            })
            createMCPLog({
              phone: localStorage.getItem("subjectPhoneNumber") || '',
              utm_campaign: localStorage.getItem('landingSource') || '',
              merchant_id: localStorage.getItem('landingMerchantId') || '',
              event: 'select_language'
            });
            }} value={this.props.language} >
                <option value="">Select Language</option>
                <option value="english">English</option>
                {country == "india" && <option value="hindi">Hindi</option>}
                {country == "indonesia" && categoryId != 5 && <option value="bahasa">Bahasa</option>}
                {country == "india" && <option value="marathi">Marathi</option>}
                {country == "india" && <option value="telugu">Telugu</option>}
                {country == "india" && <option value="kannada">Kannada</option>}
            })
          </select>

    );
  }
})
