const LOAD = 'getArData/LOAD';
const LOAD_SUCCESS = 'getArData/LOAD_SUCCESS';
const LOAD_FAIL = 'getArData/LOAD_FAIL';

const CANCEL_AR_REQUEST = 'getArData/CANCEL_AR_REQUEST';
const CANCEL_AR_REQUEST_SUCCESS = 'getArData/CANCEL_AR_REQUEST_SUCCESS';
const CANCEL_AR_REQUEST_FAIL = 'getArData/CANCEL_AR_REQUEST_FAIL';

const TOGGLE_AR_MODAL = 'getArData/TOGGLE_AR_MODAL'

const intialState = {
  arDataLoaded: false,
  arModalVisible: false
};

export default function getArData(state = intialState, action = {} ){
	switch(action.type){
		case LOAD:
      return {
        ...state,
        arDataLoading: true,
      };
    case LOAD_SUCCESS:
      return {

        ...state,
        arDataLoading: false,
        arDataLoaded: true,
        arData: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        arDataLoading: false,
        arDataLoaded: false,
        error: action.error,
      };

    case CANCEL_AR_REQUEST:
      return{
        ...state,
        cancelArRequestLoading: true,
      };

    case CANCEL_AR_REQUEST_SUCCESS:
      return{
        ...state,
        cancelArRequestLoading: false,
        cancelArRequestLoaded: true,
        cancelArRequestData: action.result
      }; 
    case CANCEL_AR_REQUEST_FAIL:
      return{
        ...state,
        cancelArRequestLoading: false,
        cancelArRequestLoaded: true,
        cancelArRequestError: action.error
      };  
    case TOGGLE_AR_MODAL:
      return {
        ...state,
        arModalVisible: !state.arModalVisible
      }
        
    default:
      return state;
	}
}


export function loadArData(accessToken){
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getArData', {
      data: {accessToken}
    })
  };
}

export function cancelArSubscription(accessToken, arObject, arVoucherInfo){
  return{
    types:[CANCEL_AR_REQUEST, CANCEL_AR_REQUEST_SUCCESS, CANCEL_AR_REQUEST_FAIL],
    promise: (client) => client.default.post('cancelArSubscription', {
      data: {accessToken, arObject, arVoucherInfo}
    })
  };
}

export function toggleArModal(){
  return{
    type:TOGGLE_AR_MODAL,
  }
}