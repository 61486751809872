const LOAD = 'allVouchers/LOAD';
const LOAD_SUCCESS = 'allVouchers/LOAD_SUCCESS';
const LOAD_FAIL = 'allVouchers/LOAD_FAIL';

const initialState = {
  loaded: false,
  vouchers:[],
  seeMore: false,
};

export default function allVouchers(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      let newVouchers = [];
      // let voucherCount = action.voucherStart || 0;
      // let payByMagicpinCount = action.payByMagicpinStart || 0;

      let voucherCount = (action.result && action.result.vouchers && action.result.vouchers.next && action.result.vouchers.next.vouchers_next)
      let payByMagicpinCount = (action.result && action.result.vouchers && action.result.vouchers.next && action.result.vouchers.next.pay_by_magicpin_next)
  
      voucherCount = voucherCount && voucherCount > 0 ? voucherCount : action.voucherStart || 0;
      payByMagicpinCount = payByMagicpinCount && payByMagicpinCount > 0 ? payByMagicpinCount : action.payByMagicpinStart || 0;

      if( action.voucherStart ==0 && action.payByMagicpinStart==0 && action.result.vouchers.data ){
          newVouchers = action.result.vouchers.data
      }
      else{
        if(action.result.vouchers.data)
          newVouchers = [...state.vouchers, ...action.result.vouchers.data]
        else
          newVouchers = state.vouchers
      }

      const transactionDataExtraType = ["DELIVERY_OTHER", "DELIVERY_CHARGE"]
      let transactionDataExtra = newVouchers && Array.isArray(newVouchers) && newVouchers.filter((txn) => transactionDataExtraType.includes(txn.product_type && txn.product_type.toUpperCase() )) || []

      newVouchers = newVouchers
      .filter((txn) => !transactionDataExtraType.includes(txn.product_type && txn.product_type.toUpperCase() ))
      .map((txn) => {
        if(!txn || !txn.product_id || !Array.isArray(transactionDataExtra) || !transactionDataExtra.length || !txn.product_type || !(txn.product_type.toLowerCase() == "delivery"))
        {
          return txn
        }
        
        // let extraDataObj = transactionDataExtra.find(extraTxn => ((extraTxn && extraTxn.product_id) == txn.product_id))

        let extraDataObj = transactionDataExtra.reduce((extraDataObj, item) => {

          if((item && item.product_id) == txn.product_id){
            extraDataObj.bill_amount = extraDataObj.bill_amount + item.bill_amount || 0
            extraDataObj.commission = extraDataObj.commission + item.commission || 0
            extraDataObj.cost_to_magicpin = extraDataObj.cost_to_magicpin + item.cost_to_magicpin || 0
            extraDataObj.loan_deduct = extraDataObj.loan_deduct + item.loan_deduct || 0
            extraDataObj.netMerchantCreditAmount = extraDataObj.netMerchantCreditAmount + item.netMerchantCreditAmount || 0
            extraDataObj.pgAmount = extraDataObj.pgAmount + item.pgAmount || 0
            extraDataObj.totalDeduct = extraDataObj.totalDeduct + item.totalDeduct || 0
          }
    
          return extraDataObj
        }, {bill_amount: 0, commission: 0, cost_to_magicpin: 0, loan_deduct: 0, netMerchantCreditAmount: 0, pgAmount: 0, totalDeduct: 0})

        if(!extraDataObj){
          return txn
        }

        let newDataObj = {
          ...txn,
          bill_amount: txn.bill_amount + extraDataObj.bill_amount,
          commission: txn.commission + extraDataObj.commission,
          cost_to_magicpin: txn.cost_to_magicpin + extraDataObj.cost_to_magicpin,
          loan_deduct: txn.loan_deduct + extraDataObj.loan_deduct,
          netMerchantCreditAmount: txn.netMerchantCreditAmount + extraDataObj.netMerchantCreditAmount,
          pgAmount: txn.pgAmount + extraDataObj.pgAmount,
          totalDeduct: txn.totalDeduct + extraDataObj.totalDeduct
        }

        return newDataObj
      } )

      // let tempCount = action.result.vouchers.data.filter(voucher=>voucher.type=='voucher').length;
      // voucherCount += tempCount;
      // payByMagicpinCount += (action.result.vouchers.data.length - tempCount)
      return {
        ...state,
        loading: false,
        loaded: true,
        seeMore: action.result.vouchers.count <=0 || !action.result.vouchers.data ?  false : true,
        vouchers: newVouchers,
        voucherStats: action.result.voucherStats,
        voucherCount,
        payByMagicpinCount,
        error: null,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.allVouchers && globalState.allVouchers.loaded;
}

export function load(voucherFilters, merchantId, merchantUserId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getAllVouchers', {
      data: { ...voucherFilters }
    }),
    ...voucherFilters,
  };
}
