import moment from 'moment';
import { encryptObj, getCurrentEmailId } from '../../helpers/Helpers';
const LOAD = 'walletSummary/LOAD';
const LOAD_SUCCESS = 'walletSummary/LOAD_SUCCESS';
const LOAD_FAIL = 'walletSummary/LOAD_FAIL';

const LOAD_BALANCE = 'walletSummary/LOAD_BALANCE';
const LOAD_BALANCE_SUCCESS = 'walletSummary/LOAD_BALANCE_SUCCESS';
const LOAD_BALANCE_FAIL = 'walletSummary/LOAD_BALANCE_FAIL';

const GET_STATUS = 'walletSummary/GET_STATUS';
const GET_STATUS_SUCCESS = 'walletSummary/GET_STATUS_SUCCESS';
const GET_STATUS_FAIL = 'walletSummary/GET_STATUS_FAIL';

const REGISTER_WITHDRAWAL = 'walletSummary/REGISTER_WITHDRAWAL';
const REGISTER_WITHDRAWAL_SUCCESS = 'walletSummary/REGISTER_WITHDRAWAL_SUCCESS';
const REGISTER_WITHDRAWAL_FAIL = 'walletSummary/REGISTER_WITHDRAWAL_FAIL';

const WITHDRAWAL_HISTORY = 'walletSummary/WITHDRAWAL_HISTORY';
const WITHDRAWAL_HISTORY_SUCCESS = 'walletSummary/WITHDRAWAL_HISTORY_SUCCESS';
const WITHDRAWAL_HISTORY_FAIL = 'walletSummary/WITHDRAWAL_HISTORY_FAIL';

const UPDATE_DETAILS = 'walletSummary/UPDATE_DETAILS';
const UPDATE_DETAILS_SUCCESS = 'walletSummary/UPDATE_DETAILS_SUCCESS';
const UPDATE_DETAILS_FAIL = 'walletSummary/UPDATE_DETAILS_FAIL';

const CANCEL_REQUEST = 'walletSummary/CANCEL_REQUEST';
const CANCEL_REQUEST_SUCCESS = 'walletSummary/CANCEL_REQUEST_SUCCESS';
const CANCEL_REQUEST_FAIL = 'walletSummary/CANCEL_REQUEST_FAIL';

const CANCEL_WITHDRAWAL = 'walletSummary/CANCEL_WITHDRAWAL';
const CANCEL_WITHDRAWAL_SUCCESS = 'walletSummary/CANCEL_WITHDRAWAL_SUCCESS';
const CANCEL_WITHDRAWAL_FAIL = 'walletSummary/CANCEL_WITHDRAWAL_FAIL';

const WITHDRAWAL_REQUEST_STATUS = 'walletSummary/WITHDRAWAL_REQUEST_STATUS';
const WITHDRAWAL_REQUEST_STATUS_SUCCESS = 'walletSummary/WITHDRAWAL_REQUEST_STATUS_SUCCESS';
const WITHDRAWAL_REQUEST_STATUS_FAIL = 'walletSummary/WITHDRAWAL_REQUEST_STATUS_FAIL';

const UPDATE_REQUEST = 'walletSummary/UPDATE_REQUEST';
const UPDATE_REQUEST_SUCCESS = 'walletSummary/UPDATE_REQUEST_SUCCESS';
const UPDATE_REQUEST_FAIL = 'walletSummary/UPDATE_REQUEST_FAIL';

const WITHDRAWAL_REQUEST_INDONESIA_LOADING ='walletSummary/WITHDRAWAL_REQUEST_INDONESIA_LOADING';
const WITHDRAWAL_REQUEST_INDONESIA_SUCCESS ='walletSummary/WITHDRAWAL_REQUEST_INDONESIA_SUCCESS';
const WITHDRAWAL_REQUEST_INDONESIA_FAIL ='walletSummary/WITHDRAWAL_REQUEST_INDONESIA_FAIL';

const PAYMENT_COMMERCIALS_UPSERT_LOADING = 'walletSummary/PAYMENT_COMMERCIALS_UPSERT_LOADING'
const PAYMENT_COMMERCIALS_UPSERT_SUCCESS = 'walletSummary/PAYMENT_COMMERCIALS_UPSERT_SUCCESS'
const PAYMENT_COMMERCIALS_UPSERT_FAIL = 'walletSummary/PAYMENT_COMMERCIALS_UPSERT_FAIL'

const GET_MERCHANT_WALLET_TXN = 'walletSummary/GET_MERCHANT_WALLET_TXN'
const GET_MERCHANT_WALLET_TXN_SUCCESS = 'walletSummary/GET_MERCHANT_WALLET_TXN_SUCCESS'
const GET_MERCHANT_WALLET_TXN_FAIL = 'walletSummary/GET_MERCHANT_WALLET_TXN_FAIL'

const initialState = {
  loaded: false
};

export default function walletSummary(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        walletSummary: action.result.summary,
        transactionGmv: action.result.total_cashback_gmv,
        isMerchantOnWallet: action.result.isMerchantOnWallet,
        isExcluded: action.result.isExcluded
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_BALANCE:
      return {
        ...state,
        loadingBalance: true
      };
    case LOAD_BALANCE_SUCCESS:
      return {
        ...state,
        loadingBalance: false,
        loadedBalance: true,
        walletBalance: action.result.walletBalance,
        creditAmount: Math.min(0, action.result.creditAmount || 0),
        isMerchantOnWallet: action.result.isMerchantOnWallet,
        loanCommercialDto: action.result.loanCommercialDto,
        loanCommercialDtos: action.result.loanCommercialDtos,
        isRepaymentRedemptionPercent100: action.result.isRepaymentRedemptionPercent100,
        loanToRecover: action.result.loanToRecover,
        loanDisbursed: action.result.loanDisbursed,
        activeRepaymentRedemptionPercent: action.result.activeRepaymentRedemptionPercent
      };
    case LOAD_BALANCE_FAIL:
      return {
        ...state,
        loadingBalance: false,
        loadedBalance: false,
        balanceError: action.error
      };
    case GET_STATUS:
      return {
        ...state,
        loadingWithdrawalStatus: true,
      }
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        loadedWithdrawalStatus: true,
        loadingWithdrawalStatus: false,
        registeredBankDetails: action.result.registeredBankDetails && Array.isArray(action.result.registeredBankDetails) && action.result.registeredBankDetails.length ? action.result.registeredBankDetails : [],
        maxAmountWithdrawable: Math.floor(action.result.maxAllowedWithdrawable || 0),
        maxWithdrawable: action.result.maxWithdrawable,
        previousWithdrawals: action.result.previousWithdrawls,
        minimumWalletBalanceRequired: action.result.minimumWalletBalanceRequired,
        merchantBankDetailsRequest: action.result.merchantBankDetailsRequest, 
        firstWithdrawalNotDone: action.result.firstWithdrawalNotDone,
        redemptionsRelistingDate: action.result.redemptionsRelistingDate,
        merchantBankDetailsLatestRequest: action.result.merchantBankDetailsLatestRequest 
      }
    case GET_STATUS_FAIL:
      return {
        ...state,
        loadedWithdrawalStatus: false,
        loadingWithdrawalStatus: false,
        withdrawalStatusError: action.error
      }

    case UPDATE_REQUEST:
      return{
        ...state,
        updating:true,
      }
    case UPDATE_REQUEST_SUCCESS:
      return{
        ...state,
        updating:false,
        updated:true,
      } 
    case UPDATE_REQUEST_FAIL:
      return{
        ...state,
        updating:false,
        updated:false,
        updateError:action.error,
      }
    case CANCEL_REQUEST:
      return{
        ...state,
        cancelling:true,
      }
    case CANCEL_REQUEST_SUCCESS:
      return{
        ...state,
        cancelling:false,
        cancelled:true,
      } 
    case CANCEL_REQUEST_FAIL:
      return{
        ...state,
        cancelling:false,
        cancelled:false,
        canelError:action.error,
      }
    case CANCEL_WITHDRAWAL:
      return{
        ...state,
        cancellingWithdrawal:true,
      }
    case CANCEL_WITHDRAWAL_SUCCESS:
      return{
        ...state,
        cancellingWithdrawal:false,
        cancelledWithdrawal:true,
      } 
    case CANCEL_WITHDRAWAL_FAIL:
      return{
        ...state,
        cancellingWithdrawal:false,
        cancelledWithdrawal:false,
        cancelWithdrawalError:action.error,
      }
    case UPDATE_DETAILS:
      return{
        ...state,
        updatingDetails:true,
      }
    case UPDATE_DETAILS_SUCCESS:
      return{
        ...state,
        updatingDetails:false,
        updatedDetails:true,
      } 
    case UPDATE_DETAILS_FAIL:
      return{
        ...state,
        updatingDetails:false,
        updatedDetails:false,
        updateDetailsError:action.error,
      }    
      
      case REGISTER_WITHDRAWAL:
      return {
        ...state,
        sendingWithdrawalRequest: true,
        sentWithdrawalRequest: false,
      }
      case REGISTER_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        sendingWithdrawalRequest: false,
        sentWithdrawalRequest: true,
        priority: action.result.priority,
      }
      case REGISTER_WITHDRAWAL_FAIL:
      return {
        ...state,
        sendingWithdrawalRequest: false,
        sentWithdrawalRequest: true,
        registerRequestError: action.error
      }
      case WITHDRAWAL_HISTORY:
      return {
        ...state,
        loadingHistory: true,
        loadedHistory: false,
      }

      case WITHDRAWAL_HISTORY_SUCCESS:
      return {
        ...state,
        loadingHistory: false,
        loadedHistory: true,
        withdrawalHistory: action.result.transactionHistory
      }

      case WITHDRAWAL_HISTORY_FAIL:
      return {
        ...state,
        loadingHistory: false,
        loadedHistory: true,
        loadingHistoryError: action.error
      }

      case WITHDRAWAL_REQUEST_STATUS:
      return {
        ...state,
        requestingWithdrawalStatus:true,
        requestedWithdrawalStatus: false,
      }
      case WITHDRAWAL_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        requestingWithdrawalStatus:false,
        requestedWithdrawalStatus: true,
        requestingWithdrawalStatusError: null,
        accountNumber: action.result.accountNumber,
        bankIfscNumber: action.result.bankIfscNumber,
        beneficiaryName: action.result.beneficiaryName,
        receivedDate: action.result.receivedDate ? moment.unix(action.result.receivedDate/1000).format("MMM, DD, YYYY hh:mm a") : null,
        updatedAt:action.result.updatedAt? moment.unix(action.result.updatedAt/1000).format("MMM, DD, YYYY hh:mm a") : null,
        requestedAmount: action.result.requestedAmount ? action.result.requestedAmount : null,
        transactionDate: action.result.transactionDate ? moment.unix(action.result.transactionDate/1000).format("MMM, DD, YYYY hh:mm a") : null,
        transactionId: action.result.transactionId ? action.result.transactionId: 0,
        isNpsShown: action.result.isNpsShown,
        withdrawalStatus: action.result.withdrawalStatus,
        processingRemark: action.result.processingRemark || (action.result.withdrawalPaymentDtoList && Array.isArray(action.result.withdrawalPaymentDtoList) && action.result.withdrawalPaymentDtoList.length && action.result.withdrawalPaymentDtoList[0].processingRemark),
        merchantWithdrawlId: action.result.merchantWithdrawlId
      }
      case WITHDRAWAL_REQUEST_STATUS_FAIL:
      return {
        ...state,
        requestingWithdrawalStatus:false,
        requestedWithdrawalStatus: true,
        requestingWithdrawalStatusError: action.error
      }
      case WITHDRAWAL_REQUEST_INDONESIA_LOADING:
      return {
        ...state,
        withdrawRequestIndonesiaLoading: true,
      }
      case WITHDRAWAL_REQUEST_INDONESIA_SUCCESS:
      return {
        ...state,
        withdrawRequestIndonesiaLoaded: true,
        withdrawRequestIndonesiaLoading: false,
        withdrawRequestIndonesiaError: false,
      }
      case WITHDRAWAL_REQUEST_INDONESIA_FAIL:
      return {
        ...state,
        withdrawRequestIndonesiaLoaded: false,
        withdrawRequestIndonesiaLoading: false,
        withdrawRequestIndonesiaError: true,
      }

      case PAYMENT_COMMERCIALS_UPSERT_LOADING:
        return{
          ...state,
          paymentCommercialsUpserting: true,
          paymentCommercialsUpserted: false
        }
      case PAYMENT_COMMERCIALS_UPSERT_SUCCESS:
        return{
          ...state,
          paymentCommercialsUpserting: false,
          paymentCommercialsUpserted: true
        }
      case PAYMENT_COMMERCIALS_UPSERT_FAIL:
        return{
          ...state,
          paymentCommercialsUpserting: false,
          paymentCommercialsUpserted: false
        }

      case GET_MERCHANT_WALLET_TXN:
        return {
          ...state,
          requestingLedger: true,
          requestingLedgerDownload: action.downloadCsv ? true : false,
          ledgerUrlToDownload: null,
          ledgerDownloadForParent: action?.isParentMerchant,
          ledgerDownloadUserId: action?.userId,
          ledgerDownloadParentCalling: action?.isParentMerchantCalling
        };

      case GET_MERCHANT_WALLET_TXN_SUCCESS:
        return {
          ...state,
          requestingLedger: false,
          requestedLedger: true,
          requestLedgerError: null,
          requestingLedgerDownload: action.result.status === "PENDING" ? true : false,
          ledgerUrlToDownload: action.downloadCsv ? action.result.url : null,
          ledgerStartDate: action.startDate,
          ledgerEndDate: action.endDate,
          ledgerDownloadForParent: action?.isParentMerchant,
          ledgerDownloadUserId: action?.userId,
          ledgerDownloadParentCalling: action?.isParentMerchantCalling
        };
        
      case GET_MERCHANT_WALLET_TXN_FAIL:
        return {
          ...state,
          requestingLedger: false,
          requestedLedger: false,
          requestLedgerError: action?.error,
          requestingLedgerDownload: false,
          ledgerUrlToDownload: null,
          ledgerDownloadForParent: action?.isParentMerchant,
          ledgerDownloadUserId: action?.userId,
          ledgerDownloadParentCalling: action?.isParentMerchantCalling
        };

      default:
      return state;
    }
}

export function loadWalletSummary(accessToken, month) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/walletSummary', {
      data: { accessToken, month }
    })
  };
}

export function getWalletBalance(accessToken){
  return {
    types: [LOAD_BALANCE, LOAD_BALANCE_SUCCESS, LOAD_BALANCE_FAIL],
    promise: (client) => client.default.post('/getWalletBalance', {
      data: { accessToken }
    })
  };
}

export function getWithdrawalStatus(accessToken){
  return {
    types: [GET_STATUS, GET_STATUS_SUCCESS, GET_STATUS_FAIL],
    promise: (client) => client.default.post('/getWithdrawalStatus', {
      data: {accessToken}
    })
  }
}

export function getWithdrawalRequestStatus(accessToken){
  return {
    types: [WITHDRAWAL_REQUEST_STATUS, WITHDRAWAL_REQUEST_STATUS_SUCCESS, WITHDRAWAL_REQUEST_STATUS_FAIL],
    promise: (client) => client.default.post('/getWithdrawalRequestStatus', {
      data: {accessToken}
    })
  }
}

export function cancelWithdrawalRequest(accessToken, merchantWithdrawlId) {
  return {
    types: [CANCEL_WITHDRAWAL, CANCEL_WITHDRAWAL_SUCCESS, CANCEL_WITHDRAWAL_FAIL],
    promise: (client) => client.default.post('/cancelWithdrawalRequest', {
      data: {accessToken, merchantWithdrawlId}
    })
  }
}

export function registerWithdrawalRequest(accessToken, amount, bankDetails, firstWithdrawalNotDone, otpSentTo, otp, priority = 0){
  let data = new FormData();
  const bankDetail = JSON.stringify(bankDetails);
  const phoneValue  = otpSentTo ? otpSentTo.replace(/\D/g,'') : otpSentTo

  if(bankDetails.upiId)
  {
    data.append('upiId',bankDetails.upiId)
  }
  else
  {
    data.append('bankDetail', bankDetail);
  }
  
  data.append('accessToken', accessToken);
  data.append('amount', amount)
  data.append('phone', phoneValue);
  data.append('otp', otp)
  data.append('updatedBy', (getCurrentEmailId() || ""))

  if(firstWithdrawalNotDone){
    data.append('firstWithdrawalNotDone', firstWithdrawalNotDone)
  }
  if(priority)
  {
    data.append('priority', priority)
  }
  return {
    types: [REGISTER_WITHDRAWAL, REGISTER_WITHDRAWAL_SUCCESS, REGISTER_WITHDRAWAL_FAIL],
    promise: (client) => client.default.post('/registerWithdrawalRequest', {data})
  }
}

export function changeBankDetails(accessToken, bankAccountNumber, bankIfscNumber, beneficiaryName, phone, otp, latLonObj = {}){

  const xyObj = encryptObj(latLonObj, accessToken)

  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAIL],
    promise: (client) => client.default.post('/changeBankDetails', {data:{accessToken, bankAccountNumber, bankIfscNumber, beneficiaryName, phone, otp, xyObj}})
  }

} 

export function cancelRequest(accessToken, changeReason){
  return {
    types: [CANCEL_REQUEST, CANCEL_REQUEST_SUCCESS, CANCEL_REQUEST_FAIL],
    promise: (client) => client.default.post('/cancelBankRequest', {data:{accessToken, remark: changeReason}})
  }
}

export function getWithdrawalHistory(accessToken){
  return {
    types:[WITHDRAWAL_HISTORY, WITHDRAWAL_HISTORY_SUCCESS, WITHDRAWAL_HISTORY_FAIL],
    promise:(client)=>client.default.post('/getWithdrawalHistory', {
      data:{accessToken}
    })
  }
}

export function updateBankDetails(accessToken,bankDetail,files=[]){
  let data = new FormData();
  if(files.length)
    data.append('merchant_single_photo', files[0], files[0].name);
  data.append('bankDetail', bankDetail);
  data.append('accessToken', accessToken);
  return {
    types: [UPDATE_REQUEST, UPDATE_REQUEST_SUCCESS, UPDATE_REQUEST_FAIL],
    promise: (client) => client.default.post('/updateBankDetails', {data})
  }
}

export function generateWithdrawalRequestIndonesia(accessToken){
  return {
    types:[WITHDRAWAL_REQUEST_INDONESIA_LOADING, WITHDRAWAL_REQUEST_INDONESIA_SUCCESS, WITHDRAWAL_REQUEST_INDONESIA_FAIL],
    promise:(client)=>client.default.post('/generateWithdrawalRequestIndonesia', {
      data:{accessToken}
    })
  }
}

export function paymentCommercialsUpsert(accessToken){
  return {
    types:[PAYMENT_COMMERCIALS_UPSERT_LOADING, PAYMENT_COMMERCIALS_UPSERT_SUCCESS,PAYMENT_COMMERCIALS_UPSERT_FAIL],
    promise:(client)=>client.default.post('/paymentCommercialsUpsert', {
      data:{accessToken, updatedBy: (getCurrentEmailId() || "")}
    })
  }
}

export function getMerchantWalletTransactionAsync(accessToken, startDate, endDate, email, downloadCsv, isParentMerchant,
  userId, isParentMerchantCalling ) {
  return {
  types: [GET_MERCHANT_WALLET_TXN, GET_MERCHANT_WALLET_TXN_SUCCESS, GET_MERCHANT_WALLET_TXN_FAIL],
  promise: (client) => client.default.post('/getMerchantWalletTransactionAsync', {
    data: {
      accessToken,
      startDate,
      endDate,
      email,
      downloadCsv,
      source: (getCurrentEmailId() || ""),
      isParentMerchant,
      userId,
      isParentMerchantCalling
    }
  }),
  startDate, endDate, downloadCsv, isParentMerchant, userId, isParentMerchantCalling
}
}

export function stopMerchantWalletTransactionAsync() {
  return {
    type: GET_MERCHANT_WALLET_TXN_FAIL
  };
}