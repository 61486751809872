import React, {Component, PropTypes} from 'react';
import {Carousel} from 'react-bootstrap';
import config from '../../config';

export default class GalleryBlock extends Component{
  render(){
    return(
      <div>
        <section className="all-merchant-logo-wrapper">
          <div className="container">
            <div  className="all-merchant-logo">
              <h3>Join the 10,000+ businesses using magicpin</h3>
              <img src={config.host + "/images/all-mercht-logo.png"} />
            </div>
          </div>
        </section>
        <section className="mrcht-login-about-wrapper">
          <div className="container">
            <div className="mrcht-login-about-bx">
              <h2>About us</h2>
              <p>magicpin is a community that binds customers, activities, and merchants in a locality. It allows brands to connect and engage with their potential and existing customers. Voted as the best local discovery app last year, it allows you to boost your business and increase your visibility in the market.</p>
            </div>
          </div>
        </section>

        <section className="selfie-wrapper">
          <div className="container">
            <Carousel controls={false} style={{textAlign: 'center'}}>
             <Carousel.Item className='selfie-txt-cont'>
               <div className="selfie-txt-img"><img src={config.host + "/images/selfie1_web.png"} /></div>
               <div className="selfie-txt-txt">
                 <div style={{verticalAlign:'middle'}}>
                   <h3>Acquire new customers for businesses</h3>
                   <ul>
                     <li>Spread the word</li>
                     <li>Incentivize new customers</li>
                   </ul>
                 </div>
               </div>
             </Carousel.Item>
             <Carousel.Item className='selfie-txt-cont'>
               <div className="selfie-txt-img"><img src={config.host + "/images/selfie2_web.png"} /></div>
               <div className="selfie-txt-txt">
                 <div style={{verticalAlign:'middle'}}>
                   <h3>Drive utilization for existing businesses</h3>
                   <ul>
                     <li>Get rid of unsold inventory</li>
                     <li>Improve usage in lean periods</li>
                   </ul>
                 </div>
               </div>
             </Carousel.Item>
             <Carousel.Item className='selfie-txt-cont'>
               <div className="selfie-txt-img"><img src={config.host + "/images/selfie3_web.png"} /></div>
               <div className="selfie-txt-txt">
                 <div style={{verticalAlign:'middle'}}>
                   <h3>Engage & Increase repeat for existing businesses</h3>
                   <ul>
                     <li>Reward loyal users</li>
                     <li>Incentivize users to repeat</li>
                  </ul>
                 </div>
               </div>
             </Carousel.Item>
           </Carousel>
          </div>
        </section>
        <section className="acquire-retain-analysis-lg-wrapper">
          <div className="container">
            <div className="acquire-retain-analysis-lg-row">
              <div className="selfie-txt-txt acquire-lg-txt-block">
                <h3>Acquire</h3>
                <p>Promote your business with a platform designed to attract the right audience based on location, spending pattern, gender, and more.</p>
              </div>
              <div className="acquire-lg-img-block">
                <img src={config.host + "/images/acquire-lg.png"} />
              </div>
            </div>
            <div className="acquire-retain-analysis-lg-row">
              <div id="RetainLgImgPc" className="acquire-lg-img-block">
                <img src={config.host + "/images/Retain-lg.png"} />
              </div>
              <div className="selfie-txt-txt acquire-lg-txt-block acquire-lg-txt-block-rgt">
                <h3>Retain</h3>
                <p>magicpin makes it possible for businesses to bring back that nostalgic, remember-your-name kind of loyalty that consumers look for.</p>
              </div>
              <div id="RetainLgImgMob" className="acquire-lg-img-block">
                <img src={config.host + "/images/Retain-lg.png"} />
              </div>
            </div>
            <div className="acquire-retain-analysis-lg-row">
              <div className="selfie-txt-txt acquire-lg-txt-block">
                <h3>Analyse</h3>
                <p>magicpin makes it possible for businesses to remember seemingly small details about customers from their names to their favorite drinks. Feel the pulse of your business: page views, generated revenue, customers data, and more.</p>
              </div>
              <div className="acquire-lg-img-block">
                <img src={config.host + "/images/Analysis-lg.png"} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
