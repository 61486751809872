import React, { useEffect, useState } from 'react'
import TrendingUpBlack from '../../images/trendingUpBlack.svg'
import Eye from '../../images/eye.svg'
import CircleArrow from '../../images/circleArrow.svg'
import { categoryName, feedPushSamplesMapping } from '../../constants';
import { Modal } from 'react-bootstrap';
import CoverImage from './CoverImage';

const FeedPushSamples = (props) => {
    const [scrollViewIndex, setScrollViewIndex] = useState(0);
    useEffect(() => {
        setScrollViewIndex(0)
    }, [])  

    const category = categoryName[props.user.categoryId];
    const FeedSample0 = require(`../../images/feed_sample_0_${category}.png`);
    const FeedSample1 = require(`../../images/feed_sample_1_${category}.png`);
    const FeedSample2 = require(`../../images/feed_sample_2_${category}.png`);
    const currBrandName = feedPushSamplesMapping[category]["name"][scrollViewIndex];

    const feedPushSamples = [{src: FeedSample0}, {src: FeedSample1}, {src: FeedSample2}];

    const leftScroll = (event) => {
        event.stopPropagation()
        if(scrollViewIndex > 0){
            let decrement = scrollViewIndex - 1;
            setScrollViewIndex(decrement);
            const element = document.getElementById(`desc${decrement}`)
            element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });           
        }
    }

    const rightScroll = (event) => {
        event && event.stopPropagation()
        if(scrollViewIndex < feedPushSamples.length - 1){
            let increment = scrollViewIndex + 1;
            setScrollViewIndex(increment);
            let element = document.getElementById(`desc${increment}`)
            element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });           
        }
    }

    return (
        <Modal dialogClassName='modal-dialog new-modal-small small-rp-modal small-rp-modal-width' show={props.show} style={{ height: 'auto' }} onHide={props.onHide}>
            <div className="modal-content" style={{ height: 'auto' }}>
                <div className="modal-header" style={{ marginTop: '0' }}>
                    <h2 style={{ textTransform: 'none' }}>FeedPush Samples</h2>
                    <div onClick={props.onHide} className="dialog-cross fa fa-close" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    </div>
                </div>
                <div className="modal-body" >
                    <div style={{ backgroundColor: 'rgba(196, 196, 196, 0.15)', height: '50px', padding: '2vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <div style={{height:'36px', width:'36px', marginRight:'10px', overflow:'auto', borderRadius:'5px'}}>
                            <CoverImage coverImageComponent={FeedpushLogo}/>
                           </div> */}
                            <div>
                                <div style={{ fontSize: 'medium' }}>{currBrandName}</div>
                                <div style={{ fontSize: 'smaller', color: 'rgba(0,0,0,0.54)' }}>{props.user.locality}</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 'medium' }}>Sponsored</div>
                    </div>

                    <div>
                        <div style={{ margin: '1vw', position: 'relative' }}>
                            <div className='forum-collection-heading' style={{ borderTop: 'none', overflowX:'hidden' }} >
                                {feedPushSamples.map((feedSample, index) => (
                                    <div id={`desc${index}`} className='forum-question' style={{ width: '97%', padding: '0', border: 'none', display: 'flex', alignItems: 'center' }}>
                                    <img className='mobileCurve' src={feedSample.src} style={{ objectFit: 'cover' }} width="100%" />
                                </div>
                                ))}

                                <div>
                                    {
                                        scrollViewIndex > 0 &&
                                        <img className="pointer"
                                            src={CircleArrow}
                                            style={{ position: 'absolute', left: '1px', top: '45%', transform: 'rotate(180deg)', width:'7%' }}
                                            onClick={(event) => leftScroll(event, "feedsample")} />

                                    }
                                    {scrollViewIndex < feedPushSamples.length - 1 &&
                                        <img className="pointer"
                                            src={CircleArrow}
                                            style={{ position: 'absolute', right: '10px', top: '45%', width:'7%' }}
                                            onClick={(event) => rightScroll(event, "feedsample", 2)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: '#EBEBFF', height: '50px', padding: '2vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                        <div style={{ width: '50%', borderRight: '1px solid rgba(101, 97, 232, 0.2)', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ height: '20px', width: '20px', marginRight: '10px' }}><CoverImage coverImageComponent={Eye} /></div>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>10k + Views</div>
                        </div>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div style={{ height: '20px', width: '20px', marginRight: '10px' }}><CoverImage coverImageComponent={TrendingUpBlack} /></div>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>5% Claims</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default FeedPushSamples