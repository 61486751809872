const UPDATE_CONTENT = 'merchantContent/UPDATE_CONTENT'
const UPLOAD_LOADING = 'merchantContent/UPLOAD_LOADING'
const UPLOAD_SUCCESS = 'merchantContent/UPLOAD_SUCCESS'
const UPLOAD_FAILURE = 'merchantContent/UPLOAD_FAILURE'
const PORTAL_DATA_LOADING = 'merchantContent/PORTAL_DATA_LOADING'
const PORTAL_DATA_SUCCESS = 'merchantContent/PORTAL_DATA_SUCCESS'
const PORTAL_DATA_FAILURE = 'merchantContent/PORTAL_DATA_FAILURE'
const GET_CONTENT_LOADING = 'merchantContent/GET_CONTENT_LOADING'
const GET_CONTENT_SUCCESS = 'merchantContent/GET_CONTENT_SUCCESS'
const GET_CONTENT_FAILURE = 'merchantContent/GET_CONTENT_FAILURE'
const UPDATE_UPLOAD_CONTENT = 'merchantContent/UPDATE_UPLOAD_CONTENT'
const SET_MERCHANT_UPLOAD_DETAILS = 'merchantContent/SET_MERCHANT_UPLOAD_DETAILS'
const PORTAL_NAVIGATION = 'merchantContent/PORTAL_NAVIGATION'
const CONTENT_REVIEW_LOADING = 'merchantContent/CONTENT_REVIEW_LOADING'
const CONTENT_REVIEW_SUCCESS = 'merchantContent/CONTENT_REVIEW_SUCCESS'
const CONTENT_REVIEW_FAILURE = 'merchantContent/CONTENT_REVIEW_FAILURE'

let arrayToConsider = null;

const initialState = {
  loaded: false,
  data: [],
  merchantData: {}
};

function setMerchantDataForPortal(dataArr, currentIndex, direction) {
  if (!Array.isArray(dataArr))
    return {}
  let len = dataArr.length
  let nextIndex = currentIndex + direction
  nextIndex = (nextIndex < len && nextIndex >= 0) ? nextIndex : currentIndex

  return {
    data: dataArr[nextIndex].data,
    m_id: dataArr[nextIndex].merchant_id,
    phone: dataArr[nextIndex].phone,
    portalIndex: nextIndex
  }
}

export default function merchantContent(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_CONTENT:
      let merchantData = {}
      if (action.parentKey)
        merchantData = { ...state.merchantData, [action.parentKey]: { ...state.merchantData[action.parentKey], [action.key]: action.value } }
      else
        merchantData = { ...state.merchantData, [action.key]: action.value }
      localStorage.setItem('merchantUploadData', JSON.stringify(merchantData))
      return {
        ...state,
        merchantData: merchantData
      }
    case PORTAL_DATA_SUCCESS:
      let parsedWrapperData = []
      try{
        parsedWrapperData = JSON.parse(action.result)
      }
      catch(err){
        console.log('error in parsing merchantData ' + err)
      }
      return {
        ...state,
        contentPortalData: parsedWrapperData,
        portalIndex: 0,
        merchantData: parsedWrapperData && Array.isArray(parsedWrapperData) && parsedWrapperData.length > 0 ? parsedWrapperData[0].data : {},
        contentMerchantId: parsedWrapperData && Array.isArray(parsedWrapperData) && parsedWrapperData.length > 0 ? parsedWrapperData[0].merchant_id : null,
        contentMerchantPhone: parsedWrapperData && Array.isArray(parsedWrapperData) && parsedWrapperData.length > 0 ? parsedWrapperData[0].phone : null
      }
    case GET_CONTENT_LOADING:
      return {
        ...state,
        merchantData: localStorage.getItem('merchantUploadData') && JSON.parse(localStorage.getItem('merchantUploadData')) || {}
      }
    case GET_CONTENT_SUCCESS:
      parsedWrapperData = []
      try{
        parsedWrapperData = JSON.parse(action.result)
      }
      catch(err){
        console.log('error in parsing merchantData '+ err)
      }
      return {
        ...state,
        merchantData: (localStorage.getItem('merchantUploadData') && JSON.parse(localStorage.getItem('merchantUploadData'))) || parsedWrapperData.length > 0 ? parsedWrapperData[0].data : {},
        merchantDataLoaded: true
      }
    case UPDATE_UPLOAD_CONTENT:
      return {
        ...state,
        merchantData: { ...state.merchantData, ['info']: { ...state.merchantData['info'], logo: action.currentData.merchant_logo, storeName: action.currentData.merchant_name } }
      }
    case SET_MERCHANT_UPLOAD_DETAILS:
      return {
        ...state,
        contentMerchantId: action.contentMerchantId,
        contentMerchantPhone: action.contentMerchantPhone
      }
    case PORTAL_NAVIGATION:
      let { data, m_id, phone, portalIndex } = setMerchantDataForPortal(state.contentPortalData, state.portalIndex, action.direction)
      return {
        ...state,
        portalIndex: portalIndex,
        merchantData: data,
        contentMerchantId: m_id,
        contentMerchantPhone: phone
      }
    default:
      return state;
  }
}

export function updateMerchantContent(key, value, parentKey) {
  return {
    type: UPDATE_CONTENT,
    key: key,
    value: value,
    parentKey: parentKey
  }
}

export function uploadContent(data, merchant_id, phone) {
  return {
    types: [UPLOAD_LOADING, UPLOAD_SUCCESS, UPLOAD_FAILURE],
    promise: (client) => client.default.post('https://magicpin.in/api/apiWrapper', {
      data: {
        "type": "POST",
	      "url": "http://mcp.magicpin.in/content-portal/merchant/content/",
        "data": {
                "data": data,
                "merchant_id": merchant_id,
                "phone": phone
              }
      }
    })
  }
}

export function getMerchantContent(merchantId, phone) {
  return {
    types: [GET_CONTENT_LOADING, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE],
    promise: (client) => client.default.post('https://magicpin.in/api/apiWrapper', {
      data: {
        "type": "GET",
	      "url": "http://mcp.magicpin.in/content-portal/merchant/content/merchant/?merchant_id="+merchantId+"&phone="+phone
      }
    })
  }
}

export function getMerchantContentForPortal(assignedTo) {
  return {
    types: [PORTAL_DATA_LOADING, PORTAL_DATA_SUCCESS, PORTAL_DATA_FAILURE],
    promise: (client) => client.default.post('https://magicpin.in/api/apiWrapper', {
      data: {
        "type": "GET",
	      "url": 'http://mcp.magicpin.in/content-portal/merchant/content/?assignedTo=' + assignedTo
      }
    })
  }
}

export function reviewContent(merchantId, phone, status) {
  return {
    types: [CONTENT_REVIEW_LOADING, CONTENT_REVIEW_SUCCESS, CONTENT_REVIEW_FAILURE],
    promise: (client) => client.default.post('https://magicpin.in/api/apiWrapper', {
      data: {
        "type": "POST",
	      "url": "http://mcp.magicpin.in/content-portal/merchant/content/change_review/",
        "data": {
                "merchant_id":merchantId,
                "phone":phone,
                "review_status":status
              }
      }
  })
}
}

export function updateUploadData(data) {
  return {
    type: UPDATE_UPLOAD_CONTENT,
    currentData: data
  }
}

export function setContentUploadMerchantDetails(merchantId, phone) {
  return {
    type: SET_MERCHANT_UPLOAD_DETAILS,
    contentMerchantId: merchantId,
    contentMerchantPhone: phone
  }
}

export function navigateForPortal(direction) {
  return {
    type: PORTAL_NAVIGATION,
    direction: direction
  }
}
