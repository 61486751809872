import { SELECT_CHILD } from './childMerchants';
import moment from 'moment'

const LOAD = 'businessSummaryParentMerchant/LOAD';
const LOAD_SUCCESS = 'businessSummaryParentMerchant/LOAD_SUCCESS';
const LOAD_FAIL = 'businessSummaryParentMerchant/LOAD_FAIL';

const LOAD_NEW_CUSTOMERS = 'businessSummaryParentMerchant/LOAD_NEW_CUSTOMERS'
const LOAD_NEW_CUSTOMERS_SUCCESS = 'businessSummaryParentMerchant/LOAD_NEW_CUSTOMERS_SUCCESS';
const LOAD_NEW_CUSTOMERS_FAIL = 'businessSummaryParentMerchant/LOAD_NEW_CUSTOMERS_FAIL';

const LOAD_LOST_CUSTOMERS = 'businessSummaryParentMerchant/LOAD_LOST_CUSTOMERS'
const LOAD_LOST_CUSTOMERS_SUCCESS = 'businessSummaryParentMerchant/LOAD_LOST_CUSTOMERS_SUCCESS';
const LOAD_LOST_CUSTOMERS_FAIL = 'businessSummaryParentMerchant/LOAD_LOST_CUSTOMERS_FAIL';

const LOAD_GROWTH_DATA = 'businessSummaryParentMerchant/LOAD_GROWTH_DATA';
const LOAD_GROWTH_DATA_SUCCESS = 'businessSummaryParentMerchant/LOAD_GROWTH_DATA_SUCCESS';
const LOAD_GROWTH_DATA_FAIL = 'businessSummaryParentMerchant/LOAD_GROWTH_DATA_FAIL';

const LOAD_WALLET_SUMMARY = 'businessSummaryParentMerchant/LOAD_WALLET_SUMMARY';
const LOAD_WALLET_SUMMARY_SUCCESS = 'businessSummaryParentMerchant/LOAD_WALLET_SUMMARY_SUCCESS';
const LOAD_WALLET_SUMMARY_FAIL = 'businessSummaryParentMerchant/LOAD_WALLET_SUMMARY_FAIL';

const LOAD_BUSINESS_WITHDATE = 'businessSummaryParentMerchant/LOAD_BUSINESS_WITHDATE';
const LOAD_BUSINESS_WITHDATE_SUCCESS = 'businessSummaryParentMerchant/LOAD_BUSINESS_WITHDATE_SUCCESS';
const LOAD_BUSINESS_WITHDATE_FAIL = 'businessSummaryParentMerchant/LOAD_BUSINESS_WITHDATE_FAIL';

const LOAD_CUSTOMER_PROFILE = 'businessSummaryParentMerchant/LOAD_CUSTOMER_PROFILE';
const LOAD_CUSTOMER_PROFILE_SUCCESS = 'businessSummaryParentMerchant/LOAD_CUSTOMER_PROFILE_SUCCESS';
const LOAD_CUSTOMER_PROFILE_FAIL = 'businessSummaryParentMerchant/LOAD_CUSTOMER_PROFILE_FAIL';

const LOAD_GMV = 'businessSummaryParentMerchant/LOAD_GMV';
const LOAD_GMV_SUCCESS = 'businessSummaryParentMerchant/LOAD_GMV_SUCCESS';
const LOAD_GMV_FAIL = 'businessSummaryParentMerchant/LOAD_GMV_FAIL';

const LOAD_IMPRESSIONS = 'businessSummaryParentMerchant/LOAD_IMPRESSIONS';
const LOAD_IMPRESSIONS_SUCCESS = 'businessSummaryParentMerchant/LOAD_IMPRESSIONS_SUCCESS';
const LOAD_IMPRESSIONS_FAIL = 'businessSummaryParentMerchant/LOAD_IMPRESSIONS_FAIL';

const LOAD_RETAIL_CAMPAIGN_SUMMARY = 'businessSummaryParentMerchant/LOAD_RETAIL_CAMPAIGN_SUMMARY';
const LOAD_RETAIL_CAMPAIGN_SUMMARY_SUCCESS = 'businessSummaryParentMerchant/LOAD_RETAIL_CAMPAIGN_SUMMARY_SUCCESS'
const LOAD_RETAIL_CAMPAIGN_SUMMARY_FAIL = 'businessSummaryParentMerchant/LOAD_RETAIL_CAMPAIGN_SUMMARY_FAIL'

const LOAD_REPEAT_RATE = 'businessSummaryParentMerchant/LOAD_REPEAT_RATE';
const LOAD_REPEAT_RATE_SUCCESS = 'businessSummaryParentMerchant/LOAD_REPEAT_RATE_SUCCESS'
const LOAD_REPEAT_RATE_FAIL = 'businessSummaryParentMerchant/LOAD_REPEAT_RATE_FAIL'

const LOAD_TXN_PATTERN = 'businessSummaryParentMerchant/LOAD_TXN_PATTERN';
const LOAD_TXN_PATTERN_SUCCESS = 'businessSummaryParentMerchant/LOAD_TXN_PATTERN_SUCCESS'
const LOAD_TXN_PATTERN_FAIL = 'businessSummaryParentMerchant/LOAD_TXN_PATTERN_FAIL'

const LOAD_CITY = 'businessSummaryParentMerchant/LOAD_CITY';
const LOAD_CITY_SUCCESS = 'businessSummaryParentMerchant/LOAD_CITY_SUCCESS'
const LOAD_CITY_FAIL = 'businessSummaryParentMerchant/LOAD_CITY_FAIL'

const LOAD_GMV_CHILD = 'businessSummaryParentMerchant/LOAD_GMV_CHILD';
const LOAD_GMV_CHILD_SUCCESS = 'businessSummaryParentMerchant/LOAD_GMV_CHILD_SUCCESS'
const LOAD_GMV_CHILD_FAIL = 'businessSummaryParentMerchant/LOAD_GMV_CHILD_FAIL'

const LOAD_FUNDED_CHILD = 'businessSummaryParentMerchant/LOAD_FUNDED_CHILD';
const LOAD_FUNDED_CHILD_SUCCESS = 'businessSummaryParentMerchant/LOAD_FUNDED_CHILD_SUCCESS'
const LOAD_FUNDED_CHILD_FAIL = 'businessSummaryParentMerchant/LOAD_FUNDED_CHILD_FAIL'

const LOAD_NEW_REPEAT = 'businessSummaryParentMerchant/LOAD_NEW_REPEAT';
const LOAD_NEW_REPEAT_SUCCESS = 'businessSummaryParentMerchant/LOAD_NEW_REPEAT_SUCCESS'
const LOAD_NEW_REPEAT_FAIL = 'businessSummaryParentMerchant/LOAD_NEW_REPEAT_FAIL'

const LOAD_CHILD_MERCHANTS = 'businessSummaryParentMerchant/LOAD_CHILD_MERCHANTS'
const LOAD_CHILD_MERCHANTS_SUCCESS = 'businessSummaryParentMerchant/LOAD_CHILD_MERCHANTS_SUCCESS'
const LOAD_CHILD_MERCHANTS_FAIL = 'businessSummaryParentMerchant/LOAD_CHILD_MERCHANTS_FAIL'

const LOAD_GROUPBUY_STAT = 'businessSummaryParentMerchant/LOAD_GROUPBUY_STAT';
const LOAD_GROUPBUY_STAT_SUCCESS = 'businessSummaryParentMerchant/LOAD_GROUPBUY_STAT_SUCCESS'
const LOAD_GROUPBUY_STAT_FAIL = 'businessSummaryParentMerchant/LOAD_GROUPBUY_STAT_FAIL'

const LOAD_OFFERS = 'businessSummaryParentMerchant/LOAD_OFFERS';
const OFFERS_LOADED = 'businessSummaryParentMerchant/OFFERS_LOADED'
const OFFERS_FAILED = 'businessSummaryParentMerchant/OFFERS_FAILED'

const LOAD_POSTS = 'businessSummaryParentMerchant/LOAD_POSTS';
const POSTS_LOADED = 'businessSummaryParentMerchant/POSTS_LOADED'
const POSTS_FAILED = 'businessSummaryParentMerchant/POSTS_FAILED'

const UPLOAD_CSV = 'businessSummaryParentMerchant/UPLOAD_CSV';
const CSV_UPLOADED = 'businessSummaryParentMerchant/CSV_UPLOADED'
const CSV_FAILED = 'businessSummaryParentMerchant/CSV_FAILED'

const CHECK_CSV = 'businessSummaryParentMerchant/CHECK_CSV';
const CSV_CHECKED = 'businessSummaryParentMerchant/CSV_CHECKED'
const CHECK_FAILED = 'businessSummaryParentMerchant/CHECK_FAILED'

const LOAD_REPEAT_FREQUENCY = 'businessSummaryParentMerchant/LOAD_REPEAT_FREQUENCY';
const LOAD_REPEAT_FREQUENCY_SUCCESS = 'businessSummaryParentMerchant/LOAD_REPEAT_FREQUENCY_SUCCESS';
const LOAD_REPEAT_FREQUENCY_FAILED = 'businessSummaryParentMerchant/LOAD_REPEAT_FREQUENCY_FAILED';

const CHANGE_SELECTED_CITIES = 'businessSummaryParentMerchant/CHANGE_SELECTED_CITIES'
const CHANGE_SELECTED_LOCALITIES = 'businessSummaryParentMerchant/CHANGE_SELECTED_LOCALITIES'

const LOAD_STORE_COMPARE = 'businessSummaryParentMerchant/LOAD_STORE_COMPARE';
const STORE_COMPARE_LOADED = 'businessSummaryParentMerchant/STORE_COMPARE_LOADED'
const STORE_COMPARE_FAILED = 'businessSummaryParentMerchant/STORE_COMPARE_FAILED'

const INITIALIZE_DATE = 'businessSummaryParentMerchant/INITIALIZE_DATE'
const LOCALITY_COMPARE = 'businessSummaryParentMerchant/LOCALITY_COMPARE'

const LOAD_CUSTOMER_DISTRIBUTION = 'businessSummaryParentMerchant/LOAD_CUSTOMER_DISTRIBUTION';
const CUSTOMER_DISTRIBUTION_LOADED = 'businessSummaryParentMerchant/CUSTOMER_DISTRIBUTION_LOADED'
const CUSTOMER_DISTRIBUTION_FAILED = 'businessSummaryParentMerchant/CUSTOMER_DISTRIBUTION_FAILED'

const LOAN_AMOUNT_LOAD = 'businessSummaryParentMerchant/LOAN_AMOUNT_LOAD'
const LOAN_AMOUNT_SUCCESS = 'businessSummaryParentMerchant/LOAN_AMOUNT_SUCCESS'
const LOAN_AMOUNT_FAIL = 'businessSummaryParentMerchant/LOAN_AMOUNT_FAIL'

const WALLET_BALANCE_PARENT_LOAD = 'businessSummaryParentMerchant/WALLET_BALANCE_PARENT_LOAD'
const WALLET_BALANCE_PARENT_SUCCESS = 'businessSummaryParentMerchant/WALLET_BALANCE_PARENT_SUCCESS'
const WALLET_BALANCE_PARENT_FAIL = 'businessSummaryParentMerchant/WALLET_BALANCE_PARENT_FAIL'

const AOV_DATA_LOAD = 'businessSummaryParentMerchant/AOV_DATA_LOAD'
const AOV_DATA_SUCCESS = 'businessSummaryParentMerchant/AOV_DATA_SUCCESS'
const AOV_DATA_FAIL = 'businessSummaryParentMerchant/AOV_DATA_FAIL'

const LOAD_RETAIL_PREFERENCE = 'businessSummaryParentMerchant/LOAD_RETAIL_PREFERENCE'
const LOAD_RETAIL_PREFERENCE_SUCCESS = 'businessSummaryParentMerchant/LOAD_RETAIL_PREFERENCE_SUCCESS'
const LOAD_RETAIL_PREFERENCE_FAIL = 'businessSummaryParentMerchant/LOAD_RETAIL_PREFERENCE_FAIL'

const SUBMIT_CATEGORY = 'businessSummaryParentMerchant/SUBMIT_CATEGORY'
const SUBMIT_CATEGORY_SUCCESS = 'businessSummaryParentMerchant/SUBMIT_CATEGORY_SUCCESS'
const SUBMIT_CATEGORY_FAIL = 'businessSummaryParentMerchant/SUBMIT_CATEGORY_FAIL'

const LOAD_RETAIL_FEED = 'businessSummaryParentMerchant/LOAD_RETAIL_FEED'
const LOAD_RETAIL_FEED_SUCCESS = 'businessSummaryParentMerchant/LOAD_RETAIL_FEED_SUCCESS'
const LOAD_RETAIL_FEED_FAIL = 'businessSummaryParentMerchant/LOAD_RETAIL_FEED_FAIL'

const LOAD_RETAIL_CATEGORY_IMPRESSION = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION'
const LOAD_RETAIL_CATEGORY_IMPRESSION_SUCCESS = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION_SUCCESS'
const LOAD_RETAIL_CATEGORY_IMPRESSION_FAIL = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION_FAIL'

const LOAD_RETAIL_CATEGORY_IMPRESSION_CPC = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION_CPC'
const LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_SUCCESS = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_SUCCESS'
const LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_FAIL = 'businessSummaryParentMerchant/LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_FAIL'

const SEND_MAIL = 'businessSummaryParentMerchant/SEND_MAIL'
const SEND_MAIL_SUCCESS = 'businessSummaryParentMerchant/SEND_MAIL_SUCCESS'
const SEND_MAIL_FAIL = 'businessSummaryParentMerchant/SEND_MAIL_FAIL'

const BRAND_ALERT = 'businessSummaryParentMerchant/BRAND_ALERT'
const BRAND_ALERT_SUCCESS = 'businessSummaryParentMerchant/BRAND_ALERT_SUCCESS'
const BRAND_ALERT_FAIL = 'businessSummaryParentMerchant/BRAND_ALERT_FAIL'

const BRAND_POSTS = 'businessSummaryParentMerchant/BRAND_POSTS'
const BRAND_POSTS_SUCCESS = 'businessSummaryParentMerchant/BRAND_POSTS_SUCCESS'
const BRAND_POSTS_FAIL = 'businessSummaryParentMerchant/BRAND_POSTS_FAIL'

const BRAND_RMS = 'businessSummaryParentMerchant/BRAND_RMS'
const BRAND_RMS_SUCCESS = 'businessSummaryParentMerchant/BRAND_RMS_SUCCESS'
const BRAND_RMS_FAIL = 'businessSummaryParentMerchant/BRAND_RMS_FAIL'

const ECOM_AFFINITY = 'businessSummaryParentMerchant/ECOM_AFFINITY'
const ECOM_AFFINITY_SUCCESS = 'businessSummaryParentMerchant/ECOM_AFFINITY_SUCCESS'
const ECOM_AFFINITY_FAIL = 'businessSummaryParentMerchant/ECOM_AFFINITY_FAIL'

const RETAIL_INVIOCE = 'businessSummaryParentMerchant/RETAIL_INVIOCE'
const RETAIL_INVIOCE_SUCCESS = 'businessSummaryParentMerchant/RETAIL_INVIOCE_SUCCESS'
const RETAIL_INVIOCE_FAIL = 'businessSummaryParentMerchant/RETAIL_INVIOCE_FAIL'

const RMS_CHECK_MODE = 'businessSummaryParentMerchant/RMS_CHECK_MODE'

const initialState = {
  uploaded: false,
  selectedCities:[],
  selectedLocalities: [],
  subcategoryData:[],
  allOffset:0,
  vouchersOffset:0,
  cashbackOffset:0,
  magicpayOffset:0,
  offsetPref: 1,
  rmsCheckMode:"City",
  allPosts:[],
  vouchersPosts:[],
  cashbackPosts:[],
  magicpayPosts:[],
};

function createGmvChildrenRanks(gmvChild, fundedChild){
  return gmvChild.filter(gmvData=>fundedChild[gmvData.merchant_user_id] && !fundedChild[gmvData.merchant_user_id]['is_parent_merchant']).map(gmvData=>({gmv:gmvData.computed_gmv, ...fundedChild[gmvData.merchant_user_id]}))
}

export default function reducer(state = initialState, action = {}) {
  var gmvChildrenRanks;
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      // console.log(action);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    
    case LOAD_RETAIL_FEED:
      return {
        ...state,
        loadingRetailFeed : true
      }

    case LOAD_RETAIL_FEED_SUCCESS:
      return {
        ...state,
        loadingRetailFeed: false,
        loadedRetailFeed: true,
        retailFeed: action.result,
        retailError: null
      }

    case LOAD_RETAIL_FEED_FAIL:
      return {
        ...state,
        loadingRetailFeed: false,
        loadedRetailFeed: false,
        retailFeed: null,
        retailError: action.error
      }

    case SUBMIT_CATEGORY:
      return {
        ...state,
        loadingPotential : true
      };

    case SUBMIT_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingPotential: false,
        loadedPotential: true,
        potential: action.result,
        error: action.error
      }

    case SUBMIT_CATEGORY_FAIL:
      return {
        ...state,
        loadingPotential: false,
        loadedPotential: false,
        potential: null,
        error: action.error
      }

      case SEND_MAIL:
      return {
        ...state,
        sendingMail : true
      };

    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        sendingMail: false,
        mailSent: true,
        mail: action.result,
        error: action.error
      }

    case SEND_MAIL_FAIL:
      return {
        ...state,
        sendingMail: false,
        mailSent: false,
        mail: null,
        error: action.error
      }

      case LOAD_CUSTOMER_PROFILE:
        return {
          ...state,
          loadingCustomerProfile: true
        };
      case LOAD_CUSTOMER_PROFILE_SUCCESS:
        return {
          ...state,
          loadingCustomerProfile: false,
          loadedCustomerProfile: true,
          customerProfile: action.result,
          error: null
        };
      case LOAD_CUSTOMER_PROFILE_FAIL:
        return {
          ...state,
          loadingCustomerProfile: false,
          loadedCustomerProfile: false,
          customerProfile: null,
          error: action.error
        };

    case LOAD_NEW_CUSTOMERS:
      return {
        ...state,
        newCustomersLoading: true
      };
    case LOAD_NEW_CUSTOMERS_SUCCESS:
      return {
        ...state,
        newCustomersLoading: false,
        newCustomersLoaded: true,
        newCustomerData: action.result,
        error: null
      };
    case LOAD_NEW_CUSTOMERS_FAIL:
      return {
        ...state,
        newCustomersLoading: false,
        newCustomersLoaded: false,
        newCustomerData: null,
        error: action.error
      };

    case LOAD_LOST_CUSTOMERS:
      return {
        ...state,
        lostCustomerLoading: true
      };
    case LOAD_LOST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        lostCustomerLoading: false,
        lostCustomerLoaded: true,
        lostCustomerData: action.result,
        error: null
      };
    case LOAD_LOST_CUSTOMERS_FAIL:
      return {
        ...state,
        lostCustomerLoading: false,
        lostCustomerLoaded: false,
        lostCustomerError: action.error
      };
    
    case LOAD_GROWTH_DATA:
      return{
        ...state,
        loadingGrowthData: true
      };
    
    case LOAD_GROWTH_DATA_SUCCESS:
      return {
        ...state,
        loadingGrowthData: false,
        loadedGrowthData: true,
        growthData: action.result,
        error: null
      };
    
    case LOAD_GROWTH_DATA_FAIL:
      return {
        ...state,
        loadingGrowthData: false,
        loadedGrowthData: false,
        error: null
      }
    case LOAD_REPEAT_FREQUENCY:
      return {
        ...state,
        loadingRepeatFrequency: true
      };
    case LOAD_REPEAT_FREQUENCY_SUCCESS:
      return {
        ...state,
        loadingRepeatFrequency: false,
        loadedRepeatFrequency: true,
        repeatFrequencyData: action.result,
        error: null
      };
    case LOAD_REPEAT_FREQUENCY_FAILED:
      return{
        ...state,
        loadingRepeatFrequency: false,
        loadedRepeatFrequency: false,
        repeatFrequencyError: action.error
      };
    case AOV_DATA_LOAD:
      return{
        ...state,
        loadingAovData: true
      }
    case AOV_DATA_SUCCESS:
      return{
        ...state,
        loadingAovData: false,
        loadedAovData: true,
        aovData: action.result,
        error: null
      }
    case AOV_DATA_FAIL:
      return{
        ...state,
        loadingAovData: false,
        loadedAovData: true,
        error: action.error
      }
    case LOAD_WALLET_SUMMARY:
      return {
        ...state,
        loadingWalletSummary: true
      };
    case LOAD_WALLET_SUMMARY_SUCCESS:
      return {
        ...state,
        loadingWalletSummary: false,
        loadedWalletSummary: true,
        walletSummary: action.merchantId ? { ...state.walletSummary,
          ...action.result
        } : action.result,
        error: null
      };
    case LOAD_WALLET_SUMMARY_FAIL:
      return {
        ...state,
        loadingWalletSummary: false,
        loadedWalletSummary: false,
        walletSummary: null,
        error: action.error
      };
    case LOAD_BUSINESS_WITHDATE:
      return {
        ...state,
        businesWithDates: null,
        businesWithDatesLoading: true,
      }
    case LOAD_BUSINESS_WITHDATE_SUCCESS: 
      return {
        ...state,
        businessWithDates: action.result,
        businessWithDatesLoading: false,
        businessWithDatesLoaded: true,
      }
    case LOAD_BUSINESS_WITHDATE_FAIL:
      return {
        ...state,
        businesWithDatesLoading: false,
        businesWithDatesLoaded: false,
      }
    case SELECT_CHILD:
      return {
        ...state,
        businessWithDates: null,  // clearing the data when new child is selected
      }
      case LOAD_GMV:
		  return {
		  ...state,
		  loadingGmv: true,
		  }
	case LOAD_GMV_SUCCESS:
		  return {
		  ...state,
		  loadingGmv: false,
      gmvData: action.callingComponent === 'home' ?  action.result : state.gmvData,
      localGmvData: action.callingComponent === 'gmv' ||  action.callingComponent === 'home' ? action.result : state.localGmvData,
      localAovData: action.callingComponent === 'aov' || action.callingComponent === 'home' ? action.result : state.localAovData,
	}
	case LOAD_GMV_FAIL:
		return {
		...state,
		loadingGmv: false,
		gmvError: action.error
  }
  
  case LOAD_IMPRESSIONS:
    return {
      ...state,
      loadingImpression: true
    }
  case LOAD_IMPRESSIONS_SUCCESS:
    return {
      ...state,
      loadingImpression: false,
      impressions: action.result
    }
  case LOAD_IMPRESSIONS_FAIL:
    return {
      ...state,
      loadingImpression: false,
      impressions: null,
    }
  case LOAD_RETAIL_CAMPAIGN_SUMMARY:
    return {
      ...state,
      loadingGoalHistory: true
    }
  case LOAD_RETAIL_CAMPAIGN_SUMMARY_SUCCESS:
    return {
      ...state,
      loadingGoalHistory: false,
      goalHistory: action.result,
      startDate: action.startDate || moment(action.result.startDate).format('YYYY-MM-DD'),
      endDate: action.endDate || moment(action.result.endDate).format('YYYY-MM-DD'),
      goalHistoryDtoList: action.result.goalHistoryDtoList || state.goalHistoryDtoList,
    }
  case LOAD_RETAIL_CAMPAIGN_SUMMARY_FAIL:
    return {
      ...state,
      loadingGoalHistory: false,
      goalHistory: null,
      goalHistoryError: action.error
    }

  case LOAD_REPEAT_RATE:
    return {
      ...state,
      loadingRepeatRate: true,
    }
  case LOAD_REPEAT_RATE_SUCCESS:
    return {
      ...state,
      loadingRepeatRate: false,
      repeatRate: action.result
    }
  case LOAD_REPEAT_RATE_FAIL:
    return {
      ...state,
      loadingRepeatRate: false,
      repeatRate: null,
      repeatRateError: action.error
    }
  
  case LOAD_TXN_PATTERN:
    return {
      ...state,
      loadingTransactionPattern: true,
    }
  case LOAD_TXN_PATTERN_SUCCESS:
    let timeFormat = action.aggType === 'time_of_day' ? 'HH' : 'dddd'
    let newTimeFormat = action.aggType === 'time_of_day' ? 'h a' : 'dddd'
    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day=>day.toUpperCase())
    let busiestDay = (action.aggType === 'day_of_week') ? action.result[0].key : state.busiestDay
    let busiestTime = action.aggType === 'time_of_day' ? moment(action.result[0].key, 'HH').format('h a')+'-'+moment(action.result[0].key, 'HH').add(1, 'hours').format('h a') : state.busiestTime
    let pattern = action.result.sort((a,b)=>{
      if(action.aggType === 'time_of_day')
        return a.key - b. key
      else return days.indexOf(a.key) - days.indexOf(b.key)
    })
    .map(time=>({key: moment(time.key, timeFormat).format(newTimeFormat), doc_count: time.doc_count}))
    return {
      ...state,
      busiestDay,
      busiestTime,
      loadingTransactionPattern: false,
      transactionPattern: pattern,
    }
  case LOAD_TXN_PATTERN_FAIL:
    return {
      ...state,
      loadingTransactionPattern: false,
      transactionPattern: null,
      transactionPattern: action.error
    }

  case LOAD_CITY:
    return {
      ...state,
      loadingCity: true,
    }
  case LOAD_CITY_SUCCESS:
    return {
      ...state,
      loadingCity: false,
      city: action.result
    }
  case LOAD_CITY_FAIL:
    return {
      ...state,
      loadingCity: false,
      city: null,
      cityError: action.error
    }
  
  case LOAD_GMV_CHILD:
    return {
      ...state,
      loadingGmvChild: true,
    }
  case LOAD_GMV_CHILD_SUCCESS:
    if(state.fundedChild)
      gmvChildrenRanks = createGmvChildrenRanks(action.result, state.fundedChild)
    return {
      ...state,
      loadingGmvChild: false,
      gmvChild: action.result,
      ...(state.fundedChild && {gmvChildrenRanks, selectedLocalitiesToCompare: gmvChildrenRanks.slice(0,3).map(child=>({locality: child.locality, city: child.city}))})
    }
  case LOAD_GMV_CHILD_FAIL:
    return {
      ...state,
      loadingGmvChild: false,
      gmvChild: null,
      gmvChildError: action.error
    }
  case LOAD_CHILD_MERCHANTS:
    return {
      ...state,
      loadingChildMerchants: true
    }
  case LOAD_CHILD_MERCHANTS_SUCCESS:
    return {
      ...state,
      childMerchantsList: action.result,
      loadingChildMerchants: false
    }
  case LOAD_CHILD_MERCHANTS_FAIL:
    return {
      ...state,
      loadingChildMerchants: false,
      loadingChildMerchantsError: action.error
    }
  case LOAD_FUNDED_CHILD:
    return {
      ...state,
      loadingFundedChild: true,
    }
  case LOAD_FUNDED_CHILD_SUCCESS:
    let fundedChild = action.result.reduce((acc, curr)=>{acc[curr['user_id']]=curr; return acc},{})
    let fundedChildPerCity = action.result.filter(localityData=>!!localityData.city).reduce((acc, curr)=>{
      if(!!acc[curr['city']]){
        acc[curr['city']].push(curr)
      }
      else acc[curr['city']]=[curr]
      return acc
    },{})
    if(state.gmvChild)
      gmvChildrenRanks = createGmvChildrenRanks(state.gmvChild, fundedChild)
    return {
      ...state,
      loadingFundedChild: false,
      fundedChild: fundedChild,
      fundedChildPerCity: fundedChildPerCity,
      ...(state.gmvChild && {gmvChildrenRanks, selectedLocalitiesToCompare: gmvChildrenRanks.slice(0,3).map(child=>({locality: child.locality, city: child.city}))})
      
    }
  case LOAD_FUNDED_CHILD_FAIL:
    return {
      ...state,
      loadingFundedChild: false,
      fundedChild: null,
      fundedChildError: action.error
    }
  
  case LOAD_NEW_REPEAT:
    return {
      ...state,
      loadingNewRepeat: true,
    }
  case LOAD_NEW_REPEAT_SUCCESS:
    return {
      ...state,
      loadingNewRepeat: false,
      newRepeat: action.result
    }
  case LOAD_NEW_REPEAT_FAIL:
    return {
      ...state,
      loadingNewRepeat: false,
      newRepeat: null,
      newRepeatError: action.error
    }

  case LOAD_GROUPBUY_STAT:
    return {
      ...state,
      groupbuyStatLoading : true
    }
  case LOAD_GROUPBUY_STAT_SUCCESS:
    return {
      ...state,
      groupbuyStatLoading : false,
      groupbuyStat: action.result
    }
  case LOAD_GROUPBUY_STAT_FAIL:
    return {
      ...state,
      groupbuyStatLoading : false,
      groupbuyStat: null,
      groupbuyStatError: action.error
    }
  case CHANGE_SELECTED_CITIES:
    return {
      ...state,
      selectedCities: action.cities
    }
  case CHANGE_SELECTED_LOCALITIES:
    return {
      ...state,
      selectedLocalities: action.localities,
    }
  case LOAD_OFFERS:
    return {
      ...state,
      offersLoading: true
    }
  case OFFERS_LOADED:
    return {
      ...state,
      offersLoading: false,
      runningOffers: action.result,
      runningOffersError: null
    }
  case OFFERS_FAILED:
    return {
      ...state,
      offersLoading: false,
      runningOffers: null,
      runningOffersError: action.error,
    }
  case LOAD_POSTS:
    return {
      ...state,
      postsLoading: true,
    }
  case POSTS_LOADED:
    return {
      ...state,
      postsLoading: false,
      [action.filterType+'Posts']: action.offset !== 0 ? [...state[action.filterType+'Posts'],...action.result] : action.result,
      [action.filterType+'Offset']: action.offset+action.result.length,
      [action.filterType+'More']:action.result.length >= 10,
      postsError: null
    }
  case POSTS_FAILED:
    return {
      ...state,
      postsLoading: false,
      posts:null,
      postsError:action.error
    }
    case UPLOAD_CSV:
      return {
        ...state,
        uploading: true
      }
    case CSV_UPLOADED:
      return {
        ...state,
        uploading: false,
        uploadResult: action.result,
        uploadError: null,
      }
    case CSV_FAILED:
      return {
        ...state,
        uploading: false,
        uploadResult: null,
        uploadError: action.error
      }
    case CHECK_CSV:
      return {
        ...state,
        checking: true,
      }
    case CSV_CHECKED:
      return {
        ...state,
        checking: false,
        csvStatus: action.result,
        csvStatusError: null
      }
    case CHECK_FAILED:
      return {
        ...state,
        checking: false,
        csvStatus: null,
        csvStatusError: action.error
      }
    case LOAD_STORE_COMPARE:
      return {
        ...state,
        storeCompareLoading: true,
      }
    case STORE_COMPARE_LOADED:
      return {
        ...state,
        storeCompareLoading: false,
        storeCompareData: action.result,
        storeCompareError: null,
      }
    case STORE_COMPARE_FAILED:
      return {
        ...state,
        storeCompareLoading: false,
        storeCompareData: null,
        storeCompareError: action.error
      }
    case INITIALIZE_DATE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate
      }
    case LOCALITY_COMPARE:
      return {
        ...state,
        selectedLocalitiesToCompare: action.localityData
      }
    case LOAD_CUSTOMER_DISTRIBUTION:
      return {
        ...state,
        customerPatternLoading: true
      }
    case CUSTOMER_DISTRIBUTION_LOADED:
      return {
        ...state,
        customerPatternLoading: false,
        customerPattern: action.result,
        customerPatternError: null
      }
    case CUSTOMER_DISTRIBUTION_FAILED:
      return {
        ...state,
        customerPatternLoading: false,
        customerPattern: null,
        customerPatternError: action.error
      }
    case LOAD_RETAIL_PREFERENCE:
      return {
        ...state,
        loadingRetailPreference: true
      }
    case LOAD_RETAIL_PREFERENCE_SUCCESS:
      return {
        ...state,
        loadingRetailPreference: false,
        loadedRetailPreference: true,
        visibleShowMore: (action.result.finalResponse || []).length > 2,
        subcategoryData: action.offsetPref !== 1 && !action.clearList ? [...state.subcategoryData, ...(action.result && action.result.finalResponse ? action.result.finalResponse : [])] : (action.result && action.result.finalResponse ? action.result.finalResponse : []),
        totalNumberOfPages: action.result && action.result.totalNumberOfPages ? action.result.totalNumberOfPages : 0,
        error: null
      }
    case LOAD_RETAIL_PREFERENCE_FAIL:
      return{
        ...state,
        loadingRetailPreference: false,
        loadedRetailPreference: false,
        subcategoryData: null,
        error: action.error
      }
    case LOAN_AMOUNT_LOAD:
      return{
        ...state,
        loadingLoanAmount: true,
      }
    case LOAN_AMOUNT_SUCCESS:
      return{
        ...state,
        creditAmount: action.result.creditAmount,
        loadingLoanAmount: false,
      }
    case LOAN_AMOUNT_FAIL:
      return{
        ...state,
        creditAmount:null,
        loadingLoanAmount:false,
      }

    case WALLET_BALANCE_PARENT_LOAD:
      return{
        ...state,
        loadingParentWalletBalance: true,
      }
    case WALLET_BALANCE_PARENT_SUCCESS:
      return{
        ...state,
        parentWalletBalance: action.result.walletBalance,
        loadingParentWalletBalance: false,
      }
    case WALLET_BALANCE_PARENT_FAIL:
      return{
        ...state,
        parentWalletBalance:null,
        loadingParentWalletBalance:false,
      }

    case LOAD_RETAIL_CATEGORY_IMPRESSION:
      return{
        ...state,
        loadingCategoryImpression: true,
      }
    case LOAD_RETAIL_CATEGORY_IMPRESSION_SUCCESS:
      return{
        ...state,
        categoryImpression: action.result.viewsAndCtr,
        loadingCategoryImpression: false,
      }
    case LOAD_RETAIL_CATEGORY_IMPRESSION_FAIL:
      return{
        ...state,
        categoryImpression: null,
        loadingCategoryImpression:false,
      }

    case LOAD_RETAIL_CATEGORY_IMPRESSION_CPC:
      return{
        ...state,
        loadingCategoryImpressionCpc: true,
      }
    case LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_SUCCESS:
      return{
        ...state,
        categoryImpressionCPC: action.result.retailCpc,
        loadingCategoryImpressionCpc: false,
      }
    case LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_FAIL:
      return{
        ...state,
        categoryImpressionCPC: null,
        loadingCategoryImpressionCpc:false,
      }
    
    case BRAND_ALERT:
      return{
        ...state,
        voucherTypesLoading: true,
      }
    case BRAND_ALERT_SUCCESS:
      return{
        ...state,
        voucherTypes: action.result,
        voucherTypesLoading: false,
      }
    case BRAND_ALERT_FAIL:
      return{
        ...state,
        voucherTypes: null,
        voucherTypesLoading:false,
      }

    case BRAND_POSTS:
      return{
        ...state,
        brandPostLoading: true,
      }
    case BRAND_POSTS_SUCCESS:
      return{
        ...state,
        brandPost: action.result,
        brandPostLoading: false,
      }
    case BRAND_POSTS_FAIL:
      return{
        ...state,
        brandPost: null,
        brandPostLoading:false,
      }
    case BRAND_RMS:
      return{
        ...state,
        brandRmsLoading: true,
      }
    case BRAND_RMS_SUCCESS:
      return{
        ...state,
        brandRms: action.result,
        brandRmsLoading: false,
      }
    case BRAND_RMS_FAIL:
      return{
        ...state,
        brandRms: null,
        brandRmsLoading:false,
      }
    
    case ECOM_AFFINITY:
      return{
        ...state,
        ecomAffinityLoading: true,
      }
    case ECOM_AFFINITY_SUCCESS:
      return{
        ...state,
        ecomAffinity: action.result,
        ecomAffinityLoading: false,
      }
    case ECOM_AFFINITY_FAIL:
      return{
        ...state,
        ecomAffinity: null,
        ecomAffinityLoading:false,
      }
    case RETAIL_INVIOCE:
      return{
        ...state,
        retailInvoiceLoading: true,
      }
    case RETAIL_INVIOCE_SUCCESS:
      return{
        ...state,
        retailInvoice: action.result && action.result.txnInvoice ? action.result.txnInvoice : [],
        retailInvoiceLoading: false,
      }
    case RETAIL_INVIOCE_FAIL:
      return{
        ...state,
        retailInvoice: null,
        retailInvoiceLoading:false,
      }
    case RMS_CHECK_MODE:
      return {
        ...state,
        rmsCheckMode: action.rmsCheckMode
      }
    default:
      return state;
  }
}

export function getRetailGrowth(accessToken,startDate,endDate, cities, flag){
  return {
    types: [LOAD_GROWTH_DATA, LOAD_GROWTH_DATA_SUCCESS, LOAD_GROWTH_DATA_FAIL],
    promise: (client) => client.default.post(`/getRetailGrowth?startDate=${startDate}&endDate=${endDate}`,{
      data: {accessToken, cities, flag}
    })
  };
}

export function loadBusinessSummaryParentMerchant(accessToken, merchantId = 0) {
  if(merchantId == 0){
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/loadBusinessSummaryParentMerchant', {
        data: {accessToken}
      })
    };
  }
  else{
    return {
      types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
      promise: (client) => client.default.post('/loadBusinessSummaryWithMerchantId', {
        data: {accessToken, merchantId}
      })
    };
  }
}

export function getCustomerProfile(accessToken, cities){
  return {
    types: [LOAD_CUSTOMER_PROFILE, LOAD_CUSTOMER_PROFILE_SUCCESS, LOAD_CUSTOMER_PROFILE_FAIL],
    promise: (client) => client.default.post('/getCustomersProfile', {
      data: {accessToken, cities}
    })
  }
}

export function getAovComparison(accessToken, cities, startDate, endDate){
  return {
    types: [AOV_DATA_LOAD, AOV_DATA_SUCCESS, AOV_DATA_FAIL],
    promise: (client) => client.default.post('/getAovComparison', {
      data: {accessToken, cities, startDate, endDate}
    })
  }
}

export function walletSummaryParent(accessToken, parentMerchantId, month, merchantId=null){
  return {
    types: [LOAD_WALLET_SUMMARY, LOAD_WALLET_SUMMARY_SUCCESS, LOAD_WALLET_SUMMARY_FAIL],
    promise: (client) => client.default.post('/loadWalletSummaryParentMerchant', {
      data: {accessToken, parentMerchantId, month, merchantId}
    })
  ,merchantId};
}

export function getNewCustomers(accessToken, cities){
  return {
    types: [LOAD_NEW_CUSTOMERS, LOAD_NEW_CUSTOMERS_SUCCESS, LOAD_NEW_CUSTOMERS_FAIL],
    promise: (client) => client.default.post('/getNewCustomersComingFrom',{
      data: {accessToken, cities}
    })
  };
}

export function getLostCustomers(accessToken, cities){
  return {
    types: [LOAD_LOST_CUSTOMERS, LOAD_LOST_CUSTOMERS_SUCCESS, LOAD_LOST_CUSTOMERS_FAIL],
    promise: (client) => client.default.post('/getCustomerGoingTo',{
      data: {accessToken, cities}
    })
  };
}

export function getRepeatFrequencyData(accessToken, cities){
  return {
    types: [LOAD_REPEAT_FREQUENCY, LOAD_REPEAT_FREQUENCY_SUCCESS, LOAD_REPEAT_FREQUENCY_FAILED],
    promise: (client) => client.default.post('/getRepeatFrequency',{
      data: {accessToken, cities}
    })
  };
}
export function getParentMerchantWalletBalance(accessToken){
  return {
    types: [WALLET_BALANCE_PARENT_LOAD, WALLET_BALANCE_PARENT_SUCCESS, WALLET_BALANCE_PARENT_FAIL],
    promise: (client) => client.default.post('/getParentMerchantWalletBalance', {
      data: {accessToken}
    })
  };
}
export function loadBusinessWithDates(accessToken, startDate, endDate){
  return {
    types: [LOAD_BUSINESS_WITHDATE, LOAD_BUSINESS_WITHDATE_SUCCESS, LOAD_BUSINESS_WITHDATE_FAIL],
    promise: (client) => client.default.post(`/loadBusinessWithDatesParentMerchant/${startDate}/${endDate}`, {
      data: {accessToken, startDate, endDate}
    })
  }
}

export function loadBusinessWithDatesMerchantId(accessToken, startDate, endDate, merchantId){
  return {
    types: [LOAD_BUSINESS_WITHDATE, LOAD_BUSINESS_WITHDATE_SUCCESS, LOAD_BUSINESS_WITHDATE_FAIL],
    promise: (client) => client.default.post(`/loadBusinessWithDatesMerchantId/${startDate}/${endDate}`, {
      data: {accessToken, startDate, endDate, merchantId}
    })
  }
}

export function getRetailGmv(accessToken, cities, localities, startDate, endDate, callingComponent, flag){
	return {
		types:[LOAD_GMV, LOAD_GMV_SUCCESS, LOAD_GMV_FAIL],
		promise:client=>client.default.post(`/getRetailGMV?start_date=${startDate}&end_date=${endDate}`,{
			data:{accessToken, cities, localities, flag}
    }),
    callingComponent
	}
}

export function getRetailImpressions(accessToken, cities, localities, startDate, endDate){
  return {
		types:[LOAD_IMPRESSIONS, LOAD_IMPRESSIONS_SUCCESS, LOAD_IMPRESSIONS_FAIL],
		promise:client=>client.default.post(`/getRetailImpressions?start_date=${startDate}&end_date=${endDate}`,{
			data:{accessToken, cities, localities}
		})
  }
}

export function getRetailCampaignSummary(accessToken,isRetailChain, startDate,endDate){
  let url='/getRetailCampaignSummary'
  if(startDate && endDate) url+=`?start_date=${startDate}&end_date=${endDate}`
  return {
    types:[LOAD_RETAIL_CAMPAIGN_SUMMARY, LOAD_RETAIL_CAMPAIGN_SUMMARY_SUCCESS, LOAD_RETAIL_CAMPAIGN_SUMMARY_FAIL],
    promise: client=>client.default.post(url,{
      data:{accessToken, isRetailChain}
    }),
    startDate,
    endDate,
  }
}

export function getRetailRepeatPattern(accessToken, cities){
  return {
    types:[LOAD_REPEAT_RATE, LOAD_REPEAT_RATE_SUCCESS, LOAD_REPEAT_RATE_FAIL],
    promise: client=>client.default.post(`/getRepeatRate`,{
      data:{accessToken, cities}
    })
  }
}

export function getRetailCustomerPreference(accessToken, locality, offset, clearList){
  return {
    offsetPref : offset,
    clearList,
    types:[LOAD_RETAIL_PREFERENCE, LOAD_RETAIL_PREFERENCE_SUCCESS, LOAD_RETAIL_PREFERENCE_FAIL],
    promise: client=>client.default.post(`/getRetailCustomerPreferenceV3`,{
      data:{accessToken,locality, offset}
    })
  }
}

export function getRetailTxnPattern(accessToken, startDate, endDate, aggType, cities, flag){
  return {
    types:[LOAD_TXN_PATTERN, LOAD_TXN_PATTERN_SUCCESS, LOAD_TXN_PATTERN_FAIL],
    promise: client=>client.default.post(`/getRetailTxnPattern?start_date=${startDate}&end_date=${endDate}&agg_type=${aggType}`,{
      data:{accessToken, cities, flag}
    }),
    aggType
  }
}

export function getRetailCityDistribution(accessToken, startDate, endDate){
  return {
    types:[LOAD_CITY, LOAD_CITY_SUCCESS, LOAD_CITY_FAIL],
    promise: client=>client.default.post(`/getRetailCityDistribution?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken}
    })
  }
}

export function getRetailGmvChilds(accessToken, startDate, endDate){
  return {
    types:[LOAD_GMV_CHILD, LOAD_GMV_CHILD_SUCCESS, LOAD_GMV_CHILD_FAIL],
    promise: client=>client.default.post(`/getRetailGMVChilds?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken}
    })
  }
}

export function getRetailFundedChilds(accessToken, startDate, endDate, localities, cities){
  return {
    types:[LOAD_FUNDED_CHILD, LOAD_FUNDED_CHILD_SUCCESS, LOAD_FUNDED_CHILD_FAIL],
    promise: client=>client.default.post(`/getRetailFundedChilds?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken, localities, cities}
    })
  }
}

export function getRetailNewRepeat(accessToken, startDate, endDate){
  return {
    types:[LOAD_NEW_REPEAT, LOAD_NEW_REPEAT_SUCCESS, LOAD_NEW_REPEAT_FAIL],
    promise: client=>client.default.post(`/getRetailNewRepeat?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken}
    })
  }
}

export function getGroupbuyStat(accessToken){
  return {
    types:[LOAD_GROUPBUY_STAT, LOAD_GROUPBUY_STAT_SUCCESS, LOAD_GROUPBUY_STAT_FAIL],
    promise: client=>client.default.post(`/groupbuyVouchersByLocality`,{
      data:{accessToken}
    })
  }
}

export function selectCity(cities){
  return {
    type:CHANGE_SELECTED_CITIES,
    cities
  }
}

export function selectLocality(localities){
  return {
    type:CHANGE_SELECTED_LOCALITIES,
    localities
  }
}

export function getRetailRunningOffers(accessToken, startDate, endDate){
  return {
    types:[LOAD_OFFERS, OFFERS_LOADED, OFFERS_FAILED],
    promise: client=>client.default.post(`/getRetailRunningOffers?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken}
    })
  }
}

export function getChildMerchants(accessToken, parentMerchantId) {
  return {
    types:[LOAD_CHILD_MERCHANTS, LOAD_CHILD_MERCHANTS_SUCCESS, LOAD_CHILD_MERCHANTS_FAIL],
    promise: client=>client.default.post(`/getChildMerchants`,{
      data:{accessToken, parentMerchantId}
    })
  }
}

export function getRetailTransactions(accessToken, offset, type, cities, localities, startDate, endDate){
  let url=`/getRetailTransactions?offset=${offset}&type=${type}`
  if(startDate && endDate){
    url+=`&start_date=${startDate}&end_date=${endDate}`
  }
  return {
    types:[LOAD_POSTS, POSTS_LOADED, POSTS_FAILED],
    promise:client=>client.default.post(url,{
      data:{accessToken, cities, localities}
    }),
    offset,
    filterType: type,
  }
}

export function uploadVoucherCsv(accessToken, file, fileName){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : fileName);
  data.append('accessToken', accessToken);
  return {
    types:[UPLOAD_CSV, CSV_UPLOADED, CSV_FAILED],
    promise:client=>client.default.post('/uploadVoucherCsv',{data})
  }
}

export function checkCsvStatus(accessToken, batchId){
  return {
    types:[CHECK_CSV, CSV_CHECKED, CHECK_FAILED],
    promise:client=>client.default.post('/checkCsvStatus',{data:{accessToken, batchId}})
  }
}

export function getStoreCompareData({accessToken, area, startDate, endDate, flag}){
  return {
    types:[LOAD_STORE_COMPARE, STORE_COMPARE_LOADED, STORE_COMPARE_FAILED],
    promise: client=>client.default.post(`/getStoreCompareData?start_date=${startDate}&end_date=${endDate}`,{data:{accessToken, area, flag}})
  }
}

export function initializeDates({startDate, endDate}){
  return {
    type:INITIALIZE_DATE,
    startDate,
    endDate
  }
}

export function updateStoreCompareSelections({localityData}){
  return {
    type:LOCALITY_COMPARE,
    localityData
  }
}

export function getRetailCustomerDistribution({accessToken, startDate, endDate, cities, localities}){
  return {
    types:[LOAD_CUSTOMER_DISTRIBUTION, CUSTOMER_DISTRIBUTION_LOADED, CUSTOMER_DISTRIBUTION_FAILED],
    promise:client=>client.default.post(`/getRetailCustomerDistribution?start_date=${startDate}&end_date=${endDate}`,{
      data:{accessToken, cities, localities}
    })
  }
}

export function getLoanAmount(accessToken){
  return {
    types:[LOAN_AMOUNT_LOAD, LOAN_AMOUNT_SUCCESS, LOAN_AMOUNT_FAIL],
    promise:client=>client.default.post('getLoanAmount',{
      data:{accessToken}
    })
  }
}

export function submitCategory(category,age,gender,city,AOV){
  return {
      types : [SUBMIT_CATEGORY, SUBMIT_CATEGORY_SUCCESS, SUBMIT_CATEGORY_FAIL],
      promise: (client) => client.default.post('/getRetailPotentialAudience', {
      data: {category,age,gender,city,AOV}
    })
  }
}

export function getRetailFeed(accessToken, startDate, endDate){
  return {
    types : [LOAD_RETAIL_FEED, LOAD_RETAIL_FEED_SUCCESS, LOAD_RETAIL_FEED_FAIL],
    promise: (client) => client.default.post('/getRetailFeed', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}

export function getRetailCategoryImpression(accessToken, startDate, endDate){
  return {
    types : [LOAD_RETAIL_CATEGORY_IMPRESSION, LOAD_RETAIL_CATEGORY_IMPRESSION_SUCCESS, LOAD_RETAIL_CATEGORY_IMPRESSION_FAIL],
    promise: (client) => client.default.post('/getRetailCategoryImpression', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}

export function getRetailCategoryImpressionCPC(accessToken, startDate, endDate){
  return {
    types : [LOAD_RETAIL_CATEGORY_IMPRESSION_CPC, LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_SUCCESS, LOAD_RETAIL_CATEGORY_IMPRESSION_CPC_FAIL],
    promise: (client) => client.default.post('/getRetailImpressionCPC', {
      data: {accessToken, startDate, endDate}
    })
  
  }
}

export function sendMail(emailId, name, value, count, brandName){
  return {
      types : [SEND_MAIL, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL],
      promise: (client) => client.default.post('/sendRetailMail', {
        data: {emailId, name, value, count, brandName}
      })
  }
}

export function sendWarningMail(emailId, name, brandName){
  return {
      types : [SEND_MAIL, SEND_MAIL_SUCCESS, SEND_MAIL_FAIL],
      promise: (client) => client.default.post('/sendRetailWarningMail', {
        data: {emailId, name, brandName}
      })
  }
}


export function getBrandAlert(accessToken){
  return {
      types : [BRAND_ALERT, BRAND_ALERT_SUCCESS, BRAND_ALERT_FAIL],
      promise: (client) => client.default.post('/getVouchersTypeAlert', {
        data: {accessToken}
      })
  }
}



export function getBrandPosts(accessToken, isParent){
  return {
      types : [BRAND_POSTS, BRAND_POSTS_SUCCESS, BRAND_POSTS_FAIL],
      promise: (client) => client.default.post('/getRetailOrOnlinePosts', {
        data: {accessToken, isParent}
      })
  }
}


export function getBrandRms(accessToken, isLocality, startDate, endDate){
  return {
      types : [BRAND_RMS, BRAND_RMS_SUCCESS, BRAND_RMS_FAIL],
      promise: (client) => client.default.post('/getRetailRMSCities', {
        data: {accessToken, isLocality: !!isLocality, startDate, endDate}
      })
  }
}

export function getRmsCheckMode(rmsCheckMode){
  return {
      type : RMS_CHECK_MODE,
      rmsCheckMode
  }
}


export function getEcomAffinity(accessToken){
  return {
      types : [ECOM_AFFINITY, ECOM_AFFINITY_SUCCESS, ECOM_AFFINITY_FAIL],
      promise: (client) => client.default.post('/getCustomerPreferenceOnEcomBrands', {
        data: {accessToken}
      })
  }
}


export function getRetailInvoice(accessToken, start_date, end_date){
  return {
      types : [RETAIL_INVIOCE, RETAIL_INVIOCE_SUCCESS, RETAIL_INVIOCE_FAIL],
      promise: (client) => client.default.post('/getRetailInvoiceNumber', {
        data: {accessToken, start_date, end_date}
      })
  }
}

