import React, { Component } from "react";
import { connect } from 'react-redux';
import lunr from "lunr";
import moment from "moment";
import TextareaAutosize from 'react-autosize-textarea';
import { bindActionCreators } from 'redux';
import Loader from '../Loader/Loader';
import backArrow from '../../images/back.svg';
import LeftArrow from '../../images/left-black-arrow.svg';
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {sendEvent, constructEventNameForBq, sendBqEventForPageLanding } from '../../helpers/Helpers';
import {get} from '../../helpers/LocalStorageHelper';
import closeIcon from '../../images/close-crm.svg';
import minimizeIcon from '../../images/minus-circle.svg';
import { toast } from 'react-toastify';
import blueTick from '../../images/blue-tick.svg';
import profileIcon from '../../images/profile-icon.svg';
import infoIcon from '../../images/infoiconi.svg';
import chatIcon from '../../images/chat-icon-img.svg';
import attachIcon from '../../images/attach_icon.svg';
import reply from '../../images/reply.svg';
import magicpinLogo from '../../images/magicpin-logo.svg';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './HelpDesk.css';
import '../HomeDelivery/MerchantCrm.css';
import HelpDeskFeedBack from './HelpDeskFeedBack';
import HelpDeskListPage from './HelpDeskListPage';
import {getHelpDeskTree, getHelpDeskTicketHistory, updateHelpDeskTicketHistory} from '../../redux/modules/helpDesk';
import { getPaginatedOrders,getMerchantOrders} from '../../redux/modules/newMerchantLanding';

import { getMembers } from "../../redux/modules/myteam.js";

const gbq_identity = "helpdesk";

let pathMap = {};
let nodeMap = {};
let store = [];

function dfsFullTreeTraversal(root,path=[]){
    if(!root)
        return;

    path.push(root);
    pathMap[root.id] = path;
    store.push(root);
    nodeMap[root.id] = root;

    if(!root.next)
        return;
    
    for(var j = 0; j < root.next.length; j++)
        dfsFullTreeTraversal(root.next[j],[...path])
}


export default connect(store => ({ 
    treeData: store.helpDeskMP.data,
    loading: store.helpDeskMP.loading,
    members: store.myteam.members,
    listOrders: store.newMerchantLanding.paginatedOrdersData,
    merchantOrderAllData: store.newMerchantLanding.merchantOrderAllData,
    allTicketsData: store.helpDeskMP.ticket_data,
    ticket_loading: store.helpDeskMP.ticket_loading,
    message_sending: store.helpDeskMP.message_sending,
    messageSent: store.helpDeskMP.message_sent,
  }), dispatch => bindActionCreators({
    getHelpDeskTree,
    getMembers,
    getPaginatedOrders,
    getMerchantOrders,
    getHelpDeskTicketHistory,
    updateHelpDeskTicketHistory,
  }, dispatch))
(class HelpDesk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentNode: null,
            parentQueue: [],
            isForward: false,
            showFeedBack: false,
            results: [],
            query: "",
            email: "",
            pastIssues: false,
            ticketId: "",
            replyMessage: "",
            openTicketsCount: 0,
        };
        this.results = [];
        this.index = {};
        this.root = [];
    }
    
    componentDidMount() {
        if(this.props.user){
            sendBqEventForPageLanding("helpdesk_page_landing", this.props.user);
            const useCase = 'fetching_full_tree';
            const phone = this.props.user.phone ? this.props.user.phone.replace(/\D/g,''):"";
            this.props.getMembers(this.props.user.accessToken).then(res => {
                if(this.props.members){
                    let contact = this.props.members.filter(member => member.mobileNumber && (member.mobileNumber.replace(/\D/g,'') === phone))
                    if(contact.length === 0){
                        this.props.members.forEach(member => {
                            if(member.designation.toLowerCase() === "owner")
                                contact = [member]
                        })
                    }
                    if(contact.length > 0)
                        this.setState({
                            email: contact[0].email
                        })
                }
            });
            this.sendHelpDeskEvent('api_requested',useCase)
            this.props.getHelpDeskTicketHistory(this.props.user.merchantId,this.props.user.accessToken).then(res => {
                if(this.props.allTicketsData){
                    this.setState({
                        openTicketsCount: this.props.allTicketsData?.all_tickets?.filter(ticket => !['CLOSED','RESOLVED'].includes(ticket?.status.toUpperCase()))?.length || 0
                    })
                }
            });
            this.props.getHelpDeskTree(this.props.user.salt,this.props.user.merchantId,this.props.user.userId).then(res => {
                if(this.props.treeData){
                    this.sendHelpDeskEvent('api_succeeded',useCase)
                    this.setState({
                        currentNode: this.props.treeData
                    })
                    this.root = this.props.treeData;
                    dfsFullTreeTraversal(this.props.treeData,[]);
                    this.index = lunr(function() {
                        this.ref('id');
                        this.field('title');
                        this.field('searchText');
                        this.field('message');
                        store.forEach(function(node){
                            let newNode = {
                                ...node,
                                title: node.title ? node.title.toLowerCase(): "",
                                searchText: node.searchText ? node.searchText.toLowerCase() :"",
                                message: node.message ? node.message.toLowerCase() : "",
                            }
                            this.add(node);
                        },this);
                    });
                }
            })
            .catch(err => {
                this.sendHelpDeskEvent('api_failed',useCase)
                this.setState({
                    error: true
                })
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    getContextualTree = (orderId) => {
        const fields = this.state.parentQueue.map(parentNode => parentNode.id)
        this.props.getHelpDeskTree(this.props.user.salt,this.props.user.merchantId,this.props.user.userId, false, [...fields,orderId])
        .then(res =>{
            if(res && res.length > 0){
                const newNode = {
                    ...this.state.currentNode,
                    next: res[0].next
                }
                this.setState({
                    currentNode: newNode,
                    orderId: orderId
                })

            }
        })
        .catch(error => {
            // this.sendHelpDeskEvent('api_failed',useCase,{"entity_type": node.title, "entity_name": node.id})
            this.setState({error: true})
        })
    }

    sendHelpDeskEvent = (eventName,useCase="",extraParams={}) => {
        let finalEventName = constructEventNameForBq({}, `${gbq_identity}${useCase ? `_${useCase}`:""}_${eventName}`)
        let event = {
            event: finalEventName,
            page_type: 'helpdesk',
            page_name: 'helpdesk',
            actor_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
            actor_id: this.props.user.merchantId,
            subject_merchant_id: this.props.user ? this.props.user.merchantId: "" ,
            device: window.innerWidth > 768 ? 'desktop' :'mobile',
            ...extraParams
        }
        sendEvent(event);
    }

    changeCurrentNode = (node) => {
        this.sendHelpDeskEvent('clicked','next_arrow',{"entity_type": node.title, "entity_name": node.id})
        let newParentList = [...pathMap[node.id]];

        this.setState({
            isForward: true,
            results: [],
            query: "",
            parentQueue: newParentList
        })
        if(node.next === null){
            const fields = newParentList.map(parentNode => parentNode.id)
            const useCase = 'terminal_node'
            this.setState({
                currentNode: null,
            })
            if(node.type === "page"){
                this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
                .then(res =>{
                    if(this.props.merchantOrderAllData && this.props.merchantOrderAllData['ordersDetail']){
                        let child = this.props.merchantOrderAllData['ordersDetail'].map(order => {
                            return {
                                ...order,
                                id: order.orderId,
                                next: null,
                                ctaLink: "",
                                extra: "",
                                message: "",
                                searchText: "",
                                title: `${order.userName} <--> ${order.orderId}` ,
                                type: "order",
                            }
                        })
                        const newNode = {
                            ...node,
                            next: child
                        };
                        this.setState({
                            currentNode: newNode,
                        })
                        let newPath = pathMap[newNode.id] ? [...pathMap[newNode.id]] : [];
                        newPath.pop();
                        dfsFullTreeTraversal(newNode,newPath);
                    }
                    else{
                        this.setState({
                            currentNode: {
                                ...node,
                                message: "There are no orders",
                                type: "terminal",
                                next: null,
                            }
                        })
                    }
                })
                return;
            }
            if(node.type === "order"){
                this.props.getHelpDeskTree(this.props.user.salt,this.props.user.merchantId,this.props.user.userId, false, fields).then(res => {
                    if(res && res.length > 0){
                        const newNode = {
                            ...node,
                            next: res[0].next,
                        };
                        this.sendHelpDeskEvent('api_succeeded',useCase,{"entity_type": newNode.title, "entity_name": newNode.id})
                        this.setState({
                            currentNode: newNode
                        })
                        let newPath = pathMap[newNode.id] ? [...pathMap[newNode.id]] : [];
                        newPath.pop();
                        dfsFullTreeTraversal(newNode,newPath);
                    }
                })
                .catch(error => {
                    this.sendHelpDeskEvent('api_failed',useCase,{"entity_type": node.title, "entity_name": node.id})
                    this.setState({error: true})
                })
                return;
            }
            this.sendHelpDeskEvent('api_requested',useCase,{"entity_type": node.title, "entity_name": node.id})
            this.props.getHelpDeskTree(this.props.user.salt,this.props.user.merchantId,this.props.user.userId, false, fields).then(res => {
                if(res && res.length > 0){
                    const newNode = {
                        ...node,
                        "message": res[0].message,
                        "input_required": res[0]["input_required"] ? true:false,
                        "next": res[0].next ? res[0].next: null,
                        "input_type": res[0]["input_type"] ||'',
                    };
                    this.sendHelpDeskEvent('api_succeeded',useCase,{"entity_type": newNode.title, "entity_name": newNode.id})
                    this.setState({
                        currentNode: newNode
                    })
                    if(res[0].next){
                        let newPath = pathMap[newNode.id] ? [...pathMap[newNode.id]] : [];
                        newPath.pop();
                        dfsFullTreeTraversal(newNode,newPath);
                    }
                }
            })
            .catch(error => {
                this.sendHelpDeskEvent('api_failed',useCase,{"entity_type": node.title, "entity_name": node.id})
                this.setState({error: true})
            })
        }
        else{
            this.setState((prevState) => {
                return{
                    currentNode: node,
                }
            })
        }
    }

    handleCtaLink = (node) => {
        this.sendHelpDeskEvent('clicked','terminal_node_cta', {"entity_type": node.title, "entity_name": node.id})
        this.setState({
            ctaClicked: true
        })
        if(window.innerWidth > 786 && node.ctaLink.includes("magicpin.in/partners")){
            const path = node.ctaLink.includes('https') ? node.ctaLink.replace('https://magicpin.in/partners','') : node.ctaLink.replace('http://magicpin.in/partners','')
            this.props.push(path);
        }
        else{
            const win = window.open(node.ctaLink, "_blank");
            win.focus();
        }
    }

    handleSubmit = (message="",fileSelected="",feedback=false) => {
        let magicpinMaildId =  get('magicpinId')
        if(this.props?.user?.userType == 'ADMIN' && magicpinMaildId && !['sai.teja@magicpin.in','sandeep.kumar1@magicpin.in','abhishek.aggarwal@magicpin.in','saurabh.mundeja@magicpin.in','vikas.bhatia@magicpin.in',
        'arun.devanathan@magicpin.in','animesh.das@magicpin.in','soumya.banerjee@magicpin.in','sumit.aswal@magicpin.in'].includes(magicpinMaildId))
            return toast.error('Admin cannot raise a ticket');

        const file = fileSelected || this.state.fileSelected;
        const comment = message || this.state.comment;
        const fields = this.state.parentQueue.map(parentNode => parentNode.id)
        const useCase = 'raising_ticket'
        if(!feedback && this.state.currentNode.input_type.includes("text") && !comment){
            toast.error("Please type message")
            return
        }
        if(!feedback && this.state.currentNode.input_type.includes("file") && !file){
            toast.error("Please attach a file")
            return
        }
        this.sendHelpDeskEvent('api_requested',useCase,{"entity_type": this.state.currentNode.title, "entity_name": this.state.currentNode.id})
        this.props.getHelpDeskTree(this.props.user.salt,this.props.user.merchantId,this.props.user.userId, false, fields,comment,this.state.email,file).then(res => {
            if(res && res.length > 0){
                this.sendHelpDeskEvent('api_succeeded',useCase, {"entity_type": this.state.currentNode.title, "entity_name": this.state.currentNode.id})
                if(message !== ""){
                    this.closeHelpDesk();
                    toast.success("Feedback submitted successfully, we will get back to you");
                }
                else{
                    const newNode = {
                        ...this.state.currentNode,
                        "message": res[0].message,
                        "input_required": false,
                        "input_type": "",
                        "type": "eof",
                    };
                    this.setState({
                        currentNode: newNode,
                        fileSelected: "",
                        replyMessage: "",
                        comment: ""
                    })
                    setTimeout(() => this.closeHelpDesk(),2400);
                }
            }
        })
        .catch(error => {
            this.sendHelpDeskEvent('api_failed',useCase,{"entity_type": this.state.currentNode.title, "entity_name": this.state.currentNode.id})
            this.setState({error: true})
            toast.error('Error in creating the ticket, please try after sometime or write to partner@magicpin.in')
        })        
    }

    changeToParentNode = (child) => {
        this.sendHelpDeskEvent('clicked','back_arrow',{"entity_type": child.title, "entity_name": child.id})
        this.setState((prevState) => {
            const parentList = [...pathMap[child.id]];
            parentList.pop();
            return{
                currentNode: {...parentList.pop()},
                parentQueue: parentList,
                isForward: false,
                results: [],
                query: "",
                replyMessage: "",
                fileSelected: "",
            }
        })
    }

    openPastIssues = () => {
        this.setState({
            pastIssues: true
        })
    }

    closePastIssues = () => {
        if(this.state.ticketId){
            this.props.getHelpDeskTicketHistory(this.props.user.merchantId,this.props.user.accessToken)
            this.setState({
                ticketId: "",
                replyMessage: "",
                fileSelected: ""
            })
        }
        else{
            this.setState({
                pastIssues: false,
                replyMessage: "",
                fileSelected: ""
            })
        }

    }

    openTicketConversation = (ticketId,isClear=false) => {
        this.props.getHelpDeskTicketHistory(this.props.user.merchantId,this.props.user.accessToken,ticketId).then(res => {
            if(this.props.allTicketsData){
                let newState = {
                    ticketId,
                }
                if(isClear){
                    newState.replyMessage = "";
                    newState.fileSelected = "";
                }
                this.setState(newState);
            }
        });
    }

    sendReplyMessage = () => {
        let magicpinMaildId = get('magicpinId');
        if(this.props?.user?.userType == 'ADMIN' && magicpinMaildId && !['sai.teja@magicpin.in','sandeep.kumar1@magicpin.in','abhishek.aggarwal@magicpin.in','saurabh.mundeja@magicpin.in','vikas.bhatia@magicpin.in',
        ,'arun.devanathan@magicpin.in','animesh.das@magicpin.in','soumya.banerjee@magicpin.in','sumit.aswal@magicpin.in'].includes(magicpinMaildId))
            return toast.error('Admin cannot reply');

        if((this.state.replyMessage && this.state.replyMessage.trim().length > 0) || this.state.fileSelected){
            this.props.updateHelpDeskTicketHistory(this.props.user.salt,this.props.user.merchantId,this.props.user.userId,this.props.user.accessToken,this.state.ticketId,this.state.replyMessage,this.state.fileSelected)
            .then(res => {
                if(this.props.messageSent)
                    this.openTicketConversation(this.state.ticketId,true);
            })
            .catch(error => {
                toast.error('Error in sending the message, please try after sometime or write to partner@magicpin.in')
            })
        }
    }

    pastIssuesGenerate = () => {
        if(this.props.ticket_loading)
            return <div style={{position: "relative"}}><Loader></Loader></div>;

        const node = {
            ...this.props.allTicketsData,
            ticket_id: this.props.allTicketsData.ticket_id ? this.props.allTicketsData.ticket_id : "ticket-history",
            title: this.props.allTicketsData.title ? this.props.allTicketsData.title : 'Past Issues',
        }
        let results = [];
        if(this.state.ticketId){
            results = node.messages || [];
        }
        else{
            node.all_tickets = node.all_tickets ? node.all_tickets : [];
            node.archived = node.archived ? node.archived : [];
            results = [...node.all_tickets,...node.archived];
        }

        return (
            <div className="helpdesk-node-body">
                <div className="helpdesk-header">
                    <div style={{display:"flex",flexDirection:"row"}}>
                        {<img style={{width:"20px",height:"20px",marginRight:"10px"}} src={backArrow} onClick={() => this.closePastIssues()} alt="back"></img>}
                        <div> 
                            <h4>{node.title}</h4>                      
                            {this.state.ticketId && <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"12px",fontFamily:"robotoregular"}}> {`Date: ${moment(node.create_time).format("DD/MM/YYYY")}. Ticket id: ${this.state.ticketId}`}</div>}
                        </div>
                    </div>
                </div>
                <ReactCSSTransitionGroup transitionName={"carousel"} transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                    <div className="helpdesk-body" key={node.ticket_id}>
                        {
                            this.state.ticketId ?
                            <div className="ticket-message-body">
                                <div className="ticket-messages">
                                    {Array.isArray(results) && results.map(message => {
                                        const isMerchant = message.author_name && message.author_name.toUpperCase() === "YOU";
                                        const profilePic = this.props.user?.profilePic || profileIcon;
                                        return (
                                            <div key={message.message} className={`ticket-message${isMerchant ? "-reverse":""}`}>
                                                <div className="ticket-message-header">
                                                    <div style={{marginRight:"12px"}}> <img width="34px" style={{borderRadius:"50%",padding: isMerchant ? "2px":"",border:"0.5px rgba(0, 0, 0, 0.54) solid"}} src={isMerchant ? profilePic : magicpinLogo}></img></div>
                                                    <div>
                                                        <div>{message.author_name} :</div>
                                                        <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"12px",fontFamily:"robotoregular"}}> {`${moment(message.time).format("DD/MM/YYYY, hh:mm a")}`}</div>
                                                    </div>
                                                </div>
                                                <div className="ticket-message-content" dangerouslySetInnerHTML={{ __html: message.message}}>
                                                </div>
                                                {
                                                    Array.isArray(message.attachments) && 
                                                    message.attachments.map(attachment => {
                                                        return (
                                                            <img
                                                                src={attachment?.url}
                                                                frameBorder="0"
                                                                scrolling="auto"
                                                                height="100%"
                                                                width="100%"
                                                                alt="attachment file"
                                                            ></img>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="ticket-message-reply">
                                    <img style={{float:'left',marginRight:"2px"}} width="16px" onClick={this.triggerUpload} src={attachIcon}></img>
                                    <TextareaAutosize className="ticket-message-reply-textarea" placeholder="Reply..." value={this.state.replyMessage} onChange={e => this.setState({replyMessage: e.target.value})} />
                                    <img src={reply} onClick={this.sendReplyMessage}></img>
                                </div>
                                {!this.props.message_sending && this.state.fileSelected && <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"14px",marginTop:"-8px"}}>{"Uploaded:" + this.state.fileSelected?.name}</div>}
                                {this.props.message_sending && <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"12px",marginBottom:"4px"}}>{'Sending...'}</div>}
                                {
                                    results.length === 0 && <div>No Past Issues</div>                               
                                }
                            </div>
                            :
                            <div className="helpdesk-lists">
                                <ReactCSSTransitionGroup transitionName={"carousel"} transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                                {
                                    Array.isArray(results) && results.map(child => {
                                        return(
                                            <li key={child.ticket_id} className="helpdesk-issue" style={{fontSize:"18px"}} onClick={() => this.openTicketConversation(child.ticket_id)}>
                                                <div>
                                                    <div>{child.title}</div>
                                                    <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"12px",fontFamily:"robotoregular"}}> {`Date: ${moment(child.create_time).format("DD/MM/YYYY")}. Ticket id: ${child.ticket_id}`}</div>
                                                </div>
                                                {
                                                    child.status && ['CLOSED','RESOLVED'].includes(child.status.toUpperCase()) ?
                                                    <img src={blueTick} width="24px" alt="next"></img>
                                                    :
                                                    <div style={{color:"#ff455f"}}>
                                                        {<div style={{fontSize:"16px",fontFamily:"robotoregular",lineHeight:"20px"}}>Open</div>}
                                                    </div>
                                                }
                                            </li>
                                        )
                                    })
                                }
                                {
                                    results.length === 0 && <div>No Past Issues</div>                               
                                }
                                </ReactCSSTransitionGroup>
                            </div>
                        }
                    </div>
                </ReactCSSTransitionGroup>
            </div>
        );        
    }

    helpDeskTreeTraversal = (node) => {
        const results = this.state.results.length > 0 ? this.state.results: node.next;
        if(!node)
            return null;
        return (
            <div className="helpdesk-node-body">
                <div className="helpdesk-header">
                    <div style={{display:"flex",flexDirection:"row"}}>
                        {node.title !== 'Helpdesk' ? <img style={{width:"20px",height:"20px",marginRight:"10px"}} src={backArrow} onClick={() => this.changeToParentNode(node)} alt="back"></img>:null}
                        <h4>{node.title}</h4>
                    </div>
                    {
                        this.props.allTicketsData && results !== null &&
                        <div className="helpdesk-past-issues" onClick={() => this.openPastIssues()}>
                            <h4 style={{marginBottom:'0px'}}>Past Issues</h4>
                            {this.state.openTicketsCount > 0 && <span>{this.state.openTicketsCount}</span>}
                        </div>                        
                    }
                </div>
                <ReactCSSTransitionGroup transitionName={this.state.isForward ? "carousel":"carousel-reverse"} transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                {
                    results === null ?
                    <div className="helpdesk-body-terminal" key={node.id} style={{justifyContent: node.input_required ? "start":""}}>
                        <div style={{height: node.input_required !== true ? window.innerWidth < 768 ? "65%":"70%":"", overflowY:"auto",marginBottom: node.input_required !== true ? "40%":"",paddingBottom: window.innerWidth < 768 && node.input_required !== true  ? "22%": ""}} dangerouslySetInnerHTML={{ __html: node.message}}>
                        </div>
                        {
                            node.input_required === true ?
                            <div className="helpdesk-input-comment">
                                <textarea placeholder="Add a comment" onChange={e => this.setState({comment: e.target.value})}>
                                </textarea>
                                <div style={{marginLeft:"4px",color:"#ff455f",fontFamily:"robotomedium",cursor:"pointer"}} onClick={this.triggerUpload}> Attach files{node.input_type.includes("file") && <span>*</span>}</div> 
                                {this.state.fileSelected && <div style={{marginLeft:"4px", padding:"2px 4px",backgroundColor:"#f7f7fa",borderRadius:"16px",width:'50%'}}>{this.state.fileSelected?.name.slice(0,20)}</div>}
                                <button disabled={this.props.loading} onClick={() => this.handleSubmit()}>{this.props.loading ? "Submitting..":"Submit"}</button>
                            </div>
                            :
                            <div className="cta-bottom-fix">
                                <hr></hr>
                                {
                                    node.ctaText && <button onClick={() => this.handleCtaLink(node)}>{node.ctaText}</button>
                                }
                                {
                                    node.type !== "eof" &&
                                    <HelpDeskFeedBack loading={this.props.loading || false} handleSubmit={(value,file,flag) => this.handleSubmit(value,file,flag)} closeDialog={this.closeHelpDesk} sendHelpDeskEvent={(eventName,useCase) => this.sendHelpDeskEvent(eventName,useCase,{"entity_type": this.state.currentNode.title, "entity_name": this.state.currentNode.id})}></HelpDeskFeedBack>
                                }
                            </div>
                        }
                    </div>
                    :                       
                    node.type === "page" ?
                    <div key={node.id}>
                        <HelpDeskListPage data={node.next || []} getHelpDeskTree={(value) => this.changeCurrentNode(value)} pageType={node.page_type}/>
                    </div>
                    :
                    <div className="helpdesk-body" key={node.id}>
                        <div className="helpdesk-search">
                            <input type="text" placeholder="Search your concern (Min 3 chars)" onChange={e => this.searchInput(e.target.value)} value={this.state.query}></input>
                            {this.state.query.length >= 3 && this.state.results.length === 0 && <p style={{color:"red",fontSize:"10px"}}>No results found, instead showing results for all </p>}
                        </div>
                        {
                            this.state.parentQueue.length > 1 &&
                            <div style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"16px",marginBottom:"8px"}}>{this.state.currentNode.title}({this.state.currentNode.next && this.state.currentNode.next.length})</div>
                        }
                        <div className="helpdesk-lists">
                            <ReactCSSTransitionGroup transitionName={this.state.isForward ? "carousel":"carousel-reverse"} transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                            {
                                Array.isArray(results) && results.map(child => {
                                    return(
                                        <li key={child.id} className="helpdesk-issue" onClick={() => this.changeCurrentNode(child)}>
                                            <div>{child.title}
                                            {
                                                child.toolTip && 
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">{child.toolTip}</Tooltip>}>
                                                    <img style={{margin:"2px 4px",width:"14px"}} className="info-icon" src={infoIcon}/>
                                                </OverlayTrigger>
                                            }
                                            </div>
                                            <img src={LeftArrow} alt="next"></img>
                                        </li>
                                    )
                                })
                            }
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                }
                </ReactCSSTransitionGroup>
            </div>
        );
    }

    closeHelpDesk = () => {
        this.setState({
            close: true
        })
        setTimeout(() => this.props.hideHelpDesk(),600);
    }

    onChange = () => {
        var uploadedFile = document.getElementById('file-input') ? document.getElementById('file-input').files:[];
        if(uploadedFile.length > 0){
          this.setState({
            fileSelected: uploadedFile[0],
            isProof: true
          })
        }
        else{
          toast.error("please upload file in jpg/png/jpeg format only")
        }
    }
    
    triggerUpload = () => {
        let fileInput = document.getElementById('file-input')
        if(fileInput){
            fileInput.click()
        }
    }

    minimizeHelpDesk = () => {
        this.sendHelpDeskEvent('clicked','minimizing_helpdesk',{"entity_type": this.state.currentNode.title, "entity_name": this.state.currentNode.id})

        // document.getElementById('custom_chat_button').style.visibility = "hidden";
        this.props.minimizeHelpDesk();
    }

    bfsTraversal = (root, query) => {
        if(!root)
            return;
        
        let queue = [];
        queue.push(root);

        while(queue.length > 0){
            let currentNode = queue.shift();
            if(currentNode.title && currentNode.title.toLowerCase().includes(query) || currentNode.searchText && currentNode.searchText.toLowerCase().includes(query))
                this.results.push(currentNode);
            if(Array.isArray(currentNode.next))
                for(var j = 0; j < currentNode.next.length; j++)
                    queue.push(currentNode.next[j])
        }
    }

    searchInput = (value) => {
        if(value.length >= 3){
            this.setState({
                isSearching: true,
                query: value,
                results: this.treeSearch(value.toLowerCase())
            })
        }
        else{
            this.setState({
                query: value,
                results: [],
                isSearching: false,
            })
        }
    }

    treeSearch = (query) => {
        this.results = [];
        let results = this.index.search(`*${query.toLowerCase()}*`);
        results = results.map(result => nodeMap[result.ref]);
        return results;
        // this.state.currentNode.next.forEach(child => this.bfsTraversal(child,query));
        // return this.results.reverse();
    }

    render(){
        const currentNode = this.state.currentNode;
        if(this.props.minimizeHelpDeskWidget){
            return (
                <div style={{visibility:"hidden"}} className="minimize-clip" onClick={this.minimizeHelpDesk}>
                    Help
                </div>
            )
        }
        if(!currentNode){
            return (
                <div className={`helpdesk-${this.state.close ? "reverse-":""}container`}>
                    <img src={closeIcon} onClick={this.closeHelpDesk}/>
                    {/* <img width="20px" style={{position: "absolute",top: window.innerWidth < 768 ? "3%":"8%",right: window.innerWidth < 768 ? "3%":"8%"}} src={minimizeIcon} onClick={this.minimizeHelpDesk}/> */}
                    <h3>Support</h3>
                    {
                        this.state.error ? 
                        <div>
                            Error in fetching the details, Try again after sometime
                        </div>
                        :
                        <div style={{position: "relative"}}><Loader></Loader></div>
                    }
                </div>
            );
        }
        else{
            return (
                <div className={`helpdesk-${this.state.close ? "reverse-":""}container`}>
                    <input
                        ref='fileInput' 
                        id="file-input" 
                        type="file" 
                        accept=".png, .jpg, .jpeg, .csv, .pdf, .xlsx"
                        style={{display:'none'}}
                        onChange={this.onChange}
                    />
                    {/* <img src={closeIcon} onClick={this.closeHelpDesk}/> */}
                    <img width="20px" style={{position: "absolute",top: window.innerWidth < 768 ? "3%":"8%",right: window.innerWidth < 768 ? "3%":"8%"}} src={minimizeIcon} onClick={this.minimizeHelpDesk}/>
                    <h3 style={{marginBottom:"20px"}}>Support</h3>
                    {this.state.pastIssues ? this.pastIssuesGenerate() :this.helpDeskTreeTraversal(currentNode)}
                </div>
            );
        }
    }
})
