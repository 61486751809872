const LOAD = 'bookDeal/LOAD';
const LOAD_SUCCESS = 'bookDeal/LOAD_SUCCESS';
const LOAD_FAIL = 'bookDeal/LOAD_FAIL';

const CONTACT = 'bookDeal/CONTACT';
const CONTACT_SUCCESS = 'bookDeal/CONTACT_SUCCESS';
const CONTACT_FAIL = 'bookDeal/CONTACT_FAIL';

const GET_COUNT = 'bookDeal/GET_COUNT';
const GET_COUNT_SUCCESS = 'bookDeal/GET_COUNT_SUCCESS';
const GET_COUNT_FAIL = 'bookDeal/GET_COUNT_FAIL';

const LOAD_MISSED = 'bookDeal/LOAD_MISSED';
const LOAD_MISSED_SUCCESS = 'bookDeal/LOAD_MISSED_SUCCESS';
const LOAD_MISSED_FAIL = 'bookDeal/LOAD_MISSED_FAIL';

const LOAD_CONVERTED = 'bookDeal/LOAD_CONVERTED';
const LOAD_CONVERTED_SUCCESS = 'bookDeal/LOAD_CONVERTED_SUCCESS';
const LOAD_CONVERTED_FAIL = 'bookDeal/LOAD_CONVERTED_FAIL';

const LOAD_ACTIVE = 'bookDeal/LOAD_ACTIVE';
const LOAD_ACTIVE_SUCCESS = 'bookDeal/LOAD_ACTIVE_SUCCESS';
const LOAD_ACTIVE_FAIL = 'bookDeal/LOAD_ACTIVE_FAIL';

const LOAD_STATS = 'bookDeal/LOAD_STATS';
const LOAD_STATS_SUCCESS = 'bookDeal/LOAD_STATS_SUCCESS';
const LOAD_STATS_FAIL = 'bookDeal/LOAD_STATS_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  data : [],
  next : '0',
  moreData : true,
  total_booked_deals : 0,
  total_contacted_customers : 0,
  total_lost_customers : 0,
  business_missed : '',
  business_earned : '',
  customers_visited : 0,
  isContacted : false,
  dealsCount: 0,
  loader: false,
  missedStart: 0,
  convertedStart: 0,
  activeStart: 0,
  activeLoaded: false,
  missedLoaded: false,
  convertedLoaded: false,
  activeData: [],
  convertedData: [],
  missedData: [],
  statsData: null,
  contactingBookingId: null,
}

export default function bookDeal(state = initialState, action = {}) {
  switch (action.type) {
    case CONTACT:
      return {
        ...state,
        isContacting: true,
        isContacted: false,
        contactingBookingId: action.booking_id,
      };
    case CONTACT_SUCCESS:
      return{
        ...state,
        isContacted: true,
        isContacting: false,
        contactingBookingId: null,
        activeData: state.activeData.map(bookeddeal => {
          if(bookeddeal.booking_id == action.booking_id){
            return ({...bookeddeal, is_confirmed: true})
          }
          else{
            return bookeddeal;
          }
        }),
        dealsCount: state.dealsCount - 1,
      };

    case CONTACT_FAIL:
      return{
        ...state,
        contactingBookingId: null,
        isContacting: false,
        isContacted : false,
        contactError: action.error
      }

    case GET_COUNT:
      return {
        ...state,
        countLoading: true,
      };
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        countLoading: false,
        countLoaded: true,
        dealsCount: action.result,
      };
    case GET_COUNT_FAIL:
      return {
        ...state,
        countLoading: false,
        countLoaded: false,
        countError: action.error,
      };
    case LOAD_ACTIVE:
      return {
        ...state,
        activeLoading: true,
        activeLoaded: false
      }
    case LOAD_ACTIVE_SUCCESS:
      return {
        ...state,
        activeLoading: false,
        activeLoaded: true,
        activeTotal: action.result.total ? action.result.total : 0,
        activeData: action.result.bookings ? [...state.activeData, ...action.result.bookings] : state.activeData,
        activeStart: action.result.bookings && Array.isArray(action.result.bookings) && action.result.bookings.length >= 9 ? state.activeStart + action.result.bookings.length : 0,
      };
    case LOAD_ACTIVE_FAIL:
      return {
        ...state,
        activeLoading: false,
        activeLoaded: true,
        activeError: action.error
      };
      case LOAD_MISSED:
      return {
        ...state,
        missedLoading: true,
        missedLoaded: false
      }
    case LOAD_MISSED_SUCCESS:
      return {
        ...state,
        missedLoading: false,
        missedLoaded: true,
        missedTotal: action.result.total ? action.result.total : 0,
        missedData: action.start === 0 ? action.result.bookings ? action.result.bookings : [] : [...state.missedData, ...(action.result.bookings ? action.result.bookings : [])],
        missedStart: action.result.bookings && Array.isArray(action.result.bookings) &&  action.result.bookings.length >= 9 ? state.missedStart + action.result.bookings.length : 0,
      };
    case LOAD_MISSED_FAIL:
      return {
        ...state,
        missedLoading: false,
        missedLoaded: true,
        missedError: action.error
      };
      case LOAD_CONVERTED:
      return {
        ...state,
        convertedLoading: true,
        convertedLoaded: false
      }
    case LOAD_CONVERTED_SUCCESS:
      
      return {
        ...state,
        convertedLoading: false,
        convertedLoaded: true,
        convertedBusiness: action.result.business ? action.result.business : {},
        convertedTotal: action.result.total ? action.result.total : 0,
        convertedData: action.start === 0 ? ( action.result.bookings ? action.result.bookings : [] ) : [...state.convertedData, ...(action.result.bookings ? action.result.bookings : []) ],
        convertedStart: action.result.bookings && Array.isArray(action.result.bookings) && action.result.bookings.length >= 9 ? state.convertedStart + action.result.bookings.length : 0,
      };
    case LOAD_CONVERTED_FAIL:
      return {
        ...state,
        convertedLoading: false,
        convertedLoaded: true,
        convertedError: action.error
      };
    case LOAD_STATS:
      return{
        ...state,
        statsLoading: true,
        statsLoaded: false,
      }
    case LOAD_STATS_SUCCESS:
      return{
        ...state,
        statsLoading: false,
        statsLoaded: true,
        statsData:action.result
      }
    case LOAD_STATS_FAIL:
      return{
        ...state,
        statsLoading: false,
        statsLoaded: true,
        statsError: action.error
      }
   default:
      return state;
  }
}


export function loadMissedBookings(accessToken, startDate, endDate, start, limit){
  return {
    types:[ LOAD_MISSED, LOAD_MISSED_SUCCESS, LOAD_MISSED_FAIL],
    promise: (client) => client.default.post('getMissedBookings',{
      data:{accessToken, startDate, endDate, start, limit}
    }),
    start, limit
  };
}

export function loadConvertedBookings(accessToken, startDate, endDate, start, limit){
  return {
    types:[ LOAD_CONVERTED, LOAD_CONVERTED_SUCCESS, LOAD_CONVERTED_FAIL],
    promise: (client) => client.default.post('getConvertedBookings',{
      data:{accessToken, startDate, endDate, start, limit}
    }),
    start, limit
  };
}

export function loadActiveBookings(accessToken, start, limit){
  return {
    types:[ LOAD_ACTIVE, LOAD_ACTIVE_SUCCESS, LOAD_ACTIVE_FAIL],
    promise: (client) => client.default.post('getActiveBookings',{
      data:{accessToken, start, limit}
    })
  };
}

export function loadBookingStats(accessToken){
  return{
    types:[LOAD_STATS, LOAD_STATS_SUCCESS, LOAD_STATS_FAIL],
    promise:(client)=>client.default.post('/getOverallBooking',{
      data:{accessToken}
    })
  }
}

export function contact(accessToken, booking_id) {
  return {
    types: [CONTACT, CONTACT_SUCCESS, CONTACT_FAIL],
    promise: (client) => client.default.post('/updateIsContacted',{
      data:{accessToken, booking_id}
    }),
    booking_id,
  };
}

export function getDealsCount(accessToken) {
  return {
    types: [GET_COUNT, GET_COUNT_SUCCESS, GET_COUNT_FAIL],
    promise: (client) => client.default.post('/getDealsCount', {
      data: {accessToken}
    }),
  };
}
