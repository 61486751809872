import React, {Component, PropTypes} from 'react';

import './help_page_stylesheet.css'

export default class Help extends Component{
  render(){
    return(
      <section className="content-wrapper help-main-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12 white-box-wrapper-sm">
              <div className="help-title">
                <h1>It’s always good to hear from you, so here’s how you can get in touch</h1>
              </div>
            </div>
            {this.props.user && this.props.user.BDinfo.map((BD, idx) =>
              <div key={idx} className="col-md-6 white-box-wrapper-sm">
                <div className="white-box">
                  <div className="help-icon-box" style={{padding:0}}>
                    <img src={BD.photo ? BD.photo : 'images/user-hd-icon.png'}
                      style={{width:'100%', height:'auto', borderRadius:'144px'}} />
                  </div>
                  <div className="help-text-block">
                    <h2>{BD.firstName + ' ' + BD.lastName}</h2>
                    <p><a href={'mailto:partner@magicpin.in'} style={{color:'#637e8c', fontFamily:'robotoregular'}}>partner@magicpin.in</a></p>
                    <p><a href={'tel:7510175101'}>75101 75101 (10 AM to 8 PM)</a></p>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6 white-box-wrapper-sm">
              <div className="white-box">
                <div className="help-icon-box"><img src="images/help-call-icon.png" width="80" /></div>
                <div className="help-text-block">
                  <h2>Call or Whatsapp</h2>
                  <p>Anytime between 9:30 am to 9:00 pm, Monday to Saturday.</p>
                  <p><a href="#">75101 75101 (10 AM to 8 PM)</a></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 white-box-wrapper-sm">
              <div className="white-box">
                <div className="help-icon-box"><img src="images/help-email-icon.png" width="80" /></div>
                <div className="help-text-block">
                  <h2>Email</h2>
                  <p>Mail us your queries anytime of the day, and we wi'll get back to you asap!</p>
                  <p><a href={'mailto:partner@magicpin.in'}>partner@magicpin.in</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
