import moment from 'moment';

const LOAD = 'businessSummary/LOAD';
const LOAD_SUCCESS = 'businessSummary/LOAD_SUCCESS';
const LOAD_FAIL = 'businessSummary/LOAD_FAIL';

const LOAD_ACCOUNT_STATUS = 'businessSummary/LOAD_ACCOUNT_STATUS';
const LOAD_ACCOUNT_STATUS_SUCCESS = 'businessSummary/LOAD_ACCOUNT_STATUS_SUCCESS';
const LOAD_ACCOUNT_STATUS_FAIL = 'businessSummary/LOAD_ACCOUNT_STATUS_FAIL';

const LOAD_PERFORMANCE_REPORT= 'businessSummary/LOAD_PERFORMANCE_REPORT';
const LOAD_PERFORMANCE_REPORT_SUCCESS= 'businessSummary/LOAD_PERFORMANCE_REPORT_SUCCESS';
const LOAD_PERFORMANCE_REPORT_FAIL= 'businessSummary/LOAD_PERFORMANCE_REPORT_FAIL';

const GENERATE_SCREEN_SHOT= 'businessSummary/GENERATE_SCREEN_SHOT'
const GENERATE_SCREEN_SHOT_SUCCESS='businessSummary/GENERATE_SCREEN_SHOT_SUCCESS'
const GENERATE_SCREEN_SHOT_FAIL='businessSummary/GENERATE_SCREEN_SHOT_FAIL'

const GET_BLOGS = 'businessSummary/GET_BLOGS'
const GET_BLOGS_SUCCESS = 'businessSummary/GET_BLOGS_SUCCESS'
const GET_BLOGS_FAIL = 'businessSummary/GET_BLOGS_FAIL'

const GET_MEETUPS = 'businessSummary/GET_MEETUPS'
const GET_MEETUPS_SUCCESS = 'businessSummary/GET_MEETUPS_SUCCESS'
const GET_MEETUPS_FAIL = 'businessSummary/GET_MEETUPS_FAIL'


const LOAD_GST_COUNT = 'businessSummary/LOAD_GST_COUNT';
const LOAD_GST_COUNT_SUCCESS = 'businessSummary/LOAD_GST_COUNT_SUCCESS';
const LOAD_GST_COUNT_FAIL = 'businessSummary/LOAD_GST_COUNT_FAIL';

const INTREST_MAGIC_ORDER_LOAD = 'businessSummary/INTREST_MAGIC_ORDER_LOAD';
const INTREST_MAGIC_ORDER_SUCCESS = 'businessSummary/INTREST_MAGIC_ORDER_SUCCESS';
const INTREST_MAGIC_ORDER_FAIL = 'businessSummary/INTREST_MAGIC_ORDER_FAIL';

const DISABLE_CATALOG_ACCESS = 'businessSummary/DISABLE_CATALOG_ACCESS';
const DISABLE_CATALOG_ACCESS_SUCCESS = 'businessSummary/DISABLE_CATALOG_ACCESS_SUCCESS';
const DISABLE_CATALOG_ACCESS_FAIL = 'businessSummary/DISABLE_CATALOG_ACCESS_FAIL';

const GET_MERCHANT_REPORT_URL = 'businessSummary/GET_MERCHANT_REPORT_URL'
const GET_MERCHANT_REPORT_URL_SUCCESS = 'businessSummary/GET_MERCHANT_REPORT_URL_SUCCESS'
const GET_MERCHANT_REPORT_URL_FAIL = 'businessSummary/GET_MERCHANT_REPORT_URL_FAIL'

const LOAD_MAGICPIN_DISCOUNT = 'businessSummary/LOAD_MAGICPIN_DISCOUNT';
const LOAD_MAGICPIN_DISCOUNT_SUCCESS = 'businessSummary/LOAD_MAGICPIN_DISCOUNT_SUCCESS';
const LOAD_MAGICPIN_DISCOUNT_FAIL = 'businessSummary/LOAD_MAGICPIN_DISCOUNT_FAIL';


const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      // console.log(action);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.callingComponent === 'dashboard' ? action.result: state.data,
        chartData: ['dashboard', 'gmvChart'].includes(action.callingComponent) ? action.result : state.chartData,
        oldData: action.callingComponent === 'last60Days' ? action.result : state.oldData,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
      case LOAD_ACCOUNT_STATUS:
        return {
          ...state,
          loadingStatus: true
        };
      case LOAD_ACCOUNT_STATUS_SUCCESS:
        return {
          ...state,
          loadingStatus: false,
          loadedStatus: true,
          accountStatus: action.result.accountStatus,
          acccoutStatusError: null,
        };
      case LOAD_ACCOUNT_STATUS_FAIL:
        return {
          ...state,
          loadingStatus: false,
          loadedStatus: false,
          accountStatus: null,
          acccoutStatusError: action.error,
        };
      case LOAD_PERFORMANCE_REPORT:
        return{
          ...state,
          loadingReport: true
        }
      case LOAD_PERFORMANCE_REPORT_SUCCESS:
        return{
          ...state,
          loadingReport: false,
          performanceReportData: action.result,
          error:null,
      }
      case LOAD_PERFORMANCE_REPORT_FAIL:
        return{
          ...state,
          loadingReport:false,
          performanceReportData: null,
          error: action.error
        }
      case GENERATE_SCREEN_SHOT:
        return{
            ...state,
            loadingScreenShot: true
        }
      case GENERATE_SCREEN_SHOT_SUCCESS:
        return{
            ...state,
            loadingScreenShot: false,
            loadedScreenShot: true,
            screenShotUrl: action.result
        }
      case GENERATE_SCREEN_SHOT_FAIL:
        return{
            ...state,
            loadingScreenShot: false,
            loadedScreenShot: true,
            error: action.error
        }
      case GET_BLOGS:
        return{
          ...state,
          loadingBlogs: true,
          loadedBlogs: false
        }
      case GET_BLOGS_SUCCESS:
        return{
          ...state,
          loadingBlogs: false,
          loadedBlogs: true,
          blogs: action.result,
          error:null
        }
      case GET_BLOGS_FAIL:
        return{
          ...state,
          loadingBlogs: false,
          loadedBlogs: true,
          blogs: null,
          error: action.error
        }
      case GET_MEETUPS:
        return{
          ...state,
          loadingMeetups: true,
          loadedMeetups: false
        }
      case GET_MEETUPS_SUCCESS:
        return{
          ...state,
          loadingMeetups: false,
          loadedMeetups: true,
          meetups: action.result,
          error:null
        }
      case GET_MEETUPS_FAIL:
        return{
          ...state,
          loadingMeetups: false,
          loadedMeetups: true,
          meetups: null,
          error: action.error
        }
      case LOAD_GST_COUNT:
          return {
            ...state,
            loadingGstCount: true
          }
      case LOAD_GST_COUNT_SUCCESS:
          return {
            ...state,
            loadingGstCount: false,
            gstModalCount: action.result
          }
      case LOAD_GST_COUNT_FAIL:
          return {
            ...state,
            loadingGstCount: false,
            gstModalCount: 0
          }

      case INTREST_MAGIC_ORDER_LOAD:
        return {
          ...state,
          isIntrestedLoading: true,
        }
      case INTREST_MAGIC_ORDER_SUCCESS:
        return {
          ...state,
          isIntrestedLoading: false,
          isIntrestedInMagicOrder: action.result
        }
      case INTREST_MAGIC_ORDER_FAIL:
        return {
          ...state,
          isIntrestedLoading: false,
          isIntrestedInMagicOrder: 0
        }
      case DISABLE_CATALOG_ACCESS:
        return {
          ...state,
          catalogDisabledLoading: true,
          catalogDisabledData: null
        }
      case DISABLE_CATALOG_ACCESS_SUCCESS:
        return {
          ...state,
          catalogDisabledLoading: false,
          catalogDisabledData: action.result
        }
      case DISABLE_CATALOG_ACCESS_FAIL:
        return {
          ...state,
          catalogDisabledLoading: false,
          catalogDisabledData: false
        }

      case GET_MERCHANT_REPORT_URL:
        return {
          generatingReportUrl: true
        }
      case GET_MERCHANT_REPORT_URL_SUCCESS:
        return {
          generatingReportUrl: false,
          reportUrl: action.result
        }
      case GET_MERCHANT_REPORT_URL_FAIL:
        return {
          generatingReportUrl: false
        }

      case LOAD_MAGICPIN_DISCOUNT:
        return {
          ...state,
          loadingMagicpinDiscount: true,
          magicpinDiscountData: null,
          magicpinDiscountDataFailure: false
        }
      case LOAD_MAGICPIN_DISCOUNT_SUCCESS:
        return {
          ...state,
          loadingMagicpinDiscount: false,
          magicpinDiscountData: action.result,
          magicpinDiscountDataFailure: false
        }
      case LOAD_MAGICPIN_DISCOUNT_FAIL:
        return {
          ...state,
          loadingMagicpinDiscount: false,
          magicpinDiscountData: null,
          magicpinDiscountDataFailure: true
        }

   default:
      return state;
  }
}

export function loadBusinessSummary(accessToken, callingComponent) {
  const now = moment().format('YYYY-MM-DD');
  const oneMonthAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const sevenWeeksAgo= moment().subtract(49, 'days').format('YYYY-MM-DD');
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/loadBusinessSummary', {
      data: {accessToken, now, oneMonthAgo, sevenWeeksAgo}
    }),
    callingComponent
  };
}

export function getAccountStatus(accessToken) {
  return {
    types: [LOAD_ACCOUNT_STATUS, LOAD_ACCOUNT_STATUS_SUCCESS, LOAD_ACCOUNT_STATUS_FAIL],
    promise: (client) => client.default.post('/getAccountStatus', {
      data: {accessToken}
    })
  }
}

export function getPerformanceReport(merchantId, startDate, endDate) {
  let url=`/getPerformanceReport?merchantId=${merchantId}`
  if(startDate)
    url=url+`&start_date=${startDate}`
  if(endDate)
    url=url+`&end_date=${endDate}`
  return {
    types: [LOAD_PERFORMANCE_REPORT,LOAD_PERFORMANCE_REPORT_SUCCESS , LOAD_PERFORMANCE_REPORT_FAIL],
    promise: (client) => client.default.post(url)
  }
}

export function generatePerformanceReport(url, merchantId, accessToken) {
  return {
    types: [GENERATE_SCREEN_SHOT, GENERATE_SCREEN_SHOT_SUCCESS, GENERATE_SCREEN_SHOT_FAIL],
    promise: (client) => client.default.post('/generatePerformanceReport',{
      data:{url, merchantId, accessToken}
    })
  }
}

export function getBlogs(categoryID, city) {
  const url=`https://magicpin.in/sam-api/blog/brands/?categoryID=${categoryID}&rows=2&city=${city}`
  return {
    types: [GET_BLOGS, GET_BLOGS_SUCCESS, GET_BLOGS_FAIL],
    promise: (client) => client.default.get(url)
  }
}

export function getMeetups(merchantCity) {
  const action = {
    city: merchantCity
  };
  return {
    types: [GET_MEETUPS, GET_MEETUPS_SUCCESS, GET_MEETUPS_FAIL],
      
    promise: (client) => client.default.post('/getMeetups', {
      data:{action}
    })
  }
}

export function getGstModalCount(accessToken, userType) {
  return {
    types:[LOAD_GST_COUNT, LOAD_GST_COUNT_SUCCESS, LOAD_GST_COUNT_FAIL],
    promise:(client) => client.default.post('/getGstModalCount',{
      data:{accessToken, userType}
    })
  }
}

export function merchantShowsIntrestMagicOrder(accessToken, merchantId) {
  return {
    types:[INTREST_MAGIC_ORDER_LOAD, INTREST_MAGIC_ORDER_SUCCESS, INTREST_MAGIC_ORDER_FAIL],
    promise:(client) => client.default.post('/merchantShowsIntrestRedis',{
      data:{accessToken, merchantId}
    })
  }
}

export function disableCatalogAccessFromRedis(accessToken, merchantId) {
  return {
    types:[DISABLE_CATALOG_ACCESS, DISABLE_CATALOG_ACCESS_SUCCESS, DISABLE_CATALOG_ACCESS_FAIL],
    promise:(client) => client.default.post('/isCatalogDisabled',{
      data:{accessToken, merchantId}
    })
  }
}

export function getMerchantReportUrl(merchantId, startDate, endDate, campaign_only) {
  return {
    types:[GET_MERCHANT_REPORT_URL, GET_MERCHANT_REPORT_URL_SUCCESS, GET_MERCHANT_REPORT_URL_FAIL],
    promise:(client) => client.default.post('/getMerchantReportUrl',{
      data:{merchantId, startDate, endDate, campaign_only}
    })
  }
}

export function magicpinOverallDiscountCall(accessToken, startDate, endDate) {
  return {
    types: [LOAD_MAGICPIN_DISCOUNT, LOAD_MAGICPIN_DISCOUNT_SUCCESS, LOAD_MAGICPIN_DISCOUNT_FAIL],
    promise: (client) => client.default.post('/magicpinOverallDiscount', {
      data: {accessToken, startDate, endDate}
    })
  };
}