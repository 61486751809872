const INSTALL_BPP_SOFTWARE = 'brandPrivilegeProgram/INSTALL_BPP_SOFTWARE';
const INSTALL_BPP_SOFTWARE_SUCCESS = 'brandPrivilegeProgram/INSTALL_BPP_SOFTWARE_SUCCESS';
const INSTALL_BPP_SOFTWARE_FAIL = 'brandPrivilegeProgram/INSTALL_BPP_SOFTWARE_FAIL';

const LOAD_BPP_DETAILS = 'brandPrivilegeProgram/LOAD_BPP_DETAILS';
const LOAD_BPP_DETAILS_SUCCESS = 'brandPrivilegeProgram/LOAD_BPP_DETAILS_SUCCESS';
const LOAD_BPP_DETAILS_FAIL = 'brandPrivilegeProgram/LOAD_BPP_DETAILS_FAIL';

const LOAD_BRANDS_COLLECTION = 'brandPrivilegeProgram/LOAD_BRANDS_COLLECTION';
const LOAD_BRANDS_COLLECTION_SUCCESS = 'brandPrivilegeProgram/LOAD_BRANDS_COLLECTION_SUCCESS';
const LOAD_BRANDS_COLLECTION_FAIL = 'brandPrivilegeProgram/LOAD_BRANDS_COLLECTION_FAIL';

function getBrandsCollection(brandsCollection){
  if(brandsCollection && brandsCollection.data)
    return brandsCollection.data;
  return null;
}

export default function brandPrivilegeProgram(state = {}, action = {}) {
  switch (action.type) {

    case INSTALL_BPP_SOFTWARE:
      return {
        ...state,
        bppSoftwareInstalling: true
      };
    case INSTALL_BPP_SOFTWARE_SUCCESS:
      return {
        ...state,
        bppSoftwareInstalling: false,
        bppSoftwareInstalled: true,
        bppSoftware: action.result,
      };
    case INSTALL_BPP_SOFTWARE_FAIL:
      return {
        ...state,
        bppSoftwareInstalling: false,
        bppSoftwareInstalled: true,
        bppSoftwareInstallError: action.error
      };

    case LOAD_BPP_DETAILS:
      return {
        ...state,
        bppDetailsLoading: true,
        bppDetailsLoaded: false
      };
    case LOAD_BPP_DETAILS_SUCCESS:
      return {
        ...state,
        bppDetailsLoading: false,
        bppDetailsLoaded: true,
        bppDetails: action.result,
      };
    case LOAD_BPP_DETAILS_FAIL:
      return {
        ...state,
        bppDetailsLoading: false,
        bppDetailsLoaded: true,
        bppDetailsLoadError: action.error
      };

    case LOAD_BRANDS_COLLECTION:
      return {
        ...state,
        brandsCollectionLoading: true,
        brandsCollectionLoaded: false
      };
    case LOAD_BRANDS_COLLECTION_SUCCESS:
      return {
        ...state,
        brandsCollectionLoading: false,
        brandsCollectionLoaded: true,
        brandsCollection: getBrandsCollection(action.result),
      };
    case LOAD_BRANDS_COLLECTION_FAIL:
      return {
        ...state,
        brandsCollectionLoading: false,
        brandsCollectionLoaded: true,
        brandsCollectionLoadError: action.error
      };


    default:
      return state;
  }
}

export function installBppSoftware(accessToken) {
  return {
    types: [INSTALL_BPP_SOFTWARE, INSTALL_BPP_SOFTWARE_SUCCESS, INSTALL_BPP_SOFTWARE_FAIL],
    promise: (client) => client.default.post('/installBppSoftware', {
      data: {
        accessToken
      }
    })
  };
}

export function loadBppDetails(accessToken) {
  return {
    types: [LOAD_BPP_DETAILS, LOAD_BPP_DETAILS_SUCCESS, LOAD_BPP_DETAILS_FAIL],
    promise: (client) => client.default.post('/loadBppDetails', {
      data: {
        accessToken
      }
    })
  };
}

export function getMagicBrandsCollection(accessToken) {
  return {
    types: [LOAD_BRANDS_COLLECTION, LOAD_BRANDS_COLLECTION_SUCCESS, LOAD_BRANDS_COLLECTION_FAIL],
    promise: (client) => client.default.post('/getMagicBrandsCollection', {
      data: {
        accessToken
      }
    })
  };
}