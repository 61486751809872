const initialState={
    popup: false,
}
const TOGGLE_SMS = 'smsCampaign/TOGGLE_SMS'
export default (state=initialState, action) => {
    switch(action.type){
        case TOGGLE_SMS:
            return {
                ...state,
                popup: !state.popup
            }
        default:
            return state
    }
}

export function toggleSmsPopup(){
    return {
        type:TOGGLE_SMS
    }
}