import React from 'react'
import IphoneWhite from '../../images/iphoneWhite.svg'
import FeedCardBg from '../../images/feedCard.svg'
import BannerCardBg from '../../images/bannerCard.svg'
import FeedCard from './FeedCard'
import { categoryName, promotionCardDescription } from '../../constants'

const Iphone = (props) => {
    const category = categoryName[props.user.categoryId];
    const FeedCardLogo = require(`../../images/feed_card_logo_${category}.png`);
    const BannerCardLogo = require(`../../images/banner_card_logo_${category}.png`);
    const description = promotionCardDescription[category];
    // let description = props.promotionType === 'banner' ? "Save up to 20% Bakes and cakes you can’t resist" : "Your kinda October Fest Flat 50% off on bars & restaurants"
    const feedcardDimensionsClass = props.promotionType === 'banner' ? 'feedcardDimensions bannerDimension' : 'feedcardDimensions'
  return (
    <div className='fullImage' style={{ backgroundImage: `url(${IphoneWhite})`}}>
         <div className='feedcardOvertop' style={{display:'flex', justifyContent:'center'}}>
             <div className={feedcardDimensionsClass}>
                 <FeedCard bgImageComponent={props.promotionType === 'banner' ? BannerCardBg : FeedCardBg}
                 description={description}
                 cardImage={props.promotionType === 'banner' ? BannerCardLogo : FeedCardLogo}
                 logo={props.user && props.user.profilePic}/>
             </div>
         </div>
    </div>
  )
}

export default Iphone