import React, {Component} from 'react';
import {connect} from 'react-redux';
import {sendOtpByCall, sendOtpBySms, verifyOtp as ownVerifyOtp} from '../../redux/modules/sendOtp';
import {getMembers} from '../../redux/modules/myteam.js';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import moment from 'moment';
import {push} from 'react-router-redux';
import './SendOtp.css'
import {getUserTypeFromDesignation, constructEventNameForBq, sendEvent} from '../../helpers/Helpers'
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
class SendOtpNew extends Component {
    constructor(props){
      super(props);
      this.state = {
        otpSentTo: null,
        otpSent: false,
        showPhoneSelect: true,
        sendOtpDiv: false,
        showMemberDialog: false,
        resendOtpDiv: false,
        verifyOtpDiv: false,
        otpEntered: false,
        maxTries: this.props.maxTries || 3,
        colorStatus:"",
        backSpace: false,
        ctaDisabled: this.props.ownSendingOtp || this.props.ownVerifyingOtp || this.props.verifyingOtp || (typeof(this.props.ctaEnabled) === 'boolean' && !this.props.ctaEnabled),
        phoneNumbers: this.props.phoneNumbers,
        sendSixDigitOtp: true
      }
      this.otpDigits = this.props.otpDigits || 6
      this.eventObj = {
        useCase: 'view',
        pageName: 'sendNewOtp'
        }; 
    }

    setOtpSendUserDesignation = (methodType = "portal") => {
      const designation = this.refs.phone && this.refs.phone.value && this.phoneOwnerType && this.phoneOwnerType[this.refs.phone.value] ||  ""
      window.localStorage.setItem('setOtpSendUserDesignation', `${designation}_${methodType}`);
    }

    sendOtpByCall = ()=>{
      this.sendUIGGenericEvents(`otp_call_clicked`, "new_otp_flow", {phoneNo:this.state.otpSentTo})
      this.resetColorStatus()
      this.setOtpSendUserDesignation("call")
      this.props.sendOtpByCall(this.props.user.accessToken, this.refs.phone.value);
      this.setState({otpSentTo: this.refs.phone.value});
    }
    //this.props.additionalPayload, it just used to pass any additional object you want to pass in sendOtpBySms wrapper(currently used only in VerificaionFlow.js)  
    sendOtpBySms = ()=>{
      this.sendUIGGenericEvents(`otp_sms_clicked`, "new_otp_flow", {phoneNo:this.state.otpSentTo})
      this.resetColorStatus();
      this.setOtpSendUserDesignation("sms")
      this.props.sendOtpBySms(this.props.user.accessToken, this.refs.phone.value, this.props.otpUseCaseIdentifier, this.props.additionalPayload, this.state.sendSixDigitOtp);
      this.setState({otpSentTo: this.refs.phone.value});
      if(this.props.shouldPersistPhoneNumber === true){
        this.props.persistPhoneNumber(this.refs.phone.value);
      }
    }
    
    verifyOtp = (e) => {
      e.preventDefault()
      this.resetColorStatus()
      let otp=Array.from(document.querySelectorAll("#otp-select .inputs input")).map(ele=>ele.value).slice(0,this.otpDigits).join("")
      this.sendUIGGenericEvents(`submit_clicked`, "new_otp_flow",{phoneNo:this.state.otpSentTo});
      if(this.props.willVerifiyInsideWrapper)
          this.props.onVerify(this.state.otpSentTo, otp);
      else if(this.props.verifyOtp && typeof(this.props.verifyOtp) === 'function')
        this.props.verifyOtp(this.state.otpSentTo, otp);
      else
        this.props.ownVerifyOtp(this.props.user.accessToken, otp, this.state.otpSentTo);
    }

    resetColorStatus = ()=>{
      this.setState({colorStatus:""})
    }

    successColorStatus = ()=>{
      this.setState({colorStatus:"success"})
    }

    failColorStatus = ()=>{
      this.setState({colorStatus:'error'})
    }

    initialisePhones = (members, allowedUserTypes)=>{
      let filteredPhoneNumbers = members.filter(member=>allowedUserTypes.includes(getUserTypeFromDesignation( member.designation)) && member.mobileNumber)
      let phoneNumbers = filteredPhoneNumbers.map(allowedMember=>allowedMember.mobileNumber)
      phoneNumbers = this.props.extraPhoneNumber && this.props.extraPhoneNumber.mobileNumber ? phoneNumbers.concat(this.props.extraPhoneNumber.mobileNumber) : phoneNumbers

      this.setState({phoneNumbers})
      this.phoneOwnerType = filteredPhoneNumbers.reduce((agg, curr, idx)=>{
        agg[curr.mobileNumber] = curr.designation
        return agg
      },{})
    }

    componentDidUpdate(prevProps, prevState){
      if((prevProps.sendingOtp && !this.props.sendingOtp) || (prevProps.ownSendingOtp && !this.props.ownSendingOtp)){
        if(this.props.error || this.props.sendOtpError){
          let errorObj = this.props.error || this.props.sendOtpError
          toast.error(errorObj && errorObj.message || "something went wrong, please try again later")
          this.failColorStatus();
        }
        else{
          this.successColorStatus();
          this.clock = moment("00:30","mm:ss").format("mm:ss")
          this.setState({otpEntered:false,verifyOtpDiv: true, timer: true, resendOtpDiv: false,sendOtpDiv: false},this.props.resendOtp ? this.startTimer: ()=>{})
          Array.from(document.querySelectorAll("#otp-select .inputs input")).map(ele=>ele.value="");
        }
      }

      if((prevProps.verifyingOtp && !this.props.verifyingOtp) || (prevProps.ownVerifyingOtp && !this.props.ownVerifyingOtp)){
        if(this.props.verifyOtpError || this.props.ownVerifyOtpError){
            let errorObj = this.props.verifyOtpError || this.props.ownVerifyOtpError
            toast.error(errorObj && errorObj.message || "something went wrong, please try again later")
            this.failColorStatus();
            this.setState({maxTries:this.state.maxTries-1});
        }
        else {
          this.successColorStatus()
          this.props.onVerify(this.state.otpSentTo);
        }
      }

      if(prevState.maxTries === 1 && this.state.maxTries === 0)
        this.props.onReject()
      
      if(prevProps.ctaEnabled !== this.props.ctaEnabled)
        this.setState({ctaDisabled : !this.props.ctaEnabled})

      if(prevProps.loadingMembers && !this.props.loadingMembers && Array.isArray(this.props.members) && this.props.allowedUserTypes){
        this.initialisePhones(this.props.members, this.props.allowedUserTypes)
      }

      if(!prevState.phoneNumbers && Array.isArray(this.state.phoneNumbers)){
        if(this.state.phoneNumbers.length > 0)
          this.setState({sendOtpDiv: true})
        else
          this.setState({showMemberDialog: true})
      }
    }

    startTimer = ()=>{
      this.sendUIGGenericEvents(`timer_started`, "new_otp_flow") 
      let x = setInterval(()=>{
        this.clock = moment(this.clock,'mm:ss').subtract(1,'second').format("mm:ss")
        if(this.clock === "00:00") 
          {
            this.setState({resendOtpDiv: true, timer: false})
            clearInterval(x);
            this.sendUIGGenericEvents(`timer_end`, "new_otp_flow")
          }
        else{
          if(document.getElementById("timer")) document.getElementById("timer").innerHTML = "Resend in "+this.clock;
        }
      }, 1000)
    }

    captureOtp = (e, idx) => {
      if(e.target.value){
        document.querySelectorAll("#otp-select input")[idx+1].focus()
        if(idx+1 === this.otpDigits) this.setState({otpEntered:true})
      }
      else{
          if(idx >= 1 ){
            if(this.state.backSpace){
              this.setState({backSpace:false});
              document.querySelectorAll("#otp-select input")[idx-1].focus()
            }
            else{
              this.setState({backSpace:true})
            }
          }
      }
    e.preventDefault();
    }

    componentDidMount(){
      this.sendUIGGenericEvents(`landing`, "new_otp_flow", {phoneNo:this.state.otpSentTo})
      if(!isNaN(this.props.sendOtpTo)){
        if(this.props.sendOtpAction && typeof(this.props.sendOtpAction) === 'function'){
          this.props.sendOtpAction(this.state.phoneNumbers[this.props.sendOtpTo])
        }
        else  this.props.sendOtpBySms(this.props.user.accessToken, this.state.phoneNumbers[this.props.sendOtpTo], this.props.otpUseCaseIdentifier, null, this.state.sendSixDigitOtp)
        this.setState({otpSentTo:this.state.phoneNumbers[this.props.sendOtpTo]})
      }

      if(Array.isArray(this.state.phoneNumbers) && this.state.phoneNumbers.length)
        this.setState({sendOtpDiv: true})
      if(this.props.members){
        if(this.props.allowedUserTypes){
          this.initialisePhones(this.props.members, this.props.allowedUserTypes)
        }
      }
      else if(this.props.user && this.props.allowedUserTypes){
        this.props.getMembers(this.props.user.accessToken, this.props.additionalPayload && this.props.additionalPayload.useCase === "upsellVerification")
      }
      
    }
    sendUIGGenericEvents = (eventName, useCase = "", extraFeilds) => {
      try{
       this.eventObj.useCase = useCase ? useCase : this.eventObj.useCase
       this.eventName = constructEventNameForBq(this.eventObj, eventName);
     
       sendEvent({
           event: this.eventName,
           actor_id: this.props.user.merchantId,
           actor_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
           subject_merchant_id: this.props.user.merchantId,
           user_type: this.props.user.userType == "ADMIN" ? "ADMIN" : "MERCHANT",
           datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
           timestamp: Date.now(),
           device: window.innerWidth > 768 ? 'desktop' :'mobile',
           entity_name: 'merchant_dashboard',
           page_type: "merchant_panel_new_otp_flow",
           extraFeilds: extraFeilds ? JSON.stringify(extraFeilds) : ""
         });
       }catch(err){}
     }
  
    render(){
      if(this.props.onVerifyLoading)
         return (<div className="otp-div">
                  <Loader/>
                </div>)
      return (
        
            <div className="otp-div">
            
            {(this.state.sendOtpDiv || this.state.resendOtpDiv) && 
              <div style={{width:'100%', textAlign:'center'}}>
                <div>
                  {this.state.resendOtpDiv ? "Didnt receive your OTP?" : 'A verification OTP will be sent to this number'}
                </div>
                <div className='phone-selector-wrapper'>
                <select ref='phone' className="phone form-control">
                    {Array.isArray(this.state.phoneNumbers) && Array.from(new Set(this.state.phoneNumbers)).map(phone=>
                      <option className="phone" key={phone} value={phone}>{phone}{this.phoneOwnerType && this.phoneOwnerType[phone] ? " - "+this.phoneOwnerType[phone] : ''}</option>
                    )}
                </select>
                </div>
                  <div className="send-otp-buttons-wrapper">
                    <button id="send-otp"
                    style={this.state.sendOtpDiv ? {margin: "0 10%"} : {}}
                    disabled={this.state.ctaDisabled}
                    className={`large-btn large-btn2 ${this.props.otpSending ? 'loading' : ''} ${this.state.resendOtpDiv ? 'anchor' :''}`} 
                    onClick={this.props.sendOtpAction || this.sendOtpBySms}>
                    {this.props.ownSendingOtp ? 'Sending OTP' : this.state.sendOtpDiv ? 'Send OTP' : 'Resend OTP By SMS'}</button>
                  {this.state.resendOtpDiv && <button id="send-otp2"
                    disabled={this.state.ctaDisabled}
                    className={`large-btn large-btn2 anchor ${this.props.otpSending ? 'loading' : ''}`} 
                    onClick={this.props.sendOtpAction || this.sendOtpByCall}>
                    {this.props.ownSendingOtp ? 'Sending OTP' : 'Resend OTP By Call'}
                  </button>}
                </div>
              </div>}
              {this.state.showMemberDialog && <Dialog
                title="No phone numbers found"
                actions={[
                  <FlatButton
                    label="Cancel"
                    primary={true}
                    onClick={this.props.onReject}
                  />,
                  <RaisedButton 
                  label="Add Contacts"
                  onClick={()=>{this.props.push('/myteam')}}
                  color="primary" />
                ]}
                modal={false}
                open={this.state.showMemberDialog}
                onRequestClose={this.props.onReject}>
                Your contact details are not updated on magicpin. Please update your contact details by visiting My Team page.
              </Dialog>
            }
                  {
                  this.state.verifyOtpDiv &&
                  <div>
                    <div className="clock" style={{color:'rgba(0,0,0,0.54', fontSize:'14px'}}>
                      {this.state.timer && `OTP sent to ${this.state.otpSentTo}`}
                    </div>
                    <div style={{color:'rgba(0,0,0,0.54)', fontSize:'14px'}}>
                      {!this.state.resendOtpDiv &&
                        <span id="timer"></span>
                      }
                    </div>
                    <div>
                      <form id="otp-select" action="" onSubmit={this.verifyOtp}>
                        <div className="inputs">
                          {Array.from({length:this.otpDigits}).map((ele, idx)=>
                            <input key={idx} className={`${this.state.colorStatus} no-spinners`} required pattern="[0-9]" onKeyUp={(e)=>this.captureOtp(e, idx)} type="number" maxLength="1" size="1"/>
                          )}
                        </div>
                        <span className="hint-text" style={{margin:'0 auto', width:'100%'}}> Enter OTP here</span>
                        
                          <input 
                          type="submit"
                          value={`${this.props.verifyingOtp || this.props.ownVerifyingOtp || this.props.disableSendOtpBtn ? 'submitting' : 'submit'}`} 
                          className="submit large-btn large-btn2" 
                          style={{margin:'0px auto', cursor: !this.state.otpEntered || this.props.disableSendOtpBtn? 'not-allowed' : 'pointer'}} 
                          disabled={!this.state.otpEntered || this.props.disableSendOtpBtn}
                        />
                      </form>
                    </div>
                  </div>
                }
                {this.props.onSkip && <div style={{cursor:'pointer', color:'#458eff', margin:'5px'}} onClick={this.props.onSkip}>{this.props.skipMessage ? this.props.skipMessage : 'skip'}</div>}
            </div>
      )
    }
  }

SendOtpNew.propTypes={
    phoneNumbers : PropTypes.array, // array of phone numbers
    maxTries : PropTypes.number, // maximum retries
    resendOtp: PropTypes.bool,   // allow resend
    resendOtpInterval: PropTypes.number, // show resend option after what time
    sendOtpTo:PropTypes.number, // sendOtp to this index as soon as component mounts
    onSkip:PropTypes.func,      // skip this flow, other than onVerify and onReject
    skipMessage: PropTypes.string, // skipmessage is shown beneath main CTA
    onReject: PropTypes.func.isRequired, // call when otp verification failed
    onVerify: PropTypes.func.isRequired, // call when verification completes
    rootClass: PropTypes.string, // used for styling
    ctaEnabled: PropTypes.bool,  // controls whether "SEND OTP" CTA is enabled
    
    verifyOtp: PropTypes.func, // parents own function for veriification
    verifyingOtp: PropTypes.bool, // used to check verification state when parent verifies by his own, required when verifyOtp is passed
    otpVerified: PropTypes.bool,  
    verifyOtpError: PropTypes.object,

    sendOtpAction: PropTypes.func,
    sendingOtp: PropTypes.bool,
    sentOtp: PropTypes.bool,
    sendOtpError: PropTypes.object,
    
    otpDigits: PropTypes.number, // number of digits in OTP
    allowedUserTypes: PropTypes.array,
    }

export default connect((state, ownProps) => ({
    error: state.sendOtp.error,
    ownOtpSent: state.sendOtp.otpSent,
    ownSendingOtp: state.sendOtp.otpSending,
    
    ownOtpVerified: state.sendOtp.otpVerified,
    ownVerifyingOtp: state.sendOtp.verifyingOtp,
    ownVerifyOtpError: state.sendOtp.verifyOtpError,

    loadingMembers: state.myteam.loadingMembers,
    members: state.myteam.members,
    membersLoaded: state.myteam.membersLoaded,
    allowedUserTypes: ownProps.allowedUserTypes
  }), {
  ownVerifyOtp,
  sendOtpByCall,
  sendOtpBySms,
  push,
  getMembers,
})
(SendOtpNew)
