import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';

const styles = {
  titleStyle: {
    backgroundColor: '#338aed',
    fontSize: '16px',
    color: '#fff',
    padding: '16px',
    paddingTop: '8px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },

  contentStyle: {
    borderRadius : '8px',
    width: '400px',
  },

  closeStyle: {
    position: 'absolute',
    top: '4px',
    right: '8px',
    width: '16px',
    height: '16px'
  },

  bodyStyle: {
    padding: '16px',
  },

  paperStyle: {
    style: {
        borderRadius: '8px',
    }
  }
}

export default class BrandPopup extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: !!this.props.open,
    }
  }

  render(){

    return (
      <div>
        <Dialog
          title="Store Cannot be Claimed"
          titleStyle = {styles.titleStyle}
          paperProps = {styles.paperStyle}
          contentStyle = {styles.contentStyle}
          bodyStyle = {styles.bodyStyle}
          modal={false}
          open={this.props.open}
          onRequestClose={this.handleClose} >
          <img src="images/close.svg" onClick={this.props.handleClose} style={styles.closeStyle}/>
          <p>Oops! seems this outlet cannot be claimed. Please contact us at <a href={'tel:7510175101'}>75101 75101</a> or email to <a href={'mailto:partner@magicpin.in'}>partner@magicpin.in</a></p>
        </Dialog>
    </div>
    )
  }
}
