import { relative } from "path";
import { DEFAULT_TEAM_EMAILS, userTypes } from "../../constants";
import {updateOrderStatusCommon} from '../../merchant-app-portal/Redux/MagicOrder';
import { func } from "prop-types";
import moment from 'moment';
import { getUserDetail } from "../../helpers/Helpers";
const packageJson = require('../../../package.json');

const appVersion = packageJson.version

const saltFromAuth = window.localStorage.getItem('saltFromAuth');

const LOAD = 'newMerchantLanding/LOAD';
const LOAD_SUCCESS = 'newMerchantLanding/LOAD_SUCCESS';
const LOAD_FAIL = 'newMerchantLanding/LOAD_FAIL';

const MAIL_LOAD = 'newMerchantLanding/MAIL_LOAD';
const MAIL_LOAD_SUCCESS = 'newMerchantLanding/MAIL_LOAD_SUCCESS';
const MAIL_LOAD_FAIL = 'newMerchantLanding/MAIL_LOAD_FAIL';

const CUSTOMER_LOAD = 'newMerchantLanding/CUSTOMER_LOAD';
const CUSTOMER_LOAD_SUCCESS = 'newMerchantLanding/CUSTOMER_LOAD_SUCCESS';
const CUSTOMER_LOAD_FAIL = 'newMerchantLanding/CUSTOMER_LOAD_FAIL';

const POSTS_LOAD = 'newMerchantLanding/POSTS_LOAD';
const POSTS_LOAD_SUCCESS = 'newMerchantLanding/POSTS_LOAD_SUCCESS';
const POSTS_LOAD_FAIL = 'newMerchantLanding/POSTS_LOAD_FAIL';

const COMPETITOR_MERCHANT_LOADING = 'newMerchantLanding/COMPETITOR_MERCHANT_LOADING';
const COMPETITOR_MERCHANT_SUCCESS = 'newMerchantLanding/COMPETITOR_MERCHANT_SUCCESS';
const COMPETITOR_MERCHANT_FAILURE = 'newMerchantLanding/COMPETITOR_MERCHANT_FAILURE';

const LOCALITY_LOADING = 'newMerchantLanding/LOCALITY_LOADING';
const LOCALITY_SUCCESS = 'newMerchantLanding/LOCALITY_SUCCESS';
const LOCALITY_FAILURE = 'newMerchantLanding/LOCALITY_FAILURE';

const BANNERS_LOADING = 'newMerchantLanding/BANNER_LOADING';
const BANNERS_SUCCESS = 'newMerchantLanding/BANNERS_SUCCESS';
const BANNERS_FAILURE = 'newMerchantLanding/BANNERS_FAILURE';

const LEADERBOARD_LOADING = 'newMerchantLanding/LEADERBOARD_LOADING';
const LEADERBOARD_SUCCESS = 'newMerchantLanding/LEADERBOARD_SUCCESS';
const LEADERBOARD_FAILURE = 'newMerchantLanding/LEADERBOARD_FAILURE';

const SURVEY_FLAG = 'newMerchantLanding/SURVEY_FLAG';

const UNSUBSRIBE_FAILURE = 'newMerchantLanding/UNSUBSRIBE_FAILURE'
const UNSUBSRIBE_LOADING = 'newMerchantLanding/UNSUBSRIBE_LOADING'
const UNSUBSRIBE_SUCCESS = 'newMerchantLanding/UNSUBSRIBE_SUCCESS'

const INACTIVE_FAILURE = 'newMerchantLanding/INACTIVE_FAILURE'
const INACTIVE_LOADING = 'newMerchantLanding/INACTIVE_LOADING'
const INACTIVE_SUCCESS = 'newMerchantLanding/INACTIVE_SUCCESS' 

const CHEQUE_LOADING = 'newMerchantLanding/CHEQUE_LOADING'
const CHEQUE_SUCCESS = 'newMerchantLanding/CHEQUE_SUCCESS'
const CHEQUE_FAILURE = 'newMerchantLanding/CHEQUE_FAILURE'

const PRIVILEGE_ACTIVATION_LOADING = 'newMerchantLanding/PRIVILEGE_ACTIVATION_LOADING'
const PRIVILEGE_ACTIVATION_SUCCESS = 'newMerchantLanding/PRIVILEGE_ACTIVATION_SUCCESS'
const PRIVILEGE_ACTIVATION_FAILURE = 'newMerchantLanding/PRIVILEGE_ACTIVATION_FAILURE'

const CREATE_PSR_LOADING = 'newMerchantLanding/CREATE_PSR_LOADING'
const CREATE_PSR_SUCCESS = 'newMerchantLanding/CREATE_PSR_SUCCESS'
const CREATE_PSR_FAILURE = 'newMerchantLanding/CREATE_PSR_FAILURE'

const MERCHANT_ORDERS_LOADING = 'newMerchantLanding/MERCHANT_ORDERS_LOADING'
const MERCHANT_ORDERS_LOADED_SUCCESS = 'newMerchantLanding/MERCHANT_ORDERS_LOADED_SUCCESS'
const MERCHANT_ORDERS_LOADED_FAILURE = 'newMerchantLanding/MERCHANT_ORDERS_LOADED_FAILURE'

const MERCHANT_SINGLE_ORDER_LOADING = 'newMerchantLanding/MERCHANT_SINGLE_ORDER_LOADING'
const MERCHANT_SINGLE_ORDER_LOADED_SUCCESS = 'newMerchantLanding/MERCHANT_SINGLE_ORDER_LOADED_SUCCESS'
const MERCHANT_SINGLE_ORDER_LOADED_FAILURE = 'newMerchantLanding/MERCHANT_SINGLE_ORDER_LOADED_FAILURE'

const PARENT_MERCHANT_ORDERS_LOADING = 'newMerchantLanding/PARENT_MERCHANT_ORDERS_LOADING'
const PARENT_MERCHANT_ORDERS_LOADED_SUCCESS = 'newMerchantLanding/PARENT_MERCHANT_ORDERS_LOADED_SUCCESS'
const PARENT_MERCHANT_ORDERS_LOADED_FAILURE = 'newMerchantLanding/PARENT_MERCHANT_ORDERS_LOADED_FAILURE'

const PARENT_MERCHANT_ALL_ORDERS_LOADING = 'newMerchantLanding/PARENT_MERCHANT_ALL_ORDERS_LOADING'
const PARENT_MERCHANT_ALL_ORDERS_LOADED_SUCCESS = 'newMerchantLanding/PARENT_MERCHANT_ALL_ORDERS_LOADED_SUCCESS'
const PARENT_MERCHANT_ALL_ORDERS_LOADED_FAILURE = 'newMerchantLanding/PARENT_MERCHANT_ALL_ORDERS_LOADED_FAILURE'

const PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADING = 'newMerchantLanding/PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADING'
const PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_SUCCESS = 'newMerchantLanding/PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_SUCCESS'
const PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_FAILURE = 'newMerchantLanding/PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_FAILURE'

const UPDATE_ORDERS_VALUE_LOADING = 'newMerchantLanding/UPDATE_ORDERS_VALUE_LOADING'
const UPDATE_ORDERS_VALUE_SUCCESS = 'newMerchantLanding/UPDATE_ORDERS_VALUE_SUCCESS'
const UPDATE_ORDERS_VALUE_FAILURE = 'newMerchantLanding/UPDATE_ORDERS_VALUE_FAILURE'

const UPDATE_ORDERS_STATUS_LOADING = 'newMerchantLanding/UPDATE_ORDERS_STATUS_LOADING'
const UPDATE_ORDERS_STATUS_SUCCESS = 'newMerchantLanding/UPDATE_ORDERS_STATUS_SUCCESS'
const UPDATE_ORDERS_STATUS_FAILURE = 'newMerchantLanding/UPDATE_ORDERS_STATUS_FAILURE'

const UPDATE_TRACK_LINK_LOADING = 'newMerchantLanding/UPDATE_TRACK_LINK_LOADING'
const UPDATE_TRACK_LINK_SUCCESS = 'newMerchantLanding/UPDATE_TRACK_LINK_SUCCESS'
const UPDATE_TRACK_LINK_FAILURE = 'newMerchantLanding/UPDATE_TRACK_LINK_FAILURE'

const UPDATE_AGENT_PHONE_NUMBER_LOADING = 'newMerchantLanding/UPDATE_AGENT_PHONE_NUMBER_LOADING'
const UPDATE_AGENT_PHONE_NUMBER_SUCCESS = 'newMerchantLanding/UPDATE_AGENT_PHONE_NUMBER_SUCCESS'
const UPDATE_AGENT_PHONE_NUMBER_FAILURE = 'newMerchantLanding/UPDATE_AGENT_PHONE_NUMBER_FAILURE'

const VERIFY_OTP_LOADING = 'newMerchantLanding/VERIFY_OTP_LOADING'
const VERIFY_OTP_SUCCESS = 'newMerchantLanding/VERIFY_OTP_SUCCESS'
const VERIFY_OTP_FAILURE = 'newMerchantLanding/VERIFY_OTP_FAILURE'

const GET_SEARCH_RESULTS = 'newMerchantLanding/GET_SEARCH_RESULTS'
const GET_SEARCH_RESULTS_SUCCESS = 'newMerchantLanding/GET_SEARCH_RESULTS_SUCCESS'
const GET_SEARCH_RESULTS_FAILURE = 'newMerchantLanding/GET_SEARCH_RESULTS_FAIL'

const MERCHANT_DELIVERY_DETAILS = 'newMerchantLanding/MERCHANT_DELIVERY_DETAILS'
const MERCHANT_DELIVERY_DETAILS_SUCCESS = 'newMerchantLanding/MERCHANT_DELIVERY_DETAILS_SUCCESS'
const MERCHANT_DELIVERY_DETAILS_FAILURE = 'newMerchantLanding/MERCHANT_DELIVERY_DETAILS_FAILURE'

const SET_MERCHANT_DELIVERY_DETAILS = 'newMerchantLanding/SET_MERCHANT_DELIVERY_DETAILS'
const SET_MERCHANT_DELIVERY_DETAILS_SUCCESS = 'newMerchantLanding/SET_MERCHANT_DELIVERY_DETAILS_SUCCESS'
const SET_MERCHANT_DELIVERY_DETAILS_FAILURE = 'newMerchantLanding/SET_MERCHANT_DELIVERY_DETAILS_FAILURE'

const BILL_UPLOAD = 'newMerchantLanding/BILL_UPLOAD'
const BILL_UPLOAD_SUCCESS = 'newMerchantLanding/BILL_UPLOAD_SUCCESS'
const BILL_UPLOAD_FAIL = 'newMerchantLanding/BILL_UPLOAD_FAIL'

const GET_ORDER_STATS_LOADING ='newMerchantLanding/GET_ORDER_STATS_LOADING'
const GET_ORDER_STATS_SUCCESS ='newMerchantLanding/GET_ORDER_STATS_SUCCESS'
const GET_ORDER_STATS_FAILURE='newMerchantLanding/GET_ORDER_STATS_FAILURE'

const MERCHANT_PAGINATED_ORDERS_LOADING = 'newMerchantLanding/MERCHANT_PAGINATED_ORDERS_LOADING'
const MERCHANT_PAGINATED_ORDERS_LOADED_SUCCESS = 'newMerchantLanding/MERCHANT_PAGINATED_ORDERS_LOADED_SUCCESS'
const MERCHANT_PAGINATED_ORDERS_LOADED_FAILURE = 'newMerchantLanding/MERCHANT_PAGINATED_ORDERS_LOADED_FAILURE'

const SUGGESTED_PRICE_LOADING = 'newMerchantLanding/SUGGESTED_PRICE_LOADING'
const SUGGESTED_PRICE_SUCCESS = 'newMerchantLanding/SUGGESTED_PRICE_SUCCESS'
const SUGGESTED_PRICE_FAILURE = 'newMerchantLanding/SUGGESTED_PRICE_FAILURE'

const UPLOAD_CSV = 'newMerchantLanding/UPLOAD_CSV';
const CSV_UPLOADED = 'newMerchantLanding/CSV_UPLOADED'
const CSV_FAILED = 'newMerchantLanding/CSV_FAILED'

const DELIVERY_PREFERENCE = 'newMerchantLanding/DELIVERY_PREFERENCE';
const DELIVERY_PREFERENCE_SUCCESS = 'newMerchantLanding/DELIVERY_PREFERENCE_SUCCESS'
const DELIVERY_PREFERENCE_FAILED = 'newMerchantLanding/DELIVERY_PREFERENCE_FAILED'

const LOAD_CHAT_HISTORY = 'newMerchantLanding/LOAD_CHAT_HISTORY'
const CHAT_HISTORY_DATA = 'newMerchantLanding/CHAT_HISTORY_DATA'

const OPEN_NEW_WEB_SOCKET = 'newMerchantLanding/OPEN_NEW_WEB_SOCKET'

const CHAT_IMAGE_UPLOADING = 'newMerchantLanding/CHAT_IMAGE_UPLOADING'
const CHAT_IMAGE_UPLOADED_SUCCESS = 'newMerchantLanding/CHAT_IMAGE_UPLOADED_SUCCESS'
const CHAT_IMAGE_UPLOADED_ERROR = 'newMerchantLanding/CHAT_IMAGE_UPLOADED_ERROR'

const GET_DELIVERY_CONFIGURATOR = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR'
const GET_DELIVERY_CONFIGURATOR_SUCCESS = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR_SUCCESS'
const GET_DELIVERY_CONFIGURATOR_ERROR = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR_ERROR'

const GET_DELIVERY_CONFIGURATOR_BY_PARENT = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR_BY_PARENT'
const GET_DELIVERY_CONFIGURATOR_SUCCESS_BY_PARENT = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR_SUCCESS_BY_PARENT'
const GET_DELIVERY_CONFIGURATOR_ERROR_BY_PARENT = 'newMerchantLanding/GET_DELIVERY_CONFIGURATOR_ERROR_BY_PARENT'

const GET_SINGLE_ORDER_DATA_BY_PARENT = "newMerchantLanding/GET_SINGLE_ORDER_DATA_BY_PARENT"
const GET_SINGLE_ORDER_DATA_BY_PARENT_SUCCESS = "newMerchantLanding/GET_SINGLE_ORDER_DATA_BY_PARENT_SUCCESS"
const GET_SINGLE_ORDER_DATA_BY_PARENT_ERROR = "newMerchantLanding/GET_SINGLE_ORDER_DATA_BY_PARENT_ERROR"

const SET_DELIVERY_CONFIGURATOR = 'newMerchantLanding/SET_DELIVERY_CONFIGURATOR'
const SET_DELIVERY_CONFIGURATOR_SUCCESS = 'newMerchantLanding/SET_DELIVERY_CONFIGURATOR_SUCCESS'
const SET_DELIVERY_CONFIGURATOR_ERROR = 'newMerchantLanding/SET_DELIVERY_CONFIGURATOR_ERROR'

const ORDER_HERE_ALIAS_LOADING = 'newMerchantLanding/ORDER_HERE_ALIAS_LOADING'
const ORDER_HERE_ALIAS_SUCCESS = 'newMerchantLanding/ORDER_HERE_ALIAS_SUCCESS'
const ORDER_HERE_ALIAS_FAILED = 'newMerchantLanding/ORDER_HERE_ALIAS_FAILED'

const MAGICSTORE_TEXT_LOADING = 'newMerchantLanding/MAGICSTORE_TEXT_LOADING'
const MAGICSTORE_TEXT_SUCCESS = 'newMerchantLanding/MAGICSTORE_TEXT_SUCCESS'
const MAGICSTORE_TEXT_FAILED = 'newMerchantLanding/MAGICSTORE_TEXT_FAILED'

const SUGGESTED_ALIAS_LOADING = 'newMerchantLanding/SUGGESTED_ALIAS_LOADING'
const SUGGESTED_ALIAS_SUCCESS = 'newMerchantLanding/SUGGESTED_ALIAS_SUCCESS'
const SUGGESTED_ALIAS_FAILED = 'newMerchantLanding/SUGGESTED_ALIAS_FAILED'

const CREATE_ALIAS_LOADING = 'newMerchantLanding/CREATE_ALIAS_LOADING'
const CREATE_ALIAS_SUCCESS = 'newMerchantLanding/CREATE_ALIAS_SUCCESS'
const CREATE_ALIAS_FAILED = 'newMerchantLanding/CREATE_ALIAS_FAILED'

const GET_ALIAS_LOADING = 'newMerchantLanding/GET_ALIAS_LOADING'
const GET_ALIAS_SUCCESS = 'newMerchantLanding/GET_ALIAS_SUCCESS'
const GET_ALIAS_FAILED = 'newMerchantLanding/GET_ALIAS_FAILED'

const CREATE_ORDER_LOADING = 'newMerchantLanding/CREATE_ORDER_LOADING'
const CREATE_ORDER_SUCCESS = 'newMerchantLanding/CREATE_ORDER_SUCCESS'
const CREATE_ORDER_FAILED = 'newMerchantLanding/CREATE_ORDER_FAILED'

const GET_DEL_PARTNERS_LOADING = 'newMerchantLanding/GET_DEL_PARTNERS_LOADING'
const GET_DEL_PARTNERS_SUCCESS = 'newMerchantLanding/GET_DEL_PARTNERS_SUCCESS'
const GET_DEL_PARTNERS_FAILED = 'newMerchantLanding/GET_DEL_PARTNERS_FAILED'

const ACTIVATE_MAGICSTORE_LOADING = 'newMerchantLanding/ACTIVATE_MAGICSTORE_LOADING'
const ACTIVATE_MAGICSTORE_SUCCESS = 'newMerchantLanding/ACTIVATE_MAGICSTORE_SUCCESS'
const ACTIVATE_MAGICSTORE_FAILED = 'newMerchantLanding/ACTIVATE_MAGICSTORE_FAILED'

const IS_MENU_AVAILABLE_LOADING = 'newMerchantLanding/IS_MENU_AVAILABLE_LOADING'
const IS_MENU_AVAILABLE_SUCCESS = 'newMerchantLanding/IS_MENU_AVAILABLE_SUCCESS'
const IS_MENU_AVAILABLE_FAILED = 'newMerchantLanding/IS_MENU_AVAILABLE_FAILED'

const UPLOAD_MENU_LOADING = 'newMerchantLanding/UPLOAD_MENU_LOADING'
const UPLOAD_MENU_SUCCESS = 'newMerchantLanding/UPLOAD_MENU_SUCCESS'
const UPLOAD_MENU_FAILED = 'newMerchantLanding/UPLOAD_MENU_FAILED'

const UPDATE_DELIVERY_PREF_LOADING = 'newMerchantLanding/UPDATE_DELIVERY_PREF_LOADING'
const UPDATE_DELIVERY_PREF_SUCCESS = 'newMerchantLanding/UPDATE_DELIVERY_PREF_SUCCESS'
const UPDATE_DELIVERY_PREF_FAILED = 'newMerchantLanding/UPDATE_DELIVERY_PREF_FAILED'

const MARK_CATALOG_AVAILABLE = 'newMerchantLanding/MARK_CATALOG_AVAILABLE'
const MARK_CATALOG_AVAILABLE_SUCCESS = 'newMerchantLanding/MARK_CATALOG_AVAILABLE_SUCCESS'
const MARK_CATALOG_AVAILABLE_FAILURE = 'newMerchantLanding/MARK_CATALOG_AVAILABLE_FAILURE'

const MARK_CATALOG_UNAVAILABLE = 'newMerchantLanding/MARK_CATALOG_UNAVAILABLE'
const MARK_CATALOG_UNAVAILABLE_SUCCESS = 'newMerchantLanding/MARK_CATALOG_UNAVAILABLE_SUCCESS'
const MARK_CATALOG_UNAVAILABLE_FAILURE = 'newMerchantLanding/MARK_CATALOG_UNAVAILABLE_FAILURE'

const UPDATE_DISCOUNT_BATCH = 'newMerchantLanding/UPDATE_DISCOUNT_BATCH'
const UPDATE_DISCOUNT_BATCH_SUCCESS = 'newMerchantLanding/UPDATE_DISCOUNT_BATCH_SUCCESS'
const UPDATE_DISCOUNT_BATCH_FAILURE = 'newMerchantLanding/UPDATE_DISCOUNT_BATCH_FAILURE'

const UPDATE_DISCOUNT_CATALOG = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG'
const UPDATE_DISCOUNT_CATALOG_SUCCESS = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG_SUCCESS'
const UPDATE_DISCOUNT_CATALOG_FAILURE = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG_FAILURE'

const UPDATE_GST_CATALOG = 'newMerchantLanding/UPDATE_GST_CATALOG'
const UPDATE_GST_CATALOG_SUCCESS = 'newMerchantLanding/UPDATE_GST_CATALOG_SUCCESS'
const UPDATE_GST_CATALOG_FAILURE = 'newMerchantLanding/UPDATE_GST_CATALOG_FAILURE'

const CHECK_IF_CATALOG_EXISTS = 'newMerchantLanding/CHECK_IF_CATALOG_EXISTS'
const CHECK_IF_CATALOG_EXISTS_SUCCESS = 'newMerchantLanding/CHECK_IF_CATALOG_EXISTS_SUCCESS'
const CHECK_IF_CATALOG_EXISTS_FAILURE = 'newMerchantLanding/CHECK_IF_CATALOG_EXISTS_FAILURE'

const GET_ITEM_LIST_CATALOG_LOADING = 'newMerchantLanding/GET_ITEM_LIST_CATALOG_LOADING'
const GET_ITEM_LIST_CATALOG_SUCCESS = 'newMerchantLanding/GET_ITEM_LIST_CATALOG_SUCCESS'
const GET_ITEM_LIST_CATALOG_FAILURE = 'newMerchantLanding/GET_ITEM_LIST_CATALOG_FAILURE'

const SAVE_OR_MODIFY_CATALOG_NAME_LOADING='newMerchantLanding/SAVE_OR_MODIFY_CATALOG_NAME_LOADING'
const SAVE_OR_MODIFY_CATALOG_NAME_SUCCESS='newMerchantLanding/SAVE_OR_MODIFY_CATALOG_NAME_SUCCESS'
const SAVE_OR_MODIFY_CATALOG_NAME_FAILURE='newMerchantLanding/SAVE_OR_MODIFY_CATALOG_NAME_FAILURE'

const GET_MERCHANT_INFO = 'newMerchantLanding/GET_MERCHANT_INFO'
const GET_MERCHANT_INFO_SUCCESS = 'newMerchantLanding/GET_MERCHANT_INFO_SUCCESS'
const GET_MERCHANT_INFO_FAILURE = 'newMerchantLanding/GET_MERCHANT_INFO_FAILURE'

const SAVE_OR_MODIFY_ITEM_LOADING='newMerchantLanding/SAVE_OR_MODIFY_ITEM_LOADING'
const SAVE_OR_MODIFY_ITEM_SUCCESS='newMerchantLanding/SAVE_OR_MODIFY_ITEM_SUCCESS'
const SAVE_OR_MODIFY_ITEM_FAILURE='newMerchantLanding/SAVE_OR_MODIFY_ITEM_FAILURE'

const CREATE_TEST_ORDER_ADMIN_LOADING='newMerchantLanding/CREATE_TEST_ORDER_ADMIN_LOADING'
const CREATE_TEST_ORDER_ADMIN_SUCCESS='newMerchantLanding/CREATE_TEST_ORDER_ADMIN_SUCCESS'
const CREATE_TEST_ORDER_ADMIN_FAILURE='newMerchantLanding/CREATE_TEST_ORDER_ADMIN_FAILURE'

const REPORT_WRONG_MRP_LOADING= 'newMerchantLanding/REPORT_WRONG_MRP_LOADING'
const REPORT_WRONG_MRP_SUCCESS= 'newMerchantLanding/REPORT_WRONG_MRP_SUCCESS'
const REPORT_WRONG_MRP_FAILURE= 'newMerchantLanding/REPORT_WRONG_MRP_FAILURE'

const ENABLE_ORDER_BOOKING_LOADING= 'newMerchantLanding/ENABLE_ORDER_BOOKING_LOADING'
const ENABLE_ORDER_BOOKING_SUCCESS= 'newMerchantLanding/ENABLE_ORDER_BOOKING_SUCCESS'
const ENABLE_ORDER_BOOKING_FAILURE= 'newMerchantLanding/ENABLE_ORDER_BOOKING_FAILURE'

const HAS_ACCEPTED_ORDERS_LOADING= 'newMerchantLanding/HAS_ACCEPTED_ORDERS_LOADING'
const HAS_ACCEPTED_ORDERS_SUCCESS= 'newMerchantLanding/HAS_ACCEPTED_ORDERS_SUCCESS'
const HAS_ACCEPTED_ORDERS_FAILURE= 'newMerchantLanding/HAS_ACCEPTED_ORDERS_FAILURE'

const GET_VERSION_CODE_LOADING= 'newMerchantLanding/GET_VERSION_CODE_LOADING'
const GET_VERSION_CODE_SUCCESS= 'newMerchantLanding/GET_VERSION_CODE_SUCCESS'
const GET_VERSION_CODE_FAILURE= 'newMerchantLanding/GET_VERSION_CODE_FAILURE'

const REPORT_ERROR_TO_METRIC_MONITOR_LOADING = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_LOADING'
const REPORT_ERROR_TO_METRIC_MONITOR_SUCCESS = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_SUCCESS'
const REPORT_ERROR_TO_METRIC_MONITOR_FAILURE = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_FAILURE'

const REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_LOADING = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_LOADING'
const REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_SUCCESS = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_SUCCESS'
const REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_FAILURE = 'newMerchantLanding/REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_FAILURE'

const GET_MERCHANT_BRAND_WISE_LOADING= 'newMerchantLanding/GET_MERCHANT_BRAND_WISE_LOADING'
const GET_MERCHANT_BRAND_WISE_SUCCESS= 'newMerchantLanding/GET_MERCHANT_BRAND_WISE_SUCCESS'
const GET_MERCHANT_BRAND_WISE_FAILURE= 'newMerchantLanding/GET_MERCHANT_BRAND_WISE_FAILURE'

const GET_MERCHANT_REWARD_DAY_WISE_LOADING= 'newMerchantLanding/GET_MERCHANT_REWARD_DAY_WISE_LOADING'
const GET_MERCHANT_REWARD_DAY_WISE_SUCCESS= 'newMerchantLanding/GET_MERCHANT_REWARD_DAY_WISE_SUCCESS'
const GET_MERCHANT_REWARD_DAY_WISE_FAILURE= 'newMerchantLanding/GET_MERCHANT_REWARD_DAY_WISE_FAILURE'

const WRITE_RELIST_REQUEST_LOADING = 'newMerchantLanding/WRITE_RELIST_REQUEST_LOADING'
const WRITE_RELIST_REQUEST_SUCCESS = 'newMerchantLanding/WRITE_RELIST_REQUEST_SUCCESS'
const WRITE_RELIST_REQUEST_FAILURE = 'newMerchantLanding/WRITE_RELIST_REQUEST_FAILURE'

const MERCHANT_RELIST_REQUESTED_LOADING = 'newMerchantLanding/MERCHANT_RELIST_REQUESTED_LOADING'
const MERCHANT_RELIST_REQUESTED_SUCCESS = 'newMerchantLanding/MERCHANT_RELIST_REQUESTED_SUCCESS'
const MERCHANT_RELIST_REQUESTED_FAILURE = 'newMerchantLanding/MERCHANT_RELIST_REQUESTED_FAILURE'

const MERCHANT_RATINGS_VISITS_LOADING = 'newMerchantLanding/MERCHANT_RATINGS_VISITS_LOADING'
const MERCHANT_RATINGS_VISITS_SUCCESS = 'newMerchantLanding/MERCHANT_RATINGS_VISITS_SUCCESS'
const MERCHANT_RATINGS_VISITS_FAILURE = 'newMerchantLanding/MERCHANT_RATINGS_VISITS_FAILURE'

const CALCULATE_PRICE_LOADING = 'newMerchantLanding/CALCULATE_PRICE_LOADING'
const CALCULATE_PRICE_SUCCESS = 'newMerchantLanding/CALCULATE_PRICE_SUCCESS'
const CALCULATE_PRICE_FAILURE = 'newMerchantLanding/CALCULATE_PRICE_FAILURE'

const ACCEPT_PARTIAL_CATALOG_ORDER_LOADING = 'newMerchantLanding/ACCEPT_PARTIAL_CATALOG_ORDER_LOADING'
const ACCEPT_PARTIAL_CATALOG_ORDER_SUCCESS = 'newMerchantLanding/ACCEPT_PARTIAL_CATALOG_ORDER_SUCCESS'
const ACCEPT_PARTIAL_CATALOG_ORDER_FAILURE = 'newMerchantLanding/ACCEPT_PARTIAL_CATALOG_ORDER_FAILURE'

const GET_MERCHANT_TIMINGS_LOADING = 'newMerchantLanding/GET_MERCHANT_TIMINGS_LOADING'
const GET_MERCHANT_TIMINGS_SUCCESS = 'newMerchantLanding/GET_MERCHANT_TIMINGS_SUCCESS'
const GET_MERCHANT_TIMINGS_FAILURE = 'newMerchantLanding/GET_MERCHANT_TIMINGS_FAILURE'

const WHATSAPP_PRODUCER_LOADING = 'newMerchantLanding/WHATSAPP_PRODUCER_LOADING'
const WHATSAPP_PRODUCER_SUCCESS = 'newMerchantLanding/WHATSAPP_PRODUCER_SUCCESS'
const WHATSAPP_PRODUCER_FAILURE = 'newMerchantLanding/WHATSAPP_PRODUCER_FAILURE'

const GET_WHATSAPP_IMAGE_LOADING = 'newMerchantLanding/GET_WHATSAPP_IMAGE_LOADING'
const GET_WHATSAPP_IMAGE_SUCCESS = 'newMerchantLanding/GET_WHATSAPP_IMAGE_SUCCESS'
const GET_WHATSAPP_IMAGE_FAILURE = 'newMerchantLanding/GET_WHATSAPP_IMAGE_FAILURE'

const SHOW_APP_DOWNLOAD_POPUP_LOADING = 'newMerchantLanding/SHOW_APP_DOWNLOAD_POPUP_LOADING'
const SHOW_APP_DOWNLOAD_POPUP_SUCCESS = 'newMerchantLanding/SHOW_APP_DOWNLOAD_POPUP_SUCCESS'
const SHOW_APP_DOWNLOAD_POPUP_FAILURE = 'newMerchantLanding/SHOW_APP_DOWNLOAD_POPUP_FAILURE'

const GET_TOP_BRANDS_LOADING = 'newMerchantLanding/GET_TOP_BRANDS_LOADING'
const GET_TOP_BRANDS_SUCCESS = 'newMerchantLanding/GET_TOP_BRANDS_SUCCESS'
const GET_TOP_BRANDS_FAILURE = 'newMerchantLanding/GET_TOP_BRANDS_FAILURE'

const GET_MISSED_EARNING_LOADING = 'newMerchantLanding/GET_MISSED_EARNING_LOADING'
const GET_MISSED_EARNING_SUCCESS = 'newMerchantLanding/GET_MISSED_EARNING_SUCCESS'
const GET_MISSED_EARNING_FAILURE = 'newMerchantLanding/GET_MISSED_EARNING_FAILURE'

const GET_MERCHANT_TOP_EARNING_LOADING = 'newMerchantLanding/GET_MERCHANT_TOP_EARNING_LOADING'
const GET_MERCHANT_TOP_EARNING_SUCCESS = 'newMerchantLanding/GET_MERCHANT_TOP_EARNING_SUCCESS'
const GET_MERCHANT_TOP_EARNING_FAILURE = 'newMerchantLanding/GET_MERCHANT_TOP_EARNING_FAILURE'

const UPLOAD_MENU_CSV_LOADING = 'newMerchantLanding/UPLOAD_MENU_CSV_LOADING'
const UPLOAD_MENU_CSV_SUCCESS = 'newMerchantLanding/UPLOAD_MENU_CSV_SUCCESS'
const UPLOAD_MENU_CSV_FAILURE = 'newMerchantLanding/UPLOAD_MENU_CSV_FAILURE'

const GET_LOST_BRANDS_LOADING = 'newMerchantLanding/GET_LOST_BRANDS_LOADING'
const GET_LOST_BRANDS_SUCCESS = 'newMerchantLanding/GET_LOST_BRANDS_SUCCESS'
const GET_LOST_BRANDS_FAILURE = 'newMerchantLanding/GET_LOST_BRANDS_FAILURE'

const CREATE_CALLBACK_TICKET_LOADING = 'newMerchantLanding/CREATE_CALLBACK_TICKET_LOADING'
const CREATE_CALLBACK_TICKET_SUCCESS = 'newMerchantLanding/CREATE_CALLBACK_TICKET_SUCCESS'
const CREATE_CALLBACK_TICKET_FAILURE = 'newMerchantLanding/CREATE_CALLBACK_TICKET_FAILURE'

const SEND_ALERT_EMAIL_LOADING = 'newMerchantLanding/SEND_ALERT_EMAIL_LOADING'
const SEND_ALERT_EMAIL_SUCCESS = 'newMerchantLanding/SEND_ALERT_EMAIL_SUCCESS'
const SEND_ALERT_EMAIL_FAILURE = 'newMerchantLanding/SEND_ALERT_EMAIL_FAILURE'

const SEND_DIYCOM_EMAIL_LOADING = 'newMerchantLanding/SEND_DIYCOM_EMAIL_LOADING'
const SEND_DIYCOM_EMAIL_SUCCESS = 'newMerchantLanding/SEND_DIYCOM_EMAIL_SUCCESS'
const SEND_DIYCOM_EMAIL_FAILURE = 'newMerchantLanding/SEND_DIYCOM_EMAIL_FAILURE'

const UPDATE_MERCHANT_HIGHLIGHTS_LOADING = 'newMerchantLanding/UPDATE_MERCHANT_HIGHLIGHTS_LOADING'
const UPDATE_MERCHANT_HIGHLIGHTS_SUCCESS = 'newMerchantLanding/UPDATE_MERCHANT_HIGHLIGHTS_SUCCESS'
const UPDATE_MERCHANT_HIGHLIGHTS_FAILURE = 'newMerchantLanding/UPDATE_MERCHANT_HIGHLIGHTS_FAILURE'

const UPDATE_MERCHANT_TIMINGS_LOADING = 'newMerchantLanding/UPDATE_MERCHANT_TIMINGS_LOADING'
const UPDATE_MERCHANT_TIMINGS_SUCCESS = 'newMerchantLanding/UPDATE_MERCHANT_TIMINGS_SUCCESS'
const UPDATE_MERCHANT_TIMINGS_FAILURE = 'newMerchantLanding/UPDATE_MERCHANT_TIMINGS_FAILURE'

const ENABLE_MAGIC_ORDER_LOADING = 'newMerchantLanding/ENABLE_MAGIC_ORDER_LOADING'
const ENABLE_MAGIC_ORDER_SUCCESS = 'newMerchantLanding/ENABLE_MAGIC_ORDER_SUCCESS'
const ENABLE_MAGIC_ORDER_FAILURE = 'newMerchantLanding/ENABLE_MAGIC_ORDER_FAILURE'

const UPDATE_STORE_NUMBER_LOADING = 'newMerchantLanding/UPDATE_STORE_NUMBER_LOADING'
const UPDATE_STORE_NUMBER_SUCCESS = 'newMerchantLanding/UPDATE_STORE_NUMBER_SUCCESS'
const UPDATE_STORE_NUMBER_FAILURE = 'newMerchantLanding/UPDATE_STORE_NUMBER_FAILURE'

const GET_MERCHANT_COD_LOADING = 'newMerchantLanding/GET_MERCHANT_COD_LOADING'
const GET_MERCHANT_COD_SUCCESS = 'newMerchantLanding/GET_MERCHANT_COD_SUCCESS'
const GET_MERCHANT_COD_FAILURE = 'newMerchantLanding/GET_MERCHANT_COD_FAILURE'

const SET_MERCHANT_COD_LOADING = 'newMerchantLanding/SET_MERCHANT_COD_LOADING'
const SET_MERCHANT_COD_SUCCESS = 'newMerchantLanding/SET_MERCHANT_COD_SUCCESS'
const SET_MERCHANT_COD_FAILURE = 'newMerchantLanding/SET_MERCHANT_COD_FAILURE'

const DID_MERCHANT_CREATE_NY_VOUCHERS_LOADING = 'newMerchantLanding/DID_MERCHANT_CREATE_NY_VOUCHERS_LOADING'
const DID_MERCHANT_CREATE_NY_VOUCHERS_SUCCESSS = 'newMerchantLanding/DID_MERCHANT_CREATE_NY_VOUCHERS_SUCCESSS'
const DID_MERCHANT_CREATE_NY_VOUCHERS_FAILURE = 'newMerchantLanding/DID_MERCHANT_CREATE_NY_VOUCHERS_FAILURE'

const CREATE_NY_VOUCHER_REQUEST_LOADING = 'newMerchantLanding/CREATE_NY_VOUCHER_REQUEST_LOADING'
const CREATE_NY_VOUCHER_REQUEST_SUCCESS = 'newMerchantLanding/CREATE_NY_VOUCHER_REQUEST_SUCCESS'
const CREATE_NY_VOUCHER_REQUEST_FAILURE = 'newMerchantLanding/CREATE_NY_VOUCHER_REQUEST_FAILURE'

const CATALOG_CRAWL_SCHEDULER_LOADING = 'newMerchantLanding/CATALOG_CRAWL_SCHEDULER_LOADING';
const CATALOG_CRAWL_SCHEDULER_SUCCESS = 'newMerchantLanding/CATALOG_CRAWL_SCHEDULER_SUCCESS';
const CATALOG_CRAWL_SCHEDULER_FAILURE = 'newMerchantLanding/CATALOG_CRAWL_SCHEDULER_FAILURE';

const SALT_SYNC_REDIS_CHECK_LOADING = 'newMerchantLanding/SALT_SYNC_REDIS_CHECK_LOADING'
const SALT_SYNC_REDIS_CHECK_SUCCESS = 'newMerchantLanding/SALT_SYNC_REDIS_CHECK_SUCCESS'
const SALT_SYNC_REDIS_CHECK_FAILURE = 'newMerchantLanding/SALT_SYNC_REDIS_CHECK_FAILURE'

const SHOW_CHROME_EXTENSION_BANNER_LOADING = 'newMerchantLanding/SHOW_CHROME_EXTENSION_BANNER_LOADING';
const SHOW_CHROME_EXTENSION_BANNER_SUCCESS = 'newMerchantLanding/SHOW_CHROME_EXTENSION_BANNER_SUCCESS';
const SHOW_CHROME_EXTENSION_BANNER_FAILURE = 'newMerchantLanding/SHOW_CHROME_EXTENSION_BANNER_FAILURE';

const SHOW_ONSURITY_SNACKBAR_LOADING = 'newMerchantLanding/SHOW_ONSURITY_SNACKBAR_LOADING';
const SHOW_ONSURITY_SNACKBAR_SUCCESS = 'newMerchantLanding/SHOW_ONSURITY_SNACKBAR_SUCCESS';
const SHOW_ONSURITY_SNACKBAR_FAILURE = 'newMerchantLanding/SHOW_ONSURITY_SNACKBAR_FAILURE';

const ACCESS_TOKEN_DECRYPTOR_LOADING = 'newMerchantLanding/ACCESS_TOKEN_DECRYPTOR_LOADING';
const ACCESS_TOKEN_DECRYPTOR_SUCCESS = 'newMerchantLanding/ACCESS_TOKEN_DECRYPTOR_SUCCESS';
const ACCESS_TOKEN_DECRYPTOR_FAILURE = 'newMerchantLanding/ACCESS_TOKEN_DECRYPTOR_FAILURE';

const IS_MERCHANT_APP_INSTALLED_LOAD = 'newMerchantLanding/IS_MERCHANT_APP_INSTALLED_LOAD';
const IS_MERCHANT_APP_INSTALLED_SUCCESS = 'newMerchantLanding/IS_MERCHANT_APP_INSTALLED_SUCCESS';
const IS_MERCHANT_APP_INSTALLED_FAIL = 'newMerchantLanding/IS_MERCHANT_APP_INSTALLED_FAIL';

const SET_MERCHANT_FOOD = '/takeAction/writeMxFssai/SET_MERCHANT_FOOD'
const SET_MERCHANT_FOOD_SUCCESS = '/takeAction/writeMxFssai/SET_MERCHANT_FOOD'
const SET_MERCHANT_FOOD_ERROR = '/takeAction/writeMxFssai/SET_MERCHANT_FOOD'

const WRITE_INTERACTIVITY_EVENT_LOADING = 'newMerchantLanding/WRITE_INTERACTIVITY_EVENT_LOADING';
const WRITE_INTERACTIVITY_EVENT_SUCCESS = 'newMerchantLanding/WRITE_INTERACTIVITY_EVENT_SUCCESS';
const WRITE_INTERACTIVITY_EVENT_FAILURE = 'newMerchantLanding/WRITE_INTERACTIVITY_EVENT_FAILURE';

const CANCEL_REASONS_LOADING = "newMerchantLanding/cancel_reasons_loading";
const CANCEL_REASONS_SUCCESS = "newMerchantLanding/cancel_reasons_success";
const CANCEL_REASONS_FAILURE = "newMerchantLanding/cancel_reasons_failure";

const GET_BUZZER_RING_SIGNAL_LOADING = "newMerchantLanding/GET_BUZZER_RING_SIGNAL_LOADING";
const GET_BUZZER_RING_SIGNAL_SUCCESS = "newMerchantLanding/GET_BUZZER_RING_SIGNAL_SUCCESS";
const GET_BUZZER_RING_SIGNAL_ERROR = "newMerchantLanding/GET_BUZZER_RING_SIGNAL_ERROR";

const WRITE_BUZZER_RING_SIGNAL_LOADING = "newMerchantLanding/WRITE_BUZZER_RING_SIGNAL_LOADING";
const WRITE_BUZZER_RING_SIGNAL_SUCCESS = "newMerchantLanding/WRITE_BUZZER_RING_SIGNAL_SUCCESS";
const WRITE_BUZZER_RING_SIGNAL_ERROR = "newMerchantLanding/WRITE_BUZZER_RING_SIGNAL_ERROR";

const GET_ORDER_SUMMARY_FOR_CSV_PANEL_LOADING = "newMerchantLanding/GET_ORDER_SUMMARY_FOR_CSV_PANEL_LOADING";
const GET_ORDER_SUMMARY_FOR_CSV_PANEL_SUCCESS = "newMerchantLanding/GET_ORDER_SUMMARY_FOR_CSV_PANEL_SUCCESS";
const GET_ORDER_SUMMARY_FOR_CSV_PANEL_FAILURE = "newMerchantLanding/GET_ORDER_SUMMARY_FOR_CSV_PANEL_FAILURE";

const UPLOAD_INVENTORY_CSV_LOADING = "newMerchantLanding/UPLOAD_INVENTORY_CSV_LOADING";
const UPLOAD_INVENTORY_CSV_SUCCESS = "newMerchantLanding/UPLOAD_INVENTORY_CSV_SUCCESS";
const UPLOAD_INVENTORY_CSV_FAILURE = "newMerchantLanding/UPLOAD_INVENTORY_CSV_FAILURE";

const UPLOAD_ORDER_STATUS_CSV_LOADING = "newMerchantLanding/UPLOAD_ORDER_STATUS_CSV_LOADING";
const UPLOAD_ORDER_STATUS_CSV_SUCCESS = "newMerchantLanding/UPLOAD_ORDER_STATUS_CSV_SUCCESS";
const UPLOAD_ORDER_STATUS_CSV_FAILURE = "newMerchantLanding/UPLOAD_ORDER_STATUS_CSV_FAILURE";

const GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_LOADING = "newMerchantLanding/GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_LOADING";
const GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_SUCCESS = "newMerchantLanding/GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_SUCCESS";
const GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_FAILURE = "newMerchantLanding/GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_FAILURE";

function getDefaultEndTime(res){
  let defaultEndTimings = undefined
  try {
    defaultEndTimings = !!res.defaultEndTimings ? moment(res.defaultEndTimings).format("YYYY-MM-DD HH:mm:ss"): undefined
  } catch (error) {
    defaultEndTimings = undefined
  }
  return defaultEndTimings
}

const initialState = {
  loading: false,
  loaded: false,
  data : [],
  webSocket: null,

  cancel_reasons_loading: false,
  cancel_reasons_success: false,
  cancel_reasons_failure: false,
  cancel_reasons_list: [],
}

export default function promotionsAround(state = initialState, action = {}) {
  switch (action.type) {
    case CANCEL_REASONS_LOADING:
      return {
        ...state,
        cancel_reasons_loading: true,
        cancel_reasons_success: false,
        cancel_reasons_failure: false,
        cancel_reasons_list: [],
      };
    case CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        cancel_reasons_loading: false,
        cancel_reasons_success: true,
        cancel_reasons_failure: false,
        cancel_reasons_list: !!action.result && action.result.rejectionDetails || [],
      };
    case CANCEL_REASONS_FAILURE:
      return {
        ...state,
        cancel_reasons_loading: false,
        cancel_reasons_success: false,
        cancel_reasons_failure: true,
        cancel_reasons_list: [],
      };
    case LOAD:
      return {
        ...state,
        loading: true,

      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data:  action.result,

      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case REPORT_ERROR_TO_METRIC_MONITOR_LOADING:
        return {
          ...state,
          errorReportLoading: true
        };
    case REPORT_ERROR_TO_METRIC_MONITOR_SUCCESS:
        return {
          ...state,
          errorReportLoading: false,
          errorReportData: action.result,
          errorReportError: null
        };
    case REPORT_ERROR_TO_METRIC_MONITOR_FAILURE:
        return {
          ...state,
          errorReportLoading: false,
          errorReportData: null,
          errorReportError: action.error
        };

    case REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_LOADING:
        return {
          ...state,
          errorReportLoadingUnauth: true
        };
    case REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_SUCCESS:
        return {
          ...state,
          errorReportLoadingUnauth: false,
          errorReportDataUnauth: action.result,
          errorReportErrorUnauth: null
        };
    case REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_FAILURE:
        return {
          ...state,
          errorReportLoadingUnauth: false,
          errorReportDataUnauth: null,
          errorReportErrorUnauth: action.error
        };

    case MAIL_LOAD_SUCCESS:
      return {
        ...state,
        mailed: true,
        mailing: false
      }

    case MAIL_LOAD_FAIL:
      return {
        ...state,
        mailed: true,
        mailing: false
      }
    case MAIL_LOAD:
      return {
        ...state,
        mailed:false,
        mailing:true
      }

    case CUSTOMER_LOAD:
      return {
        ...state,
        customer_loading: true,

      };
    case CUSTOMER_LOAD_SUCCESS:
      return {
        ...state,
        customer_loading: false,
        customer_loaded: true,
        merchantInfo: action.result.merchantInfo,
        summary: action.result.summary,
      };
    case CUSTOMER_LOAD_FAIL:
      return {
        ...state,
        customer_loading: false,
        customer_loaded: false,
        error: action.error
      };
    case POSTS_LOAD:
      return {
        ...state,
        posts_loading: true,

      };
    case POSTS_LOAD_SUCCESS:
      if (action.merchantId){
        return {
          ...state,
          posts_loading: false,
          posts_loaded: true,
          merchantPosts: action.result.posts,
        };
      }
      else
        return {
          ...state,
          posts_loading: false,
          posts_loaded: true,
          fallbackPosts: action.result.posts,
        };
    case POSTS_LOAD_FAIL:
      return {
        ...state,
        posts_loading: false,
        posts_loaded: false,
        error: action.error
      };
    case COMPETITOR_MERCHANT_LOADING:
      return {
        ...state,
        competitor_merchant_loading: true,
        competitor_merchant_loaded: false
      }
    case COMPETITOR_MERCHANT_SUCCESS:
      return {
        ...state,
        competitor_merchants: action.result,
        competitor_merchant_loading: false,
        competitor_merchant_loaded: true
      }
    case COMPETITOR_MERCHANT_FAILURE:
      return {
        competitor_merchant_error: action.error,
        competitor_merchant_loading: false,
        competitor_merchant_loaded: false
      }
    case LOCALITY_LOADING:
      return {
        ...state,
        locality_details_loading:true,
        locality_details_loaded:false,
      }
    case LOCALITY_SUCCESS:
      return {
        ...state,
        locality_details: action.result,
        locality_details_loading:false,
        locality_details_loaded:true,
      }
    case LOCALITY_FAILURE:
      return {
        ...state,
        locality_details_loading:false,
        locality_details_loaded:false,
      }
    case LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.result
      }
    case SURVEY_FLAG:
      return{
        ...state,
        survey_flag:true
      }
    case UNSUBSRIBE_SUCCESS:
      return{
        ...state,
        unsubscribe_remarketing:true
      }
    case INACTIVE_SUCCESS:
      return{
        ...state,
        inactive_marked:true
      }
    case PRIVILEGE_ACTIVATION_LOADING:
      return{
        ...state,
        activationLoading: true,
      }
    case PRIVILEGE_ACTIVATION_SUCCESS:
      return{
        ...state,
        activationLoading: false,
        activationData: action.result
      }
    case PRIVILEGE_ACTIVATION_FAILURE:
      return{
        ...state,
        activationLoading: false,
        activationError: action.error
    }
    case CREATE_PSR_SUCCESS:
      return{
        ...state,
        createPSRData: action.result
      }
    case BANNERS_SUCCESS:
    return {
      ...state,
      localityBanners: action.result
    }
    case MERCHANT_ORDERS_LOADING:
      return{
         ...state,
         merchantOrdersDataLoading: true,
      }
    case MERCHANT_ORDERS_LOADED_SUCCESS:
      return{
        ...state,
        merchantOrdersDataLoading: false,
        merchantOrderAllData: action.result,
        merchantOrdersData: action.result.ordersDetail && action.result.ordersDetail.length > 0 && action.result.ordersDetail.filter(orderObj => {
          if(orderObj.orderCategory == "CATALOG") {
            if(orderObj.orderStatus != "BILL_READY" || orderObj.orderStatus != "PAYMENT_INITIATED") {
              return orderObj
            }
          } else {
            return orderObj
          }
        }) || [],
        merchantOrdersDataError: null
    }
    case MERCHANT_ORDERS_LOADED_FAILURE:
      return{
        ...state,
        merchantOrdersDataLoading: false,
        merchantOrdersData: null,
        merchantOrdersDataError: action.error
    }
    case MERCHANT_SINGLE_ORDER_LOADING:
      return{
         ...state,
         merchantSingleOrderDataLoading: true,
      }
    case MERCHANT_SINGLE_ORDER_LOADED_SUCCESS:
      return{
        ...state,
        merchantSingleOrderDataLoading: false,
        merchantSingleOrderData: action.result,
        merchantSingleOrderDataError: null
    }
    case MERCHANT_SINGLE_ORDER_LOADED_FAILURE:
      return{
        ...state,
        merchantSingleOrderDataLoading: false,
        merchantSingleOrderData: null,
        merchantSingleOrderDataError: action.error
    }
    case GET_SINGLE_ORDER_DATA_BY_PARENT:
      return{
        ...state,
        merchantSingleOrderDataLoading: true,
      }
    case GET_SINGLE_ORDER_DATA_BY_PARENT_SUCCESS:
      return{
        ...state,
        merchantSingleOrderDataLoading: false,
        merchantSingleOrderData: action.result,
        merchantSingleOrderDataError: null
      }
    case GET_SINGLE_ORDER_DATA_BY_PARENT_ERROR:
      return{
        ...state,
        merchantSingleOrderDataLoading: false,
        merchantSingleOrderData: null,
        merchantSingleOrderDataError: action.error
      }
    case PARENT_MERCHANT_ORDERS_LOADING:
      return{
         ...state,
         parentMerchantOrdersDataLoading: true,
      }
    case PARENT_MERCHANT_ORDERS_LOADED_SUCCESS:
      return{
        ...state,
        parentMerchantOrdersDataLoading: false,
        // merchantOrderAllData: action.result,
        parentMerchantOrdersData: action.result.ordersDetail,
        parentMerchantOrdersDataError: null
    }
    case PARENT_MERCHANT_ORDERS_LOADED_FAILURE:
      return{
        ...state,
        parentMerchantOrdersDataLoading: false,
        parentMerchantOrdersData: null,
        parentMerchantOrdersDataError: action.error
    }
    case PARENT_MERCHANT_ALL_ORDERS_LOADING:
      return{
         ...state,
         parentMerchantAllOrdersDataLoading: true,
      }
    case PARENT_MERCHANT_ALL_ORDERS_LOADED_SUCCESS:
      return{
        ...state,
        parentMerchantAllOrdersDataLoading: false,
        // merchantOrderAllData: action.result,
        parentMerchantAllOrdersData: action.result.ordersDetail,
        parentMerchantAllOrdersCount: action.result.orderCount,
        parentMerchantAllOrdersDataError: null
    }
    case PARENT_MERCHANT_ALL_ORDERS_LOADED_FAILURE:
      return{
        ...state,
        parentMerchantAllOrdersDataLoading: false,
        parentMerchantAllOrdersData: null,
        parentMerchantAllOrdersDataError: action.error
    }
    case PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADING:
      return{
         ...state,
         parentMerchantAllPaginatedOrdersDataLoading: true,
      }
    case PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_SUCCESS:
      return{
        ...state,
        parentMerchantAllPaginatedOrdersDataLoading: false,
        parentMerchantAllPaginatedOrdersData: action.result.ordersDetail,
        parentMerchantAllPaginatedOrdersCount: action.result.orderCount,
        parentMerchantAllPaginatedOrdersDataError: null
    }
    case PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_FAILURE:
      return{
        ...state,
        parentMerchantAllPaginatedOrdersDataLoading: false,
        parentMerchantAllPaginatedOrdersData: null,
        parentMerchantAllPaginatedOrdersDataError: action.error
    }
    case GET_MERCHANT_INFO:
      return{
        ...state,
        merchantInfoLoading: true
      }
    case GET_MERCHANT_INFO_SUCCESS:
      return{
        ...state,
        merchantInfoLoading: false,
        merchantInfoData: action.result,
        merchantInfoError: null
      }
    case GET_MERCHANT_INFO_FAILURE:
      return{
        ...state,
        merchantInfoLoading: false,
        merchantInfoData: null,
        merchantInfoError: action.error
      }
    case UPDATE_ORDERS_STATUS_LOADING:
      return{
         ...state,
         updateOrdersStatusLoading: true
      }
    case UPDATE_ORDERS_STATUS_SUCCESS:
      return{
        ...state,
        updateOrdersStatusLoading: false,
        updateOrdersStatusData: action.result,
        updateOrdersStatusError: null
      }
    case UPDATE_ORDERS_STATUS_FAILURE:
      return{
        ...state,
        updateOrdersStatusLoading: false,
        updateOrdersStatusData: null,
        updateOrdersStatusError: action.error
      }
    case UPDATE_ORDERS_VALUE_LOADING:
      return{
        ...state,
        updateOrdersValueLoading: true
      }
    case UPDATE_ORDERS_VALUE_SUCCESS:
      return{
        ...state,
        updateOrdersValueLoading: false,
        updateOrdersValueData: action.data,
        updateOrdersStatusError: null
      }
    case UPDATE_ORDERS_VALUE_FAILURE:
      return{
        ...state,
        updateOrdersValueLoading: false,
        updateOrdersValueData: null,
        updateOrdersStatusError: action.error
      }
    case UPDATE_TRACK_LINK_LOADING:
      return{
        ...state,
        updateTrackingLinkLoading: true
      }
    case UPDATE_TRACK_LINK_SUCCESS:
      return{
        ...state,
        updateTrackingLinkLoading: false,
        updateTrackingLinkData: action.result,
        updateTrackingLinkError: null
      }
    case UPDATE_TRACK_LINK_FAILURE:
      return{
        ...state,
        updateTrackingLinkLoading: false,
        updateTrackingLinkData: null,
        updateTrackingLinkError: action.error
      }
    case UPDATE_AGENT_PHONE_NUMBER_LOADING:
      return{
        ...state,
        updateAgentPhoneNumberLoading: true,
      }
    case UPDATE_AGENT_PHONE_NUMBER_SUCCESS:
      return{
        ...state,
        updateAgentPhoneNumberLoading: false,
        updateAgentPhoneNumberData: action.result,
        updateAgentPhoneNumberError: false
      }
    case UPDATE_AGENT_PHONE_NUMBER_FAILURE:
      return{
        ...state,
        updateAgentPhoneNumberLoading: false,
        updateAgentPhoneNumberData: null,
        updateAgentPhoneNumberError: action.error
      }
    case VERIFY_OTP_LOADING:
      return{
        ...state,
        verifyOtpLoading: true
      }
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpResponseData: action.result,
        verifyOtpError: false,
      }
    case VERIFY_OTP_FAILURE:
      return{
        ...state,
        verifyOtpLoading: false,
        verifyOtpResponseData: null,
        verifyOtpError: action.error
      }
    case GET_SEARCH_RESULTS:
      return{
        ...state,
        searchResultsLoading: true,
      }
    case GET_SEARCH_RESULTS_SUCCESS:
      return{
        ...state,
        searchResultsLoading: false,
        searchResultsData: action.result,
        searchResultsError: false
      }
    case GET_SEARCH_RESULTS_FAILURE:
      return{
        ...state,
        searchResultsLoading: false,
        searchResultsData: null,
        searchResultsError: action.error
      }
    case MERCHANT_DELIVERY_DETAILS:
      return{
        ...state,
        merchantDeliveryDetailsLoading: true
      }
    case MERCHANT_DELIVERY_DETAILS_SUCCESS:
      let res = action.result;
      return{
        ...state,
        merchantDeliveryDetailsLoading: false,
        merchantDeliveryDetailsData: res,
        defaultEndTimings: getDefaultEndTime(res),
        priorityMerchant: res && res.priorityMerchant || false,
        showCustomOnly: res && res.showCustomOnly || false,
        closingTime: !!res && !!res.merchant && !!res.merchant.deliveryTimingsEnd
                      ? moment(res.merchant.deliveryTimingsEnd): undefined,
        merchantDeliveryDetailsError: null
      }
    case MERCHANT_DELIVERY_DETAILS_FAILURE:
      return{
        ...state,
        merchantDeliveryDetailsLoading: false,
        merchantDeliveryDetailsData: null,
        merchantDeliveryDetailsError: action.error
      }
      case SET_MERCHANT_DELIVERY_DETAILS:
      return{
        ...state,
        setMerchantDeliveryDetailsLoading: true
      }
    case SET_MERCHANT_DELIVERY_DETAILS_SUCCESS:
      return{
        ...state,
        setMerchantDeliveryDetailsLoading: false,
        setMerchantDeliveryDetailsData: action.result,
        setMerchantDeliveryDetailsError: null
      }
    case SET_MERCHANT_DELIVERY_DETAILS_FAILURE:
      return{
        ...state,
        setMerchantDeliveryDetailsLoading: false,
        setMerchantDeliveryDetailsData: null,
        setMerchantDeliveryDetailsError: action.error
      }
    case MERCHANT_PAGINATED_ORDERS_LOADING:
      return{
        ...state,
        paginatedOrdersLoading: true
      }
    case MERCHANT_PAGINATED_ORDERS_LOADED_SUCCESS:
      return{
        ...state,
        paginatedOrdersLoading: true,
        paginatedOrdersData: action.result,
        paginatedOrdersError: null
      }
    case MERCHANT_PAGINATED_ORDERS_LOADED_FAILURE:
      return{
        ...state,
        paginatedOrdersLoading: true,
        paginatedOrdersData: null,
        paginatedOrdersError: action.error
      }
      case BILL_UPLOAD:
      return{
        ...state,
        billUploading: true,
      }
    case BILL_UPLOAD_SUCCESS:
      return{
        ...state,
        billUploading: false,
        billUploaded: true,
      }
    case BILL_UPLOAD_FAIL:
      return{
        ...state,
        billUploading: false,
        billUploaded: false,
      }
      case CHAT_IMAGE_UPLOADING:
        return{
          ...state,
          chatImageUploading: true,
        }
      case CHAT_IMAGE_UPLOADED_SUCCESS:
        return{
          ...state,
          chatImageUploading: false,
          chatImageUrl: action.result,
          chatImageError: null
        }
      case CHAT_IMAGE_UPLOADED_ERROR:
        return{
          ...state,
          chatImageUploading: false,
          chatImageUrl: null,
          chatImageError: action.error
        }
    case SUGGESTED_PRICE_LOADING:
      return{
          ...state,
          suggestedPriceLoading: true,
          suggestedPriceData: null,
      }  
    case SUGGESTED_PRICE_SUCCESS:
      return{
          ...state,
          suggestedPriceLoading: false,
          suggestedPriceData: action.result
      }
    case SUGGESTED_PRICE_FAILURE:
      return{
         ...state,
         suggestedPriceLoading: false,
         suggestedPriceData: null,
         suggestedPriceError: action.error
      }
    case GET_ORDER_STATS_LOADING:
      return{
        ...state,
        orderStatsLoading: true
      } 
    case GET_ORDER_STATS_SUCCESS:
      return{
        ...state,
        orderStatsLoading: false,
        orderStatsData: action.result,
        orderStatsError: null
      } 
    case GET_ORDER_STATS_FAILURE:
      return{
        ...state,
        orderStatsLoading: false,
        orderStatsData: null,
        orderStatsError: action.error
      }
      case UPLOAD_CSV:
      return {
        ...state,
        uploading: true
      }
    case CSV_UPLOADED:
      return {
        ...state,
        uploading: false,
        uploadResult: action.result,
        uploadError: null,
      }
    case CSV_FAILED:
      return {
        ...state,
        uploading: false,
        uploadResult: null,
        uploadError: action.error
      }
    case DELIVERY_PREFERENCE:
      return {
        ...state,
        deliveryPreferenceUpdating: true
      }
    case DELIVERY_PREFERENCE_SUCCESS:
      return {
        ...state,
        deliveryPreferenceUpdating: false,
        deliveryPreferenceUpdateSuccess: action.result,
        deliveryPreferenceUpdateFailed: null,
      }
    case DELIVERY_PREFERENCE_FAILED:
      return {
        ...state,
        deliveryPreferenceUpdating: false,
        deliveryPreferenceUpdateSuccess: null,
        deliveryPreferenceUpdateFailed: action.error
      }
    case LOAD_CHAT_HISTORY:
      return {
        ...state,
        chatHistoryDataLoading: action.chatHistoryDataLoading
      }
    case CHAT_HISTORY_DATA:
      return {
        ...state,
        chatHistoryData: action.chatHistoryData
      }
    case OPEN_NEW_WEB_SOCKET:
      return{
        ...state,
        webSocket: action.webSocket
      }
     case ORDER_HERE_ALIAS_LOADING:
      return{
        ...state
      }
    case ORDER_HERE_ALIAS_SUCCESS:
      return{
        ...state,
        alias: action.result && action.result.alias ? action.result.alias : ''
      }
    case ORDER_HERE_ALIAS_FAILED:
      return{
        ...state,
        alias: ''
      }
    case GET_DELIVERY_CONFIGURATOR:
      return{
        ...state,
        deliveryConfiguratorDataLoading: true,
      }
    case GET_DELIVERY_CONFIGURATOR_SUCCESS:
      return{
        ...state,
        deliveryConfiguratorDataLoading: false,
        deliveryConfiguratorData: action.result,
        deliveryConfiguratorDataError: null
      }
    case GET_DELIVERY_CONFIGURATOR_ERROR:
      return{
        ...state,
        deliveryConfiguratorDataLoading: false,
        deliveryConfiguratorData: null,
        deliveryConfiguratorDataError: action.error
      }
    case GET_DELIVERY_CONFIGURATOR_BY_PARENT:
      return{
        ...state,
        deliveryConfiguratorDataLoading: true,
      }
    case GET_DELIVERY_CONFIGURATOR_SUCCESS_BY_PARENT:
      return{
        ...state,
        deliveryConfiguratorDataLoading: false,
        deliveryConfiguratorData: action.result,
        deliveryConfiguratorDataError: null
      }
    case GET_DELIVERY_CONFIGURATOR_ERROR_BY_PARENT:
      return{
        ...state,
        deliveryConfiguratorDataLoading: false,
        deliveryConfiguratorData: null,
        deliveryConfiguratorDataError: action.error
      }
    case SET_DELIVERY_CONFIGURATOR:
      return{
        ...state,
        deliveryConfiguratorDataUploading: true
      }
    case SET_DELIVERY_CONFIGURATOR_SUCCESS:
      return{
        ...state,
        deliveryConfiguratorDataUploading: false,
        deliveryConfiguratorDataUploaded: action.result,
        deliveryConfiguratorDataUploadError: null
      }
    case SET_DELIVERY_CONFIGURATOR_ERROR:
      return{
        ...state,
        deliveryConfiguratorDataUploading: false,
        deliveryConfiguratorDataUploaded: null,
        deliveryConfiguratorDataUploadError: action.error
      }
    case MARK_CATALOG_UNAVAILABLE:
      return {
        ...state,
        markCatalogUnavailableLoading: true
      }
    case MARK_CATALOG_UNAVAILABLE_SUCCESS: 
      return {
        ...state,
        markCatalogUnavailableLoading: false,
        markCatalogUnavailableResult: action.result,
        markCatalogUnavailableError: null
      }
    case MARK_CATALOG_UNAVAILABLE_FAILURE:
      return {
        ...state,
        markCatalogUnavailableLoading: false,
        markCatalogUnavailableResult: null,
        markCatalogUnavailableError: action.error
      }
    case MARK_CATALOG_AVAILABLE:
      return {
        ...state,
        markCatalogAvailableLoading: true
      }
    case MARK_CATALOG_AVAILABLE_SUCCESS: 
      return {
        ...state,
        markCatalogAvailableLoading: false,
        markCatalogAvailableResult: action.result,
        markCatalogAvailableError: null
      }
    case MARK_CATALOG_AVAILABLE_FAILURE:
      return {
        ...state,
        markCatalogAvailableLoading: false,
        markCatalogAvailableResult: null,
        markCatalogAvailableError: action.error
      }
    case UPLOAD_MENU_CSV_LOADING:
      return {
        ...state,
        uploadMenuCsvLoading: true
      }
    case UPLOAD_MENU_CSV_SUCCESS:
      return {
        ...state,
        uploadMenuCsvLoading: false,
        uploadCsvSuccess: action.result,
        uploadCsvFailure: null
      }
    case UPLOAD_MENU_CSV_FAILURE:
      return {
        ...state,
        uploadMenuCsvLoading: false,
        uploadCsvSuccess: null,
        uploadCsvFailure: action.error

      }
    case CALCULATE_PRICE_LOADING:
      return{
        ...state,
        calculatePriceLoading: true
      }  
    case CALCULATE_PRICE_SUCCESS:
      return{
        ...state,
        calculatePriceLoading: false,
        calculatePriceData: action.result,
        calculatePriceError: null
      }
    case CALCULATE_PRICE_FAILURE:
      return{
        ...state,
        calculatePriceLoading: false,
        calculatePriceData: null,
        calculatePriceError: action.error
      }
    case ACCEPT_PARTIAL_CATALOG_ORDER_LOADING:
      return{
        ...state,
        acceptPartialCatalogOrderLoading: true
      }  
    case ACCEPT_PARTIAL_CATALOG_ORDER_SUCCESS:
      return{
        ...state,
        acceptPartialCatalogOrderLoading: false,
        acceptPartialCatalogOrderData: action.result,
        acceptPartialCatalogOrderError: null
      }
    case ACCEPT_PARTIAL_CATALOG_ORDER_FAILURE:
      return{
        ...state,
        acceptPartialCatalogOrderLoading: false,
        acceptPartialCatalogOrderData: null,
        acceptPartialCatalogOrderError: action.error
      }
/**
 * 
const UPDATE_DISCOUNT_CATALOG = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG'
const UPDATE_DISCOUNT_CATALOG_SUCCESS = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG_SUCCESS'
const UPDATE_DISCOUNT_CATALOG_FAILURE = 'newMerchantLanding/UPDATE_DISCOUNT_CATALOG_FAILURE'
 * 
 */
    case UPDATE_DISCOUNT_BATCH:
      return {
        ...state,
        updateDiscountBatchLoading: true
      }
    case UPDATE_DISCOUNT_BATCH_SUCCESS: 
      return {
        ...state,
        updateDiscountBatchLoading: false,
        updateDiscountBatchResult: action.result,
        updateDiscountBatchError: null
      }
    case UPDATE_DISCOUNT_BATCH_FAILURE:
      return {
        ...state,
        updateDiscountBatchLoading: false,
        updateDiscountBatchResult: null,
        updateDiscountBatchError: action.error
      }
    case UPDATE_DISCOUNT_CATALOG:
      return {
        ...state,
        updateDiscountCatalogLoading: true
      }
    case UPDATE_DISCOUNT_CATALOG_SUCCESS: 
      return {
        ...state,
        updateDiscountCatalogLoading: false,
        updateDiscountCatalogResult: action.result,
        updateDiscountCatalogError: null
      }
    case UPDATE_DISCOUNT_CATALOG_FAILURE:
      return {
        ...state,
        updateDiscountCatalogLoading: false,
        updateDiscountCatalogResult: null,
        updateDiscountCatalogError: action.error
      }

    case UPDATE_GST_CATALOG:
      return {
        ...state,
        updateGSTCatalogLoading: true
      }
    case UPDATE_GST_CATALOG_SUCCESS: 
      return {
        ...state,
        updateGSTCatalogLoading: false,
        updateGSTCatalogResult: action.result,
        updateGSTCatalogError: null
      }
    case UPDATE_GST_CATALOG_FAILURE:
      return {
        ...state,
        updateGSTCatalogLoading: false,
        updateGSTCatalogResult: null,
        updateGSTCatalogError: action.error
      }

    case MAGICSTORE_TEXT_LOADING:
      return{
        ...state
      }
    case MAGICSTORE_TEXT_SUCCESS:
      return{
        ...state,
        magicstoreCdnText: action.result
      }
    case MAGICSTORE_TEXT_FAILED:
      return{
        ...state
      }
    case SUGGESTED_ALIAS_LOADING:
      return{
        ...state
      }
    case SUGGESTED_ALIAS_SUCCESS:
      return{
        ...state,
        suggestedAlias: action.result ? action.result : []
      }
    case SUGGESTED_ALIAS_FAILED:
      return{
        ...state,
        suggestedAlias: []
      }
    case CREATE_ALIAS_LOADING:
      return{
        ...state
      }
    case CREATE_ALIAS_SUCCESS:
      return{
        ...state,
        createAliasResult: action.result ? action.result : {}
      }
    case CREATE_ALIAS_FAILED:
      return{
        ...state,
        createAliasResult: {}
      }
    case GET_ALIAS_LOADING:
      return{
        ...state
      }
    case GET_ALIAS_SUCCESS:
      return{
        ...state,
        getAliasResult: action.result ? action.result : {}
      }
    case GET_ALIAS_FAILED:
      return{
        ...state,
        getAliasResult: {}
      }
    case CREATE_ORDER_LOADING:
      return{
        ...state
      }
    case CREATE_ORDER_SUCCESS:
      return{
        ...state,
        createTestOrderResult: action.result ? action.result : {}
      }
    case CREATE_ORDER_FAILED:
      return{
        ...state,
        createTestOrderResult: ''
      }
    case GET_DEL_PARTNERS_LOADING:
      return{
        ...state
      }
    case GET_DEL_PARTNERS_SUCCESS:
      return{
        ...state,
        deliveryPartnersResult: action.result && action.result.deliveryPartnerDetails ? action.result.deliveryPartnerDetails : []
      }
    case GET_DEL_PARTNERS_FAILED:
      return{
        ...state,
        deliveryPartnersResult: ''
      }
    case ACTIVATE_MAGICSTORE_LOADING:
      return{
        ...state
      }
    case ACTIVATE_MAGICSTORE_SUCCESS:
      return{
        ...state,
        activateMagicstoreResult: action.result ? action.result : []
      }
    case ACTIVATE_MAGICSTORE_FAILED:
      return{
        ...state,
        activateMagicstoreResult: ''
      }
    case IS_MENU_AVAILABLE_LOADING:
      return{
        ...state
      }
    case IS_MENU_AVAILABLE_SUCCESS:
      return{
        ...state,
        isMenuAvailable: action.result ? action.result : false
      }
    case IS_MENU_AVAILABLE_FAILED:
      return{
        ...state,
        isMenuAvailable: ''
      }
    case UPLOAD_MENU_LOADING:
      return{
        ...state
      }
    case UPLOAD_MENU_SUCCESS:
      return{
        ...state,
        menuItemsUploaded: action.result && action.result.message && action.result.message.toUpperCase() == 'SUCCESS' ? true : false
      }
    case UPLOAD_MENU_FAILED:
      return{
        ...state,
        menuItemsUploaded: false
      }
    case UPDATE_DELIVERY_PREF_LOADING:
      return{
        ...state
      }
    case UPDATE_DELIVERY_PREF_SUCCESS:
      return{
        ...state,
        updateDeliveryPrefResult: action.result && action.result.message && action.result.message.toUpperCase() == 'SUCCESS' ? true : false
      }
    case UPDATE_DELIVERY_PREF_FAILED:
      return{
        ...state,
        updateDeliveryPrefResult: false
      }
    case GET_ITEM_LIST_CATALOG_LOADING:
      return{
        ...state,
        catalogItemListLoading: true 
      }
    case GET_ITEM_LIST_CATALOG_SUCCESS:
      return{
        ...state,
        catalogItemListLoading: false ,
        catalogItemListData: action.result,
        catalogItemListError: null
      }
    case GET_ITEM_LIST_CATALOG_FAILURE:
      return{
        ...state,
        catalogItemListLoading: false,
        catalogItemListData: [],
        catalogItemListError: action.error
      }
    case SAVE_OR_MODIFY_CATALOG_NAME_LOADING:
      return{
        ...state,
        saveOrModifyCatalogNameLoading : true,
      }
    case SAVE_OR_MODIFY_CATALOG_NAME_SUCCESS:
      return{
        ...state,
        saveOrModifyCatalogNameLoading : false,
        saveOrModifyCatalogNameData: action.result,
        catalogItemListError: null
      }
      case SAVE_OR_MODIFY_CATALOG_NAME_FAILURE:
        return{
         ...state,
         saveOrModifyCatalogNameLoading : false,
         saveOrModifyCatalogNameData: [],
         catalogItemListError: action.error
        }
      case CHECK_IF_CATALOG_EXISTS:
        return{
          ...state,
          doesCatalogExistLoading: true
        }
      case CHECK_IF_CATALOG_EXISTS_SUCCESS:
        return{
          ...state,
          doesCatalogExistLoading: false,
          doesCatalogExistData: action.result,
          doesCatalogExistError: null
        }
      case CHECK_IF_CATALOG_EXISTS_FAILURE:
        return{
          ...state,
          doesCatalogExistLoading: false,
          doesCatalogExistData: null,
          doesCatalogExistError: action.error
        }
      case SAVE_OR_MODIFY_ITEM_LOADING:
        return{
            ...state,
            saveOrModifyItemLoading : true
        }
      case SAVE_OR_MODIFY_ITEM_SUCCESS:
        return{
            ...state,
            saveOrModifyItemLoading : false,
            saveOrModifyItemData: action.result,
            saveOrModifyItemError: action.error
        }
      case SAVE_OR_MODIFY_ITEM_FAILURE:
        return{
            ...state,
            saveOrModifyItemLoading : false,
            saveOrModifyItemData: [],
            saveOrModifyItemError: action.error
        }
      case CREATE_TEST_ORDER_ADMIN_LOADING:
        return{
            ...state,
            creatingTestOrderAdminLoading: true
        }
      case CREATE_TEST_ORDER_ADMIN_SUCCESS:
        return{
          ...state,
          creatingTestOrderAdminLoading: false,
          creatingTestOrderaAdminData: action.result,
          creatingTestOrderaAdminError: null
        }
      case CREATE_TEST_ORDER_ADMIN_FAILURE:
        return{
          ...state,
          creatingTestOrderAdminLoading: false,
          creatingTestOrderaAdminError: action.error,
          creatingTestOrderaAdminData: null
      }
      case REPORT_WRONG_MRP_LOADING:
        return{
          ...state,
          reportingWrongMrp: true
        }
      case REPORT_WRONG_MRP_SUCCESS:
        return{
          ...state,
          reportingWrongMrp: false,
          reportingWrongMrpData: action.result,
          reportinWrongMrpError: null
      }
      case REPORT_WRONG_MRP_FAILURE:
        return{
          ...state,
          reportingWrongMrp: false,
          reportingWrongMrpData: null,
          reportinWrongMrpError: action.error
      }
    case ENABLE_ORDER_BOOKING_LOADING:
      return{
        ...state
      }
    case ENABLE_ORDER_BOOKING_SUCCESS:
      return{
        ...state,
        enableOrderBookingResponse: action.result,
      }
    case ENABLE_ORDER_BOOKING_FAILURE:
      return{
        ...state,
        enableOrderBookingResponse: null
      }
    case HAS_ACCEPTED_ORDERS_LOADING:
      return{
        ...state
      }
    case HAS_ACCEPTED_ORDERS_SUCCESS:
      return{
        ...state,
        hasAcceptedOrdersResponse: action.result,
      }
    case HAS_ACCEPTED_ORDERS_FAILURE:
      return {
          ...state,
          hasAcceptedOrdersResponse: null
      }
      case GET_VERSION_CODE_LOADING:
        return{
          ...state,
          versionCodeLoading: true
        }
      case GET_VERSION_CODE_SUCCESS:
        return{
          ...state,
          versionCodeLoading: false,
          versionCodeData: action.result,
          versionCodeError: null
      }
      case GET_VERSION_CODE_FAILURE:
        return{
          ...state,
          versionCodeLoading: false,
          versionCodeData: null,
          versionCodeError: action.error
      }
      case GET_MERCHANT_REWARD_DAY_WISE_LOADING:
        return{
          ...state,
          merchantRewardDayWiseLoading: true
      }
      case GET_MERCHANT_REWARD_DAY_WISE_SUCCESS:
        return{
          ...state,
          merchantRewardDayWiseLoading: false,
          merchantRewardDayWise: action.result,
          merchantRewardDayWiseError: null
      }
      case GET_MERCHANT_REWARD_DAY_WISE_FAILURE:
        return{
          ...state,
          merchantRewardDayWiseLoading: false,
          merchantRewardDayWise: null,
          merchantRewardDayWiseError: action.error
      }
      case GET_MERCHANT_BRAND_WISE_LOADING:
        return{
          ...state,
          merchantRewardBrandWiseLoading: true,
      }
      case GET_MERCHANT_BRAND_WISE_SUCCESS:
        return{
          ...state,
          merchantRewardBrandWiseLoading: false,
          merchantRewardBrandWise: action.result && action.result.res ? action.result.res : [],
          merchantRewardBrandWiseError: null
      }
      case GET_MERCHANT_BRAND_WISE_FAILURE:
        return{
          ...state,
          merchantRewardBrandWiseLoading: false,
          merchantRewardBrandWise: [],
          merchantRewardBrandWiseError: action.error
      }
      case MERCHANT_RELIST_REQUESTED_LOADING:
        return{
          ...state,
          merchantRelistRequestLoading: true,
      }
      case MERCHANT_RELIST_REQUESTED_SUCCESS:
        return{
          ...state,
          merchantRelistRequestLoading: false,
          merchantHasRequestedRelist: action.result,
          merchantHasRequestedRelistError: null
      }
      case MERCHANT_RELIST_REQUESTED_FAILURE:
        return{
          ...state,
          merchantRelistRequestLoading: false,
          merchantHasRequestedRelist: null,
          merchantHasRequestedRelistError: action.error
      }
      case GET_MERCHANT_TIMINGS_LOADING:
        return{
          ...state,
          merchantTimingsLoading: true
        }
      case GET_MERCHANT_TIMINGS_SUCCESS:
        return{
          ...state,
          merchantTimingsLoading: false,
          merchantTimingsData: action.result,
          merchantTimingsError: null
        }
      case GET_MERCHANT_TIMINGS_FAILURE:
        return{
          ...state,
          merchantTimingsLoading: false,
          merchantTimingsData: null,
          merchantTimingsError: action.error
        }
      case DID_MERCHANT_CREATE_NY_VOUCHERS_LOADING:
        return{
          ...state,
          didCreateVouchersLoading: true
        }
      case DID_MERCHANT_CREATE_NY_VOUCHERS_SUCCESSS:
        return{
          ...state,
          didCreateVouchersLoading: false,
          didCreateVouchersData: action.result,
          didCreateVouchersError: null
        }
      case DID_MERCHANT_CREATE_NY_VOUCHERS_FAILURE:
        return{
          ...state,
          didCreateVouchersLoading: false,
          didCreateVouchersData: null,
          didCreateVouchersError: action.error
        }
      case CREATE_NY_VOUCHER_REQUEST_LOADING:
        return{
          ...state,
          createVouchersLoading: true,
          createVouchersLoaded: false
        }
      case CREATE_NY_VOUCHER_REQUEST_SUCCESS:
        return{
          ...state,
          createVouchersLoading: false,
          createVouchersData: action.result,
          createVouchersError: null,
          createVouchersLoaded: true
        }
      case CREATE_NY_VOUCHER_REQUEST_FAILURE:
        return{
          ...state,
          createVouchersLoading: false,
          createVouchersData: null,
          createVouchersError: action.error ? action.error : "Try again later",
          createVouchersLoaded: false
        }
      case WRITE_RELIST_REQUEST_LOADING:
        return{
          ...state,
          writeRelistRequestLoading: true,
      }
      case WRITE_RELIST_REQUEST_SUCCESS:
        return{
          ...state,
          writeRelistRequestLoading: false,
          writeRelistRequestData: action.result,
          writeRelistRequestError: null
      }
      case WRITE_RELIST_REQUEST_FAILURE:
        return{
          ...state,
          writeRelistRequestLoading: false,
          writeRelistRequestData: null,
          writeRelistRequestError: action.error
      }
      case MERCHANT_RATINGS_VISITS_LOADING:
        return{
          ...state,
          merchantRatingsVisitsLoading: true
        }
      case MERCHANT_RATINGS_VISITS_SUCCESS:
        return{
          ...state,
          merchantRatingsVisitsLoading: false,
          merchantRatingsVisitsData: action.result,
          merchantRatingsVisitsError: null
        }
      case MERCHANT_RATINGS_VISITS_FAILURE:
        return{
          ...state,
          merchantRatingsVisitsLoading: false,
          merchantRatingsVisitsData: null,
          merchantRatingsVisitsError: action.error
        }
      case GET_WHATSAPP_IMAGE_LOADING:
        return{
          ...state,
          whatsAppImageLoading: true
        } 
      case GET_WHATSAPP_IMAGE_SUCCESS:
        return{
          ...state,
          whatsAppImageLoading: false,
          whatAppImageUrl: action.result 
        }
      case GET_WHATSAPP_IMAGE_FAILURE:
        return{
          ...state,
          whatsAppImageLoading: false,
          whatAppImageUrl: '',
          whatsAppImageError: action.error
        }
      case WHATSAPP_PRODUCER_LOADING:
        return{
          ...state,
          whatsappProducerLoading: false,
        } 
      case WHATSAPP_PRODUCER_SUCCESS:
        return{
          ...state,
          whatsappProducerLoaded: true,
          whatsappProducerLoading: false
        }
      case WRITE_RELIST_REQUEST_FAILURE:
        return{
          ...state,
          whatsappProducerLoaded: true,
          whatsappProducerLoading: false
        } 
      case SHOW_APP_DOWNLOAD_POPUP_LOADING:
        return{
          ...state,
          showAppDownloadPopupLoading: false,
        } 
      case SHOW_APP_DOWNLOAD_POPUP_SUCCESS:
        return{
          ...state,
          showAppDownloadPopupLoading: false,
          showAppDownloadPopupRes: action.result
        } 
      case SHOW_APP_DOWNLOAD_POPUP_FAILURE:
        return{
          ...state,
          showAppDownloadPopupLoading: false,
        }  
      
      case GET_TOP_BRANDS_SUCCESS:{
        return{
          ...state,
          topBrandsLoading: false,
          topBrandsData: action.result
        }
      }
      case GET_TOP_BRANDS_FAILURE:{
        return{
          ...state,
          topBrandsLoading: false,
          topBrandsData: []
        }
      }
      case GET_MISSED_EARNING_LOADING:{
        return{
          ...state,
          missedEarningLoading: true
        }
      } 
      case GET_MISSED_EARNING_SUCCESS:{
        return{
          ...state,
          missedEarningLoading: false,
          missedEarning: action.result
        }
      }

    case GET_MISSED_EARNING_FAILURE:{
      return{
        ...state,
        missedEarningLoading: false,
      }
    } 

    case GET_MERCHANT_TOP_EARNING_LOADING:{
      return{
        ...state,
        topEarningLoading: true
      }
    } 
    case GET_MERCHANT_TOP_EARNING_SUCCESS:{
      return{
        ...state,
        topEarningLoading: false,
        topEarning: action.result
      }
    }

  case GET_MERCHANT_TOP_EARNING_FAILURE:{
    return{
      ...state,
      topEarningLoading: false,
    }
  } 
      case CREATE_CALLBACK_TICKET_LOADING:{
        return{
          ...state,
          ticketCreatedLoading: true
        }
      } 
      case CREATE_CALLBACK_TICKET_SUCCESS:{
        return{
          ...state,
          ticketCreatedLoading: false,
          ticketCreatedData: action.result,
          ticketCreatedFailure: null
        }
      }
      case CREATE_CALLBACK_TICKET_FAILURE:{
        return{
          ...state,
          ticketCreatedLoading: false,
          ticketCreatedData: null,
          ticketCreatedFailure: action.error
        }
      }
      case SEND_ALERT_EMAIL_LOADING:{
        return{
          ...state,
          sendAlertLoading: true
        }
      } 
      case SEND_ALERT_EMAIL_SUCCESS:{
        return{
          ...state,
          sendAlertLoading: false,
          sendAlertData: action.result,
          sendAlertFailure: null
        }
      }
      case SEND_ALERT_EMAIL_FAILURE:{
        return{
          ...state,
          sendAlertLoading: false,
          sendAlertData: null,
          sendAlertFailure: action.error
        }
      } 
      case GET_LOST_BRANDS_LOADING:{
        return{
          ...state,
          lostBrandsLoading: false
        }
      } 
      case GET_LOST_BRANDS_SUCCESS:{
        return{
          ...state,
          lostBrandsLoading: false,
          lostBrandsData: action.result
        }
      }
      case GET_LOST_BRANDS_FAILURE:{
        return{
          ...state,
          lostBrandsLoading: false,
          lostBrandsData: []
        }
      }

      case UPDATE_MERCHANT_HIGHLIGHTS_LOADING:
          return {
              ...state,
              updateHighlightsLoading: true
          }

      case UPDATE_MERCHANT_HIGHLIGHTS_SUCCESS:
          return {
              ...state,
              updateHighlightsLoading: false,
              updateHighlightsResult: action.result,
          }

      case UPDATE_MERCHANT_HIGHLIGHTS_FAILURE:
          return {
              ...state,
              updateHighlightsLoading: false,
              updateHighlightsResult: null,
              updateHighlightsError: action.error
          }

      case UPDATE_MERCHANT_TIMINGS_LOADING:
          return {
              ...state,
              updateTimingsLoading: true
          }

      case UPDATE_MERCHANT_TIMINGS_SUCCESS:
          return {
              ...state,
              updateTimingsLoading: false,
              updateTimingsResult: action.result,
          }

      case UPDATE_MERCHANT_TIMINGS_FAILURE:
          return {
              ...state,
              updateTimingsLoading: false,
              updateTimingsError: action.error
          }

      case ENABLE_MAGIC_ORDER_LOADING:
          return {
              ...state,
              enableMagicOrderLoading: true
          }

      case ENABLE_MAGIC_ORDER_SUCCESS:
          return {
              ...state,
              enableMagicOrderLoading: false,
              enableMagicOrderResult: action.result
          }

      case ENABLE_MAGIC_ORDER_FAILURE:
          return {
              ...state,
              enableMagicOrderLoading: false,
              enableMagicOrderResult: null,
              enableMagicOrderError: action.error
          }

      case UPDATE_STORE_NUMBER_LOADING:
          return {
              ...state,
              updateMagicOrderLoading: true
          }

      case UPDATE_STORE_NUMBER_SUCCESS:
          return {
              ...state,
              updateMagicOrderLoading: false,
              updateMagicOrderResult: action.result
          }

      case UPDATE_STORE_NUMBER_FAILURE:
          return {
              ...state,
              updateMagicOrderLoading: false,
              updateMagicOrderResult: null,
              updateMagicOrderError: action.error
          }

      case GET_MERCHANT_COD_LOADING:{
        return{
           ...state,
           merchantCodLoading: true
        }
      }
      case GET_MERCHANT_COD_SUCCESS:{
        return{
           ...state,
           merchantCodLoading: false,
           merchantCodData: action.result
        }
      }
      case GET_MERCHANT_COD_FAILURE:{
        return{
           ...state,
           merchantCodLoading: false,
           merchantCodData: {}
        }
      }
      case SET_MERCHANT_COD_LOADING:{
        return{
          ...state,
          merchantCodSetting: true
        }
      }
      case SET_MERCHANT_COD_SUCCESS:{
        return{
          ...state,
           merchantCodSetting: false
        }
      }
      case SET_MERCHANT_COD_FAILURE:{
        return{
          ...state,
          merchantCodSetting: false
        }
      }
      
      case SET_MERCHANT_FOOD:{
        return{
          ...state,
          merchantFssaiNumber: true
        }
      }
      case SET_MERCHANT_FOOD_SUCCESS:{
        return{
          ...state,
          merchantFssaiNumber: false
        }
      }
      case SET_MERCHANT_FOOD_ERROR:{
        return{
          ...state,
          merchantFssaiNumber: false
        }
      }

      case SALT_SYNC_REDIS_CHECK_LOADING:{
        return{
          ...state,
          saltSyncRedisCheckLoading: true
        }
      }
      case SALT_SYNC_REDIS_CHECK_SUCCESS:{
        return{
          ...state,
          saltSyncRedisCheckLoading: false,
          saltSyncRedisCheckData: action.result,
          saltSyncRedisCheckError: null
        }
      }
      case SALT_SYNC_REDIS_CHECK_FAILURE:{
        return{
          ...state,
          saltSyncRedisCheckLoading: false,
          saltSyncRedisCheckData: null,
          saltSyncRedisCheckError: action.error
        }
      }
    case SEND_DIYCOM_EMAIL_LOADING:{
      return{
        ...state,
        diycomEmailSending: true
      }
    }
    case SEND_DIYCOM_EMAIL_SUCCESS:{
      return{
        ...state,
        diycomEmailSuccess: true,
        diycomEmailFailure: false
        ,
      }
    }
    case SEND_DIYCOM_EMAIL_FAILURE:{
      return{
        ...state,
        diycomEmailSuccess: false,
        diycomEmailFailure: true
      }
    }
    case CATALOG_CRAWL_SCHEDULER_LOADING:{
      return{
        ...state,
        crawlSchedulerLoading: true
      }
    }
    case CATALOG_CRAWL_SCHEDULER_SUCCESS:{
      return{
        ...state,
        crawlSchedulerLoading: false,
        crawlSchedulerData: action.result,
        crawlSchedulerFailure: null
        ,
      }
    }
    case CATALOG_CRAWL_SCHEDULER_FAILURE:{
      return{
        ...state,
        crawlSchedulerLoading: false,
        crawlSchedulerData: null,
        crawlSchedulerFailure: action.error
      }
    }
    case SHOW_CHROME_EXTENSION_BANNER_LOADING: {
      return{
        ...state,
        showChromeExtensionLoading: true
      }
    }
    case SHOW_CHROME_EXTENSION_BANNER_SUCCESS: {
      return{
        ...state,
        showChromeExtensionLoading: false,
        showChromeExtensionData: action.result,
        showChromeExtensionError: null
      }
    }
    case SHOW_CHROME_EXTENSION_BANNER_FAILURE:{ 
      return{
        ...state,
        showChromeExtensionLoading: false,
        showChromeExtensionData: null,
        showChromeExtensionError: action.error
      }
    }
    case SHOW_ONSURITY_SNACKBAR_LOADING: {
      return{
        ...state,
        showOnsurityDataLoading: true
      }
    }
    case SHOW_ONSURITY_SNACKBAR_SUCCESS: {
      return{
        ...state,
        showOnsurityDataLoading: false,
        showOnsurityData: action.result,
        showOnsurityError: null
      }
    }
    case SHOW_ONSURITY_SNACKBAR_FAILURE: {
      return{
        ...state,
        showOnsurityDataLoading: false,
        showOnsurityData: null,
        showOnsurityError: action.error
      }
    }
    case ACCESS_TOKEN_DECRYPTOR_LOADING: {
      return{
        ...state,
        tokenValsLoading: true
      }
    }
    case ACCESS_TOKEN_DECRYPTOR_SUCCESS: {
      return{
        ...state,
        tokenValsLoading: false,
        tokenValsData: action.result,
        tokenValsError: null
      }
    }
    case ACCESS_TOKEN_DECRYPTOR_FAILURE: {
      return{
        ...state,
        tokenValsLoading: false,
        tokenValsData: null,
        tokenValsError: action.error
      }
    }

    case IS_MERCHANT_APP_INSTALLED_LOAD: {
      return{
        ...state,
        isAppInstalledDataLoading: true
      }
    }
    case IS_MERCHANT_APP_INSTALLED_SUCCESS: {
      return{
        ...state,
        isAppInstalledDataLoading: false,
        isAppInstalledData: action.result,
        isAppInstalledDataError: null
      }
    }
    case IS_MERCHANT_APP_INSTALLED_FAIL: {
      return{
        ...state,
        isAppInstalledDataLoading: false,
        isAppInstalledData: null,
        isAppInstalledDataError: action.error
      }
    }
    case WRITE_INTERACTIVITY_EVENT_LOADING: {
      return{
        ...state,
        writeInteractivityEventLoading: true
      }
    }
    case WRITE_INTERACTIVITY_EVENT_SUCCESS: {
      return{
        ...state,
        writeInteractivityEventLoading: false,
        writeInteractivityEventData: action.result,
        writeInteractivityEventError: null
      }
    }
    case WRITE_INTERACTIVITY_EVENT_FAILURE: {
      return{
        ...state,
        writeInteractivityEventLoading: false,
        writeInteractivityEventData: null,
        writeInteractivityEventError: action.error
      }
    }
    case GET_BUZZER_RING_SIGNAL_LOADING: {
      return{
        ...state,
        getBuzzerRingLoading: true
      }
    }
    case GET_BUZZER_RING_SIGNAL_SUCCESS: {
      return{
        ...state,
        getBuzzerRingLoading: false,
        getBuzzerRingData: action.result,
        getBuzzerRingError: null
      }
    }
    case GET_BUZZER_RING_SIGNAL_ERROR: {
      return{
        ...state,
        getBuzzerRingLoading: false,
        getBuzzerRingData: null,
        getBuzzerRingError: action.error
      }
    }
    case WRITE_BUZZER_RING_SIGNAL_LOADING: {
      return{
        ...state,
        writeBuzzerRingLoading: true
      }
    }
    case WRITE_BUZZER_RING_SIGNAL_SUCCESS: {
      return{
        ...state,
        writeBuzzerRingLoading: false,
        writeBuzzerRingData: action.result,
        writeBuzzerRingError: null
      }
    }
    case WRITE_BUZZER_RING_SIGNAL_ERROR: {
      return{
        ...state,
        writeBuzzerRingLoading: false,
        writeBuzzerRingData: null,
        writeBuzzerRingError: action.error
      }
    }
    case GET_ORDER_SUMMARY_FOR_CSV_PANEL_LOADING: {
      return{
        ...state,
        getOrderSummaryCsvLoading: true
      }
    }
    case GET_ORDER_SUMMARY_FOR_CSV_PANEL_SUCCESS: {
      return{
        ...state,
        getOrderSummaryCsvLoading: false,
        getOrderSummaryCsvData: action.result,
        getOrderSummaryCsvError: null
      }
    }
    case GET_ORDER_SUMMARY_FOR_CSV_PANEL_FAILURE: {
      return{
        ...state,
        getOrderSummaryCsvLoading: false,
        getOrderSummaryCsvData: null,
        getOrderSummaryCsvError: action.error
      }
    }
    case UPLOAD_INVENTORY_CSV_LOADING:
      return {
        ...state,
        uploadInventoryCsvLoading: true
      }
    case UPLOAD_INVENTORY_CSV_SUCCESS:
      return {
        ...state,
        uploadInventoryCsvLoading: false,
        uploadInventoryCsvSuccess: action.result,
        uploadInventoryCsvFailure: null
      }
    case UPLOAD_INVENTORY_CSV_FAILURE:
      return {
        ...state,
        uploadInventoryCsvLoading: false,
        uploadInventoryCsvSuccess: null,
        uploadInventoryCsvFailure: action.error
    }
    case UPLOAD_ORDER_STATUS_CSV_LOADING:
      return {
        ...state,
        uploadOrderStatusCsvLoading: true
      }
    case UPLOAD_ORDER_STATUS_CSV_SUCCESS:
      return {
        ...state,
        uploadOrderStatusCsvLoading: false,
        uploadOrderStatusCsvSuccess: action.result,
        uploadOrderStatusCsvFailure: null
      }
    case UPLOAD_ORDER_STATUS_CSV_FAILURE:
      return {
        ...state,
        uploadOrderStatusCsvLoading: false,
        uploadOrderStatusCsvSuccess: null,
        uploadOrderStatusCsvFailure: action.error
    }
    case GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_LOADING:
      return {
        ...state,
        getSampleInventoryCsvLoading: true
      }
    case GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_SUCCESS:
      return {
        ...state,
        getSampleInventoryCsvLoading: false,
        getSampleInventoryCsvData: action.result && action.result.headers ? [action.result.headers] : action.result,
        getSampleInventoryCsvFailure: null
      }
    case GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_FAILURE:
      return {
        ...state,
        getSampleInventoryCsvLoading: false,
        getSampleInventoryCsvData: null,
        getSampleInventoryCsvFailure: action.error
      }  
    // GET_ORDER_SUMMARY_FOR_CSV_PANEL_SUCCESS

    default:
      return state;
  }
}

// headerAuth: true,
//     params : {
//       merchantUserId: merchantUserId,
//       limit: 100
//     }
//   };

export function logUpdateOrderFailuresToMetricMonitor(accessToken, merchantId, orderId, orderStatus, userType, errObj, isParent=null, isEditBillVal=false) {
  let err = JSON.stringify(errObj)

  let payload = {}
  payload['monitor_name'] = "OMS.MP.UpdateOrderFailures";
  payload['status'] = "FAILURE";
  payload['url'] = window.location.href;
  payload['message'] = isEditBillVal ? `Error generated by MID: ${merchantId} while trying to update bill amount for orderId ${orderId} via ${userType} login.
      Full error message: ${err}`
    : 
      isParent
      ? `Error generated by MUID: ${merchantId} (PID panel) while trying to set order_status = ${orderStatus} for orderId ${orderId} via ${userType} login.
        Full error message: ${err}`
      : 
        `Error generated by MID: ${merchantId} while trying to set order_status = ${orderStatus} for orderId ${orderId} via ${userType} login.
        Full error message: ${err}`
  // payload['emailList'] = ["soumya.banerjee@magicpin.in", "gaurav.makhija@magicpin.in"];
  return reportErrorToMetricMonitor(accessToken, payload)
}


export function getDeliveryConfiguratorData(accessToken, merchantUserId, userType) {
  let action
  // if(userType == "MERCHANT") {
  //   action = {
  //     url: 'https://ultrontest.magicpin.com/loms/test/getMerchantDeliveryRules',
  //     method: 'get',
  //     headerAuth: true
  //   };
  // } else {
    action = {
      // url: 'https://ultrontest.magicpin.com/loms/test/getMerchantDeliveryRules',
      url: 'https://ultron.magicpin.com/loms/getMerchantDeliveryRules',
      method: 'get',
      headerAuth: true,
      params : {
        merchantUserId: merchantUserId
      }
    };
  // }
  return {
    types: [GET_DELIVERY_CONFIGURATOR, GET_DELIVERY_CONFIGURATOR_SUCCESS, GET_DELIVERY_CONFIGURATOR_ERROR],
    promise: (client) => client.default.post('/takeAction/getDeliveryConfigurator', {
      data: {accessToken, action}
    })
  }
}

export function getDeliveryConfiguratorDataByParent(accessToken, merchantUserId, merchantAuthToken) {

    let headerAuthUserId = merchantUserId
    let headerAuthToken = merchantAuthToken

    let action
      action = {
        url: 'https://ultron.magicpin.com/loms/getMerchantDeliveryRules',
        method: 'get',
        headerAuth: false,
        applyMerchantHeaderAuthForPidLogin: true,
        params : {
          merchantUserId: merchantUserId
        }
      };

  return {
    types: [GET_DELIVERY_CONFIGURATOR_BY_PARENT, GET_DELIVERY_CONFIGURATOR_SUCCESS_BY_PARENT, GET_DELIVERY_CONFIGURATOR_ERROR_BY_PARENT],
    promise: (client) => client.default.post('/takeActionByParentMerchant/getDeliveryConfiguratorByParent', {
      data: {accessToken, action, headerAuthUserId, headerAuthToken}
    })
  };
}

export function viewSingleOrderDataByParent(accessToken, merchantUserId, merchantAuthToken, orderId) {

  let headerAuthUserId = merchantUserId
  let headerAuthToken = merchantAuthToken

  let action
    action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrderDetailByMerchant",
      url: "http://ultron.magicpin.com/loms/viewOrderDetailByMerchant",
      method: 'get',
      headerAuth: false,
      applyMerchantHeaderAuthForPidLogin: true,
      params : {
        merchantUserId: merchantUserId,
        orderId: orderId
      }
    };

return {
  types: [GET_SINGLE_ORDER_DATA_BY_PARENT, GET_SINGLE_ORDER_DATA_BY_PARENT_SUCCESS, GET_SINGLE_ORDER_DATA_BY_PARENT_ERROR],
  promise: (client) => client.default.post('/takeActionByParentMerchant/viewSingleOrderDataByParent', {
    data: {accessToken, action, headerAuthUserId, headerAuthToken}
  })
};
}

export function setDeliveryConfiguratorData(accessToken, merchantUserId, categoryId, deliveryConfigurationDataSelf, deliveryConfigurationData3PL) {

  const action = {
    // url: 'https://ultrontest.magicpin.com/loms/test/updateMerchantDeliveryRules',
    url: 'https://ultron.magicpin.com/loms/updateMerchantDeliveryRules',
    method: 'post',
    headerAuth: true,
    data: {
    merchantUserId: merchantUserId,
    categoryId: categoryId,
    rules: [deliveryConfigurationDataSelf, deliveryConfigurationData3PL]
   }
  }
  return {
    types: [SET_DELIVERY_CONFIGURATOR, SET_DELIVERY_CONFIGURATOR_SUCCESS, SET_DELIVERY_CONFIGURATOR_ERROR],
    promise: (client) => client.default.post('/takeAction/setDeliveryConfigurator', {
      data: {accessToken, action}
    })
  };
}

export function loadChatHistoryRequest(chatHistoryDataLoading) {
  return{
    type: LOAD_CHAT_HISTORY,
    chatHistoryDataLoading
  }
}

export function loadChatHistoryResponse(chatHistoryData) {
  return{
    type: CHAT_HISTORY_DATA,
    chatHistoryData
  }
}

export function openNewWebSocket(webSocket){
  return{
    type: OPEN_NEW_WEB_SOCKET,
    webSocket
  }
}

export function loadNewPromotion(city) {
  if(!city)
    city = 'New Delhi';

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/newMerchantLanding',{
      data:{city}
    })
  };
}

export function paymentMailer(storeinfo) {
  return {
    types: [MAIL_LOAD, MAIL_LOAD_SUCCESS, MAIL_LOAD_FAIL],
    promise: (client) => client.default.post('/paymentMailer',{
      data:{storeinfo}
    })
  };
}

export function getNewMerchantCustomerData(identifier) {
  identifier = identifier || window.localStorage.getItem('landingMerchantId')
  return {
    types: [CUSTOMER_LOAD, CUSTOMER_LOAD_SUCCESS, CUSTOMER_LOAD_FAIL],
    promise: (client) => client.default.post('/getNewMerchantCustomerData',{
      data:{identifier}
    })
  };
}


export function getMerchantPosts(merchantId, ids) {
  return {
    types: [POSTS_LOAD, POSTS_LOAD_SUCCESS, POSTS_LOAD_FAIL],
    promise: (client) => client.default.post('/getMerchantPosts',{
      data:{
        merchantId:merchantId,
        ids:ids
      }
    }),
    merchantId:merchantId,
    postIds:ids
  };
}

export function getCompetitorMerchants(merchant_id) {
  return {
    types: [COMPETITOR_MERCHANT_LOADING, COMPETITOR_MERCHANT_SUCCESS, COMPETITOR_MERCHANT_FAILURE],
    promise: (client) => client.default.get('/getCompetitorMerchants',{
      params:{
        merchant_id:merchant_id
      }
    })
  }
}

export function getLocalityDetails(localityId){
  return {
    types: [LOCALITY_LOADING, LOCALITY_SUCCESS, LOCALITY_FAILURE],
    promise: (client) => client.default.get('/getLocalityDetails',{
      params:{
        localityId:localityId
      }
    })
  }
}

export function getLocalityBanners(merchantId){
  return {
    types: [BANNERS_LOADING, BANNERS_SUCCESS, BANNERS_FAILURE],
    promise: (client) => client.default.post('https://mcp.magicpin.in/mcp/merchant/banner',{
      data : {
        merchant_id:merchantId
      }
    })
  }
}

export function getLocalityLeaderBoard(merchantId){
  return {
    types: [LEADERBOARD_LOADING, LEADERBOARD_SUCCESS, LEADERBOARD_FAILURE],
    promise: (client) => client.default.get('/getLocalityLeaderboard',{
      params:{
        merchantId:merchantId,
        lastDays: 365
      }
    })
  }
}

export function getMerchantDeliveryDetails(accessToken, merchantId, merchantUserId){
  return {
    types: [MERCHANT_DELIVERY_DETAILS, MERCHANT_DELIVERY_DETAILS_SUCCESS, MERCHANT_DELIVERY_DETAILS_FAILURE],
    promise: (client) => client.default.post('/getMerchantDeliveryDetails',{
      data:{
        accessToken,
        merchantId: merchantId,
        merchantUserId: merchantUserId
      }
    })
  }
}

export function setAcceptingDeliveryTrue(accessToken, merchantUserId, timestamp, reason, userType){
  
  const sourceDetails = getUserDetail();
  const merchantObj = {merchantUserId: merchantUserId, acceptingDelivery: true, source: sourceDetails, allowedForAutomaticOn: true, deliveryTimingsEnd: timestamp, reason: reason, userType: userType}

  return {
    types: [SET_MERCHANT_DELIVERY_DETAILS, SET_MERCHANT_DELIVERY_DETAILS_SUCCESS, SET_MERCHANT_DELIVERY_DETAILS_FAILURE],
    promise: (client) => client.default.post('/setAcceptingDeliveryField',{
      data:{
        accessToken,
        merchantObj
      }
    })
  }
}

export function setAcceptingDeliveryFalse(accessToken, merchantUserId, timestamp, reason, userType, caller){
  let merchantObj
  const sourceDetails = getUserDetail();
  if(caller === "PID_PANEL") {
    merchantObj = {merchantUserId: merchantUserId, acceptingDelivery: false, source: sourceDetails, allowedForAutomaticOn: false, reason: reason, userType: userType}
  } else {
    merchantObj = {merchantUserId: merchantUserId, acceptingDelivery: false, source: sourceDetails, allowedForAutomaticOn: true, deliveryTimingsStart: timestamp, reason: reason, userType: userType}
  }
  // V1 payload structure
  // const merchantObj = {merchantUserId: merchantUserId, acceptingDelivery: false, source: "MERCHANT", allowedForAutomaticOn: false}
  
  // V2 payload structure
  // const merchantObj = {merchantUserId: merchantUserId, acceptingDelivery: false, source: "MERCHANT", allowedForAutomaticOn: true, deliveryTimingsStart: timestamp, reason: reason, userType: userType}


  return {
    types: [SET_MERCHANT_DELIVERY_DETAILS, SET_MERCHANT_DELIVERY_DETAILS_SUCCESS, SET_MERCHANT_DELIVERY_DETAILS_FAILURE],
    promise: (client) => client.default.post('/setAcceptingDeliveryField',{
      data:{
        accessToken,
        merchantObj
      }
    })
  }
}

export function getMerchantOrders(accessToken, merchantUserId){
  const action = {
    // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByMerchant",
    url: "http://ultron.magicpin.com/loms/viewOrdersByMerchant",
    method: 'get',
    headerAuth: true,
    params : {
      merchantUserId: merchantUserId,
      startOrderCreatedAt: moment().valueOf(),
      limit: 100
    }
  };
  return {
    types: [MERCHANT_ORDERS_LOADING, MERCHANT_ORDERS_LOADED_SUCCESS, MERCHANT_ORDERS_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeAction/viewOrdersByMerchant', {
      data: {accessToken, action}
    })
  }
}


export function getCancellationReasons(user_id, accessToken) {

  const action = {
    url: "https://webapi.magicpin.in/loms/getRejectionReason?type=MERCHANT",
    method: 'get',
    headerAuth: true,
    params : {user_id}
  };


  return {
    types: [
      CANCEL_REASONS_LOADING,
      CANCEL_REASONS_SUCCESS,
      CANCEL_REASONS_FAILURE,
    ],
    user_id: user_id,
    promise: (client) => client.default.post('/takeAction/getRejectionReason', {
      data: {accessToken, action}
    })
  };
}

export function getMerchantSingleOrder(accessToken, merchantUserId, orderId){
  const action = {
     //url: "https://ultrontest.magicpin.com/loms/test/viewOrderDetailByMerchant",
     url: "http://ultron.magicpin.com/loms/viewOrderDetailByMerchant",
    method: 'get',
    headerAuth: true,
    params : {
      merchantUserId: merchantUserId,
      orderId: orderId
    }
  };
  return {
    types: [MERCHANT_SINGLE_ORDER_LOADING, MERCHANT_SINGLE_ORDER_LOADED_SUCCESS, MERCHANT_SINGLE_ORDER_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeAction/viewSingleOrderByMerchant', {
      data: {accessToken, action}
    })
  }
}

export function getPaginatedOrders(accessToken, merchantUserId, filter, orderOffset) {
  let action;
  if(filter == "NONE") {
    action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByMerchant",
      url: "http://ultron.magicpin.com/loms/viewOrdersByMerchant",
      method: 'get',
      headerAuth: true,
      params: {
        merchantUserId: merchantUserId,
        limit: 20,
        // startOrderId: orderOffset
        startOrderCreatedAt: orderOffset
      }
    };
  } else {
    action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByMerchant",
      url: "http://ultron.magicpin.com/loms/viewOrdersByMerchant",
      method: 'get',
      headerAuth: true,
      params: {
        merchantUserId: merchantUserId,
        limit: 20,
        orderStatus: filter,
        // startOrderId: orderOffset
        startOrderCreatedAt: orderOffset
      }
    };
  }
  return {
    types: [MERCHANT_PAGINATED_ORDERS_LOADING, MERCHANT_PAGINATED_ORDERS_LOADED_SUCCESS, MERCHANT_PAGINATED_ORDERS_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeAction/viewPaginatedOrders', {
      data: {accessToken, action}
    })
  }
}



/**
 * 
 * PID
 */


export function getPidOrdersByOrderStatus(accessToken, merchantUserId, filter, paginationOffsetMultiplier=1) {
  let start = moment().subtract(((paginationOffsetMultiplier - 1) * 7), 'days').valueOf();
  let end = moment().subtract((paginationOffsetMultiplier * 7), 'days').valueOf();
  let action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByParentMerchant/v2",
      // url: "https://ultrontest.magicpin.com/loms/test3/viewOrdersByParentMerchant/v2",
      url: 'https://webapi.magicpin.in/loms/viewOrdersByParentMerchant/v2',
      method: 'get',
      headerAuth: true,
      params: {
        merchantUserId: merchantUserId,
        // startOrderCreatedAt: moment().valueOf(),
        startOrderCreatedAt: start,
        endOrderCreatedAt: end,
        limit: 100,
        orderStatus: filter
      }
    };
  
  return {
    types: [PARENT_MERCHANT_ORDERS_LOADING, PARENT_MERCHANT_ORDERS_LOADED_SUCCESS, PARENT_MERCHANT_ORDERS_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeActionByParentMerchant/viewOrdersByParent', {
      data: {accessToken, action}
    })
  }
}

export function getAllPidOrders(accessToken, merchantUserId) {
  let start = moment().valueOf();
  let end = moment().subtract((7), 'days').valueOf();
  let action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByParentMerchant/v2",
      // url: "https://ultrontest.magicpin.com/loms/test3/viewOrdersByParentMerchant/v2",
      url: 'https://webapi.magicpin.in/loms/viewOrdersByParentMerchant/v2',
      method: 'get',
      headerAuth: true,
      params: {
        merchantUserId: merchantUserId,
        // startOrderCreatedAt: moment().valueOf(),
        startOrderCreatedAt: start,
        endOrderCreatedAt: end,
        limit: 100
      }
    };
  
  return {
    types: [PARENT_MERCHANT_ALL_ORDERS_LOADING, PARENT_MERCHANT_ALL_ORDERS_LOADED_SUCCESS, PARENT_MERCHANT_ALL_ORDERS_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeActionByParentMerchant/viewAllOrdersByParent', {
      data: {accessToken, action}
    })
  }
}

export function getAllPidPaginatedOrders(accessToken, merchantUserId, paginationOffsetMultiplier=1) {
  let start = moment().subtract(((paginationOffsetMultiplier - 1) * 7), 'days').valueOf();
  let end = moment().subtract((paginationOffsetMultiplier * 7), 'days').valueOf();
  let action = {
      // url: "https://ultrontest.magicpin.com/loms/test/viewOrdersByParentMerchant/v2",
      // url: "https://ultrontest.magicpin.com/loms/test3/viewOrdersByParentMerchant/v2",
      url: 'https://webapi.magicpin.in/loms/viewOrdersByParentMerchant/v2',
      method: 'get',
      headerAuth: true,
      params: {
        merchantUserId: merchantUserId,
        // startOrderCreatedAt: moment().valueOf(),
        startOrderCreatedAt: start,
        endOrderCreatedAt: end,
        limit: 100
      }
    };
  
  return {
    types: [PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADING, PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_SUCCESS, PARENT_MERCHANT_ALL_PAGINATED_ORDERS_LOADED_FAILURE],
    promise: (client) => client.default.post('/takeActionByParentMerchant/getAllPidPaginatedOrders', {
      data: {accessToken, action}
    })
  }
}

export function didMerchantCreateNYVouchers(accessToken, merchantId, merchantUserId, salt) {
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': salt,
    'merchantId': merchantId
  }
  let action = {
      url: 'http://m-partners-2:8080/ultron-mp/merchant/voucherRequested',
      method: 'get',
      data:{
        headers: headers
      }
      
    };
  
  return {
    types: [DID_MERCHANT_CREATE_NY_VOUCHERS_LOADING, DID_MERCHANT_CREATE_NY_VOUCHERS_SUCCESSS, DID_MERCHANT_CREATE_NY_VOUCHERS_FAILURE],
    promise: (client) => client.default.post('/takeActionForUltronMp/didMerchantCreateNYVoucher', {
      data: {accessToken, action}
    })
  }
}

export function createNYVoucherRequest(accessToken, merchantId, merchantUserId, salt, voucherName, price, discount, description) {
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': salt,
    'merchantId': merchantId
  }
  let payload = {}
  payload["merchantId"] = merchantId.toString()
  payload["datetime"] = voucherName ? voucherName.toString() : ""
  payload["price"] = price ? price.toString() : ""
  payload["discount"] = discount ? discount.toString() : ""
  payload["description"] = description ? description.toString() + ` [${moment().format('YYYY-MM-DD HH:mm:ss')}]` : `[${moment().format('YYYY-MM-DD HH:mm:ss')}]`
  
  let action = {
      url: 'http://m-partners-2:8080/ultron-mp/merchant/createNewYearVoucherRequest',
      method: 'post',
      data:{
        headers: headers,
        payload: payload
      }
      
    };
  
  return {
    types: [CREATE_NY_VOUCHER_REQUEST_LOADING, CREATE_NY_VOUCHER_REQUEST_SUCCESS, CREATE_NY_VOUCHER_REQUEST_FAILURE],
    promise: (client) => client.default.post('/takeActionForUltronMp/createNYVoucherRequest', {
      data: {accessToken, action}
    })
  }
}

export function updateOrderStatusByParent(merchantUserId, merchantAuthToken, accessToken, orderId, status, userType, rejectionId, deliveryType=null, retryCount, merchantKpt=null, itemPriceChanges=null) {

  let headerAuthUserId = merchantUserId
  let userTypeInPayload = userType === userTypes.ADMIN ? "ADMIN" : "MERCHANT"
  let headerAuthToken = merchantAuthToken
  let isAdmin= userType === userTypes.ADMIN
  let url;
  let action;
  if(isAdmin){
    //  url = "https://ultrontest.magicpin.com/loms/test/updateOrderStatusByAdmin"
    url= "http://ultron.magicpin.com/loms/updateOrderStatusByAdmin"
    
    status == "CANCELLED" 
    ? 
      action = {
        url: url,
        method: 'post',
        headerAuth: false,
        applyMerchantHeaderAuthForPidLogin: true,
        data:{
          orderId: orderId,
          status: status,
          userType: userTypeInPayload,
          rejectionId: rejectionId
        }
      }
    :
      action = {
        url: url,
        method: 'post',
        headerAuth: false,
        applyMerchantHeaderAuthForPidLogin: true,
        data:{
          orderId: orderId,
          status: status,
          userType: userTypeInPayload,
          retryCount: retryCount ? retryCount : 0
        }
      }
  }
  else{
    //  url= "https://ultrontest.magicpin.com/loms/test/updateOrderStatusByMerchant"
    url= "http://ultron.magicpin.com/loms/updateOrderStatusByMerchant"
    action = {
      url: url,
      method: 'post',
      headerAuth: false,
      applyMerchantHeaderAuthForPidLogin: true,
      data:{
        orderId: orderId,
        status: status,
        userType: userTypeInPayload,
        rejectionId: rejectionId
      }
    };
  }

  if(deliveryType){
    action.data.deliveryInitiator = deliveryType
  }
  if(merchantKpt) {
    action.data['merchantKpt'] = Number(merchantKpt)
  }
  if(status === "ACCEPTED" && itemPriceChanges && itemPriceChanges.length > 0) {
    action.data['amountChanges'] = itemPriceChanges;
  }
  // console.log("Payload: ", action.data)
  return {
    types: [UPDATE_ORDERS_STATUS_LOADING, UPDATE_ORDERS_STATUS_SUCCESS, UPDATE_ORDERS_STATUS_FAILURE],
    promise: (client) => client.default.post('/takeActionByParentMerchant/updateOrderStatusByMerchant', {
      data: {accessToken, action, headerAuthUserId, headerAuthToken}
    })
  };
}






/**
 * 
 *PID
 */



export function verifyOrder(accessToken, orderId, otp) {
  const action = {
    url: 'http://ultron.magicpin.com/loms/verifyOrder',
    method: 'post',
    headerAuth: true,
    data: {
      orderId: orderId,
      otp: otp
    }
  }
  return {
    types: [VERIFY_OTP_LOADING, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE],
    promise: (client) => client.default.post('/takeAction/verifyOrder', {
      data: {accessToken, action}
    })
  };
}

export function getSearchResults(accessToken, searchQuery){
  return {
    types: [GET_SEARCH_RESULTS, GET_SEARCH_RESULTS_SUCCESS, GET_SEARCH_RESULTS_FAILURE],
    promise:(client) => client.default.get(`https://aryan.magicpin.in/search/item/v1?query=${searchQuery}&rows=10`)
  }
}

export function updateOrderStatus(tokenValsData=null, accessToken, orderId, status, userType, rejectionId, deliveryType=null, retryCount, user, merchantDeliveryDetailsObj=null, merchantKpt=null, magicpinDeliveryByMerchant=null, itemPriceChanges=null){
  let isAdmin= userType === userTypes.ADMIN
  let url;
  let action;
  if(isAdmin){
    // url = "https://ultrontest.magicpin.com/loms/test/updateOrderStatusByAdmin"
    url= "http://ultron.magicpin.com/loms/updateOrderStatusByAdmin"
    
    status == "CANCELLED" 
    ? 
      action = {
        url: url,
        method: 'post',
        headerAuth: true,
        data:{
          orderId: orderId,
          status: status,
          userType: userType,
          rejectionId: rejectionId
        }
      }
    :
      action = {
        url: url,
        method: 'post',
        headerAuth: true,
        data:{
          orderId: orderId,
          status: status,
          userType: userType,
          retryCount: retryCount ? retryCount : 0
        }
      }
  }
  else{
    // url= "https://ultrontest.magicpin.com/loms/test/updateOrderStatusByMerchant"
    url= "http://ultron.magicpin.com/loms/updateOrderStatusByMerchant"
    action = {
      url: url,
      method: 'post',
      headerAuth: true,
      data:{
        orderId: orderId,
        status: status,
        userType: userType,
        rejectionId: rejectionId
      }
    };
  }

  if(deliveryType){
    action.data.deliveryInitiator = deliveryType
  }

  if(merchantDeliveryDetailsObj) {
    action.data["merchantDeliveryDetails"] = merchantDeliveryDetailsObj;
  }

  if(merchantKpt) {
    action.data['merchantKpt'] = Number(merchantKpt)
  }

  if(magicpinDeliveryByMerchant) {
    action.data['magicpinDeliveryByMerchant'] = true;
  }
  
  if(status === "ACCEPTED" && itemPriceChanges && itemPriceChanges.length > 0) {
    action.data['amountChanges'] = itemPriceChanges;
  }

  if(isAdmin) {
    // console.log("Payload: ", action.data)
    return {
      types: [UPDATE_ORDERS_STATUS_LOADING, UPDATE_ORDERS_STATUS_SUCCESS, UPDATE_ORDERS_STATUS_FAILURE],
      promise: (client) => client.default.post('/takeAction/updateOrderStatusByMerchant', {
        data: {accessToken, action}
      })
    };
  } else {
    // console.log("saltFromAuth: ", saltFromAuth)
    // console.log("this.props.user.salt : ", user.salt)
    // console.log("Initial state: ", tokenValsData)
    // tokenValsData && tokenValsData.salt 
    // && console.log("salt from accessToken decryption: ", tokenValsData.salt)
    let salt;
    salt = tokenValsData && tokenValsData.salt ? tokenValsData.salt
     : !!saltFromAuth ? saltFromAuth : user.salt;

    // console.log("Salt being used for updateOrder call: ", salt)
    // console.log("Payload: ", action.data)
    return updateOrderStatusCommon(action.data, user.userId, salt, {source: "MERCHANT_PORTAL"})
  }
}

export function updateOrderValue(accessToken, orderId, billValue, items, deliveryType){
  items = items.filter(item=> {return(item.itemType !== "SUGGESTED" || item.status !=="UNAVAILABLE")})
  items.map(item=>{ 
    if(item.itemType=="SUGGESTED")
      item.quantity=item.qtyUpperBound
  })
  let data={}
  if(deliveryType){
    data={
      orderId: orderId,
      amount: parseFloat(parseFloat(billValue).toFixed(1)),
      items: items,
      deliveryInitiator: deliveryType
    }
  }
  else{
    data={
      orderId: orderId,
      amount: parseFloat(parseFloat(billValue).toFixed(1)),
      items: items,
    }
  }
  const action = {
    // url: "https://ultrontest.magicpin.com/loms/test/updateOrderAmountByMerchant",
    url: "http://ultron.magicpin.com/loms/updateOrderAmountByMerchant",
    method: 'post',
    headerAuth: true,
    data
  };
  return {
    types: [UPDATE_ORDERS_VALUE_LOADING, UPDATE_ORDERS_VALUE_SUCCESS, UPDATE_ORDERS_VALUE_FAILURE],
    promise: (client) => client.default.post('/takeAction/updateOrderStatusByMerchant', {
      data: {accessToken, action}
    })
  };
}

export function getMagicOrderStats(accessToken, startDate, endDate){
  return {
    types: [GET_ORDER_STATS_LOADING, GET_ORDER_STATS_SUCCESS, GET_ORDER_STATS_FAILURE],
    promise: (client) => client.default.post('/getMagicOrderStats', {
      data: {accessToken, startDate, endDate}
    })
  };
}



export function updateTrackingLink(accessToken, orderId, trackingLink, deliveryProvider){
  return {
    types: [UPDATE_TRACK_LINK_LOADING, UPDATE_TRACK_LINK_SUCCESS, UPDATE_TRACK_LINK_FAILURE],
    promise: (client) => client.default.post('/updateTrackingLink',{
      data:{
        accessToken,
        orderId: orderId,
        trackingUrl: trackingLink,
        deliveryProvider: deliveryProvider
      }
    })
  }
}

export function updateDeliveryAgentPhoneNumber(accessToken, orderId, phoneNumber, deliveryProvider){
  return {
    types: [UPDATE_AGENT_PHONE_NUMBER_LOADING, UPDATE_AGENT_PHONE_NUMBER_SUCCESS, UPDATE_AGENT_PHONE_NUMBER_FAILURE],
    promise: (client) => client.default.post('/updateTrackingLink',{
      data:{
        accessToken,
        orderId: orderId,
        trackingPhoneNo: phoneNumber,
        deliveryProvider: deliveryProvider
      }
    })
  }
}

export function setSurveyFlag(){
  return{
    type:SURVEY_FLAG
  }
}

export function unsubscribeRemarketing(phone, markedBy){
  return{
    types:[UNSUBSRIBE_LOADING, UNSUBSRIBE_SUCCESS, UNSUBSRIBE_FAILURE ],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/shadow_merchant/',{
      params:{
        phone:phone,
        marked_by:markedBy
      }
    })
  }
}

export function markInactive(phone){
  return{
    types:[INACTIVE_LOADING, INACTIVE_SUCCESS, INACTIVE_FAILURE ],
    promise: (client) => client.default.get('https://mcp.magicpin.in/mcp/merchant/contact/markinactive/',{
      params:{
        phone:phone
      }
    })
  }
}

export function payViaCheque(info){
  return {
    types:[CHEQUE_LOADING, CHEQUE_SUCCESS, CHEQUE_FAILURE],
    promise: (client) => client.default.post('https://aryan.magicpin.in/merchant/completeMerchantPackageSubscriptionDirect',{
      data:info
    })
  }
}

export function activatePrivilegeAccount(payload){
  return {
    types:[PRIVILEGE_ACTIVATION_LOADING, PRIVILEGE_ACTIVATION_SUCCESS, PRIVILEGE_ACTIVATION_FAILURE],
    promise:(client) => client.default.post('/activatePrivilegeAccount', {
      data:{payload}
    })
  }
}

export function createComissionPSR(payload){
  return {
    types:[CREATE_PSR_LOADING, CREATE_PSR_SUCCESS, CREATE_PSR_FAILURE],
    promise:(client) => client.default.post('/createCommissionPSR', {
      data:{payload}
    })
  }
}

export function uploadBill(accessToken, file, orderId){
  var data  = new FormData();
  data.append('merchant_single_photo', file);
  data.append('uploadedBy','MERCHANT');
  data.append('orderId', orderId);
  data.append('accessToken', accessToken);

  return {
  types:[BILL_UPLOAD, BILL_UPLOAD_SUCCESS, BILL_UPLOAD_FAIL],
    promise:(client) => client.default.post('/uploadBill', {
      data
    })
  }
}

export function uploadChatImage(accessToken, file, orderId){
  var data  = new FormData();
  data.append('merchant_single_photo', file);
  data.append('accessToken', accessToken);

  return {
  types:[CHAT_IMAGE_UPLOADING, CHAT_IMAGE_UPLOADED_SUCCESS, CHAT_IMAGE_UPLOADED_ERROR],
    promise:(client) => client.default.post('/uploadChatImage', {
      data
    })
  }
}

export function getSuggestedPrice(accessToken, merchantUserId, listItemIds){
  const url=`http://mcs.gc.magicpin.in/catalog/v1/merchant/${merchantUserId}/items?ids=${listItemIds}`
  // const url=`http://search5.magicpin.com:8080/catalog2/v1/merchant/${merchantUserId}/items?ids=${listItemIds}`
  return {
    types:[SUGGESTED_PRICE_LOADING, SUGGESTED_PRICE_SUCCESS, SUGGESTED_PRICE_FAILURE],
      promise:(client) => client.default.post('/getSuggestedPrice',{
        data:{
          url,
          accessToken
        }
      })
    }
}

export function uploadCatalogue(accessToken, file, fileName){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : fileName);
  data.append('accessToken', accessToken);
  return {
    types:[UPLOAD_CSV, CSV_UPLOADED, CSV_FAILED],
    promise:(client) => client.default.post('/uploadCatalogue', {
      data
    })
  }
}

export function uploadDeliveryPreference(accessToken, selfDeliveryOption, thirdPartyDeliveryOption, costPaidByYou3PL, minOrderVal3PL, maxRadiusSelfDelivery, minOrderValSelfDelivery, deliveryFeesBelowMinOrderVal){
  return {
    types:[DELIVERY_PREFERENCE, DELIVERY_PREFERENCE_SUCCESS, DELIVERY_PREFERENCE_FAILED],
    promise:(client) => client.default.post('/uploadDeliveryPreference', {
      data:{
        accessToken,
        selfDeliveryOption: selfDeliveryOption,
        thirdPartyDeliveryOption: thirdPartyDeliveryOption,
        costPaidByYou3PL: costPaidByYou3PL,
        minOrderVal3PL: minOrderVal3PL,
        maxRadiusSelfDelivery: maxRadiusSelfDelivery,
        minOrderValSelfDelivery: minOrderValSelfDelivery,
        deliveryFeesBelowMinOrderVal: deliveryFeesBelowMinOrderVal
      }
    })
  }
}


export function getOrderHereAlias(accessToken){
  return{
    types:[ORDER_HERE_ALIAS_LOADING, ORDER_HERE_ALIAS_SUCCESS, ORDER_HERE_ALIAS_FAILED],
    promise:(client) => client.default.post('/getOrderHereAlias', {
      data:{ accessToken }
    })
  }
}

export function getMagicstoreText(){
  return{
    types:[MAGICSTORE_TEXT_LOADING, MAGICSTORE_TEXT_SUCCESS, MAGICSTORE_TEXT_FAILED],
    promise:(client) => client.default.get('/getMagicstoreCDNText')
  }
}

export function getSuggestedAlias(storeName,locality){
  return{
    types:[SUGGESTED_ALIAS_LOADING, SUGGESTED_ALIAS_SUCCESS, SUGGESTED_ALIAS_FAILED],
    promise:(client) => client.default.get('/getSuggestedAlias', {
      params:{ storeName: storeName, locality: locality }
    })
  }
}

export function createAlias(userId,alias){
  return{
    types:[CREATE_ALIAS_LOADING, CREATE_ALIAS_SUCCESS, CREATE_ALIAS_FAILED],
    promise:(client) => client.default.post('/createAlias', {
      data:{ userId, alias: alias}
    })
  }
}

export function getAlias(accessToken,alias){
  return{
    types:[GET_ALIAS_LOADING, GET_ALIAS_SUCCESS, GET_ALIAS_FAILED],
    promise:(client) => client.default.post('/getAlias', {
      data:{accessToken}
    })
  }
}

export function getCatalogItems(accessToken){
  return{
    types:[GET_ITEM_LIST_CATALOG_LOADING, GET_ITEM_LIST_CATALOG_SUCCESS, GET_ITEM_LIST_CATALOG_FAILURE],
    promise:(client) => client.default.post('/getCatalogItems', {
      data:{accessToken}
    })
  }
}

export function saveOrModifyCatalogName(accessToken, itemName, requestType, isCategoryLevelUpdate , categoryId, subCategoryId){
  return{
    types:[SAVE_OR_MODIFY_CATALOG_NAME_LOADING, SAVE_OR_MODIFY_CATALOG_NAME_SUCCESS, SAVE_OR_MODIFY_CATALOG_NAME_FAILURE],
    promise:(client) => client.default.post('/saveOrModifyCatalogName', {
      data:{accessToken, itemName, requestType, isCategoryLevelUpdate , categoryId, subCategoryId}
    })
  }
}

export function markCatalogUnavailable(accessToken, element, type, merchantUserId) {
// http://search5.magicpin.com:8080/catalog/merchant/4338607/category/151
  let urlToBeUsed
    if(type === "CATEGORY") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/category/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/category/`
    } else if(type === "SUB_CATEGORY") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/subcategory/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/subcategory/`
    } else if(type === "ITEM") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/item/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/item/`
    }
    let action
    if(type === "ITEM") {
      element.inStock = false
      action = {
        url: `${urlToBeUsed}${element.id}`,
        method: 'put',
        headerAuth: false,
        data : element
      };
    }
    // if(type === "ITEM" && element.subcategoryId !== 0) {
    //   action = {
    //     url: `${urlToBeUsed}${element.id}`,
    //     method: 'put',
    //     headerAuth: false,
    //     data : {
    //       name: element.name,
    //       inStock: false,
    //       subcategoryId: element.subcategoryId
    //     }
        
    //   };
    // } 
    // else if(type === "ITEM" && element.categoryId !== 0) {
    //   action = {
    //     url: `${urlToBeUsed}${element.id}`,
    //     method: 'put',
    //     headerAuth: false,
    //     data : {
    //       name: element.name,
    //       inStock: false,
    //       categoryId: element.categoryId
    //     }
        
    //   };
    // } 
    else {
      action = {
        url: `${urlToBeUsed}${element.id}`,
        method: 'put',
        headerAuth: false,
        data : {
          name: element.name,
          inStock: false
        }
        
      };
    }
  return {
    types: [MARK_CATALOG_UNAVAILABLE, MARK_CATALOG_UNAVAILABLE_SUCCESS, MARK_CATALOG_UNAVAILABLE_FAILURE],
    promise: (client) => client.default.post('/takeAction/markCatalogUnavailable', {
      data: {accessToken, action}
    })
  }
}

export function markCatalogAvailable(accessToken, element, type, merchantUserId) {
  // http://search5.magicpin.com:8080/catalog/merchant/4338607/category/151
    let urlToBeUsed
    if(type === "CATEGORY") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/category/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/category/`

    } else if(type === "SUB_CATEGORY") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/subcategory/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/subcategory/`

    } else if(type === "ITEM") {
      // urlToBeUsed = `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/item/`
      urlToBeUsed = `http://mcs.service/catalog/merchant/${merchantUserId}/item/`
    }
    
    let action
    if(type === "ITEM") {
      element.inStock = true
      action = {
        url: `${urlToBeUsed}${element.id}`,
        method: 'put',
        headerAuth: false,
        data : element
        
      };
    }
    // if(type === "ITEM" && element.subcategoryId !== 0) {
    //   action = {
    //     url: `${urlToBeUsed}${element.id}`,
    //     method: 'put',
    //     headerAuth: false,
    //     data : {
    //       name: element.name,
    //       inStock: true,
    //       subcategoryId: element.subcategoryId
    //     }
        
    //   };
    // }
    //  else if(type === "ITEM" && element.categoryId !== 0) {
    //   action = {
    //     url: `${urlToBeUsed}${element.id}`,
    //     method: 'put',
    //     headerAuth: false,
    //     data : {
    //       name: element.name,
    //       inStock: true,
    //       categoryId: element.categoryId
    //     }
    //   };
    // } 
    else {
      action = {
        url: `${urlToBeUsed}${element.id}`,
        method: 'put',
        headerAuth: false,
        data : {
          name: element.name,
          inStock: true
        }
        
      };
    }
    return {
      types: [MARK_CATALOG_AVAILABLE, MARK_CATALOG_AVAILABLE_SUCCESS, MARK_CATALOG_AVAILABLE_FAILURE],
      promise: (client) => client.default.post('/takeAction/markCatalogAvailable', {
        data: {accessToken, action}
      })
    }
  }

  export function updateDiscountBatch(accessToken, itemToDiscountArray, merchantUserId) {
    let payload = []
    itemToDiscountArray.map(itemToDiscount => {
      let itemObj = {}
      itemObj['id'] = itemToDiscount[0].id
      itemObj['percentDiscount'] = itemToDiscount[1]
      payload.push(itemObj)
    })

    const action = {
      url: `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/item`,
      method: 'put',
      headerAuth: false,
      data : payload
    };
    return {
      types: [UPDATE_DISCOUNT_BATCH, UPDATE_DISCOUNT_BATCH_SUCCESS, UPDATE_DISCOUNT_BATCH_FAILURE],
      promise: (client) => client.default.post('/takeAction/updateDiscountBatch', {
        data: {accessToken, action}
      })
    }
  }


  export function updateDiscountCatalog(accessToken, discountPercentage, catalogId, merchantUserId) {
    const action = {
      url: `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/menu/${catalogId}`,
      method: 'put',
      headerAuth: false,
      data : {
        percentDiscount: discountPercentage
      }
    };
    return {
      types: [UPDATE_DISCOUNT_CATALOG, UPDATE_DISCOUNT_CATALOG_SUCCESS, UPDATE_DISCOUNT_CATALOG_FAILURE],
      promise: (client) => client.default.post('/takeAction/updateDiscountCatalog', {
        data: {accessToken, action}
      })
    }
  }

  export function updateGSTCatalog(accessToken, GSTPercentage, merchantUserId) {
    const action = {
      url: `http://mcs.service/catalog/merchant/${merchantUserId}/menu/tax`,
      method: 'put',
      headerAuth: false,
      data : {
        tax: GSTPercentage
      }
    };
    return {
      types: [UPDATE_GST_CATALOG, UPDATE_GST_CATALOG_SUCCESS, UPDATE_GST_CATALOG_FAILURE],
      promise: (client) => client.default.post('/takeAction/updateGSTCatalog', {
        data: {accessToken, action}
      })
    }
  }

  // will change to ultron mp wrapper once it starts supporting PUT request

  // export function updateGSTCatalog(access_token, user_id, GSTPercentage) {
  //   let payload = {
  //     tax : GSTPercentage
  //   }
  //   const action = {
  //     method: 'POST',
  //     apiName: "UPDATE_GST_PERCENTAGE_BULK",
  //     apiUrl: `http://mcs.service/catalog/merchant/${user_id}/menu/tax`,
  //     payload: payload
  //   };
  //   return {
  //     types: [UPDATE_GST_CATALOG, UPDATE_GST_CATALOG_SUCCESS, UPDATE_GST_CATALOG_FAILURE],
  //     promise: (client) => client.ultron_mp.post('merchant/takeActionUltronMp', {
  //       headers: {
  //         "auth-user-token": access_token,
  //         "auth-userid": user_id,
  //         "Content-Type": "application/json"
  //       },
  //       data: action
  //     })
  //   }
  // }

  export function checkIfCatalogExists(accessToken, merchantUserId) {
    let action = {
      url: `http://mcs.gc.magicpin.in/catalog/merchant/${merchantUserId}/info`,
      method: 'get',
      headerAuth: false,
    }
    return {
      types: [CHECK_IF_CATALOG_EXISTS,CHECK_IF_CATALOG_EXISTS_SUCCESS,CHECK_IF_CATALOG_EXISTS_FAILURE],
      promise: (client) => client.default.post('/takeAction/doesCatalogExist', {
        data: {accessToken, action}
      })
    }
  }
/**
 * const MARK_CATALOG_AVAILABLE = 'newMerchantLanding/MARK_CATALOG_AVAILABLE'
const MARK_CATALOG_AVAILABLE_SUCCESS = 'newMerchantLanding/MARK_CATALOG_AVAILABLE_SUCCESS'
const MARK_CATALOG_AVAILABLE_FAILURE = 'newMerchantLanding/MARK_CATALOG_AVAILABLE_FAILURE'
 */

export function reportErrorToMetricMonitor(accessToken, payload) {

  // if(payload)
  //   payload.emailList = payload.emailList ? payload.emailList : DEFAULT_TEAM_EMAILS

  // const action = {
  //   url: `http://cbs.service/metric/storeMonitor`,
  //   method: 'post',
  //   headerAuth: false,
  //   data : payload
  // };
  return {
    types: [REPORT_ERROR_TO_METRIC_MONITOR_LOADING, REPORT_ERROR_TO_METRIC_MONITOR_SUCCESS, REPORT_ERROR_TO_METRIC_MONITOR_FAILURE],
    promise: (client) => client.default.post('/metricMonitorUnauth', {
      data: {payload}
    })
  }
}

export function reportErrorToMetricMonitorUnauthenticated(payload) {

  return {
    types: [REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_LOADING, REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_SUCCESS, REPORT_ERROR_TO_METRIC_MONITOR_UNAUTH_FAILURE],
    promise: (client) => client.default.post('/metricMonitorUnauth', {
      data: {payload}
    })
  }
}

export function saveOrModifyItem(accessToken, item, itemId, file, requestType){
  var data  = new FormData();
  data.append('merchant_single_photo', file)
  data.append('item', JSON.stringify(item));
  data.append('accessToken', accessToken);
  if(itemId >0){
    data.append('itemId', itemId)
  }
  if(requestType){
    data.append('requestType', requestType)
  }
  return{
    types:[SAVE_OR_MODIFY_ITEM_LOADING, SAVE_OR_MODIFY_ITEM_SUCCESS, SAVE_OR_MODIFY_ITEM_FAILURE],
    promise:(client) => client.default.post('/saveOrModifyItem', {
      data
    })
  }
}

export function getMerchantInfo(accessToken, merchantUserId) {
    return{
      types: [GET_MERCHANT_INFO, GET_MERCHANT_INFO_SUCCESS, GET_MERCHANT_INFO_FAILURE],
      promise: (client) => client.default.post('/getMerchantDetailsFromSolr', {
        data: {accessToken,
          merchantUserId: merchantUserId}
      })
    }
  }

export function createTestOrder(accessToken,categoryId,isOnboardingTestOrder){
  return{
    types:[CREATE_ORDER_LOADING, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILED],
    promise:(client) => client.default.post('/createTestOrder', {
      data:{accessToken,categoryId, isOnboardingTestOrder}
    })
  }
}

export function getDeliveryPartnersByLocality(accessToken,localityId,categoryId){
  return{
    types:[GET_DEL_PARTNERS_LOADING, GET_DEL_PARTNERS_SUCCESS, GET_DEL_PARTNERS_FAILED],
    promise:(client) => client.default.post('/getDeliveryPartnersByLocality', {
      data:{accessToken,localityId:localityId,categoryId}
    })
  }
}

export function activateMagicstore(accessToken,subscriptionId){
  return{
    types:[ACTIVATE_MAGICSTORE_LOADING, ACTIVATE_MAGICSTORE_SUCCESS, ACTIVATE_MAGICSTORE_FAILED],
    promise:(client) => client.default.post('/activateMagicstore', {
      data:{accessToken,subscriptionId:subscriptionId}
    })
  }
}

export function isMenuAvailable(accessToken){
  return{
    types:[IS_MENU_AVAILABLE_LOADING, IS_MENU_AVAILABLE_SUCCESS, IS_MENU_AVAILABLE_FAILED],
    promise:(client) => client.default.post('/isMenuAvailable', {
      data:{ accessToken }
    })
  }
}

export function uploadMenuItems(accessToken,menu){
  return{
    types:[UPLOAD_MENU_LOADING, UPLOAD_MENU_SUCCESS, UPLOAD_MENU_FAILED],
    promise:(client) => client.default.post('/uploadMenuItems', {
      data:{ accessToken, menu }
    })
  }
}

export function updateDeliveryPrefs(accessToken,salt,magicpinDelivery,merchantDelivery,categoryId){
  let saltToBeUsed = !!saltFromAuth ? saltFromAuth : salt
  return{
    types:[UPDATE_DELIVERY_PREF_LOADING, UPDATE_DELIVERY_PREF_SUCCESS, UPDATE_DELIVERY_PREF_FAILED],
    promise:(client) => client.default.post('/updateDeliveryRules', {
      data:{ accessToken, magicpinDelivery, merchantDelivery, categoryId, saltToBeUsed }
    })
  }
}

export function saltSyncRedisCheck(merchantId){
  return{
    types:[SALT_SYNC_REDIS_CHECK_LOADING, SALT_SYNC_REDIS_CHECK_SUCCESS, SALT_SYNC_REDIS_CHECK_FAILURE],
    promise:(client) => client.default.post('/saltSyncRedisCheck', {
      data:{ merchantId: merchantId }
    })
  }
}

export function createTestOrderFromAdmin(accessToken, merchantId){
  return{
    types:[CREATE_TEST_ORDER_ADMIN_LOADING, CREATE_TEST_ORDER_ADMIN_SUCCESS, CREATE_TEST_ORDER_ADMIN_FAILURE],
    promise:(client) => client.default.post('/createTestOrderFromAdmin', {
      data:{ accessToken, merchantId }
    })
  }
}

export function reportWrongMrp(accessToken, itemId, itemPrice, correctMrp, merchantName){
  return{
    types:[REPORT_WRONG_MRP_LOADING, REPORT_WRONG_MRP_SUCCESS, REPORT_WRONG_MRP_FAILURE],
    promise:(client) => client.default.post('/reportWrongMrp', {
      data:{ accessToken, itemId, itemPrice, correctMrp, merchantName}
    })
  }
}

export function enableOrderBooking(accessToken){
  const userDetails = getUserDetail();
  const createdBy = `enableOrderBooking_${userDetails}`
  return{
    types:[ENABLE_ORDER_BOOKING_LOADING, ENABLE_ORDER_BOOKING_SUCCESS, ENABLE_ORDER_BOOKING_FAILURE],
    promise:(client) => client.default.post('/enableOrderBooking', {
      data:{ accessToken, createdBy }
    })
  }
}

export function hasAcceptedOrders(accessToken){
  return{
    types:[HAS_ACCEPTED_ORDERS_LOADING, HAS_ACCEPTED_ORDERS_SUCCESS, HAS_ACCEPTED_ORDERS_FAILURE],
    promise:(client) => client.default.post('/hasAcceptedOrders', {
        data:{ accessToken }
    })
  }
}

export function getVersionCode(accessToken){
  const version = appVersion + `.${moment().unix()}`;
  return{
    types:[GET_VERSION_CODE_LOADING, GET_VERSION_CODE_SUCCESS, GET_VERSION_CODE_FAILURE],
    promise:(client) => client.magicpin_in.get(`/partners/meta.json?${version}`)
  }
}

export function getMerchantRewardDayWise(accessToken, startDate, endDate){
  return{
    types:[GET_MERCHANT_REWARD_DAY_WISE_LOADING, GET_MERCHANT_REWARD_DAY_WISE_SUCCESS, GET_MERCHANT_REWARD_DAY_WISE_FAILURE],
    promise:(client) => client.default.post('/getMerchantRewardDayWise', {
      data:{ accessToken, startDate, endDate }
    })
  }
}

export function getMerchantRewardBrandWise(accessToken){
  return{
    types:[GET_MERCHANT_BRAND_WISE_LOADING, GET_MERCHANT_BRAND_WISE_SUCCESS, GET_MERCHANT_BRAND_WISE_FAILURE],
    promise:(client) => client.default.post('/getMerchantRewardBrandWise', {
      data:{ accessToken }
    })
  }
}

export function writeRelistRequestToGoogleSheet(accessToken, merchantName, merchantId, contactNumber){
  return {
     types: [WRITE_RELIST_REQUEST_LOADING, WRITE_RELIST_REQUEST_SUCCESS, WRITE_RELIST_REQUEST_FAILURE],
     promise: (client) => client.default.post('/writeRelistRequestToSheet',{data:{
       accessToken,
       "merchantId":merchantId,
       "Merchant":merchantName,
       "phoneNumber":contactNumber
     }})
  };
}

export function merchantRelistRequested(accessToken, merchantId) {
  return {
    types:[MERCHANT_RELIST_REQUESTED_LOADING, MERCHANT_RELIST_REQUESTED_SUCCESS, MERCHANT_RELIST_REQUESTED_FAILURE],
    promise:(client) => client.default.post('/merchantRelistRequested',{
      data:{accessToken, merchantId}
    })
  }
}

export function getMerchantRatingsAndVisits(accessToken, merchantUserId) {
  return {
    types:[MERCHANT_RATINGS_VISITS_LOADING, MERCHANT_RATINGS_VISITS_SUCCESS, MERCHANT_RATINGS_VISITS_FAILURE],
    promise:(client) => client.default.post('/getMerchantRatingsInfo',{
      data:{accessToken, merchantUserId}
    })
  }
}

export function calculateUpdatedPrice(accessToken, orderData) {
  // https://ultrontest.magicpin.com/loms/test/getDeliveryDetailsForOrder
  let action = {
    url: `https://ultron.magicpin.com/loms/getDeliveryDetailsForOrder`,
    method: 'post',
    headerAuth: true,
    data : orderData
  }
  return {
    types: [CALCULATE_PRICE_LOADING, CALCULATE_PRICE_SUCCESS, CALCULATE_PRICE_FAILURE],
    promise: (client) => client.default.post('/takeAction/calculateUpdatedPrice', {
      data: {accessToken, action}
    })
  }
}

export function acceptPartialCatalogOrder(accessToken, orderData) {
  // https://ultrontest.magicpin.com/loms/test/updateOrderMerchant
  let action = {
    url: `https://ultron.magicpin.com/loms/updateOrderMerchant`,
    method: 'post',
    headerAuth: true,
    data : orderData
  }
  return {
    types: [ACCEPT_PARTIAL_CATALOG_ORDER_LOADING, ACCEPT_PARTIAL_CATALOG_ORDER_SUCCESS, ACCEPT_PARTIAL_CATALOG_ORDER_FAILURE],
    promise: (client) => client.default.post('/takeAction/acceptPartialOrder', {
      data: {accessToken, action}
    })
  }
}

export function getMerchantTimings(accessToken, merchantUserId) {
  // http://aryantest.gc.magicpin.in:8080/magicpin/merchant/getMerchantTimings/2029
  let action = {
    url: `http://aryan.service/merchant/getMerchantTimings/${merchantUserId}`,
    method: 'get',
    headerAuth: false
  }
  return {
    types: [GET_MERCHANT_TIMINGS_LOADING, GET_MERCHANT_TIMINGS_SUCCESS, GET_MERCHANT_TIMINGS_FAILURE],
    promise: (client) => client.default.post('/takeAction/getMerchantTimings', {
      data: {accessToken, action}
    })
  }
}

export function getWhatsappImage(accessToken) {
  return {
    types:[GET_WHATSAPP_IMAGE_LOADING, GET_WHATSAPP_IMAGE_SUCCESS, GET_WHATSAPP_IMAGE_FAILURE],
    promise:(client) => client.default.post('/getWhatsappImage',{
      data:{accessToken}
    })
  }
}

export function whatsappProducer(accessToken, merchantName, alias) {
    return {
     types:[WHATSAPP_PRODUCER_LOADING, WHATSAPP_PRODUCER_SUCCESS, WHATSAPP_PRODUCER_FAILURE],
     promise:(client) => client.default.post('/whatsappProducer',{
       data:{accessToken, merchantName, alias}
    })  
  }
}

export function uploadMenuCSV(accessToken, file, merchantUserId){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : "download");
  data.append('accessToken', accessToken);
  data.append('merchantUserId',merchantUserId);
  return {
    types:[UPLOAD_MENU_CSV_LOADING, UPLOAD_MENU_CSV_SUCCESS, UPLOAD_MENU_CSV_FAILURE],
    promise:(client) => client.default.post('/uploadMenuCSV', {
      data
    })
  }
}

export function showAppDownloadPopup(accessToken) {
    return {
     types:[SHOW_APP_DOWNLOAD_POPUP_LOADING, SHOW_APP_DOWNLOAD_POPUP_SUCCESS, SHOW_APP_DOWNLOAD_POPUP_FAILURE],
     promise:(client) => client.default.post('/showAppDownloadPopup',{
       data:{ accessToken }
    })  
  }
}

export function getTopBrands(accessToken, city, categoryId, startDate, endDate, days){
  return {
     types:[GET_TOP_BRANDS_LOADING, GET_TOP_BRANDS_SUCCESS, GET_TOP_BRANDS_FAILURE],
     promise:(client) => client.default.post('/getTopBppBrands',{
       data:{accessToken, city, categoryId, startDate, endDate, days}
    })  
  }
}

export function getMerchantMissedEarning(accessToken, city, days){
  return {
     types:[GET_MISSED_EARNING_LOADING, GET_MISSED_EARNING_SUCCESS, GET_MISSED_EARNING_FAILURE],
     promise:(client) => client.default.post('/getBppMissedEarnings',{
       data:{ accessToken, city, days}
    })  
  }
}

export function getMerchantTopEarning(accessToken, city, days){
  return {
     types:[GET_MERCHANT_TOP_EARNING_LOADING, GET_MERCHANT_TOP_EARNING_SUCCESS, GET_MERCHANT_TOP_EARNING_FAILURE],
     promise:(client) => client.default.post('/getBppTopEarnings',{
       data:{ accessToken, city, days}
    })  
  }
}

export function createCallbackRequestTicket(accessToken, timestamp, optionChosen) {
  const openingTimeObj = {timestamp: timestamp, optionChosen: optionChosen}

  return {
    types:[CREATE_CALLBACK_TICKET_LOADING, CREATE_CALLBACK_TICKET_SUCCESS, CREATE_CALLBACK_TICKET_FAILURE],
    promise: (client) => client.default.post('/createCallbackRequestTicket',{
      data:{accessToken, openingTimeObj}
    })
  }
}

export function invalidCredsAlertEmail(accessToken, userType, emailId, userId, merchantId, parentMerchantId, url) {
  const emailFields = {userType: userType, emailId: emailId, userId: userId, merchantId: merchantId, parentMerchantId: parentMerchantId, url: url}
  return {
    types:[SEND_ALERT_EMAIL_LOADING, SEND_ALERT_EMAIL_SUCCESS, SEND_ALERT_EMAIL_FAILURE],
    promise: (client) => client.default.post('/invalidCredsAlertEmail',{
      data:{accessToken, emailFields}
    })
  }
}

export function getLostBrands(accessToken, merchantId, localityId, categoryId){
  let locality=[]
  locality.push(localityId)
  const action = {
      url: 'http://brand.magicpin.in:8090/getLostBrandsEarning/',
      method: 'post',
      headerAuth: true,
      data: {
       merchantId,
       categoryId,
       localityId: locality
      }
   }
  return {
     types:[GET_LOST_BRANDS_LOADING, GET_LOST_BRANDS_SUCCESS, GET_LOST_BRANDS_FAILURE],
     promise:(client) => client.default.post('/takeAction/getLostBrands',{
       data:{ action, accessToken }
    })  
  }
}

export function updateStoreTimings(accessToken, merchantUserId, timings, salt) {
  let saltToBeUsed = !!saltFromAuth ? saltFromAuth : salt
    const headers = {
        'auth-userid': merchantUserId,
        'auth-user-token': saltToBeUsed
    }
    return {
        types: [UPDATE_MERCHANT_TIMINGS_LOADING, UPDATE_MERCHANT_TIMINGS_SUCCESS, UPDATE_MERCHANT_TIMINGS_FAILURE],
        promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/updateTimings', {
            data: {accessToken, merchantUserId, timings},
            headers: headers
        })
    }
}

export function updateStoreHighlights(salt, merchantId, productTypes) {
  let saltToBeUsed = !!saltFromAuth ? saltFromAuth : salt
    const headers = {
        'auth-userid': merchantId,
        'auth-user-token': saltToBeUsed
    }
    return {
        types: [UPDATE_MERCHANT_HIGHLIGHTS_LOADING, UPDATE_MERCHANT_HIGHLIGHTS_SUCCESS, UPDATE_MERCHANT_HIGHLIGHTS_FAILURE],
        promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/merchant/updateProductTypes', {
            data: {merchantId, productTypes},
            headers: headers
        })
    }
}

export function enableMagicOrderFundedMerchant(accessToken, merchantUserId) {
    return {
        types: [ENABLE_MAGIC_ORDER_LOADING, ENABLE_MAGIC_ORDER_SUCCESS, ENABLE_MAGIC_ORDER_FAILURE],
        promise: (client) => client.default.post('./enableMagicOrder', {
            data: {merchantUserId, accessToken}
        })
    }
}

export function updateStoreNumber(accessToken, mobileNumber, merchantContactId, merchantId) {
    return {
        types: [UPDATE_STORE_NUMBER_LOADING, UPDATE_STORE_NUMBER_SUCCESS, UPDATE_STORE_NUMBER_FAILURE],
        promise: (client) => client.default.post('./updateStoreNumber', {
            data: {accessToken, mobileNumber, merchantContactId, merchantId}
        })
    }
}

export function getMerchantCOD(accessToken, merchantUserId) {
  const action = {
    // url: `https://ultrontest.magicpin.com/loms/test/getMerchantCOD?merchantUserId=${merchantUserId}`,
    url: `https://ultron.magicpin.com/loms/getMerchantCOD?merchantUserId=${merchantUserId}`,
    method: 'get',
    headerAuth: true,
    params:{
      merchantUserId: merchantUserId
    }
  }
  return {
   types:[GET_MERCHANT_COD_LOADING, GET_MERCHANT_COD_SUCCESS, GET_MERCHANT_COD_FAILURE],
     promise:(client) => client.default.post('/takeAction/getMerchantCOD',{
     data:{ accessToken, action}
  })  
}
}

export function setMerchantCOD(accessToken, merchantUserId, CODEnabled) {
const action = {
  // url: 'https://ultrontest.magicpin.com/loms/test/setMerchantCOD',
  url: 'https://ultron.magicpin.com/loms/setMerchantCOD',
  method: 'post',
  headerAuth: true,
  data:{
      "merchantUserId":merchantUserId,
      "CODEnabled": CODEnabled
  }
}
  return {
 types:[SET_MERCHANT_COD_LOADING, SET_MERCHANT_COD_SUCCESS, SET_MERCHANT_COD_FAILURE],
     promise:(client) => client.default.post('/takeAction/setMerchantCOD',{
   data:{ accessToken , action}
})  
}
}

export function sendDIYComissionSignupEmail(payload) {
  return {
    types:[SEND_DIYCOM_EMAIL_LOADING, SEND_DIYCOM_EMAIL_SUCCESS, SEND_DIYCOM_EMAIL_FAILURE],
    promise: (client) => client.default.post('/sendDIYCommissionSignupEmail',{
      data:payload
    })
  }
}

export function catalogUpdateCrawlScheduler(accessToken, emailId, merchantIds) {
  return {
    types:[CATALOG_CRAWL_SCHEDULER_LOADING, CATALOG_CRAWL_SCHEDULER_SUCCESS, CATALOG_CRAWL_SCHEDULER_FAILURE],
    promise: (client) => client.default.post('/catalogUpdateCrawlScheduler',{
      data: {accessToken, emailId, merchantIds}
    })
  }
}

export function showChromeExtensionBanner(merchantId) {
  return {
    types:[SHOW_CHROME_EXTENSION_BANNER_LOADING, SHOW_CHROME_EXTENSION_BANNER_SUCCESS, SHOW_CHROME_EXTENSION_BANNER_FAILURE],
    promise: (client) => client.default.post('/showChromeExtensionBanner',{
      data: {merchantId: merchantId}
    })
  }
}

export function onsuritySnackbarLocalityCheck(localityId, merchantId) {
  return {
    types:[SHOW_ONSURITY_SNACKBAR_LOADING, SHOW_ONSURITY_SNACKBAR_SUCCESS, SHOW_ONSURITY_SNACKBAR_FAILURE],
    promise: (client) => client.default.post('/onsuritySnackbarLocalityCheck',{
      data: {localityId: localityId, merchantId: merchantId}
    })
  }
}

export function accessTokenDecryptor(accessToken) {
  return {
    types:[ACCESS_TOKEN_DECRYPTOR_LOADING, ACCESS_TOKEN_DECRYPTOR_SUCCESS, ACCESS_TOKEN_DECRYPTOR_FAILURE],
    promise: (client) => client.default.post('/accessTokenDecryptor',{
      data: {accessToken}
    })
  }
}

export function isMerchantAppInstalled(salt, userId){
  const headers = {
    'auth-userid': userId,
    'auth-user-token': salt
  }

  const url = `https://webapi.magicpin.in/ultron-mp/merchant/${userId}/isMerchantAppInstalled`
  return {
    types: [IS_MERCHANT_APP_INSTALLED_LOAD, IS_MERCHANT_APP_INSTALLED_SUCCESS, IS_MERCHANT_APP_INSTALLED_FAIL],
    promise: (client) => client.default.get(url, {
      headers: headers
    })
  };
}

export function setMerchantFood(accessToken, fssaiLicenseNumber, merchantUserId, salt) {
  let action
    action = {
      // url: 'https://ultrontest.magicpin.com/loms/test/getMerchantDeliveryRules',
      url: 'http://aryan.service/merchant/updateMerchantFoodLicense',
      method: 'post',
      headerAuth: true,
      data : {
        "merchantUserId": merchantUserId,
        "fssaiLicenseNumber": fssaiLicenseNumber
      }
    };
  // }
  return {
    types: [SET_MERCHANT_FOOD, SET_MERCHANT_FOOD_SUCCESS, SET_MERCHANT_FOOD_ERROR],
    promise: (client) => client.default.post('/takeAction/writeMxFssai', {
      data: {accessToken, action, salt}
    })
  }
}

export function writeInteractivityEvent(merchantId, deviceBrowserId, deviceType, signinTimeStamp, extraFields){
  let payload = {}
  payload['mid'] = merchantId;
  payload['eventPlatform'] = "MERCHANT_PORTAL";
  payload['timestamp'] = Date.now();
  payload['eventname'] = "order_everyminute_ping_test_vieworder";
  payload['mpDeviceBrowserId'] = Number(deviceBrowserId);
  payload['mpDeviceType'] = deviceType;

  if(signinTimeStamp) {
    payload['signinTimeStamp'] = signinTimeStamp;
  }
  if(extraFields) {
    payload['mpExtraFields'] = extraFields;
  }

  return {
    types: [WRITE_INTERACTIVITY_EVENT_LOADING, WRITE_INTERACTIVITY_EVENT_SUCCESS, WRITE_INTERACTIVITY_EVENT_FAILURE],
    promise: (client) => client.ultron_mp.post('/merchant/writeActivityAnalyticsToBT', {
      data: [payload]
    })
  };
}

export function getBuzzerSignal(accessToken, orderId){
  return {
    types: [GET_BUZZER_RING_SIGNAL_LOADING, GET_BUZZER_RING_SIGNAL_SUCCESS, GET_BUZZER_RING_SIGNAL_ERROR],
    promise: (client) => client.default.post('/getBuzzerRingSignal', {
      data: {accessToken, orderId}
    })
  }
}

export function writeBuzzerSignal(orderId, merchantId, timestamp){
  return {
    types: [WRITE_BUZZER_RING_SIGNAL_LOADING, WRITE_BUZZER_RING_SIGNAL_SUCCESS, WRITE_BUZZER_RING_SIGNAL_ERROR],
    promise: (client) => client.default.post('/writeBuzzerRingSignal', {
      data: {orderId, merchantId, timestamp}
    })
  }
}

export function getOrderSummaryForCsvPanel(accessToken, merchantUserId){
  let startDate = moment().valueOf();
  let endDate = moment().subtract(30, 'days').valueOf();

  return {
    types: [GET_ORDER_SUMMARY_FOR_CSV_PANEL_LOADING, GET_ORDER_SUMMARY_FOR_CSV_PANEL_SUCCESS, GET_ORDER_SUMMARY_FOR_CSV_PANEL_FAILURE],
    promise: (client) => client.default.post('/getOrderSummaryForCsvPanel', {
      data: {accessToken, merchantUserId, startDate, endDate}
    })
  }
}

export function uploadInventoryCSV(accessToken, file){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : "inventory_update");
  data.append('accessToken', accessToken);
  // data.append('merchantUserId',merchantUserId);
  return {
    types:[UPLOAD_INVENTORY_CSV_LOADING, UPLOAD_INVENTORY_CSV_SUCCESS, UPLOAD_INVENTORY_CSV_FAILURE],
    promise:(client) => client.default.post('/uploadInventoryCSV', {
      data
    })
  }
}

export function uploadOrderStatusCSV(accessToken, file){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : "inventory_update");
  data.append('accessToken', accessToken);
  // data.append('merchantUserId',merchantUserId);
  return {
    types:[UPLOAD_ORDER_STATUS_CSV_LOADING, UPLOAD_ORDER_STATUS_CSV_SUCCESS, UPLOAD_ORDER_STATUS_CSV_FAILURE],
    promise:(client) => client.default.post('/uploadOrderStatusCSV', {
      data
    })
  }
}

export function downloadSampleInventoryForCsvPanel(accessToken){  
  return {
    types: [GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_LOADING, GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_SUCCESS, GET_SAMPLE_INVENTORY_FOR_CSV_PANEL_FAILURE],
    promise: (client) => client.default.post('/downloadSampleCsv', {
      data: {accessToken}
    })
  }
}