import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import cross from '../../images/cross.png';
import './CustomerComplaints.css';
import zoomIcon from '../../images/zoom-icon-image.svg'
export class ImageViewer extends Component {

    constructor(props){
        super(props);
        this.state={
            showSrc:null,
            isShow:true,
        }
    }
    render() {
        return (
            <React.Fragment>
                <Modal show={this.state.showSrc && this.state.isShow} onHide={() => { this.setState({ isShow: false }) }}>
                    <Modal.Body className="complaint-style" style={{ padding: '15px', left: '0', right: '0', top: '0', bottom: '0' }}>
                        <div className="dialog-cross" onClick={() => { this.setState({ isShow: false }) }} style={{ textAlign: 'right',marginRight:'30px' }}>
                            <img src={cross} style={{ height: "26px", width: "26px" ,position:'absolute', marginTop: '10px'}} />
                        </div>
                        <img src={this.state.showSrc} onError={this.props.onError}
                            style={{ height: 'auto', width: '100%',maxHeight:'600px' }} />
                    </Modal.Body>
                </Modal>
                {
                 this.props.isZoomIcon
                 ?
                 <div style={{position: 'relative', height: '36px', width:'36px'}}>
                    <img src={this.props.src} onError={this.props.onError} 
                            onClick={(event)=>this.setState({showSrc:event.target.src, isShow: true })} 
                            className={this.props.className || ""} style={this.props.style || {}}/>
                    <img src={zoomIcon} style={{position: 'absolute', bottom: '0', right:'0'}}/>
                 </div>
                 :
                <img src={this.props.src} onError={this.props.onError} 
                        onClick={(event)=>this.setState({showSrc:event.target.src, isShow: true })} 
                        className={this.props.className || ""} style={this.props.style || {}}/>
                }
            </React.Fragment>
        )
    }
}
