import { TENURE_PACKAGES } from '../../components/Upgradations/upsellConstants'

const UPGRADE_PACKAGE = 'upgradePackage/UPGRADE_PACKAGE';
const UPGRADE_PACKAGE_SUCCESS = 'upgradePackage/UPGRADE_PACKAGE_SUCCESS';
const UPGRADE_PACKAGE_FAIL = 'upgradePackage/UPGRADE_PACKAGE_FAIL';
const SEND_UPGRADE_MAIL='upgradePackage/SEND_UPGRADE_MAIL';
const SEND_UPGRADE_MAIL_SUCCESS='upgradePackage/SEND_UPGRADE_MAIL_SUCCESS';
const SEND_UPGRADE_MAIL_FAIL='upgradePackage/SEND_UPGRADE_MAIL_FAIL';


const VERIFY_OTP = 'upgradePackage/VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'upgradePackage/VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAIL = 'upgradePackage/VERIFY_OTP_FAIL';

const PACKAGE_SUBSCRIPTION_DETAILS = 'upgradePackage/PACKAGE_SUBSCRIPTION_DETAILS';
const PACKAGE_SUBSCRIPTION_DETAILS_SUCCESS = 'upgradePackage/PACKAGE_SUBSCRIPTION_DETAILS_SUCCESS';
const PACKAGE_SUBSCRIPTION_DETAILS_FAIL = 'upgradePackage/PACKAGE_SUBSCRIPTION_DETAILS_FAIL'

const NEIGHBORING_MERCHANTS_INFO = 'upgradePackage/NEIGHBORING_MERCHANTS_INFO';
const NEIGHBORING_MERCHANTS_INFO_SUCCESS = 'upgradePackage/NEIGHBORING_MERCHANTS_INFO_SUCCESS';
const NEIGHBORING_MERCHANTS_INFO_FAIL = 'upgradePackage/NEIGHBORING_MERCHANTS_INFO_FAIL';

const assignMerchantToUpsellDIYS_load = 'upgradePackage/assignMerchantToUpsellDIYS_load'
const assignMerchantToUpsellDIYS_SUCCESS = 'upgradePackage/assignMerchantToUpsellDIYS_SUCCESS'
const assignMerchantToUpsellDIYS_FAIL = 'upgradePackage/assignMerchantToUpsellDIYS_FAIL'

const initialState = {
  loaded: false,
  otpVerified: false,
};

function getIsTenurePackage(packageResponse){
  const packageName = packageResponse && packageResponse.suitablePackage && packageResponse.suitablePackage.name ? packageResponse.suitablePackage.name.toUpperCase() : '';
  return TENURE_PACKAGES.includes(packageName);
}

function getSuitablePackage(packageResponse){
  const suitablePackage = packageResponse && packageResponse.suitablePackage ? packageResponse.suitablePackage : null;
  return suitablePackage;
}

function getMerchantActivePackage(packageResponse){
  const activePackage = packageResponse && packageResponse.merchantActivePackage ? packageResponse.merchantActivePackage : null; 
  return activePackage;
}

function getMerchantWalletBalance(packageResponse){

  let walletBalance = 0

  walletBalance = packageResponse && packageResponse.merchantWalletBalance && 
                  packageResponse.merchantWalletBalance.walletBalance ? packageResponse.merchantWalletBalance.walletBalance : 0;

  if(packageResponse && packageResponse.is_parent_merchant && packageResponse.parentChildMerchantBalance){
    let childBalance = packageResponse.parentChildMerchantBalance.childBalance && Number.isFinite(packageResponse.parentChildMerchantBalance.childBalance) ? packageResponse.parentChildMerchantBalance.childBalance : 0
    let parentBalance = packageResponse.parentChildMerchantBalance.parentBalance && Number.isFinite(packageResponse.parentChildMerchantBalance.parentBalance) ? packageResponse.parentChildMerchantBalance.parentBalance : 0

    walletBalance = childBalance + parentBalance ? childBalance + parentBalance : walletBalance
  }

  return walletBalance; 
}

function getChildWalletBalance(packageResponse){
  const childWalletBalance = packageResponse && packageResponse.parentMerchantWalletBalance && 
                      !!packageResponse.parentMerchantWalletBalance.walletBalance ? packageResponse.parentMerchantWalletBalance.walletBalance : null;
  
  return childWalletBalance; 
}

function getCreditTakenByMerchant(packageResponse){
  const creditAmount = packageResponse && packageResponse.merchantWalletBalance && 
                        !!packageResponse.merchantWalletBalance.creditAmount ? packageResponse.merchantWalletBalance.creditAmount : 0;
  return creditAmount; 
}

function getUpsellCreditPercent(packageResponse){
  let creditPercent = 0;
  
  if(packageResponse.creditData && packageResponse.creditData.creditPercent)
    creditPercent = packageResponse.creditData.creditPercent;  

  return creditPercent;
}

function getMerchantMobileNumber(packageResponse){
  const merchantMobileNumber = packageResponse && packageResponse.merchantMobileNumbers && packageResponse.merchantMobileNumbers.mobile_number ? packageResponse.merchantMobileNumbers.mobile_number : null;
  return merchantMobileNumber;
}

function getUpsellCreditDataAmount(packageResponse){
  let creditDataAmount = 0;
  
  if(packageResponse.creditData && packageResponse.creditData.creditToBeApplied)
      creditDataAmount = packageResponse.creditData.creditToBeApplied;  

  return Math.floor(creditDataAmount);
}

function getVoucherCommission(packageResponse){
  const voucherCommission = packageResponse && packageResponse.suitablePackage 
                          && packageResponse.suitablePackage.voucher_commission ? 
                          packageResponse.suitablePackage.voucher_commission : 10;
  return voucherCommission;
}


function getSubscriptionData(subscriptionData){

  let subscriptionInfo = {}
  if(subscriptionData){
    let suitablePackage = {};
    if(Array.isArray(subscriptionData.packageOfferings) && subscriptionData.packageOfferings.length)
        subscriptionData.packageOfferings.map(features => suitablePackage[features.offeringName] = features.offeringValue)
    
    if(subscriptionData.packageDtoList){
      const packageDtoList = subscriptionData.packageDtoList;
      suitablePackage['name'] = packageDtoList.name || '';
      suitablePackage['amount'] = packageDtoList.amount || 0;
      suitablePackage['return_on_investment'] = packageDtoList.returnOnInvestment || 0;
      suitablePackage['package_id'] = packageDtoList.packageId || 0;
      suitablePackage['roi_amount'] = packageDtoList.amount && packageDtoList.returnOnInvestment ?  packageDtoList.returnOnInvestment * packageDtoList.amount : 0;
      suitablePackage['duration'] = packageDtoList.duration || 0
    }

    subscriptionInfo['suitablePackage'] = suitablePackage;
    subscriptionInfo['merchantPhone'] = subscriptionData.merchantPhone || '';
    subscriptionInfo['merchantEmail'] = subscriptionData.merchantEmail || '';
    subscriptionInfo['subscriptionId'] = subscriptionData.subscriptionId || 0;
    subscriptionInfo['creditLimit'] = subscriptionData.creditLimit || 0;
    subscriptionInfo['inventoryCost'] = subscriptionData.inventoryCost || 0;
    subscriptionInfo['merchantId'] = subscriptionData.merchantId;
    subscriptionInfo['paymentMode'] = subscriptionData.paymentMode;
    subscriptionInfo['bookingAmount'] = subscriptionData.bookingAmount;
    subscriptionInfo['isTenurePackage'] = TENURE_PACKAGES.includes(suitablePackage.name.toUpperCase());
    subscriptionInfo['additionalCreditLoanConversion'] = subscriptionData.additionalCreditLoanConversion;
    subscriptionInfo['loanConversionFee'] = subscriptionData.loanConversionFee;
    subscriptionInfo['leftoverCredit'] = subscriptionData.leftoverCredit;
    subscriptionInfo['leftoverGoal'] = subscriptionData.leftoverGoal;
    subscriptionInfo['holdAmount'] = subscriptionData.holdAmount || 0;
    subscriptionInfo['editedAndNoContactDetails'] = subscriptionData.editedAndNoContactDetails || false;
    subscriptionInfo['shortLink'] = subscriptionData.shortLink || null;
    subscriptionInfo['loanRepaymentRedemptionPercent'] = subscriptionData.loanRepaymentRedemptionPercent || null;
    subscriptionInfo['agentEmailAddress'] = subscriptionData.agentEmailAddress || '';
  }

  return subscriptionInfo;
}

function getMessage(data){

  let message = ""

  if(data && data.message)
    message = data.message
  else if(data && data.suitablePackage && data.suitablePackage.message)
    message = data.suitablePackage.message
  
  return message
}


function resolvePackageData (data) {
  let inventoryCost = 0
  let amount = 0
  let upsellObj = {}
  let promotional_spend = 0
  let offprice_per_total_amount = 0
  if (data && data.inventoryCost) {
    inventoryCost = data.inventoryCost;
    amount = data.suitablePackage.amount;
    if (inventoryCost > 0) promotional_spend = (inventoryCost * 40) / 100;
    if (amount > 0) offprice_per_total_amount = (amount / (inventoryCost + promotional_spend)) * 100;
    offprice_per_total_amount = offprice_per_total_amount.toFixed(2);
    let discount_per = Math.round(100 - offprice_per_total_amount);
    upsellObj['marketing'] = inventoryCost
    upsellObj['promotion'] = promotional_spend
    upsellObj['total'] = inventoryCost + promotional_spend
    upsellObj['discountPercent'] = discount_per
    upsellObj['payable'] = amount
  }
  return upsellObj
}

function getCreditLimitFromCrmData(subscriptionData){
  return subscriptionData && subscriptionData.creditLimit ? subscriptionData.creditLimit  : 0;
}

export default function upgradePackage(state = initialState, action = {}) {
  switch (action.type) {
    case UPGRADE_PACKAGE:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case UPGRADE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        upgradePackageData: action.result,
        factorsUsedForCalculation: action.result && action.result.factorsUsedForCalculation,
        message : getMessage(action.result),
        upsellData: resolvePackageData(action.result),
        suitablePackage: getSuitablePackage(action.result),
        activePackage: getMerchantActivePackage(action.result),
        merchantWalletBalance: getMerchantWalletBalance(action.result),
        childWalletBalance: getChildWalletBalance(action.result),
        merchantMobileNumber: getMerchantMobileNumber(action.result),
        creditAmount: Math.max(0, getUpsellCreditDataAmount(action.result)),
        creditPercent: getUpsellCreditPercent(action.result),
        creditTaken: getCreditTakenByMerchant(action.result),
        voucherCommission: getVoucherCommission(action.result),
        merchantInfo: action.result.merchantInfo,
        isMerchantFraud: action.result.isMerchantFraud,
        isTenurePackage: getIsTenurePackage(action.result),
        isParentMerchant: action.result.is_parent_merchant || false,
        isApplicableRoiPenalty: action.result.roiPenaltyDiys && action.result.roiPenaltyDiys.isApplicableRoiPenalty,
        roiPercentAfterPenalty: action.result.roiPenaltyDiys && action.result.roiPenaltyDiys.roiPercentAfterPenalty ? action.result.roiPenaltyDiys.roiPercentAfterPenalty : 0,
        roiAmtOnAppliedPenalty: action.result.roiPenaltyDiys && action.result.roiPenaltyDiys.roiAmtOnAppliedPenalty ? action.result.roiPenaltyDiys.roiAmtOnAppliedPenalty : 0,
        penaltyPercent: action.result.roiPenaltyDiys && action.result.roiPenaltyDiys.penaltyPercent ? action.result.roiPenaltyDiys.penaltyPercent : 0,
        penaltyAmt: action.result.roiPenaltyDiys && action.result.roiPenaltyDiys.penaltyAmt ? action.result.roiPenaltyDiys.penaltyAmt : 0,
        leftoverCreditDiy: action.result?.factorsUsedForCalculation?.leftoverCredit|| 0,
        leftoverGoalDiy: action.result?.factorsUsedForCalculation?.leftoverGoal || 0,
        childMerchantPackages: action.result?.childMerchantPackages || []
      };
    case UPGRADE_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

      case SEND_UPGRADE_MAIL:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case SEND_UPGRADE_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        successful_message: action.result.messageInfo,
        error: null
      };
    case SEND_UPGRADE_MAIL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    case VERIFY_OTP:
      return {
        ...state,
        otpVerifying: true
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpVerifying: false,
        otpVerified: true,
      };

    case VERIFY_OTP_FAIL:
      return {
        ...state,
        otpVerifying: false,
        otpVerified: false,
        verifyError: action.error
      };

    case PACKAGE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionLoading: true
      };

    case PACKAGE_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        subscriptionData: getSubscriptionData(action.result),
        applicablePackage: getSubscriptionData(action.result).suitablePackage,
        bookingAmount: getSubscriptionData(action.result).bookingAmount,
        creditLimit : getCreditLimitFromCrmData(action.result),
        isTenurePackageCrm: getSubscriptionData(action.result).isTenurePackage,
        additionalCreditLoanConversion : getSubscriptionData(action.result).additionalCreditLoanConversion,
        loanConversionFee : getSubscriptionData(action.result).loanConversionFee,
        leftoverCredit : getSubscriptionData(action.result).leftoverCredit,
        leftoverGoal : getSubscriptionData(action.result).leftoverGoal,
        holdAmount :  getSubscriptionData(action.result).holdAmount,
        shortLink : getSubscriptionData(action.result).shortLink,
        loanRepaymentRedemptionPercent : getSubscriptionData(action.result).loanRepaymentRedemptionPercent
      };

    case PACKAGE_SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        subscriptionError: action.error
      };

    case NEIGHBORING_MERCHANTS_INFO:
      return {
        ...state,
        merchantListLoading: true
      };

    case NEIGHBORING_MERCHANTS_INFO_SUCCESS:
      return {
        ...state,
        merchantListLoading: false,
        merchantListLoaded: true,
        merchantList: action.result && action.result.merchantList ? action.result.merchantList : []
      };

    case NEIGHBORING_MERCHANTS_INFO_FAIL:
      return {
        ...state,
        merchantListLoading: false,
        merchantListLoaded: true,
        merchantListError: action.error
      };

     case assignMerchantToUpsellDIYS_load:
       return {
         ...state
       }

     case assignMerchantToUpsellDIYS_SUCCESS:
        return {
          ...state
        }

     case assignMerchantToUpsellDIYS_FAIL:
          return {
            ...state
          }
     
      


   default:
      return state;
  }
}

export function getUpgradePackage(merchantObject) {
  return {
    types: [UPGRADE_PACKAGE, UPGRADE_PACKAGE_SUCCESS, UPGRADE_PACKAGE_FAIL],
    promise: (client) => client.default.post('/getUpgradePackage',{
      data:merchantObject
    })
  };
}


export function sendEmailToMagicPinTeam(mailObject){
  return {
    types: [SEND_UPGRADE_MAIL, SEND_UPGRADE_MAIL_SUCCESS, SEND_UPGRADE_MAIL_FAIL],
    promise: (client) => client.default.post('/sendUpgradeMail',{
      data:mailObject
    })
  };

}

export function verifyOtp(token, phoneIndex, otp) {
  return {
    types: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL],
    promise: (client) => client.default.post('/verifyPasswordByMobileIndex', {
      data: {token, phoneIndex, otp}
    })
  };
}

export function getApplicablePackage(id) {
  return {
    types: [PACKAGE_SUBSCRIPTION_DETAILS, PACKAGE_SUBSCRIPTION_DETAILS_SUCCESS, PACKAGE_SUBSCRIPTION_DETAILS_FAIL],
    promise: (client) => client.default.post('/getPackageSubscriptionDetails', {
      data: {id: id}
    })
  };
}

export function getNeighboringMerchantsInfo(merchantId) {
  return {
    types: [NEIGHBORING_MERCHANTS_INFO, NEIGHBORING_MERCHANTS_INFO_SUCCESS, NEIGHBORING_MERCHANTS_INFO_FAIL],
    promise: (client) => client.default.post('/getNeighboringMerchantsInfo', {
      data: {merchantId: merchantId}
    })
  };
}

export function assignMerchantToUpsellDIYS(merchantId,upsellType)
{
  return {
    types: [assignMerchantToUpsellDIYS_load, assignMerchantToUpsellDIYS_SUCCESS, assignMerchantToUpsellDIYS_FAIL],
    promise: (client) => client.default.post('/assignMerchantToUpsellDIYS', {
      data: {merchantId: merchantId, upsellType: upsellType}
    })
  };
}