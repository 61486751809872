import React, {Component } from "react"
import './DeliveryItemList.css'
import backBtnImg from '../../images/back-btn-popup.svg'
import {connect} from 'react-redux';
import growthIcon from '../../images/home-delivery-growth-arrow.svg';
import rupeeIcon from '../../images/home-delivery-rupee-icon.svg';
import searchIcon from '../../images/home-delivery-suggested-search-icon.svg';
import { bindActionCreators } from 'redux';
import { getSearchResults} from '../../redux/modules/newMerchantLanding';
import { toast } from "react-toastify";


export default connect(
    store => ({
        searchResultsLoading: store.newMerchantLanding.searchResultsLoading,
        searchResultsData: store.newMerchantLanding.searchResultsData
    }),dispatch=>bindActionCreators({
        getSearchResults
    }, dispatch))
    (class AddItemsScreen extends Component{
    constructor(props){
        super(props)
        this.state={ 
            enteredText: null
        }
    }

    componentDidMount(){
        // window.fcWidget.hide()
        if(!!window.fcWidget)
        { 
            window.fcWidget.hide();
            document.getElementById('custom_chat_button').style.visibility = "hidden"
        }
    }

    handleOnChange = (event) => {
        let enteredText = event.target.value
        this.setState({enteredText: enteredText})
        // console.log(enteredText)
            if(enteredText.length > 2) {
                this.props.getSearchResults(this.props.user.accessToken, enteredText)
            }
    }

    handleItemSelect = (item) => {
        toast.success(`${item.displayText} selected!`)
        /**
         * Add the item to the list of order items - write a fn in DeliveryItemList
         * Close this component - write a handleClose in DeliveryItemList
         */
    }

    addNewItem = () =>{
        console.log("clicked occureed on add new item======")
        let item={}
        item["displayText"]=this.state.enteredText
        item["id"]= -1
        this.props.addItem(item)
    }

    render(){
        // console.log(this.state.enteredText)
        // this.state.enteredText.length && console.log(this.state.enteredText.length)
        // console.log(this.props.searchResultsLoading)
        // console.log(this.props.searchResultsData)
        
        return(
        <div style={{height: window.innerWidth <= 786 ? '100vh' : '', background: '#fff'}}>
           {
            <div className={window.innerWidth > 768 && "row col-md-6 col-md-offset-3 mobile-view-card delivery-desktop-view"} style={{marginTop: window.innerWidth > 768 && '30px', height: window.innerWidth > 768 && '590px'}}>
                <div>
                    <div className="delivery-header-overflow">
                        <div className="delivery-item-body-header">
                            <div className="delivery-item-body-header-text" style={{width: this.state.showRejectionIdField && window.innerWidth <= 768 ? "75%": "", textAlign: window.innerWidth > 768 ? 'right' : 'left'}}>
                            {
                                
                                <span>
                                    {window.innerWidth <= 768 
                                      &&
                                    <img src={backBtnImg} onClick={()=>{this.props.handleCloseListItemsPage()}} className="back-btn-padding"/>
                                    }
                                    {window.innerWidth > 768
                                        &&
                                    <i class="fa fa-times-thin fa-2x" aria-hidden="true" onClick={()=>{this.props.handleCloseListItemsPage()}} style={{ fontSize:'30px',cursor: 'pointer' }}></i>
                                    }
                                </span>
                            }
                            </div> 
                        </div>
                        <div style={{textAlign: 'start', marginLeft: '7%', marginRight: '7%'}}>
                            <div style={{display: 'flex', width: '100%', backgroundColor: '#fde8f1', border: 'none', padding: '2% 3%', borderRadius: '4px', marginTop: window.innerWidth > 768 && '5%'}}>
                                <img src={searchIcon}></img>
                                <input className='suggested-item-search-input' placeholder="Search to add suggested item" onChange={(e) => this.handleOnChange(e)} />
                            </div>
                               <div style={{overflow: 'auto', maxHeight: '40vh', border: '1px solid rgba(0,0,0,0.12)', borderRadius: '4px'}}>
                                {this.state.enteredText && this.state.enteredText.length && 
                                <div style={{borderTop: '1px solid rgba(0,0,0,0.12)', padding: '1rem 12px', cursor: 'pointer'}} onClick={()=> this.addNewItem()} className='item-search-result'>
                                    {this.state.enteredText}
                                </div>}
                                {this.state.enteredText && this.state.enteredText.length && this.state.enteredText.length > 2 
                                 && this.props.searchResultsData && this.props.searchResultsData.data 
                                 && this.props.searchResultsData.data.length > 0
                                    && this.props.searchResultsData.data.map((result, index) => (
                                        <div style={{borderTop:'1px dashed rgba(0,0,0,0.12)', borderBottomLeftRadius: index == (this.props.searchResultsData.data.length - 1) ? '4px' : 'none',
                                            borderBottomRightRadius: index == (this.props.searchResultsData.data.length - 1) ? '4px' : 'none',
                                            borderBottom: index == (this.props.searchResultsData.data.length - 1) ? '1px solid rgba(0,0,0,0.12)' : 'none', padding: '1rem 12px', cursor: 'pointer'}} 
                                            className='item-search-result' onClick={() => this.props.addItem(result)}>
                                            {result.mrp && result.mrp > 0 ? <div>{result.displayText} | <span style={{color: 'rgba(0,0,0,0.54)'}}>MRP ₹{result.mrp}</span></div> : `${result.displayText}`}
                                        </div>))
                                }
                            </div>
                            {!this.state.enteredText
                                 && <div style={{padding: '3% 0'}}>
                                     <div style={{fontFamily: 'robotomedium', fontSize: '16px', padding: '2% 0'}}>
                                        Orders with suggested items have 
                                     </div>
                                     <div style={{border: '1px solid rgba(0,0,0,0.12)', borderRadius: '4px', color: 'rgba(0,0,0,0.54)'}}>
                                       <div style={{display: 'flex', alignItems: 'center', padding: '2%'}}>
                                           <img style={{marginRight: '2%'}} src={growthIcon}></img>
                                           <div style={{marginRight: '2%'}} style={{fontFamily: 'robotomedium', fontStyle: 'italic'}}>Generated 70% more business</div>
                                       </div>
                                       <div style={{display: 'flex', alignItems: 'center', padding: '2%'}}>
                                           <img src={rupeeIcon}></img>
                                           <div style={{fontFamily: 'robotomedium', fontStyle: 'italic'}}>50% higher chance of getting paid </div>
                                       </div>
                                     </div>
                                 </div>
                                }
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
        );
    }
})