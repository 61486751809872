const FEED_SHORTCUTS_STATUS_LOAD = 'feedShortcutsStatus/FEED_SHORTCUTS_STATUS_LOAD';
const FEED_SHORTCUTS_STATUS_LOAD_SUCCESS = 'feedShortcutsStatus/FEED_SHORTCUTS_STATUS_LOAD_SUCCESS';
const FEED_SHORTCUTS_STATUS_LOAD_FAIL = 'feedShortcutsStatus/FEED_SHORTCUTS_STATUS_LOAD_FAIL';

const intialState = {
  feedShortcutsStatusLoaded: false,
};

export default function feedShortcutsStatus(state = intialState, action = {} ){
	switch(action.type){
	case FEED_SHORTCUTS_STATUS_LOAD:
      return {
        ...state,
        feedShortcutsStatusLoading: true,
      };
    case FEED_SHORTCUTS_STATUS_LOAD_SUCCESS:
      return {

        ...state,
        feedShortcutsStatusLoading: false,
        feedShortcutsStatusLoaded: true,
        feedShortcutsStatusData: action.result,
      };
    case FEED_SHORTCUTS_STATUS_LOAD_FAIL:
      return {
        ...state,
        feedShortcutsStatusLoading: false,
        feedShortcutsStatusLoaded: true,
        feedShortcutsStatusError: action.error,
      };
    default:
      return state;
	}
}


export function getFeedShortcutsStatus(accessToken){
  return {
    types: [FEED_SHORTCUTS_STATUS_LOAD, FEED_SHORTCUTS_STATUS_LOAD_SUCCESS, FEED_SHORTCUTS_STATUS_LOAD_FAIL],
    promise: (client) => client.default.post('/getFeedShortcutsStatus', {
      data: {accessToken}
    })
  };
}