const VOUCHER_REDEMPTION_LEADER_BOARD_LOAD ='voucherRedemptionLeaderBoard/VOUCHER_REDEMPTION_LEADER_BOARD_LOAD'
const VOUCHER_REDEMPTION_LEADER_BOARD_SUCCESS ='voucherRedemptionLeaderBoard/VOUCHER_REDEMPTION_LEADER_BOARD_SUCCESS'
const VOUCHER_REDEMPTION_LEADER_BOARD_FAIL ='voucherRedemptionLeaderBoard/VOUCHER_REDEMPTION_LEADER_BOARD_FAIL'
const NEW_LEADER_BOARD_LOAD ='voucherRedemptionLeaderBoard/NEW_LEADER_BOARD_LOAD'
const NEW_LEADER_BOARD_SUCCESS ='voucherRedemptionLeaderBoard/NEW_LEADER_BOARD_SUCCESS'
const NEW_LEADER_BOARD_FAIL ='voucherRedemptionLeaderBoard/NEW_LEADER_BOARD_FAIL'
const PAST_WINNERS_LOAD ='voucherRedemptionLeaderBoard/PAST_WINNERS_LOAD'
const PAST_WINNERS_SUCCESS ='voucherRedemptionLeaderBoard/PAST_WINNERS_SUCCESS'
const PAST_WINNERS_FAIL ='voucherRedemptionLeaderBoard/PAST_WINNERS_FAIL'

const initialState = {
    voucherRedemptionLeaderBoardLoaded: false,
    voucherRedemptionLeaderBoardLoading: false
}

export default function voucherRedemptionLeaderBoard(state= initialState, action){
    switch(action.type){
        case VOUCHER_REDEMPTION_LEADER_BOARD_LOAD:
            return{
                ...state,
                voucherRedemptionLeaderBoardLoading: true
        }

        case VOUCHER_REDEMPTION_LEADER_BOARD_SUCCESS:
            return{
                ...state,
                voucherRedemptionLeaderBoardLoaded: true,
                voucherRedemptionLeaderBoardLoading: false,
                error: null,
                voucherRedemptionLeaderBoardData: action.result
        }
        
        case VOUCHER_REDEMPTION_LEADER_BOARD_FAIL:
            return{
                ...state,
                voucherRedemptionLeaderBoardLoaded: false,
                voucherRedemptionLeaderBoardLoading: false,
                voucherRedemptionLeaderBoardData: null,
                error: action.error
        }
        case NEW_LEADER_BOARD_LOAD:
            return{
                ...state,
                newLeaderBoardLoading: true
        }

        case NEW_LEADER_BOARD_SUCCESS:
            return{
                ...state,
                newLeaderBoardLoaded: true,
                newLeaderBoardLoading: false,
                error: null,
                newLeaderBoardData: action.result
        }
        
        case NEW_LEADER_BOARD_FAIL:
            return{
                ...state,
                newLeaderBoardLoaded: false,
                newLeaderBoardLoading: false,
                newLeaderBoardData: null,
                error: action.error
        }
        case PAST_WINNERS_LOAD:
            return{
                ...state,
                pastWinnersDataLoading: true
            }
        case PAST_WINNERS_SUCCESS:
            return{
                ...state,
                pastWinnersDataLoading: false,
                pastWinnersData: action.result,
                pastWinnersDataError: null
            }
        case PAST_WINNERS_FAIL:
            return{
                ...state,
                pastWinnersDataLoading: false,
                pastWinnersData: null,
                pastWinnersDataError: action.error
            }    
        default:
           return state;
    }      
}

// export function getNewMerchantLeaderBoard(accessToken, merchantId) {
//     const url = `http://localhost:8085/getRank/${merchantId}`
//     return {
//         types: [NEW_LEADER_BOARD_LOAD, NEW_LEADER_BOARD_SUCCESS, NEW_LEADER_BOARD_FAIL],
//         promise: (client) => client.default.get(url)
//     }
// }

export function getPastWinnersData(accessToken, localityId, categoryId) {
    // const url = `http://35.244.31.122:8080/cbs/merchant/pastRewards/${localityId}/${categoryId}`
    return {
        types: [PAST_WINNERS_LOAD, PAST_WINNERS_SUCCESS, PAST_WINNERS_FAIL],
        promise: (client) => client.default.post('/getPastWinners', {
            data:{accessToken, localityId, categoryId}
        })
    }
}

export function getNewMerchantLeaderBoard(accessToken, merchantId){
    return{
      types:[NEW_LEADER_BOARD_LOAD, NEW_LEADER_BOARD_SUCCESS, NEW_LEADER_BOARD_FAIL],
      promise:(client) => client.default.post('/getMerchantNewLeaderBoard',{
        data:{accessToken, merchantId}
      })
    }
  }

export function getVoucherRedemptionLeaderBoard(merchantId){
    return{
       types:[VOUCHER_REDEMPTION_LEADER_BOARD_LOAD, VOUCHER_REDEMPTION_LEADER_BOARD_SUCCESS, VOUCHER_REDEMPTION_LEADER_BOARD_FAIL],
       promise: (client) => client.default.post(`/getVoucherRedeemptionLeaderBoard?merchantId=${merchantId}`)
    }
}