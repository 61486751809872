const LOAD = 'childMerchants/LOAD';
const LOAD_SUCCESS = 'childMerchants/LOAD_SUCCESS';
const LOAD_FAIL = 'childMerchants/LOAD_FAIL';

export const SELECT_CHILD = 'childMerchants/SELECT_CHILD';

const initialState = {
  loaded: false,
  selectedChildId: null,
};

function generateMap(stores){
  let cityMap = {}
  if(!stores || !Array.isArray(stores))
    return
  for(let i=0; i<stores.length; i++){
    if(cityMap[stores[i]['city']] && Array.isArray(cityMap[stores[i]['city']]))
      cityMap[stores[i]['city']].push(stores[i]['locality'])
    else
    cityMap[stores[i]['city']] = [stores[i]['locality']]
  }
  return cityMap
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        cityMap: generateMap(action.result),
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error
      };
    case SELECT_CHILD:
      return {
        ...state,
        selectedChildId: action.merchantId
      }
   default:
      return state;
  }
}

export function loadChildMerchants(accessToken) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/loadChildMerchants', {
      data: {accessToken}
    })
  };
}

export function selectChildId(merchantId){
  return {
    type: SELECT_CHILD,
    merchantId
  }
}