import { getCurrentEmailId } from "../../helpers/Helpers";

const LOAD = 'merchantSubscription/LOAD';
const LOAD_SUCCESS = 'merchantSubscription/LOAD_SUCCESS';
const LOAD_FAIL = 'merchantSubscription/LOAD_FAIL';

const SUBMIT_REQUEST = 'merchantSubscription/SUBMIT_REQUEST';
const SUBMIT_REQUEST_SUCCESS = 'merchantSubscription/SUBMIT_REQUEST_SUCCESS';
const SUBMIT_REQUEST_FAIL = 'merchantSubscription/SUBMIT_REQUEST_FAIL';

const UPDATING = 'merchantSubscription/UPDATING';
const UPDATED = 'merchantSubscription/UPDATED';
const UPDATE_ERROR = 'merchantSubscription/UPDATE_ERROR';

const MOVE_MERCHANT_TO_COMMISSION_LOAD= 'merchantSubscription/MOVE_MERCHANT_TO_COMMISSION_LOAD';
const MOVE_MERCHANT_TO_COMMISSION_SUCCESS= 'merchantSubscription/MOVE_MERCHANT_TO_COMMISSION_SUCCESS';
const MOVE_MERCHANT_TO_COMMISSION_FAIL= 'merchantSubscription/MOVE_MERCHANT_TO_COMMISSION_FAIL';

const UPDATE_LOYALTY = 'merchantSubscription/UPDATE_LOYALTY';
const UPDATE_LOYALTY_SUCCESS = 'merchantSubscription/UPDATE_LOYALTY_SUCCESS';
const UPDATE_LOYALTY_FAIL = 'merchantSubscription/UPDATE_LOYALTY_FAIL';

const UPDATE_DISCOUNT = 'merchantSubscription/UPDATE_DISCOUNT';
const UPDATE_DISCOUNT_SUCCESS = 'merchantSubscription/UPDATE_DISCOUNT_SUCCESS';
const UPDATE_DISCOUNT_FAIL = 'merchantSubscription/UPDATE_DISCOUNT_FAIL';

const GET_DISCOUNT = 'merchantSubscription/GET_DISCOUNT';
const GET_DISCOUNT_SUCCESS = 'merchantSubscription/GET_DISCOUNT_SUCCESS';
const GET_DISCOUNT_FAIL = 'merchantSubscription/GET_DISCOUNT_FAIL';

const UPDATE_SUBSCRIPTION = 'merchantSubscription/UPDATE_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESS = 'merchantSubscription/UPDATE_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_FAILED = 'merchantSubscription/UPDATE_SUBSCRIPTION_FAILED';

const UPDATE_FIXED_CASHIN = 'merchantSubscription/UPDATE_FIXED_CASHIN_SUBSCRIPTION';
const UPDATE_FIXED_CASHIN_SUCCESS = 'merchantSubscription/UPDATE_FIXED_CASHIN_SUCCESS';
const UPDATE_FIXED_CASHIN_FAILED = 'merchantSubscription/UPDATE_FIXED_CASHIN_FAILED';

const GET_PACKAGE_RENWAL_INFO = 'merchantSubscription/GET_PACKAGE_RENWAL_INFO'
const GET_PACKAGE_RENWAL_INFO_SUCCESS = 'merchantSubscription/GET_PACKAGE_RENWAL_INFO_SUCCESS'
const GET_PACKAGE_RENWAL_INFO_FAILED = 'merchantSubscription/GET_PACKAGE_RENWAL_INFO_FAILED'

const UPDATE_MERCHAT_MFD = 'merchantSubscription/UPDATE_MERCHAT_MFD';
const UPDATE_MERCHAT_MFD_SUCCESS = 'merchantSubscription/UPDATE_MERCHAT_MFD_SUCCESS';
const UPDATE_MERCHAT_MFD_FAILED = 'merchantSubscription/UPDATE_MERCHAT_MFD_FAILED';

const getCurrentEmail = getCurrentEmailId() || ""

const initialState = {
  loading: false,
  loaded: false,
  requested: false,
}

export default function merchantSubscription(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        merchantBusinessModel: action.result
      }
    case LOAD_FAIL:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.error
      }
    case SUBMIT_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
      };
    case SUBMIT_REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
      };

    case UPDATING:
      return {
        ...state,
        updating:true
      }
    case UPDATED:
      return {
        ...state,
        updating:false,
        updateResult: action.result,
        updateError: null
      }
    case UPDATE_ERROR:
      return {
        ...state,
        updating: false,
        updateError: action.error,
        updateResult: null
      }
    case MOVE_MERCHANT_TO_COMMISSION_LOAD:
      return {
        ...state,
        movingMerchantToCommission: true
      }

    case MOVE_MERCHANT_TO_COMMISSION_SUCCESS:
      return {
        ...state,
        movingMerchantToCommission: false,
        movedMerchantToCommissionData: action.result,
        movingMerchantToCommissionError: null,
      }

    case MOVE_MERCHANT_TO_COMMISSION_FAIL:
        return {
          ...state,
          movingMerchantToCommission: false,
          movedMerchantToCommissionData: null,
          movingMerchantToCommissionError: action.error,
        }
    case UPDATE_LOYALTY: 
        return {
          ...state,
          updateLoyalty: true,
          updatedLoyalty: false,
        }
    case UPDATE_LOYALTY_SUCCESS: 
        return {
          ...state,
          updateLoyalty: false,
          updatedLoyalty: true,
        }
    case UPDATE_LOYALTY_FAIL: 
        return {
          ...state,
          updateLoyalty: false,
          updatedLoyalty: false,
        }
    case GET_DISCOUNT: 
        return {
          ...state,
          getDiscount: true,
          gotDiscount: false,
        }
    case GET_DISCOUNT_SUCCESS: 
        return {
          ...state,
          getDiscount: false,
          discountData: action.result,
        }
    case GET_DISCOUNT_FAIL: 
        return {
          ...state,
          getDiscount: false,
          discountData: false,
          discountError: action.error,
        }
    case UPDATE_SUBSCRIPTION: 
        return {
          ...state,
          update_loading: true,
        }
    case UPDATE_SUBSCRIPTION_SUCCESS: 
        return {
          ...state,
          update_loading: false,
          update_data: action.result,
        }
    case UPDATE_SUBSCRIPTION_FAILED: 
        return {
          ...state,
          update_loading: false,
          update_data: false,
          update_error: action.error,
        }
    case UPDATE_FIXED_CASHIN:
      return {
        ...state,
        update_fixed_cashin_loading: true,
      }
    case UPDATE_FIXED_CASHIN_SUCCESS:
      return {
        ...state,
        update_fixed_cashin_loading: false,
        update_fixed_cashin_result: action.result,
      }
    case UPDATE_FIXED_CASHIN_FAILED:
      return {
        ...state,
        update_fixed_cashin_loading: false,
        update_fixed_cashin_result: false,
        update_fixed_cashin_error: action.error,
      }
    case GET_PACKAGE_RENWAL_INFO:
      return {
        ...state,
        gettingRenwalInfo: true,
      }
    case GET_PACKAGE_RENWAL_INFO_SUCCESS:
      return {
        ...state,
        gettingRenwalInfo: false,
        renwalInfoData: action.result,
      }
    case GET_PACKAGE_RENWAL_INFO_FAILED:
      return {
        ...state,
        gettingRenwalInfo: false,
        renwalInfoData: false,
      }

    case UPDATE_MERCHAT_MFD:
      return {
        ...state,
        updatingMerchantMFD: true,
      }
    case UPDATE_MERCHAT_MFD_SUCCESS:
      return {
        ...state,
        updatingMerchantMFD: false,
        updateMerchantMFDResult: action.result,
      }
    case UPDATE_MERCHAT_MFD_FAILED:
      return {
        ...state,
        updatingMerchantMFD: false,
        updateMerchantMFDResult: null,
        updateMerchantMFDError: action.error,
      }

    default:
       return state;
  }
}

export function loadMerchantBusinessModel(accessToken){
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/loadMerchantBusinessModel',{
      data: {accessToken}
    })
  }
}

export function submitPauseSubsRequest(accessToken, merchantName, reason, detail='', skipMovement, message='', updatedBy = ""){
  updatedBy = updatedBy ? updatedBy : (getCurrentEmailId() || "merchant_portal_redux")
  return {
    types: [SUBMIT_REQUEST, SUBMIT_REQUEST_SUCCESS, SUBMIT_REQUEST_FAIL],
    promise: (client) => client.default.post('/submitPauseSubsRequest', {
      data: {accessToken, merchantName, reason, detail, message, updatedBy, skipMovement}
    })
  }
}

export function updateSubscription(accessToken, active, merchantName, reason, bankDetail){
  let data = {accessToken, active, merchantName, reason, updatedBy: getCurrentEmail};
  if(bankDetail)
    data['bankDetail'] = bankDetail
  return {
    types: [UPDATING, UPDATED, UPDATE_ERROR],
    promise: client => client.default.post('/updateSubscription',{data})
  }
}
export function moveMerchantFromPackage(accessToken, moveTo, reason, skipMovement = null, updatedBy = ""){
  updatedBy = updatedBy ? updatedBy : (getCurrentEmailId() || "merchant_portal_redux")
  return {
    types: [MOVE_MERCHANT_TO_COMMISSION_LOAD, MOVE_MERCHANT_TO_COMMISSION_SUCCESS, MOVE_MERCHANT_TO_COMMISSION_FAIL],
    promise: (client) => client.default.post('/moveMerchantFromPackage', {
      data: {accessToken, moveTo, reason, skipMovement, updatedBy}
    })
  }
}

export function updateLoyaltyForMerchant(accessToken, payload){
  return {
    types: [UPDATE_LOYALTY, UPDATE_LOYALTY_SUCCESS, UPDATE_LOYALTY_FAIL],
    promise: (client) => client.default.post('/updateLoyaltyForMerchant', {
      data: {...payload, accessToken}
    })
  }
}

export function updateFirstTimeCustomerDiscount(accessToken, payload){
  return {
    types: [UPDATE_DISCOUNT, UPDATE_DISCOUNT_SUCCESS, UPDATE_DISCOUNT_FAIL],
    promise: (client) => client.default.post('/updateFirstTimeCustomerDiscount', {
      data: {...payload, accessToken}
    })
  }
}

export function getFirstTimeDiscountApplicable(accessToken, payload){
  return {
    types: [GET_DISCOUNT, GET_DISCOUNT_SUCCESS, GET_DISCOUNT_FAIL],
    promise: (client) => client.default.post('/getFirstTimeDiscountApplicable', {
      data: {...payload, accessToken}
    })
  }
}

export function unpauseSubscription(accessToken) {
  return {
    types: [UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION_FAILED],
    promise: (client) => client.default.post('/unpauseSubscription', {
      data: {accessToken}
    })
  }
}

export function updateFixedCashin(accessToken, magicpayDayWiseMFD, updatedBy) {

  updatedBy = updatedBy ? updatedBy : (getCurrentEmailId() || "merchant_portal_redux")

  return {
    types: [UPDATE_FIXED_CASHIN, UPDATE_FIXED_CASHIN_SUCCESS, UPDATE_FIXED_CASHIN_FAILED],
    promise: (client) => client.default.post('/reconcileFixedCashin', {
      data: {accessToken, magicpayDayWiseMFD, updatedBy}
    })
  }
}

export function packageRenewalInfo(accessToken) {
  return {
    types: [GET_PACKAGE_RENWAL_INFO, GET_PACKAGE_RENWAL_INFO_SUCCESS, GET_PACKAGE_RENWAL_INFO_FAILED],
    promise: (client) => client.default.post('/packageRenewalInfo', {
      data: {accessToken}
    })
  }
}

export function updateMerchantMFD(accessToken, magicpayDayWiseMFDMx, cashVoucherDayWiseMFDMx, comboVoucherDayWiseMFDMx, magicOrderDayWiseMFDMx, phoneNumber) {

  let updatedBy = phoneNumber ? phoneNumber : (getCurrentEmailId() || "merchant_portal_redux")

  return {
    types: [UPDATE_MERCHAT_MFD, UPDATE_MERCHAT_MFD_SUCCESS, UPDATE_MERCHAT_MFD_FAILED],
    promise: (client) => client.default.post('/reconcileFixedCashin', {
      data: {accessToken, magicpayDayWiseMFDMx, cashVoucherDayWiseMFDMx, comboVoucherDayWiseMFDMx, magicOrderDayWiseMFDMx, updatedBy}
    })
  }
}