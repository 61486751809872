import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadScript } from '../../helpers/Helpers';

const ga = window.ga;

export default class HeatMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapType: 'home',
      scriptLoaded: false,
    };
  }

  componentWillMount(){
    Promise.all([
      loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDyHksPCg1-wdLRNmqdae5OY--oUiFxOI4&libraries=visualization', 
        'google')
    ])
      .then(() => this.setState({scriptLoaded: true}))
      .catch(err=> console.log(err));
  }

  updateHeatmap = () => {
    const mapdata = [];
    if(this.state.scriptLoaded && window.google && window.google.maps){
      /* Data points defined as an array of LatLng objects */
      
      const latlongs = this.props.customerLocations[this.state.mapType];
      if(!Array.isArray(latlongs)) return;
      const mapdata = latlongs.map(l => ({location: new window.google.maps.LatLng(l.lat_long[0], l.lat_long[1]), weight: l.count}));

      const merchantLocation = new window.google.maps.LatLng(this.props.user.lattitude, this.props.user.longitude);

      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: merchantLocation,
        scrollwheel: false,
        zoom: 11,
        style: {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {color:"#979897"},
            { lightness: 50 },
            { visibility: "off" }
          ]
        }
      });

     const heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: mapdata,
        map: map
      });

      var gradient = [
        'rgba(0, 255, 255, 0)',
        'rgba(0, 255, 255, 1)',
        'rgba(0, 191, 255, 1)',
        'rgba(0, 127, 255, 1)',
        'rgba(0, 63, 255, 1)',
        'rgba(0, 0, 255, 1)',
        'rgba(0, 0, 223, 1)',
        'rgba(0, 0, 191, 1)',
        'rgba(0, 0, 159, 1)',
        'rgba(0, 0, 127, 1)',
        'rgba(63, 0, 91, 1)',
        'rgba(127, 0, 63, 1)',
        'rgba(191, 0, 31, 1)',
        'rgba(255, 0, 0, 1)'
      ]
      heatmap.set('gradient',gradient);

      // const markers = mapdata.map(function (location, i) {
      //   return new window.google.maps.Marker({
      //     position: location,
      //   });
      // });

      // const markerCluster = new window.MarkerClusterer(map, markers,
      //   { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }
      // );

      // const marker = new window.google.maps.Marker({
      //   position: merchantLocation,
      //   map: map,
      //   title: 'Your Location',
      //   icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'
      // });
    }
  }

  componentDidMount(){
    this.updateHeatmap();
  }

  componentDidUpdate(){
    this.updateHeatmap();
  }

  render() {
    return (
    <div style={{width:'100%', display:'block', margin: '0 auto', marginBottom: '24px', borderRadius: '8px', border:'1px solid #d8dae1', position: 'relative'}}>
    {this.props.showOptions &&
      <div className="heat-map-link" style={{position: 'absolute', right: '100px', zIndex: '1', top: '24px'}}>
        <ul style={{border: '1px solid #d8dae1', borderRadius: '8px', backgroundColor: '#fff'}}>
          <li>
            <a
              className={this.state.mapType == 'home' ? 'active' : ''}
              onClick={()=> { ga('send', 'event', 'Analytics', 'Customers Map Toggle', 'Home Map'); this.setState({mapType: 'home'});}}
              style={{padding: '8px', borderRight: '1px solid #d8dae1'}}>
              Home
            </a>
          </li>
          <li>
            <a
              className={this.state.mapType == 'work' ? 'active' : ''}
              onClick={()=> { ga('send', 'event', 'Analytics', 'Customers Map Toggle', 'Work Map'); this.setState({mapType: 'work'});}}
              style={{padding: '8px', marginLeft: '0'}}>
              Work</a></li>
        </ul>
      </div>
    }
      <div style={{width:'100%', height:'280px'}} id="map">
        Loading Map
      </div>
    </div>
    );
  }
}
