import { relative } from "path";
import { userTypes } from "../../constants";
import { func } from "prop-types";

const UPLOAD_CSV = 'merchantCrm/UPLOAD_CSV';
const CSV_UPLOADED = 'merchantCrm/CSV_UPLOADED';
const CSV_FAILED = 'merchantCrm/CSV_FAILED';

const ADD_CONTACT = 'merchantCrm/ADD_CONTACT';
const ADDED_CONTACT = 'merchantCrm/ADDED_CONTACT';
const ADD_CONTACT_FAILED = 'merchantCrm/ADD_CONTACT_FAILED';

const FEEDBACK_SUBMIT = 'merchantCrm/FEEDBACK_SUBMIT';
const FEEDBACK_SUBMITTED = 'merchantCrm/FEEDBACK_SUBMITTED';
const FEEDBACK_SUBMIT_FAILED = 'merchantCrm/FEEDBACK_SUBMIT_FAILED';

const LOAD = 'merchantCrm/LOAD';
const LOAD_SUCCESS = 'merchantCrm/LOAD_SUCCESS';
const LOAD_FAILED = 'merchantCrm/LOAD_FAILED';

const LOAD_CRM_DATA = 'merchantCrm/LOAD_CRM_DATA';
const LOAD_CRM_DATA_SUCCESS = 'merchantCrm/LOAD_CRM_DATA_SUCCESS';
const LOAD_CRM_DATA_FAILED = 'merchantCrm/LOAD_CRM_DATA_FAILED';

const SMS_LOAD = 'merchantCrm/SMS_LOAD';
const SMS_LOAD_SUCCESS = 'merchantCrm/SMS_LOAD_SUCCESS';
const SMS_LOAD_FAILED = 'merchantCrm/SMS_LOAD_FAILED';

const SMS_SCHEDULE = 'merchantCrm/SMS_SCHEDULE';
const SMS_SCHEDULED = 'merchantCrm/SMS_SCHEDULED';
const SMS_SCHEDULE_FAILED = 'merchantCrm/SMS_SCHEDULE_FAILED';


const initialState = {
  loading: false,
  loaded: false,
}

export default function merchantCrm(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return{
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return{
        ...state,
        loading: false,
        totalContacts: action.result,
      };
    case LOAD_FAILED:
      return{
        ...state,
        loading: false,
        totalContacts: null,
        error: action.error,
      };
    case LOAD_CRM_DATA:
      return{
        ...state,
        crm_data_loading: true,
      };
    case LOAD_CRM_DATA_SUCCESS:
      return{
        ...state,
        crm_data_loading: false,
        all_crm_data: action.result,
      };
    case LOAD_CRM_DATA_FAILED:
      return{
        ...state,
        crm_data_loading: false,
        all_crm_data: null,
        crm_data_error: action.error,
      };
    case SMS_LOAD:
      return{
        ...state,
        sms_loading: true,
      };
    case SMS_LOAD_SUCCESS:
      return{
        ...state,
        sms_loading: false,
        sms_data: action.result,
      };
    case SMS_LOAD_FAILED:
      return{
        ...state,
        sms_loading: false,
        sms_data: null,
        sms_error: action.error,
      };
    case ADD_CONTACT:
        return {
            ...state,
            adding: true,
        };
    case ADDED_CONTACT:
        return {
            ...state,
            adding: false,
            data: action.result,
            addError: null,
        };
    case ADD_CONTACT_FAILED:
        return {
            ...state,
            adding: false,
            data: null,
            addError: action.error
        };
    case FEEDBACK_SUBMIT:
        return {
            ...state,
            submitting: true,
        };
    case FEEDBACK_SUBMITTED:
        return {
            ...state,
            submitting: false,
            submit_data: action.result,
            submitError: null,
        };
    case FEEDBACK_SUBMIT_FAILED:
        return {
            ...state,
            submitting: false,
            submit_data: null,
            submitError: action.error,
        };
    case UPLOAD_CSV:
        return {
            ...state,
            uploading: true
        };
    case CSV_UPLOADED:
        return {
            ...state,
            uploading: false,
            uploadResult: action.result,
            uploadError: null,
        };
    case CSV_FAILED:
        return {
            ...state,
            uploading: false,
            uploadResult: null,
            uploadError: action.error
        };
    case SMS_SCHEDULE:
      return {
          ...state,
          scheduling: true
      };
    case SMS_SCHEDULED:
        return {
            ...state,
            scheduling: false,
            scheduleResult: action.result,
            scheduleError: null,
        };
    case SMS_SCHEDULE_FAILED:
        return {
            ...state,
            scheduling: false,
            scheduleResult: null,
            scheduleError: action.error
        };
    default:
        return state;
  }
}

export function uploadCrmContacts(accessToken, file, fileName, merchantUserId){
  let data = new FormData();
  data.append('merchant_single_photo', file, file.name ? file.name : fileName);
  data.append('accessToken', accessToken);
  data.append('merchantUserId',merchantUserId);
  return {
    types:[UPLOAD_CSV, CSV_UPLOADED, CSV_FAILED],
    promise:(client) => client.default.post('/uploadCrmContacts', {
      data
    })
  }
}

export function addSingleContact(accessToken, phoneNumber, merchantUserId, dataType="single"){
  return {
    types:[ADD_CONTACT, ADDED_CONTACT, ADD_CONTACT_FAILED],
    promise:(client) => client.default.post('/addSingleContact', {
      data: {
        accessToken: accessToken,
        merchantUserId: merchantUserId,
        phoneNumber: dataType === "single" ? [phoneNumber,]: phoneNumber,
      }
    })
  }
}

export function sendFeedback(merchantName, merchantId, feedback){
  return {
    types:[FEEDBACK_SUBMIT, FEEDBACK_SUBMITTED, FEEDBACK_SUBMIT_FAILED],
    promise:(client) => client.default.post('/writeToFeedbackSheet', {
      data: {
        merchantName: merchantName,
        merchantId: merchantId,
        feedback: feedback,
      }
    })
  }
}

export function getGlobalContactList(accessToken, merchantUserId){
  return {
    types:[LOAD, LOAD_SUCCESS, LOAD_FAILED],
    promise:(client) => client.default.post('/getGlobalContactList', {
      data: {
        accessToken: accessToken,
        merchantUserId: merchantUserId,
      }
    })
  }
}

export function getSMSBudget(accessToken, merchantUserId){
  return {
    types:[SMS_LOAD, SMS_LOAD_SUCCESS, SMS_LOAD_FAILED],
    promise:(client) => client.default.post('/getSMSBudget', {
      data: {
        accessToken: accessToken,
        merchantUserId: merchantUserId,
      }
    })
  }
}

export function getAllCrmData(ultronToken, merchantUserId,lightVersion=true){
  const headers = {
    'auth-userid': merchantUserId,
    'auth-user-token': ultronToken
  }
  return {
    types: [LOAD_CRM_DATA, LOAD_CRM_DATA_SUCCESS, LOAD_CRM_DATA_FAILED],
    promise: (client) => client.default.get(`${lightVersion ? "https://webapi.magicpin.in/ultron-onboarding/crm/globalKey":"https://webapi.magicpin.in/ultron-onboarding/crm/data"}`, {
      headers: headers
    })
  };
}

export function scheduleSmsBlast(accessToken, messageText, segmentUuid, startTime, occurrence, amount, transactionId, isPaid){

  let payload = {
      accessToken: accessToken,
      messageText: messageText,
      segmentUuid: segmentUuid,
      startTime: startTime,
      occurrence: occurrence,
      campaignType: "ONE_TIME",
      amount: amount,
  }
  if(isPaid){
    payload = {
      ...payload,
      transactionId,
      promotionType: "PAID",
    }
  }
  return {
    types:[SMS_SCHEDULE, SMS_SCHEDULED, SMS_SCHEDULE_FAILED],
    promise:(client) => client.default.post('/scheduleSMS', {
      data: payload
    })
  }
}
