import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import './HomeDelivery.css';

import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: "10px",
    width: window.innerWidth > 768 ? "324px" : "250px"
  },
  closeButton: {
    position: 'absolute',
    right: "5px",
    top: "5px",
    color: "gray",
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <img src={closeBtn}/>
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: "16px",
    textAlign: "center"
  },
}))(MuiDialogContent);

export default class CancelOrderDialog extends Component {
    

    cancelOrderAndClosePopup = (order) => {
        this.props.cancelOrder(order)
        this.props.closePopUp()
    }

render () {
  return (
      <Dialog onClose={() => this.props.closePopUp()} aria-labelledby="customized-dialog-title" open={true}>
        <DialogTitle id="customized-dialog-title" onClose={() => this.props.closePopUp()}>
            {/* Improve rank
            <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
        </DialogTitle>
        
        <DialogContent dividers>
            <div style={{ fontSize: '16px', fontFamily: 'robotoregular', paddingBottom: '8%'}}>
                <div>Are you sure you want to cancel this order?</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{fontSize: '14px', padding: '6px 24px'}} className="merchant-primary-action-btn" onClick={() => this.props.closePopUp()}>
                    NO
                </div>
                <div style={{fontSize: '14px', color: 'rgba(0,0,0,0.87)', backgroundColor: '#fff', border: '1px solid rgba(0,0,0,0.12)', padding: '6px 24px'}} className="merchant-primary-action-btn" onClick={() => this.cancelOrderAndClosePopup(this.props.order)}>
                    YES
                </div>
            </div>
        </DialogContent>
      </Dialog>
  );
          }
}

/**
 * 
 * 
 */