import React, {Component} from 'react';
import {toast} from 'react-toastify';
import Select from 'react-select';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';
import { Tooltip} from 'react-bootstrap';

const ga = window.ga;

export default class RatingsChart extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedMerchants : [{name: 'You', locality: '', id: this.props.user.merchantId}],
      startDate: moment().subtract(15, 'days'),
      endDate: moment(),
    };
    this.changeChartData(this.props.combinedRatings);
  }

  addMerchant = (value) => {
    ga('send', 'event', 'Analytics', 'Track_Competitor', 'Add_Competitor');
    if(this.state.selectedMerchants.length == 4){
      toast('you can select upto 3 other merchants');
      return;
    }
    if(this.state.selectedMerchants.find((m) => m.id == value[0].value.merchant_id)){
      toast('you have already selected this merchant');
      return;
    }
    this.setState({selectedMerchants: [...this.state.selectedMerchants,
      {name: value[0].value.merchant_name, locality: value[0].value.locality, id: value[0].value.merchant_id}]
    });
  }

  onStartDateChange = (e, date) => {
    // this indicates the date range change has been initiated
    this.startDateChanged = moment(date).format('YYYYMMDD') !== this.state.startDate.format('YYYYMMDD');
    this.setState({startDate: moment(date)});
  }

  onEndDateChange = (e, date) => {
    if(this.startDateChanged || moment(date).format('YYYYMMDD') !== this.state.endDate.format('YYYYMMDD')){
      const merchantIds = this.state.selectedMerchants.map(merchant => merchant.id);
      this.props.getRatingsMerchants(this.props.user.accessToken, merchantIds, this.state.startDate.format('YYYY-MM-DD'), moment(date).format('YYYY-MM-DD'));
    }
    this.setState({endDate: moment(date)});
    this.startDateChanged = false; // reset the range change bit
  }


  componentWillUpdate(nextprops, nextstate){
    if(this.state.selectedMerchants.length != nextstate.selectedMerchants.length){
      const merchantIds = nextstate.selectedMerchants.map(merchant => merchant.id);
      this.props.getRatingsMerchants(this.props.user.accessToken, merchantIds, nextstate.startDate.format('YYYY-MM-DD'), nextstate.endDate.format('YYYY-MM-DD'));
    }
  }

  getOptions = (input, callback) => {
    // console.log('input', input);
    if(!input || input.length < 3) {
      callback(null); // this hack is required to disable loading state after selecting any option
      return;
    };
    ga('send', 'event', 'Analytics', 'Search Merchants Views', input);
    this.props.getMerchants('*', '*', input).then(result => {
      // console.log('result', result);
      const options = result[0].map(merchant => ({value: merchant, label: merchant.merchant_name + ', ' + merchant.locality}));
      // console.log('options', options);
      callback(null, {options});
    });
  }

  changeChartData = (combinedRatings) => {
    let diff = this.state.endDate.diff(this.state.startDate,'days');
    this.chartData = [];
    this.chartFields = [{'type': 'string', 'label' : 'Time'}];
    for(let i = moment(this.state.startDate), end = moment(this.state.endDate); i <= end; i.add(1, 'days')){
      let ratingsVals = [i.format('DD-MM')];
      for(let j = 0; j < combinedRatings.length; ++j){
        for(let k = 0; k <= diff; k++){
          let t = moment(combinedRatings[j][k].date).format('DD-MM');
          if(t == i.format('DD-MM')){
            const val = combinedRatings[j][k].rating? combinedRatings[j][k].rating : 0;
            ratingsVals.push(val);
          }
        }
      }
      this.chartData.push(ratingsVals);
    }
    for(let i = 0; i < combinedRatings.length; ++i) {
      this.chartFields.push({
        'type' : 'number',
        'label' : i < this.state.selectedMerchants.length ? this.state.selectedMerchants[i].name : 'Views'
      });
    }
  }

  componentWillReceiveProps(nextprops){
    if(this.props.combinedRatings != nextprops.combinedRatings) this.changeChartData(nextprops.combinedRatings);
  }

  render(){
    const chartOptions = {
      dataOpacity: 0.8 ,
      colors: ["#338aed", "#00bfa5", "#fe9844", "#7e57c2"],
      chartArea: {
        width: '85%',
        height:'70%'
      },
      bar: {groupWidth: "70%"},
      // legend: { position: "top", maxLines: 3 },
      legend: { position: 'none' },
      isStacked: true,
      vAxis:{
        gridThickness: 0,
        labelAngle:180,
        textStyle:{color: '#9cadb5'},
        gridlines: {
          color: '#e5e5e5',
          baselineColor: '#e5e5e5',
          opacity: '0.5'
        },
        format: 'decimal',
        baselineColor: 'transparent'
      },
      hAxis:{
        textStyle:{color: '#9cadb5'},
        gridlines: {
          color: 'transparent',
          count:2
        },
      },
    };

    const colors = ['blue', 'green', 'yellow', 'purple'];

    const selectedMerchants = this.state.selectedMerchants.map((merchant, index) =>
      <div className="graph-indicator" style={{display:'inline-block'}} key={merchant.name + merchant.locality}>
        <ul>
          <li><span className={"icon dot " + colors[index]}><i className="fa fa-circle"></i></span></li>
          <li>
            <span className="title-text">
              {merchant.name}<br/>
              <span className="address">{merchant.locality}</span>
            </span>
          </li>
          {index != 0 && <li>
            <a className="round-cross" onClick={() =>
                this.setState({selectedMerchants: this.state.selectedMerchants.filter(m => m.id != merchant.id)})}>
              <img src="images/cross2.png" />
            </a>
          </li>}
          <li><span className="divid">/</span></li>
        </ul>
      </div>
    );

    const tooltip = (
      // <Tooltip id="tooltip">{this.props.user.ratings.rating}</Tooltip>);
      <Tooltip id="tooltip">{this.props.merchantRatingsVisitsData.ratings.rating}</Tooltip>);

  return(
    <div className='comparison-wrapper'>
      <div className="white-box" style={{borderRadius:'8px', borderColor:'#d8dae1'}}>
        <div className="white-box-hd"
          style={{borderTopLeftRadius:'8px', borderTopRightRadius:'8px', background:'none',
            borderColor:'#d8dae1', display:'inline-block', width:'100%'}}>
            Compare Rating With Your Competitors

            <div className="pull-right" style={{marginTop: window.innerWidth < 768 ? '8px' : '0px'}}>
              <DatePicker autoOk={true} value={this.state.startDate.toDate()}
                maxDate={this.state.endDate.toDate()}
                className='feed-create-promotion'
                onChange={this.onStartDateChange}
                name='startDate'
                formatDate={(date) => moment(date).format('DD MMM, YYYY')}
                />
              to
              <DatePicker autoOk={true} value={this.state.endDate.toDate()}
                minDate={this.state.startDate.toDate()}
                className='feed-create-promotion'
                onChange={this.onEndDateChange}
                name='endDate'
                formatDate={(date) => moment(date).format('DD MMM, YYYY')}
                />
            </div>
          </div>
          <div className="form-horizontal" style={{padding:'16px'}}>
            <div style={{display:'inline-block', width:'100%'}}>
              <div style={{display:'inline-block', width:'100%', margin:'2% 0'}}>
              <div className='parent-merchant-hdr-wrapper dropdown pull-left' style={{padding:0}}>
                <Select.Async
                  placeholder='Add any 3 competitors'
                  loadOptions = {this.getOptions}
                  style={{width: '300px'}}
                  autoload={false}
                  multi
                  onChange = {this.addMerchant}
                  />
              </div>
              <div className="pull-right" style={{display:'inline-block', margin:'2% 0'}}>
                {selectedMerchants}
              </div>
              </div>

              <div className="chartWrapper" id="LineChart" style={window.innerWidth >= 768 ? {marginTop:'-50px'}:{marginTop:'0px'} }>
                <div style={{width:'100%', height:'330px'}}>
                  {this.props.combinedRatings.length > 0 && <this.props.Chart
                    chartType="LineChart"
                    rows={this.chartData}
                    columns={this.chartFields}
                    options={chartOptions}
                    graph_id="competitorRatingsChart"
                    width={"100%"}
                    height={"100%"}
                    legend_toggle={true} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
