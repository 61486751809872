const LOAD = 'impressionDistribution/LOAD';
const LOAD_SUCCESS = 'impressionDistribution/LOAD_SUCCESS';
const LOAD_FAIL = 'impressionDistribution/LOAD_FAIL';

const LOAD_CLICKS = 'impressionDistribution/LOAD_CLICKS';
const LOAD_CLICKS_SUCCESS = 'impressionDistribution/LOAD_CLICKS_SUCCESS';
const LOAD_CLICKS_FAIL = 'impressionDistribution/LOAD_CLICKS_FAIL';

const LOAD1 = 'impressionDistribution/LOAD1';
const LOAD_SUCCESS1 = 'impressionDistribution/LOAD_SUCCESS1';
const LOAD_FAIL1 = 'impressionDistribution/LOAD_FAIL1';

const LOAD_CLICKS1 = 'impressionDistribution/LOAD_CLICKS1';
const LOAD_CLICKS_SUCCESS1 = 'impressionDistribution/LOAD_CLICKS_SUCCESS1';
const LOAD_CLICKS_FAIL1 = 'impressionDistribution/LOAD_CLICKS_FAIL1';

const LOAD_CASHBACK = 'impressionDistribution/LOAD_CASHBACK';
const LOAD_CASHBACK_SUCCESS = 'impressionDistribution/LOAD_CASHBACK_SUCCESS';
const LOAD_CASHBACK_FAIL = 'impressionDistribution/LOAD_CASHBACK_FAIL';



const initialState = {
  loaded: false,
  combinedImpressions: [],
  combinedClicks: [],
  combinedCashback1: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return !action.caller ? {...state,
        loading: true}
        :{...state, [action.caller+'Loading'] : true}

    case LOAD_SUCCESS:
      return !action.caller ? {...state,
        loading: false,
        loaded: true,
        combinedImpressions: action.result}
        : {...state, [action.caller] : action.result, [action.caller+'Loading']: false, [action.caller+'Loaded']: true}
      
    case LOAD_FAIL:
      return !action.caller ? {...state,
        loading: false,
        loaded: false,
        combinedImpressions: [],
        error: action.error}
        : {...state, [action.caller] : [], [action.caller+'Loading']: false, [action.caller+'Loaded']: false,[action.caller+'Error']: action.error}

    case LOAD_CLICKS:
      return !action.caller ? {...state,
        loading: true}
        : {...state, [action.caller+'Loading'] : true}

    case LOAD_CLICKS_SUCCESS:
      return !action.caller ? {...state,
        loading: false,
        loaded: true,
        combinedClicks: action.result}
        : {...state, [action.caller] : action.result, [action.caller+'Loading']: false, [action.caller+'Loaded']: true}

    case LOAD_CLICKS_FAIL:
      return !action.caller ? {...state,
        loading: false,
        loaded: false,
        combinedClicks: [],
        error: action.error}
        : {...state, [action.caller] : [], [action.caller+'Loading']: false, [action.caller+'Loaded']: false,[action.caller+'Error']: action.error}
      case LOAD1:
        return !action.caller ? {...state,
          loading1: true}
          :{...state, [action.caller+'Loading'] : true}
  
      case LOAD_SUCCESS1:
        return !action.caller ? {...state,
          loading1: false,
          loaded1: true,
          combinedImpressions1: action.result}
          : {...state, [action.caller] : action.result, [action.caller+'Loading']: false, [action.caller+'Loaded']: true}
        
      case LOAD_FAIL1:
        return !action.caller ? {...state,
          loading1: false,
          loaded1: false,
          combinedImpressions1: [],
          error: action.error}
          : {...state, [action.caller] : [], [action.caller+'Loading1']: false, [action.caller+'Loaded1']: false,[action.caller+'Error1']: action.error}
  
      case LOAD_CLICKS1:
        return !action.caller ? {...state,
          loading1: true}
          : {...state, [action.caller+'Loading1'] : true}
  
      case LOAD_CLICKS_SUCCESS1:
        return !action.caller ? {...state,
          loading1: false,
          loaded1: true,
          combinedClicks1: action.result}
          : {...state, [action.caller] : action.result, [action.caller+'Loading1']: false, [action.caller+'Loaded1']: true}
  
      case LOAD_CLICKS_FAIL1:
        return !action.caller ? {...state,
          loading1: false,
          loaded1: false,
          combinedClicks1: [],
          error: action.error}
          : {...state, [action.caller] : [], [action.caller+'Loading1']: false, [action.caller+'Loaded1']: false,[action.caller+'Error1']: action.error}

      case LOAD_CASHBACK:
          return !action.caller ? {...state,
              loading1: true}
              : {...state, [action.caller+'Loading1'] : true}
      
      case LOAD_CASHBACK_SUCCESS:
          return !action.caller ? {...state,
              loading1: false,
              loaded1: true,
              combinedCashback1: action.result}
              : {...state, [action.caller] : action.result , [action.caller+'Loading1']: false, [action.caller+'Loaded1']: true}
      
      case LOAD_CASHBACK_FAIL:
          return !action.caller ? {...state,
              loading1: false,
              loaded1: false,
              combinedCashback1: [],
              error: action.error}
              : {...state, [action.caller] : [], [action.caller+'Loading1']: false, [action.caller+'Loaded1']: false,[action.caller+'Error1']: action.error}

  
   default:
      return state;
  }
}

export function getImpressionsMerchants(accessToken, merchantIds, startDate, endDate, caller) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getImpressionsMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    caller
  };
}

export function getClicksMerchants(accessToken, merchantIds, startDate, endDate, caller) {
  return {
    types: [LOAD_CLICKS, LOAD_CLICKS_SUCCESS, LOAD_CLICKS_FAIL],
    promise: (client) => client.default.post('/getClicksMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    caller
  };
}

export function getImpressionsMerchants1(accessToken, merchantIds, startDate, endDate, caller) {
  return {
    types: [LOAD1, LOAD_SUCCESS1, LOAD_FAIL1],
    promise: (client) => client.default.post('/getImpressionsMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    caller
  };
}

export function getClicksMerchants1(accessToken, merchantIds, startDate, endDate, caller) {
  return {
    types: [LOAD_CLICKS1, LOAD_CLICKS_SUCCESS1, LOAD_CLICKS_FAIL1],
    promise: (client) => client.default.post('/getClicksMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    caller
  };
}

export function getCashbackMerchant(accessToken, merchantIds, startDate, endDate, caller) {
  return {
    types: [LOAD_CASHBACK, LOAD_CASHBACK_SUCCESS, LOAD_CASHBACK_FAIL],
    promise: (client) => client.default.post('/getCashbackMerchants', {
      data: {accessToken, merchantIds, startDate, endDate}
    }),
    caller
  };
}
