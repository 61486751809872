import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as postActions from '../../redux/modules/postPage';
import FbShare from '../FbShare/FbShare';
import commentImage from '../../images/comment-image.svg';
import {Tooltip, OverlayTrigger,Image} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { extractHashtag } from '../../helpers/Helpers';
import './PostPage.css'
import { toast } from 'react-toastify';

const ga = window.ga;

export default connect(
  store => ({
    loading: store.postPage.loading,
    loaded: store.postPage.loaded,
    error: store.postPage.error,
    post : store.postPage.post,
    comments : store.postPage.comments,
    likelist: store.postPage.likelist,
    commentLikes: store.postPage.commentLikes,
    nextLikes:store.postPage.nextLikes,
  }),
  dispatch => bindActionCreators(postActions, dispatch)
)(class Postpage extends Component{
  constructor(props){
    super(props);
    this.state = {
      showData: 'comments',
      showCommentLikes: false,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.loaded == false && nextProps.loaded == true)
      this.props.getComments(nextProps.post.author_profile.user_id,nextProps.postId,nextProps.accessToken);
    if(this.props.loading && !nextProps.loading){
      if(nextProps.error){
        if(nextProps.error.status === 404)
          toast.warn('This is a private post')
        else
          toast.error(nextProps.error.message)
        this.props.hidePostModal()
        this.setState({showModal: false})
      }
      else{
        this.setState({showModal: true})
      }
    }
  }

  likeComment(comment_id){
    this.props.likeComment(this.props.accessToken,comment_id);
  }

  showCommentLikes(){
    this.setState({showCommentLikes: true});
  }

  getCommentLikes(comment_id)
  {
    this.showCommentLikes();
    this.props.getCommentLikes(this.props.accessToken,comment_id);
  }

  componentWillMount() {
    ga('send', 'pageview', 'Post Page');
    this.props.getPost(this.props.postId ,this.props.accessToken);
    // this.props.getComments(this.props.post.author_profile.user_id,this.props.postId,this.props.accessToken);
  }

  onClickGetComments(userId,postId,event){
    event.preventDefault();
    if(this.state.showData != 'comments')
    this.setState({showData: 'comments'});
    this.props.getComments(userId,postId,this.props.accessToken);
  }

  onClickGetLikes(userId,postId,event){
    event.preventDefault();
    if(this.state.showData != 'likes'){
      this.setState({showData: 'likes'});
    }
    this.props.getLikes(userId,postId,this.props.accessToken,-1);
  }

  onClickLikeButton(userId,postId,event){
    event.preventDefault();
    ga('send', 'event', 'Customer Page', 'like_customer', 'like_customer');
    this.props.like(this.props.accessToken, postId, userId);
  }

  onClickCommentButton = (userId,postId,logo,name,event) => {
    event.preventDefault();
    var content=this.refs.commentText;
    if(content){
      this.props.comment(userId,postId,content.value,this.props.accessToken,logo,name);
    }
    content.value = '';
  }

  onShare(){
    ga('send', 'event', 'Social Actions', 'Share', 'Post page');
    this.setState({shareModal : true});
  }

  render(){

    if(this.props.loaded){
      var authorProfile = this.props.post.author_profile;
      var post = this.props.post;
      var commentModalData = this.props.commentModalData;
      var deal = this.props.post.deal;
    }
    const likeTooltip = (
      <Tooltip id="likeTooltip">Like all your customer's picture to improve your repeat rate by 30%</Tooltip>);
    const commentTooltip = (
      <Tooltip id="commentTooltip">Say thanks to your customers and ask for their reviews.</Tooltip>);

    return (
      <div>
        <Modal show={this.state.showModal} dialogClassName="modal-dialog user-post-detail-modal" onHide={(e)=>{this.setState({showModal: false});this.props.hidePostModal(e)}}>
          <Modal.Header className="postPageModal" closeButton>
          </Modal.Header>
          <Modal.Body style={{overflowY:"hidden"}}>
            {!this.props.loaded || !post.image ? <div style={{width: '120px', margin: '10% auto'}}><img src= "images/loader.gif" /></div> :
            <div className= "modal-content">
              <div className="modal-body">
                <div className="row" style={{margin:'0'}}>
                  <div className="col-md-7 user-post-detail-selfie-wrapper">
                    <div className="user-post-detail-selfie">
                      {post.image && <img style={{width:"100%"}} src={post.image}/>}
                    </div>
                  </div>
                  <div className="col-md-5 user-post-detail-text-wrapper">
                    {/* <div className="user-post-detail-text"  style={{height: window.innerWidth <= 768 ? '334px' : '575px'}}> */}
                    <div style={{overflowY:"hidden"}} className="user-post-detail-text">
                      <div className="user-post-detail-text-top">
                        <div style={{padding:"0px",marginTop:"20px"}} className="cutomer-wraper row">
                          <div className="col-lg-1 col-xs-1"></div>
                          <div style={{paddingLeft:"0px", marginLeft:window.innerWidth <= 768 ? "-8px" : "" }} className="col-lg-2 col-xs-2">
                            <Image style={{width:"50px",height:"50px"}} src={authorProfile.profile_pic} circle />
                          </div>
                          <div style={{paddingLeft:"0px",marginLeft:window.innerWidth <=768 ? "-10px":""}} className="customer-hd-txt col-lg-8 col-xs-8">
                            <p style={{fontSize:"12px"}}><span style={{fontSize:"14px",fontFamily:"RobotoBold",color:"rgba(0,0,0,0.87)"}}>{authorProfile.name}</span><br/>
                            <span style={{color:"rgba(0,0,0,0.54)"}}>{authorProfile.vanity}</span>
                            <br/>
                            {moment(post.create_time).format('D MMM, YYYY')}
                            &nbsp;{moment(post.create_time).format('hh:mm a')}
                             </p>
                            {post.tagged_profiles ? <p style={{fontSize:"14px",fontFamily:"RobotoBold",color:"rgba(0,0,0,0.87)"}} className="note-taggtext">with <a href="#">{ReactHtmlParser( post.tagged_profiles)}</a></p>:""}
                          </div>
                        </div>
                        <div style={{fontSize:"14px",color:"rgba(0,0,0,0.54)",marginLeft:"15px"}} className="feed-user-block row">{ReactHtmlParser( post.caption)}</div>
                        <div className="Separator2"></div>
                        <div className="customer-action" style={{background:'none'}}>{ReactHtmlParser( post.caption, {transform:extractHashtag})}
                          <div style={{marginBottom:"4px"}} className="row">
                            <div style={{textAlign:"left"}} className="col-xs-4">
                            <OverlayTrigger placement="top" overlay={likeTooltip} rootClose={true}>
                              <a style={{color:post.like_id > 0 ? "#ff455f" : "rgba(0,0,0,0.54)"}} href="#" className={post.like_id > 0 ?"like-action fa fa-heart":"fa fa-thumbs-up"} onClick={this.onClickLikeButton.bind(this,authorProfile.user_id,post.post_id)}>
                              <span className="icon">
                              </span> Like
                              </a>
                            </OverlayTrigger>
                            </div>
                            <div style={{paddingLeft:"0px",paddingRight:"0px",textAlign:"left"}} className="col-xs-4">
                            <OverlayTrigger placement="top" overlay={commentTooltip} rootClose={true}>
                              <a style={{color:"rgba(0,0,0,0.54)"}} onClick={this.onClickGetComments.bind(this,authorProfile.user_id,post.post_id)} className="fa fa-comment">
                              <span className="icon">
                                {/* <img style={{width:"23px"}} src={commentIcon} /> */}
                                </span> Comment
                              </a>
                            </OverlayTrigger>
                            </div>
                            <div style={{textAlign:"left"}} className="col-xs-4">
                              <a style={{color:"rgba(0,0,0,0.54)"}} href="#" onClick={this.onShare.bind(this)}><span className="fa fa-share">
                                  {/* <img style={{width:"23px"}} src={shareIcon} /> */}
                                </span> Share
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-post-detail-text-bottom">
                        <ul className="nav nav-tabs">
                          <li>
                            <a style={{borderBottom:this.state.showData === 'comments' ? "2px solid #458eff":""}} 
                               onClick={this.onClickGetComments.bind(this,authorProfile.user_id,post.post_id)}>
                              <span>{post.comments}</span> Comments
                            </a>
                            </li>
                            <li>
                              <a style={{borderBottom:this.state.showData === 'likes' ? "2px solid #458eff":""}}
                                 onClick={(this.props.likelist && !this.props.likelist[0]) || this.state.showData == 'comments'?this.onClickGetLikes.bind(this,authorProfile.user_id,post.post_id):''}>
                                <span>{post.likes}</span> Likes
                              </a>
                              </li>
                            </ul>
                            <div className="tab-content">

                              {this.state.showData == 'comments' &&
                                <div  className="tab-pane fade in active" id="Comments">
                                  {
                                    window.innerWidth > 768 && !this.props.comments &&
                                    <div> 
                                      <div style={{textAlign:"center",marginTop:"23px"}}><Image src={commentImage}/></div>
                                      <div style={{marginRight:"auto",marginLeft:"auto",width:"80%",color:"rgba(0,0,0,0.54)",textAlign:"center"}}>When life gives you an opportunity to be first, you take it. Comment now!</div>
                                    </div>
                                  }
                                  {this.props.comments && this.props.comments.map((data, idx) =>{
                                    return (
                                      <div key={idx}>
                                        <div style={{height:window.innerWidth > 768 ? "265px" : "125px",overflowY:"auto",overflowX:"hidden"}} className="cutomer-wraper row">
                                          <div className="col-lg-1 col-xs-1"></div>
                                          <div style={{paddingLeft:"0px"}}className="col-lg-2 col-xs-2">    
                                            <Image style={{width:"50px",height:"50px"}} src={data.profile_pic} circle/>
                                          </div>  
                                          <div className="customer-hd-txt col-lg-7 col-xs-7">
                                            <p style={{marginBottom:"0",fontSize:"14px",fontFamily:"RobotoBold",color:"rgba(0,0,0,0.87)"}}><span>{data.name}</span></p>
                                            <p style={{fontSize:"12px",color:"rgba(0,0,0,0.54)"}}>{ReactHtmlParser( data.comment, {transform:extractHashtag})}</p>
                                            <p className="note-txt" style={{marginTop:'8px'}}>
                                              <span>{moment(data.action_time).fromNow()} </span>
                                              <span className="user-post-cmt-like-cont"><a href="#" style = {{textDecoration:'none'}} onClick={data.likes_count > 0 ?this.getCommentLikes.bind(this,data.comment_id):''}> {data.likes_count > 0 ? '\u2022 '+ data.likes_count + ' Likes' : ''} </a></span>
                                              <a className="user-post-cmt-like-btn" href="#">
                                                <span className={`${data.like_id != null ? "like-action " : " "} ${data.like_id > 0 ? ' fa fa-heart ' : ' fa fa-thumbs-up'}`} onClick={this.likeComment.bind(this,data.comment_id)}>
                                                  {/* <img src={data.like_id > 0 ? "images/heart-copy-7.png" : "images/liked-icon.png"}/> */}
                                                </span>
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )})}
                                    <div style={{marginTop:"12px",borderBottomRightRadius:"15px",padding:"8px",paddingLeft:"10px",bottom:"0px"}} className="user-post-dtl-comment-wrapper">

                                      <div className="cutomer-wraper">
                                        {/* <div className="mrcht-notification-card-dp"><img src={deal.merchant_logo} /></div> */}
                                        <div className="customer-hd-txt">
                                          <div>
                                              <div className="col-lg-3 col-xs-3">
                                                <Image style={{width:"40px",height:"40px"}} src={deal.merchant_logo} circle/>
                                              </div>
                                              <div style={{padding:"0"}} className="col-lg-9 col-xs-9 user-post-dtl-comment-block">
                                                <form role="form" onSubmit={e=>this.onClickCommentButton(authorProfile.user_id,post.post_id,deal.merchant_logo,deal.merchant_name, e)}>
                                                  <div className="row">
                                                    <div className="col-lg-9 col-xs-9">
                                                      <input type="text" className="comment-submit-bx" ref="commentText" required placeholder="Write a Comment" />
                                                    </div>
                                                    <div className="col-lg-3 col-xs-3" style={{paddingLeft:"0",textAlign:'right'}}>
                                                      <button className="comment-submit-btn fa fa-paper-plane" value=""></button>
                                                    </div>
                                                  </div>
                                                </form>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>}
                                  {(this.state.showData === 'likes' && this.props.likelist) &&
                                    <div style={{height:window.innerWidth > 768 ? "265px" : "140px",overflowY:"auto",overflowX:"hidden"}} className="tab-pane fade in active" id="Likes">
                                      {this.props.likelist.map((data, idx) => {
                                        return (
                                          <div key={idx} style={{display:"inline-flex",height:"auto",marginTop:"10px",marginBottom:"10px"}} className="cutomer-wraper row" >
                                            <div className="col-lg-1 col-xs-1"></div>
                                            <div style={{paddingLeft:"0px"}}className="col-lg-2 col-xs-2">
                                              <Image style={{width:"50px",height:"50px"}} src={data.profile_pic} circle/>
                                            </div>  
                                            <div className="col-lg-9 col-xs-9" >
                                              <div className="customer-hd-txt">
                                                <p style={{marginBottom:"0",fontSize:"14px",fontFamily:"RobotoBold",color:"rgba(0,0,0,0.87)"}}><span>{data.name}</span></p>
                                                <p className="note-txt">
                                                  <span style={{fontSize:"12px",color:"rgba(0,0,0,0.54)"}}>{data.vanity}</span>
                                                </p>
                                                {/* <div className="Separator2" style={{margin:'8px 0px 16px', width:'auto'}}></div> */}
                                              </div>
                                            </div>  
                                          </div>
                                        )}
                                      )}
                                      {(this.props.nextLikes != -1) && <div style={{textAlign:'centre'}}><a onClick={this.onClickGetLikes.bind(this,authorProfile.user_id,post.post_id)}>See More</a>
                                  </div>
                                }

                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Modal.Body>
          <Modal show = {this.state.showCommentLikes}  onHide={()=> this.setState({showCommentLikes: false})}>
            <div>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <div className="ReviewComment">
                  <h2 id="getCommentCount" style={{fontSize:'20px'}}><span className="review-cooment-icon"></span>Likes</h2>
                </div>
                {(this.state.showCommentLikes && this.props.commentLikes) &&
                  <div className="tab-pane fade in active" id="Likes">
                    {this.props.commentLikes.map((data, idx) => {
                      return (
                        <div key={idx} className="cutomer-wraper" >
                          <div className="custpmer-img-thumb"><img src={data.profile_pic} /></div>
                          <div className="customer-hd-txt">
                            <p><span>{data.name}</span></p>
                            <p className="note-txt">
                              <span>{data.vanity}</span>
                            </p>
                            <div className="Separator2" style={{margin:'8px 0px 16px', width:'auto'}}></div>
                          </div>
                        </div>
                      )}
                    )}
                  </div>
                }
              </Modal.Body>
            </div>
          </Modal>
        </Modal>
        {this.state.shareModal && <FbShare facebookId = {this.props.facebookId}
        accessToken = {this.props.accessToken}
        caption = {this.props.post.caption}
        image = {this.props.post.image}
        />}
      </div>
    );
  }
})
