import React, {Component } from "react"
import './DeliveryItemList.css'
import backBtnImg from '../../images/back-btn-popup.svg'
import phoneCallImg from '../../images/phone-call.svg'
import cameraImg from '../../images/camera-1.svg'
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMerchantOrders, updateOrderStatus, updateOrderValue, verifyOrder, uploadBill} from '../../redux/modules/newMerchantLanding';
import homeDeliveryImg from '../../images/home_delivery.svg';
import selfPickUpImg from '../../images/self_pick_up.svg';
import successGreenTick from '../../images/success-green-tick.svg'
import TimerStatusImg from '../../images/timer-status.svg'
import CancelOrderDialog from '../HomeDelivery/CancelOrderDialog';
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { userTypes } from "../../constants";
import { currencyString } from "../../helpers/Helpers";
import {ImageViewer} from '../Feed/ImageViewer';
import OtpVerificationScreen from './OtpVerificationScreen';
import UpdatePopupDialog from "../HomeDelivery/UpdatePopupDialog";
import AddItemsScreen from './AddItemsScreen';
import leftArrow from "../../images/left-arrow2.svg";
import deliveryCheckBoxIcon from '../../images/delivery-check-box.svg';
import deliveryUncheckBoxIcon from '../../images/delivery-uncheck-box.svg';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import closeBtn from '../../images/cross2.png';
import IconButton from '@material-ui/core/IconButton';
import loader from '../../images/loader.gif'
import crossActiveDeliveryImg from '../../images/cross-active-delivery-item.svg';
import crossInactiveDeliveryImg from '../../images/cross-delivery-item.svg';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {compressToBlob} from '../../helpers/Helpers.js';
import InstantChat from '../HomeDelivery/InstantChat';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const styles = theme => ({
    root: {
      margin: 0,
      padding: "10px",
      width: window.innerWidth > 768 ? "324px" : "250px"
    },
    closeButton: {
      position: 'absolute',
      right: "5px",
      top: "5px",
      color: "gray",
    },
  });

const rejectionOptions = [
    {label: "Customer denying order", value:1, message: "We've got to know that the customer doesn't want this order and hence it is cancelled."},
    {label: "LP Assignment Timeout", value:2, message: "We've rejected the order as we were unable to find a rider. We regret for the inconvenience caused"},
    {label: "Mx non-responsive", value:3, message: "We've tried calling you but did not get any response and hence the order is cancelled."},
    {label: "Merchant denying order", value:4, message: "We've cancelled the order as per your request"},
    {label: "Items out of stock", value:5, message: "We've cancelled the order as items are out of stock"},
    {label: "Not operational today", value:6, message: "We've cancelled the order as outlet is closed"},
    {label: "Customer non-responsive", value:7, message: "We've got to know that the customer is non-responsive and hence the order is cancelled." },
    {label: "Nearing closing time", value:8, message: "We've cancelled the order as outlet is closed"},
    {label: "Duplicate Order", value:9, message: "We've cancelled the order as it was a duplicate order"},
    {label: "Delay in order acceptance", value:61, message: "We've rejected the order as we did not get any order acceptance confirmation from your end"},
    {label: "Poor quality of packaging", value:62, message: "We've received a complaint of poor packaging from the customer and hence this order is cancelled. We request you to please ensure proper packaging to avoid customer complaints "},
    {label: "Poor quality", value:63, message: "We've received a complaint of poor quality from the customer and hence this order is cancelled. We request you to please ensure good quality product to avoid customer complaints"},
    {label: "Wrong order", value:64, message: "We've received a complaint of wrong order from the customer and hence this order is cancelled. We request you to please ensure that driver is collecting the right parcel from your store."},
    {label: "Foreign object in food/FSSAI", value:65, message: "We've received a complaint of FSSAI issue from the customer and hence this order is cancelled."},
    {label: "Missing Item", value:66, message: "We've received a complaint of missing item from the customer and hence this order is cancelled. We request you to please ensure that driver is collecting the right parcel from your store."},
    {label: "Customer reject due to delay", value:67, message: "We've got to know that the customer doesn't want this order and hence it is cancelled."},
    {label: "FE - No answer", value:68, message: "We've got to know that the rider is not responsive and hence the order is cancelled. We regret for the inconvenience caused"},
    {label: "Denial - Picked up time out", value:69, message: "The current rider has declined to deliver the order. We regret for the inconvenience caused"},
    {label: "Food not delivered", value:70, message: "Order is cancelled because the customer has claimed that the order is not received yet. We regret for the inconvenience caused to you"},
    {label: "Instructions not followed", value:71, message: "We've received a complaint of instructions not followed from the customer and hence this order is cancelled. We request you to please ensure that all the instructions given by the customer is being followed as it might lead to a bad customer experience"},
    {label: "PG failure", value:72, message: "Order is cancelled"},
    {label: "Denial - Rider abusive", value:73, message: "We've cancelled the order due to rider behaviour.  We apologize for this inconvenience. Please be assured that we have shared a stern feedback with our partner and we'll make sure this doesn't get repeated in future"},
    {label: "Merchant charging extra amount", value:74, message: "As per customer's request the order is cancelled."},
    {label: "Merchant device issue", value:75, message: "Order has been cancelled due to the order details not visible in your device. We deeply regret for the inconvenience caused"},
    {label: "Nearing opening time", value:76, message: "Since you won't be able to fulfill this delivery because of outlet not being serviceable at this hour, we've cancelled this order."},
    {label: "Kitchen is full", value:77, message: "Since you won't be able to fulfill this order because kitchen is full, we've cancelled this order."},
    {label: "Out of subzone/area", value:78, message: "Order has been cancelled as rider will not be able to fulfill this order.  We regret for the inconvenience caused to you."},
    {label: "unsafe area", value:79, message: "Order has been cancelled as rider will not be able to fulfill this order.  We regret for the inconvenience caused to you."},
    {label: "FE - Accident / Rain / Strike / vehicle issues", value:80, message: "Order has been cancelled as rider will not be able to fulfill this order.  We regret for the inconvenience caused to you."},
    {label: "Wrong user address", value:81, message: "We've got to know that the our rider was unable to find the customer location and hence the order is cancelled."},
    {label: "FE - Device/app issue", value:82, message: "Order has been cancelled as rider will not be able to fulfill this order.  We regret for the inconvenience caused to you."},
    {label: "FE - Long distance order", value:83, message: "Order has been cancelled as rider will not be able to fulfill this order.  We regret for the inconvenience caused to you."},
    {label: "System driven auto-cancellation", value:84, message: "Order was cancelled due to DELAYED response in acceptance. Make sure you are accepting all orders in 30 MINUTES to keep receiving orders."},
    ]

const rejectionOptionsMerchant = [
    {label: "Outlet closed", value:200, message: "As per your request we've cancelled the order as the outlet is closed."},
    {label: "Item out of stock", value:201, message: "As per your request we've cancelled the order as items are out of stock."},
    {label: "Other", value:202, message: "Order has been cancelled as per your request."}
]

const rejectionOptionsCustomer = [
    {label: "Merchant is not responding", value:300, message: "As per customer's request we've cancelled this order because you were not responsive."},
    {label: "Merchant is denying my order", value:301, message: "As per customer's request we've cancelled the order because you're unable to fulfill it."},
    {label: "Order taking longer than expected", value:302, message: "We've got to know that the customer wants to cancel this order because it is delayed."},
    {label: "Item out of stock", value:303, message: "As per customer's request we've cancelled the order as items are out of stock."},
    {label: "Reason not mentioned", value:304, message: "As per customer's request we've cancelled this order, we aplogize for the inconvenience caused."}
]

const DialogContent = withStyles(theme => ({
    root: {
        padding: "16px",
        textAlign: "center",
        width: "100%"
    },
    }))(MuiDialogContent);

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h4">{children}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <img src={closeBtn}/>
        </IconButton>
        </MuiDialogTitle>
    );
});

export default connect(
    store => ({
        tokenValsData: store.newMerchantLanding.tokenValsData,
        tokenValsLoading: store.newMerchantLanding.tokenValsLoading,
        merchantOrdersData:store.newMerchantLanding.merchantOrdersData,
        updateOrdersStatusLoading: store.newMerchantLanding.updateOrdersStatusLoading,
        updateOrdersStatusData: store.newMerchantLanding.updateOrdersStatusData,
        updateOrdersStatusError: store.newMerchantLanding.updateOrdersStatusError,
        updateOrdersValueLoading: store.newMerchantLanding.updateOrdersValueLoading,
        updateOrdersValueData: store.newMerchantLanding.updateOrdersValueData,
        updateOrdersStatusError: store.newMerchantLanding.updateOrdersStatusError,
    }),dispatch=>bindActionCreators({
        getMerchantOrders,
        updateOrderStatus,
        updateOrderValue,
        verifyOrder,
        uploadBill
    }, dispatch))
    (class DeliveryItemList extends Component{
    constructor(props){
        super(props)
        this.state={
            step: this.props.step || 0,
            showCancelOrderPopup: false,
            showRejectionIdField: false,
            showRejectionIdFieldInFooter: false,
            showEntirePhoneNumber: false,
            showCallAgentPhoneNumber: false,
            showUpdatePopup: false,
            rejectionId: null,
            showOtpDialog: false,
            uploadingBill: false, 
            showAddItemsScreen: false,
            deliveryType: "MAGICPIN",
            // freeTextItemId: 0
        }
        this.orderData=null
        this.suggestedItemsIgnoredByCustomer=[]
        this.selectedSuggestedItemsWithQtyChange=[]
        this.nonMutatedOrderAmount=null
        // this.freeTextItemId = 0
    }
    
    componentDidMount(){
        if(!this.props.merchantOrdersData){
            this.props.getMerchantOrders(this.props.user.accessToken,this.props.user.userId);
        }
        this.props.sendGbAndGbqEventsOrder('order_page_landing','order_page_landing','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        // window.fcWidget.hide()
        if(!!window.fcWidget)
        { 
            window.fcWidget.hide();
            document.getElementById('custom_chat_button').style.visibility = "hidden"
        }

        if(this.props.suggestedItems && this.orderData)
        {
            this.orderData.availableItems = this.orderData.availableItems || []
            this.props.suggestedItems.map(itemObj=>{
                let itemPresent = false;
                if(itemObj[this.orderData.orderId]) {
                    this.orderData.availableItems.map(item => {
                        if(item.id == itemObj[this.orderData.orderId].id) {
                            itemPresent = true
                            item.amount = itemObj[this.orderData.orderId].amount;
                            item.status = "AVAILABLE";                            
                        }
                    })
                    
                    if(!itemPresent) {
                        this.orderData.availableItems.push(itemObj[this.orderData.orderId])
                    }

                }
           })
        }
    }

    addSuggestedItemList = () =>{ // Gets called using In-memory addition
        this.markSuggested()
        if(this.orderData && (this.orderData.orderStatus !== "BILL_READY" && this.orderData.orderStatus !=="ACCEPTED"))return;
        if(this.props.suggestedItems && this.orderData)
        {
            this.orderData.availableItems = this.orderData.availableItems || []
            this.props.suggestedItems.map(itemObj=>{
                let itemPresent = false;
                if(itemObj[this.orderData.orderId]) {
                    // console.log("To be added: ",itemObj[this.orderData.orderId])
                    this.orderData.availableItems.map(item => {
                        if(item.id == itemObj[this.orderData.orderId].id) {
                            itemPresent = true
                            item.amount = itemObj[this.orderData.orderId].amount;
                           
                            /**
                             * else if (item.status == "MODIFIED") {
                                // item.quantity = pick quantity from quantity selector
                            }
                             * 
                             */
                            // item.quantity = itemObj[this.orderData.orderId].quantity;
                            item.status = "AVAILABLE";
                            // item.status = itemObj[this.orderData.orderId].status;
                            
                        }
                    }) 
                    if(!itemPresent) {
                        this.orderData.availableItems.push(itemObj[this.orderData.orderId])
                    }

                }
           })
        }
        // }
    }

    checkIfItemsRemoved = (index) => {
        if(this.orderData.availableItems){
            let newOrderList =[]
            newOrderList=this.orderData.availableItems.filter((item,idx)=> {return idx!=index})
            this.orderData.availableItems = newOrderList
        }
    }

    handleCallOnDesktop = () => {
        this.props.sendGbAndGbqEventsOrder('order_page_customer_call_clicked','order_page_customer_call_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        this.setState({ showEntirePhoneNumber: !this.state.showEntirePhoneNumber })
    }

    handleCallOnDesktopAgent = () =>{
        this.props.sendGbAndGbqEventsOrder('order_page_delivery_agent_link_clicked','order_page_delivery_agent_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        this.setState({ showCallAgentPhoneNumber: !this.state.showCallAgentPhoneNumber})
    }

    updateOrder= () =>{
        let currAction=this.listOfActions[this.state.step]
        if(!currAction.status || currAction.status === "DELIVERED"){
            return;
        }
        let nextStatus= this.listOfActions[this.state.step+1].status
        if(currAction.status === "ACCEPTED" || currAction.status == "PENDING"){
            this.props.updateOrderValue(this.props.user.accessToken, this.orderData.orderId, this.state.billValue, this.orderData.availableItems, this.orderData.orderType != "PICK_UP" ? this.state.deliveryType: null).then((res)=>{
                this.props.clearSuggestedItems(this.orderData.orderId)
                this.props.getMerchantOrders(this.props.user.accessToken,this.props.user.userId).then(response =>{
                    if(res && res.length > 0 && res[0].status && res[0].status.toUpperCase()== 'FAILURE'){
                        let errMessage = "Edit amount failed. User made the payment while you were trying to edit the amount"
                        if(res[0].message != "") {
                            errMessage = res[0].message
                        }
                        toast.error(errMessage)
                    }
                    let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                    this.orderData=order
                    this.props.refreshOrderObj(order.orderId)
                    this.props.orderObj && this.setTransitionList(this.props.orderObj)
                    // this.setTransitionList(order)
                })
            })
            .catch(err=>{
                this.props.getMerchantOrders(this.props.user.accessToken,this.props.user.userId).then(response =>{
                    let order= response.find(item=> item.orderId == this.orderData.orderId)
                    this.orderData=order
                    this.props.refreshOrderObj(order.orderId)
                    this.props.orderObj && this.setTransitionList(this.props.orderObj)

                    // this.setTransitionList(order) 
                })
            })
        }
        else{
            if(currAction.status === "PENDING"){
                this.props.updateOrderStatus(this.props.tokenValsData, this.props.user.accessToken, this.orderData.orderId, nextStatus, this.props.user.userType, null, this.state.deliveryType, null, this.props.user).then((res)=>{
                    this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then((response)=>{
                        let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                        this.orderData=order
                        this.props.refreshOrderObj(order.orderId)
                        this.props.orderObj && this.setTransitionList(this.props.orderObj)

                        // this.setTransitionList(order)
                    })
                })
                .catch((err)=>{
                    this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then(response =>{
                        let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                        this.orderData=order
                        this.props.refreshOrderObj(order.orderId)
                        this.props.orderObj && this.setTransitionList(this.props.orderObj)
                    })
                })
            }
            else{
                this.props.updateOrderStatus(this.props.tokenValsData, this.props.user.accessToken, this.orderData.orderId, nextStatus, this.props.user.userType, null, null, null, this.props.user).then((res)=>{
                    this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then((response)=>{
                        let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                        this.orderData=order
                        this.props.refreshOrderObj(order.orderId)
                        this.props.orderObj && this.setTransitionList(this.props.orderObj)

                        // this.setTransitionList(order)
                    })
                })
                .catch((err)=>{
                    this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then(response =>{
                        let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                        this.orderData=order
                        this.props.refreshOrderObj(order.orderId)
                        this.props.orderObj && this.setTransitionList(this.props.orderObj)
                    })
                })
            }
        }

    }

    submitBillValue = () =>{
        // this.props.sendGbAndGbqEventsOrder('order_page_submit_bill_value_clicked','order_page_submit_bill_value_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        this.updateOrder()
    }

    acceptOrder = () =>{
        this.updateOrder()
    }

    editAmount = () =>{
        this.props.sendGbAndGbqEventsOrder('order_page_edit_clicked','order_page_edit_clicked','singleOrderItem', this.orderData ? this.orderData.orderId : '')
        this.setState({step: this.state.step-1});
    }

    handleChange = (e) =>{
        e.preventDefault()
        this.setState({'billValue': e.target.value});
    }
    
    handleChangeForRejectionId = (e) =>{
        e.preventDefault()
        this.setState({rejectionId: e.target.value});
    }

    markPacked =()=>{
        this.props.sendGbAndGbqEventsOrder('order_page_mark_packed_clicked','order_page_mark_packed_clicked','singleOrderItem', this.orderData ? this.orderData.orderId : '')
        this.updateOrder()
    }

    verifyDispatched=()=>{
        this.props.verifyOrder(this.props.user.accessToken, this.props.orderObj.orderId, '').then(res=>{
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then((response)=>{
                let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
                this.orderData=order
                this.props.refreshOrderObj(order.orderId)
                this.props.orderObj && this.setTransitionList(this.props.orderObj)
            })
        })
    }

    markDispatched=()=>{
        this.updateOrder()
    }

    markDelivered= () =>{
        this.props.sendGbAndGbqEventsOrder('order_page_mark_delivered_clicked','order_page_mark_delivered_clicked','singleOrderItem', this.orderData ? this.orderData.orderId : '')
        this.updateOrder()
    }

    cancelOrder= () =>{
        if(!this.state.rejectionId || this.state.rejectionId == ""){
            toast.error("Please select a rejection reason before sumbitting")
            return
        }
        // this.props.user.userType == "ADMIN" 
        // ? 
        this.props.updateOrderStatus(this.props.tokenValsData, this.props.user.accessToken, this.orderData.orderId, "CANCELLED", this.props.user.userType, parseInt(this.state.rejectionId, 10), null, null, this.props.user).then(()=>{
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
            this.props.handleCloseListItemsPage();
        }).catch(err=>{
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
            this.props.handleCloseListItemsPage();
        }) 

    }

    handleClosePopUp = () => {
        this.setState({ showCancelOrderPopup: !this.state.showCancelOrderPopup })
    }

    handleCloseUpdatePopUp = () =>{
        this.setState({ showUpdatePopup: false})
    }

    amountForAllItemsAvaliable = () =>{
        let amountAvailable=true;
        if(this.orderData && this.orderData.availableItems){
            this.orderData.availableItems.map(item=>{
                if(!item.amount && item.itemType == "SUGGESTED"){
                    amountAvailable =false;
                }
            })
        }
        return amountAvailable
    }

    amountNotEligible =() =>{
        let totAmount=0;
        let isNormalItemPresent=false;
        if(this.orderData && this.orderData.availableItems){
            this.orderData.availableItems.forEach(item=>{
                if(item.itemType == "SUGGESTED" && item.status != "UNAVAILABLE"){
                    totAmount = totAmount + (item.amount*item.qtyUpperBound)
                }
                else if(item.itemType == "VOUCHER" && item.status != "UNAVAILABLE"){
                    totAmount = totAmount + (item.amount*item.quantity)
                }
                if(item.itemType == "ITEM" && (item.status == "AVAILABLE" || item.status == "MODIFIED")){
                   isNormalItemPresent=true
                }
            })
        }
        if(isNormalItemPresent){
            if(totAmount < parseFloat(this.state.billValue,10)){
                return false
            }
            else{
                return true;
            }
        }
        else{
            if(totAmount <= parseFloat(this.state.billValue,10)){
                return false;
            }
            else{
                return true;
            }
        }
    }

    checkIfAnyItemsPresent=()=>{
        let presciptionIndex = this.props.orderObj && this.props.orderObj.uploadedBy && this.props.orderObj.uploadedBy.length > 0 ?  this.props.orderObj.uploadedBy.findIndex(x => x=='CUSTOMER') : -1
        let prescriptionImage = this.props.orderObj && this.props.orderObj.images && this.props.orderObj.images.length > 0 ? this.props.orderObj.images[presciptionIndex] : null
        let isAnyItemPresent = false
        if(this.orderData && this.orderData.availableItems){
            this.orderData.availableItems.map(item=>{
                if(item.status !== "UNAVAILABLE"){
                    isAnyItemPresent = true
                }
            })
        }
        if(prescriptionImage  || isAnyItemPresent)return true;
        return false;
    }

    handleOpenUpdatePopUp = (deliveryType=null) =>{
        this.setState({deliveryType})
        if(this.orderData){
            if(this.orderData.orderStatus === "PENDING"){
               if(deliveryType == 'MAGICPIN')
                   this.props.sendGbAndGbqEventsOrder('order_page_magicpin_accept_clicked','order_page_magicpin_accept_clicked','singleOrderItem', this.orderData.orderId)
               else if(deliveryType == 'MERCHANT')
                   this.props.sendGbAndGbqEventsOrder('order_page_merchant_accept_clicked','order_page_merchant_accept_clicked','singleOrderItem', this.orderData.orderId)
               else
                   this.props.sendGbAndGbqEventsOrder('order_page_pickup_accept_clicked','order_page_pickup_accept_clicked','singleOrderItem', this.orderData.orderId)
            }
            if(this.orderData.orderStatus === "ACCEPTED" || this.orderData.orderStatus === "BILL_READY")
               this.props.sendGbAndGbqEventsOrder('order_page_submit_bill_value_clicked','order_page_submit_bill_value_clicked','singleOrderItem', this.orderData.orderId)
            if(this.orderData.orderStatus === "DISPATCH_READY")
               this.props.sendGbAndGbqEventsOrder('order_page_mark_dispatched_clicked','order_page_mark_dispatched_clicked','singleOrderItem', this.orderData.orderId)
        }
        if(this.state.step===1){
            if(!this.state.billValue){
                toast.warn("Please enter a valid bill amount")
                return
            }
            else if(this.state.billValue <10){
                toast.warn("Bill value can't be less than 10")
                return
            }
            else if(this.state.billValue >=10000){
                toast.warn("Bill amount should be less than 10000")
                return
            }
            else if(!this.amountForAllItemsAvaliable()){
                toast.warn("Please enter amount for all the suggested availableItems")
                return
            }
            else if(!this.checkIfAnyItemsPresent()){
                toast.warn("Please cancel this order as items are not available")
                return
            }
            else if(this.amountNotEligible()){
                toast.warn("Total amount should be more than the sum of voucher and suggested items. Bill amount should include - AVAILABLE ITEMS, VOUCHER ITEMS, SUGGESTED ITEMS")
                return
            }
        }
        this.setState({ showUpdatePopup: true})
    }

    sortAvailableItemsBasisItemType=()=>{
       let itemList=[]
       let voucherList=[]
       let suggestedList=[]
       this.orderData.availableItems.map(item=>{
           if(item.itemType=="ITEM")
              itemList.push(item);
           else if(item.itemType=="VOUCHER")
             voucherList.push(item);
           else
              suggestedList.push(item);
       })
       this.orderData.availableItems = itemList.concat(voucherList).concat(suggestedList)
    }

    setTransitionList= (order)=>{
        let isAdmin = this.props.user.userType == "ADMIN" ? true : false
        let isDeliveryAgentAssigned = this.orderData.deliveryInitiator == "MAGICPIN" && this.orderData.deliveryStatus === 'ASSIGNED'
        let rejectionReason="Order Cancelled"
        if(this.orderData.rejectionId){
            let rejection= rejectionOptions.find(x => x.value == this.orderData.rejectionId)
            if(rejection){
                rejectionReason = rejection.message
            }
            else if(!rejection){
                let rejectionReasonMerchant = rejectionOptionsMerchant.find(x => x.value == this.orderData.rejectionId)
                if(rejectionReasonMerchant){
                    rejectionReason = rejectionReasonMerchant.message
                }
                else if(!rejectionReasonMerchant){
                    let rejectionReasonCustomer = rejectionOptionsCustomer.find(x => x.value == this.orderData.rejectionId)
                    if(rejectionReasonCustomer){
                        rejectionReason = rejectionReasonCustomer.message
                    }
                    
                }
            }
        }
        if(this.orderData.orderType == "PICK_UP"){
            this.listOfActions=[
                {"status" : "PENDING", "ctaText": "ACCEPT ORDER", "step": 0, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.acceptOrder, showInputBox: false, cancelBtn: true, popUpText: 'Are you sure you want to accept the order?'}, 
                {"status" : "ACCEPTED", "ctaText": "Send bill", "step": 1, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.submitBillValue, showInputBox: true, cancelBtn: true, popUpText: null ,ctaLeft: 'Change amount', ctaRight: 'Yes, send it'}, 
                {"status" : "BILL_READY", "ctaText":"EDIT AMOUNT", "step": 2, "bgColor":"#fef1d8", infoText:"Bill amount sent to customer. ", actionPerform: this.editAmount, showInputBox: false, cancelBtn: false, popUpText: null},
                {"status" : "PAYMENT_DONE", "ctaText":"MARK AS PACKED", "step": 3, "bgColor":"#d9f3f4", infoText:"Prepare the order", actionPerform: this.markPacked, showInputBox: false, cancelBtn: false, popUpText: null},
                {"status" : "DISPATCH_READY", "ctaText":"MARK DISPATCHED", "step":4, "bgColor":"#d9f3f4", infoText: "Order is ready. Customer will pick up from the store.", actionPerform: this.markDispatched, showInputBox: false, cancelBtn: false, popUpText: 'Are you sure you want to mark this order dispatched?'},
                {"status" : "DISPATCHED", "ctaText": isAdmin ? "MARK DELIVERED" : null, "step":5, "bgColor":"#d9f3f4", infoText: "Order dispatched", actionPerform: isAdmin ? this.markDelivered : null, showInputBox: false, cancelBtn: false,popUpText:null},
                {"status" : "DELIVERED", "ctaText":null, "step":6, "bgColor":"#d9f3f4", infoText: "Order delivered", actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
                {"status" : "CANCELLED", "ctaText":null, "step":7, "bgColor":"#d9f3f4", infoText: rejectionReason, actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
            ]
        }
        else {
            if(this.orderData.deliveryInitiator == "MAGICPIN"){
                this.listOfActions=[
                    // {"status" : "PENDING", "ctaText": null, "ctaText1": "Self delivery", "ctaText2": "Partner delivery", "step": 0, "bgColor":"#ffffff", infoText: null, actionPerform: this.acceptOrder, showInputBox: false, cancelBtn: true, popUpText: 'Are you sure you want to accept the order?'},
                    {"status" : "PENDING", "ctaText": "Send bill", "step": 0, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.submitBillValue, showInputBox: true, cancelBtn: true, popUpText: null,ctaLeft: 'Change amount', ctaRight: 'Yes, send it'},  
                    {"status" : "ACCEPTED", "ctaText": "Send bill", "step": 1, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.submitBillValue, showInputBox: true, cancelBtn: true, popUpText: null,ctaLeft: 'Change amount', ctaRight: 'Yes, send it'}, 
                    {"status" : "BILL_READY", "ctaText":"EDIT AMOUNT", "step": 2, "bgColor":"#fef1d8", infoText:"Bill amount sent to customer. ", actionPerform: this.editAmount, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "PAYMENT_DONE", "ctaText":"MARK AS PACKED", "step": 3, "bgColor":"#d9f3f4", infoText:"Delivery agent will be assigned shortly.", actionPerform: this.markPacked, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "DISPATCH_READY", "ctaText": null, "step":4, "bgColor":"#d9f3f4", infoText: "Assigning an agent to deliver this order...", actionPerform: this.markDispatched, showInputBox: false, cancelBtn: false, popUpText: 'Are you sure you want to mark this order dispatched?'},
                    {"status" : "DISPATCHED", "ctaText": isDeliveryAgentAssigned ? "MARK DISPATCHED" : isAdmin ?  "MARK DELIVERED" :null, "step":5, "bgColor":"#d9f3f4", infoText: isDeliveryAgentAssigned ? "Delivery agent is arriving" : "Order out for delivery", actionPerform: isDeliveryAgentAssigned ? this.verifyDispatched : isAdmin? this.markDelivered: null, showInputBox: false, cancelBtn: false, popUpText: null},/* Track Now cta in this state*/
                    {"status" : "DELIVERED", "ctaText":null, "step":6, "bgColor":"#d9f3f4", infoText: "Order delivered", actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "CANCELLED", "ctaText":null, "step":7, "bgColor":"#d9f3f4", infoText:  rejectionReason, actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
                ]
            }
            else{
                this.listOfActions=[
                    {"status" : "PENDING", "ctaText": "Send bill", "step": 0, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.submitBillValue, showInputBox: true, cancelBtn: true, popUpText: null,ctaLeft: 'Change amount', ctaRight: 'Yes, send it'}, 
                    // {"status" : "PENDING", "ctaText": null, "ctaText1": "Self delivery", "ctaText2": "Partner delivery", "step": 0, "bgColor":"#ffffff", infoText: null, actionPerform: this.acceptOrder, showInputBox: false, cancelBtn: true, popUpText: 'Are you sure you want to accept the order?'}, 
                    {"status" : "ACCEPTED", "ctaText": "Send bill", "step": 1, "bgColor":"linear-gradient(106deg, rgb(255, 218, 223), rgb(255, 255, 255))", infoText: null, actionPerform: this.submitBillValue, showInputBox: true, cancelBtn: true, popUpText: null,ctaLeft: 'Change amount', ctaRight: 'Yes, send it'}, 
                    {"status" : "BILL_READY", "ctaText":"EDIT AMOUNT", "step": 2, "bgColor":"#fef1d8", infoText:"Bill amount sent to customer. ", actionPerform: this.editAmount, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "PAYMENT_DONE", "ctaText":"MARK AS PACKED", "step": 3, "bgColor":"#d9f3f4", infoText:"Prepare the order", actionPerform: this.markPacked, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "DISPATCH_READY", "ctaText":"MARK DISPATCHED", "step":4, "bgColor":"#d9f3f4", infoText: "Dispatch the order", actionPerform: this.markDispatched, showInputBox: false, cancelBtn: false, popUpText: 'Are you sure you want to mark this order dispatched?'},
                    {"status" : "DISPATCHED", "ctaText": isAdmin ? "MARK DELIVERED" :null, "step":5, "bgColor":"#d9f3f4", infoText: "Delivery in progress...", actionPerform: isAdmin ? this.markDelivered : null, showInputBox: false, cancelBtn: false, popUpText: null},/* Track Now cta in this state*/
                    {"status" : "DELIVERED", "ctaText":null, "step":6, "bgColor":"#d9f3f4", infoText: "Order delivered", actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
                    {"status" : "CANCELLED", "ctaText":null, "step":7, "bgColor":"#d9f3f4", infoText:  rejectionReason, actionPerform: null, showInputBox: false, cancelBtn: false, popUpText: null},
                ]
            }
            
        }
        
        let currAction= this.listOfActions.find(item=> item.status == this.orderData.orderStatus)

        let billValue=0
        if(this.orderData){
            billValue= this.orderData.orderStatus === "BILL_READY" ? this.orderData.merchantAmount : this.orderData.amount
            if(this.orderData.suggestedItems && this.orderData.orderStatus === "BILL_READY"){
                this.orderData.suggestedItems.map(item=>{
                    billValue =billValue+((item.amount)*(item.qtyUpperBound))
                })
            }
        }
        if(this.orderData.availableItems && this.orderData.availableItems.length > 0 && this.orderData.vouchersDetail){
            this.orderData.availableItems.map((item, index)=>{
                if(item.itemType === "VOUCHER"){
                    if(this.orderData.vouchersDetail[item.id]){
                        let voucherDetail=this.orderData.vouchersDetail[item.id]
                        this.orderData.availableItems[index].amount = voucherDetail.amount
                    }
                }
            })
        }
        if(this.orderData && this.orderData.orderStatus === "BILL_READY")
        {
            if(this.orderData.unavailableItems && this.orderData.unavailableItems.length > 0){
                let alreadyPresent = false;
                this.orderData.availableItems = this.orderData.availableItems || []
                this.orderData.availableItems.map(item => {
                    if(item.id == this.orderData.unavailableItems[0].id) {
                        alreadyPresent = true;
                    }
                })

                if(!alreadyPresent) {
                    this.orderData.unavailableItems.map(item=>{
                        this.orderData.availableItems.push(item)
                    })
                }
            }

            if(this.orderData.suggestedItems && this.orderData.suggestedItems.length > 0){
                let isContainSuggestedItem=false;
                this.orderData.availableItems = this.orderData.availableItems || []
                this.orderData.availableItems.map(item=> {
                    if(item.itemType === "SUGGESTED")
                        isContainSuggestedItem=true
                })
                if(!isContainSuggestedItem){
                    this.orderData.suggestedItems.map(item=>{
                        if(!this.containsSameItemBefore(item.id, item)){
                            this.orderData.availableItems.push(item)
                        }
                    })
                }
            }
        }
        this.orderData && this.orderData.availableItems && 
        this.orderData.availableItems.length && this.sortAvailableItemsBasisItemType()
        let deliveryInitiator=this.state.deliveryType
        if(this.orderData.deliveryInitiator && this.orderData.deliveryInitiator != ""){
            deliveryInitiator= this.orderData.deliveryInitiator
        }
        this.setState({step: currAction.step, status: currAction.status, billValue : billValue, deliveryType: deliveryInitiator})
    }

    performAction = () =>{
        this.listOfActions[this.state.step].actionPerform()
        this.handleCloseUpdatePopUp()
    }

    handleCloseOtpPopUp = () => {
        this.setState({ showOtpDialog: !this.state.showOtpDialog})
        this.props.getMerchantOrders(this.props.user.accessToken,this.props.user.userId).then(response =>{
            let order= this.props.merchantOrdersData.find(item=> item.orderId == this.orderData.orderId)
            this.orderData=order
            this.props.refreshOrderObj(order.orderId)
            this.props.orderObj && this.setTransitionList(this.props.orderObj)
        })
    }

    handleOpenOtpScreen = ()=>{
        this.setState({showOtpDialog: true})
    }

    uploadBill = () => {
        this.props.sendGbAndGbqEventsOrder('order_page_customer_bill_upload_start','order_page_customer_bill_upload_start','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        if(document.getElementById('file-input').files.length){
            let uploadedBill = document.getElementById('file-input').files[0];
            compressToBlob(uploadedBill).then(blob => {
                this.uploadFile(blob)
            }).catch((err) => {
                this.uploadFile(uploadedBill)
            })
        }
    }
    uploadFile = (file) => {
       
        let uploadedBill = file
        // alert(uploadedBill.name )
        this.setState({uploadingBill: true})
        
        if(uploadedBill){
            if(uploadedBill.type.includes('image/') || uploadedBill.type.includes('application/pdf')){
                this.props.uploadBill(this.props.user.accessToken, uploadedBill, this.props.orderObj.orderId)
                .then(result => {
                    if(result){
                        this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId).then((res) => {
                            this.setState({uploadingBill: false})
                        })
                        toast.info("Bill Uploaded")
                        this.props.sendGbAndGbqEventsOrder('order_page_customer_bill_upload_completed','order_page_customer_bill_upload_completed','singleOrderItem', this.orderData ?this.orderData.orderId: '')
                    }
                }).catch(err => {
                    toast.error("Something went wrong. Bill did not upload")
                    this.setState({uploadingBill: false})
                    // this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
                })
            }
            else {
                toast.error("Uploaded file is not an Image. Please upload again")
                this.setState({uploadingBill: false})
            }
        }
    }

    markUnavailable =(index) =>{
        let flagToBeUsed = false
        let listOfItemIdsInMemory = []
        if(this.props.suggestedItems && this.props.suggestedItems.length) {
            this.props.suggestedItems.map(itemObj=>{
                if(itemObj[this.orderData.orderId]) {
                    flagToBeUsed = true;
                    listOfItemIdsInMemory.push(itemObj[this.orderData.orderId].id)
                }      
            })
        }
        if(this.orderData.availableItems && this.orderData.availableItems[index] && this.orderData.availableItems[index].id && this.orderData.availableItems[index].displayText){
            this.props.sendGbAndGbqEventsOrder('order_page_mark_unavailable_clicked','order_page_mark_unavailable_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '',this.orderData.availableItems[index].displayText+"_"+this.orderData.availableItems[index].id)
        }
        if(this.orderData.availableItems[index]['itemType'] == "SUGGESTED") {
            if(flagToBeUsed && listOfItemIdsInMemory.includes(this.orderData.availableItems[index].id)){
                this.props.removeSuggestedItem(this.orderData.availableItems[index], this.orderData.orderId)
                this.checkIfItemsRemoved(index)
            }
            else{
                this.orderData.availableItems[index]['status']="UNAVAILABLE";
                this.setState({'updateItemList': this.state.updateItemList ? this.state.updateItemList + 1 : 1}) 
            }
        }
        else{
            this.orderData.availableItems[index]['status']="UNAVAILABLE";
            this.setState({'updateItemList': this.state.updateItemList ? this.state.updateItemList + 1 : 1})
        }
    }

    markAvailable =(index) =>{   
        if(this.orderData.availableItems && this.orderData.availableItems[index] && this.orderData.availableItems[index].id && this.orderData.availableItems[index].displayText){
            this.props.sendGbAndGbqEventsOrder('order_page_mark_available_clicked','order_page_mark_available_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '',this.orderData.availableItems[index].displayText+"_"+this.orderData.availableItems[index].id)
        }
        this.orderData.availableItems[index]['status']="AVAILABLE";
        this.setState({'updateItemList': this.state.updateItemList ? this.state.updateItemList - 1 : 1})
    }

    handleItemChange = (e, index)=>{
        this.orderData.availableItems[index].quantity=e.target.value
    }

    handleQuantityChange= (e,index)=>{
        if(this.orderData.availableItems[index].itemType === "SUGGESTED")
           this.orderData.availableItems[index].qtyUpperBound=parseInt(e.target.value, 10);
        else 
           this.orderData.availableItems[index].quantity=parseInt(e.target.value, 10);
        this.orderData.availableItems[index].status="MODIFIED";
        if(this.orderData.availableItems && this.orderData.availableItems[index] && this.orderData.availableItems[index].id && this.orderData.availableItems[index].displayText){
            this.props.sendGbAndGbqEventsOrder('order_page_quantity_changed_clicked','order_page_quantity_changed_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '',this.orderData.availableItems[index].displayText+"_"+this.orderData.availableItems[index].id)
        }
        this.setState({'updateItemList': this.state.updateItemList ? this.state.updateItemList - 1 : 1})
    }

    getItemDropDown =(quantityUpperBound, quantity, index, status, itemType)=>{
        let isAvailable= status==="UNAVAILABLE"? false: true
        let itemList=[]
        let maxItem;
        if(itemType === "SUGGESTED")
            maxItem=20;
        else
            maxItem=quantityUpperBound
        for(let i=1;i<=maxItem;i++)
           itemList.push(i)
        return (<select id={`select_delivery_item_list_${index}`} onChange={(e)=>{this.handleQuantityChange(e,index)}} disabled={isAvailable ? false: true} className={isAvailable ? "select-delivery-enabled-item-number": "select-delivery-disabled-item-number"}>
            {
               itemList.map(quant=>{
               return (<option value={quant} selected={itemType === "SUGGESTED" ? (quantityUpperBound == quant) : quantity ==quant}>{quant}</option>)
               })
            }
            </select>)
    }
    handleAddSuggestedItem = (item) => {
        this.props.addSuggestedItem(item, this.orderData.orderId)
        this.setState({ showAddItemsScreen: false })
    }

    handleCloseListItemsPage = () => {
        this.setState({ showAddItemsScreen: false })
    }

    addSuggestedItemPrice = (e, index) =>{
        this.orderData.availableItems[index]['amount']= parseFloat(parseFloat(e.target.value).toFixed(1))
        this.setState({'updateItemList': this.state.updateItemList ? this.state.updateItemList - 1 : 1})
    }

    containsSameItemBefore=(itemId, suggestedItem)=>{
        let isContain=false;
        this.orderData.availableItems.map((item,index)=>{
            if(item.id == itemId){
              isContain=true
              this.orderData.availableItems[index].itemType="SUGGESTED"
              this.orderData.availableItems[index].qtyUpperBound= suggestedItem.qtyUpperBound
            }
        })
        return isContain
    }

    markSuggested=()=>{
        if(this.orderData && this.orderData.availableItems && this.orderData.suggestedItems){
            this.orderData.suggestedItems.map(suggestedItem=>{
                this.orderData.availableItems.map((item,index)=>{
                    if(item.id == suggestedItem.id)
                       this.orderData.availableItems[index].itemType="SUGGESTED"
                })
            })
        }
    }

    appendSuggestedItem = () =>{
        if(this.orderData && (this.orderData.orderStatus !== "BILL_READY" || this.orderData.orderStatus !=="ACCEPTED"))return;
        if(this.orderData.suggestedItems){
          this.orderData.availableItems = this.orderData.availableItems || []
          this.orderData.suggestedItems.map(item =>{
              if(!this.containsSameItemBefore(item.id, item)){
                this.orderData.availableItems.push(item)
              }
          })
        }
    }

    // findPriceBreakUp = () =>{
    //     this.totalSuggestedAmount= 0
    //     this.totalSuggestedCount= 0
    //     this.totalVoucherAmount= 0
    //     this.totalVoucherCount= 0
    //     this.totalItemCount= 0
    //     this.totalItemAmount= 0
    //     if(this.orderData && this.orderData.availableItems){
    //         this.orderData.availableItems.map(item=>{
    //             if(item.itemType == "SUGGESTED"){
    //                 this.totalSuggestedCount=this.totalSuggestedCount+1
    //                 this.totalSuggestedAmount=this.totalSuggestedAmount +((item.amount)*(item.quantity))
    //             }
    //             else if(item.itemType == "ITEM"){
    //                 this.totalItemCount=this.totalItemCount+1
    //             }
    //             else{
    //                 this.totalVoucherCount=this.totalVoucherCount+1
    //                 this.totalVoucherAmount=this.totalVoucherAmount +((item.amount)*(item.quantity))
    //             }
    //         })
    //         this.totalItemAmount = this.orderData.amount -(this.totalVoucherAmount + this.totalSuggestedAmount)
    //     }
    // }

    findPriceBreakUp = () =>{
        this.totalSuggestedAmount= 0
        this.totalSuggestedCount= 0
        this.totalVoucherAmount= 0
        this.totalVoucherCount= 0
        this.totalItemCount= 0
        this.totalItemAmount= 0
        if(this.orderData && this.orderData.availableItems){
            this.orderData.availableItems.map(item=>{
                if(item.itemType == "SUGGESTED" && item.status != "UNAVAILABLE"){
                    this.totalSuggestedCount=this.totalSuggestedCount+1
                    this.totalSuggestedAmount=this.totalSuggestedAmount +((item.amount)*(item.qtyUpperBound))
                }
                else if(item.itemType == "ITEM" && item.status != "UNAVAILABLE"){
                    this.totalItemCount=this.totalItemCount+1
                    this.totalItemAmount=this.totalItemAmount +((item.amount)*(item.quantity))
                }
                else if(item.itemType == "VOUCHER" && item.status != "UNAVAILABLE"){ 
                    this.totalVoucherCount=this.totalVoucherCount+1
                    this.totalVoucherAmount=this.totalVoucherAmount +((item.amount)*(item.quantity))
                }
            })
            this.orderData.amount = this.totalSuggestedAmount + this.totalItemAmount + this.totalVoucherAmount
        }
    }

    showSuggestItemScreen = ()=>{
        this.setState({ showAddItemsScreen: true })
        this.props.sendGbAndGbqEventsOrder('order_page_suggest_items_clicked','order_page_suggest_items_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
    }

    handleDeliveryInitiatorChange=(e, deliveryType)=>{
        e.preventDefault()
        if(deliveryType == "MAGICPIN"){
            this.props.sendGbAndGbqEventsOrder('order_page_magicpindelivery_rb_clicked','order_page_suggest_items_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        }
        else{
            this.props.sendGbAndGbqEventsOrder('order_page_merchantdelivery_rb_clicked','order_page_merchantdelivery_rb_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
        }
        this.setState({'deliveryType': deliveryType})
    }

    render(){
        if(!this.props.merchantOrdersData)return <Loader/>
        if((!this.orderData && this.props.orderObj && this.props.orderObj.orderId) || ( this.orderData && this.props.orderObj && (this.props.orderObj.orderStatus !== this.orderData.orderStatus || this.props.orderObj.deliveryStatus != this.orderData.deliveryStatus))){
            this.orderData=this.props.orderObj
            this.nonMutatedOrderAmount = this.orderData.amount
            this.appendSuggestedItem();
            this.setTransitionList()
        }
        this.addSuggestedItemList()
        // if(this.orderData && this.state.step >=3){
        //     this.findPriceBreakUp()
        // }

        if(this.orderData){
            this.findPriceBreakUp()
            if(this.totalItemAmount == 0) {
                if(this.state.billValue > 0) {
                    this.totalItemAmount = this.state.billValue - this.totalSuggestedAmount - this.totalVoucherAmount
                }
            }

            if(this.state.step > 2 && this.suggestedItemsIgnoredByCustomer.length == 0 && this.selectedSuggestedItemsWithQtyChange.length == 0) {
                // To be filtered here
                if(this.orderData.suggestedItems && this.orderData.suggestedItems.length > 0) {
                    let suggestedItemIds = this.orderData.suggestedItems.map(item => item.id)
                    let selectedSuggestedItemIds = this.orderData.availableItems.filter(item => item.itemType == "SUGGESTED").map(item => item.id)
                    let ignoredSuggestedItemIds = suggestedItemIds.filter(item => !selectedSuggestedItemIds.find(id => id == item))
                    // console.log("Full Strike-through :: ", this.orderData.suggestedItems.filter(item => ignoredSuggestedItemIds.find(id => id == item.id)))
                    
                    this.suggestedItemsIgnoredByCustomer = this.orderData.suggestedItems.filter(item => ignoredSuggestedItemIds.find(id => id == item.id))
                    
                    this.orderData.availableItems.filter(item => {
                        if(item.itemType == "SUGGESTED") {
                            if(this.selectedSuggestedItemsWithQtyChange
                                 && this.selectedSuggestedItemsWithQtyChange.length && this.selectedSuggestedItemsWithQtyChange.length > 0) {
                                    let tempArr = this.orderData.suggestedItems.filter(suggItem => ((suggItem.id == item.id) && (suggItem.qtyUpperBound != item.qtyUpperBound) && !!suggItem))
                                    if(tempArr && tempArr.length && tempArr.length > 0) {
                                        this.selectedSuggestedItemsWithQtyChange = this.selectedSuggestedItemsWithQtyChange.concat(tempArr[0])
                                    }
                                 } else {
                                    this.selectedSuggestedItemsWithQtyChange = this.selectedSuggestedItemsWithQtyChange.concat(this.orderData.suggestedItems.filter(suggItem => ((suggItem.id == item.id) && (suggItem.qtyUpperBound != item.qtyUpperBound)))[0])
                                 }
                            // this.selectedSuggestedItemsWithQtyChange = this.selectedSuggestedItemsWithQtyChange && this.selectedSuggestedItemsWithQtyChange.length > 0 ? this.selectedSuggestedItemsWithQtyChange.concat(this.orderData.suggestedItems.filter(suggItem => ((suggItem.id == item.id) && (suggItem.qtyUpperBound != item.qtyUpperBound)))[0]) : this.orderData.suggestedItems.filter(suggItem => ((suggItem.id == item.id) && (suggItem.qtyUpperBound != item.qtyUpperBound)))
                        }
                    } )
                    
                }
            }
        }
        let isAdmin = this.props.user.userType === userTypes.ADMIN ? true : false
        let dunzoPaymentDone= this.orderData && this.orderData.orderStatus === "PAYMENT_DONE" && this.orderData.orderType  != "PICK_UP"
        let isTrackingUrl = this.orderData && this.orderData.trackingUrl && this.orderData.trackingUrl.startsWith("http") ? true : false
        let billImageIndex = this.props.orderObj && this.props.orderObj.uploadedBy && this.props.orderObj.uploadedBy.length > 0 ?  this.props.orderObj.uploadedBy.findIndex(x => x=='MERCHANT') : -1
        let billImage = this.props.orderObj && this.props.orderObj.images && this.props.orderObj.images.length > 0 ? this.props.orderObj.images[billImageIndex] : null
        let presciptionIndex = this.props.orderObj && this.props.orderObj.uploadedBy && this.props.orderObj.uploadedBy.length > 0 ?  this.props.orderObj.uploadedBy.findIndex(x => x=='CUSTOMER') : -1
        let prescriptionImage = this.props.orderObj && this.props.orderObj.images && this.props.orderObj.images.length > 0 ? this.props.orderObj.images[presciptionIndex] : null

        
        if(this.orderData.availableItems && this.orderData.availableItems.length) 
            {
                this.sortAvailableItemsBasisItemType() 
        }
        if (this.state.showAddItemsScreen)
         return <AddItemsScreen 
            user={this.props.user}
            handleCloseListItemsPage={this.handleCloseListItemsPage}
            addItem={this.handleAddSuggestedItem}
            />


        if (!this.state.showAddItemsScreen)
         return(
        <div>
            {/* {this.state.showOtpDialog && <OtpVerificationScreen 
                closePopUp={this.handleCloseOtpPopUp}
                orderData={this.props.orderObj}
                user={this.props.user}
            />} */}

           {
            <div className={window.innerWidth > 768 && "row col-md-6 col-md-offset-3 mobile-view-card delivery-desktop-view"} style={{marginTop: window.innerWidth > 768 && '30px',  marginTop : (this.props.online ||  this.props.retail) && '5%'}}>
                <div>
                    <div className="delivery-header-overflow">
                        <div className="delivery-item-body-header">
                            <div className="delivery-item-body-header-text" style={{width: this.state.showRejectionIdField && window.innerWidth <= 768 ? "75%": ""}}>
                            {
                                
                                <span>
                                    {
                                    <img src={backBtnImg} onClick={()=>{this.props.handleCloseListItemsPage()}} className="back-btn-padding"/>
                                    }
                                    { this.orderData && this.orderData.availableItems && this.orderData.availableItems.length > 0 ? this.orderData.availableItems.length : ""} Delivery item(s)
                                    {
                                      window.innerWidth > 768 && this.props.user.userType != "ADMIN"
                                      &&
                                        <div style={{float: 'right', display: 'flex', alignItems: 'center'}}>
                                            {
                                                (this.state.step<=2 || (isAdmin && this.state.step <= 5)) 
                                                &&
                                                <div className="cancel-desktop-view" onClick={()=> this.setState({ showRejectionIdField: true },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_clicked','order_page_cancel_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')})}>
                                                   CANCEL ORDER
                                                </div>
                                            }
                                            {/* <i class="fa fa-times-thin fa-2x" aria-hidden="true" onClick={()=>{this.props.handleCloseListItemsPage()}} style={{ fontSize:'30px',cursor: 'pointer' }}></i> */}
                                        </div>
                                    }
                                    {
                                      window.innerWidth > 768 && this.props.user.userType == "ADMIN"
                                      &&
                                        <div style={{float: 'right', display: 'flex', alignItems: 'center'}}>
                                            {
                                                (this.state.step === 2 || (isAdmin && this.state.step <= 5)) 
                                                && !this.state.showRejectionIdField &&
                                                <div className="cancel-desktop-view" onClick={()=> this.setState({ showRejectionIdField: !this.state.showRejectionIdField },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_clicked','order_page_cancel_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')})}>
                                                   CANCEL ORDER
                                                </div>
                                            }
                                            {
                                                (this.state.step === 2 || (isAdmin && this.state.step <= 5)) 
                                                && this.state.showRejectionIdField &&
                                                <React.Fragment>
                                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <select
                                                            value={this.state.rejectionId}
                                                            onChange={this.handleChangeForRejectionId}
                                                            style={{width: "200px", fontSize: "16px", marginRight: "10px"}}
                                                            >
                                                            <option value="">Select Rejection Reason</option>
                                                            {rejectionOptions.map((option) => (
                                                                <option
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                                ))}
                                                            </select>
                                                        {/* <input type="number" max="50" required placeholder="Rejection ID" onChange={(e) => this.handleChangeForRejectionId(e)} className="rejection-id-input-box"></input> */}

                                                        {this.state.rejectionId != null 
                                                            ? 
                                                                <div className="cancel-desktop-view" style={{marginTop: '4px', marginBottom: '4px'}} onClick={()=> {
                                                                    this.cancelOrder();
                                                                    this.setState({ showRejectionIdField: !this.state.showRejectionIdField, showRejectionIdFieldInFooter: false },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData.orderId)});
                                                                    }}>
                                                                    CONFIRM
                                                                </div>
                                                            :
                                                                <div className="cancel-desktop-view" onClick={() => {
                                                                    alert("Please select a rejection reason before sumbitting")
                                                                    this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
                                                                    }}>
                                                                    CONFIRM
                                                                </div>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {/* <i class="fa fa-times-thin fa-2x" aria-hidden="true" onClick={()=>{this.props.handleCloseListItemsPage()}} style={{ fontSize:'30px',cursor: 'pointer' }}></i> */}
                                        </div>
                                    }
                                </span>
                            }
                            </div>
                            { window.innerWidth < 768 && this.state.step <= 2 && this.props.user.userType != "ADMIN" &&
                                <div className="delivery-item-cancel-top" onClick={()=> this.setState({ showRejectionIdField: !this.state.showRejectionIdField },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_clicked','order_page_cancel_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')})}>
                                    Cancel
                                </div>
                            }
                            { window.innerWidth < 768 && (this.state.step <=5 && this.props.user.userType == "ADMIN") 
                                && !this.state.showRejectionIdField &&
                                <div className="delivery-item-cancel-top" onClick={()=> this.setState({ showRejectionIdField: !this.state.showRejectionIdField },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_clicked','order_page_cancel_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')})}>
                                    Cancel
                                </div>
                            }
                            { (window.innerWidth < 768 || !isAdmin) && ((this.state.step <=2 && !isAdmin)||(this.state.step <=5 && this.props.user.userType == "ADMIN")) 
                                && this.state.showRejectionIdField &&
                                <Dialog  onClose={() => this.setState({showRejectionIdField: false})}  aria-labelledby="customized-dialog-title" open={true}>
                                <DialogTitle id="customized-dialog-title" onClose={() => this.setState({showRejectionIdField: false})}>
                                    {/* Improve rank
                                    <div style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.54)", marginTop: '5px'}}>Effective activities that help improve your rank</div> */}
                                </DialogTitle>
                                <DialogContent dividers>
                                <div style={{display: 'flex', justifyContent: 'space-between', padding: "16px"}}>
                                    <select
                                        value={this.state.rejectionId}
                                        onChange={this.handleChangeForRejectionId}
                                        style={{width: "200px", fontSize: "16px", marginRight: "10px", border: "1px solid"}}
                                        >
                                        <option value="">Select Rejection Reason</option> 
                                        {isAdmin
                                        ?
                                        rejectionOptions.map((option) => (
                                        <option
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                        ))
                                        :
                                        rejectionOptionsMerchant.map((option) => (
                                        <option
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                        ))
                                        }
                                    </select>
                                    {/* <input type="number" required placeholder="Rejection ID" onChange={(e) => this.handleChangeForRejectionId(e)} className="rejection-id-input-box"></input> */}

                                    {this.state.rejectionId != null 
                                        ? 
                                            <div className="cancel-desktop-view" style={{marginRight: '0px', fontSize: '12px'}} onClick={()=> {
                                                this.cancelOrder();
                                                this.setState({ showRejectionIdField: !this.state.showRejectionIdField, showRejectionIdFieldInFooter: false },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')});
                                                }}>
                                                CONFIRM
                                            </div>
                                        :
                                            <div className="cancel-desktop-view" style={{marginRight: '0px', fontSize: '12px'}} onClick={() => {
                                                alert("Please enter a rejection ID before sumbitting")
                                                this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
                                                }}>
                                                CONFIRM
                                            </div>
                                    }
                                </div>
                                </DialogContent>
                            </Dialog>
                            }
                        </div>
                         {/* Header was here */}
                    </div>
                    <div className="list-item-body" style={{height: (this.state.step >= 0 && this.state.step <= 2 && this.listOfActions[this.state.step].showInputBox) ? '73vh' : '80vh', paddingTop: '55px',}}>
                    <div className="delivery-item-profile-outer-div" style={{padding: '0px 0px 10px 0px'}}>
                            <div className="delivery-item-author-div">
                                <img src={this.orderData.orderType == "PICK_UP" ? selfPickUpImg : homeDeliveryImg} className="image-icon-padding"/>
                                <div className="delivery-item-author-details">
                                    <div>
                                        {this.orderData.userName}
                                    </div>
                                    <div className="delivery-item-order-txt">
                                        {this.orderData.orderType == "PICK_UP" ? "Pickup order": "Delivery order"}: {this.orderData.orderId} / {this.orderData.createdAt ? moment(this.orderData.createdAt).fromNow() : ""}
                                   </div>
                                   <div className="delivery-item-order-txt">
                                        {this.orderData.address}
                                   </div>
                                </div>
                            </div>

                            <div style={{display:'flex'}}>
                                { <React.Fragment>
                                {this.state.uploadingBill ? <img src={loader} style={{width:"40px", height:"40px", marginRight: 10}} /> :
                                <React.Fragment>
                                {billImage?
                                <React.Fragment>
                                { 
                                   <div style={{width: '36px', height:'36px', marginRight:'16px'}}>
                                     <ImageViewer src={billImage} className="show-img-icon"/>
                                   </div>
                                }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                {/* <span className="delivery-item-call-btn">
                                  <img src={phoneCallImg} style={{margin:'0px auto'}}/>
                                </span> */}
                                {this.state.step <=5 && <React.Fragment>
                                <input 
                                    id={'file-input'}
                                    type="file" 
                                    accept=".pdf,image/*" 
                                    className="thumb-file-upload" 
                                    style={{display:'none'}} 
                                    onChange={this.uploadBill}
                                />
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">
                                        <div style={{textAlign: 'center'}}>
                                            <strong>Upload bill image to share with customer</strong>
                                            <br />
                                            {window.innerWidth > 768 && <div>Tip: Do this in fewer steps by using the mobile version to directly click and upload</div>}
                                        </div>
                                        </Tooltip>}>
                                        <label htmlFor={`file-input`} style={{height: 'fit-content'}}>
                                            <div className="delivery-item-call-btn" style={{marginRight: '10px'}}>
                                                <img src={cameraImg} style={{margin:'0px auto'}}></img>
                                            </div>
                                        </label>
                                    </OverlayTrigger>
                                    </React.Fragment>}
                                </React.Fragment>}
                                </React.Fragment>
                                } </React.Fragment>}
                            <div>
                            { window.innerWidth <= 786 && this.orderData && this.orderData.phoneNo && <div className="delivery-item-call-btn">
                             <a href={`tel:${this.orderData.phoneNo}`} style={{position:'relative', left:'24%'}} onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_customer_call_clicked','order_page_customer_call_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>
                                <img src={phoneCallImg} style={{margin:'0px auto'}}></img>
                             </a>
                             </div>
                            }
                            { window.innerWidth > 786 && this.orderData && this.orderData.phoneNo && !this.state.showEntirePhoneNumber &&
                              <div className="delivery-item-call-btn">
                              <a style={{position:'relative', left:'24%'}} onClick={() => this.handleCallOnDesktop()}>
                                <img src={phoneCallImg} style={{margin:'0px auto'}}></img>
                              </a>
                              </div>
                            }
                            { window.innerWidth > 786 && this.orderData && this.orderData.phoneNo && this.state.showEntirePhoneNumber &&
                              <a>
                                <div style={{color: 'rgba(0,0,0,0.87)', fontFamily: 'robotomedium', fontSize: '14px'}}>
                                {this.orderData.phoneNo}</div>
                              </a>
                            }
                            </div>
                          </div>
                            
                        </div>
                        {/* show this when there are no unread messages, but there are previous conversations in this thread */}

                        {/* <div style={{paddingLeft: '13%'}}>
                            {<InstantChat />}
                        </div> */}

                        {/* show this when there are unread messages */}

                        {this.orderData.versionCode >= 720 && this.orderData.versionCode < 5300
                            && <div style={{marginLeft: '48px', position: 'sticky', width: window.innerWidth > 768 ? '44vw' : '80vw', top: '0px', paddingBottom: '2%'}}>
                            {<InstantChat
                            orderData={this.orderData}
                            callAcceptOrder={false}
                            updatedChatsData={this.props.updatedChatsData}
                            handleInstantChatClick={this.props.handleInstantChatClick}
                            sendMessageToChat={this.props.sendMessageToChat}
                            user={this.props.user}
                            />}
                        </div>}
                        {/* onClick={() => this.props.handleInstantChatClick(this.orderData, false)} */}
                        <div className="list-item-heading" style={{marginBottom: '3%', padding: '0px'}}>
                            <div>{this.state.step != 3 ? "ITEMS" : "ITEMS TO BE PACKED"}</div>
                            <div>QUANTITY</div>
                        </div>
                        { this.orderData.availableItems && this.orderData.availableItems.map((item,index)=>{
                                return(
                                <React.Fragment>
                                    { item.itemType == "SUGGESTED"
                                        ?
                                        this.state.step <= 2 && <div className='suggest-items-banner'>
                                            Suggested
                                        </div>
                                        :
                                       item.itemType == "VOUCHER"
                                       ?
                                       <div className='voucher-items-banner'>
                                            Voucher
                                       </div>
                                       :
                                       <React.Fragment></React.Fragment>
                                    }
                                    {((item.itemType != "SUGGESTED") || (item.itemType == "SUGGESTED" && this.state.step <= 2) ) && <div className="list-item-name-quant-div">
                                    {item.itemType != "SUGGESTED" && <div id={`order_item_list_${index}`} style={{textAlign: 'left', textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.displayText}</div>}
                                    {item.itemType == "SUGGESTED" && this.state.step <= 2 && <div id={`order_item_list_${index}`} style={{textAlign: 'left', textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.displayText}</div>}
                                        {this.orderData.versionCode != "" && this.orderData.versionCode > 686 && <div style={{display: 'flex'}}>
                                            {
                                                this.state.step === 1 && item.itemType === "SUGGESTED"
                                                ?
                                                <input type="number" step="0.1" placeholder="₹--"  defaultValue={item.amount || null} onChange={(e)=>{this.addSuggestedItemPrice(e,index)}} className="suggested-item-input-box" />
                                                :
                                                (item.itemType === "SUGGESTED" || item.itemType ==="VOUCHER" || item.amount > 0)
                                                ?
                                                <div className="suggested-voucher-item-box" style={{textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': '', width: '60px', marginRight: '12px'}}>
                                                   {currencyString(item.amount, this.props.user.country,1)}
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                                            }
                                            {(item.itemType === "SUGGESTED" || item.itemType ==="VOUCHER") && <div style={{marginRight: '12px', alignSelf: 'center'}}>X</div>}
                                            { this.state.step !== 1
                                                ?
                                                item.itemType != "SUGGESTED" && <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>
                                                : 
                                                this.getItemDropDown(item.qtyUpperBound, item.quantity, index, item.status, item.itemType)
                                            }
                                            {this.state.step === 2 && item.itemType === "SUGGESTED" && <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>}
                                            { this.state.step === 1 &&
                                                <React.Fragment>
                                                {
                                                    item.status === "UNAVAILABLE"
                                                    ?
                                                    <img src={crossActiveDeliveryImg} style={{marginLeft:'15px', width: '25px'}} onClick={()=>this.markAvailable(index)}/>
                                                    :
                                                    <img src={crossInactiveDeliveryImg} style={{marginLeft:'15px', width: '25px'}} onClick={()=>this.markUnavailable(index)}/>
                                                }
                                                </React.Fragment>
                                            }
                                        </div>}
                                        {this.orderData.versionCode <= 686 && 
                                            <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>
                                        }
                                    </div>}
                                </React.Fragment>
                            )
                            
                           })
                        }
                        {/* {this.state.step > 2 && (!this.suggestedItemsIgnoredByCustomer || (this.suggestedItemsIgnoredByCustomer && this.suggestedItemsIgnoredByCustomer.length == 0) || (this.suggestedItemsIgnoredByCustomer && this.suggestedItemsIgnoredByCustomer.length < this.orderData.suggestedItems.length)) 
                        && (!this.selectedSuggestedItemsWithQtyChange || (this.selectedSuggestedItemsWithQtyChange && this.selectedSuggestedItemsWithQtyChange.length == 0) || (this.selectedSuggestedItemsWithQtyChange && this.selectedSuggestedItemsWithQtyChange.length < this.orderData.suggestedItems.length))
                          && this.orderData.availableItems && this.orderData.availableItems.map(item => item.itemType == "SUGGESTED" ? (<React.Fragment> */}
                          {this.state.step > 2 && this.orderData.suggestedItems
                          && this.orderData.suggestedItems.length && this.orderData.suggestedItems.map(item => (item.itemType == "SUGGESTED" && item.qtyUpperBound == item.quantity) ? (<React.Fragment>
                            <div className='suggest-items-banner'>
                                          Suggested
                              </div>
                              <div className="list-item-name-quant-div">

                                <div style={{textAlign: 'left'}}>{item.displayText}</div>
                                {this.orderData.versionCode != "" && this.orderData.versionCode > 686 && <div style={{display: 'flex'}}>
                                            {
                                                this.state.step === 1 && item.itemType === "SUGGESTED"
                                                ?
                                                ""
                                                // <input type="number" step="0.1" placeholder="₹--"  defaultValue={item.amount || null} onChange={(e)=>{this.addSuggestedItemPrice(e,index)}} className="suggested-item-input-box" />
                                                :
                                                (item.itemType ==="VOUCHER" || item.itemType === "SUGGESTED" || item.amount > 0)
                                                ?
                                                <div className="suggested-voucher-itemized-box" style={{textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': ''}}>
                                                   {currencyString(item.amount, this.props.user.country,1)}
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                                            }
                                            <div className="itemized-multiplier-icon">
                                                        X
                                            </div>
                                            { this.state.step !== 1
                                                ?
                                                <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", display: 'flex', justifyContent: 'space-evenly'}}>
                                                    {/* <div style={{textDecoration: 'line-through'}}>{item.qtyUpperBound}</div> */}
                                                    <div style={{textDecoration: 'none'}}>{item.quantity}</div>
                                                </div>
                                                :
                                                "" 
                                                // this.getItemDropDown(item.qtyUpperBound, item.quantity, index, item.status, item.itemType)
                                            }
                                            
                                        </div>}
                                        {this.orderData.versionCode <= 686 && 
                                            <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>
                                        }
                              </div>
                        </React.Fragment>) : "")
                        }
                        {this.selectedSuggestedItemsWithQtyChange && this.selectedSuggestedItemsWithQtyChange.length > 0
                          && this.selectedSuggestedItemsWithQtyChange.map(item => (<React.Fragment>
                            <div className='suggest-items-banner'>
                                          Suggested
                              </div>
                              <div className="list-item-name-quant-div">

                                <div style={{textAlign: 'left'}}>{item.displayText}</div>
                                {this.orderData.versionCode != "" && this.orderData.versionCode > 686 && <div style={{display: 'flex'}}>
                                            {
                                                this.state.step === 1 && item.itemType === "SUGGESTED"
                                                ?
                                                ""
                                                // <input type="number" step="0.1" placeholder="₹--"  defaultValue={item.amount || null} onChange={(e)=>{this.addSuggestedItemPrice(e,index)}} className="suggested-item-input-box" />
                                                :
                                                (item.itemType ==="VOUCHER" || item.itemType === "SUGGESTED" || item.amount > 0)
                                                ?
                                                <div className="suggested-voucher-item-box" style={{textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': '',  width: '60px', marginRight: '12px'}}>
                                                   {currencyString(item.amount, this.props.user.country,1)}
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                                            }
                                            <div style={{marginRight: '12px', alignSelf: 'center'}}>X</div>                                            
                                            { this.state.step !== 1
                                                ?
                                                <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", display: 'flex', justifyContent: 'space-evenly'}}>
                                                    <div style={{textDecoration: 'line-through'}}>{item.qtyUpperBound}</div>
                                                    <div style={{textDecoration: 'none'}}>{item.quantity}</div>
                                                </div>
                                                :
                                                "" 
                                                // this.getItemDropDown(item.qtyUpperBound, item.quantity, index, item.status, item.itemType)
                                            }
                                            
                                        </div>}
                                        {this.orderData.versionCode <= 686 && 
                                            <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>
                                        }
                              </div>
                        </React.Fragment>))
                        }
                        {this.suggestedItemsIgnoredByCustomer && this.suggestedItemsIgnoredByCustomer.length > 0 
                         && this.suggestedItemsIgnoredByCustomer.map(item => (<React.Fragment>
                            <div className='suggest-items-banner'>
                                          Suggested
                              </div>
                              <div className="list-item-name-quant-div">

                                <div style={{textAlign: 'left', textDecoration: 'line-through'}}>{item.displayText}</div>
                                {this.orderData.versionCode != "" && this.orderData.versionCode > 686 && <div style={{display: 'flex'}}>
                                            {
                                                this.state.step === 1 && item.itemType === "SUGGESTED"
                                                ?
                                                ""
                                                // <input type="number" step="0.1" placeholder="₹--"  defaultValue={item.amount || null} onChange={(e)=>{this.addSuggestedItemPrice(e,index)}} className="suggested-item-input-box" />
                                                :
                                                (item.itemType ==="VOUCHER" || item.itemType === "SUGGESTED" || item.amount > 0)
                                                ?
                                                <div className="suggested-voucher-item-box" style={{textDecoration: 'line-through', width: '60px', marginRight: '12px'}}>
                                                   {currencyString(item.amount, this.props.user.country,1)}
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                                            }
                                            <div style={{marginRight: '12px', alignSelf: 'center'}}>X</div>
                                            { this.state.step !== 1
                                                ?
                                                <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: 'line-through'}}>{item.qtyUpperBound}</div>
                                                :
                                                "" 
                                                // this.getItemDropDown(item.qtyUpperBound, item.quantity, index, item.status, item.itemType)
                                            }
                                            
                                        </div>}
                                        {this.orderData.versionCode <= 686 && 
                                            <div style={{height: "24px", width: "40px", paddingTop: "2px",  backgroundColor:"#f7f7fa", textDecoration: item.status=== "UNAVAILABLE" ? 'line-through': 'unset'}}>{item.itemType === "SUGGESTED" ? item.qtyUpperBound: item.quantity}</div>
                                        }
                              </div>
                        </React.Fragment>))
                        }
                        {this.orderData.availableItems && !this.orderData.availableItems.length && <div> Please refer to the image above to get order item(s)</div>  }
                        { this.state.step === 1 && this.orderData.versionCode != "" && this.orderData.versionCode > 686
                          &&
                            <div className='suggest-items-btn' onClick={() => {this.showSuggestItemScreen()}}>
                                Add/Suggest items +
                            </div>
                        }
                         {prescriptionImage && <div style={{width: '36px', height:'36px', marginRight:'16px'}}>
                            <ImageViewer src={prescriptionImage} className="show-img-icon" isZoomIcon={true}/>
                        </div>}
                        {this.state.step >= 1 && this.orderData && this.orderData.versionCode != "" 
                            && this.orderData.versionCode > 686 &&
                            <div className="item-price-breakup" style={{padding: '1% 0px', marginTop: '5%', borderTop: '1px dashed rgba(0,0,0,0.12)', borderBottom: '1px dashed rgba(0,0,0,0.12)'}}>
                            {this.totalItemCount > 0 && 
                                    <div className="item-price-show">
                                       <div>Available items ({this.totalItemCount})</div>
                                       {this.totalItemAmount == 0 && <div>₹--</div>}
                                       {this.totalItemAmount > 0 && <div>{currencyString(this.totalItemAmount, this.props.user.country,1)}</div>}
                                    </div>
                                } 
                               {this.totalVoucherCount > 0 && 
                                    <div className="item-price-show">
                                       <div>Voucher items ({this.totalVoucherCount})</div>
                                       <div>{currencyString(this.totalVoucherAmount, this.props.user.country,1)}</div>
                                    </div>
                                }
                               {this.totalSuggestedCount > 0 && 
                                    <div className="item-price-show">
                                        <div>Suggested items ({this.totalSuggestedCount})</div>
                                        <div>{currencyString(this.totalSuggestedAmount, this.props.user.country,1)}</div>
                                    </div>
                                }
                                {(this.orderData.deliveryInitiator == "MERCHANT" && this.state.step >= 3) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                }
                                {(this.orderData.deliveryInitiator == "MERCHANT" && this.state.step == 2) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                }
                                {(this.state.deliveryType == "MERCHANT" && this.state.step < 2) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                }
                            </div>}
                    </div>
                    <div className="list-item-footer" style={{ height: 'auto', flexDirection: dunzoPaymentDone && 'column'}}>
                        {
                            this.state.step >= 1 && this.orderData && this.orderData.versionCode != "" 
                            && this.orderData.versionCode > 686 &&
                            <div className="item-price-breakup" style={{padding: '0px 16px'}}>
                               {/* {this.totalItemCount > 0 && 
                                    <div className="item-price-show">
                                       <div>Available items ({this.totalItemCount})</div>
                                       {this.totalItemAmount == 0 && <div>₹--</div>}
                                       {this.totalItemAmount > 0 && <div>{currencyString(this.totalItemAmount, this.props.user.country,1)}</div>}
                                    </div>
                                } 
                               {this.totalVoucherCount > 0 && 
                                    <div className="item-price-show">
                                       <div>Voucher items ({this.totalVoucherCount})</div>
                                       <div>{currencyString(this.totalVoucherAmount, this.props.user.country,1)}</div>
                                    </div>
                                }
                               {this.totalSuggestedCount > 0 && 
                                    <div className="item-price-show">
                                        <div>Suggested items ({this.totalSuggestedCount})</div>
                                        <div>{currencyString(this.totalSuggestedAmount, this.props.user.country,1)}</div>
                                    </div>
                                }
                                {(this.orderData.deliveryInitiator == "MERCHANT" && this.state.step >= 3) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                }
                                {(this.orderData.deliveryInitiator == "MERCHANT" && this.state.step == 2) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                }
                                {(this.state.deliveryType == "MERCHANT" && this.state.step < 2) && <div className="item-price-show">
                                        <div>Delivery fee</div>
                                        <div>{currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 1)}</div>
                                    </div>
                                } */}
                               {this.state.step >= 3 && this.orderData.deliveryInitiator != "MERCHANT" &&  <div className="item-price-total-show" style={{border: 'none'}}>
                                    <div>Total received amount</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount, this.props.user.country,1)}</div>
                                    {/* <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount, this.props.user.country,1)}</div> */}
                               </div>}
                               {this.state.step >= 3 && this.orderData.deliveryInitiator == "MERCHANT" &&  <div className="item-price-total-show" style={{border: 'none'}}>
                                    <div>Total received amount</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div>
                                    {/* <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div> */}
                               </div>}
                                
                                
                                
                                
                                                
                                {/* {this.state.step == 2 && this.orderData.deliveryInitiator != "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount, this.props.user.country,1)}</div>
                                </div>}
                               {this.state.step == 2 && this.orderData.deliveryInitiator == "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div>
                                </div>}

                               {this.state.step < 2 && (this.state.deliveryType != "MERCHANT") &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount, this.props.user.country,1)}</div>
                               </div>}
                               {this.state.step < 2 && this.state.deliveryType == "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    {console.log(this.state.billValue)}
                                    <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div> 
                               </div>} */}




                               {/* {this.orderData.deliveryInitiator == "MERCHANT" && <span style={{fontFamily: 'robotolight', fontSize: '13px'}}><div style={{textAlign: "left"}}>You will earn an extra {currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)} delivery fee</div></span>} */}
                            </div>
                        }
                        <div style={{background: this.listOfActions[this.state.step].bgColor, width: '100%', padding: '16px 12px'}}>
                        {/* { this.state.step>=1 && this.state.step <= 2 */}
                        {/* { this.orderData && (this.orderData.orderStatus == "PENDING" || this.orderData.orderStatus == "ACCEPTED") */}
                        { this.state.step>=0 && this.state.step <= 2
                            ?
                             <div style={{width: '100%'}}>
                                {
                                   this.listOfActions[this.state.step].showInputBox
                                    // (this.orderData.orderStatus == "PENDING" || this.orderData.orderStatus == "ACCEPTED")
                                     ?
                                    <React.Fragment>
                                        <div className="input-btn-alignment">
                                            <div className="outer-input-submit-div">
                                            {this.props.user.userType == "ADMIN" && this.orderData.orderStatus == "PENDING" && <div>Hey ADMIN, this order HAS NOT been accepted by the merchant yet</div>}
                                                <div className="tot-bill-amt-text-div">
                                                    <div>Enter Bill Amount{(this.state.deliveryType == "MERCHANT" || this.orderData.deliveryInitiator == "MERCHANT") && ` (excluding delivery fees)`}{this.state.deliveryType == "MERCHANT" && <span style={{fontFamily: 'robotolight', fontSize: '13px'}}><div>You will earn an extra {currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)} delivery fee</div></span>}</div>
                                                    {/* {this.state.deliveryType == "MERCHANT" && <div>You will earn xx for delivering the order</div>} */}
                                                    {/* HERE */}



                            {/* {this.state.step == 2 && this.orderData.deliveryInitiator != "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount, this.props.user.country,1)}</div>
                                </div>}
                               {this.state.step == 2 && this.orderData.deliveryInitiator == "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div>
                                </div>}

                               {this.state.step < 2 && (this.state.deliveryType != "MERCHANT") &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount, this.props.user.country,1)}</div>
                               </div>}
                               {this.state.step < 2 && this.state.deliveryType == "MERCHANT" &&  <div className="item-price-total-show">
                                    <div>Total amount (to be received from customer)</div>
                                    {console.log(this.state.billValue)}
                                    <div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div> 
                               </div>} */}






                                                    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">
                                                        <div style={{textAlign: 'left'}}>
                                                        • Include price of only available items
                                                        <br />
                                                        • Include indicated price for voucher items.
                                                        <br />
                                                        • Include price added by you for suggested items.
                                                        </div>
                                                        </Tooltip>}>
                                                        <input type="number" step="0.1" placeholder="₹--"  defaultValue={this.state.billValue || null} onChange={(e) => this.handleChange(e)} className="bill-value-input-box"></input>
                                                            {/* <label htmlFor={`file-input`} style={{height: 'fit-content'}}>
                                                            <div className="delivery-item-call-btn" style={{marginRight: '10px'}}>
                                                            <img src={cameraImg} style={{margin:'0px auto'}}></img>
                                                            </div>
                                                        </label> */}
                                                    </OverlayTrigger>


                                                    {/* <input type="number" step="0.1" placeholder="₹--"  defaultValue={this.state.billValue || null} onChange={(e) => this.handleChange(e)} className="bill-value-input-box"></input> */}
                                                </div>
                                                {/* {this.orderData.versionCode != "" && this.orderData.versionCode > 686 
                                                && <div className="delivery-tnc-text">
                                                  <ul>
                                                    <li className="list-style-unset">Include price of only available items.</li>
                                                    <li className="list-style-unset">Include indicated price for voucher items.</li>
                                                    <li className="list-style-unset">Include price added by you for suggested items.</li>
                                                   </ul>
                                                </div>} */}
                                                { this.orderData.orderType != "PICK_UP" && (this.orderData.orderStatus == "ACCEPTED" || this.orderData.orderStatus == "PENDING" || this.orderData.orderStatus == "BILL_READY") && this.orderData.orderType != "PICK_UP"  &&
                                                    <div style={{paddingBottom: '8px'}}>


{this.state.step == 2 && this.orderData.deliveryInitiator == "MERCHANT" &&  <div className="item-price-total-show"  style={{border: 'none'}}>
<div>Total amount (to be received from customer)</div>
<div className="item-price-tot-bar">{currencyString(this.nonMutatedOrderAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div>
</div>}

{this.state.step < 2 && this.state.deliveryType == "MERCHANT" &&  <div className="item-price-total-show"  style={{border: 'none'}}>
<div>Total amount (to be received from customer)</div>
<div className="item-price-tot-bar">{currencyString(this.totalItemAmount + this.totalVoucherAmount + this.totalSuggestedAmount + this.orderData.merchantCommissionMerchantDelivery, this.props.user.country,1)}</div> 
</div>}


                                                        {/* <div className="order-delivery-mode-selector-heading" style={{display: 'flex', justifyContent: 'space-between'}} >Select a delivery option to proceed{this.state.deliveryType == "MERCHANT" && <span style={{marginRight: '6%'}}>+ {currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)}</span>}</div> */}
                                                        {/* <div className="order-delivery-mode-selector-heading" style={{display: 'flex', justifyContent: 'space-between'}} >Select a delivery option to proceed</div>
                                                            <div className="order-delivery-mode-selector-type" style={{paddingRight: '0px'}} onClick={(e)=>{this.handleDeliveryInitiatorChange(e,'MERCHANT')}}>
                                                            <img src={this.state.deliveryType == "MERCHANT" ?  deliveryCheckBoxIcon: deliveryUncheckBoxIcon}></img>
                                                            <div style={{width: '100%'}}>
                                                            <div className="order-delivery-itemized-mode-main-text">
                                                            I will deliver on my own
                                                            <div>
                                                                <div className="background-ribbon-image">
                                                                    Earn {currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-delivery-mode-selector-type" onClick={(e)=>{this.handleDeliveryInitiatorChange(e,'MAGICPIN')}}>
                                                        <img src={this.state.deliveryType == "MAGICPIN" ?  deliveryCheckBoxIcon: deliveryUncheckBoxIcon}></img>
                                                        <div>
                                                            <div className="order-delivery-itemized-mode-main-text">I will use partner assisted delivery</div>
                                                        </div>
                                                    </div> */}
                                                </div>                                            
                                                }
                                                {/* <div className={`deliver-item-input-btn-submit ${this.props.updateOrdersValueLoading ? 'loading' : ''}`}style={{background : !this.state.billValue  ? '#f7f7fa' :'#ef1c74', color: !this.state.billValue && 'rgba(0, 0, 0, 0.2) '}} onClick={()=>{this.handleOpenUpdatePopUp(this.state.deliveryType)}}>
                                                    {this.listOfActions[this.state.step].ctaText}
                                                </div> */}
                                                



                                                {
                                                 this.orderData.orderType !== "PICK_UP"
                                                 ?
                                                <React.Fragment>
                                                    <div className="order-delivery-outer-div">
                                                        <div>
                                                            <div className="order-delivery-mode-selector-type" style={{paddingRight: '0px'}} onClick={(e)=>{this.handleDeliveryInitiatorChange(e,'MERCHANT')}}>
                                                                <img src={this.state.deliveryType == "MERCHANT" ?  deliveryCheckBoxIcon: deliveryUncheckBoxIcon}></img>
                                                                <div>
                                                                    <div className={ this.state.deliveryType === "MERCHANT" ? "order-delivery-itemized-mode-main-text": "order-delivery-itemized-mode-second-text"}>
                                                                        Deliver on my own .
                                                                        <span className="highlight-txt-commission"> Earn {currencyString(Math.abs(this.orderData.merchantCommissionMerchantDelivery), this.props.user.country, 0)}</span>
                                                                        {/* <div>
                                                                            <div className="background-ribbon-image">
                                                                                Earn {currencyString(this.orderData.merchantCommissionMerchantDelivery, this.props.user.country, 0)}
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="order-delivery-mode-selector-type" onClick={(e)=>{this.handleDeliveryInitiatorChange(e,'MAGICPIN')}}>
                                                                <img src={this.state.deliveryType == "MAGICPIN" ?  deliveryCheckBoxIcon: deliveryUncheckBoxIcon}></img>
                                                                <div>
                                                                    <div className={this.state.deliveryType === "MAGICPIN" ? "order-delivery-itemized-mode-main-text": "order-delivery-itemized-mode-second-text"}>
                                                                        Partner assisted delivery . 
                                                                        <span className="highlight-txt-commission"> Pay {currencyString(Math.abs(this.orderData.merchantCommissionMagicpinDelivery), this.props.user.country, 0)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`deliver-item-input-btn-submit ${this.props.updateOrdersValueLoading ? 'loading' : ''}`} style={{background :'#ef1c74'}} onClick={()=>{this.handleOpenUpdatePopUp(this.state.deliveryType)}}>
                                                            {this.listOfActions[this.state.step].ctaText}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className={`deliver-item-input-btn-submit ${this.props.updateOrdersValueLoading ? 'loading' : ''}`} style={{ marginTop: '8px', width: '100%',background :'#ef1c74'}} onClick={()=>{this.handleOpenUpdatePopUp(this.state.deliveryType)}}>
                                                        {this.listOfActions[this.state.step].ctaText}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            </div>
                                            { window.innerWidth >768 && this.props.user.userType == "ADMIN" && this.state.showRejectionIdFieldInFooter &&  
                                                !this.state.showRejectionIdField &&
                                                <React.Fragment>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    {/* <input type="number" required placeholder="Rejection ID" onChange={(e) => this.handleChangeForRejectionId(e)} className="rejection-id-input-box"></input> */}
                                                        <select
                                                            value={this.state.rejectionId}
                                                            onChange={this.handleChangeForRejectionId}
                                                            style={{width: "200px", fontSize: "16px", marginRight: "10px"}}
                                                            >
                                                            <option value="">Select Rejection Reason</option> 
                                                            {rejectionOptions.map((option) => (
                                                            <option
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </option>
                                                            ))}
                                                        </select>
                                                    {this.state.rejectionId != null 
                                                        ? 
                                                            <div className="cancel-desktop-view" style={{marginRight: '14px', fontSize: '12px'}} onClick={()=> {
                                                                this.cancelOrder();
                                                                this.setState({ showRejectionIdField: !this.state.showRejectionIdField, showRejectionIdFieldInFooter: false },()=>{this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')});
                                                                }}>
                                                                CONFIRM
                                                            </div>
                                                        :
                                                            <div className="cancel-desktop-view" style={{marginRight: '14px', fontSize: '12px'}} onClick={() => {
                                                                alert("Please enter a rejection ID before sumbitting")
                                                                this.props.sendGbAndGbqEventsOrder('order_page_cancel_confirm_clicked','order_page_cancel_confirm_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')
                                                                }}>
                                                                CONFIRM
                                                            </div>
                                                    }
                                                </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="input-btn-alignment">
                                        <img src={TimerStatusImg}/>
                                        <div className="status-msg-alignment-payment">
                                         {this.listOfActions[this.state.step].infoText}<br/>
                                         
                                         {this.orderData.deliveryInitiator != "MERCHANT" && this.orderData.versionCode != "" && this.orderData.versionCode > 686 
                                          && <React.Fragment>
                                              <b style={{fontSize:'16px'}}>{currencyString(this.state.billValue,this.props.user.country,1)}</b> Payment Pending <br/>{ this.orderData && this.orderData.suggestedItems && this.orderData.suggestedItems.length > 0 && `Subject to suggested items chosen by customer`}
                                             </React.Fragment>
                                         }
                                         {this.orderData.deliveryInitiator == "MERCHANT" && this.orderData.versionCode != "" && this.orderData.versionCode > 686 
                                          && <React.Fragment>
                                              <b style={{fontSize:'16px'}}>{currencyString(this.state.billValue + this.orderData.merchantCommissionMerchantDelivery,this.props.user.country,1)}</b> Payment Pending <br/>{ this.orderData && this.orderData.suggestedItems && this.orderData.suggestedItems.length > 0 && `Subject to suggested items chosen by customer`}
                                             </React.Fragment>
                                         }

                                         {this.orderData.deliveryInitiator != "MERCHANT" && this.orderData.versionCode <= 686
                                          && <React.Fragment>
                                              <b style={{fontSize:'16px'}}>{currencyString(this.orderData.amount,this.props.user.country,1)}</b> Payment Pending
                                          </React.Fragment>
                                         }
                                         {this.orderData.deliveryInitiator == "MERCHANT" && this.orderData.versionCode <= 686 
                                          &&  <React.Fragment>
                                              <b style={{fontSize:'16px'}}>{currencyString(this.orderData.amount + this.orderData.merchantCommissionMerchantDelivery,this.props.user.country,1)}</b> Payment Pending
                                          </React.Fragment>
                                         }


                                         {/* {this.orderData.versionCode != "" && this.orderData.versionCode > 686 && <React.Fragment> <b style={{fontSize:'16px'}}>{currencyString(this.state.billValue,this.props.user.country,1)}</b> Payment Pending <br/>{ this.orderData && this.orderData.suggestedItems && this.orderData.suggestedItems.length > 0 && `Subject to suggested items chosen by customer`}</React.Fragment>}
                                         {this.orderData.versionCode <= 686 && <React.Fragment><b style={{fontSize:'16px'}}>{currencyString(this.orderData.amount,this.props.user.country,1)}</b> Payment Pending</React.Fragment>} */}
                                        </div>
                                        <div className={`deliver-btn-edit-link`} onClick={()=>{this.listOfActions[this.state.step].actionPerform()}}>
                                           {this.listOfActions[this.state.step].ctaText}
                                        </div>
                                    </div>
                                } 
                             </div>
                            :
                             this.listOfActions[this.state.step].infoText
                             ?
                             <React.Fragment>
                                { dunzoPaymentDone &&
                                    <div className="dunzo-delivery-track">
                                        <div>
                                            { this.props.orderObj.deliveryInitiator == 'MAGICPIN' ? 'Delivery Agent will be assigned soon' : 'Prepare the order'}
                                        </div>
                                        { (this.props.orderObj.trackingUrl || this.props.orderObj.trackingPhoneNo)
                                            &&
                                            <div>
                                            {
                                              this.props.orderObj.trackingUrl 
                                              ?
                                              <React.Fragment>
                                                { 
                                                   isTrackingUrl 
                                                   ?
                                                   <a target="_blank" href={this.props.orderObj.trackingUrl} className="track-order-link" onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_tracking_link_clicked','order_page_tracking_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>Track Order</a>
                                                   :
                                                   window.innerWidth <= 786
                                                   ?
                                                    <a href={`tel:${this.props.orderObj.trackingPhoneNo}`} onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_delivery_agent_link_clicked','order_page_delivery_agent_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}} className="track-order-link">
                                                    Call Delivery Agent
                                                    </a>
                                                    :
                                                    <a className="track-order-link" onClick={() => this.handleCallOnDesktopAgent()}>
                                                    { !this.state.showCallAgentPhoneNumber
                                                        ?
                                                        'Call Delivery Agent'
                                                        :
                                                        this.orderData.trackingPhoneNo
                                                    }
                                               </a>

                                                }
                                               </React.Fragment>
                                              :
                                               window.innerWidth <= 786
                                               ?
                                                <a href={`tel:${this.props.orderObj.trackingPhoneNo}`} className="track-order-link" onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_delivery_agent_link_clicked','order_page_delivery_agent_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>
                                                   Call Delivery Agent
                                                </a>
                                               :
                                               <a className="track-order-link" onClick={() => this.handleCallOnDesktopAgent()}>
                                                   { !this.state.showCallAgentPhoneNumber
                                                      ?
                                                      'Call Delivery Agent'
                                                      :
                                                      this.orderData.trackingPhoneNo
                                                   }
                                               </a>
                                            }
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="img-info-txt-div">
                                    <div style={{display: 'flex'}}>
                                        <img src={successGreenTick} style={{marginRight: '18px', float:'left'}}></img>
                                        <div className="bold-info-text" style={{float:'left'}}>
                                            {
                                            this.orderData.orderStatus === "PAYMENT_DONE"
                                            ?
                                            <React.Fragment>
                                                {this.orderData.deliveryInitiator == "MERCHANT"
                                                 ? 
                                                 <span><b style={{fontSize:'16px', fontFamily:'robotobold'}}>{currencyString(this.state.billValue + this.orderData.merchantCommissionMerchantDelivery,this.props.user.country,0)}</b> {"payment received. "}</span>
                                                 :
                                                 <span><b style={{fontSize:'16px', fontFamily:'robotobold'}}>{currencyString(this.state.billValue,this.props.user.country,0)}</b> {"payment received. "}</span>
                                                }
                                                {/* <b style={{fontSize:'16px', fontFamily:'robotobold'}}>{currencyString(this.state.billValue,this.props.user.country,0)}</b> {"payment received. "} */}
                                                <br/>
                                            </React.Fragment>
                                            :
                                              this.listOfActions[this.state.step].infoText
                                            }
                                        </div>
                                    </div>
                                    <div>
                                    { (this.props.orderObj.trackingUrl || this.props.orderObj.trackingPhoneNo) && this.orderData.orderStatus !== "PAYMENT_DONE"
                                        &&
                                            <div>
                                            {
                                              this.props.orderObj.trackingUrl 
                                              ?
                                            //   <a target="_blank" href={this.props.orderObj.trackingUrl} className="track-order-link">Track Order</a>
                                               <React.Fragment>
                                                { 
                                                    isTrackingUrl 
                                                    ?
                                                    <a target="_blank" href={this.props.orderObj.trackingUrl} className="track-order-link" onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_tracking_link_clicked','order_page_tracking_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>Track Order</a>
                                                    :
                                                    window.innerWidth <= 786
                                                    ?
                                                        <a href={`tel:${this.props.orderObj.trackingPhoneNo}`} className="track-order-link" onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_delivery_agent_link_clicked','order_page_delivery_agent_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>
                                                        Call Delivery Agent
                                                        </a>
                                                        :
                                                        <a className="track-order-link" onClick={() => this.handleCallOnDesktopAgent()}>
                                                            { !this.state.showCallAgentPhoneNumber
                                                                ?
                                                                'Call Delivery Agent'
                                                                :
                                                                this.orderData.trackingPhoneNo
                                                            }
                                                        </a>
                                                    }
                                               </React.Fragment>
                                              :
                                               window.innerWidth <= 786
                                               ?
                                                <a href={`tel:${this.props.orderObj.trackingPhoneNo}`} className="track-order-link" onClick={()=>{this.props.sendGbAndGbqEventsOrder('order_page_delivery_agent_link_clicked','order_page_delivery_agent_link_clicked','singleOrderItem', this.orderData ?this.orderData.orderId: '')}}>
                                                   Call Delivery Agent
                                                </a>
                                               :
                                               <a className="track-order-link" onClick={() => this.handleCallOnDesktopAgent()}>
                                                   { !this.state.showCallAgentPhoneNumber
                                                      ?
                                                      'Call Delivery Agent'
                                                      :
                                                      this.props.orderObj.trackingPhoneNo
                                                   }
                                               </a>
                                            }
                                            </div>
                                    }
                                    { this.listOfActions[this.state.step].ctaText &&
                                        <span className={`deliver-btn-edit-submit ${this.props.updateOrdersStatusLoading ? 'loading' : ''}`}  style={{float: "right", marginTop: !this.props.orderObj.trackingUrl &&'-4px'}} onClick={()=>{this.state.step == 4 ? this.handleOpenUpdatePopUp() : this.listOfActions[this.state.step].actionPerform()}}>
                                            {this.listOfActions[this.state.step].ctaText}
                                        </span>
                                    }
                                    </div>
                                </div>
                             </React.Fragment>
                             :
                            // <React.Fragment>
                            //     {this.orderData.orderType == "DELIVERY" ? <div style={{width: "100%", textAlign: "left"}}>
                            //         <div>
                            //             Select a delivery option to accept this order
                            //         </div>
                            //         <div className="deliver-option-submit" onClick={()=>{this.handleOpenUpdatePopUp("MERCHANT")}}>
                            //             <div>
                            //                 <div className="delivery-option-text">Self delivery</div>
                            //                 <div className="delivery-option-subtext">Faster, safer and preferred by customers. </div>
                            //             </div>
                            //             <div style={{paddingTop:"6px"}}>
                            //                 <img src={leftArrow} />
                            //             </div>
                            //         </div>  
                            //         <div className="deliver-option-submit" onClick={()=>{this.handleOpenUpdatePopUp("MAGICPIN")}}>
                            //             <div style={{width: "70%"}}>
                            //                 <div className="delivery-option-text">Partner delivery</div>
                            //                 <div className="delivery-option-subtext">Rider subject to availability. User will be charged a delivery fee.</div>
                            //             </div>
                            //             <div style={{paddingTop: window.innerWidth > 768 ? "6px" : "16px"}}>
                            //                 <img src={leftArrow} />
                            //             </div>
                            //         </div>  
                            //     </div> :
                            //     <div className={`deliver-item-btn-submit ${this.props.updateOrdersStatusLoading ? 'loading' : ''}`} onClick={()=>{this.handleOpenUpdatePopUp()}}>
                            //         {this.listOfActions[this.state.step].ctaText}
                            //     </div>
                            // }
                            // </React.Fragment>
                            ""
                        }




                        {/* {this.orderData.orderStatus == "ACCEPTED" && 
                        <React.Fragment>
                                {this.orderData.orderType == "DELIVERY" ? <div style={{width: "100%", textAlign: "left"}}>
                                    <div>
                                        Select a delivery option to accept this order
                                    </div>
                                    <div className="deliver-option-submit" onClick={()=>{this.handleOpenUpdatePopUp("MERCHANT")}}>
                                        <div>
                                            <div className="delivery-option-text">Self delivery</div>
                                            <div className="delivery-option-subtext">Faster, safer and preferred by customers. </div>
                                        </div>
                                        <div style={{paddingTop:"6px"}}>
                                            <img src={leftArrow} />
                                        </div>
                                    </div>  
                                    <div className="deliver-option-submit" onClick={()=>{this.handleOpenUpdatePopUp("MAGICPIN")}}>
                                        <div style={{width: "70%"}}>
                                            <div className="delivery-option-text">Partner delivery</div>
                                            <div className="delivery-option-subtext">Rider subject to availability. User will be charged a delivery fee.</div>
                                        </div>
                                        <div style={{paddingTop: window.innerWidth > 768 ? "6px" : "16px"}}>
                                            <img src={leftArrow} />
                                        </div>
                                    </div>  
                                </div> :
                                <div className={`deliver-item-btn-submit ${this.props.updateOrdersStatusLoading ? 'loading' : ''}`} onClick={()=>{this.handleOpenUpdatePopUp()}}>
                                    {this.listOfActions[this.state.step].ctaText}
                                </div>
                            }
                        </React.Fragment>} */}
                    </div>          
                    </div>
                    {this.state.showCancelOrderPopup && 
                        <CancelOrderDialog 
                          order={this.props.orderData}
                          cancelOrder={this.cancelOrder}
                          closePopUp={this.handleClosePopUp}
                        />}
                    {
                        this.state.showUpdatePopup &&
                        <UpdatePopupDialog
                           sendGbAndGbqEventsOrder={this.props.sendGbAndGbqEventsOrder}
                           orderData={this.orderData}
                           currStep={this.listOfActions[this.state.step].status}
                           closePopUp={this.handleCloseUpdatePopUp}
                           performAction={this.performAction}
                           totalItemCount={this.totalItemCount}
                           totalItemAmount={this.totalItemAmount}
                           totalVoucherCount={this.totalVoucherCount}
                           totalSuggestedCount={this.totalSuggestedCount}
                           totalBillValue={this.state.billValue}
                           totalVoucherAmount={this.totalVoucherAmount}
                           suggestedItems={this.props.suggestedItems}
                           totalSuggestedAmount={this.totalSuggestedAmount}
                           deliveryType={this.state.deliveryType}
                           user={this.props.user}
                           ctaLeft={this.listOfActions[this.state.step].ctaLeft || "NO"}
                           actionInfoText={this.listOfActions[this.state.step].popUpText ? this.listOfActions[this.state.step].popUpText : (this.orderData.versionCode != "" && this.orderData.versionCode >686) ? `Please make sure bill amount ${currencyString(this.state.billValue,this.props.user.country,1)} includes AVAILABLE ITEMS, VOUCHER ITEMS and SUGGESTED ITEMS.` : `Please confirm if ${currencyString(this.state.billValue,this.props.user.country,1)} bill amount is correct for this order`}
                           showPriceBreakup={this.listOfActions[this.state.step].popUpText ? false : (this.orderData.versionCode != "" && this.orderData.versionCode >686) ? true : false }
                           ctaRight={this.listOfActions[this.state.step].ctaRight || "YES"}
                           isLeftFocus={this.state.step != 1 }
                        />
                    }
                </div>
            </div>
        }
        </div>
        );
    }
})