/*global chrome*/
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton'
import Drawer from 'material-ui/Drawer';
import { getNotificationCount } from '../../redux/modules/notifications';
import {toggleSmsPopup} from '../../redux/modules/smsCampaign';
import {toast} from 'react-toastify';
import { getDealsCount } from '../../redux/modules/bookDeal';
// import {sendSnackbarEmail} from '../../redux/modules/snackbarEmail';
import { userTypes, countries, MAGIC_ORDER_VISIBLE, ledgerDownloadRetryObj }  from '../../constants';
// import MagicpayToast from '../Wallet/MagicpayToast';
import {getBppActive, logout, setShowSecurityAlert, setShowLocationSettingGuide} from '../../redux/modules/authReducers/auth';
import { getMerchantOrders, getMerchantDeliveryDetails, loadChatHistoryRequest, loadChatHistoryResponse, 
  openNewWebSocket, getOrderHereAlias, checkIfCatalogExists, getMerchantRatingsAndVisits, whatsappProducer, 
  getWhatsappImage, showAppDownloadPopup, getDeliveryConfiguratorData, didMerchantCreateNYVouchers, 
  createNYVoucherRequest, saltSyncRedisCheck, onsuritySnackbarLocalityCheck, accessTokenDecryptor, writeInteractivityEvent} from '../../redux/modules/newMerchantLanding';
import GroupBuyToast from '../Wallet/GroupBuyToast';
import { getMembers} from '../../redux/modules/myteam.js';
import {getWalletBalance, getWithdrawalStatus, paymentCommercialsUpsert, getMerchantWalletTransactionAsync, stopMerchantWalletTransactionAsync} from '../../redux/modules/walletSummary';
import { getFeedShortcutsStatus } from '../../redux/modules/feedReducers/feedShortcutsStatus';
import { showMagicpayModalFeedShortcutClick, getMagicpaySubscription, toggleMagicPay} from '../../redux/modules/magicpaySubscription';
import { getGroupBuyPlans ,launchGroupBuyModal } from '../../redux/modules/groupBuyLaunch';
import { launchFixedCashinModal } from '../../redux/modules/fixedCashinLaunch';
import logoBusiness from '../../images/logo-business.svg';
import logoBusinessWhite from '../../images/magicpin-for-business-white.svg';
import { getMerchantActivationStatus, showActivationModal, hideActivationModal } from '../../redux/modules/feedReducers/merchantActivation';
import { loadScript, compactCurrencyString, showPopup, showPopupAfter2days, sendEvent, getBrowserInfo, getDeviceDetails, getEmailId, getLoginMedium, lastViewOrdersCallCheck} from '../../helpers/Helpers'
import { isChrome, getVersion, sendMessage } from '../../helpers/ChromeExtensionHelpers'
import { getUpgradePackage } from '../../redux/modules/upgradePackage';
import { loadBusinessSummary, getGstModalCount, merchantShowsIntrestMagicOrder } from '../../redux/modules/dashboardReducers/businessSummary';
import {toggleArModal} from '../../redux/modules/feedReducers/getArData'
import { getMarketingRedemptionStatus } from '../../redux/modules/dealReducers/cashVouchers';
import { showNYVoucherModal, hideNYVoucherModal, showGroceryPharmaModal, hideGroceryPharmaModal, showMagicOrderModal, hideMagicOrderModal } from '../../redux/modules/dealReducers/createVouchers';
import SnackBar from './SnackBar';
import HelpDesk from '../HelpDesk/HelpDesk';
import AddNewYearVoucher from '../Vouchers/AddNewYearVoucher'
import ShareStoreDialog from '../HomeDelivery/ShareStoreDialog'
import { verifyGstAfterActivation } from '../../redux/modules/verifyGst';
import GstVerificationModal from './GstVerificationModal';
import NotificationToast from '../HomeDelivery/NotificationToast';
import { IsPharmaMerchant } from '../../helpers/PharmaMerchants'
import UIFx from 'uifx';
import AskAutoplayPermission from '../HomeDelivery/AskAutoplayPermission';
// import newOrderAudio from '../../audio/home-delivery-new-order-repeat-5.mp3';
import newOrderAudio from '../../audio/home-delivery-new-order-sound-v2-repeat.mp3';
import orderNotificationAudio from '../../audio/home-delivery-notification.wav';
import chatAudio from '../../audio/home-delivery-chat-notification-audio.mp3';
import './header.css';
import  '../Wallet/MagicPay.css';
import AddHomeDeliveryVouchers from '../Vouchers/AddHomeDeliveryVouchers';
import { hideSnackBar } from '../../redux/modules/snackbarEmail';
import { loadBppDetails } from '../../redux/modules/brandPrivilegeProgram';
import {getAllCrmData} from '../../redux/modules/merchantCrm';

import { getGoalTrackerHistory, setUpiId } from '../../redux/modules/goalTracker';
import IntrestedMagicOrderPopup from "../Vouchers/IntrestedMagicOrderPopup.js";
import {isAppInstalled} from "../../redux/modules/appInstall"
import moment from 'moment';
import { getAccountStatus } from '../../redux/modules/dashboardReducers/businessSummary';
import AppDownloadPopup from './AppDownloadPopup';
import { SetUpi } from '../PopUps/SetUpi';
import PineLabsConsent from '../PopUps/PineLabsConsent';
import LocSettingGuideline from '../PopUps/LocSettingGuideline';
import { checkAdminSession, checkMerchantSession } from '../../redux/modules/loginSession';
import SecurityAlert from '../PopUps/SecurityAlert';

const ga = window.ga;
const MagicPay = React.lazy(() => import('../Wallet/MagicPay'));
// const AddNewYearVoucher = asyncComponent(() => import('../Vouchers/AddNewYearVoucher'))
const MerchantActivationModal = React.lazy(() => import('./MerchantActivationModal'))
const VoucherRelisting = React.lazy(() => import('../Vouchers/VoucherRelisting'))
const GroupBuy = React.lazy(()=>import('../Wallet/GroupBuy'))
const FixedCashinPopup = React.lazy(()=>import('../FixedCashin/FixedCashinDialog'))
const Confirm = React.lazy(()=>import('../Utilities/Confirm'))
const LoginPasswordCheck = React.lazy(() => import('../Login/LoginPasswordCheck'))
const SmsPromotion  = React.lazy(()=>import('../SmsPromotion/SmsPromotion'))
const ArGame = React.lazy(() => import("../Feed/ArGame"));
const saltFromAuth = window.localStorage.getItem('saltFromAuth');
const BUZZER_REPEAT_LIMIT = 5;
const deviceType = window.innerWidth > 768 ? 'DESKTOP' : 'MOBILE';
const signInTimeStamp = window.localStorage.getItem('timeOfLastLogin') ? moment(window.localStorage.getItem('timeOfLastLogin')).unix() : null;
const deviceBrowserId = window.localStorage.getItem('session_actor_id');
const timeStampOfLastBuzzerPlay = window.localStorage.getItem('timeStampOfLastBuzzerPlay');
// const orderNotificationSound = new UIFx(
//   orderNotificationAudio,
//   {
//     volume: 1.0, // number between 0.0 ~ 1.0
//     throttleMs: 100
//   })
  
// const newOrderNotificationSound = new UIFx(
//   newOrderAudio,
//   {
//     volume: 1.0, // number between 0.0 ~ 1.0
//     throttleMs: 100
//   })

// const chatNotificationAudio = new UIFx(
//   chatAudio,
//   {
//     volume: 1.0, // number between 0.0 ~ 1.0
//     throttleMs: 100
//   })

export default connect(store => ({
  tokenValsData: store.newMerchantLanding.tokenValsData,
  tokenValsLoading: store.newMerchantLanding.tokenValsLoading,

  merchantOrdersData: store.newMerchantLanding.merchantOrdersData,

  merchantDeliveryDetailsLoading: store.newMerchantLanding.merchantDeliveryDetailsLoading,
  merchantDeliveryDetailsData: store.newMerchantLanding.merchantDeliveryDetailsData,
  merchantDeliveryDetailsError: store.newMerchantLanding.merchantDeliveryDetailsError,

  newCount: store.notifications.newCount,
  dealsCount: store.bookDeal.dealsCount,

  togglingMagicPay: store.magicpaySubscription.togglingMagicPay,
  toggledMagicPay: store.magicpaySubscription.toggledMagicPay,
  toggleError: store.magicpaySubscription.error,

  walletBalance: store.walletSummary.walletBalance,
  loadingBalance: store.walletSummary.loadingBalance,
  loadedBalance: store.walletSummary.loadedBalance,

  showMagicpayModalDirect: store.magicpaySubscription.showMagicpayModalDirect,

  groupBuyPlans: store.groupBuy.groupBuyPlans,
  groupBuyPlansLoading: store.groupBuy.loadingPlans,
  groupBuyPlansLoaded: store.groupBuy.loadedPlans,
  showLaunchGroupBuy: store.groupBuy.showLaunchGroupBuy,

  showFixedCashinPopup: store.fixedCashin.showFixedCashinPopup,

  showConfirm: store.confirm.showConfirm,

  showNYModal : store.createVoucher.nyVoucherModalVisible,

  showGroceryAndPharmaModal: store.createVoucher.groceryPharmaModalVisible,

  members: store.myteam.members,
  membersLoaded: store.myteam.membersLoaded,

  doesCatalogExistData: store.newMerchantLanding.doesCatalogExistData,
  doesCatalogExistLoading: store.newMerchantLanding.doesCatalogExistLoading,

  merchantActivationStatusData: store.merchantActivation.merchantActivationStatusData,
  merchantActivationStatusLoading: store.merchantActivation.merchantActivationStatusLoading,
  merchantActivationStatusLoaded: store.merchantActivationStatusLoaded,
  merchantActivationStatusError: store.merchantActivation.merchantActivationStatusError,
  feedShortcutsStatusLoading: store.feedShortcutsStatus.feedShortcutsStatusLoading,
  feedShortcutsStatusLoaded: store.feedShortcutsStatus.feedShortcutsStatusLoaded,
  feedShortcutsStatusData: store.feedShortcutsStatus.feedShortcutsStatusData,

  showOnsurityData: store.newMerchantLanding.showOnsurityData,
  showOnsurityDataLoading: store.newMerchantLanding.showOnsurityDataLoading,

  modalVisible: store.merchantActivation.modalVisible,
  upgradePackageData : store.upgradePackage.upgradePackageData,
  creditAmount: store.upgradePackage.creditAmount,
  suitablePackage: store.upgradePackage.suitablePackage,
  isTenurePackage: store.upgradePackage.isTenurePackage,
  roiAmtOnAppliedPenalty: store.upgradePackage.roiAmtOnAppliedPenalty,
  isMerchantFraud: store.upgradePackage.isMerchantFraud,
  upsellDataloaded: store.upgradePackage.loaded,
  summary: store.businessSummary.data,

  merchantCrmData: store.merchantCrm.all_crm_data,

  magicpayStatusData: store.magicpaySubscription.magicpayStatus,
  gstModalCount: store.businessSummary.gstModalCount,
  smsPopup: store.smsCampaign.popup,

  arModalVisible: store.getArData.arModalVisible,

  bppDetails: store.brandPrivilegeProgram.bppDetails,
  subscriptionData: store.merchantOnboarding.data,

  goalTrackerHistoryData: store.goalTracker.goalTrackerHistoryData,
  registeringUpi: store.goalTracker.registeringUpi,

  visibleMagicOrderModal: store.createVoucher.visibleMagicOrderModal,
  isIntrestedLoading: store.businessSummary.isIntrestedLoading,
  isIntrestedInMagicOrder: store.businessSummary.isIntrestedInMagicOrder,

  accountStatus: store.businessSummary.accountStatus,

  chatHistoryLoading: store.newMerchantLanding.chatHistoryLoading,
  chatHistoryDataLoading: store.newMerchantLanding.chatHistoryDataLoading,
  chatHistoryData: store.newMerchantLanding.chatHistoryData,
  saltSyncRedisCheckData: store.newMerchantLanding.saltSyncRedisCheckData,
  webSocket: store.newMerchantLanding.webSocket,
  alias: store.newMerchantLanding.alias,
  bppDetailsLoaded: store.brandPrivilegeProgram.bppDetailsLoaded,

  showAppDownloadPopupRes: store.newMerchantLanding.showAppDownloadPopupRes,
  shareOutSnackbarRes: store.newMerchantLanding.shareOutSnackbarRes,

  previousWithdrawals: store.walletSummary.previousWithdrawals,

  didCreateVouchersData: store.newMerchantLanding.didCreateVouchersData,
  isBppActive: store.auth.isBppActive,

  showLocationSettingGuide: store.auth.showLocationSettingGuide,
  showSecurityAlert: store.auth.showSecurityAlert,

  requestingLedger: store.walletSummary.requestingLedger,
  requestedLedger: store.walletSummary.requestedLedger,
  requestLedgerError: store.walletSummary.requestLedgerError,
  ledgerUrlToDownload: store.walletSummary.ledgerUrlToDownload,
  requestingLedgerDownload: store.walletSummary.requestingLedgerDownload,
  ledgerStartDate: store.walletSummary.ledgerStartDate,
  ledgerEndDate: store.walletSummary.ledgerEndDate,

  
}), dispatch => bindActionCreators({
  accessTokenDecryptor,
  loadChatHistoryRequest,
  loadChatHistoryResponse,
  openNewWebSocket,
  getMerchantOrders,
  getMerchantDeliveryDetails,
  getWalletBalance,
  getNotificationCount,
  getDealsCount,
  getMembers,
  getMerchantRatingsAndVisits,
  onsuritySnackbarLocalityCheck,
  // sendSnackbarEmail,
  getMagicpaySubscription,
  toggleMagicPay,
  getGroupBuyPlans,
  showMagicpayModalFeedShortcutClick,
  getFeedShortcutsStatus,
  getMerchantActivationStatus,
  showActivationModal,
  hideActivationModal,
  getUpgradePackage,
  launchGroupBuyModal,
  showNYVoucherModal,
  hideNYVoucherModal,
  showGroceryPharmaModal,
  hideGroceryPharmaModal,
  loadBusinessSummary,
  checkIfCatalogExists,
  toggleSmsPopup,
  getDeliveryConfiguratorData,
  toggleArModal,
  verifyGstAfterActivation,
  getGstModalCount,
  loadBppDetails,
  didMerchantCreateNYVouchers,
  createNYVoucherRequest,
  getGoalTrackerHistory,
  showMagicOrderModal,
  hideMagicOrderModal,
  merchantShowsIntrestMagicOrder,
  getAccountStatus,
  getOrderHereAlias,  
  getWhatsappImage,
  whatsappProducer,
  showAppDownloadPopup,
  getMarketingRedemptionStatus,
  isAppInstalled,
  getWithdrawalStatus,
  getAllCrmData,
  getBppActive,
  saltSyncRedisCheck,
  logout,
  launchFixedCashinModal,
  setUpiId,
  paymentCommercialsUpsert,
  writeInteractivityEvent,
  checkAdminSession,
  setShowLocationSettingGuide,
  checkMerchantSession,
  setShowSecurityAlert,
  getMerchantWalletTransactionAsync,
  stopMerchantWalletTransactionAsync
}, dispatch))
(class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      showAppDownloadPopup: false,
      showGroupBuyModal: false,
      showShareStoreDialog: false,
      showVoucherRelisting: false,
      showHelpDeskWidget: false,
      minimizeHelpDesk: false,
      showPwdModal: false,
      showGstModal: false,
      pendingCount: 0,
      showAutoplayConfirmation: false,
      queuedBuzzer: null,
      permissionsMessage: "Magicpin wants to play order notification sound",
      paymentDoneCount: 0,
      trackingInfoAvailableCount: 0,
      codOrdersCount:0,
      hideSnackBarRelistOnVoucher: false,
      BPP_0 : false,
      requestChromeExtensionInstallation: false,
      showOrdersTab: null,
      fcWidgetHideSaas : ['/vouchers', '/dashboard', '/feed'],
      fcWidgetHide : [ '/marketing', '/qrCode', '/orders'],
      adminSessionPaths: ['/orders', '/dashboard', '/wallet', '/profile'],
      setUpi: false,
      showPineLabConsent: true,
      showLocationSettingGuide: false,
      showSecurityAlert: false
    }
    this.intervalId = 0;
    this.dealsIntervalId = 0;
    this.toastDisplay = false;
    this.updateOrderMessage = null;
    this.params = new URLSearchParams(this.props.location.search);
    this.newOrderNotificationTriggered = false;
    this.helpDeskTimer = null;
    // this.url= `wss://chat.magicpin.com/orders?auth-userid=6168691&auth-user-token=zpALnoWoY4Pa9tIv`
    this.url= `wss://chat.magicpin.com/orders?auth-userid=${this.props.user.userId}&auth-user-token=${(this.props.tokenValsData && this.props.tokenValsData.salt) ? this.props.tokenValsData.salt : !!saltFromAuth ? saltFromAuth : this.props.user.salt}`
    // chat.magicpin.com/orders?auth-userid=6168691&auth-user-token=zpALnoWoY4Pa9tIv
    this.ws= new WebSocket(this.url)
    this.holdMutex = false;
    this.acquireLock = false;
    this.orderIdForEvent = null;
    this.notificationShown = false;
    this.lastChatNotificationId = null;
    this.lastNewOrderNotificationId = null;
    this.lastPaymentDoneNotificationId = null;
    this.lastAgentAssignedNotificationId = null;
    this.lastRepeatBuzzerNotificationId = null;
    this.chatHistoryThreads = null;
    this.props.openNewWebSocket(this.ws)
    this.arrayOfToasts = [];
    this.lastRepeatBuzzerPlayedTime = null;
    this.orderIdListForNewOrderBuzzer = null;
    this.orderIdListForUpdateOrderBuzzer = null;
    this.orderIdListForChatBuzzer = null;
    this.pingTestTimeStart = null;
    this.isChromeExtensionInstalled = null;
    this.wasLastAudioPlayed = null;
    this.retryCounter = ledgerDownloadRetryObj.retryCounter
  }

  navigate = (nav) => {
    this.setState({open: false});
    this.props.push(nav);
    ga('send', 'event', 'Navigation', 'Navigation', `${nav}`);
  }

  merchantSessionLogger = async () => {
    if(this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() === "ADMIN") return;
    const deviceDetails = await getDeviceDetails();
    const loginMedium = getLoginMedium();
    const eventDeviceDetails = {...deviceDetails, loginMedium}
    this.sendBqEvent('merchant_session_logger', null, null, null, eventDeviceDetails);
    this.props.checkMerchantSession(this.props.user.accessToken, deviceDetails)
    .catch(err => {
      if(err && err.status && err.status == 401) {
        if(this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() !== "ADMIN") {
          this.sendBqEvent('merchant_session_expired', null, null, null, eventDeviceDetails);
          this.props.logout(this.props.user.accessToken)
        }
      }
    })
  }

  adminSessionLogger = async () => {
    try {
      if(!window.localStorage.getItem("jgdyjsgq261") && this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() === "ADMIN"){
        window.localStorage.setItem('jgdyjsgq261', "jgdyjsgq261");
        this.props.logout(this.props.user.accessToken)
      }
    } catch (e) {}

    const deviceDetails = await getDeviceDetails();
    const adminEmail = getEmailId();
    if(this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() !== "ADMIN") return;
    this.props.checkAdminSession(adminEmail, deviceDetails)
    .catch(err => {
      if(err && err.status && err.status == 401) {
        if(this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() === "ADMIN") {
          window.localStorage.setItem('jgdyjsgq261', "jgdyjsgq261");
          this.props.logout(this.props.user.accessToken)
        }
      }
    })
  }

  calculateGmvAmount =()=>{
    let gmvAmount = this.props.summary && this.props.summary.BUSINESS_SUMMARY.total_computed_gmv;
    gmvAmount = Math.round(gmvAmount)
    if(gmvAmount<=50000 && showPopupAfter2days('groupbuy_modal')){
      if(!this.props.groupBuyPlans){
        this.props.getGroupBuyPlans(this.props.user.accessToken).then(()=>{
          this.props.launchGroupBuyModal(true)})
      } else {
        this.props.launchGroupBuyModal(true)
      }
    }
  }


addMessage = message =>
  this.setState(state => ({ messages: [message, ...state.messages] }))

  createWebSocket = () => {
    if(!this.ws || !(this.ws && this.ws.readyState == WebSocket.OPEN)) {
      this.ws = new WebSocket(this.url);
    }

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      let orderIdArr = (this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0)
           ?
           this.props.merchantOrdersData.filter(order => (parseInt(order.versionCode, 10) >= 720 && parseInt(order.versionCode, 10) < 5300)).map(order => order.orderId)
           :
           []
      this.props.loadChatHistoryRequest(this.ws.send(JSON.stringify({"command": "LOAD_THREADS", "data": orderIdArr})))
  
      // console.log('connected')
    }

    this.ws.onmessage = evt => {
      // on receiving a message, add it to the list of messages
      // const message = JSON.parse(evt.data)
      // this.props.chatHistoryData = JSON.parse(evt.data)
      this.props.loadChatHistoryResponse(JSON.parse(evt.data))


      // this.addMessage(message)
      // console.log('message send', message)
    }

    this.ws.onclose = (evt) => {
      // console.log("onClose called! -- from Header.js")
      // console.log("enableOrderBooking obj :: ", this.props.merchantDeliveryDetailsData)
      if(this._ismounted && this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant 
        && this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking) {
        // console.log("Creating new ws...........", this.props.merchantDeliveryDetailsData)
        this.createWebSocket()
      }
    }
  }

  playBuzzerMultipleTimes = () => {
    let buzzerRepeatCount = 0;
     let customDelayedLoop = (playNewOrderBuzzer) => {
      setTimeout(() => {
        playNewOrderBuzzer(null, true);
        if(this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0) {
          let codOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "COD")
          if(this.props.doesCatalogExistData && (this.props.doesCatalogExistData.catalog || this.props.doesCatalogExistData.orderFlow === "beyondfood")) {
            
              let paymentDoneOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "PAYMENT_DONE")
              if(paymentDoneOrders.length === 0 && codOrders.length === 0) {
                buzzerRepeatCount = BUZZER_REPEAT_LIMIT;
              }
          } else {
            let pendingOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "PENDING")
            if(pendingOrders.length === 0 && codOrders.length === 0) {
              buzzerRepeatCount = BUZZER_REPEAT_LIMIT;
            }
          }
        }
        if(buzzerRepeatCount < BUZZER_REPEAT_LIMIT) {
          buzzerRepeatCount++;
          customDelayedLoop(playNewOrderBuzzer);
        }
      }, 5000)
    }
    customDelayedLoop(this.playNewOrderBuzzer)
  }

  playNewOrderBuzzer = (noRepeat, noPopup) => {
    if(noPopup !== true && !(this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant 
      && this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking)) {
      this.props.getMerchantDeliveryDetails(this.props.user.accessToken, this.props.user.merchantId, this.props.user.userId)
      .then(res => {
        this.sendBqEvent("order_getmerchantdeliverydetails_call_success", null, res.length > 0 ? res[0] : res)
      })
      .catch(err => {
        this.sendBqEvent("order_getmerchantdeliverydetails_call_failed", null, err)
      })
    }
    let audioElement = document.getElementsByClassName('new-order-notification-audio-file')[0];
    // console.log("Buzzer order_ids NEW ORDERS: ", this.orderIdListForNewOrderBuzzer);
    audioElement && audioElement.play().then(res => {
      this.wasLastAudioPlayed = true;
      window.localStorage.setItem('timeStampOfLastBuzzerPlay', Date.now());
    }).catch(err => {
      this.sendBqEvent("order_new_order_buzzer_blocked_by_browser", this.orderIdListForNewOrderBuzzer);
      //toDo:// chrome extension work
      /**
       * Check if Chrome and if desktop
       * If Chrome on desktop, check if extension is installed.
       * If extension is installed, play sound via the extension. Else, show pop-up to download extension
       */
      if(isChrome() && window.innerWidth > 768) {
        this.sendBqEvent("order_buzzer_blocked_chromedesktop", this.orderIdListForNewOrderBuzzer);
        getVersion()
        .then(res => {
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_passed", this.orderIdListForNewOrderBuzzer, res);
          // sendMessage to Chrome extension to play buzzer
          sendMessage("NEW_ORDER").then((res) => {
            // console.log("extension resolve",res)
            window.localStorage.setItem('timeStampOfLastBuzzerPlay', Date.now());
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_success", this.orderIdListForNewOrderBuzzer, res);
          }).catch((e) => {
            // console.log("extension reject",e.toString())
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_failure", this.orderIdListForNewOrderBuzzer, e);
            // this.setState({showAutoplayConfirmation: true, queuedBuzzer: 'NEW_ORDER', permissionsMessage: 'You have received a new order!'})
            // alert(e)
          })
          if(this.state.requestChromeExtensionInstallation) {
            this.setState({ requestChromeExtensionInstallation: false })
          }
        })
        .catch(err => {
          this.isChromeExtensionInstalled = false;
          this.wasLastAudioPlayed = false;
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_failed", this.orderIdListForNewOrderBuzzer, err);
          // Show "Download extension popup"
          if(noPopup !== true) {
            this.setState({showAutoplayConfirmation: true, requestChromeExtensionInstallation: true })
          }
        })
        
      } else {
        /**
         * Not chrome. Show the default pop-up and try playing the buzzer again after user interacts (via btn click)
         * This will not work if the browser fails to register user's click as an interaction.
         */
        this.isChromeExtensionInstalled = false;
        this.wasLastAudioPlayed = false;
        // INTERACTION POP_UP COMMENTED OUT
        // if(noRepeat !== true) {
        //   this.setState({showAutoplayConfirmation: true, queuedBuzzer: 'NEW_ORDER', permissionsMessage: 'You have received a new order!'})
        // }
      }
    });
  }

  playUpdateOrderBuzzer = (noRepeat) => {
    let audioElement = document.getElementsByClassName('update-order-notification-audio-file')[0];
    // console.log("Buzzer order_ids UPDATE ORDERS: ", this.orderIdListForUpdateOrderBuzzer);
    audioElement.play().then().catch(err => {
      this.sendBqEvent("order_update_order_buzzer_blocked_by_browser", this.orderIdListForUpdateOrderBuzzer);
      //toDo:// chrome extension work
      /**
       * Check if Chrome and if desktop
       * If Chrome on desktop, check if extension is installed.
       * If extension is installed, play sound via the extension. Else, show pop-up to download extension
       */

      if(isChrome() && window.innerWidth > 768) {
        this.sendBqEvent("order_buzzer_blocked_chromedesktop", this.orderIdListForUpdateOrderBuzzer);
        getVersion()
        .then(res => {
          // console.log("GET_VERSION_RESULT_DUMP: ", res)
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_passed", this.orderIdListForUpdateOrderBuzzer, res);
          // sendMessage to Chrome extension to play buzzer
          sendMessage("NOTIFICATION").then((res) => {
            // console.log("extension resolve",res)
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_success", this.orderIdListForUpdateOrderBuzzer, res);
          }).catch((e) => {
            // console.log("extension reject",e.toString())
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_failure", this.orderIdListForUpdateOrderBuzzer, e);
            // this.setState({showAutoplayConfirmation: true, queuedBuzzer: this.state.queuedBuzzer === 'NEW_ORDER'? 'NEW_ORDER' : 'UPDATE_ORDER', permissionsMessage: this.state.queuedBuzzer === 'NEW_ORDER'? 'You have received a new order!' : this.updateOrderMessage === "AGENT_ASSIGNED" ? 'Delivery agent has been assigned' : 'Payment received for an order'})
            // alert(e)
          })
          if(this.state.requestChromeExtensionInstallation) {
            this.setState({ requestChromeExtensionInstallation: false })
          }
        })
        .catch(err => {
          // console.log("GET_VERSION_ERROR_DUMP: ", err)
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_failed", this.orderIdListForUpdateOrderBuzzer, err);
          // Show "Download extension popup"
          this.setState({showAutoplayConfirmation: true, requestChromeExtensionInstallation: true })
        })
        
      } else {
        /**
         * Not chrome. Show the default pop-up and try playing the buzzer again after user interacts (via btn click)
         * This will not work if the browser fails to register user's click as an interaction.
         */
        // INTERACTION POP_UP COMMENTED OUT
        // if(noRepeat !== true) {
        //   this.setState({showAutoplayConfirmation: true, queuedBuzzer: this.state.queuedBuzzer === 'NEW_ORDER'? 'NEW_ORDER' : 'UPDATE_ORDER', permissionsMessage: this.state.queuedBuzzer === 'NEW_ORDER'? 'You have received a new order!' : this.updateOrderMessage === "AGENT_ASSIGNED" ? 'Delivery agent has been assigned' : 'Payment received for an order'})
        // }
      }
    });
  }

  playChatNotificationBuzzer = (noRepeat) => {
    let audioElement = document.getElementsByClassName('chat-notification-audio-file')[0];
    // console.log("Buzzer order_ids Chat: ", this.orderIdListForChatBuzzer);
    audioElement.play().then().catch(err => {
      this.sendBqEvent("order_chat_buzzer_blocked_by_browser", this.orderIdListForChatBuzzer);
      //toDo:// chrome extension work

      /**
       * Check if Chrome and if desktop
       * If Chrome on desktop, check if extension is installed.
       * If extension is installed, play sound via the extension. Else, show pop-up to download extension
       */

      if(isChrome() && window.innerWidth > 768) {
        this.sendBqEvent("order_buzzer_blocked_chromedesktop", this.orderIdListForChatBuzzer);
        getVersion()
        .then(res => {
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_passed", this.orderIdListForChatBuzzer, res);
          // sendMessage to Chrome extension to play buzzer
          sendMessage("CHAT_NOTIFICATION").then((res) => {
            // console.log("extension resolve",res)
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_success", this.orderIdListForChatBuzzer, res);
          }).catch((e) => {
            // console.log("extension reject",e.toString())
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_failure", this.orderIdListForChatBuzzer, e);
            // this.setState({showAutoplayConfirmation: true, queuedBuzzer: this.state.queuedBuzzer === 'NEW_ORDER'? 'NEW_ORDER' : 'CHAT', permissionsMessage: this.state.queuedBuzzer === 'NEW_ORDER'? 'You have received a new order!' : 'You have received a new chat message from a customer' })
            // alert(e)
          })
          if(this.state.requestChromeExtensionInstallation) {
            this.setState({ requestChromeExtensionInstallation: false })
          }
        })
        .catch(err => {
          // Show "Download extension popup"
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_failed", this.orderIdListForChatBuzzer, err);
          this.setState({showAutoplayConfirmation: true, requestChromeExtensionInstallation: true })
        })
        
      } else {
        /**
         * Not chrome. Show the default pop-up and try playing the buzzer again after user interacts (via btn click)
         * This will not work if the browser fails to register user's click as an interaction.
         */
        // INTERACTION POP_UP COMMENTED OUT
        // if(noRepeat !== true) {
        //   this.setState({showAutoplayConfirmation: true, queuedBuzzer: this.state.queuedBuzzer === 'NEW_ORDER'? 'NEW_ORDER' : 'CHAT', permissionsMessage: this.state.queuedBuzzer === 'NEW_ORDER'? 'You have received a new order!' : 'You have received a new chat message from a customer' })
        // }
      }
    });
  }

  playRepeatBuzzer = () => {
    let audioElement = document.getElementsByClassName('new-order-notification-audio-file')[0];
    this.lastRepeatBuzzerPlayedTime = moment().add(1,'m').format('YYYY-MM-DD HH:mm:ss')
    audioElement.play().then().catch(err => {
      this.sendBqEvent("order_repeat_buzzer_blocked_by_browser", this.lastRepeatBuzzerNotificationId);
      //toDo:// chrome extension work
      /**
       * Check if Chrome and if desktop
       * If Chrome on desktop, check if extension is installed.
       * If extension is installed, play sound via the extension. Else, show pop-up to download extension
       */

      if(isChrome() && window.innerWidth > 768) {
        this.sendBqEvent("order_buzzer_blocked_chromedesktop", this.lastRepeatBuzzerNotificationId);
        getVersion()
        .then(res => {
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_passed", this.lastRepeatBuzzerNotificationId, res);
          // sendMessage to Chrome extension to play buzzer
          sendMessage("REPEAT_BUZZER").then((res) => {
            // console.log("extension resolve",res)
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_success", this.lastRepeatBuzzerNotificationId, res);
          }).catch((e) => {
            // console.log("extension reject",e.toString())
            this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_audio_playback_failure", this.lastRepeatBuzzerNotificationId, e);
            // this.setState({showAutoplayConfirmation: true, queuedBuzzer: 'NEW_ORDER', permissionsMessage: 'You have received a new order!'})
            // alert(e)
          })
          if(this.state.requestChromeExtensionInstallation) {
            this.setState({ requestChromeExtensionInstallation: false })
          }
        })
        .catch(err => {
          // Show "Download extension popup"
          this.sendBqEvent("order_buzzer_blocked_chromedesktop_extension_getversion_failed", this.lastRepeatBuzzerNotificationId, err);
          this.setState({showAutoplayConfirmation: true, requestChromeExtensionInstallation: true })
        })
        
      } else {
        /**
         * Not chrome. Show the default pop-up and try playing the buzzer again after user interacts (via btn click)
         * This will not work if the browser fails to register user's click as an interaction.
         */
        // INTERACTION POP_UP COMMENTED OUT
        // this.setState({showAutoplayConfirmation: true, queuedBuzzer: 'NEW_ORDER', permissionsMessage: 'You have received a new order!'})
      }
    });
  }

  shouldRepeatBuzzer = () => {    
    if(this.lastRepeatBuzzerPlayedTime === null) {
      return true
    } else {
      return moment().format('YYYY-MM-DD HH:mm:ss') > this.lastRepeatBuzzerPlayedTime
    }
  }

  closeAutoplayPermissionPopup = () => {
    this.setState({showAutoplayConfirmation: false})
    // window.location.reload();
    this.sendBqEvent("order_buzzer_autoplay_permission_popup_action")
    if(this.state.queuedBuzzer) {
      switch(this.state.queuedBuzzer) {
        case 'NEW_ORDER':
          this.setState({ queuedBuzzer: null })
          this.playNewOrderBuzzer(true);
          break;
        case 'UPDATE_ORDER':
          this.setState({ queuedBuzzer: null })
          this.playUpdateOrderBuzzer(true);
          break;
        case 'CHAT':
          this.setState({ queuedBuzzer: null })
          this.playChatNotificationBuzzer(true);
          break;
      }
    }
    // let audioElement = document.getElementsByClassName('update-order-notification-audio-file')[0];
    // audioElement.play().then().catch(err => this.setState({showAutoplayConfirmation: true}));
  }

  sendBqEvent = (eventName, orderId, responseDump, latency="", deviceDetails=null) => {
    deviceDetails = deviceDetails && JSON.stringify(deviceDetails)
    const merchantId=this.props.user.merchantId
    const userType= this.props.user.userType === userTypes.ADMIN ? "ADMIN" : "MERCHANT"
    if(userType ==="ADMIN"){
        this.emailId = window.localStorage.getItem("magicpinId")
    }
    else{
        this.emailId= ""
    }
    let browserDetailsObject = getBrowserInfo()
    const browserName = browserDetailsObject.browser_name ? browserDetailsObject.browser_name : "";
    // window.ga('send', 'event', eventName, eventType, pageName);
    sendEvent({
        event: eventName,
        actor_id: merchantId,
        actor_type: userType,
        subject_merchant_id: merchantId,
        item_id: orderId,
        responseDump: JSON.stringify(responseDump),
        browser_name: browserName,
        latency: latency,
        page_name: this.props.location && this.props.location.pathname && this.props.location.pathname,
        user_type: userType,
        merchant_email: this.emailId,
        datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        timestamp: Date.now(),
        // itemNameAndId: itemNameAndId ? itemNameAndId : "", //
        device: window.innerWidth > 768 ? 'desktop' :'mobile',
        deviceDetails
        // itemPrice: itemPrice || 0, //
        // suggestedItemPrice: suggestedItemPrice //
      });
  }

  componentDidMount(){
    this.merchantSessionLogger();
    if(this.props.location && this.state.adminSessionPaths.includes(this.props.location.pathname)) this.adminSessionLogger();

    // console.log("isChrome: ", isChrome())
    // alert(`isChrome:  ${isChrome()}`)
    if(this.props.user) {
      if(this.props.user.userType === "MERCHANT") {
        this.props.accessTokenDecryptor(this.props.user.accessToken);
      }
      this.props.getBppActive(this.props.user.accessToken);
      this.props.getDeliveryConfiguratorData(this.props.user.accessToken, this.props.user.userId, this.props.user.userType)
      this.props.getMarketingRedemptionStatus(this.props.user.accessToken);
      this.props.isAppInstalled(this.props.user.accessToken, this.props.user.merchantId)
      // this.props.didMerchantCreateNYVouchers(this.props.user.accessToken, this.props.user.merchantId, this.props.user.userId, this.props.user.salt)
      this.props.onsuritySnackbarLocalityCheck(this.props.user.localityId, this.props.user.merchantId)
    }
    if(this.props.user.defaultPwd == true){
      sendEvent({
        event: "detected_defaultpwd_and_loggedout",
         actor_id: this.props.user.merchantId,
         actor_type: this.props.user.userType,
         subject_merchant_id: this.props.user.merchantId,
         user_type: this.props.user.userType,
         datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
         timestamp: Date.now(),
         device: window.innerWidth > 768 ? 'desktop' :'mobile',
      });
      toast.warn("You haven't changed your default password. Please change it now")
      this.props.logout(this.props.user.accessToken).then(res=>{
        this.props.push('/forgotPassword')
      })
    }
    if(window.innerWidth <= 768 && this.props.user.userType != userTypes.ADMIN){
      this.props.showAppDownloadPopup(this.props.user.accessToken)
    }
    this._ismounted = true;
    this.props.getMerchantRatingsAndVisits(this.props.user.accessToken, this.props.user.userId)
    this.createWebSocket()
    this.props.checkIfCatalogExists(this.props.user.accessToken, this.props.user.userId)
    this.props.getOrderHereAlias(this.props.user.accessToken)
    if (!this.props.accountStatus) this.props.getAccountStatus(this.props.user.accessToken);
    // this calls the getNotificationCount function every 2 minute
    document.body.classList.add('top-header-bar-visible')
    // getMerchantDeliveryDetails
    if(!this.props.merchantDeliveryDetailsData) {
      this.props.getMerchantDeliveryDetails(this.props.user.accessToken, this.props.user.merchantId, this.props.user.userId)
      .then(res => {
        this.sendBqEvent("order_getmerchantdeliverydetails_call_success", null, res.length > 0 ? res[0] : res)
      })
      .catch(err => {
        this.sendBqEvent("order_getmerchantdeliverydetails_call_failed", null, err)
      })
    }
    
// Trigger order notifications once from here.
// Wss will take care of the other cases
    // FIRST_CALL acquireLock
    this.acquireLock = true;
    this.pingTestTimeStart = Date.now();
    let extraFieldsForBT = "";
    if(this.isChromeExtensionInstalled === true || this.isChromeExtensionInstalled === false) {
      extraFieldsForBT += `isChromeExtensionInstalled:${this.isChromeExtensionInstalled}||delim||`;
    }
    if(this.wasLastAudioPlayed === true || this.wasLastAudioPlayed === false) {
      extraFieldsForBT += `wasLastAudioPlayed:${this.wasLastAudioPlayed}||delim||`;
    }
    if(timeStampOfLastBuzzerPlay) {
      extraFieldsForBT += `timeStampOfLastBuzzerPlay:${timeStampOfLastBuzzerPlay}||delim||`;
    }
    // if (lastViewOrdersCallCheck()) {
    this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
    .then(res => {
      // LOG PING TO BQ
      let latency = Date.now() - this.pingTestTimeStart;
      this.sendBqEvent("order_everyminute_ping_test_vieworder", null, null, latency)
      if(this.props.user && this.props.user.userType === "MERCHANT" 
        && this.props.doesCatalogExistData && this.props.doesCatalogExistData.thirdPartyIntegrated === false) {
        this.props.writeInteractivityEvent(this.props.user.merchantId, deviceBrowserId, deviceType, signInTimeStamp, extraFieldsForBT)
      }
      this.pingTestTimeStart = null;
      // Notification - V1 | ViewOrders repeater
       if(res && res.length>0 && res[0].ordersDetail !== null) {
        this.homeDeliveryIntervalId = setInterval(
          ()=> {
            if (lastViewOrdersCallCheck()) {
// FIRST_CALL releaseLock
            this.acquireLock = false;
            this.pingTestTimeStart = Date.now();
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
            .then(() => {
              // LOG PING TO BQ
              let extraFieldsForBT = "";
              if(this.isChromeExtensionInstalled === true || this.isChromeExtensionInstalled === false) {
                extraFieldsForBT += `isChromeExtensionInstalled:${this.isChromeExtensionInstalled}||delim||`;
              }
              if(this.wasLastAudioPlayed === true || this.wasLastAudioPlayed === false) {
                extraFieldsForBT += `wasLastAudioPlayed:${this.wasLastAudioPlayed}||delim||`;
              }
              if(timeStampOfLastBuzzerPlay) {
                extraFieldsForBT += `timeStampOfLastBuzzerPlay:${timeStampOfLastBuzzerPlay}||delim||`;
              }
              let latency = Date.now() - this.pingTestTimeStart;
              this.sendBqEvent("order_everyminute_ping_test_vieworder", null, null, latency)
              if(this.props.user && this.props.user.userType === "MERCHANT" 
                && this.props.doesCatalogExistData && this.props.doesCatalogExistData.thirdPartyIntegrated === false) {
                this.props.writeInteractivityEvent(this.props.user.merchantId, deviceBrowserId, deviceType, signInTimeStamp, extraFieldsForBT)
              }
              this.pingTestTimeStart = null;
            })
            .catch(err => {
              if(err && err.status && err.status == 401) {
                this.sendBqEvent("order_vieworders_401")
                if(this.props.user && this.props.user.userType === "MERCHANT") {
                  this.props.logout(this.props.user.accessToken)
                }
              }
            })
            }   
          },60*1000);
      } else {
        this.homeDeliveryIntervalIdInactiveMerchant = setInterval(
          ()=> {
            if (lastViewOrdersCallCheck()) {
              // FIRST_CALL releaseLock
            this.acquireLock = false;
            this.pingTestTimeStart = Date.now();
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
            .then(() => {
              // LOG PING TO BQ
              let extraFieldsForBT = "";
              if(this.isChromeExtensionInstalled === true || this.isChromeExtensionInstalled === false) {
                extraFieldsForBT += `isChromeExtensionInstalled:${this.isChromeExtensionInstalled}||delim||`;
              }
              if(this.wasLastAudioPlayed === true || this.wasLastAudioPlayed === false) {
                extraFieldsForBT += `wasLastAudioPlayed:${this.wasLastAudioPlayed}||delim||`;
              }
              if(timeStampOfLastBuzzerPlay) {
                extraFieldsForBT += `timeStampOfLastBuzzerPlay:${timeStampOfLastBuzzerPlay}||delim||`;
              }
              let latency = Date.now() - this.pingTestTimeStart;
              this.sendBqEvent("order_everyminute_ping_test_vieworder", null, null, latency)
              if(this.props.user && this.props.user.userType === "MERCHANT" 
                && this.props.doesCatalogExistData && this.props.doesCatalogExistData.thirdPartyIntegrated === false) {
                this.props.writeInteractivityEvent(this.props.user.merchantId, deviceBrowserId, deviceType, signInTimeStamp, extraFieldsForBT)
              }
              this.pingTestTimeStart = null;
            })
            .catch(err => {
              if(err && err.status && err.status == 401) {
                this.sendBqEvent("order_vieworders_401")
                if(this.props.user && this.props.user.userType === "MERCHANT") {
                  this.props.logout(this.props.user.accessToken)
                }
              }
            })
            }
          }, 10*60*1000);
      }
    })
    .catch(err => {
      this.pingTestTimeStart = null;
      if(err && err.status && err.status == 401) {
        this.sendBqEvent("order_vieworders_401")
        if(this.props.user && this.props.user.userType === "MERCHANT") {
          this.props.logout(this.props.user.accessToken)
        }
      }
    })
    // }
    
    if(this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant) {
      if(!this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking) {
        if(this.ws && this.ws.readyState != WebSocket.CLOSED) {
          // console.log("WS Closed - Headers.js - enableOrderBooking = false")
          // console.log("SUSPECT #1")
          this.ws.close()
        }
      }
    }
    if(!this.props.walletBalance)
        this.props.getWalletBalance(this.props.user.accessToken)
    
    if(!this.props.upgradePackageData && this.props.user.merchantId )
        this.props.getUpgradePackage({merchantId: this.props.user.merchantId})

    if(!this.props.groupBuyPlans){
      if(this.params.has('action') && this.params.get('action').toLowerCase() === 'launch_groupbuy'){
        this.props.getGroupBuyPlans(this.props.user.accessToken).then(()=>{
          this.props.launchGroupBuyModal(true)
        })
      }
    }
    if(!this.props.allCrmData)
        // this.props.getAllCrmData(this.props.user.salt, this.props.user.userId);
        this.props.getAllCrmData((this.props.tokenValsData && this.props.tokenValsData.salt) ? this.props.tokenValsData.salt : this.props.user.salt, this.props.user.userId)

    if (!this.props.goalTrackerHistoryData)
    this.props.getGoalTrackerHistory(this.props.user.accessToken);
    
    this.props.user && this.props.getGstModalCount(this.props.user.accessToken,this.props.user.userType);
    this.props.user && this.props.merchantShowsIntrestMagicOrder(this.props.user.accessToken,this.props.user.merchantId)

    this.props.loadBppDetails(this.props.user.accessToken)   

    if(!this.props.magicpayStatusData) this.props.getMagicpaySubscription(this.props.user.accessToken);

    this.props.getNotificationCount(this.props.user.accessToken);
    this.intervalId = setInterval(
      ()=>(this.props.getNotificationCount(this.props.user.accessToken)),
      1000*60*2);
    // this.props.getDealsCount(this.props.user.accessToken);
    // this.dealsIntervalId = setInterval(
    //   ()=>(this.props.getDealsCount(this.props.user.accessToken)),
    //   1000*60*2);
    if(!this.props.members) this.props.getMembers(this.props.user.accessToken);
    loadScript('//translate.google.com/translate_a/element.js?cb=window.googleTranslateElementInit', null)
    if(!this.props.feedShortcutsStatusData){
      this.props.getFeedShortcutsStatus(this.props.user.accessToken)
  }

  if(!this.props.previousWithdrawals)
  {
    this.props.getWithdrawalStatus(this.props.user.accessToken)
  }

  if(this.props.location.pathname === '/vouchers')
  {
    this.setState({
      hideSnackBarRelistOnVoucher : true
    })
  }

  if(!!window.fcWidget) 
    {
      // window.fcWidget.open()
      window.fcWidget.destroy();
      const helpDesk = document.getElementById('custom_chat_button');
      helpDesk.style.visibility = "hidden";
      // helpDesk.addEventListener('click',() => this.showHelpDesk());
    }

  if(this.props.goalTrackerHistoryData 
     && this.props.goalTrackerHistoryData.length > 0
     && this.props.goalTrackerHistoryData[0].packageName === "BPP_0"
      )
  {
    this.setState({BPP_0: true})
  }

  if(!!window.fcWidget && ( this.state.fcWidgetHide.includes(this.props.location.pathname) 
      || (this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.feedShortcutsStatusData.commercialTypes.includes('SAAS') && this.state.fcWidgetHideSaas.includes(this.props.location.pathname)) ))
    { 
        // window.fcWidget.hide();
        window.fcWidget.destroy();
        document.getElementById('custom_chat_button').style.visibility = "hidden"
    }


    if(this.props.user && !this.props.user.isParentMerchant) {
      if(this.params.has('openMerchantActivation')) {
         this.props.getFeedShortcutsStatus(this.props.user.accessToken).then((res)=> 
              { 
                if(res && res.length > 0 && (!res[0].ownershipVerified || !res[0].documentUploaded))
                {         
                   this.props.getMerchantActivationStatus(this.props.user.accessToken).then((res)=>
                   this.props.showActivationModal()
                ).catch((err)=> toast.error("Try again later"))
                }
                else{
                  toast.info("Ownership already Verified")
                }
          }).catch((err)=>toast.error("Try again later"))
      }
  }
  if(!!window.fcWidget) 
  {
    // window.fcWidget.open()
    window.fcWidget.destroy();
    const helpDesk = document.getElementById('custom_chat_button');
    helpDesk.style.visibility = "hidden";
    // helpDesk.addEventListener('click',() => this.showHelpDesk());

  }

  if(this.params && this.params.has('showSetUpiModal'))
    this.setUpiFN()

  if (this.params && this.params.has('status') && this.params.get('status').toUpperCase() === 'FAILURE') {
    const msg = this.params.has('message') && this.params.get('message') ? this.params.get('message') : "Something went wrong, Please log out and login again."
    toast.error(msg);
  }

  }


  getComputedUpsellPackage(){

    const roiAmount = this.props.roiAmtOnAppliedPenalty || this.props.suitablePackage.roi_amount || 0;
    
    let paybleAmount = this.props.suitablePackage.amount;
    const duration =  this.props.suitablePackage.duration;
    
    if(this.props.creditAmount > 0){
      paybleAmount -= this.props.creditAmount;
    }

    if(this.props.walletBalance > 0 && paybleAmount > 0){
      paybleAmount = this.props.walletBalance < paybleAmount ? paybleAmount - this.props.walletBalance : 0
    }
    return ([roiAmount, paybleAmount, duration])
  }
  

  handleDisplayToast(){

    if(window.innerWidth > 768 ){
      let toastContent = null;
      let bppContent = null;
      let upsellContent = null;

      const toastFrequencyCheck = this.props.user.userType === userTypes.ADMIN ? (this.props.gstModalCount)%20 === 0 : (this.props.gstModalCount)%10 === 0;
      const bppToastFrequency = this.props.user.userType === userTypes.ADMIN ? (this.props.gstModalCount)%10 === 0 : (this.props.gstModalCount)%5 === 0;
      if((this.props.user.categoryId === 5 || this.props.user.categoryId === 91) && bppToastFrequency && this.props.bppDetails && this.props.bppDetails.isBppActive){
        bppContent = {
          toastText: <div>
                      <div style={{marginBottom:"7px",color:"white",fontSize:"13px",fontFamily:"RobotoBold",textAlign:"left"}} className="row">Earn Extra Rs. 5000 per month with Brand Privilege Program!</div>
                      <div style={{lineHeight:"14px",color:"white",fontSize:"12px",fontFamily:"RobotoRegular",textAlign:"left"}} className="row">Earn upto Rs. 5000 per month!</div> 
                    </div>,
          toastClass: 'toastStylingBpp',
          toastTextColor: '#ff455f',
          redirectPage: '/bpp',
          eventName: 'shown_bpp_toast'
        }
      }

      else if((this.props.user.categoryId === 5 || this.props.user.categoryId === 91) && bppToastFrequency && this.props.bppDetails  &&  !this.props.bppDetails.isBppActive){
        bppContent = {
          toastText: <div>
                      <div style={{marginBottom:"7px",color:"white",fontSize:"13px",fontFamily:"RobotoBold",textAlign:"left"}} className="row">It's time to earn more with BPP bill print rewards! <br/><strong>Restart printing now</strong> to earn upto Rs 5000/month!</div>
                      <div style={{lineHeight:"14px",color:"white",fontSize:"12px",fontFamily:"RobotoRegular",textAlign:"left"}} className="row"> Click to check your BPP reward status</div> 
                    </div>,
          toastClass: 'toastStylingBpp',
          toastTextColor: '#ff455f',
          redirectPage: '/bpp',
          eventName: 'shown_bpp_toast_inactive'
        }
      }

      if(this.props.upgradePackageData && this.props.upgradePackageData.isEligibleForUpsell && !this.props.isMerchantFraud && showPopup('upsell_toast')){
        const [roiAmount, packageAmount, duration] = this.getComputedUpsellPackage();
        upsellContent = {
          toastText: <div>
                      {
                        this.props.isTenurePackage ?
                          <div style={{marginBottom:"7px",color:"white",fontSize:"13px",fontFamily:"RobotoBold",textAlign:"left"}} className="row">Upgrade now with MAGIC ASSURED to get online marketing for <span style={{ fontSize: '14px' }}> {duration} months </span> at just <span>{ compactCurrencyString(packageAmount, this.props.user.country)}</span></div>
                        :
                          <div style={{marginBottom:"7px",color:"white",fontSize:"13px",fontFamily:"RobotoBold",textAlign:"left"}} className="row">Upgrade now with MAGIC ASSURED to get <span style={{ fontSize: '14px' }}> {compactCurrencyString(roiAmount, this.props.user.country)} </span> Guaranteed Sales for just <span>{ packageAmount > 0 ? compactCurrencyString(packageAmount, this.props.user.country) : 'Free*'  }</span></div>
                        
                      }
                    </div>,
          toastClass: 'toastStylingUpsell',
          toastTextColor: '#458eff',
          redirectPage: `/upgrade/${this.props.user.merchantId}?utm_source=merchantPortalfeedToast`,
          eventName: 'clicked_feed_upsell_toast'
        }
      }
      
      if(upsellContent && bppContent)
        toastContent = Math.random() < 0.5 ? upsellContent : bppContent;
      else 
        toastContent = upsellContent || bppContent;
      
      if(toastContent)
        this.toastDisplay = toast( <GroupBuyToast toastContent = {toastContent} user={this.props.user} push={this.props.push}/>,{
            autoClose : false,
            position:toast.POSITION.BOTTOM_RIGHT,
            className: toastContent.toastClass
            })
      }

  }

  handleDisplayOrderNotification(notificationType, noOfOrders){

    let toastContent = null;
    let newOrderContent = null;
    let paymentReceivedContent = null;
    if(notificationType == "PENDING") {
        newOrderContent = {
            toastText: <div>
                <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">You've received {noOfOrders} new order(s)</div>
                </div>,
                toastClass: 'new-order-notification-toast',
                toastTextColor: '#ff455f'
        }
    } else {
        paymentReceivedContent = {
            toastText: <div>
                {notificationType == "PAYMENT_DONE" && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">Payment received for {noOfOrders} order(s)</div>}
                {notificationType == "COD" && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">Cash On Delivery for {noOfOrders} order(s)</div>}
                {notificationType == "TRACKING_INFO_AVAILABLE" && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">{noOfOrders} delivery agent(s) arriving for pickup</div>}
                </div>,
                toastClass: 'payment-done-notification-toast',
                toastTextColor: '#ff455f'
        }
    }
      
      if(paymentReceivedContent && newOrderContent)
        toastContent = newOrderContent;
      else 
        toastContent = paymentReceivedContent || newOrderContent;
      if(this.arrayOfToasts && this.arrayOfToasts.length > 4) {
        let toastToBeDismissed = this.arrayOfToasts.shift();
         toast.dismiss(toastToBeDismissed)
      }
      if(toastContent)
        {
          this.toastDisplay = toast( <NotificationToast toastContent = {toastContent} user={this.props.user} push={this.props.push}/>,{
            autoClose : false,
            position:toast.POSITION.BOTTOM_RIGHT,
            className: toastContent.toastClass
            })
            this.arrayOfToasts.push(this.toastDisplay)
        }
  }

  handleDisplayChatNotification(notificationType, threadId, customerName, customerMessage, attachmentReceived, unreadMsgCountForNotifiedThread){

    // play chat audio here
    // chatNotificationAudio.play()
    this.orderIdListForChatBuzzer = threadId;
    this.playChatNotificationBuzzer()

    let toastContent = null;

    //newOrderContent => single chat content
    //paymentReceivedContent => multi chat content
    let shortenedCustomerMsg = customerMessage.length > 13 ? customerMessage.slice(0,10) + "..." : customerMessage
    let singleChat = null;
    let multiChat = null;
    if(notificationType == "SINGLE") {
        singleChat = {
            toastText: <div>
                {!attachmentReceived && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">{`${customerName} says ${shortenedCustomerMsg}`}</div>}
                {attachmentReceived && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left"}} className="row">{`${customerName} sent an image`}</div>}
                </div>,
                toastClass: 'order-chat-notification-toast',
                toastTextColor: '#ff455f'
        }
    } else {
        multiChat = {
           toastText: <div>
                {!attachmentReceived && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left", paddingLeft: '10%'}} className="row">{`You have ${unreadMsgCountForNotifiedThread} unread messages from ${customerName}`}</div>}
                {attachmentReceived && <div style={{display: 'flex', justifyContent: 'center',color:"white",fontSize: window.innerWidth <=768 ? "14px" : "16px",fontFamily:"RobotoMedium",textAlign:"left", paddingLeft: '10%'}} className="row">{`You have ${unreadMsgCountForNotifiedThread} unread messages from ${customerName}`}</div>}
                </div>,
                toastClass: 'order-chat-notification-toast',
                toastTextColor: '#ff455f'
        }
    }
      
      if(multiChat && singleChat)
        toastContent = multiChat;
      else 
        toastContent = multiChat || singleChat;
      
      if(toastContent)
        this.toastDisplay = toast( <NotificationToast toastContent = {toastContent} user={this.props.user} push={this.props.push} isChatNotification={true} threadId={threadId}/>,{
            autoClose : 5000,
            position:toast.POSITION.BOTTOM_RIGHT,
            className: toastContent.toastClass
            })
  }


    componentDidUpdate(prevProps, prevState){
      if(this.props.location && prevProps.location && prevProps.location.pathname != this.props.location.pathname && this.state.adminSessionPaths.includes(this.props.location.pathname))
        this.adminSessionLogger();

      if(this.props.location && prevProps.location && prevProps.location.pathname != this.props.location.pathname) this.merchantSessionLogger();

      if(prevProps.merchantOrdersData != this.props.merchantOrdersData) {
        if(this.ws && this.ws.readyState == WebSocket.OPEN) {
          // let orderIdArr = (this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0) ? this.props.merchantOrdersData.map(order => order.orderId) : []
          // this.props.merchantOrdersData.filter(order => (parseInt(order.versionCode, 10) >= 720 && parseInt(order.versionCode, 10) < 2000)).map(order => order.orderId)
          let orderIdArr = (this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0)
           ?
           this.props.merchantOrdersData.filter(order => (parseInt(order.versionCode, 10) >= 720 && parseInt(order.versionCode, 10) < 5300)).map(order => order.orderId)
           :
           []
          this.props.loadChatHistoryRequest(this.ws.send(JSON.stringify({"command": "LOAD_THREADS", "data": orderIdArr})))
        }
      }
      if(this.props.chatHistoryData && this.props.chatHistoryData.type == "threads") {
        this.chatHistoryThreads = this.props.chatHistoryData
      }
      if(prevProps.merchantDeliveryDetailsData != this.props.merchantDeliveryDetailsData) {
        if(this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant) {
          this.setState({ showOrdersTab: this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking })
        }
        
        if(this.props.merchantDeliveryDetailsData) {
        }
      }
      if(this.props.merchantDeliveryDetailsData && this.props.merchantDeliveryDetailsData.merchant) {
        if(!this.props.merchantDeliveryDetailsData.merchant.enableOrderBooking) {
          if(this.ws && this.ws.readyState != WebSocket.CLOSED) {
            this.ws.close()
          }
        }
      } 
      if(this.props.merchantDeliveryDetailsData) {        
        if(this.props.merchantOrdersData) {
    
          // Order notifications - V1 [START]
        // For showing order notifications
        let pendingOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "PENDING")
        let paymentDoneOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "PAYMENT_DONE")
        let codOrders = this.props.merchantOrdersData.filter(order => order.orderStatus == "COD")
        let ordersWithTrackingDetails = this.props.merchantOrdersData.filter(order => {
          if(order.orderStatus != "CANCELLED" && order.orderStatus != "DELIVERED" && order.orderStatus != "DISPATCHED" && order.orderStatus != "MERCHANT_DELIVERED") {
            // if((order.orderStatus != "DELIVERED")) {
              if((order.trackingUrl != "" || order.trackingPhoneNo != "")) {
                return order
              }
            // }
          }
        })
        // sendBqEvent = (eventName, orderId, itemNameAndId, itemPrice, suggestedItemPrice) => {
        // console.log(ordersWithTrackingDetails)
        if(pendingOrders.length != this.state.pendingCount){
          if(pendingOrders.length > this.state.pendingCount){
              // newOrderNotificationSound.play()
              if(this.holdMutex === true) {
                // Fire BQ updated_by_socket
                this.sendBqEvent('order_new_order_list_updated_by_socket', this.orderIdForEvent)
                this.orderIdListForNewOrderBuzzer = this.orderIdForEvent;
              } else {
                this.orderIdListForNewOrderBuzzer = JSON.stringify(pendingOrders.map(order => order.orderId))
                // Fire BQ updated by per min call
                this.acquireLock === true
                 ?
                  this.sendBqEvent('order_new_order_list_updated_by_page_load', JSON.stringify(pendingOrders.map(order => order.orderId)))
                 :
                  this.sendBqEvent('order_new_order_list_updated_by_recurring_call', JSON.stringify(pendingOrders.map(order => order.orderId)))
              }
              this.holdMutex = false;
              // Compute orderId
              // CALL_MULTIPLE_TIMES
              this.playNewOrderBuzzer()
              this.playBuzzerMultipleTimes()
              this.newOrderNotificationTriggered = true
              this.handleDisplayOrderNotification("PENDING", pendingOrders.length)
          }
          this.setState({pendingCount: pendingOrders.length})
        }

        if(codOrders.length != this.state.codOrdersCount){
          if(codOrders.length > this.state.codOrdersCount){
              // newOrderNotificationSound.play()
              if(this.holdMutex === true) {
                // Fire BQ updated_by_socket
                this.sendBqEvent('order_new_order_cod_updated_by_socket', this.orderIdForEvent)
                this.orderIdListForNewOrderBuzzer = this.orderIdForEvent
              } else {
                this.orderIdListForNewOrderBuzzer = JSON.stringify(codOrders.map(order => order.orderId))
                // Fire BQ updated by per min call
                this.acquireLock === true
                ?
                  this.sendBqEvent('order_new_order_cod_updated_by_page_load', JSON.stringify(codOrders.map(order => order.orderId)))
                :
                  this.sendBqEvent('order_new_order_cod_updated_by_recurring_call', JSON.stringify(codOrders.map(order => order.orderId)))
              }
              this.holdMutex = false;
              // Compute orderId
              // CALL_MULTIPLE_TIMES
              this.playNewOrderBuzzer()
              this.playBuzzerMultipleTimes()
              this.handleDisplayOrderNotification("COD", codOrders.length)
          }
          this.setState({codOrdersCount: codOrders.length})
        }

        if(paymentDoneOrders.length != this.state.paymentDoneCount){
          if(paymentDoneOrders.length > this.state.paymentDoneCount) {

            if(this.props.doesCatalogExistData && (this.props.doesCatalogExistData.catalog || this.props.doesCatalogExistData.orderFlow === "beyondfood")) {
              // newOrderNotificationSound.play() // For F&B merchants, PAYMENT_DONE = PENDING
              if(this.holdMutex === true) {
                // Fire BQ updated_by_socket
                this.sendBqEvent('order_new_order_catalog_updated_by_socket', this.orderIdForEvent)
                this.orderIdListForNewOrderBuzzer = this.orderIdForEvent
              } else {
                this.orderIdListForNewOrderBuzzer = JSON.stringify(paymentDoneOrders.map(order => order.orderId))
                // Fire BQ updated by per min call
                this.acquireLock === true
                ?
                  this.sendBqEvent('order_new_order_catalog_updated_by_page_load', JSON.stringify(paymentDoneOrders.map(order => order.orderId)))
                :
                  this.sendBqEvent('order_new_order_catalog_updated_by_recurring_call', JSON.stringify(paymentDoneOrders.map(order => order.orderId)))
              }
                this.holdMutex = false;
                // Compute orderId
                // CALL_MULTIPLE_TIMES
                this.playNewOrderBuzzer()
                this.playBuzzerMultipleTimes()
                this.handleDisplayOrderNotification("PENDING", paymentDoneOrders.length)
              } else {
                // !this.newOrderNotificationTriggered && orderNotificationSound.play()
                if(this.holdMutex === true) {
                  // Fire BQ updated_by_socket
                  this.sendBqEvent('order_update_order_list_payment_done_updated_by_socket', this.orderIdForEvent)
                  this.orderIdListForUpdateOrderBuzzer = this.orderIdForEvent
                } else {
                  this.orderIdListForUpdateOrderBuzzer = JSON.stringify(paymentDoneOrders.map(order => order.orderId))
                  // Fire BQ updated by per min call
                  this.acquireLock === true
                  ?
                  this.sendBqEvent('order_update_order_list_payment_done_updated_by_page_load', JSON.stringify(paymentDoneOrders.map(order => order.orderId)))
                  :
                  this.sendBqEvent('order_update_order_list_payment_done_updated_by_recurring_call', JSON.stringify(paymentDoneOrders.map(order => order.orderId)))
                }
                this.holdMutex = false;
                this.updateOrderMessage = "PAYMENT_RECEIVED"
                // !this.newOrderNotificationTriggered && this.playUpdateOrderBuzzer()
                this.playUpdateOrderBuzzer()
                this.handleDisplayOrderNotification("PAYMENT_DONE", paymentDoneOrders.length)
              }
          }
          this.setState({paymentDoneCount: paymentDoneOrders.length})
        }

        if(ordersWithTrackingDetails.length != this.state.trackingInfoAvailableCount){
          if(ordersWithTrackingDetails.length > this.state.trackingInfoAvailableCount) {
              
              // !this.newOrderNotificationTriggered && orderNotificationSound.play()
              if(this.holdMutex === true) {
                this.orderIdListForUpdateOrderBuzzer = this.orderIdForEvent
                // Fire BQ updated_by_socket
                this.props.doesCatalogExistData && (this.props.doesCatalogExistData.catalog || this.props.doesCatalogExistData.orderFlow === "beyondfood")
                 ? 
                  this.sendBqEvent('order_update_order_catalog_agent_assigned_updated_by_socket', this.orderIdForEvent)
                 :
                  this.sendBqEvent('order_update_order_list_agent_assigned_updated_by_socket', this.orderIdForEvent)
              } else {
                this.orderIdListForUpdateOrderBuzzer = JSON.stringify(ordersWithTrackingDetails.map(order => order.orderId))
                // Fire BQ updated by per min call
                this.props.doesCatalogExistData && (this.props.doesCatalogExistData.catalog || this.props.doesCatalogExistData.orderFlow === "beyondfood")
                 ? 
                  this.acquireLock === true
                  ?
                    this.sendBqEvent('order_update_order_catalog_agent_assigned_updated_by_page_load', JSON.stringify(ordersWithTrackingDetails.map(order => order.orderId)))
                  :
                    this.sendBqEvent('order_update_order_catalog_agent_assigned_updated_by_recurring_call', JSON.stringify(ordersWithTrackingDetails.map(order => order.orderId)))
                 :
                  this.acquireLock === true
                  ?
                    this.sendBqEvent('order_update_order_list_agent_assigned_updated_by_page_load', JSON.stringify(ordersWithTrackingDetails.map(order => order.orderId)))
                  :
                    this.sendBqEvent('order_update_order_list_agent_assigned_updated_by_recurring_call', JSON.stringify(ordersWithTrackingDetails.map(order => order.orderId)))
              }
              this.holdMutex = false;
              this.updateOrderMessage = "AGENT_ASSIGNED"  
              // !this.newOrderNotificationTriggered && this.playUpdateOrderBuzzer()
              this.playUpdateOrderBuzzer()
              this.handleDisplayOrderNotification("TRACKING_INFO_AVAILABLE", ordersWithTrackingDetails.length)
          }
          this.setState({trackingInfoAvailableCount: ordersWithTrackingDetails.length})
        }
        
          // Order notifications - V1 [END]

        // For clearing out setInterval in case merchant has no orders

          let isActiveHomeDeliveryMerchant = this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0 ? true : false;
          if(!isActiveHomeDeliveryMerchant) {
            // console.log("Interval cleared")
            clearInterval(this.homeDeliveryIntervalId);

        }
        }
        
      }

      if(((prevProps.upsellDataloaded !== this.props.upsellDataloaded) ||  (prevProps.bppDetailsLoaded != this.props.bppDetailsLoaded)) && !this.toastCount  && !this.props.user.isSaasOnly){
        this.handleDisplayToast();
        this.toastCount = 1
      }

      if(prevProps.togglingMagicPay && !this.props.togglingMagicPay){
        if(this.props.toggledMagicPay) toast.success("Your request has been submitted.It can take 1-2 hours to process your request");
        else toast.warn("Some error occurred. Please try again.")
      }

      const popupFrequencyCheck = this.props.user.userType === userTypes.ADMIN ? (this.props.gstModalCount)%10 === 0 : (this.props.gstModalCount)%5 === 0;
      
      if(window.innerWidth <= 768 && !prevProps.showAppDownloadPopupRes && this.props.showAppDownloadPopupRes && this.props.showAppDownloadPopupRes.showPopup == true){
        this.setState({showAppDownloadPopup: true})
      } 
      else if(prevProps.feedShortcutsStatusLoading && !this.props.feedShortcutsStatusLoading && this.props.feedShortcutsStatusData){
        const {ownershipVerified, passwordSet, isRedemptionOptionLive, merchantActive, gstVerified} = this.props.feedShortcutsStatusData
        if(!ownershipVerified){
          this.props.getMerchantActivationStatus(this.props.user.accessToken);
        }
        else{
          if(!gstVerified && this.props.user.country && this.props.user.country.toUpperCase() !== countries.INDONESIA && popupFrequencyCheck) this.setState({ showGstModal: true})
          else if(!passwordSet && popupFrequencyCheck) this.setState({ showPwdModal:true })
          // else if(!isRedemptionOptionLive) this.setState({showVoucherRelisting: true})
          else if(this.props.location.pathname !=='/bpp' && this.props.user.country.toUpperCase() !== countries.INDONESIA && !this.props.showLaunchGroupBuy){
            if(this.props.summary)
              this.calculateGmvAmount();
            else this.props.loadBusinessSummary(this.props.user.accessToken,'dashboard').then(result => this.calculateGmvAmount())
          }
        }
      }

      if(prevProps.merchantActivationStatusLoading
        && !this.props.merchantActivationStatusLoading
        && this.props.merchantActivationStatusData 
        && this.props.merchantActivationStatusData.activationStatus ){
          
          if(['INCOMPLETE', 'REJECTED'].includes(this.props.merchantActivationStatusData.activationStatus.toUpperCase())){
            showPopupAfter2days('activation_modal') && this.props.showActivationModal();
          }
          else {
            // if this is field onboarding and acitvation pop up is disabled then logout.
            if(this.params.has('utm_campaign') && this.params.get('utm_campaign') === 'field_onboarding') {
              // this.props.logout(this.props.user.accessToken);
              window.localStorage.removeItem('accessToken');
              window.location.href = 'magicsales://onboard?message=ALREADY_ONBOARDED';
            }
          }
        }

        if(this.props.location.pathname === '/vouchers' && !prevState.hideSnackBarRelistOnVoucher)
        {
          this.setState({
            hideSnackBarRelistOnVoucher : true
          })
        }else if(this.props.location.pathname !== '/vouchers' && prevState.hideSnackBarRelistOnVoucher)
        {
          this.setState({
            hideSnackBarRelistOnVoucher : false
          })
        }

        if(!!window.fcWidget) 
        {
          // window.fcWidget.show()
          window.fcWidget.destroy();
          document.getElementById('custom_chat_button').style.visibility = "hidden"
    
        }

        if(!!window.fcWidget && ( this.state.fcWidgetHide.includes(this.props.location.pathname) 
        || (this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.commercialTypes && this.props.feedShortcutsStatusData.commercialTypes.includes('SAAS') && this.state.fcWidgetHideSaas.includes(this.props.location.pathname)) ))
       { 
        // window.fcWidget.hide();
        window.fcWidget.destroy();
        document.getElementById('custom_chat_button').style.visibility = "hidden"
       }

        if(!prevState.BPP_0 && this.props.goalTrackerHistoryData 
           && this.props.goalTrackerHistoryData.length > 0
          && this.props.goalTrackerHistoryData[0].packageName === "BPP_0" 
           )
        {
          this.setState({BPP_0: true})
         }

        if(prevState.showLocationSettingGuide !== this.props.showLocationSettingGuide){
          this.setState({showLocationSettingGuide: this.props.showLocationSettingGuide})
        }
        
        if(prevState.showSecurityAlert !== this.props.showSecurityAlert){
            this.setState({showSecurityAlert: this.props.showSecurityAlert})
        }

        if(!this.props.ledgerUrlToDownload && prevProps.requestingLedgerDownload && this.props.requestingLedgerDownload)
        {
          if(this.retryCounter > 0 && this.retryCounter !== this.state.retryCounterState){
            console.log(this.retryCounter, "retry requestingLedgerDownload")
            this.setCounterToReload(this.retryCounter)
            this.setState({retryCounterState: this.retryCounter})
            this.sendBqEvent("ledger_download_retry_counter", null, {retryCounter: this.retryCounter, retryCounterState: this.state.retryCounterState}, null)
          }else if(this.retryCounter == 0){
            toast.error("Server timed out, please try again after some time");
            this.props.stopMerchantWalletTransactionAsync()
            this.sendBqEvent("ledger_download_retry_counter_end", null, {retryCounter: this.retryCounter, retryCounterState: this.state.retryCounterState}, null)
            this.retryCounter = ledgerDownloadRetryObj.retryCounter
            this.setState({retryCounterState: 0}) // stop here
          }
        }

        if(!prevProps.ledgerUrlToDownload && this.props.ledgerUrlToDownload)
        {
          try{
            const url = this.props.ledgerUrlToDownload
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ledger.csv');
            document.body.appendChild(link);
            link.click();
            toast.success('Ledger downloaded successfully.')
            this.sendBqEvent("ledger_download_success", null, {retryCounter: this.retryCounter, retryCounterState: this.state.retryCounterState, ledgerUrlToDownload: this.props.ledgerUrlToDownload}, null)
          }catch (err){
            const errorMessage = err && err.message || "some error occured, please try again after some time";
            toast.error(errorMessage);
            this.sendBqEvent("ledger_download_failure", null, {retryCounter: this.retryCounter, retryCounterState: this.state.retryCounterState, errorMessage}, null)
          }

          this.retryCounter = ledgerDownloadRetryObj.retryCounter
          this.setState({retryCounterState: 0}) // stop here
        }
    }

    setCounterToReload = (retryCounter) => {
      setTimeout(() => {
        this.props.getMerchantWalletTransactionAsync(this.props.user.accessToken, this.props.ledgerStartDate, this.props.ledgerEndDate, null, true) 
        this.retryCounter = retryCounter - 1
      }, ledgerDownloadRetryObj.retryTime);
    }

    componentWillUnmount(){
      this.sendBqEvent("order_header_unmounted")
      // close ws
      this._ismounted = false;
      if(this.ws) {
        // console.log("WS Closed - Header.js component unmounted")
        // console.log("SUSPECT #3")
        this.ws.close()
      }
      clearInterval(this.intervalId);
      clearInterval(this.dealsIntervalId);
      clearInterval(this.homeDeliveryIntervalId);
      clearTimeout(this.helpDeskTimer);
      if(this.homeDeliveryIntervalIdInactiveMerchant) {
        clearInterval(this.homeDeliveryIntervalIdInactiveMerchant)
      }
      document.body.classList.remove('top-header-bar-visible')
      document.body.classList.remove('snackbar-visible')
      if(this.toastDisplay) toast.dismiss(this.toastDisplay)
    }

    handleLogout = (event) => {
      event.preventDefault();
      ga('send', 'event', 'Header', 'navigation', 'logout');
      this.props.logout(this.props.user.accessToken);
    }

    showVoucherDelisted = () => {
      this.setState({showVoucherRelisting: !this.state.showVoucherRelisting})
    }

    showShareStoreDialog = () => {
      this.setState({showShareStoreDialog: true})
    }

    hideShareStoreDialog = () => {
        this.setState({showShareStoreDialog: false})
    }

    minimizeHelpDesk = () => {
      this.setState(prevState => {
          if(!prevState.minimizeHelpDesk === true)
            this.helpDeskTimer = setTimeout(() => this.hideHelpDesk(),50000)
          else
            clearTimeout(this.helpDeskTimer);
          return {
              minimizeHelpDesk: !prevState.minimizeHelpDesk
          }
      })
    }

    showHelpDesk = () => {
      if(this.state.showHelpDeskWidget){
        this.setState({
          open: false,
        })
        this.minimizeHelpDesk();
      }
      else{
        this.setState({
          open: false,
          showHelpDeskWidget: true
        })
      }
    }

    hideHelpDesk = () => {
      this.setState({
        showHelpDeskWidget: false,
        minimizeHelpDesk: false
      })
    }

    setUpiFN = () => {
      this.setState({setUpi: !this.state.setUpi})
    }

    setShowPineLabConsent = () => {
      if(this.props.user && this.props.user.userType && this.props.user.userType.toUpperCase() === 'MERCHANT' ){
        this.props.paymentCommercialsUpsert(this.props.user.accessToken)
      }
      else{
        let showPineLabConsentCount = window.localStorage.getItem(`showPineLabConsentCount_${this.props.user.merchantId || ""}`) || 0
        showPineLabConsentCount = parseInt(showPineLabConsentCount || 0) + 1
        window.localStorage.setItem(`showPineLabConsentCount_${this.props.user.merchantId || ""}`, showPineLabConsentCount)
      }
      this.setState({showPineLabConsent: !this.state.showPineLabConsent})
    }

    setShowLocationSettingGuideFN = () => {
      this.props.setShowLocationSettingGuide()
    }
    
    setShowSecurityAlertFN = () => {
      this.props.setShowSecurityAlert()
    }

    handleLogoutFN = () => {
      this.props.logout(this.props.user?.accessToken)
      window.location.reload();
    }
   
    render() {
      // console.log("IS WEBSOCKET CLOSED?  __________-----------> ", this.ws.readyState == WebSocket.CLOSED)

// console.log("REDUX connected with ws - Chat history :: ", this.props.chatHistoryData )
if(this.props.chatHistoryData && this.props.chatHistoryData.type == "threads" ) {
  this.chatHistoryThreads = this.props.chatHistoryData
}

// Order notifications - V2 (wss based) [START]

// New order notification
if(this.props.chatHistoryData && this.props.chatHistoryData.type === "new_order" && this.props.chatHistoryData.thread_id
  && this.lastNewOrderNotificationId !== this.props.chatHistoryData.thread_id) {

    this.lastNewOrderNotificationId = this.props.chatHistoryData.thread_id
    this.orderIdForEvent = this.props.chatHistoryData.thread_id
    this.holdMutex = true;

    setTimeout(() => {
      this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId)
      .then(res => {
        let orderIdArr = (this.props.merchantOrdersData && this.props.merchantOrdersData.length > 0)
            ?
            this.props.merchantOrdersData.map(order => order.orderId)
            :
            []
        this.props.loadChatHistoryRequest(this.ws.send(JSON.stringify({"command": "LOAD_THREADS", "data": orderIdArr})))
      })
    }, 2000);
  }

// Update order notifications
  if(this.props.chatHistoryData && this.props.chatHistoryData.type === "order_updated" && this.props.chatHistoryData.thread_id
     && this.props.chatHistoryData.order_state) {
    this.holdMutex = true;
    switch(this.props.chatHistoryData.order_state) {
      case 'PAYMENT_DONE':
          if(this.lastPaymentDoneNotificationId !== this.props.chatHistoryData.thread_id) {
            if (lastViewOrdersCallCheck()) {
              this.lastPaymentDoneNotificationId = this.props.chatHistoryData.thread_id
              this.orderIdForEvent = this.props.chatHistoryData.thread_id
              
              setTimeout(() => {
                this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId);
              }, 2000);
            } 
          }
          break;
      case "PAYMENT_DONE|ASSIGNED":
      case "ACCEPTED|ASSIGNED":
      case "DISPATCH_READY|ASSIGNED":
          if(this.lastAgentAssignedNotificationId !== this.props.chatHistoryData.thread_id) {
            if (lastViewOrdersCallCheck()) {
              this.lastAgentAssignedNotificationId = this.props.chatHistoryData.thread_id
              this.orderIdForEvent = this.props.chatHistoryData.thread_id
  
              setTimeout(() => {
                this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId);
              }, 2000);
            }
          }
          break;
      case "REPEAT_BUZZER":
        // if(this.lastRepeatBuzzerNotificationId !== this.props.chatHistoryData.thread_id && this.shouldRepeatBuzzer()) {
          if(this.shouldRepeatBuzzer()) {
          this.lastRepeatBuzzerNotificationId = this.props.chatHistoryData.thread_id
          this.orderIdForRepeatBuzzerEvent = this.props.chatHistoryData.thread_id
          // play buzzer
          this.playRepeatBuzzer()
        }
        break;
      default:
        if (lastViewOrdersCallCheck()) {
          setTimeout(() => {
            this.props.getMerchantOrders(this.props.user.accessToken, this.props.user.userId);
          }, 2000);
        }
    }
  }
// Order notifications - V2 (wss based) [END]

if(this.props.chatHistoryData && this.notificationShown) {
  this.notificationShown = false
}
if(this.props.chatHistoryData && this.props.chatHistoryData.type == "message" && this.props.chatHistoryData.message
 && this.props.chatHistoryData.message.id && this.props.chatHistoryData.message.id.slice(0,3) != "mx_"
  && !this.notificationShown && this.lastChatNotificationId !== this.props.chatHistoryData.message.id) {
    this.lastChatNotificationId = this.props.chatHistoryData.message.id
  // console.log('NOTIFIED!!!!')
  // console.log(">>>>>>>><<<<<<<<<<", this.props.merchantOrdersData.filter(order => order.orderId == this.props.chatHistoryData.thread_id))
  let orderArr = this.props.merchantOrdersData && this.props.merchantOrdersData.filter(order => order.orderId == this.props.chatHistoryData.thread_id)
  let customerName = orderArr.length > 0 ? orderArr[0].userName : ""
  let customerMessage = this.props.chatHistoryData.message.text
  let attachmentReceived = (this.props.chatHistoryData.message.attachment && this.props.chatHistoryData.message.attachment != "") ? true : false
    // console.log(">>>>>>>><<<<<<<<<<", customerName)

  // console.log(">>>>>>>><<<<<<<<<<", this.props.chatHistoryData.message.text)
  // console.log(">>>>>>>><<<<<<<<<<", this.props.chatHistoryData.message.id.slice(0,10))
  // if(this.props.chatHistoryData.)

  // "data": {
  //   "id": messageId,
  //   "text": messageString
  // },
  //this.props.chatHistoryData['thread_id']


  // console.log("WSS reply FTW :: ", this.chatHistoryThreads)

    if(this.chatHistoryThreads && this.chatHistoryThreads.threads
       && this.chatHistoryThreads.threads.length > 0) {
        let unreadMsgCountForNotifiedThread = this.chatHistoryThreads.threads.filter(
          thread => thread.thread_id == this.props.chatHistoryData['thread_id'])[0].unread_messages + 1 // Adding 1 because this is the chatData before the current message was received, i.e., it doesn't have knowledge of the currently received message.
        
        unreadMsgCountForNotifiedThread > 1
         ?
         this.handleDisplayChatNotification("MULTI", this.props.chatHistoryData['thread_id'], customerName, customerMessage, attachmentReceived, unreadMsgCountForNotifiedThread)
        :
         this.handleDisplayChatNotification("SINGLE", this.props.chatHistoryData['thread_id'], customerName, customerMessage, attachmentReceived, null)
         
        this.notificationShown = true
       }

  // this.handleDisplayChatNotification("SINGLE", this.props.chatHistoryData['thread_id'], customerName, customerMessage, attachmentReceived)
}

// if(this.props.chatHistoryData && this.props.chatHistoryData.type == "threads" && this.notificationShown) {
//   this.notificationShown = false
// }


      let isSaaS = window.localStorage.getItem('landingOriginSource') == 'magicStore'|| (this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.packageName == 'magicStore')
      let showDelistedSnackbar = !isSaaS && this.props.feedShortcutsStatusData ? !this.props.feedShortcutsStatusData.isRedemptionOptionLive : null

      const cover_pic = this.props.user ? this.props.user.coverPic : '';
      let activePage = '';
      switch (this.props.location.pathname) {
        case '/feed':
        activePage = 'Home'; break;
        case '/dashboard': activePage = 'Business'; break;
        // case '/bookings': activePage = 'Bookings'; break;
        case '/orders': activePage = 'Orders'; break;
        case '/customers': activePage = 'Customers'; break;
        case '/vouchers': activePage = 'Vouchers'; break;
        case '/promotions': activePage = 'Promotions'; break;
        case '/profile': activePage = 'Profile'; break;
        case '/subscription': activePage = 'Subscription'; break;
        case '/myteam': activePage = 'Myteam'; break;
        case '/leads' : activePage = 'Leads' ; break;
        case '/bpp': activePage = 'Brand Privilege'; break;
        default: activePage = '';
      }
      const isForIndonesia = this.props.user.country && this.props.user.country.toUpperCase() === 'INDONESIA';

      const pharmaMerchant = IsPharmaMerchant(this.props.user.merchantId);
      
      let hideSnackBarForPhonesInOrdersPage = (activePage == "Orders" && window.innerWidth <= 786 && (window.localStorage.getItem('landingOriginSource') != 'magicStore' && (this.props.feedShortcutsStatusData && this.props.feedShortcutsStatusData.packageName != 'magicStore'))) ? true : false

      if(hideSnackBarForPhonesInOrdersPage) {
                document.body.classList.remove('snackbar-visible')
              document.body.classList.remove('top-header-bar.snackbar-visible')  
              }

      let hide = this.state.BPP_0 ;
      
      const isCeetMerchant = this.props.user && this.props.user.isCeetMerchant
      const isCeetMerchantAndMagicpinMerchant = this.props.user && this.props.user.isCeetMerchantAndMagicpinMerchant

      return (
        !this.props.user ? null :
        <header className={`${this.props.location.pathname}`} style={{position:"fixed", zIndex:"1000", width:"100vw", top:"0"}}>
          { this.props.feedShortcutsStatusData && this.props.gstModalCount && !hideSnackBarForPhonesInOrdersPage && ((MAGIC_ORDER_VISIBLE.includes(this.props.user.categoryId) && !(showDelistedSnackbar) )|| (!isSaaS && !this.props.feedShortcutsStatusData.isRedemptionOptionLive)  || (this.props.user.categoryId && this.props.user.categoryId == 5 )|| pharmaMerchant == true) && !this.props.user.isSaasOnly && <SnackBar
              push={this.props.push}
              user={this.props.user}
              showOnsurityData={this.props.showOnsurityData}
              allCrmData={this.props.allCrmData}
              location = { this.props.location }
              groupBuyPlans = {this.props.groupBuyPlans}
              groupBuyPlansLoading = {this.props.groupBuyPlansLoading}
              groupBuyPlansLoaded = {this.props.groupBuyPlansLoaded}
              showNYVoucherModal = {this.props.showNYVoucherModal}
              showShareStoreDialog = {this.showShareStoreDialog}
              showGroceryModal = {this.props.showGroceryPharmaModal}
              showVoucherDelisted={this.showVoucherDelisted}
              IsPharmaMerchant={pharmaMerchant}
              // launchGroupBuyModal = {this.props.launchGroupBuyModal}
              hideSnackBarRelistOnVoucher={this.state.hideSnackBarRelistOnVoucher}
              hide = {hide}
              showMagicOrderModal = {this.props.showMagicOrderModal}
              isIntrestedInMagicOrder = {this.props.isIntrestedInMagicOrder}
              merchantDeliveryDetailsData ={this.props.merchantDeliveryDetailsData}
              accountStatus = {this.props.accountStatus}
              alias={this.props.alias}
              gstModalCount={this.props.gstModalCount}
              showNewYearVoucherSnackbar = {false}
              setUpiFN = {this.setUpiFN}
          />}
          {this.props.modalVisible && 
          <Suspense fallback={<div>loading</div>}>
            <MerchantActivationModal
                user = { this.props.user }
                push = { this.props.push }
                logout = { this.props.logout }
                hideActivationModal = {this.props.hideActivationModal}
                location = { this.props.location } />
          </Suspense>}
          {this.props.showMagicpayModalDirect && 
          <Suspense fallback="loading">
            <MagicPay showModal={true}
              members={this.props.members}
              membersLoaded={this.props.membersLoaded}
              hidemodal={this.props.showMagicpayModalFeedShortcutClick}
              push={this.props.push}
              user={this.props.user}
              toggleMagicPay = {this.props.toggleMagicPay}
            />
            </Suspense>}
          {/* {this.props.showLaunchGroupBuy && 
            <Suspense fallback={<div>loading</div>}>
              <GroupBuy 
                hidemodal={()=>this.props.launchGroupBuyModal(false)}
                user={this.props.user}
                push={this.props.push}
                location={this.props.location}
                    />
            </Suspense>} */}
          {this.props.showFixedCashinPopup &&
          <Suspense fallback={<div>loading</div>}>
            <FixedCashinPopup
                hidemodal={()=>this.props.launchFixedCashinModal(false)}
                user={this.props.user}
                members={this.props.members}
                push={this.props.push}
                location={this.props.location}
            />
          </Suspense>}
          {this.props.showConfirm &&
          <Suspense fallback={<div>loading</div>}>
            <Confirm
                user={this.props.user}
                push={this.props.push}
                location={this.props.location}
            />
          </Suspense>}
          {this.props.showNYModal && <AddNewYearVoucher
            show={this.props.showNYModal}
            user={this.props.user}
            onHide={()=> {
              this.props.hideNYVoucherModal();
              const params = new URLSearchParams(this.props.location.search);
              if(params.has('openNYVoucher')) {
                this.props.push('feed')
              }
            }}
          />}
          {this.props.showGroceryAndPharmaModal && <AddHomeDeliveryVouchers
            show={this.props.showGroceryAndPharmaModal}
            user={this.props.user}
            onHide={()=>this.props.hideGroceryPharmaModal()}
          />}
          {this.props.visibleMagicOrderModal && <IntrestedMagicOrderPopup
            show={this.props.visibleMagicOrderModal}
            user={this.props.user}
            onHide={()=>this.props.hideMagicOrderModal()}
          />

          }
          <Drawer
            docked={false}
            width={200}
            open={this.state.open}
            ref='mobileTab'
            onRequestChange={(open) => this.setState({open})}
            >
            <div style={{position: 'absolute',backgroundImage:'url('+ cover_pic +')', opacity:'0.6', width:'100%', height:'110px', backgroundSize:'100%'}}>
            </div>
            <div style={{position: 'absolute',backgroundColor:'black', opacity:'0.5', width:'100%', height:'110px', backgroundSize:'100%'}}>
            </div>
            <div style={{width:'100%', padding:'16px', background:'transparent', maxWidth:'300px', textAlign:'center', opacity:'0.99'}}
              onClick={() => this.navigate('/profile')}>

              <div className="store-thumb" style={{display:'inline-block', width:'36px', height:'36px',
              verticalAlign:'middle', marginBottom:'8px', borderRadius:'36px'}}>

              <img src={this.props.user.profilePic} alt='logo'
              style={{width:'100%', height:'100%', borderRadius:'36px'}} />
            </div>

            <div style={{display:'inline-block', verticalAlign:'middle', textAlign:'center', width: '100%'}}>
              <p style={{margin:'0', fontSize:'14px', color:'#fff'}}><b>{this.props.user.merchantName}</b></p>
              <p style={{margin:'0', fontSize:'12px', color:'#fff'}}>{this.props.user.locality}, {this.props.user.city}</p>
            </div>

          </div>

          {this.props.user.userType !== 'MERCHANT' &&
            <MenuItem  style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}} onClick={ this.navigate.bind(this,'/')}>
              Admin
            </MenuItem>
          }
            {!(this.props.feedShortcutsStatusData && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes) &&  this.props.feedShortcutsStatusData.commercialTypes.length == 1 && this.props.feedShortcutsStatusData.commercialTypes[0] == 'SAAS') && <MenuItem className={this.props.pathname === '/feed'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={this.navigate.bind(this,'/feed')}>
              Home
            </MenuItem>}

            <MenuItem className={this.props.pathname === '/dashboard'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={this.navigate.bind(this,'/dashboard')}>
              Business
            </MenuItem>

              <MenuItem className={this.props.pathname === '/vouchers'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={this.navigate.bind(this,'/vouchers')}>
              Vouchers
            </MenuItem>

            {/* <MenuItem className={this.props.pathname === '/customers'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={this.navigate.bind(this,'/customers')}>
              Customers
            </MenuItem> */}
            {
              this.props.user.country.toUpperCase() == "INDIA" && 
              <MenuItem className={this.props.pathname === '/orders'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
                onClick={this.navigate.bind(this,'/orders')}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                Orders
                <div className='orders-tab-sticker' style={{paddingRight: '5%', paddingLeft: '5%', maxHeight: '23px', alignSelf: 'center', left: '8%'}}><div style={{position: 'relative', bottom: '8px'}}>New</div></div>

                </div>
                {/* <Link to="/orders" style={{display: 'flex', flexDirection: 'column', width: '40px', position: 'relative', bottom: '8px'}}> */}
                                    {/* <div className='orders-tab-sticker'>New</div> */}
                                    {/* </Link> */}
              </MenuItem>
            }

            <MenuItem className={this.props.pathname === '/profile'? "header-mobile-active":''} style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={this.navigate.bind(this,'/profile')}>
              Profile
            </MenuItem>
            <MenuItem style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px'}}
              onClick={() => this.showHelpDesk()}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  Support
                  <div className='orders-tab-sticker' style={{paddingRight: '5%', paddingLeft: '5%', maxHeight: '23px', alignSelf: 'center', left: '8%'}}><div style={{position: 'relative', bottom: '8px'}}>New</div></div>
                </div>
             </MenuItem>

            {/* <MenuItem style={{fontSize: 16, textAlign: 'left', lineHeight: '40px', minHeight: '40px', textDecorationStyle: 'solid'}}
              onClick={() => {
                // if(window.supportHeroWidget) window.supportHeroWidget.show();
                // ga('send', 'event', 'Navigation', 'Navigation', 'Support Hero');
                ga('send', 'event', 'Navigation', 'Navigation', 'FreshChat');
                if(!!window.fcWidget) 
                {window.fcWidget.open()
                   window.fcWidget.show();
                }
              }}>
              Helpdesk
            </MenuItem> */}

            <div className='Separator2'></div>
            {!(this.props.feedShortcutsStatusData && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes) &&  this.props.feedShortcutsStatusData.commercialTypes.length == 1 && this.props.feedShortcutsStatusData.commercialTypes[0] == 'SAAS') && <MenuItem className={this.props.pathname === '/promotions'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/promotions')}>
              Promotions
            </MenuItem>}
            
            {/* {!(this.props.user && this.props.user.isSaasOnly) && <MenuItem className={this.props.pathname === '/bookings'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/bookings')}>
              Bookings
              {this.props.dealsCount > 0 &&
                <div className="mrcht-notification-txt" style={{right:'10%', top:'10px', padding:'4px 5px', fontSize:'14px'}}>
                  {this.props.dealsCount}
                </div>
              }
            </MenuItem>} */}

            <MenuItem className={this.props.pathname === '/myteam'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/myteam')}>
              My Team
            </MenuItem>

            <MenuItem className={this.props.pathname === '/subscription'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/subscription')}>
              My Subscription
            </MenuItem>

            { this.props.isBppActive && !isForIndonesia && <MenuItem className={this.props.pathname === '/bpp'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/bpp')}>
              Brand Privilege
            </MenuItem>}

            <MenuItem className={this.props.pathname === '/customers'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/customers')}>
              Customers
            </MenuItem>

            <MenuItem className={this.props.pathname === '/transaction'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/transactions')}>
              View Transactions
            </MenuItem>
            
            
            {(this.props.user.userType === 'ADMIN' || isCeetMerchantAndMagicpinMerchant) && (
              <MenuItem className={this.props.pathname === '/ceet'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/ceet')}>
              Ceet
              </MenuItem>
            )}

            {/* {!isCeetMerchant &&
            <div style={{textAlign:"left"}}>
              <a href="https://partners.magicpin.in">
                <MenuItem style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}>
                  Community
                </MenuItem>
              </a>
            </div>} */}

            {!isCeetMerchant &&
            <MenuItem className={this.props.pathname === '/leads'? "header-mobile-active":''} style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px'}}
              onClick={this.navigate.bind(this,'/leads')}>
              Leads
            </MenuItem>}


            <MenuItem style={{fontSize: 12, textAlign: 'left', lineHeight: '30px', minHeight: '30px' }} onClick={(e)=> this.handleLogout(e)} disabled={this.props.isLoggingOut}>
            {this.props.isLoggingOut ? "Logging out" : "Log out"}
            </MenuItem>
          </Drawer>

          <div id="top-header-bar" className="top-header-bar">
                <div className="company-logo-wrapper">
                <div className="logo mobile-view"><Link to='/'><img src={ this.props.location.pathname === '/bpp' ? logoBusinessWhite : logoBusiness } alt="Magicpin" /></Link></div>
                </div>
                <div className="top-navigation" style={{textAlign:'left'}}>
                          <nav className="navbar navbar-default">
                            <div className="navbar-header">
                              <button id="mobile-drawer-opener" type="button" className="navbar-toggle collapsed"  onClick={()=> this.setState({open: !this.state.open})}>
                              <i className="fa fa-bars hamburger-mobile"></i>
                              </button>
                              <label htmlFor="mobile-drawer-opener" className="navbar-text visible-xs-inline-block header-active-page">
                                  {activePage}
                              </label>
                            </div>
                            <div id="navbar" className="navbar-collapse collapse" style={{padding:'0'}}>
                              <ul className="nav navbar-nav" style={{float:"none", width:'100%', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                                {this.props.user.userType !== userTypes.MERCHANT && <li>
                                  <Link to="/">Admin</Link>
                                </li>}
                                {!(this.props.user && this.props.user.isSaasOnly) && <li className={this.props.location.pathname === '/feed' ? 'active' : ''}>
                                  <Link to="/feed">Home</Link>
                                </li>}
                                <li className={this.props.location.pathname === '/dashboard' ? 'active' : ''}>
                                  <Link to="/dashboard">Business</Link>
                                </li>
                                {/* {isCeetMerchant &&
                                <li className={this.props.location.pathname === '/customers' ? 'active' : ''}>
                                  <Link to="/customers">Customers</Link>
                                </li>} */}
                                <li className={this.props.location.pathname === '/vouchers' ? 'active' : ''}>
                                  <Link to="/vouchers">Vouchers</Link>
                                </li>
                                {this.props.user.country.toUpperCase() == "INDIA" &&
                                <li className={this.props.location.pathname === '/orders' ? 'active' : ''}>
                                  <Link to="/orders">
                                    Orders
                                    </Link>
                                    {/* <Link to="/orders" style={{display: 'flex', flexDirection: 'column', width: '40px', position: 'relative', bottom: '8px'}}>
                                    <div className='orders-tab-sticker'>New</div>
                                    Orders
                                    </Link> */}
                                </li>
                                }
                                {/* <li className={this.props.location.pathname === '/catalog' ? 'active' : ''}>
                                  <Link to="/catalog">Catalog</Link>
                                </li> */}
                                {/* <li className={this.props.location.pathname === '/customers' ? 'active' : ''}>
                                  <Link to="/customers">Customerst</Link>
                                </li> */}
                                <li className={this.props.location.pathname === '/profile' ? 'active' : ''}>
                                  <Link style={{display:"inline-block"}} to="/profile">Profile</Link>
                                </li>
                                <li className={this.state.showHelpDeskWidget ? 'active' : ''} onClick={() => this.showHelpDesk()} >
                                  <a>
                                    Support
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </nav>
                </div>
                <div className="merchant-login-wraper" role = "navigation">
                        <ul style={{width:'80%', display:'flex', justifyContent:'flex-end', alignItems:'center', marginLeft: window.innerWidth < 768 ? 'auto' : 'unset' }}>
                          <li>
                            <div style={{marginRight:'5px'}} id="google_translate_element"></div>
                          </li>
                          {/* <li className="merchant-login-text dropdown" style={{marginRight:'10px'}}>
                            <Link to="/notifications">
                            <i className="fa fa-bell-o hamburger-mobile"></i>
                             {this.props.newCount > 0 &&
                               <div className="mrcht-notification-txt">{this.props.newCount}</div>
                              }
                            </Link>
                          </li> */}
                          {window.innerWidth > 768 &&
                          <li className='merchant-login-text' style={{margin:'0', lineHeight:'unset'}}>
                                <IconMenu
                                  iconButtonElement={<IconButton style={{borderRadius:'4px', padding:'0', width:'40px', height:'40px', overflow:'hidden'}} ><img src={this.props.user.profilePic} height="40px" width="40px" alt="logo"/></IconButton>}
                                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                                  listStyle={{ width: 140 }}
                                  style={{cursor:"pointer", padding:'0'}}
                                >
                                  {!(this.props.feedShortcutsStatusData && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes) &&  this.props.feedShortcutsStatusData.commercialTypes.length == 1 && this.props.feedShortcutsStatusData.commercialTypes[0] == 'SAAS') && <MenuItem primaryText="Promotions" onClick={() => {
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Promotions');
                                    this.props.push('/promotions');
                                  }}
                                  />}

                                  <MenuItem primaryText="My Team" onClick={() => {
                                    ga('event', 'event', 'Navigation', 'Navigation', 'My Team');
                                    this.props.push('/myteam');
                                  }}
                                  />
                                  <MenuItem primaryText="My Subscription" onClick={() => {
                                    ga('event', 'event', 'Navigation', 'Navigation', 'My Subscription');
                                    this.props.push('/subscription');
                                  }}
                                  />

                                  {this.props.isBppActive && !isForIndonesia && <MenuItem primaryText="Brand Privilege" onClick={() => {
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Brand Privilege');
                                    this.props.push('/bpp');
                                  }}
                                  />}

                                  <MenuItem primaryText="Customers" onClick={() =>{
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Customers');
                                    this.props.push('/customers');
                                  }}>
                                  </MenuItem>

                                  {/* {!isCeetMerchant &&
                                  <MenuItem primaryText="Community" onClick={()=>{
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Community');
                                    window.location.href="https://partners.magicpin.in"
                                  }}
                                  />} */}

                                  {!isCeetMerchant &&
                                  <MenuItem primaryText="Leads" onClick={()=>{
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Leads');
                                    this.props.push('/leads');
                                  }}
                                  />}

                                  {/* <MenuItem primaryText="Help" onClick={() => {
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Help');
                                    window.fcWidget.open();
                                    window.fcWidget.show();
                                  }}
                                  /> */}

                                  {(this.props.user.userType === 'ADMIN' || isCeetMerchantAndMagicpinMerchant) &&
                                  <MenuItem primaryText="Ceet" onClick={() =>{
                                    ga('event', 'event', 'Navigation', 'Navigation', 'Ceet');
                                    this.props.push('/ceet');
                                  }}>
                                  </MenuItem>}

                                  <MenuItem primaryText={this.props.isLoggingOut ? "Logging out" : "Log out"} disabled={this.props.isLoggingOut} onClick={this.handleLogout} />
                                </IconMenu>
                              </li>}
                        </ul>
                      </div>
          </div>
          {this.state.showVoucherRelisting &&
      <Suspense fallback="loading">
        <VoucherRelisting
          //push={this.props.push}
          user={this.props.user}
          location={this.props.location}
          showVoucherDelisted={this.showVoucherDelisted}
          />
      </Suspense>}
      {this.state.showShareStoreDialog &&
      <Suspense fallback="loading">
        <ShareStoreDialog
          push={this.props.push}
          user={this.props.user}
          location={this.props.location}
          showShareStoreDialog={this.showShareStoreDialog}
          hideShareStoreDialog={this.hideShareStoreDialog}
          openShare={this.state.showShareStoreDialog}
          whatsappProducer={this.props.whatsappProducer}
          getWhatsappImage={this.props.getWhatsappImage}
          alias={this.props.alias}
          />
      </Suspense>}
      {this.state.showGstModal && 
      <Suspense fallback='loading'>
        <GstVerificationModal 
          showModal={this.state.showGstModal}
          hideModal={() => this.setState({showGstModal: false})}
          accessToken={this.props.user.accessToken}
          postGst={this.props.verifyGstAfterActivation}
          feedShortcutsStatusData={this.props.feedShortcutsStatusData}
        />
      </Suspense>
      }
      {this.state.showPwdModal && 
      <Suspense fallback ="loading">
        <LoginPasswordCheck user={this.props.user} 
          location={this.props.location} push={this.props.push}
        />
      </Suspense>
      }
      {
        this.state.showAppDownloadPopup &&
        <AppDownloadPopup closePopUp={()=>{this.setState({showAppDownloadPopup: false})}} user={this.props.user}/>
      }
      {
        this.state.showHelpDeskWidget && 
        <HelpDesk user={this.props.user} minimizeHelpDeskWidget={this.state.minimizeHelpDesk} hideHelpDesk={this.hideHelpDesk} 
        location={this.props.location} 
        push={this.props.push}
        minimizeHelpDesk={this.minimizeHelpDesk}></HelpDesk>
      }
      {this.props.smsPopup && <Suspense fallback="loading">
        <SmsPromotion  push={this.props.push} show={this.props.smsPopup} onHide={_=>this.props.toggleSmsPopup()}/>
      </Suspense>}
      {/* {this.props.arModalVisible && <Suspense fallback="loading">
        <ArGame
          user={this.props.user} 
          showModal={true}
          hideModal={this.props.toggleArModal}
          push={this.props.push}
        />
      </Suspense>} */}
      {/* DO NOT MODIFY / DELETE THESE */}
        {/* <iframe src={silence} allow="autoplay" id="audio" style={{display: 'none'}}></iframe> */}
        <audio autoplay src={newOrderAudio} className="new-order-notification-audio-file"></audio>
        <audio autoplay src={orderNotificationAudio} className="update-order-notification-audio-file"></audio>
        <audio autoplay src={chatAudio} className="chat-notification-audio-file"></audio>
      {/* DO NOT MODIFY / DELETE THESE */}
      {this.state.showAutoplayConfirmation 
        && <AskAutoplayPermission
        // autoplayPermissionGranted={this.autoplayPermissionGranted}
        showDownloadExtensionPopup={this.state.requestChromeExtensionInstallation}
        sendBqEvent={this.sendBqEvent}
        permissionsMessage={this.state.permissionsMessage}
        closePopUp={this.closeAutoplayPermissionPopup}
        />}

      {this.state.setUpi && 
        <SetUpi
          user = {this.props.user}
          setUpi = {this.state.setUpi}
          setUpiFN = {this.setUpiFN}
          getFeedShortcutsStatus = {this.props.getFeedShortcutsStatus}
          feedShortcutsStatusData = {this.props.feedShortcutsStatusData}
          setUpiId = {this.props.setUpiId}
          registeringUpi = {this.props.registeringUpi}
        />}

      {/* {this.state.showPineLabConsent && this.props.feedShortcutsStatusData && 
        this.props.feedShortcutsStatusData.commercialTypes && Array.isArray(this.props.feedShortcutsStatusData.commercialTypes) && this.props.feedShortcutsStatusData.commercialTypes.includes('MARKETING', 'marketing') && 
        this.props.feedShortcutsStatusData.pineLabsEnabled && !this.props.feedShortcutsStatusData.pineLabsEnabled.MARKETING &&
        (!window.localStorage.getItem(`showPineLabConsentCount_${this.props.user.merchantId || ""}`) || window.localStorage.getItem(`showPineLabConsentCount_${this.props.user.merchantId || ""}`) < 2) &&
        <PineLabsConsent
          showPineLabConsent = {this.state.showPineLabConsent}
          setShowPineLabConsent = {this.setShowPineLabConsent}
          user = {this.props.user}
        />
      } */}

      {this.state.showLocationSettingGuide &&
      <LocSettingGuideline
        showLocationSettingGuide = {this.state.showLocationSettingGuide}
        setShowLocationSettingGuide = {this.setShowLocationSettingGuideFN}
      />
      }
      
      {
        this.state.showSecurityAlert &&
        <SecurityAlert
          setShowSecurityAlert = {this.setShowSecurityAlertFN}
          showSecurityAlert = {this.state.showSecurityAlert}
          handleLogout = {() => this.handleLogoutFN()}
        />
      }
      </header>
    );
  }
})
