import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';
import particles from '../../images/particles.svg'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMerchantCommission } from '../../redux/modules/getMerchantCommission';
import { createNYVoucher } from '../../redux/modules/dealReducers/createVouchers';
import {sendEvent} from '../../helpers/Helpers';
import { createNYVoucherRequest} from '../../redux/modules/newMerchantLanding';

const ga=window.ga;
export default connect(store => ({
    loading: store.commission.loading,
    loaded: store.commission.loaded,
    commission: store.commission.commission,
    error: store.commission.error,

    creatingNYVoucher: store.newMerchantLanding.createVouchersLoading,
    createdNYVoucher: store.newMerchantLanding.createVouchersLoaded,
    NYVoucherdata: store.newMerchantLanding.createVouchersData,
    NYVoucherError: store.newMerchantLanding.createVouchersError,

}), dispatch => bindActionCreators({
  getMerchantCommission,
  createNYVoucher,
  createNYVoucherRequest
}, dispatch))
(class AddNewYearVoucher extends Component{
  constructor(props){
    super(props);
    this.state = { 
      NYVoucherCost: null,
      NYVoucherText:null,
      discountPercentage: null,
      toMerchant:0,
      selectedFile:null,
      voucherFeedBack:"",
    };
  }

  componentDidMount(){
    if(!this.props.loaded && !this.props.loading){
      this.props.getMerchantCommission(this.props.user.accessToken);
    }
    ga('send', 'event', 'New Year Voucher', 'clicked', `merchant_id_${this.props.user.merchantId}`);
  }

  onFileSelect = (e)=>{
    this.setState({selectedFile:e.target.files[0], NYVoucherCost:null, NYVoucherText:null, discountPercentage:null, voucherFeedBack:null});
    Array.from(document.getElementsByClassName("ny-voucher-input")).forEach(ele=>ele.value="")
  }

  onToggle=(e,idx)=>{
    if(idx === 0){
      if(document.getElementsByClassName("checkboxes")[0].checked){
        Array.from(document.getElementsByClassName("checkboxes")).forEach(ele=>ele.checked=true);
      }
      else{
        Array.from(document.getElementsByClassName("checkboxes")).forEach(ele=>ele.checked=false);
      }
    }
    else{ 
      let checkStatus = Array.from(document.getElementsByClassName("checkboxes")).slice(1,).every(ele=>ele.checked);
      document.getElementsByClassName("checkboxes")[0].checked = checkStatus;
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.creatingNYVoucher && this.props.createdNYVoucher){
      toast.success("Your request is submitted successfully")
      this.props.onHide();
    }
    if(prevProps.creatingNYVoucher && this.props.NYVoucherError){
      toast.error(this.props.NYVoucherError)
      this.props.onHide();
    }
  }

  onHide=()=>{
    document.forms.comboVoucherForm.reset();
    this.props.onHide();
  }

  onChange = (e, inputType)=>{
    let comboForm = document.forms.comboVoucherForm;
    let comboFormData = new FormData(comboForm);
    let comboCost = comboFormData.get('combo-cost');
    let discountPercent = comboFormData.get('discount-percent');

    try{
      let actualCost = comboCost - (comboCost*discountPercent)/100;
      let toMerchant = actualCost - (actualCost*this.props.commission.voucherCommission)/100
      if(!isNaN(toMerchant)){
        this.setState({toMerchant})
      }

      if(!isNaN(discountPercent)){
        if(discountPercent <= 20){
          this.setState({voucherFeedBack:'Why don\'t you increase the discount? This seems boring!'})
          return
        }
        if(discountPercent <= 50){
          this.setState({voucherFeedBack:"Better! Make it above 50% to get more attention"})
          return 
        }
        if(discountPercent <= 80){
          this.setState({voucherFeedBack:"Awesome! Your vouchers will sell like hot cake"})
          return 
        }
        if(discountPercent > 80){
          this.setState({voucherFeedBack:"Are you serious? This is crazzzy!"})
          return 
        }
      }
    }
    catch(err){
      console.log(err)
    }
  }
  handleSubmit = (e)=>{
    e.preventDefault();
    let comboForm = document.forms.comboVoucherForm;
    let comboFormData = new FormData(comboForm);
    let comboText = comboFormData.get('combo-text');
    let comboCost = comboFormData.get('combo-cost');
    let discountPercent = comboFormData.get('discount-percent');
    const comboDescription = comboFormData.get('combo-description');
    // let occassions = ['New Year', 'Christmas', "Valentine's day"].map(occ=>comboFormData.get(occ))
    // let OccassionValid = occassions.some(occ=>occ==='on')
    // if(!OccassionValid){
    //   toast.warn('please select occassions for making your voucher live')
    //   return
    // }

    let requestBody = {}
    // requestBody["occassionName"]=["New Year", "Christmas", "Valentine's day"]
    // requestBody["occassionLive"]=occassions

    
    
    if(comboText && comboCost && discountPercent)
      {
      requestBody['offer-text'] = comboText;
      requestBody['offer-cost'] = comboCost;
      requestBody['discountPercent'] = discountPercent;
      requestBody['combo-description'] = comboDescription;
      this.props.createNYVoucherRequest(this.props.user.accessToken, this.props.user.merchantId, this.props.user.userId, this.props.user.salt, comboText, comboCost, discountPercent, comboDescription)
      // this.props.createNYVoucher(this.props.user.accessToken, this.props.user.merchantName, requestBody, null);
      sendEvent({
        event: "new-year-voucher-create",
        page_type: 'Feed',
        page_name: 'merchant_portal',
        actor_id: this.props.user.merchantId,
        subject_merchant_id: this.props.user.merchantId,
        payload:JSON.stringify(requestBody)
    });
    }
    else {
      let attachedFile = comboFormData.get('ny-request-attachment');
      this.props.createNYVoucherRequest(this.props.user.accessToken, this.props.user.merchantId, this.props.user.userId, this.props.user.ultronToken, comboText, comboCost, discountPercent, comboDescription)
      // this.props.createNYVoucher(this.props.user.accessToken, this.props.user.merchantName, requestBody, attachedFile);
      sendEvent({
        event: "navratra-voucher-create",
        page_type: 'Feed',
        page_name: 'merchant_portal',
        actor_id: this.props.user.merchantId,
        subject_merchant_id: this.props.user.merchantId,
        payload:"attachment"
      })
    }
    
    ga('send', 'event', 'New Year Voucher', 'requested', `merchant_id_${this.props.user.merchantId}`);
  }

  render(){
    return(
      <div>
        <Modal dialogClassName='full-modal combo-voucher-modal ny-voucher-modal'
          show={this.props.show}
          onHide={this.onHide} >
          <Modal.Header style={{textAlign:"center",backgroundColor:"#1c003d",backgroundSize:"100% 100%", backgroundImage:`url(${particles})`, height:'10%'}} className="" closeButton>
            <h2 style={{width:"100%", color:'white', padding: window.innerWidth < 756 ? "0 10%" : null }}>Launch Special New Year vouchers</h2>
          </Modal.Header>
          <Modal.Body>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <form id="comboVoucherForm" action="" style={{minWidth:'80%'}} onSubmit={this.handleSubmit}>
              {/* <div className="interval-LAUNCH NOW
selector" style={{margin:'10px auto', textAlign:'center'}}>
                {['All', 'New Year', 'Christmas', "Valentine's day"].map((text, idx)=>
                  <span key={idx} style={{marginRight:'10px'}}><input className="checkboxes" onChange={e=>this.onToggle(e,idx)} type="checkbox" name={text} style={{marginRight:'5px'}}/>{text}</span>)}
              </div> */}
              <div className="body ny-body" style={{marginTop: '10%', marginBottom: '5%'}}>
                <div className="entry-container">
                    <div className="combo-card ny-card">
                      <div style={{width:'100%'}}>
                        <input className="ny-voucher-input" style={{width:"40%"}} name='combo-text' type="text" required={!this.state.selectedFile} placeholder="Offer name (Eg: NYE, X-mas)">
                        </input>
                      </div>
                      <div style={{width:'100%'}}>
                          <div style={{display:'inline-block', width:'50%', overflow:'hidden'}}>
                            <input className="ny-voucher-input" style={{width:"80%"}} onChange={(e)=>this.onChange(e, 'mrp')} type="number" name='combo-cost' required={!this.state.selectedFile} pattern="[0-9]+" placeholder="MRP of offer">
                            </input>
                          </div>
                          <div style={{display:'inline-block', width:'50%', overflow:'hidden', whiteSpace:'no-wrap'}}>
                            <div style={{display:'flex'}}>
                              <input className="ny-voucher-input" type="number" onChange={(e)=>{this.onChange(e, 'discount')}} name='discount-percent' required={!this.state.selectedFile} pattern="[0-9]+" min="0" max="100" placeholder="Discount" style={{width:'80%'}}>
                              </input>
                              <span style={{width:'20%', padding:'6px'}}>%</span>
                            </div>
                          </div>
                      </div>
                      {this.props.commission && <div style={{width:'100%', padding:'6px'}}>
                          You get: {this.state.toMerchant.toFixed(2)}
                      </div>}
                      <div style={{padding: '10% 0% 10%'}}>
                        <textarea name='combo-description' type="text" maxLength='300' required placeholder={`Eg: Female stag- (8- veg starters + 8- non veg starters) +  Unlimited IMFL Drinks/Cocktails/Mocktails/Soft drinks `} style={{padding: '5%', width: '100%', height: '120px', border: '1px solid rgba(0,0,0,0.12)'}}>
                        </textarea>
                      </div>
                    </div>
                </div>
              </div>
              {this.state.voucherFeedBack &&  <div className="discount-feedback" style={{fontSize:'12px', color:'#00bfa5', textAlign:'center'}}>{this.state.voucherFeedBack}</div>}
              {/* <div style={{textAlign:'center', margin:'10px auto', color:'rgba(0,0,0,0.54)'}}>OR</div> */}
              {/* <div className="file-chooser" style={{display:'flex', margin:'10px auto', height:'40px', background:'rgba(69, 142, 255, 0.1)', borderRadius:'4px', border:'1px dashed #458eff'}}>
              <label style={{height:'100%', width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around', padding:'0'}} htmlFor="ny-voucher-request-upload" className="pointer">
                <div><i className="fa fa-paperclip">&nbsp;</i>
                <span style={{fontWeight:'100'}}>Got multiple vouchers? Upload details </span>
                </div>
                <span style={{fontWeight:'100', color:'#338aed'}}>{this.state.selectedFile && this.state.selectedFile.name}</span>
              </label>
                <input type="file" style={{display:'none'}} name="ny-request-attachment" id="ny-voucher-request-upload" onChange={this.onFileSelect}/>
              </div> */}
              <input disabled={this.props.creatingNYVoucher} type="submit" value={!this.props.creatingNYVoucher ? "submit" : "submitting"} className="pointer large-btn large-btn2" style={{width:'40%', margin:'10px auto', borderRadius:'4px'}}></input>
              </form>
              </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
})
