import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import FbShare from '../FbShare/FbShare';

const ga = window.ga;

export default class LikeComment extends Component{
  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      shareModal: false
    };
  }
  handleLike = (e) => {
    e.preventDefault();
    ga('send', 'event', 'Feed', 'post_like', 'post_like');
    this.props.like(this.props.user.accessToken,
      this.props.post.post_id,
      this.props.post.author_profile.user_id);
  }

  handleComment = (e) => {
    e.preventDefault();
    ga('send', 'event', 'Feed', 'post_comment', 'post_comment');
    let comment = this.refs.comment.value;
    this.props.comment(this.props.user.accessToken,
      this.props.post.post_id,
      this.props.post.author_profile.user_id,
      comment);
    comment = '';
    this.setState({showModal: false});
  }

  hideModal = (e) => {
    e.preventDefault();
    this.setState({showModal: false});
  }

  hideShareModal(){
    this.setState({shareModal:false});
  }



  onShare(){
    ga('send', 'event', 'Social Actions', 'Share', 'Merchant Feed page');
    this.setState({shareModal : true});
  }

  render() {
    return (
      <div className="feed-social-activity-wrapper">
        <ul>
          <li>
            {!!this.props.post.like_id && this.props.post.like_id != -1 ?
              <a>
                <span style={{color: 'rgb(243, 96, 87)'}} className="fa fa-heart"></span>Like</a>
              :
              <a style={{color: '#637e8c'}} onClick={this.handleLike}>
                <span className="fa fa-heart"></span>Like
              </a>
            }
          </li>
          <li><a onClick={() => this.setState({showModal: true})}><span className="fa fa-commenting"></span>Comment</a></li>
          <li><a id="fb_share_button" onClick={this.onShare.bind(this)} ><span className="fa fa-share-alt"></span>Share</a></li>
        </ul>

        {/*comment modal*/}
        <Modal show={this.state.showModal} dialogClassName="small-modal-box customer-comment"
          onHide={this.hideModal}>
          <div>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="ReviewComment">
                <h2 id="getCommentCount"><span className="review-cooment-icon"></span>Comment</h2>
              </div>
              <div id="CustomersComment_bx">
                <div className="customer-review-box" id="getCustomerComments">
                    <div className="row">
                      <div className="col-xs-12">
                        <div id="ReviewReplyBox" className="review-reply-comment-tex">
                          <textarea style={{"border-color":"#888080"}} placeholder="Leave your Comment here ... "
                            ref="comment" className="review-comment-box form-control"
                            rows="3" cols="4" >
                          </textarea>
                          <p><a style={{"cursor":"pointer" }} className="review-comment-save pull-right"
                            onClick={this.handleComment}>Comment</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
        {this.state.shareModal && <FbShare facebookId = {this.props.user.facebookId}
        accessToken = {this.props.user.accessToken}
        userName={this.props.post.author_profile.first_name}
        merchantName={this.props.post.merchant_profile.name}
        rating={this.props.post.rating}
        review={this.props.post.question_response_content}
        postId={this.props.post.post_id}
        hideShareModal = {this.hideShareModal.bind(this)}
        />}
      </div>
    );
  }
}
