import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { load as getMerchants, getReferrerDetails, setReferralFlag, getMerchantListFromPhone } from '../../redux/modules/merchantList';
import { claimBusiness, getImpressions, changeLanguage, getCustomerPreference, saveMerchantContacts, getBppBrands,createBppPsr } from '../../redux/modules/claimBusiness';
import { getNewMerchantCustomerData } from '../../redux/modules/newMerchantLanding';
import HeaderLogin from '../Header/HeaderLogin';
import SearchBusiness from './SearchBusiness';
import SearchBusinessDialog from './SearchBusinessDialog';
import CustomerPosts from './CustomerPosts';
import Testimonials from './Testimonials';
import LocalityWidget from './LocalityWidget'
import {countries} from '../../constants';
import CustomerPreference from './CustomerPreference';
import Footer from './Footer'

import logos2 from '../../images/logos2.png';
import productLogos from '../../images/product.png';
import productLogosMobile from '../../images/product-mobile.png';
import { setSource, set, get, MERCHANT_ID, CATEGORY_ID, REFERRAL, PHONE, COUNTRY, MERCHANT_LOGO } from '../../helpers/LocalStorageHelper'
import SurveyFeedback from './SurveyFeedback';
import { isValidPhone, sendEvent, currencyString, createMCPLog, constructEventNameForBq } from '../../helpers/Helpers';
import banner from '../../images/banner2.svg';
import bannerClaim from '../../images/banner-texture-mobile.jpg';
import bg1 from '../../images/dottedBG.png';
import mobileBanner from '../../images/banner-mob.svg';
import star from "../../images/rating2.svg";
import reviews from "../../images/reviews2.svg";
import views from "../../images/views2.svg";
import logoBusiness from '../../images/logo-business.svg';
import defaultProfilePic from "../../images/Store_dp.svg";
import FosHeader from '../Upgradations/UpsellHeader';
import phoneImg from '../../images/new_phone.svg';
import whitePhoneImg from '../../images/iphone-white.jpg';
import phoneImgJakarta from '../../images/mobile_jakarta.svg';
import beautyScreenshot from '../../images/Beauty.jpg';
import fnbScreenshot from '../../images/F&B.jpg';
import groceryScreenshot from '../../images/grocery.jpg';
import fashionScreenshot from '../../images/Fashion.jpg';
import fnbScreenshotJakarta from '../../images/F&B_jakarta.jpg';
import bottomBar from '../../images/bottom_bar.png';
import food from '../../images/food.svg';
import beauty from '../../images/beauty.svg';
import grocery from '../../images/grocery.svg';
import fashion from '../../images/fashion.svg';
import fnbLogos from '../../images/fnb_logos.png';
import beautyLogos from '../../images/beauty_logos.png';
import fashionLogos from '../../images/fashion_logos.png';
import groceryLogos from '../../images/grocery_logos.png';
import graph1 from '../../images/graph-1.svg';
import graph2 from '../../images/graph-2.svg';
import increaseOrder from '../../images/increase-order.svg';
import getMoreVists from '../../images/get-more-visit-icon.svg';
import increaseRepeat from '../../images/increase-repeat-icon.svg';
import groceryMarketing from '../../images/group-73.svg';


import fnbLogosMob from '../../images/fnb_logos_mobile.png';
import beautyLogosMob from '../../images/beauty_logos_mobile.png';
import fashionLogosMob from '../../images/fashion_logos_mobile.png';
import groceryLogosMob from '../../images/grocery_logos_mobile.png';

import indBrandLogo from '../../images/indonesia_brand_logo.png';
import indBrandLogoMob from '../../images/indonesia_brand_logo_mobile.png';

import BenefitsSection from './BenefitsSection';
import Slider from './BrandSlider';
import '../BrandPrivileges/BrandPrivileges.css';
import './Claim.css'
import LocalityBanners from './LocalityBanners';
import MagicpinInfluence from './MagicpinInfluence';
import SelectLanguage from './SelectLanguage';

import language from './languageConst.js';
import LanguageBar from './LanguageBar';
import ExitPopup from './ExitPopup';
import ReactHtmlParser from "react-html-parser";
import {toast} from "react-toastify";
import OtpVerificationMagicStore from "../Otp/OtpVerificationMagicStore";
import {sendOtp, verifyOtp} from "../../redux/modules/verifyOtpMagicStore";
import * as FullStory from '@fullstory/browser';
import SearchBusinessLimited from './SearchBusinessLimited';

const ga = window.ga;
let cssYPos = 0;

const categoryImg = {
  "1":{ "screenshot": fnbScreenshot},
  "2":{ "screenshot": beautyScreenshot},
  "5":{ "screenshot": groceryScreenshot},
  "3":{ "screenshot": fashionScreenshot},
} 

export default connect(store => ({
  storeslist: store.merchantList.data,
  storesloading: store.merchantList.loading,
  storesloaded: store.merchantList.loaded,
  storeserror: store.merchantList.error,
  referrerData: store.merchantList.referrer_data,

  submitted: store.claimBusiness.submitted,
  submiterror: store.claimBusiness.error,
  impressionsCount: store.claimBusiness.impressionsCount,
  language: store.claimBusiness.language,
  customerPreference: store.claimBusiness.customerPreference,
  bppBrands: store.claimBusiness.bppBrands,

  summary: store.newMerchantLanding.summary,
  merchantInfo: store.newMerchantLanding.merchantInfo,
  postsLoading: store.newMerchantLanding.posts_loading,
  otpSent: store.verifyOtpMagicStore.otpSent,
  otpSending: store.verifyOtpMagicStore.otpSending,
  otpSendingError: store.verifyOtpMagicStore.error,

  psrLoading: store.claimBusiness.psrLoading,
  psrData: store.claimBusiness.psrData,

  merchantList: store.merchantList.merchantList,

}), dispatch => bindActionCreators({
  getMerchants,
  claimBusiness,
  changeLanguage,
  getImpressions,
  getNewMerchantCustomerData,
  getReferrerDetails,
  setReferralFlag,
  getCustomerPreference,
  getBppBrands,
  saveMerchantContacts,
  createBppPsr,
  sendOtp,
  verifyOtp,
  getMerchantListFromPhone
}, dispatch))
  (class ClaimNew extends Component {
    constructor(props) {
      super(props);
      this.state = {
        page: 'search',
        showDialog: false,
        openTopDiv: false,
        brandsTab: "retail",
        ABTesting: false,
        selectedCategory: 1,
        showLanguageDialog: false,
        shownLanguageDialog: false,
        showLanguageBar:false,
        sendSixDigitOtp: true
      };
      this.url = '';
      this.isDirectLink = false;
      this.languageCheck = false;
      this.language = props.language || get('landingLanguage')
      this.categoryArray = [
        {
          categoryId: 1,
          categoryTitle: language[this.language].foodConst,
          categoryLogo: food,
        },
        {
          categoryId: 2,
          categoryTitle: language[this.language].beautyConst,
          categoryLogo: beauty,
        },
        {
          categoryId: 3,
          categoryTitle: language[this.language].fashionConst,
          categoryLogo: fashion,
        },
        {
          categoryId: 5,
          categoryTitle: language[this.language].groceryConst,
          categoryLogo: grocery,
        }
      ]

      this.eventObj = {
        useCase: 'view',
        pageName: 'claim'
      }; 
    }

    handleListItemClick = (language) => {
      sendEvent({
        event: `language_select_${language}`,
        page_type: 'new_merchant_landing',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: this.getMerchantId(),
      });
    createMCPLog({
        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
        merchant_id: this.getMerchantId(),
        event: `language_select_${language}`,
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct'
    });
      this.setState({
        showLanguageDialog: false
      })
      this.props.changeLanguage(language);
    }

    getCategoryLogos = (categoryId, source="web", country = "India") => {
      if(country && country.toUpperCase() === countries.INDONESIA){
        if(source == "web")
          return indBrandLogo
        else
          return indBrandLogoMob
      }

      switch(categoryId){
        case 1: 
          if(source == "web")
            return fnbLogos
          else 
            return fnbLogosMob
        case 2:
          if(source == "web")
            return beautyLogos
          else
            return beautyLogosMob
        case 3:
          if(source == "web")
            return fashionLogos
          else
            return fashionLogosMob
        case 5:
          if(source == "web")
            return groceryLogos
          else
            return groceryLogosMob
        default: 
          if(source == "web")
            return fnbLogos
          else 
            return fnbLogosMob
      }
    }

    showOtpDialog = () => {
      if (!this.state.phoneNumber) {
        toast.warn("Please provide a valid phone number")
        return;
      }
      let subjectedPhoneNumber = this.state.phoneNumber.toString();
      if (subjectedPhoneNumber.length === 10) {
        subjectedPhoneNumber = "91" + subjectedPhoneNumber;
      }
      let isValidIndianMobile = /^(?:91)[6-9][0-9]{9}$/.test(subjectedPhoneNumber)
      if (isNaN(this.state.phoneNumber) || !isValidIndianMobile) {
        toast.warn("Please provide a valid phone number")
        return;
      }

      this.props.sendOtp(this.state.phoneNumber.toString(), this.state.sendSixDigitOtp)
      this.setState({
        otpDialog: true
      })
    }

    hideOtpDialog = () => {
      this.setState({
        otpDialog: false
      })
    }

    setOtpRetryState = () => {
      this.props.sendOtp(this.state.phoneNumber.toString(), this.state.sendSixDigitOtp)
      this.setState({
        otpRetry: true
      })
    }

    verifyOtp = (otp, phone) => {
      this.props.getMerchantListFromPhone(phone, otp).then(res => {
        try {
          localStorage.setItem('merchantList', JSON.stringify(this.props.merchantList));
        } catch (err) {}
        this.setState({
          otpDialog: false,
        })
        this.savePhoneNumberInLocalStorage()
      }).catch()
    }

    debouncedhandlePhoneChange = (e) => {
      this.setState({phoneNumber: e.target.value});
    }

    savePhoneNumberInLocalStorage = () => {

      if (!isValidPhone(this.state.phoneNumber)) {
        console.log("Phone number is invalid", this.state.phoneNumber)
        return
      }
      localStorage.setItem("subjectPhoneNumber", this.state.phoneNumber)
      this.setState({getPhoneNumber: false})
      createMCPLog({
        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
        event: 'claim_page',
        page_type: 'claim_page',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
      })
    }

    phoneNumberKeyPress = (e) => {
      if (e.keyCode === 13) {
        this.showOtpDialog()
      }
    }

    componentWillMount() {
      FullStory.init({ orgId: 'o-1A16GJ-na1' });

      this.params = new URLSearchParams(this.props.location.search);

      const merchantId = this.getMerchantId(true)
      this.props.changeLanguage("english" )

      if (merchantId) {
        var phone = this.getPhoneN(true)
        if(phone){
          set(PHONE, phone)
          localStorage.setItem("subjectPhoneNumber", phone)
        }
        set(MERCHANT_ID, merchantId)
        this.isDirectLink = true;
        this.props.getMerchants('*', '*', merchantId);
        this.props.getNewMerchantCustomerData(merchantId).then(() => {
          if (this.props.merchantInfo){
            if(this.props.merchantInfo.merchantId % 2 == 1){
              this.setState({
                ABTesting: true
              })
            }
            else{
              this.setState({
                ABTesting: false
              })
            }
            set(CATEGORY_ID, this.props.merchantInfo.categoryId)
            set(COUNTRY, this.props.merchantInfo.country)
            set(MERCHANT_LOGO, this.props.merchantInfo.profilePic)
            this.props.getCustomerPreference(merchantId,this.props.merchantInfo.localityId)
            if(!localStorage.getItem("landingLanguage")){
              if(this.props.merchantInfo.country && this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA)
                this.props.changeLanguage("bahasa" )
              else
                this.props.changeLanguage(this.props.merchantInfo.lang || "english" )
            }

          }            
        })
      }
      else if (localStorage.getItem("landingMerchantId")) {
        this.changePage();
      }
      if (this.params.has('referrer')) {
        var referrerId = parseInt(this.params.get('referrer'), 13)
        this.props.getReferrerDetails('*', '*', referrerId).then((res) => {
          if (this.props.referrerData && Array.isArray(this.props.referrerData) && this.props.referrerData.length && this.props.referrerData[0].is_funded) {
            this.props.setReferralFlag(this.params.get('referrer'))
            set(REFERRAL, referrerId)
          }
        })
      }
      if (this.params.has('language')){
        var language = this.params.get('language').toLowerCase();
        if(language && ["english","hindi","bahasa","marathi","telugu","kannada"].includes(language))
          this.props.changeLanguage(language);
      }

      if (localStorage.getItem("landingLanguage")){
        this.props.changeLanguage(localStorage.getItem("landingLanguage"))
      }

      if(!!window.fcWidget )
      { 
          window.fcWidget.hide();
          document.getElementById('custom_chat_button').style.visibility = "hidden"
      }
    }

    upsellFallback = () => {
      this.setState({showFosHeader:false})
    }

    scrollToBottom = () => {
      if(this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91){
        cssYPos+=3;  
        if(cssYPos >=this.messagesEnd.scrollHeight ){
          cssYPos = -1000
        }
        this.messagesEnd.scrollTo({
          top: cssYPos,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
    
    componentDidMount() {
      const phoneNumber = localStorage.getItem("subjectPhoneNumber")
      if (phoneNumber && isValidPhone(phoneNumber)) {
        this.setState({getPhoneNumber: false})
      } else {
        this.setState({getPhoneNumber: true})
      }

      if (this.params.has('utm_campaign')) {
        ga('set', 'dimension2', this.params.get('utm_campaign'));
        setSource(this.params.get('utm_campaign'))
        if(this.params.get('utm_campaign') == "field_onboarding"){
          this.showClaimDialog();
          this.props.changeLanguage('english');
        }
      }
      else {
        ga('set', 'dimension2', 'direct');
        setSource('direct')
      }
      if (this.params.has('showSurvey')) {
        this.setState({ showSurveyDialog: true })
      }
      if (this.params.has('utm_source') && this.params.get('utm_source') == 'fos'){
        this.setState({showFosHeader : true})
      }

      ga('set', 'page', 'NewMerchantLanding');
      ga('send', 'pageview');
      this.utm_source = this.params.has('utm_source') ? this.params.get('utm_source') : 'basic'
      sendEvent({
        event: 'new_merchant_landing',
        page_type: 'new_merchant_landing',
        subject_type: 'merchant',
        utm_source: this.utm_source,
        utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
        utm_content: this.props.location.search,
        subject_merchant_id: this.getMerchantId(),
      });   
      createMCPLog({
        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
        merchant_id: this.getMerchantId(),
        event: 'new_merchant_landing',
        utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct'
      });
      window.addEventListener('scroll', this.handleScroll);
            if (document.getElementById('top-header-bar'))
                document.getElementById('top-header-bar').classList.add('bpp')
      if(window.innerWidth > 768)
        this.interval = setInterval(this.scrollToBottom, 60);
        this.closeLanguageBarInt = setInterval(this.closeLanguageBar, 15000);
      this.props.getBppBrands()

      if(this.props.merchantInfo){
        this.setState({
          selectedCategory: this.props.merchantInfo.categoryId
        })
      }

      if(!!window.fcWidget )
      { 
          window.fcWidget.hide();
          document.getElementById('custom_chat_button').style.visibility = "hidden"
      }
    }

    componentDidUpdate(preProps, preState){
  
      if(preState.page!==this.state.page || preState.getPhoneNumber!==this.state.getPhoneNumber)
      {
          if(this.state.getPhoneNumber && this.state.page=== 'search')
          {
            this.sendUIGGenericEvents("new_merchant_sign_up_page_landing", "");
          }
          if(this.state.getPhoneNumber===false && this.state.page=== 'search')
          {
            this.sendUIGGenericEvents("new_merchant_search_store_page_landing", "");
          }
          if(this.state.getPhoneNumber===false && this.state.page==='claim')
          {
            this.sendUIGGenericEvents("new_merchant_welcome_page_landing", "");
          }
      }


    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll)
      if (document.getElementById('top-header-bar'))
          document.getElementById('top-header-bar').classList.remove('bpp')
      if(window.innerWidth > 768)
        clearInterval(this.interval);
        clearInterval(this.closeLanguageBarInt);
  }


    componentWillReceiveProps(nextProps) {
      if (nextProps.storesloaded && nextProps.storeslist && Array.isArray(nextProps.storeslist) && nextProps.storeslist.length && this.isDirectLink) {
        localStorage.setItem('landingMerchant', nextProps.storeslist[0].merchant_name);
        localStorage.setItem('landingMerchantId', nextProps.storeslist[0].merchant_id);
        localStorage.setItem('landingMerchantRating', nextProps.storeslist[0].rating);
        localStorage.setItem('landingMerchantReviews', nextProps.storeslist[0].total_txn_count);
        localStorage.setItem('landingLocality', nextProps.storeslist[0].locality);
        localStorage.setItem('landingCity', nextProps.storeslist[0].city);
        localStorage.setItem('landingMerchantLogo', nextProps.storeslist[0].merchant_logo);
        this.isDirectLink = false;
        console.log('dsdsd');
        this.changePage()
      }
      if(nextProps.language && nextProps.language != this.props.language){
        this.categoryArray = [
          {
            categoryId: 1,
            categoryTitle: language[nextProps.language].foodConst,
            categoryLogo: food,
          },
          {
            categoryId: 2,
            categoryTitle: language[nextProps.language].beautyConst,
            categoryLogo: beauty,
          },
          {
            categoryId: 3,
            categoryTitle: language[nextProps.language].fashionConst,
            categoryLogo: fashion,
          },
          {
            categoryId: 5,
            categoryTitle: language[nextProps.language].groceryConst,
            categoryLogo: grocery,
          }
        ]
      }
      if(nextProps.summary && nextProps.summary.ratingsData){
           this.setState({
             ratingValue:nextProps.summary.ratingsData.rating_value,
             reviewCount:nextProps.summary.ratingsData.total_rating_count
           })
         }

      if(!nextProps.otpSent && nextProps.otpSendingError) {
        toast.error(nextProps.otpSendingError.message || 'Please try again later')
      }
    }

    handleScroll = (event) => {
      event.preventDefault();
      let winScroll = window.scrollY;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrolled = (winScroll / height) * 100;
      let floaterLength = window.innerWidth > 768 ? 11 : 7;
      if (scrolled > floaterLength){
          this.setState({ 'openTopDiv': true , showLanguageBar: false})
          // if(!this.state.shownLanguageDialog){
          //    this.setState({showLanguageDialog: true, shownLanguageDialog : true})
          // }
        }
      else
          this.setState({ 'openTopDiv': false })

  }

    showAddMerchantDialog = () => {
      // this.setState({
      //   merchant_not_found: true,
      //   showDialog: false,
      // })
      this.props.push('/addOutlet');
    }
    hideAddMerchantDialog = () => {
      this.setState({
        merchant_not_found: false,
        showDialog: false
      })
    }

    hideSurveyDialog = () => {
      this.setState({
        showSurveyDialog: false
      })
    }

    changePage = () => {
      this.merchant = localStorage.getItem("landingMerchant");
      this.rating = localStorage.getItem("landingMerchantRating");
      this.merchant_id = localStorage.getItem("landingMerchantId");
      this.reviews = localStorage.getItem("landingMerchantReviews");
      window.gtag('event', 'page_view', { 'send_to': 'AW-940625898', 'dynx_itemid': this.merchant_id, 'dynx_pagetype': 'claim', 'local_id': this.merchant_id, 'local_pagetype': 'claim' });
      this.props.getImpressions(this.merchant_id).then((res) => {
        this.setState(
          { impressions: this.props.impressionsCount ? parseInt(this.props.impressionsCount) : 0 }
        )
      });
      this.props.getNewMerchantCustomerData(this.merchant_id).then(() => {
        if (this.props.merchantInfo){
          if(this.props.merchantInfo.merchantId % 2 == 1){
            this.setState({
              ABTesting: true
            })
          }
          else{
            this.setState({
              ABTesting: false
            })
          }
          set(CATEGORY_ID, this.props.merchantInfo.categoryId)
          set(COUNTRY, this.props.merchantInfo.country)
          set(MERCHANT_LOGO, this.props.merchantInfo.profilePic)
          this.props.getCustomerPreference(this.merchant_id, this.props.merchantInfo.localityId)
          if(!localStorage.getItem("landingLanguage")){
            if(this.props.merchantInfo.country && this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA){
              this.props.changeLanguage('bahasa');
            }
            else
              this.props.changeLanguage("english");
          }
        }
      });
      if (this.rating) {
        this.rating = parseFloat(this.rating).toFixed(1);
      }
      this.setState({ page: 'claim' });
    };

    showClaimDialog = () => {
      if(this.params.has('utm_campaign') && this.params.get('utm_campaign') === 'field_demo'){
        alert('this is demo mode');
        return;
      }
      var merchantId = get('landingMerchantId') ? get('landingMerchantId') : "";
      var phoneNumber = get('subjectPhoneNumber') ? get('subjectPhoneNumber'): "";
      if(this.props.merchantInfo && this.props.merchantInfo.country.toUpperCase() === countries.INDIA){
        this.props.createBppPsr(parseInt(merchantId), phoneNumber).then(() => {
          if(this.props.psrData && this.props.psrData.status.toUpperCase() === "SUCCESS"){
            const packageData = this.props.psrData.psDto;
            if(packageData)
              this.loginShortUrl = packageData.smsLink;
              window.location.href = this.loginShortUrl;
          }
          else{
            this.setState({ showDialog: true });
          }
        });
      }
      else {
        toast.info("Please sign up using phone number")
        // this.setState({ showDialog: true });
      }
    }

    showSearch = () => {
      this.sendUIGGenericEvents("notYourBusClick", "")
      this.setState({ page: 'search' });
    }

    hideClaimDialog = () => {
      this.setState({ showDialog: false });
    }

    push = (url) => {
      if (localStorage.getItem("landingMerchantId"))
        this.props.push(url);
      else {
        this.url = url;
        this.showClaimDialog();
      }
    }

    changeBrandsTab = (tab) => {
      sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: `merchant_logos_${tab}`,
        page_type: 'new_merchant_landing'
      });
      createMCPLog({
        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: `merchant_logos_${tab}`,
        utm_campaign: localStorage.getItem('landingSource') || ''
      });
      this.setState({brandsTab:tab})
    }

    changeCategory = (categoryId) => {
      sendEvent({
        utm_campaign: localStorage.getItem('landingSource') || '',
        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'change_category_logos',
        page_type: 'new_merchant_landing'
      })
      createMCPLog({
        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
        utm_campaign: localStorage.getItem('landingSource') || '',
        merchant_id: localStorage.getItem('landingMerchantId') || '',
        event: 'change_category_logos'
      });
      this.setState({
        selectedCategory: categoryId
      })
    }

    closeLanguageBar = () => {
      this.setState({
        showLanguageBar: false
      })
    }

    isLiquorMerchant = () => {
      let liquorCategories = [
        "LIQUOR",
        "LIQOR",
        "LIQUOR STORE",
        "LIQOR STORE",
      ]
      if (this.props.merchantInfo && this.props.merchantInfo.categoryId == 5 && this.props.merchantInfo.primarySubcategory && liquorCategories.includes(this.props.merchantInfo.primarySubcategory[0].toUpperCase())) {
        return true
      }
      return false
    }

    getMerchantId = (onlyParams = false) => {
      this.params = new URLSearchParams(this.props.location.search);
      let merchantId = ""

      try {
        if(this.params.has('merchant')){
          merchantId = parseInt(this.params.get('merchant'), 13)
        }else if(this.params.has('merchantID')){
          merchantId = parseInt(this.params.get('merchantID'))
        }else{
          merchantId = onlyParams ? "" : localStorage.getItem('landingMerchantId') || ""
        }
      } catch (error) {}
     
      return merchantId
    }

    getPhoneN = (onlyParams = false) => {
      this.params = new URLSearchParams(this.props.location.search);
      let phoneNumber = ""

      try {
        if(this.params.has('phone')){
          phoneNumber = parseInt(this.params.get('phone'), 13)
        }else if(this.params.has('phoneN')){
          phoneNumber = parseInt(this.params.get('phoneN'))
        }else{
          phoneNumber = onlyParams ? "" : localStorage.getItem("subjectPhoneNumber") || ""
        }
      } catch (error) {}
     
      return phoneNumber
    }

    sendUIGGenericEvents = (eventName, useCase = "", extraFeilds = {}) => {
      try{
       this.eventObj.useCase = useCase ? useCase : this.eventObj.useCase
       this.eventName = constructEventNameForBq(this.eventObj, eventName);

       this.utm_source = this.params.has('utm_source') ? this.params.get('utm_source') : 'basic'
       sendEvent({
         event: this.eventName,
         page_type: 'new_merchant_landing',
         subject_type: 'merchant',
         utm_source: this.utm_source,
         utm_medium: this.params.has('utm_medium') ? this.params.get('utm_medium') : '',
         utm_campaign: this.params.has('utm_campaign') ? this.params.get('utm_campaign') : localStorage.getItem('landingSource') || 'direct',
         utm_content: this.props.location.search,
         subject_merchant_id: this.getMerchantId(),
         actor_id: this.getMerchantId(),
         device: window.innerWidth > 768 ? 'desktop' :'mobile',
         extraFeilds: extraFeilds ? JSON.stringify(extraFeilds) : "",
         subject_phone : this.getPhoneN()
       });
       }catch(err){}
     }

    render() {
      let languageConst = this.props.language ? language[this.props.language] : language["english"];
      if(!this.languageCheck && this.props.merchantInfo && this.props.merchantInfo.lang && !localStorage.getItem("landingLanguage")){
        this.languageCheck = true
        if(["english","hindi","bahasa","marathi","telugu","kannada"].includes(this.props.merchantInfo.lang)){
          this.props.changeLanguage(this.props.merchantInfo.lang)
        }
      }
      var bannerUrl = banner;
      bannerUrl = "url(" + bannerUrl + ")";
      var claimBannerUrl = bannerClaim;
      claimBannerUrl = "url(" + claimBannerUrl + ")";
      var mobileBannerUrl = mobileBanner;
      mobileBannerUrl = "url(" + mobileBannerUrl + ")";

      const screenshot = (this.props.merchantInfo && this.props.merchantInfo.country && (this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA ? fnbScreenshotJakarta :  (this.props.merchantInfo.categoryId && categoryImg[this.props.merchantInfo.categoryId] ? categoryImg[this.props.merchantInfo.categoryId].screenshot : categoryImg["1"].screenshot))) || categoryImg["1"].screenshot
      const phoneImage = (this.props.merchantInfo && this.props.merchantInfo.country && ( this.props.merchantInfo.country.toUpperCase() === countries.INDONESIA ? phoneImgJakarta :phoneImg)) || phoneImg
      // console.log(this.props.merchantInfo )
      return (
        <div>
          {/* <div onClick={() => {window.fcWidget.open(); window.fcWidget.show(); }} style={{position: 'fixed', zIndex: '1000', bottom: window.innerWidth > 768 ? '6%' : '9%', right: window.innerWidth > 768 ? '6%' : '9%', cursor: 'pointer' }}>
            <div style={{ minWidth: window.innerWidth > 768 ? '200px' : '145px', marginLeft: '8%', display: 'flex', justifyContent: 'center', backgroundColor: 'blue', border: '2px solid blue', borderRadius: '50px', color: '#fff', padding: window.innerWidth > 768 ? '10px 10px' : '2% 2%',boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)"}} >
              <div style={{alignSelf: 'center', fontFamily: 'robotobold', fontSize: window.innerWidth > 768 ? '16px': ''}}> Having trouble <br/> signing up? </div>
            </div>
          </div> */}
          <div style={{ display: (this.state.showSurveyDialog && window.innerWidth < 800) ? 'none' : 'block' }}>
            {this.state.showLanguageBar && <LanguageBar categoryId={this.props.merchantInfo ? this.props.merchantInfo.categoryId: 1} language={this.props.language} closeLanguageBar={this.closeLanguageBar} />}
            {this.state.showFosHeader && <HeaderLogin user={this.props.user} login={this.props.login}
              loggingIn={this.props.loggingIn}
              loginError={this.props.loginError}
              verifyOtp={this.props.verifyOtp}
              push={this.props.push} 
              country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country : "india"} />}
              
            {!this.state.showFosHeader &&
              <div>
                {this.state.page.localeCompare("search") == 0 &&
                  <header style={{ backgroundColor: "#fff", backgroundImage: window.innerWidth > 768 ? `url(${bg1})` : claimBannerUrl, marginTop:"5%", textAlign:"left", position: "relative" }} className="landing-header">
                    <HeaderLogin user={this.props.user} login={this.props.login}
                      loggingIn={this.props.loggingIn}
                      loginError={this.props.loginError}
                      verifyOtp={this.props.verifyOtp}
                      push={this.props.push} />
                    <div style={{width: window.innerWidth > 768 ? "52%" : "100%", display:"inline-block", float:"left"}}>
                    <section className="header-details-holder" style={{width:window.innerWidth > 768 ? "90%" : "100%"}}>
                      {this.state.getPhoneNumber ?
                          <h1>{languageConst.topHeading}</h1>
                          :
                          <div style={{fontSize:window.innerWidth > 768 ? "28px": "18px", fontFamily:"robotobold"}}>Signed in with {localStorage.getItem("subjectPhoneNumber")}
                            <span style={{cursor:"pointer", marginLeft:"8px", fontSize:window.innerWidth > 768 ? "18px" : "14px", textDecoration: "underline", color:"rgb(239, 28, 116)", marginTop:"-14px"}}
                                  onClick={() => {
                                    localStorage.removeItem("subjectPhoneNumber")
                                    localStorage.removeItem("landingMerchantId")
                                    localStorage.removeItem("merchantList")
                                    this.props.push('/claim')
                                    setTimeout(() => {
                                      window.location.reload()
                              }, 500)
                            }}><br/>Sign in with different number</span>
                          </div>
                      }
                      <h3 style={{ fontFamily: "robotoregular", fontSize:"16px", marginTop:'30px', marginBottom:'8px' }}>{this.state.getPhoneNumber ? languageConst.enterNo : languageConst.searchBusiness}</h3>
                    </section>
                      {this.state.getPhoneNumber ?
                          <div style={{marginTop:"12px"}}>
                            <input className="phone-input" type="number" placeholder="Phone Number"
                                   onChange={this.debouncedhandlePhoneChange}
                                   onKeyDown={(e) => this.phoneNumberKeyPress(e)}
                                   defaultValue={this.state.phoneNumber || ''} name="" id=""
                                   style={{ zIndex: (this.state.otpDialog || this.state.showDialog) ? "0":"1000000"}} />

                            <div className="sign-up" onClick={this.showOtpDialog}
                                 style={this.props.otpSending ? {alpha: '0.2', backgroundColor: "#bbb", zIndex: (this.state.otpDialog || this.state.showDialog) ? "0":"1000000"}: {zIndex: (this.state.otpDialog || this.state.showDialog) ? "0":"1000000"}}>

                              <div style={{width: "100%", textAlign: "center"}}>
                                {this.props.otpSending ? "Signing up..." : "SIGN UP"}
                              </div>

                              {this.props.otpSending ?
                                  <div style={{width: "20%", position: "relative"}}>
                                    <span className="loading"/>
                                  </div>
                                  : ""
                              }
                            </div>

                          </div>
                          :
                          // !this.props.merchantList ? <SearchBusiness showAddMerchantDialog={this.showAddMerchantDialog} changePage={this.changePage} push={this.props.push} language={this.props.language} />:
                          <SearchBusinessLimited merchantList={this.props.merchantList} showAddMerchantDialog={this.showAddMerchantDialog} changePage={this.changePage} push={this.props.push} language={this.props.language} />
                      }
                    {window.innerWidth > 768 && <img style={{height:"40px", paddingLeft:"7%", marginTop:this.state.getPhoneNumber?"10%":"5%"}} src={logos2} />}
                    {window.innerWidth < 768 && <div className="facts">
                      <div className="localityInfoCard" style={{position:"inherit", height:'90px', width:'160px', justifyContent:'left', border:"1px solid #0000000f", borderRadius:"10px 0 0 0", boxShadow:'none'}}>
                        <div className="infoGroup" style={{padding: "0px 10px", margin:"19px 0", width:"100%"}}>
                          <div className="number-locality number-locality-mob" style={{color:"#000000de"}}>{languageConst.business1}</div>
                          <div className="title title-new"  >{languageConst.business12}</div>
                        </div>
                      </div>
                      <div className="localityInfoCard" style={{position:"inherit", height:'90px', width:'160px', justifyContent:'left', border:"1px solid #0000000f", borderRadius:"0 10px 0 0", boxShadow:'none'}}>
                        <div className="infoGroup" style={{padding: "0px 10px", margin:"19px 0", width:"100%"}}>
                          <div className="number-locality number-locality-mob" style={{color:"#000000de"}}>{languageConst.business2}</div>
                          <div className="title title-new" >{languageConst.business22}</div>
                        </div>
                      </div>
                      <div className="localityInfoCard" style={{position:"inherit", height:'90px', width:'160px', justifyContent:'left', border:"1px solid #0000000f", borderRadius:"0 0 0 10px", boxShadow:'none'}}>
                        <div className="infoGroup" style={{padding: "0px 10px", margin:"19px 0", width:"100%"}}>
                          <div className="number-locality number-locality-mob" style={{color:"#000000de"}}>{languageConst.business3}</div>
                          <div className="title title-new" >{languageConst.business32}</div>
                        </div>
                      </div>
                      <div className="localityInfoCard" style={{position:"inherit", height:'90px', width:'160px', justifyContent:'left', border:"1px solid #0000000f", borderRadius:"0 0 10px 0", boxShadow:'none'}}>
                        <div className="infoGroup" style={{padding: "0px 10px", margin:"19px 0", width:"100%"}}>
                          <div className="number-locality number-locality-mob" style={{fontSize:"15px",color:"#000000de"}}>{languageConst.business4}</div>
                          <div className="title title-new" >{languageConst.business42}</div>
                        </div>
                      </div>
                    </div>}
                    </div>
                    {window.innerWidth > 768 &&
                      <div className="parent-scroller">
                         <div>
                          <img style={{marginTop:"2%"}} src={phoneImage} />
                          <div className="screenshot-scroller" style={{right:"34%"}} ref={(el) => { this.messagesEnd = el; }}>
                          <img alt="Feed" src={screenshot} />
                          </div>
                          <img className = "bottom-bar" src={bottomBar} />
                        </div>
                      </div>
                    }

                    {/* <h6 style={{ fontFamily: "robotolight", marginTop:'16px', color:"#fff", fontSize: window.innerWidth > 768 ?  "16px" : "14px" }}><b>45</b> Merchants joined us in the last <b>7 days</b></h6> */}
                    {/* <div className="partner-logos">
                      <img src={lifestyle} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={lakme} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={mcdonalds} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                      <img src={subway} style={{paddingRight:"2%", height: window.innerWidth > 768 ? "" : "25px"}}/>
                    </div> */}
                  </header>
                }
                {this.state.page.localeCompare("claim") == 0 &&
                  <header style={{ backgroundImage: window.innerWidth > 768 ? `url(${bg1})` : claimBannerUrl, backgroundColor: "transparent", marginTop:"3%", height: window.innerWidth > 768 ? "580px" : this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? "440px":"520px", position: "relative" }} className="landing-header">
                    <div className="row" style={{width:"100%", background:"#fff", margin:"0px", display: 'flex' ,top:"0px", position:"fixed", zIndex:"1000",   boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)' }}>
                      <div style={{ margin: 'auto', padding: '0px' }} className={window.innerWidth > 768 ? "col-sm-12 col-xs-8" : "col-sm-12 col-xs-12"}>
                        <div style={{ padding: '10px 15px' }} className="logo login-logo logo-wrapper-new">
                            <img src={logoBusiness} alt="magicpin" style={{height: window.innerWidth > 768 ? '35px' : '25px', marginLeft: window.innerWidth > 768 ? '120px' : '0px', marginTop: window.innerWidth > 768 ? '0px' : '7px !important'}} />
                            <div style={window.innerWidth > 768 ? {float:"right"} :{position: "absolute", top: "22%", right: "26%"}}>
                              <SelectLanguage country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country : "india"} language={this.props.language || "english"} categoryId={this.props.merchantInfo ? this.props.merchantInfo.categoryId:1}/>
                            </div>
                            <a className="btn-o button-header-hover" style = {{float:"right", width: '76px', textAlign: 'center', background: "#ef1c74", borderRadius: '4px', marginRight:window.innerWidth > 768 ? '60px' : '0px'}} onClick={() => this.props.push('/login')}>Login</a>              
                        </div>
                      </div>
                    </div>
                    <section className="header-details-holder merchant-details-header" style={{paddingTop: window.innerWidth > 768 ? "50px" : "90px", paddingLeft: this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? "1%":"5%", display:"inline-block", float:"left", marginLeft: this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? window.innerWidth < 768 ? "2%": "5%": "0px"}}>
                      
                        <div style={{display:"flex", flexDirection:"column"}}>
                          {window.innerWidth > 768 && <img style={{height:"65px", width:"65px"}} src={get(MERCHANT_LOGO) ? get(MERCHANT_LOGO) : defaultProfilePic } />}
                          <span style={{fontFamily:"robotoregular",fontSize: window.innerWidth > 768 ? "20px" : "16px", color:"rgba(0, 0, 0, 0.87)"}}>{languageConst.hey}, <span style={{fontFamily:"robotomedium"}}>{this.merchant}</span></span>
                        </div>
                      { this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) && <h1 style={{fontWeight: "bold",fontFamily: "robotobold",lineHeight: window.innerWidth > 768 ? "1.35":"32px",fontSize: window.innerWidth > 768 ? "32px": "24px", paddingTop:"0px"}}> {languageConst.groceryTopHeading} </h1>}
                      { this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && <h1 style={{fontWeight: "bold",paddingTop:"10px",fontFamily: "robotobold"}}>{languageConst.welcomeConst} {languageConst.topHeading}</h1>}
                      {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) && <h3 style={{fontSize: window.innerWidth > 768 ? "16px": "14px",marginTop:"6px",marginRight:"6px",marginBottom: window.innerWidth > 768 ? "48px":"24px",lineHeight:"20px",color:"rgba(0, 0, 0, 0.87)"}}>{languageConst.grocerySubHeading}</h3>}
                       
                      {this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && !!this.state.ratingValue &&
                        <article>
                          <img src={star} />
                          <div>
                            <h3>{this.state.ratingValue}</h3>
                            <h5>{languageConst.yourRatingConst}</h5>
                          </div>
                        </article>
                      }
                      {this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && this.state.reviewCount > 0 &&
                        <article>
                          <img src={reviews} />
                          <div>
                            <h3>{this.state.reviewCount}</h3>
                            <h5>{languageConst.customerReviewConst}</h5>
                          </div>
                        </article>
                      }
                      {
                        this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && 
                        <article>
                          <img src={views} />
                          <div>
                            <h3>{!this.state.impressions ? (100 + (parseInt(get(MERCHANT_ID)) % 100)) : (this.state.impressions > 0 && this.state.impressions < 100) ? (this.state.impressions + 100) : this.state.impressions}</h3>
                            <h5>{languageConst.viewsMagicpinConst}</h5>
                          </div>
                        </article>
                      }
                      {
                        this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && 
                        <h3 style={{marginTop: window.innerWidth > 768 ? "15px" : "25px"}}>
                          {languageConst.heading2}
                        </h3>
                      }
                      <section className="claim-holder">
                        {this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && 
                           <button className="try-button button-header-hover" onClick={() => {
                             sendEvent({
                               utm_campaign: localStorage.getItem('landingSource') || '',
                               subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                               event: 'partner_with_magicpin',
                               page_type: 'new_merchant_landing'
                             });
                             createMCPLog({
                               phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                               utm_campaign: localStorage.getItem('landingSource') || '',
                               merchant_id: localStorage.getItem('landingMerchantId') || '',
                               event: 'partner_with_magicpin'
                             });
                           this.showClaimDialog();
                          }}>{this.props.merchantInfo && this.props.merchantInfo.country.toUpperCase() != 'INDIA' ? languageConst.partnerFree: languageConst.buttonCta2.toUpperCase()}</button>
                        }
                        {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91 ) && 
                          <button style={{fontSize:"20px",padding:"10px 20px"}} className="try-button button-header-hover" onClick={() => {
                              if (this.isLiquorMerchant()) {
                                toast.warn("To signup for this category, please connect at partner@magicpin.in")
                              } else {
                                sendEvent({
                                  utm_campaign: localStorage.getItem('landingSource') || '',
                                  subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                                  event: 'partner_with_magicpin',
                                  page_type: 'new_merchant_landing'
                                });
                                createMCPLog({
                                  phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                                  utm_campaign: localStorage.getItem('landingSource') || '',
                                  merchant_id: localStorage.getItem('landingMerchantId') || '',
                                  event: 'partner_with_magicpin'
                                });
                                this.showClaimDialog();
                              }
                            }}>{languageConst.groceryClaim}
                          </button>
                        }
                      </section>
                      <div style={{paddingLeft: this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? window.innerWidth < 768 ? "0px":"12px":"0px", cursor: "pointer"}} onClick={this.showSearch}><h3 style={{marginBottom: "0px"}} className="not-business">{languageConst.notBusiness}</h3> <span className="click-me">{languageConst.clickHere}</span></div>
                    </section>
                    {window.innerWidth > 768 && 
                      <div className="parent-scroller" style={{right: this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? "0%":"4%"}}>
                        {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
                          <div style={{position:"relative"}}>
                           <img style={{marginLeft:"20%",marginTop:"2%",width:"55%"}} src={whitePhoneImg} />
                           <img style={{borderRadius:"8px",left:"43%",top:"29.2%",position:"absolute",height:"52px", width:"52px"}} src={get(MERCHANT_LOGO) ? get(MERCHANT_LOGO) : defaultProfilePic } />
                           <div style={{fontFamily:"robotobold",position:"absolute",left:"43%",top:"43%"}}> {this.merchant}</div>
                           <div style={{fontFamily:"robotoregular",fontSize:"10px",position:"absolute",left:"43%",top:"47%"}}> {this.props.merchantInfo ? this.props.merchantInfo.locality : 'Vikaspur'}</div>
                         </div>                        
                        }
                        {this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 &&
                          <div>
                            <img style={{marginTop:"2%"}} src={phoneImage} />
                            <div className="screenshot-scroller" style={{right:"35.4%"}} ref={(el) => { this.messagesEnd = el; }}>
                            <img alt="Feed" src={screenshot} />
                            </div>
                            <img className = "bottom-bar" src={bottomBar} />
                          </div>
                        }
                          
                      </div>
                    }
                  </header>
                }
              </div>
            }

            {this.state.showFosHeader &&
              <FosHeader 
                showAddMerchantDialog={this.showAddMerchantDialog} 
                changePage={this.changePage} 
                push={this.props.push}
                page = {this.state.page}
                showClaimDialog = {this.showClaimDialog}
                showSearch = {this.showSearch}
                upsellFallback = {this.upsellFallback}
                />
              }

            {/* <UserNumbers /> */}

            {/* {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
              <div className="magicpin-stats-bar-bg">
                <div className="magicpin-stats-bar">
                  <div className="magicpin-stats-bar-blocks">
                    <div>50 Lac+</div>
                    <div style={{fontFamily:"robotoregular",fontSize:"14px"}}>{languageConst.customers}</div>
                  </div>
                  <div className="magicpin-stats-bar-blocks">
                    <div>50 Lac+</div>
                  <div style={{fontFamily:"robotoregular",fontSize:"14px"}}>{languageConst.monthlyTxns}</div>
                  </div>
                  <div className="magicpin-stats-bar-blocks">
                    <div>20 Lac+</div>
                  <div style={{fontFamily:"robotoregular",fontSize:"14px"}}>{languageConst.merchants}</div>
                  </div>
                  <div className="magicpin-stats-bar-blocks">
                    <div>40+</div>
                  <div style={{fontFamily:"robotoregular",fontSize:"14px"}}>{languageConst.cities}</div>
                  </div>
                </div>
              </div>
            } */}

            {!!get(MERCHANT_ID) && this.props.merchantInfo &&
             <MagicpinInfluence
                categoryId={this.props.merchantInfo && this.props.merchantInfo.categoryId}
                country={this.props.merchantInfo && this.props.merchantInfo.country}
                showClaimDialog={this.showClaimDialog}
                merchantId={get(MERCHANT_ID)} 
                language={this.props.language}
              />
            }

            {
              this.state.ABTesting &&
              <section className="all-merchant-logo-wrapper" style={{ paddingBottom: window.innerWidth > 768 ? this.props.merchantInfo ? "" :"6%" : "10%" , marginTop:0}}>
                <div className="container">
                  <div className="all-merchant-logo">
                    <h3 style={{lineHeight:"1.35",fontWeight:"bold",fontFamily:"robotobold",fontSize: window.innerWidth > 768 ? "40px" : "20px"}}>{languageConst.joinHeading}</h3>
                    {<div className="brands-heading"><a onClick={()=>this.changeBrandsTab("product")} className={this.state.brandsTab == "product"? "active" : ""}>{languageConst.productBrands}</a><a onClick={()=>this.changeBrandsTab("retail")} className={this.state.brandsTab == "retail"? "active" : ""}>{languageConst.retailBrands}</a> </div>}
                    {window.innerWidth > 768 ?
                      this.state.brandsTab=="product" ?
                      <img src={ productLogos } style={{width:"70%"}}/>
                      :
                      <div>
                        {<div style={{width: "40%", marginBottom:"2%", border: "1px solid #00000019", borderRadius: "40px"}}>
                          {
                            this.categoryArray.map(category=>{
                              return (
                                <div className={`category-tab ${category.categoryId == this.state.selectedCategory ? "active" : ""}`} onClick={()=>this.changeCategory(category.categoryId)}>
                                  <img style={{width:"auto"}} src={category.categoryLogo}/>
                                  <p>{category.categoryTitle}</p>
                                </div>
                              )
                            })
                          }
                        </div>}
                        <img src={ this.getCategoryLogos(this.state.selectedCategory, "web", get(COUNTRY) ) } style={{width:"68%", height:"20%"}}/>
                      </div>
                      :
                      this.state.brandsTab=="product" ?
                        <img src={ productLogosMobile } style={{width:"70%"}}/>
                      :
                        <div>
                          {<div style={{width:"100%", border: "1px solid #00000019", borderRadius: "40px", margin: "16px auto"}}>
                            {
                              this.categoryArray.map(category=>{
                                return (
                                  <div className={`category-tab ${category.categoryId == this.state.selectedCategory ? "active" : ""}`} onClick={()=>this.changeCategory(category.categoryId)}>
                                    <img style={{width:"25px"}} src={category.categoryLogo}/>
                                    <p>{category.categoryTitle}</p>
                                  </div>
                                )
                              })
                            }
                          </div>}
                          <img src={ this.getCategoryLogos(this.state.selectedCategory, "mobile", get(COUNTRY)) } style={{width:"80%", height:"20%"}}/>
                        </div>
                    }
                  </div>
                </div>
                <h2 style={ {lineHeight:"1.35",fontWeight: "bold", fontSize: window.innerWidth < 768 ? "24px":"40px",fontFamily: "robotobold",margin: window.innerWidth < 768 ? "20% 10px 20px":"8% 10px 0px" }}>{languageConst.whyMagicpin}</h2>
              </section>
            }

            {
              this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 &&
              <section className="partners data-holder customer-holder">
              <div>
                <h2 style={ {lineHeight:"1.35",fontWeight: "bold", fontFamily: "robotobold" }}>{languageConst.incFootfall}</h2>
                {/* <h2>Your customers market you on magicpin leading to more footfalls</h2> */}
              </div>
              <CustomerPosts
                merchantId={get(MERCHANT_ID)}
                categoryId={this.props.merchantInfo && this.props.merchantInfo.categoryId}
                showDialog={this.showClaimDialog}
                showMore={true} 
                utm_source={this.utm_source}
                search={this.state.page.localeCompare("search")}
                country={this.props.merchantInfo && this.props.merchantInfo.country}
                city={this.props.merchantInfo && this.props.merchantInfo.city}
                language={this.props.language || "english"} 
                />
              {/* {this.props.summary && ((this.props.summary.customerLocations && this.props.summary.customerLocations.home.length > 5) || (this.props.summary.ratingsData)) &&
                <div className='tab-container' style={{marginBottom: window.innerWidth > 768 ? "60px" : "40px"}}>
                  <div className="map-rating">
                    {this.props.summary.customerLocations && (this.props.summary.customerLocations.home.length > 5) &&
                      <div className="heat-map">
                        <div className="sub-header">We help you know your customers better</div>
                        <div style={{ height: '316px', padding: '16px', backgroundColor: '#ffffff', border: "1px solid #eceef4", borderRadius: '8px' }}>
                          <HeatMap
                            user={this.props.merchantInfo}
                            customerLocations={this.props.summary.customerLocations} />
                        </div>
                      </div>
                    }
                    {this.props.summary.ratingsData && this.props.summary.ratingsData.total_rating_count > 5 &&
                      <div className="rating-card" >
                        <div className="sub-header">Your ratings</div>
                        <RatingCard
                          ratings={this.props.summary.ratingsData} />
                      </div>
                    }
                  </div>
                </div>
              } */}
            </section>
            }
            {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
              <section className="graph-section" style={{backgroundColor:"#f7f6fb"}}>
                <div className="graph-section-headers">
                  <h2 style={{lineHeight:"1.35",fontWeight: "bold",fontFamily: "robotobold",fontSize: window.innerWidth < 768 ? "24px":"35px"}}>{languageConst.groceryGetSales}</h2>
                  <p style={{lineHeight: window.innerWidth < 768 ? "20px":"24px",width: window.innerWidth < 768 ? "90%":"65%",margin:"14px auto 0px",fontSize: window.innerWidth < 768 ? "14px":"16px",fontFamily:"robotoregular"}}>{languageConst.groceryPartnership}</p>
                </div>
                <section className="partners data-holder customer-holder" style={{padding:"40px 0px 60px"}}>
                  <CustomerPosts
                    merchantId={get(MERCHANT_ID)}
                    categoryId={this.props.merchantInfo && this.props.merchantInfo.categoryId}
                    showDialog={this.showClaimDialog}
                    showMore={true} 
                    utm_source={this.utm_source}
                    search={this.state.page.localeCompare("search")}
                    country={this.props.merchantInfo && this.props.merchantInfo.country}
                    city={this.props.merchantInfo && this.props.merchantInfo.city}
                    language={this.props.language || "english"} 
                    />
                </section>
                {/* <hr className="section-divider" style={{marginBottom: window.innerWidth < 768 ? "30px":"50px"}}></hr> */}
              </section>
            }

                      
            {!!get(MERCHANT_ID) && this.props.merchantInfo &&
              <LocalityWidget
                localityId={!!this.props.merchantInfo && this.props.merchantInfo.localityId}
                categoryId={!!this.props.merchantInfo && this.props.merchantInfo.categoryId}
                localityName={!!this.props.merchantInfo && this.props.merchantInfo.locality}
                lat={!!this.props.merchantInfo && this.props.merchantInfo.lattitude}
                lon={!!this.props.merchantInfo && this.props.merchantInfo.longitude}
                merchantId={get(MERCHANT_ID)} 
                utm_source={this.utm_source}
                showClaimDialog={this.showClaimDialog}
                country={this.props.merchantInfo && this.props.merchantInfo.country}
                language={this.props.language}
                />
            }

            {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
              <section style={{padding: window.innerWidth < 768 ? "1px":"10px",backgroundColor:"white"}}>
                <div className="rewards-section-header">
                  <h2 style={{lineHeight:"1.35",fontWeight: "bold",fontSize: window.innerWidth < 768 ? "22px": "35px",fontFamily:"robotobold"}}>{languageConst.groceryRewards}</h2>
                </div>
                <div className="rewards-section-img">
                  <div className="rewards-section-img-block">
                    <img style={{paddingBottom:"5px"}} src={getMoreVists}></img>
                    <div>
                      <h3 style={{lineHeight:"1.35",fontWeight: "bold",fontSize:"20px",fontFamily:"robotobold"}}>{languageConst.getMore}</h3>
                      <p style={{lineHeight:"20px",fontSize: window.innerWidth < 768 ? "14px":"15px",fontFamily:"robotoregular"}}>{languageConst.getMoreSubHeading}</p>
                    </div>
                  </div>
                  <div className="rewards-section-img-block">
                    <img style={{paddingBottom:"5px"}} src={increaseRepeat}></img>
                    <div>
                      <h3 style={{lineHeight:"1.35",fontWeight: "bold",fontSize:"20px",fontFamily:"robotobold"}}>{languageConst.increaseRepeat}</h3>
                      <p style={{lineHeight:"20px",fontSize: window.innerWidth < 768 ? "14px":"15px",fontFamily:"robotoregular"}}>{languageConst.exclusiveVouchers}</p>
                    </div>
                  </div>
                  <div className="rewards-section-img-block">
                    <img style={{paddingBottom:"5px"}} src={increaseOrder}></img>
                    <div>
                      <h3 style={{lineHeight:"1.35",fontWeight: "bold",fontSize:"20px",fontFamily:"robotobold"}}>{languageConst.increaseOrder}</h3>
                      <p style={{lineHeight:"20px",fontSize: window.innerWidth < 768 ? "14px":"15px",fontFamily:"robotoregular"}}>{languageConst.magicPayComboVouchers}</p>
                    </div>
                  </div>
                </div>
                <div className="partners" style={{margin:"10px auto",backgroundColor:"white",paddingTop:"0px",paddingBottom:"0px"}}>
                  <button  className="start-button button-header-hover" style={{border:"0px",marginTop: "10px"}} onClick={() => {
                        sendEvent({
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin',
                          page_type: 'new_merchant_landing'
                        });
                        createMCPLog({
                          phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin'
                        });
                        this.showClaimDialog();
                      }}>{languageConst.increaseBusiness}
                  </button>
                </div>
                <hr className="section-divider" style={{marginBottom: window.innerWidth < 768 ? "0px":"40px"}}></hr>
              </section>
            }

            {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
              <section style={{padding: window.innerWidth < 768 ? "20px 10px 1px":"10px 10px",backgroundColor:"white"}}>
                <div className="rewards-section-header" style={{width: window.innerWidth < 768 ? "100%":"65%"}}>
                  <h2 style={{fontWeight: "bold",lineHeight: window.innerWidth < 768 ? "1.35":"35px",fontSize: window.innerWidth < 768 ? "22px": "35px",fontFamily:"robotobold"}}> {languageConst.spendOnly} </h2>
                  <p style={{fontFamily:"robotoregular",marginTop:"16px",fontSize: window.innerWidth < 768 ? "14px":"16px"}}>{languageConst.chargeOnly} </p>
                </div>
                <div className="graph-section-img">
                  <img src={graph1}></img>
                  <img src={graph2}></img>
                </div>
                <div className="partners" style={{margin:"10px auto",backgroundColor:"white",paddingTop:"0px",paddingBottom:"0px"}}>
                  <button className="start-button button-header-hover" style={{border:"0px",marginTop: "10px"}}onClick={() => {
                        sendEvent({
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin',
                          page_type: 'new_merchant_landing'
                        });
                        createMCPLog({
                          phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin'
                        });
                        this.showClaimDialog();
                      }}>{languageConst.increaseBusiness}
                  </button>
                </div>
                {/* <div className="partners" style={{margin:"40px auto",backgroundColor:"white",paddingTop:"0px",paddingBottom:"0px"}}>
                  <button className="start-button button-header-hover" style={{border:"0px",marginTop: "10px"}} onClick={() => {
                        sendEvent({
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin',
                          page_type: 'new_merchant_landing'
                        });
                        createMCPLog({
                          utm_campaign: localStorage.getItem('landingSource') || '',
                          merchant_id: localStorage.getItem('landingMerchantId') || '',
                          event: 'partner_with_magicpin'
                        });
                        this.showClaimDialog();
                      }}>{languageConst.increaseBusiness}
                  </button>
                </div> */}
                <hr className="section-divider" style={{marginBottom: window.innerWidth > 768 ? "0px": "60px"}}></hr>
              </section>
            }

            <BenefitsSection 
              merchantName={this.merchant}
              merchantLogo={get(MERCHANT_LOGO) ? get(MERCHANT_LOGO) : defaultProfilePic}
              categoryId={this.props.merchantInfo ? this.props.merchantInfo.categoryId : 1} 
              search={this.state.page.localeCompare("search")} 
              language={this.props.language}
              country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country.toLowerCase() : "india"}
            />


            {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) && <div className="partners" style={{paddingTop:"0px",paddingBottom:"0px",width: "100%",margin:"0px auto", backgroundColor:"white"}}>
                  <button className="start-button button-header-hover" style={{border:"0px",marginTop: "10px"}} onClick={() => {
                         window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
                         sendEvent({
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner',
                           page_type: 'new_merchant_landing'
                         })
                         createMCPLog({
                           phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner'
                         });
                        this.showClaimDialog();
                      }}> {languageConst.groceryClaim}
                  </button>
                  <hr className="section-divider" style={{marginBottom:"0px"}}></hr>
            </div>}

            {/* <div style={{backgroundColor:"#fff"}}>
              <RewardVsDiscount/>
            </div> */}

           {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) &&
           <section style={{paddingTop: window.innerWidth < 768 ? "50px":"80px",backgroundColor:"white", position:"relative"}}>
             <h1 style={{lineHeight:"1.35",fontWeight: "bold",fontSize: window.innerWidth < 768 ? "22px": "35px",fontFamily:"robotobold", width:window.innerWidth > 768 ? "50%":"90%",margin:"0px auto"}} >{languageConst.additionalIncome}</h1>
             <p style={{lineHeight: window.innerWidth < 768 ? "20px":"24px",width: window.innerWidth < 768 ? "90%":"46%",margin:"14px auto 0px",fontSize: window.innerWidth < 768 ? "14px":"16px",fontFamily:"robotoregular"}}>{languageConst.sellBrands}</p>
            <Slider>
              {
                this.props.bppBrands && 
                this.props.bppBrands.map((curr,index) => {
                  return (
                    <div key={index} style={{width: window.innerWidth < 768 ? "180px":"300px",margin: window.innerWidth < 768 ? "8px":"20px"}}>
                      <img style={{borderRadius:"10px",width: window.innerWidth < 768 ? "160px":"280px",height: window.innerWidth < 768 ? "100px":"180px"}} src={curr.coverpic}/>
                      <div style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems: "flex-start",lineHeight: "4px"}}>
                        <h3 style={{fontWeight: "bold",fontSize:"16px",fontFamily:"robotobold"}} >{curr.brand_name}</h3>
                        <p  style={{fontSize:"12px",fontFamily:"robotoregular"}} >{curr.merchant_description}</p>
                      </div>
                    </div>
                  )
                })
              }
            </Slider> 
           </section>
           }
           {this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) && <div className="partners" style={{paddingTop:"0px",paddingBottom: window.innerWidth < 768 ? "40px":"0px",width: "100%",margin:"0px auto", backgroundColor:"white"}}>
                  <button className="start-button button-header-hover" style={{border:"0px",marginTop: "10px"}} onClick={() => {
                         window.ga('send', 'event', 'Logout_page', 'become_a_partner', 'Repeat');
                         sendEvent({
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner',
                           page_type: 'new_merchant_landing'
                         })
                         createMCPLog({
                           phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                           utm_campaign: localStorage.getItem('landingSource') || '',
                           merchant_id: localStorage.getItem('landingMerchantId') || '',
                           event: 'become_a_partner'
                         });
                        this.showClaimDialog();
                      }}> {languageConst.groceryClaim}
                  </button>
                  <hr className="section-divider" style={{marginBottom:"0px"}}></hr>
            </div>}

            
            {get(COUNTRY) && get(COUNTRY).toUpperCase() != countries.INDONESIA && this.props.merchantInfo &&
              <div className="testimonial-block">
                <LocalityBanners localityId={!!this.props.merchantInfo && this.props.merchantInfo.localityId} merchant_id={get(MERCHANT_ID)} showClaimDialog={this.showClaimDialog} utm_source={this.utm_source} categoryId={this.props.merchantInfo ? this.props.merchantInfo.categoryId : 1}/>
              </div>
            }

            {this.props.merchantInfo && this.props.merchantInfo.categoryId != 5 && this.props.merchantInfo.categoryId != 91 && this.props.customerPreference &&
              <CustomerPreference 
                customerPreference={this.props.customerPreference} 
                language={this.props.language}
              />
            }


            {/* {get(COUNTRY) && get(COUNTRY).toUpperCase() != countries.INDONESIA &&
              <div className="testimonial-block">
                <Testimonials showClaimDialog={this.showClaimDialog} city={!!this.props.merchantInfo && this.props.merchantInfo.city} utm_source={this.utm_source} language={this.props.language}/>
              </div>
            } */}
            {
              !this.state.ABTesting &&
              <section className="all-merchant-logo-wrapper" style={{ paddingBottom: window.innerWidth > 768 ? this.props.merchantInfo ? "10%" :"6%" : "24%" , marginTop:0}}>
                <div className="container">
                  <div className="all-merchant-logo">
                    <h3 style={{lineHeight:"1.35",fontWeight:"bold",fontFamily:"robotobold",fontSize: window.innerWidth > 768 ? "40px" : "20px"}}>{languageConst.joinHeading}</h3>
                    { <div className="brands-heading"><a onClick={()=>this.changeBrandsTab("product")} className={this.state.brandsTab == "product"? "active" : ""}>{languageConst.productBrands}</a><a onClick={()=>this.changeBrandsTab("retail")} className={this.state.brandsTab == "retail"? "active" : ""}>{languageConst.retailBrands}</a> </div>}
                    {window.innerWidth > 768 ?
                      this.state.brandsTab=="product" ?
                      <img src={ productLogos } style={{width:"70%"}}/>
                      :
                      <div>
                        {<div style={{width: "40%", marginBottom:"2%", border: "1px solid #00000019", borderRadius: "40px"}}>
                          {
                            this.categoryArray.map(category=>{
                              return (
                                <div className={`category-tab ${category.categoryId == this.state.selectedCategory ? "active" : ""}`} onClick={()=>this.changeCategory(category.categoryId)}>
                                  <img style={{width:"auto"}} src={category.categoryLogo}/>
                                  <p>{category.categoryTitle}</p>
                                </div>
                              )
                            })
                          }
                        </div>}
                        <img src={ this.getCategoryLogos(this.state.selectedCategory, "web", get(COUNTRY) ) } style={{width:"68%", height:"20%"}}/>
                      </div>
                      :
                      this.state.brandsTab=="product" ?
                        <img src={ productLogosMobile } style={{width:"70%"}}/>
                      :
                        <div>
                          {<div style={{width:"100%", border: "1px solid #00000019", borderRadius: "40px", margin: "16px auto"}}>
                            {
                              this.categoryArray.map(category=>{
                                return (
                                  <div className={`category-tab ${category.categoryId == this.state.selectedCategory ? "active" : ""}`} onClick={()=>this.changeCategory(category.categoryId)}>
                                    <img style={{width:"25px"}} src={category.categoryLogo}/>
                                    <p>{category.categoryTitle}</p>
                                  </div>
                                )
                              })
                            }
                          </div>}
                          <img src={ this.getCategoryLogos(this.state.selectedCategory, "mobile", get(COUNTRY)) } style={{width:"80%", height:"20%"}}/>
                        </div>
                    }
                  </div>
                </div>
              </section>
            }
            

            {/* <ActivityTabs showClaimDialog={this.showClaimDialog}/> */}

            
          </div>
          {this.state.showDialog &&
            <SearchBusinessDialog
              merchant_not_found={this.state.merchant_not_found}
              open={true} close={this.hideClaimDialog}
              hideAddMerchantDialog={this.hideAddMerchantDialog}
              push={this.props.push}
              url={this.url}
              fos = {this.state.showFosHeader}
              source='claim' 
              utm_source = {this.utm_source}
              language={this.props.language}
              hidePhoneInput={true}
              phoneNumber = {localStorage.getItem("subjectPhoneNumber")}
              country={this.props.merchantInfo && this.props.merchantInfo.country ? this.props.merchantInfo.country : "india"}
              city={this.props.merchantInfo && this.props.merchantInfo.city.toLowerCase()}
              opportunity_id={this.params.has("opportunity_id") ? this.params.get("opportunity_id") : null}
              />
          }
          {this.state.showSurveyDialog && get(MERCHANT_ID) &&
            <SurveyFeedback
              hide={this.hideSurveyDialog}
              response={this.params.has('surveyResponse') ? this.params.get('surveyResponse') : null}
              source={this.params.has('surveySource') ? this.params.get('surveySource') : null}
              remark={this.params.has('surveyRemark') ? this.params.get('surveyRemark') : ''} />
          }

                    {
                        <div className={this.state.openTopDiv ? "bpp-top-float-scale bpp-top-float" : "bpp-top-float"} style={{display:"grid", backgroundColor:"#fff", height:"70px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px"}}>
                            <div className="bpp-top-snack-bar" style={{width:"85%", marginLeft: window.innerWidth < 768 ? "auto":"27%"}}>
                                <div className="bpp-join-now-click-mobile bpp-join-now-snack">
                                    <div onClick={() => {
                                      sendEvent({
                                        utm_campaign: localStorage.getItem('landingSource') || '',
                                        subject_merchant_id: localStorage.getItem('landingMerchantId') || '',
                                        event: 'partner_with_magicpin',
                                        page_type: 'new_merchant_landing'
                                      });
                                      createMCPLog({
                                        phone: this.state.phoneNumber || localStorage.getItem("subjectPhoneNumber"),
                                        utm_campaign: localStorage.getItem('landingSource') || '',
                                        merchant_id: localStorage.getItem('landingMerchantId') || '',
                                        event: 'partner_with_magicpin'
                                      });
                                      this.showClaimDialog();
                                    }}
                                        className='bpp-claim-snack'
                                        style={{width: "100%", textAlign: "center", lineHeight: "1.35",borderRadius: "4px",padding: "10px 40px",cursor: "pointer"}}
                                        disabled={this.state.installing}
                                        onMouseEnter={() => this.setState({ hoverOnButton: true })}
                                        onMouseLeave={() => this.setState({ hoverOnButton: false })}
                                    >
                                        <span className="bpp-install-button-text" style={{fontSize:"14px",color: '#fff'}} >
                                          {this.props.merchantInfo && this.props.merchantInfo.country.toUpperCase() != 'INDIA' ? languageConst.partnerFree: this.props.merchantInfo && (this.props.merchantInfo.categoryId == 5 || this.props.merchantInfo.categoryId == 91) ? languageConst.groceryClaim.toUpperCase() :languageConst.buttonCta2.toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                {
                                  window.innerWidth > 768 && <span className="bpp-install-button-text" style={{color: 'rgba(0, 0, 0, 0.54)'}} >
                                      {languageConst.alreadyPartner}  <span onClick={() => {window.scrollTo(0, 0); this.props.push('/login')}} style={{color: '#ef1c74',cursor: "pointer"}}>{languageConst.login}</span>
                                  </span>
                                } 
                            </div>
                        </div>
                    }
          {(this.state.otpRetry || this.props.otpSent) && !this.state.otpVerified && this.state.otpDialog &&
          <OtpVerificationMagicStore
              retryCount = {3}
              closePopUp = {this.hideOtpDialog}
              number = {this.state.phoneNumber.toString()}
              tryAgain = {this.setOtpRetryState}
              verifyOtp={this.verifyOtp}
          />
          }
                  {/* {window.innerWidth > 768 && this.state.page.localeCompare("claim") == 0 && (get(COUNTRY) && (get(COUNTRY).toUpperCase() != countries.INDONESIA) && <ExitPopup saveMerchantContacts={this.props.saveMerchantContacts} />} */}
                  <Footer language={this.props.language} country={get(COUNTRY)} location={this.props.location}/>
        </div>
      );
    }
  })
