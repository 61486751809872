const LOAD = 'auth/LOAD';
const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
const LOAD_FAIL = 'auth/LOAD_FAIL';

const SELECT_MERCHANT = 'auth/SELECT_MERCHANT';
export const SELECT_MERCHANT_SUCCESS = 'auth/SELECT_MERCHANT_SUCCESS';
const SELECT_MERCHANT_FAIL = 'auth/SELECT_MERCHANT_FAIL';

const LOGIN = 'auth/LOGIN';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';

const BPP_ACTIVE = 'auth/BPP_ACTIVE';
const BPP_ACTIVE_SUCCESS = 'auth/BPP_ACTIVE_SUCCESS';
const BPP_ACTIVE_FAIL = 'auth/BPP_ACTIVE_FAIL';

const REQUEST_OTP = 'auth/REQUEST_OTP';
const REQUEST_OTP_SUCCESS = 'auth/REQUEST_OTP_SUCCESS';
const REQUEST_OTP_FAIL = 'auth/REQUEST_OTP_FAIL';

const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';

const SET_FB_TOKEN = 'auth/SET_FB_TOKEN';
const SET_FB_TOKEN_SUCCESS = 'auth/SET_FB_TOKEN_SUCCESS';
const SET_FB_TOKEN_FAIL = 'auth/SET_FB_TOKEN_FAIL';

const PWD_CHANGE = 'auth/PWD_CHANGE';
const PWD_CHANGE_SUCCESS = 'auth/PWD_CHANGE_SUCCESS';
const PWD_CHANGE_FAIL = 'auth/PWD_CHANGE_FAIL';

const REQUEST_OTP_BY_CALL = 'auth/REQUEST_OTP_BY_CALL';
const REQUEST_OTP_BY_CALL_SUCCESS = 'auth/REQUEST_OTP_BY_CALL_SUCCESS';
const REQUEST_OTP_BY_CALL_FAIL = 'auth/REQUEST_OTP_BY_CALL_FAIL';

const SET_SHOW_LOCATION_GUIDE = 'auth/SET_SHOW_LOCATION_GUIDE';
const SECURITY_ALERT = 'auth/SECURITY_ALERT';

const initialState = {
  loaded: false,
  loggedIn: false,
  selected: false,
  changingPwd:false,
  changedPwd:false,
  changePwdError:null,
  showLocationSettingGuide :false
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.result,
        loginError: null,
        loginMedium: window.localStorage.getItem('loginMedium')
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        loginError: action.error,
        user: null,
      };
    case SELECT_MERCHANT:
      return {
        ...state,
        selecting: true,
        selected: false,
      };
    case SELECT_MERCHANT_SUCCESS:
      //renewing the access token
      window.localStorage.setItem('accessToken', action.result.accessToken);
      window.localStorage.setItem('loginMedium', action.loginMedium);
      return {
        ...state,
        selecting: false,
        selected: true,
        user: action.result,
        selectError: null,
        loginMedium: action.loginMedium
      };
    case SELECT_MERCHANT_FAIL:
      return {
        ...state,
        selecting: false,
        selected: false,
        selectError: action.error,
        user:null,
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        loginError: null
      };
    case LOGIN_SUCCESS:
      window.localStorage.setItem('loginMedium', action.loginMedium);
      if(Array.isArray(action.result)) return {
        ...state,
        merchants: action.result,
        loginMedium: action.loginMedium,
      };
      else return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.result,
        loginError: null,
        loginMedium: action.loginMedium,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginError: action.error
      }
    case BPP_ACTIVE:
      return {
        ...state,
        isBppActive: false,
        bppActiveLoading: true
      };
    case BPP_ACTIVE_SUCCESS:
      return {
        ...state,
        isBppActive: action.result,
        bppActiveLoading: false
      };
    case BPP_ACTIVE_FAIL:
      return {
        ...state,
        bppActiveError: action.error,
        bppActiveLoading: false
      };
    case REQUEST_OTP:
      return {
        ...state,
        requestingOtp: true,
        otpRequested: false,
      };
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        requestingOtp: false,
        otpRequested: true,
        otpError: null,
      };
    case REQUEST_OTP_FAIL:
      return {
        ...state,
        requestingOtp: false,
        otpRequested: false,
        otpError: action.error,
      };
      case REQUEST_OTP_BY_CALL:
      return {
        ...state,
        requestingOtpByCall: true,
        otpRequestedByCall: false,
      };
    case REQUEST_OTP_BY_CALL_SUCCESS:
      return {
        ...state,
        requestingOtpByCall: false,
        otpRequestedByCall: true,
        otpByCallError: null,
      };
    case REQUEST_OTP_BY_CALL_FAIL:
      return {
        ...state,
        requestingOtpByCall: false,
        otpRequestedByCall: false,
        otpByCallError: action.error,
      };
    case LOGOUT:
      // clear the accessToken from localStorage
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('loginMedium');
      window.localStorage.removeItem('saltFromAuth');
      window.localStorage.removeItem('phoneNumberDuringOtpLogin');
      window.localStorage.removeItem('timeOfLastLogin');
      window.localStorage.removeItem('magicpinId');
      window.localStorage.removeItem(`isCeetMerchant_${action.accessToken}`);
      googleSignOut();
      return {
        ...state,
        loggingOut: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        selected: false,
        loggedIn: false,
        user: null,
        logoutError: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };
    case SET_FB_TOKEN:
      return {
        ...state,
        settingFbToken: true,
      };
    case SET_FB_TOKEN_SUCCESS:
      return {
        ...state,
        user: {...state.user, facebookId: action.facebookId},
        setFbToken: true,
      };
    case SET_FB_TOKEN_FAIL:
      return {
        ...state,
        settingFbToken: false,
        setFbToken: false,
      };
    case PWD_CHANGE:
      return {
        ...state,
        changingPwd:true,
      };
    case PWD_CHANGE_SUCCESS:
      return {
        ...state,
        changingPwd:false,
        changedPwd:true,
        changePwdError:null,
      };
    case PWD_CHANGE_FAIL:
      return {
        ...state,
        changingPwd:false,
        changePwdError: action.error,
      };

    case SET_SHOW_LOCATION_GUIDE:
      return {
        ...state,
        showLocationSettingGuide: !state.showLocationSettingGuide
      };

    case SECURITY_ALERT:
      return{
        ...state,
        showSecurityAlert: !state.showSecurityAlert
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && (globalState.auth.loaded || globalState.auth.loggedIn);
}

export function loadAuth(accessToken, loginMedium) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/loadAuth', {
      data: {accessToken}
    }),
  };
}

export function selectMerchant(accessToken, merchantId) {
  return {
    types: [SELECT_MERCHANT, SELECT_MERCHANT_SUCCESS, SELECT_MERCHANT_FAIL],
    promise: (client) => client.default.post('/selectMerchant', {
      data: {accessToken, merchantId}
    }),
    loginMedium: 'admin'
  };
}

export function login(credentials) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.default.post('/login', {
      data: credentials
    }),
    loginMedium: 'credential'
  };
}

export function requestOtp(phone, msg = null, sendSixDigitOtp = false) {
  return {
    types: [REQUEST_OTP, REQUEST_OTP_SUCCESS, REQUEST_OTP_FAIL],
    promise: (client) => client.default.post('/loginRequestOtp', {
      data: { phone, msg, sendSixDigitOtp}
    })
  };
}

export function verifyOtp(phone, otp, merchantId, salt) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.default.post('/verifyOtp', {
      data: { phone, otp, merchantId, salt }
    }),
    loginMedium: 'otp'
  };
}

export function loginShortUrl(token, utmSource) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.default.post('/loginShortUrl', {
      data: {token}
    }),
    loginMedium: utmSource === 'admin_dashboard' ? 'short_link' : `${utmSource || ""}_short_link`   
  };
}

export function logout(accessToken) {
  const session_actor_id = window.localStorage.getItem('session_actor_id') || '';
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (client) => client.default.post('/logout', {
      data: { accessToken, session_actor_id }
    }),
    accessToken: accessToken,
  };
}

export function setFbDetails(accessToken, fbAccessToken, fbId){
  return {
    types: [SET_FB_TOKEN, SET_FB_TOKEN_SUCCESS, SET_FB_TOKEN_FAIL],
    promise: (client) => client.default.post('/setFbDetails',{
      data:{ accessToken, fbAccessToken, fbId }
    }),
    facebookId: fbId,
  };
}

export function loginViaApp(appUserId, appUserToken, merchantId){
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (client) => client.default.post('/loginViaApp', {
      data: {appUserId, appUserToken, merchantId}
    }),
    loginMedium: 'app'
  }
}

export function changePassword(accessToken ,phone ,otp , password) {
  return {
    types: [PWD_CHANGE, PWD_CHANGE_SUCCESS, PWD_CHANGE_FAIL],
    promise: (client) => client.default.post('/setPassword', {
      data: {accessToken,phone,otp,password}
    })
  };
}

export function sendOtpByCall(phone) {
  let requestBody = {}
  requestBody['phone_no'] = "91"+phone
  requestBody['app-version-name'] = "MERCHANT-1.0.0"
  requestBody['app-version'] = "1000"
  requestBody['sms_service_flag'] = 0
  return {
    types: [REQUEST_OTP_BY_CALL, REQUEST_OTP_BY_CALL_SUCCESS, REQUEST_OTP_BY_CALL_FAIL],
    promise: (client) => client.default.post('https://auth.magicpin.in/SendOtpByCall/', {
      data: requestBody
    })
  }
}

export function getBppActive(accessToken){
  return {
    types: [BPP_ACTIVE, BPP_ACTIVE_SUCCESS, BPP_ACTIVE_FAIL],
    promise: (client) => client.default.post('/getBppActive', {
      data: {accessToken}
    })
  }
}

export const googleSignOut = () => {
  try {
    window.gapi.auth2.getAuthInstance().signOut();
  } catch (err) {};
}

export function setShowLocationSettingGuide(){
  return {
    type: SET_SHOW_LOCATION_GUIDE
  };
}

export const setShowSecurityAlert = () => {
  return{
    type: SECURITY_ALERT
  }
}
