 import React, { Component } from 'react'
 import "./ConvexPattern.css"

 export default class ConvexPattern extends Component {
     render() {

        const profilePic = this.props.profilePic || "images/no-banner.png"
        const merchantName =  this.props.merchantName || ""
        const merchantLocality = this.props.merchantLocality || ""
        const message = this.props.message || "Text will be shown here"
        const buttonCta = message.toUpperCase().includes("ORDER") ? 'Order Now': 'Visit Us Now'
        const backgroundImage = this.props.backgroundImage || "images/no-banner.png"

         return (
            <div className = "convex-pattern-main-body">

                <div className = "convex-pattern-details-body">
                    <div className = "convex-pattern-merchant-info">
                        <div className = "convex-pattern-merchant-logo-body">
                        <img
                            className = "convex-pattern-merchant-logo" 
                            style = {{width: "25px", height: "25px", borderRadius: "0"}}
                            src = {profilePic} 
                        />
                        </div>
                        <div className = "convex-pattern-merchant-info-text">
                        <span className = "convex-pattern-merchant-name">{merchantName}</span>
                        <span className = "convex-pattern-merchant-locality">{merchantLocality}</span>
                        </div>
                    </div>

                    <div className = {`convex-pattern-banner-text ${message.length > 90 && "font-size-12"}`}>
                        <p>
                          {message}
                        </p>
                    </div>

                    <div>
                        <span className = "convex-pattern-banner-btn">
                            {buttonCta}
                        </span>
                    </div>
                </div>

                <div className = "convex-pattern-image-body">
                    <img src= {backgroundImage} className = "convex-pattern-image"/>
                </div>
            </div>
         )
     }
 }