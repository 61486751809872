import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import Loader from '../Loader/Loader';
import { countriesInfo } from '../../constants';
export default class Insights extends Component{

  render(){
    const impressions = Array.isArray(this.props.impressions) 
                          && this.props.impressions.length > 0 
                          && this.props.impressions[0] 
                          && this.props.impressions[0].impressions && 
                          this.props.impressions[0].impressions.length > 0 ?
                          this.props.impressions[0].impressions : [];
    const chartFields = [
      {'type': 'string', 'label' : 'Time'},
      {'type' : 'number', 'label' : 'Impressions'}
    ];

    const chartOptions = {
      backgroundColor: '#fff',
      dataOpacity: 0.8 ,
      colors: ['#fab155'],
      pointSize: 5,
      pointShape: 'circle',
      chartArea: {
        width: '88%',
        height:'80%'
      },
      legend: { position: "none" },
      vAxis:{
        gridThickness: 0,
        labelAngle: 180,
        textStyle:{ color: '#9cadb5' },
        format: 'short',
        baselineColor: 'transparent'
      },
      hAxis:{
         textStyle:{ color: '#9cadb5' },
         gridlines: {
         color: '#e5e5e5',
         baselineColor: '#e5e5e5',
         count: 1
       },
       format: 'DD-MM-YY',
      },
    };
    
    let chartData=[];
    let totalImpression=0;
    if(impressions && Array.isArray(impressions)){
      impressions.forEach( element => {
          totalImpression+=element.impressions;
          chartData.push([moment(element.date).format('DD-MM-YY'),element.impressions]);
      });
    }
    const country= this.props.user.country.toUpperCase() 
    return(
       <Modal dialogClassName='modal-dialog new-modal-small small-rp-modal'
        show={true} onHide={this.props.hideInsights}>
        { this.props.impressionLoading ? 
           <Loader/>
          :
          impressions && <div className= "modal-content">
          <div className= "modal-header" style={{marginTop:'0'}}>
            <h2>INSIGHTS</h2>
            <div className= "dialog-cross fa fa-close" onClick={this.props.hideInsights} style={{ display: 'flex',flexDirection: 'row-reverse' }}>
            </div>
          </div>
          <div className="modal-body">
            <div className="rp-modal-row">
              <div className="modal-lft-text-block">
                <div className="rp-modal-top-imp-block">
                  <p><span style={{color:'rgba(0,0,0,0.87)'}}>{totalImpression.toLocaleString(countriesInfo[country]['locale'])}</span></p>
                  <p style={{color:'rgba(0,0,0,0.54)',marginTop:'10px'}}>Total impressions</p>
                </div>
                <div id="InsiteGraph" className="rp-modal-graph-block"></div>
                {<div style={{width:'100%',height:'215px'}} className='chart-loading bottom-round-box'>
                  <Chart chartType="AreaChart"
                    rows={chartData}
                    loader={<span></span>}
                    columns={chartFields}
                    options={chartOptions}
                    graph_id="ImpressionsAreaChart" width={"100%"} height={"100%"}  legend_toggle={true} />
                </div>}
                <div className="rp-modal-row" style={{marginTop:'2%'}}>
                <div className="Separator2"></div>
                  {this.props.promotion.start_date && <div className="rp-insite-txt" style={{display: 'flex'}}>
                    <p style={{color: 'rgba(0, 0, 0, 0.54)'}}>Published: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{moment(this.props.promotion.start_date).format('D MMM, YYYY')}</span></p>
                    <p style={{color: 'rgba(0, 0, 0, 0.54)',marginLeft:'12px'}}>Locality: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{this.props.user.locality}</span></p>
                  </div>}
                  {/* <div className="rp-insite-txt"><p>Cost: <span>{getCurrencySymbol(this.props.user.country)}5000</span></p></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      </Modal>
    );
  }
}
