const GET_TREE = 'helpDesk/GET_TREE';
const GET_TREE_SUCCESS = 'helpDesk/GET_TREE_SUCCESS';
const GET_TREE_FAIL = 'helpDesk/GET_TREE_FAIL';

const GET_TICKETS = 'helpDesk/GET_TICKETS';
const GET_TICKETS_SUCCESS = 'helpDesk/GET_TICKETS_SUCCESS';
const GET_TICKETS_FAIL = 'helpDesk/GET_TICKETS_FAIL';

const UPDATE_TICKETS = 'helpDesk/UPDATE_TICKETS';
const UPDATE_TICKETS_SUCCESS = 'helpDesk/UPDATE_TICKETS_SUCCESS';
const UPDATE_TICKETS_FAIL = 'helpDesk/UPDATE_TICKETS_FAIL';

const initialState = {
  loading: false,
  loaded: false,
}

export default function helpDesk(state = initialState, action = {}) {
  switch (action.type) {
  case GET_TREE:
    return {
      ...state,
      loading: true
    }
  case GET_TREE_SUCCESS:
    return {
      ...state,
      data: action.result,
      loading: false,
    }
  case GET_TREE_FAIL:
    return {
      ...state,
      error: action.error,
      loading: false,
    }
  case GET_TICKETS:
    return {
      ...state,
      ticket_loading: true
    }
  case GET_TICKETS_SUCCESS:
    return {
      ...state,
      ticket_data: action.result,
      ticket_loading: false,
    }
  case GET_TICKETS_FAIL:
    return {
      ...state,
      ticket_error: action.error,
      ticket_loading: false,
    }
  case UPDATE_TICKETS:
    return {
      ...state,
      message_sending: true
    }
  case UPDATE_TICKETS_SUCCESS:
    return {
      ...state,
      message_sent: true,
      message_sending: false,
    }
  case UPDATE_TICKETS_FAIL:
    return {
      ...state,
      message_error: action.error,
      message_sending: false,
    }
   default:
      return state;
  }
}

export function getHelpDeskTree(salt,merchantId,merchantUserId,isRoot=true,fields=[],message="",email="",fileSelected=""){
  let formData = new FormData();
  if(fileSelected)
    formData.append('merchant_single_photo', fileSelected, fileSelected?.name || `helpdesk-attachment-${merchantId}`)
  formData.append('message', message);
  formData.append('merchantId', merchantId)
  formData.append('merchantUserId',merchantUserId)
  formData.append('email', email)
  formData.append('fields',fields)
  formData.append('salt',salt)
  if(isRoot)
    formData.append('isRoot',isRoot)
  formData.append('versionCode', 3)

  return {
    types: [GET_TREE, GET_TREE_SUCCESS, GET_TREE_FAIL],
    promise: (client) => client.default.post('/getHelpDeskData',{
      data: formData
    })
  }
}


export function getHelpDeskTicketHistory(merchantId,accessToken,ticketId=""){

  let action = {
    url: `http://10.140.15.60:8555/helpdesk/partner/ticket${ticketId !== "" ? `/${ticketId}` : ""}`,
    method: "get",
  }; 

  return {
    types: [GET_TICKETS, GET_TICKETS_SUCCESS, GET_TICKETS_FAIL],
    promise: (client) => client.default.post('/takeAction/getHelpDeskTicketHistory',{
      data: {accessToken, action, merchantId}
    })
  }
}

export function updateHelpDeskTicketHistory(salt,merchantId,merchantUserId,accessToken,ticketId,message=" ",fileSelected){
 
  let formData = new FormData();
  if(fileSelected)
    formData.append('merchant_single_photo', fileSelected,  fileSelected?.name || `helpdesk-attachment-${merchantId}`)
  formData.append('message', message);
  formData.append('accessToken', accessToken)
  formData.append('merchantId', merchantId)
  formData.append('ticketId', ticketId)
  formData.append('merchantUserId',merchantUserId)
  formData.append('ticketUpdate', true)
  formData.append('versionCode', 3)
  formData.append('salt',salt)

  return {
    types: [UPDATE_TICKETS, UPDATE_TICKETS_SUCCESS,UPDATE_TICKETS_FAIL],
    promise: (client) => client.default.post('/getHelpDeskData',{
      data: formData
    })
  }
}



