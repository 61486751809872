const GET_CREDIT_LIMIT = 'updateCreditLimit/GET_CREDIT_LIMIT';
const GET_CREDIT_LIMIT_SUCCESS = 'updateCreditLimit/GET_CREDIT_LIMIT_SUCCESS';
const GET_CREDIT_LIMIT_FAIL = 'updateCreditLimit/GET_CREDIT_LIMIT_FAIL';

const UPDATE_CREDIT_LIMIT = 'updateCreditLimit/UPDATE_CREDIT_LIMIT';
const UPDATE_CREDIT_LIMIT_SUCCESS = 'updateCreditLimit/UPDATE_CREDIT_LIMIT_SUCCESS';
const UPDATE_CREDIT_LIMIT_FAIL = 'updateCreditLimit/UPDATE_CREDIT_LIMIT_FAIL';


const CLEAR_UPSELL_CACHE = 'updateCreditLimit/CLEAR_UPSELL_CACHE';
const CLEAR_UPSELL_CACHE_SUCCESS = 'updateCreditLimit/CLEAR_UPSELL_CACHE_SUCCESS';
const CLEAR_UPSELL_CACHE_FAIL = 'updateCreditLimit/CLEAR_UPSELL_CACHE_FAIL';

const CLEAR_BPP_DETAILS_CACHE = 'updateCreditLimit/CLEAR_BPP_DETAILS_CACHE';
const CLEAR_BPP_DETAILS_CACHE_SUCCESS = 'updateCreditLimit/CLEAR_BPP_DETAILS_CACHE_SUCCESS';
const CLEAR_BPP_DETAILS_CACHE_FAIL = 'updateCreditLimit/CLEAR_BPP_DETAILS_CACHE_FAIL';

const MERCHANT_PACKAGE_LIST = 'updateCreditLimit/MERCHANT_PACKAGE_LIST';
const MERCHANT_PACKAGE_LIST_SUCCESS = 'updateCreditLimit/MERCHANT_PACKAGE_LIST_SUCCESS';
const MERCHANT_PACKAGE_LIST_FAIL = 'updateCreditLimit/MERCHANT_PACKAGE_LIST_FAIL';

const UPDATE_PACKAGE = 'updateCreditLimit/UPDATE_PACKAGE';
const UPDATE_PACKAGE_SUCCESS = 'updateCreditLimit/UPDATE_PACKAGE_SUCCESS';
const UPDATE_PACKAGE_FAIL = 'updateCreditLimit/UPDATE_PACKAGE_FAIL';

const UPDATE_LPF = 'updateCreditLimit/UPDATE_LPF'
const UPDATE_LPF_SUCCESS = 'updateCreditLimit/UPDATE_LPF_SUCCESS'
const UPDATE_LPF_FAIL = 'updateCreditLimit/UPDATE_LPF_FAIL'

const UPDATE_HOLD_AMOUNT = 'updateCreditLimit/UPDATE_HOLD_AMOUNT'
const UPDATE_HOLD_AMOUNT_SUCCESS = 'updateCreditLimit/UPDATE_HOLD_AMOUNT_SUCCESS'
const UPDATE_HOLD_AMOUNT_FAIL = 'updateCreditLimit/UPDATE_HOLD_AMOUNT_FAIL'


const CALCULATE_PRICE = 'updateCreditLimit/CALCULATE_PRICE'
const CALCULATE_PRICE_SUCCESS = 'updateCreditLimit/CALCULATE_PRICE_SUCCESS'
const CALCULATE_PRICE_FAIL = 'updateCreditLimit/CALCULATE_PRICE_FAIL'

const UPDATE_LEFT_OVER_GOAL = 'updateCreditLimit/UPDATE_LEFT_OVER_GOAL'
const UPDATE_LEFT_OVER_GOAL_SUCCESS = 'updateCreditLimit/UPDATE_LEFT_OVER_GOAL_SUCCESS'
const UPDATE_LEFT_OVER_GOAL_FAIL = 'updateCreditLimit/UPDATE_LEFT_OVER_GOAL_FAIL'

const initialState = {
    loaded: false,
};

export default function updateCreditLimit(state = initialState, action = {}) {

    switch (action.type) {
        case GET_CREDIT_LIMIT:
            return {
                ...state,
                loading: true
            };

        case GET_CREDIT_LIMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                maxCreditLimit: action.result && action.result.maxCreditLimit ? action.result.maxCreditLimit : 0,
                availableCreditLimit: action.result && action.result.availableCreditLimit ? action.result.availableCreditLimit : 0,
                usedCreditLimit: action.result && action.result.usedCreditLimit ? action.result.usedCreditLimit : 0,
            }

        case GET_CREDIT_LIMIT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: true,

            }
        
        case UPDATE_CREDIT_LIMIT:
            return {
                ...state,
                updateCreditLoading: true
            };

        case UPDATE_CREDIT_LIMIT_SUCCESS:
            return {
                ...state,
                updateCreditLoading: false,
                updateCreditLoaded: true,
                currentLimit: action.result && action.result.currentLimit ? action.result.currentLimit : 0,
                updatedLimit: action.result && action.result.updatedLimit ? action.result.updatedLimit : 0,
            }

        case UPDATE_CREDIT_LIMIT_FAIL:
            return {
                ...state,
                updateCreditLoading: false,
                updateCreditLoaded: true,

            }

        case CLEAR_UPSELL_CACHE:
            return {
                ...state,
                clearUpsellCacheError: false,
                clearUpsellCacheLoading: true,
                message: ""
            };

        case CLEAR_UPSELL_CACHE_SUCCESS:
            return {
                ...state,
                clearUpsellCacheError: false,
                clearUpsellCacheLoading: false,
                message: action.result.message || "...."
            }

        case CLEAR_UPSELL_CACHE_FAIL:
            return {
                ...state,
                clearUpsellCacheLoading: false,
                message: action.error.message,
                clearUpsellCacheError: action.error,
            }

        case CLEAR_BPP_DETAILS_CACHE:
            return {
                ...state,
                clearBppDetailsCacheError: false,
                clearBppDetailsCacheLoading: true,

            };

        case CLEAR_BPP_DETAILS_CACHE_SUCCESS:
            return {
                ...state,
                clearBppDetailsCacheError: false,
                clearBppDetailsCacheLoading: false,
                clearBppDetailsCacheMessage: 'Cache has been cleared'
            }

        case CLEAR_BPP_DETAILS_CACHE_FAIL:
            return {
                ...state,
                clearBppDetailsCacheLoading: false,
                clearBppDetailsCacheMessage: action.error.message,
                clearBppDetailsCacheError: action.error,
            }
        
        case MERCHANT_PACKAGE_LIST:
            return {
                ...state,
                packageListLoading: true,

            };

        case MERCHANT_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                packageListLoading: false,
                packageList: action.result.merchantAvailablePackages,
            }

        case MERCHANT_PACKAGE_LIST_FAIL:
            return {
                ...state,
                packageListLoading: false,
                packageListError: action.error,
            }

        case UPDATE_PACKAGE:
            return {
                ...state,
                updatePackageLoading: true,
            };

        case UPDATE_PACKAGE_SUCCESS:
            return {
                ...state,
                updatePackageLoading: false,
                updateStatus: action.result,
                updatePackageError: null,
            }

        case UPDATE_PACKAGE_FAIL:
            return {
                ...state,
                updatePackageLoading: false,
                updatePackageError: action.error,
                updateStatus: null
            }

        case UPDATE_LPF:
                return {
                    ...state,
                    updateLPFLoading: true,
                    messageLPF: null,
                    updateLPFError: false
                };
    
        case UPDATE_LPF_SUCCESS:
                return {
                    ...state,
                    updateLPFLoading: false,
                    messageLPF: 'LPF updates successfully',
                    updateLPFError: false
                }
    
        case UPDATE_LPF_FAIL:
                return {
                    ...state,
                    updateLPFLoading: false,
                    messageLPF: action.error && action.error.message ? action.error.message : 'error while updateing LPF',
                    updateLPFError: true,
                }

        case UPDATE_HOLD_AMOUNT:
                return {
                    ...state,
                    updateHoldAmountLoading: true,
                    messageHoldAmount: null,
                    updateHoldAmountError: false
                };
    
        case UPDATE_HOLD_AMOUNT_SUCCESS:
                return {
                    ...state,
                    updateHoldAmountLoading: false,
                    messageHoldAmount: 'Hold Amount updates successfully',
                    updateHoldAmountError: false
                }
    
        case UPDATE_HOLD_AMOUNT_FAIL:
                return {
                    ...state,
                    updateHoldAmountLoading: false,
                    messageHoldAmount: action.error && action.error.message ? action.error.message : 'error while updateing Hold Amount',
                    updateHoldAmountError: true,
                }

        case UPDATE_LEFT_OVER_GOAL:
                return {
                    ...state,
                    updatingLeftOverGoal: true,
                    messageLeftOverGoal: null,
                    updateLeftOverGoalError: false
                };

        case UPDATE_LEFT_OVER_GOAL_SUCCESS:
                return {
                    ...state,
                    updatingLeftOverGoal: false,
                    messageLeftOverGoal: 'Left over goal updated successfully',
                    updateLeftOverGoalError: false
                }
    
        case UPDATE_LEFT_OVER_GOAL_FAIL:
                return {
                    ...state,
                    updatingLeftOverGoal: false,
                    messageLeftOverGoal: action.error && action.error.message ? action.error.message : 'error while updateing left over goal',
                    updateLeftOverGoalError: true,
                }

        default:
            return state;

    }
}

export function getAvailableCreditAmount(merchantId) {
    return {
        types: [GET_CREDIT_LIMIT, GET_CREDIT_LIMIT_SUCCESS, GET_CREDIT_LIMIT_FAIL],
        promise: (client) => client.default.post('/getAvailableCreditLimit', {
            data: {merchantId: merchantId}
        })
    };
}


export function updateMerchantCreditLimit(merchantId, updateAmount, idToken, accessToken){
    return {
        types: [UPDATE_CREDIT_LIMIT, UPDATE_CREDIT_LIMIT_SUCCESS, UPDATE_CREDIT_LIMIT_FAIL],
        promise: (client) => client.default.post('/updateCreditLimit', {
            data: {merchantId: merchantId, amount: updateAmount, idToken: idToken, accessToken: accessToken }
        })
    }
}

export function clearUpsellCache(merchantId, idToken, accessToken){

    return {
        types: [CLEAR_UPSELL_CACHE, CLEAR_UPSELL_CACHE_SUCCESS, CLEAR_UPSELL_CACHE_FAIL],
        promise: (client) => client.default.post('/clearUpsellCache', {
            data: { merchantId, idToken, accessToken }
        })
    }
}

export function clearBppDetailsCache(merchantId, idToken, accessToken){

    return {
        types: [CLEAR_BPP_DETAILS_CACHE, CLEAR_BPP_DETAILS_CACHE_SUCCESS, CLEAR_BPP_DETAILS_CACHE_FAIL],
        promise: (client) => client.default.post('/bppDetailsCacheRefresh', {
            data: { merchantId, idToken, accessToken }
        })
    }
}

export function getMerchantPackageList(merchantId, accessToken){

    return {
        types: [MERCHANT_PACKAGE_LIST, MERCHANT_PACKAGE_LIST_SUCCESS, MERCHANT_PACKAGE_LIST_FAIL],
        promise: (client) => client.default.post('/getMerchantPackage', {
            data: { merchantId, accessToken }
        })
    }
}

export function updateMerchantPackage(merchantId, merchantSuitablePackage, creditPercent, idToken, accessToken){

    return {
        types: [UPDATE_PACKAGE, UPDATE_PACKAGE_SUCCESS, UPDATE_PACKAGE_FAIL],
        promise: (client) => client.default.post('/updateMerchantPackageCache', {
            data: { merchantId, merchantSuitablePackage, idToken, creditPercent, accessToken }
        })
    }
}

export function updateMerchantLoanProcessingFee(merchantId, lpfToUpdate, idToken, accessToken){
    return {
        types: [UPDATE_LPF, UPDATE_LPF_SUCCESS, UPDATE_LPF_FAIL],
        promise: (client) => client.default.post('/updateMerchantLoanProcessingFee', {
            data: {merchantId: merchantId, loanProcessingFee: lpfToUpdate, idToken: idToken, accessToken: accessToken }
        })
    }
}

export function updateMerchantHoldAmount(merchantId, holdAmount, idToken, accessToken){
    return {
        types: [UPDATE_HOLD_AMOUNT, UPDATE_HOLD_AMOUNT_SUCCESS, UPDATE_HOLD_AMOUNT_FAIL],
        promise: (client) => client.default.post('/updateMerchantHoldAmount', {
            data: {merchantId, holdAmount, idToken, accessToken}
        })
    }
}

export function upsellCalculator(newRoiToBe, newPendingGoalToBe, newLoanDeductionToBe, newCashbackGMVToBe, newVoucherGMVToBe, factorsAlreadyUsed, idToken, accessToken){
    return {
        types: [CALCULATE_PRICE, CALCULATE_PRICE_SUCCESS, CALCULATE_PRICE_FAIL],
        promise: (client) => client.default.post('/upsellCalculator', {
            data: {newRoiToBe, newPendingGoalToBe, newLoanDeductionToBe, newCashbackGMVToBe, newVoucherGMVToBe, factorsAlreadyUsed, idToken, accessToken}
        })
    }
}

export function updateMerchantLeftOverGoal(merchantId, updatedLeftOverGoal, idToken, accessToken){
    return {
        types: [UPDATE_LEFT_OVER_GOAL, UPDATE_LEFT_OVER_GOAL_SUCCESS, UPDATE_LEFT_OVER_GOAL_FAIL],
        promise: (client) => client.default.post('/updateMerchantLeftOverGoal', {
            data: {merchantId, updatedLeftOverGoal, idToken, accessToken}
        })
    }
}