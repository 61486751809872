import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment";

import { getPaginatedOrders,getMerchantOrders} from '../../redux/modules/newMerchantLanding';

import './HelpDesk.css';

export default connect(store => ({ 

  }), dispatch => bindActionCreators({

  }, dispatch))
(class HelpDeskListPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            limit: 5
        }
        this.myRef = React.createRef();
    }

    componentDidMount(){

    }

    viewMore = () => {
        this.setState(prevState => {
            return{
                limit: prevState.limit + 5
            }
        })
    }

    scrollToTop = () => {
        this.myRef.current.scrollIntoView()
    }

    render() {
        const type = this.props.pageType || "delivery";
        let realOrders = Array.isArray(this.props.data) ? this.props.data.filter(order => order.isTestOrder === 0):[];
        return (
            <div className="helpdesk-list-page">
                <div ref={this.myRef} id="helpdesk-top" className="helpdesk-list-header">
                    <p>
                        {
                            type === 'delivery' ? 
                            `Here are your last ${realOrders.length} orders, please let us know if you have issue with any of these`
                            :
                            `Here are your last 20 transactions, please let us know if you have issue with any of these.
                            Display payment status and raise ticket if the payment is not credited in the wallet for that particular order ID with an elaborative description`
                        }
                    </p>
                </div>
                <div className="helpdesk-orders-body">
                    <p style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"12px",lineHeight:"16px",letterSpacing:"1.5px",textTransform:"uppercase"}}>Last {realOrders.length} orders</p>
                    {
                        realOrders.filter((order,index) => index < this.state.limit).map(order => {
                            return (
                                <li key={order.orderId} className="helpdesk-order-list" onClick={() => this.props.getHelpDeskTree(order)}>
                                    <div className="helpdesk-order-details">
                                        <p>{`${order.userName.length > 11 ? order.userName.slice(0,9)+"..":order.userName}` || 'User'} <span style={{backgroundColor:"#f5e0fe",color:"#ab02f8"}}>{order.source || "magicpin"}</span> <span style={order.orderStatus.toUpperCase() === "DELIVERED" ? {}:{color:"white",backgroundColor:"#ef1c74"}}>{order.orderStatus}</span></p>
                                        <p>Deliver order id: {order.orderId}</p>
                                        <p>Update date: {moment.utc(order.createdAt).local().format('Do MMM YYYY, h:mm a')}</p>
                                    </div>
                                    <div className="helpdesk-order-cost">
                                        {`₹${order.amount.toFixed(1)}/${order.availableItems.length} items`}
                                    </div>
                                </li>
                            )
                        })
                    }
                    {
                        realOrders.length > this.state.limit && 
                        <div className="helpdesk-list-view-more" onClick={() => this.viewMore()}>View More</div>
                    }
                </div>
                {
                    this.state.limit > 5 &&
                    <div className="go-to-top" onClick={() => this.scrollToTop()}>
                        <i class="fa fa-arrow-up"></i>
                    </div>
                }

            </div>
        );
    }
})