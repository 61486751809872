import moment from 'moment'

const LOAD = 'feed/LOAD';
const LOAD_SUCCESS = 'feed/LOAD_SUCCESS';
const LOAD_FAIL = 'feed/LOAD_FAIL';

const LIKE = 'feed/LIKE';
const LIKE_SUCCESS = 'feed/LIKE_SUCCESS';
const LIKE_FAIL = 'feed/LIKE_FAIL';

const COMMENT = 'feed/COMMENT';
const COMMENT_SUCCESS = 'feed/COMMENT_SUCCESS';
const COMMENT_FAIL = 'feed/COMMENT_FAIL';

const LAUNCH_AR_REQUEST = 'feed/LAUNCH_AR_REQUEST';
const LAUNCH_AR_REQUEST_SUCCESS = 'feed/LAUNCH_AR_REQUEST_SUCCESS';
const LAUNCH_AR_REQUEST_FAIL = 'feed/LAUNCH_AR_REQUEST_FAIL';

const BD_DETAIL = 'feed/BD_DETAIL';
const BD_DETAIL_SUCCESS = 'feed/BD_DETAIL_SUCCESS';
const BD_DETAIL_FAIL = 'feed/BD_DETAIL_FAIL';

const CUSTOMER_COMPLAINTS_LOAD = 'feed/CUSTOMER_COMPLAINTS_LOAD';
const CUSTOMER_COMPLAINTS_LOAD_SUCCESS = 'feed/CUSTOMER_COMPLAINTS_LOAD_SUCCESS';
const CUSTOMER_COMPLAINTS_LOAD_FAIL = 'feed/CUSTOMER_COMPLAINTS_LOAD_FAIL';

const initialState = {
  loaded: false,
  feed: [],
  feedTop: {},
  callFurther: false,
  liked: false,
  commented: false,
  lastPostId: '0',
  lastPostTime: '0',
};

export default function feed(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      const firstCall = action.lastPostId == '0' && action.lastPostTime == '0';
      const newFeed = firstCall ? [{post_type: 'review_widget'}, ...action.result]:[...state.feed, ...action.result]
      /** we are pushing review widget after the posts in first call */
      let calculateLastPostId = action.result && action.result.length > 2 ? action.result[action.result.length -1].post_id : null
      return {
        ...state,
        loading: false,
        loaded: true,
        feed: newFeed,
        callFurther: action.result && action.result.length > 2,
        lastPostId: calculateLastPostId
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LIKE:
      return {
        ...state,
        liking: true,
      };
    case LIKE_SUCCESS:
      return {
        ...state,
        liking: false,
        liked: true,
        feed: state.feed.map(post => {
          /* we should not modify state so making a new array with new postResponse object
          * here postId is coming in the object getting fired
          * feed array is being iterated and when post id matches we are updating postResponse part
          * keeping it in mind that old postResponse of old feed not mutate
          */
          if(['Transaction','Moment', 'Selfieless'].includes(post.post_type) && post.post_id == action.postId){
            return ({...post, like_id: action.result.id, likes: post.likes + 1});
          }
          else return post;
        }),
      };
    case LIKE_FAIL:
      return {
        ...state,
        liked: false,
        liking: false,
        likeerror: action.error,
      };
    case COMMENT:
      return {
        ...state,
        commenting: true,
        ...(action.postId && {commentingPostId : action.postId})
      };
    case COMMENT_SUCCESS:
      return {
        ...state,
        commenting: false,
        commented: true,
        commentingPostId: null,
        feed: state.feed.map(post => {
          /* here post id is coming in the response so we are not adding post id during firing
          * of action so we are using action.result.postId
          */
          if(post.post_type == 'Transaction' && post.post_id == action.postId){
            return ({...post, comments: post.comments + 1});
          }
          else return post;
        }),
        ...(action.postId && action.callingComponent === 'complaint' && {customerComplaintsData: state.customerComplaintsData.map(complain=>{
          if(complain.post.post_id === action.postId){
            let newComments = complain.comments;
            newComments.push({comment:action.content, post_id:action.postId, created_at:moment().format("YYYY-MM-DDTHH:mm:ssZ")})
            return ({...complain, comments:newComments})
          }
          return complain
        })
      })}
    case COMMENT_FAIL:
      return {
        ...state,
        commenting: false,
        commented: false,
        commentingPostId: null,
        commenterror: action.error,
      }
      case LAUNCH_AR_REQUEST:
        return {
          ...state,
          requestingAr: true,
        };
      case LAUNCH_AR_REQUEST_SUCCESS:
        return {
          ...state,
          requestingAr: false,
          requestedAr: true,
        };
      case LAUNCH_AR_REQUEST_FAIL:
        return {
          ...state,
          requestingAr: false,
          requestedAr: false,
          errorAr: action.error,
        };
      case BD_DETAIL:
        return {
          ...state,
          bdLoading: true
        }
      case BD_DETAIL_SUCCESS:
        return {
          ...state,
          bdLoading: false,
          bdDetails: action.result,
          bdError: null,
        }
      case BD_DETAIL_FAIL:
        return {
          ...state,
          bdLoading: false,
          bdDetails: null,
          bdError: action.error
        }
      case CUSTOMER_COMPLAINTS_LOAD:
        return {
          ...state,
          customerComplaintsLoading: true,
        };
      case CUSTOMER_COMPLAINTS_LOAD_SUCCESS:
        return {
          ...state,
          customerComplaintsLoading: false,
          customerComplaintsLoaded: true,
          customerComplaintsData: action.result,
        };
      case CUSTOMER_COMPLAINTS_LOAD_FAIL:
        return {
          ...state,
          customerComplaintsLoading: false,
          customerComplaintsLoaded: true,
          customerComplaintsError: action.error,
        };
    default:
      return state;
  }
}

export function loadFeed(accessToken, lastPostId, lastPostTime, localityId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getFeed', {
      data: {accessToken, lastPostId, lastPostTime, localityId}
    }),
    lastPostId,
    lastPostTime,
  };
}

export function like(accessToken, postId, postUserId){
  return {
    types: [LIKE, LIKE_SUCCESS, LIKE_FAIL],
    promise: (client) => client.default.post('/likePost',{
      data : { accessToken, postId, userId: postUserId }
   }),
    postId,
    postUserId
  };
}

export function comment(accessToken, postId, postUserId, content, callingComponent){
  return {
    types: [COMMENT, COMMENT_SUCCESS, COMMENT_FAIL],
    promise: (client) => client.default.post('/commentOnPost',{
        data : { accessToken, postId, postUserId, content }
     }),
     callingComponent,
     postId,
     content
   };
 }

 export function launchArRequest(accessToken, merchantName,questObject,rewardSelected,requestId){
   return {
     types: [LAUNCH_AR_REQUEST, LAUNCH_AR_REQUEST_SUCCESS, LAUNCH_AR_REQUEST_FAIL],
     promise: (client) => client.default.post('/launchArRequest',{
       data : { accessToken, merchantName,questObject,rewardSelected, requestId}
     })
   }
 }

 export function getBDDetails(accessToken){
   return {
     types: [BD_DETAIL, BD_DETAIL_SUCCESS, BD_DETAIL_FAIL],
     promise: client=>client.default.post('/getBDDetails',{
       data:{accessToken}
     })
   }
  }

export function getCustomerComplaints(accessToken){
  return {
    types: [CUSTOMER_COMPLAINTS_LOAD, CUSTOMER_COMPLAINTS_LOAD_SUCCESS, CUSTOMER_COMPLAINTS_LOAD_FAIL],
    promise: (client) => client.default.post('/getCustomerComplaints', {
      data: {accessToken}
    })
  };
}