
const INSTALL = 'bookDeal/INSTALL';
const INSTALL_SUCCESS = 'bookDeal/INSTALL_SUCCESS';
const INSTALL_FAIL = 'bookDeal/INSTALL_FAIL';

const INSTALL_UNAUTH = 'bookDeal/INSTALL_UNAUTH';
const INSTALL_UNAUTH_SUCCESS = 'bookDeal/INSTALL_UNAUTH_SUCCESS';
const INSTALL_UNAUTH_FAIL = 'bookDeal/INSTALL_UNAUTH_FAIL';

const initialState = {
    loading: false,
    loaded: false,
    appInstalled: false
}

export default function appInstall(state = initialState, action = {}) {
    switch (action.type) {
        case INSTALL:
            return {
                ...state,
                loading: true,
                loaded: false,
                appInstalled: false,
            };
        case INSTALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                appInstalled: action.result.isDownloaded,
            };

        case INSTALL_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                appInstalled: false,
            }
        case INSTALL_UNAUTH:
            return {
                ...state,
                loading: true,
                loaded: false,
                appUnAuthInstalled: false,
            };
        case INSTALL_UNAUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                appUnAuthInstalled: action.result.isDownloaded,
            };

        case INSTALL_UNAUTH_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                appUnAuthnstalled: false,
            }
        default:
            return {
                ...state
            }
    }
}

export function isAppInstalled(accessToken, merchantId) {
    return {
        types: [INSTALL, INSTALL_SUCCESS, INSTALL_FAIL],
        promise: (client) => client.default.post('/isAppInstalled',{
            data:{accessToken, merchantId}
        }),
    };
}

export function isAppInstalledUnauth(merchantId, phoneNumber) {
    return {
        types: [INSTALL_UNAUTH, INSTALL_UNAUTH_SUCCESS, INSTALL_UNAUTH_FAIL],
        promise: (client) => client.default.post('/isAppInstalledUnauth',{
            data:{merchantId,phoneNumber}
        }),
    };
}

