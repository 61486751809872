import React, {Component, Proptypes} from 'react';
import moment from 'moment';
import Rating from './Rating';
import LikeComment from './LikeComment';
import { compactCurrencyString, convertNth, getCurrencySymbol, extractHashtag } from '../../helpers/Helpers';
import { ImageViewerFeedCards } from './ImageViewerFeedCards';
import defaultProfilePic from '../../images/profile-default.png';
import ReactHtmlParser from 'react-html-parser';
import ReactVideoPlayer from 'react-player';
import VisibilitySensor from 'react-visibility-sensor';
import { Modal, Image } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


const ga = window.ga;

export default class 
NewUserOnMerchantCard extends Component{

constructor(props){
    super(props);
    this.state =  {
      playVideo: true,
      minimize: true,
      showVideoModal: false
    };
}

handleLike = (e) => {
   
    if(this.props.post.rating>3)
    { 
      e.preventDefault();
      ga('send', 'event', 'Feed', 'post_like', 'post_like');
      this.props.like(this.props.user.accessToken,
        this.props.post.post_id,
        this.props.post.author_profile.user_id);
    }
  }

onChange = (isVisible) =>{
    this.setState({playVideo:isVisible})
}

minimize = () =>{
  this.setState({
    minimize: !this.state.minimize
  })
}

  render(){
    const post = this.props.post;

    let updateDiff = moment.unix(post.created_at).fromNow().replace(/ /g,'');

    if(updateDiff[0] === 'a' )
    {
      updateDiff = '1' + updateDiff.substr(1,5)
    }
    if(!isNaN(updateDiff.substr(0,2)))
    {
       updateDiff = updateDiff.substr(0,3)
    }
    else
    {
      updateDiff = updateDiff.substr(0,2)
    }

    if(!post.author_profile || (post.author_profile && !post.author_profile.first_name))
    return null;

    if(this.props.moment && post.selfie_rating < 3 && !post.video_url &&  !post.rating && !post.caption && !post.question_response_content)
    return null;

    return (
      <div>
        <div className="feed-card-wrapper">
        <div className="feed-top-title-wrapper">
          <div className="feed-user-icon">
            <ImageViewerFeedCards src={post.author_profile.profile_pic_magicurl ? post.author_profile.profile_pic_magicurl : post.author_profile.profile_pic_url} thumb={post.author_profile.profile_pic_url_thumbnail} onError={(e) => e.target.src = defaultProfilePic }/>
          </div>
          {window.innerWidth > 900 ? <div className="feed-user-title" style={{width: `${this.props.dontShrinkInMobile ? window.innerWidth > 768 ? '84%' : '76%' : window.innerWidth > 1500 ? "80%":"76%"}`, marginLeft: this.props.dontShrinkInMobile? '50px' : '48px' }} >
            
            <OverlayTrigger placement="right" overlay={<Tooltip id="promotion-fee">
                     {post.author_profile.first_name} 
                                        </Tooltip>}>
                                        <h3>   {post.author_profile.first_name.split(" ")[0]}   <span style={{fontSize:"11px",color:"rgba(0,0,0,0.54)",float:"right",marginTop:"2%"}}>{moment.unix(post.created_at).format("Do MMM 'YY")}</span></h3>
                                </OverlayTrigger>
            <p>{post.author_profile.total_visits} Visits, {compactCurrencyString(post.author_profile.total_spent, this.props.user.country)} Total spent</p>
          </div> : null}
          
          {window.innerWidth <= 900 ?<div className="feed-time-wrapper pull-right">
              {moment.unix(post.created_at).format("Do MMM 'YY")}
          </div> : null
          }
        </div>
        {window.innerWidth <= 900 ? <div className="feed-user-title-and-visits" >
            <h3>{post.author_profile.first_name}</h3>
            <p>{post.author_profile.total_visits} Visits, {compactCurrencyString(post.author_profile.total_spent, this.props.user.country)} Total spent</p>
          </div> : null}

        { (post && ((post.selfie_url || post.selfie_magic_url) || ( post.video_url ))) ? this.props.moment && post.media_type.toUpperCase()==='VIDEO' ? 
             <div className="feed-selfie-block" style={{marginBottom:"5%"}} onClick= {()=> ( window.innerWidth <= 450) && this.setState({ showVideoModal: true})}>
             <a className="fancybox activity_images" style={{width:'100%',height:`${( window.innerWidth <= 450) ? '90px':'250px'}`}}>
             {( window.innerWidth <= 450) ? 
              <ReactVideoPlayer url={post.video_url} controls={true} width={'100%'} height={'100%'} playing={false} light={ post.video_url_thumbnail} muted={true}/>
          :
                <VisibilitySensor onChange={this.onChange}>
                  <ReactVideoPlayer url={post.video_url} controls={true} width={'100%'} height={'100%'} playing={this.state.playVideo} light={ post.video_url_thumbnail} muted={true}/>
                </VisibilitySensor>
            }
             </a>
             </div>
           :
           post.selfie_rating >= 3 ?
           this.state.minimize && (post.bill_amount && post.bill_amount >0 || post.rating) && !( window.innerWidth <= 768)? null :<div className="feed-selfie-block" 
            style={{marginBottom:`${post && ((post.bill_amount && post.bill_amount >0) || post.rating || post.caption || post.question_response_content ) ? "5%" : "0"}`}} onClick={()=>this.minimize()} >
              <a className="fancybox activity_images"><img src={ post.selfie_magic_url ? post.selfie_magic_url : post.selfie_url}/></a>
            </div>
            :null
            :null
        }

        { (post && (post.rating || post.bill_amount > 0 )) ?<div className = 'rating-min-image-wrapper' style={{marginBottom: `${!post.rating ? '4%' : '0%'}`}}>
        <div style={{width:"80%",display:"flex",flexDirection:"column-reverse"}}>
        {!!post.rating && parseFloat(post.rating) !== 0 &&
          <div className="customer-comment-rate">
            <div className="customer-rate-title">Rated</div>
            <div className="star-rate-wrapper star-wraper">
              <Rating rating={post.rating}/>
            </div>
          </div>
        }
        {(post.bill_amount !== null && post.bill_amount >0) && <div className= "feed-user-billamount" >{window.innerWidth <= 450 ?"Bill:":"Bill amount:"}<span style={{color:"rgba(0, 0, 0, 0.87)"}}>{getCurrencySymbol(this.props.user.country)}{post.bill_amount}</span></div>}
        </div>
        {this.state.minimize && post.selfie_rating >=3 && !(this.props.moment && post.media_type.toUpperCase()==='VIDEO' ) && !( window.innerWidth <= 768) && !( post.bill_amount <= 0 && !post.rating) ?<div className = 'min-selfie-image' onClick={()=>this.minimize()}>
              <a className="fancybox activity_images" style={{width:'100%',height:'100%'}}><img style={{width:'100%',height:'100%',borderRadius:"4px",border: "1px solid rgba(0, 0, 0, 0.12)"}} src={post.selfie_magic_url ? post.selfie_magic_url : post.selfie_url}/></a>
        </div>:null }
        </div> :null }
        
       
        {post && !!post.caption && post.caption.length > 1 && <div className="feed-user-block" style={{marginBottom:`${post && post.question_response_content ? "2%" : "0"}`,marginTop:"4%",fontSize:`${window.innerWidth <= 900 ?"10px":"12px"}`}}>{ ReactHtmlParser( post.caption, {transform: extractHashtag})}</div>}
        {
           post && post.question_response_content && <div className="feed-user-block" style={{fontSize:`${window.innerWidth <= 900 ?"10px":"12px"}`, marginTop:`${post && !post.caption ? "2%" : "0"}`}}>{post.question_response_content}</div>
        }
        {
          !this.props.moment && !this.props.notShowNthVisit ? <div className="feed-top-title-wrapper">
            <div className="feed-user-visited" style={{marginLeft: 0}}>
              <div className="feed-user-block" style={{margin: 0, padding: 0,fontSize:`${window.innerWidth <= 900 ?"10px":"12px"}`}}><span
                style={{fontFamily: 'robotobold',color:"rgba(0, 0, 0, 0.87)"}}>{post.author_profile.first_name.split(" ")[0]}</span>
              &nbsp;visited your store for the {convertNth(post.author_profile.nth_visit)} time
            </div>
          </div>
        </div> : null 
        }

        {!!post.like_id && post.like_id != -1 ?
               <div className="feed-user-Like">
              <span  style={{color:"#ef1c74",fontSize:`${window.innerWidth <= 900 ?"10px":"12px"}`, fontWeight:"600"}}>
                Thanked</span>  <span style={{color: 'rgb(243, 96, 87)'}} className="fa fa-heart"></span></div>
              :
              <div className="feed-user-Like"><span style={{color: '#ef1c74',cursor:post.rating>3?"pointer":"default",fontSize:`${window.innerWidth <= 900 ?"10px":"12px"}`,fontWeight:"600"}} onClick={this.handleLike}>
                {post.rating>3 ? "Say thanks!": null}
              </span></div>
            }
            </div>
       {
       this.state.showVideoModal && 
        <Modal show={this.state.showVideoModal} onHide={()=>this.setState({showVideoModal:false})} style={{ margin:"50% 0%"}}>
          <Modal.Body style={{height:"26vh"}}>
                  <ReactVideoPlayer url={post.video_url} controls={true} width={'100%'}  height={'100%'} playing={true} muted={true}/>
          </Modal.Body>
         </Modal>
}
    </div>
  );
}
}
