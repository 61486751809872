const LOAD = 'merchantOnboarding/LOAD';
const LOAD_SUCCESS = 'merchantOnboarding/LOAD_SUCCESS';
const LOAD_FAIL = 'merchantOnboarding/LOAD_FAIL';

const LOAD_SUBS_ID = 'merchantOnboarding/LOAD_SUBS_ID';
const LOAD_SUBS_ID_SUCCESS = 'merchantOnboarding/LOAD_SUBS_ID_SUCCESS';
const LOAD_SUBS_ID_FAIL = 'merchantOnboarding/LOAD_SUBS_ID_FAIL';

const LOAD_ACTIVATE_SAAS = 'merchantOnboarding/LOAD_ACTIVATE_SAAS';
const ACTIVATE_SAAS_SUCCESS = 'merchantOnboarding/ACTIVATE_SAAS_SUCCESS';
const ACTIVATE_SAAS_FAIL = 'merchantOnboarding/ACTIVATE_SAAS_FAIL';

const UPDATE_PACKAGE_REQUEST='merchantOnboarding/UPDATE_PACKAGE_REQUEST';
const UPDATE_PACKAGE_REQUEST_SUCCESS='merchantOnboarding/UPDATE_PACKAGE_REQUEST_SUCCESS';
const UPDATE_PACKAGE_REQUEST_FAIL='merchantOnboarding/UPDATE_PACKAGE_REQUEST_FAIL';

const VERIFY_ONLINE_PAYMENT='merchantOnboarding/VERIFY_ONLINE_PAYMENT';
const VERIFY_ONLINE_PAYMENT_SUCCESS='merchantOnboarding/VERIFY_ONLINE_PAYMENT_SUCCESS';
const VERIFY_ONLINE_PAYMENT_FAIL='merchantOnboarding/VERIFY_ONLINE_PAYMENT_FAIL';


const VERIFY_299_PAYMENT='merchantOnboarding/VERIFY_299_PAYMENT';
const VERIFY_299_PAYMENT_SUCCESS='merchantOnboarding/VERIFY_299_PAYMENT_SUCCESS';
const VERIFY_299_PAYMENT_FAIL='merchantOnboarding/VERIFY_299_PAYMENT_FAIL';

const ADD_MERCHANT = 'merchantOnboarding/ADD_OUTLET';
const ADD_MERCHANT_SUCCESS = 'merchantOnboarding/ADD_OUTLET_SUCCESS';
const ADD_MERCHANT_FAIL = 'merchantOnboarding/ADD_OUTLET_FAIL';

const GET_GMV_LOADING = 'merchantOnboarding/GET_GMV_LOADING';
const GET_GMV_SUCCESS = 'merchantOnboarding/GET_GMV_SUCCESS';
const GET_GMV_FAIL = 'merchantOnboarding/GET_GMV_FAIL';

const ACTIVATE_DIRECT_LOADING = 'merchantOnboarding/ACTIVATE_DIRECT_LOADING';
const ACTIVATE_DIRECT_SUCCESS = 'merchantOnboarding/ACTIVATE_DIRECT_SUCCESS';
const ACTIVATE_DIRECT_FAIL = 'merchantOnboarding/ACTIVATE_DIRECT_FAIL';

const GET_MUID_BY_ALIAS_LOADING = 'merchantOnboarding/GET_MUID_BY_ALIAS_LOADING';
const GET_MUID_BY_ALIAS_SUCCESS = 'merchantOnboarding/GET_MUID_BY_ALIAS_SUCCESS';
const GET_MUID_BY_ALIAS_FAIL = 'merchantOnboarding/GET_MUID_BY_ALIAS_FAIL';

const GET_ORDERS_URL_LOADING = 'merchantOnboarding/GET_ORDERS_URL_LOADING';
const GET_ORDERS_URL_SUCCESS = 'merchantOnboarding/GET_ORDERS_URL_SUCCESS';
const GET_ORDERS_URL_FAIL = 'merchantOnboarding/GET_ORDERS_URL_FAIL';

const UPDATE_DISCOUNT = 'merchantOnboarding/UPDATE_DISCOUNT';
const UPDATE_DISCOUNT_SUCCESS = 'merchantOnboarding/UPDATE_DISCOUNT_SUCCESS';
const UPDATE_DISCOUNT_FAIL = 'merchantOnboarding/UPDATE_DISCOUNT_FAIL';


const initialState = {
  loading: false,
  loaded: false,
  requested: false,
}

export default function merchantOnboarding(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

      case LOAD_SUBS_ID:
      return {
        ...state,
        loadingId: true,
      };
    case LOAD_SUBS_ID_SUCCESS:
      return {
        ...state,
        loadingId: false,
        loadedId: true,
        subscriptionId: action.result.subscriptionId
      };
    case LOAD_SUBS_ID_FAIL:
      return {
        ...state,
        loadingId: false,
        loadedId: false,
        error: action.error
      };

    case UPDATE_PACKAGE_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case UPDATE_PACKAGE_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        requested: true,
      };
    case UPDATE_PACKAGE_REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        requested: false,
        requestError: action.error,
      };
    case VERIFY_ONLINE_PAYMENT:
      return {
        ...state,
        verifyingPayment: true,
      };
    case VERIFY_ONLINE_PAYMENT_SUCCESS:
      if (action.result.message && action.result.message.toUpperCase() == 'PENDING') {
        return {
          ...state,
          verifyingPayment: false,
          paymentVerified: false,
          paymentPending: true,
        };
      } else {
        return {
          ...state,
          verifyingPayment: false,
          paymentVerified: true,
        };
      }
    case VERIFY_ONLINE_PAYMENT_FAIL:
      if (action.error.message && action.error.message.toUpperCase() == 'PENDING') {
        return {
          ...state,
          verifyingPayment: false,
          paymentVerified: false,
          paymentPending: true,
        };
      } else {
        return {
          ...state,
          verifyingPayment: false,
          paymentVerified: false,
          verificationError: action.error,
        };
      }
    case VERIFY_299_PAYMENT:
    return {
      ...state,
      verifyingPaymentNew: true,
    };
    case VERIFY_299_PAYMENT_SUCCESS:
      return {
        ...state,
        verifyingPaymentNew: false,
        paymentVerifiedNew: true,
      };
    case VERIFY_299_PAYMENT_FAIL:
      return {
        ...state,
        verifyingPaymentNew: false,
        paymentVerifiedNew: false,
        verificationErrorNew: action.error,
      };
    case ADD_MERCHANT:
      return {
        ...state,
        addingOutlet: true,
        addedOutlet: false,
      }
    case ADD_MERCHANT_SUCCESS:
      return {
        ...state,
        addingOutlet: false,
        addedOutlet: true,
        merchantId: action.result.merchant_id,
        errorMessage: action.result.error
      }
    case ADD_MERCHANT_FAIL:
      return {
        ...state,
        addingOutlet: false,
        addedOutlet: true,
        error: action.error
      }
    case GET_GMV_LOADING:
      return {
        ...state
      }
    case GET_GMV_SUCCESS:
      return {
        ...state,
        gmv: action.result.total_cashback_gmv ? action.result.total_cashback_gmv : 0
      }
    case GET_GMV_FAIL:
      return {
        ...state,
        gmv: 0
      }
    case ACTIVATE_DIRECT_LOADING:
      return {
        ...state
      }
    case ACTIVATE_DIRECT_SUCCESS:
      return {
        ...state,
        activateDirectResponse: action.result
      }
    case ACTIVATE_DIRECT_FAIL:
      return {
        ...state,
        activateDirectResponse: action.error
      }
    case LOAD_ACTIVATE_SAAS:
      return {
        ...state,
        saas_loading: true
      }
    case ACTIVATE_SAAS_SUCCESS:
      return {
        ...state,
        ultronOnboardingResponse: action.result
      }
    case ACTIVATE_SAAS_FAIL:
      return {
        ...state,
        ultronOnboardingResponse: action.error
      }
    case GET_MUID_BY_ALIAS_LOADING:
      return {
        ...state
      }
    case GET_MUID_BY_ALIAS_SUCCESS:
      return {
        ...state,
        muidByAliasResponse: action.result
      }
    case GET_MUID_BY_ALIAS_FAIL:
      return {
        ...state,
        muidByAliasResponse: action.error
      }
    case GET_ORDERS_URL_LOADING:
      return {
        ...state
      }
    case GET_ORDERS_URL_SUCCESS:
      return {
        ...state,
        orderShortUrlResponse: action.result
      }
    case GET_ORDERS_URL_FAIL:
      return {
        ...state,
        orderShortUrlResponse: action.error
      }
    case UPDATE_DISCOUNT:
      return {
        ...state
      }
    case UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        updateDiscountResult: action.result,
      }
    case UPDATE_DISCOUNT_FAIL:
      return {
        ...state,
        updateDiscountResult: action.error,
      }

   default:
      return state;
  }
}

export function getSubscriptionPackageData(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.default.post('/getSubscriptionPackageData',{
      data:{id}
    })
  };
}

export function getSubscriptionId(data) {
  return {
    types: [LOAD_SUBS_ID, LOAD_SUBS_ID_SUCCESS, LOAD_SUBS_ID_FAIL],
    promise: (client) => client.default.post('/getSubscriptionId',{
      data:data
    })
  };
}

export function getOrdersURL(merchantId) {
  return {
    types: [GET_ORDERS_URL_LOADING, GET_ORDERS_URL_SUCCESS, GET_ORDERS_URL_FAIL],
    promise: (client) => client.default.post('/getOrdersUrl',{
      data:{merchantId}
    })
  };
}

export function activateSaasUltron(data) {
  return {
    types: [LOAD_ACTIVATE_SAAS, ACTIVATE_SAAS_SUCCESS, ACTIVATE_SAAS_FAIL],
    promise: (client) => client.default.post('https://webapi.magicpin.in/ultron-onboarding/createStore',{
      data:data
    })
  };
}

export function updateMerchantPackageSubscription(responseBody){
  return {
    types: [UPDATE_PACKAGE_REQUEST, UPDATE_PACKAGE_REQUEST_SUCCESS, UPDATE_PACKAGE_REQUEST_FAIL],
    promise: (client) => client.default.post('/updateMerchantPackageSubscription', {
      data: {responseBody}
    })
  }
}

export function verifyOnlinePayment(id, txn){
  return {
    types: [VERIFY_ONLINE_PAYMENT, VERIFY_ONLINE_PAYMENT_SUCCESS, VERIFY_ONLINE_PAYMENT_FAIL],
    promise: (client) => client.default.post('/verifyOnlinePayment', {
      data: {id, txn}
    })
  }
}

export function verifyPayment(args){
  return {
    types: [VERIFY_299_PAYMENT, VERIFY_299_PAYMENT_SUCCESS, VERIFY_299_PAYMENT_FAIL],
    promise: (client) => client.default.post('/verify299Payment', {
      data: args
    })
  }
}

export function addOutlet(payload) {
  const {outletName, contact, email, address, locality, categoryId, city, landingSource} = payload;

  let data = new FormData();
  
  data.append('outlet_name', outletName);
  data.append('phone', contact);
  data.append('email', email);
  data.append('address', address);
  data.append('locality',locality);
  data.append('city', city);
  data.append('category_id', categoryId);
  data.append('country', 'India');
  data.append('landing_source', landingSource);
  data.append('created_by', "magicStore");
  
  return {
    types: [ADD_MERCHANT, ADD_MERCHANT_SUCCESS, ADD_MERCHANT_FAIL],
    promise: (client) => client.default.post('https://mcp.magicpin.in/mcp/merchant/create/',{
      data
    })
  }
}

export function getGMV(userId) {
  return {
    types: [GET_GMV_LOADING, GET_GMV_SUCCESS, GET_GMV_FAIL],
    promise: (client) => client.default.get('/getGMV',{
      params:{userId}
    })
  };
}

export function activateDirectMagicstore(payload) {
  return {
    types: [ACTIVATE_DIRECT_LOADING, ACTIVATE_DIRECT_SUCCESS, ACTIVATE_DIRECT_FAIL],
    promise: (client) => client.default.post('/activateDirectMagicstore',{
      data: {payload}
    })
  };
}

export function getMUIDByAlias(alias) {
  return {
    types: [GET_MUID_BY_ALIAS_LOADING, GET_MUID_BY_ALIAS_SUCCESS, GET_MUID_BY_ALIAS_FAIL],
    promise: (client) => client.default.post('/getMUIDByAlias',{
      data: {alias}
    })
  };
}

export function updateFirstTimeCustomerDiscount(accessToken, payload){
  return {
    types: [UPDATE_DISCOUNT, UPDATE_DISCOUNT_SUCCESS, UPDATE_DISCOUNT_FAIL],
    promise: (client) => client.default.post('/updateFirstTimeCustomerDiscount', {
      data: {...payload, accessToken}
    })
  }
}
